<div class="grid-container">
  <form
    *ngIf="creditNoteForm"
    (ngSubmit)="onSubmit()"
    [formGroup]="creditNoteForm"
    class="p-3 form-grid-container"
  >
    <div id="credit-note-form-top">
      <div class="form-group">
        <label class="center-block"
          >{{ 'Credit note for' | translate }} {{ invoice?.number }}</label
        >
        <ng-select
          id="customer"
          [items]="customers"
          [loading]="isLoadingCustomers"
          placeholder="{{ 'Select customer' | translate }}"
          formControlName="business"
        >
        </ng-select>
      </div>
      <app-address
        [billingAddress]="true"
        [address]="customerAddress"
      ></app-address>
    </div>
    <div class="mt-3" id="dates">
      <div class="input-group">
        <input
          class="form-control"
          formControlName="date"
          placeholder="{{ 'Credit note date' | translate }}"
          name="d2"
          (click)="d2.toggle()"
          ngbDatepicker
          placement="bottom-left"
          #d2="ngbDatepicker"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-dark"
            type="button"
            (click)="d2.toggle()"
          >
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
        <div class="invalid-feedback">
          {{ 'Please use dd/mm/yyyy to format your dates.' | translate }}
        </div>
      </div>
    </div>
    <div id="credit-note-items-table">
      <table class="table table-borderless mt-3">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ 'Item' | translate }}</th>
            <th scope="col">{{ 'Quantity' | translate }}</th>
            <th scope="col">{{ 'Unit price' | translate }}</th>
            <th scope="col" id="tax-header">{{ 'Tax %' | translate }}</th>
            <th scope="col">{{ 'Total' | translate }}</th>
            <th scope="col" class="invisible-header"></th>
            <th scope="col" class="invisible-header"></th>
          </tr>
        </thead>
        <tbody formArrayName="items">
          <ng-template
            ngFor
            let-item
            [ngForOf]="creditNoteForm?.get('items')['controls']"
            let-i="index"
          >
            <tr [formGroupName]="i">
              <td class="p-0">
                <input
                  class="form-control"
                  type="text"
                  (focus)="
                    i === creditNoteForm?.get('items').value.length - 1
                      ? addCreditNoteItem()
                      : ''
                  "
                  formControlName="description"
                />
              </td>
              <td class="p-0">
                <input
                  class="form-control"
                  type="number"
                  (keyup)="updateTotal(i)"
                  (change)="updateTotal(i)"
                  (focus)="
                    i === creditNoteForm?.get('items').value.length - 1
                      ? addCreditNoteItem()
                      : ''
                  "
                  formControlName="quantity"
                />
              </td>
              <td class="p-0">
                <input
                  class="form-control"
                  type="number"
                  (keyup)="updateTotal(i)"
                  (change)="updateTotal(i)"
                  (focus)="
                    i === creditNoteForm?.get('items').value.length - 1
                      ? addCreditNoteItem()
                      : ''
                  "
                  formControlName="unitPrice"
                />
              </td>
              <td class="p-0">
                <ng-select
                  id="tax"
                  class="table-select"
                  [addTag]="createNewTaxRate"
                  [addTagText]="addTagText"
                  [loading]="isLoadingTaxRates"
                  [items]="taxRates"
                  (keyup)="updateTotal(i)"
                  (focus)="
                    i === creditNoteForm?.get('items').value.length - 1
                      ? addCreditNoteItem()
                      : ''
                  "
                  (change)="updateTotal(i)"
                  formControlName="taxRate"
                >
                </ng-select>
              </td>
              <td class="p-0">
                <input
                  class="form-control"
                  type="text"
                  disabled="true"
                  formControlName="total"
                />
              </td>
              <td class="p-0">
                <button
                  (click)="duplicateItem(i)"
                  type="button"
                  class="btn btn-link"
                >
                  <i
                    class="text-dark far fa-fw fa-copy"
                    ngbTooltip="{{ 'Copy' | translate }}"
                  ></i>
                </button>
              </td>
              <td class="p-0">
                <button
                  *ngIf="i !== creditNoteForm?.get('items').value.length - 1"
                  (click)="removeCreditNoteItem(i)"
                  class="btn btn-link"
                  type="button"
                >
                  <i
                    class="far fa-fw fa-trash-alt text-danger"
                    ngbTooltip="{{ 'Delete' | translate }}"
                  ></i>
                </button>
                <button
                  *ngIf="i === creditNoteForm?.get('items').value.length - 1"
                  (click)="addCreditNoteItem()"
                  class="btn btn-link"
                  type="button"
                >
                  <i
                    class="far fa-fw fa-plus text-success"
                    ngbTooltip="{{ 'New line' | translate }}"
                  ></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div id="credit-note-total">
      <div class="credit-note-total--item">
        <span>{{ 'Sub total' | translate }}</span>
        <span> &minus; {{ subTotal | currency: 'EUR':'code' }}</span>
      </div>
      <div class="credit-note-total--item">
        <span>{{ 'Taxes' | translate }}</span>
        <span> &minus; {{ taxes | currency: 'EUR':'code' }}</span>
      </div>
      <div class="credit-note-total--item bg-gray-light">
        <span>{{ 'Total' | translate }}</span>
        <span> &minus; {{ total | currency: 'EUR':'code' }}</span>
      </div>
    </div>
    <div id="contact-and-comment">
      <div id="contact">
        <label class="center-block">{{ 'Main contact' | translate }}</label>
        <app-contact
          [contact]="customerMainContact"
          [mainContact]="true"
        ></app-contact>
      </div>
      <div id="comment">
        <label class="center-block">&nbsp;</label>
        <textarea
          id="notes"
          class="form-control"
          maxlength="256"
          formControlName="notes"
          placeholder="{{ 'Add comment for client' | translate }}"
          data-cy="notes"
          cols="20"
          rows="2"
        ></textarea>
      </div>
    </div>
    <div id="submit-buttons">
      <app-button
        [isLoading]="isLoading"
        [btnClass]="'btn btn-primary text-white'"
        [type]="'submit'"
      >
        {{ 'Save' | translate }}
      </app-button>
      <app-button
        [isLoading]="isLoading"
        (click)="saveAndDownloadAsPDF()"
        [type]="'button'"
        [btnClass]="'btn btn-dark ml-3'"
      >
        <i class="far fa-fw fa-download"></i>
        <span>{{ 'Save and download as PDF' | translate }}</span>
      </app-button>
    </div>
  </form>
</div>
