import { Component, OnDestroy } from '@angular/core';
import { LoadWallet } from 'app/core/store/actions/wallet.actions';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Wallet } from 'app/core/store/models/wallet.model';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-credits',
  templateUrl: 'credits.component.html',
  styleUrls: ['credits.component.scss']
})
export class CreditsComponent implements OnDestroy {
  title = extract('Manage Credits');
  wallet: Wallet;
  loading = true;

  constructor(private store: Store<State>) {
    this.store.dispatch(new LoadWallet());
    this.store.select('wallet')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.wallet = state;
          this.loading = false;
        }
      });
  }

  ngOnDestroy() { }
}
