<div class="card-header d-flex">
  <h3 class="align-self-center mb-0">{{ 'Edit driver' | translate }}</h3>
  <button class="btn ml-auto" (click)="activeModal.close()">
    {{ 'Close' | translate }}
  </button>
</div>
<div class="p-3">
  <app-cmp-user-context-form
    [userContext]="driver"
    (userContextFormValue)="updateDriver($event)"
    [driver]="true"
  ></app-cmp-user-context-form>
</div>
