/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../shared/customer-form/customer-form.component.ngfactory";
import * as i3 from "../../shared/customer-form/customer-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./new-customer-modal.component";
import * as i6 from "@ngrx/store";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@ngrx/effects";
import * as i9 from "../../services/toast.service";
var styles_NewCustomerModalComponent = [];
var RenderType_NewCustomerModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewCustomerModalComponent, data: {} });
export { RenderType_NewCustomerModalComponent as RenderType_NewCustomerModalComponent };
export function View_NewCustomerModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-customer-form", [], null, [[null, "customerFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("customerFormSubmitted" === en)) {
        var pd_0 = (_co.createCustomer($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerFormComponent_0, i2.RenderType_CustomerFormComponent)), i0.ɵdid(5, 573440, null, 0, i3.CustomerFormComponent, [i4.FormBuilder], { isLoading: [0, "isLoading"], countries: [1, "countries"], query: [2, "query"] }, { customerFormSubmitted: "customerFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; var currVal_2 = _co.countries; var currVal_3 = _co.query; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Add new customer")); _ck(_v, 2, 0, currVal_0); }); }
export function View_NewCustomerModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-customer-modal", [], null, null, null, View_NewCustomerModalComponent_0, RenderType_NewCustomerModalComponent)), i0.ɵdid(1, 114688, null, 0, i5.NewCustomerModalComponent, [i6.Store, i7.NgbActiveModal, i8.Actions, i9.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewCustomerModalComponentNgFactory = i0.ɵccf("app-new-customer-modal", i5.NewCustomerModalComponent, View_NewCustomerModalComponent_Host_0, { query: "query" }, {}, []);
export { NewCustomerModalComponentNgFactory as NewCustomerModalComponentNgFactory };
