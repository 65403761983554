import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';
import { DeleteLocation } from 'app/core/store/actions/location.actions';
import { LocationTypes } from 'app/core/store/models/location-type.model';
import { Location } from 'app/core/store/models/location.model';
import { State } from 'app/core/store/store.model';
import * as types from 'app/core/store/types/location.types';
import { extract } from 'app/services/i18n.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'app-manage-locations',
  templateUrl: './manage-locations.component.html',
})
export class ManageLocationsComponent implements OnDestroy {
  locationTypes = LocationTypes;
  locations$: Observable<Location[]>;
  locationType$ = new BehaviorSubject<string>(LocationTypes.OTHER);
  loading = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private modalService: NgbModal,
    private store: Store<State>,
    private updates$: Actions
  ) {
    this.locations$ = combineLatest(
      this.locationType$,
      this.updates$.pipe(ofType(types.location.DELETE_LOCATION_SUCCEEDED), startWith(null)),
    ).pipe(
      switchMap(([locationType]) => {
        this.loading = true;
        return this.api.get({
          path: `/locations?locationType.code=${locationType}&order[name]=asc`
        });
      }),
      map(data => {
        this.loading = false;
        return <Location[]>data['hydra:member'];
      }),
    );
  }

  edit(id: string) {
    this.router.navigate(['superadmin/locations', id, 'edit']);
  }

  deleteLocation(locationId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this location?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteLocation({ locationId }));
    });
  }

  ngOnDestroy() { }
}
