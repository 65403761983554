import {
  Component,
  OnInit,
  Output,
  OnChanges,
  Input,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RegistrationRequest } from '../../store/models/registration-request.model';
import { SubscriptionType } from '../../store/models/subscriptions.model';

@Component({
  selector: 'app-registration-request-form',
  templateUrl: './registration-request-form.component.html',
  styleUrls: ['./registration-request-form.component.scss']
})
export class RegistrationRequestFormComponent implements OnInit, OnChanges {
  @Input()
  languages: { value: string; label: string }[];
  @Input()
  countries: { value: string; label: string }[];
  @Input()
  subscriptionTypes: SubscriptionType[];
  @Input()
  loading: boolean;
  @Input()
  isVerifyingVatNumber: boolean;
  @Input()
  vatNumberIsValid: boolean;
  @Input()
  vatNumberIsVerified: boolean;
  @Input()
  registrationRequest: RegistrationRequest;
  @Output()
  registrationRequestFormSaved = new EventEmitter<any>();
  @Output()
  registrationRequestFormSavedAndApproved = new EventEmitter<any>();
  @Output()
  registrationRequestDeclined = new EventEmitter<any>();
  @Output()
  verifyRegistrationRequestVatNumber = new EventEmitter<any>();

  registrationRequestForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (!this.registrationRequestForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.registrationRequest &&
      changes.registrationRequest.currentValue
    ) {
      if (!this.registrationRequestForm) {
        this.createForm();
      }
      this.registrationRequestForm.patchValue(
        changes.registrationRequest.currentValue
      );
    }
  }

  createForm() {
    this.registrationRequestForm = this.fb.group({
      companyName: [null],
      vatNumber: [null],
      streetAndNumber: [null],
      zipcode: [null],
      city: [null],
      country: [null],
      locale: [null],
      invoiceEmail: [null],
      phoneNumber: [null],
      email: [null],
      firstName: [null],
      lastName: [null],
      subscriptionTypes: [null]
    });
  }

  save() {
    const body = Object.assign({}, this.registrationRequestForm.value);
    body.subscriptionTypes = body.subscriptionTypes.map(
      (st: SubscriptionType) => {
        return st['@id'];
      }
    );
    this.registrationRequestFormSaved.emit(body);
  }

  saveAndApprove() {
    const body = Object.assign({}, this.registrationRequestForm.value);
    body.subscriptionTypes = body.subscriptionTypes.map(
      (st: SubscriptionType) => st['@id']
    );
    this.registrationRequestFormSavedAndApproved.emit(body);
  }

  decline() {
    this.registrationRequestDeclined.emit();
  }
  verifyVatNumber() {
    this.verifyRegistrationRequestVatNumber.emit();
  }
}
