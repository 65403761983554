<div class="login-background fullscreen overflow-y">
    <div class="row justify-content-center mt-5">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <img src="/assets/img/logo.png" alt="Hakka" class="sidebar-logo"/>
                    <p class="text-muted">{{ 'Enter your email address to request access tot the Hakka platform.' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
