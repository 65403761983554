import { Component, VERSION } from '@angular/core';

@Component({
  selector: 'app-cmp-about',
  templateUrl: './about.component.html',
})
export class AboutComponent {
  version: any;

  constructor() {
    this.version = VERSION;
  }
}
