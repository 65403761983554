export const messages = {
  LOAD_MESSAGES: '[Message] Load',
  LOAD_SUCCEEDED: '[Message] Load Success',
  LOAD_FAILED: '[Message] Load Fail',
  CREATE_MESSAGE: '[Message] Create',
  CREATE_SUCCEEDED: '[Message] Create Success',
  CREATE_FAILED: '[Message] Create Fail',
  CREATE_REPLY: '[Message] Create Reply',
  CREATE_REPLY_SUCCEEDED: '[Message] Create Reply Success',
  CREATE_REPLY_FAILED: '[Message] Create Reply Fail',
  CLEAR: '[Messages] Clear'
};
