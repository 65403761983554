import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Message } from 'app/core/store/models/messages.model';

interface MessageReply {
  messageId: String;
  reply: String;
}

@Component({
  selector: 'app-cmp-message',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.scss']
})
export class MessageComponent {
  @Input() message: Message;
  @Output() replySubmited = new EventEmitter<MessageReply>();
  @Output() replying = new EventEmitter<boolean>();
  replyVisible = false;
  reply: String;

  toggleReply(): void {
    this.replyVisible = !this.replyVisible;
    this.replying.emit(this.replyVisible);
  }

  onSubmit(id, reply): void {
    this.replySubmited.emit({
      messageId: id,
      reply: reply
    });
  }
}
