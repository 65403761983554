<ngb-alert *ngIf="alert" [type]="alert.type" (close)="closeAlert()">
  <i class="fa" [ngClass]="alert.icon"></i>
  <span>{{ alert.message }}</span>
</ngb-alert>
<div class="card" id="transactions-container">
  <h3 class="card-header">{{ 'My Transactions' | translate }}</h3>
  <div class="card-body pre-scrollable" id="transaction-list">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let transaction of transactions">
        <app-cmp-credit-transaction *ngIf="transaction.type === 'credit'; else debitTransaction" [creditTransaction]="transaction"></app-cmp-credit-transaction>
        <ng-template #debitTransaction>
          <app-cmp-debit-transaction [debitTransaction]="transaction"></app-cmp-debit-transaction>
        </ng-template>
      </li>
      <button class="btn btn-block btn-info" [disabled]="!nextPage" (click)="loadMoreTransactions()">
        <span *ngIf="loading"><i class="far fa-spinner fa-spin"></i></span>
        <span *ngIf="loading; else loaded">
          {{ 'Loading transactions' | translate }}
        </span>
        <ng-template #loaded>
          {{ nextPage ? 'Load more' : 'All transactions loaded' | translate }}
        </ng-template>
      </button>
    </ul>
  </div>
</div>
