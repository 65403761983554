import { Actions, ofType } from '@ngrx/effects';
import { UpdateProduct, LoadProducts } from 'app/core/store/actions/product.actions';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import * as ProductTypes from 'app/core/store/types/product.types';
import { Product } from 'app/core/store/models/product.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-tariff-modal',
  templateUrl: './edit-tariff-modal.component.html',
})
export class EditTariffModalComponent implements OnDestroy {
  @Input()
  tariff: Product;

  isLoading: boolean;

  constructor(
    private modal: NgbActiveModal,
    private store: Store<State>,
    private updates$: Actions
  ) {
    this.updates$
      .pipe(ofType(ProductTypes.product.UPDATE_PRODUCT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.isLoading = false;
        this.store.dispatch(new LoadProducts());
        this.modal.close(result['payload']);
      });
  }

  editTariff(tariffFormValue) {
    const body = Object.assign({}, tariffFormValue, { template: true });
    this.isLoading = true;
    this.store.dispatch(
      new UpdateProduct({
        productId: this.tariff.id,
        body: body
      })
    );
  }

  ngOnDestroy() { }
}
