<div class="login-background fullscreen overflow-sm-x-hidden overflow-y">
  <div class="row justify-content-center mt-5">
    <div class="col-md-4">
      <div class="card">
        <form #loginForm="ngForm">
          <div class="card-body">
            <h3>{{ 'Request a password reset' | translate }}</h3>
            <p class="text-muted">
              {{
                'Enter your email address to receive a link to reset your password.'
                  | translate
              }}
            </p>
            <div class="form-group mb-3">
              <label for="login-user">{{ 'Email' | translate }}</label>
              <input
                [(ngModel)]="email"
                name="mail"
                data-cy="email"
                type="email"
                placeholder="{{ 'Your email address' | translate }}"
                autofocus="autofocus"
                class="form-control"
              />
            </div>
          </div>
          <div class="card-footer">
            <button
              data-cy="reset"
              type="submit"
              (click)="reset()"
              class="btn btn-primary text-white"
            >
              {{ 'Reset' | translate }}
            </button>
            <a routerLink="/" class="btn float-right">{{
              'Ready to log in ?' | translate
            }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
