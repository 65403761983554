import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { DropTenant, Logout } from 'app/core/store/actions/session.actions';
import { Tenant } from 'app/core/store/models/tenant.model';
import { UserContext } from 'app/core/store/models/user-contexts.model';
import { State } from 'app/core/store/store.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

/**
 * The avatar component a small footer type thingy with a users gravatar.
 *
 * @export
 * @class AvatarComponent
 */
@Component({
  selector: 'app-cmp-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnDestroy {
  private _showUsername = false;
  private _size = '';
  isAdmin = false;
  tenants: Tenant[];

  @Input() dropdown;

  @Input()
  set showName(showUsername: boolean) {
    this._showUsername = showUsername || false;
  }

  @Input()
  set size(size: string) {
    this._size = size || '';
  }

  @Input() opened;

  user$: Observable<UserContext>;
  constructor(private store: Store<State>, private router: Router) {
    this.store.select('tenants')
      .pipe(untilDestroyed(this))
      .subscribe(tenants => (this.tenants = tenants.userTenants));
    this.user$ = this.store.select(state => state.user);
    this.user$.subscribe(user => {
      if (user) {
        this.isAdmin =
          user.roles.filter(role => role['name'] === 'admin').length !== 0;
      }
    });
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  login() {
    this.router.navigate(['/login']);
  }

  changeTenant() {
    this.store.dispatch(new DropTenant());
  }

  get showName(): boolean {
    return this._showUsername;
  }
  get size(): string {
    return this._size;
  }

  ngOnDestroy() { }
}
