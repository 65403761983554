import * as types from '../types/update.types';
import * as UpdateActions from '../actions/update.actions';
import * as moment from 'moment';

const INITIAL_STATE: string = moment().format('YYYY-MM-DD HH:mm:ss');

export function reducer(state = INITIAL_STATE, action: UpdateActions.All) {
  switch (action.type) {
    case types.updates.LOAD_UPDATES_SUCCEEDED:
      state = action.payload;
      return state;
  }
  return state;
}
