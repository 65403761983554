<div class="m-3 registration-request-grid">
  <ngb-alert *ngIf="alert" [type]="alert.type" (close)="closeAlert()">
    <i class="fa" [ngClass]="alert.icon"></i>
    <span>{{ alert.message | translate }}</span>
  </ngb-alert>
  <div class="card">
    <h5 class="card-title card-title-bb">
      <strong>{{ 'Edit Registration request' | translate }}</strong>
    </h5>
    <app-registration-request-form
      [subscriptionTypes]="subscriptionTypes"
      [languages]="languages"
      [countries]="countries"
      [loading]="loading"
      [isVerifyingVatNumber]="isVerifyingVatNumber"
      [vatNumberIsValid]="vatNumberIsValid"
      [vatNumberIsVerified]="vatNumberIsVerified"
      [registrationRequest]="registrationRequest"
      (registrationRequestFormSaved)="save($event)"
      (registrationRequestFormSavedAndApproved)="saveAndApprove($event)"
      (registrationRequestDeclined)="decline()"
      (verifyRegistrationRequestVatNumber)="verifyVatNumber()"
    >
    </app-registration-request-form>
  </div>
  <div *ngIf="vatVerificationResult || duplicateVatNumberTenant" class="vat-verification-result p-3">
    <div *ngIf="vatVerificationResult" class="alert" [ngClass]="{'alert-danger': vatVerificationResult.valid === 'false', 'alert-success': vatVerificationResult.valid === 'true'}">
      <div>
        <p><b>{{ ((vatVerificationResult.valid === 'false') ? 'VIES check failed' : 'VIES check OK') | translate }}</b></p>
      </div>
      <div>
        <span>{{ 'VAT number:' | translate }}</span>
        <span
          >&nbsp;{{ vatVerificationResult.countryCode
          }}{{ vatVerificationResult.vatNumber }}</span
        >
      </div>
      <div>
        <span>{{ 'Name:' | translate }}</span>
        <span>&nbsp;{{ vatVerificationResult.name }}</span>
      </div>
      <div>
        <span>{{ 'Address:' | translate }}</span>
        <span>&nbsp;{{ vatVerificationResult.address }}</span>
      </div>
    </div>
    <div *ngIf="duplicateVatNumberTenant" class="alert alert-danger duplicate-vat-number-tenant">
      <div>
        <p><b>{{ 'Tenant with the same VAT number found' | translate }}</b></p>
      </div>
      <div>
        <span>{{ 'Name:' | translate }}</span>
        <span>&nbsp;{{ duplicateVatNumberTenant.name }}</span>
      </div>
      <div>
        <span>{{ 'Address:' | translate }}</span>
        <span>&nbsp;{{ duplicateVatNumberTenant.address }}</span>
      </div>
    </div>
  </div>
</div>
