<div class="visits-top-bar">
  <div class="visits-top-bar__filter">
    <app-datepicker
      (dayChange)="handleDateSelection($event)"
      [today]="fromDate"
    ></app-datepicker>
    <ng-select
      class="ml-3"
      id="locations"
      [items]="locations"
      bindLabel="name"
      placeholder="{{ 'Select location to filter' | translate }}"
      [loading]="isLoadingLocations"
      [(ngModel)]="selectedLocation"
      (ngModelChange)="handleLocationSelection()"
      (clearEvent)="handleLocationSelection()"
    >
    </ng-select>
  </div>
  <div class="d-flex align-items-end justify-content-end">
    <button class="btn" (click)="goToCreate()">
      <i class="far fa-fw fa-plus"></i>
      {{ 'Create prenotification' | translate }}
    </button>
  </div>
</div>
<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [rows]="visits"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="false"
  [count]="totalElements"
  [limit]="rowLimit"
  [loadingIndicator]="isLoadingVisits"
>
  <div>
    <ngx-datatable-column
      name="{{ 'Scheduled At' | translate }}"
      prop="scheduledAt"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ value | date: 'dd/MM/yyyy' }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'Tasks' | translate }}"
      prop=""
      [width]="400"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        <app-transport-tasks-table
          [visitStatus]="value.status"
          [transportTasks]="value.transportTasks"
          [transportIsOwnedByCurrentTenant]="true"
        ></app-transport-tasks-table>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Driver' | translate }}" prop="driver">
      <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
        <span *ngIf="row.status !== 'completed'"
          >{{ value.user?.firstName }} {{ value.user?.lastName }}
          {{ value.licensePlate }}</span
        >
        <span *ngIf="row.status === 'completed'"
          >{{ row.driverName }} {{ row.driverLicensePlate }}</span
        >
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [cellClass]="'datatable-actions'"
      name="{{ 'Action' | translate }}"
      prop=""
      [minWidth]="120"
      [width]="120"
      [maxWidth]="200"
    >
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <!-- Switch between dropdown and dropup depending on the row index. -->
        <div
          ngbDropdown
          [placement]="getDropdownPlacement(rowIndex)"
          [container]="'body'"
          class="d-inline-block"
        >
          <button
            class="btn btn-outline-info"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <span
              translate
              class="btn__label"
              [ngClass]="{ invisible: isLoading && actionRow === rowIndex }"
              >Select Action</span
            >
            <i
              class="fa fa-spinner fa-spin btn__loader"
              [ngClass]="{ visible: isLoading && actionRow === rowIndex }"
            ></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
              class="dropdown-item"
              (click)="checkTaskReferences(row, rowIndex)"
            >
              <i class="far fa-question-circle"></i>
              <span>{{ 'Check references' | translate }}</span>
            </button>
            <a
              class="dropdown-item"
              [routerLink]="['/tms', 'prenotifications', row.id, 'edit']"
            >
              <i class="far fa-pencil"></i>
              <span>{{ 'Edit' | translate }}</span>
            </a>
            <button class="dropdown-item" (click)="deleteVisit(row, rowIndex)">
              <i class="far fa-trash-alt"></i>
              <span>{{ 'Delete' | translate }}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
