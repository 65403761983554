var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import * as types from '../types/notification.types';
import * as NotificationActions from '../actions/notifications.actions';
import { map, delay } from 'rxjs/operators';
var NotificationEffects = /** @class */ (function () {
    function NotificationEffects(actions$) {
        this.actions$ = actions$;
        // Automatically dismiss a notification after x milliseconds
        this.notificationAutoDismiss$ = this.actions$.pipe(ofType(types.notifications.ADD_NOTIFICATION), delay(4000), map(function (AddNotificationAction) {
            return new NotificationActions.DismissNotification(AddNotificationAction.payload);
        }));
        // Takes in a an Error Notification action and adds a new one.
        this.ErrorNotification$ = this.actions$.pipe(ofType(types.notifications.ERROR_NOTIFICATION), map(function (ErrorAction) {
            var newNotification = {
                message: ErrorAction.payload.message,
                type: 'alert-danger'
            };
            return new NotificationActions.AddNotification(newNotification);
        }));
        // Takes in a an Success Notification action and adds a new one.
        this.SuccessNotification$ = this.actions$.pipe(ofType(types.notifications.SUCCESS_NOTIFICATION), map(function (SuccessAction) {
            var newNotification = {
                message: SuccessAction.payload.message,
                type: 'alert-success'
            };
            return new NotificationActions.AddNotification(newNotification);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], NotificationEffects.prototype, "notificationAutoDismiss$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], NotificationEffects.prototype, "ErrorNotification$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], NotificationEffects.prototype, "SuccessNotification$", void 0);
    return NotificationEffects;
}());
export { NotificationEffects };
