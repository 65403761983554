<div class="search-container mb-3 ml-3 mr-3">
  <app-search
    [placeholder]="searchPlaceholder"
    (search)="handleSearch($event)"
  ></app-search>
  <ng-select
    id="statusFilter"
    [items]="requestStatuses"
    bindLabel="label"
    bindValue="value"
    [(ngModel)]="statusFilter"
    (change)="handleStatusFilterChange()"
    data-cy="statusFilter"
    placeholder="{{ 'Filter by status' | translate }}"
  >
  </ng-select>
  <button class="btn"
    *ngIf="!forLiner"
    id="new-reload-request-button"
    routerLink="/reload/new-reload-request"
  >
    <i class="far fa-plus fa-fw"></i>
    <span>{{ 'New Reload Request' | translate }}</span>
  </button>
</div>
<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [rows]="reloadRequests"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  (page)="setPage($event)"
  [count]="totalElements"
  [limit]="30"
  [messages]="tableMessages"
  [loadingIndicator]="isLoadingReloadRequests"
>
  <div>
    <ngx-datatable-column name="{{ 'Request Date' | translate }}" prop="creationDate">
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ value | date: 'dd-MM-yyyy HH:mm:ss' }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Liner' | translate }}" prop="liner.shortName" [width]="40">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Container number' | translate }}" prop="containerNr">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Export Reference' | translate }}" prop="exportReference">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Reload Date' | translate }}" prop="reloadDate">
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ value | date: 'dd-MM-yyyy' }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Requestor' | translate }}" prop="tenant.name">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Requestor e-mail' | translate }}" prop="">
      <ng-template ngx-datatable-cell-template let-row="row">
        <span *ngIf="row.userContext && row.userContext.user && row.userContext.user.email">{{ row.userContext.user.email }}</span>
        <span *ngIf="!row.userContext || !row.userContext.user || !row.userContext.user.email">{{ row.tenant.email }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [cellClass]="getStatusClass" name="{{ 'Status' | translate }}" prop="requestStatus">
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ formatRequestStatus(value) }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [maxWidth]="1"
      [width]="1"
      [resizeable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [sortable]="false"
      [cellClass]="'column-9-bugfix'"
      [headerClass]="'column-9-bugfix'"
    >
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Details' | translate }}" prop="" [cellClass]="'column-9-bugfix-next'" [headerClass]="'column-9-bugfix-next'">
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div
          ngbDropdown
          *ngIf="row.liner.code !== 'HYU' && row.liner.code !== 'EVE'"
          placement="getDropdownPlacement(rowIndex)"
          class="d-inline-block"
        >
          <button class="btn btn-outline-dark" id="reload-request-actions" ngbDropdownToggle data-cy="actions-dropdown">
            <span translate class="btn__label" [ngClass]="{ invisible: isLoadingAction && actionRow === rowIndex }">Select Action</span>
            <i class="fa fa-spinner fa-spin btn__loader" [ngClass]="{ visible: isLoadingAction && actionRow === rowIndex }"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="reload-request-actions">
            <button class="dropdown-item" (click)="goToReloadRequestDetail(row.id)" data-cy="details-action">
              <i class="far fa-pencil"></i>
              <span>&nbsp; {{ 'Details' | translate }}</span>
            </button>
            <button
              class="dropdown-item"
              *ngIf="row.liner.code === 'MSC' && forLiner"
              [disabled]="isForceApproveButtonDisabled(row)"
              (click)="forceApprove(row.id, rowIndex)"
              data-cy="force-approve-action"
            >
              <i class="far fa-check"></i>
              <span>&nbsp; {{ 'Force approval' | translate }}</span>
            </button>
            <button
              class="dropdown-item"
              *ngIf="row.liner.code === 'MSC'"
              [disabled]="isCancelButtonDisabled(row)"
              (click)="cancel(row.id, rowIndex)"
              data-cy="cancel-action"
            >
              <i class="far fa-trash-alt"></i>
              <span>&nbsp; {{ 'Cancel' | translate }}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
