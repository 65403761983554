<div class="container-fluid main-container-fluid pl-0 pr-0">
  <ng-sidebar-container style="height: 100vh;">
    <!-- A sidebar -->
    <ng-sidebar
      #sidebar
      [closeOnClickOutside]="true"
      [dock]="true"
      [dockedSize]="'50px'"
      [(opened)]="opened"
    >
      <div class="d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between">
          <a routerLink="/dashboard" *ngIf="opened">
            <img
              src="/assets/img/logo.png"
              alt="Hakka"
              class="sidebar-logo"
            />
          </a>
          <button (click)="toggleSidebar()" class="navbar-toggler m-1">
            <i
              class="far"
              [ngClass]="{ 'fa-times': opened, 'fa-chevron-right': !opened }"
            ></i>
          </button>
        </div>
        <app-navigation style="overflow-y:auto" [opened]="opened"></app-navigation>
        <div class="mt-auto">
          <app-cmp-avatar
            *ngIf="(showNavigation() | async)"
            [opened]="opened"
            [size]="avatar - xs"
            [dropdown]="true"
          ></app-cmp-avatar>
        </div>
      </div>
    </ng-sidebar>
    <!-- Page Content -->
    <div ng-sidebar-content class="main-content" style="height: 100vh;">
      <app-navbar-top></app-navbar-top>
      <router-outlet></router-outlet>
    </div>
  </ng-sidebar-container>
</div>
