import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as DateFns from 'date-fns';
import { TransportTask } from 'app/core/store/models/transport-task.model';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateNativeAdapter,
  NgbDateCustomParserFormatter
} from '../../shared/datepicker-config';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LocationService } from 'app/services/location.service';

@Component({
  selector: 'app-transport-task',
  templateUrl: './transport-task.component.html',
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class TransportTaskComponent implements OnInit, OnChanges {
  @Input()
  taskType: string;
  @Input()
  taskIcon: string;
  @Input()
  transportTask: TransportTask;

  @Output()
  transportTaskChanged = new EventEmitter<any>();

  transportTaskForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.createForm();
    this.transportTaskForm.valueChanges.subscribe(transporTaskValue => {
      this.transportTaskChanged.emit(transporTaskValue);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.transportTaskForm) {
      this.createForm();
    }

    if (changes && changes.transportTask && changes.transportTask.firstChange) {
      const transportTask = changes.transportTask.currentValue as TransportTask;
      this.transportTaskForm.patchValue({
        location: transportTask.location,
        startDate: transportTask.startDate
          ? new Date(
            DateFns.getYear(transportTask.startDate),
            DateFns.getMonth(transportTask.startDate),
            DateFns.getDate(transportTask.startDate)
          )
          : null,
        startTime: transportTask.startDateTimeSpecified
          ? {
            hour: DateFns.getHours(transportTask.startDate),
            minute: DateFns.getMinutes(transportTask.startDate)
          }
          : null,
        endDate: transportTask.endDate
          ? new Date(
            DateFns.getYear(transportTask.endDate),
            DateFns.getMonth(transportTask.endDate),
            DateFns.getDate(transportTask.endDate)
          )
          : null,
        endTime: transportTask.endDateTimeSpecified
          ? {
            hour: DateFns.getHours(transportTask.endDate),
            minute: DateFns.getMinutes(transportTask.endDate)
          }
          : null,
        startDateTimeSpecified: transportTask.startDateTimeSpecified,
        endDateTimeSpecified: transportTask.endDateTimeSpecified
      });
      this.transportTaskChanged.emit(this.transportTaskForm.value);
    }
  }

  createForm() {
    // Only create the form once, this prevents the form resetting accidentally
    if (this.transportTaskForm) {
      return;
    }
    this.transportTaskForm = this.fb.group({
      location: [null],
      startDate: [null],
      startTime: [null],
      endDate: [null],
      endTime: [null],
      startDateTimeSpecified: [false],
      endDateTimeSpecified: [false]
    });
  }

  /**
   *
   * @param date date object given by the datepicker
   * @param current the current year and month
   * @returns boolean if given date is before today
   */
  getDisabledDates(date: NgbDateStruct): boolean {
    const today = new Date();
    let d = new Date(date.year, date.month, date.day);
    d = DateFns.subMonths(d, 1);
    d = DateFns.addDays(d, 1);
    return DateFns.isBefore(d, today);
  }

  locationFormatter = (x: { name: String }) => {
    return x.name;
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.location(term);
      })
    )
}
