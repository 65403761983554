<div class="m-3">
  <ngb-alert class="mb-3" *ngIf="alert" [type]="alert.type" (close)="alert = null">
    <i class="far" [ngClass]="alert.icon"></i>
    <span>{{ alert.message['statusText'] ? alert.message['statusText'] : alert.message | json }}</span>
  </ngb-alert>
  <div class="card">
    <div class="card-header">
      <h3>{{ 'Edit Location' | translate }}</h3>
    </div>
    <div class="card-body">
      <app-location-form [location]="location" (locationFormSubmitted)="updateLocation($event)"></app-location-form>
    </div>
  </div>
</div>
