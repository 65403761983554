<div class="card p-3 mb-3 match-candidate">
  <div class="d-flex">
    <span style="width: 100%">
      <app-transport-matching-pool-item
        *ngIf="matchingPoolItem.transport"
        [transport]="matchingPoolItem.transport"
      >
        <app-button
          (click)="handleRemoveMatchItem()"
          [isLoading]="disabledMatch && disabledMatch === id"
          [btnClass]="'btn-sm btn-danger align-self-start'"
          [type]="'button'"
        >
          {{ 'Disable matching' | translate }}
        </app-button>
      </app-transport-matching-pool-item>
      <app-market-post-offer-matching-pool-item
        *ngIf="matchingPoolItem.marketPostOffer"
        [offer]="matchingPoolItem.marketPostOffer?.transportOrigin"
      >
        <app-button
          (click)="handleRemoveMatchItem()"
          [isLoading]="disabledMatch && disabledMatch === id"
          [btnClass]="'btn-sm btn-danger align-self-start'"
          [type]="'button'"
        >
          {{ 'Disable matching' | translate }}
        </app-button>
      </app-market-post-offer-matching-pool-item>
      <app-market-post-demand-matching-pool-item
        *ngIf="matchingPoolItem.marketPostDemand"
        [demand]="matchingPoolItem.marketPostDemand"
      >
        <app-button
          (click)="handleRemoveMatchItem()"
          [isLoading]="disabledMatch && disabledMatch === id"
          [btnClass]="'btn-sm btn-danger align-self-start'"
          [type]="'button'"
        >
          {{ 'Disable matching' | translate }}
        </app-button>
      </app-market-post-demand-matching-pool-item>
    </span>
  </div>
  <div
    class="hr-sect"
    (click)="matchingPoolItem.showMatches = !matchingPoolItem.showMatches"
  >
    <span *ngIf="matchingPoolItem.showMatches; else showMatches">{{
      'Hide Matches' | translate
    }}</span>
    <ng-template #showMatches>
      <span>{{ 'Show Matches' | translate }}</span>
    </ng-template>
  </div>
  <div *ngIf="matchingPoolItem.showMatches">
    <ngb-alert
      [dismissible]="false"
      class="text-center"
      *ngIf="
        !matchingPoolItem.matchOptions ||
        matchingPoolItem.matchOptions.length === 0
      "
      [type]="'info'"
      >{{ 'No matches found for this transport' | translate }}</ngb-alert
    >
    <div
      *ngFor="let option of matchingPoolItem.matchOptions; let counter = index"
    >
      <div
        *ngIf="
          (option.visible && counter < MATCHLIMIT) || matchingPoolItem.showAll
        "
      >
        <app-matching-pool-option
          [option]="option"
          [isLoading]="isLoading"
          (removeOption)="handleRemoveMatchOption($event)"
          (submitReply)="handleSubmitReply($event)"
        ></app-matching-pool-option>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !(matchingPoolItem.matchOptions.length < MATCHLIMIT) &&
      !matchingPoolItem.showAll &&
      matchingPoolItem.matchOptions.length !== MATCHLIMIT
    "
    class="hr-sect"
    (click)="matchingPoolItem.showAll = true"
  >
    {{ 'Load all (' | translate
    }}{{ matchingPoolItem.matchOptions.length - MATCHLIMIT }}
    {{ 'more)' | translate }}
  </div>
</div>
