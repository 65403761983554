import { Action } from '@ngrx/store';
import * as types from '../types/country.types';

export class LoadCountries implements Action {
  readonly type = types.country.LOAD_COUNTRIES;
  constructor(public payload?: any) { }
}

export class LoadCountriesFail implements Action {
  readonly type = types.country.LOAD_COUNTRIES_FAILED;
  constructor(public payload: any) { }
}

export class LoadCountriesSuccess implements Action {
  readonly type = types.country.LOAD_COUNTRIES_SUCCEEDED;
  constructor(public payload: any) { }
}

export type All = LoadCountries | LoadCountriesFail | LoadCountriesSuccess;
