import { Location } from 'app/core/store/models/location.model';
import { ToastService } from 'app/services/toast.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { CreateVisit } from 'app/core/store/actions/visits.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/tms.types';
import * as LocationTypes from 'app/core/store/types/location.types';
import { Router } from '@angular/router';
import { extract } from 'app/services/i18n.service';
import { LoadPrenotifiableLocations } from 'app/core/store/actions/location.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-visit',
  templateUrl: './new-visit.component.html',
})
export class NewVisitComponent implements OnInit, OnDestroy {
  @Input()
  task;
  @Output()
  visitCreated = new EventEmitter<boolean>();

  title = extract('Add new prenotification');
  isLoading = false;
  isLoadingLocations: boolean;
  locations: any[];

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router
  ) { }

  ngOnInit() {
    this.updates$
      .pipe(ofType(Types.visits.CREATE_VISIT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Prenotification created!')
        });
        this.isLoading = false;
        this.visitCreated.emit(true);
        this.router.navigateByUrl('/tms/prenotifications');
      });
    this.updates$
      .pipe(ofType(Types.visits.CREATE_VISIT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to create prenotification')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(LocationTypes.location.LOAD_PRENOTIFIABLE_LOCATIONS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to load prenotification locations')
        });
        this.isLoadingLocations = false;
      });
    this.store
      .select(state => state.tms.locations)
      .pipe(untilDestroyed(this))
      .subscribe(locationsState => {
        if (locationsState && locationsState.prenotifiableLocations) {
          this.locations = locationsState.prenotifiableLocations.map(
            (l: Location) => {
              return {
                label: l.name,
                value: l.id
              };
            }
          );
          this.isLoadingLocations = false;
          return;
        }
        this.store.dispatch(new LoadPrenotifiableLocations());
        this.isLoadingLocations = true;
      });
  }

  createVisit(visitFormValue) {
    const body = Object.assign({}, visitFormValue);
    this.store.dispatch(
      new CreateVisit({
        body: body
      })
    );
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
