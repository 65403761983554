import { createAction, props } from '@ngrx/store';

import { Preannouncement } from '../models/preannouncement.model';


export const loadPreannouncements = createAction(
  '[Preannouncements] Load All'
);

export const loadPreannouncementsSuccess = createAction(
  '[Preannouncements] Load All Success',
  props<{ payload: Preannouncement[]; }>()
);

export const loadPreannouncementsFail = createAction(
  '[Preannouncements] Load All Fail',
  props<object>()
);


export const createPreannouncement = createAction(
  '[Preannouncements] Create',
  props<{ payload: Preannouncement; }>()
);

export const createPreannouncementSuccess = createAction(
  '[Preannouncements] Create Success',
  props<{ payload: Preannouncement; }>()
);

export const createPreannouncementFail = createAction(
  '[Preannouncements] Create Fail',
  props<object>()
);


export const updatePreannouncement = createAction(
  '[Preannouncements] Update',
  props<{ id: string; data: Partial<Preannouncement>; }>()
);

export const updatePreannouncementSuccess = createAction(
  '[Preannouncements] Update Success',
  props<{ payload: Preannouncement; }>()
);

export const updatePreannouncementFail = createAction(
  '[Preannouncements] Update Fail',
  props<object>()
);


export const deletePreannouncement = createAction(
  '[Preannouncements] Delete',
  props<{ id: string; }>()
);

export const deletePreannouncementSuccess = createAction(
  '[Preannouncements] Delete Success',
  props<{ id: string; }>()
);

export const deletePreannouncementFail = createAction(
  '[Preannouncements] Delete Fail',
  props<object>()
);
