import { location } from '../types/location.types';
import { Action } from '@ngrx/store';

export class LoadLocations implements Action {
  readonly type = location.LOAD_LOCATIONS;
  constructor(public payload?: any) {}
}

export class LoadLocationsSuccess implements Action {
  readonly type = location.LOAD_LOCATIONS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadLocationsFail implements Action {
  readonly type = location.LOAD_LOCATIONS_FAILED;
  constructor(public payload?: any) {}
}

export class LoadUncheckedLocations implements Action {
  readonly type = location.LOAD_UNCHECKED_LOCATIONS;
  constructor(public payload?: any) {}
}

export class LoadUncheckedLocationsSuccess implements Action {
  readonly type = location.LOAD_UNCHECKED_LOCATIONS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadUncheckedLocationsFail implements Action {
  readonly type = location.LOAD_UNCHECKED_LOCATIONS_FAILED;
  constructor(public payload?: any) {}
}

export class LoadAddressBookLocations implements Action {
  readonly type = location.LOAD_ADDRESS_BOOK_LOCATIONS;
  constructor(public payload?: any) {}
}

export class LoadAddressBookLocationsSuccess implements Action {
  readonly type = location.LOAD_ADDRESS_BOOK_LOCATIONS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadAddressBookLocationsFail implements Action {
  readonly type = location.LOAD_ADDRESS_BOOK_LOCATIONS_FAILED;
  constructor(public payload?: any) {}
}

export class LoadPrenotifiableLocations implements Action {
  readonly type = location.LOAD_PRENOTIFIABLE_LOCATIONS;
  constructor(public payload?: any) {}
}

export class LoadPrenotifiableLocationsSuccess implements Action {
  readonly type = location.LOAD_PRENOTIFIABLE_LOCATIONS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadPrenotifiableLocationsFail implements Action {
  readonly type = location.LOAD_PRENOTIFIABLE_LOCATIONS_FAILED;
  constructor(public payload?: any) {}
}

export class LoadLocationTypes implements Action {
  readonly type = location.LOAD_LOCATION_TYPES;
  constructor(public payload?: any) {}
}

export class LoadLocationTypesSuccess implements Action {
  readonly type = location.LOAD_LOCATION_TYPES_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadLocationTypesFail implements Action {
  readonly type = location.LOAD_LOCATION_TYPES_FAILED;
  constructor(public payload?: any) {}
}

export class LoadLocationsByType implements Action {
  readonly type = location.LOAD_LOCATIONS_BY_LOCATION_TYPE;
  constructor(public payload?: any) {}
}

export class LoadLocationsByTypeSuccess implements Action {
  readonly type = location.LOAD_LOCATIONS_BY_LOCATION_TYPE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadLocationsByTypeFail implements Action {
  readonly type = location.LOAD_LOCATIONS_BY_LOCATION_TYPE_FAILED;
  constructor(public payload?: any) {}
}

export class CreateUserLocation implements Action {
  readonly type = location.CREATE_USER_LOCATION;
  constructor(public payload?: any) {}
}

export class CreateUserLocationSuccess implements Action {
  readonly type = location.CREATE_USER_LOCATION_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class CreateUserLocationFail implements Action {
  readonly type = location.CREATE_USER_LOCATION_FAILED;
  constructor(public payload?: any) {}
}

export class UpdateUserLocation implements Action {
  readonly type = location.UPDATE_USER_LOCATION;
  constructor(public payload?: any) {}
}

export class UpdateUserLocationSuccess implements Action {
  readonly type = location.UPDATE_USER_LOCATION_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class UpdateUserLocationFail implements Action {
  readonly type = location.UPDATE_USER_LOCATION_FAILED;
  constructor(public payload?: any) {}
}

export class DeleteUserLocation implements Action {
  readonly type = location.DELETE_USER_LOCATION;
  constructor(
    public payload: {
      locationId: string;
    }
  ) {}
}

export class DeleteUserLocationSuccess implements Action {
  readonly type = location.DELETE_USER_LOCATION_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class DeleteUserLocationFail implements Action {
  readonly type = location.DELETE_USER_LOCATION_FAILED;
  constructor(public payload?: any) {}
}

export class DeleteLocation implements Action {
  readonly type = location.DELETE_LOCATION;
  constructor(
    public payload: {
      locationId: string;
    }
  ) {}
}

export class DeleteLocationSuccess implements Action {
  readonly type = location.DELETE_LOCATION_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class DeleteLocationFail implements Action {
  readonly type = location.DELETE_LOCATION_FAILED;
  constructor(public payload?: any) {}
}
export class ClearAddressBookLocations implements Action {
  readonly type = location.CLEAR_ADDRESS_BOOK_LOCATIONS;
  constructor(public payload?: any) {}
}

export type All =
  | LoadLocations
  | LoadLocationsSuccess
  | LoadLocationsFail
  | LoadUncheckedLocations
  | LoadUncheckedLocationsSuccess
  | LoadUncheckedLocationsFail
  | LoadLocationTypes
  | LoadLocationTypesSuccess
  | LoadLocationTypesFail
  | LoadAddressBookLocations
  | LoadAddressBookLocationsSuccess
  | LoadAddressBookLocationsFail
  | LoadPrenotifiableLocations
  | LoadPrenotifiableLocationsSuccess
  | LoadPrenotifiableLocationsFail
  | LoadLocationsByType
  | LoadLocationsByTypeSuccess
  | LoadLocationsByTypeFail
  | CreateUserLocation
  | CreateUserLocationSuccess
  | CreateUserLocationFail
  | UpdateUserLocation
  | UpdateUserLocationSuccess
  | UpdateUserLocationFail
  | DeleteUserLocation
  | DeleteUserLocationSuccess
  | DeleteUserLocationFail
  | DeleteLocation
  | DeleteLocationSuccess
  | DeleteLocationFail
  | ClearAddressBookLocations;
