import { Tenant } from 'app/core/store/models/tenant.model';
import { MarketPostDemand } from 'app/core/store/models/matching.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-market-post-demand-matching-pool-item',
  templateUrl: './market-post-demand-matching-pool-item.component.html',
})
export class MarketPostDemandMatchingPoolItemComponent {
  @Input()
  demand: MarketPostDemand;
  @Input()
  isMatchingPoolOption: boolean;
  @Input()
  tenant: Tenant;
}
