import { TransportTask } from 'app/core/store/models/transport-task.model';
import { ToastService } from 'app/services/toast.service';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Visit, VisitStatuses } from 'app/core/store/models/visits.model';
import * as Types from 'app/core/store/types/transport.types';
import { Actions, ofType } from '@ngrx/effects';
import { LoadDriverTasks } from 'app/core/store/actions/driver-task.actions';
import {
  TransitionTransportTaskForward,
  TransitionTransportTaskBack
} from 'app/core/store/actions/transports.actions';
import { UpdateTransportTask } from 'app/core/store/actions/transport-task.actions';
import { LoadUser } from 'app/core/store/actions/user.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-driver-visits',
  templateUrl: './driver-visits.component.html',
  styleUrls: ['./driver-visits.component.scss']
})
export class DriverVisitsComponent implements OnInit, OnDestroy {
  objectKeys = Object.keys;
  schedule;
  visits: Visit[];
  newVisits: Visit[];
  inProgressVisits: Visit[];
  completedVisits: Visit[];

  isLoadingVisits: boolean;
  isGoingForward: boolean;
  isGoingBackward: boolean;
  userId: string;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
  ) {
    this.store
      .select(state => state.user)
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        if (user && user.id) {
          this.userId = user.id;
          this.store
            .select(state => state.tms.driverTasks)
            .subscribe(tasks => {
              if (tasks) {
                this.isLoadingVisits = false;
                this.schedule = Object.assign({}, tasks);
              }
            });
          this.isLoadingVisits = true;
        }
      });
    this.updates$
      .pipe(ofType(
        Types.transports.TRANSITION_TRANSPORT_TASK_BACK_SUCCEEDED,
        Types.transports.TRANSITION_TRANSPORT_TASK_FORWARD_SUCCEEDED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.toastr.showSuccess({
          message: 'Success!'
        });
        this.isGoingBackward = false;
        this.isGoingForward = false;
        this.store.dispatch(new LoadDriverTasks());
        if (data['payload']['status'] === VisitStatuses.COMPLETED) {
          const body = Object.assign({}, data['payload']) as TransportTask;
          if (!navigator.geolocation) {
            return;
          }
          const that = this;
          navigator.geolocation.getCurrentPosition(function(position) {
            body['completedInLatitude'] = position.coords.latitude.toString();
            body['completedInLongitude'] = position.coords.longitude.toString();
            that.store.dispatch(
              new UpdateTransportTask({
                transportTaskId: body['id'],
                body: body
              })
            );
          });
        }
      });
    this.updates$
      .pipe(ofType(
        Types.transports.TRANSITION_TRANSPORT_TASK_BACK_FAILED,
        Types.transports.TRANSITION_TRANSPORT_TASK_FORWARD_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.isGoingBackward = false;
        this.isGoingForward = false;
        this.store.dispatch(new LoadDriverTasks());
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadDriverTasks());
    this.store.dispatch(new LoadUser());
  }

  transitionForward(taskId: string) {
    this.store.dispatch(
      new TransitionTransportTaskForward({ transportTaskId: taskId })
    );
  }

  transitionBack(taskId: string) {
    this.store.dispatch(
      new TransitionTransportTaskBack({ transportTaskId: taskId })
    );
  }

  ngOnDestroy() { }
}
