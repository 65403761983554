import * as types from '../types/matching.types';
import * as MatchingActions from '../actions/matching.actions';
import { ActionReducerMap } from '@ngrx/store';

const INITIAL_STATE = {
  activeMatchingPoolItems: null,
  next: null
};

export function MatchingReducer(
  state = INITIAL_STATE,
  action: MatchingActions.All
): MatchingState {
  switch (action.type) {
    case types.matching.LOAD_ACTIVE_MATCHING_POOL_ITEMS_SUCCEEDED:
      if (!action.payload['hydra:member']) {
        state.activeMatchingPoolItems = null;
        return Object.assign({}, state);
      }
      state.activeMatchingPoolItems = [...action.payload['hydra:member']];
      if (
        action.payload['hydra:view'] &&
        action.payload['hydra:view']['hydra:next']
      ) {
        let next = action.payload['hydra:view']['hydra:next'];
        next = next.slice(next.indexOf('=') + 1);
        state.next = next;
      } else if (state.next) {
        state.next = null;
      }
      return Object.assign({}, state);
    case types.matching.LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE_SUCCEEDED:
      state.activeMatchingPoolItems = [
        ...state.activeMatchingPoolItems,
        ...action.payload['hydra:member']
      ];
      if (
        action.payload['hydra:view'] &&
        action.payload['hydra:view']['hydra:next']
      ) {
        let next = action.payload['hydra:view']['hydra:next'];
        next = next.slice(next.indexOf('=') + 1);
        state.next = next;
      } else if (state.next) {
        state.next = null;
      }
      return Object.assign({}, state);
    case types.matching.DISABLE_MATCHING_SUCCEEDED:
      if (!state.activeMatchingPoolItems) {
        return state;
      }
      const matchingPoolItemId = action.payload.matchingPoolItem.id;
      state.activeMatchingPoolItems = state.activeMatchingPoolItems.filter(
        matchingPoolItem => matchingPoolItem.id !== matchingPoolItemId
      );
      return Object.assign({}, state);
    case types.matching.DELETE_MATCHING_POOL_OPTION_SUCCEEDED:
      if (!state.activeMatchingPoolItems) {
        return;
      }

      const item = state.activeMatchingPoolItems.find(
        mpi => mpi.id === action.payload.matchId
      );
      if (item) {
        item.matchOptions = item.matchOptions.filter(
          option => option.id !== action.payload.matchOptionId
        );
      }
      return Object.assign({}, state);
    case types.matching.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
  }
}

export interface MatchingState {
  activeMatchingPoolItems: any[];
  next?: string;
}

export const MatchingReducers: ActionReducerMap<{
  matching: MatchingState;
}> = {
  matching: MatchingReducer
};
