import { CustomerFormComponent } from './customer-form/customer-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransportInfoComponent } from './transport-info/transport-info.component';
import { TransportTasksTableComponent } from '../tms/transports/transport-tasks-table/transport-tasks-table.component';
import { SettingsCardComponent } from '../core/admin/settings-card/settings-card.component';
import { LinerEffects } from '../core/store/effects/liner.effects';
import { TransportEffects } from '../core/store/effects/transports.effects';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { UserContextFormComponent } from '../core/admin/tenant-settings/user-contexts/user-context-form/user-context-form.component';
import { MessageBoardMessagesSubscriptionToggleComponent } from '../core/admin/tenant-settings/notification-subscriptions/message-board-messages-subscription/message-board-messages-subscription-toggle/message-board-messages-subscription-toggle.component';
import { MarketPostDemandComponent } from '../marketplace/market-posts/market-post-demands/market-post-demand.component';
import { MarketPostOfferComponent } from '../marketplace/market-posts/market-post-offers/market-post-offer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from './loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { UserInfoFormComponent } from '../core/user/user-info-form/user-info-form.component';
import { SearchService } from '../services/search.service';
import { CreditAmountComponent } from '../core/admin/tenant-settings/credits/credit-amount/credit-amount.component';
import { ReloadCreditsComponent } from '../core/admin/tenant-settings/credits/reload-credits/reload-credits.component';
import { SubscriptionComponent } from '../core/admin/tenant-settings/subscriptions/subscription/subscription.component';
import { NavbarComponent } from '../core/navbar/navbar.component';
import { MarketPostTableComponent } from '../marketplace/market-posts/market-post-table/market-post-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TransportTasksComponent } from './transport-tasks/transport-tasks.component';
import { NgToggleModule } from '@nth-cloud/ng-toggle';
import { NewUserContextComponent } from '../core/admin/tenant-settings/user-contexts/new-user-context/new-user-context.component';
import { EditUserContextComponent } from '../core/admin/tenant-settings/user-contexts/edit-user-context/edit-user-context.component';
import { TenantUsersListComponent } from '../core/admin/tenant-settings/tenant-users-list/tenant-users-list.component';
import { UserContextsTableComponent } from '../core/admin/tenant-settings/user-contexts-table/user-contexts-table.component';
import { EffectsModule } from '@ngrx/effects';
import { DaterangepickerComponent } from './daterangepicker/daterangepicker.component';
import { DaterangepickerContainerComponent } from './daterangepicker-container/daterangepicker-container.component';
import { ButtonComponent } from './button/button.component';
import { LocationComponent } from './location/location.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { CardSpinnerComponent } from './card-spinner/card-spinner.component';
import { SearchComponent } from './search/search.component';
import { RibbonComponent } from './ribbon/ribbon.component';
import { ConfirmActionModalComponent } from './confirm-action-modal/confirm-action-modal.component';
import { DemandLocationsFormComponent } from './demand-locations-form/demand-locations-form.component';
import { TransportTaskComponent } from './transport-task/transport-task.component';

@NgModule({
  imports: [
    NgSelectModule,
    NgxDatatableModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    MomentModule,
    NgToggleModule,
    NgbModule,
    TranslateModule.forRoot(),
    EffectsModule.forFeature([TransportEffects, LinerEffects])
  ],
  declarations: [
    NavbarComponent,
    LoadingComponent,
    UserInfoFormComponent,
    CreditAmountComponent,
    NewUserContextComponent,
    EditUserContextComponent,
    UserContextFormComponent,
    ReloadCreditsComponent,
    SubscriptionComponent,
    MarketPostTableComponent,
    MarketPostOfferComponent,
    MarketPostDemandComponent,
    TenantUsersListComponent,
    UserContextsTableComponent,
    TransportTasksComponent,
    MessageBoardMessagesSubscriptionToggleComponent,
    DaterangepickerComponent,
    SettingsCardComponent,
    TransportTasksTableComponent,
    DaterangepickerContainerComponent,
    ButtonComponent,
    LocationComponent,
    UserInfoComponent,
    CustomerFormComponent,
    TransportInfoComponent,
    DatepickerComponent,
    CardSpinnerComponent,
    SearchComponent,
    RibbonComponent,
    ConfirmActionModalComponent,
    DemandLocationsFormComponent,
    TransportTaskComponent
  ],
  exports: [
    SearchComponent,
    CommonModule,
    NgbModule,
    FormsModule,
    NgToggleModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
    MomentModule,
    NgxDatatableModule,
    LoadingComponent,
    NewUserContextComponent,
    UserContextFormComponent,
    UserContextsTableComponent,
    UserInfoFormComponent,
    InfiniteScrollModule,
    NavbarComponent,
    CreditAmountComponent,
    ReloadCreditsComponent,
    SubscriptionComponent,
    MarketPostTableComponent,
    EditUserContextComponent,
    TenantUsersListComponent,
    MarketPostOfferComponent,
    MarketPostDemandComponent,
    TransportTasksComponent,
    MessageBoardMessagesSubscriptionToggleComponent,
    DaterangepickerComponent,
    DaterangepickerContainerComponent,
    TransportTasksTableComponent,
    SettingsCardComponent,
    ButtonComponent,
    LocationComponent,
    CustomerFormComponent,
    UserInfoComponent,
    TransportInfoComponent,
    DatepickerComponent,
    CardSpinnerComponent,
    RibbonComponent,
    ConfirmActionModalComponent,
    DemandLocationsFormComponent
  ],
  providers: [SearchService]
})
export class SharedModule { }
