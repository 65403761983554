import { Tenant } from 'app/core/store/models/tenant.model';
import { Transport } from 'app/core/store/models/transport.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-market-post-offer-matching-pool-item',
  templateUrl: './market-post-offer-matching-pool-item.component.html',
})
export class MarketPostOfferMatchingPoolItemComponent {
  @Input()
  offer: Transport;
  @Input()
  isMatchingPoolOption: boolean;
  @Input()
  tenant: Tenant;
}
