import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-registration-request-modal',
  templateUrl: './approve-registration-request-modal.component.html'
})
export class ApproveRegistrationRequestModalComponent {
  constructor(public activeModal: NgbActiveModal) { }
}
