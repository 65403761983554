import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { ManageTenantsComponent } from './manage-tenants/manage-tenants.component';
import { ManageRegistrationRequestsComponent } from './manage-registration-requests/manage-registration-requests.component';
import { EditRegistrationRequestComponent } from './edit-registration-request/edit-registration-request.component';
import { RegistrationRequestFormComponent } from './registration-request-form/registration-request-form.component';
import {
  ApproveRegistrationRequestModalComponent
} from './approve-registration-request-modal/approve-registration-request-modal.component';
import {
  DeclineRegistrationRequestModalComponent
} from './decline-registration-request-modal/decline-registration-request-modal.component';
import { RegistrationRequestEffects } from '../store/effects/registration-request.effects';
import { SharedModule } from '../../shared/shared.module';
import { NewTenantComponent } from './new-tenant/new-tenant.component';
import { SuperAdminComponent } from './super-admin.component';
import { TenantFormComponent } from './tenant-form/tenant-form.component';
import { EditTenantComponent } from './edit-tenant/edit-tenant.component';
import { ManageWalletsComponent } from './manage-wallets/manage-wallets.component';
import { ManageLocationsComponent } from './manage-locations/manage-locations.component';
import { LocationFormComponent } from './location-form/location-form.component';
import { NewLocationComponent } from './new-location/new-location.component';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { ContainerTypeFormComponent } from './container-type-form/container-type-form.component';
import { NewContainerTypeComponent } from './new-container-type/new-container-type.component';
import { ManageContainerTypesComponent } from './manage-container-types/manage-container-types.component';
import { EditContainerTypeComponent } from './edit-container-type/edit-container-type.component';
import { LinerFormComponent } from './liner-form/liner-form.component';
import { NewLinerComponent } from './new-liner/new-liner.component';
import { EditLinerComponent } from './edit-liner/edit-liner.component';
import { ManageLinersComponent } from './manage-liners/manage-liners.component';
import { ManageBetaUsersComponent } from './manage-beta-users/manage-beta-users.component';
import { ManageSubscriptionTypesComponent } from './manage-subscription-types/manage-subscription-types.component';
import { SubscriptionTypeFormComponent } from './subscription-type-form/subscription-type-form.component';
import { NewSubscriptionTypeComponent } from './new-subscription-type/new-subscription-type.component';
import { EditSubscriptionTypeComponent } from './edit-subscription-type/edit-subscription-type.component';
import { EditCreditThresholdComponent } from './manage-psa-accounts/edit-credit-threshold.component';
import { NewPsaAccountComponent } from './manage-psa-accounts/new-psa-account.component';
import { ManagePsaAccountsComponent } from './manage-psa-accounts/psa-accounts.component';
import { AccountActionHistoryComponent } from './manage-psa-accounts/account-action-history.component';
import { ManageShippingAgentsComponent } from './manage-shipping-agents/manage-shipping-agents.component';
import { NewShippingAgentComponent } from './new-shipping-agent/new-shipping-agent.component';
import { EditShippingAgentComponent } from './edit-shipping-agent/edit-shipping-agent.component';
import { ShippingAgentFormComponent } from './shipping-agent-form/shipping-agent-form.component';
import { ManageCertiweightMeasurementsComponent } from './certiweight/manage-measurements.component';
import { NewCertiweightMeasurementComponent } from './certiweight/new-measurement.component';
import { EditCertiweightMeasurementComponent } from './certiweight/edit-measurement.component';
import { ContainerMeasurementFormComponent } from './certiweight/measurement-form.component';

@NgModule({
  imports: [
    NgSelectModule,
    NgxDatatableModule,
    CommonModule,
    SharedModule,
    RouterModule,
    SuperAdminRoutingModule,
    EffectsModule,
    EffectsModule.forFeature([
      RegistrationRequestEffects
    ])
  ],
  declarations: [
    NewTenantComponent,
    EditTenantComponent,
    SuperAdminComponent,
    ManageTenantsComponent,
    ManageRegistrationRequestsComponent,
    EditRegistrationRequestComponent,
    RegistrationRequestFormComponent,
    ApproveRegistrationRequestModalComponent,
    DeclineRegistrationRequestModalComponent,
    TenantFormComponent,
    ManageWalletsComponent,
    ManageLocationsComponent,
    LocationFormComponent,
    NewLocationComponent,
    EditLocationComponent,
    ContainerTypeFormComponent,
    NewContainerTypeComponent,
    ManageContainerTypesComponent,
    EditContainerTypeComponent,
    LinerFormComponent,
    NewLinerComponent,
    EditLinerComponent,
    ManageLinersComponent,
    ManageBetaUsersComponent,
    ManageSubscriptionTypesComponent,
    SubscriptionTypeFormComponent,
    NewSubscriptionTypeComponent,
    EditSubscriptionTypeComponent,
    NewPsaAccountComponent,
    ManagePsaAccountsComponent,
    EditCreditThresholdComponent,
    AccountActionHistoryComponent,
    ManageShippingAgentsComponent,
    NewShippingAgentComponent,
    EditShippingAgentComponent,
    ShippingAgentFormComponent,
    ManageCertiweightMeasurementsComponent,
    EditCertiweightMeasurementComponent,
    NewCertiweightMeasurementComponent,
    ContainerMeasurementFormComponent,
  ],
  entryComponents: [
    ApproveRegistrationRequestModalComponent,
    DeclineRegistrationRequestModalComponent,
    EditCreditThresholdComponent,
    AccountActionHistoryComponent,
  ]
})
export class SuperAdminModule { }
