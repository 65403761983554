import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/user-context.types';
import * as UserContextsActions from '../actions/user-contexts.actions';
import * as NotificationsActions from '../actions/notifications.actions';

@Injectable()
export class UserContextsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadUserContexts$: Observable<Action> = this.actions$.pipe(
    ofType(types.userContexts.LOAD_USER_CONTEXTS),
    map((action: UserContextsActions.LoadUserContexts) => action.payload),
    concatMap(payload => this.api.get({
      path: `/user_contexts?user.superAdmin=${payload.includeSuperAdmins ||
        false}&roles.name[]=PLANNER&roles.name[]=ADMIN`
    }).pipe(
      map(data => new UserContextsActions.LoadUserContextsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  loadUserContext$: Observable<Action> = this.actions$.pipe(
    ofType(types.userContexts.LOAD_USER_CONTEXT),
    map((action: UserContextsActions.LoadUserContext) => action.payload),
    concatMap(payload => this.api.get({
      path: '/user_contexts/' + payload.id
    }).pipe(
      map(data => new UserContextsActions.LoadSingleUserContextSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  createUserContext$: Observable<Action> = this.actions$.pipe(
    ofType(types.userContexts.CREATE_USER_CONTEXT),
    map((action: UserContextsActions.LoadUserContexts) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user_contexts', body: payload
    }).pipe(
      map(data => new UserContextsActions.CreateUserContextSuccess(data)),
      catchError(error => of(new UserContextsActions.CreateUserContextFail(error)))
    )),
  );

  @Effect()
  updateUserContext$: Observable<Action> = this.actions$.pipe(
    ofType(types.userContexts.UPDATE_USER_CONTEXT),
    map((action: UserContextsActions.UpdateUserContext) => action.payload),
    concatMap(payload => this.api.put({
      path: '/user_contexts/' + payload.id, body: payload.body
    }).pipe(
      map(data => new UserContextsActions.UpdateUserContextSuccess(data)),
      catchError(error => of(new UserContextsActions.UpdateUserContextFail(error)))
    )),
  );

  @Effect()
  enableUserContext$: Observable<Action> = this.actions$.pipe(
    ofType(types.userContexts.ENABLE_USER_CONTEXT),
    map((action: UserContextsActions.EnableUserContext) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user_contexts/' + payload + '/enable', body: {}
    }).pipe(
      map(data => new UserContextsActions.UpdateUserContextSuccess(data)),
      catchError(error => of(new UserContextsActions.UpdateUserContextFail(error)))
    )),
  );

  @Effect()
  disableUserContext$: Observable<Action> = this.actions$.pipe(
    ofType(types.userContexts.DISABLE_USER_CONTEXT),
    map((action: UserContextsActions.DisableUserContext) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user_contexts/' + payload + '/disable', body: {}
    }).pipe(
      map(data => new UserContextsActions.UpdateUserContextSuccess(data)),
      catchError(error => of(new UserContextsActions.UpdateUserContextFail(error)))
    )),
  );

  @Effect()
  deleteUserContext$: Observable<Action> = this.actions$.pipe(
    ofType(types.userContexts.DELETE_USER_CONTEXT),
    map((action: UserContextsActions.DeleteUserContext) => action.payload),
    concatMap(payload => this.api.delete({
      path: '/user_contexts/' + payload, body: {}
    }).pipe(
      map(data => new UserContextsActions.DeleteUserContextSuccess(data)),
      catchError(error => of(new UserContextsActions.DeleteUserContextFail(error)))
    )),
  );
}
