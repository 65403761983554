import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const payload = this.route.snapshot.paramMap.get('payload');
    const parsedPayload = JSON.parse(window.atob(payload));
    const tokenData = parsedPayload.token;
    const redirectUrl = parsedPayload.redirect;

    localStorage.setItem('session', JSON.stringify(tokenData));

    window.location.href = redirectUrl;
  }
}
