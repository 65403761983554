import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { Alert } from 'app/core/store/models/alert.model';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-new-shipping-agent',
  templateUrl: './new-shipping-agent.component.html',
})
export class NewShippingAgentComponent {
  alert: Alert;

  constructor(private api: ApiService, private toastr: ToastService, private router: Router) { }

  createNewShippingAgent(shippingAgentFormValue) {
    this.api
      .post({
        path: '/shipping_agents',
        body: shippingAgentFormValue
      })
      .subscribe(
        () => {
          this.toastr.showSuccess({
            message: 'Shipping Agent created!'
          });
          this.router.navigateByUrl(`/superadmin/shipping-agents`);
        },
        err => {
          this.toastr.showSuccess({
            title: 'Something went wrong',
            message: err
          });
        }
      );
  }
}
