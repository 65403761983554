import { ToastService } from 'app/services/toast.service';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import {
  LoadAllRegistrationRequests,
  ApproveRegistrationRequest,
  DeclineRegistrationRequest
} from '../../store/actions/registraction-request.actions';
import { State } from '../../store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RegistrationRequest } from '../../store/models/registration-request.model';
import * as Types from '../../store/types/registration-request.types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ApproveRegistrationRequestModalComponent
} from '../approve-registration-request-modal/approve-registration-request-modal.component';
import {
  DeclineRegistrationRequestModalComponent
} from '../decline-registration-request-modal/decline-registration-request-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-manage-registration-requests',
  templateUrl: 'manage-registration-requests.component.html',
  styleUrls: [
    './manage-registration-requests.component.scss'
  ]
})
export class ManageRegistrationRequestsComponent implements OnInit, OnDestroy {
  registrationRequests: RegistrationRequest[];
  totalElements: Number = 0;
  page: Number = 1;
  showProcessed = false;
  loading = false;

  constructor(
    private store: Store<State>,
    private registrationRequestUpdates$: Actions,
    private router: Router,
    private toastr: ToastService,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => state.registrationRequests)
      .pipe(untilDestroyed(this))
      .subscribe(registrationRequests => {
        if (registrationRequests && registrationRequests['showProcessed'] !== null) {
          this.showProcessed = registrationRequests['showProcessed'];
        }
        if (registrationRequests && registrationRequests['data']) {
          this.loading = false;
          this.registrationRequests = registrationRequests['data'];
          this.totalElements = registrationRequests['totalElements'] || 0;
        } else {
          this.loading = true;
          this.store.dispatch(new LoadAllRegistrationRequests());
        }
      });
  }

  ngOnInit() {
    this.registrationRequestUpdates$
      .pipe(ofType(Types.registrationRequests.APPROVE_REGISTRATION_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Approve registration request successful!'
        });
        this.loading = true;
        this.store.dispatch(new LoadAllRegistrationRequests());
      });
    this.registrationRequestUpdates$
      .pipe(ofType(Types.registrationRequests.APPROVE_REGISTRATION_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Approve registration request failed!'
        });
      });
    this.registrationRequestUpdates$
      .pipe(ofType(Types.registrationRequests.DECLINE_REGISTRATION_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Decline registration request successful!'
        });
        this.loading = true;
        this.store.dispatch(new LoadAllRegistrationRequests());
      });
    this.registrationRequestUpdates$
      .pipe(ofType(Types.registrationRequests.DECLINE_REGISTRATION_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Decline registration request failed!'
        });
      });
  }

  toggleShowProcessed() {
    this.loading = true;
    this.store.dispatch(new LoadAllRegistrationRequests({ toggleShowProcessed: true }));
  }

  approve(registrationRequestId: string) {
    this.modalService
      .open(ApproveRegistrationRequestModalComponent)
      .result.then(result => {
        if (result) {
          this.store.dispatch(new ApproveRegistrationRequest(registrationRequestId));
        }
      });
  }

  decline(registrationRequestId: string) {
    this.modalService
      .open(DeclineRegistrationRequestModalComponent)
      .result.then(result => {
        if (result) {
          this.store.dispatch(new DeclineRegistrationRequest(registrationRequestId));
        }
      });
  }

  edit(registrationRequestId: string) {
    this.router.navigate(['superadmin/registration-requests', registrationRequestId, 'edit']);
  }

  ngOnDestroy() { }
}
