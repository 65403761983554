import { Injectable } from '@angular/core';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/market-post.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as MarketpostActions from '../actions/market-posts.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class MarketpostEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions,
    private encoder: HttpUrlEncodingCodec
  ) { }

  @Effect()
  loadMarketposts$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.LOAD_MARKETPOSTS),
    map((action: MarketpostActions.LoadMarketposts) => action.payload),
    concatMap(payload => this.api.get({
      path: '/search?q=' + this.encoder.encodeValue(payload)
    }).pipe(
      map(data => new MarketpostActions.LoadMarketpostsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  loadMarketpostsCount$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.LOAD_MARKETPOSTS_COUNT),
    concatMap(() => this.api.get({ path: `/dashboard/marketplace/status` }).pipe(
      map(data => new MarketpostActions.LoadMarketpostsCountSuccess(data)),
      catchError(error => of(new MarketpostActions.LoadMarketpostsCountFailed(error)))
    )),
  );
}
