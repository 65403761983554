<div class="search-container mb-3 ml-3 mr-3">
  <app-search
    [placeholder]="searchPlaceholder"
    (search)="handleSearch($event)"
  ></app-search>
  <button class="btn" routerLink="/crm/tariffs/new" id="new-tariff-button">
    <i class="far fa-plus fa-fw"></i>
    <span>{{ 'New tariff template' | translate }}</span>
  </button>
</div>
<ngb-tabset [justify]="'justified'">
  <ngb-tab>
    <ng-template ngbTabTitle>{{
      'Customer tariff templates' | translate
    }}</ng-template>
    <ng-template ngbTabContent>
      <app-tariffs-table
        [isLoading]="isLoadingTariffs"
        [tariffs]="filteredCustomerTariffs"
        [totalElements]="totalElements"
        (deleteTariff)="handleDeleteTariff($event)"
      ></app-tariffs-table>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>{{
      'Supplier tariff templates' | translate
    }}</ng-template>
    <ng-template ngbTabContent>
      <app-tariffs-table
        [isLoading]="isLoading"
        [isLoading]="isLoadingTariffs"
        [tariffs]="filteredSupplierTariffs"
        (deleteTariff)="handleDeleteTariff($event)"
      ></app-tariffs-table>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
