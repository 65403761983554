import { Action } from '@ngrx/store';
import * as types from '../types/contacts.types';

/**
 * Starts loading the contacts.
 *
 * @export
 * @class LoadContacts
 * @implements {Action}
 */
export class LoadContacts implements Action {
  readonly type = types.contacts.LOAD_CONTACTS;
  constructor(public payload?: any) {}
}

/**
 * When loading the contacts fails for some reason.
 *
 * @export
 * @class LoadContactsFail
 * @implements {Action}
 */
export class LoadContactsFail implements Action {
  readonly type = types.contacts.LOAD_CONTACTS_FAILED;
  constructor(public payload: any) {}
}

/**
 * When the contacts loads successfully.
 *
 * @export
 * @class LoadContactsSuccess
 * @implements {Action}
 */
export class LoadContactsSuccess implements Action {
  readonly type = types.contacts.LOAD_CONTACTS_SUCCEEDED;
  constructor(public payload: any) {}
}

export type All = LoadContacts | LoadContactsFail | LoadContactsSuccess;
