<div class="card">
  <h3 class="card-header">{{ 'Reload Credits' | translate }}</h3>
  <div class="card-body">
    <p class="text-muted small">{{ 'This form starts a purchase process through our payment partner. Pick the amount of credits you want to purchase.
      After payment your credits will be available immediately. The price of 1 credit is 1 euro.' | translate }}
    </p>
    <div class="col">
      <div class="row">
        <button [class.active]="20 == rechargeForm.value.credits" (click)="setAmount(20)"
          class="col m-1 payment-amount-button btn btn-outline-primary">&euro; 20</button>
        <button [class.active]="250 == rechargeForm.value.credits" (click)="setAmount(250)"
          class="col m-1 payment-amount-button btn btn-outline-primary">&euro; 250</button>
        <button [class.active]="1000 == rechargeForm.value.credits" (click)="setAmount(1000)"
          class="col m-1 payment-amount-button btn btn-outline-primary">&euro; 1000</button>
        <button [class.active]="3000 == rechargeForm.value.credits" (click)="setAmount(3000)"
          class="col m-1 payment-amount-button btn btn-outline-primary">&euro; 3000</button>
        <button [class.active]="6000 == rechargeForm.value.credits" (click)="setAmount(6000)"
          class="col m-1 payment-amount-button btn btn-outline-primary">&euro; 6000</button>
      </div>
    </div>
    <p class="text-center mt-2" [hidden]="amountVisible">{{ 'Or' | translate }}
      <a href="javascript:void(0)" (click)="showAmount()">{{ 'set a custom amount.' | translate }}</a>
    </p>
    <form (ngSubmit)="onSubmit()" class="mt-2" [formGroup]="rechargeForm">
      <div class="form-group" [hidden]="!amountVisible">
        <label>{{ 'Amount' | translate }}</label>
        <input type="number" min="20" max="750000" class="form-control"
          [ngClass]="{'is-invalid': rechargeForm.controls['credits'].invalid &&
                      rechargeForm.controls['credits'].touched }"
          formControlName="credits">
        <div class="invalid-feedback">
          {{ 'This is not possible.' | translate }}
        </div>
      </div>
      <button class="btn btn-primary text-white" type="submit"
          [disabled]="rechargeForm.invalid || recharging">
        <i class="far fa-spinner fa-spin" *ngIf="recharging"></i>
        <span>
          {{ 'Recharge' | translate }}
        </span>
      </button>
    </form>
  </div>
</div>
