import * as types from '../types/contacts.types';
import * as ContactsActions from '../actions/contacts.actions';
import { Contact } from '../../contacts/contact.model';

const INITIAL_STATE = null;

/**
 * Reduces the contacts into the store.
 *
 * @export
 * @param {any} [state=INITIAL_STATE]
 * @param {ContactsActions.All} action
 * @returns {Contact[]}
 */
export function reducer(
  state = INITIAL_STATE,
  action: ContactsActions.All
): Contact[] {
  switch (action.type) {
    case types.contacts.LOAD_CONTACTS_SUCCEEDED:
      let sorted = [];
      if (action.payload && action.payload.length > 0) {
        sorted = action.payload.sort(
          (a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
        );
      }
      return [...sorted];
  }
  return state;
}
