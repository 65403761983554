export const reload = {
  LOAD_ALL_RELOAD_REQUESTS: '[Reload requests] Load All',
  LOAD_ALL_SUCCEEDED: '[Reload requests] Load All Success',
  LOAD_ALL_FAILED: '[Reload requests] Load All Fail',
  CREATE_RELOAD_REQUEST: '[Reload] Create Request',
  CREATE_RELOAD_REQUEST_SUCCEEDED: '[Reload] Create Request Success',
  CREATE_RELOAD_REQUEST_FAILED: '[Reload] Create Request Fail',
  UPDATE_RELOAD_REQUEST: '[Reload] Update Request',
  UPDATE_RELOAD_REQUEST_SUCCEEDED: '[Reload] Update Request Success',
  UPDATE_RELOAD_REQUEST_FAILED: '[Reload] Update Request Fail',
  FORCE_APPROVE_RELOAD_REQUEST: '[Reload] Force Approve Request',
  FORCE_APPROVE_RELOAD_REQUEST_SUCCEEDED: '[Reload] Force Approve Request Success',
  FORCE_APPROVE_RELOAD_REQUEST_FAILED: '[Reload] Force Approve Request Fail',
  CANCEL_RELOAD_REQUEST: '[Reload] Cancel Request',
  CANCEL_RELOAD_REQUEST_BY_LINER: '[Reload] Cancel Request by Liner',
  CANCEL_RELOAD_REQUEST_SUCCEEDED: '[Reload] Cancel Request Success',
  CANCEL_RELOAD_REQUEST_FAILED: '[Reload] Cancel Request Fail',
  CLEAR: '[Reload] Clear',
};
