import { AddressEffects } from '../core/store/effects/address.effects';
import { TaxRateEffects } from '../core/store/effects/tax-rate.effects';
import { CrmReducers } from '../core/store/reducers/crm.reducer';
import { SharedModule } from '../shared/shared.module';
import { CrmRoutingModule } from './crm-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CrmComponent } from './crm.component';
import { StoreModule } from '@ngrx/store';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { CustomersComponent } from './customers/customers.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { AddressComponent } from './address/address.component';
import { ContactComponent } from './contact/contact.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { InvoiceEffects } from '../core/store/effects/invoice.effects';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';
import { NewInvoiceComponent } from './new-invoice/new-invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { EditAddressModalComponent } from './edit-address-modal/edit-address-modal.component';
import { EditContactModalComponent } from './edit-contact-modal/edit-contact-modal.component';
import { CreditNoteEffects } from '../core/store/effects/credit-note.effects';
import { CreditNoteFormComponent } from './credit-note-form/credit-note-form.component';
import { NewCreditNoteComponent } from './new-credit-note/new-credit-note.component';
import { CreditNotesComponent } from './credit-notes/credit-notes.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { EditCreditNoteComponent } from './edit-credit-note/edit-credit-note.component';
import { TaxRateFormComponent } from './tax-rate-form/tax-rate-form.component';
import { NewTaxRateComponent } from './new-tax-rate/new-tax-rate.component';
import { EditTaxRateComponent } from './edit-tax-rate/edit-tax-rate.component';
import { TaxRatesComponent } from './tax-rates/tax-rates.component';
import { NewTaxRateModalComponent } from './new-tax-rate-modal/new-tax-rate-modal.component';
import { NewSupplierComponent } from './new-supplier/new-supplier.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierEffects } from '../core/store/effects/supplier.effects';
import { EditSupplierComponent } from './edit-supplier/edit-supplier.component';
import { PurchaseOrderEffects } from '../core/store/effects/purchase-order.effects';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { NewPurchaseOrderComponent } from './new-purchase-order/new-purchase-order.component';
import { PurchaseOrderFormComponent } from './purchase-order-form/purchase-order-form.component';
import { EditPurchaseOrderComponent } from './edit-purchase-order/edit-purchase-order.component';
import { BusinessContactEffects } from '../core/store/effects/business-contact.effects';

@NgModule({
  imports: [
    CommonModule,
    CrmRoutingModule,
    SharedModule,
    StoreModule.forFeature('crm', CrmReducers),
    EffectsModule.forFeature([
      InvoiceEffects,
      TaxRateEffects,
      AddressEffects,
      CreditNoteEffects,
      SupplierEffects,
      PurchaseOrderEffects,
      BusinessContactEffects
    ])
  ],
  declarations: [
    CrmComponent,
    NewCustomerComponent,
    CustomersComponent,
    EditCustomerComponent,
    AddressComponent,
    ContactComponent,
    AddressFormComponent,
    ContactFormComponent,
    AddressModalComponent,
    ContactModalComponent,
    InvoiceFormComponent,
    NewInvoiceComponent,
    InvoicesComponent,
    EditAddressModalComponent,
    EditContactModalComponent,
    CreditNoteFormComponent,
    NewCreditNoteComponent,
    CreditNotesComponent,
    EditInvoiceComponent,
    EditCreditNoteComponent,
    TaxRateFormComponent,
    NewTaxRateComponent,
    EditTaxRateComponent,
    TaxRatesComponent,
    NewTaxRateModalComponent,
    NewSupplierComponent,
    SuppliersComponent,
    EditSupplierComponent,
    PurchaseOrdersComponent,
    NewPurchaseOrderComponent,
    PurchaseOrderFormComponent,
    EditPurchaseOrderComponent
  ],
  providers: [CurrencyPipe],
  entryComponents: [
    NewTaxRateModalComponent,
    EditAddressModalComponent,
    EditContactModalComponent,
    AddressModalComponent,
    ContactModalComponent
  ]
})
export class CrmModule { }
