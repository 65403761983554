import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logout } from '../store/actions/session.actions';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';

import { AuthService } from './user.auth';
import { Store } from '@ngrx/store';
import { State } from '../store/store.model';
import { Go } from '../store/actions/router.actions';

/**
 * Class that allows or disallows users into routes.
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  /**
   * @constructor
   * @param authService { AuthSerice } The authentication service boii
   * @param router { Router } Router injectable from @angular/router
   */
  constructor(
    private store: Store<State>,
    private authService: AuthService
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.hasActiveSession().pipe(
      map(hasValidAccessToken => {
        if (!hasValidAccessToken) {
          this.store.dispatch(new Logout());
        }
        return hasValidAccessToken;
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}

/**
 * This route guard checks if the user is logged in
 */
@Injectable()
export class LoggedInGuard implements CanActivate, CanActivateChild {
  /**
   * @constructor
   * @param authService { AuthSerice } The authentication service boii
   * @param router { Router } Router injectable from @angular/router
   */
  constructor(
    private store: Store<State>,
    private authService: AuthService
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.hasActiveSession().pipe(
      map(hasValidAccessToken => {
        if (hasValidAccessToken) {
          this.store.dispatch(new Go({ path: ['/dashboard'] }));
          return false;
        } else {
          this.store.dispatch(new Go({ path: ['/login'] }));
          return true;
        }
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}

@Injectable()
export class PlannerGuard implements CanActivate, CanActivateChild {
  constructor(
  ) { }

  canActivate(): Observable<boolean> {
    return of(true);
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}

@Injectable()
export class BetaUserGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.getFromStoreOrAPI().pipe(
      map(data => {
        const isBetaUser = this.authService.isBetaUser(data);
        if (!isBetaUser) {
          this.router.navigate(['/404']);
        }
        return isBetaUser;
      }),
      catchError(() => of(false))
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}
