import { ELASTIC_SEARCH_RESPONSE_SIZE } from '../constants';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LoadMarketposts } from '../core/store/actions/market-posts.actions';
import { Autocomplete } from '../services/autocomplete.service';
var SearchService = /** @class */ (function () {
    function SearchService(store, autocompleteService) {
        this.store = store;
        this.autocompleteService = autocompleteService;
        this.base = {
            size: ELASTIC_SEARCH_RESPONSE_SIZE
        };
        this.locationFormatter = function (x) {
            return x.postcode ? x.name + ' (' + x.postcode + ')' : x.name;
        };
        this.addressFormatter = function (x) {
            if (typeof x === 'string') {
                return x;
            }
            if (x['fullAddress']) {
                return x['fullAddress'];
            }
            return (x.street ? x.street : '') + " " + (x.housenumber ? x.housenumber : '') + " " + (x.postcode ? x.postcode : '') + " " + (x.city ? x.city : '');
        };
    }
    SearchService.prototype.searchWithQuery = function (fields, query) {
        var body = {
            multi_match: {
                query: query,
                fields: fields,
                tie_breaker: 0.3,
                fuzziness: 'AUTO'
            }
        };
        localStorage.setItem('searchQuery', query);
        if (query && query.length > 1) {
            this.search(body, null, null);
        }
        else {
            localStorage.setItem('searchQuery', '');
            this.search(undefined, null, null);
        }
    };
    SearchService.prototype.filter = function (containerTypes, transportTypes, minWeight, maxWeight, reference, marketPostOffer, marketPostDemand, liners, maxDate, minDate) {
        var _this = this;
        var body = {
            must: []
        };
        if (transportTypes && transportTypes.length > 0) {
            body.must.push({
                terms: {}
            });
            var index_1 = body.must.length - 1;
            body.must[index_1]['terms']['transportType.label'] = [];
            transportTypes.forEach(function (tType) {
                if (tType.label.split(/(\s+)/).length > 1) {
                    tType.label.split(/(\s+)/).forEach(function (element) {
                        if (element !== ' ') {
                            body.must[index_1]['terms']['transportType.label'].push(element);
                        }
                    });
                }
                else {
                    body.must[index_1]['terms']['transportType.label'].push(tType.label);
                }
            });
        }
        if (liners && liners.length > 0) {
            body.must.push({
                terms: {}
            });
            var index_2 = body.must.length - 1;
            body.must[index_2]['terms']['container.liner.shortName'] = [];
            liners.forEach(function (liner) {
                // const index = body.query.bool.must.length - 1;
                if (liner.shortName.split(/(\s+)/).length > 1) {
                    liner.shortName.split(/(\s+)/).forEach(function (element) {
                        if (element !== ' ') {
                            body.must[index_2]['terms']['container.liner.shortName'].push(element.toLowerCase());
                        }
                    });
                }
                else {
                    body.must[index_2]['terms']['container.liner.shortName'].push(liner.shortName.toLowerCase());
                }
            });
        }
        if (containerTypes && containerTypes.length > 0) {
            body.must.push({
                terms: {}
            });
            body.must.push({
                terms: {}
            });
            var index_3 = body.must.length - 1;
            body.must[index_3]['terms']['container.containerType.label'] = [];
            body.must[index_3 - 1]['terms']['container.containerType.label'] = [];
            containerTypes.forEach(function (cType, cTypeIndex) {
                body.must[index_3]['terms']['container.containerType.label'].push(_this.containerTypeLabelLength(cTypeIndex, containerTypes));
                body.must[index_3 - 1]['terms']['container.containerType.label'].push(_this.containerTypeLabelText(cTypeIndex, containerTypes));
            });
        }
        if (minWeight || maxWeight) {
            body.must.push({
                range: {}
            });
            var index = body.must.length - 1;
            body.must[index]['range'] = {
                'container.weight': {
                    gte: minWeight,
                    lte: maxWeight
                }
            };
        }
        if (maxDate) {
            body.must.push({
                range: {}
            });
            var index = body.must.length - 1;
            body.must[index]['range'] = {
                primary_task_date: {
                    gte: new Date(minDate.year, minDate.month - 1, minDate.day, 0, 0, 0).toISOString(),
                    lte: new Date(maxDate.year, maxDate.month - 1, maxDate.day, 23, 59, 59).toISOString()
                }
            };
        }
        if (reference) {
            body.must.push({
                fuzzy: {}
            });
            var index = body.must.length - 1;
            body.must[index]['fuzzy'] = {
                reference: reference
            };
        }
        if (marketPostOffer || marketPostDemand) {
            body.must.push({
                terms: {}
            });
            var index = body.must.length - 1;
            body.must[index]['terms']['marketPostType'] = [];
            if (marketPostOffer) {
                body.must[index]['terms']['marketPostType'].push('offer');
            }
            if (marketPostDemand) {
                body.must[index]['terms']['marketPostType'].push('demand');
            }
        }
        if (body.must.length > 0) {
            this.search(null, body, null);
        }
        else {
            this.search(null, undefined, null);
        }
    };
    SearchService.prototype.filterGeoBoundBox = function (tlLat, tlLon, brLat, brLon) {
        var body = {
            bool: {
                should: [
                    {
                        bool: {
                            must: [
                                {
                                    match_all: {
                                        boost: 1.0
                                    }
                                }
                            ],
                            filter: {
                                geo_bounding_box: {
                                    'originLocation.geo': {
                                        bottom_right: {
                                            lat: brLat,
                                            lon: brLon
                                        },
                                        top_left: {
                                            lat: tlLat,
                                            lon: tlLon
                                        }
                                    }
                                }
                            }
                        }
                    },
                    {
                        bool: {
                            must: [
                                {
                                    match_all: {
                                        boost: 1.0
                                    }
                                }
                            ],
                            filter: {
                                geo_bounding_box: {
                                    'destinationLocation.geo': {
                                        bottom_right: {
                                            lat: brLat,
                                            lon: brLon
                                        },
                                        top_left: {
                                            lat: tlLat,
                                            lon: tlLon
                                        }
                                    }
                                }
                            }
                        }
                    },
                    {
                        bool: {
                            must: [
                                {
                                    match_all: {
                                        boost: 1.0
                                    }
                                }
                            ],
                            filter: {
                                geo_bounding_box: {
                                    'tasks.location.geo': {
                                        bottom_right: {
                                            lat: brLat,
                                            lon: brLon
                                        },
                                        top_left: {
                                            lat: tlLat,
                                            lon: tlLon
                                        }
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        };
        this.search(null, null, body);
    };
    SearchService.prototype.resetSearch = function () {
        // this.search(this.base);
        localStorage.removeItem('qq');
        localStorage.removeItem('fq');
        localStorage.removeItem('mq');
        localStorage.removeItem('query');
        this.search(null, null, null);
    };
    SearchService.prototype.search = function (qQuery, fQuery, mQuery) {
        var _a;
        var body = {
            sort: [{ last_updated: { order: 'desc' } }],
            size: ELASTIC_SEARCH_RESPONSE_SIZE,
            query: {
                bool: {
                    must: []
                }
            }
        };
        // Get the queryquery, filterquery and mapquery from localstorage
        var qq = localStorage.getItem('qq');
        if (qq) {
            qq = JSON.parse(qq);
        }
        var fq = localStorage.getItem('fq');
        if (fq) {
            fq = JSON.parse(fq);
        }
        var mq = localStorage.getItem('mq');
        if (mq) {
            mq = JSON.parse(mq);
        }
        // If qQuery has a value, the qq needs to be updated with said value
        if (qQuery) {
            body.query.bool.must.push(qQuery);
            localStorage.removeItem('qq');
            localStorage.setItem('qq', JSON.stringify(qQuery));
        }
        else if (qQuery === undefined) {
            localStorage.removeItem('qq');
        }
        else if (qq) {
            body.query.bool.must.push(qq);
        }
        if (fQuery) {
            (_a = body.query.bool.must).push.apply(_a, fQuery.must);
            localStorage.removeItem('fq');
            localStorage.setItem('fq', JSON.stringify(fQuery.must.slice()));
        }
        else if (fq) {
            // a filter query was found in the localstorage
            body.query.bool.must.push(fq);
        }
        // else if (fQuery === undefined) { // no filter parameters were given
        //   localStorage.removeItem('fq');
        // }
        if (mQuery) {
            body.query.bool.must.push(mQuery);
            localStorage.removeItem('mq');
            localStorage.setItem('mq', JSON.stringify(mQuery));
        }
        else if (mq) {
            body.query.bool.must.push(mq);
        }
        var query = JSON.stringify(body);
        localStorage.setItem('query', query);
        var session = JSON.parse(localStorage.getItem('session'));
        if (session && session['tenant']) {
            this.store.dispatch(new LoadMarketposts(query));
        }
    };
    SearchService.prototype.containerTypeLabelLength = function (index, containerTypes) {
        var length = containerTypes[index].label.split(/(\s+)/)[0];
        return length;
    };
    SearchService.prototype.containerTypeLabelText = function (index, containerTypes) {
        var text = containerTypes[index].label.split(/(\s+)/)[2];
        return text;
    };
    SearchService.prototype.location = function (term) {
        var options = {
            include_internal_locations: true,
            cities_only: true
        };
        var results = this.autocompleteService
            .search('location', term, options)
            .pipe(map(function (locations) {
            if (locations) {
                return locations.features.map(function (location) {
                    var properties = location.properties;
                    var geometry = location.geometry;
                    var name = properties.name;
                    if (properties.postcode) {
                        name += " (" + properties.postcode + ")";
                    }
                    if (properties.country) {
                        name += ", " + properties.country;
                    }
                    return {
                        id: properties['@id'],
                        shortName: properties.short_name || '',
                        name: name,
                        country: properties.country || null,
                        longitude: geometry.coordinates[0],
                        latitude: geometry.coordinates[1]
                    };
                });
            }
        }));
        return results;
    };
    SearchService.prototype.privateLocation = function (term) {
        var options = {
            include_internal_locations: true,
            cities_only: true
        };
        var results = this.autocompleteService
            .search('location', term, options)
            .pipe(map(function (locations) {
            if (locations) {
                return locations.features.map(function (location) {
                    if (location.properties.visibility === 'private') {
                        return {
                            id: location.properties.id,
                            shortName: location.properties.short_name || null,
                            name: location.properties.name || null,
                            address: location.properties.address || null,
                            longitude: location.geometry.coordinates[0],
                            latitude: location.geometry.coordinates[1],
                            public_location: location.properties.public_location || null,
                            visibility: location.properties.visibility
                        };
                    }
                    else {
                        return {
                            id: location.properties['@id'],
                            shortName: location.properties.short_name || '',
                            name: location.properties.postcode
                                ? location.properties.name +
                                    ' (' +
                                    location.properties.postcode +
                                    ')'
                                : location.properties.name,
                            longitude: location.geometry.coordinates[0],
                            latitude: location.geometry.coordinates[1],
                            visibility: location.properties.visibility
                        };
                    }
                });
            }
        }));
        return results;
    };
    SearchService.prototype.reverseGeocode = function (latitude, longitude) {
        console.log("Reversing lat: " + latitude + ", lon: " + longitude);
        // return this.autocompleteService.search('reverse', { latitude: latitude, longitude: longitude });
    };
    SearchService.prototype.address = function (term) {
        var results = this.autocompleteService
            .search('location', term)
            .pipe(map(function (locations) {
            if (locations) {
                return (locations.features
                    // .filter(location => location.properties.street)
                    .map(function (location) {
                    return {
                        id: location.properties['@id'],
                        street: location.properties.street ||
                            location.properties.name ||
                            '',
                        housenumber: location.properties.housenumber || '',
                        postcode: location.properties.postcode || '',
                        city: location.properties.city || '',
                        longitude: location.geometry.coordinates[0],
                        latitude: location.geometry.coordinates[1]
                    };
                }));
            }
        }));
        return results;
    };
    return SearchService;
}());
export { SearchService };
