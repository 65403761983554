<app-cmp-loading [loading]="loading"></app-cmp-loading>
<ngx-datatable
class="bootstrap offers row ml-0 mr-0"
[rows]="marketPostOfferReplies"
[columnMode]="'force'"
[headerHeight]="50"
[footerHeight]="50"
[rowHeight]="'auto'"
[limit]="15"
[loadingIndicator]="true">
<ngx-datatable-column name="Date" prop="created">
  <ng-template ngx-datatable-cell-template let-value="value">
    {{ value | date:'dd/MM/yyyy HH:mm'}}
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column name="Offer" prop="marketPostOffer.id"></ngx-datatable-column>
<ngx-datatable-column name="Reply" prop="text"></ngx-datatable-column>
</ngx-datatable>
