<form [formGroup]="registrationRequestForm" class="pb-3 card-body">
  <div class="form-group">
    <label class="center-block">{{ 'Company Name' | translate }}</label>
    <input
      class="form-control"
      formControlName="companyName"
      data-cy="companyName"
      [ngClass]="{
        'is-invalid':
          registrationRequestForm.controls['companyName'].invalid &&
          registrationRequestForm.controls['companyName'].touched
      }"
    />
    <div class="invalid-feedback">
      {{ 'Company Name is required.' | translate }}
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'VAT number' | translate }}</label>
    <div class="input-group">
      <input
        class="form-control"
        formControlName="vatNumber"
        data-cy="vatNumber"
        [ngClass]="{
          'is-invalid':
            (registrationRequestForm.controls['vatNumber'].invalid &&
              registrationRequestForm.controls['vatNumber'].touched) ||
            (vatNumberIsVerified && !vatNumberIsValid),
          'is-valid': vatNumberIsValid
        }"
      />
      <div class="input-group-append">
        <app-button
          (click)="verifyVatNumber()"
          [btnClass]="'btn-dark'"
          [isLoading]="isVerifyingVatNumber"
          [type]="'button'"
        >
          {{ 'Verify VAT' | translate }}
        </app-button>
      </div>
    </div>
    <div class="invalid-feedback">
      {{ 'VAT number is required.' | translate }}
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{
          'Street and number' | translate
        }}</label>
        <input
          class="form-control"
          formControlName="streetAndNumber"
          data-cy="streetAndNumber"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['streetAndNumber'].invalid &&
              registrationRequestForm.controls['streetAndNumber'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Street and number is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Zipcode' | translate }}</label>
        <input
          class="form-control"
          formControlName="zipcode"
          data-cy="zipcode"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['zipcode'].invalid &&
              registrationRequestForm.controls['zipcode'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Zipcode is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'City' | translate }}</label>
        <input
          class="form-control"
          formControlName="city"
          data-cy="city"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['city'].invalid &&
              registrationRequestForm.controls['city'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'City is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block"> {{ 'Country' | translate }}</label>
        <ng-select
          id="country"
          [items]="countries"
          bindLabel="label"
          bindValue="value"
          data-cy="countries"
          placeholder="{{ 'Select country' | translate }}"
          formControlName="country"
        >
        </ng-select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">
          {{ 'Prefered language' | translate }}</label
        >
        <ng-select
          id="language"
          [items]="languages"
          bindLabel="label"
          bindValue="value"
          data-cy="languages"
          placeholder="{{ 'Select Language' | translate }}"
          formControlName="locale"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Invoice Email' | translate }}</label>
        <input
          class="form-control"
          formControlName="invoiceEmail"
          data-cy="invoiceEmail"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['invoiceEmail'].invalid &&
              registrationRequestForm.controls['invoiceEmail'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Invoice Email is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Phone number' | translate }}</label>
        <input
          class="form-control"
          formControlName="phoneNumber"
          data-cy="phoneNumber"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['phoneNumber'].invalid &&
              registrationRequestForm.controls['phoneNumber'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Phone number is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Email' | translate }}</label>
        <input
          class="form-control"
          formControlName="email"
          data-cy="email"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['email'].invalid &&
              registrationRequestForm.controls['email'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Email is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'First Name' | translate }}</label>
        <input
          class="form-control"
          formControlName="firstName"
          data-cy="firstName"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['firstName'].invalid &&
              registrationRequestForm.controls['firstName'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'First name is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Last Name' | translate }}</label>
        <input
          class="form-control"
          formControlName="lastName"
          data-cy="lastName"
          [ngClass]="{
            'is-invalid':
              registrationRequestForm.controls['lastName'].invalid &&
              registrationRequestForm.controls['lastName'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Last Name is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">
      {{ 'Requested subscriptions' | translate }}</label
    >
    <ng-select
      id="subscriptionTypes"
      [items]="subscriptionTypes"
      bindLabel="name"
      placeholder="Select subscriptions"
      data-cy="subscriptionTypes"
      formControlName="subscriptionTypes"
      [multiple]="true"
      [closeOnSelect]="false"
    >
    </ng-select>
  </div>
  <button
    class="btn btn-primary text-white"
    (click)="save()"
    [disabled]="
      registrationRequestForm.invalid || registrationRequestForm.pristine
    "
  >
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span> {{ 'Save' | translate }} </span>
  </button>
  <button
    class="btn btn-primary text-white"
    *ngIf="registrationRequest && registrationRequest.status !== 'approved'"
    [disabled]="registrationRequestForm.invalid"
    (click)="saveAndApprove()"
  >
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span> {{ 'Save and Approve' | translate }} </span>
  </button>
  <button
    class="btn btn-danger text-white"
    *ngIf="registrationRequest && registrationRequest.status === 'pending'"
    (click)="decline()"
  >
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span> {{ 'Decline' | translate }} </span>
  </button>
</form>
