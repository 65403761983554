import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'app/services/toast.service';
import { ApiService } from 'app/core/api/api.service';
import { Component, Input } from '@angular/core';
import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-edit-visit-modal',
  templateUrl: './edit-visit-modal.component.html',
})
export class EditVisitModalComponent {
  @Input()
  task;

  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService,
    private toastr: ToastService
  ) { }

  updateVisit(visitFormValue) {
    this.api
      .put({ path: '/visits/' + this.task.visit.id, body: visitFormValue })
      .subscribe(response => {
        this.toastr.showSuccess({
          message: extract('Prenotification updated sucessfully')
        });
      });
  }
}
