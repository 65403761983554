/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirm-action-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmActionModalComponent = [];
var RenderType_ConfirmActionModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmActionModalComponent, data: {} });
export { RenderType_ConfirmActionModalComponent as RenderType_ConfirmActionModalComponent };
export function View_ConfirmActionModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 12, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "message mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 8, "div", [["class", "actions d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-outline-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 4, "button", [["class", "btn ml-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(15, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(16, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = "btn ml-3"; var currVal_4 = _co.confirmButtonClass; _ck(_v, 15, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.title)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.message)); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.cancelButtonText)); _ck(_v, 11, 0, currVal_2); var currVal_5 = i0.ɵunv(_v, 16, 0, i0.ɵnov(_v, 17).transform(_co.confirmButtonText)); _ck(_v, 16, 0, currVal_5); }); }
export function View_ConfirmActionModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-action-modal", [], null, null, null, View_ConfirmActionModalComponent_0, RenderType_ConfirmActionModalComponent)), i0.ɵdid(1, 49152, null, 0, i3.ConfirmActionModalComponent, [i4.NgbActiveModal], null, null)], null, null); }
var ConfirmActionModalComponentNgFactory = i0.ɵccf("app-confirm-action-modal", i3.ConfirmActionModalComponent, View_ConfirmActionModalComponent_Host_0, { title: "title", message: "message", cancelButtonText: "cancelButtonText", cancelButtonClass: "cancelButtonClass", confirmButtonText: "confirmButtonText", confirmButtonClass: "confirmButtonClass" }, {}, []);
export { ConfirmActionModalComponentNgFactory as ConfirmActionModalComponentNgFactory };
