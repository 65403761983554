/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../core/admin/tenant-settings/user-contexts/user-context-form/user-context-form.component.ngfactory";
import * as i3 from "../../../core/admin/tenant-settings/user-contexts/user-context-form/user-context-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../services/i18n.service";
import * as i7 from "./new-driver-modal.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../../services/toast.service";
import * as i10 from "@ngrx/effects";
var styles_NewDriverModalComponent = [];
var RenderType_NewDriverModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewDriverModalComponent, data: {} });
export { RenderType_NewDriverModalComponent as RenderType_NewDriverModalComponent };
export function View_NewDriverModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "card-header d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [["class", "align-self-center mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "btn ml-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-cmp-user-context-form", [], null, [[null, "userContextFormValue"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userContextFormValue" === en)) {
        var pd_0 = (_co.createNewDriver($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UserContextFormComponent_0, i2.RenderType_UserContextFormComponent)), i0.ɵdid(9, 770048, null, 0, i3.UserContextFormComponent, [i4.FormBuilder, i5.Store, i6.I18nService], { loading: [0, "loading"], driver: [1, "driver"] }, { userContextFormValue: "userContextFormValue" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isLoading; var currVal_3 = true; _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("New driver")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Close")); _ck(_v, 5, 0, currVal_1); }); }
export function View_NewDriverModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-driver-modal", [], null, null, null, View_NewDriverModalComponent_0, RenderType_NewDriverModalComponent)), i0.ɵdid(1, 180224, null, 0, i7.NewDriverModalComponent, [i8.NgbActiveModal, i5.Store, i9.ToastService, i10.Actions], null, null)], null, null); }
var NewDriverModalComponentNgFactory = i0.ɵccf("app-new-driver-modal", i7.NewDriverModalComponent, View_NewDriverModalComponent_Host_0, {}, {}, []);
export { NewDriverModalComponentNgFactory as NewDriverModalComponentNgFactory };
