import { EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import * as DateFns from 'date-fns';
var CreditNoteFormComponent = /** @class */ (function () {
    function CreditNoteFormComponent(route, api, fb) {
        var _this = this;
        this.route = route;
        this.api = api;
        this.fb = fb;
        this.creditNoteFormSubmitted = new EventEmitter();
        this.onSaveAndDownloadAsPDF = new EventEmitter();
        this.onSaveAndPreview = new EventEmitter();
        this.taxRateExpr = new RegExp(/[0-9]{1,3}/);
        this.addTagText = 'Add tax rate';
        this.createNewTaxRate = function (rate) {
            if (!_this.taxRateExpr.test(rate)) {
                return null;
            }
            // TODO: add api call to create the new tag
            return rate;
        };
        // Get invoice id from query params
        this.route.queryParamMap.subscribe(function (data) {
            if (data) {
                _this.invoiceId = data.get('id');
                if (!_this.invoiceId) {
                    return;
                }
                _this.api
                    .get({
                    path: "/crm/invoices/" + _this.invoiceId
                })
                    .subscribe(function (response) {
                    response.items.forEach(function (item) { return delete item.id; });
                    _this.invoice = response;
                    _this.updateFormIfReady();
                });
            }
        });
        // Get credit note id from params
        this.route.paramMap.subscribe(function (data) {
            if (data) {
                _this.creditNoteId = data.get('id');
                if (!_this.creditNoteId) {
                    return;
                }
                _this.api
                    .get({
                    path: "/crm/credit_notes/" + _this.creditNoteId
                })
                    .subscribe(function (response) {
                    _this.creditNote = response;
                    _this.updateFormIfReady();
                });
            }
        });
    }
    CreditNoteFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.customers) {
            this.updateFormIfReady();
        }
        if (changes && changes.taxRates) {
            this.updateFormIfReady();
        }
    };
    CreditNoteFormComponent.prototype.updateFormIfReady = function () {
        if (this.isComponentReady()) {
            this.createForm();
            this.patchForm();
        }
    };
    CreditNoteFormComponent.prototype.isComponentReady = function () {
        return (this.customers &&
            (this.invoice || this.creditNote) &&
            this.taxRates &&
            !this.creditNoteForm);
    };
    CreditNoteFormComponent.prototype.createForm = function () {
        this.creditNoteForm = this.fb.group({
            business: [null],
            date: [new Date()],
            notes: [null],
            items: this.fb.array([this.createCreditNoteItem()])
        });
    };
    Object.defineProperty(CreditNoteFormComponent.prototype, "customerAddress", {
        get: function () {
            if (!this.creditNoteForm ||
                !this.creditNoteForm.value ||
                !this.creditNoteForm.get('business').value) {
                return;
            }
            return this.creditNoteForm.get('business').value['billingAddress'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditNoteFormComponent.prototype, "customerMainContact", {
        get: function () {
            if (!this.creditNoteForm ||
                !this.creditNoteForm.value ||
                !this.creditNoteForm.get('business').value) {
                return;
            }
            return this.creditNoteForm.get('business').value['defaultContact'];
        },
        enumerable: true,
        configurable: true
    });
    CreditNoteFormComponent.prototype.createCreditNoteItem = function () {
        var group = this.fb.group({
            id: null,
            description: [''],
            quantity: [1],
            unitPrice: [null],
            taxRate: [this.taxRates[0]],
            total: [null]
        });
        return group;
    };
    CreditNoteFormComponent.prototype.addCreditNoteItem = function () {
        var creditNoteItems = this.creditNoteForm.controls['items'];
        creditNoteItems.push(this.createCreditNoteItem());
    };
    CreditNoteFormComponent.prototype.removeCreditNoteItem = function (index) {
        var creditNoteItems = this.creditNoteForm.controls['items'];
        creditNoteItems.removeAt(index);
    };
    /**
     * Duplicates the given creditNote item
     * @param index index of the creditNote item
     */
    CreditNoteFormComponent.prototype.duplicateItem = function (index) {
        this.addCreditNoteItem();
        var creditNoteItems = this.creditNoteForm.controls['items'];
        var item = Object.assign({}, creditNoteItems.at(index).value);
        creditNoteItems.at(creditNoteItems.value.length - 1).patchValue(item);
    };
    /**
     * Updates the total of the given creditNote item based on the quantity, unit pirce
     * and tax rate
     * @param index index of the creditNote item
     */
    CreditNoteFormComponent.prototype.updateTotal = function (index) {
        var creditNoteItems = this.creditNoteForm.controls['items'];
        var item = creditNoteItems.value[index];
        if (!item.quantity ||
            !item.unitPrice ||
            !item.taxRate ||
            !item.taxRate.rate) {
            return;
        }
        item.total =
            +item.quantity * +item.unitPrice +
                ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate;
        creditNoteItems.at(index).setValue(item);
    };
    Object.defineProperty(CreditNoteFormComponent.prototype, "total", {
        get: function () {
            var creditNoteItems = this.creditNoteForm.controls['items'].value;
            return creditNoteItems
                .map(function (item) { return +item.total; })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditNoteFormComponent.prototype, "subTotal", {
        get: function () {
            var creditNoteItems = this.creditNoteForm.controls['items'].value;
            return creditNoteItems
                .map(function (item) { return +item.quantity * +item.unitPrice; })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditNoteFormComponent.prototype, "taxes", {
        get: function () {
            var creditNoteItems = this.creditNoteForm.controls['items'].value;
            if (creditNoteItems.some(function (item) { return !item.taxRate.rate; })) {
                return;
            }
            return creditNoteItems
                .map(function (item) { return ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate; })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    CreditNoteFormComponent.prototype.patchForm = function () {
        var _this = this;
        var paymentyNote = this.invoice
            ? this.invoice
            : this.creditNote
                ? this.creditNote
                : null;
        this.creditNoteForm.patchValue({
            business: paymentyNote.business,
            notes: paymentyNote.notes
        });
        paymentyNote.items.forEach(function (item) {
            var creditNoteItems = _this.creditNoteForm.controls['items'];
            creditNoteItems.at(creditNoteItems.value.length - 1).patchValue({
                id: item.id,
                description: item.description,
                quantity: item.quantity,
                unitPrice: item.unitPrice,
                taxRate: item.taxRate,
                total: item['totalInclTaxes']
            });
            _this.addCreditNoteItem();
        });
    };
    /**
     * Emits the creditNote form value
     */
    CreditNoteFormComponent.prototype.onSubmit = function () {
        if (this.creditNoteForm.invalid) {
            return;
        }
        var creditNoteFormValue = this.parseCreditFormValue();
        this.creditNoteFormSubmitted.emit(creditNoteFormValue);
    };
    CreditNoteFormComponent.prototype.parseCreditFormValue = function () {
        var creditNoteFormValue = JSON.parse(JSON.stringify(this.creditNoteForm.value));
        creditNoteFormValue.items = creditNoteFormValue.items.filter(function (item) { return item.unitPrice || item.description || item.transport; });
        creditNoteFormValue.Date = DateFns.setHours(creditNoteFormValue.date, 12);
        creditNoteFormValue.business = creditNoteFormValue.business['@id'];
        creditNoteFormValue.items.forEach(function (item) {
            item.taxRate = {
                id: item.taxRate['id']
            };
        });
        return creditNoteFormValue;
    };
    CreditNoteFormComponent.prototype.saveAndDownloadAsPDF = function () {
        if (this.creditNoteForm.invalid) {
            return;
        }
        var creditNoteFormValue = this.parseCreditFormValue();
        this.onSaveAndDownloadAsPDF.emit(creditNoteFormValue);
    };
    CreditNoteFormComponent.prototype.saveAndPreview = function () {
        if (this.creditNoteForm.invalid) {
            return;
        }
        var creditNoteFormValue = this.parseCreditFormValue();
        this.onSaveAndPreview.emit(creditNoteFormValue);
    };
    return CreditNoteFormComponent;
}());
export { CreditNoteFormComponent };
