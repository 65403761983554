import { Attachment } from '../models/attachment.model';
import { Action } from '@ngrx/store';
import * as types from '../types/attachment.types';

export class UpdateAttachment implements Action {
  readonly type = types.attachment.UPDATE_ATTACHMENT;
  constructor(
    public payload?: {
      attachmentId: string;
      body: Attachment;
    }
  ) {}
}

export class UpdateAttachmentFail implements Action {
  readonly type = types.attachment.UPDATE_ATTACHMENT_FAILED;
  constructor(public payload: any) {}
}

export class UpdateAttachmentSuccess implements Action {
  readonly type = types.attachment.UPDATE_ATTACHMENT_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteAttachment implements Action {
  readonly type = types.attachment.DELETE_ATTACHMENT;
  constructor(
    public payload?: {
      attachmentId: string;
    }
  ) {}
}

export class DeleteAttachmentFail implements Action {
  readonly type = types.attachment.DELETE_ATTACHMENT_FAILED;
  constructor(public payload: any) {}
}

export class DeleteAttachmentSuccess implements Action {
  readonly type = types.attachment.DELETE_ATTACHMENT_SUCCEEDED;
  constructor(public payload: any) {}
}

export type All =
  | UpdateAttachment
  | UpdateAttachmentFail
  | UpdateAttachmentSuccess
  | DeleteAttachment
  | DeleteAttachmentFail
  | DeleteAttachmentSuccess;
