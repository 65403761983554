import { PlanningComponent } from './planning/planning.component';
import { ManageDriverScheduleComponent } from './manage-driver-schedule/manage-driver-schedule.component';
import { DriverVisitsComponent } from './driver-visits/driver-visits.component';
import { DriverListComponent } from './drivers/driver-list/driver-list.component';
import { EditDriverComponent } from './drivers/edit-driver/edit-driver.component';
import { NewDriverComponent } from './drivers/new-driver/new-driver.component';
import { AuthGuard } from '../core/user/user.authguard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TmsComponent } from './tms.component';
import { TransportListComponent } from './transports/transport-list/transport-list.component';
import { NewTransportComponent } from './transports/new-transport/new-transport.component';
import { EditTransportComponent } from './transports/edit-transport/edit-transport.component';
import { NewVisitComponent } from './visits/new-visit/new-visit.component';
import { VisitsComponent } from './visits/visits.component';
import { EditVisitComponent } from './visits/edit-visit/edit-visit.component';
import { Route } from '../core/route.service';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';
import { CopyTransportComponent } from './copy-transport/copy-transport.component';

const defaultTmsGaurds = [
  AuthGuard,
  TenantGuard,
  SubscriptionGuard,
  DriverGuard,
  AllowedSubscriptionGuard
];
const routes: Routes = Route.withShell([
  {
    path: 'driver/tasks',
    component: DriverVisitsComponent,
    canActivate: [AuthGuard, TenantGuard, SubscriptionGuard],
    data: { subscriptionCode: 'TMS' }
  },
  {
    path: 'tms',
    component: TmsComponent,
    canActivate: [...defaultTmsGaurds],
    data: { subscriptionCode: 'TMS' }
  },
  {
    path: 'tms/drivers',
    children: [
      {
        path: '',
        component: DriverListComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: 'new',
        component: NewDriverComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: ':id/edit',
        component: EditDriverComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      }
    ]
  },
  {
    path: 'tms/transports',
    children: [
      {
        path: '',
        component: TransportListComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: 'new',
        component: NewTransportComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: 'copy',
        component: CopyTransportComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: ':id/edit',
        component: EditTransportComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      }
    ]
  },
  {
    path: 'tms/prenotifications',
    children: [
      {
        path: '',
        component: VisitsComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: 'new',
        component: NewVisitComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: ':id/edit',
        component: EditVisitComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      },
      {
        path: 'driver-schedule',
        component: ManageDriverScheduleComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      }
    ]
  },
  {
    path: 'tms/planning',
    children: [
      {
        path: '',
        component: PlanningComponent,
        canActivate: [...defaultTmsGaurds],
        data: { subscriptionCode: 'TMS' }
      }
    ]
  }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TmsRoutingModule { }

export const routedComponents = [TmsComponent];
