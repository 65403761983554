import { Action } from '@ngrx/store';
import * as types from '../types/invoice.types';

export class LoadInvoice implements Action {
  readonly type = types.invoice.LOAD_INVOICE;
  constructor(public payload?: any) {}
}

export class LoadInvoiceSuccess implements Action {
  readonly type = types.invoice.LOAD_INVOICE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadInvoiceFail implements Action {
  readonly type = types.invoice.LOAD_INVOICE_FAILED;
  constructor(public payload?: any) {}
}

export class LoadInvoices implements Action {
  readonly type = types.invoice.LOAD_INVOICES;
  constructor(public payload?: any) {}
}

export class LoadInvoicesFail implements Action {
  readonly type = types.invoice.LOAD_INVOICES_FAILED;
  constructor(public payload: any) {}
}

export class LoadInvoicesSuccess implements Action {
  readonly type = types.invoice.LOAD_INVOICES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadFilteredInvoices implements Action {
  readonly type = types.invoice.LOAD_FILTERED_INVOICES;
  constructor(public payload?: any) {}
}

export class LoadFilteredInvoicesFail implements Action {
  readonly type = types.invoice.LOAD_FILTERED_INVOICES_FAILED;
  constructor(public payload: any) {}
}

export class LoadFilteredInvoicesSuccess implements Action {
  readonly type = types.invoice.LOAD_FILTERED_INVOICES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class CreateInvoice implements Action {
  readonly type = types.invoice.CREATE_INVOICE;
  constructor(public payload?: any) {}
}

export class CreateInvoiceFail implements Action {
  readonly type = types.invoice.CREATE_INVOICE_FAILED;
  constructor(public payload: any) {}
}

export class CreateInvoiceSuccess implements Action {
  readonly type = types.invoice.CREATE_INVOICE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateInvoice implements Action {
  readonly type = types.invoice.UPDATE_INVOICE;
  constructor(
    public payload?: {
      invoiceId: string;
      body: any;
    }
  ) {}
}

export class UpdateInvoiceFail implements Action {
  readonly type = types.invoice.UPDATE_INVOICE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateInvoiceSuccess implements Action {
  readonly type = types.invoice.UPDATE_INVOICE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteInvoice implements Action {
  readonly type = types.invoice.DELETE_INVOICE;
  constructor(
    public payload?: {
      invoiceId: string;
    }
  ) {}
}

export class DeleteInvoiceFail implements Action {
  readonly type = types.invoice.DELETE_INVOICE_FAILED;
  constructor(public payload: any) {}
}

export class DeleteInvoiceSuccess implements Action {
  readonly type = types.invoice.DELETE_INVOICE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class ClearInvoices implements Action {
  readonly type = types.invoice.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadInvoices
  | LoadInvoicesFail
  | LoadInvoicesSuccess
  | LoadFilteredInvoices
  | LoadFilteredInvoicesFail
  | LoadFilteredInvoicesSuccess
  | CreateInvoice
  | CreateInvoiceSuccess
  | CreateInvoiceFail
  | DeleteInvoice
  | DeleteInvoiceSuccess
  | DeleteInvoiceFail
  | UpdateInvoice
  | UpdateInvoiceSuccess
  | UpdateInvoiceFail
  | ClearInvoices;
