/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./registration-request.component";
var styles_RegistrationRequestComponent = [];
var RenderType_RegistrationRequestComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegistrationRequestComponent, data: {} });
export { RenderType_RegistrationRequestComponent as RenderType_RegistrationRequestComponent };
export function View_RegistrationRequestComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "login-background fullscreen overflow-y"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "row justify-content-center mt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["alt", "Hakka"], ["class", "sidebar-logo"], ["src", "/assets/img/logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("Enter your email address to request access tot the Hakka platform.")); _ck(_v, 7, 0, currVal_0); }); }
export function View_RegistrationRequestComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-registration-request", [], null, null, null, View_RegistrationRequestComponent_0, RenderType_RegistrationRequestComponent)), i0.ɵdid(1, 49152, null, 0, i2.RegistrationRequestComponent, [], null, null)], null, null); }
var RegistrationRequestComponentNgFactory = i0.ɵccf("app-cmp-registration-request", i2.RegistrationRequestComponent, View_RegistrationRequestComponent_Host_0, {}, {}, []);
export { RegistrationRequestComponentNgFactory as RegistrationRequestComponentNgFactory };
