import { TaxRate } from '../models/tax-rate.model';
import * as types from '../types/tax-rate.types';
import * as TaxRateActions from '../actions/tax-rate.actions';

export interface TaxRatesState {
  taxRates: TaxRate[];
  filteredTaxRates: TaxRate[];
}

const INITIAL_STATE = {
  taxRates: null,
  filteredTaxRates: null
};

export function TaxRatesReducer(
  state = INITIAL_STATE,
  action: TaxRateActions.All
): TaxRatesState {
  switch (action.type) {
    case types.taxRate.LOAD_TAX_RATES_SUCCEEDED:
      return Object.assign({}, state, {
        taxRates: [...action.payload['hydra:member']],
        filteredTaxRates: [...action.payload['hydra:member']]
      });
    case types.taxRate.LOAD_FILTERED_TAX_RATES_SUCCEEDED:
      return Object.assign({}, state, {
        filteredTaxRates: [...action.payload['hydra:member']]
      });
    case types.taxRate.UPDATE_TAX_RATE_SUCCEEDED:
      if (!state || !state.taxRates || !state.filteredTaxRates) {
        return state;
      }
      let taxRates = [...state.taxRates];
      let filteredTaxRates = [...state.filteredTaxRates];

      const taxRateIndex = taxRates.findIndex(i => i.id === action.payload.id);
      const tempTaxRate = Object.assign(
        {},
        state.taxRates[taxRateIndex],
        action.payload
      );
      taxRates[taxRateIndex] = tempTaxRate;
      const filteredTaxRateIndex = filteredTaxRates.findIndex(
        i => i.id === action.payload.id
      );
      const tempFilteredTaxRate = Object.assign(
        {},
        filteredTaxRates[filteredTaxRateIndex],
        action.payload
      );
      filteredTaxRates[filteredTaxRateIndex] = tempFilteredTaxRate;
      return Object.assign({}, state, {
        taxRates,
        filteredTaxRates
      });
    case types.taxRate.DELETE_TAX_RATE_SUCCEEDED:
      if (!state || !state.taxRates || !state.filteredTaxRates) {
        return state;
      }
      taxRates = [
        ...state.taxRates.filter(
          (taxRate: TaxRate) => taxRate.id !== action.payload
        )
      ];
      filteredTaxRates = [
        ...state.filteredTaxRates.filter(
          (taxRate: TaxRate) => taxRate.id !== action.payload
        )
      ];
      return Object.assign({}, state, {
        taxRates,
        filteredTaxRates
      });
    case types.taxRate.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return state;
  }
  return state;
}
