<div class="sidebar-user p-2 pr-0"
  *ngIf="opened">
  <div class="d-flex">
    <div class="pl-0">
      <img src="{{(user$ | async).avatar}}"
        alt="{{(user$ | async)['user']?.firstName}}"
        class="avatar avatar-xs" /> &nbsp;{{ (user$ | async)['user']?.firstName }} {{ (user$ | async)['user']?.lastName }}
    </div>
    <div class="text-right pr-0 ml-2"
      id="profileDropdown"
      ngbDropdown
      placement="top-right">
      <button class="btn"
        ngbDropdownToggle></button>
      <div class="dropdown-menu"
        ngbDropdownMenu
        aria-labelledby="profileDropdown">
        <button (click)="logout()"
          class="dropdown-item">
          {{ 'Logout' | translate }}
        </button>
        <button *ngIf=" tenants?.length !==1 "
          (click)="changeTenant() "
          class="dropdown-item ">
          {{ 'Switch company' | translate }}
        </button>
        <a *ngIf="isAdmin "
          class="dropdown-item "
          routerLink="about ">{{ 'About' | translate }}</a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!opened"
  class="closed-menu">
  <div class="m-1 "
    id="profileDropdown"
    ngbDropdown
    placement="top-left ">
    <button class="btn "
      ngbDropdownToggle></button>
    <div class="dropdown-menu"
      ngbDropdownMenu
      aria-labelledby="profileDropdown"
      data-cy="profileDropdown">
      <button (click)="logout() "
        class="dropdown-item "
        data-cy="logoutButton">
        <i class="far fa-power-off "></i>
      </button>
      <button *ngIf="tenants?.length !==1 "
        (click)="changeTenant() "
        class="dropdown-item ">
        <i class="far fa-exchange "></i>
      </button>
    </div>
  </div>
</div>
