import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/marketplace.types';
import * as MarketPostOffersActions from '../actions/market-post-offer.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class MarketPostOffersEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadMarketPostOffers$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.LOAD_MARKETPOSTS_OFFERS),
    concatMap(() => this.api.get({
      path: '/market_post_offers'
    }).pipe(
      map(data => new MarketPostOffersActions.LoadMarketPostOffersSuccess(data)),
      catchError(error => of(new MarketPostOffersActions.LoadMarketPostOffersFail(error)))
    )),
  );

  @Effect()
  createMarketPostOffer$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.CREATE_MARKET_POST_OFFER),
    map((action: MarketPostOffersActions.CreateMarketPostOffer) => action.payload),
    concatMap(payload => this.api.post({
      path: '/market_post_offer_collections', body: payload
    }).pipe(
      map(data => new MarketPostOffersActions.CreateMarketPostOfferSuccess(data)),
      catchError(error => of(new MarketPostOffersActions.CreateMarketPostOfferFail(error)))
    )),
  );

  @Effect()
  replyMarketPostOffer$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.REPLY_MARKET_POST_OFFER),
    map((action: MarketPostOffersActions.ReplyMarketPostOffer) => action.payload),
    concatMap(payload => this.api.post({
      path: `/market_post_offer_replies`,
      body: payload.body
    }).pipe(
      map(data => new MarketPostOffersActions.ReplyMarketPostOfferSuccess(data)),
      catchError(error => of(new MarketPostOffersActions.ReplyMarketPostOfferFail(error)))
    )),
  );

  @Effect()
  publishMarketPostOffer$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.PUBLISH_MARKET_POST_OFFER),
    map((action: MarketPostOffersActions.PublishMarketPostOffer) => action.payload),
    concatMap(payload => this.api.put({
      path: '/market_post_offers/' + payload.id + '/publish',
      body: {}
    }).pipe(
      map(() => new MarketPostOffersActions.PublishMarketPostOfferSuccess(payload)),
      catchError(error => of(new MarketPostOffersActions.PublishMarketPostOfferFail(error)))
    )),
  );

  @Effect()
  unpublishMarketPostOffer$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.UNPUBLISH_MARKET_POST_OFFER),
    map((action: MarketPostOffersActions.UnpublishMarketPostOffer) => action.payload),
    concatMap(payload => this.api.put({
      path: '/market_post_offers/' + payload.id + '/unpublish',
      body: {}
    }).pipe(
      map(() => new MarketPostOffersActions.UnpublishMarketPostOfferSuccess(payload)),
      catchError(error => of(new MarketPostOffersActions.UnpublishMarketPostOfferFail(error)))
    )),
  );

  @Effect()
  updateMarketPostOffer$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.UPDATE_MARKETPOSTS_OFFER),
    map((action: MarketPostOffersActions.UpdateMarketPostOffer) => action.payload),
    concatMap(payload => this.api.put({
      path: '/market_post_offers/' + payload.id,
      body: payload.body
    }).pipe(
      map(data => new MarketPostOffersActions.UpdateMarketPostOfferSuccess(data)),
      catchError(error => of(new MarketPostOffersActions.UpdateMarketPostOfferFail(error)))
    )),
  );
}
