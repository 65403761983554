import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-settings-card',
  templateUrl: './settings-card.component.html',
  styleUrls: ['./settings-card.component.scss']
})
export class SettingsCardComponent {
  @Input()
  title: string;
  @Input()
  route: {
    url: string;
    icon: string;
    title: string;
    description: string;
    createRoute?: {
      url: string;
    };
  };
}
