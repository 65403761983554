/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../contact-form/contact-form.component.ngfactory";
import * as i3 from "../contact-form/contact-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./edit-contact-modal.component";
import * as i6 from "@ngrx/store";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@ngrx/effects";
import * as i9 from "../../services/toast.service";
var styles_EditContactModalComponent = [];
var RenderType_EditContactModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditContactModalComponent, data: {} });
export { RenderType_EditContactModalComponent as RenderType_EditContactModalComponent };
export function View_EditContactModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-contact-form", [], null, [[null, "contactFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contactFormSubmitted" === en)) {
        var pd_0 = (_co.updateContact($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ContactFormComponent_0, i2.RenderType_ContactFormComponent)), i0.ɵdid(4, 573440, null, 0, i3.ContactFormComponent, [i4.FormBuilder], { contact: [0, "contact"], isLoading: [1, "isLoading"] }, { contactFormSubmitted: "contactFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.contact; var currVal_2 = _co.isLoading; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Edit contact")); _ck(_v, 1, 0, currVal_0); }); }
export function View_EditContactModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-contact-modal", [], null, null, null, View_EditContactModalComponent_0, RenderType_EditContactModalComponent)), i0.ɵdid(1, 180224, null, 0, i5.EditContactModalComponent, [i6.Store, i7.NgbActiveModal, i8.Actions, i9.ToastService], null, null)], null, null); }
var EditContactModalComponentNgFactory = i0.ɵccf("app-edit-contact-modal", i5.EditContactModalComponent, View_EditContactModalComponent_Host_0, { contact: "contact" }, {}, []);
export { EditContactModalComponentNgFactory as EditContactModalComponentNgFactory };
