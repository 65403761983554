/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./buy-certificate-form.component.ngfactory";
import * as i3 from "./buy-certificate-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "@ngrx/store";
import * as i6 from "../services/toast.service";
import * as i7 from "../core/user/user.auth";
import * as i8 from "./buy-certificate-modal.component";
import * as i9 from "../core/api/api.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
import * as i11 from "@angular/router";
var styles_BuyCertificateModalComponent = [];
var RenderType_BuyCertificateModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BuyCertificateModalComponent, data: {} });
export { RenderType_BuyCertificateModalComponent as RenderType_BuyCertificateModalComponent };
export function View_BuyCertificateModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-buy-certificate-form", [], null, [[null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubmit" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BuyCertificateFormComponent_0, i2.RenderType_BuyCertificateFormComponent)), i0.ɵdid(4, 245760, null, 0, i3.BuyCertificateFormComponent, [i4.FormBuilder, i5.Store, i6.ToastService, i7.AuthService], { loading: [0, "loading"], data: [1, "data"] }, { onSubmit: "onSubmit" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; var currVal_2 = _co.data; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Buy certificate")); _ck(_v, 1, 0, currVal_0); }); }
export function View_BuyCertificateModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-buy-certificate-modal", [], null, null, null, View_BuyCertificateModalComponent_0, RenderType_BuyCertificateModalComponent)), i0.ɵdid(1, 49152, null, 0, i8.BuyCertificateModalComponent, [i9.ApiService, i10.NgbActiveModal, i11.Router, i6.ToastService], null, null)], null, null); }
var BuyCertificateModalComponentNgFactory = i0.ɵccf("app-buy-certificate-modal", i8.BuyCertificateModalComponent, View_BuyCertificateModalComponent_Host_0, {}, {}, []);
export { BuyCertificateModalComponentNgFactory as BuyCertificateModalComponentNgFactory };
