<div class="grid-container mr-3 mb-2">
  <button class="btn" (click)="goToCreate()">
    {{ 'Add user' | translate }}
  </button>
</div>
<app-user-contexts-table
  [isLoading]="isLoadingUsers"
  [userContexts]="userContexts"
  [totalElements]="totalElements"
></app-user-contexts-table>
