import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { ApiService } from 'app/core/api/api.service';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { ContainerType } from 'app/core/store/models/container-types.model';
import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-container-types',
  templateUrl: './manage-container-types.component.html',
})
export class ManageContainerTypesComponent implements OnDestroy {
  totalItems: number;
  containerTypes: ContainerType[];
  loading = false;

  constructor(
    private store: Store<State>,
    private router: Router,
    private api: ApiService,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadContainerTypes());
    this.loading = true;
    this.store
      .select(state => state.containerTypes)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.containerTypes = data;
          this.totalItems = data.length;
        }
      });
  }

  edit(id) {
    this.router.navigate(['superadmin/containerTypes', id, 'edit']);
  }

  delete(id) {
    this.api.delete({ path: `/container_types/${id}` }).subscribe(
      () => {
        this.toastr.showSuccess({
          message: 'Container deleted!'
        });
      },
      () => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to delete container'
        });
      }
    );
  }

  ngOnDestroy() { }
}
