<span class="text-muted"
  id="message-length">({{ message.length }} / 256)</span>
<div class="input-group"
  id="message-input">
  <input data-cy="messageBoardInput"
    type="text"
    maxlength="256 "
    (keyup.enter)="onSubmit()"
    class="form-control"
    id='message'
    [(ngModel)]="message"
    placeholder="{{ 'Enter your message here' | translate }}">
  <div class="input-group-append">
    <span class="input-group-text"
      (click)="onSubmit()">
      <i class="far fa-comments"
        *ngIf="!loading"></i>
      <i class="far fa-spinner fa-spin"
        *ngIf="loading"></i>
    </span>
  </div>
</div>
<ngb-alert *ngIf="reply"
  [dismissible]="false"
  [type]="'warning'">
  <i class="fa fa-exclamation-triangle"></i>
  <span>{{ 'Everyone can read this message.' | translate }}</span>
</ngb-alert>
