import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import {
  StartSubscription,
  StopSubscription
} from 'app/core/store/actions/subscriptions.actions';
import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-cmp-subscription',
  templateUrl: 'subscription.component.html',
  styleUrls: ['subscription.component.scss']
})
export class SubscriptionComponent {
  @Input()
  subscriptionType;
  @Input()
  subscription;
  @Input()
  credits;
  @Input()
  active;
  @Input()
  isLoading;
  @Input()
  hasEnoughCredits;

  constructor(private store: Store<State>) { }

  stopSubscription() {
    if (!this.subscriptionType || this.subscriptionType.code === 'PSA_TRUCK_VISITS') {
      return;
    }
    // this.isLoading = true;
    this.store.dispatch(new StopSubscription(this.subscriptionType.id));
  }

  startSubscription() {
    if (!this.subscriptionType || this.subscriptionType.code === 'PSA_TRUCK_VISITS') {
      return;
    }
    // this.isLoading = true;
    this.store.dispatch(new StartSubscription(this.subscriptionType.id));
  }

  getSubscriptionDescription() {
    // if (this.subscription && this.subscription.autoRenew) {
    //   return 'Your subscription is active.';
    // }
    switch (this.subscriptionType && this.subscriptionType.code) {
      case 'TMS':
        return extract('Manage your transports and visits in Hakka.');
      case 'CONNECTIONS':
        return extract(
          `Click here to connect Hakka with your TMS. Contact us for more info on support@hakka.eu`
        );
      default:
        return '';
    }
  }
}
