<div
  id="datepicker"
  class="sticky-dates d-flex justify-content-between align-items-center"
>
  <button class="btn" (click)="visibleFrom = false; visibleTo = false; subtractDay()">
    <i class="far fa-fw fa-chevron-left"></i>
  </button>
  &nbsp;
  <span>
    <span class="pointer" (click)="visibleTo = false; visibleFrom = !visibleFrom" data-cy="date">{{ today | date: 'dd MMM yyyy' }}</span>
    <div *ngIf="visibleFrom" class="input-group">
      <ngb-datepicker
        class="dropdown-menu show p-0"
        (select)="onFromDateSelection($event); visibleFrom = false"
        [(ngModel)]="fromDateModel"
      >
      </ngb-datepicker>
    </div>
  </span>
  <span>
    <span *ngIf="toDate">&nbsp;-&nbsp;<span class="pointer" (click)="visibleFrom = false; visibleTo = !visibleTo" data-cy="dateTo">{{ toDate | date: 'dd MMM yyyy' }}</span></span>
    <div *ngIf="visibleTo" class="input-group offset">
      <ngb-datepicker
        class="dropdown-menu show p-0"
        (select)="onToDateSelection($event); visibleTo = false"
        [(ngModel)]="toDateModel"
      >
      </ngb-datepicker>
    </div>
  </span>
  &nbsp;
  <button class="btn" (click)="visibleFrom = false; visibleTo = false; addDay()">
    <i class="far fa-fw fa-chevron-right"></i>
  </button>
</div>
