import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/wallet.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as WalletActions from '../actions/wallet.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class WalletEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions,
  ) { }

  @Effect()
  loadWallet$: Observable<Action> = this.actions$.pipe(
    ofType(types.wallet.LOAD_WALLET),
    concatMap(() => this.api.get({ path: '/me/wallet' }).pipe(
      map(data => new WalletActions.LoadWalletSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  rechargeWallet$: Observable<Action> = this.actions$.pipe(
    ofType(types.wallet.RECHARGE_WALLET),
    map((action: WalletActions.RechargeWallet) => action.payload),
    concatMap(payload => this.api.post({
      path: '/mollie/new_payment',
      body: payload
    }).pipe(
      map(data => new WalletActions.RechargeWalletSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  requestRechargeWallet$: Observable<Action> = this.actions$.pipe(
    ofType(types.wallet.REQUEST_RECHARGE_WALLET),
    map((action: WalletActions.RequestRechargeWallet) => action.payload),
    concatMap(payload => this.api.post({
      path: '/mollie/new_payment',
      body: payload
    }).pipe(
      map(data => new WalletActions.RequestRechargeWalletSuccess(data)),
      catchError(error => of(new WalletActions.RequestRechargeWalletFail(error)))
    )),
  );

  @Effect()
  addCreditsToWallet$: Observable<Action> = this.actions$.pipe(
    ofType(types.wallet.ADD_CREDITS_TO_WALLET),
    map((action: WalletActions.AddCreditsToWallet) => action.payload),
    concatMap(payload => this.api.get({
      path:
        '/admin/wallet/add?amount=' +
        payload.amount +
        '&tenant_id=' +
        payload.id
    }).pipe(
      map(data => new WalletActions.AddCreditsToWalletSuccess(data)),
      catchError(error => of(new WalletActions.AddCreditsToWalletFail(error)))
    )),
  );

  @Effect()
  subtractCreditsToWallet$: Observable<Action> = this.actions$.pipe(
    ofType(types.wallet.SUBTRACT_CREDITS_FROM_WALLET),
    map((action: WalletActions.SubtractCreditsFromWallet) => action.payload),
    concatMap(payload => this.api.get({
      path:
        '/admin/wallet/subtract?amount=' +
        payload.amount +
        '&tenant_id=' +
        payload.id
    }).pipe(
      map(data => new WalletActions.SubtractCreditsFromWalletSuccess(data)),
      catchError(error => of(new WalletActions.SubtractCreditsFromWalletFail(error)))
    )),
  );

  @Effect()
  updateWallet$: Observable<Action> = this.actions$.pipe(
    ofType(types.wallet.UPDATE_WALLET),
    map((action: WalletActions.UpdateWallet) => action.payload),
    concatMap(payload => this.api.put({
      path: '/wallets/' + payload.id, body: payload.body
    }).pipe(
      map(data => new WalletActions.UpdateWalletSuccess(data)),
      catchError(error => of(new WalletActions.UpdateWalletFail(error)))
    )),
  );
}
