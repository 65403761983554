import { Roles } from 'app/core/store/models/roles.model';
import { UserContext } from 'app/core/store/models/user-contexts.model';
import { State } from 'app/core/store/store.model';
import { Store, ActionsSubject } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoadUserContexts } from 'app/core/store/actions/user-contexts.actions';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-tenant-users-list',
  templateUrl: 'tenant-users-list.component.html',
  styleUrls: [
    './tenant-users-list.component.scss'
  ]
})
export class TenantUsersListComponent implements OnInit, OnDestroy {
  ROLES = Roles;
  title = extract('Manage Users');
  userContexts: UserContext[];
  next: string;
  totalElements: Number = 0;
  loading = false;
  isLoadingUsers: boolean;

  constructor(
    private store: Store<State>,
    private actions: ActionsSubject,
    private router: Router
  ) {
    this.actions
      .pipe(filter(action => action.type === '[UserContexts] Disable Success'))
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.loading = false));

    this.actions
      .pipe(filter(action => action.type === '[UserContexts] Enable Success'))
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.loading = false));

    this.store
      .select(state => state.userContexts)
      .pipe(untilDestroyed(this))
      .subscribe(userContextsState => {
        if (userContextsState['data']) {
          this.userContexts = userContextsState['data'];
          this.totalElements =
            userContextsState['totalElements'] || this.userContexts.length;
          this.isLoadingUsers = false;
        }
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadUserContexts({ page: 1 }));
    this.isLoadingUsers = true;
  }

  goToCreate() {
    this.router.navigateByUrl('/settings/users/create', {
      skipLocationChange: true
    });
  }

  ngOnDestroy() { }
}
