import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadSubscriptionTypes } from 'app/core/store/actions/subscriptions.actions';
import { RegistrationRequest } from 'app/core/store/models/registration-request.model';
import { State } from 'app/core/store/store.model';
import { Alert } from 'app/core/store/models/alert.model';
import * as Types from 'app/core/store/types/registration-request.types';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { SubscriptionType } from 'app/core/store/models/subscriptions.model';
import {
  LoadAllRegistrationRequests,
  LoadOneRegistrationRequest,
  UpdateRegistrationRequest,
  ApproveRegistrationRequest,
  DeclineRegistrationRequest,
  LoadOneRegistrationRequestSuccess,
  UpdateRegistrationRequestSuccess
} from 'app/core/store/actions/registraction-request.actions';
import { ToastService } from 'app/services/toast.service';
import { I18nService } from 'app/services/i18n.service';
import {
  ApproveRegistrationRequestModalComponent
} from '../approve-registration-request-modal/approve-registration-request-modal.component';
import {
  DeclineRegistrationRequestModalComponent
} from '../decline-registration-request-modal/decline-registration-request-modal.component';
import { ValidationService } from 'app/services/validation.service';
import { VatVerificationResult } from 'app/core/store/models/vat-verification-result.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-registration-request',
  templateUrl: './edit-registration-request.component.html',
  styleUrls: ['./edit-registration-request.component.scss']
})
export class EditRegistrationRequestComponent implements OnInit, OnDestroy {
  registrationRequest: RegistrationRequest;
  alert: Alert;
  languages: { value: string; label: string }[];
  loading: boolean;
  countries: { value: string; label: string }[];
  subscriptionTypes: SubscriptionType[];
  pendingApproval = false;
  vatNumberIsValid: boolean;
  isVerifyingVatNumber: boolean;
  vatNumberIsVerified = false;
  vatVerificationResult: VatVerificationResult;
  duplicateVatNumberTenant: any;

  constructor(
    private store: Store<State>,
    private editUpdates$: Actions,
    private route: ActivatedRoute,
    private i18nService: I18nService,
    private toastr: ToastService,
    private modalService: NgbModal,
    private validationService: ValidationService
  ) {
    this.store.dispatch(new LoadSubscriptionTypes());
    this.languages = this.i18nService.languages;
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countries => {
        if (!countries) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countries];
      });
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
      const registrationRequestId = params.get('id');
      if (registrationRequestId) {
        this.store
          .select(state => state.registrationRequests)
          .pipe(untilDestroyed(this))
          .subscribe(registrationRequests => {
            if (registrationRequests && registrationRequests['data']) {
              this.registrationRequest = registrationRequests['data'].find(
                (rq: RegistrationRequest) => rq.id === params.get('id')
              );
            }
            if (!this.registrationRequest) {
              this.store.dispatch(
                new LoadOneRegistrationRequest({ id: registrationRequestId })
              );
            }
          });
      }
    });
    this.store
      .select(state => state.subscriptionTypes)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptionTypesState => {
        if (subscriptionTypesState) {
          this.subscriptionTypes = subscriptionTypesState;
        }
      });
  }

  ngOnInit() {
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.LOAD_ONE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((data: LoadOneRegistrationRequestSuccess) => {
        if (data && data.payload) {
          this.registrationRequest = data.payload;
        }
      });
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.LOAD_ONE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Loading registration request failed!'
        });
      });
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.UPDATE_REGISTRATION_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((data: UpdateRegistrationRequestSuccess) => {
        this.toastr.showSuccess({
          message: 'Updated registration request successfully'
        });
        if (this.pendingApproval && this.registrationRequest) {
          this.store.dispatch(
            new ApproveRegistrationRequest(this.registrationRequest.id)
          );
        } else {
          this.store.dispatch(
            new LoadOneRegistrationRequest({ id: data.payload.id })
          );
        }
        this.pendingApproval = false;
      });
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.UPDATE_REGISTRATION_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to update registration request'
        });
        this.pendingApproval = false;
      });
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.APPROVE_REGISTRATION_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Approve registration request successful!'
        });
        if (this.registrationRequest) {
          this.store.dispatch(
            new LoadOneRegistrationRequest({ id: this.registrationRequest.id })
          );
        }
        this.store.dispatch(new LoadAllRegistrationRequests());
      });
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.APPROVE_REGISTRATION_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Approve registration request failed!'
        });
      });
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.DECLINE_REGISTRATION_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Decline registration request successful!'
        });
        if (this.registrationRequest) {
          this.store.dispatch(
            new LoadOneRegistrationRequest({ id: this.registrationRequest.id })
          );
        }
        this.store.dispatch(new LoadAllRegistrationRequests());
      });
    this.editUpdates$
      .pipe(ofType(Types.registrationRequests.DECLINE_REGISTRATION_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Decline registration request failed!'
        });
      });
  }

  closeAlert() {
    this.alert = null;
  }

  saveAndApprove(registrationRequestFormValue: any) {
    this.modalService
      .open(ApproveRegistrationRequestModalComponent)
      .result.then(result => {
        if (result) {
          this.store.dispatch(
            new UpdateRegistrationRequest({
              id: this.registrationRequest.id,
              body: registrationRequestFormValue
            })
          );
          this.pendingApproval = true;
        }
      });
  }

  decline() {
    this.modalService
      .open(DeclineRegistrationRequestModalComponent)
      .result.then(result => {
        if (result && this.registrationRequest) {
          this.store.dispatch(
            new DeclineRegistrationRequest(this.registrationRequest.id)
          );
        }
      });
  }

  save(registrationRequestFormValue: any) {
    this.store.dispatch(
      new UpdateRegistrationRequest({
        id: this.registrationRequest.id,
        body: registrationRequestFormValue
      })
    );
  }
  verifyVatNumber() {
    this.isVerifyingVatNumber = true;
    this.validationService
      .verifyVatNumberWithVIES(
        this.registrationRequest.vatNumber,
        this.registrationRequest.country
      )
      .subscribe(
        (result: VatVerificationResult) => {
          this.vatNumberIsValid = result.valid === 'true';
          this.vatVerificationResult = result;
        },
        err => {
          console.log(err);
        },
        () => {
          this.isVerifyingVatNumber = false;
          this.vatNumberIsVerified = true;
        }
      );
    this.validationService
      .checkIfVatNumberExists(
        this.registrationRequest.vatNumber
      ).subscribe(
        (result: any) => {
          if (result['hydra:totalItems'] !== 0) {
            const tenant: any = result['hydra:member'][0];
            this.vatNumberIsValid = false;
            this.duplicateVatNumberTenant = tenant;
          } else {
            this.duplicateVatNumberTenant = null;
          }
        },
        err => {
          console.log(err);
        },
        () => {
          this.isVerifyingVatNumber = false;
          this.vatNumberIsVerified = true;
        }
      );
  }

  ngOnDestroy() { }
}
