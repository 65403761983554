export interface LocationType {
  '@id': string;
  '@type': string;
  label: string;
  id: string;
  code: string;
  deleted: boolean;
}

export enum LocationTypes {
  USER = 'USER',
  CITY = 'CITY',
  OTHER = 'OTHER',
  DEPOT = 'DEPOT',
  TERMINAL = 'TERMINAL',
  TRASH = 'TRASH',
  WEIGHT = 'WEIGHT'
}
