import { Router, NavigationEnd } from '@angular/router';
import { LoadTenants } from '../tenant/tenants.actions';
import { Component, Input, OnDestroy } from '@angular/core';
import { User } from '../store/models/user.model';
import { Store } from '@ngrx/store';
import { State } from '../store/store.model';
import { Route } from '../route.service';
import { LoadUser } from '../store/actions/user.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent implements OnDestroy {
  @Input()
  title = 'Title';
  @Input()
  breadCrumbs;

  user: User;
  tenant: any;
  tenantId: string;

  constructor(
    private store: Store<State>,
    private router: Router,
    private routeService: Route
  ) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        let url = e.url;
        url = url.split('?')[0]; // Remove query params if present
        url = url.split(';')[0]; // Remove optional params if present
        this.breadCrumbs = url.split('/');
        this.breadCrumbs.splice(0, 1);
        if (
          this.breadCrumbs[this.breadCrumbs.length - 1] === 'detail' ||
          this.breadCrumbs[this.breadCrumbs.length - 1] === 'edit' ||
          this.breadCrumbs[this.breadCrumbs.length - 1] === 'reply'
        ) {
          this.breadCrumbs.splice(this.breadCrumbs.length - 2, 1); // Remove the uuid from breadcrumbs
        }
      }
    });
    this.store
      .select(state => state.session)
      .pipe(untilDestroyed(this))
      .subscribe(session => {
        if (!session) {
          return;
        }
        this.tenantId = session.tenant;
        if (!this.tenantId) {
          return;
        }
        this.store
          .select(state => state.tenants.userTenants)
          .pipe(untilDestroyed(this))
          .subscribe(state => {
            if (!state) {
              this.store.dispatch(new LoadTenants());
              return;
            }
            this.tenant = state.find(t => t.id === this.tenantId);
          });
      });
    this.store
      .select(state => state.user.user)
      .pipe(untilDestroyed(this))
      .subscribe(userState => {
        if (!userState || !userState['@id']) {
          this.store.dispatch(new LoadUser());
          return;
        }
        this.user = userState;
      });
  }

  getRouteName(url) {
    return this.routeService.getRouteNameFromUrl(url);
  }
  getRouteUrl(breadCrumbIndex) {
    let url = '/';
    for (let index = 0; index <= breadCrumbIndex; index++) {
      url = `${url}${this.breadCrumbs[index]}/`;
    }
    return url;
  }

  ngOnDestroy() { }
}
