import { Component } from '@angular/core';
import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-cmp-reload-reporting',
  templateUrl: 'reload-reporting.component.html'
})
export class ReloadReportingComponent {
  title = extract('Reporting');

  constructor() {}
}
