import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import {
  UpdateUserContext,
  DeleteUserContext,
  EnableUserContext,
  DisableUserContext,
  LoadUserContexts
} from 'app/core/store/actions/user-contexts.actions';
import { Store } from '@ngrx/store';
import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Types from 'app/core/store/types/user-context.types';
import * as UserTypes from 'app/core/store/types/user.types';
// models
import { State } from 'app/core/store/store.model';
import { Role, Roles } from 'app/core/store/models/roles.model';
import { UserContext } from 'app/core/store/models/user-contexts.model';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-edit-user-context',
  templateUrl: 'edit-user-context.component.html'
})
export class EditUserContextComponent implements OnDestroy {
  @Input()
  driver = false;

  ROLES = Roles;
  userContexts: UserContext[];
  navbarTitle = 'Edit User';
  buttonType: string;
  buttonText: string;
  roles: Role[];
  userContext: UserContext;
  userContextId: string;
  userId: string;
  loading: boolean;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private router: Router,
    private updates$: Actions,
    private location: Location,
    private toastr: ToastService,
    private modalService: NgbModal
  ) {
    this.userContextId = this.route.snapshot.paramMap.get('id');
    this.store
      .select(state => state.userContexts)
      .pipe(untilDestroyed(this))
      .subscribe(userContexts => {
        if (userContexts) {
          this.userContexts = userContexts.data;
          if (userContexts.page && !this.userContext) {
            this.store.dispatch(
              new LoadUserContexts({ page: userContexts.page })
            );
          } else if (!this.userContext) {
            this.store.dispatch(new LoadUserContexts({ page: 1 }));
          }
          if (userContexts.data) {
            this.userContext = userContexts.data.find(
              user => user.id === this.userContextId
            );
            if (this.userContext) {
              this.userId = this.userContext.user.id;
              this.driver =
                this.userContext.roles.filter(
                  role => role.name === 'Driver'
                ).length !== 0;
              if (this.userContext.enabled) {
                this.buttonText = 'disable';
                this.buttonType = 'btn-warning';
              } else {
                this.buttonText = 'enable';
                this.buttonType = 'btn-success';
              }
            }
          }
        }
      });
    this.store
      .select(state => state.roles)
      .pipe(untilDestroyed(this))
      .subscribe(roles => {
        if (roles) {
          this.roles = roles;
        }
      });

    updates$
      .pipe(ofType(Types.userContexts.UPDATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'User successfully updated'
        });
        if (this.userContexts && this.userContexts['page']) {
          this.store.dispatch(
            new LoadUserContexts({ page: this.userContexts['page'] })
          );
        } else {
          this.store.dispatch(new LoadUserContexts({ page: 1 }));
        }
        this.isLoading = false;
        this.router.navigateByUrl('/settings/users');
      });

    updates$
      .pipe(ofType(Types.userContexts.DELETE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'User successfully deleted'
        });
        this.loading = false;
        this.location.back();
      });

    updates$
      .pipe(ofType(Types.userContexts.DELETE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to delete user'
        });
        this.loading = false;
      });

    updates$
      .pipe(ofType(
        UserTypes.users.UPDATE_USER_FAILED,
        Types.userContexts.UPDATE_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to update user'
        });
        this.loading = false;
        this.isLoading = false;
      });
  }

  /**
   * update the userContext of the selected user
   * @param userContextFormValue userContext object
   */
  updateUserContext(userContextFormValue) {
    this.isLoading = true;
    const body = {
      roles: [],
      phoneNumber: userContextFormValue.phoneNumber,
      locale: userContextFormValue.locale,
      user: {
        id: this.userId,
        firstName: userContextFormValue.firstName,
        lastName: userContextFormValue.lastName,
        email: userContextFormValue.email
      }
    };

    if (this.driver) {
      body['licensePlate'] = userContextFormValue.licensePlate;
    }
    if (userContextFormValue.isAdmin) {
      body.roles.push(
        this.roles.find(role => role.code === this.ROLES.ADMIN)['@id']
      );
    }

    if (userContextFormValue.isPlanner) {
      body.roles.push(
        this.roles.find(role => role.code === this.ROLES.PLANNER)['@id']
      );
    }
    if (userContextFormValue.isDriver) {
      body.roles.push(
        this.roles.find(role => role.code === this.ROLES.DRIVER)['@id']
      );
    }

    if (userContextFormValue.isBetaUser) {
      body.roles.push(
        this.roles.find(role => role.code === this.ROLES.BETA_USER)['@id']
      );
    }
    this.store.dispatch(
      new UpdateUserContext({ id: this.userContextId, body: body })
    );
  }

  deleteUser() {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this user?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteUserContext(this.userContextId));
    });
  }

  enableUser() {
    this.store.dispatch(new EnableUserContext(this.userContextId));
  }

  disableUser() {
    this.store.dispatch(new DisableUserContext(this.userContextId));
  }

  ngOnDestroy() { }
}
