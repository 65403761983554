import { catchError, concatMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/attachment.types';
import * as AttachmentActions from '../actions/attachment.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class AttachmentEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  updateAttachment$: Observable<Action> = this.actions$.pipe(
    ofType(types.attachment.UPDATE_ATTACHMENT),
    map((action: AttachmentActions.UpdateAttachment) => action.payload),
    concatMap(payload => this.api.put({
      path: `/attachments/${payload.attachmentId}`,
      body: payload.body
    }).pipe(
      map(data => new AttachmentActions.UpdateAttachmentSuccess(data)),
      catchError(error => of(new AttachmentActions.UpdateAttachmentFail(error)))
    )),
  );

  @Effect()
  deleteAttachment$: Observable<Action> = this.actions$.pipe(
    ofType(types.attachment.DELETE_ATTACHMENT),
    map((action: AttachmentActions.DeleteAttachment) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/attachments/${payload.attachmentId}`
    }).pipe(
      map(() => new AttachmentActions.DeleteAttachmentSuccess(payload)),
      catchError(error => of(new AttachmentActions.DeleteAttachmentFail(error)))
    )),
  );
}
