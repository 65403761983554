import * as types from '../types/circle.types';
import * as CirclesActions from '../actions/circles.actions';
import { Circle } from '../models/circles.model';

const INITIAL_STATE = null;

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.
export function reducer(
  state = INITIAL_STATE,
  action: CirclesActions.All
): Circle[] {
  switch (action.type) {
    case types.circles.LOAD_SUCCEEDED:
      return [...action.payload['hydra:member']];
    case types.circles.DELETE_SUCCEEDED:
      return state;
    case types.circles.CREATE_SUCCEEDED:
      return [...state, action.payload];
    case types.circles.CLEAR:
      return null;
  }
  return state;
}
