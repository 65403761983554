<div class="card-header">
  {{ 'Account actions for' | translate }} {{ account.name }}
</div>
<div class="card-body">
  <ngx-datatable
    class="bootstrap ml-0 mr-0"
    [rows]="actions"
    [loadingIndicator]="loading"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [selectionType]="undefined"
    [sorts]="[{prop: 'createdAt', dir: 'desc'}]"
  >
    <ngx-datatable-column name="Action code" prop="code"></ngx-datatable-column>
    <ngx-datatable-column name="API result" prop="result"></ngx-datatable-column>
    <ngx-datatable-column name="Created at" prop="createdAt"></ngx-datatable-column>
  </ngx-datatable>
</div>
