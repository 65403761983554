<div class="psa-container card" *ngIf="!(checkPsaAccount$ | async)">
  <h3 class="card-header">{{ 'PSA ePortal setup' | translate }}</h3>
  <div class="card-body container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-4 mb-4">
        <h3>{{ 'Steps to complete' | translate }}</h3>
        <ul class="psa-checklist">
          <li>
            <i class="fas fa-fw fa-check"></i>
            {{ 'Create an account' | translate }}
          </li>
          <li>
            <i class="fas fa-fw {{ (checkPsaAccount$ | async) ? 'fa-check' : 'fa-times' }}"></i>
            {{ 'Link your PSA ePortal account' | translate }}
          </li>
          <li>
            <i class="fas fa-fw {{ (checkCredits$ | async) ? 'fa-check' : 'fa-times' }}"></i>
            {{ 'Buy credits' | translate }}
          </li>
        </ul>
      </div>

      <div class="col-sm-12 col-md-4 mb-4">
        <div *ngIf="!(checkPsaAccount$ | async)">
          <h3>{{ 'Link your PSA ePortal account' | translate }}</h3>
          <ng-select
            [items]="companies$ | async"
            bindLabel="id"
            [(ngModel)]="selectedCompany">
          </ng-select>
          <button class="btn btn-primary mt-2 text-white"
            (click)="linkAccount()" [disabled]="!selectedCompany">
            {{ 'Save' | translate }}
          </button>
        </div>
      </div>

      <div class="col-sm-12 col-md-4 mb-4">
        <img class="img-fluid"
          style="cursor:pointer"
          (click)="enlargeScreenshot(screenshot)"
          src="/assets/img/eportalscreenshot.png"
          alt="PSA ePortal screenshot" />
      </div>

    </div>
  </div>
</div>

<ng-template #screenshot let-d="dismiss">
  <div class="text-center" style="cursor:pointer" (click)="d()">
    <img class="img-fluid"
      src="/assets/img/eportalscreenshot.png"
      alt="PSA ePortal screenshot" />
  </div>
</ng-template>
