import { LoadContacts } from '../store/actions/contacts.actions';
import { Contact } from './contact.model';
import { State } from '../store/store.model';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

/**
 * The contacts component, used for looking up other planners from different
 * companies.
 *
 * @export
 * @class ContactsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-cmp-contacts',
  templateUrl: 'contacts.component.html',
  styleUrls: ['contacts.component.scss']
})
export class ContactsComponent implements OnDestroy {
  title = extract('Contacts');
  plannersMapping: { [k: string]: string } = {
    '=1': 'Planner',
    other: 'Planners'
  };
  addressBookEntries: Contact[];
  filteredAddressBookEntries: Contact[];
  query: string;
  loading = true;

  constructor(private store: Store<State>) {
    this.store.dispatch(new LoadContacts());
    this.store.select(state => state.contacts)
      .pipe(untilDestroyed(this))
      .subscribe(contacts => {
        if (contacts) {
          this.addressBookEntries = contacts;
          this.filteredAddressBookEntries = this.addressBookEntries;
          this.loading = false;
        }
      });
  }

  filter($event) {
    this.filteredAddressBookEntries = (this.addressBookEntries || []).filter(tenant => {
      $event = $event.toLowerCase();
      return (
        (tenant.name && tenant.name.toLowerCase().indexOf($event) !== -1) ||
        (tenant.address &&
          tenant.address.toLowerCase().indexOf($event) !== -1) ||
        (tenant.phone_number &&
          tenant.phone_number.toLowerCase().indexOf($event) !== -1) ||
        (tenant.email && tenant.email.toLowerCase().indexOf($event) !== -1)
      );
    });
  }

  mailtoClicked($event) {
    $event.preventDefault();
  }

  ngOnDestroy() { }
}
