/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../credit-note-form/credit-note-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../shared/datepicker-config";
import * as i4 from "../credit-note-form/credit-note-form.component";
import * as i5 from "@angular/router";
import * as i6 from "../../core/api/api.service";
import * as i7 from "@angular/forms";
import * as i8 from "./edit-credit-note.component";
import * as i9 from "@ngrx/effects";
import * as i10 from "@ngrx/store";
import * as i11 from "../../services/download.service";
import * as i12 from "../../services/toast.service";
var styles_EditCreditNoteComponent = [];
var RenderType_EditCreditNoteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditCreditNoteComponent, data: {} });
export { RenderType_EditCreditNoteComponent as RenderType_EditCreditNoteComponent };
export function View_EditCreditNoteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-credit-note-form", [], null, [[null, "creditNoteFormSubmitted"], [null, "onSaveAndDonloadAsPDF"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("creditNoteFormSubmitted" === en)) {
        var pd_0 = (_co.updateCreditNote($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSaveAndDonloadAsPDF" === en)) {
        var pd_1 = (_co.updateAndDownloadCreditNote($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CreditNoteFormComponent_0, i1.RenderType_CreditNoteFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i2.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 573440, null, 0, i4.CreditNoteFormComponent, [i5.ActivatedRoute, i6.ApiService, i7.FormBuilder], { customers: [0, "customers"], isLoadingCustomers: [1, "isLoadingCustomers"], isLoading: [2, "isLoading"], taxRates: [3, "taxRates"], isLoadingTaxRates: [4, "isLoadingTaxRates"] }, { creditNoteFormSubmitted: "creditNoteFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customers; var currVal_1 = _co.isLoadingCustomers; var currVal_2 = _co.isLoading; var currVal_3 = _co.taxRates; var currVal_4 = _co.isLoadingTaxRates; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_EditCreditNoteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-credit-note", [], null, null, null, View_EditCreditNoteComponent_0, RenderType_EditCreditNoteComponent)), i0.ɵdid(1, 180224, null, 0, i8.EditCreditNoteComponent, [i5.ActivatedRoute, i5.Router, i9.Actions, i10.Store, i11.DownloadService, i12.ToastService], null, null)], null, null); }
var EditCreditNoteComponentNgFactory = i0.ɵccf("app-edit-credit-note", i8.EditCreditNoteComponent, View_EditCreditNoteComponent_Host_0, {}, {}, []);
export { EditCreditNoteComponentNgFactory as EditCreditNoteComponentNgFactory };
