var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/tax-rate.types';
import * as TaxRateActions from '../actions/tax-rate.actions';
import { ApiService } from '../../api/api.service';
var TaxRateEffects = /** @class */ (function () {
    function TaxRateEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadTaxRates$ = this.actions$.pipe(ofType(types.taxRate.LOAD_TAX_RATES), concatMap(function () { return _this.api.get({ path: "/crm/tax_rates" }).pipe(map(function (data) { return new TaxRateActions.LoadTaxRatesSuccess(data); }), catchError(function (error) { return of(new TaxRateActions.LoadTaxRatesFail(error)); })); }));
        this.loadFilteredTaxRates$ = this.actions$.pipe(ofType(types.taxRate.LOAD_FILTERED_TAX_RATES), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            return _this.api.get({ path: "/crm/tax_rates?rate=" + query + "&label=" + query }).pipe(map(function (data) { return new TaxRateActions.LoadFilteredTaxRatesSuccess(data); }), catchError(function (error) { return of(new TaxRateActions.LoadFilteredTaxRatesFail(error)); }));
        }));
        this.createTaxRate$ = this.actions$.pipe(ofType(types.taxRate.CREATE_TAX_RATE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({ path: "/crm/tax_rates", body: payload }).pipe(map(function (data) { return new TaxRateActions.CreateTaxRateSuccess(data); }), catchError(function (error) { return of(new TaxRateActions.CreateTaxRateFail(error)); })); }));
        this.deleteTaxRate$ = this.actions$.pipe(ofType(types.taxRate.DELETE_TAX_RATE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/tax_rates/" + payload.taxRateId
        }).pipe(map(function () { return new TaxRateActions.DeleteTaxRateSuccess(payload.taxRateId); }), catchError(function (error) { return of(new TaxRateActions.DeleteTaxRateFail(error)); })); }));
        this.updateTaxRate$ = this.actions$.pipe(ofType(types.taxRate.UPDATE_TAX_RATE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/tax_rates/" + payload.taxRateId,
            body: payload.body
        }).pipe(map(function (data) { return new TaxRateActions.UpdateTaxRateSuccess(data); }), catchError(function (error) { return of(new TaxRateActions.UpdateTaxRateFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TaxRateEffects.prototype, "loadTaxRates$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TaxRateEffects.prototype, "loadFilteredTaxRates$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TaxRateEffects.prototype, "createTaxRate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TaxRateEffects.prototype, "deleteTaxRate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TaxRateEffects.prototype, "updateTaxRate$", void 0);
    return TaxRateEffects;
}());
export { TaxRateEffects };
