<form
  *ngIf="form"
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="p-3 grid-container"
>
  <div class="form-group" id="name-fg">
    <input
      class="form-control"
      formControlName="label"
      type="text"
      placeholder="{{ 'Name' | translate }}"
      data-cy="label"
      [ngClass]="{'is-invalid': form.controls['label'].invalid && form.controls['label'].touched}"
    />
    <div class="invalid-feedback">
      {{ 'Company name is required.' | translate }}
    </div>
  </div>
  <div id="submit">
    <app-button [type]="'submit'" id="submit-button" [isLoading]="isLoading">
      <span
        data-cy="submitCustomerButton"
        class="text-white"
        *ngIf="!preannouncement; else edit"
        >{{ 'Create' | translate }}</span
      >
      <ng-template #edit>
        <span class="text-white">{{ 'Update' | translate }}</span>
      </ng-template>
    </app-button>
  </div>
</form>
