import { LoadCircles } from 'app/core/store/actions/circles.actions';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Circle } from 'app/core/store/models/circles.model';
import { Observable } from 'rxjs';
import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-share-post',
  templateUrl: 'share-post.component.html',
  styleUrls: ['share-post.component.scss', '../new-marketpost.component.scss']
})
export class SharePostComponent implements OnInit, OnDestroy {
  circles$: Observable<Circle[]>;
  selectedCircles: Circle[] = [];
  shareType = 'public';
  @Output()
  selectCircle = new EventEmitter<any>();
  @Input()
  loading;
  @Input()
  transportsListEmpty = true;

  constructor(private store: Store<State>) {
    store.dispatch(new LoadCircles());
    this.circles$ = this.store
      .select(state => state.circles)
      .pipe(untilDestroyed(this));
  }

  ngOnInit() {
    this.circles$.subscribe(state => {
      if (state) {
        const circles = state.map(circle => ({
          name: circle.name,
          id: circle.id,
          checked: false
        }));
      }
    });
  }

  onSubmit() {
    if (this.selectedCircles) {
      const body = {
        circles: this.selectedCircles.map(c => c['@id']),
        shareType: this.shareType
      };
      this.selectCircle.emit(body);
    } else {
      this.selectCircle.emit({ circles: [], shareType: '' });
    }
  }

  ngOnDestroy() { }
}
