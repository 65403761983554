import { LoadProducts, CreateProduct } from 'app/core/store/actions/product.actions';
import { Component, OnDestroy } from '@angular/core';
import * as ProductTypes from 'app/core/store/types/product.types';
import { Product } from 'app/core/store/models/product.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-tariff',
  templateUrl: './new-tariff.component.html',
  styleUrls: ['./new-tariff.component.scss']
})
export class NewTariffComponent implements OnDestroy {
  isLoading: boolean;

  constructor(
    private router: Router,
    private store: Store<State>,
    private toastr: ToastService,
    private updates$: Actions
  ) {
    this.updates$
      .pipe(ofType(ProductTypes.product.CREATE_PRODUCT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to create tariff')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(ProductTypes.product.CREATE_PRODUCT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Tariff updated successfully!')
        });
        this.store.dispatch(new LoadProducts());
        this.router.navigateByUrl('/crm/tariffs');
        this.isLoading = false;
      });
  }

  createTariff(tariffFormValue) {
    const tariff = Object.assign({}, tariffFormValue, {
      template: true
    }) as Product;
    this.isLoading = true;
    this.store.dispatch(new CreateProduct(tariff));
  }

  ngOnDestroy() { }
}
