<div class="card">
  <div class="card-header d-flex justify-content-between">
    <h3>{{ 'Message board messages' | translate }}</h3>
    <span>
      <app-message-board-messages-subscription-toggle [messageBoardMessageSubscription]="messageBoardMessageSubscription"></app-message-board-messages-subscription-toggle>
    </span>
  </div>
  <div class="card-body" *ngIf="messageBoardMessageSubscription; else noSub">
    {{ 'You are subscribed to Message board messages. You will recieve email notifications each time a user posts a new message.'
    | translate }}
  </div>
  <ng-template #noSub>
    <div class="card-body">
      {{ 'You are not subscribed to Message board messages. You will not recieve email notifications each time a user posts a new
      message.' | translate }}
    </div>
  </ng-template>
</div>
