import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { Logout, PickTenant } from 'app/core/store/actions/session.actions';
import { LoadTenants } from 'app/core/store/actions/tenants.actions';
import { Tenant } from 'app/core/store/models/tenant.model';
import { State } from 'app/core/store/store.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-tenant-picker',
  templateUrl: './tenant-picker.component.html'
})
export class TenantPickerComponent implements OnDestroy {
  loading = true;
  tenants: Tenant[];

  @Input() hideTitle;

  constructor(private store: Store<State>) {
    this.store.dispatch(new LoadTenants());
    this.store
      .select('tenants', 'userTenants')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.tenants = state;
          this.loading = false;
        }
      });
  }

  pickTenant(event: Number) {
    this.store.dispatch(new PickTenant(event));
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  ngOnDestroy() { }
}
