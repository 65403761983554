export const customer = {
  LOAD_CUSTOMERS: '[Customers] Load',
  LOAD_CUSTOMERS_SUCCEEDED: '[Customers] Load Success',
  LOAD_CUSTOMERS_FAILED: '[Customers] Load Fail',
  LOAD_FILTERED_CUSTOMERS: '[Customers] Load filtered',
  LOAD_FILTERED_CUSTOMERS_SUCCEEDED: '[Customers] Load filtered Success',
  LOAD_FILTERED_CUSTOMERS_FAILED: '[Customers] Load filtered Fail',
  CREATE_CUSTOMER: '[Customer] Create',
  CREATE_CUSTOMER_SUCCEEDED: '[Customer] Create Success',
  CREATE_CUSTOMER_FAILED: '[Customer] Create Fail',
  DELETE_CUSTOMER: '[Customer] Delete',
  DELETE_CUSTOMER_SUCCEEDED: '[Customer] Delete Success',
  DELETE_CUSTOMER_FAILED: '[Customer] Delete Fail',
  UPDATE_CUSTOMER: '[Customer] Update',
  UPDATE_CUSTOMER_SUCCEEDED: '[Customer] Update Success',
  UPDATE_CUSTOMER_FAILED: '[Customer] Update Fail',
  CLEAR: '[Customers] Clear'
};
