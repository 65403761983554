/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../user-context-form/user-context-form.component.ngfactory";
import * as i3 from "../user-context-form/user-context-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../../../services/i18n.service";
import * as i7 from "@angular/common";
import * as i8 from "./edit-user-context.component";
import * as i9 from "@angular/router";
import * as i10 from "@ngrx/effects";
import * as i11 from "../../../../../services/toast.service";
import * as i12 from "@ng-bootstrap/ng-bootstrap";
var styles_EditUserContextComponent = [];
var RenderType_EditUserContextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditUserContextComponent, data: {} });
export { RenderType_EditUserContextComponent as RenderType_EditUserContextComponent };
function View_EditUserContextComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-warning"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.disableUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Disable")); _ck(_v, 1, 0, currVal_0); }); }
function View_EditUserContextComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enableUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Enable")); _ck(_v, 1, 0, currVal_0); }); }
export function View_EditUserContextComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "app-cmp-user-context-form", [], null, [[null, "userContextFormValue"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userContextFormValue" === en)) {
        var pd_0 = (_co.updateUserContext($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UserContextFormComponent_0, i2.RenderType_UserContextFormComponent)), i0.ɵdid(2, 770048, null, 0, i3.UserContextFormComponent, [i4.FormBuilder, i5.Store, i6.I18nService], { userContext: [0, "userContext"], buttonText: [1, "buttonText"], loading: [2, "loading"], driver: [3, "driver"] }, { userContextFormValue: "userContextFormValue" }), (_l()(), i0.ɵted(-1, 0, [" \u00A0 "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EditUserContextComponent_1)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_EditUserContextComponent_2)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, 0, 2, "button", [["class", "btn btn-link text-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userContext; var currVal_1 = "Save changes"; var currVal_2 = _co.isLoading; var currVal_3 = _co.driver; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.userContext && _co.userContext.enabled); _ck(_v, 5, 0, currVal_4); var currVal_5 = (_co.userContext && !_co.userContext.enabled); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_6 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("Delete")); _ck(_v, 9, 0, currVal_6); }); }
export function View_EditUserContextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-edit-user-context", [], null, null, null, View_EditUserContextComponent_0, RenderType_EditUserContextComponent)), i0.ɵdid(1, 180224, null, 0, i8.EditUserContextComponent, [i9.ActivatedRoute, i5.Store, i9.Router, i10.Actions, i7.Location, i11.ToastService, i12.NgbModal], null, null)], null, null); }
var EditUserContextComponentNgFactory = i0.ɵccf("app-cmp-edit-user-context", i8.EditUserContextComponent, View_EditUserContextComponent_Host_0, { driver: "driver" }, {}, []);
export { EditUserContextComponentNgFactory as EditUserContextComponentNgFactory };
