var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/purchase-order.types';
import * as PurchaseOrderActions from '../actions/purchase-order.actions';
import { ApiService } from '../../api/api.service';
var PurchaseOrderEffects = /** @class */ (function () {
    function PurchaseOrderEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadPurchaseOrders$ = this.actions$.pipe(ofType(types.purchaseOrder.LOAD_PURCHASE_ORDERS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var page = payload && payload.page ? payload.page : 1;
            return _this.api.get({ path: "/crm/purchase_orders?page=" + page }).pipe(map(function (data) { return new PurchaseOrderActions.LoadPurchaseOrdersSuccess(data); }), catchError(function (error) { return of(new PurchaseOrderActions.LoadPurchaseOrdersFail(error)); }));
        }));
        this.loadFilteredPurchaseOrders$ = this.actions$.pipe(ofType(types.purchaseOrder.LOAD_FILTERED_PURCHASE_ORDERS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            var page = payload && payload.page ? payload.page : 1;
            return _this.api.get({
                path: "/crm/purchase_orders?page=" + page + "&business.label=" + query + "&number=" + query + "&notes=" + query
            }).pipe(map(function (data) { return new PurchaseOrderActions.LoadFilteredPurchaseOrdersSuccess(data); }), catchError(function (error) { return of(new PurchaseOrderActions.LoadFilteredPurchaseOrdersFail(error)); }));
        }));
        this.createPurchaseOrder$ = this.actions$.pipe(ofType(types.purchaseOrder.CREATE_PURCHASE_ORDER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/crm/purchase_orders", body: payload
        }); }), map(function (data) { return new PurchaseOrderActions.CreatePurchaseOrderSuccess(data); }), catchError(function (error) { return of(new PurchaseOrderActions.CreatePurchaseOrderFail(error)); }));
        this.updatePurchaseOrder$ = this.actions$.pipe(ofType(types.purchaseOrder.UPDATE_PURCHASE_ORDER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/purchase_orders/" + payload.purchaseOrderId,
            body: payload.body
        }).pipe(map(function (data) { return new PurchaseOrderActions.UpdatePurchaseOrderSuccess(data); }), catchError(function (error) { return of(new PurchaseOrderActions.UpdatePurchaseOrderFail(error)); })); }));
        this.deletePurchaseOrder$ = this.actions$.pipe(ofType(types.purchaseOrder.DELETE_PURCHASE_ORDER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/purchase_orders/" + payload.purchaseOrderId
        }).pipe(map(function () { return new PurchaseOrderActions.DeletePurchaseOrderSuccess(payload.purchaseOrderId); }), catchError(function (error) { return of(new PurchaseOrderActions.DeletePurchaseOrderFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PurchaseOrderEffects.prototype, "loadPurchaseOrders$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PurchaseOrderEffects.prototype, "loadFilteredPurchaseOrders$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PurchaseOrderEffects.prototype, "createPurchaseOrder$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PurchaseOrderEffects.prototype, "updatePurchaseOrder$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PurchaseOrderEffects.prototype, "deletePurchaseOrder$", void 0);
    return PurchaseOrderEffects;
}());
export { PurchaseOrderEffects };
