import { ManageSubscriptionTypesComponent } from './manage-subscription-types/manage-subscription-types.component';
import { ManageBetaUsersComponent } from './manage-beta-users/manage-beta-users.component';
import { EditLinerComponent } from './edit-liner/edit-liner.component';
import { NewLinerComponent } from './new-liner/new-liner.component';
import { ManageContainerTypesComponent } from './manage-container-types/manage-container-types.component';
import { NewLocationComponent } from './new-location/new-location.component';
import { ManageLocationsComponent } from './manage-locations/manage-locations.component';
import { ManageWalletsComponent } from './manage-wallets/manage-wallets.component';
import { EditTenantComponent } from './edit-tenant/edit-tenant.component';
import { ManageTenantsComponent } from './manage-tenants/manage-tenants.component';
import { ManageRegistrationRequestsComponent } from './manage-registration-requests/manage-registration-requests.component';
import { EditRegistrationRequestComponent } from './edit-registration-request/edit-registration-request.component';
import { NewTenantComponent } from './new-tenant/new-tenant.component';
import { AuthGuard } from '../user/user.authguard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { NewContainerTypeComponent } from './new-container-type/new-container-type.component';
import { EditContainerTypeComponent } from './edit-container-type/edit-container-type.component';
import { ManageLinersComponent } from './manage-liners/manage-liners.component';
import { NewSubscriptionTypeComponent } from './new-subscription-type/new-subscription-type.component';
import { EditSubscriptionTypeComponent } from './edit-subscription-type/edit-subscription-type.component';
import { NewPsaAccountComponent } from './manage-psa-accounts/new-psa-account.component';
import { ManagePsaAccountsComponent } from './manage-psa-accounts/psa-accounts.component';
import { ManageShippingAgentsComponent } from './manage-shipping-agents/manage-shipping-agents.component';
import { NewShippingAgentComponent } from './new-shipping-agent/new-shipping-agent.component';
import { EditShippingAgentComponent } from './edit-shipping-agent/edit-shipping-agent.component';
import { ManageCertiweightMeasurementsComponent } from './certiweight/manage-measurements.component';
import { NewCertiweightMeasurementComponent } from './certiweight/new-measurement.component';
import { EditCertiweightMeasurementComponent } from './certiweight/edit-measurement.component';
import { Route } from '../route.service';
import { SuperAdminGuard } from '../auth/route-guards/super-admin.guard';

const defaultSuperAdminGuards = [AuthGuard, SuperAdminGuard];
const routes: Routes = Route.withShell([
  {
    path: 'superadmin/tenants',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManageTenantsComponent },
      { path: 'create', component: NewTenantComponent },
      { path: ':id/edit', component: EditTenantComponent },
    ]
  },
  {
    path: 'superadmin/registration-requests',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManageRegistrationRequestsComponent },
      { path: ':id/edit', component: EditRegistrationRequestComponent },
    ]
  },
  {
    path: 'superadmin/psa-accounts',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManagePsaAccountsComponent },
      { path: 'create', component: NewPsaAccountComponent },
    ]
  },
  {
    path: 'superadmin/wallets',
    component: ManageWalletsComponent,
    canActivate: [...defaultSuperAdminGuards]
  },
  {
    path: 'superadmin/locations',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManageLocationsComponent },
      { path: 'new', component: NewLocationComponent },
      { path: ':id/edit', component: EditLocationComponent },
    ]
  },
  {
    path: 'superadmin/containerTypes',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManageContainerTypesComponent },
      { path: 'new', component: NewContainerTypeComponent },
      { path: ':id/edit', component: EditContainerTypeComponent },
    ]
  },
  {
    path: 'superadmin/liners',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManageLinersComponent },
      { path: 'new', component: NewLinerComponent },
      { path: ':id/edit', component: EditLinerComponent },
    ]
  },
  {
    path: 'superadmin/shipping-agents',
    component: ManageShippingAgentsComponent,
    canActivate: [...defaultSuperAdminGuards]
  },
  {
    path: 'superadmin/shipping-agents/new',
    component: NewShippingAgentComponent,
    canActivate: [...defaultSuperAdminGuards]
  },
  {
    path: 'superadmin/shipping-agents/:id/edit',
    component: EditShippingAgentComponent,
    canActivate: [...defaultSuperAdminGuards]
  },
  {
    path: 'superadmin/beta-users',
    component: ManageBetaUsersComponent,
    canActivate: [...defaultSuperAdminGuards]
  },
  {
    path: 'superadmin/subscriptionTypes',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManageSubscriptionTypesComponent },
      { path: 'new', component: NewSubscriptionTypeComponent },
      { path: ':id/edit', component: EditSubscriptionTypeComponent },
    ]
  },
  {
    path: 'superadmin/certiweight',
    canActivate: [...defaultSuperAdminGuards],
    canActivateChild: [...defaultSuperAdminGuards],
    children: [
      { path: '', component: ManageCertiweightMeasurementsComponent },
      { path: 'new', component: NewCertiweightMeasurementComponent },
      { path: ':id/edit', component: EditCertiweightMeasurementComponent },
    ]
  },
]);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [],
  providers: []
})
export class SuperAdminRoutingModule { }
