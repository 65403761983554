<button
  class="btn"
  type="{{ type }}"
  [ngClass]="btnClass"
  (click)="onClick()"
  [disabled]="isLoading || isDisabled"
>
  <span translate class="btn__label" [ngClass]="{ invisible: isLoading }">
    <ng-content></ng-content>
  </span>
  <i
    class="fa fa-spinner fa-spin btn__loader"
    [ngClass]="{ visible: isLoading }"
  ></i>
</button>
