import { TransportTask } from 'app/core/store/models/transport-task.model';
import { LocationService } from 'app/services/location.service';
import { SoftDeleteService } from 'app/services/soft-delete.service';
import { IconService } from 'app/services/icon.service';
import { Location } from 'app/core/store/models/location.model';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as DateFns from 'date-fns';
import { NewUserLocationModalComponent } from '../../tms/locations/new-user-location-modal/new-user-location-modal.component';
import { EditUserLocationModalComponent } from '../../tms/locations/edit-user-location-modal/edit-user-location-modal.component';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { LoadAddressBookLocations } from 'app/core/store/actions/location.actions';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-transport-tasks',
  templateUrl: './transport-tasks.component.html',
  styleUrls: ['./transport-tasks.component.scss']
})
export class TransportTasksComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  tms = false;
  @Input()
  showPublicLocationField = false;
  @Input()
  needsConfirmation: boolean;
  @Input()
  transportTasks;
  @Input()
  submitAttempt: boolean;
  @Input()
  transportTasksAreValid: boolean;
  @Output()
  transportTasksChange = new EventEmitter<any>();

  addressBookLocations: Location[];
  isLoadingAddressBookLocations = false;
  constructor(
    private modalService: NgbModal,
    private store: Store<State>,
    private iconService: IconService,
    public locationService: LocationService,
    private softDeleteService: SoftDeleteService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (changes.matchingIsEnabled) {
      // Whenever matching is toggled emit transportTasksChange to reset validation
      this.tTasksChange();
    }

    if (
      this.addressBookLocations &&
      changes.transportTasks &&
      !changes.transportTasks.previousValue &&
      changes.transportTasks.currentValue
    ) {
      changes.transportTasks.currentValue.forEach((task: TransportTask) => {
        this.addressBookLocations = this.softDeleteService.addElementToArrayIfNotExists(
          task.location,
          this.addressBookLocations
        );
      });
    }
  }

  ngOnInit() {
    this.store.dispatch(new LoadAddressBookLocations());
    this.store
      .select(state => state.tms.locations)
      .pipe(untilDestroyed(this))
      .subscribe(locationState => {
        if (locationState && locationState.addressBookLocations) {
          this.addressBookLocations = [
            ...locationState.addressBookLocations
          ].map(location => {
            return {
              id: location.id,
              '@id': location['@id'],
              name: location.name,
              fullAddress: location.fullAddress,
              publicLocation: location.publicLocation,
              latitude: location.latitude,
              longitude: location.longitude,
              prePaid: location.prePaid
            };
          });
          this.isLoadingAddressBookLocations = false;
          if (this.transportTasks) {
            this.transportTasks.forEach((task: TransportTask) => {
              if (!task.location) {
                return;
              }

              this.addressBookLocations = this.softDeleteService.addElementToArrayIfNotExists(
                task.location,
                this.addressBookLocations
              );
              (task.location.privateLocations || []).forEach(privLocation => {
                this.addressBookLocations = this.softDeleteService.addElementToArrayIfNotExists(
                  privLocation,
                  this.addressBookLocations
                );
              });
            });
          }
        }
      });
  }

  getTaskIcon(task) {
    return this.iconService.getTransportTaskIcon(task);
  }

  searchPrivateLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.privateLocation(term);
      })
    )

  get hasAtLeastOneLocation() {
    return this.transportTasks.some(task => task.location);
  }

  get everyTaskHasLocation() {
    return this.transportTasks.every(task => task.location);
  }

  privateLocationChange(event, index) {
    if (!event) {
      // this.transportTasks[index].location = null;
      return;
    }
    // if location is private set the public location associated with the selected private location
    this.transportTasks[index].location = event.publicLocation
      ? event.publicLocation
      : event;
    this.tTasksChange();
  }

  /**
   * Set start or end date if one date and two times are filed in
   */
  timeChange(task: TransportTask) {
    if (task.startTime && task.endTime) {
      if (task.startDate && !task.endDate) {
        task.endDate = task.startDate;
      }
      if (!task.startDate && task.endDate) {
        task.startDate = task.endDate;
      }
    }
    this.tTasksChange();
  }
  /**
   * Emits the new transport tasks value and the location validation (at least one)
   */
  tTasksChange() {
    this.transportTasksChange.emit({
      tasks: this.transportTasks,
      valid: this.hasAtLeastOneLocation
    });
  }

  startTimeChange(i) {
    this.transportTasks[i].startDateTimeSpecified =
      this.transportTasks[i].startTime !== null;
  }

  endTimeChange(i) {
    this.transportTasks[i].endDateTimeSpecified =
      this.transportTasks[i].endTime !== null;
  }

  /**
   *
   * @param date date object given by the datepicker
   * @param current the current year and month
   * @returns boolean if given date is before today
   */
  getDisabledDates(date: NgbDateStruct): boolean {
    const today = new Date();
    let d = new Date(date.year, date.month, date.day);
    d = DateFns.subMonths(d, 1);
    d = DateFns.addDays(d, 1);
    return DateFns.isBefore(d, today);
  }

  openNewUserLocationModal(taskId: number, locationName?: string) {
    const modalRef = this.modalService.open(NewUserLocationModalComponent);
    if (locationName) {
      modalRef.componentInstance.userLocation = {
        name: locationName
      };
    }
    modalRef.result.then(result => {
      // Patch the result to the private and public location of the right task
      const parsed = {
        id: result.payload.id,
        '@id': result.payload['@id'],
        name: result.payload.name,
        fullAddress: `${result.payload.name} - ${result.payload.fullAddress}`,
        latitude: result.payload.latitude,
        longitude: result.payload.longitude,
        publicLocation: result.payload.publicLocation,
        prePaid: result.prePaid
      };
      this.addressBookLocations = [...this.addressBookLocations, parsed];
      this.transportTasks[taskId].privateLocation = result.payload.id;
      this.transportTasks[taskId].location = result.payload.publicLocation;
      this.tTasksChange();
    });
  }

  /**
   * Used to check if the location is not a user location
   * Only user locations are editable
   * @param location
   */
  isPublicLocation(location: string) {
    if (!location || !this.addressBookLocations) {
      return;
    }
    const addressBookLocation = this.addressBookLocations.find(
      loc => loc.id === location
    ) as any;
    return !(
      (addressBookLocation && addressBookLocation.public_location) ||
      (addressBookLocation && addressBookLocation.publicLocation)
    );
  }

  openEditUserLocationModal(taskId: number, userLocation) {
    const modalRef = this.modalService.open(EditUserLocationModalComponent);
    // Depending of how we got the user location; the public location has a different structure
    const location = this.addressBookLocations.find(
      l => l.id === userLocation
    ) as any;
    const publicLocation = location.public_location
      ? location.public_location
      : location.publicLocation
        ? location.publicLocation
        : location.location.map(l => {
          return {
            '@id': l['@id'],
            fullAddress: l.address
          };
        });
    modalRef.componentInstance.location = {
      id: location.id,
      name: location.name,
      fullAddress: location.address ? location.address : location.fullAddress,
      latitude: location.latitude,
      longitude: location.longitude,
      publicLocation: publicLocation
    };
    modalRef.result.then(result => {
      if (!result) {
        return;
      }
      // Patch the result to the private and public location of the right task
      this.transportTasks[taskId].privateLocation = result.payload.id;
      this.transportTasks[taskId].location = result.payload.publicLocation;
      this.tTasksChange();
    });
  }

  ngOnDestroy() { }
}
