/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../container-type-form/container-type-form.component.ngfactory";
import * as i6 from "../container-type-form/container-type-form.component";
import * as i7 from "@angular/forms";
import * as i8 from "./new-container-type.component";
import * as i9 from "../../api/api.service";
import * as i10 from "../../../services/toast.service";
var styles_NewContainerTypeComponent = [];
var RenderType_NewContainerTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewContainerTypeComponent, data: {} });
export { RenderType_NewContainerTypeComponent as RenderType_NewContainerTypeComponent };
function View_NewContainerTypeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.alert = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_NgbAlert_0, i1.RenderType_NgbAlert)), i0.ɵdid(1, 638976, null, 0, i2.NgbAlert, [i2.NgbAlertConfig, i0.Renderer2, i0.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "i", [["class", "fa"]], null, null, null, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(0, i3.JsonPipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.alert.type; _ck(_v, 1, 0, currVal_1); var currVal_2 = "fa"; var currVal_3 = _co.alert.icon; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_4 = (_co.alert.message["statusText"] ? _co.alert.message["statusText"] : i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.alert.message))); _ck(_v, 6, 0, currVal_4); }); }
export function View_NewContainerTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "m-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NewContainerTypeComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-container-type-form", [], null, [[null, "containerTypeFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("containerTypeFormSubmitted" === en)) {
        var pd_0 = (_co.createNewContainerType($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ContainerTypeFormComponent_0, i5.RenderType_ContainerTypeFormComponent)), i0.ɵdid(10, 638976, null, 0, i6.ContainerTypeFormComponent, [i7.FormBuilder], null, { containerTypeFormSubmitted: "containerTypeFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert; _ck(_v, 2, 0, currVal_0); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("New Container Type")); _ck(_v, 6, 0, currVal_1); }); }
export function View_NewContainerTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-container-type", [], null, null, null, View_NewContainerTypeComponent_0, RenderType_NewContainerTypeComponent)), i0.ɵdid(1, 49152, null, 0, i8.NewContainerTypeComponent, [i9.ApiService, i10.ToastService], null, null)], null, null); }
var NewContainerTypeComponentNgFactory = i0.ɵccf("app-new-container-type", i8.NewContainerTypeComponent, View_NewContainerTypeComponent_Host_0, {}, {}, []);
export { NewContainerTypeComponentNgFactory as NewContainerTypeComponentNgFactory };
