/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./button.component";
var styles_ButtonComponent = [];
var RenderType_ButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "button", [["class", "btn"]], [[8, "type", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "span", [["class", "btn__label"], ["translate", ""]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { invisible: 0 }), i0.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(9, 0, null, null, 3, "i", [["class", "fa fa-spinner fa-spin btn__loader"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(11, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(12, { visible: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn"; var currVal_3 = _co.btnClass; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = "btn__label"; var currVal_5 = _ck(_v, 6, 0, _co.isLoading); _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = ""; _ck(_v, 7, 0, currVal_6); var currVal_7 = "fa fa-spinner fa-spin btn__loader"; var currVal_8 = _ck(_v, 12, 0, _co.isLoading); _ck(_v, 11, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.type, ""); var currVal_1 = (_co.isLoading || _co.isDisabled); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i0.ɵdid(1, 49152, null, 0, i3.ButtonComponent, [], null, null)], null, null); }
var ButtonComponentNgFactory = i0.ɵccf("app-button", i3.ButtonComponent, View_ButtonComponent_Host_0, { btnClass: "btnClass", type: "type", isLoading: "isLoading", isDisabled: "isDisabled" }, { buttonClicked: "buttonClicked" }, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
