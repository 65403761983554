import { AuthGuard } from '../core/user/user.authguard';
import { Routes } from '@angular/router';
import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { ReloadComponent } from './reload.component';
import { ReloadRequestsComponent } from './reload-requests/reload-requests.component';
import { ReloadRequestComponent } from './reload-request/reload-request.component';
import { ReloadBackOfficeComponent } from './reload-back-office.component';
import { NewReloadRequestComponent } from './new-reload-request/new-reload-request.component';
import { ReloadReportingComponent } from './reporting/reload-reporting.component';
import { Route } from '../core/route.service';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
var defaultReloadGaurds = [
    AuthGuard,
    TenantGuard,
    SubscriptionGuard,
    DriverGuard,
    AllowedSubscriptionGuard
];
var ɵ0 = { subscriptionCode: 'RELOAD' }, ɵ1 = { subscriptionCode: 'RELOAD' }, ɵ2 = { subscriptionCode: 'RELOAD' }, ɵ3 = { subscriptionCode: 'RELOAD' }, ɵ4 = { subscriptionCode: 'RELOAD_BACK_OFFICE' }, ɵ5 = { subscriptionCode: 'RELOAD_BACK_OFFICE' }, ɵ6 = { subscriptionCode: 'RELOAD_BACK_OFFICE' }, ɵ7 = { subscriptionCode: 'RELOAD_BACK_OFFICE' };
var routes = Route.withShell([
    {
        path: 'reload',
        component: ReloadComponent,
        canActivate: defaultReloadGaurds.slice(),
        data: ɵ0
    },
    {
        path: 'reload/reload-requests',
        children: [
            {
                path: '',
                component: ReloadRequestsComponent,
                canActivate: defaultReloadGaurds.slice(),
                data: ɵ1
            },
            {
                path: ':id/detail',
                component: ReloadRequestComponent,
                canActivate: defaultReloadGaurds.slice(),
                data: ɵ2
            }
        ]
    },
    {
        path: 'reload/new-reload-request',
        component: NewReloadRequestComponent,
        canActivate: defaultReloadGaurds.slice(),
        data: ɵ3
    },
    {
        path: 'reload-back-office',
        component: ReloadBackOfficeComponent,
        canActivate: defaultReloadGaurds.slice(),
        data: ɵ4
    },
    {
        path: 'reload-back-office/reload-requests',
        children: [
            {
                path: '',
                component: ReloadRequestsComponent,
                canActivate: defaultReloadGaurds.slice(),
                data: ɵ5
            },
            {
                path: ':id/detail',
                component: ReloadRequestComponent,
                canActivate: defaultReloadGaurds.slice(),
                data: ɵ6
            }
        ]
    },
    {
        path: 'reload-back-office/reporting',
        component: ReloadReportingComponent,
        canActivate: defaultReloadGaurds.slice(),
        data: ɵ7
    },
]);
var ReloadRoutingModule = /** @class */ (function () {
    function ReloadRoutingModule() {
    }
    return ReloadRoutingModule;
}());
export { ReloadRoutingModule };
export var routedComponents = [ReloadComponent];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
