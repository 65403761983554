import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../../user/user.auth';
import { combineLatest } from 'rxjs';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate() {
    return combineLatest(
      this.authService.hasActiveSession(),
      this.authService.hasActiveTenant()
    ).pipe(
      take(1),
      map(data => {
        if (data[0] && data[1]) {
          this.router.navigateByUrl('/dashboard');
          return false;
        }
        return true;
      })
    );
  }
}
