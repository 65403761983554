import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  DisableMessageBoardMessagesNotificationSubscription,
  EnableMessageBoardMessagesNotificationSubscription
} from 'app/core/store/actions/notification-subscriptions.actions';

@Component({
  selector: 'app-message-board-messages-subscription-toggle',
  templateUrl: './message-board-messages-subscription-toggle.component.html',
})
export class MessageBoardMessagesSubscriptionToggleComponent
  implements OnInit, OnChanges {
  @Input() messageBoardMessageSubscription;
  model: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: 'On',
    offText: 'Off',
    disabled: false,
    size: '',
    value: false
  };

  constructor(private store: Store<State>) { }

  ngOnInit() {
    if (this.messageBoardMessageSubscription) {
      this.model.value = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.messageBoardMessageSubscription &&
      changes.messageBoardMessageSubscription.currentValue
    ) {
      this.model.value = !this.model.value
        ? !this.model.value
        : this.model.value;
    }
  }

  toggleSubscription() {
    this.messageBoardMessageSubscription
      ? this.disableSubscription()
      : this.enableSubscription();
  }

  enableSubscription() {
    this.store.dispatch(
      new EnableMessageBoardMessagesNotificationSubscription()
    );
  }

  disableSubscription() {
    if (this.messageBoardMessageSubscription) {
      this.store.dispatch(
        new DisableMessageBoardMessagesNotificationSubscription({
          id: this.messageBoardMessageSubscription.id
        })
      );
    }
  }
}
