import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/tenant-settings.types';
import * as TenantSettingsActions from '../../store/actions/tenant-settings.actions';
import * as NotificationsActions from '../actions/notifications.actions';

@Injectable()
export class TenantSettingsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect() loadTenantSettings$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenantSettings.LOAD_TENANT_SETTINGS),
    map((action: TenantSettingsActions.LoadTenantSettings) => action.payload),
    concatMap(() => this.api.get({
      path: '/tenant_settings'
    }).pipe(
      map(data => new TenantSettingsActions.LoadTenantSettingsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    ))
  );

  @Effect() updateTenantSettings$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenantSettings.UPDATE_TENANT_SETTINGS),
    map((action: TenantSettingsActions.UpdateTenantSettings) => action.payload),
    concatMap(payload => this.api.put({
      path: '/tenant_settings/' + payload.path, body: payload.body
    }).pipe(
      map(data => new TenantSettingsActions.UpdateTenantSettingsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    ))
  );
}
