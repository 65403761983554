<div class="card">
  <div class="card-body">
    <h2 class="text-center font-weight-light">
      {{ title | translate }}
    </h2>
    <form (ngSubmit)="onSubmit()" [formGroup]="newMarketpostDemandForm">
      <div
        ngbRadioGroup="ngbRadioGroup"
        name="transportTypeRadio"
        formControlName="transportType"
        ngDefaultControl="ngDefaultControl"
        data-cy="transportTypes"
        class="btn-group btn-group-toggle d-none d-md-flex justify-content-center form-group"
      >
        <label
          ngbButtonLabel="ngbButtonLabel"
          *ngFor="let type of transportTypes"
          class="btn-outline-primary"
        >
          <input
            ngbButton="ngbButton"
            type="radio"
            [value]="type"
            (change)="transportTypePicked()"
          />
          <span class="icon">
            <i class="far" [ngClass]="getTypeIcons(type)"></i>
          </span>
          <span>
            {{ type.label | titlecase | translate }}
          </span>
        </label>
      </div>
      <div class="d-md-none d-flex justify-content-center">
        <ng-template #content let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title">{{ 'Pick transport type' | translate }}</h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="list-group">
              <li
                *ngFor="let type of transportTypes"
                class="list-group-item"
                [ngClass]="{
                  active:
                    type === newMarketpostDemandForm.get('transportType').value
                }"
                (click)="setTransportType(type)"
              >
                {{ type.label | titlecase | translate }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              (click)="c('Close click')"
            >
              {{ 'Confirm' | translate }}
            </button>
          </div>
        </ng-template>

        <button
          class="btn btn-primary text-white mb-3"
          type="button"
          *ngIf="
            newMarketpostDemandForm.get('transportType').value &&
            newMarketpostDemandForm.get('transportType').value.label
          "
          (click)="open($event, content)"
        >
          {{ 'Pick transport type' | translate }} ({{
            newMarketpostDemandForm.get('transportType').value.label
              | titlecase
              | translate
          }})
        </button>
      </div>
      <!-- Origin and Destination row -->
      <div class="form-row mb-3">
        <!-- Origin column -->
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="form-row mb-2">
                <div class="col">
                  <h4>
                    <span class="icon">
                      <i class="far fa-map-marker-alt"></i>
                    </span>
                    <label>{{ 'Origin' | translate }}</label>
                  </h4>
                  <div class="input-group location-input">
                    <input
                      placeholder="{{
                        'Enter originlocation here' | translate
                      }}"
                      formControlName="originLocation"
                      id="originLocation"
                      data-cy="originLocation"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': !hasAtLeastOneLocation() }"
                      [ngbTypeahead]="searchLocation"
                      [resultFormatter]="locationFormatter"
                      [inputFormatter]="locationFormatter"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="far fa-location-arrow"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <span class="d-flex justify-content-between">
                      <div class="input-group mr-1">
                        <input
                          class="form-control"
                          placeholder="{{
                            'From date (dd/mm/yyyy)' | translate
                          }}"
                          name="d1"
                          formControlName="originStartDate"
                          data-cy="originStartDate"
                          ngbDatepicker
                          [ngClass]="{
                            'is-invalid': newMarketpostDemandForm.get(
                              'originStartDate'
                            ).invalid
                          }"
                          [markDisabled]="getDisabledDates"
                          placement="bottom-left"
                          #d1="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-dark"
                            type="button"
                            data-cy="originStartDateCalendarButton"
                            (click)="d1.toggle()"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div
                          class="invalid-feedback"
                          [ngClass]="{
                            'invalid-feedback--visible': newMarketpostDemandForm.get(
                              'originStartDate'
                            ).invalid
                          }"
                        >
                          {{
                            'Please use dd/mm/yyyy to format your dates.'
                              | translate
                          }}
                        </div>
                      </div>
                      <ngb-timepicker
                        data-cy="originStartTime"
                        formControlName="originStartTime"
                        [spinners]="false"
                      ></ngb-timepicker>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <span class="d-flex justify-content-between">
                      <div class="input-group mr-1">
                        <input
                          class="form-control"
                          placeholder="{{ 'To date (dd/mm/yyyy)' | translate }}"
                          name="d2"
                          formControlName="originEndDate"
                          [ngClass]="{
                            'is-invalid': newMarketpostDemandForm.get(
                              'originEndDate'
                            ).invalid
                          }"
                          data-cy="originEndDate"
                          ngbDatepicker
                          [markDisabled]="getDisabledDates"
                          placement="bottom-left"
                          #d2="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-dark"
                            type="button"
                            (click)="d2.toggle()"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div
                          class="invalid-feedback"
                          [ngClass]="{
                            'invalid-feedback--visible': newMarketpostDemandForm.get(
                              'originEndDate'
                            ).invalid
                          }"
                        >
                          {{
                            'Please use dd/mm/yyyy to format your dates.'
                              | translate
                          }}
                        </div>
                      </div>
                      <ngb-timepicker
                        formControlName="originEndTime"
                        data-cy="originEndTime"
                        [spinners]="false"
                      ></ngb-timepicker>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Destination column -->
        <div
          class="col"
          *ngIf="
            newMarketpostDemandForm.get('transportType').value &&
            newMarketpostDemandForm.get('transportType').value.label !==
              'empty (hub)'
          "
        >
          <div class="card">
            <div class="card-body">
              <div class="form-row mb-2">
                <div class="col">
                  <h4>
                    <span class="icon">
                      <i class="far fa-map-marker"></i>
                    </span>
                    <label>{{ 'Destination' | translate }}</label>
                  </h4>
                  <div class="input-group location-input">
                    <input
                      placeholder="{{
                        'Enter destination location here' | translate
                      }}"
                      formControlName="destinationLocation"
                      id="destinationLocation"
                      data-cy="destinationLocation"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': !hasAtLeastOneLocation() }"
                      [ngbTypeahead]="searchLocation"
                      [resultFormatter]="locationFormatter"
                      [inputFormatter]="locationFormatter"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="far fa-location-arrow"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <span class="d-flex justify-content-between">
                      <div class="input-group mr-1">
                        <input
                          class="form-control"
                          placeholder="{{
                            'From date (dd/mm/yyyy)' | translate
                          }}"
                          name="d3"
                          formControlName="destinationStartDate"
                          data-cy="destinationStartDate"
                          [ngClass]="{
                            'is-invalid': newMarketpostDemandForm.get(
                              'destinationStartDate'
                            ).invalid
                          }"
                          ngbDatepicker
                          [markDisabled]="getDisabledDates"
                          placement="bottom-left"
                          #d3="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-dark"
                            type="button"
                            (click)="d3.toggle()"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div
                          class="invalid-feedback"
                          [ngClass]="{
                            'invalid-feedback--visible': newMarketpostDemandForm.get(
                              'destinationStartDate'
                            ).invalid
                          }"
                        >
                          {{
                            'Please use dd/mm/yyyy to format your dates.'
                              | translate
                          }}
                        </div>
                      </div>
                      <ngb-timepicker
                        formControlName="destinationStartTime"
                        data-cy="destinationStartTime"
                        [spinners]="false"
                      ></ngb-timepicker>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <span class="d-flex justify-content-between">
                      <div class="input-group mr-1">
                        <input
                          class="form-control"
                          placeholder="{{ 'To date (dd/mm/yyyy)' | translate }}"
                          name="d4"
                          formControlName="destinationEndDate"
                          data-cy="destinationEndDate"
                          [ngClass]="{
                            'is-invalid': newMarketpostDemandForm.get(
                              'destinationEndDate'
                            ).invalid
                          }"
                          ngbDatepicker
                          [markDisabled]="getDisabledDates"
                          placement="bottom-left"
                          #d4="ngbDatepicker"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-dark"
                            type="button"
                            (click)="d4.toggle()"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div
                          class="invalid-feedback"
                          [ngClass]="{
                            'invalid-feedback--visible': newMarketpostDemandForm.get(
                              'destinationEndDate'
                            ).invalid
                          }"
                        >
                          {{
                            'Please use dd/mm/yyyy to format your dates.'
                              | translate
                          }}
                        </div>
                      </div>
                      <ngb-timepicker
                        formControlName="destinationEndTime"
                        data-cy="destinationEndTime"
                        [spinners]="false"
                      >
                      </ngb-timepicker>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Container -->
      <div class="form-section-darker p-3">
        <div formGroupName="container">
          <div class="d-flex justify-content-between">
            <div class="col col-md-3 col-sm-6 pl-0">
              <div class="form-group">
                <ng-select
                  id="containerType"
                  [items]="containerTypes"
                  data-cy="containerTypes"
                  bindLabel="label"
                  bindValue="id"
                  placeholder="{{ 'Select Container Type' | translate }}"
                  formControlName="containerType"
                >
                </ng-select>
              </div>
            </div>
            <div class="col col-md-3 col-sm-6">
              <div class="form-group">
                <ng-select
                  id="liner"
                  [items]="liners"
                  bindLabel="name"
                  data-cy="liners"
                  bindValue="id"
                  placeholder="{{ 'Select Liner' | translate }}"
                  formControlName="liner"
                >
                </ng-select>
              </div>
            </div>
            <div class="col col-md-3 col-sm-6">
              <div class="form-group">
                <input
                  placeholder="{{ 'Enter weight (T)' | translate }}"
                  formControlName="weight"
                  type="number"
                  id="weight"
                  data-cy="weight"
                  class="form-control"
                  min="0"
                  [ngClass]="{
                    'is-invalid':
                      newMarketpostDemandForm.get('container.weight').invalid &&
                      newMarketpostDemandForm.get('container.weight').touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="weight.errors && weight.errors.notNumber"
                >
                  {{
                    'Weight should have the following format: 1.5' | translate
                  }}
                </div>
              </div>
            </div>
            <div class="form-check">
              <label data-cy="adr" class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="adr"
                />
                <span class="custom-control-label">{{
                  'ADR' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <input
                placeholder="{{ 'Enter your reference here' | translate }}"
                formControlName="reference"
                data-cy="reference"
                class="form-control"
                id="reference"
              />
            </div>
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col">
            <label>{{ 'Comment' | translate }}</label>
            <span class="text-muted" id="message-length"
              >({{ comment.length }} / 256)</span
            >
            <textarea
              id="comment"
              class="form-control"
              maxlength="256"
              formControlName="comment"
              data-cy="comment"
              cols="20"
              rows="2"
            ></textarea>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <div class="d-flex align-items-center">
            <button
              [disabled]="
                !hasAtLeastOneLocation() ||
                newMarketpostDemandForm.get('quantity').invalid ||
                newMarketpostDemandForm.get('originStartDate').invalid ||
                newMarketpostDemandForm.get('originEndDate').invalid ||
                newMarketpostDemandForm.get('destinationStartDate').invalid ||
                newMarketpostDemandForm.get('destinationEndDate').invalid
              "
              id="addTransportButton"
              data-cy="submitDemandButton"
              class="btn btn-primary float-right"
            >
              <span class="text-light">
                <span *ngIf="!transportDemand; else editMode">
                  <i class="far fa-plus"></i>&nbsp;
                  {{ 'Add Transport' | translate }}
                </span>
                <ng-template #editMode>
                  <i class="far fa-save"></i>&nbsp;
                  <span>{{ 'Save Changes' | translate }}</span>
                </ng-template>
              </span>
            </button>
          </div>
          <label
            class="mr-3 custom-control custom-checkbox"
            *ngIf="hasActiveMatchingSubscription"
          >
            <input
              type="checkbox"
              id="matching-check"
              class="custom-control-input"
              [(ngModel)]="isMatchingEnabled"
            />
            <span class="custom-control-label">{{
              'Matching' | translate
            }}</span>
          </label>
          <div class="form-inline mr-3">
            <span class="mr-3">{{ 'Quantity' | translate }}</span>
            <input
              formControlName="quantity"
              id="quantity"
              class="form-control"
              min="1"
              [ngClass]="{
                'is-invalid': newMarketpostDemandForm.get('quantity').invalid
              }"
              type="number"
            />
            <div class="invalid-feedback">
              {{ 'Quantity cannot be less than 0' | translate }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
