import { Action } from '@ngrx/store';
import * as types from '../types/transport.types';

export class LoadTransports implements Action {
  readonly type = types.transports.LOAD_TRANSPORTS;
  constructor(public payload?: any) {}
}

export class LoadTransportsSuccess implements Action {
  readonly type = types.transports.LOAD_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadTransportsFail implements Action {
  readonly type = types.transports.LOAD_FAILED;
  constructor(public payload?: any) {}
}

export class LoadTransportsByDaterange implements Action {
  readonly type = types.transports.LOAD_TRANSPORTS_BY_DATERANGE;
  constructor(
    public payload?: {
      fromDate?: string;
      toDate?: string;
      page?: number;
      query?: string;
    }
  ) {}
}

export class LoadTransportsByDaterangeSuccess implements Action {
  readonly type = types.transports.LOAD_TRANSPORTS_BY_DATERANGE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadTransportsByDaterangeFail implements Action {
  readonly type = types.transports.LOAD_TRANSPORTS_BY_DATERANGE_FAILED;
  constructor(public payload?: any) {}
}

export class CreateTransport implements Action {
  readonly type = types.transports.CREATE_TRANSPORT;
  constructor(public payload: any) {}
}

export class CreateTransportSuccess implements Action {
  readonly type = types.transports.CREATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class CreateTransportFail implements Action {
  readonly type = types.transports.CREATE_FAILED;
  constructor(public payload?: any) {}
}

export class DeleteTransport implements Action {
  readonly type = types.transports.DELETE_TRANSPORT;
  constructor(public payload: any) {}
}

export class DeleteTransportSuccess implements Action {
  readonly type = types.transports.DELETE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteTransportFail implements Action {
  readonly type = types.transports.DELETE_FAILED;
  constructor(public payload?: any) {}
}

export class UpdateTransport implements Action {
  readonly type = types.transports.UPDATE_TRANSPORT;
  constructor(public payload: any) {}
}

export class UpdateTransportSuccess implements Action {
  readonly type = types.transports.UPDATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateTransportFail implements Action {
  readonly type = types.transports.UPDATE_FAILED;
  constructor(public payload?: any) {}
}

export class CreateTransportTask implements Action {
  readonly type = types.transports.CREATE_TRANSPORT_TASK;
  constructor(public payload: any) {}
}

export class CreateTransportTaskSuccess implements Action {
  readonly type = types.transports.CREATE_TRANSPORT_TASK_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class CreateTransportTaskFail implements Action {
  readonly type = types.transports.CREATE_TRANSPORT_TASK_FAILED;
  constructor(public payload?: any) {}
}

export class TransitionTransportTaskForward implements Action {
  readonly type = types.transports.TRANSITION_TRANSPORT_TASK_FORWARD;
  constructor(
    public payload: {
      transportTaskId: string;
    }
  ) {}
}

export class TransitionTransportTaskForwardSuccess implements Action {
  readonly type = types.transports.TRANSITION_TRANSPORT_TASK_FORWARD_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class TransitionTransportTaskForwardFail implements Action {
  readonly type = types.transports.TRANSITION_TRANSPORT_TASK_FORWARD_FAILED;
  constructor(public payload?: any) {}
}

export class TransitionTransportTaskBack implements Action {
  readonly type = types.transports.TRANSITION_TRANSPORT_TASK_BACK;
  constructor(
    public payload: {
      transportTaskId: string;
    }
  ) {}
}

export class TransitionTransportTaskBackSuccess implements Action {
  readonly type = types.transports.TRANSITION_TRANSPORT_TASK_BACK_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class TransitionTransportTaskBackFail implements Action {
  readonly type = types.transports.TRANSITION_TRANSPORT_TASK_BACK_FAILED;
  constructor(public payload?: any) {}
}

export class CheckTransportTaskReference implements Action {
  readonly type = types.transports.CHECK_TRANSPORT_TASK_REFERENCE;
  constructor(public payload: any) {}
}

export class CheckTransportTaskReferenceSuccess implements Action {
  readonly type = types.transports.CHECK_TRANSPORT_TASK_REFERENCE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class CheckTransportTaskReferenceFail implements Action {
  readonly type = types.transports.CHECK_TRANSPORT_TASK_REFERENCE_FAILED;
  constructor(public payload?: any) {}
}

export class LoadInvoiceableTransports implements Action {
  readonly type = types.transports.LOAD_INVOICEABLE_TRANSPORTS;
  constructor(public payload?: any) {}
}

export class LoadInvoiceableTransportsSuccess implements Action {
  readonly type = types.transports.LOAD_INVOICEABLE_TRANSPORTS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadInvoiceableTransportsFail implements Action {
  readonly type = types.transports.LOAD_INVOICEABLE_TRANSPORTS_FAILED;
  constructor(public payload?: any) {}
}

export class LoadNextInvoiceableTransportsPage implements Action {
  readonly type = types.transports.LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE;
  constructor(public payload?: any) {}
}

export class LoadNextInvoiceableTransportsPageSuccess implements Action {
  readonly type =
    types.transports.LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadNextInvoiceableTransportsPageFail implements Action {
  readonly type = types.transports.LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE_FAILED;
  constructor(public payload?: any) {}
}

export class LoadPurchaseOrderableTransports implements Action {
  readonly type = types.transports.LOAD_PURCHASE_ORDERABLE_TRANSPORTS;
  constructor(public payload?: any) {}
}

export class LoadPurchaseOrderableTransportsSuccess implements Action {
  readonly type = types.transports.LOAD_PURCHASE_ORDERABLE_TRANSPORTS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadPurchaseOrderableTransportsFail implements Action {
  readonly type = types.transports.LOAD_PURCHASE_ORDERABLE_TRANSPORTS_FAILED;
  constructor(public payload?: any) {}
}

export class LoadNextPurchaseOrderableTransportsPage implements Action {
  readonly type = types.transports.LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE;
  constructor(public payload?: any) {}
}

export class LoadNextPurchaseOrderableTransportsPageSuccess implements Action {
  readonly type =
    types.transports.LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadNextPurchaseOrderableTransportsPageFail implements Action {
  readonly type =
    types.transports.LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE_FAILED;
  constructor(public payload?: any) {}
}

export class CopyTransport implements Action {
  readonly type = types.transports.COPY_TRANSPORT;
  constructor(public payload?: any) {}
}

export class ClearTransports implements Action {
  readonly type = types.transports.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadTransports
  | LoadTransportsSuccess
  | LoadTransportsFail
  | LoadTransportsByDaterange
  | LoadTransportsByDaterangeSuccess
  | LoadTransportsByDaterangeFail
  | CreateTransport
  | CreateTransportSuccess
  | CreateTransportFail
  | DeleteTransport
  | DeleteTransportSuccess
  | DeleteTransportFail
  | UpdateTransport
  | UpdateTransportSuccess
  | UpdateTransportFail
  | CreateTransportTask
  | CreateTransportTaskSuccess
  | CreateTransportTaskFail
  | TransitionTransportTaskForward
  | TransitionTransportTaskForwardSuccess
  | TransitionTransportTaskForwardFail
  | TransitionTransportTaskBack
  | TransitionTransportTaskBackSuccess
  | TransitionTransportTaskBackFail
  | CheckTransportTaskReference
  | CheckTransportTaskReferenceSuccess
  | CheckTransportTaskReferenceFail
  | LoadInvoiceableTransports
  | LoadInvoiceableTransportsFail
  | LoadInvoiceableTransportsSuccess
  | LoadNextInvoiceableTransportsPage
  | LoadNextInvoiceableTransportsPageFail
  | LoadNextInvoiceableTransportsPageSuccess
  | LoadPurchaseOrderableTransports
  | LoadPurchaseOrderableTransportsFail
  | LoadPurchaseOrderableTransportsSuccess
  | LoadNextPurchaseOrderableTransportsPage
  | LoadNextPurchaseOrderableTransportsPageFail
  | LoadNextPurchaseOrderableTransportsPageSuccess
  | ClearTransports;
