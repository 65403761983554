import { MatchingTypes } from 'app/core/store/models/matching.model';
import { Transport } from 'app/core/store/models/transport.model';
import { ToastService } from 'app/services/toast.service';
import { UnpublishMarketPostDemand } from 'app/core/store/actions/market-post-demand.actions';
import { Router } from '@angular/router';
import {
  Component,
  Input,
  ViewChild,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  NgZone,
  Output,
  EventEmitter
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { UnpublishMarketPostOffer } from 'app/core/store/actions/market-post-offer.actions';
import * as Types from 'app/core/store/types/marketplace.types';
import { Actions, ofType } from '@ngrx/effects';
import { IconService } from 'app/services/icon.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-market-post-table',
  templateUrl: 'market-post-table.component.html',
  styleUrls: ['market-post-table.component.scss']
})
export class MarketPostTableComponent implements OnDestroy, OnChanges {
  @ViewChild(DatatableComponent, { static: false })
  ngxDatatable: DatatableComponent;

  @Input() marketposts: any[] = [];

  @Input() showFilter: boolean;
  @Input() showMB: boolean;
  @Input()
  hasActiveMatchingSubscription: boolean;

  @Output()
  disableMatching = new EventEmitter();
  @Output()
  enableMatching = new EventEmitter();

  selected: any[] = [];
  tenantId: string;
  MatchingTypes = MatchingTypes;
  loading = false;
  ALERT_TIME_MS = 5000;

  constructor(
    private router: Router,
    private store: Store<State>,
    private updates$: Actions,
    private zone: NgZone,
    private toastr: ToastService,
    private iconService: IconService
  ) {
    this.store
      .select(state => state.session)
      .pipe(untilDestroyed(this))
      .subscribe(session => {
        if (session) {
          this.tenantId = session.tenant;
        }
      });
    updates$
      .pipe(ofType(
        Types.marketposts.UNPUBLISH_MARKET_POST_DEMAND_SUCCESS,
        Types.marketposts.UNPUBLISH_MARKET_POST_OFFER_SUCCESS
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showSuccess({ message: 'Unpublish Sucessful!' });
      });
    updates$
      .pipe(ofType(
        Types.marketposts.UNPUBLISH_MARKET_POST_OFFER_FAILED,
        Types.marketposts.UNPUBLISH_MARKET_POST_DEMAND_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showDanger({
          message: 'Delete failed',
          title: 'Something went wrong'
        });
      });
  }

  ngOnDestroy() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ngxDatatable && (changes.showFilter || changes.showMB)) {
      this.ngxDatatable.recalculate();
    }
  }
  unpublish(post) {
    this.loading = true;
    post['marketPostType'] === 'demand'
      ? this.store.dispatch(
        new UnpublishMarketPostDemand({ id: post._id ? post._id : post.id })
      )
      : this.store.dispatch(
        new UnpublishMarketPostOffer({ id: post._id ? post._id : post.id })
      );
  }

  edit(post) {
    this.zone.run(() => {
      const id = post._id ? post._id : post.id;
      this.router.navigate([`marketplace/${post.marketPostType}s/${id}/edit`]);
    });
  }

  reply(post) {
    this.zone.run(() => {
      const id = post._id ? post._id : post.id;
      this.router.navigate([`marketplace/${post.marketPostType}s/${id}/reply`]);
    });
  }

  toggleExpandRow(row) {
    this.ngxDatatable.rowDetail.toggleExpandRow(row);
  }
  getTaskIcon(task) {
    return this.iconService.getTransportTaskIcon(task);
  }
  getTransportTypeIcon(transport: Transport) {
    return this.iconService.getTransportTypeIcon(transport.transportType);
  }
  handleEnableMatching(row) {
    this.enableMatching.emit({
      type:
        row.marketPostType === 'offer'
          ? MatchingTypes.MARKET_POST_OFFER
          : MatchingTypes.MARKET_POST_DEMAND,
      id: row.id
    });
  }
  handleDisableMatching(row) {
    this.disableMatching.emit({
      type:
        row.marketPostType === 'offer'
          ? MatchingTypes.MARKET_POST_OFFER
          : MatchingTypes.MARKET_POST_DEMAND,
      id: row.id
    });
  }
}
