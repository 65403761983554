<ngb-tabset [justify]="'fill'">
  <ngb-tab id="offer-tab">
    <ng-template ngbTabTitle>
      <span class="icon">
        <i class="far fa-hand-holding"></i>
      </span>
      {{ 'Offers' | translate }}
    </ng-template>
    <ng-template ngbTabContent>
      <div class="p-3">
        <app-cmp-my-offers></app-cmp-my-offers>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab id="demand-tab">
    <ng-template ngbTabTitle>
      <span class="icon">
        <i class="far fa-question-circle"></i>
      </span>
      {{ 'Demands' | translate }}
    </ng-template>
    <ng-template ngbTabContent>
      <div class="p-3">
        <app-cmp-my-demands></app-cmp-my-demands>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
