import { Actions, ofType } from '@ngrx/effects';
import { Driver } from 'app/core/store/models/driver.model';
import { Visit } from 'app/core/store/models/visits.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import { State } from 'app/core/store/store.model';
import { LoadVisits, UpdateVisitSequence } from 'app/core/store/actions/visits.actions';
import { LoadDrivers } from 'app/core/store/actions/user-contexts.actions';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import * as VisitTypes from 'app/core/store/types/visit.types';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-driver-schedule',
  templateUrl: './manage-driver-schedule.component.html',
  styleUrls: ['./manage-driver-schedule.component.scss']
})
export class ManageDriverScheduleComponent implements OnDestroy {
  title = extract('Manage Driver Schedule');
  dragulaEventSubscriptions = new Subscription();

  visits: Visit[];
  driver: Driver;
  drivers: Driver[];
  isLoadingDrivers = true;
  isLoadingVisits = true;

  constructor(
    private store: Store<State>,
    private dragulaService: DragulaService,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.dragulaEventSubscriptions.add(
      this.dragulaService
        .dropModel('VISITS')
        .subscribe(({ targetModel }) => {
          this.updateSequence(targetModel);
        })
    );
    this.store.dispatch(
      new LoadDrivers({
        includeSuperAdmins: 0,
        roleName: 'DRIVER'
      })
    );
    this.store
      .select(state => state.drivers)
      .pipe(untilDestroyed(this))
      .subscribe(driversState => {
        if (driversState && driversState['drivers']) {
          this.drivers = driversState['drivers'].map(driver => {
            return {
              id: driver.id,
              name: `${driver.user.firstName} ${driver.user.lastName} - ${
                driver['licensePlate']
                }`
            };
          });
          this.driver = this.drivers[0];
          this.isLoadingDrivers = true;
          this.store.dispatch(
            new LoadVisits({
              driverId: this.driver.id
            })
          );
          this.isLoadingDrivers = false;
        }
      });
    this.store
      .select(state => state.tms.visits)
      .pipe(untilDestroyed(this))
      .subscribe(visitsState => {
        if (visitsState) {
          this.visits = visitsState;
        }
        this.isLoadingVisits = false;
      });
    this.updates$
      .pipe(ofType(VisitTypes.visits.UPDATE_VISIT_SEQUENCE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to update sequence')
        });
      });
    this.updates$
      .pipe(ofType(VisitTypes.visits.UPDATE_VISIT_SEQUENCE_SUCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Sequence updated successfully')
        });
      });
  }

  /**
   * Update the sequence of all visits
   */
  private updateSequence(targetModel) {
    const body = {
      collection: targetModel.map((visit, index) => {
        return {
          id: visit.id,
          sequence: index + 1
        };
      })
    };
    if (!body) {
      return;
    }
    this.store.dispatch(new UpdateVisitSequence(body));
  }

  handleDriverSelection() {
    if (this.driver) {
      this.isLoadingVisits = true;
      this.visits = [];
      this.store.dispatch(
        new LoadVisits({
          driverId: this.driver.id
        })
      );
    }
  }

  ngOnDestroy() { }
}
