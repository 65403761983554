import { ELASTIC_SEARCH_RESPONSE_SIZE } from '../constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '../core/store/store.model';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { LoadMarketposts } from '../core/store/actions/market-posts.actions';
import { Autocomplete } from '../services/autocomplete.service';

@Injectable()
export class SearchService {
  base = {
    size: ELASTIC_SEARCH_RESPONSE_SIZE
  };
  constructor(
    private store: Store<State>,
    private autocompleteService: Autocomplete
  ) { }

  searchWithQuery(fields: string[], query: string) {
    const body = {
      multi_match: {
        query: query,
        fields: fields,
        tie_breaker: 0.3,
        fuzziness: 'AUTO'
      }
    };
    localStorage.setItem('searchQuery', query);
    if (query && query.length > 1) {
      this.search(body, null, null);
    } else {
      localStorage.setItem('searchQuery', '');
      this.search(undefined, null, null);
    }
  }

  filter(
    containerTypes?: any[],
    transportTypes?: any[],
    minWeight?: number,
    maxWeight?: number,
    reference?: string,
    marketPostOffer?: boolean,
    marketPostDemand?: boolean,
    liners?: any[],
    maxDate?: any,
    minDate?: any
  ) {
    const body = {
      must: []
    };
    if (transportTypes && transportTypes.length > 0) {
      body.must.push({
        terms: {}
      });
      const index = body.must.length - 1;
      body.must[index]['terms']['transportType.label'] = [];
      transportTypes.forEach(tType => {
        if (tType.label.split(/(\s+)/).length > 1) {
          tType.label.split(/(\s+)/).forEach(element => {
            if (element !== ' ') {
              body.must[index]['terms']['transportType.label'].push(element);
            }
          });
        } else {
          body.must[index]['terms']['transportType.label'].push(tType.label);
        }
      });
    }
    if (liners && liners.length > 0) {
      body.must.push({
        terms: {}
      });
      const index = body.must.length - 1;
      body.must[index]['terms']['container.liner.shortName'] = [];
      liners.forEach(liner => {
        // const index = body.query.bool.must.length - 1;
        if (liner.shortName.split(/(\s+)/).length > 1) {
          liner.shortName.split(/(\s+)/).forEach(element => {
            if (element !== ' ') {
              body.must[index]['terms']['container.liner.shortName'].push(
                element.toLowerCase()
              );
            }
          });
        } else {
          body.must[index]['terms']['container.liner.shortName'].push(
            liner.shortName.toLowerCase()
          );
        }
      });
    }
    if (containerTypes && containerTypes.length > 0) {
      body.must.push({
        terms: {}
      });
      body.must.push({
        terms: {}
      });
      const index = body.must.length - 1;
      body.must[index]['terms']['container.containerType.label'] = [];
      body.must[index - 1]['terms']['container.containerType.label'] = [];
      containerTypes.forEach((cType, cTypeIndex) => {
        body.must[index]['terms']['container.containerType.label'].push(
          this.containerTypeLabelLength(cTypeIndex, containerTypes)
        );
        body.must[index - 1]['terms']['container.containerType.label'].push(
          this.containerTypeLabelText(cTypeIndex, containerTypes)
        );
      });
    }
    if (minWeight || maxWeight) {
      body.must.push({
        range: {}
      });
      const index = body.must.length - 1;
      body.must[index]['range'] = {
        'container.weight': {
          gte: minWeight,
          lte: maxWeight
        }
      };
    }
    if (maxDate) {
      body.must.push({
        range: {}
      });
      const index = body.must.length - 1;
      body.must[index]['range'] = {
        primary_task_date: {
          gte: new Date(minDate.year, minDate.month - 1, minDate.day, 0, 0, 0).toISOString(),
          lte: new Date(maxDate.year, maxDate.month - 1, maxDate.day, 23, 59, 59).toISOString()
        }
      };
    }
    if (reference) {
      body.must.push({
        fuzzy: {}
      });
      const index = body.must.length - 1;
      body.must[index]['fuzzy'] = {
        reference: reference
      };
    }
    if (marketPostOffer || marketPostDemand) {
      body.must.push({
        terms: {}
      });
      const index = body.must.length - 1;
      body.must[index]['terms']['marketPostType'] = [];
      if (marketPostOffer) {
        body.must[index]['terms']['marketPostType'].push('offer');
      }
      if (marketPostDemand) {
        body.must[index]['terms']['marketPostType'].push('demand');
      }
    }
    if (body.must.length > 0) {
      this.search(null, body, null);
    } else {
      this.search(null, undefined, null);
    }
  }

  filterGeoBoundBox(tlLat, tlLon, brLat, brLon) {
    const body = {
      bool: {
        should: [
          {
            bool: {
              must: [
                {
                  match_all: {
                    boost: 1.0
                  }
                }
              ],
              filter: {
                geo_bounding_box: {
                  'originLocation.geo': {
                    bottom_right: {
                      lat: brLat,
                      lon: brLon
                    },
                    top_left: {
                      lat: tlLat,
                      lon: tlLon
                    }
                  }
                }
              }
            }
          },
          {
            bool: {
              must: [
                {
                  match_all: {
                    boost: 1.0
                  }
                }
              ],
              filter: {
                geo_bounding_box: {
                  'destinationLocation.geo': {
                    bottom_right: {
                      lat: brLat,
                      lon: brLon
                    },
                    top_left: {
                      lat: tlLat,
                      lon: tlLon
                    }
                  }
                }
              }
            }
          },
          {
            bool: {
              must: [
                {
                  match_all: {
                    boost: 1.0
                  }
                }
              ],
              filter: {
                geo_bounding_box: {
                  'tasks.location.geo': {
                    bottom_right: {
                      lat: brLat,
                      lon: brLon
                    },
                    top_left: {
                      lat: tlLat,
                      lon: tlLon
                    }
                  }
                }
              }
            }
          }
        ]
      }
    };
    this.search(null, null, body);
  }
  resetSearch() {
    // this.search(this.base);
    localStorage.removeItem('qq');
    localStorage.removeItem('fq');
    localStorage.removeItem('mq');
    localStorage.removeItem('query');
    this.search(null, null, null);
  }

  private search(qQuery, fQuery, mQuery) {
    const body = {
      sort: [{ last_updated: { order: 'desc' } }],
      size: ELASTIC_SEARCH_RESPONSE_SIZE,
      query: {
        bool: {
          must: []
        }
      }
    };
    // Get the queryquery, filterquery and mapquery from localstorage
    let qq = localStorage.getItem('qq');
    if (qq) {
      qq = JSON.parse(qq);
    }
    let fq = localStorage.getItem('fq');
    if (fq) {
      fq = JSON.parse(fq);
    }
    let mq = localStorage.getItem('mq');
    if (mq) {
      mq = JSON.parse(mq);
    }
    // If qQuery has a value, the qq needs to be updated with said value
    if (qQuery) {
      body.query.bool.must.push(qQuery);
      localStorage.removeItem('qq');
      localStorage.setItem('qq', JSON.stringify(qQuery));
    } else if (qQuery === undefined) {
      localStorage.removeItem('qq');
    } else if (qq) {
      body.query.bool.must.push(qq);
    }

    if (fQuery) {
      body.query.bool.must.push(...fQuery.must);
      localStorage.removeItem('fq');
      localStorage.setItem('fq', JSON.stringify([...fQuery.must]));
    } else if (fq) {
      // a filter query was found in the localstorage
      body.query.bool.must.push(fq);
    }
    // else if (fQuery === undefined) { // no filter parameters were given
    //   localStorage.removeItem('fq');
    // }

    if (mQuery) {
      body.query.bool.must.push(mQuery);
      localStorage.removeItem('mq');
      localStorage.setItem('mq', JSON.stringify(mQuery));
    } else if (mq) {
      body.query.bool.must.push(mq);
    }
    const query = JSON.stringify(body);
    localStorage.setItem('query', query);
    const session = JSON.parse(localStorage.getItem('session'));
    if (session && session['tenant']) {
      this.store.dispatch(new LoadMarketposts(query));
    }
  }

  containerTypeLabelLength(index, containerTypes) {
    const length = containerTypes[index].label.split(/(\s+)/)[0];
    return length;
  }
  containerTypeLabelText(index, containerTypes) {
    const text = containerTypes[index].label.split(/(\s+)/)[2];
    return text;
  }

  location(term): Observable<any> {
    const options = {
      include_internal_locations: true,
      cities_only: true
    };
    const results = this.autocompleteService
      .search('location', term, options)
      .pipe(map(locations => {
        if (locations) {
          return locations.features.map(location => {
            const properties = location.properties;
            const geometry = location.geometry;
            let name = properties.name;
            if (properties.postcode) {
              name += ` (${properties.postcode})`;
            }
            if (properties.country) {
              name += `, ${properties.country}`;
            }
            return {
              id: properties['@id'],
              shortName: properties.short_name || '',
              name,
              country: properties.country || null,
              longitude: geometry.coordinates[0],
              latitude: geometry.coordinates[1]
            };
          });
        }
      }));
    return results;
  }

  locationFormatter = (x: { name: String; postcode?: String }) => {
    return x.postcode ? x.name + ' (' + x.postcode + ')' : x.name;
  }

  privateLocation(term): Observable<any> {
    const options = {
      include_internal_locations: true,
      cities_only: true
    };
    const results = this.autocompleteService
      .search('location', term, options)
      .pipe(map(locations => {
        if (locations) {
          return locations.features.map(location => {
            if (location.properties.visibility === 'private') {
              return {
                id: location.properties.id,
                shortName: location.properties.short_name || null,
                name: location.properties.name || null,
                address: location.properties.address || null,
                longitude: location.geometry.coordinates[0],
                latitude: location.geometry.coordinates[1],
                public_location: location.properties.public_location || null,
                visibility: location.properties.visibility
              };
            } else {
              return {
                id: location.properties['@id'],
                shortName: location.properties.short_name || '',
                name: location.properties.postcode
                  ? location.properties.name +
                  ' (' +
                  location.properties.postcode +
                  ')'
                  : location.properties.name,
                longitude: location.geometry.coordinates[0],
                latitude: location.geometry.coordinates[1],
                visibility: location.properties.visibility
              };
            }
          });
        }
      }));
    return results;
  }

  reverseGeocode(latitude: string, longitude: string) {
    console.log(`Reversing lat: ${latitude}, lon: ${longitude}`);
    // return this.autocompleteService.search('reverse', { latitude: latitude, longitude: longitude });
  }
  address(term): Observable<any> {
    const results = this.autocompleteService
      .search('location', term)
      .pipe(map(locations => {
        if (locations) {
          return (
            locations.features
              // .filter(location => location.properties.street)
              .map(location => {
                return {
                  id: location.properties['@id'],
                  street:
                    location.properties.street ||
                    location.properties.name ||
                    '',
                  housenumber: location.properties.housenumber || '',
                  postcode: location.properties.postcode || '',
                  city: location.properties.city || '',
                  longitude: location.geometry.coordinates[0],
                  latitude: location.geometry.coordinates[1]
                };
              })
          );
        }
      }));
    return results;
  }

  addressFormatter = (
    x:
      | {
        street: string;
        housenumber?: string;
        postcode?: string;
        city?: string;
      }
      | string
  ) => {
    if (typeof x === 'string') {
      return x;
    }
    if (x['fullAddress']) {
      return x['fullAddress'];
    }
    return `${x.street ? x.street : ''} ${x.housenumber ? x.housenumber : ''} ${
      x.postcode ? x.postcode : ''
      } ${x.city ? x.city : ''}`;
  }
}
