import { Action } from '@ngrx/store';
import * as types from '../types/session.types';

export class Login implements Action {
  readonly type = types.session.LOGIN;
  constructor(public payload?: any) {}
}

export class LoginSuccess implements Action {
  readonly type = types.session.LOGIN_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoginFail implements Action {
  readonly type = types.session.LOGIN_FAILED;
  constructor(public payload: any) {}
}

export class Refresh implements Action {
  readonly type = types.session.REFRESH;
  constructor(public payload?: any) {}
}

export class RefreshSuccess implements Action {
  readonly type = types.session.REFRESH_SUCCEEDED;
  constructor(public payload: any) {}
}

export class RefreshFail implements Action {
  readonly type = types.session.REFRESH_FAILED;
  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = types.session.LOGOUT;
  constructor(public payload?: any) {}
}

export class PickTenant implements Action {
  readonly type = types.session.PICK_TENANT;
  constructor(public payload: any) {}
}

export class DropTenant implements Action {
  readonly type = types.session.DROP_TENANT;
  constructor(public payload?: any) {}
}

export type All =
  | Login
  | LoginSuccess
  | LoginFail
  | Refresh
  | RefreshSuccess
  | RefreshFail
  | Logout
  | PickTenant
  | DropTenant;
