/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sso-login.component";
import * as i2 from "../services/saml.service";
var styles_SsoLoginComponent = [];
var RenderType_SsoLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SsoLoginComponent, data: {} });
export { RenderType_SsoLoginComponent as RenderType_SsoLoginComponent };
export function View_SsoLoginComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SsoLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sso-login", [], null, null, null, View_SsoLoginComponent_0, RenderType_SsoLoginComponent)), i0.ɵdid(1, 114688, null, 0, i1.SsoLoginComponent, [i2.SamlService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsoLoginComponentNgFactory = i0.ɵccf("app-sso-login", i1.SsoLoginComponent, View_SsoLoginComponent_Host_0, {}, {}, []);
export { SsoLoginComponentNgFactory as SsoLoginComponentNgFactory };
