import { AuthService } from '../user/user.auth';
import { State } from '../store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

/**
 * Main component for the /admin page.
 *
 * @export
 * @class AdminComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit, OnDestroy {
  title = extract('Settings');
  isAdmin: boolean;
  routes = [
    {
      url: '/settings/me',
      title: extract('My information'),
      description: extract('See your account information'),
      icon: 'fa-info-circle'
    },
    {
      url: '/settings/info',
      title: extract('Company information'),
      description: extract('View and edit the company contact information'),
      allowedRoles: ['admin'],
      icon: 'fa-building'
    },
    {
      url: '/settings/notifications',
      title: extract('My email notifications'),
      description: extract('Enable or disable your email notifications'),
      allowedSubscriptions: ['MARKETPLACE'],
      icon: 'fa-bell'
    },
    {
      url: '/settings/circles',
      title: extract('Circles'),
      description: extract('List and create the Circles of your company'),
      allowedSubscriptions: ['MARKETPLACE', 'MATCHING'],
      icon: 'fa-circle'
    },
    {
      url: '/settings/locations',
      title: extract('Locations'),
      description: extract(
        'Create, edit and delete the locations of your company'
      ),
      allowedSubscriptions: ['MARKETPLACE', 'MATCHING', 'TMS'],
      icon: 'fa-map-marker'
    },
    {
      url: '/settings/locations/new',
      title: extract('Add new location'),
      description: extract('Quick link to add a new planning location'),
      icon: 'fa-plus',
      allowedSubscriptions: ['MARKETPLACE', 'MATCHING', 'TMS']
    },
    {
      url: '/settings/users',
      title: extract('Users'),
      description: extract('Create, edit and delete the users in your company'),
      allowedRoles: ['admin'],
      icon: 'fa-users'
    },
    {
      url: '/settings/users/create',
      title: extract('Add new user'),
      description: extract('Quick link to add a new user'),
      icon: 'fa-user-plus',
      allowedRoles: ['admin']
    },
    {
      url: '/tms/drivers',
      title: extract('Drivers'),
      description: extract(
        'Create, edit and delete the drivers in your company'
      ),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-car'
    },
    {
      url: '/tms/drivers/new',
      title: extract('Add new driver'),
      description: extract('Quick link to add a new driver'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-plus'
    },
    {
      url: '/settings/subscriptions',
      title: extract('Subscriptions'),
      description: extract('Start or stop your subscriptions'),
      allowedRoles: ['admin'],
      icon: 'fa-sync'
    },
    {
      url: '/settings/credits',
      title: extract('Credits'),
      description: extract('Check the credit balance, add new credits'),
      allowedRoles: ['admin'],
      icon: 'fa-money-bill'
    }
  ];
  hasTMSSubscription: boolean;
  subscriptionCodes: string[];
  constructor(
    private store: Store<State>,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.store
      .select(state => state.user)
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.isAdmin = this.authService.isAdmin(user);
        this.authService
          .getSubscriptionsFromStoreOrAPI()
          .pipe(untilDestroyed(this))
          .subscribe(subscriptions => {
            if (!subscriptions) {
              return;
            }
            const tmsSub = subscriptions.filter(
              sub => sub.subscriptionType.code === 'TMS'
            );
            this.hasTMSSubscription = tmsSub && tmsSub.length > 0;
            this.subscriptionCodes = [
              ...subscriptions.map(s => s.subscriptionType.code)
            ];
          });
      });
  }

  userIsAllowedRoute(route) {
    if (!route.allowedRoles && !route.allowedSubscriptions) {
      return true;
    }
    if (
      route.allowedRoles &&
      route.allowedRoles.indexOf('admin') !== -1 &&
      this.isAdmin
    ) {
      return true;
    }
    if (
      route.allowedSubscriptions &&
      this.subscriptionCodes &&
      this.subscriptionCodes.length > 0 &&
      this.subscriptionCodes.some(
        sub => route.allowedSubscriptions.indexOf(sub) !== -1
      )
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy() { }
}
