<nav class="navbar navbar-expand navbar-light bg-light mb-4 pt-2 d-none d-sm-flex flex-wrap d-flex justify-content-between ">
  <ul class="navbar-nav">
    <li class="nav-item">
      <button class="btn" *ngIf="showBackButton" (click)="navigateBack()">
        <i class="far fa-arrow-left"></i>
      </button>
    </li>
  </ul>
  <h3 class="d-flex align-items-center">{{ navbarTitle | translate }}</h3>
  <div>
    <div *ngIf="!showButton"></div>
    <span *ngIf="showDanger" class="text-danger mr-3" (click)="dangerClick()">
      <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
      {{ dangerText | translate }}
    </span>
    <button *ngIf="showButton" class="btn text-white" [ngClass]="buttonType" (click)="buttonClick()">
      <i class="far" [ngClass]="buttonIcon"></i>
      <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
      {{ buttonText | translate }}
    </button>
  </div>
</nav>
