import { IconService } from 'app/services/icon.service';
import { Subject } from 'rxjs';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  FormControl, AbstractControl, ValidationErrors
} from '@angular/forms';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as DateFns from 'date-fns';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Transport } from 'app/core/store/models/transport.model';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../../shared/datepicker-config';
import { PaymentNote } from 'app/core/store/models/payment-note.model';
import { NewTaxRateModalComponent } from '../new-tax-rate-modal/new-tax-rate-modal.component';
import { Business } from 'app/core/store/models/business.model';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class InvoiceFormComponent implements OnChanges {
  @Input()
  customers: Business[];
  @Input()
  isLoadingCustomers: boolean;
  @Input()
  taxRates: any[];
  @Input()
  isLoadingTaxRates: boolean;
  @Input()
  isLoading: boolean;
  @Input()
  transports: Transport[];
  @Input()
  invoice: PaymentNote;

  @Output()
  invoiceFormSubmitted = new EventEmitter();
  @Output()
  onSaveAndDownloadAsPDF = new EventEmitter();
  @Output()
  searchTransports = new EventEmitter<{
    query?: string,
    since?: string,
    until?: string
  }>();
  @Output()
  selectTransport = new EventEmitter<string>();
  @Output()
  removeTransport = new EventEmitter<string>();
  @Output()
  customerSelected = new EventEmitter<any>();
  @Output()
  loadNextTransportPage = new EventEmitter<any>();

  invoiceForm: FormGroup;
  taxRateExpr = new RegExp(/[0-9]{1,3}/);
  addTagText = 'Add tax rate';
  query: string = '';
  since?: string;
  until?: string;
  searchTextChanged = new Subject();
  submitAttempt: boolean;
  nextPage: any;
  showDateSelection: boolean = false;
  dateSelection: string;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  initialized = false;

  constructor(
    private iconService: IconService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(search => this.searchTransports.emit({query: search.toString(), since: this.since, until: this.until}))
      )
      .subscribe(() => { });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.taxRates) {
      this.taxRates = changes.taxRates.currentValue;
    }
    if (changes && changes.invoice) {
      this.invoice = changes.invoice.currentValue;
    }
    if (this.taxRates && !this.invoiceForm) {
      this.createForm();
    }
    if (this.invoice && this.taxRates) {
      this.patchForm();
    }
  }

  patchForm() {
    this.invoiceForm.patchValue({
      business: this.invoice.business,
      notes: this.invoice.notes,
      date: new Date(
        DateFns.getYear(this.invoice.date),
        DateFns.getMonth(this.invoice.date),
        DateFns.getDate(this.invoice.date)
      ),
      dueDate: new Date(
        DateFns.getYear(this.invoice.dueDate),
        DateFns.getMonth(this.invoice.dueDate),
        DateFns.getDate(this.invoice.dueDate)
      )
    });
    const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
    if (this.invoice.items && !this.initialized) {
      this.invoice.items.forEach(item => {
        if (item.transport) {
          invoiceItems.at(invoiceItems.value.length - 1).patchValue({
            transport: item.transport
          });
        }
        invoiceItems.at(invoiceItems.value.length - 1).patchValue({
          id: item.id,
          description: item.description,
          quantity: item.quantity,
          unitPrice: item.unitPrice,
          taxRate: item.taxRate,
          total: item['totalInclTaxes'].toFixed(2)
        });
        this.addInvoiceItem();
      });

      this.initialized = true;
    }
  }
  createForm() {
    const today = new Date();
    const dueDate = new Date(
      DateFns.getYear(DateFns.addDays(today, 30)),
      DateFns.getMonth(DateFns.addDays(today, 30)),
      DateFns.getDate(DateFns.addDays(today, 30))
    );
    this.invoiceForm = this.fb.group({
      business: [null, Validators.required],
      date: [today, Validators.required],
      dueDate: [dueDate, Validators.required],
      notes: [null],
      items: this.fb.array([this.createInvoiceItem()], this.invoiceItemValidator()),
    });
  }

  get customerAddress() {
    if (
      !this.invoiceForm ||
      !this.invoiceForm.value ||
      !this.invoiceForm.get('business').value
    ) {
      return;
    }
    return this.invoiceForm.get('business').value['billingAddress'];
  }
  get customerMainContact() {
    if (
      !this.invoiceForm ||
      !this.invoiceForm.value ||
      !this.invoiceForm.get('business').value
    ) {
      return;
    }
    return this.invoiceForm.get('business').value['defaultContact'];
  }

  createInvoiceItem(): FormGroup {
    const group = this.fb.group({
      id: null,
      description: [''],
      quantity: [1],
      transport: [null],
      unitPrice: [0],
      taxRate: [this.taxRates[0], this.taxRateValidator()],
      total: [null]
    });
    return group;
  }

  addInvoiceItem(): void {
    const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
    invoiceItems.push(this.createInvoiceItem());
  }
  removeInvoiceItem(index): void {
    const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
    const item = invoiceItems.value[index];
    if (item.transport) {
      this.removeTransport.emit(item.transport.id);
    }
    invoiceItems.removeAt(index);
  }

  handleCustomerSelected() {
    const customer = this.invoiceForm.get('business').value;
    const customerId = customer && customer.id ? customer.id : null;
    this.customerSelected.emit(customerId);

    // Remove all selected transports
    const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
    invoiceItems.value.forEach(control => {
      if (!control.transport) {
        return;
      }
      if (
        control.transport.customer &&
        control.transport.customer.id === customerId
      ) {
        return;
      }
      const index = invoiceItems.value.indexOf(control);
      invoiceItems.removeAt(index);
    });
  }

  openTaxRateModal(itemIndex, rate) {
    const modalRef = this.modalService.open(NewTaxRateModalComponent);
    modalRef.result.then(result => {
      const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
      invoiceItems.at(itemIndex).patchValue({
        taxRate: result
      });
      this.updateTotal(itemIndex);
    });
  }

  search($event) {
    this.searchTextChanged.next($event.target.value);
  }

  /**
   * Duplicates the given invoice item
   * @param index index of the invoice item
   */
  duplicateItem(index) {
    this.addInvoiceItem();
    const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
    const item = Object.assign({}, invoiceItems.at(index).value);

    // Set item id to null if present
    if (item.id) {
      item.id = null;
    }
    invoiceItems.at(invoiceItems.value.length - 1).patchValue(item);
  }

  /**
   * Updates the total of the given invoice item based on the quantity, unit price
   * and tax rate
   * @param index index of the invoice item
   */
  updateTotal(index) {
    const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
    const item = invoiceItems.value[index];
    if (
      !item.quantity ||
      !item.unitPrice ||
      !item.taxRate ||
      item.taxRate.rate === null
    ) {
      return;
    }

    item.total =
      +item.quantity * +item.unitPrice;

    if (item.taxRate.rate > 0) {
      item.total += ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate;
    }

    invoiceItems.at(index).setValue(item);
  }

  get total() {
    const invoiceItems = this.invoiceForm.controls['items'].value;
    return invoiceItems
      .map(item => +item.total)
      .reduce((total1, total2) => total1 + total2);
  }
  get subTotal() {
    const invoiceItems = this.invoiceForm.controls['items'].value;
    return invoiceItems
      .map(item => +item.quantity * +item.unitPrice)
      .reduce((total1, total2) => total1 + total2);
  }
  get taxes() {
    const invoiceItems = this.invoiceForm.controls['items'].value;
    return invoiceItems
      .map(item => {
        // If an item doesn't have a tax rate it is invalid
        if (!item.taxRate || !item.taxRate.rate) {
          return 0;
        }
        return (
          ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate['rate']
        );
      })
      .reduce((total1, total2) => total1 + total2);
  }

  handleTransportSelected(transport: Transport) {
    if (!transport.tariff) {
      return;
    }
    const description = transport.tariff.label;
    const unitPrice = transport.tariff.price;
    const invoiceItems = <FormArray>this.invoiceForm.controls['items'];
    let index = invoiceItems.value.length - 1;

    if (invoiceItems.at(index).get('description').value) {
      this.addInvoiceItem();
      index++;
    }
    const item = {
      description,
      unitPrice,
      transport
    };
    invoiceItems.at(index).patchValue(item);
    this.updateTotal(index);
    this.addInvoiceItem();

    // Select customer if transport has one so we can only add transports of exactly one customer
    if (
      transport.customer &&
      transport.customer['id'] &&
      !this.invoiceForm.get('business').value
    ) {
      this.invoiceForm.patchValue({
        business: transport.customer
      });
      this.handleCustomerSelected();
    }
    this.selectTransport.emit(transport.id);
  }

  /**
   * Emits the invoice form value
   */
  onSubmit() {
    if (this.invoiceForm.invalid) {
      this.markFormGroupAsTouched(this.invoiceForm);
      return;
    }
    const invoiceFormValue = this.parseInvoiceFormValue();
    this.invoiceFormSubmitted.emit(invoiceFormValue);
  }
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });
  }
  parseInvoiceFormValue() {
    const invoiceFormValue = JSON.parse(JSON.stringify(this.invoiceForm.value));

    invoiceFormValue.items = invoiceFormValue.items.filter(
      item => item.description || item.transport
    );
    invoiceFormValue.date = DateFns.setHours(invoiceFormValue.date, 12);
    invoiceFormValue.dueDate = DateFns.setHours(invoiceFormValue.dueDate, 12);
    invoiceFormValue.business = invoiceFormValue.business
      ? invoiceFormValue.business['@id']
      : null;
    invoiceFormValue.items.forEach(item => {
      item.taxRate = {
        id: item.taxRate['id']
      };
      if (!item.transport) {
        return;
      }
      item.transport = {
        id: item.transport['id']
      };
    });
    return invoiceFormValue;
  }

  getTransportIcon(transport) {
    return this.iconService.getTransportTypeIcon(transport.transportType);
  }
  saveAndDownloadAsPDF() {
    if (this.invoiceForm.invalid) {
      return;
    }
    const invoiceFormValue = this.parseInvoiceFormValue();
    this.onSaveAndDownloadAsPDF.emit(invoiceFormValue);
  }
  getPrimaryTaskLocation(transport: Transport) {
    const primaryTask = transport.transportTasks.find(t => t.primaryTask);
    if (!primaryTask.location) {
      return;
    }
    return primaryTask.location.name;
  }

  getPrimaryTaskDate(transport: Transport) {
    return transport.primaryTaskDateTimeSpecified ? moment(transport.primaryTaskDate).format("DD/MM HH:mm") : moment(transport.primaryTaskDate).format("DD/MM");

  }

  onScrollDown() {
    this.loadNextTransportPage.emit(true);
  }

  handleDateSelection(dateSelectionEvent: {
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
  }) {
    const sinceDateStruct: NgbDateStruct = (dateSelectionEvent.fromDate) ? dateSelectionEvent.fromDate : null;
    const untilDateStruct: NgbDateStruct = (dateSelectionEvent.toDate) ? dateSelectionEvent.toDate : null

    if (sinceDateStruct !== null && untilDateStruct !== null) {
      const sinceDate = moment(sinceDateStruct.year + '-' + sinceDateStruct.month + '-' + sinceDateStruct.day, 'YYYY-MM-DD');
      const untilDate = moment(untilDateStruct.year + '-' + untilDateStruct.month + '-' + untilDateStruct.day, 'YYYY-MM-DD');
      this.since = sinceDate.format('YYYY-MM-DD');
      this.until = untilDate.format('YYYY-MM-DD')
      this.dateSelection = sinceDate.format('DD/MM/YYYY') + ' - ' + untilDate.format('DD/MM/YYYY');
      this.showDateSelection = false;
      this.searchTransports.emit({query: this.query, since: this.since, until: this.until})
    } else {
      this.since = null;
      this.until = null;
    }
  }

  // check if exists at least one item with property "total"
  invoiceItemValidator() {
    return (c: AbstractControl): {[key: string]: any} => {
      var valid = false;
      c.value.forEach(function (item) {
          if (item.total !== null) {
            valid = true;
          }
      });

      return valid ? null : { 'invoiceItemValidator': { valid: valid }};
    }
  }

  taxRateValidator() {
    return (c: AbstractControl): {[key: string]: any} => {
      var valid = false;
      // dont care about validation if its dummy item
      if (c.parent && c.parent.controls['description'].value === "") {
        valid = true;
      }

      if (c.value && c.value.id) {
        valid = true;
      }
      return valid ? null : { 'taxRateValidator': { valid: valid }};
    }
  }

}

export declare class InvoiceItem {
    total: number
}
