import { ToastService } from 'app/services/toast.service';
import { Alert } from '../../store/models/alert.model';
import { ApiService } from '../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
})
export class EditLocationComponent {
  alert: Alert;
  location: Object;
  id: string;
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private toastr: ToastService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api
          .get({
            path: '/locations/' + this.id
          })
          .subscribe(response => {
            this.location = response;
          });
      }
    });
  }

  updateLocation(locationFormValue) {
    const body = Object.assign(locationFormValue);
    body.id = this.id;
    body.extraData = JSON.parse(locationFormValue.extraData);
    this.api
      .put({
        path: '/locations/' + this.id,
        body: body
      })
      .subscribe(
        res => {
          this.toastr.showSuccess({
            message: 'Location saved.'
          });
        },
        err => {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: err
          });
        }
      );
  }
}
