import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';
import { LoadTenants } from 'app/core/store/actions/tenants.actions';
import { PsaCompany } from 'app/core/store/models/psa-company.model';
import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-psa-link-account-modal',
  templateUrl: './psa-link-account-modal.component.html',
  styleUrls: ['./psa-link-account-modal.component.scss']
})
export class PsaLinkAccountModalComponent implements OnInit {
  company: PsaCompany;
  loading = false;
  message$: Observable<string>;
  linkAccountForm: FormGroup;

  constructor(
    private api: ApiService,
    private modal: NgbActiveModal,
    private store: Store<State>,
    private toastr: ToastService,
    private translate: TranslateService,
    private fb: FormBuilder,
  ) {
    this.linkAccountForm = this.fb.group({
      accept: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.message$ = this.translate.get(
      `You are about to link the ePortal Account "{{ name }}" to your Hakka account.`,
      { name: this.company.name },
    ).pipe(map(x => x.replace('{{ name }}', this.company.name)));
  }

  close() {
    this.modal.close();
  }

  submit() {
    this.loading = true;
    this.api.post({
      path: '/link-psa-account',
      body: { id: this.company.id },
      params: {
        headers: { 'Content-Type': 'application/ld+json' },
      },
    }).toPromise().then(
      () => this.store.dispatch(new LoadTenants({
        onSuccess: () => {
          this.modal.close();
          this.toastr.showSuccess({
            title: 'Account successfully linked',
          });
        },
        onFailure: () => this.modal.close(),
      })),
      () => {
        this.modal.close();
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to link tenant to PSA company',
        });
      }
    );
  }
}
