import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/user.types';
import * as UserActions from '../actions/user.actions';
import * as RouterActions from '../actions/router.actions';

@Injectable()
export class UserEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadUser$: Observable<Action> = this.actions$.pipe(
    ofType(types.users.LOAD_USER),
    concatMap(() => this.api.get({ path: '/me/user_context' }).pipe(
      map(data => new UserActions.LoadUserSuccess(data)),
      catchError(error => of(new UserActions.LoadUserFailed(error)))
    )),
  );

  /**
   * When the user requests a reset password link
   *
   * @type {Observable<Action>}
   * @memberOf UserEffects
   */
  @Effect()
  resetUser$: Observable<Action> = this.actions$.pipe(
    ofType(types.users.RESET),
    map((action: UserActions.ResetUser) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user/reset',
      body: JSON.stringify(payload),
      params: { responseType: 'text' }
    }).pipe(
      map(data => new UserActions.ResetUserSuccess(data)),
      catchError(error => of(new UserActions.ResetUserFailed(error)))
    )),
  );

  @Effect() resetUserUpdate$: Observable<Action> = this.actions$.pipe(
    ofType(types.users.RESET_UPDATE),
    map((action: UserActions.ResetUserUpdate) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user/' + payload.user + '/code/' + payload.code,
      body: JSON.stringify({ password: payload.password })
    }).pipe(
      map(data => new UserActions.ResetUserUpdateSuccess(data)),
      catchError(error => of(new UserActions.ResetUserUpdateFailed(error)))
    )),
  );

  @Effect() ResetUserSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(types.users.RESET_SUCCEEDED),
    concatMap(() => of(new RouterActions.Go({ path: ['user/reset/success'] })))
  );

  @Effect()
  updateUser$: Observable<Action> = this.actions$.pipe(
    ofType(types.users.UPDATE_USER),
    map((action: UserActions.UpdateUser) => action.payload),
    concatMap(payload => this.api.put({
      path: '/users/' + payload.id, body: payload.body
    }).pipe(
      map(data => new UserActions.UpdateUserSuccess(data)),
      catchError(error => of(new UserActions.UpdateUserFailed(error)))
    )),
  );
}
