<form (ngSubmit)="onSubmit()" [formGroup]="locationForm" class="pb-3">
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Name' | translate }}</label>
        <input class="form-control" formControlName="name" [ngClass]="{'is-invalid': locationForm.controls['name'].invalid && locationForm.controls['name'].touched }">
        <div class="invalid-feedback">
          {{ 'Name is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Short Name' | translate }}</label>
        <input class="form-control" formControlName="shortName" [ngClass]="{'is-invalid': locationForm.controls['shortName'].invalid && locationForm.controls['shortName'].touched }">
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Address' | translate }}</label>
    <input class="form-control" formControlName="fullAddress" [ngClass]="{'is-invalid': locationForm.controls['fullAddress'].invalid && locationForm.controls['fullAddress'].touched }">
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Latitude' | translate }}</label>
        <input class="form-control" formControlName="latitude" [ngClass]="{'is-invalid': locationForm.controls['latitude'].invalid && locationForm.controls['latitude'].touched }">
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Longitude' | translate }}</label>
        <input class="form-control" formControlName="longitude" [ngClass]="{'is-invalid': locationForm.controls['longitude'].invalid && locationForm.controls['longitude'].touched }">
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Location Type' | translate }}</label>
        <ng-select id="locationType" [items]="locationTypes" bindValue="id" bindLabel="label" placeholder="{{ 'Select location type' | translate }}"
          formControlName="locationType">
        </ng-select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Connector' | translate }}</label>
        <ng-select id="connector" [items]="connectors" placeholder="{{ 'Select connector' | translate }}"
          formControlName="connector">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Code' | translate }}</label>
    <input class="form-control" formControlName="code" [ngClass]="{'is-invalid': locationForm.controls['code'].invalid && locationForm.controls['code'].touched }">
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Extra Data' | translate }}</label>
    <input class="form-control" formControlName="extraData" [ngClass]="{'is-invalid': locationForm.controls['extraData'].invalid && locationForm.controls['extraData'].touched }">
  </div>

  <!-- SHOW MORE PROPERTIES IF LOCATION IS WEIGHT !-->
  <div *ngIf="showWeightProperties()">
    <h4>{{ 'External properties' | translate}}</h4>
    <hr />
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="custom-control custom-checkbox genset-checkbox">
            <input
              type="checkbox"
              id="genset-input"
              class="custom-control-input"
              formControlName="prePaid"
            />
            <span class="custom-control-label">{{ 'PrePaid' | translate }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label class="center-block">{{ 'Solas Officer' | translate }}</label>
          <input class="form-control"
                 formControlName="solasOfficer"
                 [ngClass]="{'is-invalid': locationForm.controls['solasOfficer'].invalid && locationForm.controls['solasOfficer'].touched }">
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="center-block">{{ 'Service Price' | translate }}</label>
          <input class="form-control"
                 formControlName="servicePrice"
                 [ngClass]="{'is-invalid': locationForm.controls['servicePrice'].invalid && locationForm.controls['servicePrice'].touched }">
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="center-block">{{ 'Prenotification managers' | translate }}</label>
      <ng-select
        formControlName="prenotificationManagers" data-cy="aliasType"
        [items]="tenants"
        [multiple]="true"
        [closeOnSelect]="false"
        bindLabel="name"
        bindValue="@id"
        placeholder="{{ 'Select tenant' | translate }}"
      ></ng-select>
    </div>
  </div>

  <!-- SHOW ALIAS FORM !-->
  <div *ngIf="showAliases()">
    <label class="center-block">{{ 'Aliases' | translate }}</label>
    <div
      formArrayName="aliases" class="mb-3"
      *ngFor="let alias of locationForm.get('aliases')['controls']; let i = index"
    >
      <div [formGroupName]="i" class="form-row">
        <div class="col-6">
          <input class="form-control" formControlName="label" placeholder="Location alias" />
        </div>
        <!-- TODO: this should be pulled from backend !-->
        <ng-select
          class="col-5" formControlName="code" data-cy="aliasType"
          [items]="['AVANTIDA', 'SCAC', 'TAMTRON']"
          placeholder="{{ 'Select alias type' | translate }}"
        ></ng-select>
        <div class="col-1">
          <button
            type="button" class="btn btn-danger text-white"
            [disabled]="i === 0" (click)="removeAlias(i)"
          ><i class="far fa-times"></i></button>
          <button
            *ngIf="i === locationForm.get('aliases')['controls'].length - 1"
            type="button" class="btn btn-primary"
            (click)="addAlias()"
          ><i class="far fa-plus"></i></button>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-primary text-white" type="submit" [disabled]="locationForm.invalid || locationForm.pristine">
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span>{{ 'Submit' | translate }}</span>
  </button>
</form>
