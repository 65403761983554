import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoadWallet, UpdateWallet } from 'app/core/store/actions/wallet.actions';
import { State } from 'app/core/store/store.model';
import { Wallet } from "../../../../store/models/wallet.model";
import { untilDestroyed } from "../../../../../shared/rxjs-util";
import * as Types from "../../../../store/types/wallet.types";
import { ToastService } from "../../../../../services/toast.service";
import { Actions, ofType } from "@ngrx/effects";

@Component({
  selector: 'app-credits-warning',
  templateUrl: 'credits-warning.component.html'
})
export class CreditsWarningComponent implements OnInit, OnDestroy {
  wallet: Wallet;

  thresholdForm: FormGroup;
  loading: boolean;

  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
    private updateWallet$: Actions,
    private toastr: ToastService,
  ) {
    updateWallet$
        .pipe(ofType(Types.wallet.UPDATE_WALLET_SUCCEEDED))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.loading = false;
          this.toastr.showSuccess({
            message: 'Success!'
          });
          this.store.dispatch(new LoadWallet());
        });

    updateWallet$
        .pipe(ofType(Types.wallet.UPDATE_WALLET_FAILED))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.loading = false;
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: 'Update failed'
          });
        });

  }

  ngOnInit() {
    this.createForm();
    this.store.dispatch(new LoadWallet());
    this.store.select('wallet')
        .pipe(untilDestroyed(this))
        .subscribe(state => {
          if (state) {
            this.wallet = state;
            this.thresholdForm.patchValue(state);
          }
        });
  }

  ngOnDestroy() { }

  createForm() {
    this.thresholdForm = this.fb.group({
      warningThreshold: [null, Validators.required]
    });
  }

  onSubmit() {
    if (this.thresholdForm.valid) {
      this.loading = true;
      const payload = {
        id: this.wallet.id,
        body: {
          warningThreshold: this.thresholdForm.value.warningThreshold.toString(),
        }
      };
      this.store.dispatch(new UpdateWallet(payload));
    } else {
      this.thresholdForm.get('warningThreshold').markAsTouched();
    }
  }
}
