import * as types from '../types/container-type.types';
import * as ContainerTypesActions from '../actions/container-types.actions';

const INITIAL_STATE = null;

export function reducer(
  state = INITIAL_STATE,
  action: ContainerTypesActions.All
) {
  switch (action.type) {
    case types.containerTypes.LOAD_CONTAINER_TYPES_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}
