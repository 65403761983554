import { MyOffersComponent } from '../../marketplace/my-posts/my-offers/my-offers.component';
import { MarketPostOfferRepliesComponent } from './market-post-offer-replies/market-post-offer-replies.component';
import { MarketPostDemandRepliesComponent } from './market-post-demand-replies/market-post-demand-replies.component';
import { CompanyPostsComponent } from './company-posts.component';
import { AuthGuard } from '../user/user.authguard';
import { Routes } from '@angular/router';
import { MyDemandsComponent } from '../../marketplace/my-posts/my-demands/my-demands.component';
import { Route } from '../route.service';
import { DriverGuard } from '../auth/route-guards/driver.guard';
import { SubscriptionGuard } from '../auth/route-guards/subscription.guard';
var defaultDashboardGuards = [AuthGuard, SubscriptionGuard, DriverGuard];
var ɵ0 = { subscriptionCode: 'MARKETPLACE' }, ɵ1 = { subscriptionCode: 'MARKETPLACE' }, ɵ2 = { subscriptionCode: 'MARKETPLACE' }, ɵ3 = { subscriptionCode: 'MARKETPLACE' }, ɵ4 = { subscriptionCode: 'MARKETPLACE' };
var routes = Route.withShell([
    {
        path: 'company-posts',
        component: CompanyPostsComponent,
        canActivate: defaultDashboardGuards.slice(),
        data: ɵ0
    },
    {
        path: 'company-posts/demands/replies',
        component: MarketPostDemandRepliesComponent,
        canActivate: defaultDashboardGuards.slice(),
        data: ɵ1
    },
    {
        path: 'company-posts/offers/replies',
        component: MarketPostOfferRepliesComponent,
        canActivate: defaultDashboardGuards.slice(),
        data: ɵ2
    },
    {
        path: 'company-posts/demands',
        component: MyDemandsComponent,
        canActivate: defaultDashboardGuards.slice(),
        data: ɵ3
    },
    {
        path: 'company-posts/offers',
        component: MyOffersComponent,
        canActivate: defaultDashboardGuards.slice(),
        data: ɵ4
    }
]);
var CompanyPostsRoutingModule = /** @class */ (function () {
    function CompanyPostsRoutingModule() {
    }
    return CompanyPostsRoutingModule;
}());
export { CompanyPostsRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
