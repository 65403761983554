import { catchError, concatMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/address.types';
import * as AddressActions from '../actions/address.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class AddressEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  updateAddress$: Observable<Action> = this.actions$.pipe(
    ofType(types.address.UPDATE_ADDRESS),
    map((action: AddressActions.UpdateAddress) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/addresses/${payload.addressId}`,
      body: payload.body
    }).pipe(
      map(data => new AddressActions.UpdateAddressSuccess(data)),
      catchError(error => of(new AddressActions.UpdateAddressFail(error)))
    )),
  );

  @Effect()
  deleteAddress$: Observable<Action> = this.actions$.pipe(
    ofType(types.address.DELETE_ADDRESS),
    map((action: AddressActions.DeleteAddress) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/addresses/${payload.addressId}`
    }).pipe(
      map(() => new AddressActions.DeleteAddressSuccess(payload)),
      catchError(error => of(new AddressActions.DeleteAddressFail(error)))
    )),
  );
}
