/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar-top.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "./navbar-top.component";
import * as i7 from "@ngrx/store";
import * as i8 from "../route.service";
var styles_NavbarTopComponent = [i0.styles];
var RenderType_NavbarTopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarTopComponent, data: {} });
export { RenderType_NavbarTopComponent as RenderType_NavbarTopComponent };
function View_NavbarTopComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 > \u00A0"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getRouteUrl(_v.parent.context.index), ""); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.getRouteName(_co.getRouteUrl(_v.parent.context.index)))); _ck(_v, 3, 0, currVal_3); }); }
function View_NavbarTopComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.getRouteName(_co.getRouteUrl(_v.parent.context.index)))); _ck(_v, 0, 0, currVal_0); }); }
function View_NavbarTopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "d-flex align-items-center mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarTopComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["lastCrumb", 2]], null, 0, null, View_NavbarTopComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index !== (_co.breadCrumbs.length - 1)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NavbarTopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 20, "nav", [["class", "navbar navbar-expand navbar-light pb-0 pt-0 bg-light mb-2"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i5.ɵm, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "breadCrumbs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarTopComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(7, 0, null, null, 14, "div", [["class", "user-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "span", [["class", "name divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "strong", [["class", "fist-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", "\u00A0"])), i1.ɵppd(11, 1), (_l()(), i1.ɵeld(12, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵppd(14, 1), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["id", "tenant-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 16777216, null, null, 4, "button", [["class", "btn btn-link"], ["placement", "bottom"], ["routerLink", "/settings"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 212992, null, 0, i5.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i5.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef, i1.ApplicationRef], { placement: [0, "placement"], ngbTooltip: [1, "ngbTooltip"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(20, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "far fa-fw fa-cog"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadCrumbs; _ck(_v, 5, 0, currVal_0); var currVal_4 = "bottom"; var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 18, 1, i1.ɵnov(_v, 19).transform("Settings")), ""); _ck(_v, 18, 0, currVal_4, currVal_5); var currVal_6 = "/settings"; _ck(_v, 20, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), ((_co.user == null) ? null : _co.user.firstName))); _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), ((_co.user == null) ? null : _co.user.lastName))); _ck(_v, 13, 0, currVal_2); var currVal_3 = ((_co.tenant == null) ? null : _co.tenant.name); _ck(_v, 16, 0, currVal_3); }); }
export function View_NavbarTopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar-top", [], null, null, null, View_NavbarTopComponent_0, RenderType_NavbarTopComponent)), i1.ɵdid(1, 180224, null, 0, i6.NavbarTopComponent, [i7.Store, i2.Router, i8.Route], null, null)], null, null); }
var NavbarTopComponentNgFactory = i1.ɵccf("app-navbar-top", i6.NavbarTopComponent, View_NavbarTopComponent_Host_0, { title: "title", breadCrumbs: "breadCrumbs" }, {}, ["*"]);
export { NavbarTopComponentNgFactory as NavbarTopComponentNgFactory };
