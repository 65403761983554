import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { ApiService } from 'app/core/api/api.service';

@Injectable()
export class ValidationService {
  constructor(private api: ApiService) {}

  verifyVatNumberWithVIES(vatNumber: string, countryCode: string) {
    return this.api.get({
      path: `/verify-vat-number/${countryCode}/${vatNumber.slice(2)}`
    });
  }

  checkIfVatNumberExists(vatNumber: string) {
    return this.api.get({
      path: `/tenants?vatNumber=${vatNumber}`
    });
  }

  /**
   * Custom validator that checks if the container number is ISO 6346 compliant.
   * @returns null if valid
   */
  iso6346Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // Return early so the validator function doesn't fail on component init
      if (
        control.value === null ||
        control.value === ''
      ) {
        return null;
      }
      if (!control.value || control.value.length !== 11) {
        return { badIso6346: { value: control.value } };
      }
      const con = control.value.toUpperCase();
      const re = /^[A-Z]{4}\d{7}/;
      if (re.test(con)) {
        let sum = 0;
        for (let i = 0; i < 10; i++) {
          let n = con.substr(i, 1);
          if (i < 4) {
            n = '0123456789A?BCDEFGHIJK?LMNOPQRSTU?VWXYZ'.indexOf(
              con.substr(i, 1)
            );
          }
          n *= Math.pow(2, i);
          sum += n;
        }
        if (con.substr(0, 4) === 'HLCU') {
          sum -= 2;
        }
        sum %= 11;
        sum %= 10;
        const isValid = sum.toString() === con.substr(10);
        return isValid ? null : { badIso6346: { value: control.value } };
      } else {
        return { badIso6346: { value: control.value } };
      }
    };
  }

  reloadLocationGpsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value === null) {
        return null;
      }
      if (control.value && !control.value.latitude || !control.value.longitude) {
        return { missingGps: true };
      }
      return null;
    };
  }

  reloadExportLocationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value === null) {
        return null;
      }
      if (control.value && /\s/.test(control.value)) {
        return { whitespace: true };
      }
      return null;
    };
  }
}
