import { IconService } from 'app/services/icon.service';
import {
  Component,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { Transport } from 'app/core/store/models/transport.model';

@Component({
  selector: 'app-transport-info',
  templateUrl: './transport-info.component.html',
  styleUrls: ['./transport-info.component.scss']
})
export class TransportInfoComponent implements OnChanges {
  DISPLAY_PUBLIC_LOC_TRANSPORT_TASKS = ['load', 'unload'];

  @Input()
  transport: Transport;
  @Input()
  transportId: string;
  @Input()
  planned: boolean;
  @Input()
  driverId: string;

  error: string;
  constructor(private iconService: IconService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.transport && changes.transport.currentValue) {
      const newVal = changes.transport.currentValue;
      newVal.transportTasks.forEach(task => {
        if (!task.error) {
          task.error = null;
          return;
        }
        this.error = task.error;
      });
    }
  }

  getTransportIcon() {
    if (!this.transport || !this.transport.transportType) {
      return;
    }
    return this.iconService.getTransportTypeIcon(this.transport.transportType);
  }

  get numberOfTasks() {
    if (!this.transport) {
      return 0;
    }
    return this.transport.transportTasks.length;
  }
}
