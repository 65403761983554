import * as types from '../types/notification-subscription.types';
import * as NotificationSubscriptionsActions from '../actions/notification-subscriptions.actions';

const INITIAL_STATE = null;

/**
 * Reducer for all Tenant specific settings
 *
 * @export
 * @param {any} [state=INITIAL_STATE]
 * @param {NotificationSubscriptionActions.All} action
 * @returns
 */
export function reducer(
  state = INITIAL_STATE,
  action: NotificationSubscriptionsActions.All
) {
  switch (action.type) {
    case types.notificationSubscriptions
      .LOAD_NOTIFICATION_SUBSCRIPTIONS_SUCCEEDED:
      return [...action.payload['hydra:member']];
    // case types.notificationSubscriptions.UPDATE_NOTIFICATION_SUBSCRIPTIONS_SUCCEEDED:
    //   if (action.payload.type === 'offer') {
    //     state[0].containerTypes = action.payload.containerTypes;
    //     state[0].transportTypes = action.payload.transportTypes;
    //   } else if ( action.payload.type === 'demand') {
    //     state[1].containerTypes = action.payload.containerTypes;
    //     state[1].transportTypes = action.payload.transportTypes;
    //   }
    // return state;
    case types.notificationSubscriptions.CLEAR:
      return null;
  }
  return state;
}
