import { Invoice, PaymentNote } from '../models/payment-note.model';
import * as types from '../types/invoice.types';
import * as InvoiceActions from '../actions/invoice.actions';

export interface InvoicesState {
  invoices: PaymentNote[];
  filteredInvoices: PaymentNote[];
  totalFilteredInvoices: number;
}

const INITIAL_STATE = {
  invoices: null,
  filteredInvoices: null,
  totalFilteredInvoices: null
};

export function InvoicesReducer(
  state = INITIAL_STATE,
  action: InvoiceActions.All
): InvoicesState {
  switch (action.type) {
    case types.invoice.LOAD_INVOICES_SUCCEEDED:
      state.invoices = [...action.payload['hydra:member']];
      state.filteredInvoices = [...action.payload['hydra:member']];
      state.totalFilteredInvoices = action.payload['hydra:totalItems'];
      return Object.assign({}, state);

    case types.invoice.LOAD_INVOICE_SUCCEEDED:
      if (state.invoices) {
        const i = state.invoices.findIndex(i => i.id === action.payload.id);
        if (i !== false) {
          Object.assign(
              state.invoices[i],
              action.payload
          );
        }
      } else {
        state.invoices = (state.invoices || []).concat(action.payload);
      }
      return Object.assign({}, state);

    case types.invoice.LOAD_FILTERED_INVOICES_SUCCEEDED:
      state.filteredInvoices = [...action.payload['hydra:member']];
      state.totalFilteredInvoices = action.payload['hydra:totalItems'];
      return Object.assign({}, state);

    case types.invoice.UPDATE_INVOICE_SUCCEEDED:
      if (!state || !state.invoices || !state.filteredInvoices) {
        return state;
      }
      let invoices = [...state.invoices];
      let filteredInvoices = [...state.filteredInvoices];

      const invoiceIndex = invoices.findIndex(i => i.id === action.payload.id);
      const tempInvoice = Object.assign(
        {},
        state.invoices[invoiceIndex],
        action.payload
      );
      invoices[invoiceIndex] = tempInvoice;
      const filteredInvoiceIndex = filteredInvoices.findIndex(
        i => i.id === action.payload.id
      );
      const tempFilteredInvoice = Object.assign(
        {},
        filteredInvoices[filteredInvoiceIndex],
        action.payload
      );
      filteredInvoices[filteredInvoiceIndex] = tempFilteredInvoice;
      return Object.assign({}, state, {
        invoices,
        filteredInvoices
      });

    case types.invoice.DELETE_INVOICE_SUCCEEDED:
      if (!state || !state.invoices || !state.filteredInvoices) {
        return state;
      }
      invoices = [
        ...state.invoices.filter(
          (invoice: Invoice) => invoice.id !== action.payload
        )
      ];
      filteredInvoices = [
        ...state.filteredInvoices.filter(
          (invoice: Invoice) => invoice.id !== action.payload
        )
      ];
      return Object.assign({}, state, {
        invoices,
        filteredInvoices
      });

    case types.invoice.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
  }
  return state;
}
