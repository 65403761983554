<div class="d-flex justify-content-between align-items-center mb-1">
  <span>
    <span class="badge badge-dark">{{ 'Planning' | translate }}</span>
    <span class="ml-1">{{ tenant?.name }}</span>
  </span>
  <span>
    <i class="far fa-fw" [ngClass]="getTransportTypeIcon()"></i>
    {{ transport.transportType?.label | titlecase | translate }}
  </span>
  <span>{{ transport.container?.containerType?.label }}</span>
  <span>{{ transport.container?.liner?.name | titlecase }}</span>
  <span
    >{{ transport.container?.weight }}
    <span *ngIf="transport.container?.weight">T</span>
  </span>
  <span>
    <span class="icon" *ngIf="transport.genset">
      <i class="far fa-snowflake text-info"></i>
    </span>
    <span class="icon" *ngIf="transport.container?.adr">
      <i class="far fa-exclamation-triangle text-danger"></i>
    </span>
  </span>
  <span *ngIf="!isMatchingPoolOption">{{
    transport.reference | titlecase
  }}</span>
  <ng-content></ng-content>
</div>
<div class="form-section-darker p-1">
  <app-transport-tasks-table
    [showStatus]="false"
    [showReference]="!isMatchingPoolOption"
    [transportIsOwnedByCurrentTenant]="!isMatchingPoolOption"
    [transportTasks]="transport.transportTasks"
  ></app-transport-tasks-table>
  <div class="pt-2 text-smaller d-flex justify-content-between">
    <div>{{ transport.comment }}</div>
    <ng-content select="app-confidence-score"></ng-content>
  </div>
</div>
