import { Actions, ofType } from '@ngrx/effects';
import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatchOption } from 'app/core/store/models/matching.model';
import * as MatchingTypes from 'app/core/store/types/matching.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-matching-pool-option',
  templateUrl: './matching-pool-option.component.html',
  styleUrls: [
    './matching-pool-option.component.scss',
    './../matching-pool-item/matching-pool-item.component.scss'
  ]
})
export class MatchingPoolOptionComponent implements OnDestroy {
  @Input()
  option: MatchOption;
  @Input()
  isLoading: boolean;
  @Input()
  isRemovingMatchOption: boolean;

  @Output()
  submitReply = new EventEmitter<{
    matchingPoolItemMatch: string;
    text: string;
  }>();
  @Output()
  removeOption = new EventEmitter<string>();

  replyIsVisible = false;
  reply: string;
  submitAttempt: boolean;

  constructor(private updates$: Actions) {
    this.updates$
      .pipe(ofType(MatchingTypes.matching.CREATE_MATCH_REPLY_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.replyIsVisible = false;
        this.reply = null;
        this.submitAttempt = false;
      });
  }

  toggleReply() {
    this.replyIsVisible = !this.replyIsVisible;
  }

  submitReplyClicked() {
    this.submitAttempt = true;
    if (!this.reply) {
      return;
    }
    this.submitReply.emit({
      matchingPoolItemMatch: this.option['@id'],
      text: this.reply
    });
  }

  handleRemoveOption() {
    this.removeOption.emit(this.option.id);
    this.isRemovingMatchOption = true;
  }

  ngOnDestroy() { }
}
