import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as types from '../types/credit-note.types';
import * as CreditNoteActions from '../actions/credit-note.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class CreditNoteEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadCreditNotes$: Observable<Action> = this.actions$.pipe(
    ofType(types.creditNote.LOAD_CREDIT_NOTES),
    map((action: CreditNoteActions.LoadCreditNotes) => action.payload),
    concatMap(payload => this.api.get({
      path: `/crm/credit_notes?page=${payload && payload.page ? payload.page : 1}`
    }).pipe(
      map(data => new CreditNoteActions.LoadCreditNotesSuccess(data)),
      catchError(error => of(new CreditNoteActions.LoadCreditNotesFail(error)))
    )),
  );

  @Effect()
  loadFilteredCreditNotes$: Observable<Action> = this.actions$.pipe(
    ofType(types.creditNote.LOAD_FILTERED_CREDIT_NOTES),
    map((action: CreditNoteActions.LoadFilteredCreditNotes) => action.payload),
    concatMap(payload => this.api.get({
      path: '/crm/credit_notes'
        + '?page=' + (payload && payload.page ? payload.page : 1)
        + '&business.label=' + (payload && payload.query ? payload.query : '')
        + '&number=' + (payload && payload.query ? payload.query : '')
    }).pipe(
      map(data => new CreditNoteActions.LoadFilteredCreditNotesSuccess(data)),
      catchError(error => of(new CreditNoteActions.LoadFilteredCreditNotesFail(error)))
    )),
  );

  @Effect()
  createCreditNote$: Observable<Action> = this.actions$.pipe(
    ofType(types.creditNote.CREATE_CREDIT_NOTE),
    map((action: CreditNoteActions.CreateCreditNote) => action.payload),
    concatMap(payload => this.api.post({
      path: `/crm/credit_notes`, body: payload
    }).pipe(
      map(data => new CreditNoteActions.CreateCreditNoteSuccess(data)),
      catchError(error => of(new CreditNoteActions.CreateCreditNoteFail(error)))
    )),
  );

  @Effect()
  updateCreditNote$: Observable<Action> = this.actions$.pipe(
    ofType(types.creditNote.UPDATE_CREDIT_NOTE),
    map((action: CreditNoteActions.UpdateCreditNote) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/credit_notes/${payload.creditNoteId}`,
      body: payload.body
    }).pipe(
      map(data => new CreditNoteActions.UpdateCreditNoteSuccess(data)),
      catchError(error => of(new CreditNoteActions.UpdateCreditNoteFail(error)))
    )),
  );

  @Effect()
  deleteCreditNote$: Observable<Action> = this.actions$.pipe(
    ofType(types.creditNote.DELETE_CREDIT_NOTE),
    map((action: CreditNoteActions.DeleteCreditNote) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/credit_notes/${payload.creditNoteId}`
    }).pipe(
      map(() => new CreditNoteActions.DeleteCreditNoteSuccess(payload.creditNoteId)),
      catchError(error => of(new CreditNoteActions.DeleteCreditNoteFail(error)))
    )),
  );
}
