import { SubscriptionType, Subscription } from '../models/subscriptions.model';
import * as types from '../types/subscription.types';
import * as SubscriptionsActions from '../../store/actions/subscriptions.actions';

const INITIAL_SUBTYPE_STATE = null;
const INITIAL_SUBSCRIPTIONS_STATE = null;

export function SubscriptionTypesReducer(
  state = INITIAL_SUBTYPE_STATE,
  action: SubscriptionsActions.All
): SubscriptionType[] {
  switch (action.type) {
    case types.subscriptions.LOAD_TYPES_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}

export function SubscriptionsReducer(
  state = INITIAL_SUBSCRIPTIONS_STATE,
  action: SubscriptionsActions.All
): Subscription[] {
  switch (action.type) {
    case types.subscriptions.LOAD_SUCCEEDED:
      return [...action.payload['hydra:member']];
    case types.subscriptions.CLEAR:
      return null;
  }
  return state;
}
