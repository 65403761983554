import { Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { Injectable } from '@angular/core';
import { extract } from '../services/i18n.service';
// import { AuthenticationGuard } from './route-guards/authentication.guard';

/**
 * Provides helper methods to create routes.
 */
@Injectable()
export class Route {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Routes} The new routes using shell as the base.
   */
  static withShell(routes: Routes): Routes {
    return [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: '',
        component: ShellComponent,
        children: routes,
        // canActivate: [AuthenticationGuard],
        // Reuse ShellComponent, instance when navigating between child views
        data: { reuse: true }
      }
    ];
  }

  getRouteNameFromUrl(url: string) {
    let name = url;
    switch (url) {
      case '/dashboard/':
        name = extract('Dashboard');
        break;
      case '/subscribe/':
        name = extract('Subscribe');
        break;
      case '/marketplace/':
        name = extract('Marketplace');
        break;
      case '/marketplace/chat/':
        name = extract('Message board');
        break;
      case '/marketplace/create/':
        name = extract('New marketpost');
        break;
      case '/marketplace/offers/':
        name = extract('Offers');
        break;
      case '/marketplace/offers/edit/':
        name = extract('Edit offer');
        break;
      case '/marketplace/offers/reply/':
        name = extract('Reply on offer');
        break;
      case '/marketplace/demands/':
        name = extract('Demands');
        break;
      case '/marketplace/demands/edit/':
        name = extract('Edit demand');
        break;
      case '/marketplace/demands/reply/':
        name = extract('Reply on demand');
        break;
      case '/company-posts/':
        name = extract('My marketposts');
        break;
      case '/reload/':
        name = extract('Reload');
        break;
      case '/reload/reload-requests/':
        name = extract('Reload Requests');
        break;
      case '/reload/reload-requests/detail/':
        name = extract('Reload Request');
        break;
      case '/reload/new-reload-request/':
        name = extract('New Reload Request');
        break;
      case '/reload-back-office/':
        name = extract('Reload Back Office');
        break;
      case '/reload-back-office/reload-requests/':
        name = extract('Reload Requests');
        break;
      case '/reload-back-office/reload-requests/detail/':
        name = extract('Reload Request');
        break;
      case '/reload-back-office/reporting/':
        name = extract('Reporting');
        break;
      case '/contacts/':
        name = extract('Contacts');
        break;
      case '/crm/':
        name = extract('CRM');
        break;
      case '/crm/invoices/':
        name = extract('Invoices');
        break;
      case '/crm/invoices/new/':
        name = extract('New invoice');
        break;
      case '/crm/invoices/edit/':
        name = extract('Edit invoice');
        break;
      case '/crm/customers/':
        name = extract('Customers');
        break;
      case '/crm/customers/new/':
        name = extract('New customer');
        break;
      case '/crm/customers/edit/':
        name = extract('Edit customer');
        break;
      case '/crm/suppliers/':
        name = extract('Suppliers');
        break;
      case '/crm/suppliers/new/':
        name = extract('New supplier');
        break;
      case '/crm/suppliers/edit/':
        name = extract('Edit supplier');
        break;
      case '/crm/credit-notes/':
        name = extract('Credit notes');
        break;
      case '/crm/credit-notes/new/':
        name = extract('New credit note');
        break;
      case '/crm/credit-notes/edit/':
        name = extract('Edit credit note');
        break;
      case '/crm/purchase-orders/':
        name = extract('Purchase orders');
        break;
      case '/crm/purchase-orders/new/':
        name = extract('New purchase order');
        break;
      case '/crm/purchase-orders/edit/':
        name = extract('Edit purchase order');
        break;
      case '/crm/tax-rates/':
        name = extract('Tax rates');
        break;
      case '/crm/tax-rates/new/':
        name = extract('New tax rate');
        break;
      case '/crm/tax-rates/edit/':
        name = extract('Edit tax rate');
        break;
      case '/settings/':
        name = extract('Settings');
        break;
      case '/settings/users/':
        name = extract('Users');
        break;
      case '/settings/users/create/':
        name = extract('New user');
        break;
      case '/settings/users/edit/':
        name = extract('Edit user');
        break;
      case '/settings/credits/':
        name = extract('Credits');
        break;
      case '/settings/subscriptions/':
        name = extract('Subscriptions');
        break;
      case '/settings/info/':
        name = extract('Company information');
        break;
      case '/settings/circles/':
        name = extract('Circles');
        break;
      case '/settings/circles/edit/':
        name = extract('Edit circle');
        break;
      case '/settings/circles/new/':
        name = extract('New circle');
        break;
      case '/settings/notifications/':
        name = extract('Email notifications');
        break;
      case '/settings/me/':
        name = extract('Account information');
        break;
      case '/tms/':
        name = extract('TMS');
        break;
      case '/tms/transports/':
        name = extract('Transports');
        break;
      case '/tms/transports/new/':
        name = extract('New transport');
        break;
      case '/tms/transports/copy/':
        name = extract('Copy transport');
        break;
      case '/tms/transports/edit/':
        name = extract('Edit transport');
        break;
      case '/crm/tariffs/':
        name = extract('Tariffs');
        break;
      case '/crm/tariffs/new/':
        name = extract('New tariff');
        break;
      case '/crm/tariffs/edit/':
        name = extract('Edit tariff');
        break;
      case '/tms/prenotifications/':
        name = extract('Prenotifications');
        break;
      case '/tms/prenotifications/new/':
        name = extract('New prenotification');
        break;
      case '/tms/prenotifications/edit/':
        name = extract('Edit prenotification');
        break;
      case '/tms/planning/':
        name = extract('Planning');
        break;
      case '/tms/drivers/':
        name = extract('Drivers');
        break;
      case '/tms/drivers/new/':
        name = extract('New driver');
        break;
      case '/tms/drivers/edit/':
        name = extract('Edit driver');
        break;
      case '/driver/tasks/':
        name = extract('My tasks');
        break;
      case '/driver/':
        name = extract('Driver');
        break;
      case '/settings/locations/':
        name = extract('Locations');
        break;
      case '/settings/locations/new/':
        name = extract('New location');
        break;
      case '/settings/locations/edit/':
        name = extract('Edit location');
        break;
      case '/matching/':
        name = extract('Matching');
        break;
      case '/certiweight/':
        name = extract('Certiweight');
        break;
      case '/certiweight/buy/':
        name = extract('Buy Certificates');
        break;
      case '/certiweight/my/':
        name = extract('My Certificates');
        break;
      case '/certiweight/detail/':
        name = extract('Certificate Detail');
        break;
      case '/certiweight-backoffice/':
        name = extract('Certiweight Back Office');
        break;
      case '/certiweight-backoffice/customers/':
        name = extract('Customers');
        break;
      case '/certiweight-backoffice/customers/new/':
        name = extract('New customer');
        break;
      case '/certiweight-backoffice/customers/edit/':
        name = extract('Edit customer');
        break;
      case '/certiweight-backoffice/certificates/':
        name = extract('My Certificates');
        break;
      case '/certiweight-backoffice/preannouncements/':
        name = extract('My Preannouncements');
        break;
      case '/certiweight-backoffice/preannouncements/new/':
        name = extract('New Preannouncement');
        break;
      case '/certiweight-backoffice/preannouncements/edit/':
        name = extract('Edit Preannouncement');
        break;
      case '/certiweight-backoffice/detail/':
        name = extract('Certificate Detail');
        break;
      case '/superadmin/':
        name = extract('Super admin');
        break;
      case '/superadmin/certiweight/':
        name = extract('Certiweight measurements');
        break;
      case '/superadmin/certiweight/new/':
        name = extract('New Certiweight measurement');
        break;
      case '/superadmin/certiweight/edit/':
        name = extract('Edit Certiweight measurement');
        break;
      case '/superadmin/subscriptionTypes/':
        name = extract('Subscription types');
        break;
      case '/superadmin/subscriptionTypes/edit/':
        name = extract('Edit subscription');
        break;
      case '/superadmin/tenants/':
        name = extract('Tenants');
        break;
      case '/superadmin/psa-accounts/':
        name = extract('PSA accounts');
        break;
      case '/superadmin/psa-accounts/create/':
        name = extract('Create a PSA account');
        break;
      case '/superadmin/containerTypes/new/':
        name = extract('New container type');
        break;
      case '/superadmin/tenants/create/':
        name = extract('New tenant');
        break;
      case '/superadmin/tenants/edit/':
        name = extract('Edit tenant');
        break;
      case '/superadmin/registration-requests/':
        name = extract('Manage Registration requests');
        break;
      case '/superadmin/registration-requests/edit/':
        name = extract('Edit Registration request');
        break;
      case '/superadmin/wallets/':
        name = extract('Wallets');
        break;
      case '/superadmin/locations/':
        name = extract('Locations');
        break;
      case '/superadmin/locations/edit/':
        name = extract('Edit location');
        break;
      case '/superadmin/containerTypes/':
        name = extract('Container types');
        break;
      case '/superadmin/containerTypes/edit/':
        name = extract('Edit container type');
        break;
      case '/superadmin/containerTypes/create/':
        name = extract('New container type');
        break;
      case '/superadmin/liners/':
        name = extract('Liners');
        break;
      case '/superadmin/liners/edit/':
        name = extract('Edit liner');
        break;
      case '/superadmin/liners/new/':
        name = extract('New liner');
        break;
      case '/superadmin/shipping-agents/':
        name = extract('Manage Shipping Agents');
        break;
      case '/superadmin/shipping-agents/new/':
        name = extract('New Shipping Agent');
        break;
      case '/superadmin/shipping-agents/edit/':
        name = extract('Edit Shipping Agent');
        break;
      default:
        break;
    }
    return name;
  }
}
