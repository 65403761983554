import { Component } from '@angular/core';

import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-certiweight-back-office',
  templateUrl: './certiweight-back-office.component.html',
})
export class CertiweightBackOfficeComponent {
  routes = [
    {
      url: '/certiweight-backoffice/preannouncements',
      title: extract('Preannouncements'),
      description: extract('Manage my preannouncements'),
      icon: 'fa-clipboard'
    },
    {
      url: '/certiweight-backoffice/preannouncements/new',
      title: extract('New preannouncement'),
      description: extract('Create a new preannouncement'),
      icon: 'fa-plus'
    },
    {
      url: '/certiweight-backoffice/customers',
      title: extract('Customers'),
      description: extract('Manage the customers of your company'),
      icon: 'fa-user-tie'
    },
    {
      url: '/certiweight-backoffice/customers/new',
      title: extract('New customer'),
      description: extract('Quick link to create a new customer'),
      icon: 'fa-plus'
    },
    {
      url: '/certiweight-backoffice/certificates',
      title: extract('My Certificates'),
      description: extract('Manage my certificates'),
      icon: 'fa-weight-hanging'
    },
  ];
}
