import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/tax-rate.types';
import * as TaxRateActions from '../actions/tax-rate.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class TaxRateEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadTaxRates$: Observable<Action> = this.actions$.pipe(
    ofType(types.taxRate.LOAD_TAX_RATES),
    concatMap(() => this.api.get({ path: `/crm/tax_rates` }).pipe(
      map(data => new TaxRateActions.LoadTaxRatesSuccess(data)),
      catchError(error => of(new TaxRateActions.LoadTaxRatesFail(error)))
    ))
  );

  @Effect()
  loadFilteredTaxRates$: Observable<Action> = this.actions$.pipe(
    ofType(types.taxRate.LOAD_FILTERED_TAX_RATES),
    map((action: TaxRateActions.LoadFilteredTaxRates) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      return this.api.get({ path: `/crm/tax_rates?rate=${query}&label=${query}` }).pipe(
        map(data => new TaxRateActions.LoadFilteredTaxRatesSuccess(data)),
        catchError(error => of(new TaxRateActions.LoadFilteredTaxRatesFail(error)))
      );
    })
  );

  @Effect()
  createTaxRate$: Observable<Action> = this.actions$.pipe(
    ofType(types.taxRate.CREATE_TAX_RATE),
    map((action: TaxRateActions.CreateTaxRate) => action.payload),
    concatMap(payload => this.api.post({ path: `/crm/tax_rates`, body: payload }).pipe(
      map(data => new TaxRateActions.CreateTaxRateSuccess(data)),
      catchError(error => of(new TaxRateActions.CreateTaxRateFail(error)))
    )),
  );

  @Effect()
  deleteTaxRate$: Observable<Action> = this.actions$.pipe(
    ofType(types.taxRate.DELETE_TAX_RATE),
    map((action: TaxRateActions.DeleteTaxRate) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/tax_rates/${payload.taxRateId}`
    }).pipe(
      map(() => new TaxRateActions.DeleteTaxRateSuccess(payload.taxRateId)),
      catchError(error => of(new TaxRateActions.DeleteTaxRateFail(error)))
    )),
  );

  @Effect()
  updateTaxRate$: Observable<Action> = this.actions$.pipe(
    ofType(types.taxRate.UPDATE_TAX_RATE),
    map((action: TaxRateActions.UpdateTaxRate) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/tax_rates/${payload.taxRateId}`,
      body: payload.body
    }).pipe(
      map(data => new TaxRateActions.UpdateTaxRateSuccess(data)),
      catchError(error => of(new TaxRateActions.UpdateTaxRateFail(error)))
    ))
  );
}
