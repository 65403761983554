<div class="row">
  <div class="col">
    <div class="progress" *ngIf="marketposts$?.length === 0 && !filtered">
      <div
        role="progressbar"
        style="width: 100%"
        class="progress-bar progress-bar-striped progress-bar-animated bg-info"
      ></div>
    </div>
    <div
      class="alert alert-info"
      *ngIf="marketposts$?.length === 0 && filtered"
    >
      {{ 'No results found.' | translate }}
    </div>
  </div>
</div>
<div
  class="search-results mt-3"
  [scrollWindow]="false"
  data-infinite-scroll
  debounce
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollContainer]="'.ng-sidebar__content'"
  [fromRoot]="true"
  [infiniteScrollThrottle]="throttle"
  (scrolled)="onScrollDown()"
>
  <div class="market-posts-grid ml-3 mr-3" *ngIf="cardView">
    <div
      class="market-post"
      *ngFor="let marketPost of marketposts$; trackBy: trackById"
    >
      <app-cmp-market-post-offer
        class="card"
        *ngIf="marketPost?.marketPostType !== 'demand'"
        (enableMatching)="handleEnableMatching($event)"
        (disableMatching)="handleDisableMatching($event)"
        [marketPost]="marketPost"
        [hasActiveMatchingSubscription]="hasActiveMatchingSubscription"
      ></app-cmp-market-post-offer>
      <app-cmp-market-post-demand
        class="card"
        *ngIf="marketPost?.marketPostType === 'demand'"
        [marketPost]="marketPost"
        [hasActiveMatchingSubscription]="hasActiveMatchingSubscription"
        (enableMatching)="handleEnableMatching($event)"
        (disableMatching)="handleDisableMatching($event)"
      ></app-cmp-market-post-demand>
    </div>
  </div>
  <div *ngIf="!cardView">
    <app-cmp-market-post-table
      *ngIf="!cardView"
      [showFilter]="showFilter"
      [hasActiveMatchingSubscription]="hasActiveMatchingSubscription"
      [showMB]="showMB"
      [marketposts]="marketposts$"
      (enableMatching)="handleEnableMatching($event)"
      (disableMatching)="handleDisableMatching($event)"
    ></app-cmp-market-post-table>
  </div>
</div>
