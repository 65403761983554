import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { untilDestroyed } from 'app/shared/rxjs-util';
import { State } from 'app/core/store/store.model';
import {
  CancelReloadRequest,
  CancelReloadRequestByLiner,
  CancelReloadRequestSuccess,
  ForceApproveReloadRequest,
  ForceApproveReloadRequestSuccess,
  LoadAllReloadRequests
} from 'app/core/store/actions/reload.actions';
import { ReloadRequest, ReloadRequestStatuses } from 'app/core/store/models/reload-request.model';
import * as Types from 'app/core/store/types/reload.types';
import * as moment from 'moment';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'app-cmp-reload-requests',
  templateUrl: 'reload-requests.component.html',
  styleUrls: ['./reload-requests.component.scss']
})
export class ReloadRequestsComponent implements OnInit, OnDestroy {
  title = extract('Reload Requests');
  reloadRequests: ReloadRequest[];
  isLoadingReloadRequests: boolean = false;
  isLoadingAction: boolean = false;
  actionRow: number;
  totalElements = 0;
  tableMessages = {
    emptyMessage: extract('No Reload Requests to display'),
    totalMessage: extract('total')
  };
  searchPlaceholder = extract('Search liner, container number or export reference');
  query?: string = null;
  statusFilter?: string = null;
  page: number = 1;
  forLiner: boolean = false;
  requestStatuses: { value: string; label: string }[];

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
    this.forLiner = (this.router.url === '/reload-back-office/reload-requests');
    this.requestStatuses = ReloadRequestStatuses;
    this.store
      .select(state => {
        if (
          state &&
          state.reload &&
          state.reload.reloadRequests &&
          state.reload.reloadRequests.items
        ) {
          return state.reload.reloadRequests.items;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(reloadRequests => {
        if (!reloadRequests) {
          return;
        }
        this.reloadRequests = [...reloadRequests];
        this.isLoadingReloadRequests = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.reload &&
          state.reload.reloadRequests &&
          state.reload.reloadRequests.total
        ) {
          return state.reload.reloadRequests.total;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(totalElements => {
        if (!totalElements) {
          this.totalElements = 0;
          return;
        }
        this.totalElements = totalElements;
      });
    this.updates$
      .pipe(ofType(Types.reload.LOAD_ALL_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingReloadRequests = false;
        this.toastr.showDanger({
          message: extract('Failed to load Reload Requests!')
        });
      });
    this.updates$
      .pipe(ofType(Types.reload.FORCE_APPROVE_RELOAD_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((action: ForceApproveReloadRequestSuccess) => {
        this.isLoadingAction = false;
        this.actionRow = null;
        if (action.payload && action.payload.requestStatus === 'forced_approved') {
          this.toastr.showSuccess({
            message: extract('Reload request forced approved!')
          });
        } else {
          this.toastr.showWarning({
            title: 'Reload request approval failed!',
            message: this.translate.instant('reload.api.message.' + action.payload.requestStatusDetail)
          });
        }
        this.loadReloadRequests();
      });
    this.updates$
      .pipe(ofType(Types.reload.FORCE_APPROVE_RELOAD_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingAction = false;
        this.actionRow = null;
        this.toastr.showDanger({
          title: extract('Reload request approval failed!')
        });
        this.loadReloadRequests();
      });
    this.updates$
      .pipe(ofType(Types.reload.CANCEL_RELOAD_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((action: CancelReloadRequestSuccess) => {
        this.isLoadingAction = false;
        this.actionRow = null;
        if (action.payload && action.payload.requestStatus === 'cancelled') {
          this.toastr.showSuccess({
            title: extract('Reload request cancelled!')
          });
        } else {
          this.toastr.showWarning({
            message: 'Reload request cancellation failed!',
          });
        }
        this.loadReloadRequests();
      });
    this.updates$
      .pipe(ofType(Types.reload.CANCEL_RELOAD_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingAction = false;
        this.actionRow = null;
        this.loadReloadRequests();
      });
  }

  ngOnInit() {
    this.loadReloadRequests();
  }

  loadReloadRequests() {
    this.store.dispatch(new LoadAllReloadRequests({ forLiner: this.forLiner, query: this.query, page: this.page, status: this.statusFilter }));
    this.isLoadingReloadRequests = true;
  }

  handleSearch(query: string, page?: number) {
    const p = page ? page : 1;
    this.page = p;
    if (!query) {
      this.query = null;
      this.loadReloadRequests();
      return;
    }
    this.query = query;
    this.loadReloadRequests();
  }

  handleStatusFilterChange() {
    this.page = 1;
    this.loadReloadRequests();
  }

  goToReloadRequestDetail(id: string) {
    if (this.forLiner) {
      this.router.navigateByUrl(`/reload-back-office/reload-requests/${id}/detail`);
    } else {
      this.router.navigateByUrl(`/reload/reload-requests/${id}/detail`);
    }
  }

  forceApprove(id: string, rowIndex: number) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract('Are you sure you want to force the approval of this reload request?');
    modalRef.componentInstance.confirmButtonText = extract('Yes');
    modalRef.result.then(() => {
      this.actionRow = rowIndex;
      this.isLoadingAction = true;
      this.store.dispatch(new ForceApproveReloadRequest({ requestId: id }));
    });
  }

  cancel(id: string, rowIndex: number) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract('Are you sure you want to cancel this reload request?');
    modalRef.componentInstance.confirmButtonText = extract('Yes');
    modalRef.result.then(() => {
      this.actionRow = rowIndex;
      this.isLoadingAction = true;
      if (this.forLiner) {
        this.store.dispatch(new CancelReloadRequestByLiner({ requestId: id }));
      } else {
        this.store.dispatch(new CancelReloadRequest({ requestId: id }));
      }
    });
  }

  setPage($event) {
    this.page = $event.offset + 1;
    this.handleSearch(this.query, this.page);
  }

  formatRequestStatus(status?: string) {
    if (status) {
      const requestStatus: {label: string, value: string}  = this.requestStatuses.find(
        requestStatus => requestStatus.value === status
      );
      return requestStatus? requestStatus.label : status;
    }
    return status;
  }

  getStatusClass = (cell: any) => {
    let classes = {}

    if (cell.value) {
      classes['reload_request_' + cell.value] = true;
    }

    return classes;
  }

  isForceApproveButtonDisabled(reloadRequest: ReloadRequest) {
    return reloadRequest.requestStatus !== 'declined';
  }

  isCancelButtonDisabled(reloadRequest: ReloadRequest) {
    return (
      // for company
      (reloadRequest.requestStatus !== 'approved' || moment(reloadRequest.reloadDate).isBefore(moment(), 'day'))
      && // for liner
      (!this.forLiner || (reloadRequest.requestStatus !== 'approved' && reloadRequest.requestStatus !== 'forced_approved'))
    );
  }

  ngOnDestroy() { }
}
