var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/wallet.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as WalletActions from '../actions/wallet.actions';
import { ApiService } from '../../api/api.service';
var WalletEffects = /** @class */ (function () {
    function WalletEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadWallet$ = this.actions$.pipe(ofType(types.wallet.LOAD_WALLET), concatMap(function () { return _this.api.get({ path: '/me/wallet' }).pipe(map(function (data) { return new WalletActions.LoadWalletSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.rechargeWallet$ = this.actions$.pipe(ofType(types.wallet.RECHARGE_WALLET), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/mollie/new_payment',
            body: payload
        }).pipe(map(function (data) { return new WalletActions.RechargeWalletSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.requestRechargeWallet$ = this.actions$.pipe(ofType(types.wallet.REQUEST_RECHARGE_WALLET), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/mollie/new_payment',
            body: payload
        }).pipe(map(function (data) { return new WalletActions.RequestRechargeWalletSuccess(data); }), catchError(function (error) { return of(new WalletActions.RequestRechargeWalletFail(error)); })); }));
        this.addCreditsToWallet$ = this.actions$.pipe(ofType(types.wallet.ADD_CREDITS_TO_WALLET), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/admin/wallet/add?amount=' +
                payload.amount +
                '&tenant_id=' +
                payload.id
        }).pipe(map(function (data) { return new WalletActions.AddCreditsToWalletSuccess(data); }), catchError(function (error) { return of(new WalletActions.AddCreditsToWalletFail(error)); })); }));
        this.subtractCreditsToWallet$ = this.actions$.pipe(ofType(types.wallet.SUBTRACT_CREDITS_FROM_WALLET), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/admin/wallet/subtract?amount=' +
                payload.amount +
                '&tenant_id=' +
                payload.id
        }).pipe(map(function (data) { return new WalletActions.SubtractCreditsFromWalletSuccess(data); }), catchError(function (error) { return of(new WalletActions.SubtractCreditsFromWalletFail(error)); })); }));
        this.updateWallet$ = this.actions$.pipe(ofType(types.wallet.UPDATE_WALLET), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/wallets/' + payload.id, body: payload.body
        }).pipe(map(function (data) { return new WalletActions.UpdateWalletSuccess(data); }), catchError(function (error) { return of(new WalletActions.UpdateWalletFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WalletEffects.prototype, "loadWallet$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WalletEffects.prototype, "rechargeWallet$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WalletEffects.prototype, "requestRechargeWallet$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WalletEffects.prototype, "addCreditsToWallet$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WalletEffects.prototype, "subtractCreditsToWallet$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], WalletEffects.prototype, "updateWallet$", void 0);
    return WalletEffects;
}());
export { WalletEffects };
