export const circles = {
  LOAD_CIRCLES: '[Circles] Load',
  LOAD_SUCCEEDED: '[Circles] Load Success',
  LOAD_FAILED: '[Circles] Load Fail',
  CREATE_CIRCLE: '[Circles] Create',
  CREATE_SUCCEEDED: '[Circles] Create Success',
  CREATE_FAILED: '[Circles] Create Fail',
  DELETE_CIRCLE: '[Circles] Delete',
  DELETE_SUCCEEDED: '[Circles] Delete Success',
  DELETE_FAILED: '[Circles] Delete Fail',
  EDIT_CIRCLE: '[Circles] Edit',
  EDIT_SUCCEEDED: '[Circles] Edit Success',
  EDIT_FAILED: '[Circles] Edit Fail',
  CLEAR: '[Circles] Clear'
};
