import { IconService } from 'app/services/icon.service';
import { Subject } from 'rxjs';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
  FormControl
} from '@angular/forms';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as DateFns from 'date-fns';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Transport } from 'app/core/store/models/transport.model';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../../shared/datepicker-config';
import { PaymentNote } from 'app/core/store/models/payment-note.model';
import { NewTaxRateModalComponent } from '../new-tax-rate-modal/new-tax-rate-modal.component';
import { Business } from 'app/core/store/models/business.model';

@Component({
  selector: 'app-purchase-order-form',
  templateUrl: './purchase-order-form.component.html',
  styleUrls: ['./purchase-order-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class PurchaseOrderFormComponent implements OnChanges {
  @Input()
  suppliers: Business[];
  @Input()
  isLoadingSuppliers: boolean;
  @Input()
  taxRates: any[];
  @Input()
  isLoadingTaxRates: boolean;
  @Input()
  isLoading: boolean;
  @Input()
  transports: Transport[];
  @Input()
  purchaseOrder: PaymentNote;

  @Output()
  purchaseOrderFormSubmitted = new EventEmitter();
  @Output()
  onSaveAndDownloadAsPDF = new EventEmitter();
  @Output()
  searchTransports = new EventEmitter<{
    query?: string,
    since?: string,
    until?: string
  }>();
  @Output()
  selectTransport = new EventEmitter<string>();
  @Output()
  removeTransport = new EventEmitter<string>();
  @Output()
  supplierSelected = new EventEmitter<any>();
  @Output()
  loadNextTransportPage = new EventEmitter<any>();

  purchaseOrderForm: FormGroup;
  taxRateExpr = new RegExp(/[0-9]{1,3}/);
  addTagText = 'Add tax rate';
  query: string = '';
  since?: string;
  until?: string;
  searchTextChanged = new Subject();
  submitAttempt: boolean;
  nextPage: any;
  showDateSelection: boolean = false;
  dateSelection: string;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  constructor(
    private iconService: IconService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(search => this.searchTransports.emit({query: search.toString(), since: this.since, until: this.until}))
      )
      .subscribe(() => { });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.taxRates && changes.taxRates.currentValue) {
      // Only create form if it doesn't exist
      if (!this.purchaseOrderForm) {
        this.createForm();
        if (this.purchaseOrder) {
          this.patchForm();
        }
      }
    }
    if (changes && changes.purchaseOrder) {
      // Only create form if it doesn't exist and tax rates are loaded
      if (!this.purchaseOrderForm && this.taxRates) {
        this.createForm();
      }
      if (
        this.purchaseOrderForm &&
        // !this.purchaseOrderForm.value.date &&
        this.taxRates
      ) {
        this.patchForm();
      }
    }
  }

  patchForm() {
    if (!this.purchaseOrder) {
      return;
    }
    this.purchaseOrderForm.patchValue({
      business: this.purchaseOrder.business,
      notes: this.purchaseOrder.notes,
      date: new Date(
        DateFns.getYear(this.purchaseOrder.date),
        DateFns.getMonth(this.purchaseOrder.date),
        DateFns.getDate(this.purchaseOrder.date)
      )
    });
    this.purchaseOrder.items.forEach(item => {
      const puchaseOrderItems = <FormArray>(
        this.purchaseOrderForm.controls['items']
      );
      if (item.transport) {
        puchaseOrderItems.at(puchaseOrderItems.value.length - 1).patchValue({
          transport: item.transport
        });
      }
      puchaseOrderItems.at(puchaseOrderItems.value.length - 1).patchValue({
        id: item.id,
        description: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        taxRate: item.taxRate,
        total: item['totalInclTaxes'].toFixed(2)
      });
      this.addPurchaseOrderItem();
    });
  }
  createForm() {
    this.purchaseOrderForm = this.fb.group({
      business: [null, Validators.required],
      date: [new Date(), Validators.required],
      notes: [null],
      items: this.fb.array([this.createPurchaseOrderItem()])
    });
  }

  get supplierAddress() {
    if (
      !this.purchaseOrderForm ||
      !this.purchaseOrderForm.value ||
      !this.purchaseOrderForm.get('business').value
    ) {
      return;
    }
    return this.purchaseOrderForm.get('business').value['billingAddress'];
  }
  get supplierMainContact() {
    if (
      !this.purchaseOrderForm ||
      !this.purchaseOrderForm.value ||
      !this.purchaseOrderForm.get('business').value
    ) {
      return;
    }
    return this.purchaseOrderForm.get('business').value['defaultContact'];
  }

  createPurchaseOrderItem(): FormGroup {
    const group = this.fb.group({
      id: null,
      description: [''],
      quantity: [1],
      transport: [null],
      unitPrice: [0],
      taxRate: [this.taxRates[0], Validators.required],
      total: [{ value: 0, disabled: true }]
    });
    return group;
  }

  addPurchaseOrderItem(): void {
    const purchaseOrderItems = <FormArray>(
      this.purchaseOrderForm.controls['items']
    );
    purchaseOrderItems.push(this.createPurchaseOrderItem());
  }
  removePurchaseOrderItem(index): void {
    const purchaseOrderItems = <FormArray>(
      this.purchaseOrderForm.controls['items']
    );
    const item = purchaseOrderItems.value[index];
    if (item.transport) {
      this.removeTransport.emit(item.transport.id);
    }
    purchaseOrderItems.removeAt(index);
  }

  handleSupplierSelected() {
    const supplier = this.purchaseOrderForm.get('business').value;
    const supplierId = supplier && supplier.id ? supplier.id : null;
    this.supplierSelected.emit(supplierId);

    // Remove all selected transports
    const purchaseOrderItems = <FormArray>(
      this.purchaseOrderForm.controls['items']
    );
    purchaseOrderItems.value.forEach(control => {
      if (!control.transport) {
        return;
      }
      if (
        control.transport.supplier &&
        control.transport.supplier.id === supplierId
      ) {
        return;
      }
      const index = purchaseOrderItems.value.indexOf(control);
      purchaseOrderItems.removeAt(index);
    });
  }

  openTaxRateModal(itemIndex, rate) {
    const modalRef = this.modalService.open(NewTaxRateModalComponent);
    modalRef.result.then(result => {
      const purchaseOrderItems = <FormArray>(
        this.purchaseOrderForm.controls['items']
      );
      purchaseOrderItems.at(itemIndex).patchValue({
        taxRate: result
      });
    });
  }

  search($event) {
    this.searchTextChanged.next($event.target.value);
  }

  /**
   * Duplicates the given purchaseOrder item
   * @param index index of the purchaseOrder item
   */
  duplicateItem(index) {
    this.addPurchaseOrderItem();
    const purchaseOrderItems = <FormArray>(
      this.purchaseOrderForm.controls['items']
    );
    const item = Object.assign({}, purchaseOrderItems.at(index).value);
    purchaseOrderItems.at(purchaseOrderItems.value.length - 1).patchValue(item);
  }

  /**
   * Updates the total of the given purchaseOrder item based on the quantity, unit pirce
   * and tax rate
   * @param index index of the purchaseOrder item
   */
  updateTotal(index) {
    const purchaseOrderItems = <FormArray>(
      this.purchaseOrderForm.controls['items']
    );
    const item = purchaseOrderItems.value[index];
    if (
      !item.quantity ||
      !item.unitPrice ||
      !item.taxRate ||
      !item.taxRate.rate
    ) {
      return;
    }
    item.total =
      +item.quantity * +item.unitPrice +
      ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate;
    purchaseOrderItems.at(index).setValue(item);
  }

  get total() {
    const purchaseOrderItems = this.purchaseOrderForm.controls['items'].value;
    return purchaseOrderItems
      .map(item => +item.total)
      .reduce((total1, total2) => total1 + total2);
  }
  get subTotal() {
    const purchaseOrderItems = this.purchaseOrderForm.controls['items'].value;
    return purchaseOrderItems
      .map(item => +item.quantity * +item.unitPrice)
      .reduce((total1, total2) => total1 + total2);
  }
  get taxes() {
    const purchaseOrderItems = this.purchaseOrderForm.controls['items'].value;
    return purchaseOrderItems
      .map(item => {
        // If an item doesn't have a tax rate it is invalid
        if (!item.taxRate || !item.taxRate.rate) {
          return 0;
        }
        return (
          ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate['rate']
        );
      })
      .reduce((total1, total2) => total1 + total2);
  }

  handleTransportSelected(transport: Transport) {
    if (!transport.supplierTariff) {
      return;
    }
    const description = transport.supplierTariff['label'];
    const unitPrice = transport.supplierTariff['price'];
    const purchaseOrderItems = <FormArray>(
      this.purchaseOrderForm.controls['items']
    );
    let index = purchaseOrderItems.value.length - 1;

    if (purchaseOrderItems.at(index).get('description').value) {
      this.addPurchaseOrderItem();
      index++;
    }
    const item = {
      description,
      unitPrice,
      transport
    };
    purchaseOrderItems.at(index).patchValue(item);
    this.updateTotal(index);
    this.addPurchaseOrderItem();

    // Select customer if transport has one so we can only add transports of exactly one customer
    if (transport.supplier && transport.supplier['id'] && !this.purchaseOrderForm.get('business').value) {
      this.purchaseOrderForm.patchValue({
        business: transport.supplier
      });
      this.handleSupplierSelected();
    }
    this.selectTransport.emit(transport.id);
  }

  /**
   * Emits the purchaseOrder form value
   */
  onSubmit() {
    if (this.purchaseOrderForm.invalid) {
      this.markFormGroupAsTouched(this.purchaseOrderForm);
      return;
    }
    const purchaseOrderFormValue = this.parsePurchaseOrderFormValue();
    this.purchaseOrderFormSubmitted.emit(purchaseOrderFormValue);
  }
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });
  }
  parsePurchaseOrderFormValue() {
    const purchaseOrderFormValue = JSON.parse(
      JSON.stringify(this.purchaseOrderForm.value)
    );

    purchaseOrderFormValue.items = purchaseOrderFormValue.items.filter(
      item => item.description || item.transport
    );
    purchaseOrderFormValue.date = DateFns.setHours(
      purchaseOrderFormValue.date,
      12
    );
    purchaseOrderFormValue.business = purchaseOrderFormValue.business
      ? purchaseOrderFormValue.business['@id']
      : null;
    purchaseOrderFormValue.items.forEach(item => {
      item.taxRate = {
        id: item.taxRate['id']
      };
      if (!item.transport) {
        return;
      }
      item.transport = {
        id: item.transport['id']
      };
    });
    return purchaseOrderFormValue;
  }

  getTransportIcon(transport) {
    return this.iconService.getTransportTypeIcon(transport.transportType);
  }
  saveAndDownloadAsPDF() {
    if (this.purchaseOrderForm.invalid) {
      return;
    }
    const purchaseOrderFormValue = this.parsePurchaseOrderFormValue();
    this.onSaveAndDownloadAsPDF.emit(purchaseOrderFormValue);
  }
  getPrimaryTaskLocation(transport: Transport) {
    const primaryTask = transport.transportTasks.find(t => t.primaryTask);
    if (!primaryTask.location) {
      return;
    }
    return primaryTask.location.name;
  }

  getPrimaryTaskDate(transport: Transport) {
    return transport.primaryTaskDateTimeSpecified
      ? moment(transport.primaryTaskDate).format("DD/MM HH:mm")
      : moment(transport.primaryTaskDate).format("DD/MM");
  }

  onScrollDown() {
    this.loadNextTransportPage.emit(true);
  }

  handleDateSelection(dateSelectionEvent: {
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
  }) {
    const sinceDateStruct: NgbDateStruct = (dateSelectionEvent.fromDate) ? dateSelectionEvent.fromDate : null;
    const untilDateStruct: NgbDateStruct = (dateSelectionEvent.toDate) ? dateSelectionEvent.toDate : null;

    if (sinceDateStruct !== null && untilDateStruct !== null) {
      const sinceDate = moment(sinceDateStruct.year + '-' + sinceDateStruct.month + '-' + sinceDateStruct.day, 'YYYY-MM-DD');
      const untilDate = moment(untilDateStruct.year + '-' + untilDateStruct.month + '-' + untilDateStruct.day, 'YYYY-MM-DD');
      this.since = sinceDate.format('YYYY-MM-DD');
      this.until = untilDate.format('YYYY-MM-DD');
      this.dateSelection = sinceDate.format('DD/MM/YYYY') + ' - ' + untilDate.format('DD/MM/YYYY');
      this.showDateSelection = false;
      this.searchTransports.emit({query: this.query, since: this.since, until: this.until});
    } else {
      this.since = null;
      this.until = null;
    }
  }
}
