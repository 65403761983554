/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tenant-list.component";
var styles_TenantListComponent = [];
var RenderType_TenantListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TenantListComponent, data: {} });
export { RenderType_TenantListComponent as RenderType_TenantListComponent };
function View_TenantListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "list-group-item list-group-item-action list-group-item-light"]], [[8, "tabIndex", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pickTenant(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 1, 0, currVal_1); }); }
export function View_TenantListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "tenants list-group"], ["data-cy", "tenantList"]], [[2, "list-group-flush", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TenantListComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tenants; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flush; _ck(_v, 0, 0, currVal_0); }); }
export function View_TenantListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-tenant-list", [], null, null, null, View_TenantListComponent_0, RenderType_TenantListComponent)), i0.ɵdid(1, 573440, null, 0, i2.TenantListComponent, [], null, null)], null, null); }
var TenantListComponentNgFactory = i0.ɵccf("app-cmp-tenant-list", i2.TenantListComponent, View_TenantListComponent_Host_0, { flush: "flush", tenants: "tenants" }, { pick: "pick" }, []);
export { TenantListComponentNgFactory as TenantListComponentNgFactory };
