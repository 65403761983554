<div class="search-container mb-3 ml-3 mr-3">
  <app-search
    [placeholder]="placeholder"
    (search)="handleSearch($event)"
  ></app-search>
</div>
<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [rows]="filteredCreditNotes"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  (page)="setPage($event)"
  [externalPaging]="true"
  [count]="totalElements"
  [limit]="30"
  [messages]="tableMessages"
  [loadingIndicator]="isLoadingCreditNotes"
  [selected]="selected"
  [selectionType]="'checkbox'"
  [selectAllRowsOnPage]="false"
  [displayCheck]="true"
>
  <div>
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <input
          type="checkbox"
          [checked]="allRowsSelected"
          (change)="selectFn(!allRowsSelected)"
        />
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <input
          type="checkbox"
          [checked]="isSelected"
          (change)="onCheckboxChangeFn($event)"
        />
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'Credit note #' | translate }}"
      prop="number"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'Customer' | translate }}"
      prop="business.label"
    >
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'State' | translate }}" prop="state">
      <ng-template
        ngx-datatable-cell-template
        let-row="row"
        let-rowIndex="rowIndex"
        let-value="value"
      >
        <div ngbDropdown [container]="'body'" class="d-inline-block">
          <button class="btn" id="stateDropdown" ngbDropdownToggle>
            <span
              class="btn__label"
              [ngClass]="{ invisible: isLoading && actionRow === rowIndex }"
            >
              <i
                class="far fa-fw"
                [ngClass]="getCreditNoteStateIcon(value)"
              ></i>
              {{ value | translate }}</span
            >
            <i
              class="fa fa-spinner fa-spin btn__loader"
              [ngClass]="{ visible: isLoading && actionRow === rowIndex }"
            ></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
              class="dropdown-item"
              *ngIf="
                row.state !== CreditNoteStates.PAID &&
                row.state !== CreditNoteStates.SENT
              "
              (click)="updateCreditNoteState(row.id, CreditNoteStates.SENT)"
            >
              <span class="icon"> <i class="far fa-envelope"></i> </span>
              <span>{{ 'Mark as sent' | translate }}</span>
            </button>
            <button
              class="dropdown-item"
              (click)="updateCreditNoteState(row.id, CreditNoteStates.PAID)"
            >
              <span class="icon"> <i class="far fa-check"></i> </span>
              <span>{{ 'Mark as paid' | translate }}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Date' | translate }}" prop="date">
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ value | date: 'dd-MM-yyyy' }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [cellClass]="'datatable-actions'"
      name="{{ 'Action' | translate }}"
      prop=""
      [width]="140"
    >
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <div ngbDropdown class="d-inline-block">
          <button
            class="btn btn-outline-info"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <span
              translate
              class="btn__label"
              [ngClass]="{ invisible: isLoading && actionRow === rowIndex }"
              >Select Action</span
            >
            <i
              class="fa fa-spinner fa-spin btn__loader"
              [ngClass]="{ visible: isLoading && actionRow === rowIndex }"
            ></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" (click)="editCreditNote(row.id)">
              <span class="icon"> <i class="far fa-fw fa-pencil"></i> </span>
              <span>{{ 'Edit' | translate }}</span>
            </button>
            <button
              class="dropdown-item"
              (click)="downloadCreditNotePDF(row.id, row.number)"
            >
              <span class="icon"> <i class="far fa-download"></i> </span>
              <span>{{ 'Download as PDF' | translate }}</span>
            </button>
            <button class="dropdown-item" (click)="deleteCreditNote(row.id)">
              <span class="icon"> <i class="far fa-trash-alt"></i> </span>
              <span>{{ 'Delete' | translate }}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
