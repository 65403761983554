import { Notification } from '../models/notifications.model';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as types from '../types/notification.types';

/**
 * Action for creating a new notification
 */
export class AddNotification implements Action {
  readonly type = types.notifications.ADD_NOTIFICATION;

  /**
   * @constructor
   * @param payload { Notification } The actual notification
   */
  constructor(public payload: Notification) { }
}

/**
 * Action for creating a new error notification
 */
export class ErrorNotification implements Action {
  readonly type = types.notifications.ERROR_NOTIFICATION;
  /**
   * @constructor
   * @param payload { HttpErrorResponse } An HTTP Error response
   */
  constructor(public payload: HttpErrorResponse) { }
}

/**
 * Action for creating a new success notification
 */
export class SuccessNotification implements Action {
  readonly type = types.notifications.SUCCESS_NOTIFICATION;
  /**
   * @constructor
   * @param payload { SuccessMessage } A success message
   */
  constructor(public payload: any) { }
}

/**
 * Action for dismissing notifications
 */
export class DismissNotification implements Action {
  readonly type = types.notifications.DISMISS_NOTIFICATION;
  /**
   * @constructor
   * @param payload { any } The notification to dismiss
   */
  constructor(public payload: any) { }
}

export type All =
  | AddNotification
  | DismissNotification
  | ErrorNotification
  | SuccessNotification;
