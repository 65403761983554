/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../transports/new-transport/new-transport.component.ngfactory";
import * as i2 from "../transports/new-transport/new-transport.component";
import * as i3 from "@ngrx/store";
import * as i4 from "../../services/marketpost-parser.service";
import * as i5 from "@ngrx/effects";
import * as i6 from "../../services/toast.service";
import * as i7 from "@angular/router";
import * as i8 from "../../services/icon.service";
import * as i9 from "./copy-transport.component";
var styles_CopyTransportComponent = [];
var RenderType_CopyTransportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CopyTransportComponent, data: {} });
export { RenderType_CopyTransportComponent as RenderType_CopyTransportComponent };
export function View_CopyTransportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-transport", [], null, null, null, i1.View_NewTransportComponent_0, i1.RenderType_NewTransportComponent)), i0.ɵdid(1, 245760, null, 0, i2.NewTransportComponent, [i3.Store, i4.MarketpostParserService, i5.Actions, i6.ToastService, i7.Router, i8.IconService], { transport: [0, "transport"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transport; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CopyTransportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-copy-transport", [], null, null, null, View_CopyTransportComponent_0, RenderType_CopyTransportComponent)), i0.ɵdid(1, 49152, null, 0, i9.CopyTransportComponent, [i3.Store], null, null)], null, null); }
var CopyTransportComponentNgFactory = i0.ɵccf("app-copy-transport", i9.CopyTransportComponent, View_CopyTransportComponent_Host_0, {}, {}, []);
export { CopyTransportComponentNgFactory as CopyTransportComponentNgFactory };
