<nav class="navbar navbar-expand navbar-light pb-0 pt-0 bg-light mb-2">
  <div class="breadCrumbs">
    <span
      *ngFor="let crumb of breadCrumbs; let i = index"
      class="title"
      class="d-flex align-items-center mb-0"
    >
      <span *ngIf="i !== breadCrumbs.length - 1; else lastCrumb">
        <a routerLink="{{ getRouteUrl(i) }}">{{
          getRouteName(getRouteUrl(i)) | translate
        }}</a>
        <span>&nbsp; &gt; &nbsp;</span>
      </span>
      <ng-template #lastCrumb>{{
        getRouteName(getRouteUrl(i)) | translate
      }}</ng-template>
    </span>
    <ng-content></ng-content>
  </div>
  <div class="user-info">
    <span class="name divider">
      <strong class="fist-name">{{ user?.firstName | titlecase }}&nbsp;</strong>
      <strong>{{ user?.lastName | titlecase }}</strong>
    </span>
    <div id="tenant-name">{{ tenant?.name }}</div>
    <button
      class="btn btn-link"
      ngbTooltip="{{ 'Settings' | translate }}"
      placement="bottom"
      routerLink="/settings"
    >
      <i class="far fa-fw fa-cog"></i>
    </button>
  </div>
</nav>
