<ngb-tabset [justify]="'fill'">
  <ngb-tab>
    <ng-template ngbTabTitle>{{ 'Tenants' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of tenantsRoutes"
            routerLink="{{ route.url }}"
            class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>{{ 'Wallets' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of walletsRoutes"
            routerLink="{{ route.url }}"
            class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>{{ 'Locations' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of locationsRoutes"
            routerLink="{{ route.url }}"
            class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>{{ 'Container Types' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of containerTypesRoutes"
            routerLink="{{ route.url }}"
            class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>{{ 'Liners' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of linersRoutes"
            routerLink="{{ route.url }}"
            class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab [disabled]="true">
    <ng-template ngbTabTitle>{{ 'Beta Users' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of betaUsersRoutes"
            routerLink="{{ route.url }}"
            class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>{{ 'Subscriptions' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <!-- <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of subscriptionRoutes" routerLink="{{ route.url }}" class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div> -->
      <app-manage-subscription-types></app-manage-subscription-types>
    </ng-template>
  </ngb-tab>
  <ngb-tab>
    <ng-template ngbTabTitle>{{ 'Certiweight' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <div class="card">
        <ul class="list-group list-group-flush">
          <a *ngFor="let route of certiweightRoutes"
            routerLink="{{ route.url }}"
            class="list-group-item list-group-item-action list-group-item-light">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ route.title | translate }}</h5>
              <small class="text-muted"></small>
            </div>
            <p class="mb-0">{{ route.description | translate }}</p>
          </a>
        </ul>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
