import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'app/services/toast.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { IconService } from 'app/services/icon.service';
import { LoadDrivers } from 'app/core/store/actions/driver.actions';
import { EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'app/core/api/api.service';
import * as moment from 'moment';
import { NgbModal, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NewDriverModalComponent } from '../../drivers/new-driver-modal/new-driver-modal.component';
import { EditDriverModalComponent } from '../../drivers/edit-driver-modal/edit-driver-modal.component';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
var VisitFormComponent = /** @class */ (function () {
    function VisitFormComponent(store, api, modalService, iconService, route, toastr, calendar) {
        var _this = this;
        this.store = store;
        this.api = api;
        this.modalService = modalService;
        this.iconService = iconService;
        this.route = route;
        this.toastr = toastr;
        this.calendar = calendar;
        this.selectedTasks = [];
        this.isLoading = false;
        this.isLoadingLocations = false;
        this.visitFormSubmitted = new EventEmitter();
        this.locationSelected = new EventEmitter();
        this.searchTextChanged = new Subject();
        this.tasks = [];
        this.dragulaTransports = 'dragulaTransports';
        this.subs = new Subscription();
        this.route.paramMap.pipe(take(1)).subscribe(function (params) {
            if (params.get('task')) {
                var taskId_1 = params.get('task');
                _this.store
                    .select(function (state) { return state.transports; })
                    .pipe(untilDestroyed(_this))
                    .subscribe(function (transportsState) {
                    if (!transportsState) {
                        console.log('No transports found');
                    }
                    else {
                        var transport = transportsState['data'].find(function (t) {
                            return t.transportTasks.some(function (tTask) { return tTask.id === taskId_1; });
                        });
                        _this.task = transport.transportTasks.find(function (t) { return t.id === taskId_1; });
                        if (!_this.task.startDate) {
                            _this.task.startDate = transport.primaryTaskDate;
                        }
                        _this.task.transport = transport;
                        _this.task['badgeColor'] = _this.getTaskBadgeColor(_this.task);
                        _this.scheduledAt = new Date(moment(_this.task.startDate).year(), moment(_this.task.startDate).month(), moment(_this.task.startDate).date());
                        _this.location = {
                            label: _this.task.location.name,
                            value: _this.task.location.id
                        };
                        _this.handleLocationSelected({ value: _this.task.location.id });
                    }
                });
            }
        });
        this.isLoadingDrivers = true;
        this.store.dispatch(new LoadDrivers({
            includeSuperAdmins: 0,
            roleName: 'driver'
        }));
        this.store
            .select(function (state) { return state.drivers; })
            .pipe(untilDestroyed(this))
            .subscribe(function (usercontexts) {
            if (usercontexts['drivers']) {
                _this.drivers = usercontexts['drivers'].map(function (driver) {
                    return {
                        '@id': driver['@id'],
                        name: driver.user.firstName + " " + driver.user.lastName + " - " + driver.licensePlate,
                        id: driver.id,
                        licensePlate: driver.licensePlate
                    };
                });
                _this.isLoadingDrivers = false;
            }
        });
        this.searchTextChanged
            .pipe(debounceTime(500), distinctUntilChanged(), map(function (search) { return _this.searchTransportTasks(search); }))
            .subscribe(function () { });
    }
    VisitFormComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes && changes.task && changes.task.currentValue) {
            var task_1 = changes.task.currentValue;
            // Editing a task which has a visit
            if (task_1.visit) {
                this.visit = Object.assign({}, task_1.visit);
                var visit = Object.assign({}, task_1.visit);
                this.scheduledAt = new Date(moment(visit.scheduledAt).year(), moment(visit.scheduledAt).month(), moment(visit.scheduledAt).date());
                this.tasksFromDate = {
                    year: moment(visit.scheduledAt).year(),
                    month: moment(visit.scheduledAt)
                        .add(1, 'month')
                        .month(),
                    day: moment(visit.scheduledAt).date()
                };
                this.tasksToDate = {
                    year: moment(visit.scheduledAt).year(),
                    month: moment(visit.scheduledAt)
                        .add(1, 'month')
                        .month(),
                    day: moment(visit.scheduledAt).date()
                };
                visit.driver = visit.driver
                    ? {
                        '@id': visit.driver['@id'],
                        name: visit.driver.user.firstName +
                            ' ' +
                            visit.driver.user.lastName +
                            ' - ' +
                            visit.driver.licensePlate,
                        id: visit.driver.id
                    }
                    : null;
                this.driver = visit.driver ? visit.driver.id : null;
                if (task_1.location) {
                    this.location = task_1.location.name;
                    this.getTasksByLocation({
                        value: task_1.location.id
                    });
                    this.locations = [];
                    this.locations.push(task_1.location);
                    if (this.filteredTasks && this.filteredTasks.length > 0) {
                        this.selectedTasks = [];
                        visit.transportTasks.forEach(function (t) {
                            _this.selectedTasks = _this.selectedTasks.concat([t.id]);
                        });
                    }
                }
                // Creating a new visit from a task
            }
            else {
                if (task_1.startDate) {
                    this.tasksFromDate = {
                        year: moment(task_1.startDate).year(),
                        month: moment(task_1.startDate)
                            .add(1, 'month')
                            .month(),
                        day: moment(task_1.startDate).date()
                    };
                }
                else {
                    this.tasksFromDate = {
                        year: moment(this.primaryTaskDate).year(),
                        month: moment(this.primaryTaskDate)
                            .add(1, 'month')
                            .month(),
                        day: moment(this.primaryTaskDate).date()
                    };
                }
                if (task_1.toDate) {
                    this.tasksToDate = {
                        year: moment(task_1.toDate).year(),
                        month: moment(task_1.toDate)
                            .add(1, 'month')
                            .month(),
                        day: moment(task_1.toDate).date()
                    };
                }
                else {
                    this.tasksToDate = {
                        year: moment(this.primaryTaskDate).year(),
                        month: moment(this.primaryTaskDate)
                            .add(1, 'month')
                            .add(1, 'day')
                            .month(),
                        day: moment(this.primaryTaskDate).date()
                    };
                }
                // update the tasks based on the given task location
                this.isLoadingTasks = true;
                this.api
                    .get({
                    // /transport_tasks/for_location/{locationId}/{from}/{to}
                    path: "/transport_tasks/for_location/" + task_1.location.id
                })
                    .subscribe(function (res) {
                    _this.filteredTasks = _this.mapTransportTasks(res);
                    _this.locations = [];
                    _this.locations.push(task_1.location);
                    _this.location = task_1.location.name;
                    _this.selectedTasks = [];
                    _this.selectedTasks.push(task_1.id);
                    _this.isLoadingTasks = false;
                });
                if (task_1.startDate) {
                    this.scheduledAt = new Date(moment(task_1.startDate).year(), moment(task_1.startDate).month(), moment(task_1.startDate).date());
                }
            }
        }
        if (changes && changes.visit && changes.visit.currentValue) {
            this.visit = Object.assign({}, changes.visit.currentValue);
            var visit = Object.assign({}, changes.visit.currentValue);
            this.scheduledAt = new Date(moment(visit.scheduledAt).year(), moment(visit.scheduledAt).month(), moment(visit.scheduledAt).date());
            // Set taskFromDate and taskToDate
            var taskDates_1 = [];
            taskDates_1.push(moment(visit.scheduledAt));
            visit.transportTasks.forEach(function (transportTask) {
                if (transportTask.startDate) {
                    taskDates_1.push(moment(transportTask.startDate));
                }
                if (transportTask.endDate) {
                    taskDates_1.push(moment(transportTask.endDate));
                }
                if (transportTask.transport.primaryTaskDate) {
                    taskDates_1.push(moment(transportTask.transport.primaryTaskDate));
                }
            });
            var tasksMinDate = moment.min(taskDates_1);
            var tasksMaxDate = moment.max(taskDates_1);
            this.tasksFromDate = {
                year: moment(tasksMinDate).year(),
                month: moment(tasksMinDate)
                    .add(1, 'month')
                    .month(),
                day: moment(tasksMinDate).date()
            };
            this.tasksToDate = {
                year: moment(tasksMaxDate).year(),
                month: moment(tasksMaxDate)
                    .add(1, 'month')
                    .month(),
                day: moment(tasksMaxDate).date()
            };
            visit.driver = visit.driver
                ? {
                    '@id': visit.driver['@id'],
                    name: visit.driver.user.firstName +
                        ' ' +
                        visit.driver.user.lastName +
                        ' - ' +
                        visit.driver.licensePlate,
                    id: visit.driver.id
                }
                : null;
            this.driver = visit.driver ? visit.driver.id : null;
            var task = visit.transportTasks[0];
            if (task.location) {
                this.location = task.location.name;
                this.getTasksByLocation({
                    value: task.location.id
                });
                this.locations = [];
                this.locations.push(task.location);
                if (this.filteredTasks && this.filteredTasks.length > 0) {
                    this.selectedTasks = [];
                    visit.transportTasks.forEach(function (t) {
                        _this.selectedTasks = _this.selectedTasks.concat([t.id]);
                    });
                }
            }
        }
    };
    VisitFormComponent.prototype.filterTasksByLocation = function () {
        var _this = this;
        this.filteredTasks = this.tasks.filter(function (task) { return task.location.name === _this.location; });
    };
    VisitFormComponent.prototype.getTaskLocation = function () {
        var _this = this;
        if (this.selectedTasks &&
            this.selectedTasks.length !== 0 &&
            (this.locations && this.locations.length !== 1)) {
            this.location = this.filteredTasks.find(function (task) { return task.id === _this.selectedTasks[0]; }).location.name;
            this.filterTasksByLocation();
        }
    };
    Object.defineProperty(VisitFormComponent.prototype, "fromDate", {
        get: function () {
            if (!this.tasksFromDate) {
                return;
            }
            return moment(new Date(this.tasksFromDate.year, this.tasksFromDate.month - 1, this.tasksFromDate.day));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VisitFormComponent.prototype, "toDate", {
        get: function () {
            if (!this.tasksToDate) {
                return;
            }
            return moment(new Date(this.tasksToDate.year, this.tasksToDate.month - 1, this.tasksToDate.day));
        },
        enumerable: true,
        configurable: true
    });
    VisitFormComponent.prototype.handleDateSelection = function (dateRange) {
        if (dateRange.fromDate && dateRange.toDate) {
            this.tasksFromDate = dateRange.fromDate;
            this.tasksToDate = dateRange.toDate;
            if (!this.location) {
                return;
            }
            this.getTasksByLocation({ value: this.location.value });
        }
    };
    VisitFormComponent.prototype.getTasksByLocation = function (event) {
        var _this = this;
        this.locationSelected.emit(this.location);
        // Clear selected tasks so we don't have selected tasks from another location
        this.selectedTasks = [];
        if (event && event.value) {
            var locationId = event.value;
            this.isLoadingTasks = true;
            this.api
                .get({
                path: "/transport_tasks/for_location/" + locationId
            })
                .subscribe(function (response) {
                _this.handleTransportTasksResponse(response);
                _this.isLoadingTasks = false;
                if (_this.visit) {
                    _this.visit.transportTasks.forEach(function (task) {
                        task['badgeColor'] = _this.getTaskBadgeColor(task);
                        // The current task is not serialized (to prevent infinite serialization) so we can find
                        // the task that is an @id and replace it with the current task
                        var index = task['transport']['transportTasks'].findIndex(function (t) { return typeof t === 'string'; });
                        task['transport']['transportTasks'][index] = task;
                    });
                    _this.selectedTasks = _this.visit.transportTasks.slice();
                }
            }, function () {
                _this.isLoadingTasks = false;
                _this.toastr.showDanger({
                    message: extract('Couldn\'t get tasks for this location')
                });
            });
        }
    };
    VisitFormComponent.prototype.searchTransportTasks = function (query) {
        var _this = this;
        this.isLoadingTasks = true;
        this.api
            .get({
            path: "/transport_tasks/for_location/" + this.location.value + "?query=" + query
        })
            .subscribe(function (response) {
            _this.handleTransportTasksResponse(response);
            _this.isLoadingTasks = false;
        }, function () {
            _this.isLoadingTasks = false;
        });
    };
    VisitFormComponent.prototype.handleTransportTasksResponse = function (response) {
        var tasksWithoutVisit = this.getTasksWithoutVisit(response);
        this.filteredTasks = this.mapTransportTasks(tasksWithoutVisit);
        this.tasks = this.filteredTasks.slice();
    };
    VisitFormComponent.prototype.getTasksWithoutVisit = function (tasks) {
        var _this = this;
        // Filter out tasks that are linked to a visit, but only if we're not editing.
        return tasks.filter(function (task) {
            if (!_this.visit) {
                if (!task['visit']) {
                    return task;
                }
            }
            else {
                return task;
            }
        });
    };
    VisitFormComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitAttempt = true;
        if (!this.location) {
            return;
        }
        if (!this.scheduledAt) {
            return;
        }
        var body = {
            scheduledAt: moment(this.scheduledAt).toISOString(true)
        };
        if (this.driver) {
            body['driver'] = this.drivers.find(function (d) { return d.id === _this.driver; })['@id'];
        }
        body['transportTasks'] = this.selectedTasks.map(function (t) {
            return { id: t['id'] };
        });
        this.visitFormSubmitted.emit(body);
    };
    VisitFormComponent.prototype.openEditDriverModal = function () {
        var modalRef = this.modalService.open(EditDriverModalComponent);
        modalRef.componentInstance.driverId = this.driver;
    };
    VisitFormComponent.prototype.openNewDriverModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(NewDriverModalComponent);
        modalRef.result.then(function (result) {
            _this.driver = result['payload'].id;
        });
    };
    VisitFormComponent.prototype.mapTransportTasks = function (tasks) {
        var _this = this;
        return tasks.map(function (task) {
            var index = task['transport']['transportTasks'].findIndex(function (t) { return typeof t === 'string'; });
            task['transport']['transportTasks'][index] = task;
            return {
                '@id': task['@id'],
                id: task.id,
                location: {
                    name: task.location.name
                },
                label: task.taskType.label.toUpperCase() + " " + task.reference,
                transport: task['transport'],
                reference: task.reference,
                disabled: task.referenceCheckedAt && !task.referenceVerified,
                badgeColor: _this.getTaskBadgeColor(task)
            };
        });
    };
    VisitFormComponent.prototype.getTaskIcon = function (task) {
        return this.iconService.getTransportTaskIcon(task);
    };
    /**
     * Returns true if all selected tasks have a reference
     */
    VisitFormComponent.prototype.allTasksHaveReference = function () {
        var _this = this;
        if (!this.selectedTasks || !this.filteredTasks || this.isLoadingTasks) {
            return;
        }
        return this.selectedTasks.every(function (task) {
            var filteredTask = _this.filteredTasks.find(function (t) { return t.id === task; });
            if (!filteredTask) {
                return;
            }
            return filteredTask.reference;
        });
    };
    VisitFormComponent.prototype.search = function (searchQuery) {
        this.searchTextChanged.next(this.query);
    };
    VisitFormComponent.prototype.handleTransportSelected = function (task) {
        if (this.selectedTasks.indexOf(task) !== -1) {
            this.tasks.push(task);
            this.selectedTasks = this.selectedTasks.filter(function (t) { return t.id !== task.id; });
            return;
        }
        this.selectedTasks.push(task);
        this.tasks = this.tasks.filter(function (t) { return t.id !== task.id; });
    };
    VisitFormComponent.prototype.handleLocationSelected = function (location) {
        var _this = this;
        if (!location || !location.value) {
            console.log('Location has no value');
            return;
        }
        this.isLoadingTasks = true;
        this.selectedTasks = [];
        this.api
            .get({
            path: "/transport_tasks/for_location/" + location.value
        })
            .subscribe(function (response) {
            response.forEach(function (task) {
                // Set badge status
                task['badgeColor'] = _this.getTaskBadgeColor(task);
                // The current task is not serialized (to prevent infinite serialization) so we can find
                // the task that is an @id and replace it with the current task
                var index = task['transport']['transportTasks'].findIndex(function (t) { return typeof t === 'string'; });
                task['transport']['transportTasks'][index] = task;
            });
            _this.tasks = response.slice();
            if (_this.task) {
                _this.tasks = _this.tasks.filter(function (t) { return t.id !== _this.task.id; });
                _this.selectedTasks = [_this.task].concat(_this.selectedTasks);
            }
        }, function (error) {
            _this.isLoadingTasks = false;
            _this.toastr.showDanger({
                message: extract('Couldn\'t get tasks for this location')
            });
        }, function () {
            _this.isLoadingTasks = false;
        });
    };
    Object.defineProperty(VisitFormComponent.prototype, "selectedDriver", {
        get: function () {
            var _this = this;
            if (!this.driver) {
                return;
            }
            return this.drivers.find(function (driver) { return driver.id === _this.driver; });
        },
        enumerable: true,
        configurable: true
    });
    VisitFormComponent.prototype.getTaskBadgeColor = function (task) {
        if (task.referenceCheckedAt) {
            return task.referenceVerified ? 'badge-success' : 'badge-danger';
        }
        return task.reference ? 'badge-success' : 'badge-danger';
    };
    VisitFormComponent.prototype.ngOnDestroy = function () { };
    return VisitFormComponent;
}());
export { VisitFormComponent };
