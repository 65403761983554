import { Routes } from '@angular/router';
import { SubscriptionGuard } from 'app/core/auth/route-guards/subscription.guard';
import { TenantGuard } from 'app/core/auth/route-guards/tenant.guard';
import { Route } from 'app/core/route.service';
import { AuthGuard } from 'app/core/user/user.authguard';
import { BuyCertificateComponent } from './buy-certificate.component';
import { CertificateDetailComponent } from './certificate-detail.component';
import { CertiweightComponent } from './certiweight.component';
import { MyCertificatesComponent } from './my-certificates.component';
var ɵ0 = { subscriptionCode: 'CERTIWEIGHT' };
var routes = Route.withShell([
    {
        path: 'certiweight',
        canActivate: [AuthGuard, TenantGuard, SubscriptionGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: CertiweightComponent,
            },
            {
                path: ':id/detail',
                component: CertificateDetailComponent,
            },
            {
                path: 'my',
                component: MyCertificatesComponent,
            },
            {
                path: 'buy',
                component: BuyCertificateComponent,
            }
        ],
    }
]);
var CertiweightModule = /** @class */ (function () {
    function CertiweightModule() {
    }
    return CertiweightModule;
}());
export { CertiweightModule };
export { ɵ0 };
