import { SearchService } from 'app/services/search.service';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import {
  Component,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClearFilter } from 'app/core/store/actions/filter.actions';
@Component({
  selector: 'app-cmp-market-toolbar',
  templateUrl: 'market-toolbar.component.html',
  styleUrls: ['./market-toolbar.component.scss']
})
export class MarketToolbarComponent {
  @Input() newPost;
  @Input() search;
  @Input() messages;
  @Input() viewToggle;
  @Input() filters;
  @Input() map;

  @Output() mapToggled = new EventEmitter<boolean>();
  @Output() filterToggled = new EventEmitter<boolean>();
  @Output() viewToggled = new EventEmitter<boolean>();
  @Output() messageBoardToggled = new EventEmitter<boolean>();
  @Output() searchCleared = new EventEmitter<boolean>();

  cardView: boolean;
  showMessageBoard: boolean;
  showFilter: boolean;
  showMap: boolean;
  query: string;

  constructor(
    private store: Store<State>,
    private searchService: SearchService
  ) {
    const showMessages = localStorage.getItem('showMessageBoard');
    this.showMessageBoard = showMessages && showMessages === 'true';

    const displayCardView = localStorage.getItem('displayCardView');
    this.cardView = displayCardView && displayCardView === 'true';

    const showFilter = localStorage.getItem('showFilter');
    this.showFilter = showFilter && showFilter === 'true';

    const showMap = localStorage.getItem('showMap');
    this.showMap = showMap && showMap === 'true';

    this.query = localStorage.getItem('searchQuery');
  }

  toggleTransportView() {
    this.cardView = !this.cardView;
    this.viewToggled.emit(this.cardView);
  }

  toggleMessageBoard() {
    this.showMessageBoard = !this.showMessageBoard;
    this.messageBoardToggled.emit(this.showMessageBoard);
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.filterToggled.emit(this.showFilter);
  }

  toggleMap() {
    this.showMap = !this.showMap;
    this.mapToggled.emit(this.showMap);
    if (!this.showMap) {
      localStorage.removeItem('mapQuery');
      localStorage.removeItem('mq');
      this.searchService.filter();
    }
  }

  searchWithQuery = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(50),
      distinctUntilChanged(),
      map(term => {
        this.searchService.searchWithQuery(
          [
            'container.liner.name',
            'container.liner.shortName',
            'transportType.label',
            'tasks.location.name',
            'reference',
            'comment',
            'originLocation.name',
            'destinationLocation.name',
            'location.name',
            'tenant.name',
            'container.containerType.label',
            'marketPostType'
          ],
          term
        );
        return [];
      })
    )
  clearSearch() {
    this.store.dispatch(new ClearFilter());
    this.searchService.resetSearch();
    this.query = '';
    localStorage.removeItem('searchQuery');
  }
}
