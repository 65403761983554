<div class="row p-3 mr-0">
  <div class="col p-0">
    <label class="sr-only"
      for="inlineFormInputGroup">{{ 'Search' | translate }}</label>
    <div class="input-group mb-2 ml-3 mr-3 mb-sm-0">
      <input type="text"
        class="form-control"
        id="inlineFormInputGroup"
        placeholder="{{ 'Search' | translate }}"
        [(ngModel)]="query"
        (ngModelChange)="filter($event)">
      <div class="input-group-append">
        <span class="input-group-text">
          <i class="far fa-search"></i>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="row p-3 mr-0">
  <div class="col">
    <app-cmp-loading [loading]="loading"></app-cmp-loading>
    <div class="address-book-grid"
      *ngIf="filteredAddressBookEntries">
      <div class="card mb-3 address-book-entry"
        *ngFor="let tenant of filteredAddressBookEntries">
        <div class="card-header text-center">
          <span>{{ tenant?.name }}</span>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <span class="pr-1">
              <i class="far fa-envelope"
                ngbTooltip="address"></i>
            </span>
            <span class="text-right">{{ tenant?.street_and_number }}, {{tenant?.zipcode}} {{tenant?.city}}, {{tenant?.country}}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="pr-1">
              <i class="far fa-phone"
                ngbTooltip="phone number"></i>
            </span>
            <span class="text-right">{{ tenant?.phone_number }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="pr-1">
              <i class="far fa-at"
                ngbTooltip="email"></i>
            </span>
            <span class="text-right">{{ tenant?.email }}</span>
          </div>
          <hr>
          <div *ngIf="tenant?.users">
            <div class="text-center">
            </div>
            <div *ngFor="let user of tenant.users">
              <span class="d-flex justify-content-between">
                <span class="text-weight-bold"
                  ngbTooltip="{{user.email}}">
                  <a href="mailto:'{{user.email}}'">
                    {{ user?.first_name }} {{ user?.last_name }}
                  </a>
                </span>
                <span class="text-right">{{ user?.phone_number }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
