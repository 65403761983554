import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import {
  LoadSuppliers,
  LoadFilteredSuppliers,
  DeleteSupplier
} from 'app/core/store/actions/supplier.actions';
import { Router } from '@angular/router';
import { Supplier } from 'app/core/store/models/supplier.model';
import { extract } from 'app/services/i18n.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit, OnDestroy {
  title = extract('Suppliers');
  searchPlaceholder = extract('Search name or VAT number');
  suppliers: Supplier[];
  filteredSuppliers: Supplier[];
  totalElements = 0;
  isLoadingSuppliers: boolean;
  query: any;

  constructor(
    private router: Router,
    private store: Store<State>,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.suppliers &&
          state.crm.suppliers.suppliers
        ) {
          return state.crm.suppliers.suppliers;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(supplierState => {
        if (!supplierState) {
          return;
        }
        this.suppliers = [...supplierState];
        this.isLoadingSuppliers = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.suppliers &&
          state.crm.suppliers.filteredSuppliers
        ) {
          return state.crm.suppliers.filteredSuppliers;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(filteredSuppliers => {
        if (!filteredSuppliers) {
          return;
        }
        this.filteredSuppliers = [...filteredSuppliers];
        this.isLoadingSuppliers = false;
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadSuppliers());
    this.isLoadingSuppliers = true;
  }

  ngOnDestroy() { }

  handleSearch(query) {
    if (!query) {
      this.store.dispatch(new LoadSuppliers());
      return;
    }
    this.isLoadingSuppliers = true;
    this.query = query;
    this.store.dispatch(new LoadFilteredSuppliers({ query: query }));
  }

  goToEditSupplier(supplier) {
    this.router.navigateByUrl(`/crm/suppliers/${supplier.id}/edit`);
  }
  deleteSupplier(supplier: Supplier) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this supplier?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteSupplier({ supplierId: supplier.id }));
    });
  }
}
