import { ContainerType } from 'app/core/store/models/container-types.model';
import { Liner } from 'app/core/store/models/liner.model';
import { Customer } from 'app/core/store/models/customer.model';
import * as CustomerTypes from 'app/core/store/types/customer.types';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import { LoadProducts } from 'app/core/store/actions/product.actions';
import { MatchingTypes } from 'app/core/store/models/matching.model';
import { ToastService } from 'app/services/toast.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { Alert } from 'app/core/store/models/alert.model';
import { CreateTransport } from 'app/core/store/actions/transports.actions';
import { MarketpostParserService } from 'app/services/marketpost-parser.service';
import * as Types from 'app/core/store/types/transport.types';
import { Router } from '@angular/router';
import { IconService } from 'app/services/icon.service';
import { EnableMatching } from 'app/core/store/actions/matching.actions';
import { Transport } from 'app/core/store/models/transport.model';
import { extract } from 'app/services/i18n.service';
import { Product } from 'app/core/store/models/product.model';
import { LoadCustomers } from 'app/core/store/actions/customer.actions';
import { Supplier } from 'app/core/store/models/supplier.model';
import { LoadSuppliers } from 'app/core/store/actions/supplier.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-transport',
  templateUrl: './new-transport.component.html',
})
export class NewTransportComponent implements OnInit, OnDestroy {
  @Input()
  transport: Transport;

  title = extract('New Transport');
  loading: boolean;
  transportTypesHasValue$ = new Subject<boolean>();
  transportTypes: TransportType[];
  customerTariffs: Product[];
  supplierTariffs: Product[];
  tmsTransportTypes = ['load', 'unload', 'shunt'];
  alert: Alert;
  matchingEnabled: boolean;
  customers: Customer[];
  isLoadingCustomers: boolean;
  liners: Liner[];
  isLoadingLiners: boolean;
  containerTypes: ContainerType[];
  isLoadingContainerTypes: boolean;
  suppliers: Supplier[];
  isLoadingSuppliers: boolean;
  submitAndContinue: boolean;

  constructor(
    private store: Store<State>,
    private transportParser: MarketpostParserService,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router,
    private iconSerivce: IconService
  ) {
    this.store.dispatch(new LoadTransportTypes());
    this.store
      .select(state => {
        if (state && state.marketplace && state.marketplace.transportTypes) {
          return state.marketplace.transportTypes;
        }
      })
      .pipe(takeUntil(this.transportTypesHasValue$))
      .subscribe(transportTypes => {
        if (transportTypes && transportTypes.length !== 0) {
          // Filter out transport types that are not available in TMS
          const temp = transportTypes.filter(
            transportType =>
              this.tmsTransportTypes.indexOf(transportType.label) !== -1
          );
          temp.forEach(type => {
            type['transportTypeTasks'] = type['transportTypeTasks'].map(
              task => {
                return {
                  taskType: {
                    '@id': task.taskType['@id'],
                    icon: this.getTaskIcon(task),
                    id: task.taskType.id,
                    label: task.taskType.code,
                    code: task.taskType.code
                  },
                  location: null,
                  startDate: null,
                  startTime: null,
                  endDate: null,
                  endTime: null,
                  startDateTimeSpecified: false,
                  endDateTimeSpecified: false
                };
              }
            );
          });
          this.transportTypes = [...temp];
          this.transportTypesHasValue$.next(true);
          this.transportTypesHasValue$.unsubscribe();
        }
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.products &&
          state.crm.products.customerProducts
        ) {
          return state.crm.products.customerProducts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(productState => {
        if (!productState || !productState) {
          this.store.dispatch(new LoadProducts());
          return;
        }
        this.customerTariffs = [...productState];
      });
    this.store
      .select(state => {
        if (state && state.marketplace && state.marketplace.liners) {
          return state.marketplace.liners;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (!liners) {
          this.store.dispatch(new LoadLiners());
          return;
        }
        this.liners = [...liners];
      });
    this.store
      .select(state => {
        if (state && state.containerTypes) {
          return state.containerTypes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(containerTypes => {
        if (!containerTypes) {
          this.store.dispatch(new LoadContainerTypes());
          return;
        }
        this.containerTypes = [...containerTypes];
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.products &&
          state.crm.products.supplierProducts
        ) {
          return state.crm.products.supplierProducts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(productsState => {
        if (!productsState || !productsState) {
          this.store.dispatch(new LoadProducts());
          return;
        }
        this.supplierTariffs = [...productsState];
      });
    this.store
      .select(state => state.crm.customers)
      .pipe(untilDestroyed(this))
      .subscribe(customersState => {
        if (!customersState || !customersState.customers) {
          this.store.dispatch(new LoadCustomers());
          this.isLoadingCustomers = true;
          return;
        }
        this.customers = [...customersState.customers];
        this.isLoadingCustomers = false;
      });
    this.store
      .select(state => state.crm.suppliers)
      .pipe(untilDestroyed(this))
      .subscribe(suppliersState => {
        if (!suppliersState || !suppliersState.suppliers) {
          this.store.dispatch(new LoadSuppliers());
          this.isLoadingSuppliers = true;
          return;
        }
        this.suppliers = [...suppliersState.suppliers];
        this.isLoadingSuppliers = false;
      });
  }

  ngOnInit() {
    this.updates$
      .pipe(ofType(Types.transports.CREATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const transport = result['payload'] as Transport;
        if (this.matchingEnabled) {
          this.enableMatching(transport.id);
        }
        this.loading = false;
        this.toastr.showSuccess({
          message: extract('Transport created!')
        });

        if (!this.submitAndContinue) {
          // Navigate to the transports page on the day of the primary task date
          this.router.navigate([
            `tms/transports`,
            { date: transport.primaryTaskDate }
          ]);
        }
        this.submitAndContinue = false;
      });
    this.updates$
      .pipe(ofType(Types.transports.CREATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to create transport')
        });
      });
    this.updates$
      .pipe(ofType(CustomerTypes.customer.LOAD_CUSTOMERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load customers')
        });
        this.isLoadingCustomers = false;
      });
    this.updates$
      .pipe(ofType(CustomerTypes.customer.CREATE_CUSTOMER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadCustomers());
      });
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.CREATE_SUPPLIER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadSuppliers());
      });
  }

  onSubmit(transportFormValue) {
    this.loading = true;
    const transport = this.transportParser.parseTransportOffer(
      transportFormValue
    );
    this.store.dispatch(new CreateTransport(transport));
  }

  toggleMatchingState(matchingEnabled: boolean) {
    this.matchingEnabled = matchingEnabled;
  }

  enableMatching(transportId: string) {
    this.store.dispatch(
      new EnableMatching({
        type: MatchingTypes.TRANSPORT,
        id: transportId
      })
    );
  }

  getTaskIcon(task) {
    this.iconSerivce.getTransportTaskIcon(task);
  }

  ngOnDestroy() { }
}
