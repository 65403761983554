import { Observable } from 'rxjs';
import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import { NotificationSubscription } from 'app/core/store/models/notification-subscriptions.model';
import { Actions, ofType } from '@ngrx/effects';
import * as NotificationSubscriptionTypes from 'app/core/store/types/notification-subscription.types';
import { ToastService } from 'app/services/toast.service';
import { LoadNotificationSubscriptions } from 'app/core/store/actions/notification-subscriptions.actions';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-notifcation-subscriptions',
  templateUrl: 'notification-subscriptions.component.html',
})
export class NotificationSubscriptionsComponent implements OnDestroy {
  messageBoardMessageSubscription: NotificationSubscription;
  title = extract('Manage Email Notifications');
  notificationSubscriptionTypes$: Observable<NotificationSubscription[]>;
  offerSubscriptionId: string;
  demandSubscriptionId: string;
  offerContainerTypes = [];
  demandContainerTypes = [];
  offerTransportTypes = [];
  demandTransportTypes = [];

  constructor(
    private store: Store<State>,
    private updateNotifications$: Actions,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadNotificationSubscriptions());
    this.store.dispatch(new LoadTransportTypes());
    this.store.dispatch(new LoadContainerTypes());
    this.notificationSubscriptionTypes$ = this.store.select(
      state => state.notificationSubscriptions
    ).pipe(untilDestroyed(this));
    this.notificationSubscriptionTypes$.subscribe(subscriptions => {
      if (subscriptions) {
        const offerSubs = subscriptions.filter(sub => sub.type === 'offer');
        const demandSubs = subscriptions.filter(sub => sub.type === 'demand');

        this.offerContainerTypes = offerSubs.filter(
          offerSub => offerSub.containerType
        );
        this.offerTransportTypes = offerSubs.filter(
          offerSub => offerSub.transportType
        );
        this.demandContainerTypes = demandSubs.filter(
          demandSub => demandSub.containerType
        );
        this.demandTransportTypes = demandSubs.filter(
          demandSub => demandSub.transportType
        );
        const mbs = subscriptions.filter(s => s.type === 'message_board');
        this.messageBoardMessageSubscription = mbs.length !== 0 ? mbs[0] : null;
      }
    });

    updateNotifications$
      .pipe(ofType(
        NotificationSubscriptionTypes.notificationSubscriptions
          .ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Email preferences saved!'
        });
        this.store.dispatch(new LoadNotificationSubscriptions());
      });
    updateNotifications$
      .pipe(ofType(
        NotificationSubscriptionTypes.notificationSubscriptions
          .ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_FAILED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to save your email preferences'
        });
      });
  }

  ngOnDestroy() { }
}
