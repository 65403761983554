import { Router } from '@angular/router';
import { CreateDriver, UpdateDriver } from 'app/core/store/actions/driver.actions';
import { Component, OnDestroy } from '@angular/core';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { UserContext } from 'app/core/store/models/user-contexts.model';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import * as DriverTypes from 'app/core/store/types/driver.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-new-driver',
  templateUrl: 'new-driver.component.html'
})
export class NewDriverComponent implements OnDestroy {
  title = extract('New Driver');
  isLoading: boolean;
  userContext: UserContext;

  constructor(
    private store: Store<State>,
    private toastService: ToastService,
    private updates$: Actions,
    private router: Router
  ) {
    this.updates$
      .pipe(ofType(DriverTypes.drivers.CREATE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        const userId = response['payload'].id;
        this.updateDriver(userId);
      });
    this.updates$
      .pipe(ofType(DriverTypes.drivers.UPDATE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastService.showSuccess({
          title: extract('Create driver success!')
        });
        this.isLoading = false;
        this.router.navigate(['tms/drivers']);
      });

    // Stop the loading spinner if one of our calls fails
    this.updates$
      .pipe(ofType(
        DriverTypes.drivers.CREATE_DRIVER_FAILED,
        DriverTypes.drivers.UPDATE_DRIVER_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  ngOnDestroy() { }

  createNewDriver(userContextFormValue) {
    this.userContext = userContextFormValue;
    const body = {
      email: userContextFormValue.email,
      firstName: userContextFormValue.firstName,
      lastName: userContextFormValue.lastName,
      role: 'DRIVER',
      locale: userContextFormValue.locale
    };
    this.store.dispatch(new CreateDriver(body));
    this.isLoading = true;
  }

  updateDriver(userId: string) {
    const body = {
      phoneNumber: this.userContext.phoneNumber,
      locale: this.userContext.locale,
      licensePlate: this.userContext.licensePlate
    };
    this.store.dispatch(new UpdateDriver({ driverId: userId, driver: body }));
  }
}
