var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/product.types';
import * as ProductActions from '../actions/product.actions';
import { ApiService } from '../../api/api.service';
var ProductEffects = /** @class */ (function () {
    function ProductEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadProducts$ = this.actions$.pipe(ofType(types.product.LOAD_PRODUCTS), concatMap(function () { return _this.api.get({
            path: "/crm/products?template=1"
        }).pipe(map(function (data) { return new ProductActions.LoadProductsSuccess(data); }), catchError(function (error) { return of(new ProductActions.LoadProductsFail(error)); })); }));
        this.loadFilteredProducts$ = this.actions$.pipe(ofType(types.product.LOAD_FILTERED_PRODUCTS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            return _this.api.get({
                path: "/crm/products?template=1&label=" + query
            }).pipe(map(function (data) { return new ProductActions.LoadFilteredProductsSuccess(data); }), catchError(function (error) { return of(new ProductActions.LoadFilteredProductsFail(error)); }));
        }));
        this.createProduct$ = this.actions$.pipe(ofType(types.product.CREATE_PRODUCT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/crm/products", body: payload
        }).pipe(map(function (data) { return new ProductActions.CreateProductSuccess(data); }), catchError(function (error) { return of(new ProductActions.CreateProductFail(error)); })); }));
        this.deleteProduct$ = this.actions$.pipe(ofType(types.product.DELETE_PRODUCT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/products/" + payload.productId
        }).pipe(map(function () { return new ProductActions.DeleteProductSuccess(payload.productId); }), catchError(function (error) { return of(new ProductActions.DeleteProductFail(error)); })); }));
        this.updateProduct$ = this.actions$.pipe(ofType(types.product.UPDATE_PRODUCT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/products/" + payload.productId, body: payload.body
        }).pipe(map(function (data) { return new ProductActions.UpdateProductSuccess(data); }), catchError(function (error) { return of(new ProductActions.UpdateProductFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffects.prototype, "loadProducts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffects.prototype, "loadFilteredProducts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffects.prototype, "createProduct$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffects.prototype, "deleteProduct$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ProductEffects.prototype, "updateProduct$", void 0);
    return ProductEffects;
}());
export { ProductEffects };
