import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/marketplace.types';
import * as MessagesActions from '../actions/messages.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class MessagesEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadMessages$: Observable<Action> = this.actions$.pipe(
    ofType(types.messages.LOAD_MESSAGES),
    concatMap(() => this.api.get({ path: '/public/message_board_messages' }).pipe(
      map(data => new MessagesActions.LoadMessagesSuccess(data)),
      catchError(error => of(new MessagesActions.LoadMessagesFailed(error)))
    )),
  );

  @Effect()
  createMessage$: Observable<Action> = this.actions$.pipe(
    ofType(types.messages.CREATE_MESSAGE),
    map((action: MessagesActions.CreateMessage) => action.payload),
    concatMap(payload => this.api.post({
      path: '/message_board_messages ', body: payload
    }).pipe(
      map(data => new MessagesActions.CreateMessageSuccess(data)),
      catchError(error => of(new MessagesActions.CreateMessageFailed(error)))
    )),
  );

  @Effect()
  createMessageReply$: Observable<Action> = this.actions$.pipe(
    ofType(types.messages.CREATE_REPLY),
    map((action: MessagesActions.CreateReply) => action.payload),
    concatMap(payload => this.api.post({
      path: '/message_board_messages/' + payload.id + '/reply',
      body: payload.body
    }).pipe(
      map(data => new MessagesActions.CreateReplySuccess({
        data, parentId: payload.id,
      })),
      catchError(error => of(new MessagesActions.CreateReplyFailed(error)))
    ))
  );
}
