import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import { PublishMarketPostOffer, UnpublishMarketPostOffer } from 'app/core/store/actions/market-post-offer.actions';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/marketplace.types';
import { SearchService } from 'app/services/search.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
var MyOffersComponent = /** @class */ (function () {
    function MyOffersComponent(store, updates$, searchService, toastr) {
        var _this = this;
        this.store = store;
        this.updates$ = updates$;
        this.searchService = searchService;
        this.toastr = toastr;
        this.navbarTitle = 'Company Offers';
        this.selected = [];
        this.loading = true;
        this.tenantId = '';
        this.searchService.resetSearch();
        this.searchService.filter();
        this.store
            .select(function (state) { return state.session; })
            .pipe(untilDestroyed(this))
            .subscribe(function (session) {
            if (session) {
                _this.tenantId = session.tenant;
            }
        });
        this.store
            .select(function (state) {
            if (state.marketplace && state.marketplace.marketposts) {
                return state.marketplace.marketposts;
            }
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (marketposts) {
            if (marketposts) {
                var temp = marketposts.map(function (mp) { return mp._source; });
                _this.offers = temp.filter(function (mp) {
                    return mp.marketPostType === 'offer' && mp['tenant'].id === _this.tenantId;
                });
                _this.loading = false;
            }
        });
        updates$
            .pipe(ofType(Types.marketposts.LOAD_MARKETPOSTS_OFFERS_FAILED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.toastr.showDanger({
                title: 'Something went wrong',
                message: 'Failed to load marketpost offers'
            });
        });
        updates$
            .pipe(ofType(Types.marketposts.UNPUBLISH_MARKET_POST_OFFER_SUCCESS))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.searchService.filter();
        });
    }
    MyOffersComponent.prototype.ngOnDestroy = function () { };
    MyOffersComponent.prototype.onSelect = function (_a) {
        var _b;
        var selected = _a.selected;
        this.selected.splice(0, this.selected.length);
        (_b = this.selected).push.apply(_b, selected);
    };
    MyOffersComponent.prototype.publishSelected = function () {
        var _this = this;
        this.selected.forEach(function (offer) {
            _this.store.dispatch(new PublishMarketPostOffer({ id: offer.id }));
        });
    };
    MyOffersComponent.prototype.unpublishSelected = function () {
        var _this = this;
        this.selected.forEach(function (offer) {
            _this.store.dispatch(new UnpublishMarketPostOffer({ id: offer.id }));
        });
    };
    return MyOffersComponent;
}());
export { MyOffersComponent };
