import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-buy-certificate-modal',
  templateUrl: './buy-certificate-modal.component.html',
})
export class BuyCertificateModalComponent {
  id: string;
  data: any;
  loading = false;

  constructor(
    private api: ApiService,
    private modal: NgbActiveModal,
    private router: Router,
    private toastr: ToastService,
  ) { }

  onSubmit(body) {
    this.api.post({
      path: `/container_measurements/${this.data.id}/buy_certificate`,
      body,
    }).toPromise().then(
      res => {
        this.modal.close();
        this.router.navigate(['certiweight', (<any>res).id, 'detail']);
      },
      () => this.toastr.showDanger({
        title: extract('Something went wrong'),
        message: extract('Failed to buy certificate'),
      }),
    );
  }
}
