import { Action } from '@ngrx/store';
import * as types from '../types/role.types';

export class LoadRoles implements Action {
  readonly type = types.roles.LOAD_ROLES;
  constructor(public payload?: any) {}
}

export class LoadRolesSuccess implements Action {
  readonly type = types.roles.LOAD_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadRolesFail implements Action {
  readonly type = types.roles.LOAD_FAILED;
  constructor(public payload: any) {}
}

export type All = LoadRoles | LoadRolesSuccess | LoadRolesFail;
