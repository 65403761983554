<nav
  *ngIf="newPost || viewToggle || messages || map || search || filters"
  class="navbar navbar-expand navbar-light pr-0 pt-2 d-none d-sm-flex flex-wrap"
>
  <ul class="navbar-nav col-2 col-sm-1 col-md-auto pr-0">
    <li class="nav-item mr-2" *ngIf="filters">
      <a
        id="toggleFilters"
        class="btn btn-outline-secondary"
        (click)="toggleFilter()"
        placement="bottom"
        ngbTooltip="{{ 'Toggle filters' | translate }}"
      >
        <i class="far fa-filter"></i>
      </a>
    </li>
  </ul>
  <div
    class="form-inline my-2 my-lg-0 col-10 col-sm-7 col-md-auto"
    *ngIf="search"
  >
    <span class="input-group">
      <input
        class="form-control md-2 col-8 col-md-auto"
        [(ngModel)]="query"
        name="query"
        [ngbTypeahead]="searchWithQuery"
        type="text"
        placeholder="{{ 'Liner, reference, type, ...' | translate }}"
        aria-label="Search"
      />
      <div class="input-group-append">
        <span class="input-group-text"> <i class="far fa-search"></i> </span>
      </div>
    </span>
  </div>
  <span>
    <a href="javascript: void(0);" (click)="clearSearch()">{{
      'Clear all filters' | translate
    }}</a>
  </span>
  <ul
    class="navbar-nav ml-auto col-12 col-md-auto pr-0 d-flex align-items-center"
  >
    <li class="nav-item mr-1">
      <a
        id="toggleMapButton"
        class="btn btn-outline-secondary"
        (click)="toggleMap()"
        placement="bottom"
        ngbTooltip="{{ 'Toggle map' | translate }}"
      >
        <i class="far fa-map"></i>
      </a>
    </li>
    <li class="nav-item mr-1" *ngIf="viewToggle">
      <a
        id="toggleTransportsViewButton"
        class="btn btn-outline-secondary"
        (click)="toggleTransportView()"
        placement="bottom"
        ngbTooltip="{{ 'Toggle view mode' | translate }}"
      >
        <i class="far fa-th-list" *ngIf="cardView"></i>
        <i class="far fa-table" *ngIf="!cardView"></i>
      </a>
    </li>
    <li class="nav-item mr-3" *ngIf="messages">
      <a
        id="toggleMessagesButton"
        data-cy="toggleMessagesButton"
        class="btn btn-outline-secondary"
        (click)="toggleMessageBoard()"
        placement="bottom"
        ngbTooltip="{{ 'Toggle Message Board' | translate }}"
      >
        <i class="far fa-comments"></i>
      </a>
    </li>
    <li class="nav-item" *ngIf="newPost">
      <a
        id="newMarketPostButton"
        routerLink="/marketplace/create"
        class="btn btn-primary btn"
      >
        <i class="far fa-plus text-white"></i>&nbsp;
        <span class="text-light"> {{ 'New market post' | translate }} </span>
      </a>
    </li>
  </ul>
</nav>

<div class="nav navbar-expand navbar-light bg-light fixed-bottom d-sm-none p-2">
  <ul class="navbar-nav col-12 justify-content-around">
    <li>
      <a
        id="toggleFilters"
        class="btn btn-primary btn-lg"
        (click)="toggleFilter()"
        placement="top"
        ngbTooltip="Toggle filters"
      >
        <i class="far fa-filter"></i>
      </a>
    </li>
    <li *ngIf="messages">
      <a
        id="toggleMessagesButton"
        class="btn btn-primary btn-lg"
        routerLink="/marketplace/chat"
        placement="top"
        ngbTooltip="Message Board"
      >
        <i class="far fa-comments"></i>
      </a>
    </li>
    <li>
      <a
        id="newMarketPostButton"
        class="btn btn-primary btn-lg"
        routerLink="/marketpost/create"
        tooltip="New Market Post"
      >
        <i class="far fa-plus"></i>
      </a>
    </li>
    <li *ngIf="newPost">
      <a
        id="toggleTransportsViewButton"
        class="btn btn-primary btn-lg"
        (click)="toggleTransportView()"
        placement="top"
        ngbTooltip="Toggle view mode"
      >
        <i class="far fa-th-list" *ngIf="cardView"></i>
        <i class="far fa-table" *ngIf="!cardView"></i>
      </a>
    </li>
    <li *ngIf="map">
      <a
        id="toggleMapButton"
        class="btn btn-primary btn-lg"
        routerLink="map"
        placement="top"
        ngbTooltip="Toggle map"
      >
        <i class="far fa-map"></i>
      </a>
    </li>
  </ul>
</div>
