import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
})
export class ContactModalComponent {
  constructor(private modal: NgbActiveModal) { }

  createContact(contactFormValue) {
    this.modal.close(contactFormValue);
  }
}
