import { EventEmitter } from '@angular/core';
var CircleComponent = /** @class */ (function () {
    function CircleComponent() {
        this.navbarTitle = 'Edit Circle';
        this.circleChanged = new EventEmitter();
        this.deleteCircleEmitter = new EventEmitter();
        this.membersMapping = {
            '=0': 'No Members.',
            '=1': '1 Member',
            other: '# Members'
        };
        this.showBody = false;
    }
    CircleComponent.prototype.toggleBody = function () {
        this.showBody = !this.showBody;
    };
    CircleComponent.prototype.removeMember = function (member) {
        this.circle.members.splice(this.circle.members.indexOf(member), 1);
        this.circleChanged.emit(true);
    };
    CircleComponent.prototype.deleteCircle = function () {
        if (this.isMatchingCircle) {
            return;
        }
        this.deleteCircleEmitter.emit(true);
    };
    Object.defineProperty(CircleComponent.prototype, "isMatchingCircle", {
        get: function () {
            if (!this.circle) {
                return;
            }
            return this.circle.matching;
        },
        enumerable: true,
        configurable: true
    });
    CircleComponent.prototype.changeName = function () { };
    return CircleComponent;
}());
export { CircleComponent };
