<app-invoice-form
        [customers]="customers"
        [isLoadingCustomers]="isLoadingCustomers"
        [taxRates]="taxRates"
        [transports]="filteredTransports"
        [isLoadingTaxRates]="isLoadingTaxRates"
        [isLoading]="isLoading"
        [invoice]="invoice"
        (invoiceFormSubmitted)="updateInvoice($event)"
        (onSaveAndDownloadAsPDF)="updateAndDownloadAsPDF($event)"
        (searchTransports)="handleSearchTransports($event)"
        (selectTransport)="handleSelectTransport($event)"
        (removeTransport)="handleRemoveTransport($event)"
        (customerSelected)="handleSelectCustomer($event)"
        (loadNextTransportPage)="loadNextTransportsPage()"
></app-invoice-form>
