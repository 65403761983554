/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../subscription-type-form/subscription-type-form.component.ngfactory";
import * as i2 from "../subscription-type-form/subscription-type-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../services/icon.service";
import * as i5 from "./new-subscription-type.component";
var styles_NewSubscriptionTypeComponent = [];
var RenderType_NewSubscriptionTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewSubscriptionTypeComponent, data: {} });
export { RenderType_NewSubscriptionTypeComponent as RenderType_NewSubscriptionTypeComponent };
export function View_NewSubscriptionTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "card p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-subscription-type-form", [], null, [[null, "subscriptionTypeFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("subscriptionTypeFormSubmitted" === en)) {
        var pd_0 = (_co.createSubscriptionType($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SubscriptionTypeFormComponent_0, i1.RenderType_SubscriptionTypeFormComponent)), i0.ɵdid(3, 638976, null, 0, i2.SubscriptionTypeFormComponent, [i3.FormBuilder, i4.IconService], null, { subscriptionTypeFormSubmitted: "subscriptionTypeFormSubmitted" })], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_NewSubscriptionTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-subscription-type", [], null, null, null, View_NewSubscriptionTypeComponent_0, RenderType_NewSubscriptionTypeComponent)), i0.ɵdid(1, 49152, null, 0, i5.NewSubscriptionTypeComponent, [], null, null)], null, null); }
var NewSubscriptionTypeComponentNgFactory = i0.ɵccf("app-new-subscription-type", i5.NewSubscriptionTypeComponent, View_NewSubscriptionTypeComponent_Host_0, {}, {}, []);
export { NewSubscriptionTypeComponentNgFactory as NewSubscriptionTypeComponentNgFactory };
