import {LinerAlias} from "./alias.model";

export interface Liner {
  '@id': string;
  '@type': string;
  shortName: string;
  code: string;
  name: string;
  reload?: boolean;
  id: string;
  deleted?: boolean;
  aliases: LinerAlias[];
  leadingReloadDesk?: string;
  reloadLocations: LinerReloadLocation[];
}

export interface LinerReloadLocation {
  '@id': string;
  liner: Liner;
  type: string;
  country: string;
  priceType: PriceTypes;
  fixedPrice?: number;
  distanceBasedPrice?: DistanceBasedPriceItem[];
  reloadService?: LinerReloadContainerService;
}

export interface LinerReloadContainerService {
  '@id': string;
  liner: Liner;
  reloadEnabled: boolean;
  reloadService: string;
  reloadEmail: string;
}

export interface DistanceBasedPriceItem {
  from: number;
  until: number;
  price: number;
}

export enum PriceTypes {
  FIXED = 'fixed',
  DISTANCE_BASED = 'distance'
}
