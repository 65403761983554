<div class="card">
  <div class="card-body">
    <h2 class="text-center font-weight-light">{{ '3. Visibility'| translate }}</h2>
    <p class="text-center text-muted">{{ 'Publish your post to the marketplace and share with specific circles.' | translate }}</p>
    <div class="d-flex justify-content-center">
      <div class="btn-group btn-group-toggle"
        ngbRadioGroup
        name="shareRadio"
        [(ngModel)]="shareType">
        <label ngbButtonLabel
          class="btn-outline-primary">
          <i class="far"></i>
          <input ngbButton
            type="radio"
            value="public"> {{ 'Public' | translate }}
        </label>
        <label ngbButtonLabel
          class="btn-outline-primary">
          <i class="far fa-eye"></i>
          <input ngbButton
            type="radio"
            value="allow"> {{ 'Share' | translate }}
        </label>
        <label ngbButtonLabel
          class="btn-outline-primary">
          <input ngbButton
            type="radio"
            value="deny"> {{ 'Hide' | translate }}
          <i class="far fa-eye-slash"></i>
        </label>
      </div>
    </div>
    <div class="form-row mt-3">
      <div class="col ml-md-auto">
        <ng-select [dropdownPosition]="'top'"
          [disabled]="shareType === 'public'"
          [items]="circles$ | async"
          bindLabel="name"
          multiple="true"
          closeOnSelect="false"
          placeholder="{{ 'Select Circles' | translate }}"
          [(ngModel)]="selectedCircles"
          (ngModelChange)="onSubmit()">
        </ng-select>
      </div>
    </div>
  </div>
</div>
