import { Component, OnInit, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Router } from '@angular/router';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import { extract } from 'app/services/i18n.service';
import { LoadSuppliers, CreateSupplier } from 'app/core/store/actions/supplier.actions';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-supplier',
  templateUrl: './new-supplier.component.html',
  styleUrls: ['./new-supplier.component.scss']
})
export class NewSupplierComponent implements OnInit, OnDestroy {
  countries: any[];
  isLoading: boolean;

  constructor(
    private updates$: Actions,
    private toastr: ToastService,
    private store: Store<State>,
    private router: Router
  ) {
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.CREATE_SUPPLIER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Supplier created!')
        });
        this.store.dispatch(new LoadSuppliers());
        this.isLoading = true;
        this.router.navigate(['crm/suppliers']);
      });
  }

  ngOnInit() {
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countriesState => {
        if (!countriesState) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countriesState];
      });
  }

  createNewSupplier(supplierFormValue) {
    this.store.dispatch(new CreateSupplier(supplierFormValue));
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
