/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confidence-score.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./confidence-score.component";
var styles_ConfidenceScoreComponent = [i0.styles];
var RenderType_ConfidenceScoreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfidenceScoreComponent, data: {} });
export { RenderType_ConfidenceScoreComponent as RenderType_ConfidenceScoreComponent };
function View_ConfidenceScoreComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "confidence"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "status-circle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "status-circle--success": 0, "status-circle--warning": 1 }), (_l()(), i1.ɵted(5, null, [" ", "% ", "\n"])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "status-circle"; var currVal_1 = _ck(_v, 4, 0, (_co.confidencePercentage >= 75), (_co.confidencePercentage <= 74)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.confidence * 100); var currVal_3 = i1.ɵunv(_v, 5, 1, i1.ɵnov(_v, 6).transform("match")); _ck(_v, 5, 0, currVal_2, currVal_3); }); }
export function View_ConfidenceScoreComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfidenceScoreComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confidence; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConfidenceScoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confidence-score", [], null, null, null, View_ConfidenceScoreComponent_0, RenderType_ConfidenceScoreComponent)), i1.ɵdid(1, 49152, null, 0, i4.ConfidenceScoreComponent, [], null, null)], null, null); }
var ConfidenceScoreComponentNgFactory = i1.ɵccf("app-confidence-score", i4.ConfidenceScoreComponent, View_ConfidenceScoreComponent_Host_0, { confidence: "confidence" }, {}, []);
export { ConfidenceScoreComponentNgFactory as ConfidenceScoreComponentNgFactory };
