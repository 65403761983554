import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';

import { ApiService } from 'app/core/api/api.service';
import {
  DeleteTenant,
  DisableTenant,
  EnableTenant,
  LoadAllTenants,
} from 'app/core/store/actions/tenants.actions';
import { Tenant } from 'app/core/store/models/tenant.model';
import { State } from 'app/core/store/store.model';
import * as Types from 'app/core/store/types/tenant.types';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-manage-tenants',
  templateUrl: 'manage-tenants.component.html',
})
export class ManageTenantsComponent implements OnDestroy {
  tenants: Tenant[];
  totalElements: Number = 0;
  page: Number = 1;
  loading = false;

  constructor(
    private store: Store<State>,
    private tenantUpdates$: Actions,
    private router: Router,
    private api: ApiService,
    private toastr: ToastService,
  ) {
    this.store
      .select(state => state.tenants)
      .pipe(untilDestroyed(this))
      .subscribe(tenants => {
        if (tenants && tenants['data']) {
          this.loading = false;
          this.tenants = tenants['data'];
          this.totalElements = tenants['totalElements'] || 0;
        } else {
          this.loading = true;
          this.store.dispatch(new LoadAllTenants());
        }
      });

    tenantUpdates$
      .pipe(ofType(Types.tenants.ENABLE_TENANT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Enable tenant successful!'
        });
        this.loading = true;
        this.store.dispatch(new LoadAllTenants({ page: this.page }));
      });
    tenantUpdates$
      .pipe(ofType(Types.tenants.ENABLE_TENANT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Enable tenant failed!'
        });
      });
    tenantUpdates$
      .pipe(ofType(Types.tenants.DISABLE_TENANT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Disable tenant successful!'
        });
        this.loading = true;
        this.store.dispatch(new LoadAllTenants({ page: this.page }));
      });
    tenantUpdates$
      .pipe(ofType(Types.tenants.DISABLE_TENANT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Enable tenant failed!'
        });
      });
    tenantUpdates$
      .pipe(ofType(Types.tenants.DELETE_TENANT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Disable tenant successful!'
        });
        this.loading = true;
        this.store.dispatch(new LoadAllTenants({ page: this.page }));
      });
    tenantUpdates$
      .pipe(ofType(Types.tenants.DELETE_TENANT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Enable tenant failed!'
        });
      });
  }

  disable(tenantId) {
    this.store.dispatch(new DisableTenant(tenantId));
  }

  enable(tenantId) {
    this.store.dispatch(new EnableTenant(tenantId));
  }

  delete(tenantId) {
    this.store.dispatch(new DeleteTenant(tenantId));
  }

  edit(tenantId) {
    this.router.navigate(['superadmin/tenants', tenantId, 'edit']);
  }

  getSubscriptionStatus(subscription) {
    return moment(subscription.endDate).isAfter(moment())
      ? 'Active'
      : 'Inactive';
  }

  downloadAsCSV() {
    this.api.get(
      { path: `/export/tenants-report/csv` },
      {
        'Content-Type': 'application/json',
        Accept: 'text/csv'
      },
      'blob'
    ).subscribe(data => {
      const blob = new Blob([<any>data], {
        type: 'text/csv' // must match the Accept type
      });
      const filename =
        'tenant_data_' + moment().format('DD_MM_YYYY') + '.csv';
      fileSaver.saveAs(blob, filename);
    });
  }

  ngOnDestroy() { }
}
