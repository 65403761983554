var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { ApiService } from '../../api/api.service';
import * as DriverTypes from '../types/driver.types';
import * as DriversActions from '../actions/driver.actions';
import { map, concatMap, catchError } from 'rxjs/operators';
var DriversEffects = /** @class */ (function () {
    function DriversEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadDrivers$ = this.actions$.pipe(ofType(DriverTypes.drivers.LOAD_DRIVERS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/user_contexts?user.superAdmin=" + payload.includeSuperAdmins + "&roles.name=" + payload.roleName + "&page=" + (payload && payload.page ? payload.page : 1)
        }).pipe(map(function (data) { return new DriversActions.LoadDriversSuccess(data); }), catchError(function (error) { return of(new DriversActions.LoadDriversFail(error)); })); }));
        this.createDriver$ = this.actions$.pipe(ofType(DriverTypes.drivers.CREATE_DRIVER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user_contexts_with_role', body: payload
        }).pipe(map(function (data) { return new DriversActions.CreateDriverSuccess(data); }), catchError(function (error) { return of(new DriversActions.CreateDriverFail(error)); })); }));
        this.updateDriver$ = this.actions$.pipe(ofType(DriverTypes.drivers.UPDATE_DRIVER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/user_contexts/' + payload.driverId,
            body: payload.driver
        }).pipe(map(function (data) { return new DriversActions.UpdateDriverSuccess(data); }), catchError(function (error) { return of(new DriversActions.UpdateDriverFail(error)); })); }));
        this.enableDriver$ = this.actions$.pipe(ofType(DriverTypes.drivers.ENABLE_DRIVER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user_contexts/' + payload + '/enable', body: {}
        }).pipe(map(function () { return new DriversActions.EnableDriverSuccess(payload); }), catchError(function (error) { return of(new DriversActions.EnableDriverFail(error)); })); }));
        this.disableDriver$ = this.actions$.pipe(ofType(DriverTypes.drivers.DISABLE_DRIVER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user_contexts/' + payload + '/disable', body: {}
        }).pipe(map(function () { return new DriversActions.DisableDriverSuccess(payload); }), catchError(function (error) { return of(new DriversActions.DisableDriverFail(error)); })); }));
        this.deleteDriver$ = this.actions$.pipe(ofType(DriverTypes.drivers.DELETE_DRIVER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/user_contexts_with_role/DRIVER/' + payload,
            body: {}
        }).pipe(map(function () { return new DriversActions.DeleteDriverSuccess(payload); }), catchError(function (error) { return of(new DriversActions.DeleteDriverFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], DriversEffects.prototype, "loadDrivers$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], DriversEffects.prototype, "createDriver$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], DriversEffects.prototype, "updateDriver$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], DriversEffects.prototype, "enableDriver$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], DriversEffects.prototype, "disableDriver$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], DriversEffects.prototype, "deleteDriver$", void 0);
    return DriversEffects;
}());
export { DriversEffects };
