import { MarketPost } from 'app/core/store/models/market-posts.model';
import { Observable } from 'rxjs';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SearchService } from 'app/services/search.service';

@Component({
  selector: 'app-cmp-market-post-map',
  templateUrl: 'market-post-map.component.html',
  styleUrls: ['market-post-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketPostMapComponent {
  @Input() updateSize;

  posts: Observable<MarketPost[]>;

  constructor(
    private store: Store<State>,
    private searchService: SearchService,
  ) {
    this.posts = store.select(state => state.marketplace.marketposts);
  }

  updateByGeoBoundBox(coordinates) {
    this.searchService.filterGeoBoundBox(
      coordinates.topLeftLat,
      coordinates.topLeftLon,
      coordinates.bottomRightLat,
      coordinates.bottomRightLon
    );
  }
}
