<div class="p-3">
  <app-transport-form
    [loading]="(isLoadingContainerTypes || isLoadingCustomers || isLoadingLiners || isLoadingTariffs || loading)"
    [transport]="transport"
    [customers]="allCustomers"
    [suppliers]="allSuppliers"
    [liners]="allLiners"
    [containerTypes]="allContainerTypes"
    [isLoadingCustomers]="isLoadingCustomers"
    [transportTypes]="transportTypes"
    [customerTariffs]="allTariffs"
    [supplierTariffs]="allSupplierTariffs"
    (transportFormSubmitted)="updateTransport($event)"
    (matchingStateChanged)="toggleMatchingState($event)"
  ></app-transport-form>
</div>
