import { PlanningComponent } from './planning/planning.component';
import { ManageDriverScheduleComponent } from './manage-driver-schedule/manage-driver-schedule.component';
import { DriverVisitsComponent } from './driver-visits/driver-visits.component';
import { DriverListComponent } from './drivers/driver-list/driver-list.component';
import { EditDriverComponent } from './drivers/edit-driver/edit-driver.component';
import { NewDriverComponent } from './drivers/new-driver/new-driver.component';
import { AuthGuard } from '../core/user/user.authguard';
import { Routes } from '@angular/router';
import { TmsComponent } from './tms.component';
import { TransportListComponent } from './transports/transport-list/transport-list.component';
import { NewTransportComponent } from './transports/new-transport/new-transport.component';
import { EditTransportComponent } from './transports/edit-transport/edit-transport.component';
import { NewVisitComponent } from './visits/new-visit/new-visit.component';
import { VisitsComponent } from './visits/visits.component';
import { EditVisitComponent } from './visits/edit-visit/edit-visit.component';
import { Route } from '../core/route.service';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';
import { CopyTransportComponent } from './copy-transport/copy-transport.component';
var defaultTmsGaurds = [
    AuthGuard,
    TenantGuard,
    SubscriptionGuard,
    DriverGuard,
    AllowedSubscriptionGuard
];
var ɵ0 = { subscriptionCode: 'TMS' }, ɵ1 = { subscriptionCode: 'TMS' }, ɵ2 = { subscriptionCode: 'TMS' }, ɵ3 = { subscriptionCode: 'TMS' }, ɵ4 = { subscriptionCode: 'TMS' }, ɵ5 = { subscriptionCode: 'TMS' }, ɵ6 = { subscriptionCode: 'TMS' }, ɵ7 = { subscriptionCode: 'TMS' }, ɵ8 = { subscriptionCode: 'TMS' }, ɵ9 = { subscriptionCode: 'TMS' }, ɵ10 = { subscriptionCode: 'TMS' }, ɵ11 = { subscriptionCode: 'TMS' }, ɵ12 = { subscriptionCode: 'TMS' }, ɵ13 = { subscriptionCode: 'TMS' };
var routes = Route.withShell([
    {
        path: 'driver/tasks',
        component: DriverVisitsComponent,
        canActivate: [AuthGuard, TenantGuard, SubscriptionGuard],
        data: ɵ0
    },
    {
        path: 'tms',
        component: TmsComponent,
        canActivate: defaultTmsGaurds.slice(),
        data: ɵ1
    },
    {
        path: 'tms/drivers',
        children: [
            {
                path: '',
                component: DriverListComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ2
            },
            {
                path: 'new',
                component: NewDriverComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ3
            },
            {
                path: ':id/edit',
                component: EditDriverComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ4
            }
        ]
    },
    {
        path: 'tms/transports',
        children: [
            {
                path: '',
                component: TransportListComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ5
            },
            {
                path: 'new',
                component: NewTransportComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ6
            },
            {
                path: 'copy',
                component: CopyTransportComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ7
            },
            {
                path: ':id/edit',
                component: EditTransportComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ8
            }
        ]
    },
    {
        path: 'tms/prenotifications',
        children: [
            {
                path: '',
                component: VisitsComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ9
            },
            {
                path: 'new',
                component: NewVisitComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ10
            },
            {
                path: ':id/edit',
                component: EditVisitComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ11
            },
            {
                path: 'driver-schedule',
                component: ManageDriverScheduleComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ12
            }
        ]
    },
    {
        path: 'tms/planning',
        children: [
            {
                path: '',
                component: PlanningComponent,
                canActivate: defaultTmsGaurds.slice(),
                data: ɵ13
            }
        ]
    }
]);
var TmsRoutingModule = /** @class */ (function () {
    function TmsRoutingModule() {
    }
    return TmsRoutingModule;
}());
export { TmsRoutingModule };
export var routedComponents = [TmsComponent];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
