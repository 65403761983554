<table class="transport-tasks-table table-borderless">
  <tr *ngFor="let task of transportTasks; let index = index">
    <td class="transport-tasks-table--cell">
      <div
        *ngIf="
          showDropdown && task.privateLocation && task.privateLocation.connector
        "
        ngbDropdown
        [container]="'body'"
        [placement]="'bottom-left'"
        class="d-inline-block"
      >
        <button
          class="btn btn-outline-dark"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          <span
            translate
            class="btn__label"
            [ngClass]="{
              invisible: isLoading && taskIndexes.indexOf(task.id) !== -1
            }"
          >
            <span class="icon">
              <i class="far" [ngClass]="getTaskIcon(task)"></i>
            </span>
            <span>{{ location(task) }}</span>
          </span>
          <i
            class="fa fa-spinner fa-spin btn__loader"
            [ngClass]="{
              visible: isLoading && taskIndexes.indexOf(task.id) !== -1
            }"
          ></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button
            class="dropdown-item"
            [disabled]="
              !transportHasReference(row) || !task.location?.connector
            "
            (click)="checkTaskReference(task, index)"
          >
            <i class="far fa-check-circle"></i>
            <span>{{ 'Check Reference' | translate }}</span>
          </button>
          <button
            class="dropdown-item"
            *ngIf="!task.visit"
            (click)="goToNewPrenotification(task)"
          >
            <i class="far fa-arrow-circle-right"></i>
            <span>{{ 'Create prenotification' | translate }}</span>
          </button>
          <button
            class="dropdown-item"
            *ngIf="task.visit"
            (click)="goToEditPrenotification(task)"
          >
            <i class="far fa-arrow-circle-right"></i>
            <span>{{ 'Edit prenotification' | translate }}</span>
          </button>
        </div>
      </div>
      <div
        *ngIf="
          !task.privateLocation ||
          !task.privateLocation.connector ||
          !showDropdown
        "
      >
        <span class="icon">
          <i class="far" [ngClass]="getTaskIcon(task)"></i>
        </span>
        <span>{{ location(task) }}</span>
      </div>
    </td>
    <td *ngIf="!visitStatus && showStatus" class="transport-tasks-table--cell">
      <span
        *ngIf="!task.assignedTo; else hasVisit"
        class="badge badge-gray text-white"
        >{{ 'Unplanned' | translate }}</span
      >
      <ng-template #hasVisit>
        <div *ngIf="task.status === 'new'">
          <div
            *ngIf="showChangeTaskStatusDropdown(task)"
            ngbDropdown
            [placement]="'bottom-left'"
            [container]="'body'"
            class="d-inline-block task-dropdown"
          >
            <span
              class="badge badge-offer text-white"
              id="{{ 'taskDropdown' + index }}"
              ngbDropdownToggle
            >
              <i
                class="fa fa-spinner fa-spin badge-spinner"
                [ngClass]="{ hidden: !isTaskInTransition(task) }"
              ></i>
              {{ 'Scheduled' | translate }}
            </span>
            <div ngbDropdownMenu class="task-dropdown-menu">
              <button
                class="dropdown-item"
                (click)="transitionTaskStatus(task, 'forward')"
                [disabled]="isTaskInTransition(task)"
              >
                <span class="badge badge-demand text-white">{{
                  'In progress' | translate
                }}</span>
              </button>
            </div>
          </div>
          <div *ngIf="!showChangeTaskStatusDropdown(task)">
            <span class="badge badge-offer text-white">
              {{ 'Scheduled' | translate }}
            </span>
          </div>
        </div>
        <div *ngIf="task.status === 'in_progress'">
          <div
            *ngIf="showChangeTaskStatusDropdown(task)"
            ngbDropdown
            [placement]="'bottom-left'"
            [container]="'body'"
            class="d-inline-block task-dropdown"
          >
            <span
              class="badge badge-demand text-white"
              id="{{ 'taskDropdown' + index }}"
              ngbDropdownToggle
            >
              <i
                class="fa fa-spinner fa-spin badge-spinner"
                [ngClass]="{ hidden: !isTaskInTransition(task) }"
              ></i>
              {{ 'In Progress' | translate }}
            </span>
            <div ngbDropdownMenu class="task-dropdown-menu">
              <button
                class="dropdown-item"
                (click)="transitionTaskStatus(task, 'back')"
                [disabled]="isTaskInTransition(task)"
              >
                <span class="badge badge-offer text-white">{{
                  'Scheduled' | translate
                }}</span>
              </button>
              <button
                class="dropdown-item"
                (click)="transitionTaskStatus(task, 'forward')"
                [disabled]="isTaskInTransition(task)"
              >
                <span class="badge badge-success text-white">{{
                  'Completed' | translate
                }}</span>
              </button>
            </div>
          </div>
          <div *ngIf="!showChangeTaskStatusDropdown(task)">
            <span class="badge badge-demand text-white">
              {{ 'In progress' | translate }}
            </span>
          </div>
        </div>
        <div *ngIf="task.status === 'completed'">
          <div
            *ngIf="showChangeTaskStatusDropdown(task)"
            ngbDropdown
            [placement]="'bottom-left'"
            [container]="'body'"
            class="d-inline-block task-dropdown"
          >
            <span
              class="badge badge-success text-white"
              id="{{ 'taskDropdown' + index }}"
              ngbDropdownToggle
            >
              <i
                class="fa fa-spinner fa-spin badge-spinner"
                [ngClass]="{ hidden: !isTaskInTransition(task) }"
              ></i>
              {{ 'Completed' | translate }}
            </span>
            <div ngbDropdownMenu class="task-dropdown-menu">
              <button
                class="dropdown-item"
                (click)="transitionTaskStatus(task, 'back')"
                [disabled]="isTaskInTransition(task)"
              >
                <span class="badge badge-demand text-white">{{
                  'In progress' | translate
                }}</span>
              </button>
            </div>
          </div>
          <div *ngIf="!showChangeTaskStatusDropdown(task)">
            <span class="badge badge-success text-white">
              {{ 'Completed' | translate }}
            </span>
          </div>
        </div>
      </ng-template>
    </td>
    <td *ngIf="visitStatus && showStatus" class="transport-tasks-table--cell">
      <span
        *ngIf="visitStatus === 'new'"
        class="badge badge-offer text-white"
        >{{ 'New' | translate }}</span
      >
      <span
        *ngIf="visitStatus === 'in_progress'"
        class="badge badge-demand text-white"
        >{{ 'In progress' | translate }}</span
      >
      <span
        *ngIf="visitStatus === 'completed'"
        class="badge badge-success text-white"
        >{{ 'Completed' | translate }}</span
      >
    </td>
    <td *ngIf="showReference" class="transport-tasks-table--cell">
      {{ task.reference }}
      <span
        *ngIf="task.reference && task.location?.connector"
        ngbTooltip="{{ getVerificationResult(task) | translate }}"
        class="icon"
      >
        <i
          class="far"
          [ngClass]="{
            'fa-question-circle text-warning': !task.referenceCheckedAt,
            'fa-check-circle text-primary':
              task.referenceCheckedAt && task.referenceVerified,
            'fa-exclamation-circle text-danger':
              task.referenceCheckedAt && !task.referenceVerified
          }"
        >
        </i>
      </span>
    </td>
    <td
      *ngIf="task.startDate; else noStartDate"
      class="transport-tasks-table--cell__right"
    >
      {{ task.startDate | date: 'dd/MM' }}
      <span *ngIf="task.startDateTimeSpecified">
        {{ task.startDate | date: 'HH:mm' }}
      </span>
      <span
        *ngIf="
          (task.endDate && task.endDate !== task.startDate) ||
          task.endDateTimeSpecified
        "
      >
        -
      </span>
      <span *ngIf="task.endDate && task.endDate !== task.startDate">
        {{ task.endDate | date: 'dd/MM' }}
      </span>
      <span *ngIf="task.endDate && task.endDateTimeSpecified">
        {{ task.endDate | date: 'HH:mm' }}
      </span>
    </td>
    <ng-template #noStartDate>
      <td class="transport-tasks-table--cell__right" *ngIf="task.endDate">
        {{ task.endDate | date: 'dd/MM' }}
        <span *ngIf="task.endDateTimeSpecified">
          {{ task.endDate | date: 'HH:mm' }}
        </span>
      </td>
      <td *ngIf="!task.endDate">&nbsp;</td>
    </ng-template>
  </tr>
</table>
