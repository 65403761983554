import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../../user/user.auth';
import { Tenant } from '../../store/models/tenant.model';

@Injectable()
export class AllowedSubscriptionGuard implements CanActivate {
  constructor(private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService
      .getUserTenantsFromStoreOrAPI()
      .pipe(
        switchMap(tenants => {
          const tenant: Tenant = tenants.find(
            t => t.id === this.authService.getActiveTenant()
          );
          const subscriptionCode = route.data.subscriptionCode;
          if (
            tenant.allowedSubscriptionTypes.some(
              st => st.code === subscriptionCode
            )
          ) {
            return of(true);
          }
          return of(false);
        }),
        catchError(() => of(false))
      );
  }
}
