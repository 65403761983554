/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./preannouncement-form.component.ngfactory";
import * as i2 from "./preannouncement-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "./edit-preannouncement.component";
import * as i5 from "@angular/router";
import * as i6 from "@ngrx/store";
import * as i7 from "@ngrx/effects";
var styles_EditPreannouncementComponent = [];
var RenderType_EditPreannouncementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditPreannouncementComponent, data: {} });
export { RenderType_EditPreannouncementComponent as RenderType_EditPreannouncementComponent };
export function View_EditPreannouncementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-preannouncement-form", [], null, [[null, "formSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formSubmitted" === en)) {
        var pd_0 = (_co.submit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PreannouncementFormComponent_0, i1.RenderType_PreannouncementFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.PreannouncementFormComponent, [i3.FormBuilder], { isLoading: [0, "isLoading"], preannouncement: [1, "preannouncement"] }, { formSubmitted: "formSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = _co.preannouncement; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_EditPreannouncementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-preannouncement", [], null, null, null, View_EditPreannouncementComponent_0, RenderType_EditPreannouncementComponent)), i0.ɵdid(1, 180224, null, 0, i4.EditPreannouncementComponent, [i5.ActivatedRoute, i6.Store, i7.Actions], null, null)], null, null); }
var EditPreannouncementComponentNgFactory = i0.ɵccf("app-edit-preannouncement", i4.EditPreannouncementComponent, View_EditPreannouncementComponent_Host_0, {}, {}, []);
export { EditPreannouncementComponentNgFactory as EditPreannouncementComponentNgFactory };
