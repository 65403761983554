import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Alert } from 'app/core/store/models/alert.model';
import { State } from 'app/core/store/store.model';
import { CreateReloadRequest, LoadAllReloadRequestsSuccess } from 'app/core/store/actions/reload.actions';
import * as Types from 'app/core/store/types/reload.types';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-new-reload-request',
  templateUrl: 'new-reload-request.component.html'
})
export class NewReloadRequestComponent implements OnDestroy {
  title = extract('Request reload');
  reset: boolean;
  readonly: boolean = true;
  loading: boolean;
  alert: Alert;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {
    updates$
      .pipe(ofType(Types.reload.CREATE_RELOAD_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((action: LoadAllReloadRequestsSuccess) => {
        this.loading = false;
        if (action.payload && action.payload.requestStatus === 'declined') {
          this.toastr.showWarning({
            title: 'Reload request failed!',
            message: this.translate.instant('reload.api.message.' + action.payload.requestStatusDetail)
          });
        } else {
          this.toastr.showSuccess({
            message: 'Reload requested!'
          });
        }
        this.reset = true;
        this.router.navigateByUrl(`/reload/reload-requests`);
      });
    updates$
      .pipe(ofType(Types.reload.CREATE_RELOAD_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Reload request failed!'
        });
      });
  }

  reloadFormSubmitted(reloadFormValue: any) {
    this.loading = true;
    this.store.dispatch(
      new CreateReloadRequest({
        body: reloadFormValue
      })
    );
  }

  ngOnDestroy() { }
}
