export const creditNote = {
  LOAD_CREDIT_NOTES: '[Credit notes] Load',
  LOAD_CREDIT_NOTES_SUCCEEDED: '[Credit notes] Load Success',
  LOAD_CREDIT_NOTES_FAILED: '[Credit notes] Load Fail',
  LOAD_FILTERED_CREDIT_NOTES: '[Credit notes] Load filtered',
  LOAD_FILTERED_CREDIT_NOTES_SUCCEEDED: '[Credit notes] Load filtered Success',
  LOAD_FILTERED_CREDIT_NOTES_FAILED: '[Credit notes] Load filtered Fail',
  CREATE_CREDIT_NOTE: '[Credit note] Create',
  CREATE_CREDIT_NOTE_SUCCEEDED: '[Credit note] Create Success',
  CREATE_CREDIT_NOTE_FAILED: '[Credit note] Create Fail',
  DELETE_CREDIT_NOTE: '[Credit note] Delete',
  DELETE_CREDIT_NOTE_SUCCEEDED: '[Credit note] Delete Success',
  DELETE_CREDIT_NOTE_FAILED: '[Credit note] Delete Fail',
  UPDATE_CREDIT_NOTE: '[Credit note] Update',
  UPDATE_CREDIT_NOTE_SUCCEEDED: '[Credit note] Update Success',
  UPDATE_CREDIT_NOTE_FAILED: '[Credit note] Update Fail',
  CLEAR: '[Credit notes] Clear'
};
