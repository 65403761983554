var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { catchError, concatMap, map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as types from '../types/attachment.types';
import * as AttachmentActions from '../actions/attachment.actions';
import { ApiService } from '../../api/api.service';
var AttachmentEffects = /** @class */ (function () {
    function AttachmentEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.updateAttachment$ = this.actions$.pipe(ofType(types.attachment.UPDATE_ATTACHMENT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/attachments/" + payload.attachmentId,
            body: payload.body
        }).pipe(map(function (data) { return new AttachmentActions.UpdateAttachmentSuccess(data); }), catchError(function (error) { return of(new AttachmentActions.UpdateAttachmentFail(error)); })); }));
        this.deleteAttachment$ = this.actions$.pipe(ofType(types.attachment.DELETE_ATTACHMENT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/attachments/" + payload.attachmentId
        }).pipe(map(function () { return new AttachmentActions.DeleteAttachmentSuccess(payload); }), catchError(function (error) { return of(new AttachmentActions.DeleteAttachmentFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AttachmentEffects.prototype, "updateAttachment$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AttachmentEffects.prototype, "deleteAttachment$", void 0);
    return AttachmentEffects;
}());
export { AttachmentEffects };
