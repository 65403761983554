import { LoadContacts } from '../../store/actions/contacts.actions';
import { ToastService } from 'app/services/toast.service';
import { Alert } from '../../store/models/alert.model';
import { Actions, ofType } from '@ngrx/effects';
import { State } from '../../store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import * as Types from '../../store/types/wallet.types';
import {
  AddCreditsToWallet,
  SubtractCreditsFromWallet
} from '../../store/actions/wallet.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-wallets',
  templateUrl: './manage-wallets.component.html',
  styleUrls: ['./manage-wallets.component.scss']
})
export class ManageWalletsComponent implements OnDestroy {
  tenants: any[];
  tenantId: string;
  amount = 10;
  loadingAdd = false;
  loadingSubtract = false;
  alert: Alert;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadContacts());
    this.store
      .select(state => state.contacts)
      .pipe(untilDestroyed(this))
      .subscribe(tenants => {
        if (tenants) {
          this.tenants = tenants;
        }
      });

    this.updates$
      .pipe(ofType(Types.wallet.ADD_CREDITS_TO_WALLET_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadingAdd = false;
        this.toastr.showSuccess({
          message: 'Credits added to wallet!'
        });
      });

    this.updates$
      .pipe(ofType(Types.wallet.ADD_CREDITS_TO_WALLET_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadingAdd = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to add credits to wallet!'
        });
      });

    this.updates$
      .pipe(ofType(Types.wallet.SUBTRACT_CREDITS_FROM_WALLET_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadingSubtract = false;
        this.toastr.showSuccess({
          message: 'Credits subtracted from wallet!'
        });
      });

    this.updates$
      .pipe(ofType(Types.wallet.SUBTRACT_CREDITS_FROM_WALLET_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadingSubtract = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to subttract credits from wallet!'
        });
      });
  }

  amountChange(event) {
    if (event.which <= 57 || event.key.match(/[0-9]/)) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  addCredits() {
    this.loadingAdd = true;
    this.store.dispatch(
      new AddCreditsToWallet({
        amount: this.amount,
        id: this.tenantId
      })
    );
  }

  subtractCredits() {
    this.loadingSubtract = true;
    this.store.dispatch(
      new SubtractCreditsFromWallet({
        amount: this.amount,
        id: this.tenantId
      })
    );
  }

  ngOnDestroy() { }
}
