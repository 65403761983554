import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Circle } from 'app/core/store/models/circles.model';

@Component({
  selector: 'app-cmp-circle',
  templateUrl: 'circle.component.html',
  styleUrls: ['circle.component.scss']
})
export class CircleComponent {
  navbarTitle = 'Edit Circle';
  @Input()
  circle: Circle;
  @Output()
  circleChanged = new EventEmitter<boolean>();
  @Output()
  deleteCircleEmitter = new EventEmitter<boolean>();
  membersMapping: { [k: string]: string } = {
    '=0': 'No Members.',
    '=1': '1 Member',
    other: '# Members'
  };
  showBody = false;

  toggleBody(): void {
    this.showBody = !this.showBody;
  }

  removeMember(member) {
    this.circle.members.splice(this.circle.members.indexOf(member), 1);
    this.circleChanged.emit(true);
  }

  deleteCircle() {
    if (this.isMatchingCircle) {
      return;
    }
    this.deleteCircleEmitter.emit(true);
  }

  get isMatchingCircle() {
    if (!this.circle) {
      return;
    }
    return this.circle.matching;
  }
  changeName() { }
}
