var DebitTransactionComponent = /** @class */ (function () {
    function DebitTransactionComponent() {
    }
    Object.defineProperty(DebitTransactionComponent.prototype, "transactionAmount", {
        /**
         * Returns the negative transaction value
         */
        get: function () {
            return +this.debitTransaction.credits * -1;
        },
        enumerable: true,
        configurable: true
    });
    return DebitTransactionComponent;
}());
export { DebitTransactionComponent };
