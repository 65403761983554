import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/invoice.types';
import * as InvoiceActions from '../actions/invoice.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class InvoiceEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadInvoice$: Observable<Action> = this.actions$.pipe(
    ofType(types.invoice.LOAD_INVOICE),
    map((action: InvoiceActions.LoadInvoice) => action.payload),
    concatMap(id => {
      return this.api.get({ path: `/crm/invoices/${id}` }).pipe(
        map(data => new InvoiceActions.LoadInvoiceSuccess(data)),
        catchError(error => of(new InvoiceActions.LoadInvoiceFail(error)))
      );
    }),
  );

  @Effect()
  loadInvoices$: Observable<Action> = this.actions$.pipe(
    ofType(types.invoice.LOAD_INVOICES),
    map((action: InvoiceActions.LoadInvoices) => action.payload),
    concatMap(payload => {
      const page = payload && payload.page ? payload.page : 1;
      return this.api.get({ path: `/crm/invoices?page=${page}` }).pipe(
        map(data => new InvoiceActions.LoadInvoicesSuccess(data)),
        catchError(error => of(new InvoiceActions.LoadInvoicesFail(error)))
      );
    }),
  );

  @Effect()
  loadFilteredInvoices$: Observable<Action> = this.actions$.pipe(
    ofType(types.invoice.LOAD_FILTERED_INVOICES),
    map((action: InvoiceActions.LoadFilteredInvoices) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      const page = payload && payload.page ? payload.page : 1;
      return this.api.get({
        path: `/crm/invoices?business.label=${query}&number=${query}&page=${page}`
      }).pipe(
        map(data => new InvoiceActions.LoadFilteredInvoicesSuccess(data)),
        catchError(error => of(new InvoiceActions.LoadFilteredInvoicesFail(error)))
      );
    }),
  );

  @Effect()
  createInvoice$: Observable<Action> = this.actions$.pipe(
    ofType(types.invoice.CREATE_INVOICE),
    map((action: InvoiceActions.CreateInvoice) => action.payload),
    concatMap(payload => this.api.post({
      path: `/crm/invoices`, body: payload
    }).pipe(
      map(data => new InvoiceActions.CreateInvoiceSuccess(data)),
      catchError(error => of(new InvoiceActions.CreateInvoiceFail(error)))
    )),
  );

  @Effect()
  updateInvoice$: Observable<Action> = this.actions$.pipe(
    ofType(types.invoice.UPDATE_INVOICE),
    map((action: InvoiceActions.UpdateInvoice) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/invoices/${payload.invoiceId}`,
      body: payload.body
    }).pipe(
      map(data => new InvoiceActions.UpdateInvoiceSuccess(data)),
      catchError(error => of(new InvoiceActions.UpdateInvoiceFail(error)))
    )),
  );

  @Effect()
  deleteInvoice$: Observable<Action> = this.actions$.pipe(
    ofType(types.invoice.DELETE_INVOICE),
    map((action: InvoiceActions.DeleteInvoice) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/invoices/${payload.invoiceId}`
    }).pipe(
      map(() => new InvoiceActions.DeleteInvoiceSuccess(payload.invoiceId)),
      catchError(error => of(new InvoiceActions.DeleteInvoiceFail(error)))
    )),
  );
}
