import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  @Input()
  btnClass = 'btn-primary';
  @Input()
  type = 'button';
  @Input()
  isLoading: boolean;
  @Input()
  isDisabled: boolean;

  @Output()
  buttonClicked = new EventEmitter<boolean>();

  onClick() {
    this.buttonClicked.emit(true);
  }
}
