import * as types from '../types/market-post.types';
import * as MarketpostsActions from '../actions/market-posts.actions';
import { MarketPost } from '../models/market-posts.model';

const INITIAL_STATE = [];

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.
export function MarketpostReducer(
  state = INITIAL_STATE,
  action: MarketpostsActions.All
): MarketPost[] {
  switch (action.type) {
    case types.marketposts.LOAD_MARKETPOSTS_SUCCEEDED:
      if (
        state &&
        action.payload['hits'] &&
        action.payload['hits']['hits'] &&
        state.length === action.payload['hits']['hits'].length &&
        state.every(
          (v, i) =>
            v['_id'] === action.payload['hits']['hits'][i]['_id'] &&
            v['_source']['last_updated'] ===
              action.payload['hits']['hits'][i]['_source']['last_updated']
        )
      ) {
        return state;
      } else {
        return [...action.payload['hits']['hits']];
      }
    case types.marketposts.CLEAR:
      return null;
  }
  return state;
}
