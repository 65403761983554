import {
  StartLoadUpdates,
  StopLoadUpdates
} from '../core/store/actions/update.actions';
import { LoadTransportTypes } from '../core/store/actions/transport-types.actions';
import { State } from '../core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { extract } from '../services/i18n.service';

@Component({
  selector: 'app-marketplace-root',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit, OnDestroy {
  title = extract('Marketplace');
  cardView = false;
  showMessageBoard = true;
  showFilter = true;
  showMap = false;
  filtered = false;
  loading = true;

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {
    const showMessages = localStorage.getItem('showMessageBoard');
    this.showMessageBoard = showMessages && showMessages === 'true';

    const displayCardView = localStorage.getItem('displayCardView');
    this.cardView = displayCardView && displayCardView === 'true';

    const showFilter = localStorage.getItem('showFilter');
    this.showFilter = showFilter && showFilter === 'true';

    const showMap = localStorage.getItem('showMap');
    this.showMap = showMap && showMap === 'true';
  }

  ngOnInit() {
    this.store.dispatch(new LoadTransportTypes());
    if (window.screen.width < 786) {
      this.viewToggled(true);
    }
    this.toggleUpdates();
  }

  viewToggled(cardViewEnabled) {
    localStorage.setItem('displayCardView', String(cardViewEnabled));
    this.cardView = cardViewEnabled;
  }

  messageBoardToggled(showMessageBoard) {
    localStorage.setItem('showMessageBoard', String(showMessageBoard));
    this.showMessageBoard = showMessageBoard;
    this.toggleUpdates();
  }

  toggleUpdates(): void {
    if (this.showMessageBoard) {
      this.store.dispatch(new StartLoadUpdates());
    } else {
      this.store.dispatch(new StopLoadUpdates());
    }
  }

  filterToggled(showFilter) {
    localStorage.setItem('showFilter', String(showFilter));
    this.showFilter = showFilter;
  }

  mapToggled(showMap) {
    localStorage.setItem('showMap', String(showMap));
    this.showMap = showMap;
  }

  filteredEvent(filtered) {
    this.filtered = filtered;
    this.cdr.detectChanges();
  }

  onScrollDown() { }

  ngOnDestroy() {
    this.store.dispatch(new StopLoadUpdates());
  }
}
