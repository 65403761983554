export const product = {
  LOAD_PRODUCTS: '[Products] Load',
  LOAD_PRODUCTS_SUCCEEDED: '[Products] Load Success',
  LOAD_PRODUCTS_FAILED: '[Products] Load Fail',
  LOAD_FILTERED_PRODUCTS: '[Products] Load filtered',
  LOAD_FILTERED_PRODUCTS_SUCCEEDED: '[Products] Load filtered Success',
  LOAD_FILTERED_PRODUCTS_FAILED: '[Products] Load filtered Fail',
  CREATE_PRODUCT: '[Product] Create',
  CREATE_PRODUCT_SUCCEEDED: '[Product] Create Success',
  CREATE_PRODUCT_FAILED: '[Product] Create Fail',
  DELETE_PRODUCT: '[Product] Delete',
  DELETE_PRODUCT_SUCCEEDED: '[Product] Delete Success',
  DELETE_PRODUCT_FAILED: '[Product] Delete Fail',
  UPDATE_PRODUCT: '[Product] Update',
  UPDATE_PRODUCT_SUCCEEDED: '[Product] Update Success',
  UPDATE_PRODUCT_FAILED: '[Product] Update Fail',
  CLEAR: '[Products] Clear'
};
