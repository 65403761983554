import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/market-post.types';
import * as MarketPostOfferRepliesActions from '../actions/market-post-offer-replies.actions';

@Injectable()
export class MarketPostOfferRepliesEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadMarketPostOfferReplies$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketPostOfferReplies.LOAD_MARKET_POST_OFFER_REPLIES),
    concatMap(() => this.api.get({
      path: '/market_post_offer_replies'
    }).pipe(
      map(data => new MarketPostOfferRepliesActions.LoadMarketPostOfferRepliesSuccess(data)),
      catchError(error => of(new MarketPostOfferRepliesActions.LoadMarketPostOfferRepliesFail(error)))
    )),
  );
}
