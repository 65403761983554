<div class="p-3">
  <h3>{{ 'Add new tariff template' | translate }}</h3>
  <app-tariff-form
    [searchTerm]="searchTerm"
    [isLoading]="isLoading"
    [showTariffTypeButtons]="showTariffTypeButtons"
    [tariffType]="tariffType"
    (tariffFormSubmitted)="createNewTariff($event)"
  ></app-tariff-form>
</div>
