var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
var UpdateToastComponent = /** @class */ (function (_super) {
    __extends(UpdateToastComponent, _super);
    // constructor is only necessary when not using AoT
    function UpdateToastComponent(toastrService, toastPackage) {
        var _this = _super.call(this, toastrService, toastPackage) || this;
        _this.toastrService = toastrService;
        _this.toastPackage = toastPackage;
        return _this;
    }
    UpdateToastComponent.prototype.action = function (event) {
        event.stopPropagation();
        document.location.reload();
        this.toastPackage.triggerAction();
        return false;
    };
    return UpdateToastComponent;
}(Toast));
export { UpdateToastComponent };
