<div class="card mb-2">
  <div class="card-header">
    <span class="small">{{ message.created | date:'dd/MM HH:mm'}}</span>&nbsp;
    <span class="message-tenant">{{ message.tenant.name }}</span>
  </div>
  <div class="card-body"
    data-cy="messageContent">
    <div class="d-flex justify-content-between">
      <span>{{ message.message }}</span>
      <span *ngIf="!replyVisible"
        (click)="toggleReply()"
        data-cy="replyButton"
        ngbTooltip="{{ 'Reply' | translate }}">
        <i class="far fa-reply text-success"></i>
      </span>
      <span *ngIf="replyVisible"
        (click)="toggleReply()"
        ngbTooltip="{{ 'Cancel' | translate }}">
        <i class="far fa-times text-danger"></i>
      </span>
    </div>
    <div *ngIf="replyVisible"
      class="input-group mt-3"
      id="message-input">
      <app-cmp-message-input [reply]="replyVisible"
        (messageSubmitted)="onSubmit(message.id, $event)"></app-cmp-message-input>
    </div>
  </div>
  <!-- message replies -->
  <div *ngFor="let reply of message.replies"
    class="message-reply ml-2 mr-2 mb-1 p-2">
    <span class="small">{{ reply.created | date:'dd/MM HH:mm'}}</span>&nbsp;
    <span class="message-tenant">{{ reply.tenant.name }}</span>
    <br/>
    <span>{{ reply.message }}</span>
  </div>
