<ngx-datatable
  #postsTable
  class="bootstrap row ml-3 mr-0"
  [rows]="marketposts"
  [columnMode]="'force'"
  [headerHeight]="40"
  [footerHeight]="40"
  [rowHeight]="'auto'"
  [scrollbarH]="true"
>
  <!-- Row Detail Template -->
  <ngx-datatable-row-detail [rowHeight]="60" #myDetailRow>
    <ng-template
      let-row="row"
      let-expanded="expanded"
      ngx-datatable-row-detail-template
    >
      <div class="pr-3 pt-3" style="float:right">
        <app-button
          *ngIf="hasActiveMatchingSubscription && row.matching"
          [btnClass]="'btn btn-sm'"
          (click)="handleDisableMatching(row)"
          [type]="'button'"
          class="mr-1"
        >
          <i class="far fa-fw fa-bolt"></i>
          <span>{{ 'Disable matching' | translate }}</span>
        </app-button>
        <app-button
          *ngIf="hasActiveMatchingSubscription && !row.matching"
          [btnClass]="'btn-success btn-sm'"
          (click)="handleEnableMatching(row)"
          [type]="'button'"
          class="mr-1"
        >
          <i class="far fa-fw fa-bolt"></i>
          <span>{{ 'Enable matching' | translate }}</span>
        </app-button>
        <button
          class="btn btn-sm btn-warning text-white mr-1"
          (click)="edit(row)"
        >
          <span class="icon"> <i class="far fa-pencil"></i> </span>
          <span>{{ 'Edit' | translate }}</span>
        </button>
        <button class="btn btn-sm btn-danger" (click)="unpublish(row)">
          <span class="icon" *ngIf="loading">
            <i class="far fa-spinner fa-spin"></i>
          </span>
          <span class="icon" *ngIf="!loading">
            <i class="far fa-trash"></i>
          </span>
          <span>{{ 'Delete' | translate }}</span>
        </button>
      </div>
    </ng-template>
  </ngx-datatable-row-detail>
  <ngx-datatable-column
    name="{{ 'Type' | translate }}"
    prop="marketPostType"
    [width]="35"
    [maxWidth]="35"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <span
        class="d-flex justify-content-center"
        ngbTooltip="{{ value | titlecase | translate }}"
        placement="right"
        [ngClass]="{
          'text-offer': value === 'offer',
          'text-demand': value === 'demand'
        }"
      >
        <i
          class="far"
          [ngClass]="{
            'fa-hand-holding': value === 'offer',
            'fa-question-circle': value === 'demand'
          }"
        ></i>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Transport' | translate }}"
    prop="transportType.label"
    [width]="95"
  >
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
      <span class="icon">
        <i class="far" [ngClass]="getTransportTypeIcon(row)"></i>
      </span>
      <span>{{ value | titlecase | translate }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Container' | translate }}"
    prop="container.containerType.label"
    [width]="95"
    [maxWidth]="95"
  >
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Liner' | translate }}"
    prop="container.liner"
    [width]="50"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <span>{{ value.shortName ? value.shortName : value.name }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Locations' | translate }}"
    prop=""
    [width]="405"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <span *ngIf="value.marketPostType === 'offer'">
        <div *ngFor="let task of value.tasks">
          <span
            *ngIf="task.location && task.location.name"
            class="d-flex justify-content-between"
          >
            <span>
              <span
                class="icon"
                ngbTooltip="{{ task.taskType.label | titlecase | translate }}"
                placement="right"
              >
                <i class="far" [ngClass]="getTaskIcon(task)"></i>
              </span>
              <span *ngIf="task.location">
                {{
                  task.location.shortName
                    ? task.location.shortName
                    : task.location.name
                    ? task.location.name
                    : task.location
                }}
              </span>
            </span>
            <span *ngIf="task.startDate; else noStartDate">
              {{ task.startDate | date: 'dd/MM' }}
              <span *ngIf="task.startDateTimeSpecified">
                {{ task.startDate | date: 'HH:mm' }}
              </span>
              <span
                *ngIf="
                  (task.endDate && task.endDate !== task.startDate) ||
                  task.endDateTimeSpecified
                "
              >
                -
              </span>
              <span *ngIf="task.endDate && task.endDate !== task.startDate">
                {{ task.endDate | date: 'dd/MM' }}
              </span>
              <span *ngIf="task.endDate && task.endDateTimeSpecified">
                {{ task.endDate | date: 'HH:mm' }}
              </span>
            </span>
            <ng-template #noStartDate>
              {{ task.endDate | date: 'dd/MM' }}
              <span *ngIf="task.endDateTimeSpecified">
                {{ task.endDate | date: 'HH:mm' }}
              </span>
            </ng-template>
          </span>
        </div>
      </span>
      <span *ngIf="value.marketPostType === 'demand'">
        <div
          *ngIf="value.originLocation?.name"
          class="d-flex justify-content-between"
        >
          <span>
            <span
              class="icon"
              ngbTooltip="{{ 'Origin' | translate }}"
              placement="right"
            >
              <i class="far fa-map-marker-alt"></i>
            </span>
            <span>{{
              value.originLocation.shortName
                ? value.originLocation.shortName
                : value.originLocation.name
                ? value.originLocation.name
                : value.originLocation
            }}</span>
          </span>
          <span>
            <span *ngIf="value.originTimeIntervalStart">
              {{ value.originTimeIntervalStart | date: 'dd/MM' }}
              <span
                *ngIf="
                  value.originTimeIntervalStart &&
                  value.originTimeIntervalStartTimeSpecified
                "
              >
                {{ value.originTimeIntervalStart | date: 'HH:mm' }}
              </span>
            </span>
            <span
              *ngIf="
                (value.originTimeIntervalEnd &&
                  value.originTimeIntervalEnd !==
                    value.originTimeIntervalStart) ||
                value.originTimeIntervalStartTimeSpecified
              "
            >
              -
            </span>
            <span
              *ngIf="
                value.originTimeIntervalEnd &&
                value.originTimeIntervalEnd !== value.originTimeIntervalStart
              "
            >
              {{ value.originTimeIntervalEnd | date: 'dd/MM' }}
            </span>
            <span
              *ngIf="
                value.originTimeIntervalEnd &&
                value.originTimeIntervalEndTimeSpecified
              "
            >
              {{ value.originTimeIntervalEnd | date: 'HH:mm' }}
            </span>
          </span>
        </div>
        <div
          *ngIf="value.destinationLocation?.name"
          class="d-flex justify-content-between"
        >
          <span>
            <span
              class="icon"
              ngbTooltip="{{ 'Destination' | translate }}"
              placement="right"
            >
              <i class="far fa-map-marker"></i>
            </span>
            <span>{{
              value.destinationLocation.shortName
                ? value.destinationLocation.shortName
                : value.destinationLocation.name
                ? value.destinationLocation.name
                : value.destiationLocation
            }}</span>
          </span>
          <span>
            <span *ngIf="value.destinationTimeIntervalStart">
              {{ value.destinationTimeIntervalStart | date: 'dd/MM' }}
              <span
                *ngIf="
                  value.destinationTimeIntervalStart &&
                  value.destinationTimeIntervalStartTimeSpecified
                "
              >
                {{ value.destinationTimeIntervalStart | date: 'HH:mm' }}
              </span>
            </span>
            <span
              *ngIf="
                (value.destinationTimeIntervalEnd &&
                  value.destinationTimeIntervalEnd !==
                    value.destinationTimeIntervalStart) ||
                value.destinationTimeIntervalStartTimeSpecified
              "
            >
              -
            </span>
            <span
              *ngIf="
                value.destinationTimeIntervalEnd &&
                value.destinationTimeIntervalEnd !==
                  value.destinationTimeIntervalStart
              "
            >
              {{ value.destinationTimeIntervalEnd | date: 'dd/MM' }}
            </span>
            <span
              *ngIf="
                value.destinationTimeIntervalEnd &&
                value.destinationTimeIntervalEndTimeSpecified
              "
            >
              {{ value.destinationTimeIntervalEnd | date: 'HH:mm' }}
            </span>
          </span>
        </div>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Quantity' | translate }}"
    prop="quantity"
    [width]="40"
    [maxWidth]="40"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <span
        *ngIf="value > 1"
        class="badge badge-dark"
        ngbTooltip="{{ 'Quantity' | translate }}"
        >{{ value }}</span
      >
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Weight' | translate }}"
    prop="container.weight"
    [width]="40"
    [maxWidth]="40"
  >
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Comment' | translate }}"
    prop="comment"
    [width]="300"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <p class="comment-field">{{ value }}</p>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Company' | translate }}"
    prop="tenant.name"
    [width]="120"
  >
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Genset' | translate }}"
    prop="genset"
    [width]="40"
    [maxWidth]="40"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <i class="far fa-snowflake text-info" *ngIf="value"></i>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'ADR' | translate }}"
    prop="container.adr"
    [width]="40"
    [maxWidth]="40"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <i class="far fa-exclamation-triangle text-danger" *ngIf="value"></i>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Action' | translate }}"
    prop=""
    [width]="160"
    [resizeable]="false"
    [maxWidth]="160"
  >
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
      <div *ngIf="tenantId && value.tenant && value.tenant.id === tenantId">
        <button class="btn btn-info btn-sm" (click)="toggleExpandRow(row)">
          {{ 'Select action' | translate }}
        </button>
      </div>
      <div *ngIf="tenantId && value.tenant && value.tenant.id !== tenantId">
        <button
          class="btn btn-sm btn-primary propose-button text-white"
          (click)="reply(value)"
        >
          <i class="far fa-reply"></i> <span>{{ 'Reply' | translate }}</span>
        </button>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
