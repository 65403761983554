/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../transport-task/transport-task.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../datepicker-config";
import * as i4 from "../transport-task/transport-task.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../services/location.service";
import * as i7 from "./demand-locations-form.component";
var styles_DemandLocationsFormComponent = [];
var RenderType_DemandLocationsFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DemandLocationsFormComponent, data: {} });
export { RenderType_DemandLocationsFormComponent as RenderType_DemandLocationsFormComponent };
export function View_DemandLocationsFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "app-transport-task", [], null, [[null, "transportTaskChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("transportTaskChanged" === en)) {
        var pd_0 = (_co.handleOriginChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TransportTaskComponent_0, i1.RenderType_TransportTaskComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i3.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(5, 638976, null, 0, i4.TransportTaskComponent, [i5.FormBuilder, i6.LocationService], { taskType: [0, "taskType"], taskIcon: [1, "taskIcon"], transportTask: [2, "transportTask"] }, { transportTaskChanged: "transportTaskChanged" }), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "app-transport-task", [], null, [[null, "transportTaskChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("transportTaskChanged" === en)) {
        var pd_0 = (_co.handleDestinationChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TransportTaskComponent_0, i1.RenderType_TransportTaskComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i3.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(10, 638976, null, 0, i4.TransportTaskComponent, [i5.FormBuilder, i6.LocationService], { taskType: [0, "taskType"], taskIcon: [1, "taskIcon"], transportTask: [2, "transportTask"] }, { transportTaskChanged: "transportTaskChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Origin"; var currVal_1 = "fa-map-marker-alt"; var currVal_2 = _co.getOriginLocation(_co.transport); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Destination"; var currVal_4 = "fa-map-marker"; var currVal_5 = _co.getDestinationLocation(_co.transport); _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_DemandLocationsFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-demand-locations-form", [], null, null, null, View_DemandLocationsFormComponent_0, RenderType_DemandLocationsFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i3.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 638976, null, 0, i7.DemandLocationsFormComponent, [i5.FormBuilder, i6.LocationService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DemandLocationsFormComponentNgFactory = i0.ɵccf("app-demand-locations-form", i7.DemandLocationsFormComponent, View_DemandLocationsFormComponent_Host_0, { transport: "transport", submitAttempt: "submitAttempt" }, { demandLocationsFormValueChanged: "demandLocationsFormValueChanged" }, []);
export { DemandLocationsFormComponentNgFactory as DemandLocationsFormComponentNgFactory };
