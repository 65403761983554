<div class="card">
  <div class="card-header">
    <strong>
      {{ title | translate }}
    </strong>
  </div>
  <div class="card-body">
    <div class="message mb-3">
      {{ message | translate }}
    </div>
    <div class="actions d-flex justify-content-end">
      <button class="btn btn-outline-dark" (click)="handleCancel()">
        {{ cancelButtonText | translate }}
      </button>
      <button
        class="btn ml-3"
        [ngClass]="confirmButtonClass"
        (click)="handleConfirm()"
      >
        {{ confirmButtonText | translate }}
      </button>
    </div>
  </div>
</div>
