import { Actions, ofType } from '@ngrx/effects';
import { CreateUserLocation } from 'app/core/store/actions/location.actions';
import { OnDestroy, Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import * as UserLocationTypes from 'app/core/store/types/location.types';
import { ToastService } from 'app/services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-user-location-modal',
  templateUrl: './new-user-location-modal.component.html',
})
export class NewUserLocationModalComponent implements OnInit, OnDestroy {
  @Input()
  userLocation;
  isLoading: boolean;

  constructor(
    private toastr: ToastService,
    private store: Store<State>,
    private updates$: Actions,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.updates$
      .pipe(ofType(UserLocationTypes.location.CREATE_USER_LOCATION_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Successfully created location')
        });
        this.activeModal.close(response);
      });
    this.updates$
      .pipe(ofType(UserLocationTypes.location.CREATE_USER_LOCATION_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to create location')
        });
      });
  }

  ngOnDestroy() { }

  createNewUserLocation(userLocationFormValue) {
    this.isLoading = true;
    this.store.dispatch(new CreateUserLocation(userLocationFormValue));
  }
}
