<div class="card">
  <div class="card-header">
    <strong>{{ 'Confirmation required' | translate }}</strong>
  </div>
  <div class="card-body">
    <div class="message mb-3">
      <p>
        {{ message$ | async }}
        {{ 'PSA will charge €8.34 per container move for the TAR codes of the account.' | translate }}
      </p>
      <p>
        {{ 'It is your responsibility to correctly link your company\'s ePortal Account. Hakka is not responsible when PSA wrongly deducts credits from your Hakka Wallet due to incorrect linked accounts.' | translate }}
      </p>
      <form [formGroup]="linkAccountForm">
        <div class="form-group">
          <label class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              formControlName="accept"
            />
            <span class="custom-control-label" data-cy="i-agree-with-these-conditions">
              {{'I agree with these conditions' | translate}}
            </span>
          </label>
        </div>
      </form>
    </div>
    <div class="actions d-flex justify-content-end">
      <button class="btn btn-outline-dark" (click)="close()">
        {{ 'Cancel' | translate }}
      </button>
      <button class="btn ml-3 btn-danger" (click)="submit()" [disabled]="(linkAccountForm?.controls)['accept'].invalid || (linkAccountForm?.controls)['accept'].value === false">
        <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
        {{ 'Link account' | translate }}
      </button>
    </div>
  </div>
</div>
