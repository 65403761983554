import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent {
  query: string;
  searchTextChanged = new Subject();

  @Input()
  placeholder = extract('Search name');

  @Output()
  search = new EventEmitter<string>();

  constructor() {
    this.searchTextChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((query: string) => this.search.emit(query))
      )
      .subscribe(() => { });
  }

  handleQueryChange(queryEvent) {
    this.searchTextChanged.next(queryEvent.target.value);
  }
}
