import { ToastService } from 'app/services/toast.service';
import { Alert } from 'app/core/store/models/alert.model';
import { Location } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { MarketpostParserService } from 'app/services/marketpost-parser.service';
import { ApiService } from 'app/core/api/api.service';
import { LoadMarketPostOffers, UpdateMarketPostOffer } from 'app/core/store/actions/market-post-offer.actions';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { ActivatedRoute } from '@angular/router';
import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Types from 'app/core/store/types/marketplace.types';
// models
import { ContainerType } from 'app/core/store/models/container-types.model';
import { Liner } from 'app/core/store/models/liner.model';
import { State } from 'app/core/store/store.model';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import {
  LoadMarketPostDemands,
  UpdateMarketPostDemand
} from 'app/core/store/actions/market-post-demand.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-edit-market-post',
  templateUrl: 'edit-market-post.component.html'
})
export class EditMarketPostComponent implements AfterViewInit, OnDestroy {
  containerTypes: ContainerType[] = [];
  liners: Liner[] = [];
  transportTypes: TransportType[] = [];
  post: any;
  postId: string;
  type: string;
  path: string;
  alert: Alert;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private store: Store<State>,
    private api: ApiService,
    private marketpostParserService: MarketpostParserService,
    private marketpostUpdates$: Actions,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadLiners());
    this.store.dispatch(new LoadContainerTypes());
    this.store.dispatch(new LoadTransportTypes());
    this.store.dispatch(new LoadMarketPostDemands());
    this.store.dispatch(new LoadMarketPostOffers());

    this.postId = this.route.snapshot.paramMap.get('id');
    this.type = this.route.snapshot.paramMap.get('type');

    this.path =
      this.type === 'offers'
        ? '/market_post_offers/' + this.postId
        : '/market_post_demands/' + this.postId;

    this.store
      .select(state => state.containerTypes)
      .pipe(untilDestroyed(this))
      .subscribe(cTypes => {
        if (cTypes) {
          this.containerTypes = cTypes;
        }
      });
    this.store
      .select(state => state.marketplace.liners)
      .pipe(untilDestroyed(this))
      .subscribe(storeLiners => {
        if (storeLiners) {
          this.liners = storeLiners;
        }
      });
    this.store
      .select(state => {
        if (state.marketplace && state.marketplace.transportTypes) {
          return state.marketplace.transportTypes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(tTypes => {
        if (tTypes) {
          this.transportTypes = tTypes;
        }
      });

    marketpostUpdates$
      .pipe(ofType(
        Types.marketposts.UPDATE_MARKETPOSTS_DEMAND_SUCCEEDED,
        Types.marketposts.UPDATE_MARKETPOSTS_OFFER_SUCCEEDED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Update successful'
        });
        this.location.back();
      });

    marketpostUpdates$
      .pipe(ofType(
        Types.marketposts.UPDATE_MARKETPOSTS_DEMAND_FAILED,
        Types.marketposts.UPDATE_MARKETPOSTS_OFFER_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Update failed'
        });
      });
  }

  ngOnDestroy() { }

  ngAfterViewInit() {
    this.api.get({ path: this.path }).subscribe(response => {
      if (response) {
        // For some reason transportTasks objects get converted so that they loose all their values, parsing them mitigates that
        response = JSON.parse(JSON.stringify(response));
        const transport =
          this.type === 'offers' ? response['transportOrigin'] : response;
        if (transport['container']['liner']) {
          transport['container']['liner'] =
            transport['container']['liner']['@id'];
        }
        if (transport['container']['containerType']) {
          transport['container']['containerType'] =
            transport['container']['containerType']['@id'];
        }
        this.post = transport;
      }
    });
  }

  /**
   * Update the existing demand
   * @param demand the value emitted from the demand form
   */
  transportDemandAdded(demand) {
    const body = this.marketpostParserService.parseTransportDemand(
      demand.transport
    );
    if (demand.transport.originLocation) {
      body['originLocation'] = demand.transport.originLocation.name
        ? demand.transport.originLocation
        : { name: demand.transport.originLocation };
    }
    if (demand.transport.destinationLocation) {
      body['destinationLocation'] = demand.transport.destinationLocation.name
        ? demand.transport.destinationLocation
        : { name: demand.transport.destinationLocation };
    }
    this.store.dispatch(
      new UpdateMarketPostDemand({
        id: this.postId,
        body: body
      })
    );
  }

  /**
   * Update the existing offer
   * We don't include the transport id here because we want to create a new transport instance so a reindex is triggered
   * and new mails are sent.
   * @param offer the value emitted from the offer form
   */
  transportOfferAdded(offer) {
    const body = this.marketpostParserService.parseTransportOffer(
      offer.transport
    );
    body.createdOnMarketplace = true;
    body.transportTasks = body.transportTasks
      .filter(task => task.location)
      .map(tTask => {
        if (tTask.location) {
          return {
            taskType: tTask.taskType['@id']
              ? tTask.taskType['@id']
              : tTask.taskType,
            location: {
              name: tTask.location.name || tTask.location,
              latitude: tTask.location.latitude,
              longitude: tTask.location.longitude
            },
            startDate: tTask.startDate,
            endDate: tTask.endDate,
            endDateTimeSpecified: tTask.endDateTimeSpecified,
            startDateTimeSpecified: tTask.startDateTimeSpecified
          };
        }
      });
    this.store.dispatch(
      new UpdateMarketPostOffer({
        id: this.postId,
        body: {
          transportOrigin: body
        }
      })
    );
  }

  /**
   * Closes the current alert
   */
  closeAlert() {
    this.alert = null;
  }
}
