import * as types from '../types/marketplace.types';
import * as MarketPostDemandsActions from '../actions/market-post-demand.actions';
import { MarketPostDemand } from '../models/market-post-demand.model';

const INITIAL_STATE = null;

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.
export function MarketpostDemandsReducer(
  state = INITIAL_STATE,
  action: MarketPostDemandsActions.All
): MarketPostDemand[] {
  switch (action.type) {
    case types.marketposts.LOAD_MARKETPOSTS_DEMANDS_SUCCEEDED:
      return [...action.payload['hydra:member']];
    case types.marketposts.CLEAR:
      return null;
  }
  return state;
}
