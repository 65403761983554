import { Router } from '@angular/router';
import { AppConfig } from '../app.config';
import * as i0 from "@angular/core";
import * as i1 from "../app.config";
import * as i2 from "@angular/router";
var SamlService = /** @class */ (function () {
    function SamlService(environment, router) {
        this.environment = environment;
        this.router = router;
        this.automaticRedirect = false;
        var samlObject = environment.getConfig('saml2');
        if (!samlObject) {
            return;
        }
        this.samlIdp = samlObject['identityProviderEntityId'];
        this.automaticRedirect = samlObject['automaticRedirect'];
    }
    SamlService.prototype.isSamlEnabled = function () {
        return this.samlIdp != null;
    };
    SamlService.prototype.isSamlAutomaticRedirectEnabled = function () {
        return this.isSamlEnabled() && this.automaticRedirect;
    };
    SamlService.prototype.performLoginSAML2SSO = function () {
        // Get redirect url convoluted because angular limitations
        var currentAbsoluteUrl = window.location.href;
        var currentRelativeUrl = this.router.url;
        var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
        var targetBaseUrl = currentAbsoluteUrl.substring(0, index);
        var redirectUrl = targetBaseUrl + '/user/tenants';
        var oauthClientId = this.environment.getConfig('clientId');
        var idp = this.samlIdp;
        targetBaseUrl = window.btoa(targetBaseUrl);
        redirectUrl = window.btoa(redirectUrl);
        oauthClientId = window.btoa(oauthClientId);
        var url = this.environment.getConfig('apiUrl') + "/saml/login_redirect/" + targetBaseUrl + "/" + oauthClientId + "/" + redirectUrl + "?idp=" + idp;
        return url;
    };
    SamlService.prototype.performLogoutSAML2SSO = function () {
        // Get redirect url convoluted because angular limitations
        var currentAbsoluteUrl = window.location.href;
        var currentRelativeUrl = this.router.url;
        var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
        var targetBaseUrl = currentAbsoluteUrl.substring(0, index);
        var redirectUrl = targetBaseUrl + '/login';
        var idp = window.btoa(this.samlIdp);
        redirectUrl = window.btoa(redirectUrl);
        var url = this.environment.getConfig('apiUrl') + "/saml/logout/" + idp + "/" + redirectUrl;
        return url;
    };
    SamlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SamlService_Factory() { return new SamlService(i0.ɵɵinject(i1.AppConfig), i0.ɵɵinject(i2.Router)); }, token: SamlService, providedIn: "root" });
    return SamlService;
}());
export { SamlService };
