import { Message } from '../models/messages.model';
import * as types from '../types/message.types';
import * as MessagesActions from '../actions/messages.actions';

const INITIAL_STATE = [];

export function MessagesReducer(
  state = INITIAL_STATE,
  action: MessagesActions.All
): Message[] {
  switch (action.type) {
    case types.messages.LOAD_SUCCEEDED:
      const messages = action.payload['hydra:member'] as Message[];
      messages.forEach(m => {
        m.replies = m.replies.sort((a, b) => {
          const date1 = new Date(a.updatedAt).getTime();
          const date2 = new Date(b.updatedAt).getTime();
          return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
        });
      });
      return [...messages];
    case types.messages.CREATE_SUCCEEDED:
      return state ? [action.payload, ...state] : [action.payload];
    case types.messages.CREATE_REPLY_SUCCEEDED:
      const message = state.find(m => m.id === action.payload.parentId);
      message.replies = [action.payload.data, ...message.replies].sort(
        (a, b) => {
          const date1 = new Date(a.updatedAt).getTime();
          const date2 = new Date(b.updatedAt).getTime();
          return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
        }
      );
      return state.map(item => {
        return item.id === action.payload.parentId
          ? Object.assign({}, item, message)
          : item;
      });
    case types.messages.CLEAR:
      return null;
  }
  return state;
}
