import { LoadSuppliers, UpdateSupplier } from 'app/core/store/actions/supplier.actions';
import { Supplier } from 'app/core/store/models/supplier.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { extract } from 'app/services/i18n.service';
import { State } from 'app/core/store/store.model';
import { ActivatedRoute } from '@angular/router';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import { Actions, ofType } from '@ngrx/effects';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { DeleteBusinessContact } from 'app/core/store/actions/business-contact.actions';
import { DeleteAddress } from 'app/core/store/actions/address.actions';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-supplier',
  templateUrl: './edit-supplier.component.html',
  styleUrls: ['./edit-supplier.component.scss']
})
export class EditSupplierComponent implements OnInit, OnDestroy {
  title = extract('Edit supplier');
  countries: any[];
  supplier: Supplier;
  vatNumber: string;
  name: string;
  id: string;
  suppliers: Supplier[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private modalService: NgbModal,
    private updates$: Actions
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.store
          .select(state => state.crm.suppliers)
          .pipe(untilDestroyed(this))
          .subscribe(supplierState => {
            if (!supplierState || !supplierState.suppliers) {
              this.store.dispatch(new LoadSuppliers());
              return;
            }
            this.suppliers = [...supplierState.suppliers];
            this.supplier = supplierState.suppliers.find(c => c.id === this.id);
          });
      }
    });
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.LOAD_SUPPLIERS_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.supplier || !this.id || !this.suppliers) {
          return;
        }
        this.supplier = this.suppliers.find(c => c.id === this.id);
      });
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.UPDATE_SUPPLIER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.supplier = this.suppliers.find(c => c.id === result['payload'].id);
        this.supplier.billingAddress['isLoading'] = false;
        this.supplier.defaultContact['isLoading'] = false;
      });
  }

  ngOnInit() {
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countriesState => {
        if (!countriesState) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countriesState];
      });
  }

  updateSupplier() {
    this.store.dispatch(
      new UpdateSupplier({
        supplierId: this.supplier.id,
        supplier: this.supplier
      })
    );
  }
  handleUpdateBillingAddress(addressId: string) {
    const updatedBillingAddress = this.supplier.addresses.find(
      a => a['id'] === addressId
    );
    updatedBillingAddress['isLoading'] = true;
    this.supplier.billingAddress = updatedBillingAddress;
    this.updateSupplier();
  }
  handleUpdateMainContact(supplierId: string) {
    const updatedDefaultContact = this.supplier.contacts.find(
      c => c['id'] === supplierId
    );
    updatedDefaultContact['isLoading'] = true;
    this.supplier.defaultContact = updatedDefaultContact;
    this.updateSupplier();
  }
  launchNewAddressModal() {
    const modalRef = this.modalService.open(AddressModalComponent);
    modalRef.result.then(result => {
      this.supplier.addresses.push(result);
      this.updateSupplier();
    });
  }
  launchNewContactModal() {
    const modalRef = this.modalService.open(ContactModalComponent);
    modalRef.result.then(result => {
      this.supplier.contacts.push(result);
      this.updateSupplier();
    });
  }
  handleDeleteContact(contactId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this contact?'
    );
    modalRef.result.then(() => {
      this.store.dispatch(
        new DeleteBusinessContact({
          businessId: this.supplier.id,
          businessContactId: contactId
        })
      );
    });
  }

  handleDeleteAddress(addressId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this address?'
    );
    modalRef.result.then(() => {
      this.store.dispatch(
        new DeleteAddress({
          businessId: this.supplier.id,
          addressId: addressId
        })
      );
    });
  }

  get filteredAddresses() {
    if (!this.supplier) {
      return;
    }
    const billingAddress = this.supplier.billingAddress;
    return this.supplier.addresses.filter(
      address => address['id'] !== billingAddress['id']
    );
  }

  get filteredContacts() {
    if (!this.supplier || !this.supplier.contacts) {
      return;
    }
    const defaultContact = this.supplier.defaultContact;
    return this.supplier.contacts.filter(
      contact => contact['id'] !== defaultContact['id']
    );
  }

  ngOnDestroy() { }
}
