<div class="container" *ngIf="!isLoadingMatchCandidates; else: loading">
  <div *ngIf="matchCandidates && matchCandidates.length !== 0; else: noMatches">
    <div
      data-infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="1.5"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
      [fromRoot]="true"
      [infiniteScrollContainer]="'.ng-sidebar__content'"
    >
      <div *ngFor="let item of matchCandidates">
        <app-matching-pool-item
          *ngIf="item.visible"
          [disabledMatch]="disabledMatch"
          [matchingPoolItem]="item"
          [isLoading]="isLoading"
          (removeMatchOption)="handleRemoveMatchOption($event)"
          (removeMatchItem)="handleRemoveMatchItem($event)"
          (submitReply)="handleSendReply($event)"
        ></app-matching-pool-item>
      </div>
    </div>
  </div>
  <ng-template #noMatches>
    <div class="alert alert-info">{{ 'No matches found' | translate }}</div>
  </ng-template>
</div>
<ng-template #loading>
  <p>{{ 'Loading matches...' | translate }}</p>
</ng-template>
