import { EditAddressModalComponent } from '../edit-address-modal/edit-address-modal.component';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Address } from 'app/core/store/models/address.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {
  @Input()
  address: Address;
  @Input()
  billingAddress: boolean;

  @Output()
  updateBillingAddress = new EventEmitter<string>();
  @Output()
  deleteAddress = new EventEmitter<string>();

  constructor(private modalService: NgbModal) { }

  setAsBillingAddress(): void {
    this.updateBillingAddress.emit(this.address.id);
  }
  launchEditAddressModal() {
    const modalRef = this.modalService.open(EditAddressModalComponent);
    modalRef.componentInstance.address = this.address;
    modalRef.result.then(result => {
      this.address = Object.assign({}, this.address, result);
    });
  }

  handleDelete() {
    this.deleteAddress.emit(this.address.id);
  }
}
