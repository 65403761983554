import { Component, OnDestroy } from '@angular/core';
import { extract } from 'app/services/i18n.service';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  LoadDrivers,
  UpdateDriver,
  DeleteDriver,
  DisableDriver,
  EnableDriver
} from 'app/core/store/actions/driver.actions';
import * as DriverTypes from 'app/core/store/types/driver.types';
import { Driver } from 'app/core/store/models/driver.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-edit-driver',
  templateUrl: 'edit-driver.component.html'
})
export class EditDriverComponent implements OnDestroy {
  title = extract('Edit Driver');
  userContextId: string;
  userContext: Driver;
  isLoading = false;
  superAdminId: string;
  drivers: Driver[];

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.store.dispatch(
      new LoadDrivers({
        includeSuperAdmins: 0,
        roleName: 'DRIVER'
      })
    );
    this.isLoading = true;
    this.store
      .select(state => state.drivers)
      .pipe(untilDestroyed(this))
      .subscribe(driverState => {
        if (driverState && driverState.drivers) {
          this.drivers = driverState.drivers;
          if (this.userContextId && !this.userContext) {
            this.getUserContextById();
          }
          this.isLoading = false;
        }
      });
    this.userContextId = this.route.snapshot.paramMap.get('id');
    // Get userContext
    this.getUserContextById();

    this.updates$
      .pipe(ofType(
        DriverTypes.drivers.UPDATE_DRIVER_SUCCEEDED,
        DriverTypes.drivers.ENABLE_DRIVER_SUCCEEDED,
        DriverTypes.drivers.DISABLE_DRIVER_SUCCEEDED,
        DriverTypes.drivers.DELETE_DRIVER_SUCCEEDED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastService.showSuccess({
          title: extract('Driver update successfull')
        });
        this.isLoading = false;
        // Return to the user overview after update
        this.router.navigateByUrl('/tms/drivers');
      });
    this.updates$
      .pipe(ofType(
        DriverTypes.drivers.UPDATE_DRIVER_FAILED,
        DriverTypes.drivers.ENABLE_DRIVER_FAILED,
        DriverTypes.drivers.DISABLE_DRIVER_FAILED,
        DriverTypes.drivers.DELETE_DRIVER_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  getUserContextById(): any {
    if (!this.drivers) {
      return;
    }
    if (this.userContext) {
      return;
    }
    this.userContext = this.drivers.find(
      driver => driver.id === this.userContextId
    );
  }
  updateDriver(userContextFormValue) {
    delete userContextFormValue['roles'];
    this.store.dispatch(
      new UpdateDriver({
        driverId: this.userContextId,
        driver: {
          user: {
            id: this.userContext.user.id,
            firstName: userContextFormValue.firstName,
            lastName: userContextFormValue.lastName,
            email: userContextFormValue.email
          },
          phoneNumber: userContextFormValue.phoneNumber,
          locale: userContextFormValue.locale,
          licensePlate: userContextFormValue.licensePlate
        }
      })
    );
    this.isLoading = true;
  }

  deleteDriver() {
    this.store.dispatch(new DeleteDriver(this.userContextId));
  }

  disableDriver() {
    this.store.dispatch(new DisableDriver(this.userContextId));
  }

  enableDriver() {
    this.store.dispatch(new EnableDriver(this.userContextId));
  }

  ngOnDestroy() { }
}
