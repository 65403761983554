import * as TransactionActions from '../actions/transactions.actions';
import { Transaction } from '../models/transaction.model';
import * as types from '../types/transaction.types';

export interface TransactionState {
  data: Transaction[];
  nextPage: string;
  totalElements: number;
}

const INITIAL_TRANSACTIONS_STATE = {
  data: null,
  nextPage: '',
  totalElements: 0,
};

const INITIAL_CREDIT_TRANSACTIONS_STATE = {
  data: null,
  nextPage: '',
  totalElements: 0,
};

export function reducer(
  state = INITIAL_TRANSACTIONS_STATE,
  action: TransactionActions.All
): TransactionState {
  switch (action.type) {
    case types.transactions.LOAD_ALL_TRANSACTIONS_SUCCEEDED:
      state.data = [...action.payload['hydra:member']];
      state.nextPage =
        action.payload['hydra:view'] &&
          action.payload['hydra:view']['hydra:next']
          ? action.payload['hydra:view']['hydra:next'].slice(
            action.payload['hydra:view']['hydra:next'].indexOf('=') + 1,
            action.payload['hydra:view']['hydra:next'].length
          )
          : null;
      return Object.assign({}, state);
    case types.transactions.LOAD_TRANSACTION_PAGE_SUCCEEDED:
      return {
        data: state.data
          ? [...state.data, ...action.payload['hydra:member']]
          : [...action.payload['hydra:member']],
        nextPage:
          action.payload['hydra:view'] &&
            action.payload['hydra:view']['hydra:next']
            ? action.payload['hydra:view']['hydra:next'].slice(
              action.payload['hydra:view']['hydra:next'].indexOf('=') + 1,
              action.payload['hydra:view']['hydra:next'].length
            )
            : null,
        totalElements: action.payload['hydra:totalItems'],
      };
    case types.transactions.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return state;
  }
  return state;
}

export function creditTransactionsReducer(
  state = INITIAL_CREDIT_TRANSACTIONS_STATE,
  action: TransactionActions.All
): TransactionState {
  switch (action.type) {
    case types.transactions.LOAD_CREDIT_TRANSACTIONS_SUCCEEDED:
      return {
        data: [...action.payload['hydra:member']],
        nextPage:
          action.payload['hydra:view'] &&
            action.payload['hydra:view']['hydra:next']
            ? action.payload['hydra:view']['hydra:next'].slice(
              action.payload['hydra:view']['hydra:next'].indexOf('=') + 1,
              action.payload['hydra:view']['hydra:next'].length
            )
            : null,
        totalElements: action.payload['hydra:totalItems'],
      };

    case types.transactions.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return state;
  }
  return state;
}
