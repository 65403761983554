/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./circle-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../circle/circle.component.ngfactory";
import * as i3 from "../circle/circle.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../shared/loading/loading.component.ngfactory";
import * as i7 from "../../../../shared/loading/loading.component";
import * as i8 from "./circle-list.component";
import * as i9 from "@ngrx/store";
import * as i10 from "@ngrx/effects";
import * as i11 from "../../../../services/toast.service";
var styles_CircleListComponent = [i0.styles];
var RenderType_CircleListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CircleListComponent, data: {} });
export { RenderType_CircleListComponent as RenderType_CircleListComponent };
function View_CircleListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cmp-circle", [], null, [[null, "circleChanged"], [null, "deleteCircleEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("circleChanged" === en)) {
        var pd_0 = (_co.saveCircle($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("deleteCircleEmitter" === en)) {
        var pd_1 = (_co.deleteCircle(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CircleComponent_0, i2.RenderType_CircleComponent)), i1.ɵdid(2, 49152, null, 0, i3.CircleComponent, [], { circle: [0, "circle"] }, { circleChanged: "circleChanged", deleteCircleEmitter: "deleteCircleEmitter" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CircleListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CircleListComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.circles$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CircleListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa far-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("You don't have any circles yet.")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("What are Circles ?")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("Circles can be used to manage who can see certain posts. When creating a new post in the marketplace, you can choose to hide it for certain circles or only share it with certain specific circles.")); _ck(_v, 9, 0, currVal_2); }); }
export function View_CircleListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "card mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h3", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-cmp-loading", [], null, null, null, i6.View_LoadingComponent_0, i6.RenderType_LoadingComponent)), i1.ɵdid(6, 49152, null, 0, i7.LoadingComponent, [], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CircleListComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CircleListComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.noCircles; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.noCircles; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("My Circles")); _ck(_v, 3, 0, currVal_0); }); }
export function View_CircleListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-circle-list", [], null, null, null, View_CircleListComponent_0, RenderType_CircleListComponent)), i1.ɵdid(1, 245760, null, 0, i8.CircleListComponent, [i9.Store, i10.Actions, i11.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CircleListComponentNgFactory = i1.ɵccf("app-cmp-circle-list", i8.CircleListComponent, View_CircleListComponent_Host_0, { loading: "loading", circles$: "circles$" }, {}, []);
export { CircleListComponentNgFactory as CircleListComponentNgFactory };
