import { Action } from '@ngrx/store';
import * as types from '../types/customer.types';

export class LoadCustomers implements Action {
  readonly type = types.customer.LOAD_CUSTOMERS;
  constructor(public payload?: any) {}
}

export class LoadCustomersFail implements Action {
  readonly type = types.customer.LOAD_CUSTOMERS_FAILED;
  constructor(public payload: any) {}
}

export class LoadCustomersSuccess implements Action {
  readonly type = types.customer.LOAD_CUSTOMERS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadFilteredCustomers implements Action {
  readonly type = types.customer.LOAD_FILTERED_CUSTOMERS;
  constructor(public payload?: any) {}
}

export class LoadFilteredCustomersFail implements Action {
  readonly type = types.customer.LOAD_FILTERED_CUSTOMERS_FAILED;
  constructor(public payload: any) {}
}

export class LoadFilteredCustomersSuccess implements Action {
  readonly type = types.customer.LOAD_FILTERED_CUSTOMERS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class CreateCustomer implements Action {
  readonly type = types.customer.CREATE_CUSTOMER;
  constructor(public payload?: any) {}
}

export class CreateCustomerFail implements Action {
  readonly type = types.customer.CREATE_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class CreateCustomerSuccess implements Action {
  readonly type = types.customer.CREATE_CUSTOMER_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteCustomer implements Action {
  readonly type = types.customer.DELETE_CUSTOMER;
  constructor(
    public payload: {
      customerId: string;
    }
  ) {}
}

export class DeleteCustomerFail implements Action {
  readonly type = types.customer.DELETE_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class DeleteCustomerSuccess implements Action {
  readonly type = types.customer.DELETE_CUSTOMER_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateCustomer implements Action {
  readonly type = types.customer.UPDATE_CUSTOMER;
  constructor(
    public payload: {
      customerId: string;
      customer: any;
    }
  ) {}
}

export class UpdateCustomerFail implements Action {
  readonly type = types.customer.UPDATE_CUSTOMER_FAILED;
  constructor(public payload: any) {}
}

export class UpdateCustomerSuccess implements Action {
  readonly type = types.customer.UPDATE_CUSTOMER_SUCCEEDED;
  constructor(public payload: any) {}
}

export class ClearCustomers implements Action {
  readonly type = types.customer.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadCustomers
  | LoadCustomersFail
  | LoadCustomersSuccess
  | LoadFilteredCustomers
  | LoadFilteredCustomersFail
  | LoadFilteredCustomersSuccess
  | CreateCustomer
  | CreateCustomerSuccess
  | CreateCustomerFail
  | DeleteCustomer
  | DeleteCustomerSuccess
  | DeleteCustomerFail
  | UpdateCustomer
  | UpdateCustomerSuccess
  | UpdateCustomerFail
  | ClearCustomers;
