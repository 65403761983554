var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as types from '../types/location.types';
import * as LocationActions from '../actions/location.actions';
import { ApiService } from '../../api/api.service';
import { map, concatMap, catchError } from 'rxjs/operators';
var LocationEffects = /** @class */ (function () {
    function LocationEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadLocations$ = this.actions$.pipe(ofType(types.location.LOAD_LOCATIONS), concatMap(function () { return _this.api.get({ path: '/locations' }).pipe(map(function (data) { return new LocationActions.LoadLocationsSuccess(data); }), catchError(function (error) { return of(new LocationActions.LoadLocationsFail(error)); })); }));
        this.loadUncheckedLocations$ = this.actions$.pipe(ofType(types.location.LOAD_UNCHECKED_LOCATIONS), concatMap(function () { return _this.api.get({
            path: '/dashboard/marketplace/improvable-location'
        }).pipe(map(function (data) { return new LocationActions.LoadUncheckedLocationsSuccess(data); }), catchError(function (error) { return of(new LocationActions.LoadUncheckedLocationsFail(error)); })); }));
        this.loadLocationsByType$ = this.actions$.pipe(ofType(types.location.LOAD_LOCATIONS_BY_LOCATION_TYPE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/locations?locationType.code=" + payload.locationType + "&page=" + (payload.page ? payload.page : 1)
        }).pipe(map(function (data) { return new LocationActions.LoadLocationsByTypeSuccess(data); }), catchError(function (error) { return of(new LocationActions.LoadLocationsByTypeFail(error)); })); }));
        this.loadAddressBookLocations$ = this.actions$.pipe(ofType(types.location.LOAD_ADDRESS_BOOK_LOCATIONS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            return _this.api.get({ path: "/addressbook?query=" + query }).pipe(map(function (data) { return new LocationActions.LoadAddressBookLocationsSuccess(data); }), catchError(function (error) { return of(new LocationActions.LoadAddressBookLocationsFail(error)); }));
        }));
        this.loadPrenotifiableLocations$ = this.actions$.pipe(ofType(types.location.LOAD_PRENOTIFIABLE_LOCATIONS), concatMap(function () { return _this.api.get({
            path: "/get_prenotifiable_locations"
        }).pipe(map(function (data) { return new LocationActions.LoadPrenotifiableLocationsSuccess(data); }), catchError(function (error) { return of(new LocationActions.LoadPrenotifiableLocationsFail(error)); })); }));
        this.loadLocationTypes$ = this.actions$.pipe(ofType(types.location.LOAD_LOCATION_TYPES), concatMap(function () { return _this.api.get({ path: "/location_types" }).pipe(map(function (data) { return new LocationActions.LoadLocationTypesSuccess(data); }), catchError(function (error) { return of(new LocationActions.LoadLocationTypesFail(error)); })); }));
        this.createUserLocation$ = this.actions$.pipe(ofType(types.location.CREATE_USER_LOCATION), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/locations", body: payload
        }).pipe(map(function (data) { return new LocationActions.CreateUserLocationSuccess(data); }), catchError(function (error) { return of(new LocationActions.CreateUserLocationFail(error)); })); }));
        this.updateUserLocation$ = this.actions$.pipe(ofType(types.location.UPDATE_USER_LOCATION), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/locations/" + payload.locationId,
            body: payload.location
        }).pipe(map(function (data) { return new LocationActions.UpdateUserLocationSuccess(data); }), catchError(function (error) { return of(new LocationActions.UpdateUserLocationFail(error)); })); }));
        this.deleteUserLocation$ = this.actions$.pipe(ofType(types.location.DELETE_USER_LOCATION), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/locations/" + payload.locationId
        }).pipe(map(function () { return new LocationActions.DeleteUserLocationSuccess(payload.locationId); }), catchError(function (error) { return of(new LocationActions.DeleteUserLocationFail(error)); })); }));
        this.deleteLocation$ = this.actions$.pipe(ofType(types.location.DELETE_LOCATION), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/locations/" + payload.locationId
        }).pipe(map(function () { return new LocationActions.DeleteLocationSuccess(payload.locationId); }), catchError(function (error) { return of(new LocationActions.DeleteLocationFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "loadLocations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "loadUncheckedLocations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "loadLocationsByType$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "loadAddressBookLocations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "loadPrenotifiableLocations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "loadLocationTypes$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "createUserLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "updateUserLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "deleteUserLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocationEffects.prototype, "deleteLocation$", void 0);
    return LocationEffects;
}());
export { LocationEffects };
