import { Toast } from '../core/store/models/toast.model';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
  constructor(private toastr: ToastrService, private translate: TranslateService) {}

  showSuccess(toast: Toast) {
    this.toastr.success(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null
    );
  }

  showDanger(toast: Toast) {
    this.toastr.error(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null,
      {
        positionClass: 'toast-top-full-width'
      }
    );
  }

  showWarning(toast: Toast) {
    this.toastr.warning(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null
    );
  }

  showInfo(toast: Toast) {
    this.toastr.info(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null
    );
  }

  showInfoLoading(toast: Toast) {
    this.toastr.info(
      toast.message ? this.translate.instant(toast.message) : null,
      toast.title ? this.translate.instant(toast.title) : null,
      {
        positionClass: 'toast-bottom-full-width',
        progressBar: true,
        progressAnimation: 'increasing'
      }
    );
  }
  clear() {
    this.toastr.clear();
  }
}
