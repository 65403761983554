<div class="container text-center text-white">
  <h1>
    {{ 'Help us to improve the matching' | translate }}
    <i class="far fa-bolt"></i>
  </h1>
  <span class="recently-added">{{
    'You recently added a transport with location' | translate
  }}</span>
  <h4>{{ location?.name }}</h4>
  <span class="pb-1"
    >{{
      'We could not find the exact location, could you give us the address? Or'
        | translate
    }}
    <button
      class="p-1 btn btn-link text-dark"
      (click)="handleSkipLocation()"
      [disabled]="isLoading || isLoadingPublicLocation"
    >
      {{ 'skip this location.' | translate }}
    </button>
  </span>
  <div class="address-input">
    <div class="input-group">
      <input
        placeholder="{{ 'Enter location here' | translate }}"
        [(ngModel)]="query"
        class="form-control"
        [ngbTypeahead]="searchAddress"
        [resultFormatter]="locationService.addressFormatter"
        (ngModelChange)="updateMarkerByModel(); updatePublicLocation()"
        [inputFormatter]="locationService.addressFormatter"
        [class.is-invalid]="submitAttempt && !coordinates"
        data-cy="taskLocation"
      />
      <div class="input-group-append">
        <span class="input-group-text"> <i class="far fa-search"></i> </span>
      </div>
    </div>
    <small *ngIf="submitAttempt && !coordinates" class="text-danger">{{
      'Please provide an address' | translate
    }}</small>
  </div>
  <div
    class="location-warning"
    *ngIf="query && (!query.latitude || !query.longitude)"
  >
    {{ 'Please select a private location from the dropdown list' | translate }}
  </div>
  <div class="location-input">
    <div class="input-group">
      <input
        placeholder="{{ 'Enter public location here' | translate }}"
        [ngClass]="{ 'is-invalid': submitAttempt && !publicLocation }"
        [(ngModel)]="publicLocation"
        id="publicLocation"
        class="form-control"
        [ngbTypeahead]="searchLocation"
        [resultFormatter]="locationService.locationFormatter"
        [inputFormatter]="locationService.locationFormatter"
      />
      <div class="input-group-append">
        <span class="input-group-text">
          <i *ngIf="!isLoadingPublicLocation" class="far fa-location-arrow"></i>
          <i *ngIf="isLoadingPublicLocation" class="far fa-spinner fa-spin"></i>
        </span>
      </div>
    </div>
    <app-button
      class="ml-3"
      [btnClass]="'btn-secondary'"
      [isDisabled]="
        !publicLocation ||
        !publicLocation.latitude ||
        !publicLocation.longitude ||
        (!query || !query.latitude || !query.longitude)
      "
      [isLoading]="isLoading || isLoadingPublicLocation"
      (click)="confirmLocation()"
      [type]="'button'"
    >
      {{ 'Confirm' | translate }}
    </app-button>
  </div>
  <div
    class="location-warning"
    *ngIf="
      publicLocation && (!publicLocation.latitude || !publicLocation.longitude)
    "
  >
    {{ 'Please select a location from the dropdown list' | translate }}
  </div>
  <div class="map mt-3" id="map"></div>
</div>
