import { NavbarTopComponent } from './navbar-top/navbar-top.component';
import { SidebarModule } from 'ng-sidebar';
import { RouterEffects } from './store/effects/router.effects';
import { AdminModule } from './admin/admin.module';
import { SuperAdminModule } from './super-admin/super-admin.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UserModule } from './user/user.module';
import { NotificationsModule } from './notifications/notifications.module';
import { NavigationModule } from './navigation/navigation.module';

import { EffectsModule } from '@ngrx/effects';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { reducers } from './store/store.reducer';

import { AboutComponent } from './about/about.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ApiService } from './api/api.service';
import { CompanyPostsModule } from './company-posts/company-posts.module';
import { DriversEffects } from './store/effects/driver.effects';
import { ShellComponent } from './shell/shell.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  exports: [
    UserModule,
    NotificationsModule,
    NavigationModule,
    NavbarTopComponent
  ],
  declarations: [
    AboutComponent,
    WelcomeComponent,
    ShellComponent,
    NavbarTopComponent
  ],
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    UserModule,
    NotificationsModule,
    NavigationModule,
    RouterModule,
    EffectsModule,
    EffectsModule.forFeature([RouterEffects, DriversEffects]),
    TranslateModule,
    AdminModule,
    CompanyPostsModule,
    SuperAdminModule,
    SidebarModule.forRoot(),
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25 //  Retains last 25 states
    }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [ApiService]
})
export class CoreModule { }
