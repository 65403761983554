import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { CommonModule } from '@angular/common';
import {
  ContainerTypeNotificationSubscriptionsComponent
} from './tenant-settings/notification-subscriptions/container-type-notification-subscriptions/container-type-notification-subscriptions.component';
import { ContainerTypesEffects } from '../store/effects/container-types.effects';
import { CreditsComponent } from './tenant-settings/credits/credits.component';
import { CreditTransactionComponent } from './tenant-settings/transactions/credit-transactions/credit-transaction.component';
import { DebitTransactionComponent } from './tenant-settings/transactions/debit-transactions/debit-transaction.component';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NotificationSubscriptionsComponent } from './tenant-settings/notification-subscriptions/notification-subscriptions.component';
import { NotificationSubscriptionsEffects } from '../store/effects/notification-subscriptions.effects';
import { RegistrationRequestComponent } from './registration-request/registration-request.component';
import { RolesEffects } from '../store/effects/roles-effects';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ShippingAgentEffects } from '../store/effects/shipping-agent.effects';
import { SubscriptionsComponent } from './tenant-settings/subscriptions/subscriptions.component';
import { SubscriptionsEffects } from '../store/effects/subscriptions.effects';
import { TenantInformationComponent } from './tenant-settings/tenant-information/tenant-information.component';
import { TenantSettingsEffects } from '../store/effects/tenant-settings.effects';
import { TenantUserComponent } from './tenant-settings/tenant-user/tenant-user.component';
import { TransactionsComponent } from './tenant-settings/transactions/transactions.component';
import { TransactionsEffects } from '../store/effects/transactions.effects';
import {
  TransportTypeNotificationSubscriptionsComponent
} from './tenant-settings/notification-subscriptions/transport-type-notification-subscriptions/transport-type-notification-subscriptions.component';
import { UserContextsEffects } from '../store/effects/user-contexts.effects';
import { WalletEffects } from '../store/effects/wallet.effects';
import {
  MessageBoardMessagesSubscriptionComponent
} from './tenant-settings/notification-subscriptions/message-board-messages-subscription/message-board-messages-subscription.component';
import { CreditInvoicesComponent } from './tenant-settings/transactions/credit-invoices/credit-invoices.component';
import { CreditsWarningComponent } from "./tenant-settings/credits/credits-warning/credits-warning.component";
import { PsaWalletTransactionsReportComponent } from "./tenant-settings/psa-wallet-transactions-report/psa-wallet-transactions-report.component";

/**
 * Module that contains all stuff related to administration
 *
 * @export
 * @class AdminModule
 */
@NgModule({
  imports: [
    NgxDatatableModule,
    NgSelectModule,
    CommonModule,
    SharedModule,
    RouterModule,
    AdminRoutingModule,
    EffectsModule.forFeature([
      TenantSettingsEffects,
      UserContextsEffects,
      RolesEffects,
      WalletEffects,
      SubscriptionsEffects,
      TransactionsEffects,
      NotificationSubscriptionsEffects,
      ContainerTypesEffects,
      ShippingAgentEffects
    ])
  ],
  declarations: [
    AdminComponent,
    TenantInformationComponent,
    TenantUserComponent,
    SubscriptionsComponent,
    CreditsComponent,
    TransactionsComponent,
    CreditTransactionComponent,
    DebitTransactionComponent,
    RegistrationRequestComponent,
    NotificationSubscriptionsComponent,
    ContainerTypeNotificationSubscriptionsComponent,
    TransportTypeNotificationSubscriptionsComponent,
    MessageBoardMessagesSubscriptionComponent,
    CreditInvoicesComponent,
    CreditsWarningComponent,
    PsaWalletTransactionsReportComponent
  ]
})
export class AdminModule { }
