import { LocationService } from 'app/services/location.service';
import { SoftDeleteService } from 'app/services/soft-delete.service';
import { IconService } from 'app/services/icon.service';
import { OnInit, OnChanges, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as DateFns from 'date-fns';
import { NewUserLocationModalComponent } from '../../tms/locations/new-user-location-modal/new-user-location-modal.component';
import { EditUserLocationModalComponent } from '../../tms/locations/edit-user-location-modal/edit-user-location-modal.component';
import { Store } from '@ngrx/store';
import { LoadAddressBookLocations } from 'app/core/store/actions/location.actions';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { untilDestroyed } from 'app/shared/rxjs-util';
var TransportTasksComponent = /** @class */ (function () {
    function TransportTasksComponent(modalService, store, iconService, locationService, softDeleteService) {
        var _this = this;
        this.modalService = modalService;
        this.store = store;
        this.iconService = iconService;
        this.locationService = locationService;
        this.softDeleteService = softDeleteService;
        this.tms = false;
        this.showPublicLocationField = false;
        this.transportTasksChange = new EventEmitter();
        this.isLoadingAddressBookLocations = false;
        this.searchPrivateLocation = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.locationService.privateLocation(term);
            }));
        };
    }
    TransportTasksComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (!changes) {
            return;
        }
        if (changes.matchingIsEnabled) {
            // Whenever matching is toggled emit transportTasksChange to reset validation
            this.tTasksChange();
        }
        if (this.addressBookLocations &&
            changes.transportTasks &&
            !changes.transportTasks.previousValue &&
            changes.transportTasks.currentValue) {
            changes.transportTasks.currentValue.forEach(function (task) {
                _this.addressBookLocations = _this.softDeleteService.addElementToArrayIfNotExists(task.location, _this.addressBookLocations);
            });
        }
    };
    TransportTasksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new LoadAddressBookLocations());
        this.store
            .select(function (state) { return state.tms.locations; })
            .pipe(untilDestroyed(this))
            .subscribe(function (locationState) {
            if (locationState && locationState.addressBookLocations) {
                _this.addressBookLocations = locationState.addressBookLocations.slice().map(function (location) {
                    return {
                        id: location.id,
                        '@id': location['@id'],
                        name: location.name,
                        fullAddress: location.fullAddress,
                        publicLocation: location.publicLocation,
                        latitude: location.latitude,
                        longitude: location.longitude,
                        prePaid: location.prePaid
                    };
                });
                _this.isLoadingAddressBookLocations = false;
                if (_this.transportTasks) {
                    _this.transportTasks.forEach(function (task) {
                        if (!task.location) {
                            return;
                        }
                        _this.addressBookLocations = _this.softDeleteService.addElementToArrayIfNotExists(task.location, _this.addressBookLocations);
                        (task.location.privateLocations || []).forEach(function (privLocation) {
                            _this.addressBookLocations = _this.softDeleteService.addElementToArrayIfNotExists(privLocation, _this.addressBookLocations);
                        });
                    });
                }
            }
        });
    };
    TransportTasksComponent.prototype.getTaskIcon = function (task) {
        return this.iconService.getTransportTaskIcon(task);
    };
    Object.defineProperty(TransportTasksComponent.prototype, "hasAtLeastOneLocation", {
        get: function () {
            return this.transportTasks.some(function (task) { return task.location; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportTasksComponent.prototype, "everyTaskHasLocation", {
        get: function () {
            return this.transportTasks.every(function (task) { return task.location; });
        },
        enumerable: true,
        configurable: true
    });
    TransportTasksComponent.prototype.privateLocationChange = function (event, index) {
        if (!event) {
            // this.transportTasks[index].location = null;
            return;
        }
        // if location is private set the public location associated with the selected private location
        this.transportTasks[index].location = event.publicLocation
            ? event.publicLocation
            : event;
        this.tTasksChange();
    };
    /**
     * Set start or end date if one date and two times are filed in
     */
    TransportTasksComponent.prototype.timeChange = function (task) {
        if (task.startTime && task.endTime) {
            if (task.startDate && !task.endDate) {
                task.endDate = task.startDate;
            }
            if (!task.startDate && task.endDate) {
                task.startDate = task.endDate;
            }
        }
        this.tTasksChange();
    };
    /**
     * Emits the new transport tasks value and the location validation (at least one)
     */
    TransportTasksComponent.prototype.tTasksChange = function () {
        this.transportTasksChange.emit({
            tasks: this.transportTasks,
            valid: this.hasAtLeastOneLocation
        });
    };
    TransportTasksComponent.prototype.startTimeChange = function (i) {
        this.transportTasks[i].startDateTimeSpecified =
            this.transportTasks[i].startTime !== null;
    };
    TransportTasksComponent.prototype.endTimeChange = function (i) {
        this.transportTasks[i].endDateTimeSpecified =
            this.transportTasks[i].endTime !== null;
    };
    /**
     *
     * @param date date object given by the datepicker
     * @param current the current year and month
     * @returns boolean if given date is before today
     */
    TransportTasksComponent.prototype.getDisabledDates = function (date) {
        var today = new Date();
        var d = new Date(date.year, date.month, date.day);
        d = DateFns.subMonths(d, 1);
        d = DateFns.addDays(d, 1);
        return DateFns.isBefore(d, today);
    };
    TransportTasksComponent.prototype.openNewUserLocationModal = function (taskId, locationName) {
        var _this = this;
        var modalRef = this.modalService.open(NewUserLocationModalComponent);
        if (locationName) {
            modalRef.componentInstance.userLocation = {
                name: locationName
            };
        }
        modalRef.result.then(function (result) {
            // Patch the result to the private and public location of the right task
            var parsed = {
                id: result.payload.id,
                '@id': result.payload['@id'],
                name: result.payload.name,
                fullAddress: result.payload.name + " - " + result.payload.fullAddress,
                latitude: result.payload.latitude,
                longitude: result.payload.longitude,
                publicLocation: result.payload.publicLocation,
                prePaid: result.prePaid
            };
            _this.addressBookLocations = _this.addressBookLocations.concat([parsed]);
            _this.transportTasks[taskId].privateLocation = result.payload.id;
            _this.transportTasks[taskId].location = result.payload.publicLocation;
            _this.tTasksChange();
        });
    };
    /**
     * Used to check if the location is not a user location
     * Only user locations are editable
     * @param location
     */
    TransportTasksComponent.prototype.isPublicLocation = function (location) {
        if (!location || !this.addressBookLocations) {
            return;
        }
        var addressBookLocation = this.addressBookLocations.find(function (loc) { return loc.id === location; });
        return !((addressBookLocation && addressBookLocation.public_location) ||
            (addressBookLocation && addressBookLocation.publicLocation));
    };
    TransportTasksComponent.prototype.openEditUserLocationModal = function (taskId, userLocation) {
        var _this = this;
        var modalRef = this.modalService.open(EditUserLocationModalComponent);
        // Depending of how we got the user location; the public location has a different structure
        var location = this.addressBookLocations.find(function (l) { return l.id === userLocation; });
        var publicLocation = location.public_location
            ? location.public_location
            : location.publicLocation
                ? location.publicLocation
                : location.location.map(function (l) {
                    return {
                        '@id': l['@id'],
                        fullAddress: l.address
                    };
                });
        modalRef.componentInstance.location = {
            id: location.id,
            name: location.name,
            fullAddress: location.address ? location.address : location.fullAddress,
            latitude: location.latitude,
            longitude: location.longitude,
            publicLocation: publicLocation
        };
        modalRef.result.then(function (result) {
            if (!result) {
                return;
            }
            // Patch the result to the private and public location of the right task
            _this.transportTasks[taskId].privateLocation = result.payload.id;
            _this.transportTasks[taskId].location = result.payload.publicLocation;
            _this.tTasksChange();
        });
    };
    TransportTasksComponent.prototype.ngOnDestroy = function () { };
    return TransportTasksComponent;
}());
export { TransportTasksComponent };
