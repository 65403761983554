<app-psa-onboarding *ngIf="showPsaOnboarding"></app-psa-onboarding>

<div class="grid-container mt-4">
  <div class="left">
    <div *ngIf="showReloadBanner" class="announcement announcement-green card">
      <h4><b>{{ 'HAKKA Reload' | translate }}</b></h4>
      <h5><b>{{ 'Never pay too much to triangulate!' | translate }}</b></h5>
      <p>
        {{ 'Reuse empty MSC containers in Belgium? That’s old news.' | translate }}<br>
        {{ 'But that the distance saved determines the price you pay, that’s new!' | translate }}
      </p>
      <p class="text-right mb-0">
        <b>{{ 'Distance-based Reload: only possible on Hakka from 1 October 2019.' | translate }}</b>
      </p>
    </div>

    <div
      *ngIf="showMatchingAnnouncement && !matchingActive"
      class="matching-announcement announcement card"
    >
      <app-ribbon [text]="'Beta'"></app-ribbon>
      <h3 class="card-header matching-announcement__title">
        <i class="far fa-fw fa-bolt"></i>
        <span>{{ 'New: Matching' | translate }}</span>
        <i class="far fa-fw fa-bolt"></i>
      </h3>
      <div class="card-body matching-announcement__body">
        <div class="mb-3">
          {{
          'Activate the matching and get suggestions based on your marketplace posts'
                  | translate
          }}
        </div>
        <button class="btn" (click)="goToMatching()">
          {{ 'Go to matching' | translate }}
        </button>
      </div>
    </div>

    <div id="marketpost-count" *ngIf="hasActiveMarketplaceSubscription">
      <div class="card-button count-container" routerLink="/marketplace">
        <div class="marketpost-count offer">
          <h1 class="text-offer"><i class="far fa-fw fa-hand-holding"></i></h1>
          <h1>{{ offerCount || 0 }}</h1>
          <span>{{ 'OFFERS' | translate }}</span>
        </div>
        <div class="marketpost-count demand">
          <h1 class="text-demand">
            <i class="far fa-fw fa-question-circle"></i>
          </h1>
          <h1>{{ demandCount || 0 }}</h1>
          <span>{{ 'DEMANDS' | translate }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="hasActiveMarketplaceSubscription">
      <div
        class="card-button"
        id="new-marketpost-button"
        routerLink="/marketplace/create"
      >
        <button class="btn btn-primary">
          <i class="far fa-fw fa-plus text-white"></i>
        </button>
        <span id="new-marketpost-text">{{ 'Add new post' | translate }}</span>
      </div>
    </div>
    <app-cmp-credit-amount [wallet]="wallet"></app-cmp-credit-amount>
    <app-cmp-reload-credits></app-cmp-reload-credits>
    <app-psa-status *ngIf="showPsaOnboarding"></app-psa-status>
  </div>
  <div class="right" *ngIf="uncheckedLocation">
    <app-unchecked-location
      [location]="uncheckedLocation"
      (skipLocation)="handleSkipLocation($event)"
      (updateLocation)="handleUpdateLocation($event)"
    ></app-unchecked-location>
  </div>
</div>
