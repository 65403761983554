import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/transport.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as TransportActions from '../actions/transports.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class TransportEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadTransports$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.LOAD_TRANSPORTS),
    map((action: TransportActions.LoadTransports) => action.payload),
    concatMap(payload => this.api.get({
      path:
        '/transports?createdOnMarketplace=0&page=' +
        (payload ? payload.page : 1)
    }).pipe(
      map(data => new TransportActions.LoadTransportsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  loadInvoiceableTransports$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.LOAD_INVOICEABLE_TRANSPORTS),
    map((action: TransportActions.LoadInvoiceableTransports) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      const since = payload && payload.since ? payload.since : '';
      const until = payload && payload.until ? payload.until : '';
      const customers = payload && payload.customers ? payload.customers : null;
      let url = `/tms/crm/payment-notable-transports/invoice?query=${query}&since=${since}&until=${until}`;
      if (customers) {
        url = `${url}&customers[]=${customers}`;
      }
      return this.api.get({ path: url }).pipe(
        map(data => new TransportActions.LoadInvoiceableTransportsSuccess(data)),
        catchError(error => of(new TransportActions.LoadInvoiceableTransportsFail(error)))
      );
    }),
  );

  @Effect()
  loadNextInvoiceableTransportsPage$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE),
    map((action: TransportActions.LoadNextInvoiceableTransportsPage) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      const since = payload && payload.since ? payload.since : '';
      const until = payload && payload.until ? payload.until : '';
      const page = payload && payload.page ? payload.page : '';
      const customers = payload && payload.customers ? payload.customers : null;
      let url = `/tms/crm/payment-notable-transports/invoice?page=${page}&query=${query}&since=${since}&until=${until}`;
      if (customers) {
        url = `${url}&customers[]=${customers}`;
      }
      return this.api.get({ path: url }).pipe(
        map(data => new TransportActions.LoadNextInvoiceableTransportsPageSuccess(data)),
        catchError(error => of(new TransportActions.LoadNextInvoiceableTransportsPageSuccess(error)))
      );
    }),
  );

  @Effect()
  loadPurchaseOrderableTransports$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.LOAD_PURCHASE_ORDERABLE_TRANSPORTS),
    map((action: TransportActions.LoadPurchaseOrderableTransports) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      const since = payload && payload.since ? payload.since : '';
      const until = payload && payload.until ? payload.until : '';
      const suppliers = payload && payload.suppliers ? payload.suppliers : null;
      let url = `/tms/crm/payment-notable-transports/purchase_order?query=${query}&since=${since}&until=${until}`;
      if (suppliers) {
        url = `${url}&suppliers[]=${suppliers}`;
      }
      return this.api.get({ path: url }).pipe(
        map(data => new TransportActions.LoadPurchaseOrderableTransportsSuccess(data)),
        catchError(error => of(new TransportActions.LoadPurchaseOrderableTransportsFail(error)))
      );
    }),
  );

  @Effect()
  loadNextPurchaseOrderableTransportsPage$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE),
    map((action: TransportActions.LoadNextPurchaseOrderableTransportsPage) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      const since = payload && payload.since ? payload.since : '';
      const until = payload && payload.until ? payload.until : '';
      const page = payload && payload.page ? payload.page : '';
      const suppliers = payload && payload.suppliers ? payload.suppliers : null;
      let url = `/tms/crm/payment-notable-transports/purchase_order?page=${page}&query=${query}&since=${since}&until=${until}`;
      if (suppliers) {
        url = `${url}&suppliers[]=${suppliers}`;
      }
      return this.api.get({ path: url }).pipe(
        map(data => new TransportActions.LoadNextPurchaseOrderableTransportsPageSuccess(data)),
        catchError(error => of(new TransportActions.LoadNextPurchaseOrderableTransportsPageSuccess(error)))
      );
    }),
  );

  @Effect()
  loadTransportsByDaterange$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.LOAD_TRANSPORTS_BY_DATERANGE),
    map((action: TransportActions.LoadTransportsByDaterange) => action.payload),
    concatMap(payload => {
      const fromDate = payload && payload.fromDate ? payload.fromDate : '';
      const toDate = payload && payload.toDate ? payload.toDate : '';
      const page = payload && payload.page ? payload.page : 1;
      const query = payload && payload.query ? payload.query : '';
      let url = `/transports?order[primaryTaskDate]=DESC&createdOnMarketplace=0&primaryTaskDate[after]=${fromDate}&primaryTaskDate[before]=${toDate}%2023%3A59%3A59&page=${page}`;
      if (query) {
        url = `${url}&customer.label=${query}&customerReference=${query}&container.number=${query}&reference=${query}`;
      }
      return this.api.get({ path: url }).pipe(
        map(data => new TransportActions.LoadTransportsSuccess(data)),
        catchError(error => of(new NotificationsActions.ErrorNotification(error)))
      );
    }),
  );

  @Effect()
  createTransportTasks$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.CREATE_TRANSPORT_TASK),
    map((action: TransportActions.CreateTransportTask) => action.payload),
    concatMap(payload => this.api.post({
      path: '/transport_tasks', body: payload
    }).pipe(
      map(data => new TransportActions.CreateTransportTaskSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  checkTransportTaskReference$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.CHECK_TRANSPORT_TASK_REFERENCE),
    map((action: TransportActions.CheckTransportTaskReference) => action.payload),
    concatMap(payload => this.api.get({
      path: '/transport_tasks/' + payload.id + '/check_reference'
    }).pipe(
      map(data => new TransportActions.CheckTransportTaskReferenceSuccess(data)),
      catchError(error => of(new TransportActions.CheckTransportTaskReferenceFail(error)))
    )),
  );

  @Effect()
  createTransport$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.CREATE_TRANSPORT),
    map((action: TransportActions.CreateTransport) => action.payload),
    concatMap(payload => this.api.post({
      path: '/transports', body: payload
    }).pipe(
      map(data => new TransportActions.CreateTransportSuccess(data)),
      catchError(error => of(new TransportActions.CreateTransportFail(error)))
    )),
  );

  @Effect()
  transitionTransportTaskForward$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.TRANSITION_TRANSPORT_TASK_FORWARD),
    map((action: TransportActions.TransitionTransportTaskForward) => action.payload),
    concatMap(payload => this.api.put({
      path: `/transport_tasks/${payload.transportTaskId}/transition/forward`
    }).pipe(
      map(data => new TransportActions.TransitionTransportTaskForwardSuccess(data)),
      catchError(error => of(new TransportActions.TransitionTransportTaskForwardFail(error)))
    )),
  );

  @Effect()
  transitionTransportTaskBack$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.TRANSITION_TRANSPORT_TASK_BACK),
    map((action: TransportActions.TransitionTransportTaskBack) => action.payload),
    concatMap(payload => this.api.put({
      path: `/transport_tasks/${payload.transportTaskId}/transition/back`
    }).pipe(
      map(data => new TransportActions.TransitionTransportTaskBackSuccess(data)),
      catchError(error => of(new TransportActions.TransitionTransportTaskBackFail(error)))
    )),
  );

  @Effect()
  updateTransport$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.UPDATE_TRANSPORT),
    map((action: TransportActions.UpdateTransport) => action.payload),
    concatMap(payload => this.api.put({
      path: '/transports/' + payload.id, body: payload.body
    }).pipe(
      map(data => new TransportActions.UpdateTransportSuccess(data)),
      catchError(error => of(new TransportActions.UpdateTransportFail(error)))
    )),
  );

  @Effect()
  deleteTransport$: Observable<Action> = this.actions$.pipe(
    ofType(types.transports.DELETE_TRANSPORT),
    map((action: TransportActions.DeleteTransport) => action.payload),
    concatMap(payload => this.api.delete({
      path: '/transports/' + payload.id
    }).pipe(
      map(data => new TransportActions.DeleteTransportSuccess(data)),
      catchError(error => of(new TransportActions.DeleteTransportFail(error)))
    )),
  );
}
