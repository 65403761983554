import { LocationTypes } from 'app/core/store/models/location-type.model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import {
  LoadAddressBookLocations,
  DeleteUserLocation
} from 'app/core/store/actions/location.actions';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Location } from 'app/core/store/models/location.model';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { extract } from 'app/services/i18n.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-user-locations',
  templateUrl: './manage-user-locations.component.html',
  styleUrls: ['./manage-user-locations.component.scss']
})
export class ManageUserLocationsComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: false })
  locationTable: DatatableComponent;

  title = extract('Manage Locations');
  privateLocations: Location[];
  totalPrivateLocations = 0;
  otherLocations: Location[];
  totalOtherLocations = 0;
  isLoadingLocations: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.store.dispatch(new LoadAddressBookLocations());
    this.isLoadingLocations = true;
    this.store
      .select(state => state.tms.locations)
      .pipe(untilDestroyed(this))
      .subscribe(locationsState => {
        if (locationsState && locationsState.addressBookLocations) {
          // Filter out user locations
          this.privateLocations = locationsState.addressBookLocations.filter(
            location => location.locationType.code === LocationTypes.USER
          );
          this.totalPrivateLocations = this.privateLocations.length;

          // Filter out other locations (hub, depot, terminal, ...)
          this.otherLocations = locationsState.addressBookLocations.filter(
            location => location.locationType.code !== LocationTypes.USER
          );
          this.totalOtherLocations = this.otherLocations.length;

          this.isLoadingLocations = false;
        }
      });
  }

  edit(locationId) {
    this.router.navigate(['settings/locations', locationId, 'edit']);
  }
  deleteLocation(locationId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this location?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteUserLocation({ locationId }));
    });
  }
  handleSearch(query) {
    this.isLoadingLocations = true;
    this.store.dispatch(new LoadAddressBookLocations({ query: query }));
  }

  ngOnDestroy() { }
}
