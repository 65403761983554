<div class="row"
  (click)="action($event)">
  <div class="col">
    <div *ngIf="title"
      [class]="options.titleClass"
      [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <!-- <div class="col-3 text-right">
    <a *ngIf="options.closeButton"
      (click)="remove()"
      class="btn btn-pink btn-sm">
      close
    </a>
  </div> -->
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress"
    [style.width]="width + '%'"></div>
</div>
