/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../tax-rate-form/tax-rate-form.component.ngfactory";
import * as i3 from "../tax-rate-form/tax-rate-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./new-tax-rate-modal.component";
import * as i6 from "../../services/toast.service";
import * as i7 from "@ngrx/store";
import * as i8 from "@ngrx/effects";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_NewTaxRateModalComponent = [];
var RenderType_NewTaxRateModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewTaxRateModalComponent, data: {} });
export { RenderType_NewTaxRateModalComponent as RenderType_NewTaxRateModalComponent };
export function View_NewTaxRateModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h3", [["class", "card-header p-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-tax-rate-form", [], null, [[null, "taxRateFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("taxRateFormSubmitted" === en)) {
        var pd_0 = (_co.createTaxRate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TaxRateFormComponent_0, i2.RenderType_TaxRateFormComponent)), i0.ɵdid(4, 573440, null, 0, i3.TaxRateFormComponent, [i4.FormBuilder], { isLoading: [0, "isLoading"] }, { taxRateFormSubmitted: "taxRateFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Add new tax rate")); _ck(_v, 1, 0, currVal_0); }); }
export function View_NewTaxRateModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-tax-rate-modal", [], null, null, null, View_NewTaxRateModalComponent_0, RenderType_NewTaxRateModalComponent)), i0.ɵdid(1, 180224, null, 0, i5.NewTaxRateModalComponent, [i6.ToastService, i7.Store, i8.Actions, i9.NgbActiveModal], null, null)], null, null); }
var NewTaxRateModalComponentNgFactory = i0.ɵccf("app-new-tax-rate-modal", i5.NewTaxRateModalComponent, View_NewTaxRateModalComponent_Host_0, {}, {}, []);
export { NewTaxRateModalComponentNgFactory as NewTaxRateModalComponentNgFactory };
