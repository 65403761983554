export const visits = {
  LOAD_VISITS: '[Visits] Load',
  LOAD_VISITS_SUCCEEDED: '[Visits] Load Visits Success',
  LOAD_VISITS_FAILED: '[Visits] Load Visits Fail',
  CREATE_VISIT: '[Visit] Create',
  CREATE_VISIT_SUCCEEDED: '[Visit] Create Visit Success',
  CREATE_VISIT_FAILED: '[Visit] Create Visits Fail',
  UPDATE_VISIT: '[Visit] Update',
  UPDATE_VISIT_SUCCEEDED: '[Visit] Update Visit Success',
  UPDATE_VISIT_FAILED: '[Visit] Update Visits Fail',
  COMPLETE_VISIT: '[Visit] Complete',
  COMPLETE_VISIT_SUCCEEDED: '[Visit] Complete Visit Success',
  COMPLETE_VISIT_FAILED: '[Visit] Complete Visits Fail',
  REVERT_COMPLETE_VISIT: '[Visit] Revert Complete',
  REVERT_COMPLETE_VISIT_SUCCEEDED: '[Visit] Revert Complete Visit Success',
  REVERT_COMPLETE_VISIT_FAILED: '[Visit] Revert Complete Visits Fail',
  UPDATE_VISIT_SEQUENCE: '[Visits] Update sequence',
  UPDATE_VISIT_SEQUENCE_SUCEEDED: '[Visits] Update sequence succeess',
  UPDATE_VISIT_SEQUENCE_FAILED: '[Visits] Update sequence fail',
  TRANSITION_VISIT_FORWARD: '[Visits] Transition visit forward',
  TRANSITION_VISIT_FORWARD_SUCCEEDED:
    '[Visits] Transition visit forward success',
  TRANSITION_VISIT_FORWARD_FAILED: '[Visits] Transition visit forward fail',
  TRANSITION_VISIT_BACK: '[Visits] Transition visit back',
  TRANSITION_VISIT_BACK_SUCCEEDED: '[Visits] Transition visit back success',
  TRANSITION_VISIT_BACK_FAILED: '[Visits] Transition visit back fail',
  CLEAR: '[Visits] Clear'
};
