/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./transactions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./credit-transactions/credit-transaction.component.ngfactory";
import * as i6 from "./credit-transactions/credit-transaction.component";
import * as i7 from "../../../api/api.service";
import * as i8 from "./debit-transactions/debit-transaction.component.ngfactory";
import * as i9 from "./debit-transactions/debit-transaction.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./transactions.component";
import * as i12 from "@ngrx/store";
import * as i13 from "@ngrx/effects";
import * as i14 from "../../../../services/toast.service";
var styles_TransactionsComponent = [i0.styles];
var RenderType_TransactionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TransactionsComponent, data: {} });
export { RenderType_TransactionsComponent as RenderType_TransactionsComponent };
function View_TransactionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.alert.type; _ck(_v, 1, 0, currVal_1); var currVal_2 = "fa"; var currVal_3 = _co.alert.icon; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_4 = _co.alert.message; _ck(_v, 6, 0, currVal_4); }); }
function View_TransactionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-credit-transaction", [], null, null, null, i5.View_CreditTransactionComponent_0, i5.RenderType_CreditTransactionComponent)), i1.ɵdid(1, 49152, null, 0, i6.CreditTransactionComponent, [i7.ApiService], { creditTransaction: [0, "creditTransaction"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TransactionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-debit-transaction", [], null, null, null, i8.View_DebitTransactionComponent_0, i8.RenderType_DebitTransactionComponent)), i1.ɵdid(1, 49152, null, 0, i9.DebitTransactionComponent, [], { debitTransaction: [0, "debitTransaction"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TransactionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["debitTransaction", 2]], null, 0, null, View_TransactionsComponent_4))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === "credit"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TransactionsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
function View_TransactionsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Loading transactions")); _ck(_v, 1, 0, currVal_0); }); }
function View_TransactionsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.nextPage ? "Load more" : i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("All transactions loaded"))); _ck(_v, 0, 0, currVal_0); }); }
export function View_TransactionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "card"], ["id", "transactions-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "card-body pre-scrollable"], ["id", "transaction-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsComponent_2)), i1.ɵdid(9, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "button", [["class", "btn btn-block btn-info"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loadMoreTransactions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsComponent_5)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionsComponent_6)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loaded", 2]], null, 0, null, View_TransactionsComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.transactions; _ck(_v, 9, 0, currVal_2); var currVal_4 = _co.loading; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.loading; var currVal_6 = i1.ɵnov(_v, 15); _ck(_v, 14, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("My Transactions")); _ck(_v, 4, 0, currVal_1); var currVal_3 = !_co.nextPage; _ck(_v, 10, 0, currVal_3); }); }
export function View_TransactionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-transactions", [], null, null, null, View_TransactionsComponent_0, RenderType_TransactionsComponent)), i1.ɵdid(1, 245760, null, 0, i11.TransactionsComponent, [i12.Store, i13.Actions, i14.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TransactionsComponentNgFactory = i1.ɵccf("app-cmp-transactions", i11.TransactionsComponent, View_TransactionsComponent_Host_0, {}, {}, []);
export { TransactionsComponentNgFactory as TransactionsComponentNgFactory };
