import {
  SubscriptionTypes,
  MatchingSubscriptionTypeActions
} from 'app/core/store/models/subscriptions.model';
import { LocationType } from 'app/core/store/models/location-type.model';
import { ToastService } from 'app/services/toast.service';
import { MarketpostParserService } from 'app/services/marketpost-parser.service';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { Liner } from 'app/core/store/models/liner.model';
import { ContainerType } from 'app/core/store/models/container-types.model';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import { CreateMarketPostDemand } from 'app/core/store/actions/market-post-demand.actions';
import { CreateMarketPostOffer } from 'app/core/store/actions/market-post-offer.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Transport, TransportDemand } from 'app/core/store/models/transports.model';
import { Component, OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/marketplace.types';
import * as NotificationTypes from 'app/core/store/types/notification.types';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { Router } from '@angular/router';
import { LoadLocationTypes } from 'app/core/store/actions/location.actions';
import { LoadSubscriptions } from 'app/core/store/actions/subscriptions.actions';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-marketpost',
  templateUrl: 'new-marketpost.component.html',
  styleUrls: ['new-marketpost.component.scss']
})
export class NewMarketpostComponent implements OnDestroy {
  title = extract('New Marketpost');
  shareType: string;
  circles = [];
  postType = 'offer';
  publishing = false;
  transportOffers: Transport[] = [];
  transportOffer: Transport;
  transportOfferIndex: number;
  createdTransportOffers = [];
  transportDemands = [];
  transportDemand: TransportDemand;
  transportDemandIndex: number;
  createdTransportDemands = [];
  transportTypes: TransportType[] = [];
  containerTypes: ContainerType[] = [];
  liners: Liner[] = [];
  loading = false;
  editPostIndex: number;
  locationTypes: LocationType[];
  hasActiveMatchingSubscription: boolean;
  isMatchingEnabled: boolean;
  selectedCircles = [];
  matchingCost: string;
  MatchingSubscriptionTypeActions = MatchingSubscriptionTypeActions;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private router: Router,
    private marketpostParserService: MarketpostParserService,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadLocationTypes());
    this.store.dispatch(new LoadContainerTypes());
    this.store.dispatch(new LoadLiners());
    this.store.dispatch(new LoadTransportTypes());
    this.store.dispatch(new LoadSubscriptions());
    this.store
      .select(state => state.subscriptions)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptions => {
        if (!subscriptions) {
          return;
        }
        const matchingSubscription = subscriptions.filter(
          subscription =>
            subscription.subscriptionType.code === SubscriptionTypes.MATCHING
        );
        if (matchingSubscription.length === 0) {
          return;
        }
        this.hasActiveMatchingSubscription = !!matchingSubscription[0];
        this.isMatchingEnabled = this.hasActiveMatchingSubscription;
        this.matchingCost = matchingSubscription[0].subscriptionType.subscriptionTypeActions.filter(
          sta =>
            sta.code ===
            this.MatchingSubscriptionTypeActions.MATCHING_MATCH_MARKET_POST
        )[0].credits;
      });
    this.store
      .select(state => state.tms.locations)
      .pipe(untilDestroyed(this))
      .subscribe(locationState => {
        if (locationState && locationState.locationTypes) {
          this.locationTypes = locationState.locationTypes;
        }
      });
    this.store
      .select(state => {
        if (state) {
          return state.marketplace.transportTypes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(transportTypes => {
        if (transportTypes && transportTypes.length !== 0) {
          this.transportTypes = transportTypes;
        }
      });
    this.store
      .select(state => {
        if (state) {
          return state.marketplace.liners;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (liners) {
          // Make a deep copy of Liners to prevent mutation
          const temp = JSON.parse(JSON.stringify(liners));
          this.liners = temp.map(liner => {
            if (liner.shortName) {
              liner.name = liner.name + ' (' + liner.shortName + ')';
            }
            return liner;
          });
        }
      });
    this.store
      .select(state => {
        if (state) {
          return state.containerTypes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(cTypes => {
        if (cTypes) {
          this.containerTypes = cTypes;
        }
      });

    updates$
      .pipe(ofType(
        Types.marketposts.CREATE_OFFER_SUCCEEDED,
        Types.marketposts.CREATE_DEMAND_SUCCEEDED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.transportDemands = [];
        this.transportOffers = [];
        this.createdTransportOffers = [];
        this.toastr.showSuccess({
          message: 'Marketpost Created!'
        });
        this.loading = false;
        this.router.navigate(['/marketplace']);
      });

    updates$
      .pipe(ofType(NotificationTypes.notifications.ERROR_NOTIFICATION))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
      });
    updates$
      .pipe(ofType(
        Types.marketposts.CREATE_OFFER_FAILED,
        Types.marketposts.CREATE_DEMAND_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message:
            'Failed to create marketpost. Reason: ' +
            response['payload'].statusText
        });
        this.loading = false;
      });
  }

  ngOnDestroy() { }

  transportOfferAdded(payload) {
    const transport = this.marketpostParserService.parseTransportOffer(
      payload.transport
    );
    if (payload.index !== undefined) {
      this.transportOffers[payload.index] = transport;
      this.transportOfferIndex = undefined;
      this.editPostIndex = null;
    } else {
      this.transportOffers.unshift(transport);
    }
  }

  transportDemandAdded(payload) {
    const transport = this.marketpostParserService.parseTransportDemand(
      payload.transport
    );
    if (payload.index !== undefined) {
      this.transportDemands[payload.index] = transport;
      this.transportDemandIndex = undefined;
      this.editPostIndex = null;
    } else {
      this.transportDemands.unshift(transport);
    }
  }
  editTransportDemand(event) {
    this.transportDemand = event.transport;
    this.transportDemandIndex = event.index;
    this.editPostIndex = event.index;
  }
  editTransportOffer(event) {
    this.transportOffer = event.transport;
    this.transportOfferIndex = event.index;
    this.editPostIndex = event.index;
  }

  publish() {
    this.publishing = true;
    this.postType === 'offer'
      ? this.publishMarketPostOffer()
      : this.publishMarketpostDemand();
  }

  publishMarketPostOffer() {
    const shareType =
      this.selectedCircles && this.selectedCircles.length === 0
        ? ''
        : this.shareType;
    const body = {
      marketPostOffers: this.transportOffers.map(transport => {
        return {
          matchingEnabled: this.isMatchingEnabled,
          transportOrigin: {
            createdOnMarketplace: true,
            container: transport.container,
            reference: transport.reference,
            transportType: transport.transportType,
            comment: transport.comment,
            genset: transport.genset,
            quantity: transport.quantity,
            transportTasks: transport.transportTasks
              .filter(task => task.location)
              .map(tTask => {
                if (tTask.location) {
                  return {
                    taskType: tTask.taskType['@id']
                      ? tTask.taskType['@id']
                      : tTask.taskType,
                    location: {
                      name: tTask.location.name || tTask.location,
                      latitude: tTask.location.latitude,
                      longitude: tTask.location.longitude
                    },
                    startDate: tTask.startDate,
                    startDateTimeSpecified: tTask.startDateTimeSpecified,
                    endDateTimeSpecified: tTask.endDateTimeSpecified,
                    endDate: tTask.endDate
                  };
                }
              })
          }
        };
      }),
      shareType: shareType,
      circles: this.selectedCircles
    };
    this.store.dispatch(new CreateMarketPostOffer(body));
    this.loading = true;
  }

  publishMarketpostDemand() {
    const shareType =
      this.selectedCircles && this.selectedCircles.length === 0
        ? ''
        : this.shareType;
    const body = {
      marketPostDemands: this.transportDemands.map(demand => {
        const d = {
          matchingEnabled: this.isMatchingEnabled,
          container: demand.container,
          transportType: demand.transportType,
          reference: demand.reference,
          quantity: demand.quantity,
          comment: demand.comment,
          originTimeIntervalStart: demand.originTimeIntervalStart,
          originTimeIntervalStartTimeSpecified:
            demand.originTimeIntervalStartTimeSpecified,
          originTimeIntervalEndTimeSpecified:
            demand.originTimeIntervalEndTimeSpecified,
          originTimeIntervalEnd: demand.originTimeIntervalEnd,
          destinationTimeIntervalStart: demand.destinationTimeIntervalStart,
          destinationTimeIntervalEnd: demand.destinationTimeIntervalEnd,
          destinationTimeIntervalStartTimeSpecified:
            demand.destinationTimeIntervalStartTimeSpecified,
          destinationTimeIntervalEndTimeSpecified:
            demand.destinationTimeIntervalEndTimeSpecified
        };
        if (demand.originLocation) {
          d['originLocation'] = demand.originLocation.name
            ? demand.originLocation
            : { name: demand.originLocation };
        }
        if (demand.destinationLocation) {
          d['destinationLocation'] = demand.destinationLocation.name
            ? demand.destinationLocation
            : { name: demand.destinationLocation };
        }
        return d;
      }),
      shareType: shareType,
      circles: this.selectedCircles
    };
    this.store.dispatch(new CreateMarketPostDemand(body));
    this.loading = true;
  }

  postTypeSelected($event) {
    this.postType = $event;
  }
}
