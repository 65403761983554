<div class="p-3">
  <app-cmp-user-context-form
    (userContextFormValue)="updateUserContext($event)"
    [userContext]="userContext"
    [buttonText]="'Save changes'"
    [driver]="driver"
    [loading]="isLoading"
  >
    &nbsp;
    <button
      *ngIf="userContext && userContext.enabled"
      class="btn btn-warning"
      (click)="disableUser()"
    >
      {{ 'Disable' | translate }}
    </button>
    <button
      *ngIf="userContext && !userContext.enabled"
      class="btn"
      (click)="enableUser()"
    >
      {{ 'Enable' | translate }}
    </button>
    <button class="btn btn-link text-danger" (click)="deleteUser()">
      {{ 'Delete' | translate }}
    </button>
  </app-cmp-user-context-form>
</div>
