import { ToastService } from 'app/services/toast.service';
import { ApiCall, ApiService } from './api.service';
import { Session } from '../store/models/session.model';
import { State } from '../store/store.model';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse,
  HttpSentEvent
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { Logout, RefreshSuccess } from '../store/actions/session.actions';
import { ErrorParserService } from 'app/services/error-parser.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private session: Session;
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  noAuthUrls = [
    'komoot',
    'reset',
    '/code'
    // 'oauth/v2/token',
  ];

  constructor(
    private store: Store<State>,
    private toastr: ToastService,
    private errorParser: ErrorParserService,
    private injector: Injector
  ) {
    this.store.select('session').subscribe(stateSession => {
      this.session = stateSession;
    });
  }

  get isRefreshing(): boolean {
    return this.isRefreshingToken;
  }

  set isRefreshing(isRefreshing: boolean) {
    this.isRefreshingToken = isRefreshing;
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    // Headers that are set on GET requests
    const getHeaders: any = {};

    // Headers that are set on POST requests
    const postHeaders: any = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const putHeaders: any = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    if (request.headers && request.headers.keys().length === 0) {
      switch (request.method) {
        case 'GET':
          request = request.clone({ setHeaders: getHeaders });
          break;
        case 'POST':
          request = request.clone({ setHeaders: postHeaders });
          break;
        case 'PUT':
          request = request.clone({ setHeaders: putHeaders });
          break;
        case 'DELETE':
          request = request.clone({ setHeaders: getHeaders });
          break;
        default:
          request = request.clone({ setHeaders: getHeaders });
          break;
      }
    }

    return next
      .handle(
        this.setAuthHeaders(
          request,
          this.session ? this.session.access_token : null
        )
      )
      .pipe(catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              return this.handle401Error(request, next);
            case 400:
              if (
                error &&
                error.error &&
                error.error['error_description'] &&
                error.error['error_description'] === 'Invalid refresh token'
              ) {
                this.store.dispatch(new Logout());
              }
              const message = this.errorParser.getHumanReadableErrorMessage(
                error
              );
              if (message) {
                if (error && error.error && error.error.type === 'warning') {
                  this.toastr.showWarning({
                    message: message,
                    title: 'Warning'
                  });
                } else {
                  this.toastr.showDanger({
                    message: message,
                    title: 'Something went wrong'
                  });
                }
              }
              return throwError(error);
            case 403:
              return throwError('');
            default:
              return throwError(error);
          }
        } else {
          return throwError(error);
        }
      }));
  }

  // See: https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/
  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    const sesch = localStorage.getItem('session');
    if (this.session && sesch) {
      if (!this.isRefreshing) {
        this.isRefreshingToken = true;

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.tokenSubject.next(null);
        const body = new URLSearchParams();
        body.append('refresh_token', this.session.refresh_token);
        const options: ApiCall = {
          body: body
        };
        return this.injector
          .get(ApiService)
          .refreshToken(options)
          .pipe(
            switchMap(authObj => {
              if (authObj['access_token']) {
                const newToken = authObj['access_token'];
                this.tokenSubject.next(newToken);
                this.isRefreshing = false;
                localStorage.removeItem('session');
                localStorage.setItem('session', JSON.stringify(authObj));
                this.store.dispatch(new RefreshSuccess(authObj));
                return next.handle(this.setAuthHeaders(req, newToken));
              }
            }),
            catchError((err) => {
              this.tokenSubject.next('cancel');
              this.isRefreshing = false;
              this.store.dispatch(new Logout());
              localStorage.removeItem('session');
              this.toastr.showDanger({
                title: 'Something went wrong',
                message:
                  err.error['hydra:description'] ||
                  err.error['detail'] ||
                  err.error.error_description ||
                  err.message
              });
              return of(err);
            })
          );
      } else {
        return this.tokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            if (token === 'cancel') {
              return;
            }
            return next.handle(this.setAuthHeaders(req, token));
          })
        );
      }
    } else {
      return of(false);
    }
  }

  setAuthHeaders(request: HttpRequest<any>, token): HttpRequest<any> {
    // Check if request url need authentication headers
    if (
      !this.noAuthUrls.some(url => request.url.indexOf(url) !== -1) &&
      !request.headers.has('Authorization')
    ) {
      request = request.clone({
        headers: request.headers.append('Authorization', 'Bearer ' + token)
      });
      if (
        !request.headers.has('X-tenant-id') &&
        this.session &&
        this.session.tenant
      ) {
        request = request.clone({
          headers: request.headers.append('X-tenant-id', this.session.tenant)
        });
      }
    }
    return request;
  }
}
