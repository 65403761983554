import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { extract } from './i18n.service';

@Injectable()
export class ErrorParserService {
  constructor() {}

  getErrorMessage(errorResponse: HttpErrorResponse) {
    const message =
      errorResponse.error['hydra:description'] ||
      errorResponse.error['detail'] ||
      errorResponse.error.error_description ||
      errorResponse.error ||
      errorResponse.message;
    return message;
  }

  getHumanReadableErrorMessage(errorResponse: HttpErrorResponse): string {
    const message = this.getErrorMessage(errorResponse);
    switch (message) {
      case 'Insufficient funds!':
        return extract('You have insufficient credits.');
      case 'No enabled user contexts for this user.':
      case 'Invalid username and password combination':
        return extract('Invalid username and password combination.');
      case 'user.email: User already exists.':
        return extract('This email is already in use.');
      case 'not a valid mobile phone number':
        return extract('The mobile phone number that was given is invalid.');
      case 'Container number does not match reference.':
        return extract('Container number does not match reference.');
      case 'All transport tasks must have a reference.':
        return extract('All transport tasks must have a reference.');
      case 'This transport has already been invoiced so can not delete this.':
        return extract(
          'This transport has already been invoiced so can not delete this.'
        );
      case 'All containers are already prenotified':
        return extract('All containers are already prenotified');
      case 'Container already prenotified':
        return extract('Container already prenotified');
      case 'Container already in depot':
        return extract('Container already in depot');
      case 'Container already reserved':
        return extract('Container already reserved');
      case 'All containers are already processed':
        return extract('All containers are already processed');
      case 'All containers are processed':
        return extract('All containers are processed');
      case 'All containers container types are already prenotified':
        return extract(
          'All containers container types are already prenotified'
        );
      case 'All containers container types are already processed':
        return extract('All containers container types are already processed');
      case 'Container number turns out to be invalid after checking digit':
        return extract(
          'Container number turns out to be invalid after checking digit'
        );
      case 'Empty parameters are not allowed':
        return extract('Empty parameters are not allowed');
      case 'items: inuits.crm.validation.payment_note.items.not_empty':
        return extract('An invoice needs at least one item');
      case 'Insufficient credits':
        return extract(
          'There are too few credits to start a subscription. You have to purchase credits first before starting a subscription.'
        );
      case 'Invalid length':
        return extract('Invalid length');
      case 'Only reserved containers':
        return extract('Only reserved containers');
      case 'Redelivery too soon':
        return extract('Redelivery too soon');
      case 'Reference cancelled':
        return extract('Reference cancelled');
      case 'Reference does not exist':
        return extract('Reference does not exist');
      case 'Same reference':
        return extract('Same reference');
      case 'Too many container requests':
        return extract('Too many container requests');
      case 'Wrong container type':
        return extract('Wrong container type');
      case 'Wrong reference for this container':
        return extract('Wrong reference for this container');
      case 'Container number does not match reference':
        return extract('Container number does not match reference');
      case 'Container type does not match reference':
        return extract('Container type does not match reference');
      case 'Invalid reference format':
        return extract('Invalid reference format');
      case 'No container ISO codes were found':
        return extract('No container ISO codes were found');
      case 'Can not use same container number more than once':
        return extract('Can not use same container number more than once');
      case 'Reference key cancelled':
        return extract('Reference key cancelled');
      case 'Reference key not valid':
        return extract('Reference key not valid');
      case 'Specify container number please':
        return extract('Specify container number please');
      case 'Specify container type please':
        return extract('Specify container type please');
      case 'Specify quantity please':
        return extract('Specify quantity please');
      case 'Specify reference please':
        return extract('Specify reference please');
      case 'Specify the depot please':
        return extract('Specify the depot please');
      case 'The full amount for reservations is used':
        return extract('The full amount for reservations is used');
      case 'api_outage':
        return extract('reload.api.message.api_outage');
      case 'unknown_container_number':
        return extract('reload.api.message.unknown_container_number');
      case 'request_cannot_be_cancelled':
        return extract('reload.api.message.request_cannot_be_cancelled');
      case 'cancelling_request_failed':
        return extract('reload.api.message.cancelling_request_failed');
      case 'distance_calculation_failed':
        return extract('reload.api.message.distance_calculation_failed');
      case 'Too much containers loaded':
        return extract('Too much containers loaded');
      case 'WAR container number is empty':
        return extract('WAR container number is empty');
      case 'WAR not MSC':
        return extract('WAR not MSC');
      case 'A driver with active future visits or tasks cannot be deleted.':
        return extract(
          'A driver with active future visits or tasks cannot be deleted.'
        );
      default:
        return extract('Request failed');
    }
  }
}
