import { matching } from '../types/matching.types';
import { Action } from '@ngrx/store';

export class LoadMatches implements Action {
  readonly type = matching.LOAD_MATCHES;
  constructor(
    public payload?: {
      page?: string;
    }
  ) { }
}

export class LoadMatchesSuccess implements Action {
  readonly type = matching.LOAD_MATCHES_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadMatchesFail implements Action {
  readonly type = matching.LOAD_MATCHES_FAILED;
  constructor(public payload?: any) { }
}

export class CreateMatchReply implements Action {
  readonly type = matching.CREATE_MATCH_REPLY;
  constructor(
    public payload: {
      matchingPoolItemMatch: string;
      text: string;
    }
  ) { }
}

export class CreateMatchReplySuccess implements Action {
  readonly type = matching.CREATE_MATCH_REPLY_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class CreateMatchReplyFail implements Action {
  readonly type = matching.CREATE_MATCH_REPLY_FAILED;
  constructor(public payload?: any) { }
}

export class LoadActiveMatchingPoolItems implements Action {
  readonly type = matching.LOAD_ACTIVE_MATCHING_POOL_ITEMS;
  constructor(public payload?: any) { }
}

export class LoadActiveMatchingPoolItemsSuccess implements Action {
  readonly type = matching.LOAD_ACTIVE_MATCHING_POOL_ITEMS_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadActiveMatchingPoolItemsFail implements Action {
  readonly type = matching.LOAD_ACTIVE_MATCHING_POOL_ITEMS_FAILED;
  constructor(public payload?: any) { }
}

export class LoadNextActiveMatchingPoolItemsPage implements Action {
  readonly type = matching.LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE;
  constructor(public payload?: any) { }
}

export class LoadNextActiveMatchingPoolItemsPageSuccess implements Action {
  readonly type = matching.LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadNextActiveMatchingPoolItemsPageFail implements Action {
  readonly type = matching.LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE_FAILED;
  constructor(public payload?: any) { }
}

export class DeleteMatchingPoolOption implements Action {
  readonly type = matching.DELETE_MATCHING_POOL_OPTION;
  constructor(
    public payload?: {
      matchOptionId: string;
      matchId?: string;
    }
  ) { }
}

export class DeleteMatchingPoolOptionSuccess implements Action {
  readonly type = matching.DELETE_MATCHING_POOL_OPTION_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class DeleteMatchingPoolOptionFail implements Action {
  readonly type = matching.DELETE_MATCHING_POOL_OPTION_FAILED;
  constructor(public payload?: any) { }
}

export class EnableMatching implements Action {
  readonly type = matching.ENABLE_MATCHING;
  constructor(
    public payload: {
      type: string;
      id: string;
    }
  ) { }
}

export class EnableMatchinFail implements Action {
  readonly type = matching.ENABLE_MATCHING_FAILED;
  constructor(public payload?: any) { }
}

export class EnableMatchinSuccess implements Action {
  readonly type = matching.ENABLE_MATCHING_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class DisableMatching implements Action {
  readonly type = matching.DISABLE_MATCHING;
  constructor(
    public payload: {
      type: string;
      id: string;
    }
  ) { }
}

export class DisableMatchinFail implements Action {
  readonly type = matching.DISABLE_MATCHING_FAILED;
  constructor(public payload?: any) { }
}

export class DisableMatchinSuccess implements Action {
  readonly type = matching.DISABLE_MATCHING_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class ClearMatching implements Action {
  readonly type = matching.CLEAR;
  constructor(public payload?: any) { }
}

export type All =
  | LoadMatches
  | LoadMatchesSuccess
  | LoadMatchesFail
  | CreateMatchReply
  | CreateMatchReplySuccess
  | CreateMatchReplyFail
  | LoadActiveMatchingPoolItems
  | LoadActiveMatchingPoolItemsSuccess
  | LoadActiveMatchingPoolItemsFail
  | LoadNextActiveMatchingPoolItemsPage
  | LoadNextActiveMatchingPoolItemsPageSuccess
  | LoadNextActiveMatchingPoolItemsPageFail
  | DeleteMatchingPoolOption
  | DeleteMatchingPoolOptionSuccess
  | DeleteMatchingPoolOptionFail
  | EnableMatching
  | EnableMatchinFail
  | EnableMatchinSuccess
  | DisableMatching
  | DisableMatchinFail
  | DisableMatchinSuccess
  | ClearMatching;
