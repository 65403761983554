<div class="edit-user-location-grid">
  <app-user-location-form
    [isLoading]="isLoading"
    [userLocation]="location"
    (userLocationFormSubmitted)="updateUserLocation($event)"
    (addressChanged)="updateMarkerByAddress($event)"
    [coordinates]="coordinates"
  >
    <div class="map mb-3" id="user-location-map"></div>
  </app-user-location-form>
</div>
