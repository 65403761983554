import { ApiService } from '../core/api/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class Autocomplete {
  constructor(private apiClient: ApiService) { }

  public search(
    type: string,
    query: string,
    options?: {
      include_internal_locations?: boolean;
      cities_only?: boolean;
      depots_only?: boolean;
    }
  ) {
    let path = `/autocomplete/${type}?q=${query}`;
    if (type !== 'member') {
      if (options && options.include_internal_locations) {
        path += `&include_internal_locations=1`;
      }
      if (options && options.cities_only) {
        path += `&cities_only=1`;
      }
      if (options && options.depots_only) {
        path += `&depots_only=1`;
      }
    }
    return this.apiClient.get({ path: path })
      .pipe(map((response: Response) => <any>response));
  }
}
