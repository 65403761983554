import { Action } from '@ngrx/store';
import * as types from '../types/driver.types';

export class LoadDrivers implements Action {
  readonly type = types.drivers.LOAD_DRIVERS;
  constructor(
    public payload: {
      includeSuperAdmins: number;
      roleName: string;
      page?: number | string;
    }
  ) { }
}

export class LoadDriversSuccess implements Action {
  readonly type = types.drivers.LOAD_DRIVERS_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadDriversFail implements Action {
  readonly type = types.drivers.LOAD_DRIVERS_FAILED;
  constructor(public payload: any) { }
}

export class CreateDriver implements Action {
  readonly type = types.drivers.CREATE_DRIVER;
  constructor(public payload: any) { }
}

export class CreateDriverSuccess implements Action {
  readonly type = types.drivers.CREATE_DRIVER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateDriverFail implements Action {
  readonly type = types.drivers.CREATE_DRIVER_FAILED;
  constructor(public payload: any) { }
}

export class UpdateDriver implements Action {
  readonly type = types.drivers.UPDATE_DRIVER;
  constructor(
    public payload: {
      driverId: string;
      driver: any;
    }
  ) { }
}

export class UpdateDriverSuccess implements Action {
  readonly type = types.drivers.UPDATE_DRIVER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdateDriverFail implements Action {
  readonly type = types.drivers.UPDATE_DRIVER_FAILED;
  constructor(public payload: any) { }
}

export class EnableDriver implements Action {
  readonly type = types.drivers.ENABLE_DRIVER;
  constructor(public payload: any) { }
}

export class EnableDriverSuccess implements Action {
  readonly type = types.drivers.ENABLE_DRIVER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class EnableDriverFail implements Action {
  readonly type = types.drivers.ENABLE_DRIVER_FAILED;
  constructor(public payload: any) { }
}

export class DisableDriver implements Action {
  readonly type = types.drivers.DISABLE_DRIVER;
  constructor(public payload: any) { }
}

export class DisableDriverSuccess implements Action {
  readonly type = types.drivers.DISABLE_DRIVER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class DisableDriverFail implements Action {
  readonly type = types.drivers.DISABLE_DRIVER_FAILED;
  constructor(public payload: any) { }
}

export class DeleteDriver implements Action {
  readonly type = types.drivers.DELETE_DRIVER;
  constructor(public payload: any) { }
}

export class DeleteDriverSuccess implements Action {
  readonly type = types.drivers.DELETE_DRIVER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class DeleteDriverFail implements Action {
  readonly type = types.drivers.DELETE_DRIVER_FAILED;
  constructor(public payload: any) { }
}

export class ClearDrivers implements Action {
  readonly type = types.drivers.CLEAR_DRIVERS;
  constructor(public payload?: any) { }
}

export class UnclearDrivers implements Action {
  readonly type = types.drivers.UNCLEAR_DRIVERS;
  constructor(public payload?: any) { }
}

export type All =
  | LoadDrivers
  | LoadDrivers
  | LoadDriversSuccess
  | LoadDriversFail
  | CreateDriver
  | CreateDriverSuccess
  | CreateDriverFail
  | UpdateDriver
  | UpdateDriverSuccess
  | UpdateDriverFail
  | EnableDriver
  | EnableDriverSuccess
  | EnableDriverFail
  | DisableDriver
  | DisableDriverSuccess
  | DisableDriverFail
  | DeleteDriver
  | DeleteDriverSuccess
  | DeleteDriverFail
  | ClearDrivers
  | UnclearDrivers;
