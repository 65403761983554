import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { I18nService, extract } from 'app/services/i18n.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Types from '../../store/types/user-context.types';
import { State } from '../../store/store.model';
import { UserContext } from '../../store/models/user-contexts.model';
import { UpdateUserContext, LoadUserContext } from '../../store/actions/user-contexts.actions';
import { ToastService } from 'app/services/toast.service';
import { LoadUser } from '../../store/actions/user.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-user-info',
  templateUrl: './user-info-form.component.html',
})
export class UserInfoFormComponent implements OnChanges, OnInit, OnDestroy {
  title = extract('Account Information');
  userInfoForm: FormGroup;
  userC: UserContext;
  prefLanguage;
  isLoading = false;
  languages;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private userUpdates$: Actions,
    private translateService: I18nService,
    private toastr: ToastService
  ) {
    this.store
      .select(state => state.user)
      .pipe(untilDestroyed(this))
      .subscribe(userC => {
        if (userC.id) {
          this.userC = userC;
          this.createForm();
        }
      });
    userUpdates$
      .pipe(ofType(Types.userContexts.UPDATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadUserContext({ id: this.userC.id }));
        this.isLoading = false;
        this.toastr.showSuccess({
          message: 'Success!'
        });
      });
    this.languages = this.translateService.languages;
  }

  ngOnInit() {
    this.store.dispatch(new LoadUser());
  }

  ngOnDestroy() { }

  // Watches for changes so that the form gets patched on refresh
  ngOnChanges() {
    if (this.userInfoForm) {
      this.userInfoForm.patchValue({
        firstName: this.userC.user.firstName,
        lastName: this.userC.user.lastName,
        email: this.userC.user.email,
        phoneNumber: this.userC.phoneNumber,
        locale: this.userC.locale
      });
    }
  }

  createForm() {
    this.userInfoForm = this.fb.group({
      firstName: [this.userC.user.firstName],
      lastName: [this.userC.user.lastName],
      email: [this.userC.user.email],
      phoneNumber: [this.userC.phoneNumber || null],
      locale: [this.userC.locale]
    });
  }

  onSubmit() {
    this.isLoading = true;
    const body = {
      roles: this.userC.roles.map(r => r['@id']),
      phoneNumber: this.userInfoForm.get('phoneNumber').value,
      locale: this.userInfoForm.get('locale').value,
      user: {
        firstName: this.userInfoForm.get('firstName').value,
        lastName: this.userInfoForm.get('lastName').value,
        email: this.userInfoForm.get('email').value,
        id: this.userC.user.id
      }
    };
    this.store.dispatch(
      new UpdateUserContext({ id: this.userC.id, body: body })
    );
  }

  changeLanguage(selectedLanguage) {
    this.translateService.language = selectedLanguage;
  }
}
