/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card-spinner.component";
var styles_CardSpinnerComponent = [i0.styles];
var RenderType_CardSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardSpinnerComponent, data: {} });
export { RenderType_CardSpinnerComponent as RenderType_CardSpinnerComponent };
export function View_CardSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
export function View_CardSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-spinner", [], null, null, null, View_CardSpinnerComponent_0, RenderType_CardSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i2.CardSpinnerComponent, [], null, null)], null, null); }
var CardSpinnerComponentNgFactory = i1.ɵccf("app-card-spinner", i2.CardSpinnerComponent, View_CardSpinnerComponent_Host_0, {}, {}, []);
export { CardSpinnerComponentNgFactory as CardSpinnerComponentNgFactory };
