<form (ngSubmit)="onSubmit()" [formGroup]="shippingAgentForm" class="pb-3">
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Label' | translate }}</label>
        <input
          class="form-control"
          formControlName="name"
          [ngClass]="{
            'is-invalid':
              shippingAgentForm.controls['name'].invalid &&
              shippingAgentForm.controls['name'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Label is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">
      {{ 'Tenants' | translate }}</label
    >
    <ng-select
      id="tenants"
      [items]="tenants"
      bindLabel="name"
      placeholder="{{ 'Select Tenants' | translate }}"
      data-cy="tenants"
      formControlName="tenants"
      [multiple]="true"
      [closeOnSelect]="false"
    >
    </ng-select>
  </div>
  <label class="center-block">{{ 'Branches' | translate }}</label>
  <div
    formArrayName="branches"
    class="mb-3"
    *ngFor="
      let branch of shippingAgentForm.get('branches')['controls'];
      let i = index
    "
  >
    <div [formGroupName]="i" class="form-row">
      <div class="col-6">
        <ng-select
          id="liner"
          [items]="liners"
          bindLabel="name"
          data-cy="liner"
          placeholder="{{ 'Select Liner' | translate }}"
          formControlName="liner"
        >
        </ng-select>
      </div>
      <div class="col-5">
        <ng-select
          id="country"
          [items]="countries"
          bindLabel="label"
          bindValue="value"
          data-cy="countries"
          placeholder="{{ 'Select country' | translate }}"
          formControlName="country"
        >
        </ng-select>
      </div>
      <div class="col-1">
        <button
          class="btn btn-danger text-white"
          [disabled]="i === 0"
          type="button"
          (click)="removeBranch(i)"
        >
          <i class="far fa-times"></i>
        </button>
        <button
          class="btn btn-primary ml-1"
          *ngIf="i === shippingAgentForm.get('branches')['controls'].length - 1"
          type="button"
          (click)="addBranch()"
        >
          <i class="far fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <button
    class="btn btn-primary text-white"
    type="submit"
    [disabled]="shippingAgentForm.invalid || shippingAgentForm.pristine"
  >
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span>
      {{ 'Submit' | translate }}
    </span>
  </button>
</form>
