var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/supplier.types';
import * as SupplierActions from '../actions/supplier.actions';
import { ApiService } from '../../api/api.service';
var SupplierEffects = /** @class */ (function () {
    function SupplierEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadSuppliers$ = this.actions$.pipe(ofType(types.supplier.LOAD_SUPPLIERS), concatMap(function () { return _this.api.get({
            path: "/crm/suppliers"
        }).pipe(map(function (data) { return new SupplierActions.LoadSuppliersSuccess(data); }), catchError(function (error) { return of(new SupplierActions.LoadSuppliersFail(error)); })); }));
        this.loadFilteredSuppliers$ = this.actions$.pipe(ofType(types.supplier.LOAD_FILTERED_SUPPLIERS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            return _this.api.get({
                path: "/crm/suppliers?label=" + query + "&vatNumber=" + query
            }).pipe(map(function (data) { return new SupplierActions.LoadFilteredSuppliersSuccess(data); }), catchError(function (error) { return of(new SupplierActions.LoadFilteredSuppliersFail(error)); }));
        }));
        this.createSupplier$ = this.actions$.pipe(ofType(types.supplier.CREATE_SUPPLIER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/crm/suppliers", body: payload
        }).pipe(map(function (data) { return new SupplierActions.CreateSupplierSuccess(data); }), catchError(function (error) { return of(new SupplierActions.CreateSupplierFail(error)); })); }));
        this.deleteSupplier$ = this.actions$.pipe(ofType(types.supplier.DELETE_SUPPLIER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/suppliers/" + payload.supplierId
        }).pipe(map(function () { return new SupplierActions.DeleteSupplierSuccess(payload.supplierId); }), catchError(function (error) { return of(new SupplierActions.DeleteSupplierFail(error)); })); }));
        this.updateSupplier$ = this.actions$.pipe(ofType(types.supplier.UPDATE_SUPPLIER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/suppliers/" + payload.supplierId,
            body: payload.supplier
        }).pipe(map(function (data) { return new SupplierActions.UpdateSupplierSuccess(data); }), catchError(function (error) { return of(new SupplierActions.UpdateSupplierFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SupplierEffects.prototype, "loadSuppliers$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SupplierEffects.prototype, "loadFilteredSuppliers$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SupplierEffects.prototype, "createSupplier$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SupplierEffects.prototype, "deleteSupplier$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SupplierEffects.prototype, "updateSupplier$", void 0);
    return SupplierEffects;
}());
export { SupplierEffects };
