import { ContainerType } from 'app/core/store/models/container-types.model';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  DisableContainerTypeNotificationSubscription,
  EnableContainerTypeNotificationSubscription
} from 'app/core/store/actions/notification-subscriptions.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-container-type-notification-subscriptions',
  templateUrl: 'container-type-notification-subscriptions.component.html',
})
export class ContainerTypeNotificationSubscriptionsComponent implements OnDestroy {
  @Input() subscribedOffers: String[] = [];
  @Input() subscribedDemands: String[] = [];
  @Output() subscriptionChanged = new EventEmitter<any>();
  containerTypes$: Observable<ContainerType[]>;
  loading = true;
  constructor(private store: Store<State>) {
    this.containerTypes$ = this.store
      .select(state => state.containerTypes)
      .pipe(untilDestroyed(this));
    this.containerTypes$.subscribe(ct => {
      if (ct) {
        this.loading = false;
      }
    });
  }

  toggleOffer(containerType): void {
    const sub = this.subscribedOffers.filter(
      subscription =>
        subscription['containerType']['@id'] === containerType['@id']
    );
    if (sub.length === 0) {
      this.store.dispatch(
        new EnableContainerTypeNotificationSubscription({
          body: {
            type: 'offer',
            containerType: containerType['@id']
          }
        })
      );
    } else {
      this.store.dispatch(
        new DisableContainerTypeNotificationSubscription({
          id: sub[0]['id']
        })
      );
    }
  }

  toggleDemand(containerType): void {
    const sub = this.subscribedDemands.filter(
      subscription =>
        subscription['containerType']['@id'] === containerType['@id']
    );
    if (sub.length === 0) {
      this.store.dispatch(
        new EnableContainerTypeNotificationSubscription({
          body: {
            type: 'demand',
            containerType: containerType['@id']
          }
        })
      );
    } else {
      this.store.dispatch(
        new DisableContainerTypeNotificationSubscription({
          id: sub[0]['id']
        })
      );
    }
  }
  getSubscriptionStatus(cType, postType): boolean {
    let index = false;
    if (postType === 'demand') {
      index =
        this.subscribedDemands.filter(
          sub => sub['containerType']['@id'] === cType['@id']
        ).length > 0;
    } else if (postType === 'offer') {
      index =
        this.subscribedOffers.filter(
          sub => sub['containerType']['@id'] === cType['@id']
        ).length > 0;
    }
    return index;
  }

  ngOnDestroy() { }
}
