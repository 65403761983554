import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { ApiService } from '../../api/api.service';

import * as DriverTypes from '../types/driver.types';
import * as DriversActions from '../actions/driver.actions';
import { map, concatMap, catchError } from 'rxjs/operators';

@Injectable()
export class DriversEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadDrivers$: Observable<Action> = this.actions$.pipe(
    ofType(DriverTypes.drivers.LOAD_DRIVERS),
    map((action: DriversActions.LoadDrivers) => action.payload),
    concatMap(payload => this.api.get({
      path: `/user_contexts?user.superAdmin=${
        payload.includeSuperAdmins
        }&roles.name=${payload.roleName}&page=${
        payload && payload.page ? payload.page : 1
        }`
    }).pipe(
      map(data => new DriversActions.LoadDriversSuccess(data)),
      catchError(error => of(new DriversActions.LoadDriversFail(error)))
    ))
  );

  @Effect()
  createDriver$: Observable<Action> = this.actions$.pipe(
    ofType(DriverTypes.drivers.CREATE_DRIVER),
    map((action: DriversActions.CreateDriver) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user_contexts_with_role', body: payload
    }).pipe(
      map(data => new DriversActions.CreateDriverSuccess(data)),
      catchError(error => of(new DriversActions.CreateDriverFail(error)))
    ))
  );

  @Effect()
  updateDriver$: Observable<Action> = this.actions$.pipe(
    ofType(DriverTypes.drivers.UPDATE_DRIVER),
    map((action: DriversActions.UpdateDriver) => action.payload),
    concatMap(payload => this.api.put({
      path: '/user_contexts/' + payload.driverId,
      body: payload.driver
    }).pipe(
      map(data => new DriversActions.UpdateDriverSuccess(data)),
      catchError(error => of(new DriversActions.UpdateDriverFail(error)))
    ))
  );

  @Effect()
  enableDriver$: Observable<Action> = this.actions$.pipe(
    ofType(DriverTypes.drivers.ENABLE_DRIVER),
    map((action: DriversActions.EnableDriver) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user_contexts/' + payload + '/enable', body: {}
    }).pipe(
      map(() => new DriversActions.EnableDriverSuccess(payload)),
      catchError(error => of(new DriversActions.EnableDriverFail(error)))
    ))
  );

  @Effect()
  disableDriver$: Observable<Action> = this.actions$.pipe(
    ofType(DriverTypes.drivers.DISABLE_DRIVER),
    map((action: DriversActions.DisableDriver) => action.payload),
    concatMap(payload => this.api.post({
      path: '/user_contexts/' + payload + '/disable', body: {}
    }).pipe(
      map(() => new DriversActions.DisableDriverSuccess(payload)),
      catchError(error => of(new DriversActions.DisableDriverFail(error)))
    ))
  );

  @Effect()
  deleteDriver$: Observable<Action> = this.actions$.pipe(
    ofType(DriverTypes.drivers.DELETE_DRIVER),
    map((action: DriversActions.DeleteDriver) => action.payload),
    concatMap(payload => this.api.put({
      path: '/user_contexts_with_role/DRIVER/' + payload,
      body: {}
    }).pipe(
      map(() => new DriversActions.DeleteDriverSuccess(payload)),
      catchError(error => of(new DriversActions.DeleteDriverFail(error)))
    ))
  );
}
