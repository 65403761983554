<ngb-alert *ngIf="alert" [type]="alert.type" (close)="closeAlert()">
  <i class="far" [ngClass]="alert.icon"></i>
  <span>{{ alert.message | translate }}</span>
</ngb-alert>
<!-- Market Post offer -->
<app-cmp-new-marketpost-offer
  *ngIf="type === 'offers'"
  (transportOfferAdded)="transportOfferAdded($event)"
  [title]="'Edit your transport' | translate"
  [transportOffer]="post"
  [index]="0"
  [transportTypes]="transportTypes"
  [liners]="liners"
  [containerTypes]="containerTypes"
>
</app-cmp-new-marketpost-offer>
<!-- Market Post Demand -->
<app-cmp-new-marketpost-demand
  *ngIf="type === 'demands'"
  (transportDemandAdded)="transportDemandAdded($event)"
  [title]="'Edit your tranport' | translate"
  [transportDemand]="post"
  [index]="0"
  [transportTypes]="transportTypes"
  [liners]="liners"
  [containerTypes]="containerTypes"
>
</app-cmp-new-marketpost-demand>
