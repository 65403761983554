<div class="search-container mb-3 ml-3 mr-3">
  <app-search
    [placeholder]="searchPlaceholder"
    (search)="handleSearch($event)"
  ></app-search>
  <button class="btn" routerLink="/crm/invoices/new" id="new-invoice-button">
    <i class="far fa-plus fa-fw"></i>
    <span>{{ 'New invoice' | translate }}</span>
  </button>
</div>
<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [rows]="filteredInvoices"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  (page)="setPage($event)"
  [count]="totalElements"
  [limit]="30"
  [messages]="tableMessages"
  [loadingIndicator]="isLoadingInvoices"
>
  <div>
    <ngx-datatable-column name="{{ 'Invoice #' | translate }}" prop="number">
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'Customer' | translate }}"
      prop="business.label"
    >
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'State' | translate }}" prop="state">
      <ng-template
        ngx-datatable-cell-template
        let-row="row"
        let-rowIndex="rowIndex"
        let-value="value"
      >
        <div ngbDropdown [container]="'body'" kclass="d-inline-block">
          <button class="btn" id="stateDropdown" ngbDropdownToggle>
            <span
              class="btn__label"
              [ngClass]="{ invisible: isLoading && actionRow === rowIndex }"
            >
              <i class="far fa-fw" [ngClass]="getInvoiceStateIcon(value)"></i>
              {{ value | translate }}</span
            >
            <i
              class="fa fa-spinner fa-spin btn__loader"
              [ngClass]="{ visible: isLoading && actionRow === rowIndex }"
            ></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
              class="dropdown-item"
              *ngIf="value !== InvoiceStates.SENT"
              (click)="updateInvoiceState(row.id, InvoiceStates.SENT)"
            >
              <span class="icon"> <i class="far fa-envelope"></i> </span>
              <span>{{ 'Mark as sent' | translate }}</span>
            </button>
            <button
              class="dropdown-item"
              *ngIf="value !== InvoiceStates.PAID"
              (click)="updateInvoiceState(row.id, InvoiceStates.PAID)"
            >
              <span class="icon"> <i class="far fa-check"></i> </span>
              <span>{{ 'Mark as paid' | translate }}</span>
            </button>
            <button
              class="dropdown-item"
              *ngIf="value !== InvoiceStates.REMINDED"
              (click)="updateInvoiceState(row.id, InvoiceStates.REMINDED)"
            >
              <span class="icon"> <i class="far fa-bell"></i> </span>
              <span>{{ 'Mark as reminded' | translate }}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Date' | translate }}" prop="date">
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ value | date: 'dd-MM-yyyy' }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Due date' | translate }}" prop="dueDate">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <span>{{ value | date: 'dd-MM-yyyy' }}</span>
        <span
          *ngIf="row.state !== InvoiceStates.PAID && getDaysLeft(value) >= 0"
        >
          ({{ getDaysLeft(value) }} {{ 'days left' | translate }})</span
        >
        <span
          *ngIf="row.state !== InvoiceStates.PAID && getDaysLeft(value) < 0"
        >
          ({{ getDaysLeft(value) * -1 }} {{ 'days overdue' | translate }})</span
        >
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [width]="120" [canAutoResize]="false"
      name="{{ 'Total price' | translate }}" prop="totalInclTaxes"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value | currency: 'EUR' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [cellClass]="'datatable-actions'"
      name="{{ 'Action' | translate }}"
      prop=""
      [width]="140"
    >
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <div ngbDropdown class="d-inline-block">
          <button
            class="btn btn-outline-info"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <span
              translate
              class="btn__label"
              [ngClass]="{ invisible: isLoading && actionRow === rowIndex }"
              >Select Action</span
            >
            <i
              class="fa fa-spinner fa-spin btn__loader"
              [ngClass]="{ visible: isLoading && actionRow === rowIndex }"
            ></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" (click)="editInvoice(row.id)">
              <span class="icon"> <i class="far fa-fw fa-pencil"></i> </span>
              <span>{{ 'Edit' | translate }}</span>
            </button>
            <button class="dropdown-item" (click)="createCreditNote(row.id)">
              <span class="icon">
                <i class="far fa-fw fa-file-invoice"></i>
              </span>
              <span>{{ 'Credit note' | translate }}</span>
            </button>
            <button class="dropdown-item" (click)="downloadInvoicePDF(row.id, row.number)">
              <span class="icon"> <i class="far fa-download"></i> </span>
              <span>{{ 'Download as PDF' | translate }}</span>
            </button>
            <button class="dropdown-item" (click)="deleteInvoice(row.id)">
              <span class="icon"> <i class="far fa-trash-alt"></i> </span>
              <span>{{ 'Delete' | translate }}</span>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
