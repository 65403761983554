/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./message-board-messages-subscription-toggle/message-board-messages-subscription-toggle.component.ngfactory";
import * as i3 from "./message-board-messages-subscription-toggle/message-board-messages-subscription-toggle.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "./message-board-messages-subscription.component";
var styles_MessageBoardMessagesSubscriptionComponent = [];
var RenderType_MessageBoardMessagesSubscriptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageBoardMessagesSubscriptionComponent, data: {} });
export { RenderType_MessageBoardMessagesSubscriptionComponent as RenderType_MessageBoardMessagesSubscriptionComponent };
function View_MessageBoardMessagesSubscriptionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("You are subscribed to Message board messages. You will recieve email notifications each time a user posts a new message.")); _ck(_v, 1, 0, currVal_0); }); }
function View_MessageBoardMessagesSubscriptionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("You are not subscribed to Message board messages. You will not recieve email notifications each time a user posts a new message.")); _ck(_v, 1, 0, currVal_0); }); }
export function View_MessageBoardMessagesSubscriptionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "card-header d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-message-board-messages-subscription-toggle", [], null, null, null, i2.View_MessageBoardMessagesSubscriptionToggleComponent_0, i2.RenderType_MessageBoardMessagesSubscriptionToggleComponent)), i0.ɵdid(7, 638976, null, 0, i3.MessageBoardMessagesSubscriptionToggleComponent, [i4.Store], { messageBoardMessageSubscription: [0, "messageBoardMessageSubscription"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessageBoardMessagesSubscriptionComponent_1)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noSub", 2]], null, 0, null, View_MessageBoardMessagesSubscriptionComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.messageBoardMessageSubscription; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.messageBoardMessageSubscription; var currVal_3 = i0.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Message board messages")); _ck(_v, 3, 0, currVal_0); }); }
export function View_MessageBoardMessagesSubscriptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-message-board-messages-subscription", [], null, null, null, View_MessageBoardMessagesSubscriptionComponent_0, RenderType_MessageBoardMessagesSubscriptionComponent)), i0.ɵdid(1, 49152, null, 0, i6.MessageBoardMessagesSubscriptionComponent, [], null, null)], null, null); }
var MessageBoardMessagesSubscriptionComponentNgFactory = i0.ɵccf("app-message-board-messages-subscription", i6.MessageBoardMessagesSubscriptionComponent, View_MessageBoardMessagesSubscriptionComponent_Host_0, { messageBoardMessageSubscription: "messageBoardMessageSubscription" }, {}, []);
export { MessageBoardMessagesSubscriptionComponentNgFactory as MessageBoardMessagesSubscriptionComponentNgFactory };
