import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { ApiService } from '../../api/api.service';

import * as DriverTaskTypes from '../types/driver-task.types';
import * as DriverTaskActions from '../actions/driver-task.actions';
import { map, concatMap, catchError } from 'rxjs/operators';

@Injectable()
export class DriverTaskEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadDriverTasks$: Observable<Action> = this.actions$.pipe(
    ofType(DriverTaskTypes.drivers.LOAD_DRIVER_TASKS),
    map((action: DriverTaskActions.LoadDriverTasks) => action.payload),
    concatMap(() => this.api.get({
      path: `/tms/schedule/me`
    }).pipe(
      map(data => new DriverTaskActions.LoadDriverTasksSuccess(data)),
      catchError(error => of(new DriverTaskActions.LoadDriverTasksFail(error)))
    ))
  );
}
