import { Action } from '@ngrx/store';
import * as types from '../types/shipping-agent.types';

export class LoadShippingAgents implements Action {
  readonly type = types.shippingAgent.LOAD_SHIPPING_AGENTS;
  constructor(public payload?: any) {}
}

export class LoadShippingAgentsSuccess implements Action {
  readonly type = types.shippingAgent.LOAD_SHIPPING_AGENTS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadShippingAgentsFail implements Action {
  readonly type = types.shippingAgent.LOAD_SHIPPING_AGENTS_FAILED;
  constructor(public payload?: any) {}
}

export type All =
  | LoadShippingAgents
  | LoadShippingAgentsSuccess
  | LoadShippingAgentsFail;
