import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { SubscriptionGuard } from 'app/core/auth/route-guards/subscription.guard';
import { TenantGuard } from 'app/core/auth/route-guards/tenant.guard';
import { Route } from 'app/core/route.service';
import { CertiweightEffects } from 'app/core/store/effects/certiweight.effects';
import { AuthGuard } from 'app/core/user/user.authguard';
import { SharedModule } from 'app/shared/shared.module';

import { BuyCertificateFormComponent } from './buy-certificate-form.component';
import { BuyCertificateModalComponent } from './buy-certificate-modal.component';
import { BuyCertificateComponent } from './buy-certificate.component';
import { CertificateDetailComponent } from './certificate-detail.component';
import { CertiweightComponent } from './certiweight.component';
import { MyCertificatesComponent } from './my-certificates.component';

const routes: Routes = Route.withShell([
  {
    path: 'certiweight',
    canActivate: [AuthGuard, TenantGuard, SubscriptionGuard],
    data: { subscriptionCode: 'CERTIWEIGHT' },
    children: [
      {
        path: '',
        component: CertiweightComponent,
      },
      {
        path: ':id/detail',
        component: CertificateDetailComponent,
      },
      {
        path: 'my',
        component: MyCertificatesComponent,
      },
      {
        path: 'buy',
        component: BuyCertificateComponent,
      }
    ],
  }
]);

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    EffectsModule,
    EffectsModule.forFeature([
      CertiweightEffects
    ]),
  ],
  declarations: [
    BuyCertificateComponent,
    BuyCertificateFormComponent,
    BuyCertificateModalComponent,
    CertificateDetailComponent,
    CertiweightComponent,
    MyCertificatesComponent,
  ],
  entryComponents: [
    BuyCertificateModalComponent,
  ],
})
export class CertiweightModule { }
