import { ContainerType } from 'app/core/store/models/container-types.model';
import { Liner } from 'app/core/store/models/matching.model';
import { TransportTask } from 'app/core/store/models/transport-task.model';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { EditCustomerModalComponent } from '../../edit-customer-modal/edit-customer-modal.component';
import { IconService } from 'app/services/icon.service';
import { Transport } from 'app/core/store/models/transport.model';
import { Store } from '@ngrx/store';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import {
  OnChanges,
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment';
import { State } from 'app/core/store/store.model';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateNativeAdapter,
  NgbDateCustomParserFormatter
} from 'app/shared/datepicker-config';
import { LoadSubscriptions } from 'app/core/store/actions/subscriptions.actions';
import { NewTariffModalComponent } from '../../new-tariff-modal/new-tariff-modal.component';
import {
  Product,
  ProductTypes
} from 'app/core/store/models/product.model';
import { Customer } from 'app/core/store/models/customer.model';
import { NewCustomerModalComponent } from '../../new-customer-modal/new-customer-modal.component';
import { Supplier } from 'app/core/store/models/supplier.model';
import { NewSupplierModalComponent } from '../../new-supplier-modal/new-supplier-modal.component';
import { EditSupplierModalComponent } from '../../edit-supplier-modal/edit-supplier-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import * as AttachmentTypes from 'app/core/store/types/attachment.types';
import { Attachment } from 'app/core/store/models/attachment.model';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { DeleteTransportTask } from 'app/core/store/actions/transport-task.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-transport-form',
  templateUrl: './transport-form.component.html',
  styleUrls: ['./transport-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class TransportFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  transportTypes: TransportType[];
  @Input()
  transport: Transport;
  @Input()
  customerTariffs: Product[];
  @Input()
  supplierTariffs: Product[];
  @Input()
  customers: Customer[];
  @Input()
  suppliers: Supplier[];
  @Input()
  isLoadingCustomers: boolean;
  @Input()
  isLoadingSuppliers: boolean;
  @Input()
  liners: Liner[];
  @Input()
  containerTypes: ContainerType[];
  @Input()
  loading: boolean;
  @Input()
  needsConfirmation: boolean;
  @Output()
  transportFormSubmitted = new EventEmitter<any>();
  @Output()
  matchingStateChanged = new EventEmitter<boolean>();
  @Output()
  submitAndContinueEnabled = new EventEmitter<boolean>();
  @ViewChild('tariffTable', { static: false }) tariffTable;
  @ViewChild('supplierTariffTable', { static: false }) supplierTariffTable;

  hasAtLeastOneLocation: boolean;
  hasActiveMarketplaceSubscription: boolean;
  hasActiveMatchingSubscription: boolean;
  containerValid = false;
  customerValid = true;
  supplierValid = true;
  isMatchingEnabled = false;
  isLoadingTariffs = false;

  transportForm: FormGroup;
  submitAttempt: boolean;
  selectedTariff: Product;
  selectedSupplierTariff: Product;
  ProductTypes = ProductTypes;
  customerContact: any;
  supplierContact: any;
  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private iconService: IconService,
    private modalService: NgbModal,
    private updates$: Actions,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.store.dispatch(new LoadSubscriptions());
    this.store
      .select(state => state.subscriptions)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptions => {
        if (subscriptions) {
          this.hasActiveMarketplaceSubscription =
            subscriptions.filter(
              subscription =>
                subscription.subscriptionType.code === 'MARKETPLACE'
            ).length !== 0;
          this.hasActiveMatchingSubscription =
            subscriptions.filter(
              subscription => subscription.subscriptionType.code === 'MATCHING'
            ).length !== 0;
          this.isMatchingEnabled = this.hasActiveMatchingSubscription;
        }
      });
    this.updates$
      .pipe(ofType(AttachmentTypes.attachment.DELETE_ATTACHMENT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        let attachments = this.transportForm.get('attachments').value;
        attachments = attachments.filter(
          attachment => attachment.id !== data['payload'].attachmentId
        );
        this.transportForm.patchValue({
          attachments
        });
        this.toastService.showSuccess({
          message: extract('Attachment deleted successfully!')
        });
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.transportTypes &&
      changes.transportTypes.currentValue
    ) {
      const transportTypes = changes.transportTypes.currentValue;
      if (!this.transportForm) {
        this.createForm();
        this.setTransportTasks(
          this.transportForm.get('transportType').value['transportTypeTasks']
        );
      }
      if (this.transport) {
        this.updateTransportForm(this.transport, transportTypes);
      }
    }
    if (changes && changes.transport && changes.transport.currentValue) {
      const transport = changes.transport.currentValue;
      if (!this.transportForm && this.transportTypes) {
        this.createForm();
      }
      if (this.transportTypes) {
        this.updateTransportForm(transport, this.transportTypes);
      }
    }
    this.cacheCustomerContact();
    this.cacheSupplierContact();
  }

  ngOnDestroy() { }

  updateTransportForm(transport, transportTypes) {
    const tTasks = transportTypes.find(
      type => type.id === transport.transportType.id
    )['transportTypeTasks'];
    this.setTransportTasks(tTasks);
    this.patchTransportForm(transport);
  }

  createForm() {
    this.transportForm = this.fb.group({
      transportType: [this.transportTypes[0], Validators.required],
      transportTasks: [[]],
      container: [
        this.fb.group({
          containerType: [null, Validators.required],
          liner: [null, Validators.required],
          weight: null,
          adr: false,
          number: [null]
        })
      ],
      reference: null,
      goods: null,
      ship: null,
      attachments: null,
      billOfLading: null,
      customs: null,
      genset: [{ value: null, disabled: true }],
      privateComment: '',
      comment: '',
      // Customer related fields
      customer: null,
      customerReference: null,
      tariff: null,
      contact: null,
      selectedCustomerTariffTemplate: null,
      // Supplier related fields
      supplier: null,
      supplierReference: null,
      supplierTariff: null,
      supplierContact: null,
      selectedSupplierTariffTemplate: null
    });
  }

  get transportTasks(): FormArray {
    if (this.transportForm) {
      return this.transportForm.get('transportTasks') as FormArray;
    }
  }

  get selectedSupplierContacts() {
    if (!this.suppliers) {
      return;
    }
    if (!this.transportForm.get('supplier').value) {
      return;
    }
    const supplier = this.suppliers.find(
      c => c.id === this.transportForm.get('supplier').value
    );
    if (!supplier) {
      return;
    }
    return supplier.contacts;
  }

  get selectedCustomerContacts() {
    if (!this.customers) {
      return;
    }
    if (!this.transportForm.get('customer').value) {
      return;
    }
    const customer = this.customers.find(
      c => c.id === this.transportForm.get('customer').value
    );
    if (!customer) {
      return;
    }
    return customer.contacts;
  }
  get privateComment(): FormArray {
    if (this.transportForm) {
      return this.transportForm.get('privateComment').value;
    }
  }

  get comment(): FormArray {
    if (this.transportForm) {
      return this.transportForm.get('comment').value;
    }
  }

  get transportType() {
    if (this.transportForm) {
      return this.transportForm.get('transportType').value;
    }
  }

  get container() {
    if (this.transportForm) {
      return this.transportForm.get('container').value;
    }
  }

  /**
   * Private location field should only be shown when a user has either:
   * - A marketplace subscription
   * - A matching subscription
   */
  get showPublicLocationField() {
    return (
      this.hasActiveMarketplaceSubscription ||
      this.hasActiveMatchingSubscription
    );
  }

  updateContact() {
    if (!this.transportForm.get('customer').value) {
      this.transportForm.get('contact').patchValue(null);
      return;
    }
    this.transportForm
      .get('contact')
      .patchValue(this.selectedCustomerContacts[0]['id']);
  }

  updateSupplierContact() {
    if (!this.transportForm.get('supplier').value) {
      this.transportForm.get('supplierContact').patchValue(null);
      return;
    }
    this.transportForm
      .get('supplierContact')
      .patchValue(this.selectedSupplierContacts[0]['id']);
  }

  onSubmit(submitAndContinue?: boolean) {
    const body = this.transportForm.value;
    this.submitAttempt = true;

    // check that all tariff table lines except last one are valid
    // TODO move somewhere else so we can update this.customerValid & this.supplierValid when tariff tables inputs change
    const tariffPartsCount: number = (body.tariff && body.tariff.parts) ? body.tariff.parts.length : 0;
    const supplierTariffPartsCount: number = (body.supplierTariff && body.supplierTariff.parts) ? body.supplierTariff.parts.length : 0;
    let tariffTableValid = true;
    let supplierTableValid = true;
    for (let i = 0; i < tariffPartsCount - 1; i++) {
      if (!body.tariff.parts[i].description || !body.tariff.parts[i].unitPrice || !body.tariff.parts[i].quantity) {
        tariffTableValid = false;
      }
    }
    for (let i = 0; i < supplierTariffPartsCount - 1; i++) {
      if (!body.supplierTariff.parts[i].description || !body.supplierTariff.parts[i].unitPrice || !body.supplierTariff.parts[i].quantity) {
        supplierTableValid = false;
      }
    }
    if (!tariffTableValid) {
      this.customerValid = false;
      this.tariffTable.onSubmit();
    } else {
      this.customerValid = true;
    }
    if (!supplierTableValid) {
      this.supplierValid = false;
      this.supplierTariffTable.onSubmit();
    } else {
      this.supplierValid = true;
    }

    if (!this.containerValid || !this.hasAtLeastOneLocation || !tariffTableValid || !supplierTableValid) {
      return;
    }
    if (body.container && body.container.value) {
      body.container = body.container.value;
    }

    // remove last tariff table lines if invalid
    if (body.tariff && (!body.tariff.parts[tariffPartsCount - 1].description ||
      !body.tariff.parts[tariffPartsCount - 1].unitPrice ||
      !body.tariff.parts[tariffPartsCount - 1].quantity)
    ) {
      body.tariff.parts.pop();
    }
    if (body.supplierTariff && (!body.supplierTariff.parts[supplierTariffPartsCount - 1].description ||
      !body.supplierTariff.parts[supplierTariffPartsCount - 1].unitPrice ||
      !body.supplierTariff.parts[supplierTariffPartsCount - 1].quantity)
    ) {
      body.supplierTariff.parts.pop();
    }

    if (!this.transport && body.tariff && body.tariff.id) {
      delete body.tariff.id;
    }
    if (!this.transport && body.supplierTariff && body.supplierTariff.id) {
      delete body.supplierTariff.id;
    }
    if (body.supplier && this.suppliers) {
      let supplier = this.suppliers.find(c => c['@id'] === body.supplier);
      if (!supplier) {
        supplier = this.suppliers.find(c => c.id === body.supplier);
      }
      // Hack to get the @id from the supplier, this can be removed if the accept headers in the POST request include ld+json
      body.supplier = supplier['@id'] || supplier.defaultContact['supplier'];

      let supplierContact = supplier.contacts.find(
        contact => contact['@id'] === body.supplierContact
      );
      if (!supplierContact) {
        supplierContact = supplier.contacts.find(
          contact => contact['id'] === body.supplierContact
        );
      }
      if (supplierContact && supplierContact['@id']) {
        body.supplierContact = supplierContact['@id'];
      } else {
        // Hack continues here
        const splitSupplier = body.supplier.split('/'); // Split url
        splitSupplier[splitSupplier.length - 1] = body.supplierContact; // Set id to contact id
        splitSupplier[splitSupplier.length - 2] = 'contacts'; // Set resource to contacts
        body.supplierContact = splitSupplier.join('/');
      }
    }
    if (body.customer && this.customers) {
      let customer = this.customers.find(c => c['@id'] === body.customer);
      if (!customer) {
        customer = this.customers.find(c => c.id === body.customer);
      }
      // Hack to get the @id from the customer, this can be removed if the accept headers in the POST request include ld+json
      body.customer = customer['@id'] || customer.defaultContact['customer'];

      let customerContact = customer.contacts.find(
        contact => contact['@id'] === body.contact
      );
      if (!customerContact) {
        customerContact = customer.contacts.find(
          contact => contact['id'] === body.contact
        );
      }
      if (customerContact['@id']) {
        body.contact = customerContact['@id'];
      } else {
        // Hack continues here
        const splitCustomer = body.customer.split('/'); // Split url
        splitCustomer[splitCustomer.length - 1] = body.contact; // Set id to contact id
        splitCustomer[splitCustomer.length - 2] = 'contacts'; // Set resource to contacts
        body.contact = splitCustomer.join('/');
      }
    }
    this.transportFormSubmitted.emit(body);
    this.matchingStateChanged.emit(this.isMatchingEnabled);
    if (!submitAndContinue) {
      return;
    }
    this.submitAndContinueEnabled.emit(submitAndContinue);
  }

  setTransportTasks(transportTasks: TransportTask[]) {
    const tasks = this.transportForm.get('transportTasks').value;
    tasks.forEach((task: TransportTask) => {
      const transportTask = transportTasks.find(
        t => t.taskType.code === task.taskType.code
      );
      if (transportTask) {
        Object.assign(transportTask, task);
      } else if (task['id'] !== undefined) {
        // delete unrelated transport tasks
        this.store.dispatch(
          new DeleteTransportTask({
            transportTaskId: task['id']
          })
        );
      }
    });
    this.transportForm.get('transportTasks').setValue(transportTasks);
  }

  transportTasksChange(event: { tasks: any; valid: boolean }) {
    this.setTransportTasks(event.tasks);
    this.hasAtLeastOneLocation = event.valid;
  }

  patchTransportForm(offer) {
    this.isMatchingEnabled = offer.partOfMatchingPoolItem;
    // patch form
    this.transportForm.patchValue({
      reference: offer.reference,
      privateComment: offer.privateComment,
      attachments: offer.attachments,
      comment: offer.comment,
      genset: offer.genset,
      quantity: offer.quantity ? offer.quantity : 1,
      goods: offer.goods,
      ship: offer.ship,
      billOfLading: offer.billOfLading,
      tariff: offer.tariff,
      supplierTariff: offer.supplierTariff,
      selectedCustomerTariffTemplate: offer.tariff,
      selectedSupplierTariffTemplate: offer.supplierTariff,
      customs: offer.customs,
      customer: offer.customer ? offer.customer.id : null,
      supplier: offer.supplier ? offer.supplier.id : null,
      contact: offer.contact ? offer.contact.id : null,
      supplierContact: offer.supplierContact ? offer.supplierContact.id : null,
      customerReference: offer.customerReference,
      supplierReference: offer.supplierReference
    });

    if (offer.container) {
      this.transportForm.patchValue({
        container: {
          weight: offer.container.weight ? offer.container.weight : null,
          adr: offer.container.adr,
          containerType: offer.container.containerType
            ? offer.container.containerType.id
            : null,
          liner: offer.container.liner ? offer.container.liner.id : null,
          number: offer.container.number ? offer.container.number : null
        }
      });
    }
    if (typeof offer.transportType === 'string') {
      this.transportForm
        .get('transportType')
        .setValue(
          this.transportTypes.find(tt => tt['@id'] === offer.transportType)
        );
    } else {
      this.transportForm
        .get('transportType')
        .setValue(
          this.transportTypes.find(
            tt => tt['@id'] === offer.transportType['@id']
          )
        );
    }
    offer.transportTasks = offer.transportTasks.map(task => {
      return {
        id: task.id ? task.id : null,
        taskType: task.taskType ? task.taskType : null,
        location: task.location,
        privateLocation: task.privateLocation
          ? typeof task.privateLocation === 'string'
            ? task.privateLocation
            : task.privateLocation.id
          : null,
        startDateTimeSpecified: task.startDateTimeSpecified,
        endDateTimeSpecified: task.endDateTimeSpecified,
        reference: task.reference,
        startDate: task.startDate
          ? new Date(
            moment(task.startDate).year(),
            moment(task.startDate).month(),
            moment(task.startDate).date()
          )
          : null,
        startTime: task.startTime
          ? task.startTime
          : task.startDate && task.startDateTimeSpecified
            ? {
              hour: moment(task.startDate).hours(),
              minute: moment(task.startDate).minutes(),
              second: 0
            }
            : null,
        endDate: task.endDate
          ? new Date(
            moment(task.endDate).year(),
            moment(task.endDate).month(),
            moment(task.endDate).date()
          )
          : null,
        endTime: task.endTime
          ? task.endTime
          : task.endDate && task.endDateTimeSpecified
            ? {
              hour: moment(task.endDate).hours(),
              minute: moment(task.endDate).minutes(),
              second: 0
            }
            : null
      };
    });
    offer.transportTasks.forEach(task => {
      const index = this.transportForm.get('transportTasks')['value'].indexOf(
        this.transportForm.get('transportTasks')['value'].find(control => {
          return (
            control.taskType['@id'] ===
            (task.taskType['@id'] ? task.taskType['@id'] : task.taskType)
          );
        })
      );
      if (index !== -1) {
        const tasks = [...this.transportTasks.value];
        tasks[index] = task;
        this.transportForm.get('transportTasks').setValue([...tasks]);
      }
    });
    this.hasAtLeastOneLocation = offer.transportTasks.some(
      task => task.location
    );
  }

  handleContainerFormStatusChanged(containerFormStatus) {
    this.containerValid = containerFormStatus === 'VALID';
  }
  handleContainerFormValueChanged(containerFormValue) {
    if (!containerFormValue) {
      return;
    }
    this.transportForm.get('container').patchValue(containerFormValue);
  }

  handleIsReefer(isReefer) {
    if (isReefer) {
      this.transportForm.get('genset').enable();
    } else {
      this.transportForm.get('genset').patchValue(false);
      this.transportForm.get('genset').disable();
    }
  }

  getTypeIcon(type: TransportType) {
    return this.iconService.getTransportTypeIcon(type);
  }

  openEditCustomerModal() {
    const modalRef = this.modalService.open(EditCustomerModalComponent);
    modalRef.componentInstance.customer = this.customers.find(
      c => c.id === this.transportForm.get('customer').value
    );
    modalRef.result.then(result => {
      this.customers = this.customers.filter(c => c.id !== result.id);
      this.customers = [result, ...this.customers];
      this.transportForm.get('customer').setValue(result.id);
      this.transportForm.get('contact').setValue(result.defaultContact.id);
    });
  }

  openEditSupplierModal() {
    const modalRef = this.modalService.open(EditSupplierModalComponent);
    modalRef.componentInstance.supplier = this.suppliers.find(
      s => s.id === this.transportForm.get('supplier').value
    );
    modalRef.result.then(result => {
      this.suppliers = this.suppliers.filter(s => s.id !== result.id);
      this.customers = [result, ...this.customers];
      this.transportForm.get('supplier').setValue(result.id);
      this.transportForm
        .get('supplierContact')
        .setValue(result.defaultContact.id);
    });
  }

  openNewSupplierModal(searchTerm?: string) {
    const modalRef = this.modalService.open(NewSupplierModalComponent);
    if (searchTerm) {
      modalRef.componentInstance.query = searchTerm;
    }
    modalRef.result.then(result => {
      if (!this.suppliers) {
        this.suppliers = [];
      }
      this.suppliers = [result, ...this.suppliers];
      this.transportForm.get('supplier').setValue(result.id);
      this.transportForm
        .get('supplierContact')
        .setValue(result.defaultContact.id);
    });
  }

  openNewCustomerModal(searchTerm?: string) {
    const modalRef = this.modalService.open(NewCustomerModalComponent);
    if (searchTerm) {
      modalRef.componentInstance.query = searchTerm;
    }
    modalRef.result.then(result => {
      if (!this.customers) {
        this.customers = [];
      }
      this.customers = [result, ...this.customers];
      this.transportForm.get('customer').setValue(result.id);
      this.transportForm.get('contact').setValue(result.defaultContact.id);
    });
  }
  openNewTariffModal(productType: ProductTypes, searchTerm?: string) {
    const modalRef = this.modalService.open(NewTariffModalComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.showTariffTypeButtons = false;
    modalRef.componentInstance.tariffType = productType;
    if (searchTerm) {
      modalRef.componentInstance.searchTerm = searchTerm;
    }
    modalRef.result.then(result => {
      if (productType === ProductTypes.CUSTOMER) {
        if (!this.customerTariffs) {
          this.customerTariffs = [];
        }
        this.customerTariffs = [result, ...this.customerTariffs];
        this.handleCustomerTariffSelect(result);
        this.transportForm
          .get('selectedCustomerTariffTemplate')
          .setValue(result);
      } else if (productType === ProductTypes.SUPPLIER) {
        if (!this.supplierTariffs) {
          this.supplierTariffs = [];
        }
        this.supplierTariffs = [result, ...this.supplierTariffs];
        this.handleSupplierTariffSelect(result);
        this.transportForm
          .get('selectedSupplierTariffTemplate')
          .setValue(result);
      }
    });
  }
  handleCustomerTariffSelect(tariffValue) {
    this.selectedTariff = tariffValue;

    if (this.transport && this.transport.invoiced) {
      this.toastService.showWarning({
        message: extract('You can\'t change the tariff of a transport that has already been invoiced.')
      });
      return;
    }

    if (!tariffValue) {
      this.transportForm.get('tariff').setValue(null);
      return;
    }
    this.handleCustomerTariffChange(tariffValue);
  }
  handleCustomerTariffChange(tariffValue) {
    this.transportForm.get('tariff').patchValue({
      label: tariffValue.label,
      price: tariffValue.price,
      parts: tariffValue.parts,
      type: ProductTypes.CUSTOMER,
      template: false
    });
  }

  handleSupplierTariffSelect(tariffValue) {
    this.selectedSupplierTariff = tariffValue;
      if (this.transport && this.transport.purchased) {
          this.toastService.showWarning({
              message: extract('You can\'t change the tariff of a transport that has already been invoiced.')
          });
          return;
      }
    if (!tariffValue) {
      this.transportForm.get('supplierTariff').setValue(null);
      return;
    }
    this.handleSupplierTariffChange(tariffValue);
  }
  handleSupplierTariffChange(tariffValue) {
    this.transportForm.get('supplierTariff').patchValue({
      label: tariffValue.label,
      price: tariffValue.price,
      parts: tariffValue.parts,
      type: ProductTypes.SUPPLIER,
      template: false
    });
  }

  get transportTasksAreValid() {
    return this.transportForm.value['transportTasks'].some(
      task => task.privateLocation
    );
  }

  handleFileUploadSuccess(attachment: Attachment) {
    let attachments = this.transportForm.get('attachments').value;
    if (!attachments) {
      attachments = [];
    }
    attachments.push(attachment);
    this.transportForm.patchValue({
      attachments: attachments
    });
  }

  cacheCustomerContact() {
    if (this.selectedCustomerContacts && this.transportForm) {
      this.customerContact = this.selectedCustomerContacts.find(contact => contact['id'] === this.transportForm.get('contact').value);
    }
  }

  cacheSupplierContact() {
    if (this.selectedSupplierContacts && this.transportForm) {
      this.supplierContact = this.selectedSupplierContacts.find(contact => contact['id'] === this.transportForm.get('supplierContact').value);
    }
  }
}
