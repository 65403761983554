import { IconService } from 'app/services/icon.service';
import {
  NgbDateNativeAdapter,
  NgbDateCustomParserFormatter
} from 'app/shared/datepicker-config';
import { ContainerType } from 'app/core/store/models/container-types.model';
import { Liner } from 'app/core/store/models/liner.model';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { TransportDemand } from 'app/core/store/models/transports.model';
import { Autocomplete } from 'app/services/autocomplete.service';
import { AbstractControl, FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import * as DateFns from 'date-fns';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {
  NgbModal,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';

// rxjs
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LocationService } from 'app/services/location.service';

@Component({
  selector: 'app-cmp-new-marketpost-demand',
  templateUrl: 'new-marketpost-demand.component.html',
  styleUrls: ['../new-marketpost.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class NewMarketpostDemandComponent implements OnInit, OnChanges {
  @Input()
  title: string;
  @Input()
  hasActiveMatchingSubscription: boolean;
  @Input()
  transportDemand: TransportDemand;
  @Input()
  index: number;
  @Input()
  liners: Liner[];
  @Input()
  containerTypes: ContainerType[];
  @Input()
  transportTypes: TransportType[];
  @Output()
  transportDemandAdded = new EventEmitter<any>();

  newMarketpostDemandForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private autocompleteService: Autocomplete,
    public locationService: LocationService,
    private modalService: NgbModal,
    private iconService: IconService
  ) { }

  ngOnInit() {
    if (!this.newMarketpostDemandForm || !this.newMarketpostDemandForm.value) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transportDemand && changes.transportDemand.currentValue) {
      if (
        !this.newMarketpostDemandForm ||
        !this.newMarketpostDemandForm.value
      ) {
        this.createForm();
      }
      const demand = changes.transportDemand.currentValue;
      // patch form
      if (typeof demand.transportType === 'string') {
        this.newMarketpostDemandForm
          .get('transportType')
          .setValue(
            this.transportTypes.find(tt => tt['@id'] === demand.transportType)
          );
      } else {
        this.newMarketpostDemandForm
          .get('transportType')
          .setValue(
            this.transportTypes.find(
              tt => tt['@id'] === demand.transportType['@id']
            )
          );
      }
      this.newMarketpostDemandForm.patchValue({
        originLocation: demand.originLocation,
        destinationLocation: demand.destinationLocation,
        container: {
          weight: demand.container.weight ? demand.container.weight : null,
          adr: demand.container.adr
        },
        reference: demand.reference,
        comment: demand.comment,
        genset: demand.genset,
        quantity: demand.quantity
      });
      if (demand.container.liner) {
        this.newMarketpostDemandForm.get('container').patchValue({
          liner: this.liners.find(
            liner => liner['@id'] === demand.container.liner
          ).id
        });
      } else {
        this.newMarketpostDemandForm.get('container').patchValue({
          liner: null
        });
      }
      if (demand.container.containerType) {
        this.newMarketpostDemandForm.get('container').patchValue({
          containerType: this.containerTypes.find(
            ct => ct['@id'] === demand.container.containerType
          ).id
        });
      } else {
        this.newMarketpostDemandForm.get('container').patchValue({
          containerType: null
        });
      }
      if (demand.originTimeIntervalStart) {
        this.newMarketpostDemandForm
          .get('originStartDate')
          .patchValue(
            new Date(
              moment(demand.originTimeIntervalStart).year(),
              moment(demand.originTimeIntervalStart).month(),
              moment(demand.originTimeIntervalStart).date()
            )
          );
        if (demand.originTimeIntervalStartTimeSpecified) {
          this.newMarketpostDemandForm.get('originStartTime').patchValue({
            hour: moment(demand.originTimeIntervalStart).hours(),
            minute: moment(demand.originTimeIntervalStart).minutes()
          });
        }
      }

      if (demand.originTimeIntervalEnd) {
        this.newMarketpostDemandForm
          .get('originEndDate')
          .patchValue(
            new Date(
              moment(demand.originTimeIntervalEnd).year(),
              moment(demand.originTimeIntervalEnd).month(),
              moment(demand.originTimeIntervalEnd).date()
            )
          );
        if (demand.originTimeIntervalEndTimeSpecified) {
          this.newMarketpostDemandForm.get('originEndTime').patchValue({
            hour: moment(demand.originTimeIntervalEnd).hours(),
            minute: moment(demand.originTimeIntervalEnd).minutes()
          });
        }
      }

      if (demand.destinationTimeIntervalStart) {
        this.newMarketpostDemandForm
          .get('destinationStartDate')
          .patchValue(
            new Date(
              moment(demand.destinationTimeIntervalStart).year(),
              moment(demand.destinationTimeIntervalStart).month(),
              moment(demand.destinationTimeIntervalStart).date()
            )
          );
        if (demand.destinationTimeIntervalStartTimeSpecified) {
          this.newMarketpostDemandForm.get('destinationStartTime').patchValue({
            hour: moment(demand.destinationTimeIntervalStart).hours(),
            minute: moment(demand.destinationTimeIntervalStart).minutes()
          });
        }
      }

      if (demand.destinationTimeIntervalEnd) {
        this.newMarketpostDemandForm
          .get('destinationEndDate')
          .patchValue(
            new Date(
              moment(demand.destinationTimeIntervalEnd).year(),
              moment(demand.destinationTimeIntervalEnd).month(),
              moment(demand.destinationTimeIntervalEnd).date()
            )
          );
        if (demand.destinationTimeIntervalEndTimeSpecified) {
          this.newMarketpostDemandForm.get('destinationEndTime').patchValue({
            hour: moment(demand.destinationTimeIntervalEnd).hours(),
            minute: moment(demand.destinationTimeIntervalEnd).minutes()
          });
        }
      }
    }
    if (changes.transportTypes && changes.transportTypes.currentValue) {
      if (
        !this.transportDemand ||
        !this.newMarketpostDemandForm ||
        !this.newMarketpostDemandForm.value
      ) {
        this.createForm();
      } else if (this.transportDemand) {
        if (typeof this.transportDemand.transportType === 'string') {
          this.newMarketpostDemandForm
            .get('transportType')
            .setValue(
              this.transportTypes.find(
                tt => tt['@id'] === this.transportDemand.transportType
              )
            );
        } else {
          this.newMarketpostDemandForm
            .get('transportType')
            .setValue(
              this.transportTypes.find(
                tt => tt['@id'] === this.transportDemand.transportType['@id']
              )
            );
        }
      }
    }
  }
  createForm() {
    this.newMarketpostDemandForm = this.fb.group({
      container: this.fb.group({
        containerType: [, Validators.required],
        liner: [, Validators.required],
        weight: [, this.numberValidator(/^((\d+\.?|\.(?=\d))?\d{0,3})$/)],
        adr: [false]
      }),
      transportType: [this.transportTypes[0] || ''],
      originLocation: [],
      originStartDate: [],
      originStartTime: [],
      originEndDate: [],
      originEndTime: [],
      originTimeIntervalStartTimeSpecified: [false],
      originTimeIntervalEndTimeSpecified: [false],
      destinationLocation: [],
      destinationStartDate: [],
      destinationStartTime: [],
      destinationEndDate: [],
      destinationEndTime: [],
      destinationTimeIntervalStartTimeSpecified: [false],
      destinationTimeIntervalEndTimeSpecified: [false],
      comment: [''],
      reference: [''],
      quantity: [1, Validators.min(0)]
    });
  }

  onSubmit() {
    const payload = {
      transport: this.newMarketpostDemandForm.value,
      index: this.index
    };
    this.transportDemandAdded.emit(payload);
  }

  // Autocomplete container_type
  searchMapping(searchType) {
    return searchType === 'searchContainerType'
      ? this.searchContainerType
      : this.searchLiner;
  }

  get weight() {
    return this.newMarketpostDemandForm.get('container.weight');
  }
  searchContainerType = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.autocompleteService.search('container_type', term);
      })
    )

  formatter = (x: { label: string }) => x.label;
  // Autocomplete liner
  searchLiner = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.autocompleteService.search('liner', term);
      })
    )

  locationFormatter = (x: { name: String }) => {
    return x.name;
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.location(term);
      })
    )

  hasAtLeastOneLocation() {
    return (
      this.newMarketpostDemandForm.get('originLocation').value ||
      this.newMarketpostDemandForm.get('destinationLocation').value
    );
  }

  get comment() {
    return this.newMarketpostDemandForm.get('comment').value;
  }

  open(event, content) {
    event.preventDefault();
    this.modalService.open(content);
  }

  setTransportType(type) {
    this.newMarketpostDemandForm.get('transportType').patchValue(type);
  }

  transportTypePicked() {
    /* Set destinationLocation to null when transport type is empty (hub), this way we prevent
     * the destinationLocationfield having a value because of an earlier transport that was added.
     */
    if (
      this.newMarketpostDemandForm.get('transportType').value &&
      this.newMarketpostDemandForm.get('transportType').value.label ===
      'empty (hub)'
    ) {
      this.newMarketpostDemandForm.get('destinationLocation').patchValue(null);
    }
  }

  numberValidator(numberRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const notNumber = numberRe.test(control.value);
      return notNumber ? null : { notNumber: { value: control.value } };
    };
  }

  /**
   *
   * @param date date object given by the datepicker
   * @param current the current year and month
   * @returns boolean if given date is before today
   */
  getDisabledDates(date: NgbDateStruct): boolean {
    const today = new Date();
    let d = new Date(date.year, date.month, date.day);
    d = DateFns.subMonths(d, 1);
    d = DateFns.addDays(d, 1);
    return DateFns.isBefore(d, today);
  }

  getTypeIcons(type) {
    return this.iconService.getTransportTypeIcon(type);
  }
}
