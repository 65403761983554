import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationEffects } from 'app/core/store/effects/location.effects';
import { MarketpostEffects } from 'app/core/store/effects/market-posts.effects';
import { DashboardReducer } from 'app/core/store/reducers/dashboard.reducer';
import { SharedModule } from 'app/shared/shared.module';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { PsaLinkAccountModalComponent } from './psa-onboarding/psa-link-account-modal.component';
import { PsaOnboardingComponent } from './psa-onboarding/psa-onboarding.component';
import { PsaStatusComponent } from './psa-onboarding/psa-status.component';
import { UncheckedLocationComponent } from './unchecked-locations/unchecked-location.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    StoreModule.forFeature('dashboard', DashboardReducer),
    EffectsModule.forFeature([MarketpostEffects, LocationEffects])
  ],
  declarations: [
    DashboardComponent,
    UncheckedLocationComponent,
    PsaLinkAccountModalComponent,
    PsaOnboardingComponent,
    PsaStatusComponent,
  ],
  entryComponents: [
    PsaLinkAccountModalComponent,
  ],
})
export class DashboardModule { }
