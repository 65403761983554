import { ApiService } from '../../api/api.service';
import { Alert } from '../../store/models/alert.model';
import { Component } from '@angular/core';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-new-container-type',
  templateUrl: './new-container-type.component.html',
})
export class NewContainerTypeComponent {
  alert: Alert;

  constructor(private api: ApiService, private toastr: ToastService) { }

  createNewContainerType(containerTypeFormValue) {
    this.api
      .post({
        path: '/container_types',
        body: containerTypeFormValue
      })
      .subscribe(
        () => {
          this.toastr.showSuccess({
            message: 'Container Type created!'
          });
        },
        err => {
          this.toastr.showSuccess({
            title: 'Something went wrong',
            message: err
          });
        }
      );
  }
}
