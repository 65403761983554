import { MarketPostDemandReply } from '../models/market-post-demand-replies.model';
import * as types from '../types/market-post.types';
import * as MarketPostDemandReplyActions from '../actions/market-post-demand-replies.actions';

const INITIAL_STATE = null;

export function reducer(
  state = INITIAL_STATE,
  action: MarketPostDemandReplyActions.All
): MarketPostDemandReply[] {
  switch (action.type) {
    case types.marketPostDemandReplies
      .LOAD_MARKET_POST_DEMAND_REPLIES_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}
