import { Component, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Customer } from 'app/core/store/models/customer.model';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnChanges {
  @Input()
  isLoading: boolean;
  @Input()
  countries;
  @Input()
  customer: Customer;
  @Input()
  query: string;
  @Input()
  submitAttempt: boolean;
  @Output()
  customerFormSubmitted = new EventEmitter();

  customerForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.customer && changes.customer.currentValue) {
      this.customerForm.patchValue(changes.customer.currentValue);
    }
    if (changes && changes.query && changes.query.currentValue) {
      this.customerForm.patchValue({
        label: changes.query.currentValue
      });
    }
  }

  createForm() {
    this.customerForm = this.fb.group({
      vatNumber: [null, Validators.required],
      label: [null, Validators.required],
      billingAddress: this.fb.group({
        attention: [null, Validators.required],
        streetAndNumber: [null, Validators.required],
        city: [null, Validators.required],
        zipCode: [null, Validators.required],
        country: [null, Validators.required],
        telephone: [null]
      }),
      adresses: [[]],
      contracts: [[]],
      defaultContact: this.fb.group({
        salutation: [null, Validators.required],
        email: [null, Validators.required],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        telephone: [null],
        designation: [null],
        department: [null]
      })
    });
  }
  onSubmit() {
    this.submitAttempt = true;
    this.validateAllFormFields(this.customerForm);
    if (this.customerForm.invalid) {
      return;
    }
    const customer = Object.assign({}, this.customerForm.value);
    this.customerFormSubmitted.emit(customer);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
