import { Component } from '@angular/core';
import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './company-posts.component.html',
  styleUrls: ['./company-posts.component.scss']
})
export class CompanyPostsComponent {
  title = extract('Company Posts');
}
