/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../node_modules/ngx-barcode6/ngx-barcode6.ngfactory";
import * as i3 from "ngx-barcode6";
import * as i4 from "./visit-bar-code.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
var styles_VisitBarCodeComponent = [];
var RenderType_VisitBarCodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VisitBarCodeComponent, data: {} });
export { RenderType_VisitBarCodeComponent as RenderType_VisitBarCodeComponent };
export function View_VisitBarCodeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "card-header d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [["class", "align-self-center mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "btn ml-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "ngx-barcode6", [], null, null, null, i2.View_NgxBarcode6Component_0, i2.RenderType_NgxBarcode6Component)), i0.ɵdid(9, 573440, null, 0, i3.NgxBarcode6Component, [i0.Renderer2], { displayValue: [0, "displayValue"], value: [1, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = true; var currVal_3 = _co.barCode; _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Scan Bar Code")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Close")); _ck(_v, 5, 0, currVal_1); }); }
export function View_VisitBarCodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-visit-bar-code", [], null, null, null, View_VisitBarCodeComponent_0, RenderType_VisitBarCodeComponent)), i0.ɵdid(1, 49152, null, 0, i4.VisitBarCodeComponent, [i5.NgbActiveModal], null, null)], null, null); }
var VisitBarCodeComponentNgFactory = i0.ɵccf("app-visit-bar-code", i4.VisitBarCodeComponent, View_VisitBarCodeComponent_Host_0, { barCode: "barCode" }, {}, []);
export { VisitBarCodeComponentNgFactory as VisitBarCodeComponentNgFactory };
