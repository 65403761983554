/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./settings-card/settings-card.component.ngfactory";
import * as i2 from "./settings-card/settings-card.component";
import * as i3 from "@angular/common";
import * as i4 from "./admin.component";
import * as i5 from "@ngrx/store";
import * as i6 from "../user/user.auth";
var styles_AdminComponent = [];
var RenderType_AdminComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
function View_AdminComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-settings-card", [], null, null, null, i1.View_SettingsCardComponent_0, i1.RenderType_SettingsCardComponent)), i0.ɵdid(1, 49152, null, 0, i2.SettingsCardComponent, [], { route: [0, "route"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AdminComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminComponent_2)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userIsAllowedRoute(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AdminComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "link-grid-container p-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.routes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdminComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-admin", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i0.ɵdid(1, 245760, null, 0, i4.AdminComponent, [i5.Store, i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminComponentNgFactory = i0.ɵccf("app-admin", i4.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };
