import { Component } from '@angular/core';

@Component({
  selector: 'app-new-subscription-type',
  templateUrl: './new-subscription-type.component.html',
})
export class NewSubscriptionTypeComponent {
  createSubscriptionType(subscriptionTypeFormValue) {
    console.log(subscriptionTypeFormValue);
  }
}
