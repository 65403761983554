<div class="publish-demand">
  <div class="card-header d-flex">
    <h3 class="align-self-center mb-0">
      {{ 'Confirm marketplace publication' | translate }}
    </h3>
    <button class="btn ml-auto" (click)="activeModal.close()">
      {{ 'Close' | translate }}
    </button>
  </div>
  <div class="m-3">
    <app-demand-locations-form
      [transport]="transport"
      (demandLocationsFormValueChanged)="
        handleDemandLocationsFormValueChange($event)
      "
    ></app-demand-locations-form>
    <ngb-tabset [destroyOnHide]="false" [justify]="'justified'">
      <ngb-tab>
        <ng-template ngbTabTitle>
          <span
            [class.text-danger]="this.submitAttempt && !this.containerValid"
          >
            <i class="far fa-fw fa-container-storage"></i>&nbsp;
            <span>{{ 'Container &amp; goods' | translate }}</span> &nbsp;
            <i
              *ngIf="this.submitAttempt && !this.containerValid"
              class="far fa-fw fa-exclamation-triangle"
            ></i>
          </span>
        </ng-template>
        <ng-template ngbTabContent>
          <div class="container-tab-grid">
            <app-container-form
              [needsConfirmation]="needsConfirmation"
              [container]="getContainer(transport)"
              [submitAttempt]="submitAttempt"
              (reeferStatusChanged)="handleIsReefer($event)"
              (containerFormValueChanged)="
                handleContainerFormValueChanged($event)
              "
              (containerFormStatusChanged)="
                handleContainerFormStatusChanged($event)
              "
            ></app-container-form>
            <div>
              <div class="checkbox-column">
                <label class="custom-control custom-checkbox genset-checkbox">
                  <input
                    type="checkbox"
                    id="genset-input"
                    class="custom-control-input"
                    [disabled]="!isReefer"
                    [(ngModel)]="genset"
                  />
                  <span class="custom-control-label">{{
                    'Genset' | translate
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab>
        <ng-template ngbTabTitle>
          <i class="far fa-fw fa-comment"></i>&nbsp;
          <span data-cy="commentTab">{{ 'Comment' | translate }}</span>
        </ng-template>
        <ng-template ngbTabContent>
          <div>
            <label>{{ 'Public comment' | translate }}</label>
            <span class="text-muted" id="message-length"
              >({{ comment.length }} / 256)</span
            >
            <textarea
              class="form-control"
              id="comment"
              data-cy="comment"
              maxlength="256"
              [(ngModel)]="comment"
              cols="20"
              rows="4"
            ></textarea>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
    <div class="mt-3">
      <app-button (buttonClicked)="publishDemand()" [type]="'button'">{{
        'Publish' | translate
      }}</app-button>
    </div>
  </div>
</div>
