import { Action } from '@ngrx/store';

import * as types from '../types/visit.types';
import { Visit } from '../models/visits.model';

export class LoadVisits implements Action {
  readonly type = types.visits.LOAD_VISITS;
  constructor(
    public payload?: {
      fromDate?: string;
      toDate?: string;
      locationId?: string;
      driverId?: string;
      page?: number;
    }
  ) {}
}
export class LoadVisitsSuccess implements Action {
  readonly type = types.visits.LOAD_VISITS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadVisitsFail implements Action {
  readonly type = types.visits.LOAD_VISITS_FAILED;
  constructor(public payload?: any) {}
}

export class CreateVisit implements Action {
  readonly type = types.visits.CREATE_VISIT;
  constructor(public payload?: any) {}
}

export class CreateVisitSuccess implements Action {
  readonly type = types.visits.CREATE_VISIT_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class CreateVisitFail implements Action {
  readonly type = types.visits.CREATE_VISIT_FAILED;
  constructor(public payload?: any) {}
}

export class UpdateVisit implements Action {
  readonly type = types.visits.UPDATE_VISIT;
  constructor(
    public payload: {
      visitId: string;
      body: Visit;
    }
  ) {}
}

export class UpdateVisitSuccess implements Action {
  readonly type = types.visits.UPDATE_VISIT_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class UpdateVisitFail implements Action {
  readonly type = types.visits.UPDATE_VISIT_FAILED;
  constructor(public payload?: any) {}
}

export class CompleteVisit implements Action {
  readonly type = types.visits.COMPLETE_VISIT;
  constructor(public payload?: any) {}
}

export class CompleteVisitSuccess implements Action {
  readonly type = types.visits.COMPLETE_VISIT_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class CompleteVisitFail implements Action {
  readonly type = types.visits.COMPLETE_VISIT_FAILED;
  constructor(public payload?: any) {}
}

export class RevertCompleteVisit implements Action {
  readonly type = types.visits.REVERT_COMPLETE_VISIT;
  constructor(public payload?: any) {}
}

export class RevertCompleteVisitSuccess implements Action {
  readonly type = types.visits.REVERT_COMPLETE_VISIT_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class RevertCompleteVisitFail implements Action {
  readonly type = types.visits.REVERT_COMPLETE_VISIT_FAILED;
  constructor(public payload?: any) {}
}

export class UpdateVisitSequence implements Action {
  readonly type = types.visits.UPDATE_VISIT_SEQUENCE;
  constructor(
    public payload: {
      collection: {
        id: string;
        sequence: number;
      }[];
    }
  ) {}
}

export class UpdateVisitSequenceFail implements Action {
  readonly type = types.visits.UPDATE_VISIT_SEQUENCE_FAILED;
  constructor(public payload?: any) {}
}

export class UpdateVisitSequenceSuccess implements Action {
  readonly type = types.visits.UPDATE_VISIT_SEQUENCE_SUCEEDED;
  constructor(public payload?: any) {}
}

export class TransitionVisitForward implements Action {
  readonly type = types.visits.TRANSITION_VISIT_FORWARD;
  constructor(
    public payload?: {
      visitId: string;
    }
  ) {}
}
export class TransitionVisitForwardSuccess implements Action {
  readonly type = types.visits.TRANSITION_VISIT_FORWARD_SUCCEEDED;
  constructor(public payload?: any) {}
}
export class TransitionVisitForwardFail implements Action {
  readonly type = types.visits.TRANSITION_VISIT_FORWARD_FAILED;
  constructor(public payload?: any) {}
}
export class TransitionVisitBack implements Action {
  readonly type = types.visits.TRANSITION_VISIT_BACK;
  constructor(
    public payload?: {
      visitId: string;
    }
  ) {}
}
export class TransitionVisitBackSuccess implements Action {
  readonly type = types.visits.TRANSITION_VISIT_BACK_SUCCEEDED;
  constructor(public payload?: any) {}
}
export class TransitionVisitBackFail implements Action {
  readonly type = types.visits.TRANSITION_VISIT_BACK_FAILED;
  constructor(public payload?: any) {}
}

export class ClearVisits implements Action {
  readonly type = types.visits.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadVisits
  | LoadVisitsSuccess
  | LoadVisitsFail
  | CreateVisit
  | CreateVisitSuccess
  | CreateVisitFail
  | UpdateVisit
  | UpdateVisitSuccess
  | UpdateVisitFail
  | CompleteVisit
  | CompleteVisitSuccess
  | CompleteVisitFail
  | RevertCompleteVisit
  | RevertCompleteVisitSuccess
  | RevertCompleteVisitFail
  | UpdateVisitSequence
  | UpdateVisitSequenceFail
  | UpdateVisitSequenceSuccess
  | TransitionVisitForward
  | TransitionVisitForwardSuccess
  | TransitionVisitForwardFail
  | TransitionVisitBack
  | TransitionVisitBackSuccess
  | TransitionVisitBackFail
  | ClearVisits;
