import { AppConfig } from '../../app.config';
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/**
 * Service for easily doing api calls.
 */
var ApiService = /** @class */ (function () {
    /**
     * @param http {HttpClient} Httpclient module.
     * @constructor
     */
    function ApiService(inj, config) {
        this.inj = inj;
        this.config = config;
        this.http = inj.get(HttpClient);
    }
    Object.defineProperty(ApiService.prototype, "apiUrl", {
        get: function () {
            return this.config.getConfig('apiUrl') + "/api/" + this.config.getConfig('apiVersion');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Does an api request and returns the observable
     * @param options {ApiCall} The object that should contain the path.
     * @return { Observable }
     */
    ApiService.prototype.get = function (options, headers, responseType) {
        return headers
            ? this.http.get(this.apiUrl + options.path, {
                headers: headers,
                responseType: responseType
            })
            : this.http.get(this.apiUrl + options.path);
    };
    /**
     * Does an api request to an external source and returns the observable
     * @param options {ApiCall} The object that should contain the path.
     * @return { Observable }
     */
    ApiService.prototype.getExternal = function (options) {
        return this.http.get(options.path);
    };
    /**
     * Does an api request and returns the observable
     * @param options {ApiCall} The object that should contain the path and the body
     * @return { Observable }
     */
    ApiService.prototype.post = function (options, headers) {
        if (headers) {
            options.params['headers'] = headers;
        }
        return this.http.post(this.apiUrl + options.path, options.body, options.params);
    };
    /**
     * Does an api request and returns the observable
     * @param options {ApiCall} The object that should contain the path and the body
     * @return { Observable }
     */
    ApiService.prototype.delete = function (options) {
        return this.http.delete(this.apiUrl + options.path, options.body);
    };
    /**
     * Does an api request and returns the observable
     * @param options {ApiCall} The object that should contain the path and the body
     * @return { Observable }
     */
    ApiService.prototype.refreshToken = function (options) {
        options.body.append('grant_type', 'refresh_token');
        options.body.append('client_id', this.config.getConfig('clientId'));
        options.body.append('client_secret', this.config.getConfig('clientSecret'));
        return this.http.post(this.config.getConfig('oauthUrl'), options.body.toString(), {
            headers: {
                Authorization: 'Bearer ',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    };
    /**
     * Does an api request and returns the observable
     * @param options {ApiCall} The object that should contain the path and the body
     * @return { Observable }
     */
    ApiService.prototype.getToken = function (options) {
        options = options
            .append('grant_type', 'password')
            .append('client_id', this.config.getConfig('clientId'))
            .append('client_secret', this.config.getConfig('clientSecret'));
        return this.http.post(this.config.getConfig('oauthUrl'), options.toString(), {
            headers: {
                Authorization: 'Bearer ',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    };
    /**
     * Does an api request and returns the observable
     * @param options {ApiCall} The object that should contain the path and the body
     * @return { Observable }
     */
    ApiService.prototype.put = function (options, headers) {
        return headers
            ? this.http.put(this.apiUrl + options.path, options.body, {
                headers: headers
            })
            : this.http.put(this.apiUrl + options.path, options.body);
    };
    return ApiService;
}());
export { ApiService };
