export const supplier = {
  LOAD_SUPPLIERS: '[Suppliers] Load',
  LOAD_SUPPLIERS_SUCCEEDED: '[Suppliers] Load Success',
  LOAD_SUPPLIERS_FAILED: '[Suppliers] Load Fail',
  LOAD_FILTERED_SUPPLIERS: '[Suppliers] Load filtered',
  LOAD_FILTERED_SUPPLIERS_SUCCEEDED: '[Suppliers] Load filtered Success',
  LOAD_FILTERED_SUPPLIERS_FAILED: '[Suppliers] Load filtered Fail',
  CREATE_SUPPLIER: '[Supplier] Create',
  CREATE_SUPPLIER_SUCCEEDED: '[Supplier] Create Success',
  CREATE_SUPPLIER_FAILED: '[Supplier] Create Fail',
  DELETE_SUPPLIER: '[Supplier] Delete',
  DELETE_SUPPLIER_SUCCEEDED: '[Supplier] Delete Success',
  DELETE_SUPPLIER_FAILED: '[Supplier] Delete Fail',
  UPDATE_SUPPLIER: '[Supplier] Update',
  UPDATE_SUPPLIER_SUCCEEDED: '[Supplier] Update Success',
  UPDATE_SUPPLIER_FAILED: '[Supplier] Update Fail',
  CLEAR: '[Suppliers] Clear'
};
