import * as types from '../types/reload.types';
import * as ReloadActions from '../actions/reload.actions';
import { ReloadRequest } from '../models/reload-request.model';

export interface ReloadRequestsState {
  items: ReloadRequest[];
  total: number;
}

const INITIAL_STATE = {
  items: null,
  total: null
};

export function ReloadRequestsReducer(
  state = INITIAL_STATE,
  action: ReloadActions.All
): ReloadRequestsState {
  switch (action.type) {
    case types.reload.LOAD_ALL_SUCCEEDED:
      state.items = [...action.payload['hydra:member']];
      state.total = action.payload['hydra:totalItems'];
      return Object.assign({}, state);
    case types.reload.CREATE_RELOAD_REQUEST_SUCCEEDED:
      return action.payload;
    case types.reload.UPDATE_RELOAD_REQUEST_SUCCEEDED:
      return action.payload;
    case types.reload.CLEAR:
      return null;
  }
  return state;
}
