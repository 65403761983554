import { State } from '../store.model';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as RegistrationRequestActions from '../actions/registraction-request.actions';
import * as types from '../types/registration-request.types';

@Injectable()
export class RegistrationRequestEffects {
  showProcessed = false;
  constructor(
    private api: ApiService,
    private actions$: Actions,
    private store: Store<State>
  ) {
    this.store
      .select(state => state.registrationRequests)
      .subscribe(registrationRequests => {
        if (registrationRequests && registrationRequests['showProcessed'] !== null) {
          this.showProcessed = registrationRequests['showProcessed'];
        }
      });
  }

  @Effect()
  loadAllRegistrationRequests$: Observable<Action> = this.actions$.pipe(
    ofType(types.registrationRequests.LOAD_ALL_REGISTRATION_REQUESTS),
    map((action: RegistrationRequestActions.LoadAllRegistrationRequests) => action.payload),
    concatMap(() => this.api.get({
      path: '/registration_requests?'
        + (this.showProcessed ? 'status[]=approved&status[]=declined' : 'status=pending')
    }).pipe(
      map(data => new RegistrationRequestActions.LoadAllRegistrationRequestsSuccess(data)),
      catchError(error => of(new RegistrationRequestActions.LoadAllRegistrationRequestsFailed(error)))
    )),
  );

  @Effect()
  loadOneRegistrationRequest$: Observable<Action> = this.actions$.pipe(
    ofType(types.registrationRequests.LOAD_ONE_REGISTRATION_REQUEST),
    map((action: RegistrationRequestActions.LoadOneRegistrationRequest) => action.payload),
    concatMap(payload => {
      const id = payload && payload.id ? payload.id : null;
      return this.api.get({ path: '/registration_requests/' + id }).pipe(
        map(data => new RegistrationRequestActions.LoadOneRegistrationRequestSuccess(data)),
        catchError(error => of(new RegistrationRequestActions.LoadOneRegistrationRequestFailed(error)))
      );
    }),
  );

  @Effect()
  approveRegistrationRequest$: Observable<Action> = this.actions$.pipe(
    ofType(types.registrationRequests.APPROVE_REGISTRATION_REQUEST),
    map((action: RegistrationRequestActions.ApproveRegistrationRequest) => action.payload),
    concatMap(payload => this.api.post({
      path: '/registration_requests/' + payload + '/approve', body: {}
    }).pipe(
      map(data => new RegistrationRequestActions.ApproveRegistrationRequestSuccess(data)),
      catchError(error => of(new RegistrationRequestActions.ApproveRegistrationRequestFail(error)))
    )),
  );

  @Effect()
  declineRegistrationRequest$: Observable<Action> = this.actions$.pipe(
    ofType(types.registrationRequests.DECLINE_REGISTRATION_REQUEST),
    map((action: RegistrationRequestActions.DeclineRegistrationRequest) => action.payload),
    concatMap(payload => this.api.post({
      path: '/registration_requests/' + payload + '/decline', body: {}
    }).pipe(
      map(data => new RegistrationRequestActions.DeclineRegistrationRequestSuccess(data)),
      catchError(error => of(new RegistrationRequestActions.DeclineRegistrationRequestFail(error)))
    )),
  );

  @Effect()
  updateRegistrationRequest$: Observable<Action> = this.actions$.pipe(
    ofType(types.registrationRequests.UPDATE_REGISTRATION_REQUEST),
    map((action: RegistrationRequestActions.UpdateRegistrationRequest) => action.payload),
    concatMap(payload => this.api.put({
      path: '/registration_requests/' + payload.id, body: payload.body
    }).pipe(
      map(data => new RegistrationRequestActions.UpdateRegistrationRequestSuccess(data)),
      catchError(error => of(new RegistrationRequestActions.UpdateRegistrationRequestFail(error)))
    )),
  );
}
