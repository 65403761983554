<ngb-tabset [justify]="'fill'">
  <ngb-tab id="new-tab">
    <ng-template ngbTabTitle>
      <span class="icon">
        <i class="far fa-play-circle"></i>
      </span>
      <span>{{ 'New' | translate }}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <div class="container p-3">
        <div *ngIf="schedule">
          <div *ngFor="let date of objectKeys(schedule)">
            <h2 class="date text-center">{{ date }}</h2>
            <div class="task-container">
              <div class="task mb-3"
                *ngFor="let transport of schedule[date]">
                <div *ngFor="let task of transport.transportTasks">
                  <div *ngIf="task.assignedTo?.id === userId && task['status'] === 'new'">
                    <app-driver-task [task]="task"
                      [transport]="transport"
                      [isGoingBack]="isGoingBackward"
                      [isGoingForward]="isGoingForward"
                      (transitionBack)="transitionBack($event)"
                      (transitionForward)="transitionForward($event)"></app-driver-task>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab id="in_progress-tab">
    <ng-template ngbTabTitle>
      <span class="icon">
        <i class="far fa-clock"></i>
      </span>
      <span>{{ 'In Progress' | translate }}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <div class="container p-3">
        <div *ngIf="schedule">
          <div *ngFor="let date of objectKeys(schedule)">
            <h2 class="date text-center">{{ date }}</h2>
            <div class="task-container">
              <div class="task"
                *ngFor="let transport of schedule[date]">
                <div *ngFor="let task of transport.transportTasks">
                  <div *ngIf="task.assignedTo?.id === userId && task['status'] === 'in_progress'">
                    <app-driver-task [task]="task"
                      [transport]="transport"
                      [isGoingBack]="isGoingBackward"
                      [isGoingForward]="isGoingForward"
                      (transitionBack)="transitionBack($event)"
                      (transitionForward)="transitionForward($event)"></app-driver-task>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
  <ngb-tab id="completed-tab">
    <ng-template ngbTabTitle>
      <span class="icon">
        <i class="far fa-check-circle"></i>
      </span>
      <span>{{ 'Completed' | translate }}</span>
    </ng-template>
    <ng-template ngbTabContent>
      <div class="container p-3">
        <div *ngIf="schedule">
          <div *ngFor="let date of objectKeys(schedule)">
            <h2 class="date text-center">{{ date }}</h2>
            <div class="task-container">
              <div class="task"
                *ngFor="let transport of schedule[date]">
                <div *ngFor="let task of transport.transportTasks">
                  <div *ngIf="task.assignedTo?.id === userId && task['status'] === 'completed'">
                    <app-driver-task [task]="task"
                      [transport]="transport"
                      [isGoingBack]="isGoingBackward"
                      [isGoingForward]="isGoingForward"
                      (transitionBack)="transitionBack($event)"
                      (transitionForward)="transitionForward($event)"></app-driver-task>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
