/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../visit-form/visit-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../shared/datepicker-config";
import * as i4 from "../visit-form/visit-form.component";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../core/api/api.service";
import * as i7 from "../../../services/icon.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../services/toast.service";
import * as i10 from "./edit-visit.component";
import * as i11 from "@ngrx/effects";
var styles_EditVisitComponent = [];
var RenderType_EditVisitComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditVisitComponent, data: {} });
export { RenderType_EditVisitComponent as RenderType_EditVisitComponent };
export function View_EditVisitComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-visit-form", [], null, [[null, "visitFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visitFormSubmitted" === en)) {
        var pd_0 = (_co.updateVisit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_VisitFormComponent_0, i1.RenderType_VisitFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i3.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(4, 704512, null, 0, i4.VisitFormComponent, [i5.Store, i6.ApiService, i2.NgbModal, i7.IconService, i8.ActivatedRoute, i9.ToastService, i2.NgbCalendar], { visit: [0, "visit"], isLoading: [1, "isLoading"] }, { visitFormSubmitted: "visitFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visit; var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_EditVisitComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-visit", [], null, null, null, View_EditVisitComponent_0, RenderType_EditVisitComponent)), i0.ɵdid(1, 180224, null, 0, i10.EditVisitComponent, [i8.ActivatedRoute, i5.Store, i6.ApiService, i9.ToastService, i8.Router, i11.Actions], null, null)], null, null); }
var EditVisitComponentNgFactory = i0.ɵccf("app-edit-visit", i10.EditVisitComponent, View_EditVisitComponent_Host_0, {}, {}, []);
export { EditVisitComponentNgFactory as EditVisitComponentNgFactory };
