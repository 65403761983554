import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/supplier.types';
import * as SupplierActions from '../actions/supplier.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class SupplierEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadSuppliers$: Observable<Action> = this.actions$.pipe(
    ofType(types.supplier.LOAD_SUPPLIERS),
    concatMap(() => this.api.get({
      path: `/crm/suppliers`
    }).pipe(
      map(data => new SupplierActions.LoadSuppliersSuccess(data)),
      catchError(error => of(new SupplierActions.LoadSuppliersFail(error)))
    )),
  );

  @Effect()
  loadFilteredSuppliers$: Observable<Action> = this.actions$.pipe(
    ofType(types.supplier.LOAD_FILTERED_SUPPLIERS),
    map((action: SupplierActions.LoadFilteredSuppliers) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      return this.api.get({
        path: `/crm/suppliers?label=${query}&vatNumber=${query}`
      }).pipe(
        map(data => new SupplierActions.LoadFilteredSuppliersSuccess(data)),
        catchError(error => of(new SupplierActions.LoadFilteredSuppliersFail(error)))
      );
    }),
  );

  @Effect()
  createSupplier$: Observable<Action> = this.actions$.pipe(
    ofType(types.supplier.CREATE_SUPPLIER),
    map((action: SupplierActions.CreateSupplier) => action.payload),
    concatMap(payload => this.api.post({
      path: `/crm/suppliers`, body: payload
    }).pipe(
      map(data => new SupplierActions.CreateSupplierSuccess(data)),
      catchError(error => of(new SupplierActions.CreateSupplierFail(error)))
    )),
  );

  @Effect()
  deleteSupplier$: Observable<Action> = this.actions$.pipe(
    ofType(types.supplier.DELETE_SUPPLIER),
    map((action: SupplierActions.DeleteSupplier) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/suppliers/${payload.supplierId}`
    }).pipe(
      map(() => new SupplierActions.DeleteSupplierSuccess(payload.supplierId)),
      catchError(error => of(new SupplierActions.DeleteSupplierFail(error)))
    )),
  );

  @Effect()
  updateSupplier$: Observable<Action> = this.actions$.pipe(
    ofType(types.supplier.UPDATE_SUPPLIER),
    map((action: SupplierActions.UpdateSupplier) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/suppliers/${payload.supplierId}`,
      body: payload.supplier
    }).pipe(
      map(data => new SupplierActions.UpdateSupplierSuccess(data)),
      catchError(error => of(new SupplierActions.UpdateSupplierFail(error)))
    )),
  );
}
