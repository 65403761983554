import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../../store/types/transaction.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as TransactionsActions from '../../store/actions/transactions.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class TransactionsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadCreditTransactions$: Observable<Action> = this.actions$.pipe(
    ofType(types.transactions.LOAD_CREDIT_TRANSACTIONS),
    map((action: TransactionsActions.LoadCreditTransactions) => action.payload),
    concatMap(payload => this.api.get({
      path: `/credit_transactions?invoiceNumber[exists]=true&pageSize=${
        payload && payload.pageSize ? payload.pageSize : 30
        }&page=${payload && payload.page ? payload.page : 1}`
    }).pipe(
      map(data => new TransactionsActions.LoadCreditTransactionsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  loadAllTransactions$: Observable<Action> = this.actions$.pipe(
    ofType(types.transactions.LOAD_ALL_TRANSACTIONS),
    concatMap(() => this.api.get({
      path: '/abstract_transactions?credits[gt]=0'
    }).pipe(
      map(data => new TransactionsActions.LoadAllTransactionsSuccess(data)),
      catchError(error => of(new TransactionsActions.LoadAllTransactionsFail(error)))
    )),
  );

  @Effect()
  loadTransactionPage$: Observable<Action> = this.actions$.pipe(
    ofType(types.transactions.LOAD_TRANSACTION_PAGE),
    map((action: TransactionsActions.LoadTransactionPage) => action.payload),
    concatMap(payload => this.api.get({
      path: `/abstract_transactions?credits[gt]=0&page=${payload.page}`
    }).pipe(
      map(data => new TransactionsActions.LoadTransactionPageSuccess(data)),
      catchError(error => of(new TransactionsActions.LoadTransactionPageSuccess(error)))
    )),
  );
}
