<div class="new-user-location-form">
  <app-user-location-form
    [isLoading]="isLoading"
    (userLocationFormSubmitted)="createNewUserLocation($event)"
    (addressChanged)="updateMarkerByAddress($event)"
    [coordinates]="coordinates"
  >
    <div class="map mb-3" id="user-location-map"></div>
  </app-user-location-form>
</div>
