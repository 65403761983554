import { IconService } from 'app/services/icon.service';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl, AbstractControl } from '@angular/forms';
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as DateFns from 'date-fns';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NewTaxRateModalComponent } from '../new-tax-rate-modal/new-tax-rate-modal.component';
var InvoiceFormComponent = /** @class */ (function () {
    function InvoiceFormComponent(iconService, fb, modalService) {
        var _this = this;
        this.iconService = iconService;
        this.fb = fb;
        this.modalService = modalService;
        this.invoiceFormSubmitted = new EventEmitter();
        this.onSaveAndDownloadAsPDF = new EventEmitter();
        this.searchTransports = new EventEmitter();
        this.selectTransport = new EventEmitter();
        this.removeTransport = new EventEmitter();
        this.customerSelected = new EventEmitter();
        this.loadNextTransportPage = new EventEmitter();
        this.taxRateExpr = new RegExp(/[0-9]{1,3}/);
        this.addTagText = 'Add tax rate';
        this.query = '';
        this.searchTextChanged = new Subject();
        this.showDateSelection = false;
        this.initialized = false;
        this.searchTextChanged
            .pipe(debounceTime(500), distinctUntilChanged(), map(function (search) { return _this.searchTransports.emit({ query: search.toString(), since: _this.since, until: _this.until }); }))
            .subscribe(function () { });
    }
    InvoiceFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.taxRates) {
            this.taxRates = changes.taxRates.currentValue;
        }
        if (changes && changes.invoice) {
            this.invoice = changes.invoice.currentValue;
        }
        if (this.taxRates && !this.invoiceForm) {
            this.createForm();
        }
        if (this.invoice && this.taxRates) {
            this.patchForm();
        }
    };
    InvoiceFormComponent.prototype.patchForm = function () {
        var _this = this;
        this.invoiceForm.patchValue({
            business: this.invoice.business,
            notes: this.invoice.notes,
            date: new Date(DateFns.getYear(this.invoice.date), DateFns.getMonth(this.invoice.date), DateFns.getDate(this.invoice.date)),
            dueDate: new Date(DateFns.getYear(this.invoice.dueDate), DateFns.getMonth(this.invoice.dueDate), DateFns.getDate(this.invoice.dueDate))
        });
        var invoiceItems = this.invoiceForm.controls['items'];
        if (this.invoice.items && !this.initialized) {
            this.invoice.items.forEach(function (item) {
                if (item.transport) {
                    invoiceItems.at(invoiceItems.value.length - 1).patchValue({
                        transport: item.transport
                    });
                }
                invoiceItems.at(invoiceItems.value.length - 1).patchValue({
                    id: item.id,
                    description: item.description,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    taxRate: item.taxRate,
                    total: item['totalInclTaxes'].toFixed(2)
                });
                _this.addInvoiceItem();
            });
            this.initialized = true;
        }
    };
    InvoiceFormComponent.prototype.createForm = function () {
        var today = new Date();
        var dueDate = new Date(DateFns.getYear(DateFns.addDays(today, 30)), DateFns.getMonth(DateFns.addDays(today, 30)), DateFns.getDate(DateFns.addDays(today, 30)));
        this.invoiceForm = this.fb.group({
            business: [null, Validators.required],
            date: [today, Validators.required],
            dueDate: [dueDate, Validators.required],
            notes: [null],
            items: this.fb.array([this.createInvoiceItem()], this.invoiceItemValidator()),
        });
    };
    Object.defineProperty(InvoiceFormComponent.prototype, "customerAddress", {
        get: function () {
            if (!this.invoiceForm ||
                !this.invoiceForm.value ||
                !this.invoiceForm.get('business').value) {
                return;
            }
            return this.invoiceForm.get('business').value['billingAddress'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvoiceFormComponent.prototype, "customerMainContact", {
        get: function () {
            if (!this.invoiceForm ||
                !this.invoiceForm.value ||
                !this.invoiceForm.get('business').value) {
                return;
            }
            return this.invoiceForm.get('business').value['defaultContact'];
        },
        enumerable: true,
        configurable: true
    });
    InvoiceFormComponent.prototype.createInvoiceItem = function () {
        var group = this.fb.group({
            id: null,
            description: [''],
            quantity: [1],
            transport: [null],
            unitPrice: [0],
            taxRate: [this.taxRates[0], this.taxRateValidator()],
            total: [null]
        });
        return group;
    };
    InvoiceFormComponent.prototype.addInvoiceItem = function () {
        var invoiceItems = this.invoiceForm.controls['items'];
        invoiceItems.push(this.createInvoiceItem());
    };
    InvoiceFormComponent.prototype.removeInvoiceItem = function (index) {
        var invoiceItems = this.invoiceForm.controls['items'];
        var item = invoiceItems.value[index];
        if (item.transport) {
            this.removeTransport.emit(item.transport.id);
        }
        invoiceItems.removeAt(index);
    };
    InvoiceFormComponent.prototype.handleCustomerSelected = function () {
        var customer = this.invoiceForm.get('business').value;
        var customerId = customer && customer.id ? customer.id : null;
        this.customerSelected.emit(customerId);
        // Remove all selected transports
        var invoiceItems = this.invoiceForm.controls['items'];
        invoiceItems.value.forEach(function (control) {
            if (!control.transport) {
                return;
            }
            if (control.transport.customer &&
                control.transport.customer.id === customerId) {
                return;
            }
            var index = invoiceItems.value.indexOf(control);
            invoiceItems.removeAt(index);
        });
    };
    InvoiceFormComponent.prototype.openTaxRateModal = function (itemIndex, rate) {
        var _this = this;
        var modalRef = this.modalService.open(NewTaxRateModalComponent);
        modalRef.result.then(function (result) {
            var invoiceItems = _this.invoiceForm.controls['items'];
            invoiceItems.at(itemIndex).patchValue({
                taxRate: result
            });
            _this.updateTotal(itemIndex);
        });
    };
    InvoiceFormComponent.prototype.search = function ($event) {
        this.searchTextChanged.next($event.target.value);
    };
    /**
     * Duplicates the given invoice item
     * @param index index of the invoice item
     */
    InvoiceFormComponent.prototype.duplicateItem = function (index) {
        this.addInvoiceItem();
        var invoiceItems = this.invoiceForm.controls['items'];
        var item = Object.assign({}, invoiceItems.at(index).value);
        // Set item id to null if present
        if (item.id) {
            item.id = null;
        }
        invoiceItems.at(invoiceItems.value.length - 1).patchValue(item);
    };
    /**
     * Updates the total of the given invoice item based on the quantity, unit price
     * and tax rate
     * @param index index of the invoice item
     */
    InvoiceFormComponent.prototype.updateTotal = function (index) {
        var invoiceItems = this.invoiceForm.controls['items'];
        var item = invoiceItems.value[index];
        if (!item.quantity ||
            !item.unitPrice ||
            !item.taxRate ||
            item.taxRate.rate === null) {
            return;
        }
        item.total =
            +item.quantity * +item.unitPrice;
        if (item.taxRate.rate > 0) {
            item.total += ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate;
        }
        invoiceItems.at(index).setValue(item);
    };
    Object.defineProperty(InvoiceFormComponent.prototype, "total", {
        get: function () {
            var invoiceItems = this.invoiceForm.controls['items'].value;
            return invoiceItems
                .map(function (item) { return +item.total; })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvoiceFormComponent.prototype, "subTotal", {
        get: function () {
            var invoiceItems = this.invoiceForm.controls['items'].value;
            return invoiceItems
                .map(function (item) { return +item.quantity * +item.unitPrice; })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvoiceFormComponent.prototype, "taxes", {
        get: function () {
            var invoiceItems = this.invoiceForm.controls['items'].value;
            return invoiceItems
                .map(function (item) {
                // If an item doesn't have a tax rate it is invalid
                if (!item.taxRate || !item.taxRate.rate) {
                    return 0;
                }
                return (((+item.quantity * +item.unitPrice) / 100) * +item.taxRate['rate']);
            })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    InvoiceFormComponent.prototype.handleTransportSelected = function (transport) {
        if (!transport.tariff) {
            return;
        }
        var description = transport.tariff.label;
        var unitPrice = transport.tariff.price;
        var invoiceItems = this.invoiceForm.controls['items'];
        var index = invoiceItems.value.length - 1;
        if (invoiceItems.at(index).get('description').value) {
            this.addInvoiceItem();
            index++;
        }
        var item = {
            description: description,
            unitPrice: unitPrice,
            transport: transport
        };
        invoiceItems.at(index).patchValue(item);
        this.updateTotal(index);
        this.addInvoiceItem();
        // Select customer if transport has one so we can only add transports of exactly one customer
        if (transport.customer &&
            transport.customer['id'] &&
            !this.invoiceForm.get('business').value) {
            this.invoiceForm.patchValue({
                business: transport.customer
            });
            this.handleCustomerSelected();
        }
        this.selectTransport.emit(transport.id);
    };
    /**
     * Emits the invoice form value
     */
    InvoiceFormComponent.prototype.onSubmit = function () {
        if (this.invoiceForm.invalid) {
            this.markFormGroupAsTouched(this.invoiceForm);
            return;
        }
        var invoiceFormValue = this.parseInvoiceFormValue();
        this.invoiceFormSubmitted.emit(invoiceFormValue);
    };
    InvoiceFormComponent.prototype.markFormGroupAsTouched = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.markFormGroupAsTouched(control);
            }
        });
    };
    InvoiceFormComponent.prototype.parseInvoiceFormValue = function () {
        var invoiceFormValue = JSON.parse(JSON.stringify(this.invoiceForm.value));
        invoiceFormValue.items = invoiceFormValue.items.filter(function (item) { return item.description || item.transport; });
        invoiceFormValue.date = DateFns.setHours(invoiceFormValue.date, 12);
        invoiceFormValue.dueDate = DateFns.setHours(invoiceFormValue.dueDate, 12);
        invoiceFormValue.business = invoiceFormValue.business
            ? invoiceFormValue.business['@id']
            : null;
        invoiceFormValue.items.forEach(function (item) {
            item.taxRate = {
                id: item.taxRate['id']
            };
            if (!item.transport) {
                return;
            }
            item.transport = {
                id: item.transport['id']
            };
        });
        return invoiceFormValue;
    };
    InvoiceFormComponent.prototype.getTransportIcon = function (transport) {
        return this.iconService.getTransportTypeIcon(transport.transportType);
    };
    InvoiceFormComponent.prototype.saveAndDownloadAsPDF = function () {
        if (this.invoiceForm.invalid) {
            return;
        }
        var invoiceFormValue = this.parseInvoiceFormValue();
        this.onSaveAndDownloadAsPDF.emit(invoiceFormValue);
    };
    InvoiceFormComponent.prototype.getPrimaryTaskLocation = function (transport) {
        var primaryTask = transport.transportTasks.find(function (t) { return t.primaryTask; });
        if (!primaryTask.location) {
            return;
        }
        return primaryTask.location.name;
    };
    InvoiceFormComponent.prototype.getPrimaryTaskDate = function (transport) {
        return transport.primaryTaskDateTimeSpecified ? moment(transport.primaryTaskDate).format("DD/MM HH:mm") : moment(transport.primaryTaskDate).format("DD/MM");
    };
    InvoiceFormComponent.prototype.onScrollDown = function () {
        this.loadNextTransportPage.emit(true);
    };
    InvoiceFormComponent.prototype.handleDateSelection = function (dateSelectionEvent) {
        var sinceDateStruct = (dateSelectionEvent.fromDate) ? dateSelectionEvent.fromDate : null;
        var untilDateStruct = (dateSelectionEvent.toDate) ? dateSelectionEvent.toDate : null;
        if (sinceDateStruct !== null && untilDateStruct !== null) {
            var sinceDate = moment(sinceDateStruct.year + '-' + sinceDateStruct.month + '-' + sinceDateStruct.day, 'YYYY-MM-DD');
            var untilDate = moment(untilDateStruct.year + '-' + untilDateStruct.month + '-' + untilDateStruct.day, 'YYYY-MM-DD');
            this.since = sinceDate.format('YYYY-MM-DD');
            this.until = untilDate.format('YYYY-MM-DD');
            this.dateSelection = sinceDate.format('DD/MM/YYYY') + ' - ' + untilDate.format('DD/MM/YYYY');
            this.showDateSelection = false;
            this.searchTransports.emit({ query: this.query, since: this.since, until: this.until });
        }
        else {
            this.since = null;
            this.until = null;
        }
    };
    // check if exists at least one item with property "total"
    InvoiceFormComponent.prototype.invoiceItemValidator = function () {
        return function (c) {
            var valid = false;
            c.value.forEach(function (item) {
                if (item.total !== null) {
                    valid = true;
                }
            });
            return valid ? null : { 'invoiceItemValidator': { valid: valid } };
        };
    };
    InvoiceFormComponent.prototype.taxRateValidator = function () {
        return function (c) {
            var valid = false;
            // dont care about validation if its dummy item
            if (c.parent && c.parent.controls['description'].value === "") {
                valid = true;
            }
            if (c.value && c.value.id) {
                valid = true;
            }
            return valid ? null : { 'taxRateValidator': { valid: valid } };
        };
    };
    return InvoiceFormComponent;
}());
export { InvoiceFormComponent };
