import { Wallet } from 'app/core/store/models/wallet.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cmp-credit-amount',
  templateUrl: 'credit-amount.component.html'
})
export class CreditAmountComponent {
  @Input()
  wallet: Wallet;
  loading = false;

  get total() {
    if (!this.wallet) {
      return 0;
    }
    return +this.wallet.credits + this.wallet.reservedCredits;
  }
}
