import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';

import { ApiService } from 'app/core/api/api.service';
import { ToastService } from 'app/services/toast.service';
import { NgbDateNativeAdapter, NgbDateCustomParserFormatter } from 'app/shared/datepicker-config';

@Component({
  selector: 'app-cmp-liner-reload-requests-report',
  templateUrl: 'liner-reload-requests-report.component.html',
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class LinerReloadRequestsReportComponent {
  reportForm: FormGroup;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastService
  ) {
    if (!this.reportForm) {
      this.createForm();
    }
  }

  createForm(): void {
    this.reportForm = this.fb.group({
      sinceDate: [null, Validators.required],
      untilDate: [null, Validators.required]
    });
  }

  onReportFormSubmit(): void {
    if (this.reportForm.valid) {
      const since: string = moment(this.reportForm.value.sinceDate).format('YYYY-MM-DD');
      const until: string = moment(this.reportForm.value.untilDate).format('YYYY-MM-DD');

      if (since > until) {
        this.reportForm.get('untilDate').setErrors({'incorrect': true});;
        return;
      }

      this.loading = true;
      this.api.get(
        { path: '/export/liner-reload-requests-report/csv?since=' + since + '&until=' + until },
        {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        },
        'blob'
      ).subscribe((data: any) => {
        fileSaver.saveAs(
          new Blob([data], { type: 'text/csv' }),
          'reload_requests_report_' + moment().format('DD_MM_YYYY') + '.csv',
        );
        this.loading = false
      }, () => {
        this.loading = false;
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: 'Download failed'
          });
      });
    } else {
      this.reportForm.get('sinceDate').markAsTouched();
      this.reportForm.get('untilDate').markAsTouched();
    }
  }
}
