var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/credit-note.types';
import * as CreditNoteActions from '../actions/credit-note.actions';
import { ApiService } from '../../api/api.service';
var CreditNoteEffects = /** @class */ (function () {
    function CreditNoteEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadCreditNotes$ = this.actions$.pipe(ofType(types.creditNote.LOAD_CREDIT_NOTES), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/crm/credit_notes?page=" + (payload && payload.page ? payload.page : 1)
        }).pipe(map(function (data) { return new CreditNoteActions.LoadCreditNotesSuccess(data); }), catchError(function (error) { return of(new CreditNoteActions.LoadCreditNotesFail(error)); })); }));
        this.loadFilteredCreditNotes$ = this.actions$.pipe(ofType(types.creditNote.LOAD_FILTERED_CREDIT_NOTES), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/crm/credit_notes'
                + '?page=' + (payload && payload.page ? payload.page : 1)
                + '&business.label=' + (payload && payload.query ? payload.query : '')
                + '&number=' + (payload && payload.query ? payload.query : '')
        }).pipe(map(function (data) { return new CreditNoteActions.LoadFilteredCreditNotesSuccess(data); }), catchError(function (error) { return of(new CreditNoteActions.LoadFilteredCreditNotesFail(error)); })); }));
        this.createCreditNote$ = this.actions$.pipe(ofType(types.creditNote.CREATE_CREDIT_NOTE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/crm/credit_notes", body: payload
        }).pipe(map(function (data) { return new CreditNoteActions.CreateCreditNoteSuccess(data); }), catchError(function (error) { return of(new CreditNoteActions.CreateCreditNoteFail(error)); })); }));
        this.updateCreditNote$ = this.actions$.pipe(ofType(types.creditNote.UPDATE_CREDIT_NOTE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/credit_notes/" + payload.creditNoteId,
            body: payload.body
        }).pipe(map(function (data) { return new CreditNoteActions.UpdateCreditNoteSuccess(data); }), catchError(function (error) { return of(new CreditNoteActions.UpdateCreditNoteFail(error)); })); }));
        this.deleteCreditNote$ = this.actions$.pipe(ofType(types.creditNote.DELETE_CREDIT_NOTE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/credit_notes/" + payload.creditNoteId
        }).pipe(map(function () { return new CreditNoteActions.DeleteCreditNoteSuccess(payload.creditNoteId); }), catchError(function (error) { return of(new CreditNoteActions.DeleteCreditNoteFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CreditNoteEffects.prototype, "loadCreditNotes$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CreditNoteEffects.prototype, "loadFilteredCreditNotes$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CreditNoteEffects.prototype, "createCreditNote$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CreditNoteEffects.prototype, "updateCreditNote$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CreditNoteEffects.prototype, "deleteCreditNote$", void 0);
    return CreditNoteEffects;
}());
export { CreditNoteEffects };
