<div class="card p-3 match-option">
  <div
    class="d-flex"
    *ngIf="!option?.match?.subjectDeleted; else matchIsDeleted"
  >
    <span style="width: 100%">
      <app-transport-matching-pool-item
        [tenant]="option.match.tenant"
        *ngIf="option.match.transport"
        [transport]="option.match.transport"
        [isMatchingPoolOption]="true"
      >
        <app-confidence-score
          [confidence]="option.confidence"
        ></app-confidence-score>
      </app-transport-matching-pool-item>
      <app-market-post-offer-matching-pool-item
        [tenant]="option.match.tenant"
        [isMatchingPoolOption]="true"
        *ngIf="option.match.marketPostOffer?.transportOrigin"
        [offer]="option.match.marketPostOffer.transportOrigin"
      >
        <app-confidence-score
          [confidence]="option.confidence"
        ></app-confidence-score>
      </app-market-post-offer-matching-pool-item>
      <app-market-post-demand-matching-pool-item
        [tenant]="option.match.tenant"
        [isMatchingPoolOption]="true"
        *ngIf="option.match.marketPostDemand"
        [demand]="option.match.marketPostDemand"
      >
        <app-confidence-score
          [confidence]="option.confidence"
        ></app-confidence-score>
      </app-market-post-demand-matching-pool-item>
    </span>
    <span
      class="icon text-success ml-1"
      (click)="toggleReply()"
      id="reply-option"
      ngbTooltip="{{ 'Reply' | translate }}"
    >
      <i class="far fa-reply"></i>
    </span>
    <span
      class="icon text-danger ml-1"
      id="remove-option"
      (click)="handleRemoveOption()"
      ngbTooltip="{{ 'Not interested' | translate }}"
    >
      <i
        class="far"
        [ngClass]="{
          'fa-times': !isRemovingMatchOption,
          'fa-spin fa-spinner': isRemovingMatchOption
        }"
      ></i>
    </span>
  </div>
  <ng-template #matchIsDeleted>
    <div class="text-center">
      {{ 'This match has been deleted by the owner' | translate }}
    </div>
  </ng-template>
  <div class="mt-1" id="matching-option-reply" *ngIf="replyIsVisible">
    <textarea
      class="form-control mb-1"
      [class.is-invalid]="submitAttempt && !reply"
      [(ngModel)]="reply"
      placeholder="{{ 'Type your reply here' | translate }}"
      cols="20"
      rows="5"
    ></textarea>
    <app-button
      [isLoading]="isLoading"
      (buttonClicked)="submitReplyClicked()"
      [type]="'button'"
    >
      <span>{{ 'Reply' | translate }}</span>
    </app-button>
  </div>
</div>
