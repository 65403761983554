import { AuthGuard } from '../core/user/user.authguard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { ReloadComponent } from './reload.component';
import { ReloadRequestsComponent } from './reload-requests/reload-requests.component';
import { ReloadRequestComponent } from './reload-request/reload-request.component';
import { ReloadBackOfficeComponent } from './reload-back-office.component';
import { NewReloadRequestComponent } from './new-reload-request/new-reload-request.component';
import { ReloadReportingComponent } from './reporting/reload-reporting.component';
import { Route } from '../core/route.service';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';

const defaultReloadGaurds = [
  AuthGuard,
  TenantGuard,
  SubscriptionGuard,
  DriverGuard,
  AllowedSubscriptionGuard
];
const routes: Routes = Route.withShell([
  {
    path: 'reload',
    component: ReloadComponent,
    canActivate: [...defaultReloadGaurds],
    data: { subscriptionCode: 'RELOAD' }
  },
  {
    path: 'reload/reload-requests',
    children: [
      {
        path: '',
        component: ReloadRequestsComponent,
        canActivate: [...defaultReloadGaurds],
        data: { subscriptionCode: 'RELOAD' }
      },
      {
        path: ':id/detail',
        component: ReloadRequestComponent,
        canActivate: [...defaultReloadGaurds],
        data: { subscriptionCode: 'RELOAD' }
      }
    ]
  },
  {
    path: 'reload/new-reload-request',
    component: NewReloadRequestComponent,
    canActivate: [...defaultReloadGaurds],
    data: { subscriptionCode: 'RELOAD' }
  },
  {
    path: 'reload-back-office',
    component: ReloadBackOfficeComponent,
    canActivate: [...defaultReloadGaurds],
    data: { subscriptionCode: 'RELOAD_BACK_OFFICE' }
  },
  {
    path: 'reload-back-office/reload-requests',
    children: [
      {
        path: '',
        component: ReloadRequestsComponent,
        canActivate: [...defaultReloadGaurds],
        data: { subscriptionCode: 'RELOAD_BACK_OFFICE' }
      },
      {
        path: ':id/detail',
        component: ReloadRequestComponent,
        canActivate: [...defaultReloadGaurds],
        data: { subscriptionCode: 'RELOAD_BACK_OFFICE' }
      }
    ]
  },
  {
    path: 'reload-back-office/reporting',
    component: ReloadReportingComponent,
    canActivate: [...defaultReloadGaurds],
    data: { subscriptionCode: 'RELOAD_BACK_OFFICE' }
  },
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReloadRoutingModule { }

export const routedComponents = [ReloadComponent];
