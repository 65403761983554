import * as types from '../types/visit.types';
import * as VisitsActions from '../actions/visits.actions';

const INITIAL_STATE = null;

export function VisitsReducer(
  state = INITIAL_STATE,
  action: VisitsActions.All
) {
  switch (action.type) {
    case types.visits.LOAD_VISITS_SUCCEEDED:
    case types.visits.UPDATE_VISIT_SEQUENCE_SUCEEDED:
      return [...action.payload];
    case types.visits.TRANSITION_VISIT_FORWARD_SUCCEEDED:
    case types.visits.TRANSITION_VISIT_BACK_SUCCEEDED:
      return state.map(item => {
        return item.id === action.payload.id
          ? Object.assign({}, item, action.payload)
          : item;
      });
    case types.visits.CLEAR:
      return null;
  }
  return state;
}
