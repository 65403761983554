import * as types from '../types/transport-type.types';
import * as TransportTypeActions from '../actions/transport-types.actions';
import { TransportType } from '../models/transport-types.model';

const INITIAL_STATE: TransportType[] = null;

export function TransportTypesReducer(
  state = INITIAL_STATE,
  action: TransportTypeActions.All
): TransportType[] {
  switch (action.type) {
    case types.transportTypes.LOAD_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}
