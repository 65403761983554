import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';
import { PsaCompany } from 'app/core/store/models/psa-company.model';
import { State } from 'app/core/store/store.model';
import { AuthService } from 'app/core/user/user.auth';

import { PsaLinkAccountModalComponent } from './psa-link-account-modal.component';

@Component({
  selector: 'app-psa-onboarding',
  templateUrl: './psa-onboarding.component.html',
  styleUrls: ['./psa-onboarding.component.scss']
})
export class PsaOnboardingComponent {
  checkPsaAccount$: Observable<boolean>;
  checkCredits$: Observable<boolean>;
  companies$: Observable<PsaCompany>;
  selectedCompany: PsaCompany;

  constructor(
    private store: Store<State>,
    private modalService: NgbModal,
    private api: ApiService,
    private authService: AuthService,
  ) {
    const tenant$ = this.store.select(
      state => state.tenants.userTenants
        .find(t => t.id === this.authService.getActiveTenant()));

    this.checkPsaAccount$ = tenant$
      .pipe(map(tenant => tenant ? !!tenant.psaCompany : true));

    this.checkCredits$ = combineLatest(
      tenant$, this.store.select(state => state.wallet),
      (tenant, wallet) => {
        if (!wallet || !wallet.id) {
          return true;
        }
        const threshold = tenant && tenant.psaCompany
          ? tenant.psaCompany.creditsThreshold
          : 8.34;
        return (+ wallet.credits + wallet.reservedCredits) >= threshold;
      },
    );

    this.companies$ = this.api
      .get({ path: '/available-psa-companies' })
      .pipe(map(x => <PsaCompany>x['hydra:member']));
  }

  linkAccount() {
    if (!this.selectedCompany) {
      return;
    }
    const modalRef = this.modalService.open(PsaLinkAccountModalComponent);
    modalRef.componentInstance.company = this.selectedCompany;
  }

  enlargeScreenshot(template) {
    this.modalService.open(template, { size: 'lg', windowClass: 'modal-nobg' });
  }
}
