import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { OnInit, OnDestroy, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeleteAttachment, UpdateAttachment } from 'app/core/store/actions/attachment.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as AttachmentTypes from 'app/core/store/types/attachment.types';
import { DownloadService } from 'app/services/download.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
var AttachmentComponent = /** @class */ (function () {
    function AttachmentComponent(store, updates$, downloadService, modalService) {
        this.store = store;
        this.updates$ = updates$;
        this.downloadService = downloadService;
        this.modalService = modalService;
        this.editMode = false;
    }
    Object.defineProperty(AttachmentComponent.prototype, "title", {
        set: function (elRef) {
            if (elRef) {
                this.titleRef = elRef;
            }
        },
        enumerable: true,
        configurable: true
    });
    AttachmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updates$
            .pipe(ofType(AttachmentTypes.attachment.DELETE_ATTACHMENT_SUCCEEDED, AttachmentTypes.attachment.DELETE_ATTACHMENT_FAILED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.isDeleting = false;
        });
        this.updates$
            .pipe(ofType(AttachmentTypes.attachment.UPDATE_ATTACHMENT_SUCCEEDED, AttachmentTypes.attachment.UPDATE_ATTACHMENT_FAILED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.editMode = false;
            _this.isUpdating = false;
        });
    };
    AttachmentComponent.prototype.ngOnChanges = function (changes) {
        if (!changes) {
            return;
        }
        if (changes.attachment) {
            this.attachmentTitle = changes.attachment.currentValue.title;
        }
    };
    AttachmentComponent.prototype.deleteAttachment = function () {
        var _this = this;
        var modalRef = this.modalService.open(ConfirmActionModalComponent);
        modalRef.componentInstance.message = extract('Are you sure you want to permanently delete this attachment?');
        modalRef.componentInstance.confirmButtonText = extract('Delete');
        modalRef.result.then(function () {
            _this.isDeleting = true;
            _this.store.dispatch(new DeleteAttachment({
                attachmentId: _this.attachment.id
            }));
        });
    };
    AttachmentComponent.prototype.updateAttachment = function () {
        var body = Object.assign({}, this.attachment, {
            title: this.attachmentTitle
        });
        this.isUpdating = true;
        this.store.dispatch(new UpdateAttachment({
            attachmentId: this.attachment.id,
            body: body
        }));
    };
    AttachmentComponent.prototype.downloadAttachment = function () {
        var _this = this;
        this.isDownloading = true;
        this.downloadService
            .downloadAttachment(this.attachment.filename, this.attachment.id)
            .then(function () { return (_this.isDownloading = false); });
    };
    AttachmentComponent.prototype.enableEditMode = function () {
        var _this = this;
        this.editMode = true;
        setTimeout(function () {
            _this.titleRef.nativeElement.focus();
        });
    };
    AttachmentComponent.prototype.ngOnDestroy = function () { };
    return AttachmentComponent;
}());
export { AttachmentComponent };
