import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
// import { isNumber } from 'util';

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}
export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

@Injectable()
export class NgbDateNativeAdapter extends NgbDateAdapter<Date> {
  fromModel(date: Date): NgbDateStruct {
    return date && date.getFullYear
      ? {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      : null;
  }

  toModel(date: NgbDateStruct): Date {
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { day: +dateParts[0], month: null, year: null };
      } else if (
        dateParts.length === 2 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1])
      ) {
        return {
          day: +dateParts[0],
          month: +dateParts[1],
          year: null
        };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          day: +dateParts[0],
          month: +dateParts[1],
          year: +dateParts[2]
        };
      }
    }
    return null;
  }

  padDigits(number, digits) {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number
    );
  }

  format(date: NgbDateStruct): string {
    return date
      ? `${isNumber(date.day) ? this.padDigits(date.day, 2) : ''}/${
          isNumber(date.month) ? this.padDigits(date.month, 2) : ''
        }/${date.year}`
      : '';
  }
}
