<ul class="tenants list-group"
  [class.list-group-flush]="flush"
  data-cy="tenantList">
  <a *ngFor="let tenant of tenants"
    [tabindex]="tenant.id"
    class="list-group-item list-group-item-action list-group-item-light"
    (click)="pickTenant(tenant.id)">
    {{ tenant?.name }}
  </a>
</ul>
