import { State } from 'app/core/store/store.model';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { UpdateDriver } from 'app/core/store/actions/driver.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as DriverTypes from 'app/core/store/types/driver.types';
import { ApiService } from 'app/core/api/api.service';
import { Observable } from 'rxjs';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-driver-modal',
  templateUrl: './edit-driver-modal.component.html',
})
export class EditDriverModalComponent implements OnDestroy {
  isLoading: boolean;
  @Input()
  driverId;
  private driverInterval: any;
  driver: any;
  isLoadingDriver: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<State>,
    private updates$: Actions,
    private toastService: ToastService,
    private apiService: ApiService
  ) {
    /**
     * https://github.com/ng-bootstrap/ng-bootstrap/issues/2146
     * Inputs in modals don't trigger changedetection. So we use this dirty hack to check if the input has a value
     */
    this.driverInterval = setInterval(() => {
      if (this.driverId && !this.driver && !this.isLoadingDriver) {
        this.isLoadingDriver = true;
        this.getUserContextById(this.driverId).subscribe(response => {
          this.driver = response;
          this.isLoadingDriver = false;
        });
      }
    }, 500);
    this.updates$
      .pipe(ofType(DriverTypes.drivers.UPDATE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastService.showSuccess({
          title: extract('Driver update successfull')
        });
        this.isLoading = false;
        this.activeModal.close();
      });
  }

  ngOnDestroy() {
    clearInterval(this.driverInterval);
  }

  updateDriver(userContextFormValue) {
    const body = {
      user: {
        firstName: userContextFormValue.firstName,
        lastName: userContextFormValue.lastName,
        email: userContextFormValue.email,
        id: this.driver.user.id
      },
      phoneNumber: userContextFormValue.phoneNumber,
      locale: userContextFormValue.locale,
      licensePlate: userContextFormValue.licensePlate
    };
    this.store.dispatch(
      new UpdateDriver({
        driverId: this.driverId,
        driver: body
      })
    );
    this.isLoading = true;
  }

  getUserContextById(userContextId: string): Observable<any> {
    return this.apiService.get({
      path: `/user_contexts/${userContextId}`
    });
  }
}
