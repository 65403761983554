<app-purchase-order-form
  [suppliers]="suppliers"
  [isLoading]="isLoading"
  [isLoadingSuppliers]="isLoadingSuppliers"
  [isLoadingTaxRates]="isLoadingTaxRates"
  [taxRates]="taxRates"
  [transports]="filteredTransports"
  (searchTransports)="handleSearchTransports($event)"
  (selectTransport)="handleSelectTransport($event)"
  (supplierSelected)="handleSelectSupplier($event)"
  (removeTransport)="handleRemoveTransport($event)"
  (loadNextTransportPage)="loadNextTransportsPage($event)"
  (purchaseOrderFormSubmitted)="createNewPurchaseOrder($event)"
  (onSaveAndDownloadAsPDF)="createAndDownloadAsPDF($event)"
></app-purchase-order-form>
