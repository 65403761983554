import { MarketPostDemandReply } from '../../store/models/market-post-demand-replies.model';
import { State } from '../../store/store.model';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadMarketPostDemandReplies } from '../../store/actions/market-post-demand-replies.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-market-post-demand-replies',
  templateUrl: 'market-post-demand-replies.component.html',
})

export class MarketPostDemandRepliesComponent implements OnDestroy {
  marketPostDemandReplies: MarketPostDemandReply[];
  loading = true;
  constructor(
    private store: Store<State>
  ) {
    this.store.dispatch(new LoadMarketPostDemandReplies());
    this.store.select(state => state.marketPostDemandReplies)
      .pipe(untilDestroyed(this))
      .subscribe(mpdReplies => {
        if (mpdReplies) {
          this.marketPostDemandReplies = mpdReplies;
          this.loading = false;
        }
      });
  }

  ngOnDestroy() { }
}
