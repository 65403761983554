import { Router } from '@angular/router';
import { OnInit, Renderer, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Autocomplete } from 'app/services/autocomplete.service';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/circle.types';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
var NewCircleComponent = /** @class */ (function () {
    function NewCircleComponent(fb, autocompleteService, renderer, createUpdates$, editUpdates$, router, toastr) {
        var _this = this;
        this.fb = fb;
        this.autocompleteService = autocompleteService;
        this.renderer = renderer;
        this.createUpdates$ = createUpdates$;
        this.editUpdates$ = editUpdates$;
        this.router = router;
        this.toastr = toastr;
        this.circleSubmitted = new EventEmitter();
        this.loading = true;
        this.loadingCircle = false;
        this.editMode = false;
        this.submitting = false;
        this.success = false;
        this.formatter = function (x) { return x.label; };
        this.searchMember = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.autocompleteService.search('member', term);
            }));
        };
        createUpdates$
            .pipe(ofType(Types.circles.CREATE_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.createForm();
            _this.submitting = false;
            _this.toastr.showSuccess({
                message: 'Circle successfully added!'
            });
            _this.success = true;
        });
        editUpdates$
            .pipe(ofType(Types.circles.EDIT_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.submitting = false;
            _this.router.navigate(['/settings/circles']);
        });
    }
    NewCircleComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    NewCircleComponent.prototype.ngOnDestroy = function () { };
    NewCircleComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.circle && changes.circle.currentValue) {
            this.createForm();
            this.circleForm.patchValue({
                name: changes.circle.currentValue.name
            });
            changes.circle.currentValue.members.forEach(function (member) {
                _this.pushMemberToControl({
                    label: member.name,
                    id: member.id
                });
            });
        }
    };
    NewCircleComponent.prototype.createForm = function () {
        this.circleForm = this.fb.group({
            name: ['', Validators.required],
            members: this.fb.array([])
        });
    };
    Object.defineProperty(NewCircleComponent.prototype, "name", {
        get: function () {
            return this.circleForm.get('name');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewCircleComponent.prototype, "members", {
        get: function () {
            return this.circleForm.get('members');
        },
        enumerable: true,
        configurable: true
    });
    NewCircleComponent.prototype.addMember = function (event, input) {
        event.preventDefault();
        // Check if members contain the selected member
        this.pushMemberToControl(event.item);
        // clear the input field
        this.renderer.setElementProperty(input, 'value', '');
    };
    NewCircleComponent.prototype.pushMemberToControl = function (item) {
        var control = this.circleForm.controls['members'];
        if (!control.value.find(function (m) { return m.label === item.label; })) {
            control.push(this.fb.group(item));
        }
    };
    NewCircleComponent.prototype.removeMember = function (index) {
        this.members.removeAt(index);
    };
    NewCircleComponent.prototype.onSubmit = function () {
        var body = {
            name: this.circleForm.get('name').value,
            members: this.circleForm.get('members').value.map(function (m) {
                return '/api/v1/tenants/' + m.id;
            })
        };
        this.submitting = true;
        this.circleSubmitted.emit(body);
    };
    NewCircleComponent.prototype.checkUniqueName = function () {
        var _this = this;
        if (this.circles) {
            if (this.circles.some(function (circle) {
                return circle.name.toLowerCase() ===
                    _this.circleForm.get('name').value.toLowerCase();
            })) {
                this.circleForm.get('name').setErrors({ notUnique: true });
                return true;
            }
            else {
                return false;
            }
        }
    };
    return NewCircleComponent;
}());
export { NewCircleComponent };
