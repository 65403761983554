<div class="prenotification-grid">
  <div class="card-body">
    <div class="form-row">
      <div class="col pl-0">
        <div class="form-group">
          <label for="location">{{ 'Location' | translate }}</label>
          <ng-select
            id="location"
            [items]="locations"
            [ngClass]="{ 'is-invalid': submitAttempt && !location }"
            [loading]="isLoadingLocations"
            placeholder="{{ 'Select prenotification location' | translate }}"
            [(ngModel)]="location"
            (change)="handleLocationSelected($event)"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col pl-0">
        <div class="form-group">
          <label for="scheduledAt">{{ 'Schedule At' | translate }}</label>
          <div
            class="input-group"
            ngbTooltip="{{ 'Select a location to continue' | translate }}"
            [disableTooltip]="location"
          >
            <input
              class="form-control"
              [ngClass]="{ 'is-invalid': submitAttempt && !scheduledAt }"
              (click)="d3.toggle()"
              placeholder="{{ 'Scheduled at (dd/mm/yyyy)' | translate }}"
              name="d3"
              [(ngModel)]="scheduledAt"
              ngbDatepicker
              [disabled]="!location"
              placement="bottom-left"
              #d3="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-dark"
                type="button"
                [disabled]="!location"
                (click)="d3.toggle()"
              >
                <i class="far fa-calendar-alt"></i>
              </button>
            </div>
            <div class="invalid-feedback">
              {{ 'Please use dd/mm/yyyy to format your dates.' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row mb-3">
      <div class="col pl-0">
        <label for="driver">{{ 'Driver' | translate }}</label>
        <div
          class="d-flex justify-content-between"
          ngbTooltip="{{ 'Select a location to continue' | translate }}"
          [disableTooltip]="location"
        >
          <div class="w-100 mr-1">
            <ng-select
              id="drivers"
              [items]="drivers"
              bindLabel="name"
              bindValue="id"
              [loading]="isLoadingDrivers"
              [disabled]="
                !location && (!selectedTasks || selectedTasks.length === 0)
              "
              placeholder="{{ 'Select driver' | translate }}"
              [(ngModel)]="driver"
              data-cy="driver"
            >
            </ng-select>
            <div class="invalid-feedback">
              {{ 'Driver is required.' | translate }}
            </div>
          </div>
          <button
            class="mr-1 btn btn-sm btn-info"
            type="button"
            [disabled]="!driver"
            *ngIf="driver"
            (click)="openEditDriverModal()"
          >
            <span class="icon"> <i class="far fa-pencil"></i> </span>
          </button>
          <button
            class="btn btn-sm btn-success text-white"
            type="button"
            *ngIf="!driver"
            (click)="openNewDriverModal()"
          >
            <span class="icon"> <i class="far fa-plus"></i> </span>
          </button>
        </div>
        <div
          *ngIf="selectedDriver && !selectedDriver.licensePlate"
          class="text-danger"
        >
          {{ 'This driver has no license plate' | translate }}
        </div>
      </div>
    </div>
    <div
      class="prenotification-transports mb-3"
      [(dragulaModel)]="selectedTasks"
      dragula="{{ dragulaTransports }}"
    >
      <div
        *ngIf="!selectedTasks || selectedTasks.length === 0; else transports"
      >
        <div class="prenotification-transports--title">
          <span *ngIf="!location">{{
            'Select a location to load transports.' | translate
          }}</span>
          <span *ngIf="location"
            >{{ 'Click or drag transports to add them' | translate }}
            <i class="far fa-fw fa-arrow-right"></i>
          </span>
        </div>
      </div>
      <ng-template #transports>
        <div *ngFor="let task of selectedTasks">
          <app-transport-info
            [transport]="task.transport"
            (click)="handleTransportSelected(task)"
          >
            <div
              class="text-danger reference-warning"
              *ngIf="task.badgeColor === 'badge-danger'"
            >
              <span *ngIf="!task.reference">{{
                "Transport task doesn't have a reference" | translate
              }}</span>
              <span
                *ngIf="task.referenceCheckedAt && !task.referenceVerified"
                >{{
                  'Transport task verification had failed' | translate
                }}</span
              >
            </div>
          </app-transport-info>
        </div>
      </ng-template>
    </div>
    <div class="form-row">
      <button
        class="btn btn-primary text-white"
        (click)="onSubmit()"
        [disabled]="
          isLoading ||
          !location ||
          !scheduledAt ||
          !selectedDriver ||
          !selectedDriver.licensePlate
        "
      >
        <span translate class="btn__label" [ngClass]="{ invisible: isLoading }">
          <span *ngIf="visit">{{ 'Save' | translate }}</span>
          <span *ngIf="!visit">{{ 'Create prenotification' | translate }}</span>
        </span>
        <i
          class="fa fa-spinner fa-spin btn__loader"
          [ngClass]="{ visible: isLoading }"
        ></i>
      </button>
    </div>
  </div>
  <div class="to-be-prenotified-transports p-2">
    <div class="sticky-transports">
      <div class="text-center m-2">
        <h4>{{ 'Transports' | translate }}</h4>
      </div>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Type here to filter..."
          [(ngModel)]="query"
          (ngModelChange)="search($event)"
          aria-label="Filter transports"
          aria-describedby="basic-addon2"
        />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">
            <i class="far fa-fw fa-search"></i>
          </span>
        </div>
      </div>
      <p *ngIf="isLoadingTasks">
        <ngb-progressbar
          type="info"
          [value]="100"
          [striped]="true"
          [animated]="true"
          >{{ 'Loading transports' | translate }}</ngb-progressbar
        >
      </p>
      <div class="pre-scrollable">
        <div
          id="unplanned-transports-list"
          [(dragulaModel)]="tasks"
          dragula="{{ dragulaTransports }}"
        >
          <app-transport-info
            class="transport"
            *ngFor="let task of tasks"
            (click)="handleTransportSelected(task)"
            [transportId]="task.transport?.id"
            [transport]="task.transport"
          >
            <div
              class="text-danger reference-warning"
              *ngIf="task.badgeColor === 'badge-danger'"
            >
              <span *ngIf="!task.reference">{{
                "Transport task doesn't have a reference" | translate
              }}</span>
              <span
                *ngIf="task.referenceCheckedAt && !task.referenceVerified"
                >{{
                  'Transport task verification had failed' | translate
                }}</span
              >
            </div>
          </app-transport-info>
        </div>
      </div>
    </div>
  </div>
</div>
