<form
  (ngSubmit)="onSubmit()"
  [formGroup]="addressForm"
  class="p-3 grid-container"
>
  <div class="form-group" id="attention-fg">
    <input
      class="form-control"
      formControlName="attention"
      placeholder="Attention"
    />
    <div class="invalid-feedback">
      {{ 'Attention is required.' | translate }}
    </div>
  </div>
  <div class="form-group" id="street-and-number-fg">
    <input
      class="form-control"
      formControlName="streetAndNumber"
      placeholder="Street + number"
    />
    <div class="invalid-feedback">
      {{ 'Street and number is required.' | translate }}
    </div>
  </div>
  <div class="split-fg">
    <div class="form-group" id="zipcode-fg">
      <input
        class="form-control"
        formControlName="zipCode"
        placeholder="Zip code"
      />
      <div class="invalid-feedback">
        {{ 'Zip code is required.' | translate }}
      </div>
    </div>
    <div class="form-group" id="city-fg">
      <input class="form-control" formControlName="city" placeholder="City" />
      <div class="invalid-feedback">{{ 'City is required.' | translate }}</div>
    </div>
  </div>
  <div class="form-group" id="country-fg">
    <ng-select
      id="country"
      [items]="countries"
      bindLabel="label"
      bindValue="value"
      placeholder="{{ 'Select country' | translate }}"
      formControlName="country"
    >
    </ng-select>
  </div>
  <div id="submit">
    <app-button [isLoading]="isLoading" [type]="'submit'">
      <span *ngIf="!address">{{ 'Create' | translate }}</span>
      <span *ngIf="address">{{ 'Save' | translate }}</span>
    </app-button>
  </div>
</form>
