export const matching = {
  LOAD_MATCHES: '[Matching] Load',
  LOAD_MATCHES_SUCCEEDED: '[Matching] Load Success',
  LOAD_MATCHES_FAILED: '[Matching]: Load Fail',
  CREATE_MATCH_REPLY: '[Matching] Create reply',
  CREATE_MATCH_REPLY_SUCCEEDED: '[Matching] Create reply Success',
  CREATE_MATCH_REPLY_FAILED: '[Matching]: Create reply Fail',
  LOAD_ACTIVE_MATCHING_POOL_ITEMS: '[Matching] Load Active Matching Pool Items',
  LOAD_ACTIVE_MATCHING_POOL_ITEMS_SUCCEEDED:
    '[Matching] Load Active Matching Pool Items Success',
  LOAD_ACTIVE_MATCHING_POOL_ITEMS_FAILED:
    '[Matching]: Load Active Matching Pool Items Fail',
  LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE:
    '[Matching] Load Next Active Matching Pool Items Page',
  LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE_SUCCEEDED:
    '[Matching] Load Next Active Matching Pool Items Page Success',
  LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE_FAILED:
    '[Matching]: Load Next Active Matching Pool Items Page Fail',
  ENABLE_MATCHING: '[Matching] Enable',
  ENABLE_MATCHING_SUCCEEDED: '[Matching] Enable Success',
  ENABLE_MATCHING_FAILED: '[Matching] Enable Fail',
  DISABLE_MATCHING: '[Matching] Disable',
  DISABLE_MATCHING_SUCCEEDED: '[Matching] Disable Success',
  DISABLE_MATCHING_FAILED: '[Matching] Disable Fail',
  DELETE_MATCHING_POOL_OPTION: '[Matching] Delete Matching Pool Option',
  DELETE_MATCHING_POOL_OPTION_SUCCEEDED:
    '[Matching] Delete Matching Pool Option Success',
  DELETE_MATCHING_POOL_OPTION_FAILED:
    '[Matching]: Delete Matching Pool Option Fail',
  CLEAR: '[Matching]: Clear'
};
