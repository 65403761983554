import * as CustomerActions from '../actions/customer.actions';
import { Address } from '../models/address.model';
import { BusinessContact } from '../models/business-contact.model';
import { Customer } from '../models/customer.model';
import * as addressTypes from '../types/address.types';
import * as businessContactTypes from '../types/business-contact.types';
import * as types from '../types/customer.types';

export interface CustomersState {
  customers: Customer[];
  filteredCustomers: Customer[];
}

const INITIAL_STATE = {
  customers: null,
  filteredCustomers: null,
};

export function CustomersReducer(
  state = INITIAL_STATE,
  action: CustomerActions.All
): CustomersState {
  switch (action.type) {

    case types.customer.LOAD_CUSTOMERS_SUCCEEDED:
      return Object.assign({}, state, {
        customers: [...action.payload['hydra:member']],
        filteredCustomers: [...action.payload['hydra:member']]
      });

    case types.customer.LOAD_FILTERED_CUSTOMERS_SUCCEEDED:
      return Object.assign({}, state, {
        filteredCustomers: [...action.payload['hydra:member']]
      });

    case types.customer.UPDATE_CUSTOMER_SUCCEEDED:
      if (!state || !state.customers || !state.filteredCustomers) {
        return state;
      }
      let customers = [...state.customers];
      let filteredCustomers = [...state.filteredCustomers];

      let customerIndex = customers.findIndex(i => i.id === action.payload.id);
      let tempCustomer = Object.assign(
        {},
        state.customers[customerIndex],
        action.payload
      );
      customers[customerIndex] = tempCustomer;
      let filteredCustomerIndex = filteredCustomers.findIndex(
        i => i.id === action.payload.id
      );
      let tempFilteredCustomer = Object.assign(
        {},
        filteredCustomers[filteredCustomerIndex],
        action.payload
      );
      filteredCustomers[filteredCustomerIndex] = tempFilteredCustomer;
      return Object.assign({}, state, {
        customers,
        filteredCustomers
      });
    case types.customer.DELETE_CUSTOMER_SUCCEEDED:
      if (!state || !state.customers || !state.filteredCustomers) {
        return state;
      }
      customers = [
        ...state.customers.filter(
          (customer: Customer) => customer.id !== action.payload
        )
      ];
      filteredCustomers = [
        ...state.filteredCustomers.filter(
          (customer: Customer) => customer.id !== action.payload
        )
      ];
      return Object.assign({}, state, {
        customers,
        filteredCustomers
      });

    case businessContactTypes.BusinessContact.DELETE_BUSINESS_CONTACT_SUCCEEDED:
      if (!state || !state.customers || !state.filteredCustomers) {
        return state;
      }
      customers = [...state.customers];
      filteredCustomers = [...state.filteredCustomers];
      customerIndex = customers.findIndex(
        i => i.id === action.payload.businessId
      );
      filteredCustomerIndex = filteredCustomers.findIndex(
        i => i.id === action.payload.businessId
      );
      if (customerIndex !== -1) {
        tempCustomer = Object.assign(
          {},
          state.customers[customerIndex]
        ) as Customer;
        tempCustomer.contacts = tempCustomer.contacts.filter(
          (c: BusinessContact) => c.id !== action.payload.businessContactId
        );
        customers[customerIndex] = tempCustomer;
      }

      if (filteredCustomerIndex !== -1) {
        tempFilteredCustomer = Object.assign(
          {},
          state.filteredCustomers[filteredCustomerIndex]
        ) as Customer;
        tempFilteredCustomer.contacts = tempFilteredCustomer.contacts.filter(
          (c: BusinessContact) => c.id !== action.payload.businessContactId
        );
        filteredCustomers[filteredCustomerIndex] = tempFilteredCustomer;
      }

      return Object.assign({}, state, {
        customers,
        filteredCustomers
      });

    case addressTypes.address.DELETE_ADDRESS_SUCCEEDED:
      if (!state || !state.customers || !state.filteredCustomers) {
        return state;
      }
      customers = [...state.customers];
      filteredCustomers = [...state.filteredCustomers];
      customerIndex = customers.findIndex(
        i => i.id === action.payload.businessId
      );
      filteredCustomerIndex = filteredCustomers.findIndex(
        i => i.id === action.payload.businessId
      );
      if (customerIndex !== -1) {
        tempCustomer = Object.assign(
          {},
          state.customers[customerIndex]
        ) as Customer;
        tempCustomer.addresses = tempCustomer.addresses.filter(
          (a: Address) => a.id !== action.payload.addressId
        );
        customers[customerIndex] = tempCustomer;
      }

      if (filteredCustomerIndex !== -1) {
        tempFilteredCustomer = Object.assign(
          {},
          state.filteredCustomers[filteredCustomerIndex]
        ) as Customer;
        tempFilteredCustomer.addresses = tempFilteredCustomer.addresses.filter(
          (a: Address) => a.id !== action.payload.addressId
        );
        filteredCustomers[filteredCustomerIndex] = tempFilteredCustomer;
      }

      return Object.assign({}, state, {
        customers,
        filteredCustomers
      });

    case types.customer.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
  }
  return state;
}
