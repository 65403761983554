import { Actions, ofType } from '@ngrx/effects';
import { CreateProduct } from 'app/core/store/actions/product.actions';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import * as ProductTypes from 'app/core/store/types/product.types';
import { Product } from 'app/core/store/models/product.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-tariff-modal',
  templateUrl: './new-tariff-modal.component.html',
})
export class NewTariffModalComponent implements OnDestroy {
  @Input()
  searchTerm: string;
  @Input()
  tariffType: string;
  @Input()
  showTariffTypeButtons: boolean;

  tariff: Product;
  isLoading: boolean;

  constructor(
    private modal: NgbActiveModal,
    private store: Store<State>,
    private updates$: Actions
  ) {
    this.updates$
      .pipe(ofType(ProductTypes.product.CREATE_PRODUCT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.modal.close(this.tariff);
      });
  }

  createNewTariff(tariffFormValue) {
    this.tariff = Object.assign({}, tariffFormValue, {
      template: true
    }) as Product;
    this.isLoading = true;
    this.store.dispatch(new CreateProduct(this.tariff));
  }

  ngOnDestroy() { }
}
