import { TaxRate } from 'app/core/store/models/tax-rate.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-tax-rate-form',
  templateUrl: './tax-rate-form.component.html',
})
export class TaxRateFormComponent implements OnChanges {
  @Input()
  isLoading: boolean;
  @Input()
  taxRate: TaxRate;

  @Output()
  taxRateFormSubmitted = new EventEmitter();

  taxRateForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.taxRate) {
      const taxRate = changes.taxRate.currentValue;
      if (!taxRate) {
        return;
      }
      if (!this.taxRateForm) {
        this.createForm();
      }
      this.taxRateForm.patchValue({
        label: taxRate.label,
        rate: taxRate.rate
      });
    }
  }

  createForm() {
    this.taxRateForm = this.fb.group({
      label: [null, Validators.required],
      rate: [null, Validators.required]
    });
  }

  onSubmit() {
    if (this.taxRateForm.invalid) {
      console.log('tax rate form invalid');
      return;
    }
    const taxRateFromValue = Object.assign({}, this.taxRateForm.value);
    this.taxRateFormSubmitted.emit(taxRateFromValue);
  }
}
