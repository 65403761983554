import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { BusinessContact } from 'app/core/store/models/business-contact.model';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
})
export class ContactFormComponent implements OnChanges {
  @Input()
  contact: BusinessContact;
  @Input()
  isLoading: boolean;
  @Output()
  contactFormSubmitted = new EventEmitter();

  contactForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.contact) {
      if (!this.contactForm) {
        this.createForm();
      }
      const contact = changes.contact.currentValue;
      this.contactForm.patchValue({
        salutation: contact.salutation,
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        telephone: contact.telephone,
        designation: contact.designation,
        department: contact.department
      });
    }
  }

  createForm(): any {
    this.contactForm = this.fb.group({
      salutation: [null, Validators.required],
      email: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      telephone: [null],
      designation: [null],
      department: [null]
    });
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      return;
    }
    this.contactFormSubmitted.emit(this.contactForm.value);
  }
}
