import { Component, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';
import { LoadDefaultCertificatePrice } from 'app/core/store/actions/certiweight.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { LoadWallet } from 'app/core/store/actions/wallet.actions';
import { Session } from 'app/core/store/models/session.model';
import { Wallet } from 'app/core/store/models/wallet.model';
import { State } from 'app/core/store/store.model';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

import { BuyCertificateModalComponent } from './buy-certificate-modal.component';

@Component({
  selector: 'app-buy-certificate',
  templateUrl: './buy-certificate.component.html',
})
export class BuyCertificateComponent implements OnDestroy {
  search$ = new Subject<string>();
  loading = false;
  totalItems = 0;
  rows = [];
  session: Session;
  wallet: Wallet;
  defaultPrice = null;

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private store: Store<State>,
    private toastr: ToastService,
  ) {
    const emptyResponse = (x: string) => (<any>{
      'hydra:member': [{ containerNumber: x, mock: true, certificates: [] }],
      'hydra:totalItems': 1,
    });

    this.store.select('marketplace', 'liners')
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (!liners) {
          this.store.dispatch(new LoadLiners());
        }
      });

    this.store
      .select('certiweight')
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.defaultPrice) {
          this.store.dispatch(new LoadDefaultCertificatePrice());
          return;
        }
        this.defaultPrice = result.defaultPrice;
      });

    this.store.dispatch(new LoadWallet());
    this.store
      .select('wallet')
      .pipe(untilDestroyed(this))
      .subscribe(walletState => {
        if (walletState && walletState['@id'] !== '') {
          this.wallet = walletState;
        }
      });


    this.store.select('session').subscribe(session => this.session = session);

    this.totalItems = 1;
    this.rows = emptyResponse(
      extract('Type your container details into the search box')
    )['hydra:member'];

    this.search$.pipe(
      untilDestroyed(this),
      switchMap(x => {
        if (!x) {
          return of(emptyResponse(
            extract('Type your container details into the search box')
          ));
        }
        this.loading = true;
        return this.api.get({
          path: `/container_measurements?bookingReference=${x}&containerNumber=${x}`
        }).pipe(catchError(err => emptyResponse(err)));
      }),
    ).subscribe(x => {
      this.loading = false;
      this.totalItems = x['hydra:totalItems'];
      this.rows = x['hydra:member'];
      this.rows.forEach(r => r.certificate = r.certificates.find(
        c => c.tenant.id === this.session.tenant));
    });
  }

  buy(row) {
    if (!this.wallet || this.wallet.credits < (row.site ? row.site.servicePrice : 0.99)) {
      this.toastr.showDanger({
        title: extract('Insufficient credits'),
        message: extract('Please reload your credits in order to buy this certificate'),
      });
      return;
    }
    const modalRef = this.modalService.open(BuyCertificateModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.data = row;
  }

  ngOnDestroy() { }
}
