<form *ngIf="containerForm" [formGroup]="containerForm">
  <div class="form-group">
    <ng-select
      id="containerType"
      [items]="containerTypes"
      data-cy="containerType"
      bindLabel="label"
      bindValue="id"
      placeholder="{{ 'Select Container Type' | translate }}"
      formControlName="containerType"
      [ngClass]="{
        'is-invalid': submitAttempt && !containerForm.get('containerType').value
      }"
    >
    </ng-select>
  </div>
  <div class="form-group">
    <ng-select
      id="liner"
      [items]="liners"
      bindLabel="name"
      bindValue="id"
      data-cy="liner"
      placeholder="{{ 'Select Liner' | translate }}"
      formControlName="liner"
      [ngClass]="{
        'is-invalid': submitAttempt && !containerForm.get('liner').value
      }"
    >
    </ng-select>
  </div>
  <div class="form-group">
    <input
      placeholder="{{ 'Weight (T)' | translate }}"
      formControlName="weight"
      id="weight"
      type="text"
      data-cy="weight"
      class="form-control"
    />
  </div>
  <div>
    <div *ngIf="!needsConfirmation" class="form-group">
      <input
        placeholder="{{ 'Container number' | translate }}"
        formControlName="number"
        id="number"
        type="text"
        data-cy="containerNumber"
        class="form-control"
        [ngClass]="{
          'is-invalid': containerForm.get('number').invalid
        }"
      />
      <div class="invalid-feedback">
        <p *ngIf="containerForm.get('number').invalid">
          {{
            "Container number does't have the correct ISO 6346 format."
              | translate
          }}
        </p>
      </div>
    </div>
    <label class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="adr"
      />
      <span class="custom-control-label">{{ 'ADR' | translate }}</span>
    </label>
  </div>
  <ng-content></ng-content>
</form>
