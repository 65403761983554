import { Routes } from '@angular/router';
import { SubscriptionGuard } from 'app/core/auth/route-guards/subscription.guard';
import { TenantGuard } from 'app/core/auth/route-guards/tenant.guard';
import { Route } from 'app/core/route.service';
import { AuthGuard } from 'app/core/user/user.authguard';
import { CustomersComponent } from '../../crm/customers/customers.component';
import { EditCustomerComponent } from '../../crm/edit-customer/edit-customer.component';
import { NewCustomerComponent } from '../../crm/new-customer/new-customer.component';
import { CertificateDetailComponent } from '../certificate-detail.component';
import { CertiweightBackOfficeComponent } from './certiweight-back-office.component';
import { EditPreannouncementComponent } from './edit-preannouncement.component';
import { LocationCertificatesComponent } from './location-certificates.component';
import { NewPreannouncementComponent } from './new-preannouncement.component';
import { PreannouncementsComponent } from './preannouncements.component';
var ɵ0 = { subscriptionCode: 'CERTIWEIGHT_BACK_OFFICE' };
var routes = Route.withShell([
    {
        path: 'certiweight-backoffice',
        canActivate: [AuthGuard, TenantGuard, SubscriptionGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: CertiweightBackOfficeComponent,
            },
            {
                path: ':id/detail',
                component: CertificateDetailComponent,
            },
            {
                path: 'certificates',
                component: LocationCertificatesComponent,
            },
            {
                path: 'customers',
                children: [
                    {
                        path: '',
                        component: CustomersComponent
                    },
                    {
                        path: 'new',
                        component: NewCustomerComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditCustomerComponent
                    }
                ]
            },
            {
                path: 'preannouncements',
                children: [
                    {
                        path: '',
                        component: PreannouncementsComponent
                    },
                    {
                        path: 'new',
                        component: NewPreannouncementComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditPreannouncementComponent
                    }
                ]
            },
        ],
    }
]);
var CertiweightBackOfficeModule = /** @class */ (function () {
    function CertiweightBackOfficeModule() {
    }
    return CertiweightBackOfficeModule;
}());
export { CertiweightBackOfficeModule };
export { ɵ0 };
