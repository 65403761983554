<div class="grid-container">
  <form
    *ngIf="invoiceForm"
    (ngSubmit)="onSubmit()"
    [formGroup]="invoiceForm"
    class="p-3 form-grid-container"
  >
    <div id="invoice-form-top">
      <div class="form-group">
        <label class="center-block">{{ 'Bill to' | translate }}</label>
        <ng-select
          id="customer"
          [items]="customers"
          [loading]="isLoadingCustomers"
          placeholder="{{ 'Select customer' | translate }}"
          data-cy="customer"
          formControlName="business"
          (change)="handleCustomerSelected()"
        >
        </ng-select>
      </div>
      <app-address
        *ngIf="customerAddress"
        [billingAddress]="true"
        [address]="customerAddress"
      ></app-address>
    </div>
    <div id="dates" class="mt-3">
      <div class="input-group">
        <input
          class="form-control"
          formControlName="date"
          placeholder="{{ 'Invoice date' | translate }}"
          name="d2"
          data-cy="invoiceDate"
          (click)="d2.toggle()"
          ngbDatepicker
          placement="bottom-left"
          #d2="ngbDatepicker"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-dark"
            type="button"
            (click)="d2.toggle()"
          >
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
        <div class="invalid-feedback">
          {{ 'Please use dd/mm/yyyy to format your dates.' | translate }}
        </div>
      </div>
      <div class="input-group">
        <input
          class="form-control"
          formControlName="dueDate"
          placeholder="{{ 'Due date' | translate }}"
          name="d3"
          data-cy="dueDate"
          (click)="d3.toggle()"
          ngbDatepicker
          placement="bottom-left"
          #d3="ngbDatepicker"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-dark"
            type="button"
            (click)="d3.toggle()"
          >
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
        <div class="invalid-feedback">
          {{ 'Please use dd/mm/yyyy to format your dates.' | translate }}
        </div>
      </div>
    </div>
    <div id="invoice-items-table">
      <table class="table table-borderless mt-3">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ 'Item' | translate }}</th>
            <th scope="col">{{ 'Quantity' | translate }}</th>
            <th scope="col">{{ 'Unit price' | translate }}</th>
            <th scope="col" id="tax-header">{{ 'Tax %' | translate }}</th>
            <th scope="col">{{ 'Total' | translate }}</th>
            <th scope="col" class="invisible-header"></th>
            <th scope="col" class="invisible-header"></th>
          </tr>
        </thead>
        <tbody formArrayName="items">
          <ng-template
            ngFor
            let-item
            [ngForOf]="invoiceForm.get('items')['controls']"
            let-i="index"
          >
            <tr [formGroupName]="i">
              <td class="p-0" width="30%">
                <input
                  *ngIf="!item.value['transport']"
                  class="form-control"
                  data-cy="invoiceItem"
                  type="text"
                  (focus)="
                    i === invoiceForm.get('items').value.length - 1
                      ? addInvoiceItem()
                      : ''
                  "
                  formControlName="description"
                />
                <div *ngIf="item.value['transport']" class="invoice-transport">
                  <div class="invoice-transport__top">
                    <div class="row">
                      <div class="col-6 p-1" ngbTooltip="{{ 'Type' | translate }}">
                        <i
                                class="far fa-fw"
                                [ngClass]="getTransportIcon(item.value.transport)"
                        ></i>
                        <span>
                          {{ item.value['transport']['transportType']['label'] | titlecase }}
                        </span>
                      </div>
                      <div class="col-6 p-1" ngbTooltip="{{ 'Reference' | translate }}">
                        <i
                                class="font-weight-bold far fa-fw fa-truck-container"
                        ></i>
                        <span>
                          {{ item.value['transport'].reference }}
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 p-1" ngbTooltip="{{ 'Date' | translate }}">
                        <i
                                class="far fa-fw fa-calendar-alt"
                        ></i>
                        <span>
                          {{ getPrimaryTaskDate(item.value['transport']) }}
                        </span>
                      </div>
                      <div class="col-6 p-1" ngbTooltip="{{ 'Container' | translate }} {{ 'Reference' | translate | lowercase }}">
                        <i
                                class="far fa-fw fa-container-storage"
                        ></i>
                        <span>
                          {{ item.value['transport'].container.number  }}
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 p-1" ngbTooltip="{{ 'Location' | translate }}">
                        <i
                                class="far fa-fw fa-location-arrow"
                        ></i>
                        <span>
                          {{ getPrimaryTaskLocation(item.value['transport']) }}
                        </span>
                      </div>
                      <div class="col-6 p-1" ngbTooltip="{{ 'Customer' | translate }} {{ 'Reference' | translate | lowercase }}">
                        <i
                                class="far fa-fw fa-user"
                        ></i>
                        <span>
                          {{ item.value['transport'].customerReference }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <ul class="m-0 p-0">
                      <li
                        *ngFor="
                          let part of item.value['transport'].tariff.parts
                        "
                        class="d-flex justify-content-between"
                      >
                        <span>{{ part.description }}:</span>
                        <span
                          >{{ part.quantity }} x
                          {{ part.unitPrice | currency: 'EUR':'code' }}</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="p-0">
                <input
                  class="form-control"
                  type="number"
                  [disabled]="item.value.transport"
                  (keyup)="updateTotal(i)"
                  (change)="updateTotal(i)"
                  data-cy="quantity"
                  (focus)="
                    i === invoiceForm.get('items').value.length - 1
                      ? addInvoiceItem()
                      : ''
                  "
                  formControlName="quantity"
                />
              </td>
              <td class="p-0">
                <input
                  class="form-control"
                  type="number"
                  (keyup)="updateTotal(i)"
                  (change)="updateTotal(i)"
                  (focus)="
                    i === invoiceForm.get('items').value.length - 1
                      ? addInvoiceItem()
                      : ''
                  "
                  data-cy="unitPrice"
                  formControlName="unitPrice"
                />
              </td>
              <td class="p-0">
                <ng-select
                  id="tax"
                  class="table-select"
                  [loading]="isLoadingTaxRates"
                  [items]="taxRates"
                  (keyup)="updateTotal(i)"
                  (change)="updateTotal(i)"
                  formControlName="taxRate"
                >
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.label }} ({{ item.rate }})
                  </ng-template>
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <b [ngOptionHighlight]="search">
                      {{ item.label }} ({{ item.rate }})
                    </b>
                  </ng-template>
                  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                    <div
                      class="ng-option disabled"
                      (click)="openTaxRateModal(i, searchTerm)"
                    >
                      {{
                        'Tax rate not found; click here to add it' | translate
                      }}
                    </div>
                  </ng-template>
                </ng-select>
              </td>
              <td class="p-0">
                <input
                  class="form-control"
                  type="text"
                  disabled="true"
                  formControlName="total"
                />
              </td>
              <td class="p-0">
                <button
                  (click)="duplicateItem(i)"
                  type="button"
                  class="btn btn-link"
                >
                  <i
                    class="text-dark far fa-fw fa-copy"
                    ngbTooltip="{{ 'Copy' | translate }}"
                  ></i>
                </button>
              </td>
              <td class="p-0">
                <button
                  *ngIf="i !== invoiceForm.get('items').value.length - 1"
                  (click)="removeInvoiceItem(i)"
                  class="btn btn-link"
                  type="button"
                >
                  <i
                    class="far fa-fw fa-trash-alt text-danger"
                    ngbTooltip="{{ 'Delete' | translate }}"
                  ></i>
                </button>
                <button
                  *ngIf="i === invoiceForm.get('items').value.length - 1"
                  (click)="addInvoiceItem()"
                  class="btn btn-link"
                  type="button"
                >
                  <i
                    class="far fa-fw fa-plus text-success"
                    ngbTooltip="{{ 'New line' | translate }}"
                  ></i>
                </button>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div id="invoice-total">
      <div class="invoice-total--item">
        <span>{{ 'Sub total' | translate }}</span>
        <span>{{ subTotal | currency: 'EUR':'code' }}</span>
      </div>
      <div class="invoice-total--item">
        <span>{{ 'Taxes' | translate }}</span>
        <span>{{ taxes | currency: 'EUR':'code' }}</span>
      </div>
      <div class="invoice-total--item bg-gray-light">
        <span>{{ 'Total' | translate }}</span>
        <span>{{ total | currency: 'EUR':'code' }}</span>
      </div>
    </div>
    <div id="contact-and-comment">
      <div id="contact">
        <label class="center-block">{{ 'Main contact' | translate }}</label>
        <app-contact
          *ngIf="customerMainContact"
          [contact]="customerMainContact"
          [mainContact]="true"
        ></app-contact>
      </div>
      <div id="comment">
        <label class="center-block">&nbsp;</label>
        <textarea
          id="notes"
          class="form-control"
          maxlength="256"
          formControlName="notes"
          placeholder="{{ 'Add comment for client' | translate }}"
          data-cy="notes"
          cols="20"
          rows="2"
        ></textarea>
      </div>
    </div>
    <div id="submit-buttons">
      <app-button
        id="submit-button"
        [isLoading]="isLoading"
        [isDisabled]="!invoiceForm.valid"
        data-cy="saveButton"
        [type]="'submit'"
        [btnClass]="'btn btn-primary text-white'"
      >
        {{ 'Save' | translate }}
      </app-button>
      <app-button
        id="submit-button"
        (click)="saveAndDownloadAsPDF()"
        [isLoading]="isLoading"
        [isDisabled]="!invoiceForm.valid"
        class="ml-3"
        [type]="'button'"
        [btnClass]="'btn-dark'"
      >
        <i class="far fa-fw fa-download"></i>
        <span>{{ 'Save and download as PDF' | translate }}</span>
      </app-button>
    </div>
  </form>
  <div class="to-be-invoiced-transports p-2">
    <div class="sticky-transports">
      <div class="text-center m-2">
        <h4>{{ 'To be invoiced' | translate }}</h4>
      </div>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'Search transports' | translate }}"
          ngbTooltip="{{
            'Search location, liner or container type' | translate
          }}"
          [(ngModel)]="query"
          (keyup)="search($event)"
          aria-label="Filter transports"
          aria-describedby="basic-addon2"
        />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">
            <i class="far fa-fw fa-search"></i>
          </span>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="dateSelection"
          readonly
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="showDateSelection = !showDateSelection">
              <i class="far fa-fw fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div class="input-group mb-3" *ngIf="showDateSelection">
        <app-daterangepicker
          (dateSelected)="handleDateSelection($event)"
          [displayMonths]="1"
          [markDisabledBeforeMindate]="true"
          [fromDate]="fromDate"
          [toDate]="toDate"
        ></app-daterangepicker>
      </div>
      <div class="pre-scrollable">
        <div
          data-infinite-scroll
          [infiniteScrollDistance]="1.5"
          [infiniteScrollUpDistance]="1.5"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScrollDown()"
          [scrollWindow]="false"
          [fromRoot]="true"
          [infiniteScrollContainer]="'.pre-scrollable'"
        >
          <div id="unplanned-transports-list">
            <app-transport-info
              class="transport"
              *ngFor="let transport of transports"
              (click)="handleTransportSelected(transport)"
              [transportId]="transport.id"
              [transport]="transport"
            ></app-transport-info>
            <div *ngIf="transports" class="mt-1 p-1">
              <div>{{ "Can't find your transport?" | translate }}</div>
              <div>
                {{ 'Make sure that it has a ' | translate }}
                <span class="font-weight-bold">{{
                  'customer' | translate
                }}</span>
                <span>{{ ' and a ' | translate }}</span>
                <span class="font-weight-bold">{{ 'tariff' | translate }}</span>
                <span>{{ ' assigned to it' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
