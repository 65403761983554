import { Action } from '@ngrx/store';
import * as types from '../types/update.types';

export class LoadUpdates implements Action {
  readonly type = types.updates.LOAD_UPDATES;
  constructor(
    public payload?: {
      timestamp?: string;
    }
  ) { }
}

export class LoadUpdatesFail implements Action {
  readonly type = types.updates.LOAD_UPDATES_FAILED;
  constructor(public payload: any) { }
}

export class LoadUpdatesSuccess implements Action {
  readonly type = types.updates.LOAD_UPDATES_SUCCEEDED;
  constructor(public payload: any) { }
}

export class StartLoadUpdates implements Action {
  readonly type = types.updates.START_LOAD_UPDATES;
  constructor(public payload?: any) { }
}

export class StopLoadUpdates implements Action {
  readonly type = types.updates.STOP_LOAD_UPDATES;
  constructor(public payload?: any) { }
}

export type All =
  | LoadUpdates
  | LoadUpdatesFail
  | LoadUpdatesSuccess
  | StartLoadUpdates
  | StopLoadUpdates;
