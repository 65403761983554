export const tenants = {
  LOAD_TENANTS: '[Tenants] Load',
  LOAD_SUCCEEDED: '[Tenants] Load Success',
  LOAD_FAILED: '[Tenants] Load Fail',
  LOAD_ALL_TENANTS: '[Tenants] Load All',
  LOAD_ALL_SUCCEEDED: '[Tenants] Load ALl Success',
  LOAD_ALL_FAILED: '[Tenants] Load All Fail',
  CREATE_TENANT: '[Tenant] Create',
  CREATE_SUCCEEDED: '[Tenant] Create Success',
  CREATE_FAILED: '[Tenant] Create Fail',
  UPDATE_TENANT: '[Tenant] Update',
  UPDATE_TENANT_SUCCEEDED: '[Tenant] Update Success',
  UPDATE_TENANT_FAILED: '[Tenant] Update Fail',
  ENABLE_TENANT: '[Tenant] Enable',
  ENABLE_TENANT_SUCCEEDED: '[Tenant] Enable Success',
  ENABLE_TENANT_FAILED: '[Tenant] Enable Fail',
  DISABLE_TENANT: '[Tenant] Disable',
  DISABLE_TENANT_SUCCEEDED: '[Tenant] Disable Success',
  DISABLE_TENANT_FAILED: '[Tenant] Disable Fail',
  DELETE_TENANT: '[Tenant] Disable',
  DELETE_TENANT_SUCCEEDED: '[Tenant] Disable Success',
  DELETE_TENANT_FAILED: '[Tenant] Disable Fail',
  CLEAR: '[Tenants] Clear'
};
