/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./matching-pool-item/matching-pool-item.component.ngfactory";
import * as i2 from "./matching-pool-item/matching-pool-item.component";
import * as i3 from "@angular/common";
import * as i4 from "ngx-infinite-scroll";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./matching.component";
import * as i7 from "@ngrx/store";
import * as i8 from "@ngrx/effects";
import * as i9 from "../services/toast.service";
var styles_MatchingComponent = [];
var RenderType_MatchingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MatchingComponent, data: {} });
export { RenderType_MatchingComponent as RenderType_MatchingComponent };
function View_MatchingComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-matching-pool-item", [], null, [[null, "removeMatchOption"], [null, "removeMatchItem"], [null, "submitReply"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeMatchOption" === en)) {
        var pd_0 = (_co.handleRemoveMatchOption($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeMatchItem" === en)) {
        var pd_1 = (_co.handleRemoveMatchItem($event) !== false);
        ad = (pd_1 && ad);
    } if (("submitReply" === en)) {
        var pd_2 = (_co.handleSendReply($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatchingPoolItemComponent_0, i1.RenderType_MatchingPoolItemComponent)), i0.ɵdid(1, 49152, null, 0, i2.MatchingPoolItemComponent, [], { matchingPoolItem: [0, "matchingPoolItem"], isLoading: [1, "isLoading"], disabledMatch: [2, "disabledMatch"] }, { submitReply: "submitReply", removeMatchItem: "removeMatchItem", removeMatchOption: "removeMatchOption" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.isLoading; var currVal_2 = _co.disabledMatch; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MatchingComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MatchingComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.visible; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MatchingComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["data-infinite-scroll", ""]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.onScrollDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 4866048, null, 0, i4.InfiniteScrollDirective, [i0.ElementRef, i0.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollUpDistance: [1, "infiniteScrollUpDistance"], infiniteScrollThrottle: [2, "infiniteScrollThrottle"], infiniteScrollContainer: [3, "infiniteScrollContainer"], scrollWindow: [4, "scrollWindow"], fromRoot: [5, "fromRoot"] }, { scrolled: "scrolled" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MatchingComponent_3)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 2; var currVal_1 = 1.5; var currVal_2 = 50; var currVal_3 = ".ng-sidebar__content"; var currVal_4 = false; var currVal_5 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.matchCandidates; _ck(_v, 4, 0, currVal_6); }, null); }
function View_MatchingComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("No matches found")); _ck(_v, 1, 0, currVal_0); }); }
function View_MatchingComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MatchingComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["noMatches", 2]], null, 0, null, View_MatchingComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.matchCandidates && (_co.matchCandidates.length !== 0)); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MatchingComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Loading matches...")); _ck(_v, 1, 0, currVal_0); }); }
export function View_MatchingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MatchingComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["loading", 2]], null, 0, null, View_MatchingComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoadingMatchCandidates; var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_MatchingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-matching", [], null, null, null, View_MatchingComponent_0, RenderType_MatchingComponent)), i0.ɵdid(1, 180224, null, 0, i6.MatchingComponent, [i7.Store, i8.Actions, i9.ToastService], null, null)], null, null); }
var MatchingComponentNgFactory = i0.ɵccf("app-matching", i6.MatchingComponent, View_MatchingComponent_Host_0, {}, {}, []);
export { MatchingComponentNgFactory as MatchingComponentNgFactory };
