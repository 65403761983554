<form (ngSubmit)="onSubmit()" [formGroup]="tariffForm">
  <table class="table table-borderless mt-3">
    <colgroup>
      <col span="1" style="width: 50%;" />
    </colgroup>
    <thead class="thead-dark">
      <tr>
        <th scope="col">{{ 'Name' | translate }}</th>
        <th scope="col">{{ 'Unit price (VAT excl.)' | translate }}</th>
        <th scope="col">{{ 'Quantity' | translate }}</th>
        <th scope="col">{{ 'Total' | translate }}</th>
        <th scope="col" class="invisible-header"></th>
      </tr>
    </thead>
    <tbody formArrayName="parts">
      <ng-template
        ngFor
        let-item
        [ngForOf]="tariffForm.get('parts')['controls']"
        let-i="index"
      >
        <tr [formGroupName]="i">
          <td class="p-0">
            <input
              [attr.disabled]="disabled ? '' : null"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  item.controls['description'].invalid &&
                  item.controls['description'].touched
              }"
              type="text"
              placeholder="{{ 'Transport (KM)' | translate }}"
              data-cy="description"
              (focus)="
                i === tariffForm.get('parts').value.length - 1
                  ? addTariffItem()
                  : ''
              "
              formControlName="description"
            />
          </td>
          <td class="p-0">
            <input
              [attr.disabled]="disabled ? '' : null"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  item.controls['unitPrice'].invalid &&
                  item.controls['unitPrice'].touched
              }"
              type="number"
              (focus)="
                i === tariffForm.get('parts').value.length - 1
                  ? addTariffItem()
                  : ''
              "
              formControlName="unitPrice"
              data-cy="unitPrice"
              (keyup)="updateItemTotal(i)"
              (change)="updateItemTotal(i)"
            />
          </td>
          <td class="p-0">
            <input
              [attr.disabled]="disabled ? '' : null"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  item.controls['quantity'].invalid &&
                  item.controls['quantity'].touched
              }"
              type="number"
              (focus)="
                i === tariffForm.get('parts').value.length - 1
                  ? addTariffItem()
                  : ''
              "
              formControlName="quantity"
              data-cy="quantity"
              (keyup)="updateItemTotal(i)"
              (change)="updateItemTotal(i)"
            />
          </td>
          <td class="p-0">
            <input
              class="form-control"
              disabled="true"
              type="number"
              (focus)="
                i === tariffForm.get('parts').value.length - 1
                  ? addTariffItem()
                  : ''
              "
              formControlName="itemTotal"
            />
          </td>
          <td class="p-0">
            <button
              *ngIf="i !== tariffForm.get('parts').value.length - 1"
              [disabled]="disabled"
              (click)="removeTariffItem(i)"
              class="btn btn-link"
              data-cy="deleteButton"
              type="button"
            >
              <i
                class="far fa-fw fa-trash-alt text-danger"
                ngbTooltip="{{ 'Delete' | translate }}"
                placement="left"
              ></i>
            </button>
            <button
              *ngIf="i === tariffForm.get('parts').value.length - 1"
              [disabled]="disabled"
              (click)="addTariffItem()"
              class="btn btn-link"
              data-cy="addButton"
              type="button"
            >
              <i
                class="far fa-fw fa-plus text-success"
                ngbTooltip="{{ 'New item' | translate }}"
                placement="left"
              ></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <div class="total-grid">
    <span class="total">
      <strong>{{ 'Total: ' | translate }}</strong> {{ total | json }}
    </span>
  </div>
</form>
