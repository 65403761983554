<div class="text-center">
  <h1>
    <span>{{ '404 Not found' | translate }}</span>
    <i class="far fa-frown"></i>
  </h1>
  <div>
    {{ 'It looks like you got lost.' | translate }}
  </div>
  <br>
  <div>
    <a href="/dashboard">{{ 'Return to the homepage' | translate }}</a>
  </div>
</div>
