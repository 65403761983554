/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-user-location.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-location-form/user-location-form.component.ngfactory";
import * as i3 from "../user-location-form/user-location-form.component";
import * as i4 from "../../../services/location.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../services/toast.service";
import * as i7 from "./edit-user-location.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../core/api/api.service";
import * as i10 from "@ngrx/effects";
var styles_EditUserLocationComponent = [i0.styles];
var RenderType_EditUserLocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditUserLocationComponent, data: {} });
export { RenderType_EditUserLocationComponent as RenderType_EditUserLocationComponent };
export function View_EditUserLocationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "edit-user-location-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-user-location-form", [], null, [[null, "userLocationFormSubmitted"], [null, "addressChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userLocationFormSubmitted" === en)) {
        var pd_0 = (_co.updateUserLocation($event) !== false);
        ad = (pd_0 && ad);
    } if (("addressChanged" === en)) {
        var pd_1 = (_co.updateMarkerByAddress($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_UserLocationFormComponent_0, i2.RenderType_UserLocationFormComponent)), i1.ɵdid(2, 770048, null, 0, i3.UserLocationFormComponent, [i4.LocationService, i5.Store, i6.ToastService], { isLoading: [0, "isLoading"], userLocation: [1, "userLocation"], coordinates: [2, "coordinates"] }, { userLocationFormSubmitted: "userLocationFormSubmitted", addressChanged: "addressChanged" }), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "div", [["class", "map mb-3"], ["id", "user-location-map"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = _co.location; var currVal_2 = _co.coordinates; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_EditUserLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-user-location", [], null, null, null, View_EditUserLocationComponent_0, RenderType_EditUserLocationComponent)), i1.ɵdid(1, 245760, null, 0, i7.EditUserLocationComponent, [i8.ActivatedRoute, i9.ApiService, i5.Store, i10.Actions, i6.ToastService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditUserLocationComponentNgFactory = i1.ɵccf("app-edit-user-location", i7.EditUserLocationComponent, View_EditUserLocationComponent_Host_0, {}, {}, []);
export { EditUserLocationComponentNgFactory as EditUserLocationComponentNgFactory };
