export const subscriptions = {
  LOAD_SUBSCRIPTIONS: '[Subscription] Load',
  LOAD_SUCCEEDED: '[SUBSCRIPTION] LOAD SUCCESS',
  LOAD_FAILED: '[Subscription] Load Fail',
  LOAD_SUBSCRIPTION_TYPES: '[Subscription] Load Types',
  LOAD_TYPES_SUCCEEDED: '[Subscription] Load Types Success',
  LOAD_TYPES_FAILED: '[Subscription] Load Types Fail',
  START_SUBSCRIPTION: '[Subscription] Start',
  START_SUCCEEDED: '[Subscription] Start Success',
  START_FAILED: '[Subscription] Start Fail',
  STOP_SUBSCRIPTION: '[Subscription] Stop',
  STOP_SUCCEEDED: '[Subscription] Stop Success',
  STOP_FAILED: '[Subscription] Stop Fail',
  CLEAR: '[Subscriptions] Clear'
};
