<div class="card">
  <h3 class="card-header">{{ 'Credit invoices' | translate }}</h3>
  <div class="card-body p-0">
    <ngx-datatable
      class="bootstrap dark"
      [rows]="creditTransactions"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      (page)="setPage($event)"
      [count]="totalElements"
      [limit]="pageSize"
      [messages]="tableMessages"
      [loadingIndicator]="false"
    >
      <div>
        <ngx-datatable-column name="{{ 'Date' | translate }}" prop="created">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span>{{ value | date: 'dd-MM-yyyy HH:MM' }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'Invoice #' | translate }}"
          prop="invoiceNumber"
        >
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'Credits' | translate }}" prop="credits">
        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'Download' | translate }}">
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
          >
            <div class="d-flex justify-content-end">
              <app-button
                [btnClass]="'btn-outline-info'"
                [isLoading]="isLoadingId === row.id"
                [isDisabled]="isLoadingId === row.id"
                (click)="getInvoice(row)"
                [type]="'button'"
              >
                <i class="far fa-download"></i>
              </app-button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </div>
    </ngx-datatable>
  </div>
</div>
