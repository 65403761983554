<div class="search-container mb-2 ml-3 mr-3">
  <button class="btn" routerLink="/superadmin/shipping-agents/new" id="new-shipping-agent-button">
    <i class="far fa-plus fa-fw"></i>
    <span>{{ 'New Shipping Agent' | translate }}</span>
  </button>
</div>
<div class="ml-3 mr-3">
  <div class="card">
    <ngx-datatable
      class="bootstrap ml-0 mr-0"
      [rows]="shippingAgents"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [selectionType]="undefined"
      [loadingIndicator]="loading"
    >
      <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
      <ngx-datatable-column name="Branches" prop="">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <ul *ngFor="let branch of row.branches" class="d-flex justify-content-between list-unstyled">
            <li>{{ branch.liner.name }} - {{ branch.country }}</li>
          </ul>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Teanants" prop="">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <ul *ngFor="let tenant of row.tenants" class="d-flex justify-content-between list-unstyled">
            <li>{{ tenant.name }}</li>
          </ul>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" prop="">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <button class="btn btn-info" (click)="edit(row.id)">
            <i class="far fa-fw fa-pencil"></i>
            <span>{{ 'Edit' | translate }}</span>
          </button>
          &nbsp;
          <button class="btn" (click)="delete(row.id)">
            <i class="far fa-fw fa-trash-alt"></i>
            <span>{{ 'Delete' | translate }}</span>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
