import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as types from '../types/reload.types';
import * as ReloadActions from '../actions/reload.actions';
import { ReloadRequest } from '../models/reload-request.model';
import { ApiService } from 'app/core/api/api.service';

@Injectable()
export class ReloadEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadAllReloadRequests$: Observable<Action> = this.actions$.pipe(
    ofType(types.reload.LOAD_ALL_RELOAD_REQUESTS),
    map((action: ReloadActions.LoadAllReloadRequests) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      const page = payload && payload.page ? payload.page : 1;
      const forLiner = payload && payload.forLiner ? payload.forLiner : false;
      const status = payload && payload.status ? payload.status : false;
      let url = null;
      if (forLiner) {
        url = `/container_reload_requests_for_liner?page=${page}`;
      } else {
        url = `/container_reload_requests_for_transport_company?page=${page}`;
      }
      if (query) {
        url = `${url}&liner.name=${query}&liner.shortName=${query}&containerNr=${query}&exportReference=${query}`;
      }
      if (status) {
        url = `${url}&requestStatus=${status}`
      }
      return this.api.get({ path: url }).pipe(
        map((data: ReloadRequest) => new ReloadActions.LoadAllReloadRequestsSuccess(data)),
        catchError(error => of(new ReloadActions.LoadAllReloadRequestsFailed(error)))
      );
    }),
  );

  @Effect() requestReload$: Observable<Action> = this.actions$.pipe(
    ofType(types.reload.CREATE_RELOAD_REQUEST),
    map((action: ReloadActions.CreateReloadRequest) => action.payload),
    concatMap(payload => this.api.post({
      path: '/container_reload_requests', body: payload.body
    }).pipe(
      map(data => new ReloadActions.CreateReloadRequestSuccess(data)),
      catchError(error => of(new ReloadActions.CreateReloadRequestFail(error)))
    )),
  );

  @Effect()
  updateReloadRequest$: Observable<Action> = this.actions$.pipe(
    ofType(types.reload.UPDATE_RELOAD_REQUEST),
    map((action: ReloadActions.UpdateReloadRequest) => action.payload),
    concatMap(payload => this.api.put({
      path: '/container_reload_requests/' + payload.requestId,
      body: payload.request
    }).pipe(
      map(data => new ReloadActions.UpdateReloadRequestSuccess(data)),
      catchError(error => of(new ReloadActions.UpdateReloadRequestFail(error)))
    ))
  );

  @Effect()
  forceApproveReloadRequest$: Observable<Action> = this.actions$.pipe(
    ofType(types.reload.FORCE_APPROVE_RELOAD_REQUEST),
    map((action: ReloadActions.ForceApproveReloadRequest) => action.payload),
    concatMap(payload => this.api.post({
      path: '/container_reload_requests/' + payload.requestId + '/force_approve'
    }).pipe(
      map(data => new ReloadActions.ForceApproveReloadRequestSuccess(data)),
      catchError(error => of(new ReloadActions.ForceApproveReloadRequestFail(error)))
    ))
  );

  @Effect()
  cancelReloadRequest$: Observable<Action> = this.actions$.pipe(
    ofType(types.reload.CANCEL_RELOAD_REQUEST),
    map((action: ReloadActions.CancelReloadRequest) => action.payload),
    concatMap(payload => this.api.post({
      path: '/container_reload_requests/' + payload.requestId + '/cancel',
    }).pipe(
      map(data => new ReloadActions.CancelReloadRequestSuccess(data)),
      catchError(error => of(new ReloadActions.CancelReloadRequestFail(error)))
    ))
  );

  @Effect()
  cancelReloadRequestByLiner$: Observable<Action> = this.actions$.pipe(
    ofType(types.reload.CANCEL_RELOAD_REQUEST_BY_LINER),
    map((action: ReloadActions.CancelReloadRequestByLiner) => action.payload),
    concatMap(payload => this.api.post({
      path: '/container_reload_requests/' + payload.requestId + '/cancel_by_liner',
    }).pipe(
      map(data => new ReloadActions.CancelReloadRequestSuccess(data)),
      catchError(error => of(new ReloadActions.CancelReloadRequestFail(error)))
    ))
  );
}
