<ngx-datatable
  class="bootstrap ml-0 mr-0"
  [rows]="companies$ | async"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [selectionType]="undefined"
  [externalPaging]="false"
  [limit]="30"
  [loadingIndicator]="loading"
>
  <ngx-datatable-column name="Created" prop="announcedOn"></ngx-datatable-column>
  <ngx-datatable-column name="Location" prop="site.name"></ngx-datatable-column>
  <ngx-datatable-column name="PrePaid" prop="site.prePaid">
    <ng-template ngx-datatable-cell-template let-value="value">
      <span class="fa-fw far fa-check-circle text-success" *ngIf="value"></span>
      <span class="fa-fw far fa-times-circle text-danger" *ngIf="!value"></span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Liner code" prop="liner.code"></ngx-datatable-column>
  <ngx-datatable-column name="Container no." prop="containerNumber"></ngx-datatable-column>
  <ngx-datatable-column name="Weight" prop="weight"></ngx-datatable-column>
  <ngx-datatable-column name="Avantida" prop="">
    <ng-template ngx-datatable-cell-template let-status="row.avantidaStatus" let-value="value">
      <span class="text-success" *ngIf="status === 'Accepted' ? 'text-success' : ''">{{ status }}</span>
      <span class="text-danger" *ngIf="status !== 'Sent' && status !== 'Accepted'">{{ status }}</span>
      <span *ngIf="status === 'Sent'">{{ status }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Avantida" prop="sendToAvantida"></ngx-datatable-column>
  <ngx-datatable-column name="Inttra" prop="inttraStatus">
    <ng-template ngx-datatable-cell-template let-status="row.inttraStatus" let-value="value">
      <span class="text-success" *ngIf="status === 'Accepted' ? 'text-success' : ''">{{ status }}</span>
      <span class="text-danger" *ngIf="status !== 'Sent' && status !== 'Accepted'">{{ status }}</span>
      <span *ngIf="status === 'Sent'">{{ status }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Inttra" prop="sendToInttra"></ngx-datatable-column>
  <ngx-datatable-column name="" prop="">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button class="btn btn-info" (click)="edit(row.id)">
        <i class="fa-fw far fa-pencil"></i>
        <span>{{ 'Edit' | translate }}</span>
      </button>
      <button class="btn btn-danger" (click)="delete(row.id)">
        <i class="fa-fw far fa-trash-alt"></i>
        <span>{{ 'Delete' | translate }}</span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
