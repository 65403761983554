/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-posts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../marketplace/my-posts/my-offers/my-offers.component.ngfactory";
import * as i4 from "../../marketplace/my-posts/my-offers/my-offers.component";
import * as i5 from "@ngrx/store";
import * as i6 from "@ngrx/effects";
import * as i7 from "../../services/search.service";
import * as i8 from "../../services/toast.service";
import * as i9 from "../../marketplace/my-posts/my-demands/my-demands.component.ngfactory";
import * as i10 from "../../marketplace/my-posts/my-demands/my-demands.component";
import * as i11 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i12 from "@ng-bootstrap/ng-bootstrap";
import * as i13 from "./company-posts.component";
var styles_CompanyPostsComponent = [i0.styles];
var RenderType_CompanyPostsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyPostsComponent, data: {} });
export { RenderType_CompanyPostsComponent as RenderType_CompanyPostsComponent };
function View_CompanyPostsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-hand-holding"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Offers")); _ck(_v, 2, 0, currVal_0); }); }
function View_CompanyPostsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cmp-my-offers", [], null, null, null, i3.View_MyOffersComponent_0, i3.RenderType_MyOffersComponent)), i1.ɵdid(2, 180224, null, 0, i4.MyOffersComponent, [i5.Store, i6.Actions, i7.SearchService, i8.ToastService], null, null)], null, null); }
function View_CompanyPostsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-question-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Demands")); _ck(_v, 2, 0, currVal_0); }); }
function View_CompanyPostsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cmp-my-demands", [], null, null, null, i9.View_MyDemandsComponent_0, i9.RenderType_MyDemandsComponent)), i1.ɵdid(2, 180224, null, 0, i10.MyDemandsComponent, [i5.Store, i6.Actions, i7.SearchService, i8.ToastService], null, null)], null, null); }
export function View_CompanyPostsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "ngb-tabset", [], null, null, null, i11.View_NgbTabset_0, i11.RenderType_NgbTabset)), i1.ɵdid(1, 2146304, null, 1, i12.NgbTabset, [i12.NgbTabsetConfig], { justify: [0, "justify"] }, null), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 7, "ngb-tab", [["id", "offer-tab"]], null, null, null, null, null)), i1.ɵdid(4, 2113536, [[1, 4]], 2, i12.NgbTab, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_CompanyPostsComponent_1)), i1.ɵdid(8, 16384, [[2, 4]], 0, i12.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CompanyPostsComponent_2)), i1.ɵdid(10, 16384, [[3, 4]], 0, i12.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 7, "ngb-tab", [["id", "demand-tab"]], null, null, null, null, null)), i1.ɵdid(12, 2113536, [[1, 4]], 2, i12.NgbTab, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 4, { titleTpls: 1 }), i1.ɵqud(603979776, 5, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_CompanyPostsComponent_3)), i1.ɵdid(16, 16384, [[4, 4]], 0, i12.NgbTabTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_CompanyPostsComponent_4)), i1.ɵdid(18, 16384, [[5, 4]], 0, i12.NgbTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = "fill"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "offer-tab"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "demand-tab"; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_CompanyPostsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_CompanyPostsComponent_0, RenderType_CompanyPostsComponent)), i1.ɵdid(1, 49152, null, 0, i13.CompanyPostsComponent, [], null, null)], null, null); }
var CompanyPostsComponentNgFactory = i1.ɵccf("app-dashboard", i13.CompanyPostsComponent, View_CompanyPostsComponent_Host_0, {}, {}, []);
export { CompanyPostsComponentNgFactory as CompanyPostsComponentNgFactory };
