import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/location.types';
import * as LocationActions from '../actions/location.actions';
import { ApiService } from '../../api/api.service';
import { map, concatMap, catchError } from 'rxjs/operators';

@Injectable()
export class LocationEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadLocations$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.LOAD_LOCATIONS),
    concatMap(() => this.api.get({ path: '/locations' }).pipe(
      map(data => new LocationActions.LoadLocationsSuccess(data)),
      catchError(error => of(new LocationActions.LoadLocationsFail(error)))
    ))
  );

  @Effect()
  loadUncheckedLocations$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.LOAD_UNCHECKED_LOCATIONS),
    concatMap(() => this.api.get({
      path: '/dashboard/marketplace/improvable-location'
    }).pipe(
      map(data => new LocationActions.LoadUncheckedLocationsSuccess(data)),
      catchError(error => of(new LocationActions.LoadUncheckedLocationsFail(error)))
    ))
  );

  @Effect()
  loadLocationsByType$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.LOAD_LOCATIONS_BY_LOCATION_TYPE),
    map((action: LocationActions.LoadLocationsByType) => action.payload),
    concatMap(payload => this.api.get({
      path: `/locations?locationType.code=${payload.locationType}&page=${
        payload.page ? payload.page : 1}`
    }).pipe(
      map(data => new LocationActions.LoadLocationsByTypeSuccess(data)),
      catchError(error => of(new LocationActions.LoadLocationsByTypeFail(error)))
    ))
  );

  @Effect()
  loadAddressBookLocations$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.LOAD_ADDRESS_BOOK_LOCATIONS),
    map((action: LocationActions.LoadAddressBookLocations) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      return this.api.get({ path: `/addressbook?query=${query}` }).pipe(
        map(data => new LocationActions.LoadAddressBookLocationsSuccess(data)),
        catchError(error => of(new LocationActions.LoadAddressBookLocationsFail(error)))
      );
    })
  );

  @Effect()
  loadPrenotifiableLocations$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.LOAD_PRENOTIFIABLE_LOCATIONS),
    concatMap(() => this.api.get({
      path: `/get_prenotifiable_locations`
    }).pipe(
      map(data => new LocationActions.LoadPrenotifiableLocationsSuccess(data)),
      catchError(error => of(new LocationActions.LoadPrenotifiableLocationsFail(error)))
    ))
  );

  @Effect()
  loadLocationTypes$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.LOAD_LOCATION_TYPES),
    concatMap(() => this.api.get({ path: `/location_types` }).pipe(
      map(data => new LocationActions.LoadLocationTypesSuccess(data)),
      catchError(error => of(new LocationActions.LoadLocationTypesFail(error)))
    ))
  );

  @Effect()
  createUserLocation$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.CREATE_USER_LOCATION),
    map((action: LocationActions.CreateUserLocation) => action.payload),
    concatMap(payload => this.api.post({
      path: `/locations`, body: payload
    }).pipe(
      map(data => new LocationActions.CreateUserLocationSuccess(data)),
      catchError(error => of(new LocationActions.CreateUserLocationFail(error)))
    ))
  );

  @Effect()
  updateUserLocation$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.UPDATE_USER_LOCATION),
    map((action: LocationActions.UpdateUserLocation) => action.payload),
    concatMap(payload => this.api.put({
      path: `/locations/${payload.locationId}`,
      body: payload.location
    }).pipe(
      map(data => new LocationActions.UpdateUserLocationSuccess(data)),
      catchError(error => of(new LocationActions.UpdateUserLocationFail(error)))
    ))
  );

  @Effect()
  deleteUserLocation$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.DELETE_USER_LOCATION),
    map((action: LocationActions.DeleteUserLocation) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/locations/${payload.locationId}`
    }).pipe(
      map(() => new LocationActions.DeleteUserLocationSuccess(payload.locationId)),
      catchError(error => of(new LocationActions.DeleteUserLocationFail(error)))
    ))
  );

  @Effect()
  deleteLocation$: Observable<Action> = this.actions$.pipe(
    ofType(types.location.DELETE_LOCATION),
    map((action: LocationActions.DeleteLocation) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/locations/${payload.locationId}`
    }).pipe(
      map(() => new LocationActions.DeleteLocationSuccess(payload.locationId)),
      catchError(error => of(new LocationActions.DeleteLocationFail(error)))
    ))
  );
}
