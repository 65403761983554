import { Component } from '@angular/core';
import { extract } from '../services/i18n.service';

@Component({
  selector: 'app-cmp-reload-back-office',
  templateUrl: 'reload.component.html',
})
export class ReloadBackOfficeComponent {
  title = extract('Reload');
  routes = [
    {
      url: '/reload-back-office/reload-requests',
      title: extract('Reload Requests'),
      description: extract('Manage your Reload Requests'),
      icon: 'fa-recycle'
    },
    {
      url: '/reload-back-office/reporting',
      title: extract('Reporting'),
      description: extract('Reload Reports'),
      icon: 'fa-file-chart-line'
    },
  ];
}
