<div class="d-flex justify-content-between ml-3 mr-3 pb-3">
  <span class="d-flex">
    <app-datepicker
      (dayChange)="dayChange.next($event)"
      [today]="fromDate"
      [toDate]="toDate"
    ></app-datepicker>
    <app-search
      class="ml-3"
      [placeholder]="searchPlaceholder"
      (search)="handleSearch($event)"
    ></app-search>
  </span>
  <button class="btn" (click)="goToCreate()">
    <i class="far fa-fw fa-plus"></i> {{ 'Create transport' | translate }}
  </button>
</div>
<ngx-datatable
  #postsTable
  class="bootstrap vh-89 row ml-3 mr-0"
  [rows]="transports"
  [columnMode]="'force'"
  [headerHeight]="40"
  [footerHeight]="40"
  [rowHeight]="'auto'"
  [externalPaging]="true"
  (page)="setPage($event)"
  [count]="totalElements"
  [limit]="rowLimit"
  [scrollbarH]="true"
  [loadingIndicator]="isLoadingTransports"
  [messages]="transportTableMessages"
>
  <ngx-datatable-column
    name="{{ 'Transport' | translate }}"
    prop="transportType.label"
    [minWidth]="95"
    [width]="95"
    [maxWidth]="100"
  >
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <div *ngIf="row.partOfMarketPostOffer">
        <span class="badge badge-primary text-white">{{
          'Marketplace' | translate
        }}</span>
      </div>
      <div *ngIf="row.partOfMatchingPoolItem">
        <span class="badge badge-info text-white">{{
          'Matching' | translate
        }}</span>
      </div>
      <div>
        <span class="icon">
          <i class="far" [ngClass]="getTransportTypeIcon(row)"></i>
        </span>
        <span>{{ value | titlecase | translate }}</span>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'Container' | translate }}" [width]="155">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <div>{{ row.container?.containerType?.label }}</div>
      <div>{{ row.container?.liner?.shortName }}</div>
      <div>{{ row.container?.number }}</div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'Customer' | translate }}" prop="customer">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <div>{{ value.label | titlecase }}</div>
      <div>{{ row.customerReference }}</div>
      <div *ngIf="row.contact" ngbTooltip="{{ 'Contact' | translate }}">
        <i class="far fa-fw fa-phone"></i>
        <span>{{ row.contact.firstName }} {{ row.contact.lastName }}</span>
        &nbsp;
        <div>
          <span> {{ row.contact.telephone }}</span>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Locations' | translate }}"
    prop=""
    [minWidth]="630"
    [width]="630"
    [maxWidth]="630"
  >
    <ng-template
      ngx-datatable-cell-template
      let-row="row"
      let-rowIndex="rowIndex"
      let-value="value"
    >
      <app-transport-tasks-table
        (referenceChecked)="reloadTransports($event)"
        (taskStatusChanged)="reloadTransports($event)"
        [showDropdown]="true"
        [primaryTaskDate]="value.primaryTaskDate"
        [taskIndexes]="taskIndexes"
        [transportTasks]="value.transportTasks"
        [transportIsOwnedByCurrentTenant]="true"
      >
      </app-transport-tasks-table>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Weight' | translate }}"
    prop="container.weight"
    [width]="40"
  >
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Comment' | translate }}"
    prop="privateComment"
    [width]="120"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <p class="comment-field">{{ value }}</p>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Genset' | translate }}"
    prop="genset"
    [width]="65"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <i class="far fa-snowflake text-info" *ngIf="value"></i>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'ADR' | translate }}"
    prop="container.adr"
    [width]="45"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <i class="far fa-exclamation-triangle text-danger" *ngIf="value"></i>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Reference' | translate }}"
    prop="reference"
    [width]="160"
  >
  </ngx-datatable-column>
  <ngx-datatable-column
    [cellClass]="'datatable-actions'"
    name="{{ 'Action' | translate }}"
    prop=""
    [width]="140"
  >
    <ng-template
      ngx-datatable-cell-template
      let-rowIndex="rowIndex"
      let-value="value"
      let-row="row"
    >
      <div
        ngbDropdown
        [placement]="getDropdownPlacement(rowIndex)"
        [container]="'body'"
        class="d-inline-block"
      >
        <button
          class="btn btn-outline-info"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          <span
            translate
            class="btn__label"
            [ngClass]="{ invisible: isLoading && actionRow === rowIndex }"
            >{{ 'Select action' | translate }}</span
          >
          <i
            class="fa fa-spinner fa-spin btn__loader"
            [ngClass]="{ visible: isLoading && actionRow === rowIndex }"
          ></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button
            class="dropdown-item"
            [disabled]="!transportHasReference(row)"
            (click)="checkTaskReferences(row, rowIndex)"
          >
            <span class="icon"> <i class="far fa-check-circle"></i> </span>
            <span>{{ 'Check references' | translate }}</span>
          </button>
          <button
            class="dropdown-item"
            *ngIf="
              hasActiveMarketplaceSubscription && !row.partOfMarketPostOffer
            "
            (click)="publishAsMarketpost(row, rowIndex)"
          >
            <span class="icon">
              <i class="far fa-arrow-circle-right"></i>
            </span>
            <span>{{ 'Publish to marketplace' | translate }}</span>
          </button>
          <button
            class="dropdown-item"
            *ngIf="
              false &&
              hasActiveMarketplaceSubscription &&
              !row.partOfMarketPostOffer
            "
            (click)="openPublishDemandModal(row)"
          >
            <span class="icon">
              <i class="far fa-arrow-circle-right"></i>
            </span>
            <span>{{ 'Publish to marketplace as demand' | translate }}</span>
          </button>
          <button
            class="dropdown-item"
            *ngIf="
              hasActiveMarketplaceSubscription && row.partOfMarketPostOffer
            "
            (click)="unpublishFromMarketplace(row, rowIndex)"
          >
            <span class="icon"> <i class="far fa-times-circle"></i> </span>
            <span>{{ 'Remove from Marketplace' | translate }}</span>
          </button>
          <button
            class="dropdown-item text-success"
            *ngIf="!row.partOfMatchingPoolItem"
            (click)="enableMatching(row, rowIndex)"
          >
            <span class="icon"> <i class="far fa-bolt"></i> </span>
            <span>{{ 'Enable Matching' | translate }}</span>
          </button>
          <button
            class="dropdown-item text-warning"
            *ngIf="hasActiveMatchingSubscription && row.partOfMatchingPoolItem"
            (click)="disableMatching(row, rowIndex)"
          >
            <span class="icon"> <i class="far fa-bolt"></i> </span>
            <span>{{ 'Disable Matching' | translate }}</span>
          </button>
          <button class="dropdown-item" (click)="openTransportDownloadModal(row, rowIndex)">
            <span class="icon"> <i class="far fa-download"></i> </span>
            <span>{{ 'Download' | translate }}</span>
          </button>
          <button class="dropdown-item" (click)="edit(row, rowIndex)">
            <span class="icon"> <i class="far fa-pencil"></i> </span>
            <span>{{ 'Edit' | translate }}</span>
          </button>
          <button class="dropdown-item" (click)="copyTransport(row)">
            <span class="icon"> <i class="far fa-copy"></i> </span>
            <span>{{ 'Copy' | translate }}</span>
          </button>
          <button
            class="dropdown-item"
            (click)="deleteTransport(row, rowIndex)"
          >
            <span class="icon"> <i class="far fa-trash-alt"></i> </span>
            <span>{{ 'Delete' | translate }}</span>
          </button>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
