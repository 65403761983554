import { Driver } from '../models/driver.model';
import * as types from '../types/driver.types';
import * as DriverActions from '../actions/driver.actions';

export interface DriverState {
  drivers: Driver[] | any;
  totalElements?: string;
  cleared?: boolean;
}
const INITIAL_STATE: DriverState = {
  drivers: null,
  totalElements: null,
  cleared: false
};

export function reducer(
  state = INITIAL_STATE,
  action: DriverActions.All
): DriverState {
  switch (action.type) {
    case types.drivers.LOAD_DRIVERS_SUCCEEDED:
      return {
        drivers: [...action.payload['hydra:member']],
        totalElements: action.payload['hydra:totalItems']
      };
    case types.drivers.CREATE_DRIVER_SUCCEEDED:
      if (state && state.drivers) {
        state.drivers = [action.payload, ...state.drivers];
        state.totalElements = (+state.totalElements + 1).toString();
        return Object.assign({}, state);
      } else {
        state.drivers = [];
        state.drivers.push(action.payload);
        state.totalElements = '1';
        return Object.assign({}, state);
      }
    case types.drivers.DELETE_DRIVER_SUCCEEDED:
      state.drivers = state.drivers.filter(uc => uc.id !== action.payload);
      state.totalElements = (+state.totalElements - 1).toString();
      return Object.assign({}, state);
    case types.drivers.UPDATE_DRIVER_SUCCEEDED:
      state.drivers = state.drivers.map((item: Driver) => {
        return item.id === action.payload.id
          ? Object.assign({}, item, action.payload)
          : item;
      });
      return Object.assign({}, state);
    case types.drivers.DISABLE_DRIVER_SUCCEEDED:
      state.drivers.find(uc => uc.id === action.payload).enabled = false;
      return Object.assign({}, state);
    case types.drivers.ENABLE_DRIVER_SUCCEEDED:
      state.drivers.find(uc => uc.id === action.payload).enabled = true;
      return Object.assign({}, state);
    case types.drivers.CLEAR_DRIVERS:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
    case types.drivers.UNCLEAR_DRIVERS:
      // Set cleared flag
      const unclearState = Object.assign(
        {},
        {
          drivers: null,
          totalElements: null,
          cleared: false
        }
      );
      return unclearState;
  }
  return state;
}
