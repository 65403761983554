import * as TransportActions from '../actions/transports.actions';
import { TransportTask } from '../models/transport-task.model';
import { Transport } from '../models/transport.model';
import * as marketpostTypes from '../types/market-post.types';
import * as transportTypes from '../types/transport.types';

export interface TransportState {
  data: Transport[];
  totalItems: number;
  firstPage: string | null;
  nextPage: string | null;
  lastPage: string | null;
}

export interface InvoiceableTransportsState {
  invoiceableTransports: Transport[];
  nextInvoiceableTransportPage: number;
}

export interface PurchaseOrderableTransportsState {
  purchaseOrderableTransports: Transport[];
  nextPurchaseOrderableTransportPage: number;
}

const INITIAL_STATE = {
  data: null,
  totalItems: 0,
  firstPage: null,
  nextPage: null,
  lastPage: null,
};

const INITIAL_INVOICEABLE_TRANSPORTS_STATE: InvoiceableTransportsState = {
  invoiceableTransports: null,
  nextInvoiceableTransportPage: 2
};

const INITIAL_PURCHASE_ORDERABLE_TRANSPORTS_STATE: PurchaseOrderableTransportsState = {
  purchaseOrderableTransports: null,
  nextPurchaseOrderableTransportPage: 2
};

export function TransportsReducer(
  state = INITIAL_STATE,
  action: TransportActions.All
): TransportState {
  switch (action.type) {
    case transportTypes.transports.LOAD_SUCCEEDED:
      return {
        data: [...action.payload['hydra:member']],
        totalItems: action.payload['hydra:totalItems'],
        firstPage: action.payload['hydra:view']
          ? action.payload['hydra:view']['hydra:first']
          : null,
        nextPage: action.payload['hydra:view']
          ? action.payload['hydra:view']['hydra:next']
          : null,
        lastPage: action.payload['hydra:view']
          ? action.payload['hydra:view']['hydra:last']
          : null
      };

    case transportTypes.transports.CREATE_TRANSPORT_TASK_SUCCEEDED:
      return state;

    case transportTypes.transports.COPY_TRANSPORT:
      let copy = Object.assign(action.payload) as Transport;
      copy = {
        billOfLading: copy.billOfLading,
        attachments: [],
        comment: copy.comment,
        contact: copy.contact,
        container: copy.container
          ? {
            adr: copy.container.adr,
            containerType: copy.container.containerType,
            liner: copy.container.liner,
            number: copy.container.number,
            weight: copy.container.weight
          }
          : null,
        customer: copy.customer,
        customs: copy.customs,
        genset: copy.genset,
        goods: copy.goods,
        invoiced: copy.invoiced,
        privateComment: copy.privateComment,
        purchased: copy.purchased,
        quantity: copy.quantity,
        ship: copy.ship,
        supplier: copy.supplier,
        supplierContact: copy.supplierContact,
        supplierTariff: copy.supplierTariff
          ? {
            label: copy.supplierTariff.label,
            parts: copy.supplierTariff.parts.sort((a, b) => <any>(a.id > b.id) - <any>(b.id > a.id)).map(part => {
              return {
                description: part.description,
                quantity: part.quantity,
                unitPrice: part.unitPrice
              };
            }),
            price: copy.supplierTariff.price,
            template: copy.supplierTariff.template,
            type: copy.supplierTariff.type
          }
          : null,
        tariff: copy.tariff
          ? {
            label: copy.tariff.label,
            parts: copy.tariff.parts.sort((a, b) => <any>(a.id > b.id) - <any>(b.id > a.id)).map(part => {
              return {
                description: part.description,
                quantity: part.quantity,
                unitPrice: part.unitPrice
              };
            }),
            price: copy.tariff.price,
            template: copy.tariff.template,
            type: copy.tariff.type
          }
          : null,
        transportTasks: copy.transportTasks.map(task => {
          return {
            endDate: task.endDate,
            endDateTimeSpecified: task.endDateTimeSpecified,
            location: task.location,
            privateLocation: task.privateLocation,
            startDate: task.startDate,
            startDateTimeSpecified: task.startDateTimeSpecified,
            taskType: task.taskType
          };
        }),
        transportType: copy.transportType
      };
      return Object.assign({}, state, { transportCopy: copy });

    case marketpostTypes.marketposts.CREATE_OFFER_SUCCEEDED:
      const mpTransport = action.payload.marketPostOffers[0]
        .transportOrigin as Transport;
      state.data = state.data
        ? state.data.map(item => {
          return item.id === mpTransport.id
            ? Object.assign({}, item, { partOfMarketPostOffer: true })
            : item;
        })
        : [mpTransport];
      return Object.assign({}, state);

    case marketpostTypes.marketposts.UNPUBLISH_MARKET_POST_OFFER_SUCCESS:
      if (!state.data) {
        return state;
      }
      const transportId = action.payload.transportId;
      const transport = state.data.find(t => t.id === transportId) as Transport;
      if (transport) {
        transport.partOfMarketPostOffer = false;
      }
      state.data = state.data.map(item => {
        return item.id === transportId
          ? Object.assign({}, item, transport)
          : item;
      });
      return Object.assign({}, state);

    case transportTypes.transports.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return state;

    case transportTypes.transports.TRANSITION_TRANSPORT_TASK_BACK_SUCCEEDED:
    case transportTypes.transports.TRANSITION_TRANSPORT_TASK_FORWARD_SUCCEEDED:
      if (!state.data) {
        return state;
      }
      const taskTransportId = action.payload.transport.id;
      const taskTransport = state.data.find(t => t.id === taskTransportId) as Transport;
      if (!taskTransport) {
        return state;
      }
      const taskId = action.payload.id;
      const task = taskTransport.transportTasks.find(t => t.id === taskId) as TransportTask;
      if (!task) {
        return state;
      }
      task.status = action.payload.status;
      taskTransport.transportTasks = taskTransport.transportTasks.map(item => {
        return item.id === taskId
          ? Object.assign({}, item, task)
          : item;
      });
      state.data = state.data.map(item => {
        return item.id === taskTransportId
          ? Object.assign({}, item, taskTransport)
          : item;
      });
      return Object.assign({}, state);
  }
  return state;
}

export function InvoiceableTransportsReducer(
  state = INITIAL_INVOICEABLE_TRANSPORTS_STATE,
  action: TransportActions.All
): InvoiceableTransportsState {
  switch (action.type) {
    case transportTypes.transports.LOAD_INVOICEABLE_TRANSPORTS_SUCCEEDED:
      if (!action.payload['hydra:member']) {
        state.invoiceableTransports = null;
        return Object.assign({}, state);
      }
      state.invoiceableTransports = [...action.payload['hydra:member']];
      state.nextInvoiceableTransportPage = 2;
      return Object.assign({}, state);

    case transportTypes.transports
      .LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE_SUCCEEDED:
      if (!action.payload['hydra:member']) {
        state.invoiceableTransports = [];
        return Object.assign({}, state);
      }

      return Object.assign({}, state, {
        invoiceableTransports: [
          ...state.invoiceableTransports,
          ...action.payload['hydra:member']
        ],
        nextInvoiceableTransportPage: state.nextInvoiceableTransportPage + 1
      });
  }
  return state;
}

export function PurchaseOrderableTransportsReducer(
  state = INITIAL_PURCHASE_ORDERABLE_TRANSPORTS_STATE,
  action: TransportActions.All
): PurchaseOrderableTransportsState {
  switch (action.type) {
    case transportTypes.transports.LOAD_PURCHASE_ORDERABLE_TRANSPORTS_SUCCEEDED:
      if (!action.payload['hydra:member']) {
        state.purchaseOrderableTransports = null;
        return Object.assign({}, state);
      }
      state.purchaseOrderableTransports = [...action.payload['hydra:member']];
      state.nextPurchaseOrderableTransportPage = 2;
      return Object.assign({}, state);

    case transportTypes.transports
      .LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE_SUCCEEDED:
      if (!action.payload['hydra:member']) {
        state.purchaseOrderableTransports = [];
        return Object.assign({}, state);
      }

      return Object.assign({}, state, {
        purchaseOrderableTransports: [
          ...state.purchaseOrderableTransports,
          ...action.payload['hydra:member']
        ],
        nextPurchaseOrderableTransportPage:
          state.nextPurchaseOrderableTransportPage + 1
      });
  }
  return state;
}
