<div class="ml-3 mr-3">
  <div class="row">
    <div class="mb-3 col">
      <app-cmp-credit-amount [wallet]="wallet"></app-cmp-credit-amount>
      <div class="mt-3">
        <app-credit-invoices></app-credit-invoices>
      </div>
    </div>
    <div class="mb-3 col">
      <app-cmp-reload-credits></app-cmp-reload-credits>
      <div class="mt-3">
        <app-credits-warning></app-credits-warning>
      </div>
      <div class="mt-3">
        <app-cmp-psa-wallet-transactions-report></app-cmp-psa-wallet-transactions-report>
      </div>
    </div>
    <div class="mb-3 col">
      <app-cmp-transactions></app-cmp-transactions>
    </div>
  </div>
  <div class="row">
    <div class="col-3"></div>
  </div>
</div>
