import {
  LoadTaxRates,
  LoadFilteredTaxRates,
  DeleteTaxRate
} from 'app/core/store/actions/tax-rate.actions';
import { Store } from '@ngrx/store';
import { TaxRate } from 'app/core/store/models/tax-rate.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { extract } from 'app/services/i18n.service';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { ToastService } from 'app/services/toast.service';
import * as Types from 'app/core/store/types/tax-rate.types';
import { State } from 'app/core/store/store.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-tax-rates',
  templateUrl: './tax-rates.component.html',
  styleUrls: ['./tax-rates.component.scss']
})
export class TaxRatesComponent implements OnInit, OnDestroy {
  taxRates: TaxRate[];
  filteredTaxRates: TaxRate[];
  isLoadingTaxRates: boolean;
  totalElements: number;
  searchPlaceholder = extract('Search name or tax rate');
  tableMessages = {
    emptyMessage: extract('No tax rates to display'),
    totalMessage: extract('total')
  };
  isLoading: boolean;
  query: any;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private router: Router,
    private toastr: ToastService,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.taxRates &&
          state.crm.taxRates.taxRates
        ) {
          return state.crm.taxRates.taxRates;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(taxRatesState => {
        if (!taxRatesState) {
          return;
        }
        this.taxRates = [...taxRatesState];
        this.isLoadingTaxRates = false;
      });

    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.taxRates &&
          state.crm.taxRates.filteredTaxRates
        ) {
          return state.crm.taxRates.filteredTaxRates;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(filteredTaxRatesState => {
        if (!filteredTaxRatesState) {
          return;
        }
        this.filteredTaxRates = [...filteredTaxRatesState];
        this.isLoadingTaxRates = false;
      });

    this.updates$
      .pipe(ofType(Types.taxRate.LOAD_TAX_RATES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingTaxRates = false;
        this.toastr.showDanger({
          message: extract('Failed to load tax rates')
        });
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadTaxRates());
    this.isLoadingTaxRates = true;
  }

  handleSearch(query) {
    if (!query) {
      this.store.dispatch(new LoadTaxRates());
      return;
    }
    this.isLoadingTaxRates = true;
    this.query = query;
    this.store.dispatch(new LoadFilteredTaxRates({ query: query }));
  }
  editTaxRate(taxRateId: string) {
    this.router.navigateByUrl(`/crm/tax-rates/${taxRateId}/edit`);
  }
  deleteTaxRate(taxRate: TaxRate) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this tax rate?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteTaxRate({ taxRateId: taxRate.id }));
    });
  }

  ngOnDestroy() { }
}
