import * as types from '../types/driver-task.types';
import * as DriverTaskActions from '../actions/driver-task.actions';

const INITIAL_STATE = null;

export function DriverTasksReducer(
  state = INITIAL_STATE,
  action: DriverTaskActions.All
) {
  switch (action.type) {
    case types.drivers.LOAD_DRIVER_TASKS_SUCCEEDED:
      return Object.assign({}, action.payload);
  }
  return state;
}
