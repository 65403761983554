import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/notification.types';
import * as NotificationActions from '../actions/notifications.actions';
import { Notification } from '../models/notifications.model';

import { map, delay } from 'rxjs/operators';
@Injectable()
export class NotificationEffects {
  constructor(private actions$: Actions) { }

  // Automatically dismiss a notification after x milliseconds
  @Effect()
  notificationAutoDismiss$: Observable<Action> = this.actions$.pipe(
    ofType(types.notifications.ADD_NOTIFICATION),
    delay(4000),
    map((AddNotificationAction: NotificationActions.AddNotification) => {
      return new NotificationActions.DismissNotification(
        AddNotificationAction.payload
      );
    })
  );

  // Takes in a an Error Notification action and adds a new one.
  @Effect()
  ErrorNotification$: Observable<Action> = this.actions$.pipe(
    ofType(types.notifications.ERROR_NOTIFICATION),
    map((ErrorAction: NotificationActions.ErrorNotification) => {
      const newNotification: Notification = {
        message: ErrorAction.payload.message,
        type: 'alert-danger'
      };
      return new NotificationActions.AddNotification(newNotification);
    })
  );

  // Takes in a an Success Notification action and adds a new one.
  @Effect()
  SuccessNotification$: Observable<Action> = this.actions$.pipe(
    ofType(types.notifications.SUCCESS_NOTIFICATION),
    map((SuccessAction: NotificationActions.SuccessNotification) => {
      const newNotification: Notification = {
        message: SuccessAction.payload.message,
        type: 'alert-success'
      };
      return new NotificationActions.AddNotification(newNotification);
    })
  );
}
