import { Action } from '@ngrx/store';
import * as types from '../types/user.types';

export class LoadUser implements Action {
  readonly type = types.users.LOAD_USER;
  constructor(public payload?: any) {}
}

export class LoadUserSuccess implements Action {
  readonly type = types.users.LOAD_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadUserFailed implements Action {
  readonly type = types.users.LOAD_FAILED;
  constructor(public payload: any) {}
}

export class UpdateUser implements Action {
  readonly type = types.users.UPDATE_USER;
  constructor(public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = types.users.UPDATE_USER_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class UpdateUserFailed implements Action {
  readonly type = types.users.UPDATE_USER_FAILED;
  constructor(public payload?: any) {}
}
export class ResetUser implements Action {
  readonly type = types.users.RESET;
  constructor(public payload: any) {}
}

export class ResetUserSuccess implements Action {
  readonly type = types.users.RESET_SUCCEEDED;
  constructor(public payload: any) {}
}

export class ResetUserFailed implements Action {
  readonly type = types.users.RESET_FAILED;
  constructor(public payload: any) {}
}

export class ResetUserUpdate implements Action {
  readonly type = types.users.RESET_UPDATE;
  constructor(public payload: any) {}
}

export class ResetUserUpdateSuccess implements Action {
  readonly type = types.users.RESET_UPDATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class ResetUserUpdateFailed implements Action {
  readonly type = types.users.RESET_UPDATE_FAILED;
  constructor(public payload: any) {}
}

export class ClearUser implements Action {
  readonly type = types.users.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadUser
  | LoadUserSuccess
  | LoadUserFailed
  | ResetUser
  | ResetUserSuccess
  | ResetUserFailed
  | ResetUserUpdate
  | ResetUserUpdateFailed
  | ResetUserUpdateSuccess
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFailed
  | ClearUser;
