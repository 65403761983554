import { IconService } from 'app/services/icon.service';
import { Autocomplete } from 'app/services/autocomplete.service';
import { FormBuilder, Validators, } from '@angular/forms';
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as DateFns from 'date-fns';
// rxjs
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from 'app/services/location.service';
var NewMarketpostOfferComponent = /** @class */ (function () {
    function NewMarketpostOfferComponent(fb, autocompleteService, locationService, modalService, iconService) {
        var _this = this;
        this.fb = fb;
        this.autocompleteService = autocompleteService;
        this.locationService = locationService;
        this.modalService = modalService;
        this.iconService = iconService;
        this.transportOfferAdded = new EventEmitter();
        this.savedChanges = false;
        this.searchContainerType = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.autocompleteService.search('container_type', term);
            }));
        };
        this.formatter = function (x) { return x.label; };
        // Autocomplete liner
        this.searchLiner = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.autocompleteService.search('liner', term);
            }));
        };
        this.locationFormatter = function (x) {
            return x.name;
        };
        this.searchLocation = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.locationService.location(term);
            }));
        };
    }
    NewMarketpostOfferComponent.prototype.ngOnInit = function () {
        if (!this.transportForm) {
            this.createForm();
        }
    };
    NewMarketpostOfferComponent.prototype.ngOnChanges = function (changes) {
        if (changes.transportOffer && changes.transportOffer.currentValue) {
            this.savedChanges = false;
            if (this.transportTypes) {
                this.createForm();
                this.setTransportTasks();
                this.patchTransportForm(changes.transportOffer.currentValue);
            }
        }
        if (changes.transportTypes && changes.transportTypes.currentValue) {
            if (!this.transportOffer) {
                this.createForm();
                this.setTransportTasks();
            }
            else if (this.transportOffer) {
                this.patchTransportForm(this.transportOffer);
            }
        }
    };
    NewMarketpostOfferComponent.prototype.createForm = function () {
        this.transportForm = this.fb.group({
            container: this.fb.group({
                containerType: [null],
                liner: [null],
                weight: [null, this.numberValidator(/^((\d+\.?|\.(?=\d))?\d{0,9})$/)],
                adr: [false]
            }),
            reference: [''],
            genset: [{ value: false, disabled: true }],
            transportType: [
                this.transportTypes && this.transportTypes.length !== 0
                    ? this.transportTypes[0]
                    : null,
                Validators.required
            ],
            // TransportTasks depend on the transport type.
            transportTasks: this.fb.array([]),
            quantity: [1, Validators.min(0)],
            comment: ['']
        });
    };
    Object.defineProperty(NewMarketpostOfferComponent.prototype, "transportTasks", {
        get: function () {
            if (this.transportForm) {
                return this.transportForm.get('transportTasks');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewMarketpostOfferComponent.prototype, "comment", {
        get: function () {
            return this.transportForm.get('comment').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewMarketpostOfferComponent.prototype, "weight", {
        get: function () {
            return this.transportForm.get('container.weight');
        },
        enumerable: true,
        configurable: true
    });
    NewMarketpostOfferComponent.prototype.getContainerType = function (containerType) {
        if (this.containerTypes) {
            return this.containerTypes.find(function (ct) { return ct['@id'] === containerType; });
        }
    };
    NewMarketpostOfferComponent.prototype.setTransportTasks = function () {
        var _this = this;
        if (this.transportForm.get('transportType').value) {
            var tasks = this.transportForm.get('transportType').value['transportTypeTasks'];
            if (tasks) {
                var taskFGs = tasks.map(function (task) {
                    return _this.fb.group({
                        taskType: _this.fb.group({
                            '@id': task.taskType['@id'],
                            icon: _this.getTaskIcon(task),
                            id: task.taskType.id,
                            label: task.taskType.code
                        }),
                        location: [],
                        startDate: [],
                        startTime: [],
                        endDate: [],
                        endTime: [],
                        startDateTimeSpecified: [false],
                        endDateTimeSpecified: [false]
                    });
                });
                var taskFormArray = this.fb.array(taskFGs);
                this.transportForm.setControl('transportTasks', taskFormArray);
            }
        }
    };
    NewMarketpostOfferComponent.prototype.selectContainerType = function (cType) {
        this.checkReefer(cType);
    };
    NewMarketpostOfferComponent.prototype.onSubmit = function () {
        var _this = this;
        var payload = {
            transport: JSON.parse(JSON.stringify(this.transportForm.value)),
            index: this.index
        };
        this.transportOfferAdded.emit(payload);
        // this fixes the transport tasks being reset after submit
        var tasks = this.transportForm.get('transportTasks').value;
        tasks.forEach(function (task) {
            var tasksInfo = _this.transportForm.get('transportType').value['transportTypeTasks'];
            var taskInfo = tasksInfo.find(function (t) { return t['@id'] === task.taskType['id']; });
        });
        this.transportForm.get('transportTasks').patchValue(tasks);
        if (this.transportOffer) {
            this.savedChanges = true;
            this.transportOffer = null;
        }
    };
    // Autocomplete container_type
    NewMarketpostOfferComponent.prototype.searchMapping = function (searchType) {
        return searchType === 'searchContainerType'
            ? this.searchContainerType
            : this.searchLiner;
    };
    NewMarketpostOfferComponent.prototype.getTaskIcon = function (task) {
        return this.iconService.getTransportTaskIcon(task);
    };
    NewMarketpostOfferComponent.prototype.getTypeIcon = function (type) {
        return this.iconService.getTransportTypeIcon(type);
    };
    NewMarketpostOfferComponent.prototype.checkReefer = function ($event) {
        if ($event) {
            $event.is_reefer || $event.code.toLowerCase().indexOf('reefer') !== -1
                ? this.transportForm.controls['genset'].enable()
                : this.transportForm.controls['genset'].disable();
        }
    };
    NewMarketpostOfferComponent.prototype.hasAtLeastOneLocation = function () {
        return this.transportTasks.controls.some(function (task) { return task.value.location; });
    };
    NewMarketpostOfferComponent.prototype.open = function (event, content) {
        event.preventDefault();
        this.modalService.open(content);
    };
    NewMarketpostOfferComponent.prototype.setTransportType = function (type) {
        this.transportForm.get('transportType').patchValue(type);
        this.setTransportTasks();
    };
    /**
     * Custom validator that uses a regex to check if a formcontrol is a number or decimal.
     * @param numberRe regex that checkes if the formcontrol value is a number or a decimal.
     */
    NewMarketpostOfferComponent.prototype.numberValidator = function (numberRe) {
        return function (control) {
            if (control.value === null) {
                return null;
            }
            var notNumber = numberRe.test(control.value);
            return notNumber ? null : { notNumber: { value: control.value } };
        };
    };
    NewMarketpostOfferComponent.prototype.patchTransportForm = function (offer) {
        var _this = this;
        this.edit = true;
        // patch form
        this.transportForm.patchValue({
            container: {
                weight: offer.container.weight ? offer.container.weight : null,
                adr: offer.container.adr
            },
            reference: offer.reference,
            comment: offer.comment,
            genset: offer.genset,
            quantity: offer.quantity ? offer.quantity : 1
        });
        if (offer.container.liner && this.liners) {
            this.transportForm.get('container').patchValue({
                liner: this.liners.find(function (liner) { return liner['@id'] === offer.container.liner; })
                    .id
            });
        }
        else {
            this.transportForm.get('container').patchValue({
                liner: null
            });
        }
        if (offer.container.containerType && this.containerTypes) {
            this.transportForm.get('container').patchValue({
                containerType: this.containerTypes.find(function (ct) { return ct['@id'] === offer.container.containerType; }).id
            });
        }
        else {
            this.transportForm.get('container').patchValue({
                containerType: null
            });
        }
        if (typeof offer.transportType === 'string') {
            this.transportForm
                .get('transportType')
                .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === offer.transportType; }));
        }
        else {
            this.transportForm
                .get('transportType')
                .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === offer.transportType['@id']; }));
        }
        this.setTransportTasks();
        offer.transportTasks = offer.transportTasks.map(function (task) {
            return {
                taskType: task.taskType ? task.taskType : null,
                location: task.location ? task.location : null,
                startDateTimeSpecified: task.startDateTimeSpecified,
                endDateTimeSpecified: task.endDateTimeSpecified,
                startDate: task.startDate
                    ? new Date(moment(task.startDate).year(), moment(task.startDate).month(), moment(task.startDate).date())
                    : null,
                startTime: task.startDate && task.startDateTimeSpecified && !task.startTime
                    ? {
                        hour: moment(task.startDate).hours(),
                        minute: moment(task.startDate).minutes(),
                        second: 0
                    }
                    : task.startTime || null,
                endDate: task.endDate
                    ? new Date(moment(task.endDate).year(), moment(task.endDate).month(), moment(task.endDate).date())
                    : null,
                endTime: task.endDate && task.endDateTimeSpecified && !task.endTime
                    ? {
                        hour: moment(task.endDate).hours(),
                        minute: moment(task.endDate).minutes(),
                        second: 0
                    }
                    : task.endTime || null
            };
        });
        offer.transportTasks.forEach(function (task) {
            var index = _this.transportForm
                .get('transportTasks')['controls'].indexOf(_this.transportForm.get('transportTasks')['controls'].find(function (control) {
                return (control.value.taskType['@id'] ===
                    (task.taskType['@id'] ? task.taskType['@id'] : task.taskType));
            }));
            if (index !== -1) {
                _this.transportTasks.at(index).patchValue(task);
            }
        });
    };
    /**
     *
     * @param date date object given by the datepicker
     * @param current the current year and month
     * @returns boolean if given date is before today
     */
    NewMarketpostOfferComponent.prototype.getDisabledDates = function (date) {
        var today = new Date();
        var d = new Date(date.year, date.month, date.day);
        d = DateFns.subMonths(d, 1);
        d = DateFns.addDays(d, 1);
        return DateFns.isBefore(d, today);
    };
    return NewMarketpostOfferComponent;
}());
export { NewMarketpostOfferComponent };
