<div class="row ml-1 mr-1">
  <div class="col">
    <ngb-alert
      [dismissible]="false"
      *ngIf="hasActivatedMatching"
      class="text-center"
    >
      <div>
        {{ 'You have activated your matching subscription.' | translate }}
      </div>
      <div>
        {{
          'Here you can add companies to your matching circle. You will only get matches from companies in this circle. If this circle is empty you will receive matches from all companies.'
            | translate
        }}
      </div>
    </ngb-alert>
    <app-cmp-circle-list
      [loading]="loading"
      [circles$]="circles$"
    ></app-cmp-circle-list>
  </div>
  <div class="col">
    <app-cmp-new-circle
      [circles]="circles$ | async"
      [formTitle]="formTitle"
      [buttonText]="'Submit'"
      (circleSubmitted)="saveCircle($event)"
    ></app-cmp-new-circle>
  </div>
</div>
