import { LoadCreditNotes, UpdateCreditNote } from 'app/core/store/actions/credit-note.actions';
import { PaymentNote } from 'app/core/store/models/payment-note.model';
import { Component, OnDestroy } from '@angular/core';
import { Customer } from 'app/core/store/models/customer.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';
import { LoadCustomers } from 'app/core/store/actions/customer.actions';
import { LoadTaxRates } from 'app/core/store/actions/tax-rate.actions';
import * as CreditNoteTypes from 'app/core/store/types/credit-note.types';
import * as CustmomerTypes from 'app/core/store/types/customer.types';
import * as TaxRateTypes from 'app/core/store/types/tax-rate.types';
import { extract } from 'app/services/i18n.service';
import { TaxRate } from 'app/core/store/models/tax-rate.model';
import { DownloadService } from 'app/services/download.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-credit-note',
  templateUrl: './edit-credit-note.component.html',
})
export class EditCreditNoteComponent implements OnDestroy {
  creditNote: PaymentNote;
  id: string;
  customers: Customer[];
  isLoadingCustomers: boolean;
  taxRates: TaxRate[];
  isLoadingTaxRates: boolean;
  isLoading: boolean;
  downloadCreditNoteWhenReady: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private updates$: Actions,
    private store: Store<State>,
    private downloadService: DownloadService,
    private toastr: ToastService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.store
          .select(state => state.crm.creditNotes)
          .pipe(untilDestroyed(this))
          .subscribe(creditNotesState => {
            if (!creditNotesState || !creditNotesState.creditNotes) {
              this.store.dispatch(new LoadCreditNotes());
              return;
            }
            this.creditNote = creditNotesState.creditNotes.find(
              c => c.id === this.id
            );
          });
      }
    });
    this.store
      .select(state => state.crm.customers)
      .pipe(untilDestroyed(this))
      .subscribe(customersState => {
        if (!customersState || !customersState.customers) {
          this.store.dispatch(new LoadCustomers());
          this.isLoadingCustomers = true;
          return;
        }
        this.customers = customersState.customers;
        this.isLoadingCustomers = false;
      });
    this.store
      .select(state => state.crm.taxRates)
      .pipe(untilDestroyed(this))
      .subscribe(taxRatesState => {
        if (!taxRatesState || !taxRatesState.taxRates) {
          this.store.dispatch(new LoadTaxRates());
          this.isLoadingTaxRates = true;
          return;
        }
        this.taxRates = taxRatesState.taxRates;
        this.isLoadingTaxRates = false;
      });
    this.updates$
      .pipe(ofType(CreditNoteTypes.creditNote.UPDATE_CREDIT_NOTE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update credit note!')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(CreditNoteTypes.creditNote.UPDATE_CREDIT_NOTE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.store.dispatch(new LoadCreditNotes());
        this.toastr.showSuccess({
          message: extract('Credit note updated successfully!')
        });
        if (this.downloadCreditNoteWhenReady) {
          this.downloadService.downloadPDF(
            `/crm/credit_note/download/${response['payload'].id}`,
            response['payload'].number,
          ).then(() => {
            this.router.navigateByUrl('/crm/credit-notes');
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          });
        } else {
          this.router.navigateByUrl('/crm/credit-notes');
          this.isLoading = false;
        }
      });
    this.updates$
      .pipe(ofType(CustmomerTypes.customer.LOAD_CUSTOMERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load customers')
        });
        this.isLoadingCustomers = false;
      });
    this.updates$
      .pipe(ofType(TaxRateTypes.taxRate.LOAD_TAX_RATES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load tax rates')
        });
        this.isLoadingTaxRates = false;
      });
  }

  updateCreditNote(creditNoteFormValue) {
    this.store.dispatch(
      new UpdateCreditNote({
        creditNoteId: this.id,
        body: creditNoteFormValue
      })
    );
    this.isLoading = true;
  }

  updateAndDownloadCreditNote(creditNoteFormValue) {
    this.updateCreditNote(creditNoteFormValue);
    this.downloadCreditNoteWhenReady = true;
  }

  ngOnDestroy() { }
}
