import { CreateDriver, UpdateDriver } from 'app/core/store/actions/driver.actions';
import { Actions, ofType } from '@ngrx/effects';
import { State } from 'app/core/store/store.model';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as DriverTypes from 'app/core/store/types/driver.types';
import { UserContext } from 'app/core/store/models/user-contexts.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-driver-modal',
  templateUrl: './new-driver-modal.component.html',
})
export class NewDriverModalComponent implements OnDestroy {
  isLoading: boolean;
  userContext: UserContext;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<State>,
    private toastService: ToastService,
    private updates$: Actions
  ) {
    this.updates$
      .pipe(ofType(DriverTypes.drivers.CREATE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        const userId = response['payload'].id;
        this.updateDriver(userId);
      });
    this.updates$
      .pipe(ofType(DriverTypes.drivers.UPDATE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.toastService.showSuccess({
          title: extract('Create driver success!')
        });
        this.isLoading = false;
        this.activeModal.close(response);
      });

    // Stop the loading spinner if one of our calls fails
    this.updates$
      .pipe(ofType(
        DriverTypes.drivers.CREATE_DRIVER_FAILED,
        DriverTypes.drivers.UPDATE_DRIVER_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  ngOnDestroy() { }

  createNewDriver(userContextFormValue) {
    this.userContext = userContextFormValue;
    const body = {
      email: userContextFormValue.email,
      firstName: userContextFormValue.firstName,
      lastName: userContextFormValue.lastName,
      locale: this.userContext.locale,
      role: 'DRIVER'
    };
    this.store.dispatch(new CreateDriver(body));
    this.isLoading = true;
  }

  updateDriver(userId: string) {
    const body = {
      phoneNumber: this.userContext.phoneNumber,
      locale: this.userContext.locale,
      licensePlate: this.userContext.licensePlate
    };
    this.store.dispatch(new UpdateDriver({ driverId: userId, driver: body }));
  }
}
