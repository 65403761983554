<div class="container p-3">
  <ng-select class="mb-3"
    id="driver"
    [items]="drivers"
    bindLabel="name"
    placeholder="{{ 'Select driver to filter' | translate }}"
    [loading]="isLoadingDrivers"
    [(ngModel)]="driver"
    (ngModelChange)="handleDriverSelection()"
    (clearEvent)="handleDriverSelection()">
  </ng-select>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <span class="mr-auto">
            <span>
              {{ 'Schedule' | translate }}
            </span>
            <span class="text-muted">{{ '(drag and drop visits to change order)' | translate }}</span>
          </span>
        </div>
        <div class="card-body">
          <table class="table table-bordered">
            <thead class="thead-info">
              <tr>
                <th>{{ 'Order' | translate }}</th>
                <th>{{ 'Scheduled at' | translate }}</th>
                <th>{{ 'Tasks' | translate }}</th>
              </tr>
            </thead>
            <tbody dragula="VISITS"
              [(dragulaModel)]="visits">
              <tr *ngFor="let visit of visits">
                <td>{{ visit['sequence'] }}</td>
                <td>{{ visit.scheduledAt | date:'dd/MM/yyyy' }}</td>
                <td class="p-0">
                  <app-transport-tasks-table [visitStatus]="visit.status"
                    [transportTasks]="visit.transportTasks"></app-transport-tasks-table>
                </td>
              </tr>
              <tr *ngIf="(!visits || visits.length === 0) && !isLoadingVisits">
                <td>{{ 'No visits planned for this driver' | translate }}</td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngIf="isLoadingVisits">
                <td>{{ 'Loading visits...' | translate }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
