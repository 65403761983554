import * as types from '../types/schedule.types';
import * as ScheduleActions from '../actions/schedule.actions';
import { Schedule } from '../models/schedule.model';

export interface ScheduleState {
  schedule: Schedule[];
  unplannedTransports: any[];
  nextUnplannedTransportsPage: number;
}

const INITIAL_STATE = {
  schedule: null,
  unplannedTransports: null,
  nextUnplannedTransportsPage: 2
};

export function ScheduleReducer(
  state = INITIAL_STATE,
  action: ScheduleActions.All
): ScheduleState {
  switch (action.type) {
    case types.schedule.LOAD_SCHEDULE_SUCCEEDED:
      return Object.assign({}, state, {
        schedule: [...action.payload]
      });
    case types.schedule.LOAD_UNPLANNED_TRANSPORTS_SUCCEEDED:
      if (!action.payload['hydra:member']) {
        return Object.assign({}, state, {
          unplannedTransports: [],
          nextUnplannedTransportsPage: 2
        });
      }
      return Object.assign({}, state, {
        unplannedTransports: [...action.payload['hydra:member']],
        nextUnplannedTransportsPage: 2
      });
    case types.schedule.LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE_SUCCEEDED:
      if (!action.payload['hydra:member']) {
        state.unplannedTransports = [];
        return Object.assign({}, state);
      }
      return Object.assign({}, state, {
        unplannedTransports: [
          ...state.unplannedTransports,
          ...action.payload['hydra:member']
        ],
        nextUnplannedTransportsPage: state.nextUnplannedTransportsPage + 1
      });
  }
  return state;
}
