<app-cmp-navbar
  [navbarTitle]="'Manage Registration requests' | translate"
  [showButton]="true"
  [buttonText]="(showProcessed ? 'Show pending requests' : 'Show processed requests') | translate"
  [buttonType]="'btn-info'"
  (buttonClicked)="toggleShowProcessed()"
></app-cmp-navbar>
<ngx-datatable
  class="bootstrap row ml-0 mr-0"
  [rows]="registrationRequests"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="false"
  [count]="totalElements"
  [limit]="30"
  [loadingIndicator]="loading"
>
  <ngx-datatable-column [name]="'Company Name' | translate" prop="companyName"></ngx-datatable-column>
  <ngx-datatable-column [name]="'VAT number' | translate" prop="vatNumber"></ngx-datatable-column>
  <ngx-datatable-column [name]="'First Name' | translate" prop="firstName"></ngx-datatable-column>
  <ngx-datatable-column [name]="'Last Name' | translate" prop="lastName"></ngx-datatable-column>
  <ngx-datatable-column [name]="'Requested subscriptions' | translate" prop="subscriptionTypes">
    <ng-template ngx-datatable-cell-template let-value="value">
      <div *ngFor="let subscriptionType of value">
        <span>{{ subscriptionType.name }}</span>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'Requested at' | translate" prop="createdAt">
    <ng-template ngx-datatable-cell-template let-value="value">
      {{ value | date: 'dd/MM/yyyy HH:mm' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'Actions' | translate" prop="" maxWidth="50px">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button class="btn btn-success action" *ngIf="value.status !== 'approved'" (click)="approve(row.id)">
        {{ 'Approve' | translate }}
      </button>
      <button class="btn btn-danger action" *ngIf="value.status === 'pending'" (click)="decline(row.id)">
        {{ 'Decline' | translate }}
      </button>
      <button class="btn btn-info action" (click)="edit(row.id)">
        <i class="far fa-pencil"></i> <span>{{ 'Edit' | translate }}</span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
