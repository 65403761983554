import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as types from '../types/customer.types';
import * as CustomerActions from '../actions/customer.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class CustomerEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(types.customer.LOAD_CUSTOMERS),
    concatMap(() => this.api.get({
      path: `/crm/customers`
    }).pipe(
      map(data => new CustomerActions.LoadCustomersSuccess(data)),
      catchError(error => of(new CustomerActions.LoadCustomersFail(error)))
    )),
  );

  @Effect()
  loadFilteredCustomers$: Observable<Action> = this.actions$.pipe(
    ofType(types.customer.LOAD_FILTERED_CUSTOMERS),
    map((action: CustomerActions.LoadFilteredCustomers) => action.payload),
    concatMap(payload => this.api.get({
      path: '/crm/customers'
        + '?label=' + (payload && payload.query ? payload.query : '')
        + '&vatNumber=' + (payload && payload.query ? payload.query : '')
    }).pipe(
      map(data => new CustomerActions.LoadFilteredCustomersSuccess(data)),
      catchError(error => of(new CustomerActions.LoadFilteredCustomersFail(error)))
    )),
  );

  @Effect()
  createCustomer$: Observable<Action> = this.actions$.pipe(
    ofType(types.customer.CREATE_CUSTOMER),
    map((action: CustomerActions.CreateCustomer) => action.payload),
    concatMap(payload => this.api.post({
      path: `/crm/customers`, body: payload
    }).pipe(
      map(data => new CustomerActions.CreateCustomerSuccess(data)),
      catchError(error => of(new CustomerActions.CreateCustomerFail(error)))
    )),
  );

  @Effect()
  deleteCustomer$: Observable<Action> = this.actions$.pipe(
    ofType(types.customer.DELETE_CUSTOMER),
    map((action: CustomerActions.DeleteCustomer) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/customers/${payload.customerId}`
    }).pipe(
      map(() => new CustomerActions.DeleteCustomerSuccess(payload.customerId)),
      catchError(error => of(new CustomerActions.DeleteCustomerFail(error)))
    )),
  );

  @Effect()
  updateCustomer$: Observable<Action> = this.actions$.pipe(
    ofType(types.customer.UPDATE_CUSTOMER),
    map((action: CustomerActions.UpdateCustomer) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/customers/${payload.customerId}`,
      body: payload.customer
    }).pipe(
      map(data => new CustomerActions.UpdateCustomerSuccess(data)),
      catchError(error => of(new CustomerActions.UpdateCustomerFail(error)))
    )),
  );
}
