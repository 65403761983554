<div class="m-3">
  <ngb-alert *ngIf="alert" (close)="alert = null" [type]="alert.type">
    <i class="fa" [ngClass]="alert.icon"></i>
    <span>{{ alert.message['statusText'] ? alert.message['statusText'] : alert.message | json }}</span>
  </ngb-alert>
  <div class="card">
    <div class="card-header">
      <h3>{{ 'New Shipping Agent' | translate }}</h3>
    </div>
    <div class="card-body">
      <app-shipping-agent-form (shippingAgentFormSubmitted)="createNewShippingAgent($event)"></app-shipping-agent-form>
    </div>
  </div>
</div>
