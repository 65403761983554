<div class="card">
  <h3 class="card-header">{{ 'Credit reminder' | translate }}</h3>
  <div class="card-body">
    <p class="text-muted small">{{ 'Here you can set a threshold value for an email reminder. If the credits in your wallet drop below this value, you will be notified via email.' | translate }}
    </p>

    <form (ngSubmit)="onSubmit()" class="mt-2" [formGroup]="thresholdForm">
      <div class="form-group">
        <label>{{ 'Amount' | translate }}</label>
        <input type="number"
               min="0" max="999999.99"
               class="form-control"
               formControlName="warningThreshold"
               [ngClass]="{
            'is-invalid':
              thresholdForm.controls['warningThreshold'].invalid &&
              thresholdForm.controls['warningThreshold'].touched
          }">
        <div class="invalid-feedback">
          {{ 'This is not possible.' | translate }}
        </div>
      </div>
      <button class="btn btn-primary text-white" type="submit"
              [disabled]="loading">
        <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
        <span>
          {{ 'Save' | translate }}
        </span>
      </button>
    </form>
  </div>
</div>

