import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cmp-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})
export class NavbarComponent {
  @Input() showBackButton = true;
  @Input() loading;
  @Input() navbarTitle = 'Company Posts';
  @Input() showButton = false;
  @Input() buttonType = 'btn-success';
  @Input() buttonIcon;
  @Input() buttonText = 'Click me!';
  @Input() showDanger = false;
  @Input() dangerText = 'Carefull!';
  @Output() buttonClicked = new EventEmitter<boolean>();
  @Output() dangerClicked = new EventEmitter<boolean>();
  constructor(private location: Location) {}

  navigateBack(): void {
    this.location.back();
  }

  buttonClick() {
    this.buttonClicked.emit(true);
  }

  dangerClick() {
    this.dangerClicked.emit(true);
  }
}
