import { DashboardState } from './dashboard.reducer';
import * as MarketPostsTypes from '../types/market-post.types';
import * as MarketPostActions from '../actions/market-posts.actions';
import * as LocationTypes from '../types/location.types';
import * as LocationActions from '../actions/location.actions';

export interface DashboardState {
  marketPostsCount: any;
  uncheckedLocation: any;
}

const INITIAL_STATE = {
  marketPostsCount: null,
  uncheckedLocation: null
};

export function DashboardReducer(
  state = INITIAL_STATE,
  action: MarketPostActions.All | LocationActions.All
): DashboardState {
  switch (action.type) {
    case MarketPostsTypes.marketposts.LOAD_MARKETPOSTS_COUNT_SUCCEEDED:
      state.marketPostsCount = action.payload.count;
      return Object.assign({}, state);
    case LocationTypes.location.LOAD_UNCHECKED_LOCATIONS_SUCCEEDED:
      if (action.payload === null) {
        state.uncheckedLocation = undefined;
        return Object.assign({}, state);
      }
      state.uncheckedLocation = action.payload;
      return Object.assign({}, state);
    case LocationTypes.location.UPDATE_USER_LOCATION_SUCCEEDED:
      if (
        state &&
        state.uncheckedLocation &&
        state.uncheckedLocation.id === action.payload.id &&
        action.payload.skippedDuringImprovementSuggestion
      ) {
        state.uncheckedLocation = null;
      }
      return Object.assign({}, state);
  }
  return state;
}
