/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./market-posts-component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./market-post-offers/market-post-offer.component.ngfactory";
import * as i4 from "./market-post-offers/market-post-offer.component";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/router";
import * as i7 from "@ngrx/effects";
import * as i8 from "../../services/icon.service";
import * as i9 from "./market-post-demands/market-post-demand.component.ngfactory";
import * as i10 from "./market-post-demands/market-post-demand.component";
import * as i11 from "@angular/common";
import * as i12 from "./market-post-table/market-post-table.component.ngfactory";
import * as i13 from "./market-post-table/market-post-table.component";
import * as i14 from "../../services/toast.service";
import * as i15 from "ngx-infinite-scroll";
import * as i16 from "./market-posts.component";
import * as i17 from "../../services/search.service";
var styles_MarketPostsComponent = [i0.styles];
var RenderType_MarketPostsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MarketPostsComponent, data: {} });
export { RenderType_MarketPostsComponent as RenderType_MarketPostsComponent };
function View_MarketPostsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "progress-bar progress-bar-striped progress-bar-animated bg-info"], ["role", "progressbar"], ["style", "width: 100%"]], null, null, null, null, null))], null, null); }
function View_MarketPostsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("No results found.")); _ck(_v, 1, 0, currVal_0); }); }
function View_MarketPostsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-market-post-offer", [["class", "card"]], null, [[null, "enableMatching"], [null, "disableMatching"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("enableMatching" === en)) {
        var pd_0 = (_co.handleEnableMatching($event) !== false);
        ad = (pd_0 && ad);
    } if (("disableMatching" === en)) {
        var pd_1 = (_co.handleDisableMatching($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MarketPostOfferComponent_0, i3.RenderType_MarketPostOfferComponent)), i1.ɵdid(1, 180224, null, 0, i4.MarketPostOfferComponent, [i5.Store, i6.Router, i7.Actions, i8.IconService], { marketPost: [0, "marketPost"], hasActiveMatchingSubscription: [1, "hasActiveMatchingSubscription"] }, { enableMatching: "enableMatching", disableMatching: "disableMatching" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.hasActiveMatchingSubscription; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MarketPostsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-market-post-demand", [["class", "card"]], null, [[null, "enableMatching"], [null, "disableMatching"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("enableMatching" === en)) {
        var pd_0 = (_co.handleEnableMatching($event) !== false);
        ad = (pd_0 && ad);
    } if (("disableMatching" === en)) {
        var pd_1 = (_co.handleDisableMatching($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_MarketPostDemandComponent_0, i9.RenderType_MarketPostDemandComponent)), i1.ɵdid(1, 180224, null, 0, i10.MarketPostDemandComponent, [i5.Store, i6.Router, i7.Actions, i8.IconService], { marketPost: [0, "marketPost"], hasActiveMatchingSubscription: [1, "hasActiveMatchingSubscription"] }, { enableMatching: "enableMatching", disableMatching: "disableMatching" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.hasActiveMatchingSubscription; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MarketPostsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "market-post"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_5)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_6)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.marketPostType) !== "demand"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.marketPostType) === "demand"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_MarketPostsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "market-posts-grid ml-3 mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_4)), i1.ɵdid(2, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.marketposts$; var currVal_1 = _co.trackById; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MarketPostsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-market-post-table", [], null, [[null, "enableMatching"], [null, "disableMatching"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("enableMatching" === en)) {
        var pd_0 = (_co.handleEnableMatching($event) !== false);
        ad = (pd_0 && ad);
    } if (("disableMatching" === en)) {
        var pd_1 = (_co.handleDisableMatching($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_MarketPostTableComponent_0, i12.RenderType_MarketPostTableComponent)), i1.ɵdid(1, 704512, null, 0, i13.MarketPostTableComponent, [i6.Router, i5.Store, i7.Actions, i1.NgZone, i14.ToastService, i8.IconService], { marketposts: [0, "marketposts"], showFilter: [1, "showFilter"], showMB: [2, "showMB"], hasActiveMatchingSubscription: [3, "hasActiveMatchingSubscription"] }, { disableMatching: "disableMatching", enableMatching: "enableMatching" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.marketposts$; var currVal_1 = _co.showFilter; var currVal_2 = _co.showMB; var currVal_3 = _co.hasActiveMatchingSubscription; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_MarketPostsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_8)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.cardView; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MarketPostsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { table: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_2)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "search-results mt-3"], ["data-infinite-scroll", ""], ["debounce", ""]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.onScrollDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 4866048, null, 0, i15.InfiniteScrollDirective, [i1.ElementRef, i1.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollUpDistance: [1, "infiniteScrollUpDistance"], infiniteScrollThrottle: [2, "infiniteScrollThrottle"], infiniteScrollContainer: [3, "infiniteScrollContainer"], scrollWindow: [4, "scrollWindow"], fromRoot: [5, "fromRoot"] }, { scrolled: "scrolled" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_3)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MarketPostsComponent_7)), i1.ɵdid(12, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.marketposts$ == null) ? null : _co.marketposts$.length) === 0) && !_co.filtered); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((((_co.marketposts$ == null) ? null : _co.marketposts$.length) === 0) && _co.filtered); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.scrollDistance; var currVal_3 = _co.scrollUpDistance; var currVal_4 = _co.throttle; var currVal_5 = ".ng-sidebar__content"; var currVal_6 = false; var currVal_7 = true; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.cardView; _ck(_v, 10, 0, currVal_8); var currVal_9 = !_co.cardView; _ck(_v, 12, 0, currVal_9); }, null); }
export function View_MarketPostsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-market-posts", [], null, null, null, View_MarketPostsComponent_0, RenderType_MarketPostsComponent)), i1.ɵdid(1, 245760, null, 0, i16.MarketPostsComponent, [i5.Store, i7.Actions, i14.ToastService, i17.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MarketPostsComponentNgFactory = i1.ɵccf("app-cmp-market-posts", i16.MarketPostsComponent, View_MarketPostsComponent_Host_0, { cardView: "cardView", filtered: "filtered", showFilter: "showFilter", showMB: "showMB" }, {}, []);
export { MarketPostsComponentNgFactory as MarketPostsComponentNgFactory };
