import * as types from '../types/wallet.types';
import * as WalletActions from '../actions/wallet.actions';
import { Wallet } from '../models/wallet.model';

const INITIAL_STATE = {
  '@context': '',
  '@id': '',
  '@type': '',
  credits: null,
  id: '',
  reservedCredits: null,
  warningThreshold: null
};

export function reducer(
  state = INITIAL_STATE,
  action: WalletActions.All
): Wallet {
  switch (action.type) {
    case types.wallet.LOAD_SUCCEEDED:
      return Object.assign({}, state, action.payload);
    case types.wallet.RECHARGE_SUCCEEDED:
      return Object.assign({}, state, action.payload);
    case types.wallet.CLEAR:
      return null;
  }
  return state;
}
