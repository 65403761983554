import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiService } from 'app/core/api/api.service';
import { LoadPrenotifiableLocations } from 'app/core/store/actions/location.actions';
import { CheckTransportTaskReference } from 'app/core/store/actions/transports.actions';
import { LoadVisits } from 'app/core/store/actions/visits.actions';
import { LocationTypes } from 'app/core/store/models/location-type.model';
import { Location } from 'app/core/store/models/location.model';
import { Visit } from 'app/core/store/models/visits.model';
import { State } from 'app/core/store/store.model';
import * as Types from 'app/core/store/types/transport.types';
import * as VisitTypes from 'app/core/store/types/visit.types';
import { DatatableService } from 'app/services/datatable.service';
import { extract } from 'app/services/i18n.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
import * as DateFns from 'date-fns';

import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit, OnDestroy {
  title = extract('Manage Visits');
  taskCount: number;
  visits: Visit[];
  totalElements = 0;
  rowLimit = 30;
  fromDate: any;
  toDate: any;
  isLoadingVisits: boolean;
  actionRow: number;
  isLoading: boolean;
  locations: Location[];
  selectedLocation: Location;
  isLoadingLocations = true;
  location: any;
  LocationTypes = LocationTypes;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private updates$: Actions,
    private router: Router,
    private datatableService: DatatableService,
    private toastr: ToastService,
    public locationService: LocationService,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => state.tms.visits)
      .pipe(untilDestroyed(this))
      .subscribe(visitsState => {
        if (visitsState) {
          this.visits = visitsState;
          this.totalElements = visitsState.length;
          this.visits.forEach(visit =>
            visit.transportTasks.map(
              task => (task['checkingReference'] = false)
            )
          );
          this.isLoadingVisits = false;
        }
      });
    this.store.dispatch(new LoadPrenotifiableLocations());
    this.isLoadingLocations = true;
    this.store
      .select(state => state.tms.locations)
      .pipe(untilDestroyed(this))
      .subscribe(locationsState => {
        if (locationsState && locationsState.prenotifiableLocations) {
          this.locations = locationsState.prenotifiableLocations;
          this.isLoadingLocations = false;
        }
      });
  }

  ngOnInit() {
    const today = new Date();
    if (!localStorage.getItem('visitsFromDate')) {
      this.fromDate = today;
    } else {
      const fDate = localStorage.getItem('visitsFromDate').split('-');
      this.fromDate = new Date(+fDate[2], +fDate[1] - 1, +fDate[0]);
    }
    if (!localStorage.getItem('visitsToDate')) {
      this.toDate = today;
    } else {
      const tDate = localStorage.getItem('visitsToDate').split('-');
      this.toDate = new Date(+tDate[2], +tDate[1] - 1, +tDate[0]);
    }

    this.store.dispatch(
      new LoadVisits({
        fromDate: DateFns.format(this.fromDate, 'DD-MM-YYYY'),
        toDate: DateFns.format(this.toDate, 'DD-MM-YYYY')
      })
    );
    this.isLoadingVisits = true;
    this.updates$
      .pipe(ofType(Types.transports.CHECK_TRANSPORT_TASK_REFERENCE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.taskCount--;
        if (this.taskCount === 0) {
          this.loadVisits();
          this.toastr.showSuccess({
            message: extract('Reference checked successfully')
          });
          this.actionRow = null;
          this.isLoading = false;
        }
      });
    this.updates$
      .pipe(ofType(VisitTypes.visits.LOAD_VISITS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingVisits = false;
        this.toastr.showDanger({
          message: extract('Failed to load prenotifications')
        });
      });
    this.updates$
      .pipe(ofType(Types.transports.CHECK_TRANSPORT_TASK_REFERENCE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.taskCount--;
        this.actionRow = null;
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to check reference')
        });
      });
  }

  ngOnDestroy() { }

  checkTaskReferences(visit, rowIndex) {
    this.actionRow = rowIndex;
    this.isLoading = true;
    this.taskCount = visit.transportTasks.length;
    visit.transportTasks.forEach(task => {
      if (task.reference) {
        task['checkingReference'] = true;
        this.store.dispatch(
          new CheckTransportTaskReference({
            id: task.id
          })
        );
      }
    });
  }

  /**
   * Deletes the given visit
   * @param visit the visit object
   * @param rowIndex index of the row which triggered the action
   */
  deleteVisit(visit, rowIndex) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this prenotification?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.actionRow = rowIndex;
      this.isLoading = true;
      this.api.delete({ path: '/visits/' + visit.id }).subscribe(
        () => {
          this.isLoading = false;
          this.actionRow = null;
          this.toastr.showSuccess({
            message: extract('Prenotification deleted!')
          });
          this.isLoadingVisits = true;
          this.store.dispatch(
            new LoadVisits({
              fromDate: DateFns.format(this.fromDate, 'DD-MM-YYYY'),
              toDate: DateFns.format(this.toDate, 'DD-MM-YYYY')
            })
          );
        },
        () => {
          this.isLoading = false;
          this.actionRow = null;
          this.toastr.showDanger({
            title: extract('Something went wrong'),
            message: extract('Failed to delete prenotification')
          });
        }
      );
    });
  }

  /**
   * Redirects to the create new visit page
   */
  goToCreate() {
    this.router.navigateByUrl('/tms/prenotifications/new');
  }

  getDropdownPlacement(rowIndex) {
    return this.datatableService.getDropdownPlacement(
      rowIndex,
      this.totalElements,
      this.rowLimit
    );
  }

  /**
   * Load visits that have at least one task with the selected location
   */
  handleLocationSelection() {
    this.loadVisits();
  }

  /**
   * Load visits between daterange
   * @param daterange fromDate and toDate
   */
  handleDateSelection(daterange: { fromDate: string; toDate: string }) {
    this.isLoadingVisits = true;
    let fromDate;
    let toDate;
    if (!daterange.toDate) {
      fromDate = DateFns.format(daterange.fromDate, 'DD-MM-YYYY');
      toDate = DateFns.format(daterange.fromDate, 'DD-MM-YYYY');
    } else {
      fromDate = DateFns.format(daterange.fromDate, 'DD-MM-YYYY');
      toDate = DateFns.format(daterange.toDate, 'DD-MM-YYYY');
    }
    localStorage.setItem('visitsFromDate', fromDate);
    localStorage.setItem('visitsToDate', toDate);
    this.loadVisits();
  }

  loadVisits() {
    this.isLoadingVisits = true;
    this.store.dispatch(
      new LoadVisits({
        fromDate: localStorage.getItem('visitsFromDate'),
        toDate: localStorage.getItem('visitsToDate'),
        locationId: this.selectedLocation ? this.selectedLocation.id : null
      })
    );
  }
}
