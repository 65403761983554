import { Driver } from './driver.model';
import { TransportTask } from './transport-task.model';
export interface Visit {
  '@id'?: string;
  '@type'?: string;
  id?: string;
  transportTasks: TransportTask[];
  driver: Driver;
  driverLicensePlate: string;
  driverName: string;
  completedInLatitude?: string;
  completedInLongitude?: string;
  completedAt?: string;
  scheduledAt: Date;
  status: string;
  sequence?: number;
  barCode?: any;
  deleted?: boolean;
}

export enum VisitStatuses {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed'
}
