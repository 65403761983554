/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-tariff.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tariff-form/tariff-form.component.ngfactory";
import * as i3 from "../tariff-form/tariff-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./new-tariff.component";
import * as i6 from "@angular/router";
import * as i7 from "@ngrx/store";
import * as i8 from "../../services/toast.service";
import * as i9 from "@ngrx/effects";
var styles_NewTariffComponent = [i0.styles];
var RenderType_NewTariffComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewTariffComponent, data: {} });
export { RenderType_NewTariffComponent as RenderType_NewTariffComponent };
export function View_NewTariffComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "p-3 new-tariff"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-tariff-form", [], null, [[null, "tariffFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tariffFormSubmitted" === en)) {
        var pd_0 = (_co.createTariff($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TariffFormComponent_0, i2.RenderType_TariffFormComponent)), i1.ɵdid(2, 638976, null, 0, i3.TariffFormComponent, [i4.FormBuilder], { isLoading: [0, "isLoading"] }, { tariffFormSubmitted: "tariffFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NewTariffComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-tariff", [], null, null, null, View_NewTariffComponent_0, RenderType_NewTariffComponent)), i1.ɵdid(1, 180224, null, 0, i5.NewTariffComponent, [i6.Router, i7.Store, i8.ToastService, i9.Actions], null, null)], null, null); }
var NewTariffComponentNgFactory = i1.ɵccf("app-new-tariff", i5.NewTariffComponent, View_NewTariffComponent_Host_0, {}, {}, []);
export { NewTariffComponentNgFactory as NewTariffComponentNgFactory };
