<form *ngIf="transportTaskForm" [formGroup]="transportTaskForm">
  <div class="form-row mb-3">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="form-row mb-2">
            <div class="col">
              <h4>
                <span class="icon">
                  <i class="far" [ngClass]="taskIcon"></i>
                </span>
                <label>{{ taskType | translate }}</label>
              </h4>
              <div class="input-group location-input">
                <input
                  placeholder="{{ 'Enter location here' | translate }}"
                  formControlName="location"
                  data-cy="location"
                  class="form-control"
                  [ngbTypeahead]="searchLocation"
                  [resultFormatter]="locationFormatter"
                  [inputFormatter]="locationFormatter"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="far fa-location-arrow"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <span class="d-flex justify-content-between">
                  <div class="input-group mr-1">
                    <input
                      class="form-control"
                      placeholder="{{ 'From date (dd/mm/yyyy)' | translate }}"
                      name="d1"
                      formControlName="startDate"
                      data-cy="startDate"
                      ngbDatepicker
                      [ngClass]="{
                        'is-invalid': transportTaskForm.get('startDate').invalid
                      }"
                      [markDisabled]="getDisabledDates"
                      placement="bottom-left"
                      #d1="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-dark"
                        type="button"
                        data-cy="startDateCalendarButton"
                        (click)="d1.toggle()"
                      >
                        <i class="far fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div
                      class="invalid-feedback"
                      [ngClass]="{
                        'invalid-feedback--visible': transportTaskForm.get(
                          'startDate'
                        ).invalid
                      }"
                    >
                      {{
                        'Please use dd/mm/yyyy to format your dates.'
                          | translate
                      }}
                    </div>
                  </div>
                  <ngb-timepicker
                    data-cy="startTime"
                    formControlName="startTime"
                    [spinners]="false"
                  ></ngb-timepicker>
                </span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <span class="d-flex justify-content-between">
                  <div class="input-group mr-1">
                    <input
                      class="form-control"
                      placeholder="{{ 'To date (dd/mm/yyyy)' | translate }}"
                      name="d2"
                      formControlName="endDate"
                      [ngClass]="{
                        'is-invalid': transportTaskForm.get('endDate').invalid
                      }"
                      data-cy="endDate"
                      ngbDatepicker
                      [markDisabled]="getDisabledDates"
                      placement="bottom-left"
                      #d2="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-dark"
                        type="button"
                        (click)="d2.toggle()"
                      >
                        <i class="far fa-calendar-alt"></i>
                      </button>
                    </div>
                    <div
                      class="invalid-feedback"
                      [ngClass]="{
                        'invalid-feedback--visible': transportTaskForm.get(
                          'endDate'
                        ).invalid
                      }"
                    >
                      {{
                        'Please use dd/mm/yyyy to format your dates.'
                          | translate
                      }}
                    </div>
                  </div>
                  <ngb-timepicker
                    formControlName="endTime"
                    data-cy="endTime"
                    [spinners]="false"
                  ></ngb-timepicker>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
