<div class="row m-3">
  <div class="col col-4">
    <ngb-alert [type]="alert.type" *ngIf="alert" (close)="this.alert = null">
      <i class="fa" [ngClass]="alert.icon"></i>
      <span>{{alert.message}}</span>
    </ngb-alert>
    <div class="card p-3">
      <ng-select class="mb-3" [items]="tenants" bindLabel="name" bindValue="tenant_id" placeholder="Select Tenant"
        [(ngModel)]="tenantId">
      </ng-select>
      <input
        type="text" class="form-control mb-3" name="amount" id="amount"
        [(ngModel)]="amount" (keydown)="amountChange($event)"
      >
      <span class="d-flex justify-content-between">
        <button class="btn btn-primary btn-block mr-1 text-white" (click)="addCredits()">
          <i class="far fa-spinner fa-spin" *ngIf="loadingAdd"></i>
          <span>+ {{ amount }}</span>
        </button>
        <button class="btn btn-danger btn-block ml-1" (click)="subtractCredits()">
          <i class="far fa-spinner fa-spin" *ngIf="loadingSubtract"></i>
          <span>- {{ amount }}</span>
        </button>
      </span>
    </div>
  </div>
</div>
