import { catchError, concatMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/business-contact.types';
import * as BusinessContactActions from '../actions/business-contact.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class BusinessContactEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  updatecontact$: Observable<Action> = this.actions$.pipe(
    ofType(types.BusinessContact.UPDATE_BUSINESS_CONTACT),
    map((action: BusinessContactActions.UpdateContact) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/contacts/${payload.contactId}`,
      body: payload.body
    }).pipe(
      map(data => new BusinessContactActions.UpdateContactSuccess(data)),
      catchError(error => of(new BusinessContactActions.UpdateContactFail(error)))
    )),
  );

  @Effect()
  deleteBusinessContact$: Observable<Action> = this.actions$.pipe(
    ofType(types.BusinessContact.DELETE_BUSINESS_CONTACT),
    map((action: BusinessContactActions.DeleteBusinessContact) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/contacts/${payload.businessContactId}`
    }).pipe(
      map(() => new BusinessContactActions.DeleteBusinessContactSuccess(payload)),
      catchError(error => of(new BusinessContactActions.DeleteBusinessContactFail(error)))
    )),
  );
}
