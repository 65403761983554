import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  LoadLocationTypes,
  LoadUncheckedLocations,
  UpdateUserLocation,
} from 'app/core/store/actions/location.actions';
import { LoadMarketpostsCount } from 'app/core/store/actions/market-posts.actions';
import { LoadSubscriptions } from 'app/core/store/actions/subscriptions.actions';
import { LoadTenants } from 'app/core/store/actions/tenants.actions';
import { LoadWallet } from 'app/core/store/actions/wallet.actions';
import { LocationType, LocationTypes } from 'app/core/store/models/location-type.model';
import { Location } from 'app/core/store/models/location.model';
import { SubscriptionTypes } from 'app/core/store/models/subscriptions.model';
import { Tenant } from 'app/core/store/models/tenant.model';
import { Wallet } from 'app/core/store/models/wallet.model';
import { State } from 'app/core/store/store.model';
import * as Types from 'app/core/store/types/location.types';
import { AuthService } from 'app/core/user/user.auth';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  SubscriptionTypes = SubscriptionTypes;
  offerCount: number;
  demandCount: number;
  wallet: Wallet;
  uncheckedLocation: Location;
  locationTypes: LocationType[];
  hasActiveMarketplaceSubscription: boolean;
  showMatchingAnnouncement: boolean;
  showReloadBanner: boolean;
  showPsaOnboarding: boolean;
  matchingActive: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private toastr: ToastService,
    private updates$: Actions,
    private authService: AuthService
  ) {
    this.store
      .select(state => state.subscriptions)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptions => {
        if (!subscriptions) {
          this.store.dispatch(new LoadSubscriptions());
          return;
        }
        this.hasActiveMarketplaceSubscription = subscriptions.some(
          subscription => subscription.subscriptionType.code === SubscriptionTypes.MARKETPLACE
        );
        this.matchingActive = subscriptions.some(
          subscription => subscription.subscriptionType.code === SubscriptionTypes.MATCHING
        );
      });
    this.store
      .select(state => state.dashboard)
      .pipe(untilDestroyed(this))
      .subscribe(dashboardState => {
        if (dashboardState && dashboardState.marketPostsCount) {
          this.offerCount = dashboardState.marketPostsCount['offer'];
          this.demandCount = dashboardState.marketPostsCount['demand'];
        }
      });
    this.store.dispatch(new LoadUncheckedLocations());
    this.store
      .select(state => state.dashboard)
      .pipe(untilDestroyed(this))
      .subscribe(dashboardState => {
        if (
          dashboardState &&
          (dashboardState.uncheckedLocation ||
            dashboardState.uncheckedLocation === undefined)
        ) {
          this.uncheckedLocation = dashboardState.uncheckedLocation;
        }
      });
    this.store
      .select(state => state.tms.locations.locationTypes)
      .pipe(untilDestroyed(this))
      .subscribe(locationTypesState => {
        if (locationTypesState) {
          this.locationTypes = locationTypesState;
        } else {
          this.store.dispatch(new LoadLocationTypes());
        }
      });

    this.store.dispatch(new LoadWallet());
    this.store
      .select(state => state.wallet)
      .pipe(untilDestroyed(this))
      .subscribe(walletState => {
        if (walletState && walletState['@id'] !== '') {
          this.wallet = walletState;
        }
      });

    this.store
      .select(state => state.tenants)
      .pipe(untilDestroyed(this))
      .subscribe(tenantState => {
        if (!tenantState.userTenants) {
          this.store.dispatch(new LoadTenants());
          return;
        }
        const tenant: Tenant = tenantState.userTenants.find(
          t => t.id === this.authService.getActiveTenant()
        );
        if (!tenant || !tenant.allowedSubscriptionTypes) {
          return;
        }
        this.showMatchingAnnouncement = tenant.allowedSubscriptionTypes.some(
          subscription => subscription.code === SubscriptionTypes.MATCHING
        );
        this.showPsaOnboarding = tenant.allowedSubscriptionTypes.some(
          subscription => subscription.code === SubscriptionTypes.PSA_TRUCK_VISITS
        );
        this.showReloadBanner = tenant.allowedSubscriptionTypes.some(
          subscription => subscription.code === SubscriptionTypes.RELOAD
        );
      });
    this.store
      .select(state => state.wallet)
      .pipe(untilDestroyed(this))
      .subscribe(walletState => {
        if (walletState && walletState['@id'] !== '') {
          this.wallet = walletState;
        } else {
          this.store.dispatch(new LoadWallet());
        }
      });
    this.updates$
      .pipe(ofType(Types.location.UPDATE_USER_LOCATION_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadUncheckedLocations());
        this.toastr.showSuccess({
          message: extract('Location saved!')
        });
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadMarketpostsCount());
  }

  handleUpdateLocation(completedLocation) {
    const body = Object.assign(
      {},
      this.uncheckedLocation,
      completedLocation
    ) as any;
    body.locationType = this.locationTypes.find(
      lt => lt.code === LocationTypes.USER
    )['@id'];
    this.store.dispatch(
      new UpdateUserLocation({
        locationId: body.id,
        location: body
      })
    );
  }
  handleSkipLocation() {
    const body = Object.assign({}, this.uncheckedLocation) as any;
    body.skippedDuringImprovementSuggestion = true;
    this.store.dispatch(
      new UpdateUserLocation({
        locationId: body.id,
        location: body
      })
    );
  }

  goToMatching() {
    this.router.navigateByUrl('/matching');
  }

  ngOnDestroy() { }
}
