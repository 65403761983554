<ul class="nav nav-pills flex-column">
  <li *ngFor="let route of routes"
    class="nav-item">
    <a
      *ngIf="checkRouteAndRole(route) && routeIsAllowedBySubscription(route)"
      routerLink="{{ route.url }}"
      routerLinkActive="active"
      class="nav-link text-nowrap"
      [ngClass]="{'text-center': !opened}"
      placement="right"
      ngbTooltip="{{ route.title | translate }}"
    >
      <span class="icon">
        <i class="far {{ route.icon }}" *ngIf="route.icon"></i>
      </span>
      <span class="d-sm-none d-md-inline" *ngIf="opened">
        {{ route.title | translate }}
      </span>
    </a>
  </li>
</ul>
