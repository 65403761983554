import { Component, Input } from '@angular/core';
import { Transaction } from 'app/core/store/models/transaction.model';

@Component({
  selector: 'app-cmp-debit-transaction',
  templateUrl: 'debit-transaction.component.html',
  styleUrls: ['./../transactions.component.scss']
})
export class DebitTransactionComponent {
  @Input() debitTransaction: Transaction;

  /**
   * Returns the negative transaction value
   */
  get transactionAmount() {
    return +this.debitTransaction.credits * -1;
  }
}
