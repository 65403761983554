import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-confidence-score',
  templateUrl: './confidence-score.component.html',
  styleUrls: ['./confidence-score.component.scss']
})
export class ConfidenceScoreComponent {
  @Input()
  confidence: number;

  get confidencePercentage() {
    return this.confidence * 100;
  }
}
