var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AuthService } from '../../user/user.auth';
import { ApiService } from '../../api/api.service';
import * as types from '../types/session.types';
import * as SessionActions from '../actions/session.actions';
import { HttpParams } from '@angular/common/http';
import { Go } from '../actions/router.actions';
import { LoadTenants } from '../actions/tenants.actions';
var SessionEffects = /** @class */ (function () {
    function SessionEffects(api, authService, actions$, router) {
        var _this = this;
        this.api = api;
        this.authService = authService;
        this.actions$ = actions$;
        this.router = router;
        this.destroy$ = new Subject();
        this.login$ = this.actions$.pipe(ofType(types.session.LOGIN), map(function (action) { return action.payload; }), switchMap(function (user) {
            var params = new HttpParams()
                .append('username', user.mail)
                .append('password', user.pass);
            return _this.api.getToken(params).pipe(map(function (session) { return new SessionActions.LoginSuccess(session); }), catchError(function (error) { return of(new SessionActions.LoginFail(error)); }));
        }));
        /**
         * Put the session into localStorage and load tenants
         */
        this.loginSuccess$ = this.actions$.pipe(ofType(types.session.LOGIN_SUCCEEDED), map(function (action) {
            var session = action.payload;
            localStorage.setItem('session', JSON.stringify(session));
            return new LoadTenants();
        }));
        this.refresh$ = this.actions$.pipe(ofType(types.session.REFRESH), map(function (action) { return action.payload; }), switchMap(function (session) {
            var body = new URLSearchParams();
            body.append('refresh_token', session.refresh_token);
            var options = {
                body: body
            };
            return _this.api.refreshToken(options).pipe(map(function (newSession) { return new SessionActions.RefreshSuccess(newSession); }), catchError(function (error) { return of(new SessionActions.RefreshFail(error)); }));
        }));
        this.refreshFail$ = this.actions$.pipe(ofType(types.session.REFRESH_FAILED), map(function () {
            return new SessionActions.Logout();
        }));
        this.pickTenant$ = this.actions$.pipe(ofType(types.session.PICK_TENANT), map(function (action) {
            _this.router.navigate(['dashboard']);
            var session = JSON.parse(localStorage.getItem('session'));
            if (session) {
                session.tenant = action.payload;
                localStorage.setItem('session', JSON.stringify(session));
                return new Go({ path: ['/dashboard'] });
            }
            else {
                return new SessionActions.Logout();
            }
        }));
        this.logout$ = this.actions$.pipe(ofType(types.session.LOGOUT), map(function () {
            localStorage.clear();
            _this.authService.clearStore();
            _this.authService.clearStoreOnLogout();
            _this.router.navigateByUrl('/login');
        }));
        this.dropTenant$ = this.actions$.pipe(ofType(types.session.DROP_TENANT), map(function () {
            _this.router.navigate(['user/tenants']);
            var session = JSON.parse(localStorage.getItem('session'));
            delete session.tenant;
            localStorage.setItem('session', JSON.stringify(session));
            _this.authService.clearStore();
            return new Go({ path: ['/user/tenants'] });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SessionEffects.prototype, "login$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SessionEffects.prototype, "loginSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SessionEffects.prototype, "refresh$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SessionEffects.prototype, "refreshFail$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SessionEffects.prototype, "pickTenant$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], SessionEffects.prototype, "logout$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], SessionEffects.prototype, "dropTenant$", void 0);
    return SessionEffects;
}());
export { SessionEffects };
