var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { catchError, concatMap, map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as types from '../types/business-contact.types';
import * as BusinessContactActions from '../actions/business-contact.actions';
import { ApiService } from '../../api/api.service';
var BusinessContactEffects = /** @class */ (function () {
    function BusinessContactEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.updatecontact$ = this.actions$.pipe(ofType(types.BusinessContact.UPDATE_BUSINESS_CONTACT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/contacts/" + payload.contactId,
            body: payload.body
        }).pipe(map(function (data) { return new BusinessContactActions.UpdateContactSuccess(data); }), catchError(function (error) { return of(new BusinessContactActions.UpdateContactFail(error)); })); }));
        this.deleteBusinessContact$ = this.actions$.pipe(ofType(types.BusinessContact.DELETE_BUSINESS_CONTACT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/contacts/" + payload.businessContactId
        }).pipe(map(function () { return new BusinessContactActions.DeleteBusinessContactSuccess(payload); }), catchError(function (error) { return of(new BusinessContactActions.DeleteBusinessContactFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BusinessContactEffects.prototype, "updatecontact$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], BusinessContactEffects.prototype, "deleteBusinessContact$", void 0);
    return BusinessContactEffects;
}());
export { BusinessContactEffects };
