<div class="card">
  <div class="card-body">
    <h2 class="text-center font-weight-light"> {{ title | translate }}</h2>
    <form [formGroup]="transportForm"
      novalidate="novalidate"
      (ngSubmit)="onSubmit()"
      *ngIf="transportForm">
      <div ngbRadioGroup="ngbRadioGroup"
        name="transportTypeRadio"
        formControlName="transportType"
        ngDefaultControl="ngDefaultControl"
        (change)="setTransportTasks()"
        class="d-none d-md-flex btn-group btn-group-toggle justify-content-center form-group"
        data-cy="transportTypes">
        <label ngbButtonLabel="ngbButtonLabel"
          *ngFor="let type of transportTypes"
          class="btn-outline-primary">
          <input ngbButton="ngbButton"
            type="radio"
            [value]="type" />
          <span class="icon">
            <i class="far"
              [ngClass]="getTypeIcon(type)"></i>
          </span>
          <span>
            {{ type.label | titlecase | translate }}
          </span>
        </label>
      </div>
      <div class="d-md-none d-flex justify-content-center">
        <ng-template #content
          let-c="close"
          let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title">{{ 'Pick transport type' | translate }}</h4>
            <button class="close"
              aria-label="Close"
              (click)="d('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="list-group">
              <li *ngFor="let type of transportTypes"
                class="list-group-item"
                [ngClass]="{'active': type === transportForm.get('transportType').value}"
                (click)="setTransportType(type)">
                <span class="icon">
                  <i class="fa"
                    [ngClass]="getTypeIcon(type)"></i>
                </span>
                <span>
                  {{ type.label | titlecase | translate }}
                </span>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button"
              class="btn btn-outline-dark"
              (click)="c('Close click')">{{ 'Confirm' | translate }}</button>
          </div>
        </ng-template>
        <button class="btn btn-primary text-white mb-3"
          type="button"
          *ngIf="transportForm.get('transportType').value && transportForm.get('transportType').value.label"
          (click)="open($event, content)">
          {{ 'Pick transport type' | translate }} ({{transportForm.get('transportType').value.label | titlecase | translate }})
        </button>
      </div>

      <div formArrayName="transportTasks"
        class="form-row">
        <div class="col"
          *ngFor="let task of transportTasks.controls; let i=index"
          [formGroupName]="i">
          <div class="card mb-3">
            <div class="card-body">
              <h4 class="d-flex justify-content-center">
                <i class="far mr-2"
                  [ngClass]="getTaskIcon(task.value)"></i> {{ task.value.taskType.label | titlecase | translate }}
              </h4>
              <!-- Location row -->
              <div class="form-row mb-3">
                <div class="col">
                  <div class="input-group location-input">
                    <input placeholder="{{ 'Enter location here' | translate }}"
                      id="location_{{i}}"
                      [ngClass]="{'is-invalid': !hasAtLeastOneLocation()}"
                      formControlName="location"
                      class="form-control"
                      [ngbTypeahead]="searchLocation"
                      [resultFormatter]="locationFormatter"
                      [inputFormatter]="locationFormatter"
                      data-cy="taskLocation" />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="far fa-location-arrow"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Start Date & Time -->
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <span class="d-flex justify-content-between">
                      <span class="w-100 mr-1">
                        <div class="input-group">
                          <input class="form-control"
                            (click)="d2.toggle()"
                            [ngClass]="{'is-invalid': (transportForm.get('transportTasks').controls[i].controls['startDate'].touched && transportForm.get('transportTasks').controls[i].controls['startDate'].invalid)}"

                            placeholder="{{ 'From date (dd/mm/yyyy)' | translate }}"
                            name="d2"
                            formControlName="startDate"
                            ngbDatepicker
                            placement="bottom-left"
                            [markDisabled]="getDisabledDates"
                            #d2="ngbDatepicker"
                            data-cy="startDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-dark"
                              type="button"
                              (click)="d2.toggle()"
                              data-cy="startDateCalendarButton">
                              <i class="far fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div class="invalid-feedback"
                          [ngClass]="{'invalid-feedback--visible': (transportForm.get('transportTasks').controls[i].controls['startDate'].touched && transportForm.get('transportTasks').controls[i].controls['startDate'].invalid)}">
                          {{ 'Please use dd/mm/yyyy to format your dates.' | translate }}
                        </div>
                      </span>
                      <ngb-timepicker formControlName="startTime"
                        [spinners]="false"
                        data-cy="startTime"></ngb-timepicker>
                    </span>
                  </div>
                </div>
              </div>
              <!-- End Date & Time -->
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <span class="d-flex justify-content-between">
                      <span class="w-100 mr-1">
                        <div class="input-group mr-1">
                          <input class="form-control"
                            (click)="d3.toggle()"
                            [ngClass]="{'is-invalid': (transportForm.get('transportTasks').controls[i].controls['endDate'].touched && transportForm.get('transportTasks').controls[i].controls['endDate'].invalid)}"

                            placeholder="{{ 'To date (dd/mm/yyyy)' | translate }}"
                            [markDisabled]="getDisabledDates"
                            name="d3"
                            formControlName="endDate"
                            ngbDatepicker
                            placement="bottom-left"
                            #d3="ngbDatepicker"
                            data-cy="endDate">
                          <div class="input-group-append">
                            <button class="btn btn-outline-dark"
                              type="button"
                              (click)="d3.toggle()"
                              data-cy="endDateCalendarButton">
                              <i class="far fa-calendar-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div class="invalid-feedback"
                          [ngClass]="{'invalid-feedback--visible': (transportForm.get('transportTasks').controls[i].controls['endDate'].touched && transportForm.get('transportTasks').controls[i].controls['endDate'].invalid)}">
                          {{ 'Please use dd/mm/yyyy to format your dates.' | translate }}
                        </div>
                      </span>
                      <ngb-timepicker formControlName="endTime"
                        [spinners]="false"
                        data-cy="endTime"></ngb-timepicker>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-section-darker p-3">
        <div formGroupName="container">
          <div class="d-flex justify-content-between">
            <div class="col col-md-3 col-sm-6 pl-0">
              <div class="form-group">
                <ng-select id="containerType"
                  (change)="selectContainerType($event)"
                  [items]="containerTypes"
                  bindLabel="label"
                  bindValue="id"
                  placeholder="{{ 'Select Container Type' | translate }}"
                  formControlName="containerType"
                  data-cy="containerTypes">
                </ng-select>
              </div>
            </div>
            <div class="col col-md-3 col-sm-6">
              <div class="form-group">
                <ng-select id="liner"
                  [items]="liners"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{ 'Select Liner' | translate }}"
                  formControlName="liner"
                  data-cy="liners">
                </ng-select>
              </div>
            </div>
            <div class="col col-md-3 col-sm-6">
              <div class="form-group">
                <input placeholder="{{ 'Enter weight (T)' | translate }}"
                  formControlName="weight"
                  id="weight"
                  type="text"
                  class="form-control"
                  data-cy="weight"
                  [ngClass]="{'is-invalid': transportForm.get('container.weight').invalid && transportForm.get('container.weight').touched}"
                />
                <div class="invalid-feedback"
                  *ngIf="weight.errors && weight.errors.notNumber">
                  {{ 'Weight should have the following format: 1.5' | translate }}
                </div>
              </div>
            </div>
            <div class="col col-md-3 col-sm-6 p-3 checkbox-column">
              <div class="row">
                <div class="col">
                  <label class="custom-control custom-checkbox">
                    <input type="checkbox"
                      class="custom-control-input"
                      formControlName="adr">
                    <span class="custom-control-label"
                      data-cy="adr">{{ 'ADR' | translate }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <input placeholder="{{ 'Enter your reference here' | translate }}"
                formControlName="reference"
                id="reference"
                class="form-control"
                data-cy="reference"
                [ngClass]="{'is-invalid': transportForm.get('reference').invalid && transportForm.get('reference').touched}"
              />
            </div>
          </div>
          <div class="col checkbox-column p-3">
            <label class="custom-control custom-checkbox">
              <input type="checkbox"
                id="genset-input"
                class="custom-control-input"
                formControlName="genset"
                data-cy="genset"
                [disabled]="true">
              <span class="custom-control-label">{{ 'Genset' | translate }}</span>
            </label>
          </div>
        </div>
        <div class="form-row pb-3">
          <div class="col">
            <label>{{ 'Comment' | translate }}</label>
            <span class="text-muted"
              id="message-length">({{ comment.length }} / 256)</span>
            <textarea class="form-control"
              id="comment"
              maxlength="256"
              formControlName="comment"
              cols="20"
              rows="2"
              data-cy="comment"></textarea>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <div class="d-flex align-items-center">
            <button [disabled]="!hasAtLeastOneLocation() || transportForm.get('transportTasks').invalid || transportForm.get('quantity').invalid || (transportForm.get('container.weight') && transportForm.get('container.weight').invalid)"

              type="button"
              id="addTransportButton"
              (click)="onSubmit()"
              class="btn btn-primary text-white"
              data-cy="submitOfferButton">
              <span *ngIf="!transportOffer"
                class="text-light">
                <i class="far fa-plus"></i>&nbsp;
                <span>
                  {{ 'Add Transport' | translate }}
                </span>
              </span>
              <span *ngIf="transportOffer && !savedChanges">
                <i class="far fa-save"></i>&nbsp; {{ 'Save Changes' | translate }}
              </span>
            </button>
          </div>
          <div class="form-inline mr-3">
            <span class="mr-3">{{ 'Quantity' | translate }}</span>
            <input formControlName="quantity"
              id="quantity"
              class="form-control"
              min="1"
              [ngClass]="{'is-invalid': transportForm.get('quantity').invalid}"
              type="number" />
            <div class="invalid-feedback">
              {{ 'Quantity cannot be less than 0' | translate }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
