<ngx-datatable
  [loadingIndicator]="isLoading"
  class="bootstrap ml-0 mr-0"
  [rows]="subscriptionTypes"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [selectionType]="undefined"
  [count]="totalItems"
  [limit]="30"
>
  <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
  <ngx-datatable-column name="Code" prop="code"></ngx-datatable-column>
  <ngx-datatable-column
    name="Payment Type"
    prop="paymentType"
  ></ngx-datatable-column>
  <ngx-datatable-column name="Credits" prop="credits"></ngx-datatable-column>
  <ngx-datatable-column name="Actions" prop="subscriptionTypeActions">
    <ng-template ngx-datatable-cell-template let-value="value">
      <div *ngFor="let action of value" class="d-flex justify-content-between">
        <span *ngIf="action">{{ action.name }}</span>
        <span *ngIf="action">{{ action.credits }}</span>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'Display on website' | translate" prop="displayOnWebsite">
    <ng-template ngx-datatable-cell-template let-value="value">
      <span>{{ (value ? 'Yes' : 'No') | translate }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'Website icon' | translate" prop="websiteIcon">
    <ng-template ngx-datatable-cell-template let-value="value">
      <i class="far {{value}}"></i>&nbsp;<span>{{value}}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'Website description' | translate" prop="websiteDescription"></ngx-datatable-column>
  <ngx-datatable-column name="" prop="">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button class="btn btn-info" (click)="edit(row.id)">
        <i class="far fa-pencil"></i> <span>{{ 'Edit' | translate }}</span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
