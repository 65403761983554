export const transports = {
  LOAD_TRANSPORTS: '[Transport] LOAD',
  LOAD_SUCCEEDED: '[Transport] Load Success',
  LOAD_FAILED: '[Transport] Load Fail',
  LOAD_TRANSPORTS_BY_DATERANGE: '[Transport] Load By Daterange',
  LOAD_TRANSPORTS_BY_DATERANGE_SUCCEEDED:
    '[Transport] Load By Daterange Success',
  LOAD_TRANSPORTS_BY_DATERANGE_FAILED: '[Transport] Load By Daterange Fail',
  CREATE_TRANSPORT: '[Transport] Create',
  CREATE_SUCCEEDED: '[Transport] Create Success',
  CREATE_FAILED: '[Transport] Create Fail',
  DELETE_TRANSPORT: '[Transport] Delete',
  DELETE_SUCCEEDED: '[Transport] Delete Success',
  DELETE_FAILED: '[Transport] Delete Fail',
  UPDATE_TRANSPORT: '[Transport] Update',
  UPDATE_SUCCEEDED: '[Transport] Update Success',
  UPDATE_FAILED: '[Transport] Update Fail',
  CREATE_TRANSPORT_TASK: '[TransportTask] Create',
  CREATE_TRANSPORT_TASK_SUCCEEDED: '[TransportTask] Create Success',
  CREATE_TRANSPORT_TASK_FAILED: '[TransportTask] Create Fail',
  TRANSITION_TRANSPORT_TASK_FORWARD: '[TransportTask] Transition Forward',
  TRANSITION_TRANSPORT_TASK_FORWARD_SUCCEEDED:
    '[TransportTask] Transition Forward Success',
  TRANSITION_TRANSPORT_TASK_FORWARD_FAILED:
    '[TransportTask] Transition Forward Fail',
  TRANSITION_TRANSPORT_TASK_BACK: '[TransportTask] Transition Back',
  TRANSITION_TRANSPORT_TASK_BACK_SUCCEEDED:
    '[TransportTask] Transition Back Success',
  TRANSITION_TRANSPORT_TASK_BACK_FAILED: '[TransportTask] Transition Back Fail',
  CHECK_TRANSPORT_TASK_REFERENCE: '[TransportTask] Check Reference',
  CHECK_TRANSPORT_TASK_REFERENCE_SUCCEEDED:
    '[TransportTask] Check Reference Success',
  CHECK_TRANSPORT_TASK_REFERENCE_FAILED:
    '[TransportTask] Check Reference Failed',
  LOAD_INVOICEABLE_TRANSPORTS: '[Invoiceable transports] Load',
  LOAD_INVOICEABLE_TRANSPORTS_SUCCEEDED:
    '[Invoiceable transports] Load Success',
  LOAD_INVOICEABLE_TRANSPORTS_FAILED: '[Invoiceable transports] Load Fail',
  LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE:
    '[Invoiceable transports] Load Next Page',
  LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE_SUCCEEDED:
    '[Invoiceable transports] Load Next Page Success',
  LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE_FAILED:
    '[Invoiceable transports] Load Next Page Fail',
  LOAD_PURCHASE_ORDERABLE_TRANSPORTS: '[Purchase orderable transports] Load',
  LOAD_PURCHASE_ORDERABLE_TRANSPORTS_SUCCEEDED:
    '[Purchase orderable transports] Load Success',
  LOAD_PURCHASE_ORDERABLE_TRANSPORTS_FAILED:
    '[Purchase orderable transports] Load Fail',
  LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE:
    '[Purchase orderable transports] Load Next Page',
  LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE_SUCCEEDED:
    '[Purchase orderable transports] Load Next Page Success',
  LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE_FAILED:
    '[Purchase orderable transports] Load Next Page Fail',
  COPY_TRANSPORT: '[Transport] Copy',
  CLEAR: '[Transports] Clear'
};
