import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'app/services/toast.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { IconService } from 'app/services/icon.service';
import { LoadDrivers } from 'app/core/store/actions/driver.actions';
import { Transport } from 'app/core/store/models/transport.model';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { Visit } from 'app/core/store/models/visits.model';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { ApiService } from 'app/core/api/api.service';
import { TransportTask } from 'app/core/store/models/transport-task.model';
import * as moment from 'moment';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbCalendar,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgbDateNativeAdapter,
  NgbDateCustomParserFormatter
} from 'app/shared/datepicker-config';
import { NewDriverModalComponent } from '../../drivers/new-driver-modal/new-driver-modal.component';
import { EditDriverModalComponent } from '../../drivers/edit-driver-modal/edit-driver-modal.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class VisitFormComponent implements OnChanges, OnDestroy {
  @Input()
  visit: Visit;
  @Input()
  title: string;
  @Input()
  selectedTasks: any[] = [];
  @Input()
  task: TransportTask;
  @Input()
  primaryTaskDate;
  @Input()
  isLoading = false;
  @Input()
  isLoadingLocations = false;
  @Input()
  locations: any[];

  @Output()
  visitFormSubmitted = new EventEmitter<any>();
  @Output()
  locationSelected = new EventEmitter<any>();

  isLoadingTasks: boolean;
  isLoadingDrivers: boolean;
  submitAttempt: boolean;

  searchTextChanged = new Subject();
  drivers: any;
  driver: any;
  filteredTasks: any;
  tasks = [];
  location: any;
  scheduledAt: any;
  transports: Transport[];
  dragulaTransports = 'dragulaTransports';
  subs = new Subscription();
  query: null;

  tasksFromDate: NgbDateStruct;
  tasksToDate: NgbDateStruct;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private modalService: NgbModal,
    private iconService: IconService,
    private route: ActivatedRoute,
    private toastr: ToastService,
    public calendar: NgbCalendar
  ) {
    this.route.paramMap.pipe(take(1)).subscribe(params => {
      if (params.get('task')) {
        const taskId = params.get('task');
        this.store
          .select(state => state.transports)
          .pipe(untilDestroyed(this))
          .subscribe(transportsState => {
            if (!transportsState) {
              console.log('No transports found');
            } else {
              const transport = transportsState['data'].find(t =>
                t.transportTasks.some(tTask => tTask.id === taskId)
              );
              this.task = transport.transportTasks.find(t => t.id === taskId);
              if (!this.task.startDate) {
                this.task.startDate = <string><unknown>transport.primaryTaskDate;
              }
              this.task.transport = transport;
              this.task['badgeColor'] = this.getTaskBadgeColor(this.task);
              this.scheduledAt = new Date(
                moment(this.task.startDate).year(),
                moment(this.task.startDate).month(),
                moment(this.task.startDate).date()
              );
              this.location = {
                label: this.task.location.name,
                value: this.task.location.id
              };
              this.handleLocationSelected({ value: this.task.location.id });
            }
          });
      }
    });
    this.isLoadingDrivers = true;
    this.store.dispatch(
      new LoadDrivers({
        includeSuperAdmins: 0,
        roleName: 'driver'
      })
    );
    this.store
      .select(state => state.drivers)
      .pipe(untilDestroyed(this))
      .subscribe(usercontexts => {
        if (usercontexts['drivers']) {
          this.drivers = usercontexts['drivers'].map(driver => {
            return {
              '@id': driver['@id'],
              name: `${driver.user.firstName} ${driver.user.lastName} - ${
                driver.licensePlate
                }`,
              id: driver.id,
              licensePlate: driver.licensePlate
            };
          });
          this.isLoadingDrivers = false;
        }
      });
    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((search: string) => this.searchTransportTasks(search))
      )
      .subscribe(() => { });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.task && changes.task.currentValue) {
      const task = changes.task.currentValue;
      // Editing a task which has a visit
      if (task.visit) {
        this.visit = Object.assign({}, task.visit);
        const visit = Object.assign({}, task.visit);
        this.scheduledAt = new Date(
          moment(visit.scheduledAt).year(),
          moment(visit.scheduledAt).month(),
          moment(visit.scheduledAt).date()
        );
        this.tasksFromDate = {
          year: moment(visit.scheduledAt).year(),
          month: moment(visit.scheduledAt)
            .add(1, 'month')
            .month(),
          day: moment(visit.scheduledAt).date()
        };
        this.tasksToDate = {
          year: moment(visit.scheduledAt).year(),
          month: moment(visit.scheduledAt)
            .add(1, 'month')
            .month(),
          day: moment(visit.scheduledAt).date()
        };
        visit.driver = visit.driver
          ? {
            '@id': visit.driver['@id'],
            name:
              visit.driver.user.firstName +
              ' ' +
              visit.driver.user.lastName +
              ' - ' +
              visit.driver.licensePlate,
            id: visit.driver.id
          }
          : null;
        this.driver = visit.driver ? visit.driver.id : null;
        if (task.location) {
          this.location = task.location.name;
          this.getTasksByLocation({
            value: task.location.id
          });
          this.locations = [];
          this.locations.push(task.location);
          if (this.filteredTasks && this.filteredTasks.length > 0) {
            this.selectedTasks = [];
            visit.transportTasks.forEach(t => {
              this.selectedTasks = [...this.selectedTasks, t.id];
            });
          }
        }
        // Creating a new visit from a task
      } else {
        if (task.startDate) {
          this.tasksFromDate = {
            year: moment(task.startDate).year(),
            month: moment(task.startDate)
              .add(1, 'month')
              .month(),
            day: moment(task.startDate).date()
          };
        } else {
          this.tasksFromDate = {
            year: moment(this.primaryTaskDate).year(),
            month: moment(this.primaryTaskDate)
              .add(1, 'month')
              .month(),
            day: moment(this.primaryTaskDate).date()
          };
        }
        if (task.toDate) {
          this.tasksToDate = {
            year: moment(task.toDate).year(),
            month: moment(task.toDate)
              .add(1, 'month')
              .month(),
            day: moment(task.toDate).date()
          };
        } else {
          this.tasksToDate = {
            year: moment(this.primaryTaskDate).year(),
            month: moment(this.primaryTaskDate)
              .add(1, 'month')
              .add(1, 'day')
              .month(),
            day: moment(this.primaryTaskDate).date()
          };
        }

        // update the tasks based on the given task location
        this.isLoadingTasks = true;
        this.api
          .get({
            // /transport_tasks/for_location/{locationId}/{from}/{to}
            path: `/transport_tasks/for_location/${task.location.id}`
          })
          .subscribe((res: TransportTask[]) => {
            this.filteredTasks = this.mapTransportTasks(res);
            this.locations = [];
            this.locations.push(task.location);
            this.location = task.location.name;
            this.selectedTasks = [];
            this.selectedTasks.push(task.id);
            this.isLoadingTasks = false;
          });
        if (task.startDate) {
          this.scheduledAt = new Date(
            moment(task.startDate).year(),
            moment(task.startDate).month(),
            moment(task.startDate).date()
          );
        }
      }
    }
    if (changes && changes.visit && changes.visit.currentValue) {
      this.visit = Object.assign({}, changes.visit.currentValue);
      const visit = Object.assign({}, changes.visit.currentValue);
      this.scheduledAt = new Date(
        moment(visit.scheduledAt).year(),
        moment(visit.scheduledAt).month(),
        moment(visit.scheduledAt).date()
      );

      // Set taskFromDate and taskToDate
      const taskDates = [];
      taskDates.push(moment(visit.scheduledAt));
      visit.transportTasks.forEach((transportTask: TransportTask) => {
        if (transportTask.startDate) {
          taskDates.push(moment(transportTask.startDate));
        }
        if (transportTask.endDate) {
          taskDates.push(moment(transportTask.endDate));
        }
        if (transportTask.transport.primaryTaskDate) {
          taskDates.push(moment(transportTask.transport.primaryTaskDate));
        }
      });

      const tasksMinDate = moment.min(taskDates);
      const tasksMaxDate = moment.max(taskDates);

      this.tasksFromDate = {
        year: moment(tasksMinDate).year(),
        month: moment(tasksMinDate)
          .add(1, 'month')
          .month(),
        day: moment(tasksMinDate).date()
      };
      this.tasksToDate = {
        year: moment(tasksMaxDate).year(),
        month: moment(tasksMaxDate)
          .add(1, 'month')
          .month(),
        day: moment(tasksMaxDate).date()
      };
      visit.driver = visit.driver
        ? {
          '@id': visit.driver['@id'],
          name:
            visit.driver.user.firstName +
            ' ' +
            visit.driver.user.lastName +
            ' - ' +
            visit.driver.licensePlate,
          id: visit.driver.id
        }
        : null;
      this.driver = visit.driver ? visit.driver.id : null;
      const task = visit.transportTasks[0];
      if (task.location) {
        this.location = task.location.name;
        this.getTasksByLocation({
          value: task.location.id
        });
        this.locations = [];
        this.locations.push(task.location);
        if (this.filteredTasks && this.filteredTasks.length > 0) {
          this.selectedTasks = [];
          visit.transportTasks.forEach(t => {
            this.selectedTasks = [...this.selectedTasks, t.id];
          });
        }
      }
    }
  }

  filterTasksByLocation() {
    this.filteredTasks = this.tasks.filter(
      task => task.location.name === this.location
    );
  }

  getTaskLocation() {
    if (
      this.selectedTasks &&
      this.selectedTasks.length !== 0 &&
      (this.locations && this.locations.length !== 1)
    ) {
      this.location = this.filteredTasks.find(
        task => task.id === this.selectedTasks[0]
      ).location.name;
      this.filterTasksByLocation();
    }
  }

  get fromDate() {
    if (!this.tasksFromDate) {
      return;
    }
    return moment(
      new Date(
        this.tasksFromDate.year,
        this.tasksFromDate.month - 1,
        this.tasksFromDate.day
      )
    );
  }

  get toDate() {
    if (!this.tasksToDate) {
      return;
    }
    return moment(
      new Date(
        this.tasksToDate.year,
        this.tasksToDate.month - 1,
        this.tasksToDate.day
      )
    );
  }

  handleDateSelection(dateRange: { fromDate: NgbDate; toDate: NgbDate }) {
    if (dateRange.fromDate && dateRange.toDate) {
      this.tasksFromDate = dateRange.fromDate;
      this.tasksToDate = dateRange.toDate;
      if (!this.location) {
        return;
      }
      this.getTasksByLocation({ value: this.location.value });
    }
  }

  getTasksByLocation(event) {
    this.locationSelected.emit(this.location);
    // Clear selected tasks so we don't have selected tasks from another location
    this.selectedTasks = [];
    if (event && event.value) {
      const locationId = event.value;
      this.isLoadingTasks = true;
      this.api
        .get({
          path: `/transport_tasks/for_location/${locationId}`
        })
        .subscribe(
          (response: TransportTask[]) => {
            this.handleTransportTasksResponse(response);
            this.isLoadingTasks = false;
            if (this.visit) {
              this.visit.transportTasks.forEach(task => {
                task['badgeColor'] = this.getTaskBadgeColor(task);
                // The current task is not serialized (to prevent infinite serialization) so we can find
                // the task that is an @id and replace it with the current task
                const index = task['transport']['transportTasks'].findIndex(
                  t => typeof t === 'string'
                );
                task['transport']['transportTasks'][index] = task;
              });
              this.selectedTasks = [...this.visit.transportTasks];
            }
          },
          () => {
            this.isLoadingTasks = false;
            this.toastr.showDanger({
              message: extract('Couldn\'t get tasks for this location')
            });
          }
        );
    }
  }

  searchTransportTasks(query: string) {
    this.isLoadingTasks = true;
    this.api
      .get({
        path: `/transport_tasks/for_location/${
          this.location.value
          }?query=${query}`
      })
      .subscribe(
        (response: TransportTask[]) => {
          this.handleTransportTasksResponse(response);
          this.isLoadingTasks = false;
        },
        () => {
          this.isLoadingTasks = false;
        }
      );
  }
  handleTransportTasksResponse(response: TransportTask[]) {
    const tasksWithoutVisit = this.getTasksWithoutVisit(response);
    this.filteredTasks = this.mapTransportTasks(tasksWithoutVisit);
    this.tasks = [...this.filteredTasks];
  }
  getTasksWithoutVisit(tasks: TransportTask[]): TransportTask[] {
    // Filter out tasks that are linked to a visit, but only if we're not editing.
    return tasks.filter(task => {
      if (!this.visit) {
        if (!task['visit']) {
          return task;
        }
      } else {
        return task;
      }
    });
  }
  onSubmit() {
    this.submitAttempt = true;

    if (!this.location) {
      return;
    }
    if (!this.scheduledAt) {
      return;
    }
    const body = {
      scheduledAt: moment(this.scheduledAt).toISOString(true)
    };
    if (this.driver) {
      body['driver'] = this.drivers.find(d => d.id === this.driver)['@id'];
    }
    body['transportTasks'] = this.selectedTasks.map(t => {
      return { id: t['id'] };
    });
    this.visitFormSubmitted.emit(body);
  }

  openEditDriverModal() {
    const modalRef = this.modalService.open(EditDriverModalComponent);
    modalRef.componentInstance.driverId = this.driver;
  }

  openNewDriverModal() {
    const modalRef = this.modalService.open(NewDriverModalComponent);
    modalRef.result.then(result => {
      this.driver = result['payload'].id;
    });
  }

  mapTransportTasks(tasks: any[]) {
    return tasks.map(task => {
      const index = task['transport']['transportTasks'].findIndex(
        t => typeof t === 'string'
      );
      task['transport']['transportTasks'][index] = task;
      return {
        '@id': task['@id'],
        id: task.id,
        location: {
          name: task.location.name
        },
        label: `${task.taskType.label.toUpperCase()} ${task.reference}`,
        transport: task['transport'],
        reference: task.reference,
        disabled: task.referenceCheckedAt && !task.referenceVerified,
        badgeColor: this.getTaskBadgeColor(task)
      };
    });
  }

  getTaskIcon(task) {
    return this.iconService.getTransportTaskIcon(task);
  }

  /**
   * Returns true if all selected tasks have a reference
   */
  allTasksHaveReference(): boolean {
    if (!this.selectedTasks || !this.filteredTasks || this.isLoadingTasks) {
      return;
    }
    return this.selectedTasks.every(task => {
      const filteredTask = this.filteredTasks.find(t => t.id === task);
      if (!filteredTask) {
        return;
      }
      return filteredTask.reference;
    });
  }

  search(searchQuery) {
    this.searchTextChanged.next(this.query);
  }
  handleTransportSelected(task) {
    if (this.selectedTasks.indexOf(task) !== -1) {
      this.tasks.push(task);
      this.selectedTasks = this.selectedTasks.filter(t => t.id !== task.id);
      return;
    }
    this.selectedTasks.push(task);
    this.tasks = this.tasks.filter(t => t.id !== task.id);
  }

  handleLocationSelected(location) {
    if (!location || !location.value) {
      console.log('Location has no value');
      return;
    }
    this.isLoadingTasks = true;
    this.selectedTasks = [];
    this.api
      .get({
        path: `/transport_tasks/for_location/${location.value}`
      })
      .subscribe(
        (response: any[]) => {
          response.forEach(task => {
            // Set badge status
            task['badgeColor'] = this.getTaskBadgeColor(task);
            // The current task is not serialized (to prevent infinite serialization) so we can find
            // the task that is an @id and replace it with the current task
            const index = task['transport']['transportTasks'].findIndex(
              t => typeof t === 'string'
            );
            task['transport']['transportTasks'][index] = task;
          });
          this.tasks = [...response];
          if (this.task) {
            this.tasks = this.tasks.filter(t => t.id !== this.task.id);
            this.selectedTasks = [this.task, ...this.selectedTasks];
          }
        },
        error => {
          this.isLoadingTasks = false;
          this.toastr.showDanger({
            message: extract('Couldn\'t get tasks for this location')
          });
        },
        () => {
          this.isLoadingTasks = false;
        }
      );
  }

  get selectedDriver() {
    if (!this.driver) {
      return;
    }
    return this.drivers.find(driver => driver.id === this.driver);
  }

  getTaskBadgeColor(task): string {
    if (task.referenceCheckedAt) {
      return task.referenceVerified ? 'badge-success' : 'badge-danger';
    }
    return task.reference ? 'badge-success' : 'badge-danger';
  }

  ngOnDestroy() { }
}
