var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LoadProducts, UpdateProduct } from 'app/core/store/actions/product.actions';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import * as ProductTypes from 'app/core/store/types/product.types';
import { ApiService } from 'app/core/api/api.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
var EditTariffComponent = /** @class */ (function () {
    function EditTariffComponent(route, router, updates$, store, api, toastr) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.updates$ = updates$;
        this.store = store;
        this.api = api;
        this.toastr = toastr;
        this.route.paramMap.subscribe(function (data) {
            if (data) {
                _this.id = data.get('id');
                _this.api.get({ path: "/crm/products/" + _this.id }).toPromise().then(function (res) {
                    _this.tariff = __assign({}, res, { parts: res.parts.sort(function (a, b) { return (a.id > b.id) - (b.id > a.id); }) });
                });
                // this.store
                //   .select(state => state.crm.products)
                //   .pipe(untilDestroyed(this))
                //   .subscribe(productState => {
                //     if (!productState || !productState.customerProducts) {
                //       this.store.dispatch(new LoadProducts());
                //       return;
                //     }
                //     this.tariff = productState.customerProducts.find(
                //       c => c.id === this.id
                //     );
                //   });
            }
        });
        this.updates$
            .pipe(ofType(ProductTypes.product.UPDATE_PRODUCT_FAILED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.toastr.showDanger({
                message: extract('Failed to update tariff!')
            });
            _this.isLoading = false;
        });
        this.updates$
            .pipe(ofType(ProductTypes.product.UPDATE_PRODUCT_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.toastr.showSuccess({
                message: extract('Tariff updated successfully!')
            });
            _this.store.dispatch(new LoadProducts());
            _this.router.navigateByUrl('/crm/tariffs');
            _this.isLoading = false;
        });
    }
    EditTariffComponent.prototype.editTariff = function (tariffFormValue) {
        var body = Object.assign({}, tariffFormValue, {
            template: true
        });
        this.store.dispatch(new UpdateProduct({
            productId: this.id,
            body: body
        }));
        this.isLoading = true;
    };
    EditTariffComponent.prototype.ngOnDestroy = function () { };
    return EditTariffComponent;
}());
export { EditTariffComponent };
