<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [rows]="preannouncements"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="false"
  [limit]="30"
  [loadingIndicator]="isLoading"
>
  <div>
    <ngx-datatable-column name="{{ 'Name' | translate }}" prop="label">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'VAT' | translate }}" prop="vatNumber">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Details' | translate }}" prop="">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button (click)="goToEdit(row)" class="btn">
          <i class="far fa-pencil"></i>
        </button>
        <button (click)="delete(row)" class="btn">
          <i class="far fa-trash-alt"></i>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
