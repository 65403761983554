import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { AuthService } from '../../user/user.auth';
import { Session } from '../models/session.model';
import { ApiService, ApiCall } from '../../api/api.service';

import * as types from '../types/session.types';
import * as SessionActions from '../actions/session.actions';
import { HttpParams } from '@angular/common/http';
import { Go } from '../actions/router.actions';
import { LoadTenants } from '../actions/tenants.actions';

@Injectable()
export class SessionEffects {
  destroy$ = new Subject<boolean>();

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private actions$: Actions,
    private router: Router
  ) { }

  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(types.session.LOGIN),
    map((action: SessionActions.Login) => action.payload),
    switchMap(user => {
      const params = new HttpParams()
        .append('username', user.mail)
        .append('password', user.pass);
      return this.api.getToken(params).pipe(
        map(session => new SessionActions.LoginSuccess(session)),
        catchError(error => of(new SessionActions.LoginFail(error)))
      );
    })
  );

  /**
   * Put the session into localStorage and load tenants
   */
  @Effect()
  loginSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(types.session.LOGIN_SUCCEEDED),
    map((action: SessionActions.LoginSuccess) => {
      const session: Session = action.payload;
      localStorage.setItem('session', JSON.stringify(session));
      return new LoadTenants();
    })
  );

  @Effect()
  refresh$: Observable<Action> = this.actions$.pipe(
    ofType(types.session.REFRESH),
    map((action: SessionActions.Refresh) => action.payload),
    switchMap((session: Session) => {
      const body = new URLSearchParams();
      body.append('refresh_token', session.refresh_token);
      const options: ApiCall = {
        body: body
      };
      return this.api.refreshToken(options).pipe(
        map(newSession => new SessionActions.RefreshSuccess(newSession)),
        catchError(error => of(new SessionActions.RefreshFail(error)))
      );
    })
  );

  @Effect()
  refreshFail$: Observable<Action> = this.actions$.pipe(
    ofType(types.session.REFRESH_FAILED),
    map(() => {
      return new SessionActions.Logout();
    })
  );

  @Effect()
  pickTenant$: Observable<Action> = this.actions$.pipe(
    ofType(types.session.PICK_TENANT),
    map((action: SessionActions.PickTenant) => {
      this.router.navigate(['dashboard']);
      const session = JSON.parse(localStorage.getItem('session'));
      if (session) {
        session.tenant = action.payload;
        localStorage.setItem('session', JSON.stringify(session));
        return new Go({ path: ['/dashboard'] });
      } else {
        return new SessionActions.Logout();
      }
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(types.session.LOGOUT),
    map(() => {
      localStorage.clear();
      this.authService.clearStore();
      this.authService.clearStoreOnLogout();
      this.router.navigateByUrl('/login');
    })
  );

  @Effect()
  dropTenant$: Observable<Action> = this.actions$.pipe(
    ofType(types.session.DROP_TENANT),
    map(() => {
      this.router.navigate(['user/tenants']);
      const session = JSON.parse(localStorage.getItem('session'));
      delete session.tenant;
      localStorage.setItem('session', JSON.stringify(session));
      this.authService.clearStore();
      return new Go({ path: ['/user/tenants'] });
    })
  );
}
