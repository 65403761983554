import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import {
  Subscription,
  SubscriptionType,
  SubscriptionTypes
} from 'app/core/store/models/subscriptions.model';
import { Wallet } from 'app/core/store/models/wallet.model';
import * as moment from 'moment';
import * as Types from 'app/core/store/types/subscription.types';
import { Actions, ofType } from '@ngrx/effects';
import { LoadWallet } from 'app/core/store/actions/wallet.actions';
import {
  LoadSubscriptions,
  LoadSubscriptionTypes
} from 'app/core/store/actions/subscriptions.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-no-subscription',
  templateUrl: 'no-subscription-notification.component.html'
})
export class NoSubscriptionNotificationComponent implements OnDestroy {
  started: boolean;
  subscriptions: Subscription[];
  subscriptionTypes: SubscriptionType[];
  wallet: Wallet;
  subscription: Subscription;
  subscriptionType: SubscriptionType;
  loading = true;
  code: string;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private updates$: Actions
  ) {
    route.queryParamMap.subscribe(data => {
      this.code = data.get('code');
      if (this.subscriptionTypes) {
        this.subscriptionType = this.subscriptionTypes.find(
          subscriptionType => subscriptionType.code.toLowerCase() === this.code
        );
      }
      if (this.subscriptions) {
        this.subscription = this.subscriptions.find(
          subscription =>
            subscription.subscriptionType.code.toLowerCase() === this.code
        );
      }
    });
    this.store.dispatch(new LoadWallet());
    this.store.dispatch(new LoadSubscriptionTypes());
    this.store
      .select('wallet')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.wallet = state;
          if (state.credits >= 0) {
            this.loading = false;
          }
        }
      });
    this.store
      .select(state => state.subscriptionTypes)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptionTypes => {
        if (subscriptionTypes) {
          this.subscriptionTypes = subscriptionTypes;
          this.subscriptionType = this.subscriptionTypes.find(
            subscriptionType =>
              subscriptionType.code.toLowerCase() === this.code
          );
        }
      });
    this.store
      .select(state => state.subscriptions)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptions => {
        if (subscriptions) {
          this.subscriptions = subscriptions;
          this.subscription = this.subscriptions.find(
            subscription =>
              subscription.subscriptionType.code.toLowerCase() === this.code
          );
        }
      });

    updates$
      .pipe(ofType(Types.subscriptions.START_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadSubscriptions());
        this.started = true;
      });
    updates$
      .pipe(ofType(Types.subscriptions.LOAD_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (data && this.started) {
          if (this.code.toUpperCase() === SubscriptionTypes.MATCHING) {
            this.router.navigate(['/settings/circles', { matching: true }]);
          } else if (this.code.toUpperCase() === 'CERTIWEIGHT_BACK_OFFICE') {
            this.router.navigate(['/certiweight-backoffice']);
          } else {
            this.router.navigate(['/' + this.code.toLowerCase().replace(/_/g, '-')]);
          }
        }
      });
  }

  ngOnDestroy() { }

  hasActiveSubscription() {
    if (this.subscription) {
      return moment().isBefore(moment(this.subscription.endDate));
    }
  }

  hasEnoughCredits(): boolean {
    if (this.wallet && this.subscriptionType) {
      return this.wallet.credits < +this.subscriptionType.credits; // + converts strings to numbers
    }
    return false;
  }
}
