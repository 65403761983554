/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./decline-registration-request-modal.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_DeclineRegistrationRequestModalComponent = [];
var RenderType_DeclineRegistrationRequestModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeclineRegistrationRequestModalComponent, data: {} });
export { RenderType_DeclineRegistrationRequestModalComponent as RenderType_DeclineRegistrationRequestModalComponent };
export function View_DeclineRegistrationRequestModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 10, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 6, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-danger text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Are you sure you want to decline this registration request?")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("You will still be able to approve it via the processed requests page.")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("No")); _ck(_v, 11, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("Yes")); _ck(_v, 14, 0, currVal_3); }); }
export function View_DeclineRegistrationRequestModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-decline-registration-request-modal", [], null, null, null, View_DeclineRegistrationRequestModalComponent_0, RenderType_DeclineRegistrationRequestModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.DeclineRegistrationRequestModalComponent, [i3.NgbActiveModal], null, null)], null, null); }
var DeclineRegistrationRequestModalComponentNgFactory = i0.ɵccf("app-decline-registration-request-modal", i2.DeclineRegistrationRequestModalComponent, View_DeclineRegistrationRequestModalComponent_Host_0, {}, {}, []);
export { DeclineRegistrationRequestModalComponentNgFactory as DeclineRegistrationRequestModalComponentNgFactory };
