import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { SoftDeleteService } from 'app/services/soft-delete.service';
import { ContainerType } from 'app/core/store/models/container-types.model';
import { Container } from 'app/core/store/models/container.model';
import { Liner } from 'app/core/store/models/liner.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import { Component, Input, OnDestroy } from '@angular/core';
import { Transport } from 'app/core/store/models/transport.model';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { CreateMarketPostOffer } from 'app/core/store/actions/market-post-offer.actions';
import * as MarketPlaceTypes from 'app/core/store/types/marketplace.types';
import { ToastService } from 'app/services/toast.service';
import { MarketpostParserService } from 'app/services/marketpost-parser.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-confirm-publish-transport',
  templateUrl: './confirm-publish-transport.component.html',
})
export class ConfirmPublishTransportComponent implements OnDestroy {
  @Input()
  transport: Transport;
  @Input()
  transportId: string;

  transportTypes: any[];
  isLoading: boolean;
  containerTypes: any[];
  allContainerTypes: any[];
  liners: any[];
  allLiners: any[];

  constructor(
    private toastr: ToastService,
    private store: Store<State>,
    private updates$: Actions,
    public activeModal: NgbActiveModal,
    private transportParser: MarketpostParserService,
    private softDeleteService: SoftDeleteService
  ) {
    store.dispatch(new LoadTransportTypes());
    store
      .select(state => state.marketplace.transportTypes)
      .pipe(untilDestroyed(this))
      .subscribe(transportTypes => {
        if (transportTypes) {
          this.transportTypes = transportTypes;
        }
      });
    this.updates$
      .pipe(ofType(MarketPlaceTypes.marketposts.CREATE_OFFER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract(
            'Successfully published transport to the Marketplace!'
          )
        });
        this.isLoading = false;
        this.activeModal.close();
      });
    this.updates$
      .pipe(ofType(MarketPlaceTypes.marketposts.CREATE_OFFER_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to publish transport to the Marketplace')
        });
        this.isLoading = false;
      });
    this.store
      .select(state => state.marketplace.liners)
      .pipe(untilDestroyed(this))
      .subscribe(linersState => {
        if (!linersState) {
          this.store.dispatch(new LoadLiners());
          // this.isLoadingLiners = true;
          return;
        }
        this.liners = [...linersState];
        // this.isLoadingLiners = false;
        if (this.transport) {
          this.updateLinersArray(this.transport.container, this.liners);
          this.transportParser.liners = this.allLiners;
        }
      });
    this.store
      .select(state => state.containerTypes)
      .pipe(untilDestroyed(this))
      .subscribe(containertTypesState => {
        if (!containertTypesState) {
          this.store.dispatch(new LoadContainerTypes());
          // this.isLoadingContainerTypes = true;
          return;
        }
        this.containerTypes = [...containertTypesState];
        // this.isLoadingContainerTypes = false;
        if (this.transport) {
          this.updateContainerTypesArray(
            this.transport.container,
            this.containerTypes
          );
          this.transportParser.containerTypes = this.allContainerTypes;
        }
      });
  }

  ngOnDestroy() { }

  updateLinersArray(container: Container, liners: Liner[]) {
    if (!container || !liners) {
      return;
    }
    this.allLiners = this.softDeleteService.addElementToArrayIfNotExists(
      container.liner,
      liners
    );
  }

  updateContainerTypesArray(
    container: Container,
    containerTypes: ContainerType[]
  ) {
    if (!container || !containerTypes) {
      return;
    }
    this.allContainerTypes = this.softDeleteService.addElementToArrayIfNotExists(
      container.liner,
      containerTypes
    );
  }

  publishToMarketplace(transport) {
    const parsed = this.transportParser.parseTransportOffer(transport);

    // Add id so we don't create a new transport
    parsed['id'] = this.transportId;
    const body = {
      marketPostOffers: [
        {
          transportOrigin: parsed
        }
      ],
      shareType: '',
      circles: []
    };
    this.store.dispatch(new CreateMarketPostOffer(body));
    this.isLoading = true;
  }
}
