import { DownloadService } from 'app/services/download.service';
import { PaymentNoteStates, PaymentNote } from 'app/core/store/models/payment-note.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { extract } from 'app/services/i18n.service';
import { Store } from '@ngrx/store';
import { ToastService } from 'app/services/toast.service';
import { State } from 'app/core/store/store.model';
import {
  LoadCreditNotes,
  UpdateCreditNote,
  LoadFilteredCreditNotes,
  DeleteCreditNote
} from 'app/core/store/actions/credit-note.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/credit-note.types';
import { Router } from '@angular/router';
import * as DateFns from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-credit-notes',
  templateUrl: './credit-notes.component.html',
  styleUrls: ['./credit-notes.component.scss']
})
export class CreditNotesComponent implements OnInit, OnDestroy {
  creditNotes: PaymentNote[];
  filteredCreditNotes: PaymentNote[];
  isLoadingCreditNotes: boolean;
  totalElements = 0;
  CreditNoteStates = PaymentNoteStates;
  tableMessages = {
    emptyMessage: extract('No credit notes to display'),
    totalMessage: extract('total')
  };
  placeholder = extract('Search name or VAT number');
  selected = [];
  query: any;
  page: any;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router,
    private downloadService: DownloadService,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.creditNotes &&
          state.crm.creditNotes.creditNotes
        ) {
          return state.crm.creditNotes.creditNotes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(creditNoteState => {
        if (!creditNoteState) {
          return;
        }
        this.creditNotes = [...creditNoteState];
        this.isLoadingCreditNotes = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.creditNotes &&
          state.crm.creditNotes.filteredCreditNotes
        ) {
          return state.crm.creditNotes.filteredCreditNotes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(creditNoteState => {
        if (!creditNoteState) {
          return;
        }
        this.filteredCreditNotes = [...creditNoteState];
        this.isLoadingCreditNotes = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.creditNotes &&
          state.crm.creditNotes.totalFilteredCreditNotes
        ) {
          return state.crm.creditNotes.totalFilteredCreditNotes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(totalElements => {
        if (!totalElements) {
          this.totalElements = 0;
          return;
        }
        this.totalElements = totalElements;
      });
    this.updates$
      .pipe(ofType(Types.creditNote.LOAD_CREDIT_NOTES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingCreditNotes = false;
        this.toastr.showDanger({
          message: extract('Failed to load credit notes')
        });
      });
    this.updates$
      .pipe(ofType(Types.creditNote.UPDATE_CREDIT_NOTE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update state')
        });
      });
    this.updates$
      .pipe(ofType(Types.creditNote.UPDATE_CREDIT_NOTE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingCreditNotes = true;
        this.toastr.showSuccess({
          message: extract('State updated successfully')
        });
        this.store.dispatch(new LoadCreditNotes());
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadCreditNotes());
    this.isLoadingCreditNotes = true;
  }

  getDaysLeft(dueDate) {
    return DateFns.differenceInCalendarDays(dueDate, new Date());
  }

  handleSearch(query, page?) {
    const p = page ? page : 1;
    if (!query) {
      this.query = null;
      this.store.dispatch(new LoadCreditNotes({ page: p }));
      return;
    }
    this.query = query;
    this.isLoadingCreditNotes = true;
    this.store.dispatch(new LoadFilteredCreditNotes({ query: query }));
  }

  setPage($event) {
    this.page = $event.offset + 1;
    this.handleSearch(this.query, this.page);
  }

  editCreditNote(creditNoteId: string) {
    this.router.navigateByUrl(`/crm/credit-notes/${creditNoteId}/edit`);
  }

  deleteCreditNote(creditNoteId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this credit note?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteCreditNote({ creditNoteId }));
    });
  }

  downloadCreditNotePDF(id: string, refNumber: string) {
    this.downloadService.downloadPDF(`/crm/credit_note/download/${id}`, refNumber);
  }

  updateCreditNoteState(id: string, state: string) {
    this.store.dispatch(new UpdateCreditNote({
      creditNoteId: id,
      body: { id, state },
    }));
  }

  getCreditNoteStateIcon(state) {
    switch (state) {
      case this.CreditNoteStates.DRAFT:
        return 'fa-file-edit';
      case this.CreditNoteStates.SENT:
        return 'fa-envelope';
      case this.CreditNoteStates.PAID:
        return 'fa-check';
      default:
        break;
    }
  }

  ngOnDestroy() { }
}
