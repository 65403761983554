import { TranslateService } from '@ngx-translate/core';
import * as nl_BE from '../../assets/i18n/nl.json';
import * as fr_BE from '../../assets/i18n/fr.json';
import * as en_GB from '../../assets/i18n/en.json';
import * as es_ES from '../../assets/i18n/es.json';
var languageKey = 'language';
/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param {string} s The string to extract for translation.
 * @return {string} The same string.
 */
export function extract(s) {
    return s;
}
var I18nService = /** @class */ (function () {
    function I18nService(translateService) {
        this.translateService = translateService;
        // Embed languages to avoid extra HTTP requests
        translateService.setTranslation('en_GB', en_GB.default);
        translateService.setTranslation('nl_BE', nl_BE.default);
        translateService.setTranslation('fr_BE', fr_BE.default);
        translateService.setTranslation('es_ES', es_ES.default);
    }
    /**
     * Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @param {!string} defaultLanguage The default language to use.
     * @param {Array.<String>} supportedLanguages The list of supported languages.
     */
    I18nService.prototype.init = function (defaultLanguage, supportedLanguages) {
        this.defaultLanguage = defaultLanguage;
        this.supportedLanguages = supportedLanguages;
        this.language = '';
        this.translateService.onLangChange.subscribe(function (event) {
            localStorage.setItem(languageKey, event.lang);
        });
    };
    Object.defineProperty(I18nService.prototype, "language", {
        /**
         * Gets the current language.
         * @return {string} The current language code.
         */
        get: function () {
            return this.translateService.currentLang;
        },
        /**
         * Sets the current language.
         * Note: The current language is saved to the local storage.
         * If no parameter is specified, the language is loaded from local storage (if present).
         * @param {string} language The IETF language code to set.
         */
        set: function (language) {
            language = language || localStorage.getItem(language);
            var isSupportedLanguage = this.supportedLanguages.indexOf(language) !== -1;
            // If no exact match is found, search without the region
            if (language && !isSupportedLanguage) {
                language = language.split('_')[0];
                language =
                    this.supportedLanguages.find(function (supportedLanguage) {
                        return supportedLanguage.startsWith(language);
                    }) || '';
                isSupportedLanguage = Boolean(language);
            }
            // Fallback if language is not supported
            if (!isSupportedLanguage) {
                console.log('Language not found, falling back to default.');
                language = this.defaultLanguage;
                this.translateService.use(language);
                return;
            }
            localStorage.setItem('language', language);
            this.translateService.setDefaultLang('en');
            this.translateService.use(language);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(I18nService.prototype, "languages", {
        /**
         * Gets the supported languages.
         * @return {{value: string, label: string}} The supported languages
         */
        get: function () {
            return [
                {
                    value: 'nl_BE',
                    label: 'Nederlands'
                },
                {
                    value: 'fr_BE',
                    label: 'Francais'
                },
                {
                    value: 'en_GB',
                    label: 'English'
                },
                {
                    value: 'es_ES',
                    label: 'Español'
                }
            ];
        },
        enumerable: true,
        configurable: true
    });
    return I18nService;
}());
export { I18nService };
