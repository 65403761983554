import { Observable } from 'rxjs';
import { TransportTask } from 'app/core/store/models/transport-task.model';
import { MarketPost } from 'app/core/store/models/market-posts.model';
import { HakkaMap } from './hakka-map';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';

import View from 'ol/view';
import TileLayer from 'ol/layer/tile';
import VectorLayer from 'ol/layer/vector';
import VectorSource from 'ol/source/vector';
import Coordinate from 'ol/coordinate';
import Feature from 'ol/feature';
import Point from 'ol/geom/point';
import Circle from 'ol/style/circle';
import Fill from 'ol/style/fill';
import Style from 'ol/style/style';
import proj from 'ol/proj';
import XYZ from 'ol/source/xyz';
import Overlay from 'ol/overlay';

/**
 * Component that just renders a simple map, and initialises the layers. Doesn't do much else
 *
 * @export
 * @class MapComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-cmp-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit, OnChanges {
  @Input() posts: Observable<MarketPost[]>;
  @Input() updateSize;

  showAlert = true;
  constructor() { }

  @Output()
  updateByGeoBoundBox = new EventEmitter<{
    topLeftLon: number;
    topLeftLat: number;
    bottomRightLon: number;
    bottomRightLat: number;
  }>();

  hakkaMap: HakkaMap;
  markerLayer: VectorLayer;
  arcsLayer: VectorLayer;

  ngOnChanges() {
    if (this.hakkaMap) {
      this.hakkaMap.map.updateSize();
    }
  }

  ngOnInit() {
    this.hakkaMap = new HakkaMap('map');

    this.markerLayer = this.hakkaMap.getLayerById('markers');
    this.arcsLayer = this.hakkaMap.getLayerById('arcs');
    this.posts.subscribe(posts => {
      const arcs = [];
      const markers = [];

      (posts || []).forEach((p: MarketPost) => {
        const elm = p._source;
        const geometries = [];
        switch (elm.marketPostType) {
          case 'demand':
            if (
              elm.originLocation &&
              typeof elm.originLocation.geo !== 'undefined'
            ) {
              markers.push(
                this.hakkaMap.generateFeature(
                  elm.originLocation.geo,
                  elm._id + '_origin'
                )
              );
            }
            if (elm.originLocation && elm.originLocation.geo) {
              geometries.push(elm.originLocation.geo);
            }
            if (
              elm.destinationLocation &&
              typeof elm.destinationLocation.geo !== 'undefined'
            ) {
              markers.push(
                this.hakkaMap.generateFeature(
                  elm.destinationLocation.geo,
                  elm._id + '_destination'
                )
              );
              geometries.push(elm.destinationLocation.geo);
            }
            break;
          case 'offer':
            elm.tasks.forEach((task: TransportTask) => {
              if (task.location && typeof task.location.geo !== 'undefined') {
                markers.push(
                  this.hakkaMap.generateFeature(
                    task.location.geo,
                    elm.last_updated
                  )
                );
                geometries.push(task.location.geo);
              }
            });
            break;
          default:
            break;
        }
        if (geometries.length > 1) {
          arcs.push(this.hakkaMap.connectFeatures(geometries));
        }
      });

      this.hakkaMap.sources['markers'].getFeaturesCollection().clear();
      this.hakkaMap.sources['markers'].getFeaturesCollection().extend(markers);

      // this.hakkaMap.sources['arcs'].getFeaturesCollection().clear();
      // arcs.forEach((arc) => {
      //   this.hakkaMap.sources['arcs'].getFeaturesCollection().extend(arc);
      // });
    });
  }

  // Overlay elements
  /* const container = document.getElementById('popup');
      const content = document.getElementById('popup-content');
      const closer = document.getElementById('popup-closer');

      const overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250
        },
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -5]
      });

      closer.onclick = function () {
          overlay.setPosition(undefined);
          closer.blur();
          // return false so we don't follow the anchor tag
          return false;
      };

        this.map.addOverlay(overlay);

        this.map.on('click', (evt) => {
            const feature = this.map.forEachFeatureAtPixel(evt.pixel, (feat) => {
                return feat;
            });
            if (feature) {
                const coordinate = evt.coordinate;
                switch (feature.data.marketPostType) {
                  case 'offer':
                    this.marketPostOffer = feature.data;
                    this.marketPostDemand = null;
                    break;
                  case 'demand':
                    this.marketPostDemand = feature.data;
                    this.marketPostOffer = null;
                    break;
                  default:
                    break;
                }
                overlay.setPosition(coordinate);
            }
        });
        this.updateFeatures();}*/

  updateMarketpostsByGeoBoundBox() {
    const topLeftLonLat = this.hakkaMap.getTopLeft();
    const bottomRightLonLat = this.hakkaMap.getBottomRight();
    const coordinates = {
      topLeftLon: topLeftLonLat[0],
      topLeftLat: topLeftLonLat[1],
      bottomRightLon: bottomRightLonLat[0],
      bottomRightLat: bottomRightLonLat[1]
    };
    this.updateByGeoBoundBox.emit(coordinates);
  }
}
