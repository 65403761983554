/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./matching-pool-option.component.scss.shim.ngstyle";
import * as i1 from "../matching-pool-item/matching-pool-item.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../transport-matching-pool-item/transport-matching-pool-item.component.ngfactory";
import * as i4 from "../transport-matching-pool-item/transport-matching-pool-item.component";
import * as i5 from "../../services/icon.service";
import * as i6 from "../confidence-score/confidence-score.component.ngfactory";
import * as i7 from "../confidence-score/confidence-score.component";
import * as i8 from "../market-post-offer-matching-pool-item/market-post-offer-matching-pool-item.component.ngfactory";
import * as i9 from "../market-post-offer-matching-pool-item/market-post-offer-matching-pool-item.component";
import * as i10 from "../market-post-demand-matching-pool-item/market-post-demand-matching-pool-item.component.ngfactory";
import * as i11 from "../market-post-demand-matching-pool-item/market-post-demand-matching-pool-item.component";
import * as i12 from "@angular/common";
import * as i13 from "@ng-bootstrap/ng-bootstrap";
import * as i14 from "@ngx-translate/core";
import * as i15 from "@angular/forms";
import * as i16 from "../../shared/button/button.component.ngfactory";
import * as i17 from "../../shared/button/button.component";
import * as i18 from "./matching-pool-option.component";
import * as i19 from "@ngrx/effects";
var styles_MatchingPoolOptionComponent = [i0.styles, i1.styles];
var RenderType_MatchingPoolOptionComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_MatchingPoolOptionComponent, data: {} });
export { RenderType_MatchingPoolOptionComponent as RenderType_MatchingPoolOptionComponent };
function View_MatchingPoolOptionComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "app-transport-matching-pool-item", [], null, null, null, i3.View_TransportMatchingPoolItemComponent_0, i3.RenderType_TransportMatchingPoolItemComponent)), i2.ɵdid(1, 49152, null, 0, i4.TransportMatchingPoolItemComponent, [i5.IconService], { transport: [0, "transport"], isMatchingPoolOption: [1, "isMatchingPoolOption"], tenant: [2, "tenant"] }, null), (_l()(), i2.ɵeld(2, 0, null, 1, 1, "app-confidence-score", [], null, null, null, i6.View_ConfidenceScoreComponent_0, i6.RenderType_ConfidenceScoreComponent)), i2.ɵdid(3, 49152, null, 0, i7.ConfidenceScoreComponent, [], { confidence: [0, "confidence"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.option.match.transport; var currVal_1 = true; var currVal_2 = _co.option.match.tenant; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.option.confidence; _ck(_v, 3, 0, currVal_3); }, null); }
function View_MatchingPoolOptionComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "app-market-post-offer-matching-pool-item", [], null, null, null, i8.View_MarketPostOfferMatchingPoolItemComponent_0, i8.RenderType_MarketPostOfferMatchingPoolItemComponent)), i2.ɵdid(1, 49152, null, 0, i9.MarketPostOfferMatchingPoolItemComponent, [], { offer: [0, "offer"], isMatchingPoolOption: [1, "isMatchingPoolOption"], tenant: [2, "tenant"] }, null), (_l()(), i2.ɵeld(2, 0, null, 1, 1, "app-confidence-score", [], null, null, null, i6.View_ConfidenceScoreComponent_0, i6.RenderType_ConfidenceScoreComponent)), i2.ɵdid(3, 49152, null, 0, i7.ConfidenceScoreComponent, [], { confidence: [0, "confidence"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.option.match.marketPostOffer.transportOrigin; var currVal_1 = true; var currVal_2 = _co.option.match.tenant; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.option.confidence; _ck(_v, 3, 0, currVal_3); }, null); }
function View_MatchingPoolOptionComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 3, "app-market-post-demand-matching-pool-item", [], null, null, null, i10.View_MarketPostDemandMatchingPoolItemComponent_0, i10.RenderType_MarketPostDemandMatchingPoolItemComponent)), i2.ɵdid(1, 49152, null, 0, i11.MarketPostDemandMatchingPoolItemComponent, [], { demand: [0, "demand"], isMatchingPoolOption: [1, "isMatchingPoolOption"], tenant: [2, "tenant"] }, null), (_l()(), i2.ɵeld(2, 0, null, 1, 1, "app-confidence-score", [], null, null, null, i6.View_ConfidenceScoreComponent_0, i6.RenderType_ConfidenceScoreComponent)), i2.ɵdid(3, 49152, null, 0, i7.ConfidenceScoreComponent, [], { confidence: [0, "confidence"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.option.match.marketPostDemand; var currVal_1 = true; var currVal_2 = _co.option.match.tenant; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.option.confidence; _ck(_v, 3, 0, currVal_3); }, null); }
function View_MatchingPoolOptionComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 18, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 6, "span", [["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MatchingPoolOptionComponent_2)), i2.ɵdid(3, 16384, null, 0, i12.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MatchingPoolOptionComponent_3)), i2.ɵdid(5, 16384, null, 0, i12.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MatchingPoolOptionComponent_4)), i2.ɵdid(7, 16384, null, 0, i12.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(8, 16777216, null, null, 3, "span", [["class", "icon text-success ml-1"], ["id", "reply-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleReply() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(9, 212992, null, 0, i13.NgbTooltip, [i2.ElementRef, i2.Renderer2, i2.Injector, i2.ComponentFactoryResolver, i2.ViewContainerRef, i13.NgbTooltipConfig, i2.NgZone, i12.DOCUMENT, i2.ChangeDetectorRef, i2.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), i2.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(11, 0, null, null, 0, "i", [["class", "far fa-reply"]], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 16777216, null, null, 6, "span", [["class", "icon text-danger ml-1"], ["id", "remove-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRemoveOption() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵdid(13, 212992, null, 0, i13.NgbTooltip, [i2.ElementRef, i2.Renderer2, i2.Injector, i2.ComponentFactoryResolver, i2.ViewContainerRef, i13.NgbTooltipConfig, i2.NgZone, i12.DOCUMENT, i2.ChangeDetectorRef, i2.ApplicationRef], { ngbTooltip: [0, "ngbTooltip"] }, null), i2.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(15, 0, null, null, 3, "i", [["class", "far"]], null, null, null, null, null)), i2.ɵprd(512, null, i12.ɵNgClassImpl, i12.ɵNgClassR2Impl, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2]), i2.ɵdid(17, 278528, null, 0, i12.NgClass, [i12.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i2.ɵpod(18, { "fa-times": 0, "fa-spin fa-spinner": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.option.match.transport; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.option.match.marketPostOffer == null) ? null : _co.option.match.marketPostOffer.transportOrigin); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.option.match.marketPostDemand; _ck(_v, 7, 0, currVal_2); var currVal_3 = i2.ɵinlineInterpolate(1, "", i2.ɵunv(_v, 9, 0, i2.ɵnov(_v, 10).transform("Reply")), ""); _ck(_v, 9, 0, currVal_3); var currVal_4 = i2.ɵinlineInterpolate(1, "", i2.ɵunv(_v, 13, 0, i2.ɵnov(_v, 14).transform("Not interested")), ""); _ck(_v, 13, 0, currVal_4); var currVal_5 = "far"; var currVal_6 = _ck(_v, 18, 0, !_co.isRemovingMatchOption, _co.isRemovingMatchOption); _ck(_v, 17, 0, currVal_5, currVal_6); }, null); }
function View_MatchingPoolOptionComponent_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, [" ", " "])), i2.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i2.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i2.ɵunv(_v, 1, 0, i2.ɵnov(_v, 2).transform("This match has been deleted by the owner")); _ck(_v, 1, 0, currVal_0); }); }
function View_MatchingPoolOptionComponent_6(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 12, "div", [["class", "mt-1"], ["id", "matching-option-reply"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 6, "textarea", [["class", "form-control mb-1"], ["cols", "20"], ["rows", "5"]], [[2, "is-invalid", null], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i2.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i2.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i2.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i2.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.reply = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i2.ɵdid(2, 16384, null, 0, i15.DefaultValueAccessor, [i2.Renderer2, i2.ElementRef, [2, i15.COMPOSITION_BUFFER_MODE]], null, null), i2.ɵprd(1024, null, i15.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i15.DefaultValueAccessor]), i2.ɵdid(4, 671744, null, 0, i15.NgModel, [[8, null], [8, null], [8, null], [6, i15.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i2.ɵprd(2048, null, i15.NgControl, null, [i15.NgModel]), i2.ɵdid(6, 16384, null, 0, i15.NgControlStatus, [[4, i15.NgControl]], null, null), i2.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(8, 0, null, null, 4, "app-button", [], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.submitReplyClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_ButtonComponent_0, i16.RenderType_ButtonComponent)), i2.ɵdid(9, 49152, null, 0, i17.ButtonComponent, [], { type: [0, "type"], isLoading: [1, "isLoading"] }, { buttonClicked: "buttonClicked" }), (_l()(), i2.ɵeld(10, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i2.ɵted(11, null, ["", ""])), i2.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i2.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.reply; _ck(_v, 4, 0, currVal_9); var currVal_10 = "button"; var currVal_11 = _co.isLoading; _ck(_v, 9, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.submitAttempt && !_co.reply); var currVal_1 = i2.ɵinlineInterpolate(1, "", i2.ɵunv(_v, 1, 1, i2.ɵnov(_v, 7).transform("Type your reply here")), ""); var currVal_2 = i2.ɵnov(_v, 6).ngClassUntouched; var currVal_3 = i2.ɵnov(_v, 6).ngClassTouched; var currVal_4 = i2.ɵnov(_v, 6).ngClassPristine; var currVal_5 = i2.ɵnov(_v, 6).ngClassDirty; var currVal_6 = i2.ɵnov(_v, 6).ngClassValid; var currVal_7 = i2.ɵnov(_v, 6).ngClassInvalid; var currVal_8 = i2.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_12 = i2.ɵunv(_v, 11, 0, i2.ɵnov(_v, 12).transform("Reply")); _ck(_v, 11, 0, currVal_12); }); }
export function View_MatchingPoolOptionComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [["class", "card p-3 match-option"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MatchingPoolOptionComponent_1)), i2.ɵdid(2, 16384, null, 0, i12.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i2.ɵand(0, [["matchIsDeleted", 2]], null, 0, null, View_MatchingPoolOptionComponent_5)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MatchingPoolOptionComponent_6)), i2.ɵdid(5, 16384, null, 0, i12.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.option == null) ? null : ((_co.option.match == null) ? null : _co.option.match.subjectDeleted)); var currVal_1 = i2.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.replyIsVisible; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_MatchingPoolOptionComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-matching-pool-option", [], null, null, null, View_MatchingPoolOptionComponent_0, RenderType_MatchingPoolOptionComponent)), i2.ɵdid(1, 180224, null, 0, i18.MatchingPoolOptionComponent, [i19.Actions], null, null)], null, null); }
var MatchingPoolOptionComponentNgFactory = i2.ɵccf("app-matching-pool-option", i18.MatchingPoolOptionComponent, View_MatchingPoolOptionComponent_Host_0, { option: "option", isLoading: "isLoading", isRemovingMatchOption: "isRemovingMatchOption" }, { submitReply: "submitReply", removeOption: "removeOption" }, []);
export { MatchingPoolOptionComponentNgFactory as MatchingPoolOptionComponentNgFactory };
