<ngb-alert *ngIf="alert" [type]="alert.type" (close)="closeAlert()">
  <i class="far" [ngClass]="alert.icon"></i>
  <span>{{ alert.message | translate }}</span>
</ngb-alert>
<div class="card">
  <h3 class="card-header text-center">
    <span
      class="badge badge-dark"
      placement="left"
      ngbTooltip="{{ 'Quantity' | translate }}"
      >{{ marketPost?._source.quantity }}</span
    >
    {{ marketPost?._source.transportType.label | translate }} -
    {{ 'Offer' | translate }} - {{ marketPost?._source.tenant.name }}
  </h3>
  <div class="card-body d-flex flex-wrap">
    <div class="card mb-3 col" *ngFor="let tTask of marketPost?._source.tasks">
      <div class="card-body">
        <h4 class="text-center">
          <span class="icon">
            <i class="far" [ngClass]="getTaskIcon(tTask)"></i>
          </span>
          <span>{{ tTask.taskType.label | translate }}</span>
        </h4>
        <hr />
        <div>
          <span class="icon">
            <i
              class="far fa-map-marker"
              ngbTooltip="{{ 'Location' | translate }}"
            ></i>
          </span>
          <span *ngIf="tTask.location">{{
            tTask.location.name || 'N.A.'
          }}</span>
        </div>
        <div *ngIf="tTask.startDate">
          <span>
            <span class="icon">
              <i
                class="far fa-calendar-alt"
                ngbTooltip="{{ 'Start Date' | translate }}"
              ></i>
            </span>
            <span>{{ tTask.startDate | date: 'dd/MM/yyyy' }}</span>
            <span *ngIf="tTask.startDateTimeSpecified">
              <span class="icon">
                <i
                  class="far fa-clock"
                  ngbTooltip="{{ 'Start Time' | translate }}"
                ></i>
              </span>
              <span>{{ tTask.startDate | date: 'HH:mm' }}</span>
            </span>
          </span>
        </div>
        <div *ngIf="tTask.endDate">
          <span>
            <span class="icon">
              <i
                class="far fa-calendar-alt"
                ngbTooltip="{{ 'Start Date' | translate }}"
              ></i>
            </span>
            <span>{{ tTask.endDate | date: 'dd/MM/yyyy' }}</span>
            <span *ngIf="tTask.endDateTimeSpecified">
              <span class="icon">
                <i
                  class="far fa-clock"
                  ngbTooltip="{{ 'Start Time' | translate }}"
                ></i>
              </span>
              <span>{{ tTask.endDate | date: 'HH:mm' }}</span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="card mb-3 col">
      <div class="card-body">
        <h4 class="text-center">{{ 'Container' | translate }}</h4>
        <hr />
        <div>
          <span class="icon">
            <i
              class="far fa-building"
              ngbTooltip="{{ 'Liner' | translate }}"
            ></i>
          </span>
          <span *ngIf="marketPost?._source.container.liner; else noLiner">
            {{
              marketPost?._source.container.liner.shortName
                ? marketPost?._source.container.liner.shortName
                : marketPost?._source.container.liner.name
            }}
          </span>
          <ng-template #noLiner>
            <span>{{ 'N.A.' | translate }}</span>
          </ng-template>
        </div>
        <div>
          <span class="icon">
            <i
              class="far fa-cube"
              ngbTooltip="{{ 'Container Type' | translate }}"
            ></i>
          </span>
          <span
            *ngIf="marketPost?._source.container.containerType; else noCType"
            >{{ marketPost?._source.container.containerType.label }}</span
          >
          <ng-template #noCType>
            <span>{{ 'N.A.' | translate }}</span>
          </ng-template>
        </div>
        <div *ngIf="marketPost?._source.container.adr">
          <span class="icon">
            <i
              class="far fa-exclamation-triangle"
              ngbTooltip="{{ 'ADR' | translate }}"
            ></i>
          </span>
          <span>ADR</span>
        </div>
        <div>
          <span class="icon">
            <i
              class="far fa-balance-scale"
              ngbTooltip="{{ 'Weight' | translate }}"
            ></i>
          </span>
          <span *ngIf="marketPost?._source.container.weight; else noWeight">{{
            marketPost?._source.container.weight
          }}</span>
          <ng-template #noWeight>
            <span>{{ 'N.A.' | translate }}</span>
          </ng-template>
        </div>
        <div>
          <span class="icon">
            <i
              class="far fa-id-badge"
              ngbTooltip="{{ 'Reference' | translate }}"
            ></i>
          </span>
          <span *ngIf="marketPost?._source.reference; else noRef">{{
            marketPost?._source.reference
          }}</span>
          <ng-template #noRef>
            <span>{{ 'N.A.' | translate }}</span>
          </ng-template>
        </div>
        <div>
          <span class="icon">
            <i
              class="far fa-comment-alt"
              ngbTooltip="{{ 'Comment' | translate }}"
            ></i>
          </span>
          <span *ngIf="marketPost?._source.reference; else noComment">{{
            marketPost?._source.comment
          }}</span>
          <ng-template #noComment>
            <span>{{ 'N.A.' | translate }}</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <form class="card-body" (ngSubmit)="onSubmit()" [formGroup]="replyForm">
    <textarea
      class="form-control mb-1"
      placeholder="{{ 'Type your reply here' | translate }}"
      formControlName="text"
      cols="20"
      rows="5"
    ></textarea>
    <app-button [isLoading]="loading" [type]="'submit'">
      <span>{{ 'Reply' | translate }}</span>
    </app-button>
  </form>
</div>
