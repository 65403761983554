<div class="m-3">
  <ngb-alert *ngIf="alert"
    [type]="alert.type"
    (close)="closeAlert()">
    <i class="fa"
      [ngClass]="alert.icon"></i>
    <span>{{ alert.message | translate }}</span>
  </ngb-alert>
  <div class="card">
    <h5 class="card-title card-title-bb">
      <strong>{{ 'Create new Tenant' | translate }}</strong>
    </h5>
    <app-tenant-form [subscriptionTypes]="subscriptionTypes"
      [languages]="languages"
      [countries]="countries"
      [loading]="loading"
      (tenantFormSubmitted)="onSubmit($event)">
    </app-tenant-form>
  </div>
</div>
