<div class="card p-3">
  <a routerLink="{{ route.url }}">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">
        <span class="icon mr-1">
          <i class="far" [ngClass]="route.icon"></i>
        </span>
        <span>{{ route.title | translate }}</span>
      </h5>
    </div>
    <p class="mb-0 text-muted">{{ route.description | translate }}</p>
  </a>
  <button
    *ngIf="route.createRoute"
    routerLink="{{route.createRoute.url}}"
    class="btn"
  >
    <i class="far fa-fw fa-plus"></i>
  </button>
</div>
