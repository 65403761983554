import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/preannouncement.actions';
var ɵ0 = function (_, _a) {
    var payload = _a.payload;
    return payload;
}, ɵ1 = function (state, _a) {
    var payload = _a.payload;
    return state.concat([payload]);
}, ɵ2 = function (state, _a) {
    var payload = _a.payload;
    return state.map(function (x) { return x.id === payload.id ? payload : x; });
}, ɵ3 = function (state, _a) {
    var id = _a.id;
    return state.filter(function (x) { return x.id !== id; });
};
var reducer = createReducer([], on(Actions.loadPreannouncementsSuccess, ɵ0), on(Actions.createPreannouncementSuccess, ɵ1), on(Actions.updatePreannouncementSuccess, ɵ2), on(Actions.deletePreannouncementSuccess, ɵ3));
export function preannouncementReducer(state, action) {
    return reducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
