<ngx-datatable
  #tariffTable
  class="bootstrap row ml-3 mr-3"
  [rows]="tariffs"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="false"
  [count]="totalElements"
  [limit]="30"
  [messages]="tableMessages"
  [loadingIndicator]="isLoading"
>
  <ngx-datatable-row-detail [rowHeight]="'auto'" #myDetailRow>
    <ng-template
      let-row="row"
      let-expanded="expanded"
      ngx-datatable-row-detail-template
    >
      <div style="padding-left:55px;">
        <div class="m-3" *ngFor="let part of row.parts">
          <!-- 120 x 2,00€ -->
          {{ part.description }}: {{ part.quantity }} x
          {{ part.unitPrice | currency: 'EUR':'code' }}
        </div>
      </div>
    </ng-template>
  </ngx-datatable-row-detail>
  <div>
    <ngx-datatable-column
      [width]="50"
      [resizeable]="false"
      [sortable]="false"
      [draggable]="false"
      [canAutoResize]="false"
    >
      <ng-template
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-cell-template
      >
        <a
          href="javascript:void(0)"
          [class.datatable-icon-right]="!expanded"
          [class.datatable-icon-down]="expanded"
          title="Expand/Collapse Row"
          (click)="toggleExpandRow(row)"
        >
        </a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Label' | translate }}" prop="label">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Price' | translate }}" prop="price">
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ value | currency: 'EUR':'code' }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [cellClass]="'datatable-actions'"
      name="{{ 'Details' | translate }}"
      prop=""
      [width]="140"
    >
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <button class="btn" (click)="editTariff(row.id)">
          <i class="far fa-pencil"></i>
        </button>
        <button (click)="handleDelete(row)" class="btn">
          <i class="far fa-trash-alt"></i>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
