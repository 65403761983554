var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../../store/types/transaction.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as TransactionsActions from '../../store/actions/transactions.actions';
import { ApiService } from '../../api/api.service';
var TransactionsEffects = /** @class */ (function () {
    function TransactionsEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadCreditTransactions$ = this.actions$.pipe(ofType(types.transactions.LOAD_CREDIT_TRANSACTIONS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/credit_transactions?invoiceNumber[exists]=true&pageSize=" + (payload && payload.pageSize ? payload.pageSize : 30) + "&page=" + (payload && payload.page ? payload.page : 1)
        }).pipe(map(function (data) { return new TransactionsActions.LoadCreditTransactionsSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.loadAllTransactions$ = this.actions$.pipe(ofType(types.transactions.LOAD_ALL_TRANSACTIONS), concatMap(function () { return _this.api.get({
            path: '/abstract_transactions?credits[gt]=0'
        }).pipe(map(function (data) { return new TransactionsActions.LoadAllTransactionsSuccess(data); }), catchError(function (error) { return of(new TransactionsActions.LoadAllTransactionsFail(error)); })); }));
        this.loadTransactionPage$ = this.actions$.pipe(ofType(types.transactions.LOAD_TRANSACTION_PAGE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/abstract_transactions?credits[gt]=0&page=" + payload.page
        }).pipe(map(function (data) { return new TransactionsActions.LoadTransactionPageSuccess(data); }), catchError(function (error) { return of(new TransactionsActions.LoadTransactionPageSuccess(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransactionsEffects.prototype, "loadCreditTransactions$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransactionsEffects.prototype, "loadAllTransactions$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransactionsEffects.prototype, "loadTransactionPage$", void 0);
    return TransactionsEffects;
}());
export { TransactionsEffects };
