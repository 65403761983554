import { Action } from '@ngrx/store';
import * as types from '../types/certiweight.types';

export class LoadDefaultCertificatePrice implements Action {
  readonly type = types.certiweight.LOAD_DEFAULT_CERTIFICATE_PRICE;
  constructor(public payload: any = null) { }
}

export class LoadDefaultCertificatePriceSuccess implements Action {
  readonly type = types.certiweight.LOAD_DEFAULT_CERTIFICATE_PRICE_SUCCEEDED;
  constructor(public payload: any) { }
}


export class LoadDefaultCertificatePriceFail implements Action {
  readonly type = types.certiweight.LOAD_DEFAULT_CERTIFICATE_PRICE_FAILED;
  constructor(public payload: any) { }
}

export type All =
  | LoadDefaultCertificatePrice
  | LoadDefaultCertificatePriceFail
  | LoadDefaultCertificatePriceSuccess;
