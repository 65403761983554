/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../tariff-form/tariff-form.component.ngfactory";
import * as i3 from "../tariff-form/tariff-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./edit-tariff-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@ngrx/store";
import * as i8 from "@ngrx/effects";
var styles_EditTariffModalComponent = [];
var RenderType_EditTariffModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditTariffModalComponent, data: {} });
export { RenderType_EditTariffModalComponent as RenderType_EditTariffModalComponent };
export function View_EditTariffModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-tariff-form", [], null, [[null, "tariffFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tariffFormSubmitted" === en)) {
        var pd_0 = (_co.editTariff($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TariffFormComponent_0, i2.RenderType_TariffFormComponent)), i0.ɵdid(5, 638976, null, 0, i3.TariffFormComponent, [i4.FormBuilder], { isLoading: [0, "isLoading"], tariff: [1, "tariff"] }, { tariffFormSubmitted: "tariffFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; var currVal_2 = _co.tariff; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Edit tariff template")); _ck(_v, 2, 0, currVal_0); }); }
export function View_EditTariffModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-tariff-modal", [], null, null, null, View_EditTariffModalComponent_0, RenderType_EditTariffModalComponent)), i0.ɵdid(1, 180224, null, 0, i5.EditTariffModalComponent, [i6.NgbActiveModal, i7.Store, i8.Actions], null, null)], null, null); }
var EditTariffModalComponentNgFactory = i0.ɵccf("app-edit-tariff-modal", i5.EditTariffModalComponent, View_EditTariffModalComponent_Host_0, { tariff: "tariff" }, {}, []);
export { EditTariffModalComponentNgFactory as EditTariffModalComponentNgFactory };
