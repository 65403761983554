<div class="card" style="min-width:300px">
  <div class="card-header">
    <h4>{{ 'Container Types' | translate }}</h4>
    <span class="text-muted">{{ 'Pick the container types you want to receive email notifications for.' | translate }}</span>
  </div>
  <div class="card-body">
    <div class="d-flex justify-content-between mb-2">
      <span class="font-weight-bold">{{ 'Type' | translate }}</span>
      <div>
        <span class="font-weight-bold">{{ 'Offer' | translate }}</span>
        <span class="font-weight-bold">{{ 'Demand' | translate }}</span>
      </div>
    </div>
    <app-cmp-loading [loading]="loading"></app-cmp-loading>
    <div class="d-flex justify-content-between" *ngFor="let cType of containerTypes$ | async">
      <span>{{ cType?.label | translate }}</span>
      <div>
        <div class="custom-control custom-checkbox custom-control-inline" (click)="toggleOffer(cType)">
          <input type="checkbox" class="custom-control-input" [checked]="getSubscriptionStatus(cType, 'offer')">
          <label class="custom-control-label">&nbsp;</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline" (click)="toggleDemand(cType)">
          <input type="checkbox" class="custom-control-input" [checked]="getSubscriptionStatus(cType, 'demand')">
          <label class="custom-control-label">&nbsp;</label>
        </div>
      </div>
    </div>
  </div>
</div>
