import { Customer } from './customer.model';
import { PaymentNoteItem } from './payment-note-item.model';
import { Supplier } from './supplier.model';
import { Business } from './business.model';

export interface Invoice {
  id: string;
  customer: string | Customer;
  state: string;
  number: string;
  date: string;
  dueDate: string;
  notes: string;
  items: PaymentNoteItem[];
}

export interface CreditNote {
  id: string;
  customer: string | Customer;
  state: string;
  number: string;
  date: string;
  notes: string;
  items: PaymentNoteItem[];
}

export interface PurchaseOrder {
  id: string;
  supplier: string | Supplier;
  state: string;
  number: string;
  date: string;
  notes: string;
  items: PaymentNoteItem[];
}

export interface PaymentNote {
  id: string;
  business: Business | string;
  state: string;
  number: string;
  date: string;
  dueDate?: string;
  notes: string;
  items: PaymentNoteItem[];
}

export enum PaymentNoteStates {
  INVOICE_RECEIVED = 'invoice_received',
  DRAFT = 'draft',
  SENT = 'sent',
  PAID = 'paid',
  REMINDED = 'reminded',
  SETTLED = 'settled'
}
