import { MarketPostOfferReply } from './market-post-offer-replies.model';
import * as types from '../types/market-post.types';
import * as MarketPostOfferReplyActions from '../actions/market-post-offer-replies.actions';

const INITIAL_STATE = null;

export function reducer(
  state = INITIAL_STATE,
  action: MarketPostOfferReplyActions.All
): MarketPostOfferReply[] {
  switch (action.type) {
    case types.marketPostOfferReplies.LOAD_MARKET_POST_OFFER_REPLIES_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}
