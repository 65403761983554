<form (ngSubmit)="onSubmit()" [formGroup]="containerTypeForm" class="pb-3">
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Label' | translate }}</label>
        <input
          class="form-control"
          formControlName="label"
          [ngClass]="{
            'is-invalid':
              containerTypeForm.controls['label'].invalid &&
              containerTypeForm.controls['label'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Label is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Length' | translate }}</label>
        <input
          type="number"
          class="form-control"
          formControlName="length"
          [ngClass]="{
            'is-invalid':
              containerTypeForm.controls['length'].invalid &&
              containerTypeForm.controls['length'].touched
          }"
        />
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block"></label>
        <label class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            formControlName="reefer"
          />
          <span class="custom-control-label">{{
            'Is reefer' | translate
          }}</span>
        </label>
      </div>
    </div>
  </div>
  <label class="center-block">{{ 'Aliases' | translate }}</label>
  <div
    formArrayName="aliases"
    class="mb-3"
    *ngFor="
      let alias of containerTypeForm.get('aliases')['controls'];
      let i = index
    "
  >
    <div [formGroupName]="i" class="form-row">
      <div class="col-6">
        <input
          class="form-control"
          formControlName="purpose"
          placeholder="alias purpose"
        />
      </div>
      <div class="col-5">
        <input class="form-control" formControlName="code" placeholder="code" />
      </div>
      <div class="col-1">
        <button
          class="btn btn-danger text-white"
          [disabled]="i === 0"
          type="button"
          (click)="removeAlias(i)"
        >
          <i class="far fa-times"></i>
        </button>
        <button
          class="btn btn-primary"
          *ngIf="i === containerTypeForm.get('aliases')['controls'].length - 1"
          type="button"
          (click)="addAlias()"
        >
          <i class="far fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <button
    class="btn btn-primary text-white"
    type="submit"
    [disabled]="containerTypeForm.invalid || containerTypeForm.pristine"
  >
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span>
      {{ 'Submit' | translate }}
    </span>
  </button>
</form>
