<form (ngSubmit)="onSubmit()" [formGroup]="tariffForm">
  <div class="form-group">
    <div
      class="btn-group btn-group-toggle pb-3"
      ngbRadioGroup
      name="tariffType"
      formControlName="type"
      *ngIf="showTariffTypeButtons"
    >
      <label
        ngbButtonLabel
        data-cy="customerTariff"
        class="btn-outline-primary"
      >
        <input ngbButton type="radio" [value]="'customer'" />
        {{ 'Customer' | translate }}
      </label>
      <label
        ngbButtonLabel
        data-cy="supplierTariff"
        class="btn-outline-primary"
      >
        <input ngbButton type="radio" [value]="'supplier'" />
        {{ 'Supplier' | translate }}
      </label>
    </div>
    <input
      class="form-control"
      formControlName="label"
      data-cy="label"
      placeholder="{{ 'Tariff template name' | translate }}"
      [ngClass]="{
        'is-invalid':
          tariffForm.controls['label'].invalid &&
          tariffForm.controls['label'].touched
      }"
    />
  </div>
  <div id="tariff-items-table">
    <app-tariff-table
      #tariffTable
      [selectedTemplate]="tariff"
      (tariffChanged)="handleTariffChange($event)"
    ></app-tariff-table>
  </div>
  <app-button
    data-cy="submitTariffButton"
    [type]="'button'"
    [btnClass]="'btn-primary text-white'"
    (buttonClicked)="onSubmit()"
    [isLoading]="isLoading"
  >
    <span *ngIf="!tariff">{{ 'Add' | translate }}</span>
    <span *ngIf="tariff">{{ 'Save' | translate }}</span>
  </app-button>
</form>
