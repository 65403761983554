/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../core/admin/settings-card/settings-card.component.ngfactory";
import * as i2 from "../core/admin/settings-card/settings-card.component";
import * as i3 from "@angular/common";
import * as i4 from "./crm.component";
var styles_CrmComponent = [];
var RenderType_CrmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CrmComponent, data: {} });
export { RenderType_CrmComponent as RenderType_CrmComponent };
function View_CrmComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-settings-card", [], null, null, null, i1.View_SettingsCardComponent_0, i1.RenderType_SettingsCardComponent)), i0.ɵdid(1, 49152, null, 0, i2.SettingsCardComponent, [], { route: [0, "route"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CrmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "link-grid-container p-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CrmComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.routes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CrmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-crm", [], null, null, null, View_CrmComponent_0, RenderType_CrmComponent)), i0.ɵdid(1, 49152, null, 0, i4.CrmComponent, [], null, null)], null, null); }
var CrmComponentNgFactory = i0.ɵccf("app-crm", i4.CrmComponent, View_CrmComponent_Host_0, {}, {}, []);
export { CrmComponentNgFactory as CrmComponentNgFactory };
