/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../tax-rate-form/tax-rate-form.component.ngfactory";
import * as i2 from "../tax-rate-form/tax-rate-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "./new-tax-rate.component";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/router";
import * as i7 from "@ngrx/effects";
import * as i8 from "../../services/toast.service";
var styles_NewTaxRateComponent = [];
var RenderType_NewTaxRateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewTaxRateComponent, data: {} });
export { RenderType_NewTaxRateComponent as RenderType_NewTaxRateComponent };
export function View_NewTaxRateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tax-rate-form", [], null, [[null, "taxRateFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("taxRateFormSubmitted" === en)) {
        var pd_0 = (_co.createTaxRate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TaxRateFormComponent_0, i1.RenderType_TaxRateFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.TaxRateFormComponent, [i3.FormBuilder], { isLoading: [0, "isLoading"] }, { taxRateFormSubmitted: "taxRateFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NewTaxRateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-tax-rate", [], null, null, null, View_NewTaxRateComponent_0, RenderType_NewTaxRateComponent)), i0.ɵdid(1, 180224, null, 0, i4.NewTaxRateComponent, [i5.Store, i6.Router, i7.Actions, i8.ToastService], null, null)], null, null); }
var NewTaxRateComponentNgFactory = i0.ɵccf("app-new-tax-rate", i4.NewTaxRateComponent, View_NewTaxRateComponent_Host_0, {}, {}, []);
export { NewTaxRateComponentNgFactory as NewTaxRateComponentNgFactory };
