import { Action } from '@ngrx/store';
import * as types from '../types/registration-request.types';

export class LoadAllRegistrationRequests implements Action {
  readonly type = types.registrationRequests.LOAD_ALL_REGISTRATION_REQUESTS;
  constructor(public payload?: any) {}
}

export class LoadAllRegistrationRequestsSuccess implements Action {
  readonly type = types.registrationRequests.LOAD_ALL_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadAllRegistrationRequestsFailed implements Action {
  readonly type = types.registrationRequests.LOAD_ALL_FAILED;
  constructor(public payload: any) {}
}

export class LoadOneRegistrationRequest implements Action {
  readonly type = types.registrationRequests.LOAD_ONE_REGISTRATION_REQUEST;
  constructor(public payload?: any) {}
}

export class LoadOneRegistrationRequestSuccess implements Action {
  readonly type = types.registrationRequests.LOAD_ONE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadOneRegistrationRequestFailed implements Action {
  readonly type = types.registrationRequests.LOAD_ONE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateRegistrationRequest implements Action {
  readonly type = types.registrationRequests.UPDATE_REGISTRATION_REQUEST;
  constructor(public payload: any) {}
}

export class UpdateRegistrationRequestSuccess implements Action {
  readonly type = types.registrationRequests.UPDATE_REGISTRATION_REQUEST_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateRegistrationRequestFail implements Action {
  readonly type = types.registrationRequests.UPDATE_REGISTRATION_REQUEST_FAILED;
  constructor(public payload: any) {}
}

export class ApproveRegistrationRequest implements Action {
  readonly type = types.registrationRequests.APPROVE_REGISTRATION_REQUEST;
  constructor(public payload: any) {}
}

export class ApproveRegistrationRequestSuccess implements Action {
  readonly type = types.registrationRequests.APPROVE_REGISTRATION_REQUEST_SUCCEEDED;
  constructor(public payload: any) {}
}

export class ApproveRegistrationRequestFail implements Action {
  readonly type = types.registrationRequests.APPROVE_REGISTRATION_REQUEST_FAILED;
  constructor(public payload: any) {}
}

export class DeclineRegistrationRequest implements Action {
  readonly type = types.registrationRequests.DECLINE_REGISTRATION_REQUEST;
  constructor(public payload: any) {}
}

export class DeclineRegistrationRequestSuccess implements Action {
  readonly type = types.registrationRequests.DECLINE_REGISTRATION_REQUEST_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeclineRegistrationRequestFail implements Action {
  readonly type = types.registrationRequests.DECLINE_REGISTRATION_REQUEST_FAILED;
  constructor(public payload: any) {}
}

export type All =
  | LoadAllRegistrationRequests
  | LoadAllRegistrationRequestsSuccess
  | LoadAllRegistrationRequestsFailed
  | LoadOneRegistrationRequest
  | LoadOneRegistrationRequestSuccess
  | LoadOneRegistrationRequestFailed
  | UpdateRegistrationRequest
  | UpdateRegistrationRequestSuccess
  | UpdateRegistrationRequestFail
  | ApproveRegistrationRequest
  | ApproveRegistrationRequestSuccess
  | ApproveRegistrationRequestFail
  | DeclineRegistrationRequest
  | DeclineRegistrationRequestSuccess
  | DeclineRegistrationRequestFail;
