import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cmp-reset-success',
  templateUrl: './reset.success.component.html',
  styleUrls: ['./reset.success.component.scss']
})

export class ResetSuccessComponent {
  constructor( private route: ActivatedRoute ) {}
}
