import { Logout } from '../../store/actions/session.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../../user/user.auth';
import { Store } from '@ngrx/store';
import { State } from '../../store/store.model';

@Injectable()
export class TenantGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private store: Store<State>
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.hasActiveTenant().pipe(map(hasActiveTenant => {
      if (!hasActiveTenant) {
        this.store.dispatch(new Logout());
      }
      return hasActiveTenant;
    }));
  }
}
