<ngx-datatable class="bootstrap row ml-3 mr-3" [rows]="userContexts" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [loadingIndicator]="isLoading" [externalPaging]="true" [count]="totalElements" [limit]="30" (page)="setPage($event)">
  <ngx-datatable-column name="{{ '' | translate }}" prop="enabled" [minWidth]="30" [width]="30" [maxWidth]="30">
    <ng-template ngx-datatable-cell-template let-value="value">
      <span ngbTooltip="{{ value ? 'Active' : 'Disabled' }}" placement="right">
        <i class="far" [ngClass]="{'fa-check-circle text-primary': value, 'fa-times-circle text-danger': !value}"></i>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'First Name' | translate }}" prop="user.firstName"></ngx-datatable-column>
  <ngx-datatable-column name="{{ 'Last Name' | translate }}" prop="user.lastName"></ngx-datatable-column>
  <ngx-datatable-column name="{{ 'Email' | translate }}" prop="user.email"></ngx-datatable-column>
  <ngx-datatable-column *ngIf="driver" name="{{ 'License Plate' | translate }}" prop="licensePlate"></ngx-datatable-column>
  <ngx-datatable-column name="{{ 'Roles' | translate }}" prop="roles">
    <ng-template ngx-datatable-cell-template let-value="value">
      <div *ngFor="let role of value">{{ role.name | translate | titlecase }} </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="" prop="id">
    <ng-template ngx-datatable-cell-template let-value="value">
      <a class="btn btn-sm btn-info" [routerLink]="['/settings', 'users', value, 'edit']">
        {{ 'Edit' | translate }}
      </a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
