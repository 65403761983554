import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/schedule.types';
import * as ScheduleActions from '../actions/schedule.actions';
import { ApiService } from '../../api/api.service';
import { map, concatMap, catchError } from 'rxjs/operators';

@Injectable()
export class ScheduleEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadSchedule$: Observable<Action> = this.actions$.pipe(
    ofType(types.schedule.LOAD_SCHEDULE),
    map((action: ScheduleActions.LoadSchedule) => action.payload),
    concatMap(payload => this.api.get({
      path: `/tms/schedule?from=${payload.fromDate}&to=${payload.toDate}`
    }).pipe(
      map(data => new ScheduleActions.LoadScheduleSuccess(data)),
      catchError(error => of(new ScheduleActions.LoadScheduleFail(error)))
    ))
  );

  @Effect()
  loadUnplannedTransports$: Observable<Action> = this.actions$.pipe(
    ofType(types.schedule.LOAD_UNPLANNED_TRANSPORTS),
    map((action: ScheduleActions.LoadUnplannedTransports) => action.payload),
    concatMap(payload => this.api.get({
      path: `/tms/schedule/unplanned-transports?page=${payload.page ||
        1}&query=${payload.query || ''}&from=${payload.fromDate}&to=${
        payload.toDate
        }`
    }).pipe(
      map(data => new ScheduleActions.LoadUnplannedTransportsSuccess(data)),
      catchError(error => of(new ScheduleActions.LoadUnplannedTransportsFail(error)))
    ))
  );

  @Effect()
  loadNextUnplannedTransportsPage$: Observable<Action> = this.actions$.pipe(
    ofType(types.schedule.LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE),
    map((action: ScheduleActions.LoadNextUnplannedTransportsPage) => action.payload),
    concatMap(payload => this.api.get({
      path: `/tms/schedule/unplanned-transports?page=${payload.page ||
        1}&query=${payload.query || ''}&from=${payload.fromDate}&to=${
        payload.toDate}`
    }).pipe(
      map(data => new ScheduleActions.LoadNextUnplannedTransportsPageSuccess(data)),
      catchError(error => of(new ScheduleActions.LoadNextUnplannedTransportsPageFail(error)))
    ))
  );

  @Effect()
  addToSchedule$: Observable<Action> = this.actions$.pipe(
    ofType(types.schedule.ADD_TO_SCHEDULE),
    map((action: ScheduleActions.AddToSchedule) => action.payload),
    concatMap(payload => this.api.post({
      path: `/tms/schedule/add`,
      body: payload
    }).pipe(
      map(data => new ScheduleActions.AddToScheduleSuccess(data)),
      catchError(error => of(new ScheduleActions.AddToScheduleFail(error)))
    ))
  );

  @Effect()
  removeFromSchedule$: Observable<Action> = this.actions$.pipe(
    ofType(types.schedule.REMOVE_FROM_SCHEDULE),
    map((action: ScheduleActions.RemoveFromSchedule) => action.payload),
    concatMap(payload => this.api.post({
      path: `/tms/schedule/remove`,
      body: payload
    }).pipe(
      map(data => new ScheduleActions.RemoveFromScheduleSuccess(data)),
      catchError(error => of(new ScheduleActions.RemoveFromScheduleFail(error)))
    ))
  );

  @Effect()
  updateScheduleSequence$: Observable<Action> = this.actions$.pipe(
    ofType(types.schedule.UPDATE_SCHEDULE_SEQUENCE),
    map((action: ScheduleActions.UpdateScheduleSequence) => action.payload),
    concatMap(payload => this.api.post({
      path: `/tms/schedule/sequence/update`,
      body: payload
    }).pipe(
      map(data => new ScheduleActions.UpdateScheduleSequenceSuccess(data)),
      catchError(error => of(new ScheduleActions.UpdateScheduleSequenceFail(error)))
    ))
  );
}
