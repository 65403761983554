<div class="row">
  <div class="col">
    <app-transport-task
      [transportTask]="getOriginLocation(transport)"
      [taskType]="'Origin'"
      [taskIcon]="'fa-map-marker-alt'"
      (transportTaskChanged)="handleOriginChange($event)"
    ></app-transport-task>
  </div>
  <div class="col">
    <app-transport-task
      [transportTask]="getDestinationLocation(transport)"
      [taskType]="'Destination'"
      [taskIcon]="'fa-map-marker'"
      (transportTaskChanged)="handleDestinationChange($event)"
    ></app-transport-task>
  </div>
</div>
