import { ToastService } from 'app/services/toast.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../../user/user.auth';

@Injectable()
export class SuperAdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastService
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.getFromStoreOrAPI().pipe(
      map(data => {
        const isSuperAdmin = this.authService.isSuperAdmin(data);
        if (!isSuperAdmin) {
          this.router.navigate(['/dashboard']);
          this.toastr.showDanger({
            title: 'Not allowed',
            message: 'Only Super Admins can access this page.'
          });
        }
        return isSuperAdmin;
      }),
      catchError(() => of(false))
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}
