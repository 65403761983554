import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/product.types';
import * as ProductActions from '../actions/product.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class ProductEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadProducts$: Observable<Action> = this.actions$.pipe(
    ofType(types.product.LOAD_PRODUCTS),
    concatMap(() => this.api.get({
      path: `/crm/products?template=1`
    }).pipe(
      map(data => new ProductActions.LoadProductsSuccess(data)),
      catchError(error => of(new ProductActions.LoadProductsFail(error)))
    )),
  );

  @Effect()
  loadFilteredProducts$: Observable<Action> = this.actions$.pipe(
    ofType(types.product.LOAD_FILTERED_PRODUCTS),
    map((action: ProductActions.LoadFilteredProducts) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      return this.api.get({
        path: `/crm/products?template=1&label=${query}`
      }).pipe(
        map(data => new ProductActions.LoadFilteredProductsSuccess(data)),
        catchError(error => of(new ProductActions.LoadFilteredProductsFail(error)))
      );
    }),
  );

  @Effect()
  createProduct$: Observable<Action> = this.actions$.pipe(
    ofType(types.product.CREATE_PRODUCT),
    map((action: ProductActions.CreateProduct) => action.payload),
    concatMap(payload => this.api.post({
      path: `/crm/products`, body: payload
    }).pipe(
      map(data => new ProductActions.CreateProductSuccess(data)),
      catchError(error => of(new ProductActions.CreateProductFail(error)))
    )),
  );

  @Effect()
  deleteProduct$: Observable<Action> = this.actions$.pipe(
    ofType(types.product.DELETE_PRODUCT),
    map((action: ProductActions.DeleteProduct) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/products/${payload.productId}`
    }).pipe(
      map(() => new ProductActions.DeleteProductSuccess(payload.productId)),
      catchError(error => of(new ProductActions.DeleteProductFail(error)))
    )),
  );

  @Effect()
  updateProduct$: Observable<Action> = this.actions$.pipe(
    ofType(types.product.UPDATE_PRODUCT),
    map((action: ProductActions.UpdateProduct) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/products/${payload.productId}`, body: payload.body
    }).pipe(
      map(data => new ProductActions.UpdateProductSuccess(data)),
      catchError(error => of(new ProductActions.UpdateProductFail(error)))
    )),
  );
}
