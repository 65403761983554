import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import * as moment from 'moment';

@Component({
  selector: 'app-new-certiweight-measurement',
  templateUrl: './new-measurement.component.html'
})
export class NewCertiweightMeasurementComponent {
  isLoading = false;

  constructor(
    private api: ApiService,
    private toastr: ToastService,
    private router: Router,
  ) { }

  onSubmit(value) {
    let data;
    try {
      data = JSON.parse(value.data);
    } catch (e) {
      data = value.data;
    }

    this.isLoading = true;
    this.api.post({
      path: '/container_measurements',
      params: { headers: { 'Content-Type': 'application/ld+json' } },
      body: {
        ...value,
        announcedOn: moment(value.announcedOn).format(),
        weight: value.weight.toString(),
        data: data,
      },
    }).toPromise().then(
      () => {
        this.toastr.showSuccess({
          message: extract('Measurement created'),
        });
        this.router.navigateByUrl('/superadmin/certiweight');
      },
      () => {
        this.toastr.showDanger({
          message: extract('Failed to create measurement'),
        });
        this.isLoading = false;
      },
    );
  }
}
