import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';

import { PsaCompany } from 'app/core/store/models/psa-company.model';

@Component({
  selector: 'app-new-psa-account',
  templateUrl: './new-psa-account.component.html'
})
export class NewPsaAccountComponent {
  form: FormGroup;
  loading = false;

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private toastr: ToastService,
    private router: Router,
  ) {
    this.form = this.fb.group({
      id: [null, Validators.required],
      name: [null, Validators.required],
      vatNumber: [null, Validators.required],
      creditsThreshold: [null, Validators.required],
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.get('id').markAsTouched();
      return;
    }
    this.loading = true;
    this.api.post({
      path: '/psa_companies',
      params: { headers: { 'Content-Type': 'application/ld+json' } },
      body: {
        ...this.form.value,
        creditsThreshold: this.form.value.creditsThreshold.toString(),
        blocked: true,
      },
    }).toPromise().then(
      () => {
        this.toastr.showSuccess({
          message: extract('PSA account created'),
        });
        this.router.navigateByUrl('/superadmin/psa-accounts');
      },
      () => {
        this.toastr.showDanger({
          message: extract('Failed to create PSA account'),
        });
        this.loading = false;
      },
    );
  }
}
