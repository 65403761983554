import { MyPostsNavbarComponent } from '../../marketplace/my-posts/my-posts-navbar/my-posts-navbar.component';
import { MyDemandsComponent } from '../../marketplace/my-posts/my-demands/my-demands.component';
import { MyOffersComponent } from '../../marketplace/my-posts/my-offers/my-offers.component';
import { MyPostsComponent } from '../../marketplace/my-posts/my-posts.component';
import { MarketPostOfferRepliesEffects } from '../store/effects/market-post-offer-replies.effects';
import { MarketPostDemandRepliesEffects } from '../store/effects/market-post-demand-replies.effects';
import { EffectsModule } from '@ngrx/effects';
import { MarketPostOfferRepliesComponent } from './market-post-offer-replies/market-post-offer-replies.component';
import { MarketPostDemandRepliesComponent } from './market-post-demand-replies/market-post-demand-replies.component';
import { CompanyPostsRoutingModule } from './company-posts-routing.module';
import { CompanyPostsComponent } from './company-posts.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    NgxDatatableModule,
    CommonModule,
    SharedModule,
    RouterModule,
    CompanyPostsRoutingModule,
    EffectsModule.forFeature([
      MarketPostDemandRepliesEffects,
      MarketPostOfferRepliesEffects,
    ])
  ],
  declarations: [
    MyPostsComponent,
    MyOffersComponent,
    MyPostsNavbarComponent,
    MyDemandsComponent,
    MarketPostDemandRepliesComponent,
    MarketPostOfferRepliesComponent,
    CompanyPostsComponent,
  ],
})
export class CompanyPostsModule { }
