import { Action } from '@ngrx/store';

import * as types from '../types/circle.types';

export class LoadCircles implements Action {
  readonly type = types.circles.LOAD_CIRCLES;
  constructor(public payload?: any) {}
}

export class LoadCirclesSuccess implements Action {
  readonly type = types.circles.LOAD_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadCirclesFail implements Action {
  readonly type = types.circles.LOAD_FAILED;
  constructor(public payload?: any) {}
}

export class CreateCircle implements Action {
  readonly type = types.circles.CREATE_CIRCLE;
  constructor(public payload: any) {}
}

export class CreateCircleSuccess implements Action {
  readonly type = types.circles.CREATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class CreateCircleFail implements Action {
  readonly type = types.circles.CREATE_FAILED;
  constructor(public payload?: any) {}
}

export class DeleteCircle implements Action {
  readonly type = types.circles.DELETE_CIRCLE;
  constructor(public payload: any) {}
}

export class DeleteCircleSuccess implements Action {
  readonly type = types.circles.DELETE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteCircleFail implements Action {
  readonly type = types.circles.DELETE_FAILED;
  constructor(public payload?: any) {}
}

export class EditCircle implements Action {
  readonly type = types.circles.EDIT_CIRCLE;
  constructor(public payload: any) {}
}

export class EditCircleSuccess implements Action {
  readonly type = types.circles.EDIT_SUCCEEDED;
  constructor(public payload: any) {}
}

export class EditCircleFail implements Action {
  readonly type = types.circles.EDIT_FAILED;
  constructor(public payload?: any) {}
}

export class ClearCircles implements Action {
  readonly type = types.circles.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadCircles
  | LoadCirclesSuccess
  | LoadCirclesFail
  | CreateCircle
  | CreateCircleSuccess
  | ClearCircles
  | DeleteCircle
  | DeleteCircleSuccess
  | DeleteCircleFail
  | EditCircle
  | EditCircleSuccess
  | EditCircleFail;
