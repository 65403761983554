<div class="m-3">
  <div class="card">
    <div class="card-header">
      <h3>{{ 'Create container measurement' | translate }}</h3>
    </div>
    <div class="card-body">
      <app-container-measurement-form
        [isLoading]="isLoading"
        (formSubmitted)="onSubmit($event)"
      ></app-container-measurement-form>
    </div>
  </div>
</div>
