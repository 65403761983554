import * as types from '../types/shipping-agent.types';
import * as ShippingAgentActions from '../actions/shipping-agent.actions';

const INITIAL_STATE = null;

export function reducer(
  state = INITIAL_STATE,
  action: ShippingAgentActions.All
) {
  switch (action.type) {
    case types.shippingAgent.LOAD_SHIPPING_AGENTS_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}
