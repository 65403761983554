import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
})
export class AddressModalComponent implements OnDestroy {
  countries: any[];

  constructor(private store: Store<State>, private modal: NgbActiveModal) {
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countriesState => {
        if (!countriesState) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countriesState];
      });
  }

  createAddress(addressFormValue) {
    this.modal.close(addressFormValue);
  }

  ngOnDestroy() { }
}
