import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/matching.types';
import * as MatchingActions from '../actions/matching.actions';
import { ApiService } from '../../api/api.service';
import { map, concatMap, catchError } from 'rxjs/operators';

@Injectable()
export class MatchingEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadActiveMatchingPoolItems$: Observable<Action> = this.actions$.pipe(
    ofType(types.matching.LOAD_ACTIVE_MATCHING_POOL_ITEMS),
    concatMap(() => this.api.get({
      path: `/matching/my_matching_pool_items`
    }).pipe(
      map(data => new MatchingActions.LoadActiveMatchingPoolItemsSuccess(data)),
      catchError(error => of(new MatchingActions.LoadActiveMatchingPoolItemsFail(error))),
    ))
  );

  @Effect()
  loadNextActiveMatchingPoolItemsPage$: Observable<Action> = this.actions$.pipe(
    ofType(types.matching.LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE),
    map((action: MatchingActions.LoadNextActiveMatchingPoolItemsPage) => action.payload),
    concatMap(payload => {
      const page = payload && payload.page ? payload.page : 1;
      return this.api.get({
        path: `/matching/my_matching_pool_items?page=${page}`
      }).pipe(
        map(data => new MatchingActions.LoadNextActiveMatchingPoolItemsPageSuccess(data)),
        catchError(error => of(new MatchingActions.LoadNextActiveMatchingPoolItemsPageFail(error))),
      );
    })
  );

  @Effect()
  enableMatching$: Observable<Action> = this.actions$.pipe(
    ofType(types.matching.ENABLE_MATCHING),
    map((action: MatchingActions.EnableMatching) => action.payload),
    concatMap(payload => this.api.post({
      path: `/matching/${payload.type}/${payload.id}/enabled`
    }).pipe(
      map(data => new MatchingActions.EnableMatchinSuccess(data)),
      catchError(error => of(new MatchingActions.EnableMatchinFail(error)))
    ))
  );

  @Effect()
  disableMatching$: Observable<Action> = this.actions$.pipe(
    ofType(types.matching.DISABLE_MATCHING),
    map((action: MatchingActions.DisableMatching) => action.payload),
    concatMap(payload => this.api.post({
      path: `/matching/${payload.type}/${payload.id}/disabled`
    }).pipe(
      map(data => new MatchingActions.DisableMatchinSuccess(data)),
      catchError(error => of(new MatchingActions.DisableMatchinFail(error)))
    ))
  );

  @Effect()
  createMatchReply$: Observable<Action> = this.actions$.pipe(
    ofType(types.matching.CREATE_MATCH_REPLY),
    map((action: MatchingActions.CreateMatchReply) => action.payload),
    concatMap(payload => this.api.post({
      path: `/matching_pool_item_match_replies`,
      body: payload
    }).pipe(
      map(data => new MatchingActions.CreateMatchReplySuccess(data)),
      catchError(error => of(new MatchingActions.CreateMatchReplyFail(error))),
    ))
  );

  @Effect()
  deleteMatchOption$: Observable<Action> = this.actions$.pipe(
    ofType(types.matching.DELETE_MATCHING_POOL_OPTION),
    map((action: MatchingActions.DeleteMatchingPoolOption) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/matching_pool_item_matches/${payload.matchOptionId}`
    }).pipe(
      map(() => new MatchingActions.DeleteMatchingPoolOptionSuccess(payload)),
      catchError(error => of(new MatchingActions.DeleteMatchingPoolOptionFail(error)))
    ))
  );
}
