import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { catchError, map, scan, startWith, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';
import { ContainerMeasurement } from 'app/core/store/models/container-measurement.model';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'app-manage-certiweight-measurements',
  templateUrl: './manage-measurements.component.html',
})
export class ManageCertiweightMeasurementsComponent {
  companies$: Observable<ContainerMeasurement[]>;
  deletes$ = new Subject<string>();
  loading = false;

  constructor(
    private api: ApiService,
    private toastr: ToastService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.loading = true;
    this.companies$ = this.api.get({ path: '/container_measurements' }).pipe(
      map(x => {
        this.loading = false;
        return <ContainerMeasurement[]>x['hydra:member'];
      }),
      catchError(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to load container measurements'),
        });
        return [];
      }),
      switchMap(cs => this.deletes$.pipe(
        scan(
          (data: ContainerMeasurement[], deletedId: string) =>
            data.filter(company => company.id !== deletedId),
          cs,
        ),
        startWith(cs),
      )),
    );
  }

  edit(id) {
    this.router.navigate(['superadmin/certiweight', id, 'edit']);
  }

  delete(id: string) {
    const modal = this.modalService.open(ConfirmActionModalComponent);
    modal.componentInstance.message = extract(
      'Are you sure you want to permanently delete this measurement?'
    );
    modal.componentInstance.confirmButtonText = extract('Delete');
    modal.result.then(() => this.api.delete({
      path: `/container_measurements/${id}`,
      params: { headers: { 'Content-Type': 'application/ld+json' } },
    }).toPromise()).then(
      () => {
        this.toastr.showSuccess({
          message: extract('Measurement deleted'),
        });
        this.deletes$.next(id);
      },
      (err) => {
        this.toastr.showSuccess({
          title: extract('Something went wrong'),
          message: err,
        });
      },
    );
  }
}
