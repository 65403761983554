import { DownloadService } from 'app/services/download.service';
import { ToastService } from 'app/services/toast.service';
import {
  LoadInvoices,
  UpdateInvoice,
  LoadFilteredInvoices,
  DeleteInvoice
} from 'app/core/store/actions/invoice.actions';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { State } from 'app/core/store/store.model';
import { PaymentNoteStates, PaymentNote } from 'app/core/store/models/payment-note.model';
import { extract } from 'app/services/i18n.service';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/invoice.types';
import { Router } from '@angular/router';
import * as DateFns from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, OnDestroy {
  title = extract('Invoices');
  invoices: PaymentNote[];
  filteredInvoices: PaymentNote[];
  isLoadingInvoices: boolean;
  totalElements = 0;
  InvoiceStates = PaymentNoteStates;
  tableMessages = {
    emptyMessage: extract('No invoices to display'),
    totalMessage: extract('total')
  };
  searchPlaceholder = extract('Search customer name or invoice number');
  query: any;
  page: any;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router,
    private downloadService: DownloadService,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.invoices &&
          state.crm.invoices.invoices
        ) {
          return state.crm.invoices['invoices'];
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(invoicesState => {
        if (!invoicesState) {
          return;
        }
        this.invoices = [...invoicesState];
        this.isLoadingInvoices = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.invoices &&
          state.crm.invoices.filteredInvoices
        ) {
          return state.crm.invoices['filteredInvoices'];
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(filteredInvoices => {
        if (!filteredInvoices) {
          return;
        }
        this.filteredInvoices = [...filteredInvoices];
        this.isLoadingInvoices = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.invoices &&
          state.crm.invoices.totalFilteredInvoices
        ) {
          return state.crm.invoices.totalFilteredInvoices;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(totalElements => {
        if (!totalElements) {
          this.totalElements = 0;
          return;
        }
        this.totalElements = totalElements;
      });
    this.updates$
      .pipe(ofType(Types.invoice.UPDATE_INVOICE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update state')
        });
      });
    this.updates$
      .pipe(ofType(Types.invoice.UPDATE_INVOICE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingInvoices = false;
        this.toastr.showSuccess({
          message: extract('State updated successfully')
        });
      });
    this.updates$
      .pipe(ofType(Types.invoice.LOAD_INVOICES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingInvoices = false;
        this.toastr.showDanger({
          message: extract('Failed to load invoices')
        });
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadInvoices());
    this.isLoadingInvoices = true;
  }

  getDaysLeft(dueDate) {
    return DateFns.differenceInCalendarDays(dueDate, new Date());
  }
  handleSearch(query, page?) {
    const p = page ? page : 1;
    this.isLoadingInvoices = true;
    if (!query) {
      this.query = null;
      this.store.dispatch(new LoadInvoices({ page: p }));
      return;
    }
    this.query = query;
    this.store.dispatch(new LoadFilteredInvoices({ query: query, page: p }));
  }

  editInvoice(invoiceId: string) {
    this.router.navigateByUrl(`/crm/invoices/${invoiceId}/edit`);
  }

  createCreditNote(invoiceId: string) {
    this.router.navigateByUrl(`/crm/credit-notes/new?id=${invoiceId}`);
  }

  downloadInvoicePDF(invoiceId: string, refNumber: string) {
    this.downloadService.downloadPDF(`/crm/invoice/download/${invoiceId}`, refNumber);
  }

  deleteInvoice(invoiceId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure that you want to permanently delete this invoice?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteInvoice({ invoiceId: invoiceId }));
    });
  }

  updateInvoiceState(id: string, state: string) {
    this.store.dispatch(new UpdateInvoice({
      invoiceId: id,
      body: { id, state },
    }));
  }

  setPage($event) {
    this.page = $event.offset + 1;
    this.handleSearch(this.query, this.page);
  }

  getInvoiceStateIcon(state) {
    switch (state) {
      case this.InvoiceStates.PAID:
        return 'fa-check';
      case this.InvoiceStates.SENT:
        return 'fa-envelope';
      case this.InvoiceStates.REMINDED:
        return 'fa-bell';
      case this.InvoiceStates.DRAFT:
        return 'fa-file-edit';
      default:
        break;
    }
  }

  ngOnDestroy() { }
}
