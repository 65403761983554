import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { catchError, map, scan, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { AccountActionHistoryComponent } from './account-action-history.component';
import { EditCreditThresholdComponent } from './edit-credit-threshold.component';
var ManagePsaAccountsComponent = /** @class */ (function () {
    function ManagePsaAccountsComponent(api, toastr, modalService, location, fb) {
        var _this = this;
        this.api = api;
        this.toastr = toastr;
        this.modalService = modalService;
        this.location = location;
        this.fb = fb;
        this.modifyEvents$ = new Subject();
        this.loadingReport = false;
        this.loading = false;
        this.loading = true;
        this.companies$ = this.api.get({ path: '/psa_companies' }).pipe(map(function (x) {
            _this.loading = false;
            return x['hydra:member'];
        }), catchError(function () {
            _this.toastr.showDanger({
                title: extract('Something went wrong'),
                message: extract('Failed to load PSA companies'),
            });
            return [];
        }), switchMap(function (cs) { return _this.modifyEvents$.pipe(scan(function (companies, _a) {
            var modifiedId = _a[0], modification = _a[1];
            return companies.map(function (company) {
                if (company.id === modifiedId) {
                    return Object.assign({}, company, modification);
                }
                return company;
            });
        }, cs), startWith(cs)); }));
        if (!this.reportForm) {
            this.createForm();
        }
    }
    ManagePsaAccountsComponent.prototype.editCreditThreshold = function (row) {
        var _this = this;
        var modalRef = this.modalService.open(EditCreditThresholdComponent);
        modalRef.componentInstance.credits = row.creditsThreshold;
        modalRef.result.then(function (creditsThreshold) { return _this.api.put({
            path: "/psa_companies/" + row.id,
            body: { creditsThreshold: creditsThreshold },
            params: { headers: { 'Content-Type': 'application/ld+json' } },
        }).toPromise().then(function () { return _this.modifyEvents$.next([row.id, { creditsThreshold: creditsThreshold }]); }, function () { return _this.toastr.showDanger({
            title: extract('Something went wrong'),
            message: extract('Failed to modify credits threshold'),
        }); }); });
    };
    ManagePsaAccountsComponent.prototype.showActionHistory = function (row) {
        var modalRef = this.modalService.open(AccountActionHistoryComponent);
        modalRef.componentInstance.account = row;
    };
    ManagePsaAccountsComponent.prototype.unlink = function (row) {
        var _this = this;
        var modalRef = this.modalService.open(ConfirmActionModalComponent);
        modalRef.componentInstance.message = extract('This action will unlink the PSA account from the Hakka account and send a message to the terminal to block the account');
        modalRef.componentInstance.confirmButtonText = extract('Unlink account');
        modalRef.result.then(function () { return _this.api.post({
            path: '/unlink-psa-account',
            body: { id: row.id },
            params: { headers: { 'Content-Type': 'application/ld+json' } },
        }).toPromise().then(function () { return _this.modifyEvents$.next([row.id, { tenant: null, blocked: true }]); }, function () { return _this.toastr.showDanger({
            title: extract('Something went wrong'),
            message: extract('Failed to unlink tenant from PSA company'),
        }); }); });
    };
    ManagePsaAccountsComponent.prototype.navigateBack = function () {
        this.location.back();
    };
    ManagePsaAccountsComponent.prototype.createForm = function () {
        var today = new Date();
        var weekAgo = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
        this.reportForm = this.fb.group({
            sinceDate: [weekAgo, Validators.required],
            untilDate: [today, Validators.required]
        });
    };
    ManagePsaAccountsComponent.prototype.onReportFormSubmit = function () {
        var _this = this;
        if (this.reportForm.valid) {
            var since = moment(this.reportForm.value.sinceDate).format('YYYY-MM-DD');
            var until = moment(this.reportForm.value.untilDate).format('YYYY-MM-DD');
            if (since > until) {
                this.reportForm.get('untilDate').setErrors({ 'incorrect': true });
                ;
                return;
            }
            this.loadingReport = true;
            this.api.get({ path: '/export/psa-transactions-report/csv?since=' + since + '&until=' + until }, {
                'Content-Type': 'application/json',
                Accept: 'text/csv'
            }, 'blob').subscribe(function (data) {
                fileSaver.saveAs(new Blob([data], { type: 'text/csv' }), 'psa_transactions_report_' + moment().format('DD_MM_YYYY') + '.csv');
                _this.loadingReport = false;
            }, function () {
                _this.loadingReport = false;
                _this.toastr.showDanger({
                    title: 'Something went wrong',
                    message: 'Download failed'
                });
            });
        }
        else {
            this.reportForm.get('sinceDate').markAsTouched();
            this.reportForm.get('untilDate').markAsTouched();
        }
    };
    return ManagePsaAccountsComponent;
}());
export { ManagePsaAccountsComponent };
