import { Injectable } from '@angular/core';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/contacts.types';
import * as ContactsActions from '../actions/contacts.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class ContactsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadContacts$: Observable<Action> = this.actions$.pipe(
    ofType(types.contacts.LOAD_CONTACTS),
    map((action: ContactsActions.LoadContacts) => action.payload),
    concatMap(() => this.api.get({ path: '/contacts' }).pipe(
      map(data => new ContactsActions.LoadContactsSuccess(data)),
      catchError(error => of(new ContactsActions.LoadContactsSuccess(error)))
    )),
  );
}
