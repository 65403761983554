var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import * as types from '../types/user.types';
import * as UserActions from '../actions/user.actions';
import * as RouterActions from '../actions/router.actions';
var UserEffects = /** @class */ (function () {
    function UserEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadUser$ = this.actions$.pipe(ofType(types.users.LOAD_USER), concatMap(function () { return _this.api.get({ path: '/me/user_context' }).pipe(map(function (data) { return new UserActions.LoadUserSuccess(data); }), catchError(function (error) { return of(new UserActions.LoadUserFailed(error)); })); }));
        /**
         * When the user requests a reset password link
         *
         * @type {Observable<Action>}
         * @memberOf UserEffects
         */
        this.resetUser$ = this.actions$.pipe(ofType(types.users.RESET), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user/reset',
            body: JSON.stringify(payload),
            params: { responseType: 'text' }
        }).pipe(map(function (data) { return new UserActions.ResetUserSuccess(data); }), catchError(function (error) { return of(new UserActions.ResetUserFailed(error)); })); }));
        this.resetUserUpdate$ = this.actions$.pipe(ofType(types.users.RESET_UPDATE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user/' + payload.user + '/code/' + payload.code,
            body: JSON.stringify({ password: payload.password })
        }).pipe(map(function (data) { return new UserActions.ResetUserUpdateSuccess(data); }), catchError(function (error) { return of(new UserActions.ResetUserUpdateFailed(error)); })); }));
        this.ResetUserSuccess$ = this.actions$.pipe(ofType(types.users.RESET_SUCCEEDED), concatMap(function () { return of(new RouterActions.Go({ path: ['user/reset/success'] })); }));
        this.updateUser$ = this.actions$.pipe(ofType(types.users.UPDATE_USER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/users/' + payload.id, body: payload.body
        }).pipe(map(function (data) { return new UserActions.UpdateUserSuccess(data); }), catchError(function (error) { return of(new UserActions.UpdateUserFailed(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "loadUser$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "resetUser$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "resetUserUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "ResetUserSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "updateUser$", void 0);
    return UserEffects;
}());
export { UserEffects };
