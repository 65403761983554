/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../invoice-form/invoice-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../shared/datepicker-config";
import * as i4 from "../invoice-form/invoice-form.component";
import * as i5 from "../../services/icon.service";
import * as i6 from "@angular/forms";
import * as i7 from "./edit-invoice.component";
import * as i8 from "@angular/router";
import * as i9 from "@ngrx/effects";
import * as i10 from "@ngrx/store";
import * as i11 from "../../services/download.service";
import * as i12 from "../../services/toast.service";
var styles_EditInvoiceComponent = [];
var RenderType_EditInvoiceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditInvoiceComponent, data: {} });
export { RenderType_EditInvoiceComponent as RenderType_EditInvoiceComponent };
export function View_EditInvoiceComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-invoice-form", [], null, [[null, "invoiceFormSubmitted"], [null, "onSaveAndDownloadAsPDF"], [null, "searchTransports"], [null, "selectTransport"], [null, "removeTransport"], [null, "customerSelected"], [null, "loadNextTransportPage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("invoiceFormSubmitted" === en)) {
        var pd_0 = (_co.updateInvoice($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSaveAndDownloadAsPDF" === en)) {
        var pd_1 = (_co.updateAndDownloadAsPDF($event) !== false);
        ad = (pd_1 && ad);
    } if (("searchTransports" === en)) {
        var pd_2 = (_co.handleSearchTransports($event) !== false);
        ad = (pd_2 && ad);
    } if (("selectTransport" === en)) {
        var pd_3 = (_co.handleSelectTransport($event) !== false);
        ad = (pd_3 && ad);
    } if (("removeTransport" === en)) {
        var pd_4 = (_co.handleRemoveTransport($event) !== false);
        ad = (pd_4 && ad);
    } if (("customerSelected" === en)) {
        var pd_5 = (_co.handleSelectCustomer($event) !== false);
        ad = (pd_5 && ad);
    } if (("loadNextTransportPage" === en)) {
        var pd_6 = (_co.loadNextTransportsPage() !== false);
        ad = (pd_6 && ad);
    } return ad; }, i1.View_InvoiceFormComponent_0, i1.RenderType_InvoiceFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i2.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 573440, null, 0, i4.InvoiceFormComponent, [i5.IconService, i6.FormBuilder, i2.NgbModal], { customers: [0, "customers"], isLoadingCustomers: [1, "isLoadingCustomers"], taxRates: [2, "taxRates"], isLoadingTaxRates: [3, "isLoadingTaxRates"], isLoading: [4, "isLoading"], transports: [5, "transports"], invoice: [6, "invoice"] }, { invoiceFormSubmitted: "invoiceFormSubmitted", onSaveAndDownloadAsPDF: "onSaveAndDownloadAsPDF", searchTransports: "searchTransports", selectTransport: "selectTransport", removeTransport: "removeTransport", customerSelected: "customerSelected", loadNextTransportPage: "loadNextTransportPage" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customers; var currVal_1 = _co.isLoadingCustomers; var currVal_2 = _co.taxRates; var currVal_3 = _co.isLoadingTaxRates; var currVal_4 = _co.isLoading; var currVal_5 = _co.filteredTransports; var currVal_6 = _co.invoice; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_EditInvoiceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-invoice", [], null, null, null, View_EditInvoiceComponent_0, RenderType_EditInvoiceComponent)), i0.ɵdid(1, 180224, null, 0, i7.EditInvoiceComponent, [i8.ActivatedRoute, i8.Router, i9.Actions, i10.Store, i11.DownloadService, i12.ToastService], null, null)], null, null); }
var EditInvoiceComponentNgFactory = i0.ɵccf("app-edit-invoice", i7.EditInvoiceComponent, View_EditInvoiceComponent_Host_0, {}, {}, []);
export { EditInvoiceComponentNgFactory as EditInvoiceComponentNgFactory };
