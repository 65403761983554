import { Tenant } from './tenant.model';
import { MatchingPoolItem } from './matching.model';
import { Transport } from './transport.model';
import { MarketPostOffer } from './market-post-offer.model';

export interface ContainerType {
  '@id': string;
  '@type': string;
  label: string;
  length: string;
  aliases: any[];
  id: string;
  code: string;
  reefer: boolean;
  deleted?: boolean;
}

export interface Liner {
  '@id': string;
  '@type': string;
  name: string;
  shortName: string;
  id: string;
  code: string;
  deleted: boolean;
}

export interface Container {
  '@id': string;
  '@type': string;
  number?: any;
  containerType: ContainerType;
  liner: Liner;
  weight?: any;
  adr: boolean;
  id: string;
  deleted: boolean;
}

export interface TransportType {
  '@id': string;
  '@type': string;
  label: string;
  id: string;
  code: string;
  deleted: boolean;
}

export interface LocationType {
  '@id': string;
  '@type': string;
  label: string;
  id: string;
  code: string;
  deleted: boolean;
}

export interface OriginLocation {
  '@id': string;
  '@type': string;
  publicLocation?: any;
  privateLocations: any[];
  name: string;
  shortName: string;
  fullAddress: string;
  locationType: LocationType;
  latitude: string;
  longitude: string;
  extraData: any[];
  connector?: any;
  id: string;
  visibility: string;
  deleted: boolean;
}

export interface DestinationLocation {
  '@id': string;
  '@type': string;
  publicLocation?: any;
  privateLocations: any[];
  name: string;
  shortName: string;
  fullAddress: string;
  locationType: LocationType;
  latitude: string;
  longitude: string;
  extraData: any[];
  connector?: any;
  id: string;
  visibility: string;
  deleted: boolean;
}

export interface MarketPostDemand {
  '@id': string;
  '@type': string;
  primaryTaskDate: Date;
  container: Container;
  transportType: TransportType;
  originLocation: OriginLocation;
  originTimeIntervalStart: Date;
  originTimeIntervalStartTimeSpecified: boolean;
  originTimeIntervalEnd?: any;
  originTimeIntervalEndTimeSpecified: boolean;
  destinationLocation: DestinationLocation;
  destinationTimeIntervalStart: Date;
  destinationTimeIntervalStartTimeSpecified: boolean;
  destinationTimeIntervalEnd?: any;
  destinationTimeIntervalEndTimeSpecified: boolean;
  marketPostDemandProposals: any[];
  genset?: boolean;
  comment: string;
  reference: string;
  quantity: number;
  id: string;
  deleted: boolean;
}

export interface Match {
  '@id': string;
  '@type': string;
  transport?: any;
  marketPostDemand: MarketPostDemand;
  marketPostOffer?: any;
  match?: any;
  matchOptions: MatchOption[];
  matchedAt?: any;
  active: boolean;
  subjectDeleted?: boolean;
  id: string;
  tenant: Tenant;
}

export interface MatchOption {
  matchingPoolItem: MatchingPoolItem;
  visible?: boolean;
  match: Match;
  confidence: string;
  scenarioId: string;
  subjectDeleted: boolean;
  id: string;
  '@id': string;
}

export interface MatchingPoolItem {
  '@id': string;
  '@type': string;
  transport?: Transport;
  marketPostDemand?: MarketPostDemand;
  marketPostOffer?: MarketPostOffer;
  match?: any;
  matchOptions: MatchOption[];
  matchedAt?: any;
  active: boolean;
  showAll?: boolean;
  showMatches?: boolean;
  visible?: boolean;
  id: string;
  tenant: Tenant;
}

export enum MatchingTypes {
  TRANSPORT = 'transport',
  MARKET_POST_OFFER = 'market_post_offer',
  MARKET_POST_DEMAND = 'market_post_demand'
}
