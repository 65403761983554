import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as DriverTypes from 'app/core/store/types/driver.types';
import {
  LoadDrivers,
  DeleteDriver,
  EnableDriver,
  DisableDriver
} from 'app/core/store/actions/driver.actions';
import { extract } from 'app/services/i18n.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-driver-list',
  templateUrl: 'driver-list.component.html',
  styleUrls: ['./driver-list.component.scss']
})
export class DriverListComponent implements OnInit, OnDestroy {
  title = extract('Manage Drivers');
  drivers;
  totalElements = 0;
  isLoadingDrivers: boolean;
  isLoading = false;
  actionRow: number;
  page: any;

  constructor(
    private store: Store<State>,
    private router: Router,
    private updates$: Actions,
    private toastService: ToastService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.loadDrivers();
    this.store
      .select(state => state.drivers)
      .pipe(untilDestroyed(this))
      .subscribe(userContexts => {
        if (userContexts['drivers']) {
          this.drivers = userContexts['drivers'];
          this.totalElements = +userContexts['totalElements'] || 0;
          this.isLoadingDrivers = false;
        }
      });
    this.updates$
      .pipe(ofType(DriverTypes.drivers.DELETE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastService.showSuccess({
          title: extract('Delete driver successfull')
        });
        this.isLoading = false;
        this.actionRow = null;
        this.loadDrivers();
      });
    this.updates$
      .pipe(ofType(DriverTypes.drivers.ENABLE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.actionRow = null;
        this.toastService.showSuccess({
          title: extract('Enable driver successfull')
        });
        this.loadDrivers();
      });
    this.updates$
      .pipe(ofType(DriverTypes.drivers.DISABLE_DRIVER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastService.showSuccess({
          title: extract('Disable driver successfull')
        });
        this.isLoading = false;
        this.actionRow = null;
        this.loadDrivers();
      });
    this.updates$
      .pipe(ofType(
        DriverTypes.drivers.DISABLE_DRIVER_FAILED,
        DriverTypes.drivers.ENABLE_DRIVER_FAILED,
        DriverTypes.drivers.DELETE_DRIVER_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.actionRow = null;
      });
  }

  goToCreate() {
    this.router.navigateByUrl('/tms/drivers/new');
  }

  edit(userContext) {
    this.router.navigateByUrl('/tms/drivers/' + userContext.id + '/edit');
  }
  setPage($event) {
    this.page = $event.offset + 1;
    this.loadDrivers();
  }

  delete(userContext, rowIndex) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this driver?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteDriver(userContext.id));
      this.actionRow = rowIndex;
      this.isLoading = true;
    });
  }

  enable(userContext, rowIndex) {
    this.store.dispatch(new EnableDriver(userContext.id));
    this.actionRow = rowIndex;
    this.isLoading = true;
  }

  disable(userContext, rowIndex) {
    this.store.dispatch(new DisableDriver(userContext.id));
    this.actionRow = rowIndex;
    this.isLoading = true;
  }

  loadDrivers() {
    this.store.dispatch(
      new LoadDrivers({
        includeSuperAdmins: 0,
        roleName: 'DRIVER',
        page: this.page || 1
      })
    );
    this.isLoadingDrivers = true;
  }

  ngOnDestroy() { }
}
