<div class="login-background fullscreen overflow-sm-x-hidden overflow-y">
  <div class="row justify-content-center mt-5 mb-5">
    <div class="col-md-6">
      <div class="card">
        <div class="card-title card-title-bb">
          <h5>
            <strong>{{ 'Choose a company' | translate }}</strong>
          </h5>
          <i class="far fa-info-circle"></i>
          <span> {{ 'Your account is linked to multiple companies. Pick the company you want to activate.' | translate }}</span>
          <app-cmp-loading [loading]="loading"></app-cmp-loading>
        </div>
        <app-cmp-tenant-list [flush]="true" [tenants]="tenants" (pick)="pickTenant($event)"></app-cmp-tenant-list>
        <div class="card-footer text-muted text-right">
          <button (click)="logout()" class="btn btn-small btn-default">{{ 'Logout' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
