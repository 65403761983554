/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../tariff-form/tariff-form.component.ngfactory";
import * as i2 from "../tariff-form/tariff-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "./edit-tariff.component";
import * as i5 from "@angular/router";
import * as i6 from "@ngrx/effects";
import * as i7 from "@ngrx/store";
import * as i8 from "../../core/api/api.service";
import * as i9 from "../../services/toast.service";
var styles_EditTariffComponent = [];
var RenderType_EditTariffComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditTariffComponent, data: {} });
export { RenderType_EditTariffComponent as RenderType_EditTariffComponent };
export function View_EditTariffComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-tariff-form", [], null, [[null, "tariffFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tariffFormSubmitted" === en)) {
        var pd_0 = (_co.editTariff($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TariffFormComponent_0, i1.RenderType_TariffFormComponent)), i0.ɵdid(2, 638976, null, 0, i2.TariffFormComponent, [i3.FormBuilder], { isLoading: [0, "isLoading"], tariff: [1, "tariff"] }, { tariffFormSubmitted: "tariffFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = _co.tariff; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EditTariffComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-tariff", [], null, null, null, View_EditTariffComponent_0, RenderType_EditTariffComponent)), i0.ɵdid(1, 180224, null, 0, i4.EditTariffComponent, [i5.ActivatedRoute, i5.Router, i6.Actions, i7.Store, i8.ApiService, i9.ToastService], null, null)], null, null); }
var EditTariffComponentNgFactory = i0.ɵccf("app-edit-tariff", i4.EditTariffComponent, View_EditTariffComponent_Host_0, {}, {}, []);
export { EditTariffComponentNgFactory as EditTariffComponentNgFactory };
