import { Location } from 'app/core/store/models/location.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent {
  @Input()
  location: Location;
  @Input()
  type: string;
  @Input()
  timeIntervalStart: string;
  @Input()
  timeIntervalEnd: string;
  @Input()
  timeIntervalStartTimeSpecified: boolean;
  @Input()
  timeIntervalEndTimeSpecified: boolean;

  getIconClass() {
    return this.type === 'origin' ? 'far' : 'fas';
  }
}
