import { MatchingTypes } from 'app/core/store/models/matching.model';
import { IconService } from 'app/services/icon.service';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { UnpublishMarketPostDemand } from 'app/core/store/actions/market-post-demand.actions';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/marketplace.types';
import * as MatchTypes from 'app/core/store/types/matching.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-market-post-demand',
  templateUrl: 'market-post-demand.component.html',
})
export class MarketPostDemandComponent implements OnDestroy {
  @Input() marketPost: any;
  @Input()
  hasActiveMatchingSubscription: boolean;

  @Output()
  enableMatching = new EventEmitter();
  @Output()
  disableMatching = new EventEmitter();

  MatchingTypes = MatchingTypes;
  tenantId: string;
  loading: boolean;
  isTogglingMatch: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private postUpdates: Actions,
    private iconService: IconService
  ) {
    store
      .select(state => state.session)
      .pipe(untilDestroyed(this))
      .subscribe(session => {
        if (session) {
          this.tenantId = session.tenant;
        }
      });
    this.postUpdates
      .pipe(ofType(Types.marketposts.UNPUBLISH_MARKET_POST_DEMAND_SUCCESS))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
      });
    this.postUpdates
      .pipe(ofType(
        MatchTypes.matching.ENABLE_MATCHING_SUCCEEDED,
        MatchTypes.matching.DISABLE_MATCHING_SUCCEEDED,
        MatchTypes.matching.ENABLE_MATCHING_FAILED,
        MatchTypes.matching.DISABLE_MATCHING_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isTogglingMatch = false;
      });
  }

  ngOnDestroy() { }

  editPost() {
    this.router.navigate([
      `marketplace/${this.marketPost.marketPostType}s/${
      this.marketPost._id
      }/edit`
    ]);
  }

  getTypeIcon(transportType: TransportType) {
    return this.iconService.getTransportTypeIcon(transportType);
  }

  unpublishPost() {
    this.loading = true;
    this.store.dispatch(
      new UnpublishMarketPostDemand({
        id: this.marketPost._id
      })
    );
  }

  handleEnableMatching() {
    this.enableMatching.emit({
      id: this.marketPost.id,
      type: this.MatchingTypes.MARKET_POST_DEMAND
    });
    this.isTogglingMatch = true;
  }
  handleDisableMatchin() {
    this.disableMatching.emit({
      id: this.marketPost.id,
      type: this.MatchingTypes.MARKET_POST_DEMAND
    });
    this.isTogglingMatch = true;
  }
}
