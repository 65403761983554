<form (ngSubmit)="submit()" [formGroup]="form" class="pb-3 card-body">

  <small class="text-muted">{{ 'Container number' | translate }}</small>
  {{ data.containerNumber }}<br>

  <small class="text-muted">{{ 'Seal number' | translate }}</small>
  {{ data.sealNumber }}<br>

  <small class="text-muted">{{ 'Weighing location' | translate }}</small>
  {{ data.site ? data.site.name : '' }}<br>

  <small class="text-muted">{{ 'Weighing time' | translate }}</small>
  {{ data.announcedOn | amCalendar }}<br>

  <small class="text-muted">{{ 'Price' | translate }}</small>
  {{ (data.site && data.site.servicePrice || defaultPrice) | currency: 'EUR':'code' }}<br>
  <br>

  <div class="form-group">
    <label class="center-block">{{ 'Liner' | translate }}</label>
    <ng-select
      id="liner"
      [items]="liners"
      bindLabel="shortName"
      bindValue="@id"
      placeholder="{{ 'Select liner' | translate }}"
      formControlName="liner"
    ></ng-select>
  </div>

  <div class="form-group">
    <label class="center-block">{{ 'Booking reference' | translate }}</label>
    <input
      class="form-control" formControlName="bookingReference"
      [ngClass]="{'is-invalid': form.controls.bookingReference.invalid && form.controls.bookingReference.touched}"
    />
    <div class="invalid-feedback">{{ 'Booking reference is required.' | translate }}</div>
  </div>

  <div class="form-group">
    <label class="center-block">{{ 'Client reference' | translate }}</label>
    <input autofocus
      class="form-control" formControlName="clientReference"
      [ngClass]="{'is-invalid': form.controls.clientReference.invalid && form.controls.clientReference.touched}"
    />
    <div class="invalid-feedback">{{ 'Client reference is required.' | translate }}</div>
  </div>

  <fieldset formGroupName="shipperContact">
    <legend>{{ 'Shipper' | translate }}</legend>
    <div class="form-row">
      <div class="col-6">
        <div class="form-group">
          <label class="center-block">{{ 'Shipper contact person' | translate }}</label>
          <input
            class="form-control" formControlName="name"
            [ngClass]="{'is-invalid': form.get('shipperContact.name').invalid && form.get('shipperContact.name').touched}"
          />
          <div class="invalid-feedback">{{ 'Shipper contact person is required.' | translate }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="center-block">{{ 'Shipper company' | translate }}</label>
          <input
            class="form-control" formControlName="company"
            [ngClass]="{'is-invalid': form.get('shipperContact.company').invalid && form.get('shipperContact.company').touched}"
          />
          <div class="invalid-feedback">{{ 'Shipper company is required.' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="center-block">{{ 'Address' | translate }}</label>
      <input
        class="form-control" formControlName="address"
        [ngClass]="{'is-invalid': form.get('shipperContact.address').invalid && form.get('shipperContact.address').touched}"
      />
      <div class="invalid-feedback">{{ 'Shipper address is required.' | translate }}</div>
    </div>
  </fieldset>

  <div class="form-check">
    <input id="tac" type="checkbox" class="form-check-input" formControlName="acceptTC" />
    <label for="tac" class="center-block">
      {{ 'I accept the' | translate }}
      <a href="javascript:void(0)" (click)="showTaC()">{{ 'Terms and Conditions' | translate }}</a>
    </label>
  </div>

  <div class="form-check">
    <input id="vgm" type="checkbox" class="form-check-input" formControlName="sendVGM" />
    <label for="vgm" class="center-block">{{ 'Send VGM data to carrier' | translate }}</label>
  </div>

  <button class="btn btn-primary text-white" type="submit"
    [disabled]="form.invalid || !form.value.acceptTC">
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span> {{ 'Buy' | translate }} </span>
  </button>
</form>
