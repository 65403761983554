import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { LoadDefaultCertificatePrice } from 'app/core/store/actions/certiweight.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { State } from 'app/core/store/store.model';
import { AuthService } from 'app/core/user/user.auth';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-buy-certificate-form',
  templateUrl: './buy-certificate-form.component.html',
})
export class BuyCertificateFormComponent implements OnInit, OnDestroy {
  @Input() loading;
  @Input() data;
  @Output() onSubmit = new EventEmitter<any>();

  form: FormGroup;
  liners: any[];
  defaultPrice: number;
  tenant: any;
  user: any;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private toastr: ToastService,
    private authService: AuthService,
  ) {
    this.store
      .select('certiweight')
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.defaultPrice) {
          this.store.dispatch(new LoadDefaultCertificatePrice());
          return;
        }
        this.defaultPrice = result.defaultPrice;
      });
    this.store.select('marketplace', 'liners')
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (!liners) {
          this.store.dispatch(new LoadLiners());
          return;
        }
        this.liners = liners;
      });
    this.store.select('user', 'user')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.user = state;
          this.patchForm();
        }
      });
    this.store.select('tenants', 'userTenants')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.tenant = state.find(x => x.id === this.authService.getActiveTenant());
          this.patchForm();
        }
      });
  }

  ngOnInit() {
    this.form = this.fb.group({
      liner: [this.data.liner['@id'], Validators.required],
      bookingReference: [this.data.bookingReference, Validators.required],
      clientReference: [''],
      sendVGM: [true],
      acceptTC: [null, Validators.required],
      shipperContact: this.fb.group({
        name: [null, Validators.required],
        company: [null, Validators.required],
        address: [null, Validators.required],
      }),
    });
    this.patchForm();
  }

  ngOnDestroy() { }

  patchForm() {
    if (!this.form || !this.tenant || !this.user) {
      return;
    }
    const tenant = this.tenant;
    this.form.patchValue({
      shipperContact: {
        name: `${this.user.firstName} ${this.user.lastName}`,
        company: tenant.name,
        address: `${tenant.streetAndNumber || ''}, ${tenant.zipcode || ''} ${tenant.city || ''}, ${tenant.country || ''}`,
      },
    });
  }

  showTaC() {
    this.toastr.showInfo({
      title: 'Not implemented yet',
    });
  }

  submit() {
    this.onSubmit.emit({
      ...this.form.value,
      liner: this.form.value.liner.replace('/api/v1/liners/', ''),
      clientReference: this.form.value.clientReference || '',
    });
  }
}
