import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as RolesActions from '../../store/actions/roles.actions';
import * as types from '../types/role.types';
import * as NotificationsActions from '../actions/notifications.actions';

@Injectable()
export class RolesEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadRoles$: Observable<Action> = this.actions$.pipe(
    ofType(types.roles.LOAD_ROLES),
    concatMap(() => this.api.get({ path: '/roles' }).pipe(
      map(data => new RolesActions.LoadRolesSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );
}
