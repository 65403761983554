<div class="m-3">
  <div
    ngbRadioGroup="ngbRadioGroup"
    [ngModel]="locationType$ | async"
    (ngModelChange)="locationType$.next($event)"
    name="locationTypeRadio"
    ngDefaultControl="ngDefaultControl"
    class="d-none d-md-flex btn-group btn-group-toggle justify-content-center form-group"
  >
    <label ngbButtonLabel="ngbButtonLabel" class="btn-outline-primary">
      <input
        ngbButton="ngbButton"
        type="radio"
        [value]="locationTypes.OTHER" />
      <span> {{ locationTypes.OTHER | titlecase | translate }} </span>
    </label>
    <label ngbButtonLabel="ngbButtonLabel" class="btn-outline-primary">
      <input
        ngbButton="ngbButton"
        type="radio"
        [value]="locationTypes.DEPOT" />
      <span> {{ locationTypes.DEPOT | titlecase | translate }} </span>
    </label>
    <label ngbButtonLabel="ngbButtonLabel" class="btn-outline-primary">
      <input
        ngbButton="ngbButton"
        type="radio"
        [value]="locationTypes.TERMINAL"
      />
      <span> {{ locationTypes.TERMINAL | titlecase | translate }} </span>
    </label>
    <label ngbButtonLabel="ngbButtonLabel" class="btn-outline-primary">
      <input
              ngbButton="ngbButton"
              type="radio"
              [value]="locationTypes.WEIGHT"
      />
      <span> {{ locationTypes.WEIGHT| titlecase | translate }} </span>
    </label>
  </div>
  <ngx-datatable
    class="bootstrap ml-0 mr-0"
    [rows]="locations$ | async"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [selectionType]="undefined"
    [externalPaging]="false"
    [count]="(locations$ | async)?.length"
    [limit]="30"
    [loadingIndicator]="loading"
  >
    <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
    <ngx-datatable-column
      name="Short Name"
      prop="shortName"
    ></ngx-datatable-column>
    <ngx-datatable-column
      name="Address"
      prop="fullAddress"
    ></ngx-datatable-column>
    <ngx-datatable-column name="" prop="">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <button class="btn btn-info" (click)="edit(row.id)">
          <i class="far fa-pencil"></i> <span>{{ 'Edit' | translate }}</span>
        </button>
        <button class="btn" (click)="deleteLocation(row.id)">
          <i class="far fa-trash-alt"></i>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
