import {
  LoadProducts,
  LoadFilteredProducts,
  DeleteProduct
} from 'app/core/store/actions/product.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product, ProductTypes } from 'app/core/store/models/product.model';
import { extract } from 'app/services/i18n.service';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import * as Types from 'app/core/store/types/product.types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss']
})
export class TariffsComponent implements OnInit, OnDestroy {
  filteredTariffs: Product[];
  isLoadingTariffs: boolean;
  totalElements: number;

  searchPlaceholder = extract('Search name or tariff template');
  isLoading: boolean;
  query: any;
  filteredCustomerTariffs: Product[];
  filteredSupplierTariffs: Product[];

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.products &&
          state.crm.products.filteredCustomerProducts
        ) {
          return state.crm.products.filteredCustomerProducts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(tariffsState => {
        if (!tariffsState) {
          return;
        }
        this.filteredCustomerTariffs = [
          ...tariffsState.filter(t => t.type === ProductTypes.CUSTOMER)
        ];
        this.isLoadingTariffs = false;
      });

    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.products &&
          state.crm.products.filteredSupplierProducts
        ) {
          return state.crm.products.filteredSupplierProducts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(tariffsState => {
        if (!tariffsState) {
          return;
        }
        this.filteredSupplierTariffs = [
          ...tariffsState.filter(t => t.type === ProductTypes.SUPPLIER)
        ];
        this.isLoadingTariffs = false;
      });

    this.updates$
      .pipe(ofType(Types.product.LOAD_PRODUCTS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          message: extract('Failed to load tariffs')
        });
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadProducts());
    this.isLoadingTariffs = true;
  }

  handleSearch(query) {
    if (!query) {
      this.store.dispatch(new LoadProducts());
      return;
    }
    this.query = query;
    this.isLoadingTariffs = true;
    this.store.dispatch(new LoadFilteredProducts({ query: query }));
  }
  handleDeleteTariff(tariffId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this tariff?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteProduct({ productId: tariffId }));
    });
  }

  ngOnDestroy() { }
}
