import { LocationType } from '../models/location-type.model';
import * as types from '../types/location.types';
import * as LocationActions from '../actions/location.actions';
import { Location } from '../models/location.model';

export interface LocationState {
  locations: Location[];
  addressBookLocations: Location[];
  prenotifiableLocations: Location[];
  locationTypes: LocationType[];
  totalElements: any;
}

const INITIAL_STATE: LocationState = {
  locations: null,
  addressBookLocations: null,
  prenotifiableLocations: null,
  locationTypes: null,
  totalElements: null
};

export function LocationsReducer(
  state = INITIAL_STATE,
  action: LocationActions.All
): LocationState {
  switch (action.type) {
    case types.location.LOAD_LOCATIONS_BY_LOCATION_TYPE_SUCCEEDED:
    case types.location.LOAD_LOCATIONS_SUCCEEDED:
      state.locations = [...action.payload['hydra:member']];
      state.totalElements = action.payload['hydra:totalItems'];
      return Object.assign({}, state);
    case types.location.LOAD_ADDRESS_BOOK_LOCATIONS_SUCCEEDED:
      state.addressBookLocations = [...action.payload];
      return Object.assign({}, state);
    case types.location.CREATE_USER_LOCATION_SUCCEEDED:
      if (state.addressBookLocations) {
        state.addressBookLocations = [
          ...state.addressBookLocations,
          action.payload
        ];
      } else {
        state.addressBookLocations = [action.payload];
      }
      return Object.assign({}, state);
    case types.location.DELETE_USER_LOCATION_SUCCEEDED:
      state.addressBookLocations = state.addressBookLocations.filter(
        (location: Location) => location.id !== action.payload
      );
      return Object.assign({}, state);
    case types.location.LOAD_PRENOTIFIABLE_LOCATIONS_SUCCEEDED:
      state.prenotifiableLocations = [...action.payload['hydra:member']];
      return Object.assign({}, state);
    case types.location.UPDATE_USER_LOCATION_SUCCEEDED:
      if (!state.addressBookLocations) {
        state.addressBookLocations = [action.payload];
        return Object.assign({}, state);
      }
      state.addressBookLocations = state.addressBookLocations.map(item => {
        return item.id === action.payload.id
          ? Object.assign({}, item, action.payload)
          : item;
      });
      return Object.assign({}, state);
    case types.location.LOAD_LOCATION_TYPES_SUCCEEDED:
      state.locationTypes = [...action.payload['hydra:member']];
      return Object.assign({}, state);
    case types.location.CLEAR_ADDRESS_BOOK_LOCATIONS:
      state.addressBookLocations = null;
      return Object.assign({}, state);
  }
  return state;
}
