import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { COUNTRIES } from 'app/constants';
import { LoadTenants, UpdateTenant } from 'app/core/store/actions/tenants.actions';
import { LoadUser } from 'app/core/store/actions/user.actions';
import { TenantSettings } from 'app/core/store/models/tenant-settings.model';
import * as Types from 'app/core/store/types/tenant.types';
import { State } from 'app/core/store/store.model';
import { AuthService } from 'app/core/user/user.auth';
import { I18nService, extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-tenant-information',
  templateUrl: 'tenant-information.component.html',
  styleUrls: ['tenant-information.component.scss']
})
export class TenantInformationComponent implements OnInit, OnDestroy {
  title = extract('Company Information');
  tenantSettings$: TenantSettings[];
  tenantSettingsForm: FormGroup;
  currentTenant: {
    id: string;
    name: string;
  };
  loading = true;
  submitting = false;
  languages;
  countries = COUNTRIES;

  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
    private authService: AuthService,
    private tenantInformationUpdates$: Actions,
    private toastr: ToastService,
    private router: Router,
    private i18nService: I18nService,
  ) {
    this.languages = this.i18nService.languages;

    tenantInformationUpdates$
      .pipe(ofType(Types.tenants.UPDATE_TENANT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.submitting = false;
        this.toastr.showSuccess({
          message: 'Update company successful'
        });
        this.store.dispatch(new LoadTenants());
        this.router.navigateByUrl('/settings');
      });

    tenantInformationUpdates$
      .pipe(ofType(Types.tenants.UPDATE_TENANT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.submitting = false;
        this.toastr.showSuccess({
          title: 'Something went wrong',
          message: 'Update company failed'
        });
      });
    // get tenant from usercontext.
    this.store
      .select(state => state.user.id)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (!state) {
          this.store.dispatch(new LoadUser());
        }
      });
  }

  ngOnInit() {
    this.createForm();
    this.store
      .select('tenants', 'userTenants')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state && !this.currentTenant) {
          const t = state.find(
            tenant => tenant.id === this.authService.getActiveTenant()
          );
          if (t) {
            this.loading = false;
            this.currentTenant = {
              id: t.id,
              name: t.name
            };
            this.createForm();
            this.tenantSettingsForm.patchValue(t);
          }
        } else if (!state) {
          this.store.dispatch(new LoadTenants());
        }
      });
  }

  createForm() {
    this.tenantSettingsForm = this.fb.group({
      vatNumber: { value: null, disabled: true },
      name: [null],
      email: [null, [Validators.required, Validators.email]],
      invoiceEmail: [null, [Validators.required, Validators.email]],
      bankAccountNumber: [null],
      phoneNumber: [null, Validators.required],
      streetAndNumber: null,
      zipcode: null,
      city: null,
      locale: [null, Validators.required],
      country: [null, Validators.required]
    });
  }

  onSubmit() {
    this.store.dispatch(
      new UpdateTenant({
        id: this.currentTenant.id,
        body: this.tenantSettingsForm.value
      })
    );
  }

  ngOnDestroy() { }
}
