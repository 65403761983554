<div class="card-header bg-info text-white d-flex justify-content-between">
  <div *ngIf="marketPost.quantity > 1">
    <span class="badge badge-dark" ngbTooltip="{{ 'Quantity' | translate }}">{{
      marketPost.quantity
    }}</span>
  </div>
  <div *ngIf="marketPost.container.liner">
    <span class="icon"> <i class="far fa-truck"></i> </span>
    {{ marketPost?.container?.liner?.name }}
  </div>
  <div *ngIf="marketPost.container.containerType">
    <span class="icon"> <i class="far fa-balance-scale"></i> </span>
    {{ marketPost?.container?.containerType?.label }}
  </div>
  <div>
    <span class="icon">
      <i class="far" [ngClass]="getTypeIcon(marketPost?.transportType)"></i>
    </span>
    <span>{{ marketPost?.transportType?.label | titlecase | translate }}</span>
  </div>
</div>
<div class="card-body market-post-card__body">
  <div class="d-flex justify-content-between">
    <span
      class="icon"
      *ngIf="marketPost.genset"
      ngbTooltip="{{ 'Genset' | translate }}"
    >
      <i class="far fa-snowflake"></i>
    </span>
    <span
      *ngIf="marketPost.container.adr"
      class="icon"
      ngbTooltip="{{ 'ADR' | translate }}"
    >
      <i class="far fa-exclamation-triangle text-danger"></i>
    </span>
  </div>
  <div class="d-flex justify-content-between">
    <span>{{ 'Name' | translate }}</span>
    <span>{{ marketPost?.tenant?.name | translate }}</span>
  </div>
  <div class="d-flex justify-content-between">
    <span>{{ 'Type' | translate }}</span>
    <span>{{ marketPost?.marketPostType | titlecase | translate }}</span>
  </div>
  <div
    *ngIf="marketPost?.container?.weight"
    class="d-flex justify-content-between"
  >
    <span>{{ 'Weight' | translate }}</span>
    <span>{{ marketPost?.container?.weight || 'N.A.' }}</span>
  </div>
  <div *ngIf="marketPost?.reference" class="d-flex justify-content-between">
    <span>{{ 'Reference' | translate }}</span>
    <span>{{ marketPost?.reference || 'N.A.' }}</span>
  </div>
  <div *ngIf="marketPost?.comment" class="d-flex justify-content-between mr-1">
    <span>{{ 'Comment' | translate }}</span>
    <p class="comment-field" style="max-width:60%">{{ marketPost?.comment }}</p>
  </div>
  <div>
    <hr />
    <div *ngFor="let task of marketPost?.tasks">
      <div
        class="d-flex justify-content-between"
        *ngIf="(task.location && task.location.name) || task.startDate"
      >
        <span>
          <span
            class="icon"
            ngbTooltip="{{ task.taskType?.label | translate | titlecase }}"
            placement="right"
          >
            <i class="far" [ngClass]="getTaskIcon(task)"></i>
          </span>
          <span>{{
            task.location.shortName
              ? task.location.shortName
              : task.location.name
          }}</span>
        </span>
        <span *ngIf="task.startDate; else noStartDate">
          {{ task.startDate | date: 'dd/MM' }}
          <span *ngIf="task.startDateTimeSpecified">
            {{ task.startDate | date: 'HH:mm' }}
          </span>
          <span
            *ngIf="
              (task.endDate && task.endDate !== task.startDate) ||
              task.endDateTimeSpecified
            "
          >
            -
          </span>
          <span *ngIf="task.endDate && task.endDate !== task.startDate">
            {{ task.endDate | date: 'dd/MM' }}
          </span>
          <span *ngIf="task.endDate && task.endDateTimeSpecified">
            {{ task.endDate | date: 'HH:mm' }}
          </span>
        </span>
        <ng-template #noStartDate>
          <span *ngIf="task.endDate">
            {{ task.endDate | date: 'dd/MM' }}
            <span *ngIf="task.endDateTimeSpecified">
              {{ task.endDate | date: 'HH:mm' }}
            </span>
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="card-footer market-post-card__footer">
  <div class="d-flex reply-container" *ngIf="marketPost.tenant.id !== tenantId">
    <a [routerLink]="['/marketplace/offers', marketPost?._id, 'reply']">
      <button
        class="btn btn-sm btn-primary reply-button text-white button-block"
      >
        <span class="icon"> <i class="far fa-reply"></i> </span>
        <span>{{ 'Reply' | translate }}</span>
      </button>
    </a>
  </div>
  <div class="reply-container" *ngIf="marketPost.tenant.id === tenantId">
    <app-button
      [isLoading]="isTogglingMatch"
      [btnClass]="'mb-2'"
      *ngIf="hasActiveMatchingSubscription && marketPost?.matching"
      (click)="handleDisableMatching()"
      [type]="'button'"
    >
      <i class="far fa-fw fa-bolt"></i>
      <span>{{ 'Disable matching' | translate }}</span>
    </app-button>
    <app-button
      [isLoading]="isTogglingMatch"
      [btnClass]="'btn-success mb-2'"
      *ngIf="hasActiveMatchingSubscription && !marketPost?.matching"
      (click)="handleEnableMatching()"
      [type]="'button'"
    >
      <i class="far fa-fw fa-bolt"></i>
      <span>{{ 'Enable matching' | translate }}</span>
    </app-button>
    <div class="d-flex justify-content-between">
      <button
        class="btn btn-sm mr-2 btn-warning reply-button text-black button-block"
        (click)="editPost()"
      >
        <span class="icon"> <i class="far fa-pencil"></i> </span>
        <span>{{ 'Edit' | translate }}</span>
      </button>
      <button
        class="btn btn-sm btn-danger reply-button text-white button-block"
        (click)="unpublishPost()"
      >
        <span class="icon" *ngIf="loading">
          <i class="far fa-spinner fa-spin"></i>
        </span>
        <span class="icon" *ngIf="!loading">
          <i class="far fa-trash"></i>
        </span>
        <span>{{ 'Delete' | translate }}</span>
      </button>
    </div>
  </div>
</div>
