import { Action } from '@ngrx/store';
import * as types from '../types/notification-subscription.types';

export class LoadNotificationSubscriptions implements Action {
  readonly type =
    types.notificationSubscriptions.LOAD_NOTIFICATION_SUBSCRIPTIONS;
  constructor(public payload?: any) {}
}

export class LoadNotificationSubscriptionsSuccess implements Action {
  readonly type =
    types.notificationSubscriptions.LOAD_NOTIFICATION_SUBSCRIPTIONS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadNotificationSubscriptionsFailed implements Action {
  readonly type =
    types.notificationSubscriptions.LOAD_NOTIFICATION_SUBSCRIPTIONS_FAILED;
  constructor(public payload?: any) {}
}

export class EnableTransportTypeNotificationSubscription implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION;
  constructor(public payload: any) {}
}
export class EnableTransportTypeNotificationSubscriptionSuccess
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED;
  constructor(public payload: any) {}
}
export class EnableTransportTypeNotificationSubscriptionFailed
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED;
  constructor(public payload: any) {}
}
export class DisableTransportTypeNotificationSubscription implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION;
  constructor(public payload: any) {}
}
export class DisableTransportTypeNotificationSubscriptionSuccess
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED;
  constructor(public payload: any) {}
}
export class DisableTransportTypeNotificationSubscriptionFailed
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED;
  constructor(public payload: any) {}
}
export class EnableContainerTypeNotificationSubscription implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION;
  constructor(public payload: any) {}
}
export class EnableContainerTypeNotificationSubscriptionSuccess
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED;
  constructor(public payload: any) {}
}
export class EnableContainerTypeNotificationSubscriptionFailed
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED;
  constructor(public payload: any) {}
}
export class DisableContainerTypeNotificationSubscription implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION;
  constructor(public payload: any) {}
}
export class DisableContainerTypeNotificationSubscriptionSuccess
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED;
  constructor(public payload: any) {}
}
export class DisableContainerTypeNotificationSubscriptionFailed
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED;
  constructor(public payload: any) {}
}

export class EnableMessageBoardMessagesNotificationSubscription
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION;
  constructor(public payload?: any) {}
}

export class EnableMessageBoardMessagesNotificationSubscriptionSuccess
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class EnableMessageBoardMessagesNotificationSubscriptionFail
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_FAILED;
  constructor(public payload?: any) {}
}

export class DisableMessageBoardMessagesNotificationSubscription
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION;
  constructor(public payload?: any) {}
}

export class DisableMessageBoardMessagesNotificationSubscriptionSuccess
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class DisableMessageBoardMessagesNotificationSubscriptionFail
  implements Action {
  readonly type =
    types.notificationSubscriptions
      .DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_FAILED;
  constructor(public payload?: any) {}
}
export type All =
  | LoadNotificationSubscriptions
  | LoadNotificationSubscriptionsSuccess
  | LoadNotificationSubscriptionsFailed
  | EnableTransportTypeNotificationSubscription
  | EnableTransportTypeNotificationSubscriptionSuccess
  | EnableTransportTypeNotificationSubscriptionFailed
  | DisableTransportTypeNotificationSubscription
  | DisableTransportTypeNotificationSubscriptionSuccess
  | DisableTransportTypeNotificationSubscriptionFailed
  | EnableContainerTypeNotificationSubscription
  | EnableContainerTypeNotificationSubscriptionSuccess
  | EnableContainerTypeNotificationSubscriptionFailed
  | DisableContainerTypeNotificationSubscription
  | DisableContainerTypeNotificationSubscriptionSuccess
  | DisableContainerTypeNotificationSubscriptionFailed
  | EnableMessageBoardMessagesNotificationSubscription
  | EnableMessageBoardMessagesNotificationSubscription
  | EnableMessageBoardMessagesNotificationSubscriptionSuccess
  | EnableMessageBoardMessagesNotificationSubscriptionFail
  | DisableMessageBoardMessagesNotificationSubscription
  | DisableMessageBoardMessagesNotificationSubscriptionSuccess
  | DisableMessageBoardMessagesNotificationSubscriptionFail;
