var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import * as moment from 'moment';
var EditCertiweightMeasurementComponent = /** @class */ (function () {
    function EditCertiweightMeasurementComponent(api, toastr, router, route) {
        var _this = this;
        this.api = api;
        this.toastr = toastr;
        this.router = router;
        this.route = route;
        this.isLoading = false;
        this.resetErrors();
        this.route.paramMap.subscribe(function (data) {
            if (data) {
                _this.id = data.get('id');
                _this.api.get({
                    path: "/container_measurements/" + _this.id,
                }).toPromise().then(function (response) {
                    _this.data = response;
                    _this.resetErrors();
                    _this.checkLiner();
                    _this.checkSite();
                    _this.checkTerminal();
                });
            }
        });
    }
    EditCertiweightMeasurementComponent.prototype.onSubmit = function (value) {
        var _this = this;
        var data;
        try {
            data = JSON.parse(value.data);
        }
        catch (e) {
            data = value.data;
        }
        this.isLoading = true;
        this.api.put({
            path: "/container_measurements/" + this.id,
            params: { headers: { 'Content-Type': 'application/ld+json' } },
            body: __assign({}, value, { announcedOn: moment(value.announcedOn).format(), weight: value.weight.toString(), data: data }),
        }).toPromise().then(function () {
            _this.toastr.showSuccess({
                message: extract('Measurement updated'),
            });
            _this.router.navigateByUrl('/superadmin/certiweight');
        }, function (err) {
            _this.toastr.showDanger({
                title: extract('Failed to update measurement'),
                message: err,
            });
            _this.isLoading = false;
        });
    };
    EditCertiweightMeasurementComponent.prototype.checkSite = function () {
        if (!this.data.site) {
            this.errors.site.push('Measurement doesnt have site!');
        }
        return false;
    };
    EditCertiweightMeasurementComponent.prototype.checkLiner = function () {
        if (this.data.liner && !this.data.liner.aliases.find(function (a) { return a.code === 'SCAC'; })) {
            this.errors.liner.push('Liner has no SCAC alias!');
        }
        return false;
    };
    EditCertiweightMeasurementComponent.prototype.checkTerminal = function () {
        if (this.data.terminal && !this.data.terminal.aliases.find(function (a) { return a.code === 'SCAC'; })) {
            this.errors.terminal.push('Terminal has no SCAC alias!');
        }
        return false;
    };
    EditCertiweightMeasurementComponent.prototype.resetErrors = function () {
        this.errors = {
            liner: [],
            site: [],
            terminal: []
        };
    };
    return EditCertiweightMeasurementComponent;
}());
export { EditCertiweightMeasurementComponent };
