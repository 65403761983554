<style>
  .my-drop-zone {
    border: dotted 3px lightgray;
  }

  .nv-file-over {
    border: dotted 3px red;
  }

  /* Default class applied to drop zones on over */
  .another-file-over-class {
    border: dotted 3px green;
  }

  html,
  body {
    height: 100%;
  }
</style>

<div class="container">
  <div *ngIf="errorMessage" class="row">
    <div class="col">
      <ngb-alert (close)="this.errorMessage = null">{{
        errorMessage
      }}</ngb-alert>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div
        ng2FileDrop
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        class="dropzone"
      >
        <span class="dropzone--title">
          {{ 'Drag and drop files here to upload' | translate }}
        </span>
      </div>
      <div class="mt-1 mb-3">
        <span>Or click here to select a file</span>&nbsp;
        <input type="file" ng2FileSelect [uploader]="uploader" multiple />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" style="margin-bottom: 40px">
      <table class="table">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td>
              <strong>{{ item?.file?.name }}</strong>
            </td>
            <td class="text-center">
              <span *ngIf="item.isSuccess"
                ><i class="far fa-fw fa-check-circle text-success"></i
              ></span>
              <span *ngIf="item.isCancel"
                ><i class="far fa-fw fa-ban text-warning"></i
              ></span>
              <span *ngIf="item.isError"
                ><i class="far fa-fw fa-trash-alt text-danger"></i
              ></span>
            </td>
            <td nowrap>
              <button
                type="button"
                class="btn btn-success btn-sm"
                (click)="item.upload()"
                [disabled]="item.isReady || item.isUploading || item.isSuccess"
              >
                <span class="far fa-fw fa-upload"></span> Upload
              </button>
              <button
                type="button"
                class="btn btn-warning btn-sm ml-1 mr-1"
                (click)="item.cancel()"
                [disabled]="!item.isUploading"
              >
                <span class="far fa-fw fa-ban"></span> Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm"
                (click)="item.remove(); removeItem(item)"
              >
                <span class="far fa-fw fa-trash-alt"></span> Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <div>
          Queue progress:
          <div class="progress" style="">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{ width: uploader.progress + '%' }"
            ></div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success"
          (click)="uploader.uploadAll()"
          [disabled]="!uploader.getNotUploadedItems().length"
        >
          <span class="far fa-fw fa-upload"></span> Upload all
        </button>
        <button
          type="button"
          class="btn btn-warning ml-1 mr-1"
          (click)="uploader.cancelAll()"
          [disabled]="!uploader.isUploading"
        >
          <span class="far fa-fw fa-ban"></span> Cancel all
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="uploader.clearQueue()"
          [disabled]="!uploader.queue.length"
        >
          <span class="far fa-fw fa-trash-alt"></span> Remove all
        </button>
      </div>
    </div>
  </div>
</div>
