import { IconService } from 'app/services/icon.service';
import { Autocomplete } from 'app/services/autocomplete.service';
import { FormBuilder, Validators } from '@angular/forms';
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as DateFns from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// rxjs
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LocationService } from 'app/services/location.service';
var NewMarketpostDemandComponent = /** @class */ (function () {
    function NewMarketpostDemandComponent(fb, autocompleteService, locationService, modalService, iconService) {
        var _this = this;
        this.fb = fb;
        this.autocompleteService = autocompleteService;
        this.locationService = locationService;
        this.modalService = modalService;
        this.iconService = iconService;
        this.transportDemandAdded = new EventEmitter();
        this.searchContainerType = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.autocompleteService.search('container_type', term);
            }));
        };
        this.formatter = function (x) { return x.label; };
        // Autocomplete liner
        this.searchLiner = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.autocompleteService.search('liner', term);
            }));
        };
        this.locationFormatter = function (x) {
            return x.name;
        };
        this.searchLocation = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), switchMap(function (term) {
                return _this.locationService.location(term);
            }));
        };
    }
    NewMarketpostDemandComponent.prototype.ngOnInit = function () {
        if (!this.newMarketpostDemandForm || !this.newMarketpostDemandForm.value) {
            this.createForm();
        }
    };
    NewMarketpostDemandComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.transportDemand && changes.transportDemand.currentValue) {
            if (!this.newMarketpostDemandForm ||
                !this.newMarketpostDemandForm.value) {
                this.createForm();
            }
            var demand_1 = changes.transportDemand.currentValue;
            // patch form
            if (typeof demand_1.transportType === 'string') {
                this.newMarketpostDemandForm
                    .get('transportType')
                    .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === demand_1.transportType; }));
            }
            else {
                this.newMarketpostDemandForm
                    .get('transportType')
                    .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === demand_1.transportType['@id']; }));
            }
            this.newMarketpostDemandForm.patchValue({
                originLocation: demand_1.originLocation,
                destinationLocation: demand_1.destinationLocation,
                container: {
                    weight: demand_1.container.weight ? demand_1.container.weight : null,
                    adr: demand_1.container.adr
                },
                reference: demand_1.reference,
                comment: demand_1.comment,
                genset: demand_1.genset,
                quantity: demand_1.quantity
            });
            if (demand_1.container.liner) {
                this.newMarketpostDemandForm.get('container').patchValue({
                    liner: this.liners.find(function (liner) { return liner['@id'] === demand_1.container.liner; }).id
                });
            }
            else {
                this.newMarketpostDemandForm.get('container').patchValue({
                    liner: null
                });
            }
            if (demand_1.container.containerType) {
                this.newMarketpostDemandForm.get('container').patchValue({
                    containerType: this.containerTypes.find(function (ct) { return ct['@id'] === demand_1.container.containerType; }).id
                });
            }
            else {
                this.newMarketpostDemandForm.get('container').patchValue({
                    containerType: null
                });
            }
            if (demand_1.originTimeIntervalStart) {
                this.newMarketpostDemandForm
                    .get('originStartDate')
                    .patchValue(new Date(moment(demand_1.originTimeIntervalStart).year(), moment(demand_1.originTimeIntervalStart).month(), moment(demand_1.originTimeIntervalStart).date()));
                if (demand_1.originTimeIntervalStartTimeSpecified) {
                    this.newMarketpostDemandForm.get('originStartTime').patchValue({
                        hour: moment(demand_1.originTimeIntervalStart).hours(),
                        minute: moment(demand_1.originTimeIntervalStart).minutes()
                    });
                }
            }
            if (demand_1.originTimeIntervalEnd) {
                this.newMarketpostDemandForm
                    .get('originEndDate')
                    .patchValue(new Date(moment(demand_1.originTimeIntervalEnd).year(), moment(demand_1.originTimeIntervalEnd).month(), moment(demand_1.originTimeIntervalEnd).date()));
                if (demand_1.originTimeIntervalEndTimeSpecified) {
                    this.newMarketpostDemandForm.get('originEndTime').patchValue({
                        hour: moment(demand_1.originTimeIntervalEnd).hours(),
                        minute: moment(demand_1.originTimeIntervalEnd).minutes()
                    });
                }
            }
            if (demand_1.destinationTimeIntervalStart) {
                this.newMarketpostDemandForm
                    .get('destinationStartDate')
                    .patchValue(new Date(moment(demand_1.destinationTimeIntervalStart).year(), moment(demand_1.destinationTimeIntervalStart).month(), moment(demand_1.destinationTimeIntervalStart).date()));
                if (demand_1.destinationTimeIntervalStartTimeSpecified) {
                    this.newMarketpostDemandForm.get('destinationStartTime').patchValue({
                        hour: moment(demand_1.destinationTimeIntervalStart).hours(),
                        minute: moment(demand_1.destinationTimeIntervalStart).minutes()
                    });
                }
            }
            if (demand_1.destinationTimeIntervalEnd) {
                this.newMarketpostDemandForm
                    .get('destinationEndDate')
                    .patchValue(new Date(moment(demand_1.destinationTimeIntervalEnd).year(), moment(demand_1.destinationTimeIntervalEnd).month(), moment(demand_1.destinationTimeIntervalEnd).date()));
                if (demand_1.destinationTimeIntervalEndTimeSpecified) {
                    this.newMarketpostDemandForm.get('destinationEndTime').patchValue({
                        hour: moment(demand_1.destinationTimeIntervalEnd).hours(),
                        minute: moment(demand_1.destinationTimeIntervalEnd).minutes()
                    });
                }
            }
        }
        if (changes.transportTypes && changes.transportTypes.currentValue) {
            if (!this.transportDemand ||
                !this.newMarketpostDemandForm ||
                !this.newMarketpostDemandForm.value) {
                this.createForm();
            }
            else if (this.transportDemand) {
                if (typeof this.transportDemand.transportType === 'string') {
                    this.newMarketpostDemandForm
                        .get('transportType')
                        .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === _this.transportDemand.transportType; }));
                }
                else {
                    this.newMarketpostDemandForm
                        .get('transportType')
                        .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === _this.transportDemand.transportType['@id']; }));
                }
            }
        }
    };
    NewMarketpostDemandComponent.prototype.createForm = function () {
        this.newMarketpostDemandForm = this.fb.group({
            container: this.fb.group({
                containerType: [, Validators.required],
                liner: [, Validators.required],
                weight: [, this.numberValidator(/^((\d+\.?|\.(?=\d))?\d{0,3})$/)],
                adr: [false]
            }),
            transportType: [this.transportTypes[0] || ''],
            originLocation: [],
            originStartDate: [],
            originStartTime: [],
            originEndDate: [],
            originEndTime: [],
            originTimeIntervalStartTimeSpecified: [false],
            originTimeIntervalEndTimeSpecified: [false],
            destinationLocation: [],
            destinationStartDate: [],
            destinationStartTime: [],
            destinationEndDate: [],
            destinationEndTime: [],
            destinationTimeIntervalStartTimeSpecified: [false],
            destinationTimeIntervalEndTimeSpecified: [false],
            comment: [''],
            reference: [''],
            quantity: [1, Validators.min(0)]
        });
    };
    NewMarketpostDemandComponent.prototype.onSubmit = function () {
        var payload = {
            transport: this.newMarketpostDemandForm.value,
            index: this.index
        };
        this.transportDemandAdded.emit(payload);
    };
    // Autocomplete container_type
    NewMarketpostDemandComponent.prototype.searchMapping = function (searchType) {
        return searchType === 'searchContainerType'
            ? this.searchContainerType
            : this.searchLiner;
    };
    Object.defineProperty(NewMarketpostDemandComponent.prototype, "weight", {
        get: function () {
            return this.newMarketpostDemandForm.get('container.weight');
        },
        enumerable: true,
        configurable: true
    });
    NewMarketpostDemandComponent.prototype.hasAtLeastOneLocation = function () {
        return (this.newMarketpostDemandForm.get('originLocation').value ||
            this.newMarketpostDemandForm.get('destinationLocation').value);
    };
    Object.defineProperty(NewMarketpostDemandComponent.prototype, "comment", {
        get: function () {
            return this.newMarketpostDemandForm.get('comment').value;
        },
        enumerable: true,
        configurable: true
    });
    NewMarketpostDemandComponent.prototype.open = function (event, content) {
        event.preventDefault();
        this.modalService.open(content);
    };
    NewMarketpostDemandComponent.prototype.setTransportType = function (type) {
        this.newMarketpostDemandForm.get('transportType').patchValue(type);
    };
    NewMarketpostDemandComponent.prototype.transportTypePicked = function () {
        /* Set destinationLocation to null when transport type is empty (hub), this way we prevent
         * the destinationLocationfield having a value because of an earlier transport that was added.
         */
        if (this.newMarketpostDemandForm.get('transportType').value &&
            this.newMarketpostDemandForm.get('transportType').value.label ===
                'empty (hub)') {
            this.newMarketpostDemandForm.get('destinationLocation').patchValue(null);
        }
    };
    NewMarketpostDemandComponent.prototype.numberValidator = function (numberRe) {
        return function (control) {
            var notNumber = numberRe.test(control.value);
            return notNumber ? null : { notNumber: { value: control.value } };
        };
    };
    /**
     *
     * @param date date object given by the datepicker
     * @param current the current year and month
     * @returns boolean if given date is before today
     */
    NewMarketpostDemandComponent.prototype.getDisabledDates = function (date) {
        var today = new Date();
        var d = new Date(date.year, date.month, date.day);
        d = DateFns.subMonths(d, 1);
        d = DateFns.addDays(d, 1);
        return DateFns.isBefore(d, today);
    };
    NewMarketpostDemandComponent.prototype.getTypeIcons = function (type) {
        return this.iconService.getTransportTypeIcon(type);
    };
    return NewMarketpostDemandComponent;
}());
export { NewMarketpostDemandComponent };
