import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-cmp-new-marketpost-type',
  templateUrl: 'new-marketpost-type.component.html',
  styleUrls: [
    'new-marketpost-type.component.scss',
    '../new-marketpost.component.scss'
  ]
})
export class NewMarketpostTypeComponent {
  postType = 'offer';

  @Input() transportOffers: Number;
  @Input() transportDemands: Number;

  @Output() postTypeSelected = new EventEmitter<string>();

  postTypeChange($event) {
    this.postTypeSelected.emit($event);
  }
}
