import { SubscriptionType } from '../../store/models/subscriptions.model';
import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import { FormGroup } from '@angular/forms';
import { State } from '../../store/store.model';
import { Store } from '@ngrx/store';

import { ApiService } from '../../api/api.service';
import { Component, OnDestroy } from '@angular/core';
import * as Types from '../../store/types/tenant.types';
import { I18nService } from 'app/services/i18n.service';
import { CreateTenant } from '../../store/actions/tenants.actions';
import { LoadSubscriptionTypes } from '../../store/actions/subscriptions.actions';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-new-tenant',
  templateUrl: './new-tenant.component.html'
})
export class NewTenantComponent implements OnDestroy {
  tenantForm: FormGroup;
  alert: { icon?: string; type: string; message: string };
  loading = false;
  languages;
  countries: { value: string; label: string }[];
  subscriptionTypes: SubscriptionType[];

  constructor(
    private store: Store<State>,
    private createUpdates$: Actions,
    private i18nService: I18nService,
    private api: ApiService,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadSubscriptionTypes());
    this.store
      .select(state => state.subscriptionTypes)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptionTypesState => {
        if (subscriptionTypesState) {
          this.subscriptionTypes = subscriptionTypesState;
        }
      });
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countries => {
        if (!countries) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countries];
      });
    this.languages = this.i18nService.languages;
    createUpdates$
      .pipe(ofType(Types.tenants.CREATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Create tenant success!'
        });
        this.loading = false;
        this.tenantForm.reset();
      });

    createUpdates$
      .pipe(ofType(Types.tenants.CREATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        let detail = 'Check the logs for more info.';
        if (response['payload'] && response['payload'].error) {
          detail = response['payload']['error']['detail'] || '';
        }
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to create Tenant, ' + detail
        });
        this.loading = false;
      });
  }

  ngOnDestroy() { }

  onSubmit(tenantFormValue): void {
    const body = tenantFormValue;
    this.loading = true;
    this.store.dispatch(new CreateTenant(body));
  }

  closeAlert() {
    this.alert = null;
  }
}
