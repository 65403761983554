import { NgSelectModule } from '@ng-select/ng-select';
import { ReloadEffects } from '../core/store/effects/reload.effects';
import { ReloadReducers } from '../core/store/reducers/reload.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { ReloadRoutingModule } from './reload-routing.module';

// Components
import { ReloadComponent } from './reload.component';
import { ReloadRequestsComponent } from './reload-requests/reload-requests.component';
import { ReloadRequestComponent } from './reload-request/reload-request.component';
import { ReloadBackOfficeComponent } from './reload-back-office.component';
import { NewReloadRequestComponent } from './new-reload-request/new-reload-request.component';
import { ReloadReportingComponent } from './reporting/reload-reporting.component';
import { ReloadFormComponent } from './reload-form/reload-form.component';
import { LinerReloadRequestsReportComponent } from './liner-reload-requests-report/liner-reload-requests-report.component';

@NgModule({
  imports: [
    NgSelectModule,
    ReloadRoutingModule,
    SharedModule,
    StoreModule.forFeature('reload', ReloadReducers),
    EffectsModule.forFeature([
      ReloadEffects
    ])
  ],
  exports: [],
  declarations: [
    ReloadComponent,
    ReloadRequestsComponent,
    ReloadRequestComponent,
    ReloadBackOfficeComponent,
    NewReloadRequestComponent,
    ReloadReportingComponent,
    ReloadFormComponent,
    LinerReloadRequestsReportComponent,
  ],
  providers: [],
})
export class ReloadModule { }
