<div class="card-header bg-demand text-white d-flex justify-content-between">
  <div *ngIf="marketPost.quantity > 1">
    <span class="badge badge-dark" ngbTooltip="{{ 'Quantity' | translate }}">{{
      marketPost.quantity
    }}</span>
  </div>
  <div *ngIf="marketPost?.container.liner">
    <span class="icon"> <i class="far fa-truck"></i> </span>
    <span>{{ marketPost?.container?.liner?.name | translate }}</span>
  </div>
  <div *ngIf="marketPost?.container.containerType">
    <span class="icon"> <i class="far fa-balance-scale"></i> </span>
    <span>{{ marketPost?.container?.containerType?.label | translate }}</span>
  </div>
  <div>
    <span class="icon">
      <i class="far" [ngClass]="getTypeIcon(marketPost?.transportType)"></i>
    </span>
    <span>{{ marketPost?.transportType?.label | titlecase | translate }}</span>
  </div>
</div>
<div class="card-body market-post-card__body">
  <div class="d-flex justify-content-between">
    <span class="icon" *ngIf="marketPost?.container?.adr" ngbTooltip="ADR">
      <i class="far fa-exclamation-triangle text-danger"></i>
    </span>
  </div>
  <div class="d-flex justify-content-between">
    <span>{{ 'Name' | translate }}</span>
    <span>{{ marketPost?.tenant?.name | translate }}</span>
  </div>
  <div class="d-flex justify-content-between">
    <span>{{ 'Type' | translate }}</span>
    <span>{{ marketPost?.marketPostType | titlecase | translate }}</span>
  </div>
  <div
    *ngIf="marketPost?.container.weight"
    class="d-flex justify-content-between"
  >
    <span>{{ 'Weight' | translate }}</span>
    <span>{{ marketPost?.container.weight }}</span>
  </div>
  <div *ngIf="marketPost?.reference" class="d-flex justify-content-between">
    <span>{{ 'Reference' | translate }}</span>
    <span>{{ marketPost?.reference }}</span>
  </div>
  <div *ngIf="marketPost?.comment" class="d-flex justify-content-between mr-1">
    <span>{{ 'Comment' | translate }}</span>
    <p class="comment-field" style="max-width:60%">{{ marketPost?.comment }}</p>
  </div>
  <div>
    <hr />
    <div
      *ngIf="marketPost.originLocation?.name"
      class="d-flex justify-content-between"
    >
      <span>
        <span ngbTooltip="{{ 'Origin' | translate }}" placement="right">
          <span class="icon"> <i class="far fa-map-marker"></i> </span>
        </span>
        <span>{{
          marketPost.originLocation.shortName
            ? marketPost.originLocation.shortName
            : marketPost.originLocation.name
            ? marketPost.originLocation.name
            : marketPost.originLocation
        }}</span>
      </span>
      <span>
        <span *ngIf="marketPost.originTimeIntervalStart">
          {{ marketPost.originTimeIntervalStart | date: 'dd/MM' }}
          <span
            *ngIf="
              marketPost.originTimeIntervalStart &&
              marketPost.originTimeIntervalStartTimeSpecified
            "
          >
            {{ marketPost.originTimeIntervalStart | date: 'HH:mm' }}
          </span>
        </span>
        <span
          *ngIf="
            (marketPost.originTimeIntervalEnd &&
              marketPost.originTimeIntervalEnd !==
                marketPost.originTimeIntervalStart) ||
            marketPost.originTimeIntervalStartTimeSpecified
          "
        >
          -
        </span>
        <span
          *ngIf="
            marketPost.originTimeIntervalEnd &&
            marketPost.originTimeIntervalEnd !==
              marketPost.originTimeIntervalStart
          "
        >
          {{ marketPost.originTimeIntervalEnd | date: 'dd/MM' }}
        </span>
        <span
          *ngIf="
            marketPost.originTimeIntervalEnd &&
            marketPost.originTimeIntervalEndTimeSpecified
          "
        >
          {{ marketPost.originTimeIntervalEnd | date: 'HH:mm' }}
        </span>
      </span>
    </div>
    <div
      *ngIf="marketPost.destinationLocation?.name"
      class="d-flex justify-content-between"
    >
      <span>
        <span
          class="icon"
          ngbTooltip="{{ 'destination' | translate | titlecase }}"
          placement="right"
        >
          <i class="fas fa-map-marker"></i>
        </span>
        <span>{{
          marketPost.destinationLocation.shortName
            ? marketPost.destinationLocation.shortName
            : marketPost.destinationLocation.name
            ? marketPost.destinationLocation.name
            : marketPost.destinationLocation
        }}</span>
      </span>
      <span>
        <span *ngIf="marketPost.destinationTimeIntervalStart">
          {{ marketPost.destinationTimeIntervalStart | date: 'dd/MM' }}
          <span
            *ngIf="
              marketPost.destinationTimeIntervalStart &&
              marketPost.destinationTimeIntervalStartTimeSpecified
            "
          >
            {{ marketPost.destinationTimeIntervalStart | date: 'HH:mm' }}
          </span>
        </span>
        <span
          *ngIf="
            (marketPost.destinationTimeIntervalEnd &&
              marketPost.destinationTimeIntervalEnd !==
                marketPost.destinationTimeIntervalStart) ||
            marketPost.destinationTimeIntervalStartTimeSpecified
          "
        >
          -
        </span>
        <span
          *ngIf="
            marketPost.destinationTimeIntervalEnd &&
            marketPost.destinationTimeIntervalEnd !==
              marketPost.destinationTimeIntervalStart
          "
        >
          {{ marketPost.destinationTimeIntervalEnd | date: 'dd/MM' }}
        </span>
        <span
          *ngIf="
            marketPost.destinationTimeIntervalEnd &&
            marketPost.destinationTimeIntervalEndTimeSpecified
          "
        >
          {{ marketPost.destinationTimeIntervalEnd | date: 'HH:mm' }}
        </span>
      </span>
    </div>
  </div>
</div>
<div class="card-footer market-post-card__footer">
  <div class="d-flex reply-container" *ngIf="marketPost.tenant.id !== tenantId">
    <a [routerLink]="['/marketplace/demands', marketPost?._id, 'reply']">
      <button
        class="btn btn-sm btn-primary reply-button text-white button-block"
      >
        <span class="icon"> <i class="far fa-reply"></i> </span>
        <span>{{ 'Reply' | translate }}</span>
      </button>
    </a>
  </div>
  <div class="reply-container" *ngIf="marketPost.tenant.id === tenantId">
    <app-button
      [isLoading]="isTogglingMatch"
      [btnClass]="'mb-2'"
      *ngIf="hasActiveMatchingSubscription && marketPost?.matching"
      (click)="handleDisableMatching()"
      [type]="'button'"
    >
      <i class="far fa-fw fa-bolt"></i>
      <span>{{ 'Disable matching' | translate }}</span>
    </app-button>
    <app-button
      [isLoading]="isTogglingMatch"
      [btnClass]="'btn-success mb-2'"
      *ngIf="hasActiveMatchingSubscription && !marketPost?.matching"
      (click)="handleEnableMatching()"
      [type]="'button'"
    >
      <i class="far fa-fw fa-bolt"></i>
      <span>{{ 'Enable matching' | translate }}</span>
    </app-button>
    <div class="d-flex justify-content-between">
      <button
        class="btn btn-sm mr-2 btn-warning reply-button text-black button-block"
        (click)="editPost()"
      >
        <span class="icon"> <i class="far fa-pencil"></i> </span>
        <span>{{ 'Edit' | translate }}</span>
      </button>
      <button
        class="btn btn-sm btn-danger reply-button text-white button-block"
        (click)="unpublishPost()"
      >
        <span class="icon" *ngIf="loading">
          <i class="far fa-spinner fa-spin"></i>
        </span>
        <span class="icon" *ngIf="!loading">
          <i class="far fa-trash"></i>
        </span>
        <span>{{ 'Delete' | translate }}</span>
      </button>
    </div>
  </div>
</div>
