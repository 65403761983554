<div class="card-header d-flex">
    <h3 class="align-self-center mb-0">{{ 'Download transport document' | translate }}</h3>
    <button class="btn ml-auto" (click)="activeModal.close()">
        {{ 'Close' | translate }}
    </button>
</div>

<div class="p-3">
    <div class="row form-group">
        <div class="col">
            <label> {{ 'Select Language' | translate}} </label>
            <ng-select
                        data-cy="language"
                        bindLabel="label"
                        bindValue="value"
                        [(ngModel)]="language"
                        [items]="languageOptions">
            </ng-select>
        </div>

    </div>
    <div class="row form-group">
        <div class="col text-center">
            <button class="btn btn-default col-6"
                    (click)="download('pdf')">
                {{ 'Download as PDF' | translate }}
            </button>
            <button class="btn btn-default col-6"
                    (click)="download('xlsx')">
                {{ 'Download as XLSX' | translate }}
            </button>
        </div>
    </div>
</div>
