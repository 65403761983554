import { AppConfig } from '../../app.config';
import { Actions, ofType } from '@ngrx/effects';
import { Session } from 'app/core/store/models/session.model';
import { Refresh, Logout } from 'app/core/store/actions/session.actions';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { ApiService } from 'app/core/api/api.service';
import * as SessionTypes from 'app/core/store/types/session.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  @Output()
  fileUploadSuccess = new EventEmitter<string>();

  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;
  accessToken: string;
  xTenantId: string;
  session: Session;
  hasError = false;
  failedItems = [];
  URL: any;
  MAX_FILE_SIZE_MB = 15;
  MAX_FILE_SIZE_B = this.MAX_FILE_SIZE_MB * 1024 * 1024;
  errorMessage: string;

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private updates$: Actions,
    private config: AppConfig
  ) { }

  ngOnInit() {
    this.URL = `${this.config.getConfig('apiUrl')}/api/${this.config.getConfig(
      'apiVersion'
    )}/attachments`;
    this.store
      .select(state => state.session)
      .pipe(untilDestroyed(this))
      .subscribe(sessionState => {
        this.session = sessionState;
        this.accessToken = `Bearer ${sessionState.access_token}`;
        this.xTenantId = sessionState.tenant;

        const options = {
          url: this.URL,
          maxFileSize: this.MAX_FILE_SIZE_B,
          authToken: this.accessToken,
          headers: [{ name: 'X-tenant-id', value: this.xTenantId }]
        };

        this.uploader = this.uploader ? this.uploader : new FileUploader({});
        this.uploader.setOptions(options);

        this.uploader.onAfterAddingFile = file => {
          file.withCredentials = false;
        };
        this.uploader.onWhenAddingFileFailed = (item, filter, opts) =>
          this.onWhenAddingFileFailed(item, filter, opts);

        // Retry failed items when token is refreshed
        if (this.hasError && this.uploader.queue.length > 0) {
          this.uploader.uploadAll();
        }
      });
    this.uploader.onSuccessItem = (item: any, res: any) => {
      const attachment = JSON.parse(res);
      this.fileUploadSuccess.emit(attachment);
    };
    this.uploader.onErrorItem = (item, res, status) => {
      if (status === 401 && !this.hasError) {
        this.hasError = true;
        this.store.dispatch(new Refresh(this.session));
        item.isUploaded = false;
      }
    };
    this.updates$
      .pipe(ofType(SessionTypes.session.REFRESH_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.store.dispatch(new Logout()));
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    switch (filter.name) {
      case 'fileSize':
        this.errorMessage = `Maximum file upload size of ${
          this.MAX_FILE_SIZE_MB
          }MB exceeded`;
        break;
      default:
        this.errorMessage = `Unknown error (filter is ${filter.name})`;
    }
  }
  removeItem(item) {
    if (!item._xhr) {
      return;
    }
    if (!item._xhr.response) {
      return;
    }
    const response = JSON.parse(item._xhr.response);
    console.log(response);
    this.api.delete({
      path: `/attachments/${response.id}`
    });
  }

  ngOnDestroy() { }
}
