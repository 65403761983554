/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./matching-pool-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../transport-matching-pool-item/transport-matching-pool-item.component.ngfactory";
import * as i3 from "../transport-matching-pool-item/transport-matching-pool-item.component";
import * as i4 from "../../services/icon.service";
import * as i5 from "../../shared/button/button.component.ngfactory";
import * as i6 from "../../shared/button/button.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../market-post-offer-matching-pool-item/market-post-offer-matching-pool-item.component.ngfactory";
import * as i9 from "../market-post-offer-matching-pool-item/market-post-offer-matching-pool-item.component";
import * as i10 from "../market-post-demand-matching-pool-item/market-post-demand-matching-pool-item.component.ngfactory";
import * as i11 from "../market-post-demand-matching-pool-item/market-post-demand-matching-pool-item.component";
import * as i12 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i13 from "@ng-bootstrap/ng-bootstrap";
import * as i14 from "../matching-pool-option/matching-pool-option.component.ngfactory";
import * as i15 from "../matching-pool-option/matching-pool-option.component";
import * as i16 from "@ngrx/effects";
import * as i17 from "@angular/common";
import * as i18 from "./matching-pool-item.component";
var styles_MatchingPoolItemComponent = [i0.styles];
var RenderType_MatchingPoolItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatchingPoolItemComponent, data: {} });
export { RenderType_MatchingPoolItemComponent as RenderType_MatchingPoolItemComponent };
function View_MatchingPoolItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-transport-matching-pool-item", [], null, null, null, i2.View_TransportMatchingPoolItemComponent_0, i2.RenderType_TransportMatchingPoolItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.TransportMatchingPoolItemComponent, [i4.IconService], { transport: [0, "transport"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRemoveMatchItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i6.ButtonComponent, [], { btnClass: [0, "btnClass"], type: [1, "type"], isLoading: [2, "isLoading"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matchingPoolItem.transport; _ck(_v, 1, 0, currVal_0); var currVal_1 = "btn-sm btn-danger align-self-start"; var currVal_2 = "button"; var currVal_3 = (_co.disabledMatch && (_co.disabledMatch === _co.id)); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Disable matching")); _ck(_v, 4, 0, currVal_4); }); }
function View_MatchingPoolItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-market-post-offer-matching-pool-item", [], null, null, null, i8.View_MarketPostOfferMatchingPoolItemComponent_0, i8.RenderType_MarketPostOfferMatchingPoolItemComponent)), i1.ɵdid(1, 49152, null, 0, i9.MarketPostOfferMatchingPoolItemComponent, [], { offer: [0, "offer"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRemoveMatchItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i6.ButtonComponent, [], { btnClass: [0, "btnClass"], type: [1, "type"], isLoading: [2, "isLoading"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.matchingPoolItem.marketPostOffer == null) ? null : _co.matchingPoolItem.marketPostOffer.transportOrigin); _ck(_v, 1, 0, currVal_0); var currVal_1 = "btn-sm btn-danger align-self-start"; var currVal_2 = "button"; var currVal_3 = (_co.disabledMatch && (_co.disabledMatch === _co.id)); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Disable matching")); _ck(_v, 4, 0, currVal_4); }); }
function View_MatchingPoolItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-market-post-demand-matching-pool-item", [], null, null, null, i10.View_MarketPostDemandMatchingPoolItemComponent_0, i10.RenderType_MarketPostDemandMatchingPoolItemComponent)), i1.ɵdid(1, 49152, null, 0, i11.MarketPostDemandMatchingPoolItemComponent, [], { demand: [0, "demand"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "app-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleRemoveMatchItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i6.ButtonComponent, [], { btnClass: [0, "btnClass"], type: [1, "type"], isLoading: [2, "isLoading"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matchingPoolItem.marketPostDemand; _ck(_v, 1, 0, currVal_0); var currVal_1 = "btn-sm btn-danger align-self-start"; var currVal_2 = "button"; var currVal_3 = (_co.disabledMatch && (_co.disabledMatch === _co.id)); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Disable matching")); _ck(_v, 4, 0, currVal_4); }); }
function View_MatchingPoolItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Hide Matches")); _ck(_v, 1, 0, currVal_0); }); }
function View_MatchingPoolItemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Show Matches")); _ck(_v, 1, 0, currVal_0); }); }
function View_MatchingPoolItemComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ngb-alert", [["class", "text-center alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], null, null, i12.View_NgbAlert_0, i12.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i13.NgbAlert, [i13.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = false; var currVal_2 = "info"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("No matches found for this transport")); _ck(_v, 2, 0, currVal_3); }); }
function View_MatchingPoolItemComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-matching-pool-option", [], null, [[null, "removeOption"], [null, "submitReply"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeOption" === en)) {
        var pd_0 = (_co.handleRemoveMatchOption($event) !== false);
        ad = (pd_0 && ad);
    } if (("submitReply" === en)) {
        var pd_1 = (_co.handleSubmitReply($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_MatchingPoolOptionComponent_0, i14.RenderType_MatchingPoolOptionComponent)), i1.ɵdid(2, 180224, null, 0, i15.MatchingPoolOptionComponent, [i16.Actions], { option: [0, "option"], isLoading: [1, "isLoading"] }, { submitReply: "submitReply", removeOption: "removeOption" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.isLoading; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MatchingPoolItemComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_9)), i1.ɵdid(2, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.visible && (_v.context.index < _co.MATCHLIMIT)) || _co.matchingPoolItem.showAll); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MatchingPoolItemComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_7)), i1.ɵdid(2, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_8)), i1.ɵdid(4, 278528, null, 0, i17.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.matchingPoolItem.matchOptions || (_co.matchingPoolItem.matchOptions.length === 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.matchingPoolItem.matchOptions; _ck(_v, 4, 0, currVal_1); }, null); }
function View_MatchingPoolItemComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "hr-sect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.matchingPoolItem.showAll = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", "", " ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Load all (")); var currVal_1 = (_co.matchingPoolItem.matchOptions.length - _co.MATCHLIMIT); var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 3).transform("more)")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_MatchingPoolItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "card p-3 mb-3 match-candidate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "span", [["style", "width: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_1)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_2)), i1.ɵdid(6, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_3)), i1.ɵdid(8, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "hr-sect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.matchingPoolItem.showMatches = !_co.matchingPoolItem.showMatches) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_4)), i1.ɵdid(11, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showMatches", 2]], null, 0, null, View_MatchingPoolItemComponent_5)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_6)), i1.ɵdid(14, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchingPoolItemComponent_10)), i1.ɵdid(16, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matchingPoolItem.transport; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.matchingPoolItem.marketPostOffer; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.matchingPoolItem.marketPostDemand; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.matchingPoolItem.showMatches; var currVal_4 = i1.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = _co.matchingPoolItem.showMatches; _ck(_v, 14, 0, currVal_5); var currVal_6 = ((!(_co.matchingPoolItem.matchOptions.length < _co.MATCHLIMIT) && !_co.matchingPoolItem.showAll) && (_co.matchingPoolItem.matchOptions.length !== _co.MATCHLIMIT)); _ck(_v, 16, 0, currVal_6); }, null); }
export function View_MatchingPoolItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-matching-pool-item", [], null, null, null, View_MatchingPoolItemComponent_0, RenderType_MatchingPoolItemComponent)), i1.ɵdid(1, 49152, null, 0, i18.MatchingPoolItemComponent, [], null, null)], null, null); }
var MatchingPoolItemComponentNgFactory = i1.ɵccf("app-matching-pool-item", i18.MatchingPoolItemComponent, View_MatchingPoolItemComponent_Host_0, { matchingPoolItem: "matchingPoolItem", isLoading: "isLoading", disabledMatch: "disabledMatch" }, { submitReply: "submitReply", removeMatchItem: "removeMatchItem", removeMatchOption: "removeMatchOption" }, []);
export { MatchingPoolItemComponentNgFactory as MatchingPoolItemComponentNgFactory };
