import { Component } from '@angular/core';
import { extract } from '../services/i18n.service';

@Component({
  selector: 'app-cmp-tms',
  templateUrl: 'tms.component.html',
})
export class TmsComponent {
  title = extract('Transport Management Service');
  routes = [
    {
      url: '/tms/transports',
      title: extract('Transports'),
      description: extract('Manage your transports'),
      icon: 'fa-truck-container'
    },
    {
      url: '/tms/transports/new',
      title: extract('Add new transport'),
      description: extract('Create a new transport'),
      icon: 'fa-plus'
    },
    {
      url: '/tms/drivers',
      title: extract('Drivers'),
      description: extract(
        'Create, edit and delete the drivers in your company'
      ),
      icon: 'fa-car'
    },
    {
      url: '/tms/drivers/new',
      title: extract('Add new driver'),
      description: extract('Create a new driver'),
      icon: 'fa-plus'
    },
    {
      url: '/tms/prenotifications',
      title: extract('Prenotifications'),
      description: extract('Manage your prenotifications'),
      icon: 'fa-barcode-alt'
    },
    {
      url: '/tms/prenotifications/new',
      title: extract('Add new prenotification'),
      description: extract('Create a new prenotification'),
      icon: 'fa-plus'
    },
    {
      url: '/settings/locations',
      title: extract('Locations'),
      description: extract(
        'Create, edit and delete the locations of your company'
      ),
      icon: 'fa-map-marker'
    },
    {
      url: '/settings/locations/new',
      title: extract('Add new location'),
      description: extract('Create a new location'),
      icon: 'fa-plus'
    },
    {
      url: '/tms/planning',
      title: extract('Planning'),
      description: extract('Assign transports to your drivers'),
      icon: 'fa-calendar-alt'
    }
  ];
}
