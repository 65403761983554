<div class="search-container mb-3 ml-3 mr-3">
  <app-search
    [placeholder]="searchPlaceholder"
    (search)="handleSearch($event)"
  ></app-search>
  <button class="btn" [routerLink]="['/', baseUrl, 'customers', 'new']" id="new-customer-button">
    <i class="far fa-plus fa-fw"></i>
    <span>{{ 'New customer' | translate }}</span>
  </button>
</div>
<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [rows]="filteredCustomers"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="false"
  [count]="totalElements"
  [limit]="30"
  [loadingIndicator]="isLoadingCustomers"
>
  <div>
    <ngx-datatable-column name="{{ 'Name' | translate }}" prop="label">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'VAT' | translate }}" prop="vatNumber">
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'City' | translate }}"
      prop="billingAddress.city"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'Country' | translate }}"
      prop="billingAddress.country"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ 'Contact' | translate }}"
      prop="defaultContact"
    >
      <ng-template ngx-datatable-cell-template let-value="value">
        <span>{{ value.firstName }} {{ value.lastName }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Details' | translate }}" prop="">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button (click)="goToEditCustomer(row)" class="btn">
          <i class="far fa-pencil"></i>
        </button>
        <button (click)="deleteCustomer(row)" class="btn">
          <i class="far fa-trash-alt"></i>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
