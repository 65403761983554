import { Component } from '@angular/core';

import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-certiweight',
  templateUrl: './certiweight.component.html',
})
export class CertiweightComponent {
  routes = [
    {
      url: '/certiweight/buy',
      title: extract('Buy Certificates'),
      description: extract('Buy new certificates'),
      icon: 'fa-money-bill-wave'
    },
    {
      url: '/certiweight/my',
      title: extract('My Certificates'),
      description: extract('Manage my certificates'),
      icon: 'fa-weight-hanging'
    }
  ];
}
