import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Store } from '@ngrx/store';

import { ApiService } from 'app/core/api/api.service';
import { LoadLocationTypes } from 'app/core/store/actions/location.actions';
import { LoadAllTenants } from 'app/core/store/actions/tenants.actions';
import { LocationType } from 'app/core/store/models/location-type.model';
import { Location } from 'app/core/store/models/location.model';
import { State } from 'app/core/store/store.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
})
export class LocationFormComponent implements OnInit, OnChanges, OnDestroy {
  connectors: any;
  locationTypes: LocationType[] = [];
  @Input()
  location: Location;
  @Output()
  locationFormSubmitted = new EventEmitter<Location>();

  locationForm: FormGroup;
  loading = false;
  tenants = [];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private store: Store<State>
  ) { }

  ngOnInit() {
    if (!this.locationForm) {
      this.createForm();
    }
    this.store
      .select(state => state.tenants)
      .pipe(untilDestroyed(this))
      .subscribe(tenants => {
        if (!tenants['data']) {
          this.store.dispatch(new LoadAllTenants());
          return;
        }
        this.tenants = tenants['data'];
      });
    this.store
      .select(state => state.tms.locations)
      .pipe(untilDestroyed(this))
      .subscribe(locationState => {
        if (!locationState.locationTypes) {
          this.store.dispatch(new LoadLocationTypes());
          return;
        }
        this.locationTypes = locationState.locationTypes;
      });
    this.api.get({ path: '/location_connectors_list' }).subscribe(
      response => {
        this.connectors = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.location && changes.location.currentValue) {
      if (!this.locationForm) {
        this.createForm();
      }
      const currentValue = changes.location.currentValue;
      this.locationForm.patchValue({
        name: currentValue.name,
        shortName: currentValue.shortName,
        fullAddress: currentValue.fullAddress,
        latitude: currentValue.latitude,
        longitude: currentValue.longitude,
        locationType: currentValue.locationType.id,
        connector: currentValue.connector,
        extraData: JSON.stringify(currentValue.extraData),
        code: currentValue.code,
        solasOfficer: currentValue.solasOfficer,
        servicePrice: currentValue.servicePrice,
        prePaid: currentValue.prePaid,
        prenotificationManagers: currentValue.prenotificationManagers.map(x => x['@id']),
      });

      if (currentValue.aliases && currentValue.aliases.length > 0) {
        this.removeAlias(0);
        currentValue.aliases.forEach(() => this.addAlias());
        this.locationForm.patchValue({ aliases: currentValue.aliases });
      }
    }
  }

  ngOnDestroy() { }

  createForm() {
    this.locationForm = this.fb.group({
      name: [null, Validators.required],
      shortName: [],
      fullAddress: [],
      latitude: [],
      longitude: [],
      locationType: [null, Validators.required],
      connector: [],
      extraData: ['{}'],
      code: [],
      solasOfficer: [],
      servicePrice: [],
      aliases: this.fb.array([this.createAlias()]),
      prePaid: [],
      prenotificationManagers: [],
    });
  }

  createAlias(): FormGroup {
    return this.fb.group({
      code: null,
      label: null
    });
  }

  addAlias(): void {
    const aliases = <FormArray>this.locationForm.controls['aliases'];
    aliases.push(this.createAlias());
  }

  removeAlias(index: number): void {
    const aliases = <FormArray>this.locationForm.controls['aliases'];
    aliases.removeAt(index);
  }

  onSubmit() {
    const body = this.locationForm.value;
    if (this.locationTypes) {
      body.locationType = this.locationTypes.find(
        l => l.id === body.locationType
      )['@id'];
    }
    body.solasOfficer = body.solasOfficer !== '' ? body.solasOfficer : null;

    body.prePaid = body.prePaid ? body.prePaid : false;

    if (body.servicePrice !== null) {
      body.servicePrice = body.servicePrice.toString() !== '' ? body.servicePrice.toString() : null;
    }


    body.aliases = body.aliases.filter(alias => alias.label && alias.code);
    body.prenotificationManagers = (body.prenotificationManagers === null ? [] : body.prenotificationManagers);

    this.locationFormSubmitted.emit(body);
  }

  showWeightProperties(): boolean {
    return this.locationTypes && this.locationTypes.filter((type) => {
      return type.id === this.locationForm.controls['locationType'].value && type.code === 'WEIGHT';
    }).length > 0;
  }

  showAliases(): boolean {
    return this.locationTypes && this.locationTypes.some((type) => {
      return type.id === this.locationForm.controls['locationType'].value &&
        (type.code === 'WEIGHT' || type.code === 'TERMINAL');
    });
  }
}
