var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/visit.types';
import * as VisitsActions from '../actions/visits.actions';
import { ApiService } from '../../api/api.service';
var VisitsEffects = /** @class */ (function () {
    function VisitsEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadVisits$ = this.actions$.pipe(ofType(types.visits.LOAD_VISITS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var path = "/visits?";
            if (payload) {
                if (payload.fromDate) {
                    path += "&scheduledAt[after]=" + payload.fromDate;
                }
                if (payload.toDate) {
                    path += "&scheduledAt[before]=" + payload.toDate + "%2023%3A59%3A59";
                }
                if (payload.locationId) {
                    path += "&transportTasks.location.id=" + payload.locationId;
                }
                if (payload.driverId) {
                    path += "&driver.id=" + payload.driverId;
                }
                if (payload.page) {
                    path += "&page=" + payload.page;
                }
            }
            return _this.api.get({ path: path }).pipe(map(function (data) { return new VisitsActions.LoadVisitsSuccess(data); }), catchError(function (error) { return of(new VisitsActions.LoadVisitsFail(error)); }));
        }));
        this.createVisit$ = this.actions$.pipe(ofType(types.visits.CREATE_VISIT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/visits', body: payload.body
        }).pipe(map(function (data) { return new VisitsActions.CreateVisitSuccess(data); }), catchError(function (error) { return of(new VisitsActions.CreateVisitFail(error)); })); }));
        this.updateVisit$ = this.actions$.pipe(ofType(types.visits.UPDATE_VISIT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/visits/" + payload.visitId, body: payload.body
        }).pipe(map(function (data) { return new VisitsActions.UpdateVisitSuccess(data); }), catchError(function (error) { return of(new VisitsActions.UpdateVisitFail(error)); })); }));
        this.updateVisitSequence$ = this.actions$.pipe(ofType(types.visits.UPDATE_VISIT_SEQUENCE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/bulk/visits', body: payload
        }).pipe(map(function (data) { return new VisitsActions.UpdateVisitSequenceSuccess(data); }), catchError(function (error) { return of(new VisitsActions.UpdateVisitSequenceFail(error)); })); }));
        this.transitionVisitForward$ = this.actions$.pipe(ofType(types.visits.TRANSITION_VISIT_FORWARD), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/visits/" + payload.visitId + "/transition/forward"
        }).pipe(map(function (data) { return new VisitsActions.TransitionVisitForwardSuccess(data); }), catchError(function (error) { return of(new VisitsActions.TransitionVisitForwardFail(error)); })); }));
        this.transitionVisitBack$ = this.actions$.pipe(ofType(types.visits.TRANSITION_VISIT_BACK), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/visits/" + payload.visitId + "/transition/back"
        }).pipe(map(function (data) { return new VisitsActions.TransitionVisitBackSuccess(data); }), catchError(function (error) { return of(new VisitsActions.TransitionVisitBackFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VisitsEffects.prototype, "loadVisits$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VisitsEffects.prototype, "createVisit$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VisitsEffects.prototype, "updateVisit$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VisitsEffects.prototype, "updateVisitSequence$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VisitsEffects.prototype, "transitionVisitForward$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], VisitsEffects.prototype, "transitionVisitBack$", void 0);
    return VisitsEffects;
}());
export { VisitsEffects };
