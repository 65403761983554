import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './contacts.component';
import { ContactsRoutingModule } from './contacts-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { ContactsEffects } from '../store/effects/contacts.effects';

/**
 * The address book does deserve it's own module.
 *
 * @export
 * @class ContactsModule
 */
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ContactsRoutingModule,
    EffectsModule.forFeature([ContactsEffects])
  ],
  declarations: [ContactsComponent]
})
export class ContactsModule {}
