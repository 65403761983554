import { TransportTask } from 'app/core/store/models/transport-task.model';
import { IconService } from 'app/services/icon.service';
import { Transport } from 'app/core/store/models/transport.model';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VisitBarCodeComponent } from '../visit-bar-code/visit-bar-code.component';

@Component({
  selector: 'app-driver-task',
  templateUrl: './driver-task.component.html',
  styleUrls: ['./driver-task.component.scss']
})
export class DriverTaskComponent {
  @Input()
  transport: Transport;
  @Input()
  task: TransportTask;
  @Input()
  isGoingForward: boolean;
  @Input()
  isGoingBack: boolean;
  @Output()
  transitionForward = new EventEmitter<string>();
  @Output()
  transitionBack = new EventEmitter<string>();

  constructor(
    private iconService: IconService,
    private modalService: NgbModal
  ) { }

  getTaskIcon(task: TransportTask) {
    return this.iconService.getTransportTaskIcon(task);
  }

  launchBarcodeModal(task) {
    const modalRef = this.modalService.open(VisitBarCodeComponent);
    modalRef.componentInstance.barCode = task.visit.barCode;
  }
}
