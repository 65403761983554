import {
  SimpleChanges,
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
// models
import { UserContext } from 'app/core/store/models/user-contexts.model';
import { Role, Roles } from 'app/core/store/models/roles.model';
import { State } from 'app/core/store/store.model';
import { I18nService } from 'app/services/i18n.service';
import { LoadRoles } from 'app/core/store/actions/roles.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-user-context-form',
  templateUrl: 'user-context-form.component.html'
})
export class UserContextFormComponent implements OnInit, OnChanges, OnDestroy {
  @Output()
  userContextFormValue = new EventEmitter<UserContext>();

  @Input()
  userContext: any;
  @Input()
  buttonText = 'Submit';
  @Input()
  loading = false;
  @Input()
  driver = false;

  userContextForm: FormGroup;
  ROLES = Roles;
  roles: Role[];
  createdUser: any;
  success = false;
  enabled: Boolean;
  languages;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private translateService: I18nService
  ) {
    store.dispatch(new LoadRoles());
  }

  ngOnInit() {
    this.languages = this.translateService.languages;
    this.createForm();
    this.store.select('roles')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        // This could be refactored to an input so we can use a multiselect
        if (state) {
          this.roles = state;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.userContext &&
      changes.userContext.currentValue &&
      changes.userContext.currentValue.id
    ) {
      this.createForm();
      const currentValue = changes.userContext.currentValue;
      this.userContextForm.patchValue({
        email: currentValue.user.email,
        firstName: currentValue.user.firstName,
        lastName: currentValue.user.lastName,
        phoneNumber: currentValue.phoneNumber,
        locale: currentValue.locale,
        licensePlate: currentValue.licensePlate,
        isDriver:
          currentValue.roles.filter(role => role.code === this.ROLES.DRIVER)
            .length > 0,
        isPlanner:
          currentValue.roles.filter(role => role.code === this.ROLES.PLANNER)
            .length > 0,
        isAdmin:
          currentValue.roles.filter(role => role.code === this.ROLES.ADMIN)
            .length > 0
      });
    }
  }

  ngOnDestroy() { }

  // Actually go and create the form yeah
  createForm() {
    if (
      this.userContextForm ||
      (this.userContextForm && this.userContextForm.value)
    ) {
      return;
    }
    this.userContextForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      locale: [null, Validators.required],
      licensePlate: [null],
      isDriver: [this.driver],
      isPlanner: [!this.driver],
      isAdmin: [false]
    });
  }

  resetForm() {
    this.userContextForm.reset();
  }

  togglePlanner() {
    const isPlanner = this.userContextForm.controls.isPlanner.value;
    this.userContextForm.controls.isPlanner.setValue(!isPlanner);
  }

  toggleDriver() {
    const isDriver = this.userContextForm.controls.isDriver.value;
    this.userContextForm.controls.isDriver.setValue(!isDriver);
  }

  toggleAdmin() {
    const isAdmin = this.userContextForm.controls.isPlanner.value;
    this.userContextForm.controls.isAdmin.setValue(!isAdmin);
  }

  onSubmit() {
    this.userContextFormValue.emit(this.userContextForm.value);
  }
}
