import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-credit-threshold-modal',
  templateUrl: './edit-credit-threshold.component.html'
})
export class EditCreditThresholdComponent {
  credits: number;

  constructor(private modal: NgbActiveModal) { }

  submit() {
    this.modal.close((+this.credits).toString());
  }
}
