import { EditUserLocationComponent } from '../../tms/locations/edit-user-location/edit-user-location.component';
import { ManageUserLocationsComponent } from '../../tms/locations/manage-user-locations/manage-user-locations.component';
import { AboutComponent } from '../about/about.component';
import { AdminComponent } from './admin.component';
import { AuthGuard } from '../user/user.authguard';
import { CirclesComponent } from '../user/circles/circles.component';
import { CreditsComponent } from './tenant-settings/credits/credits.component';
import { EditCircleComponent } from '../user/circles/edit-circle/edit-circle.component';
import { EditUserContextComponent } from './tenant-settings/user-contexts/edit-user-context/edit-user-context.component';
import { NewUserContextComponent } from './tenant-settings/user-contexts/new-user-context/new-user-context.component';
import { NgModule } from '@angular/core';
import { NotificationSubscriptionsComponent } from './tenant-settings/notification-subscriptions/notification-subscriptions.component';
import { RegistrationRequestComponent } from './registration-request/registration-request.component';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionsComponent } from './tenant-settings/subscriptions/subscriptions.component';
import { SuperAdminComponent } from '../super-admin/super-admin.component';
import { TenantInformationComponent } from './tenant-settings/tenant-information/tenant-information.component';
import { TenantUsersListComponent } from './tenant-settings/tenant-users-list/tenant-users-list.component';
import { UserInfoFormComponent } from '../user/user-info-form/user-info-form.component';
import { WelcomeComponent } from '../welcome/welcome.component';
import { Route } from '../route.service';
import { AdminGuard } from '../auth/route-guards/admin.guard';
import { DriverGuard } from '../auth/route-guards/driver.guard';
import { SuperAdminGuard } from '../auth/route-guards/super-admin.guard';
import { TenantGuard } from '../auth/route-guards/tenant.guard';
import { NewUserLocationComponent } from '../../tms/locations/new-user-location/new-user-location.component';

const defaultAdminGuards = [AuthGuard, TenantGuard, AdminGuard, DriverGuard];
const routes: Routes = Route.withShell([
  {
    path: 'settings',
    component: AdminComponent,
    canActivate: [AuthGuard, TenantGuard, DriverGuard]
  },
  {
    path: 'settings/me',
    component: UserInfoFormComponent,
    canActivate: [AuthGuard, TenantGuard]
  },
  {
    path: 'settings/users',
    component: TenantUsersListComponent,
    canActivate: [...defaultAdminGuards]
  },
  {
    path: 'settings/users/create',
    component: NewUserContextComponent,
    canActivate: [...defaultAdminGuards]
  },
  {
    path: 'settings/users/:id/edit',
    component: EditUserContextComponent,
    canActivate: [...defaultAdminGuards]
  },
  {
    path: 'settings/info',
    component: TenantInformationComponent,
    canActivate: [...defaultAdminGuards]
  },
  {
    path: 'settings/credits',
    component: CreditsComponent,
    canActivate: [...defaultAdminGuards]
  },
  {
    path: 'settings/circles/:id/edit',
    component: EditCircleComponent,
    canActivate: [AuthGuard, DriverGuard]
  },
  {
    path: 'settings/circles',
    component: CirclesComponent,
    canActivate: [AuthGuard, TenantGuard, DriverGuard]
  },
  {
    path: 'settings/subscriptions',
    component: SubscriptionsComponent,
    canActivate: [...defaultAdminGuards]
  },
  {
    path: 'settings/notifications',
    component: NotificationSubscriptionsComponent,
    canActivate: [AuthGuard, TenantGuard, DriverGuard]
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [...defaultAdminGuards]
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard, TenantGuard, DriverGuard]
  },
  {
    path: 'superadmin',
    component: SuperAdminComponent,
    canActivate: [AuthGuard, TenantGuard, SuperAdminGuard, DriverGuard]
  },
  { path: 'register', component: RegistrationRequestComponent },
  {
    path: 'settings/locations',
    children: [
      {
        path: '',
        component: ManageUserLocationsComponent,
        canActivate: [AuthGuard, TenantGuard, DriverGuard]
      },
      {
        path: 'new',
        component: NewUserLocationComponent,
        canActivate: [AuthGuard, TenantGuard, DriverGuard]
      },
      {
        path: ':id/edit',
        component: EditUserLocationComponent,
        canActivate: [AuthGuard, TenantGuard, DriverGuard]
      }
    ]
  }
]);

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: false })],
  exports: [],
  providers: []
})
export class AdminRoutingModule {}
