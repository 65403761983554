/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./market-post-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./map/map.component.ngfactory";
import * as i3 from "./map/map.component";
import * as i4 from "./market-post-map.component";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../services/search.service";
var styles_MarketPostMapComponent = [i0.styles];
var RenderType_MarketPostMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MarketPostMapComponent, data: {} });
export { RenderType_MarketPostMapComponent as RenderType_MarketPostMapComponent };
export function View_MarketPostMapComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-map", [], null, [[null, "updateByGeoBoundBox"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateByGeoBoundBox" === en)) {
        var pd_0 = (_co.updateByGeoBoundBox($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MapComponent_0, i2.RenderType_MapComponent)), i1.ɵdid(1, 638976, null, 0, i3.MapComponent, [], { posts: [0, "posts"], updateSize: [1, "updateSize"] }, { updateByGeoBoundBox: "updateByGeoBoundBox" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posts; var currVal_1 = _co.updateSize; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_MarketPostMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-market-post-map", [], null, null, null, View_MarketPostMapComponent_0, RenderType_MarketPostMapComponent)), i1.ɵdid(1, 49152, null, 0, i4.MarketPostMapComponent, [i5.Store, i6.SearchService], null, null)], null, null); }
var MarketPostMapComponentNgFactory = i1.ɵccf("app-cmp-market-post-map", i4.MarketPostMapComponent, View_MarketPostMapComponent_Host_0, { updateSize: "updateSize" }, {}, []);
export { MarketPostMapComponentNgFactory as MarketPostMapComponentNgFactory };
