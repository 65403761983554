import { SsoLoginComponent } from '../sso-login/sso-login.component';
import { RedirectComponent } from '../redirect/redirect.component';
import { ResetSuccessComponent } from './reset/success/reset.success.component';
import { ResetUpdateComponent } from './reset/update/reset.update.component';
import { ResetComponent } from './reset/reset.component';
import { LoginComponent } from './login/login.component';
import { Routes } from '@angular/router';
import { LoggedInGuard } from '../core/auth/route-guards/logged-in.guard';
var ɵ0 = { containerClass: 'Login' };
var routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0,
        canActivate: [LoggedInGuard]
    },
    {
        path: 'auth/redirect/:payload',
        component: RedirectComponent
    },
    { path: 'user/reset', component: ResetComponent },
    { path: 'user/reset/success', component: ResetSuccessComponent },
    { path: 'user/reset/:user/:code', component: ResetUpdateComponent },
    { path: 'sso-login', component: SsoLoginComponent }
];
var AuthenticationRoutingModule = /** @class */ (function () {
    function AuthenticationRoutingModule() {
    }
    return AuthenticationRoutingModule;
}());
export { AuthenticationRoutingModule };
export { ɵ0 };
