import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-location-certificates',
  templateUrl: './location-certificates.component.html',
})
export class LocationCertificatesComponent implements OnInit, OnDestroy {
  search$ = new Subject<string>();
  loading = false;
  totalItems = 0;
  rows = [];

  constructor(
    private api: ApiService,
  ) {
    this.search$.pipe(
      untilDestroyed(this),
      switchMap(x => {
        this.loading = true;
        return this.api.get({
          path: `/certificates-per-location?containerMeasurement.bookingReference=${x}&containerMeasurement.containerNumber=${x}`
        }).pipe(catchError(err => of(<any>{
          'hydra:member': [{ containerNumber: err, certificate: '*' }],
          'hydra:totalItems': 1,
        })));
      }),
    ).subscribe(x => {
      this.loading = false;
      this.rows = x['hydra:member'];
      this.totalItems = x['hydra:totalItems'];
    });
  }

  ngOnInit() {
    this.search$.next('');
  }

  ngOnDestroy() { }
}
