export const schedule = {
  LOAD_SCHEDULE: '[Schedule] Load',
  LOAD_SCHEDULE_SUCCEEDED: '[Schedule] Load Success',
  LOAD_SCHEDULE_FAILED: '[Schedule]: Load Fail',
  LOAD_UNPLANNED_TRANSPORTS: '[Schedule] Load Unplanned Transports',
  LOAD_UNPLANNED_TRANSPORTS_SUCCEEDED:
    '[Schedule] Load Unplanned Transports Success',
  LOAD_UNPLANNED_TRANSPORTS_FAILED:
    '[Schedule]: Load Unplanned Transports Fail',
  LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE:
    '[Schedule] Load Next Unplanned Transports Page',
  LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE_SUCCEEDED:
    '[Schedule] Load Next Unplanned Transports Page Success',
  LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE_FAILED:
    '[Schedule]: Load Next Unplanned Transports Page Fail',
  ADD_TO_SCHEDULE: '[Schedule] Add Transport Tasks',
  ADD_TO_SCHEDULE_SUCCEEDED: '[Schedule] Add Transport Tasks Success',
  ADD_TO_SCHEDULE_FAILED: '[Schedule] Add Transport Tasks Fail',
  REMOVE_FROM_SCHEDULE: '[Schedule] Remove Transport Tasks',
  REMOVE_FROM_SCHEDULE_SUCCEEDED: '[Schedule] Remove Transport Tasks Success',
  REMOVE_FROM_SCHEDULE_FAILED: '[Schedule] Remove Transport Tasks Fail',
  UPDATE_SCHEDULE_SEQUENCE: '[Schedule] Update Sequence',
  UPDATE_SCHEDULE_SEQUENCE_SUCCEEDED: '[Schedule] Update Sequence Success',
  UPDATE_SCHEDULE_SEQUENCE_FAILED: '[Schedule] Update Sequence Fail'
};
