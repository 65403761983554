import { IconService } from 'app/services/icon.service';
import { SimpleChanges, OnChanges } from '@angular/core';
var TransportInfoComponent = /** @class */ (function () {
    function TransportInfoComponent(iconService) {
        this.iconService = iconService;
        this.DISPLAY_PUBLIC_LOC_TRANSPORT_TASKS = ['load', 'unload'];
    }
    TransportInfoComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes && changes.transport && changes.transport.currentValue) {
            var newVal = changes.transport.currentValue;
            newVal.transportTasks.forEach(function (task) {
                if (!task.error) {
                    task.error = null;
                    return;
                }
                _this.error = task.error;
            });
        }
    };
    TransportInfoComponent.prototype.getTransportIcon = function () {
        if (!this.transport || !this.transport.transportType) {
            return;
        }
        return this.iconService.getTransportTypeIcon(this.transport.transportType);
    };
    Object.defineProperty(TransportInfoComponent.prototype, "numberOfTasks", {
        get: function () {
            if (!this.transport) {
                return 0;
            }
            return this.transport.transportTasks.length;
        },
        enumerable: true,
        configurable: true
    });
    return TransportInfoComponent;
}());
export { TransportInfoComponent };
