export interface Product {
  type: ProductTypes;
  id?: string;
  label: string;
  template: boolean;
  parts: ProductPart[];
  price: number;
}

export interface ProductPart {
  id?: string;
  description: string;
  quantity: string;
  unitPrice: number;
}

export enum ProductTypes {
  CUSTOMER = 'customer',
  SUPPLIER = 'supplier'
}
