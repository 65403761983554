/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reset.success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./reset.success.component";
var styles_ResetSuccessComponent = [i0.styles];
var RenderType_ResetSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResetSuccessComponent, data: {} });
export { RenderType_ResetSuccessComponent as RenderType_ResetSuccessComponent };
export function View_ResetSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "login-background fullscreen overflow-sm-x-hidden overflow-y"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "row justify-content-center mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "col-sm-8 col-md-6 col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "card reset-card"], ["data-cy", "reset-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "col col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [["class", "btn float-right"], ["data-cy", "return"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_4 = "/"; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("A password reset email has been dispatched.")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("Please check your mailbox. It should contain an email with a link to reset your password.")); _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 14).target; var currVal_3 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("Return to login")); _ck(_v, 15, 0, currVal_5); }); }
export function View_ResetSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-reset-success", [], null, null, null, View_ResetSuccessComponent_0, RenderType_ResetSuccessComponent)), i1.ɵdid(1, 49152, null, 0, i5.ResetSuccessComponent, [i3.ActivatedRoute], null, null)], null, null); }
var ResetSuccessComponentNgFactory = i1.ɵccf("app-cmp-reset-success", i5.ResetSuccessComponent, View_ResetSuccessComponent_Host_0, {}, {}, []);
export { ResetSuccessComponentNgFactory as ResetSuccessComponentNgFactory };
