<div class="row m-3">
  <!-- Transport Form -->
  <div class="col-12 pl-0 col-lg-9">
    <app-cmp-new-marketpost-type
      [transportOffers]="transportOffers.length"
      [transportDemands]="transportDemands.length"
      (postTypeSelected)="postTypeSelected($event)"
    >
    </app-cmp-new-marketpost-type>
    <!-- Market Post offer -->
    <app-cmp-new-marketpost-offer
      *ngIf="postType === 'offer'"
      (transportOfferAdded)="transportOfferAdded($event)"
      [title]="'2. Add/edit a (new) transport' | translate"
      [transportOffer]="transportOffer"
      [index]="transportOfferIndex"
      [transportTypes]="transportTypes"
      [liners]="liners"
      [containerTypes]="containerTypes"
    >
    </app-cmp-new-marketpost-offer>
    <!-- Market Post Demand -->
    <app-cmp-new-marketpost-demand
      *ngIf="postType === 'demand'"
      (transportDemandAdded)="transportDemandAdded($event)"
      [title]="'2. Add/edit a (new) transport' | translate"
      [transportDemand]="transportDemand"
      [index]="transportDemandIndex"
      [transportTypes]="transportTypes"
      [liners]="liners"
      [containerTypes]="containerTypes"
    >
    </app-cmp-new-marketpost-demand>
  </div>
  <!-- Transport List -->
  <div class="col-12 col-lg-3 mt-3 mt-lg-0">
    <div class="sticky">
      <app-cmp-new-transport-list
        (transportOffer)="editTransportOffer($event)"
        (transportDemand)="editTransportDemand($event)"
        [transportOffers]="transportOffers"
        [transportDemands]="transportDemands"
        [liners]="liners"
        [editPostindex]="editPostIndex"
        [containerTypes]="containerTypes"
        [transportTypes]="transportTypes"
        [postType]="postType"
      >
      </app-cmp-new-transport-list>
      <div class="mt-3">
        <app-cmp-share-post
          (selectCircle)="
            selectedCircles = $event.circles; shareType = $event.shareType
          "
          [loading]="loading"
          [transportsListEmpty]="
            transportDemands.length === 0 && transportOffers.length === 0
          "
        >
        </app-cmp-share-post>
        <label
          class="mr-3 custom-control custom-checkbox"
          *ngIf="hasActiveMatchingSubscription"
        >
          <input
            type="checkbox"
            id="matching-check"
            class="custom-control-input"
            [checked]="isMatchingEnabled"
            (change)="isMatchingEnabled = !isMatchingEnabled"
          />
          <span class="custom-control-label"
            >{{ 'Enable matching' | translate }} ({{ matchingCost }}
            {{ 'credits/item' | translate }})</span
          >
        </label>
        <button
          class="btn btn-primary btn-block btn-lg mt-3"
          id="publish-button"
          (click)="publish()"
          [disabled]="
            transportOffers.length === 0 && transportDemands.length === 0
          "
          data-cy="publishMarketpostButton"
        >
          <span
            translate
            class="btn__label text-white"
            [ngClass]="{ invisible: loading }"
            >{{ 'Publish to marketplace' | translate }}</span
          >
          <i
            class="fa fa-spinner fa-spin btn__loader text-white"
            [ngClass]="{ visible: loading }"
          ></i>
        </button>
      </div>
    </div>
  </div>
</div>
