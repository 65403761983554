import { AppConfig } from '../../app.config';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

/**
 * Interface for api requests. Used to send parameter objects to the the get api.
 * You can import it through the index.ts.
 */
export interface ApiCall {
  path?: string;
  params?: any;
  body?: any;
}

/**
 * Service for easily doing api calls.
 */
@Injectable()
export class ApiService {
  private http: HttpClient;

  /**
   * @param http {HttpClient} Httpclient module.
   * @constructor
   */
  constructor(private inj: Injector, private config: AppConfig) {
    this.http = inj.get(HttpClient);
  }

  get apiUrl() {
    return `${this.config.getConfig('apiUrl')}/api/${this.config.getConfig(
      'apiVersion'
    )}`;
  }

  /**
   * Does an api request and returns the observable
   * @param options {ApiCall} The object that should contain the path.
   * @return { Observable }
   */
  public get(options: ApiCall, headers?: {}, responseType?) {
    return headers
      ? this.http.get(this.apiUrl + options.path, {
        headers: headers,
        responseType: responseType
      })
      : this.http.get(this.apiUrl + options.path);
  }

  /**
   * Does an api request to an external source and returns the observable
   * @param options {ApiCall} The object that should contain the path.
   * @return { Observable }
   */
  public getExternal(options: ApiCall) {
    return this.http.get(options.path);
  }

  /**
   * Does an api request and returns the observable
   * @param options {ApiCall} The object that should contain the path and the body
   * @return { Observable }
   */
  public post(options: ApiCall, headers?: {}) {
    if (headers) {
      options.params['headers'] = headers;
    }
    return this.http.post(
      this.apiUrl + options.path,
      options.body,
      options.params
    );
  }

  /**
   * Does an api request and returns the observable
   * @param options {ApiCall} The object that should contain the path and the body
   * @return { Observable }
   */
  public delete(options: ApiCall) {
    return this.http.delete(this.apiUrl + options.path, options.body);
  }

  /**
   * Does an api request and returns the observable
   * @param options {ApiCall} The object that should contain the path and the body
   * @return { Observable }
   */
  public refreshToken(options: ApiCall) {
    options.body.append('grant_type', 'refresh_token');
    options.body.append('client_id', this.config.getConfig('clientId'));
    options.body.append('client_secret', this.config.getConfig('clientSecret'));
    return this.http.post(
      this.config.getConfig('oauthUrl'),
      options.body.toString(),
      {
        headers: {
          Authorization: 'Bearer ',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  /**
   * Does an api request and returns the observable
   * @param options {ApiCall} The object that should contain the path and the body
   * @return { Observable }
   */
  public getToken(options: HttpParams) {
    options = options
      .append('grant_type', 'password')
      .append('client_id', this.config.getConfig('clientId'))
      .append('client_secret', this.config.getConfig('clientSecret'));
    return this.http.post(
      this.config.getConfig('oauthUrl'),
      options.toString(),
      {
        headers: {
          Authorization: 'Bearer ',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  /**
   * Does an api request and returns the observable
   * @param options {ApiCall} The object that should contain the path and the body
   * @return { Observable }
   */
  public put(options: ApiCall, headers?: {}) {
    return headers
      ? this.http.put(this.apiUrl + options.path, options.body, {
        headers: headers
      })
      : this.http.put(this.apiUrl + options.path, options.body);
  }
}
