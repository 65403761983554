import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';

import * as types from '../types/shipping-agent.types';
import * as ShippingAgentActions from '../actions/shipping-agent.actions';
import * as NotificationsActions from '../actions/notifications.actions';

@Injectable()
export class ShippingAgentEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadShippingAgents$: Observable<Action> = this.actions$.pipe(
    ofType(types.shippingAgent.LOAD_SHIPPING_AGENTS),
    map((action: ShippingAgentActions.LoadShippingAgents) => action.payload),
    concatMap(() => this.api.get({ path: '/shipping_agents' }).pipe(
      map(data => new ShippingAgentActions.LoadShippingAgentsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );
}
