import * as CertiWeightActions from '../actions/certiweight.actions';
import * as types from '../types/certiweight.types';

export interface CertiweightState {
  defaultPrice: number;
}

const INITIAL_STATE = {
  defaultPrice: null,
};

export function reducer(
  state = INITIAL_STATE,
  action: CertiWeightActions.All
): CertiweightState {
  switch (action.type) {
    case types.certiweight.LOAD_DEFAULT_CERTIFICATE_PRICE_SUCCEEDED:
      return Object.assign({}, { defaultPrice: action.payload['hydra:member'][0].credits });
  }
  return state;
}
