<div class="p-3">
  <app-transport-form
    [transport]="transport"
    [transportTypes]="transportTypes"
    [customerTariffs]="customerTariffs"
    [supplierTariffs]="supplierTariffs"
    [customers]="customers"
    [suppliers]="suppliers"
    [liners]="liners"
    [containerTypes]="containerTypes"
    [isLoadingCustomers]="isLoadingCustomers"
    [loading]="loading"
    (submitAndContinueEnabled)="submitAndContinue = $event"
    (transportFormSubmitted)="onSubmit($event)"
    (matchingStateChanged)="toggleMatchingState($event)"
  >
  </app-transport-form>
</div>
