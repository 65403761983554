import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as UserTypes from 'app/core/store/types/user.types';
import { ResetUser } from 'app/core/store/actions/user.actions';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-login',
  templateUrl: './reset.component.html'
})
export class ResetComponent implements OnDestroy {
  email: '';
  success = false;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    updates$
      .pipe(ofType(UserTypes.users.RESET_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (
          data['payload'] &&
          data['payload'].status &&
          data['payload'].status === 404
        ) {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: 'We couldn\'t find a user with this email.'
          });
        } else {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message:
              'Something went wrong while trying to reset your password. Please contact us at support@hakka.eu.'
          });
        }
      });
  }

  ngOnDestroy() { }

  reset() {
    this.success = true;
    this.store.dispatch(new ResetUser({ email: this.email }));
  }
}
