import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { Tenant } from '../../store/models/tenant.model';

@Component({
  selector: 'app-cmp-tenant-list',
  templateUrl: './tenant-list.component.html'
})
export class TenantListComponent implements OnChanges {
  @Input() flush;
  @Input() tenants: Tenant[];

  sortedTenants: Tenant[];

  @Output() pick = new EventEmitter<Number>();

  // Sort tenants on changes.
  ngOnChanges() {
    this.sortTenants();
  }

  // Sort them tenants
  sortTenants() {
    if (this.tenants) {
      this.sortedTenants = this.tenants.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        return aName > bName ? 1 : aName < bName ? -1 : 0;
      });
    }
  }

  pickTenant(tenantId: Number) {
    this.pick.emit(tenantId);
  }
}
