import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Product, ProductPart } from 'app/core/store/models/product.model';

@Component({
  selector: 'app-tariff-table',
  templateUrl: './tariff-table.component.html',
  styleUrls: ['./tariff-table.component.scss']
})
export class TariffTableComponent implements OnInit, OnChanges {
  @Input()
  tariff: Product;
  @Input()
  selectedTemplate: Product;
  @Input()
  disabled: boolean;

  @Output()
  tariffChanged = new EventEmitter<Product>();

  tariffForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (!this.tariffForm) {
      this.createForm();
      this.onChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.selectedTemplate &&
      changes.selectedTemplate.currentValue
    ) {
      let tariff = Object.assign({}, changes.selectedTemplate.currentValue);
      tariff = {
        id: tariff.id,
        label: tariff.label,
        parts: tariff.parts.map((part: ProductPart) => {
          return {
            description: part.description,
            quantity: part.quantity,
            unitPrice: part.unitPrice,
            itemTotal: +part.quantity * part.unitPrice
          };
        }),
        price: tariff.price
      };
      this.createForm();
      for (let index = 1; index < tariff.parts.length; index++) {
        this.addTariffItem();
      }
      this.tariffForm.patchValue({
        id: tariff.id,
        label: tariff.label,
        parts: tariff.parts,
        price: tariff.price
      });
      this.onChanges();
    }
  }

  onChanges() {
    this.tariffForm.valueChanges.subscribe(tariffFormValue => {
      const value = Object.assign({}, tariffFormValue);
      this.tariffChanged.emit(value);
    });
  }

  createForm() {
    this.tariffForm = this.fb.group({
      id: [null],
      label: [null, Validators.required],
      parts: this.fb.array([this.createTariffItem()]),
      price: [null]
    });
  }

  createTariffItem(): FormGroup {
    const group = this.fb.group({
      description: [null, Validators.required],
      quantity: [1, Validators.required],
      unitPrice: [null, Validators.required],
      itemTotal: [null]
    });
    return group;
  }

  addTariffItem(): void {
    const tariffItems = <FormArray>this.tariffForm.controls['parts'];
    tariffItems.push(this.createTariffItem());
  }
  removeTariffItem(index): void {
    const tariffItems = <FormArray>this.tariffForm.controls['parts'];
    tariffItems.removeAt(index);
  }

  updateItemTotal(index) {
    const part = this.tariffForm.get('parts').value[index];
    const unitPrice = part['unitPrice'];
    const quantity = part['quantity'];
    const total = unitPrice * quantity;
    const tariffItems = <FormArray>this.tariffForm.controls['parts'];
    tariffItems.at(index).patchValue({
      itemTotal: total
    });
  }
  get total() {
    if (!this.tariffForm) {
      return;
    }
    const tariffParts = JSON.parse(
      JSON.stringify(
        this.tariffForm.controls['parts'].value.filter(
          part => part.quantity && part.unitPrice
        )
      )
    );
    if (!tariffParts || tariffParts.length === 0) {
      return 0;
    }
    if (tariffParts.length === 1) {
      return tariffParts[0].quantity * tariffParts[0].unitPrice;
    }
    return tariffParts
      .map(part => part.quantity * part.unitPrice)
      .reduce((part1, part2) => part1 + part2);
  }
  onSubmit() {
    // if no lines except last one exist, add one
    // mark all required empty inputs as touched (except last line)
    const tariffItems = <FormArray>this.tariffForm.controls['parts'];
    if (tariffItems.length === 1) {
      this.addTariffItem();
    }
    for (let i = 0; i < tariffItems.length - 1; i++) {
      const group = <FormGroup>tariffItems.controls[i];
      group.controls.description.markAsTouched();
      group.controls.quantity.markAsTouched();
      group.controls.unitPrice.markAsTouched();
    }
  }
}
