<ngb-alert *ngIf="alert" [type]="alert.type" (close)="closeAlert()">
  <i class="far" [ngClass]="alert.icon"></i>
  <span>{{ alert.message | translate }}</span>
</ngb-alert>
<div class="row">
  <div class="col">
    <div class="card">
      <h3 class="card-header text-center">
        <span class="badge badge-dark" placement="left" ngbTooltip="{{ 'Quantity' | translate }}">{{ marketPost?._source.quantity }}</span>
        {{ marketPost?._source.transportType.label | translate }} {{ 'Demand' | translate }} - {{ marketPost?._source.tenant.name
        }}
      </h3>
      <div class="card-body pb-0 d-flex flex-wrap">
        <!-- Origin Card -->
        <div class="card mb-3 col">
          <div class="card-body">
            <h4 class="text-center">{{ 'Origin' | translate }}</h4>
            <hr>
            <div>
              <i class="far fa-map-marker" ngbTooltip="{{ 'Location' | translate }}"></i>
              <span *ngIf="marketPost?._source.originLocation; else noOrigin">{{ marketPost?._source.originLocation.name || 'Unknown' }}</span>
              <ng-template #noOrigin>{{ 'Unknown' }}</ng-template>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <div>
                  <i class="far fa-calendar-alt" ngbTooltip="{{ 'Date' | translate }}"></i>
                  <span *ngIf="marketPost?._source.originTimeIntervalStart; else noDate">
                    {{ marketPost?._source.originTimeIntervalStart | date:'dd/MM/yyyy'}}
                  </span>
                  <ng-template #noDate>
                    <span>{{ 'N.A.' }}</span>
                  </ng-template>
                </div>
                <div *ngIf="marketPost?._source.originTimeIntervalStartTimeSpecified">
                  <i class="far fa-clock" ngbTooltip="{{ 'Time' | translate }}"></i>
                  <span>{{ marketPost?._source.originTimeIntervalStart | date:'HH:mm' }}</span>
                </div>
              </div>
              <div>
                <i class="far fa-arrow-right"></i>
              </div>
              <div>
                <div>
                  <i class="far fa-calendar-alt" ngbTooltip="{{ 'Date' | translate }}"></i>
                  <span *ngIf="marketPost?._source.originTimeIntervalEnd; else noDate">{{ marketPost?._source.originTimeIntervalEnd | date:'dd/MM/yyyy' }}</span>
                  <ng-template #noDate>
                    <span>{{ 'N.A.' }}</span>
                  </ng-template>
                </div>
                <div *ngIf="marketPost?._source.originTimeIntervalEndTimeSpecified">
                  <i class="far fa-clock" ngbTooltip="{{ 'Time' | translate }}"></i>
                  <span>{{ marketPost?._source.originTimeIntervalEnd | date:'HH:mm' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Destination Card -->
        <div class="card mb-3 col">
          <div class="card-body">
            <h4 class="text-center">{{ 'Destination' | translate }}</h4>
            <hr>
            <div>
              <i class="far fa-map-marker" ngbTooltip="{{ 'Location' | translate }}"></i>
              <span *ngIf="marketPost?._source.destinationLocation; else noDestination">{{ marketPost?._source.destinationLocation.name }}</span>
              <ng-template #noDestination>{{ 'Unknown' | translate }}</ng-template>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <div>
                  <i class="far fa-calendar-alt" ngbTooltip="{{ 'Date' | translate }}"></i>
                  <span *ngIf="marketPost?._source.destinationTimeIntervalStart; else noDate">{{ marketPost?._source.destinationTimeIntervalStart | date:'dd-MM-yyyy' }}</span>
                  <ng-template #noDate>
                    <span>{{ 'N.A.' }}</span>
                  </ng-template>
                </div>
                <div *ngIf="marketPost?._source.destinationTimeIntervalStartTimeSpecified">
                  <i class="far fa-clock" ngbTooltip="{{ 'Time' | translate }}"></i>
                  <span>{{ marketPost?._source.destinationTimeIntervalStart | date:'HH:mm' }}</span>
                </div>
              </div>
              <div>
                <i class="far fa-arrow-right"></i>
              </div>
              <div>
                <div>
                  <i class="far fa-calendar-alt" ngbTooltip="{{ 'Date' | translate }}"></i>
                  <span *ngIf="marketPost?._source.destinationTimeIntervalEnd; else noDate">{{ marketPost?._source.destinationTimeIntervalEnd | date:'dd-MM-yyyy' }}</span>
                  <ng-template #noDate>
                    <span>{{ 'N.A.' }}</span>
                  </ng-template>
                </div>
                <div *ngIf="marketPost?._source.destinationTimeIntervalEndTimeSpecified">
                  <i class="far fa-clock" ngbTooltip="{{ 'Time' | translate }}"></i>
                  <span>{{ marketPost?._source.destinationTimeIntervalEnd | date:'HH:mm' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Container Card -->
        <div class="card mb-3 col">
          <div class="card-body">
            <h4 class="text-center">{{ 'Container' | translate }}</h4>
            <hr>
            <div>
              <i class="far fa-building" ngbTooltip="{{ 'Liner' | translate }}"></i>
              <span *ngIf="marketPost?._source.container.liner; else noLiner">
                {{ marketPost?._source.container.liner.shortName ? marketPost?._source.container.liner.shortName : marketPost?._source.container.liner.name
                }}
              </span>
              <ng-template #noLiner>
                <span>{{ 'N.A.' | translate }}</span>
              </ng-template>
            </div>
            <div>
              <i class="far fa-cube" ngbTooltip="{{ 'Container Type' | translate }}"></i>
              <span *ngIf="marketPost?._source.container.containerType; else noCType">{{ marketPost?._source.container.containerType.label}}</span>
              <ng-template #noCType>
                <span>{{ 'N.A.' }}</span>
              </ng-template>
            </div>
            <div *ngIf="marketPost?._source.container.adr">
              <i class="far fa-exclamation-triangle" ngbTooltip="ADR"></i>
              <span>ADR</span>
            </div>
            <div>
              <i class="far fa-balance-scale" ngbTooltip="{{ 'Weight' | translate }}"></i>
              <span>{{ marketPost?._source.container.weight || 'N.A.' }}</span>
            </div>
            <div>
              <i class="far fa-id-badge" ngbTooltip="{{ 'Reference' | translate }}"></i>
              <span>{{ marketPost?._source.reference || 'N.A.' }}</span>
            </div>
            <div>
              <i class="far fa-comment-alt" ngbTooltip="{{ 'Coment' | translate }}"></i>
              <span>{{ marketPost?._source.comment || 'N.A.' }}</span>
            </div>
          </div>
        </div>
      </div>
      <form class="card-body" (ngSubmit)="onSubmit()" [formGroup]="replyForm">
        <textarea class="form-control" placeholder="{{ 'Type your reply here' | translate }}" formControlName="text" cols="20" rows="5"></textarea>
        <button class="btn mt-3 btn-primary submit-proposal-button text-white" type="submit" [disabled]="replyForm.pristine || replyForm.invalid|| loading">
          <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
          <span>{{ 'Reply' | translate }}</span>
        </button>
      </form>
    </div>
  </div>
</div>
