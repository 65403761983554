var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// import { isNumber } from 'util';
export function toInteger(value) {
    return parseInt("" + value, 10);
}
export function isNumber(value) {
    return !isNaN(toInteger(value));
}
var NgbDateNativeAdapter = /** @class */ (function (_super) {
    __extends(NgbDateNativeAdapter, _super);
    function NgbDateNativeAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateNativeAdapter.prototype.fromModel = function (date) {
        return date && date.getFullYear
            ? {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
            }
            : null;
    };
    NgbDateNativeAdapter.prototype.toModel = function (date) {
        return date ? new Date(date.year, date.month - 1, date.day) : null;
    };
    return NgbDateNativeAdapter;
}(NgbDateAdapter));
export { NgbDateNativeAdapter };
var NgbDateCustomParserFormatter = /** @class */ (function (_super) {
    __extends(NgbDateCustomParserFormatter, _super);
    function NgbDateCustomParserFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbDateCustomParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { day: +dateParts[0], month: null, year: null };
            }
            else if (dateParts.length === 2 &&
                isNumber(dateParts[0]) &&
                isNumber(dateParts[1])) {
                return {
                    day: +dateParts[0],
                    month: +dateParts[1],
                    year: null
                };
            }
            else if (dateParts.length === 3 &&
                isNumber(dateParts[0]) &&
                isNumber(dateParts[1]) &&
                isNumber(dateParts[2])) {
                return {
                    day: +dateParts[0],
                    month: +dateParts[1],
                    year: +dateParts[2]
                };
            }
        }
        return null;
    };
    NgbDateCustomParserFormatter.prototype.padDigits = function (number, digits) {
        return (Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number);
    };
    NgbDateCustomParserFormatter.prototype.format = function (date) {
        return date
            ? (isNumber(date.day) ? this.padDigits(date.day, 2) : '') + "/" + (isNumber(date.month) ? this.padDigits(date.month, 2) : '') + "/" + date.year
            : '';
    };
    return NgbDateCustomParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateCustomParserFormatter };
