<form [formGroup]="userContextForm">
  <div class="form-group">
    <label>{{ 'Email' | translate }}</label>
    <input
      class="form-control"
      formControlName="email"
      data-cy="email"
      placeholder="{{ 'Enter valid email address here' | translate }}"
      type="email"
      [ngClass]="{
        'is-invalid':
          (userContextForm?.controls)['email'].invalid &&
          (userContextForm?.controls)['email'].touched
      }"
    />
    <div class="invalid-feedback">{{ 'Email is required.' | translate }}</div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label>{{ 'First Name' | translate }}</label>
        <input
          class="form-control"
          formControlName="firstName"
          data-cy="first-name"
          placeholder="{{ 'Enter first name here' | translate }}"
          [ngClass]="{
            'is-invalid':
              (userContextForm?.controls)['firstName'].invalid &&
              (userContextForm?.controls)['firstName'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'First name is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>{{ 'Last Name' | translate }}</label>
        <input
          class="form-control"
          formControlName="lastName"
          data-cy="last-name"
          placeholder="{{ 'Enter last name here' | translate }}"
          [ngClass]="{
            'is-invalid':
              (userContextForm?.controls)['lastName'].invalid &&
              (userContextForm?.controls)['lastName'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Last name is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label>{{ 'Phone Number' | translate }}</label>
        <input
          class="form-control"
          formControlName="phoneNumber"
          data-cy="phone-number"
          placeholder="{{ 'Enter phone number here' | translate }}"
          [ngClass]="{
            'is-invalid':
              (userContextForm?.controls)['phoneNumber'].invalid &&
              (userContextForm?.controls)['phoneNumber'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Phone number is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">
          {{ 'Prefered language' | translate }}</label
        >
        <ng-select
          id="language"
          [items]="languages"
          bindLabel="label"
          bindValue="value"
          data-cy="language"
          placeholder="Select Language"
          formControlName="locale"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div *ngIf="driver" class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block"> {{ 'License plate' | translate }}</label>
        <input
          class="form-control"
          formControlName="licensePlate"
          data-cy="license-plate"
          placeholder="{{ 'Enter license plate number here' | translate }}"
          [ngClass]="{
            'is-invalid':
              (userContextForm?.controls)['phoneNumber'].invalid &&
              (userContextForm?.controls)['phoneNumber'].touched
          }"
        />
      </div>
    </div>
  </div>
  <div class="form-group" *ngIf="!driver">
    <div>
      <label>{{ 'Roles' | translate }}</label>
    </div>
    <label *ngIf="driver" class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="isDriver"
      />
      <span data-cy="is-driver" class="custom-control-label">{{
        'Driver' | translate
      }}</span>
    </label>
    <label class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="isPlanner"
        (change)="toggleAdmin()"
      />
      <span data-cy="is-planner" class="custom-control-label">{{
        'Planner' | translate
      }}</span>
    </label>
    <label class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="isAdmin"
        (change)="togglePlanner()"
      />
      <span data-cy="is-admin" class="custom-control-label">{{
        'Admin' | translate
      }}</span>
    </label>
  </div>
  <div>
    <button
      type="submit"
      (click)="onSubmit()"
      data-cy="submit"
      class="btn btn-primary text-white"
      [disabled]="
        loading || userContextForm?.invalid || userContextForm?.pristine
      "
    >
      <span translate class="btn__label" [ngClass]="{ invisible: loading }">{{
        buttonText | translate
      }}</span>
      <i
        class="fa fa-spinner fa-spin btn__loader"
        [ngClass]="{ visible: loading }"
      ></i>
    </button>
    <ng-content></ng-content>
  </div>
</form>
