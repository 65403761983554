var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Go } from '../actions/router.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import * as types from '../types/tenant.types';
import * as TenantActions from '../actions/tenants.actions';
var TenantEffects = /** @class */ (function () {
    function TenantEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadTenants$ = this.actions$.pipe(ofType(types.tenants.LOAD_TENANTS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/me/tenants'
        }).pipe(map(function (data) { return new TenantActions.LoadTenantsSuccess(data); }), tap(function (data) {
            if (payload && payload.onSuccess) {
                payload.onSuccess(data);
            }
            if (data.payload['hydra:totalItems'] === 1) {
                return new Go({ path: ['/marketplace'] });
            }
        }), catchError(function (error) {
            if (payload && payload.onFailure) {
                payload.onFailure(error);
            }
            return of(new TenantActions.LoadTenantsFailed(error));
        })); }));
        this.loadAllTenants$ = this.actions$.pipe(ofType(types.tenants.LOAD_ALL_TENANTS), concatMap(function () { return _this.api.get({ path: '/tenants' }).pipe(map(function (data) { return new TenantActions.LoadAllTenantsSuccess(data); }), catchError(function (error) { return of(new TenantActions.LoadTenantsFailed(error)); })); }));
        this.createTenant$ = this.actions$.pipe(ofType(types.tenants.CREATE_TENANT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({ path: '/tenants', body: payload }).pipe(map(function (data) { return new TenantActions.CreateTenantSuccess(data); }), catchError(function (error) { return of(new TenantActions.CreateTenantFail(error)); })); }));
        this.enableTenant$ = this.actions$.pipe(ofType(types.tenants.ENABLE_TENANT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/tenants/' + payload + '/enable', body: {}
        }).pipe(map(function (data) { return new TenantActions.EnableTenantSuccess(data); }), catchError(function (error) { return of(new TenantActions.EnableTenantFail(error)); })); }));
        this.disableTenant$ = this.actions$.pipe(ofType(types.tenants.DISABLE_TENANT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/tenants/' + payload + '/disable', body: {}
        }).pipe(map(function (data) { return new TenantActions.DisableTenantSuccess(data); }), catchError(function (error) { return of(new TenantActions.DisableTenantFail(error)); })); }));
        this.deleteTenant$ = this.actions$.pipe(ofType(types.tenants.DELETE_TENANT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: '/tenants/' + payload, body: {}
        }).pipe(map(function (data) { return new TenantActions.DeleteTenantSuccess(data); }), catchError(function (error) { return of(new TenantActions.DeleteTenantFail(error)); })); }));
        this.updateTenant$ = this.actions$.pipe(ofType(types.tenants.UPDATE_TENANT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/tenants/' + payload.id, body: payload.body
        }).pipe(map(function (data) { return new TenantActions.UpdateTenantSuccess(data); }), catchError(function (error) { return of(new TenantActions.UpdateTenantFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TenantEffects.prototype, "loadTenants$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TenantEffects.prototype, "loadAllTenants$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TenantEffects.prototype, "createTenant$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TenantEffects.prototype, "enableTenant$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TenantEffects.prototype, "disableTenant$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TenantEffects.prototype, "deleteTenant$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TenantEffects.prototype, "updateTenant$", void 0);
    return TenantEffects;
}());
export { TenantEffects };
