import {
  Component,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as DateFns from 'date-fns';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss']
})
export class DaterangepickerComponent {
  @Input()
  fromDate: NgbDateStruct;
  @Input()
  toDate: NgbDateStruct;
  @Input()
  markDisabledBeforeMindate: boolean;
  @Input()
  displayMonths = 2;
  @Input()
  minDate: NgbDateStruct;

  @Output()
  dateSelected = new EventEmitter<{
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
  }>();

  showDatePicker = false;
  hoveredDate: NgbDateStruct;
  originalFromDate: NgbDateStruct;
  originalToDate: NgbDateStruct;
  selection: any;

  getMonth(date) {
    if (date) {
      return DateFns.format(
        new Date(date.year, date.month - 1, date.day),
        'MMMM'
      );
    }
  }

  onDateSelection(date: NgbDateStruct) {
    if (!this.originalFromDate) {
      this.originalFromDate = this.fromDate;
    }
    if (!this.originalToDate) {
      this.originalToDate = this.toDate;
    }
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.dateSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }

  isSameDate(date1, date2) {
    return DateFns.isEqual(
      new Date(date1.year, date1.month - 1, date1.day),
      new Date(date2.year, date2.month - 1, date2.day)
    );
  }

  markDisabled(date: NgbDate) {
    if (!date) {
      return;
    }
    if (!this.markDisabledBeforeMindate) {
      return false;
    }
    return date.before(this.minDate);
  }

  isHovered = date =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    before(date, this.hoveredDate)
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
}
