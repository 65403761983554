import { Alert } from '../../store/models/alert.model';
import { ApiService } from '../../api/api.service';
import { Component } from '@angular/core';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-new-location',
  templateUrl: './new-location.component.html',
})
export class NewLocationComponent {
  alert: Alert;

  constructor(private api: ApiService, private toastr: ToastService) {}

  createLocation(locationFormValue) {
    locationFormValue.extraData = JSON.parse(locationFormValue.extraData);
    this.api
      .post({
        path: '/locations',
        body: locationFormValue
      })
      .subscribe(
        () => {
          this.toastr.showSuccess({
            message: 'Location created'
          });
        },
        err => {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: err
          });
        }
      );
  }
}
