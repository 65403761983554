/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../shared/loading/loading.component.ngfactory";
import * as i3 from "../../shared/loading/loading.component";
import * as i4 from "./tenant-list/tenant-list.component.ngfactory";
import * as i5 from "./tenant-list/tenant-list.component";
import * as i6 from "./tenant-picker.component";
import * as i7 from "@ngrx/store";
var styles_TenantPickerComponent = [];
var RenderType_TenantPickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TenantPickerComponent, data: {} });
export { RenderType_TenantPickerComponent as RenderType_TenantPickerComponent };
export function View_TenantPickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "login-background fullscreen overflow-sm-x-hidden overflow-y"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 19, "div", [["class", "row justify-content-center mt-5 mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 18, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 17, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "card-title card-title-bb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "far fa-info-circle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 1, "app-cmp-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i0.ɵdid(14, 49152, null, 0, i3.LoadingComponent, [], { loading: [0, "loading"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 1, "app-cmp-tenant-list", [], null, [[null, "pick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pick" === en)) {
        var pd_0 = (_co.pickTenant($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TenantListComponent_0, i4.RenderType_TenantListComponent)), i0.ɵdid(16, 573440, null, 0, i5.TenantListComponent, [], { flush: [0, "flush"], tenants: [1, "tenants"] }, { pick: "pick" }), (_l()(), i0.ɵeld(17, 0, null, null, 3, "div", [["class", "card-footer text-muted text-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "button", [["class", "btn btn-small btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(19, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.loading; _ck(_v, 14, 0, currVal_2); var currVal_3 = true; var currVal_4 = _co.tenants; _ck(_v, 16, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("Choose a company")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("Your account is linked to multiple companies. Pick the company you want to activate.")); _ck(_v, 11, 0, currVal_1); var currVal_5 = i0.ɵunv(_v, 19, 0, i0.ɵnov(_v, 20).transform("Logout")); _ck(_v, 19, 0, currVal_5); }); }
export function View_TenantPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-tenant-picker", [], null, null, null, View_TenantPickerComponent_0, RenderType_TenantPickerComponent)), i0.ɵdid(1, 180224, null, 0, i6.TenantPickerComponent, [i7.Store], null, null)], null, null); }
var TenantPickerComponentNgFactory = i0.ɵccf("app-cmp-tenant-picker", i6.TenantPickerComponent, View_TenantPickerComponent_Host_0, { hideTitle: "hideTitle" }, {}, []);
export { TenantPickerComponentNgFactory as TenantPickerComponentNgFactory };
