export const purchaseOrder = {
  LOAD_PURCHASE_ORDERS: '[Purchase orders] Load',
  LOAD_PURCHASE_ORDERS_SUCCEEDED: '[Purchase orders] Load Success',
  LOAD_PURCHASE_ORDERS_FAILED: '[Purchase orders] Load Fail',
  LOAD_FILTERED_PURCHASE_ORDERS: '[Purchase orders] Load filtered',
  LOAD_FILTERED_PURCHASE_ORDERS_SUCCEEDED:
    '[Purchase orders] Load filtered Success',
  LOAD_FILTERED_PURCHASE_ORDERS_FAILED: '[Purchase orders] Load filtered Fail',
  CREATE_PURCHASE_ORDER: '[Purchase order] Create',
  CREATE_PURCHASE_ORDER_SUCCEEDED: '[Purchase order] Create Success',
  CREATE_PURCHASE_ORDER_FAILED: '[Purchase order] Create Fail',
  DELETE_PURCHASE_ORDER: '[Purchase order] Delete',
  DELETE_PURCHASE_ORDER_SUCCEEDED: '[Purchase order] Delete Success',
  DELETE_PURCHASE_ORDER_FAILED: '[Purchase order] Delete Fail',
  UPDATE_PURCHASE_ORDER: '[Purchase order] Update',
  UPDATE_PURCHASE_ORDER_SUCCEEDED: '[Purchase order] Update Success',
  UPDATE_PURCHASE_ORDER_FAILED: '[Purchase order] Update Fail',
  CLEAR: '[Purchase orders] Clear'
};
