<div class="search-container mb-3 ml-3 mr-3">
  <app-search (search)="handleSearch($event)"></app-search>
  <button class="btn new-location" routerLink="/settings/locations/new">
    <i class="far fa-plus fa-fw"></i>
    <span>{{ 'New location' | translate }}</span>
  </button>
</div>
<ngb-tabset [justify]="'fill'">
  <ngb-tab [id]="'OTHER'">
    <ng-template ngbTabTitle>{{
      'Depots / Terminals' | translate
    }}</ng-template>
    <ng-template ngbTabContent>
      <ngx-datatable
        #locationTable
        class="bootstrap ml-0 mr-0 mt-3"
        [rows]="otherLocations"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [externalPaging]="false"
        [loadingIndicator]="isLoadingLocations"
        [count]="totalOtherLocations"
        [limit]="30"
      >
        <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
        <ngx-datatable-column
          name="Address"
          prop="fullAddress"
        ></ngx-datatable-column>
      </ngx-datatable>
    </ng-template>
  </ngb-tab>
  <ngb-tab [id]="'USER'">
    <ng-template ngbTabTitle>{{ 'Addressbook' | translate }}</ng-template>
    <ng-template ngbTabContent>
      <ngx-datatable
        #locationTable
        class="bootstrap ml-0 mr-0 mt-3"
        [rows]="privateLocations"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [externalPaging]="false"
        [loadingIndicator]="isLoadingLocations"
        [count]="totalPrivateLocations"
        [limit]="30"
      >
        <ngx-datatable-column
          name="{{ 'Name' | translate }}"
          prop="name"
        ></ngx-datatable-column>
        <ngx-datatable-column
          name="Address"
          prop="fullAddress"
        ></ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'Marketplace Location' | translate }}"
          prop="publicLocation"
        >
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
          >
            {{ value.name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" prop="">
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
          >
            <button
              class="btn btn-info"
              (click)="edit(row.id)"
              [disabled]="row.locationType?.code !== 'USER'"
            >
              <i class="fa-fw far fa-pencil"></i>
              <span>{{ 'Edit' | translate }}</span>
            </button>
            <button
              class="btn"
              (click)="deleteLocation(row.id)"
              [disabled]="row.locationType?.code !== 'USER'"
            >
              <i class="fa-fw far fa-trash-alt"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
