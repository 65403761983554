/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../core/admin/tenant-settings/user-contexts/user-context-form/user-context-form.component.ngfactory";
import * as i2 from "../../../core/admin/tenant-settings/user-contexts/user-context-form/user-context-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../services/i18n.service";
import * as i6 from "./edit-driver.component";
import * as i7 from "@ngrx/effects";
import * as i8 from "../../../services/toast.service";
import * as i9 from "@angular/router";
var styles_EditDriverComponent = [];
var RenderType_EditDriverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditDriverComponent, data: {} });
export { RenderType_EditDriverComponent as RenderType_EditDriverComponent };
export function View_EditDriverComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-cmp-user-context-form", [], null, [[null, "userContextFormValue"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userContextFormValue" === en)) {
        var pd_0 = (_co.updateDriver($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_UserContextFormComponent_0, i1.RenderType_UserContextFormComponent)), i0.ɵdid(2, 770048, null, 0, i2.UserContextFormComponent, [i3.FormBuilder, i4.Store, i5.I18nService], { userContext: [0, "userContext"], loading: [1, "loading"], driver: [2, "driver"] }, { userContextFormValue: "userContextFormValue" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userContext; var currVal_1 = _co.isLoading; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_EditDriverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-edit-driver", [], null, null, null, View_EditDriverComponent_0, RenderType_EditDriverComponent)), i0.ɵdid(1, 180224, null, 0, i6.EditDriverComponent, [i4.Store, i7.Actions, i8.ToastService, i9.Router, i9.ActivatedRoute], null, null)], null, null); }
var EditDriverComponentNgFactory = i0.ɵccf("app-cmp-edit-driver", i6.EditDriverComponent, View_EditDriverComponent_Host_0, {}, {}, []);
export { EditDriverComponentNgFactory as EditDriverComponentNgFactory };
