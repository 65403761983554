import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
})
export class ConfirmActionModalComponent {
  @Input()
  title = 'Confirmation required';
  @Input()
  message: string;
  @Input()
  cancelButtonText = 'Cancel';
  @Input()
  cancelButtonClass: string;
  @Input()
  confirmButtonText = 'Confirm';
  @Input()
  confirmButtonClass = 'btn-danger';

  constructor(private modal: NgbActiveModal) {}

  handleConfirm() {
    this.modal.close(true);
  }
  handleCancel() {
    this.modal.dismiss();
  }
}
