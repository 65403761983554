var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApiService } from 'app/core/api/api.service';
import * as CertiweightActions from '../actions/certiweight.actions';
import * as types from '../types/certiweight.types';
var CertiweightEffects = /** @class */ (function () {
    function CertiweightEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadDefaultCertificatePrice$ = this.actions$.pipe(ofType(types.certiweight.LOAD_DEFAULT_CERTIFICATE_PRICE), concatMap(function () { return _this.api.get({
            path: '/subscription_type_actions?code=CERTIWEIGHT_BUY_CERTIFICATE'
        }).pipe(map(function (data) { return new CertiweightActions.LoadDefaultCertificatePriceSuccess(data); }), catchError(function (error) { return of(new CertiweightActions.LoadDefaultCertificatePriceFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CertiweightEffects.prototype, "loadDefaultCertificatePrice$", void 0);
    return CertiweightEffects;
}());
export { CertiweightEffects };
