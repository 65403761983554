import { FormGroup, FormBuilder } from '@angular/forms';
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Address } from 'app/core/store/models/address.model';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnChanges {
  @Input()
  address: Address;
  @Input()
  isLoading: boolean;
  @Input()
  countries;
  @Output()
  addressFormSubmitted = new EventEmitter();

  addressForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.address) {
      if (!this.addressForm) {
        this.createForm();
      }
      const address = changes.address.currentValue as Address;
      this.addressForm.patchValue({
        attention: address.attention,
        streetAndNumber: address.streetAndNumber,
        city: address.city,
        zipCode: address.zipCode,
        country: address.country
      });
    }
  }

  createForm() {
    this.addressForm = this.fb.group({
      attention: [null],
      streetAndNumber: [null],
      city: [null],
      zipCode: [null],
      country: [null]
    });
  }

  onSubmit() {
    if (this.addressForm.invalid) {
      return;
    }
    this.addressFormSubmitted.emit(this.addressForm.value);
  }
}
