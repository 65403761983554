<div class="search-container mb-3 ml-3 mr-3">
  <app-search
    [placeholder]="searchPlaceholder"
    (search)="handleSearch($event)"
  ></app-search>
  <button class="btn" routerLink="/crm/tax-rates/new" id="new-tax-rate-button">
    <i class="far fa-plus fa-fw"></i>
    <span>{{ 'New tax rate' | translate }}</span>
  </button>
</div>
<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [rows]="filteredTaxRates"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="false"
  [count]="totalElements"
  [limit]="30"
  [messages]="tableMessages"
  [loadingIndicator]="isLoadingTaxRates"
>
  <div>
    <ngx-datatable-column name="{{ 'Label' | translate }}" prop="label">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{ 'Rate %' | translate }}" prop="rate">
    </ngx-datatable-column>
    <ngx-datatable-column
      [cellClass]="'datatable-actions'"
      name="{{ 'Details' | translate }}"
      prop=""
      [width]="140"
    >
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <button class="btn" (click)="editTaxRate(row.id)">
          <i class="far fa-pencil"></i>
        </button>
        <button (click)="deleteTaxRate(row)" class="btn">
          <i class="far fa-trash-alt"></i>
        </button>
      </ng-template>
    </ngx-datatable-column>
  </div>
</ngx-datatable>
