import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Observable } from 'rxjs';
import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { TransportType } from 'app/core/store/models/transport-types.model';
import {
  DisableTransportTypeNotificationSubscription,
  EnableTransportTypeNotificationSubscription
} from 'app/core/store/actions/notification-subscriptions.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-transport-type-notification-subscriptions',
  templateUrl: 'transport-type-notification-subscriptions.component.html',
})
export class TransportTypeNotificationSubscriptionsComponent implements OnDestroy {
  @Input() subscribedOffers: String[] = [];
  @Input() subscribedDemands: String[] = [];
  @Output() subscriptionChanged = new EventEmitter<any>();
  transportTypes$: Observable<TransportType[]>;
  loading = true;
  constructor(private store: Store<State>) {
    this.transportTypes$ = this.store.select(state => {
      if (state.marketplace) {
        return state.marketplace.transportTypes;
      }
    }).pipe(untilDestroyed(this));

    this.transportTypes$.subscribe(tt => {
      if (tt) {
        this.loading = false;
      }
    });
  }

  toggleOffer(transportType): void {
    const sub = this.subscribedOffers.filter(
      subscription =>
        subscription['transportType']['@id'] === transportType['@id']
    );
    if (sub.length === 0) {
      this.store.dispatch(
        new EnableTransportTypeNotificationSubscription({
          body: {
            type: 'offer',
            transportType: transportType['@id']
          }
        })
      );
    } else {
      this.store.dispatch(
        new DisableTransportTypeNotificationSubscription({
          id: sub[0]['id']
        })
      );
    }
  }

  toggleDemand(transportType): void {
    const sub = this.subscribedDemands.filter(
      subscription =>
        subscription['transportType']['@id'] === transportType['@id']
    );
    if (sub.length === 0) {
      this.store.dispatch(
        new EnableTransportTypeNotificationSubscription({
          body: {
            type: 'demand',
            transportType: transportType['@id']
          }
        })
      );
    } else {
      this.store.dispatch(
        new DisableTransportTypeNotificationSubscription({
          id: sub[0]['id']
        })
      );
    }
  }
  getSubscriptionStatus(tType, postType): boolean {
    let index = false;
    if (postType === 'demand') {
      index =
        this.subscribedDemands.filter(
          sub => sub['transportType']['@id'] === tType['@id']
        ).length > 0;
    } else if (postType === 'offer') {
      index =
        this.subscribedOffers.filter(
          sub => sub['transportType']['@id'] === tType['@id']
        ).length > 0;
    }
    return index;
  }

  ngOnDestroy() { }
}
