import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as types from '../types/circle.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as CircleActions from '../actions/circles.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class CircleEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadCircles$: Observable<Action> = this.actions$.pipe(
    ofType(types.circles.LOAD_CIRCLES),
    concatMap(() => this.api.get({ path: '/circles' }).pipe(
      map(data => new CircleActions.LoadCirclesSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  createCircle$: Observable<Action> = this.actions$.pipe(
    ofType(types.circles.CREATE_CIRCLE),
    map((action: CircleActions.CreateCircle) => action.payload),
    concatMap(payload => this.api.post({
      path: '/circles', body: payload
    }).pipe(
      map(data => new CircleActions.CreateCircleSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  deleteCircle$: Observable<Action> = this.actions$.pipe(
    ofType(types.circles.DELETE_CIRCLE),
    map((action: CircleActions.DeleteCircle) => action.payload),
    concatMap(payload => this.api.delete({
      path: '/circles/' + payload
    }).pipe(
      map(data => new CircleActions.DeleteCircleSuccess(data)),
      catchError(error => of(new CircleActions.DeleteCircleFail(error)))
    )),
  );

  @Effect()
  editCircle$: Observable<Action> = this.actions$.pipe(
    ofType(types.circles.EDIT_CIRCLE),
    map((action: CircleActions.EditCircle) => action.payload),
    concatMap(payload => this.api.put({
      path: '/circles/' + payload.id, body: payload.body
    }).pipe(
      map(data => new CircleActions.EditCircleSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );
}
