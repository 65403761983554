import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from '../../user/user.auth';

@Injectable()
export class SubscriptionGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.getSubscriptionsFromStoreOrAPI().pipe(
      switchMap(data => {
        if (
          !data ||
          data.length === 0 || // no subscriptions
          data.filter(
            sub => sub.subscriptionType.code === route.data.subscriptionCode
          ).length === 0 ||
          (data.filter(
            sub => sub.subscriptionType.code === route.data.subscriptionCode
          ).length !== 0 &&
            moment(
              data.find(
                sub => sub.subscriptionType.code === route.data.subscriptionCode
              ).endDate
            ).isBefore(moment()))
        ) {
          localStorage.setItem(
            'hasActive' + route.data.subscriptionCode + 'Subscription',
            'false'
          );
          this.router.navigate(['/subscribe'], {
            queryParams: {
              code: route.data.subscriptionCode.toLowerCase()
            }
          });
          return of(false);
        } else {
          localStorage.setItem(
            'hasActive' + route.data.subscriptionCode + 'Subscription',
            'true'
          );
          return of(true);
        }
      }),
      catchError(() => of(false))
    );
  }
}
