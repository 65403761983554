import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from '../actions/preannouncement.actions';
import { Preannouncement } from '../models/preannouncement.model';

export type PreannouncementState = Preannouncement[];

const reducer = createReducer<PreannouncementState>(
  [],
  on(Actions.loadPreannouncementsSuccess, (_, { payload }) => payload),
  on(Actions.createPreannouncementSuccess, (state, { payload }) => state.concat([payload])),
  on(Actions.updatePreannouncementSuccess, (state, { payload }) => state.map(
    x => x.id === payload.id ? payload : x
  )),
  on(Actions.deletePreannouncementSuccess, (state, { id }) => state.filter(
    x => x.id !== id
  )),
);

export function preannouncementReducer(state: PreannouncementState | undefined, action: Action) {
  return reducer(state, action);
}
