import { Component, Input } from '@angular/core';
import { User } from 'app/core/store/models/user.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {
  @Input()
  user: User;
  @Input()
  licensePlate: string;

  getUserInitials(user: User) {
    if (!user || !user.firstName || !user.lastName) {
      return;
    }
    return `${user.firstName[0]}${user.lastName[0]}`;
  }
}
