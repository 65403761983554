import { Action } from '@ngrx/store';
import * as types from '../types/tenant-settings.types';

export class LoadTenantSettings implements Action {
  readonly type = types.tenantSettings.LOAD_TENANT_SETTINGS;
  constructor(public payload?: any) { }
}

export class LoadTenantSettingsSuccess implements Action {
  readonly type = types.tenantSettings.LOAD_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadTenantSettingsFail implements Action {
  readonly type = types.tenantSettings.LOAD_FAILED;
  constructor(public payload: any) { }
}

export class UpdateTenantSettings implements Action {
  readonly type = types.tenantSettings.UPDATE_TENANT_SETTINGS;
  constructor(public payload: any) { }
}

export class UpdateTenantSettingsSuccess implements Action {
  readonly type = types.tenantSettings.UPDATE_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdateTenantSettingsFail implements Action {
  readonly type = types.tenantSettings.UPDATE_FAILED;
  constructor(public payload: any) { }
}

export class ClearTenantSettings implements Action {
  readonly type = types.tenantSettings.CLEAR;
  constructor(public payload?: any) { }
}

export type All =
  | LoadTenantSettings
  | LoadTenantSettingsSuccess
  | LoadTenantSettingsFail
  | UpdateTenantSettings
  | UpdateTenantSettingsSuccess
  | UpdateTenantSettingsFail
  | ClearTenantSettings;
