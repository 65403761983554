var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import * as types from '../types/market-post.types';
import * as MarketPostDemandRepliesActions from '../actions/market-post-demand-replies.actions';
var MarketPostDemandRepliesEffects = /** @class */ (function () {
    function MarketPostDemandRepliesEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadMarketPostDemandReplies$ = this.actions$.pipe(ofType(types.marketPostDemandReplies.LOAD_MARKET_POST_DEMAND_REPLIES), concatMap(function () { return _this.api.get({ path: '/market_post_demand_replies' }).pipe(map(function (data) { return new MarketPostDemandRepliesActions.LoadMarketPostDemandRepliesSuccess(data); }), catchError(function (error) { return of(new MarketPostDemandRepliesActions.LoadMarketPostDemandRepliesFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostDemandRepliesEffects.prototype, "loadMarketPostDemandReplies$", void 0);
    return MarketPostDemandRepliesEffects;
}());
export { MarketPostDemandRepliesEffects };
