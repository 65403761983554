import { Customer } from 'app/core/store/models/customer.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import * as CustomerTypes from 'app/core/store/types/customer.types';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { UpdateCustomer } from 'app/core/store/actions/customer.actions';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-edit-customer-modal',
  templateUrl: './edit-customer-modal.component.html',
})
export class EditCustomerModalComponent implements OnInit {
  @Input()
  customer: Customer;

  countries: any[];
  isLoading: boolean;

  destroyed$ = new Subject<boolean>();
  constructor(
    private store: Store<State>,
    private modal: NgbActiveModal,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.updates$
      .pipe(ofType(CustomerTypes.customer.UPDATE_CUSTOMER_SUCCEEDED))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(customer => {
        this.isLoading = false;
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
        this.modal.close(customer['payload']);
      });

    this.updates$
      .pipe(ofType(CustomerTypes.customer.UPDATE_CUSTOMER_FAILED))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to update customer')
        });
      });
  }

  ngOnInit() {
    this.store
      .select(state => state.tms.countries)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(countriesState => {
        if (!countriesState) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countriesState];
      });
  }

  updateCustomer(customerFormValue: Customer) {
    customerFormValue.defaultContact['id'] = this.customer.defaultContact['id'];
    customerFormValue.billingAddress['id'] = this.customer.billingAddress['id'];
    this.isLoading = true;
    this.store.dispatch(
      new UpdateCustomer({
        customerId: this.customer.id,
        customer: customerFormValue
      })
    );
  }
}
