/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../address-form/address-form.component.ngfactory";
import * as i3 from "../address-form/address-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./address-modal.component";
import * as i6 from "@ngrx/store";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
var styles_AddressModalComponent = [];
var RenderType_AddressModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddressModalComponent, data: {} });
export { RenderType_AddressModalComponent as RenderType_AddressModalComponent };
export function View_AddressModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-address-form", [], null, [[null, "addressFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addressFormSubmitted" === en)) {
        var pd_0 = (_co.createAddress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AddressFormComponent_0, i2.RenderType_AddressFormComponent)), i0.ɵdid(4, 573440, null, 0, i3.AddressFormComponent, [i4.FormBuilder], { countries: [0, "countries"] }, { addressFormSubmitted: "addressFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.countries; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Add new address")); _ck(_v, 1, 0, currVal_0); }); }
export function View_AddressModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-address-modal", [], null, null, null, View_AddressModalComponent_0, RenderType_AddressModalComponent)), i0.ɵdid(1, 180224, null, 0, i5.AddressModalComponent, [i6.Store, i7.NgbActiveModal], null, null)], null, null); }
var AddressModalComponentNgFactory = i0.ɵccf("app-address-modal", i5.AddressModalComponent, View_AddressModalComponent_Host_0, {}, {}, []);
export { AddressModalComponentNgFactory as AddressModalComponentNgFactory };
