import { Product, ProductTypes } from '../models/product.model';
import * as types from '../types/product.types';
import * as ProductActions from '../actions/product.actions';

export interface ProductsState {
  customerProducts: Product[];
  filteredCustomerProducts: Product[];
  supplierProducts: Product[];
  filteredSupplierProducts: Product[];
}

const INITIAL_STATE = {
  customerProducts: null,
  filteredCustomerProducts: null,
  supplierProducts: null,
  filteredSupplierProducts: null
};

export function ProductsReducer(
  state = INITIAL_STATE,
  action: ProductActions.All
): ProductsState {
  switch (action.type) {
    case types.product.LOAD_PRODUCTS_SUCCEEDED:
      const products = action.payload['hydra:member'];
      return Object.assign(
        {},
        {
          customerProducts: [
            ...filterProductsByType(products, ProductTypes.CUSTOMER)
          ],
          filteredCustomerProducts: [
            ...filterProductsByType(products, ProductTypes.CUSTOMER)
          ],
          supplierProducts: [
            ...filterProductsByType(products, ProductTypes.SUPPLIER)
          ],
          filteredSupplierProducts: [
            ...filterProductsByType(products, ProductTypes.SUPPLIER)
          ]
        }
      );
    case types.product.LOAD_FILTERED_PRODUCTS_SUCCEEDED:
      const filteredProducts = action.payload['hydra:member'];
      return Object.assign({}, state, {
        filteredCustomerProducts: [
          ...filterProductsByType(filteredProducts, ProductTypes.CUSTOMER)
        ],
        filteredSupplierProducts: [
          ...filterProductsByType(filteredProducts, ProductTypes.SUPPLIER)
        ]
      });
    case types.product.UPDATE_PRODUCT_SUCCEEDED:
      const updatedProduct = action.payload as Product;

      if (updatedProduct.type === ProductTypes.CUSTOMER) {
        const customerProducts = [...state.customerProducts];
        const filteredCustomerProducts = [...state.filteredCustomerProducts];

        const productIndex = customerProducts.findIndex(
          i => i.id === action.payload.id
        );
        const tempProduct = Object.assign(
          {},
          state.customerProducts[productIndex],
          action.payload
        );
        customerProducts[productIndex] = tempProduct;
        const filteredProductIndex = filteredCustomerProducts.findIndex(
          i => i.id === action.payload.id
        );
        const tempFilteredProduct = Object.assign(
          {},
          filteredCustomerProducts[filteredProductIndex],
          action.payload
        );
        filteredCustomerProducts[filteredProductIndex] = tempFilteredProduct;
        return Object.assign({}, state, {
          customerProducts,
          filteredCustomerProducts
        });
      }
      if (updatedProduct.type === ProductTypes.SUPPLIER) {
        const supplierProducts = [...state.supplierProducts];
        const filteredSupplierProducts = [...state.filteredSupplierProducts];

        const productIndex = supplierProducts.findIndex(
          i => i.id === action.payload.id
        );
        const tempProduct = Object.assign(
          {},
          state.supplierProducts[productIndex],
          action.payload
        );
        supplierProducts[productIndex] = tempProduct;
        const filteredProductIndex = filteredSupplierProducts.findIndex(
          i => i.id === action.payload.id
        );
        const tempFilteredProduct = Object.assign(
          {},
          filteredSupplierProducts[filteredProductIndex],
          action.payload
        );
        filteredSupplierProducts[filteredProductIndex] = tempFilteredProduct;
        return Object.assign({}, state, {
          supplierProducts,
          filteredSupplierProducts
        });
      }
      break;
    case types.product.DELETE_PRODUCT_SUCCEEDED:
      return Object.assign(
        {},
        {
          customerProducts: [
            ...state.customerProducts.filter(
              (product: Product) => product.id !== action.payload
            )
          ],
          filteredCustomerProducts: [
            ...state.filteredCustomerProducts.filter(
              (product: Product) => product.id !== action.payload
            )
          ],
          supplierProducts: [
            ...state.supplierProducts.filter(
              (product: Product) => product.id !== action.payload
            )
          ],
          filteredSupplierProducts: [
            ...state.filteredSupplierProducts.filter(
              (product: Product) => product.id !== action.payload
            )
          ]
        }
      );
    case types.product.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return state;
  }
  return state;
}

function filterProductsByType(
  products: Product[],
  type: ProductTypes
): Product[] {
  if (!products) {
    return [];
  }
  return products.filter(product => product.type === type).map(product => ({
    ...product,
    parts: product.parts.sort((a, b) => <any>(a.id > b.id) - <any>(b.id > a.id)),
  }));
}
