/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../user-location-form/user-location-form.component.ngfactory";
import * as i3 from "../user-location-form/user-location-form.component";
import * as i4 from "../../../services/location.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../services/toast.service";
import * as i7 from "./edit-user-location-modal.component";
import * as i8 from "@ngrx/effects";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_EditUserLocationModalComponent = [];
var RenderType_EditUserLocationModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditUserLocationModalComponent, data: {} });
export { RenderType_EditUserLocationModalComponent as RenderType_EditUserLocationModalComponent };
export function View_EditUserLocationModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "card-header d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [["class", "align-self-center mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "btn ml-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-user-location-form", [], null, [[null, "userLocationFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userLocationFormSubmitted" === en)) {
        var pd_0 = (_co.updateUserLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UserLocationFormComponent_0, i2.RenderType_UserLocationFormComponent)), i0.ɵdid(8, 770048, null, 0, i3.UserLocationFormComponent, [i4.LocationService, i5.Store, i6.ToastService], { isLoading: [0, "isLoading"], userLocation: [1, "userLocation"] }, { userLocationFormSubmitted: "userLocationFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isLoading; var currVal_3 = _co.location; _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Edit location")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Close")); _ck(_v, 5, 0, currVal_1); }); }
export function View_EditUserLocationModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-user-location-modal", [], null, null, null, View_EditUserLocationModalComponent_0, RenderType_EditUserLocationModalComponent)), i0.ɵdid(1, 180224, null, 0, i7.EditUserLocationModalComponent, [i5.Store, i8.Actions, i6.ToastService, i9.NgbActiveModal], null, null)], null, null); }
var EditUserLocationModalComponentNgFactory = i0.ɵccf("app-edit-user-location-modal", i7.EditUserLocationModalComponent, View_EditUserLocationModalComponent_Host_0, { location: "location" }, {}, []);
export { EditUserLocationModalComponentNgFactory as EditUserLocationModalComponentNgFactory };
