import { LoadSchedule, LoadUnplannedTransports, AddToSchedule, RemoveFromSchedule, LoadNextUnplannedTransportsPage, UpdateScheduleSequence } from 'app/core/store/actions/schedule.actions';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { LoadDrivers } from 'app/core/store/actions/driver.actions';
import { AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewDriverModalComponent } from '../drivers/new-driver-modal/new-driver-modal.component';
import { DragulaService } from 'ng2-dragula';
import { Subscription, Subject } from 'rxjs';
import * as ScheduleTypes from 'app/core/store/types/schedule.types';
import { Actions, ofType } from '@ngrx/effects';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import * as DateFns from 'date-fns';
import * as autoScroll from 'dom-autoscroller';
import { untilDestroyed } from 'app/shared/rxjs-util';
var PlanningComponent = /** @class */ (function () {
    function PlanningComponent(dragulaService, store, modalService, updates$, toastr) {
        var _this = this;
        this.dragulaService = dragulaService;
        this.store = store;
        this.modalService = modalService;
        this.updates$ = updates$;
        this.toastr = toastr;
        this.title = extract('Planning');
        this.today = new Date();
        this.drivers = [];
        this.transports = [];
        this.subs = new Subscription();
        this.dayChange = new Subject();
        this.searchTextChanged = new Subject();
        this.dragulaTransports = 'dragulaTransports';
        this.dates = [];
        this.page = 1;
        this.query = '';
        this.loadingItems = [];
        this.destroyDragulaGroupIfExists(this.dragulaTransports);
        // Disable dragging and dropping of elements that are loading
        this.dragulaService.createGroup(this.dragulaTransports, {
            moves: function (el) {
                var firstChild = el.firstChild;
                return !firstChild.classList.contains('is-loading');
            }
        });
        this.setDates();
        this.store.dispatch(new LoadDrivers({ includeSuperAdmins: 0, roleName: 'DRIVER' }));
        var endDate = DateFns.addDays(this.today, 4);
        this.loadTransportsBySelectedDate(this.today, endDate);
        this.loadScheduleBySelectedDate(this.today, endDate);
        this.store
            .select(function (state) { return state.drivers; })
            .pipe(untilDestroyed(this))
            .subscribe(function (driversState) {
            if (driversState && driversState.drivers) {
                _this.drivers = driversState.drivers;
                _this.addDatesToDrivers();
                _this.assignScheduleToDrivers();
            }
        });
        this.store
            .select(function (state) {
            if (state.tms.schedule && state.tms.schedule.unplannedTransports) {
                return state.tms.schedule.unplannedTransports;
            }
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (unplannedTransports) {
            if (!unplannedTransports) {
                return;
            }
            _this.transports = unplannedTransports.slice();
            _this.isLoading = false;
            _this.toastr.clear();
            _this.dragulaTransports = 'dragulaTransports'; // enables dragging and dropping
        });
        this.store
            .select(function (state) {
            if (state.tms.schedule &&
                state.tms.schedule.nextUnplannedTransportsPage) {
                return state.tms.schedule.nextUnplannedTransportsPage;
            }
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (nextPage) {
            if (!nextPage) {
                return;
            }
            _this.nextPage = nextPage;
        });
        this.store
            .select(function (state) {
            if (state.tms.schedule && state.tms.schedule.schedule) {
                return state.tms.schedule.schedule;
            }
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (schedule) {
            if (!schedule) {
                return;
            }
            _this.schedule = schedule.slice();
            _this.isLoading = false;
            _this.dragulaTransports = 'dragulaTransports'; // enables dragging and dropping
            _this.assignScheduleToDrivers();
        });
        /**
         * Don't send a load request each time the use changes the date.
         * This way we prevent the server getting flooded with requests.
         */
        this.dayChange
            .pipe(debounceTime(200))
            .pipe(untilDestroyed(this))
            .subscribe(function (data) {
            _this.today = new Date(data.fromDate);
            _this.loadTransportsBySelectedDate(data.fromDate, data.toDate);
            _this.loadScheduleBySelectedDate(data.fromDate, data.toDate);
            _this.setDates();
            _this.addDatesToDrivers();
        });
        this.searchTextChanged
            .pipe(debounceTime(500), distinctUntilChanged(), map(function () {
            return _this.loadTransportsBySelectedDate(_this.today, _this.dateFromToday(4));
        }))
            .subscribe(function () { });
        this.subs.add(this.dragulaService
            .dropModel()
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var target = _a.target, item = _a.item;
            if (!item) {
                return;
            }
            if (target.id === 'unplanned-transports-list') {
                var transportTaskIds = item.transportTasks.map(function (tt) { return tt.id; });
                _this.store.dispatch(new RemoveFromSchedule({
                    transportTasks: transportTaskIds
                }));
                item['isLoading'] = true;
                _this.loadingItems.push(item);
            }
            else {
                var driverId = target.getAttribute('driver-id');
                var date = DateFns.format(target.getAttribute('date'), 'YYYY-MM-DD');
                var transportTaskIds = item.transportTasks.map(function (tt) { return tt.id; });
                item['isLoading'] = true;
                _this.loadingItems.push(item);
                _this.store.dispatch(new AddToSchedule({
                    driver: driverId,
                    date: date,
                    transportTasks: transportTaskIds
                }));
            }
        }));
        this.updates$
            .pipe(ofType(ScheduleTypes.schedule.ADD_TO_SCHEDULE_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function (res) {
            var payload = res['payload'];
            var driverId = payload.driverId;
            var date = payload.date;
            var transportTaskIds = payload.transportTaskIds;
            var transportIds = payload.transportIds;
            // Update schedule sequence
            _this.updateScheduleSequence(driverId, _this.getParsedDate(date));
            // Remove isLoading from transport
            _this.removeIsLoadingFromTransport(transportIds[0]);
            if (!driverId) {
                return;
            }
            var driver = _this.drivers.find(function (d) { return d.id === driverId; });
            if (!driver) {
                return;
            }
            var transports = driver['plannedTransports'][_this.getParsedDate(date)];
            var transportIndex = transports.findIndex(function (t) { return t.id === transportIds[0]; });
            var transport = transports[transportIndex];
            if (!transport) {
                return;
            }
            transportTaskIds.forEach(function (transportTaskId) {
                // Check if transport task is part of transport
                var transportTask = transport.transportTasks.find(function (tTask) { return tTask.id === transportTaskId; });
                if (!transportTask) {
                    return;
                }
                transportTask['assignedTo'] = driver;
                // Add badge color
                transportTask = _this.validateTransportTask(transportTask, driverId);
                // Since the task has been found, we can remove it from the array
                transportTaskIds = transportTaskIds.filter(function (id) { return id !== transportTaskId; });
            });
            transport['isLoading'] = false;
            // Update transport by object.assign so we avoid reference bugs
            transports[transportIndex] = Object.assign({}, transport);
        });
        this.updates$
            .pipe(ofType(ScheduleTypes.schedule.REMOVE_FROM_SCHEDULE_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function (res) {
            var payload = res['payload'];
            var transportTaskIds = payload.transportTaskIds;
            var transportIds = payload.transportIds;
            // remove isLoading from transport
            _this.removeIsLoadingFromTransport(transportIds[0]);
            // set correct badge color on transport tasks
            if (res['payload']['driverId']) {
                return;
            }
            transportIds.forEach(function (transportId) {
                var transportIndex = _this.transports.findIndex(function (t) { return t.id === transportIds[0]; });
                var transport = _this.transports.find(function (t) { return t.id === transportId; });
                if (!transport || !transportTaskIds || transportIds.length === 0) {
                    return;
                }
                transport['isLoading'] = false;
                transportTaskIds.forEach(function (transportTaskId) {
                    // Check if transport task is part of transport
                    var transportTask = transport.transportTasks.find(function (tTask) { return tTask.id === transportTaskId; });
                    if (!transportTask) {
                        return;
                    }
                    // Remove error
                    delete transportTask['error'];
                    // Reomve badge color
                    delete transportTask['badgeColor'];
                    // Since the task has been found, we can remove it from the array
                    transportTaskIds = transportTaskIds.filter(function (id) { return id !== transportTaskId; });
                });
                _this.transports[transportIndex] = Object.assign({}, transport);
            });
        });
    }
    PlanningComponent.prototype.search = function ($event) {
        this.searchTextChanged.next($event.target.value);
    };
    PlanningComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.initializeScroll();
        }, 500);
    };
    PlanningComponent.prototype.initializeScroll = function () {
        var elements = Array.from(document.querySelectorAll('.planning'));
        // Enable autoscrolling
        this.scrollElements = autoScroll(elements.slice(), {
            margin: 100,
            maxSpeed: 30,
            scrollWhenOutside: false,
            autoScroll: function () {
                // Only scroll when the pointer is down
                return this.down;
            }
        });
    };
    PlanningComponent.prototype.destroyDragulaGroupIfExists = function (group) {
        if (!!this.dragulaService.find(group)) {
            this.dragulaService.destroy(group);
        }
    };
    PlanningComponent.prototype.updateScheduleSequence = function (driverId, date) {
        var driver = this.drivers.find(function (d) { return d.id === driverId; });
        if (!driver) {
            return;
        }
        var plannedTransports = driver['plannedTransports'][date];
        if (!plannedTransports) {
            return;
        }
        // {id: transportTaskId, sequence:1 }
        var sequence = [];
        var index = 0;
        plannedTransports.forEach(function (transport) {
            transport.transportTasks.forEach(function (task) {
                sequence.push({
                    id: task.id,
                    sequence: index
                });
                index++;
            });
        });
        this.store.dispatch(new UpdateScheduleSequence(sequence));
    };
    PlanningComponent.prototype.removeIsLoadingFromTransport = function (transportId) {
        this.loadingItems = this.loadingItems.filter(function (item) { return item.id !== transportId; });
    };
    PlanningComponent.prototype.validateTransportTask = function (task, driverId) {
        if (task['visit'] && // Task has a visit
            task['assignedTo'] && // Task is assigned to a driver
            driverId &&
            task['visit'].driver &&
            task['visit'].driver.id &&
            task['visit'].driver.id !== driverId) {
            task['error'] = extract('This task has a prenotification that is assigned to another driver');
            task['badgeColor'] = 'badge-danger';
            return task;
        }
        task['badgeColor'] = 'badge-success';
        task['error'] = null;
        return task;
    };
    PlanningComponent.prototype.assignScheduleToDrivers = function () {
        var _this = this;
        if (!this.schedule || this.drivers.length === 0) {
            return;
        }
        var that = this;
        this.drivers.forEach(function (driver) {
            // Clear current driver schedule
            Object.keys(driver['plannedTransports']).forEach(function (k) {
                driver['plannedTransports'][k] = [];
            });
            var driverSchedule = that.schedule.filter(function (s) { return s.driverId === driver.id; });
            if (driverSchedule.length === 0) {
                return;
            }
            driverSchedule = driverSchedule[0]['schedule'];
            // Loop over the schedule and assign the correct badge color the the tasks
            // that are assigned to the current driver
            Object.keys(driverSchedule).forEach(function (k) {
                driverSchedule[k].forEach(function (transport) {
                    transport.transportTasks.forEach(function (task) {
                        task = _this.validateTransportTask(task, driver.id);
                    });
                });
            });
            driver['plannedTransports'] = Object.assign({}, driver['plannedTransports'], driverSchedule);
        });
    };
    PlanningComponent.prototype.addDatesToDrivers = function () {
        var _this = this;
        this.drivers.forEach(function (driver) {
            if (!driver['plannedTransports']) {
                driver['plannedTransports'] = {};
            }
            _this.dates.forEach(function (date) {
                if (!driver['plannedTransports'][_this.getParsedDate(date)]) {
                    driver['plannedTransports'][_this.getParsedDate(date)] = [];
                }
            });
        });
    };
    PlanningComponent.prototype.getPlannedTransports = function (driver, date) {
        return driver['plannedTransports'][this.getParsedDate(date)];
    };
    PlanningComponent.prototype.getParsedDate = function (date) {
        return DateFns.format(date, 'YYYY-MM-DD');
    };
    PlanningComponent.prototype.dateFromToday = function (days) {
        return DateFns.addDays(this.today, days);
    };
    PlanningComponent.prototype.setDates = function () {
        var days = 5;
        var tempDates = [];
        for (var index = 0; index < days; index++) {
            var date = this.dateFromToday(index);
            tempDates.push(date);
        }
        this.dates = tempDates.slice();
    };
    PlanningComponent.prototype.updatePlanning = function (event) {
        var transportIds = [];
        event['transports'].forEach(function (transport) {
            var transportTasks = transport.transportTasks.map(function (transportTask) { return transportTask.id; });
            if (transportIds.length === 0) {
                transportIds = transportTasks.slice();
            }
            else {
                transportIds = transportTasks.concat(transportIds);
            }
        });
        this.store.dispatch(new AddToSchedule({
            driver: event['driverId'],
            date: DateFns.format(event['date'], 'YYYY-MM-DD'),
            transportTasks: transportIds
        }));
    };
    PlanningComponent.prototype.addDay = function () {
        this.today = DateFns.addDays(this.today, 1);
        var date = DateFns.addDays(this.today, 4);
        this.dayChange.next({ fromDate: date, toDate: date });
    };
    PlanningComponent.prototype.subtractDay = function () {
        this.today = DateFns.subDays(this.today, 1);
        this.dayChange.next({ fromDate: this.today, toDate: this.today });
    };
    PlanningComponent.prototype.loadScheduleBySelectedDate = function (startDate, endDate) {
        this.isLoading = true;
        this.store.dispatch(new LoadSchedule({
            fromDate: DateFns.getYear(startDate) + "-" + (DateFns.getMonth(startDate) +
                1) + "-" + DateFns.getDate(startDate),
            toDate: DateFns.getYear(endDate) + "-" + (DateFns.getMonth(endDate) +
                1) + "-" + DateFns.getDate(endDate)
        }));
    };
    PlanningComponent.prototype.loadTransportsBySelectedDate = function (startDate, endDate) {
        this.isLoading = true;
        // this.dragulaTransports = null; // disables dagging and dropping
        this.store.dispatch(new LoadUnplannedTransports({
            fromDate: DateFns.getYear(startDate) + "-" + (DateFns.getMonth(startDate) +
                1) + "-" + DateFns.getDate(startDate),
            toDate: DateFns.getYear(endDate) + "-" + (DateFns.getMonth(endDate) +
                1) + "-" + DateFns.getDate(endDate),
            page: this.page.toString(),
            query: this.query
        }));
    };
    PlanningComponent.prototype.launchNewDriverModal = function () {
        this.modalService.open(NewDriverModalComponent);
    };
    PlanningComponent.prototype.onScrollDown = function () {
        if (!this.nextPage) {
            return;
        }
        var startDate = this.today;
        var endDate = DateFns.addDays(this.today, 4);
        this.store.dispatch(new LoadNextUnplannedTransportsPage({
            page: this.nextPage,
            query: this.query,
            fromDate: DateFns.getYear(startDate) + "-" + (DateFns.getMonth(startDate) +
                1) + "-" + DateFns.getDate(startDate),
            toDate: DateFns.getYear(endDate) + "-" + (DateFns.getMonth(endDate) +
                1) + "-" + DateFns.getDate(endDate)
        }));
        this.toastr.showInfoLoading({
            message: extract('Loading extra transports...')
        });
    };
    PlanningComponent.prototype.ngOnDestroy = function () {
        this.destroyDragulaGroupIfExists(this.dragulaTransports);
    };
    return PlanningComponent;
}());
export { PlanningComponent };
