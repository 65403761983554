import { LoadLocationTypes } from '../core/store/actions/location.actions';
import { LocationTypes } from '../core/store/models/location-type.model';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
var MarketpostParserService = /** @class */ (function () {
    function MarketpostParserService(store) {
        var _this = this;
        this.store = store;
        this.store
            .select(function (state) { return state.tms.locations; })
            .subscribe(function (locationState) {
            if (locationState && locationState.locationTypes) {
                _this.locationTypes = locationState.locationTypes;
            }
            else {
                _this.store.dispatch(new LoadLocationTypes());
            }
        });
        this.store
            .select(function (state) { return state.containerTypes; })
            .subscribe(function (containerTypes) {
            if (containerTypes && !_this.containerTypes) {
                _this.containerTypes = containerTypes;
            }
        });
        this.store
            .select(function (state) {
            if (state.marketplace && state.marketplace.liners) {
                return state.marketplace.liners;
            }
        })
            .subscribe(function (liners) {
            if (liners && !_this.liners) {
                _this.liners = liners;
            }
        });
        this.store.select(function (state) {
            if (state.marketplace && state.marketplace.transportTypes) {
                _this.transportTypes = state.marketplace.transportTypes;
            }
        });
    }
    Object.defineProperty(MarketpostParserService.prototype, "containerTypes", {
        get: function () {
            return this._containerTypes;
        },
        set: function (containerTypes) {
            this._containerTypes = containerTypes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarketpostParserService.prototype, "liners", {
        get: function () {
            return this._liners;
        },
        set: function (liners) {
            this._liners = liners;
        },
        enumerable: true,
        configurable: true
    });
    MarketpostParserService.prototype.parseTransportOffer = function (offer) {
        var _this = this;
        var transport = JSON.parse(JSON.stringify(offer));
        transport.attachments =
            transport.attachments && transport.attachments.length > 0
                ? transport.attachments.map(function (attachment) {
                    return attachment['@id'] ? attachment['@id'] : attachment;
                })
                : [];
        transport.transportTasks = transport.transportTasks.filter(function (t) { return t.location; });
        transport.transportTasks.forEach(function (task, x) {
            // account for free text locations
            var location = {
                name: task.location.name || task.location,
                latitude: task.location.latitude,
                longitude: task.location.longitude
            };
            if (task.location.id) {
                location['id'] = task.location.id;
            }
            /**
             * If the location does not have a latitude (or longitude) the location is a user input location and
             * should get the correct locationType
             */
            if (!location.latitude && !location.longitude && !location['id']) {
                var locationType = _this.locationTypes.find(function (lt) { return lt.code === LocationTypes.TRASH; });
                if (locationType) {
                    location['locationType'] = locationType['@id'];
                }
            }
            transport['transportTasks'][x]['location'] = location;
            if (task.privateLocation) {
                var privateLocation = {
                    id: task.privateLocation
                };
                transport['transportTasks'][x]['privateLocation'] = privateLocation;
            }
            // set the correct dates and times
            var startDate = task.startDate;
            var startTime = task.startTime;
            var endDate = task.endDate;
            var endTime = task.endTime;
            if (!startDate && !startTime) {
                delete task.startDate;
                delete task.startTime;
            }
            else if (!startDate && startTime) {
                startDate = moment()
                    .hours(startTime.hour)
                    .minutes(startTime.minute)
                    .seconds(0);
                transport['transportTasks'][x]['startDate'] = moment(startDate).format();
                transport['transportTasks'][x]['startDateTimeSpecified'] = true;
            }
            else if (startDate && startTime) {
                startDate = moment(startDate)
                    .hours(startTime.hour)
                    .minutes(startTime.minute)
                    .seconds(0);
                transport['transportTasks'][x]['startDate'] = moment(startDate).format();
                transport['transportTasks'][x]['startDateTimeSpecified'] = true;
            }
            else if (startDate && !startTime) {
                startDate = moment(startDate)
                    .hours(0)
                    .minutes(0)
                    .seconds(0);
                transport['transportTasks'][x]['startDate'] = moment(startDate).format();
                transport['transportTasks'][x]['startDateTimeSpecified'] = false;
            }
            if (!endDate && !endTime) {
                delete task.endDate;
                delete task.endTime;
            }
            else if (!endDate && endTime) {
                endDate = moment()
                    .hours(endTime.hour)
                    .minutes(endTime.minute)
                    .seconds(0);
                transport['transportTasks'][x]['endDate'] = moment(endDate).format();
                transport['transportTasks'][x]['endDateTimeSpecified'] = true;
            }
            else if (endDate && endTime) {
                endDate = moment(endDate)
                    .hours(endTime.hour)
                    .minutes(endTime.minute)
                    .seconds(0);
                transport['transportTasks'][x]['endDate'] = moment(endDate).format();
                transport['transportTasks'][x]['endDateTimeSpecified'] = true;
            }
            else if (endDate && !endTime) {
                endDate = moment(endDate)
                    .hours(0)
                    .minutes(0)
                    .seconds(0);
                transport['transportTasks'][x]['endDate'] = moment(endDate).format();
                transport['transportTasks'][x]['endDateTimeSpecified'] = false;
            }
            delete task.endTime;
            delete task.startTime;
            if (task['taskType'] && task['taskType']['@id']) {
                transport['transportTasks'][x]['taskType'] = task['taskType']['@id'];
            }
        });
        // map container with correct values
        if (typeof transport.container.weight === 'string') {
            transport.container.weight =
                transport.container.weight.length === 0
                    ? null
                    : transport.container.weight;
        }
        if (transport.container.weight) {
            transport.container.weight = parseFloat(transport.container.weight);
        }
        if (transport.container.containerType) {
            transport.container.containerType = this.containerTypes.find(function (cType) { return cType.id === transport.container.containerType; })['@id'];
        }
        if (transport.container.liner) {
            transport.container.liner = this.liners.find(function (liner) { return liner.id === transport.container.liner; })['@id'];
        }
        if (transport.container.adr) {
            transport.container.adr = transport.container.adr;
        }
        if (transport.transportType) {
            transport.transportType = transport.transportType['@id'];
        }
        if (!transport.genset) {
            transport.genset = false;
        }
        this.delete_null_properties(transport, true);
        // This enables customer and contact to be deleted on update
        transport.customer = transport.customer || null;
        transport.contact = transport.contact || null;
        return transport;
    };
    MarketpostParserService.prototype.parseTransportDemand = function (demand) {
        var transport = JSON.parse(JSON.stringify(demand));
        if (transport.transportType &&
            typeof transport.transportType === 'object') {
            transport.transportType = transport.transportType['@id'];
        }
        if (transport.container.containerType) {
            transport.container.containerType = this.containerTypes.find(function (cType) { return cType.id === transport.container.containerType; })['@id'];
        }
        if (transport.container.weight) {
            transport.container.weight = parseFloat(transport.container.weight);
        }
        if (transport.container.liner) {
            transport.container.liner = this.liners.find(function (liner) { return liner.id === transport.container.liner; })['@id'];
        }
        // get origin date and time
        var originStartDate = transport.originStartDate;
        var originEndDate = transport.originEndDate;
        var originStartTime = transport.originStartTime;
        var originEndTime = transport.originEndTime;
        // get destination date and time
        var destinationStartDate = transport.destinationStartDate;
        var destinationEndDate = transport.destinationEndDate;
        var destinationStartTime = transport.destinationStartTime;
        var destinationEndTime = transport.destinationEndTime;
        if (!originStartDate && originStartTime) {
            originStartDate = moment()
                .hours(originStartTime.hour)
                .minutes(originStartTime.minute)
                .seconds(0);
            transport['originTimeIntervalStart'] = moment(originStartDate).format();
            transport['originTimeIntervalStartTimeSpecified'] = true;
        }
        else if (originStartDate && originStartTime) {
            originStartDate = moment(originStartDate)
                .hours(originStartTime.hour)
                .minutes(originStartTime.minute)
                .seconds(0);
            transport['originTimeIntervalStart'] = moment(originStartDate).format();
            transport['originTimeIntervalStartTimeSpecified'] = true;
        }
        else if (originStartDate && !originStartTime) {
            originStartDate = moment(originStartDate)
                .hours(0)
                .minutes(0)
                .seconds(0);
            transport['originTimeIntervalStart'] = moment(originStartDate).format();
            transport['originTimeIntervalStartTimeSpecified'] = false;
        }
        if (!originEndDate && originEndTime) {
            originEndDate = moment()
                .hours(originEndTime.hour)
                .minutes(originEndTime.minute)
                .seconds(0);
            transport['originTimeIntervalEnd'] = moment(originEndDate).format();
            transport['originTimeIntervalEndTimeSpecified'] = true;
        }
        else if (originEndDate && originEndTime) {
            originEndDate = moment(originEndDate)
                .hours(originEndTime.hour)
                .minutes(originEndTime.minute)
                .seconds(0);
            transport['originTimeIntervalEnd'] = moment(originEndDate).format();
            transport['originTimeIntervalEndTimeSpecified'] = true;
        }
        else if (originEndDate && !originEndTime) {
            originEndDate = moment(originEndDate)
                .hours(0)
                .minutes(0)
                .seconds(0);
            transport['originTimeIntervalEnd'] = moment(originEndDate).format();
            transport['originTimeIntervalEndTimeSpecified'] = false;
        }
        if (!destinationStartDate && destinationStartTime) {
            destinationStartDate = moment()
                .hours(destinationStartTime.hour)
                .minutes(destinationStartTime.minute)
                .seconds(0);
            transport['destinationTimeIntervalStart'] = moment(destinationStartDate).format();
            transport['destinationTimeIntervalStartTimeSpecified'] = true;
        }
        else if (destinationStartDate && destinationStartTime) {
            destinationStartDate = moment(destinationStartDate)
                .hours(destinationStartTime.hour)
                .minutes(destinationStartTime.minute)
                .seconds(0);
            transport['destinationTimeIntervalStart'] = moment(destinationStartDate).format();
            transport['destinationTimeIntervalStartTimeSpecified'] = true;
        }
        else if (destinationStartDate && !destinationStartTime) {
            destinationStartDate = moment(destinationStartDate)
                .hours(0)
                .minutes(0)
                .seconds(0);
            transport['destinationTimeIntervalStart'] = moment(destinationStartDate).format();
            transport['destinationTimeIntervalStartTimeSpecified'] = false;
        }
        if (!destinationEndDate && destinationEndTime) {
            destinationEndDate = moment()
                .hours(destinationEndTime.hour)
                .minutes(destinationEndTime.minute)
                .seconds(0);
            transport['destinationTimeIntervalEnd'] = moment(destinationEndDate).format();
            transport['destinationTimeIntervalEndTimeSpecified'] = true;
        }
        else if (destinationEndDate && destinationEndTime) {
            destinationEndDate = moment(destinationEndDate)
                .hours(destinationEndTime.hour)
                .minutes(destinationEndTime.minute)
                .seconds(0);
            transport['destinationTimeIntervalEnd'] = moment(destinationEndDate).format();
            transport['destinationTimeIntervalEndTimeSpecified'] = true;
        }
        else if (destinationEndDate && !destinationEndTime) {
            destinationEndDate = moment(destinationEndDate)
                .hours(0)
                .minutes(0)
                .seconds(0);
            transport['destinationTimeIntervalEnd'] = moment(destinationEndDate).format();
            transport['destinationTimeIntervalEndTimeSpecified'] = false;
        }
        this.delete_null_properties(transport, true);
        return transport;
    };
    MarketpostParserService.prototype.delete_null_properties = function (object, recurse) {
        for (var i in object) {
            if (object[i] === null) {
                delete object[i];
            }
            else if (recurse && typeof object[i] === 'object') {
                this.delete_null_properties(object[i], recurse);
            }
        }
    };
    return MarketpostParserService;
}());
export { MarketpostParserService };
