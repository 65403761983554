<div class="login-background fullscreen overflow-sm-x-hidden overflow-y">
  <div class="row justify-content-center mt-5">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h3>{{ 'Reset your password' | translate }}</h3>
          <p class="text-muted">
            {{
              'Insert your new password to actually go and reset it.'
                | translate
            }}
          </p>
          <p class="text-muted">
            {{
              'Your password should be longer than 7 characters and at least contain one lower and capital and number.'
                | translate
            }}
          </p>
          <form #loginForm="ngForm">
            <div class="form-group">
              <label for="login-newPass">{{
                'New Password' | translate
              }}</label>
              <input
                [(ngModel)]="newPass1"
                data-cy="password"
                name="newPass1"
                type="password"
                placeholder="{{ 'Enter your new password' | translate }}"
                autofocus="autofocus"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="login-newPass">{{
                'Repeat your new password' | translate
              }}</label>
              <input
                [(ngModel)]="newPass2"
                data-cy="password-confirm"
                name="newPass2"
                type="password"
                placeholder="{{ 'Enter your new password again' | translate }}"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <button
                type="submit"
                data-cy="reset"
                (click)="reset()"
                class="btn btn-primary text-white"
              >
                <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
                <span>{{ 'Set new password' | translate }}</span>
              </button>
              <a routerLink="/" class="btn float-right">{{
                'Ready to log in ?' | translate
              }}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
