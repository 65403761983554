import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-decline-registration-request-modal',
  templateUrl: './decline-registration-request-modal.component.html'
})
export class DeclineRegistrationRequestModalComponent {
  constructor(public activeModal: NgbActiveModal) { }
}
