/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/forms";
import * as i3 from "./edit-credit-threshold.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_EditCreditThresholdComponent = [];
var RenderType_EditCreditThresholdComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditCreditThresholdComponent, data: {} });
export { RenderType_EditCreditThresholdComponent as RenderType_EditCreditThresholdComponent };
export function View_EditCreditThresholdComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "input", [["class", "form-control mb-3"], ["required", ""], ["type", "number"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 6).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i0.ɵnov(_v, 6).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.credits = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(6, 16384, null, 0, i2.NumberValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵdid(7, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i0.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.NumberValueAccessor]), i0.ɵdid(10, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary btn-block mr-1 text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Save "]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = ""; _ck(_v, 7, 0, currVal_9); var currVal_10 = _co.credits; _ck(_v, 10, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Edit credit threshold")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (i0.ɵnov(_v, 7).required ? "" : null); var currVal_2 = i0.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 12).ngClassValid; var currVal_7 = i0.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 12).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_EditCreditThresholdComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-credit-threshold-modal", [], null, null, null, View_EditCreditThresholdComponent_0, RenderType_EditCreditThresholdComponent)), i0.ɵdid(1, 49152, null, 0, i3.EditCreditThresholdComponent, [i4.NgbActiveModal], null, null)], null, null); }
var EditCreditThresholdComponentNgFactory = i0.ɵccf("app-edit-credit-threshold-modal", i3.EditCreditThresholdComponent, View_EditCreditThresholdComponent_Host_0, {}, {}, []);
export { EditCreditThresholdComponentNgFactory as EditCreditThresholdComponentNgFactory };
