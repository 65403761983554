import { LoadMessages } from '../actions/messages.actions';
import { State } from '../store.model';
import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, Subject, interval, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';

import * as types from '../types/update.types';
import * as UpdateActions from '../actions/update.actions';
import * as moment from 'moment';

import { ApiService } from '../../api/api.service';
import { REFRESH_RATE_MS } from '../../../constants';

@Injectable()
export class UpdateEffects {
  destroy$ = new Subject<boolean>();
  constructor(
    private api: ApiService,
    private actions$: Actions,
    private store: Store<State>
  ) { }

  @Effect()
  loadUpdates$: Observable<Action> = this.actions$.pipe(
    ofType(types.updates.LOAD_UPDATES),
    map((action: UpdateActions.LoadUpdates) => action.payload),
    concatMap(payload => {
      const requestPayload = payload ? payload : {};
      const now = moment()
        .subtract(2, 'seconds')
        .format('YYYY-MM-DD HH:mm:ss');
      let timestamp = '';
      this.store
        .select(state => state.updates)
        .subscribe(data => (timestamp = data ? data : now));
      // Default timestamp to now
      if (!requestPayload.timestamp) {
        requestPayload.timestamp = encodeURIComponent(timestamp);
      }
      return this.api
        .get({ path: `/status/${requestPayload.timestamp}` })
        .pipe(
          map(data => {
            /*
            * Loop over different possible updated entities
            * and mark them outdated or reload them.
            */
            if (data['message_board_message']) {
              this.store.dispatch(new LoadMessages());
            }
            return new UpdateActions.LoadUpdatesSuccess(now);
          }),
          catchError(error => of(new UpdateActions.LoadUpdatesFail(error)))
        );
    })
  );

  // Call load updates effect on interval
  @Effect({ dispatch: false })
  startLoadUpdates$ = this.actions$.pipe(
    ofType(types.updates.START_LOAD_UPDATES),
    switchMap(() => {
      this.destroy$ = new Subject<boolean>();
      return interval(REFRESH_RATE_MS)
        .pipe(takeUntil(this.destroy$))
        .pipe(map(() => this.store.dispatch(new UpdateActions.LoadUpdates())));
    })
  );

  // Stop load updates
  @Effect({ dispatch: false })
  stopLoadUpdates$ = this.actions$.pipe(
    ofType(types.updates.STOP_LOAD_UPDATES),
    map(() => {
      this.destroy$.next(true);
      this.destroy$.complete();
    })
  );
}
