import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/liner.types';
import * as LinerActions from '../actions/liner.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class LinerEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadLiners$: Observable<Action> = this.actions$.pipe(
    ofType(types.liner.LOAD_LINERS),
    concatMap(() => this.api.get({ path: '/liners' }).pipe(
      map(data => new LinerActions.LoadLinersSuccess(data)),
      catchError(error => of(new LinerActions.LoadLinersFail(error)))
    )),
  );
}
