<span class="d-flex justify-content-between">
  <span>
    <i class="far fa-calendar-alt"></i> &nbsp;
    <span>{{ debitTransaction.created | date: 'dd-MM-yyyy HH:mm' }}</span>
  </span>
  <span
    [ngClass]="{
      'text-danger': transactionAmount < 0,
      'text-success': transactionAmount >= 0
    }"
  >
    {{ debitTransaction.description | translate }}</span
  >
  <span
    *ngIf="!debitTransaction.reservedWalletTransaction"
    class="text-right"
    [ngClass]="{
      'text-danger': transactionAmount < 0,
      'text-success': transactionAmount >= 0
    }"
    >{{ transactionAmount }}</span
  >
  <span
    *ngIf="debitTransaction.reservedWalletTransaction"
    class="text-right"
    [ngClass]="{
      'text-danger': transactionAmount < 0,
      'text-success': transactionAmount >= 0
    }"
    >( {{ transactionAmount }} )</span
  >
</span>
