import { MatchingEffects } from '../core/store/effects/matching.effects';
import { MatchingRoutingModule } from './matching-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchingComponent } from './matching.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { MatchingReducers } from '../core/store/reducers/matching.reducer';
import { MatchingPoolItemComponent } from './matching-pool-item/matching-pool-item.component';
import { TransportMatchingPoolItemComponent } from './transport-matching-pool-item/transport-matching-pool-item.component';
import {
  MarketPostOfferMatchingPoolItemComponent
} from './market-post-offer-matching-pool-item/market-post-offer-matching-pool-item.component';
import {
  MarketPostDemandMatchingPoolItemComponent
} from './market-post-demand-matching-pool-item/market-post-demand-matching-pool-item.component';
import { MatchingPoolOptionComponent } from './matching-pool-option/matching-pool-option.component';
import { ConfidenceScoreComponent } from './confidence-score/confidence-score.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatchingRoutingModule,
    StoreModule.forFeature('matching', MatchingReducers),
    EffectsModule.forFeature([MatchingEffects])
  ],
  declarations: [
    MatchingComponent,
    MatchingPoolItemComponent,
    TransportMatchingPoolItemComponent,
    MarketPostOfferMatchingPoolItemComponent,
    MarketPostDemandMatchingPoolItemComponent,
    MatchingPoolOptionComponent,
    ConfidenceScoreComponent
  ]
})
export class MatchingModule { }
