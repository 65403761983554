import { Action } from '@ngrx/store';

import * as types from 'app/core/store/types/tenant.types';

export class LoadTenants implements Action {
  readonly type = types.tenants.LOAD_TENANTS;
  constructor(public payload?: {
    onSuccess?: () => void,
    onFailure?: () => void,
  }) { }
}

export class LoadTenantsSuccess implements Action {
  readonly type = types.tenants.LOAD_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadTenantsFailed implements Action {
  readonly type = types.tenants.LOAD_FAILED;
  constructor(public payload: any) { }
}

export class LoadAllTenants implements Action {
  readonly type = types.tenants.LOAD_ALL_TENANTS;
  constructor(public payload?: any) { }
}

export class LoadAllTenantsSuccess implements Action {
  readonly type = types.tenants.LOAD_ALL_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadAllTenantsFailed implements Action {
  readonly type = types.tenants.LOAD_ALL_FAILED;
  constructor(public payload: any) { }
}

export class CreateTenant implements Action {
  readonly type = types.tenants.CREATE_TENANT;
  constructor(public payload: any) { }
}

export class CreateTenantSuccess implements Action {
  readonly type = types.tenants.CREATE_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateTenantFail implements Action {
  readonly type = types.tenants.CREATE_FAILED;
  constructor(public payload: any) { }
}

export class UpdateTenant implements Action {
  readonly type = types.tenants.UPDATE_TENANT;
  constructor(public payload: any) { }
}

export class UpdateTenantSuccess implements Action {
  readonly type = types.tenants.UPDATE_TENANT_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdateTenantFail implements Action {
  readonly type = types.tenants.UPDATE_TENANT_FAILED;
  constructor(public payload: any) { }
}

export class ClearTenants implements Action {
  readonly type = types.tenants.CLEAR;
  constructor(public payload?: any) { }
}

export class EnableTenant implements Action {
  readonly type = types.tenants.ENABLE_TENANT;
  constructor(public payload: any) { }
}

export class EnableTenantSuccess implements Action {
  readonly type = types.tenants.ENABLE_TENANT_SUCCEEDED;
  constructor(public payload: any) { }
}

export class EnableTenantFail implements Action {
  readonly type = types.tenants.ENABLE_TENANT_FAILED;
  constructor(public payload: any) { }
}

export class DisableTenant implements Action {
  readonly type = types.tenants.DISABLE_TENANT;
  constructor(public payload: any) { }
}

export class DisableTenantSuccess implements Action {
  readonly type = types.tenants.DISABLE_TENANT_SUCCEEDED;
  constructor(public payload: any) { }
}

export class DisableTenantFail implements Action {
  readonly type = types.tenants.DISABLE_TENANT_FAILED;
  constructor(public payload: any) { }
}

export class DeleteTenant implements Action {
  readonly type = types.tenants.DELETE_TENANT;
  constructor(public payload: any) { }
}

export class DeleteTenantSuccess implements Action {
  readonly type = types.tenants.DELETE_TENANT_SUCCEEDED;
  constructor(public payload: any) { }
}

export class DeleteTenantFail implements Action {
  readonly type = types.tenants.DELETE_TENANT_FAILED;
  constructor(public payload: any) { }
}

export type All =
  | LoadTenants
  | LoadTenantsSuccess
  | LoadTenantsFailed
  | LoadAllTenants
  | LoadAllTenantsSuccess
  | LoadAllTenantsFailed
  | CreateTenant
  | CreateTenantSuccess
  | CreateTenantFail
  | UpdateTenant
  | UpdateTenantSuccess
  | UpdateTenantFail
  | EnableTenant
  | EnableTenantSuccess
  | EnableTenantFail
  | DisableTenant
  | DisableTenantSuccess
  | DisableTenantFail
  | DeleteTenant
  | DeleteTenantSuccess
  | DeleteTenantFail
  | ClearTenants;
