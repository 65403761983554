var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/marketplace.types';
import * as MarketPostOffersActions from '../actions/market-post-offer.actions';
import { ApiService } from '../../api/api.service';
var MarketPostOffersEffects = /** @class */ (function () {
    function MarketPostOffersEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadMarketPostOffers$ = this.actions$.pipe(ofType(types.marketposts.LOAD_MARKETPOSTS_OFFERS), concatMap(function () { return _this.api.get({
            path: '/market_post_offers'
        }).pipe(map(function (data) { return new MarketPostOffersActions.LoadMarketPostOffersSuccess(data); }), catchError(function (error) { return of(new MarketPostOffersActions.LoadMarketPostOffersFail(error)); })); }));
        this.createMarketPostOffer$ = this.actions$.pipe(ofType(types.marketposts.CREATE_MARKET_POST_OFFER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/market_post_offer_collections', body: payload
        }).pipe(map(function (data) { return new MarketPostOffersActions.CreateMarketPostOfferSuccess(data); }), catchError(function (error) { return of(new MarketPostOffersActions.CreateMarketPostOfferFail(error)); })); }));
        this.replyMarketPostOffer$ = this.actions$.pipe(ofType(types.marketposts.REPLY_MARKET_POST_OFFER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/market_post_offer_replies",
            body: payload.body
        }).pipe(map(function (data) { return new MarketPostOffersActions.ReplyMarketPostOfferSuccess(data); }), catchError(function (error) { return of(new MarketPostOffersActions.ReplyMarketPostOfferFail(error)); })); }));
        this.publishMarketPostOffer$ = this.actions$.pipe(ofType(types.marketposts.PUBLISH_MARKET_POST_OFFER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/market_post_offers/' + payload.id + '/publish',
            body: {}
        }).pipe(map(function () { return new MarketPostOffersActions.PublishMarketPostOfferSuccess(payload); }), catchError(function (error) { return of(new MarketPostOffersActions.PublishMarketPostOfferFail(error)); })); }));
        this.unpublishMarketPostOffer$ = this.actions$.pipe(ofType(types.marketposts.UNPUBLISH_MARKET_POST_OFFER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/market_post_offers/' + payload.id + '/unpublish',
            body: {}
        }).pipe(map(function () { return new MarketPostOffersActions.UnpublishMarketPostOfferSuccess(payload); }), catchError(function (error) { return of(new MarketPostOffersActions.UnpublishMarketPostOfferFail(error)); })); }));
        this.updateMarketPostOffer$ = this.actions$.pipe(ofType(types.marketposts.UPDATE_MARKETPOSTS_OFFER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/market_post_offers/' + payload.id,
            body: payload.body
        }).pipe(map(function (data) { return new MarketPostOffersActions.UpdateMarketPostOfferSuccess(data); }), catchError(function (error) { return of(new MarketPostOffersActions.UpdateMarketPostOfferFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostOffersEffects.prototype, "loadMarketPostOffers$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostOffersEffects.prototype, "createMarketPostOffer$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostOffersEffects.prototype, "replyMarketPostOffer$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostOffersEffects.prototype, "publishMarketPostOffer$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostOffersEffects.prototype, "unpublishMarketPostOffer$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostOffersEffects.prototype, "updateMarketPostOffer$", void 0);
    return MarketPostOffersEffects;
}());
export { MarketPostOffersEffects };
