import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { ContainerType } from '../../store/models/container-types.model';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-container-type-form',
  templateUrl: './container-type-form.component.html',
})
export class ContainerTypeFormComponent implements OnInit, OnChanges {
  @Input() containerType: ContainerType;
  @Output() containerTypeFormSubmitted = new EventEmitter<Location>();

  containerTypeForm: FormGroup;
  loading = false;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (!this.containerTypeForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.containerType && changes.containerType.currentValue) {
      if (!this.containerTypeForm) {
        this.createForm();
      }
      const container = changes.containerType.currentValue;
      this.containerTypeForm.patchValue({
        label: container.label,
        length: container.length,
        reefer: container.reefer
      });
      if (container.aliases && container.aliases.length > 0) {
        this.removeAlias(0);
        container.aliases.forEach(alias => {
          this.addAlias();
        });
        this.containerTypeForm.patchValue({
          aliases: container.aliases
        });
      }
    }
  }

  createForm() {
    this.containerTypeForm = this.fb.group({
      label: [],
      length: [],
      reefer: false,
      aliases: this.fb.array([this.createAlias()])
    });
  }

  createAlias(): FormGroup {
    return this.fb.group({
      purpose: null,
      code: null
    });
  }

  addAlias(): void {
    const aliases = <FormArray>this.containerTypeForm.controls['aliases'];
    aliases.push(this.createAlias());
  }
  removeAlias(index): void {
    const aliases = <FormArray>this.containerTypeForm.controls['aliases'];
    aliases.removeAt(index);
  }

  onSubmit() {
    const value = this.containerTypeForm.value;

    // Filter out empty aliases
    value['aliases'] = value['aliases'].filter(
      alias => alias.purpose && alias.code
    );
    value['length'] = parseInt(value['length'], 10);
    this.containerTypeFormSubmitted.emit(value);
  }
}
