<div class="ml-3 mr-3">
  <div
    class="d-flex row justify-content-between notification-subscription-type"
  >
    <app-cmp-container-type-notification-subscriptions
      class="col mb-3"
      [subscribedOffers]="offerContainerTypes"
      [subscribedDemands]="demandContainerTypes"
    ></app-cmp-container-type-notification-subscriptions>
    <app-cmp-transport-type-notification-subscriptions
      class="col mb-3"
      [subscribedOffers]="offerTransportTypes"
      [subscribedDemands]="demandTransportTypes"
    ></app-cmp-transport-type-notification-subscriptions>
    <app-message-board-messages-subscription
      class="col"
      [messageBoardMessageSubscription]="messageBoardMessageSubscription"
    ></app-message-board-messages-subscription>
  </div>
</div>
