/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../core/admin/tenant-settings/user-contexts/user-context-form/user-context-form.component.ngfactory";
import * as i2 from "../../../core/admin/tenant-settings/user-contexts/user-context-form/user-context-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../services/i18n.service";
import * as i6 from "./new-driver.component";
import * as i7 from "../../../services/toast.service";
import * as i8 from "@ngrx/effects";
import * as i9 from "@angular/router";
var styles_NewDriverComponent = [];
var RenderType_NewDriverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewDriverComponent, data: {} });
export { RenderType_NewDriverComponent as RenderType_NewDriverComponent };
export function View_NewDriverComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-cmp-user-context-form", [], null, [[null, "userContextFormValue"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userContextFormValue" === en)) {
        var pd_0 = (_co.createNewDriver($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_UserContextFormComponent_0, i1.RenderType_UserContextFormComponent)), i0.ɵdid(2, 770048, null, 0, i2.UserContextFormComponent, [i3.FormBuilder, i4.Store, i5.I18nService], { loading: [0, "loading"], driver: [1, "driver"] }, { userContextFormValue: "userContextFormValue" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NewDriverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-new-driver", [], null, null, null, View_NewDriverComponent_0, RenderType_NewDriverComponent)), i0.ɵdid(1, 180224, null, 0, i6.NewDriverComponent, [i4.Store, i7.ToastService, i8.Actions, i9.Router], null, null)], null, null); }
var NewDriverComponentNgFactory = i0.ɵccf("app-cmp-new-driver", i6.NewDriverComponent, View_NewDriverComponent_Host_0, {}, {}, []);
export { NewDriverComponentNgFactory as NewDriverComponentNgFactory };
