import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import {
  PublishMarketPostOffer,
  UnpublishMarketPostOffer
} from 'app/core/store/actions/market-post-offer.actions';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/marketplace.types';
import { SearchService } from 'app/services/search.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-my-offers',
  templateUrl: 'my-offers.component.html',
})
export class MyOffersComponent implements OnDestroy {
  navbarTitle = 'Company Offers';
  offers: any[];
  selected: any[] = [];
  loading = true;
  tenantId = '';

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private searchService: SearchService,
    private toastr: ToastService
  ) {
    this.searchService.resetSearch();
    this.searchService.filter();
    this.store
      .select(state => state.session)
      .pipe(untilDestroyed(this))
      .subscribe(session => {
        if (session) {
          this.tenantId = session.tenant;
        }
      });
    this.store
      .select(state => {
        if (state.marketplace && state.marketplace.marketposts) {
          return state.marketplace.marketposts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(marketposts => {
        if (marketposts) {
          const temp = marketposts.map(mp => mp._source);
          this.offers = temp.filter(
            mp =>
              mp.marketPostType === 'offer' && mp['tenant'].id === this.tenantId
          );
          this.loading = false;
        }
      });
    updates$
      .pipe(ofType(Types.marketposts.LOAD_MARKETPOSTS_OFFERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to load marketpost offers'
        });
      });
    updates$
      .pipe(ofType(Types.marketposts.UNPUBLISH_MARKET_POST_OFFER_SUCCESS))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.searchService.filter();
      });
  }

  ngOnDestroy() { }
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  publishSelected() {
    this.selected.forEach(offer => {
      this.store.dispatch(new PublishMarketPostOffer({ id: offer.id }));
    });
  }

  unpublishSelected() {
    this.selected.forEach(offer => {
      this.store.dispatch(new UnpublishMarketPostOffer({ id: offer.id }));
    });
  }
}
