/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./update-toast.component";
import * as i3 from "ngx-toastr";
var styles_UpdateToastComponent = [];
var RenderType_UpdateToastComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UpdateToastComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { display: "none", opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate3d(100%, 0, 0) skewX(-30deg)", opacity: 0 }, offset: null }, { type: 6, styles: { transform: "skewX(20deg)", opacity: 1 }, offset: null }, { type: 6, styles: { transform: "skewX(-5deg)", opacity: 1 }, offset: null }, { type: 6, styles: { transform: "none", opacity: 1 }, offset: null }] }, timings: "400ms ease-out" }, options: null }, { type: 1, expr: "active => removed", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 6, styles: { transform: "translate3d(100%, 0, 0) skewX(30deg)", opacity: 0 }, offset: null }] }, timings: "400ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_UpdateToastComponent as RenderType_UpdateToastComponent };
function View_UpdateToastComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0], [1, "aria-label", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.titleClass; var currVal_1 = _co.title; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.title; _ck(_v, 1, 0, currVal_2); }); }
function View_UpdateToastComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["aria-live", "polite"], ["role", "alert"]], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.messageClass; var currVal_1 = _co.message; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_UpdateToastComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["aria-live", "polite"], ["role", "alert"]], [[8, "className", 0], [1, "aria-label", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.messageClass; var currVal_1 = _co.message; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.message; _ck(_v, 1, 0, currVal_2); }); }
function View_UpdateToastComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "toast-progress"]], [[4, "width", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.width + "%"); _ck(_v, 1, 0, currVal_0); }); }
export function View_UpdateToastComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UpdateToastComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UpdateToastComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UpdateToastComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UpdateToastComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.message && _co.options.enableHtml); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.message && !_co.options.enableHtml); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.options.progressBar; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_UpdateToastComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["update-toast-component", ""]], [[8, "className", 0], [40, "@flyInOut", 0], [4, "display", null]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).tapToast() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).stickAround() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).delayedHideToast() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_UpdateToastComponent_0, RenderType_UpdateToastComponent)), i0.ɵdid(1, 180224, null, 0, i2.UpdateToastComponent, [i3.ToastrService, i3.ToastPackage], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).toastClasses; var currVal_1 = i0.ɵnov(_v, 1).state; var currVal_2 = i0.ɵnov(_v, 1).displayStyle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var UpdateToastComponentNgFactory = i0.ɵccf("[update-toast-component]", i2.UpdateToastComponent, View_UpdateToastComponent_Host_0, {}, {}, []);
export { UpdateToastComponentNgFactory as UpdateToastComponentNgFactory };
