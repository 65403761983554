<div class="card">
  <h3 class="card-header">{{ formTitle | translate }}</h3>
  <form class="card-body" (ngSubmit)="onSubmit()" [formGroup]="circleForm">
    <div class="form-group">
      <label class="center-block">{{ 'Name' | translate }}</label>
      <input class="form-control" formControlName="name" placeholder="{{ 'The name of your circle' | translate }}" id="circle-name"
        [readOnly]="circle && circle.matching" [ngClass]="{'is-invalid': circleForm.controls['name'].invalid && circleForm.controls['name'].touched || checkUniqueName()}">
      <div *ngIf="circleForm.controls['name'].errors && circleForm.controls['name'].errors.required" class="invalid-feedback">
        {{ 'Circle name is required.' | translate }}
      </div>
      <div *ngIf="circleForm.controls['name'].errors && circleForm.controls['name'].errors.notUnique" class="invalid-feedback">
        {{ 'Cirlce name has to be unique.' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label>{{ 'Add Member' | translate }}</label>
      <input class="form-control" [type]="'text'" placeholder="{{ 'Start typing to see a list of suggestions' | translate }}" #membersInput
        id="member-input" [ngbTypeahead]="searchMember" [resultFormatter]="formatter" [inputFormatter]="formatter" (selectItem)="addMember($event, membersInput)"
      />
    </div>
    <h4 *ngIf="members.controls.length > 0">{{ 'Members' | translate }}</h4>
    <div formArrayName="members">
      <div *ngFor="let member of members.controls; let i=index" class="form-group form-row">
        <label class="col">{{ member.value.label }}</label>
        <i class="far fa-times text-danger" (click)="removeMember(i)"></i>
      </div>
    </div>
    <button class="btn btn-primary text-white" [disabled]="submitting || members.controls.length === 0" type="submit">
      <i *ngIf="submitting" class="far fa-spinner fa-spin"></i>
      <span>{{ buttonText | translate }}</span>
    </button>
  </form>
</div>
