/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../market-posts/market-post-demands/market-post-demand.component.ngfactory";
import * as i2 from "../../market-posts/market-post-demands/market-post-demand.component";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/effects";
import * as i6 from "../../../services/icon.service";
import * as i7 from "../../../shared/loading/loading.component.ngfactory";
import * as i8 from "../../../shared/loading/loading.component";
import * as i9 from "../../market-posts/market-post-table/market-post-table.component.ngfactory";
import * as i10 from "../../market-posts/market-post-table/market-post-table.component";
import * as i11 from "../../../services/toast.service";
import * as i12 from "@angular/common";
import * as i13 from "./my-demands.component";
import * as i14 from "../../../services/search.service";
var styles_MyDemandsComponent = [];
var RenderType_MyDemandsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MyDemandsComponent, data: {} });
export { RenderType_MyDemandsComponent as RenderType_MyDemandsComponent };
function View_MyDemandsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "market-post"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-cmp-market-post-demand", [["class", "card"]], null, null, null, i1.View_MarketPostDemandComponent_0, i1.RenderType_MarketPostDemandComponent)), i0.ɵdid(2, 180224, null, 0, i2.MarketPostDemandComponent, [i3.Store, i4.Router, i5.Actions, i6.IconService], { marketPost: [0, "marketPost"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MyDemandsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i0.ɵdid(1, 49152, null, 0, i8.LoadingComponent, [], { loading: [0, "loading"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-cmp-market-post-table", [["class", "d-none d-md-inline"]], null, null, null, i9.View_MarketPostTableComponent_0, i9.RenderType_MarketPostTableComponent)), i0.ɵdid(3, 704512, null, 0, i10.MarketPostTableComponent, [i4.Router, i3.Store, i5.Actions, i0.NgZone, i11.ToastService, i6.IconService], { marketposts: [0, "marketposts"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "d-md-none"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MyDemandsComponent_1)), i0.ɵdid(6, 278528, null, 0, i12.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.demands; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.demands; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_MyDemandsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-my-demands", [], null, null, null, View_MyDemandsComponent_0, RenderType_MyDemandsComponent)), i0.ɵdid(1, 180224, null, 0, i13.MyDemandsComponent, [i3.Store, i5.Actions, i14.SearchService, i11.ToastService], null, null)], null, null); }
var MyDemandsComponentNgFactory = i0.ɵccf("app-cmp-my-demands", i13.MyDemandsComponent, View_MyDemandsComponent_Host_0, {}, {}, []);
export { MyDemandsComponentNgFactory as MyDemandsComponentNgFactory };
