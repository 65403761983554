import { LoadProducts, UpdateProduct } from 'app/core/store/actions/product.actions';
import { Product } from 'app/core/store/models/product.model';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import * as ProductTypes from 'app/core/store/types/product.types';
import { ApiService } from 'app/core/api/api.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-tariff',
  templateUrl: './edit-tariff.component.html',
})
export class EditTariffComponent implements OnDestroy {
  id: string;
  tariff: Product;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private updates$: Actions,
    private store: Store<State>,
    private api: ApiService,
    private toastr: ToastService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api.get({ path: `/crm/products/${this.id}` }).toPromise().then((res: Product) => {
          this.tariff = {
            ...res,
            parts: res.parts.sort((a, b) => <any>(a.id > b.id) - <any>(b.id > a.id)),
          };
        });
        // this.store
        //   .select(state => state.crm.products)
        //   .pipe(untilDestroyed(this))
        //   .subscribe(productState => {
        //     if (!productState || !productState.customerProducts) {
        //       this.store.dispatch(new LoadProducts());
        //       return;
        //     }
        //     this.tariff = productState.customerProducts.find(
        //       c => c.id === this.id
        //     );
        //   });
      }
    });
    this.updates$
      .pipe(ofType(ProductTypes.product.UPDATE_PRODUCT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update tariff!')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(ProductTypes.product.UPDATE_PRODUCT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Tariff updated successfully!')
        });
        this.store.dispatch(new LoadProducts());
        this.router.navigateByUrl('/crm/tariffs');
        this.isLoading = false;
      });
  }

  editTariff(tariffFormValue) {
    const body = Object.assign({}, tariffFormValue, {
      template: true
    }) as Product;
    this.store.dispatch(
      new UpdateProduct({
        productId: this.id,
        body: body
      })
    );
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
