import { Action } from '@ngrx/store';
import * as types from '../types/transport-task.types';
import { TransportTask } from '../models/transport-task.model';

export class DeleteTransportTask implements Action {
  readonly type = types.transportTask.DELETE_TRANSPORT_TASK;
  constructor(
    public payload?: {
      transportTaskId: string;
    }
  ) { }
}

export class DeleteTransportTaskFail implements Action {
  readonly type = types.transportTask.DELETE_TRANSPORT_TASK_FAILED;
  constructor(public payload: any) { }
}

export class DeleteTransportTaskSuccess implements Action {
  readonly type = types.transportTask.DELETE_TRANSPORT_TASK_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdateTransportTask implements Action {
  readonly type = types.transportTask.UPDATE_TRANSPORT_TASK;
  constructor(
    public payload?: {
      transportTaskId: string;
      body: TransportTask;
    }
  ) { }
}

export class UpdateTransportTaskFail implements Action {
  readonly type = types.transportTask.UPDATE_TRANSPORT_TASK_FAILED;
  constructor(public payload: any) { }
}

export class UpdateTransportTaskSuccess implements Action {
  readonly type = types.transportTask.UPDATE_TRANSPORT_TASK_SUCCEEDED;
  constructor(public payload: any) { }
}

export type All =
  | DeleteTransportTask
  | DeleteTransportTaskFail
  | DeleteTransportTaskSuccess
  | UpdateTransportTask
  | UpdateTransportTaskFail
  | UpdateTransportTaskSuccess;
