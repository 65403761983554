import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { SubscriptionGuard } from 'app/core/auth/route-guards/subscription.guard';
import { TenantGuard } from 'app/core/auth/route-guards/tenant.guard';
import { Route } from 'app/core/route.service';
import { CertiweightBackOfficeEffects } from 'app/core/store/effects/certiweight-back-office.effects';
import { AuthGuard } from 'app/core/user/user.authguard';
import { SharedModule } from 'app/shared/shared.module';

import { CustomersComponent } from '../../crm/customers/customers.component';
import { EditCustomerComponent } from '../../crm/edit-customer/edit-customer.component';
import { NewCustomerComponent } from '../../crm/new-customer/new-customer.component';
import { CertificateDetailComponent } from '../certificate-detail.component';
import { CertiweightBackOfficeComponent } from './certiweight-back-office.component';
import { EditPreannouncementComponent } from './edit-preannouncement.component';
import { LocationCertificatesComponent } from './location-certificates.component';
import { NewPreannouncementComponent } from './new-preannouncement.component';
import { PreannouncementFormComponent } from './preannouncement-form.component';
import { PreannouncementsComponent } from './preannouncements.component';

const routes: Routes = Route.withShell([
  {
    path: 'certiweight-backoffice',
    canActivate: [AuthGuard, TenantGuard, SubscriptionGuard],
    data: { subscriptionCode: 'CERTIWEIGHT_BACK_OFFICE' },
    children: [
      {
        path: '',
        component: CertiweightBackOfficeComponent,
      },
      {
        path: ':id/detail',
        component: CertificateDetailComponent,
      },
      {
        path: 'certificates',
        component: LocationCertificatesComponent,
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            component: CustomersComponent
          },
          {
            path: 'new',
            component: NewCustomerComponent
          },
          {
            path: ':id/edit',
            component: EditCustomerComponent
          }
        ]
      },
      {
        path: 'preannouncements',
        children: [
          {
            path: '',
            component: PreannouncementsComponent
          },
          {
            path: 'new',
            component: NewPreannouncementComponent
          },
          {
            path: ':id/edit',
            component: EditPreannouncementComponent
          }
        ]
      },
    ],
  }
]);

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    EffectsModule,
    EffectsModule.forFeature([
      CertiweightBackOfficeEffects
    ]),
  ],
  declarations: [
    CertiweightBackOfficeComponent,
    PreannouncementsComponent,
    NewPreannouncementComponent,
    EditPreannouncementComponent,
    PreannouncementFormComponent,
    LocationCertificatesComponent,
  ],
  entryComponents: [
  ],
})
export class CertiweightBackOfficeModule { }
