<span class="d-flex justify-content-between align-items-center mb-1">
  <span>
    <span class="text-white badge badge-offer">{{ 'Offer' | translate }}</span>
    <span class="ml-1">{{ tenant?.name }}</span>
  </span>
  <span>{{ offer.transportType?.label | titlecase | translate }}</span>
  <span>{{ offer.container?.containerType?.label }}</span>
  <span>{{ offer.container?.liner?.name | titlecase }}</span>
  <span
    >{{ offer.container?.weight }}
    <span *ngIf="offer.container?.weight">T</span>
  </span>
  <span>
    <span class="icon" *ngIf="offer.genset">
      <i class="far fa-snowflake text-info"></i>
    </span>
    <span class="icon" *ngIf="offer.container?.adr">
      <i class="far fa-exclamation-triangle text-danger"></i>
    </span>
  </span>
  <span *ngIf="!isMatchingPoolOption">{{ offer.reference | titlecase }}</span>
  <ng-content></ng-content>
</span>
<div class="form-section-darker p-1">
  <app-transport-tasks-table
    [showStatus]="false"
    [showReference]="!isMatchingPoolOption"
    [transportTasks]="offer.transportTasks"
    [transportIsOwnedByCurrentTenant]="!isMatchingPoolOption"
  ></app-transport-tasks-table>
  <div class="pt-2 text-smaller d-flex justify-content-between">
    <div>{{ offer.comment }}</div>
    <ng-content select="app-confidence-score"></ng-content>
  </div>
</div>
