<div class="card p-3 mb-2">
  <div class="d-flex justify-content-between subscription__header">
    <app-ribbon *ngIf="subscriptionType?.beta" [text]="'beta'"></app-ribbon>
    <table class="subscription__header--table">
      <tr>
        <td class="subscription__header--table--cell">
          <div class="subscription__header--title">
            {{ subscriptionType?.name }}
          </div>
          <span class="subscription__header--description">{{
            getSubscriptionDescription()
          }}</span>
        </td>
        <td class="subscription__header--table--cell__right">
          <button
            class="btn btn-primary text-white"
            (click)="startSubscription()"
            *ngIf="!subscription?.autoRenew"
            [attr.disabled]="subscriptionType?.code == 'PSA_TRUCK_VISITS' ? '' : null"
          >
            <span
              translate
              class="btn__label"
              [ngClass]="{ invisible: isLoading }"
            >
              <i class="fa fa-fw fa-play"></i>
              {{ 'Start subscription' | translate }}
            </span>
            <i
              class="fa fa-spinner fa-spin btn__loader"
              [ngClass]="{ visible: isLoading }"
            ></i>
          </button>
          <button
            class="btn"
            (click)="stopSubscription()"
            *ngIf="subscription?.autoRenew"
            [attr.disabled]="subscriptionType?.code == 'PSA_TRUCK_VISITS' ? '' : null"
          >
            <span
              translate
              class="btn__label"
              [ngClass]="{ invisible: isLoading }"
            >
              <i class="fa fa-fw fa-pause"></i>
              {{ 'Stop subscription' | translate }}
            </span>
            <i
              class="fa fa-spinner fa-spin btn__loader"
              [ngClass]="{ visible: isLoading }"
            ></i>
          </button>
        </td>
      </tr>
    </table>
  </div>
  <div class="card-body pb-0">
    <table
      class="table"
      *ngIf="
        subscriptionType && subscriptionType.paymentType === 'transactional'
      "
    >
      <thead>
        <tr class="font-weight-bold">
          <td scope="col">
            <div>
              {{ 'Action' | translate }}
              <span
                class="icon"
                ngbTooltip="{{
                  'Each time you perform this action, the amount in the credits column will be substracted from your credit wallet.'
                    | translate
                }}"
              >
                <i class="far fa-question-circle"></i>
              </span>
            </div>
          </td>
          <td scope="col">{{ 'Credits' | translate }}</td>
        </tr>
      </thead>
      <tbody
        *ngIf="
          subscriptionType && subscriptionType.paymentType === 'transactional'
        "
      >
        <tr *ngFor="let action of subscriptionType.subscriptionTypeActions">
          <td>{{ action.name }}</td>
          <td>{{ action.credits }}</td>
        </tr>
      </tbody>
    </table>
    <table
      class="table"
      *ngIf="subscriptionType && subscriptionType.paymentType === 'fixed'"
    >
      <thead>
        <tr class="font-weight-bold">
          <td scope="col">
            <div>{{ 'Name' | translate }}</div>
          </td>
          <td scope="col">{{ 'Credits' | translate }}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'Monthly subscription fee' | translate }}</td>
          <td>{{ subscriptionType.credits }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
