import {
  Component,
  OnInit,
  Output,
  OnChanges,
  Input,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-tenant-form',
  templateUrl: './tenant-form.component.html',
})
export class TenantFormComponent implements OnInit, OnChanges {
  @Input()
  languages;
  @Input()
  countries;
  @Input()
  subscriptionTypes;
  @Input()
  loading;
  @Input()
  tenant;
  @Output()
  tenantFormSubmitted = new EventEmitter<any>();
  tenantForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (!this.tenantForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.tenant && changes.tenant.currentValue) {
      if (!this.tenantForm) {
        this.createForm();
      }
      this.tenantForm.patchValue(changes.tenant.currentValue);
    }
  }

  createForm() {
    this.tenantForm = this.fb.group({
      name: [null],
      country: [null],
      email: [null],
      invoiceEmail: [null],
      bankAccountNumber: [null],
      phoneNumber: [null],
      locale: [null],
      address: [null],
      streetAndNumber: [null],
      zipcode: [null],
      city: [null],
      vatNumber: [null],
      customerCode: [null],
      allowedSubscriptionTypes: [null]
    });
  }

  onSubmit() {
    const body = Object.assign({}, this.tenantForm.value);
    body.allowedSubscriptionTypes = body.allowedSubscriptionTypes.map(
      st => st['@id']
    );
    this.tenantFormSubmitted.emit(body);
  }
}
