import { Action } from '@ngrx/store';
import * as types from '../types/transaction.types';

export class LoadCreditTransactions implements Action {
  readonly type = types.transactions.LOAD_CREDIT_TRANSACTIONS;
  constructor(public payload?: any) { }
}

export class LoadCreditTransactionsSuccess implements Action {
  readonly type = types.transactions.LOAD_CREDIT_TRANSACTIONS_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadCreditTransactionsFail implements Action {
  readonly type = types.transactions.LOAD_CREDIT_TRANSACTIONS_FAILED;
  constructor(public payload: any) { }
}

export class LoadAllTransactions implements Action {
  readonly type = types.transactions.LOAD_ALL_TRANSACTIONS;
  constructor(public payload?: any) { }
}

export class LoadAllTransactionsSuccess implements Action {
  readonly type = types.transactions.LOAD_ALL_TRANSACTIONS_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadAllTransactionsFail implements Action {
  readonly type = types.transactions.LOAD_ALL_TRANSACTIONS_FAILED;
  constructor(public payload: any) { }
}

export class LoadTransactionPage implements Action {
  readonly type = types.transactions.LOAD_TRANSACTION_PAGE;
  constructor(
    public payload: {
      page: string;
    }
  ) { }
}

export class LoadTransactionPageSuccess implements Action {
  readonly type = types.transactions.LOAD_TRANSACTION_PAGE_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadTransactionPageFail implements Action {
  readonly type = types.transactions.LOAD_TRANSACTION_PAGE_FAILED;
  constructor(public payload: any) { }
}

export class ClearCreditTransactions implements Action {
  readonly type = types.transactions.CLEAR;
  constructor(public payload?: any) { }
}

export type All =
  | LoadCreditTransactions
  | LoadCreditTransactionsSuccess
  | LoadCreditTransactionsFail
  | LoadAllTransactions
  | LoadAllTransactionsSuccess
  | LoadAllTransactionsFail
  | LoadTransactionPage
  | LoadTransactionPageSuccess
  | LoadTransactionPageFail
  | ClearCreditTransactions;
