import { SsoLoginComponent } from '../sso-login/sso-login.component';
import { RedirectComponent } from '../redirect/redirect.component';
import { ResetSuccessComponent } from './reset/success/reset.success.component';
import { NgModule } from '@angular/core';
import { ResetUpdateComponent } from './reset/update/reset.update.component';
import { ResetComponent } from './reset/reset.component';
import { LoginComponent } from './login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from '../core/auth/route-guards/logged-in.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { containerClass: 'Login' },
    canActivate: [LoggedInGuard]
  },
  {
    path: 'auth/redirect/:payload',
    component: RedirectComponent
  },
  { path: 'user/reset', component: ResetComponent },
  { path: 'user/reset/success', component: ResetSuccessComponent },
  { path: 'user/reset/:user/:code', component: ResetUpdateComponent },
  { path: 'sso-login', component: SsoLoginComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [],
  providers: []
})
export class AuthenticationRoutingModule { }
