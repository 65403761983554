<span class="input-group search">
  <input
    class="form-control"
    [(ngModel)]="query"
    name="query"
    type="text"
    (keyup)="handleQueryChange($event)"
    placeholder="{{ placeholder | translate }}"
    aria-label="Search"
  />
  <div class="input-group-append">
    <span class="input-group-text"> <i class="far fa-search"></i> </span>
  </div>
</span>
