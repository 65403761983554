import { Tenant } from 'app/core/store/models/tenant.model';
import { IconService } from 'app/services/icon.service';
import { Transport } from 'app/core/store/models/transport.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-transport-matching-pool-item',
  templateUrl: './transport-matching-pool-item.component.html',
})
export class TransportMatchingPoolItemComponent {
  @Input()
  transport: Transport;
  @Input()
  isMatchingPoolOption: boolean;
  @Input()
  tenant: Tenant;

  constructor(private iconService: IconService) { }

  getTransportTypeIcon() {
    return this.iconService.getTransportTypeIcon(this.transport.transportType);
  }
}
