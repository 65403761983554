export const userContexts = {
  LOAD_USER_CONTEXTS: '[UserContexts] Load',
  LOAD_USER_CONTEXT: '[UserContexts] Load Single',
  LOAD_SUCCEEDED: '[UserContexts] Load Success',
  LOAD_SINGLE_SUCCEEDED: '[UserContexts] Load Single Success',
  LOAD_FAILED: '[UserContexts] Load Fail',
  CREATE_USER_CONTEXT: '[UserContext] Create',
  CREATE_SUCCEEDED: '[UserContext] Create Success',
  CREATE_FAILED: '[UserContext] Create Fail',
  UPDATE_USER_CONTEXT: '[UserContext] Update',
  UPDATE_SUCCEEDED: '[UserContext] Update Success',
  UPDATE_FAILED: '[UserContext] Update Fail',
  ENABLE_USER_CONTEXT: '[UserContext] Enable',
  ENABLE_SUCCEEDED: '[UserContext] Enable Success',
  ENABLE_FAILED: '[UserContext] Enable Fail',
  DISABLE_USER_CONTEXT: '[UserContext] Disable',
  DISABLE_SUCCEEDED: '[UserContext] Disable Success',
  DISABLE_FAILED: '[UserContext] Disable Fail',
  DELETE_USER_CONTEXT: '[UserContext] Delete',
  DELETE_SUCCEEDED: '[UserContext] Delete Success',
  DELETE_FAILED: '[UserContext] Delete Fail',
  LOAD_DRIVERS: '[Drivers] Load',
  LOAD_DRIVERS_SUCCEEDED: '[Drivers] Load Success',
  LOAD_DRIVERS_FAILED: '[Drivers] Load Fail',
  CLEAR: '[UserContexts] Clear'
};
