import { CreateTaxRate, LoadTaxRates } from 'app/core/store/actions/tax-rate.actions';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { State } from 'app/core/store/store.model';
import * as Types from 'app/core/store/types/tax-rate.types';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-tax-rate',
  templateUrl: './new-tax-rate.component.html',
})
export class NewTaxRateComponent implements OnDestroy {
  isLoading: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.updates$
      .pipe(ofType(Types.taxRate.CREATE_TAX_RATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to create tax rate!')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(Types.taxRate.CREATE_TAX_RATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Tax rate created successfully!')
        });
        this.store.dispatch(new LoadTaxRates());
        this.router.navigateByUrl('/crm/tax-rates');
        this.isLoading = false;
      });
  }

  createTaxRate(taxRateFormValue) {
    this.store.dispatch(new CreateTaxRate(taxRateFormValue));
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
