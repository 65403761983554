/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./measurement-form.component.ngfactory";
import * as i3 from "./measurement-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../api/api.service";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../services/toast.service";
import * as i8 from "./new-measurement.component";
import * as i9 from "@angular/router";
var styles_NewCertiweightMeasurementComponent = [];
var RenderType_NewCertiweightMeasurementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewCertiweightMeasurementComponent, data: {} });
export { RenderType_NewCertiweightMeasurementComponent as RenderType_NewCertiweightMeasurementComponent };
export function View_NewCertiweightMeasurementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "m-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-container-measurement-form", [], null, [[null, "formSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formSubmitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ContainerMeasurementFormComponent_0, i2.RenderType_ContainerMeasurementFormComponent)), i0.ɵdid(8, 770048, null, 0, i3.ContainerMeasurementFormComponent, [i4.FormBuilder, i5.ApiService, i6.Store, i7.ToastService], { isLoading: [0, "isLoading"] }, { formSubmitted: "formSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("Create container measurement")); _ck(_v, 4, 0, currVal_0); }); }
export function View_NewCertiweightMeasurementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-certiweight-measurement", [], null, null, null, View_NewCertiweightMeasurementComponent_0, RenderType_NewCertiweightMeasurementComponent)), i0.ɵdid(1, 49152, null, 0, i8.NewCertiweightMeasurementComponent, [i5.ApiService, i7.ToastService, i9.Router], null, null)], null, null); }
var NewCertiweightMeasurementComponentNgFactory = i0.ɵccf("app-new-certiweight-measurement", i8.NewCertiweightMeasurementComponent, View_NewCertiweightMeasurementComponent_Host_0, {}, {}, []);
export { NewCertiweightMeasurementComponentNgFactory as NewCertiweightMeasurementComponentNgFactory };
