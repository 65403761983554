var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import * as types from '../types/user-context.types';
import * as UserContextsActions from '../actions/user-contexts.actions';
import * as NotificationsActions from '../actions/notifications.actions';
var UserContextsEffects = /** @class */ (function () {
    function UserContextsEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadUserContexts$ = this.actions$.pipe(ofType(types.userContexts.LOAD_USER_CONTEXTS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/user_contexts?user.superAdmin=" + (payload.includeSuperAdmins ||
                false) + "&roles.name[]=PLANNER&roles.name[]=ADMIN"
        }).pipe(map(function (data) { return new UserContextsActions.LoadUserContextsSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.loadUserContext$ = this.actions$.pipe(ofType(types.userContexts.LOAD_USER_CONTEXT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/user_contexts/' + payload.id
        }).pipe(map(function (data) { return new UserContextsActions.LoadSingleUserContextSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.createUserContext$ = this.actions$.pipe(ofType(types.userContexts.CREATE_USER_CONTEXT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user_contexts', body: payload
        }).pipe(map(function (data) { return new UserContextsActions.CreateUserContextSuccess(data); }), catchError(function (error) { return of(new UserContextsActions.CreateUserContextFail(error)); })); }));
        this.updateUserContext$ = this.actions$.pipe(ofType(types.userContexts.UPDATE_USER_CONTEXT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/user_contexts/' + payload.id, body: payload.body
        }).pipe(map(function (data) { return new UserContextsActions.UpdateUserContextSuccess(data); }), catchError(function (error) { return of(new UserContextsActions.UpdateUserContextFail(error)); })); }));
        this.enableUserContext$ = this.actions$.pipe(ofType(types.userContexts.ENABLE_USER_CONTEXT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user_contexts/' + payload + '/enable', body: {}
        }).pipe(map(function (data) { return new UserContextsActions.UpdateUserContextSuccess(data); }), catchError(function (error) { return of(new UserContextsActions.UpdateUserContextFail(error)); })); }));
        this.disableUserContext$ = this.actions$.pipe(ofType(types.userContexts.DISABLE_USER_CONTEXT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/user_contexts/' + payload + '/disable', body: {}
        }).pipe(map(function (data) { return new UserContextsActions.UpdateUserContextSuccess(data); }), catchError(function (error) { return of(new UserContextsActions.UpdateUserContextFail(error)); })); }));
        this.deleteUserContext$ = this.actions$.pipe(ofType(types.userContexts.DELETE_USER_CONTEXT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: '/user_contexts/' + payload, body: {}
        }).pipe(map(function (data) { return new UserContextsActions.DeleteUserContextSuccess(data); }), catchError(function (error) { return of(new UserContextsActions.DeleteUserContextFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserContextsEffects.prototype, "loadUserContexts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserContextsEffects.prototype, "loadUserContext$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserContextsEffects.prototype, "createUserContext$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserContextsEffects.prototype, "updateUserContext$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserContextsEffects.prototype, "enableUserContext$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserContextsEffects.prototype, "disableUserContext$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserContextsEffects.prototype, "deleteUserContext$", void 0);
    return UserContextsEffects;
}());
export { UserContextsEffects };
