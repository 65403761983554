import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as types from '../types/marketplace.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as TransportTypeActions from '../actions/transport-types.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class TransportTypesEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect() loadTransportTypes$: Observable<Action> = this.actions$.pipe(
    ofType(types.transportTypes.LOAD_TRANSPORT_TYPES),
    concatMap(() => this.api.get({
      path: '/transport_types'
    }).pipe(
      map(data => new TransportTypeActions.LoadTransportTypesSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );
}
