<ngb-datepicker
  #dp
  (select)="onDateSelection($event)"
  [displayMonths]="displayMonths"
  [minDate]="minDate"
  [dayTemplate]="t"
  outsideDays="hidden"
>
</ngb-datepicker>

<ng-template #t let-date="date" let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="
      isFrom(date) || isTo(date) || isInside(date) || isHovered(date)
    "
    [class.faded]="isHovered(date) || isInside(date)"
    [class.outside]="markDisabled(date)"
    [class.text-muted]="markDisabled(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
