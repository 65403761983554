import * as RegistrationRequestActions from '../actions/registraction-request.actions';
import { RegistrationRequest } from '../models/registration-request.model';
import * as types from '../types/registration-request.types';

export interface RegistrationRequestState {
  data: RegistrationRequest[];
  totalElements: number;
  page: string | null;
  nextPage: string | null;
  lastPage: string | null;
  showProcessed: boolean;
}

const INITIAL_STATE = {
  data: null,
  totalElements: 0,
  page: null,
  nextPage: null,
  lastPage: null,
  showProcessed: false,
};

export function reducer(
  state = INITIAL_STATE,
  action: RegistrationRequestActions.All
): RegistrationRequestState {
  switch (action.type) {
    case types.registrationRequests.LOAD_ALL_REGISTRATION_REQUESTS:
      if (!action.payload || !action.payload['toggleShowProcessed']) {
        break;
      }
      state.showProcessed = !state.showProcessed;
      return Object.assign({}, state);

    case types.registrationRequests.LOAD_ALL_SUCCEEDED:
      state.data = [...action.payload['hydra:member']];
      state.totalElements = action.payload['hydra:totalItems'];
      state.page = action.payload['hydra:view']
        ? action.payload['hydra:view']['hydra:first']
        : null;
      state.nextPage = action.payload['hydra:view']
        ? action.payload['hydra:view']['hydra:next']
        : null;
      state.lastPage = action.payload['hydra:view']
        ? action.payload['hydra:view']['hydra:last']
        : null;
      return Object.assign({}, state);
    case types.registrationRequests.LOAD_ONE_SUCCEEDED:
      state.showProcessed = (action.payload.status !== 'pending');
      if (!state.data) {
        break;
      }
      const index = state.data.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.data[index] = action.payload;
        return Object.assign({}, state);
      }
      break;
  }
  return state;
}
