<app-invoice-form
  [customers]="customers"
  [isLoadingCustomers]="isLoadingCustomers"
  [isLoading]="isLoading"
  [taxRates]="taxRates"
  [transports]="filteredTransports"
  [isLoadingTaxRates]="isLoadingTaxRates"
  (invoiceFormSubmitted)="createInvoice($event)"
  (onSaveAndDownloadAsPDF)="createAndDownloadAsPDF($event)"
  (searchTransports)="handleSearchTransports($event)"
  (selectTransport)="handleSelectTransport($event)"
  (removeTransport)="handleRemoveTransport($event)"
  (customerSelected)="handleSelectCustomer($event)"
  (loadNextTransportPage)="loadNextTransportsPage()"
></app-invoice-form>
