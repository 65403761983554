import { ActivatedRoute } from '@angular/router';
import { Circle } from '../../store/models/circles.model';
import { Observable } from 'rxjs';
import { LoadCircles, CreateCircle } from '../../store/actions/circles.actions';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/store.model';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-circles',
  templateUrl: './circles.component.html',
  styleUrls: ['./circles.component.scss']
})
export class CirclesComponent implements OnDestroy {
  title = extract('Manage Circles');
  formTitle = extract('New Circle');
  circles$: Observable<Circle[]>;
  loading = true;
  hasActivatedMatching: string;

  constructor(private store: Store<State>, private route: ActivatedRoute) {
    this.circles$ = this.store
      .select(state => state.circles)
      .pipe(untilDestroyed(this));
    this.store.dispatch(new LoadCircles());
    this.circles$.subscribe(state => {
      if (state) {
        this.loading = false;
      }
    });
    this.hasActivatedMatching = this.route.snapshot.paramMap.get('matching');
  }

  saveCircle(body) {
    this.store.dispatch(new CreateCircle(body));
  }

  ngOnDestroy() { }
}
