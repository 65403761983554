<div class="card">
  <h3 class="card-header">{{ 'Your wallet' | translate }}</h3>
  <app-cmp-loading [loading]="loading"></app-cmp-loading>
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <span>{{ 'Available' | translate }}</span>
      <span>{{ wallet?.credits }}</span>
    </div>
    <div class="d-flex justify-content-between">
      <span
        >{{ 'Reserved' | translate }}
        <span
          class="icon"
          ngbTooltip="{{ 'This is the amount of credits reserved by the matching engine. If no matches are found, you will be refunded.' | translate }}"
        >
          <i class="far fa-question-circle"></i>
        </span>
      </span>
      <span>{{ wallet?.reservedCredits }}</span>
    </div>
    <hr />
    <div class="d-flex font-weight-bold justify-content-between">
      <span>{{ 'Total' | translate }}</span> <span>{{ total }}</span>
    </div>
  </div>
</div>
