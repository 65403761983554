import { MatchingComponent } from './matching.component';
import { AuthGuard } from '../core/user/user.authguard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Route } from '../core/route.service';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';

const defaultMatchingGaurds = [
  AuthGuard,
  TenantGuard,
  SubscriptionGuard,
  DriverGuard,
  AllowedSubscriptionGuard
];
const routes: Routes = Route.withShell([
  {
    path: 'matching',
    canActivate: [...defaultMatchingGaurds],
    data: { subscriptionCode: 'MATCHING' },
    children: [
      {
        path: '',
        component: MatchingComponent,
        canActivate: [...defaultMatchingGaurds],
        data: { subscriptionCode: 'MATCHING' }
      }
    ]
  }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MatchingRoutingModule { }
