import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import * as LocationTypes from 'app/core/store/types/location.types';
import { UpdateUserLocation } from 'app/core/store/actions/location.actions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-user-location-modal',
  templateUrl: './edit-user-location-modal.component.html',
})
export class EditUserLocationModalComponent implements OnDestroy {
  @Input()
  location;

  isLoading: boolean;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    public activeModal: NgbActiveModal
  ) {
    this.updates$
      .pipe(ofType(LocationTypes.location.UPDATE_USER_LOCATION_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Location updated successfully')
        });
        this.activeModal.close(response);
      });
    this.updates$
      .pipe(ofType(LocationTypes.location.UPDATE_USER_LOCATION_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to update location')
        });
      });
  }

  ngOnDestroy() { }

  updateUserLocation(userLocationFormValue) {
    this.isLoading = true;
    this.store.dispatch(
      new UpdateUserLocation({
        locationId: this.location.id,
        location: userLocationFormValue
      })
    );
  }
}
