import { Transport } from 'app/core/store/models/transport.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DownloadService } from 'app/services/download.service';
import { I18nService } from 'app/services/i18n.service';

@Component({
  selector: 'app-transport-download',
  templateUrl: './transport-download-modal.component.html'
})

export class TransportDownloadModalComponent implements OnInit, OnDestroy {
  transport: Transport;
  language: string;
  languageOptions: any;

  constructor(
    public activeModal: NgbActiveModal,
    private downloadService: DownloadService,
    private translateService: I18nService
  ) { }

  ngOnInit() {
    this.languageOptions = this.translateService.languages;
    this.language = this.translateService.language;
  }

  download(format: string) {
    const path = `/tms/transports/${this.transport.id}/download`;
    const language = this.language.split('_')[0];
    switch (format) {
      case 'pdf':
        this.downloadService.downloadPDF(path + '/pdf', this.transport.reference, language);
        break;
      case 'xlsx':
        this.downloadService.downloadXLSX(path + '/xlsx', this.transport.reference, language);
        break;
      default:
        break;
    }
  }

  ngOnDestroy() { }
}
