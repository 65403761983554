import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visit-bar-code',
  templateUrl: './visit-bar-code.component.html',
})
export class VisitBarCodeComponent {
  @Input()
  barCode: string;

  constructor(public activeModal: NgbActiveModal) {}
}
