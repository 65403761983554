/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./confirm-delete-transport.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmDeleteTransportComponent = [];
var RenderType_ConfirmDeleteTransportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmDeleteTransportComponent, data: {} });
export { RenderType_ConfirmDeleteTransportComponent as RenderType_ConfirmDeleteTransportComponent };
export function View_ConfirmDeleteTransportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 13, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(12, 0, null, null, 6, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-danger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-success text-white"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Are you sure you want to delete this transport?")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("This transport has one or more prenotifications attached to it.")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("Deleting this transport will also delete the task from that prenotification.")); _ck(_v, 10, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("Cancel")); _ck(_v, 14, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform("Confirm")); _ck(_v, 17, 0, currVal_4); }); }
export function View_ConfirmDeleteTransportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-delete-transport", [], null, null, null, View_ConfirmDeleteTransportComponent_0, RenderType_ConfirmDeleteTransportComponent)), i0.ɵdid(1, 49152, null, 0, i2.ConfirmDeleteTransportComponent, [i3.NgbActiveModal], null, null)], null, null); }
var ConfirmDeleteTransportComponentNgFactory = i0.ɵccf("app-confirm-delete-transport", i2.ConfirmDeleteTransportComponent, View_ConfirmDeleteTransportComponent_Host_0, {}, {}, []);
export { ConfirmDeleteTransportComponentNgFactory as ConfirmDeleteTransportComponentNgFactory };
