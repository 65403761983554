import { ToastService } from 'app/services/toast.service';
import { Alert } from '../../store/models/alert.model';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api/api.service';

@Component({
  selector: 'app-edit-container-type',
  templateUrl: './edit-container-type.component.html',
})
export class EditContainerTypeComponent {
  containerType: Object;
  alert: Alert;
  id: string;
  constructor(private route: ActivatedRoute, private api: ApiService, private toastr: ToastService) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api
          .get({
            path: '/container_types/' + this.id
          })
          .subscribe(response => {
            this.containerType = response;
          });
      }
    });
  }

  updateContainerType(containerTypeFormValue) {
    this.api
      .put({
        path: '/container_types/' + this.id,
        body: containerTypeFormValue
      })
      .subscribe(
        res => {
          this.toastr.showSuccess({
            message: 'Container Type saved.',
          });
        },
        err => {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: err
          });
        }
      );
  }
}
