<div class="navbar navbar-expand navbar-light bg-light pt-2">
  <app-search
    class="flex-grow-1 mr-2"
    (search)="search$.next($event)"
    [placeholder]="'Search certificates (container no., booking ref.)' | translate"
  ></app-search>
  <button class="btn btn-info text-white" routerLink="/certiweight/my">
    {{ 'My Certificates' | translate }}
  </button>
</div>
<ngx-datatable
  class="bootstrap ml-0 mr-0"
  [rows]="rows"
  [count]="totalItems"
  [loadingIndicator]="loading"
  [externalPaging]="true"
  [limit]="30"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [selectionType]="undefined"
>
  <ngx-datatable-column name="Container number" prop="containerNumber"></ngx-datatable-column>
  <ngx-datatable-column name="Booking reference" prop="bookingReference"></ngx-datatable-column>
  <ngx-datatable-column name="Weighing location" prop="site.name"></ngx-datatable-column>
  <ngx-datatable-column name="Weighing date" prop="announcedOn"></ngx-datatable-column>
  <ngx-datatable-column name="" prop="">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button
        *ngIf="row.certificate && !row.mock"
        class="btn btn-info" [routerLink]="['/certiweight', row.certificate.id, 'detail']"
      >
        <i class="fa-fw far fa-search"></i>
        {{ 'Show detail' | translate }}
      </button>

      <button
        *ngIf="!row.certificate && !row.mock"
        class="btn btn-info" (click)="buy(row)"
      >
        <i class="fa-fw far fa-coins"></i>
        <span>{{ 'Buy' | translate }} (€{{ row.site && row.site.servicePrice ? (row.site.servicePrice | number:'1.2-2') : defaultPrice }})</span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
