<span class="d-flex justify-content-between">
  <span>
    <i class="far fa-calendar-alt"></i> &nbsp;
    <span>{{ creditTransaction.created | date: 'dd-MM-yyyy HH:mm' }}</span>
  </span>
  <span class="text-primary">
    {{ creditTransaction.description | translate }}</span
  >
  <span
    *ngIf="!creditTransaction.reservedWalletTransaction"
    class="text-primary"
    >+{{ creditTransaction.credits }}</span
  >
  <span *ngIf="creditTransaction.reservedWalletTransaction" class="text-primary"
    >( +{{ creditTransaction.credits }} )</span
  >
  <button
    class="btn btn-outline-info"
    *ngIf="
      !creditTransaction.reservedWalletTransaction &&
      creditTransaction.invoiceNumber
    "
    ngbTooltip="Download Invoice"
    placement="left"
  >
    <!--
      Tooltip gets clipped by card header when using default top placement
    -->
    <i class="far fa-download" (click)="getInvoice(creditTransaction)"></i>
    <!--
      <a href="https://apidev.hakka.eu/api/v1/credit_transactions/03800f8f-b575-11e7-a027-525400ade54d/invoice" target="_self" ngbTooltip="Download Invoice" placement="left">
        <i class="far fa-file"></i>
      </a>
    -->
  </button>
</span>
