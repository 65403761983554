<form (ngSubmit)="onSubmit()" [formGroup]="linerForm" class="pb-3">
  <div class="form-group">
    <label class="center-block">{{ 'Name' | translate }}</label>
    <input class="form-control" formControlName="name" [ngClass]="{'is-invalid': linerForm.controls['name'].invalid && linerForm.controls['name'].touched }">
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Short Name' | translate }}</label>
    <input class="form-control" formControlName="shortName" [ngClass]="{'is-invalid': linerForm.controls['shortName'].invalid && linerForm.controls['shortName'].touched }">
  </div>
  <div class="form-group">
    <label class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="reload"
      />
      <span data-cy="reload" class="custom-control-label">Reload</span>
    </label>
  </div>
  <div class="form-group">
    <label class="center-block">
      {{ 'Leading Reload Desk' | translate }}</label
    >
    <ng-select
      id="leadingReloadDesk"
      [items]="['import', 'export']"
      data-cy="leadingReloadDesk"
      placeholder="{{ 'Select Reload Desk' | translate }}"
      formControlName="leadingReloadDesk"
    >
    </ng-select>
  </div>

  <label class="center-block">{{ 'Reload Locations' | translate }}</label>
  <div
    formArrayName="reloadLocations"
    class="mb-3"
    *ngFor="
      let reloadLocation of linerForm.get('reloadLocations')['controls'];
      let rl = index
    "
  >
    <div [formGroupName]="rl" class="form-row">
      <div class="col-2">
        <ng-select
          id="type"
          [items]="['import', 'export']"
          data-cy="type"
          placeholder="{{ 'Select type' | translate }}"
          formControlName="type"
        >
        </ng-select>
      </div>
      <div class="col-2">
        <ng-select
          id="country"
          [items]="countries"
          bindLabel="label"
          bindValue="value"
          data-cy="countries"
          placeholder="{{ 'Select country' | translate }}"
          formControlName="country"
        >
        </ng-select>
      </div>
      <div class="col-2">
        <ng-select
          id="reloadService"
          [items]="reloadServices"
          bindLabel="reloadService"
          data-cy="reloadServices"
          placeholder="{{ 'Select Reload Service' | translate }}"
          formControlName="reloadService"
        >
        </ng-select>
      </div>
      <div class="col-2">
        <ng-select
          id="priceType"
          [items]="priceTypes"
          bindLabel="label"
          bindValue="value"
          data-cy="priceType"
          placeholder="{{ 'Select Price type' | translate }}"
          formControlName="priceType"
        >
        </ng-select>
      </div>
      <div class="col-2" *ngIf="reloadLocation.get('priceType').value === 'fixed'">
        <input
          class="form-control"
          type="number"
          formControlName="fixedPrice"
          placeholder="{{ 'Price' | translate }}"
          data-cy="fixedPrice"
        />
      </div>
      <div class="col-1">
        <button
          class="btn btn-danger text-white"
          [disabled]="rl === 0"
          type="button"
          (click)="removeReloadLocation(rl)"
        >
          <i class="far fa-times"></i>
        </button>
        <button
          class="btn btn-primary ml-1"
          *ngIf="rl === linerForm.get('reloadLocations')['controls'].length - 1"
          type="button"
          (click)="addReloadLocation()"
        >
          <i class="far fa-plus"></i>
        </button>
      </div>
      <div class="col-6" *ngIf="reloadLocation.get('priceType').value === 'distance'">
        <table class="table table-borderless mt-3">
          <thead class="thead-dark">
            <tr>
              <th scope="col">{{ 'From (Km)' | translate }}</th>
              <th scope="col">{{ 'Until (Km)' | translate }}</th>
              <th scope="col">{{ 'Price (€)' | translate }}</th>
              <th scope="col" class="invisible-header"></th>
            </tr>
          </thead>
          <tbody formArrayName="distanceBasedPrice">
            <ng-template
              ngFor
              let-price
              [ngForOf]="reloadLocation.get('distanceBasedPrice')['controls']"
              let-p="index"
            >
              <tr [formGroupName]="p">
                <td class="p-0">
                  <input
                    class="form-control"
                    type="number"
                    data-cy="from"
                    (focus)="
                      p === reloadLocation.get('distanceBasedPrice').value.length - 1
                        ? addPriceItem(rl)
                        : ''
                    "
                    formControlName="from"
                  />
                </td>
                <td class="p-0">
                  <input
                    class="form-control"
                    type="number"
                    (focus)="
                      p === reloadLocation.get('distanceBasedPrice').value.length - 1
                        ? addPriceItem(rl)
                        : ''
                    "
                    formControlName="until"
                    data-cy="until"
                  />
                </td>
                <td class="p-0">
                  <input
                    class="form-control"
                    type="number"
                    (focus)="
                      p === reloadLocation.get('distanceBasedPrice').value.length - 1
                        ? addPriceItem(rl)
                        : ''
                    "
                    formControlName="price"
                    data-cy="price"
                  />
                </td>
                <td class="p-0">
                  <button
                    *ngIf="p !== reloadLocation.get('distanceBasedPrice').value.length - 1"
                    (click)="removePriceItem(rl, p)"
                    class="btn btn-link"
                    data-cy="deleteButton"
                    type="button"
                  >
                    <i
                      class="far fa-fw fa-trash-alt text-danger"
                      ngbTooltip="{{ 'Delete' | translate }}"
                      placement="left"
                    ></i>
                  </button>
                  <button
                    *ngIf="p === reloadLocation.get('distanceBasedPrice').value.length - 1"
                    (click)="addPriceItem(rl)"
                    class="btn btn-link"
                    data-cy="addButton"
                    type="button"
                  >
                    <i
                      class="far fa-fw fa-plus text-success"
                      ngbTooltip="{{ 'New item' | translate }}"
                      placement="left"
                    ></i>
                  </button>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <label class="center-block">{{ 'Aliases' | translate }}</label>
  <div
          formArrayName="aliases"
          class="mb-3"
          *ngFor="
      let alias of linerForm.get('aliases')['controls'];
      let i = index
    "
  >
    <div [formGroupName]="i" class="form-row">
      <div class="col-6">
        <input
                class="form-control"
                formControlName="label"
                placeholder="Liner alias"
        />
      </div>
      <!-- TODO: this should be pulled from backend !-->
      <ng-select class="col-5"
                 formControlName="code"
                 [items]="['AVANTIDA', 'SCAC', 'TAMTRON']"
                 data-cy="aliasType"
                 placeholder="{{ 'Select alias type' | translate }}">
      </ng-select>
      <div class="col-1">
        <button
                class="btn btn-danger text-white"
                [disabled]="i === 0"
                type="button"
                (click)="removeAlias(i)"
        >
          <i class="far fa-times"></i>
        </button>
        <button
                class="btn btn-primary"
                *ngIf="i === linerForm.get('aliases')['controls'].length - 1"
                type="button"
                (click)="addAlias()"
        >
          <i class="far fa-plus"></i>
        </button>
      </div>
    </div>
  </div>

  <button class="btn btn-primary text-white" type="submit" [disabled]="linerForm.invalid || linerForm.pristine">
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span>
      {{ 'Submit' | translate }}
    </span>
  </button>
</form>
