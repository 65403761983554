import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import * as Types from 'app/core/store/types/business-contact.types';
import { extract } from 'app/services/i18n.service';
import { BusinessContact } from 'app/core/store/models/business-contact.model';
import { UpdateContact } from 'app/core/store/actions/business-contact.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-contact-modal',
  templateUrl: './edit-contact-modal.component.html',
})
export class EditContactModalComponent implements OnDestroy {
  @Input()
  contact: BusinessContact;

  isLoading: boolean;

  constructor(
    private store: Store<State>,
    private modal: NgbActiveModal,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.updates$
      .pipe(ofType(Types.BusinessContact.UPDATE_BUSINESS_CONTACT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.isLoading = false;
        this.modal.close(response['payload']);
      });
    this.updates$
      .pipe(ofType(Types.BusinessContact.UPDATE_BUSINESS_CONTACT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.isLoading = false;
        this.toastr.showDanger({
          message: extract('Failed to update contact')
        });
      });
  }

  updateContact(contactFormValue) {
    this.store.dispatch(
      new UpdateContact({
        contactId: this.contact.id,
        body: contactFormValue
      })
    );
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
