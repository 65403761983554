<div class="card p-3">
  <app-card-spinner *ngIf="address && address['isLoading']"></app-card-spinner>
  <div class="d-flex justify-content-between">
    <span>
      <span class="mr-1">{{ 'T.a.v.' | translate }}</span>
      <span>{{ address?.attention }}</span>
    </span>
    <span>
      <span
        *ngIf="address"
        class="edit-icon"
        (click)="launchEditAddressModal()"
      >
        <i class="far fa-fw fa-pencil"></i>
      </span>
      &nbsp;
      <span
        *ngIf="address && !billingAddress"
        class="edit-icon"
        (click)="handleDelete()"
      >
        <i class="far fa-fw fa-trash-alt"></i>
      </span>
    </span>
  </div>
  <div>{{ address?.streetAndNumber }}</div>
  <div>
    <span class="mr-1">{{ address?.zipCode }}</span>
    <span>{{ address?.city }}</span>
  </div>
  <div>{{ address?.country }}</div>
  <div class="right" *ngIf="billingAddress">
    <i class="bg-success text-white far fa-check fa-fw mr-1"></i>
    <span>{{ 'Billing Address' | translate }}</span>
  </div>
  <div
    class="right update-billing-address"
    *ngIf="!billingAddress"
    (click)="setAsBillingAddress()"
  >
    <i class="far fa-check"></i>
    <span>{{ 'Set as billing address' | translate }}</span>
  </div>
</div>
