export const marketPostDemandReplies = {
  LOAD_MARKET_POST_DEMAND_REPLIES: '[MarketPostDemandReplies] Load',
  LOAD_MARKET_POST_DEMAND_REPLIES_SUCCEEDED:
    '[MarketPostDemandReplies] Load Success',
  LOAD_MARKET_POST_DEMAND_REPLIES_FAILED: '[MarketPostDemandReplies] Load Fail'
};

export const marketPostOfferReplies = {
  LOAD_MARKET_POST_OFFER_REPLIES: '[MarketPostOfferReplies] Load',
  LOAD_MARKET_POST_OFFER_REPLIES_SUCCEEDED:
    '[MarketPostOfferReplies] Load Success',
  LOAD_MARKET_POST_OFFER_REPLIES_FAILED: '[MarketPostOfferReplies] Load Fail'
};

export const marketposts = {
  LOAD_MARKETPOSTS: '[MarketPOSTS] LOAD',
  LOAD_MARKETPOSTS_SUCCEEDED: '[MARKETPOSTS] LOAD SUCCESS',
  LOAD_MARKETPOSTS_FAILED: '[Maketposts] Load Fail',
  LOAD_MARKETPOSTS_COUNT: '[MarketPOSTS] Load Count',
  LOAD_MARKETPOSTS_COUNT_SUCCEEDED: '[MARKETPOSTS] Load Count Success',
  LOAD_MARKETPOSTS_COUNT_FAILED: '[Maketposts] Load Fail',
  // Marketpost demand types
  LOAD_MARKETPOSTS_DEMANDS: '[Marketposts] Load Demands',
  LOAD_MARKETPOSTS_DEMANDS_SUCCEEDED: '[Marketposts] Load Demands Success',
  LOAD_MARKETPOSTS_DEMANDS_FAILED: '[Marketposts] Load Demands Fail',
  UPDATE_MARKETPOSTS_DEMAND: '[Marketposts] Update Demand',
  UPDATE_MARKETPOSTS_DEMAND_SUCCEEDED: '[Marketposts] Update Demand Success',
  UPDATE_MARKETPOSTS_DEMAND_FAILED: '[Marketposts] Update Demand Fail',
  CREATE_MARKET_POST_DEMAND: '[MarketPostDemand] CREATE',
  CREATE_DEMAND_SUCCEEDED: '[MarketPostDemand] Create Success',
  CREATE_DEMAND_FAILED: '[MarketPostDemand] Create Fail',
  PROPOSE_MARKET_POST_DEMAND: '[MarketPostDemand] Propose',
  PROPOSE_MARKET_POST_DEMAND_SUCCEEDED: '[MarketPostDemand] Propose Success',
  PROPOSE_MARKET_POST_DEMAND_FAILED: '[MarketPostDemand] Propose Fail',
  PUBLISH_MARKET_POST_DEMAND: '[MarketPostDemand] Publish',
  PUBLISH_MARKET_POST_DEMAND_SUCCESS: '[MarketPostDemand] Publish Success',
  PUBLISH_MARKET_POST_DEMAND_FAILED: '[MarketPostDemand] Publish Fail',
  UNPUBLISH_MARKET_POST_DEMAND: '[MarketPostDemand] Unpublish',
  UNPUBLISH_MARKET_POST_DEMAND_SUCCESS: '[MarketPostDemand] Unpublish Success',
  UNPUBLISH_MARKET_POST_DEMAND_FAILED: '[MarketPostDemand] Unpublish Fail',
  REPLY_MARKET_POST_DEMAND: '[MarketPostDemand] Reply',
  REPLY_MARKET_POST_DEMAND_SUCCEEDED: '[MarketPostDemand] Reply Success',
  REPLY_MARKET_POST_DEMAND_FAILED: '[MarketPostDemand] Reply Fail',
  // Marketpost offer types
  LOAD_MARKETPOSTS_OFFERS: '[Marketposts] Load Offers',
  LOAD_MARKETPOSTS_OFFERS_SUCCEEDED: '[Marketposts] Load Offers Success',
  LOAD_MARKETPOSTS_OFFERS_FAILED: '[Marketposts] Load Offers Fail',
  UPDATE_MARKETPOSTS_OFFER: '[Marketposts] Update Offer',
  UPDATE_MARKETPOSTS_OFFER_SUCCEEDED: '[Marketposts] Update Offer Success',
  UPDATE_MARKETPOSTS_OFFER_FAILED: '[Marketposts] Update Offer Fail',
  CREATE_MARKET_POST_OFFER: '[MarketPostOffer] CREATE',
  CREATE_OFFER_SUCCEEDED: '[MarketPostOffer] Create Success',
  CREATE_OFFER_FAILED: '[MarketPostOffer] Create Fail',
  ACCEPT_MARKET_POST_OFFER: '[MarketPostOffer] Accept',
  ACCEPT_MARKET_POST_OFFER_SUCCEEDED: '[MarketPostOffer] Accept Success',
  ACCEPT_MARKET_POST_OFFER_FAILED: '[MarketPostOffer] Accept Fail',
  PUBLISH_MARKET_POST_OFFER: '[MarketPostOffer] Publish',
  PUBLISH_MARKET_POST_OFFER_SUCCESS: '[MarketPostOffer] Publish Success',
  PUBLISH_MARKET_POST_OFFER_FAILED: '[MarketPostOffer] Publish Fail',
  UNPUBLISH_MARKET_POST_OFFER: '[MarketPostOffer] Unpublish',
  UNPUBLISH_MARKET_POST_OFFER_SUCCESS: '[MarketPostOffer] Unpublish Success',
  UNPUBLISH_MARKET_POST_OFFER_FAILED: '[MarketPostOffer] Unpublish Fail',
  REPLY_MARKET_POST_OFFER: '[MarketPostOffer] Reply',
  REPLY_MARKET_POST_OFFER_SUCCEEDED: '[MarketPostOffer] Reply Success',
  REPLY_MARKET_POST_OFFER_FAILED: '[MarketPostOffer] Reply Fail',
  CLEAR: '[Marketposts] Clear'
};
