import { ToastService } from 'app/services/toast.service';
import { SearchService } from 'app/services/search.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ReplyMarketPostDemand } from 'app/core/store/actions/market-post-demand.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from 'app/core/store/store.model';
import * as Types from 'app/core/store/types/marketplace.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-market-post-demand-detail',
  templateUrl: 'market-post-demand-detail.component.html',
  styleUrls: ['market-post-demand-detail.component.scss']
})
export class MarketPostDemandDetailComponent implements OnInit, OnDestroy {
  marketPost: any;
  transports: any[];
  id: string;
  loading = false;
  query: string;
  alert: {
    icon?: string;
    type: string;
    message: string;
  };
  replyForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private fb: FormBuilder,
    private replyUpdates$: Actions,
    private searchService: SearchService,
    private toastr: ToastService
  ) {
    this.searchService.filter();
    replyUpdates$
      .pipe(ofType(Types.marketposts.REPLY_MARKET_POST_DEMAND_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showSuccess({
          message: 'Reply sent successfully'
        });
      });

    replyUpdates$
      .pipe(ofType(Types.marketposts.REPLY_MARKET_POST_DEMAND_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        this.loading = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: error['payload'].error.detail
        });
      });
  }

  ngOnDestroy() { }

  ngOnInit() {
    this.store
      .select(state => {
        if (state.marketplace) {
          return state.marketplace.marketposts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(transports => {
        if (transports) {
          this.transports = transports.map(t => {
            return {
              '@id': t['@id'],
              reference: t['reference'],
              container: t['container'],
              tasks: t['transportTasks'],
              transportType: t['transportType']
            };
          });
        }
      });
    this.route.params.subscribe(param => {
      this.id = param['id'];
      this.store
        .select(state => {
          if (state.marketplace) {
            return state.marketplace.marketposts;
          }
        })
        .pipe(untilDestroyed(this))
        .subscribe(marketPosts => {
          if (marketPosts) {
            this.marketPost = marketPosts.find(mp => mp['_id'] === this.id);
          }
        });
    });
    this.createForm();
  }

  createForm(): void {
    this.replyForm = this.fb.group({
      text: ['', Validators.required]
    });
  }

  onSubmit(): void {
    const body = Object.assign({}, this.replyForm.value);
    body.marketPostDemand = {
      id: this.id
    };
    this.store.dispatch(
      new ReplyMarketPostDemand({
        body: body
      })
    );
    this.loading = true;
  }

  closeAlert(): void {
    this.alert = null;
  }
}
