/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./circles.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./circle-list/circle-list.component.ngfactory";
import * as i7 from "./circle-list/circle-list.component";
import * as i8 from "@ngrx/store";
import * as i9 from "@ngrx/effects";
import * as i10 from "../../../services/toast.service";
import * as i11 from "./new-circle/new-circle.component.ngfactory";
import * as i12 from "./new-circle/new-circle.component";
import * as i13 from "@angular/forms";
import * as i14 from "../../../services/autocomplete.service";
import * as i15 from "@angular/router";
import * as i16 from "./circles.component";
var styles_CirclesComponent = [i0.styles];
var RenderType_CirclesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CirclesComponent, data: {} });
export { RenderType_CirclesComponent as RenderType_CirclesComponent };
function View_CirclesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ngb-alert", [["class", "text-center alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], null, null, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = false; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("You have activated your matching subscription.")); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Here you can add companies to your matching circle. You will only get matches from companies in this circle. If this circle is empty you will receive matches from all companies.")); _ck(_v, 6, 0, currVal_3); }); }
export function View_CirclesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row ml-1 mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CirclesComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-cmp-circle-list", [], null, null, null, i6.View_CircleListComponent_0, i6.RenderType_CircleListComponent)), i1.ɵdid(5, 245760, null, 0, i7.CircleListComponent, [i8.Store, i9.Actions, i10.ToastService], { loading: [0, "loading"], circles$: [1, "circles$"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-cmp-new-circle", [], null, [[null, "circleSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("circleSubmitted" === en)) {
        var pd_0 = (_co.saveCircle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_NewCircleComponent_0, i11.RenderType_NewCircleComponent)), i1.ɵdid(8, 770048, null, 0, i12.NewCircleComponent, [i13.FormBuilder, i14.Autocomplete, i1.Renderer, i9.Actions, i9.Actions, i15.Router, i10.ToastService], { formTitle: [0, "formTitle"], buttonText: [1, "buttonText"], circles: [2, "circles"] }, { circleSubmitted: "circleSubmitted" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasActivatedMatching; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.loading; var currVal_2 = _co.circles$; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.formTitle; var currVal_4 = "Submit"; var currVal_5 = i1.ɵunv(_v, 8, 2, i1.ɵnov(_v, 9).transform(_co.circles$)); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_CirclesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-circles", [], null, null, null, View_CirclesComponent_0, RenderType_CirclesComponent)), i1.ɵdid(1, 180224, null, 0, i16.CirclesComponent, [i8.Store, i15.ActivatedRoute], null, null)], null, null); }
var CirclesComponentNgFactory = i1.ɵccf("app-cmp-circles", i16.CirclesComponent, View_CirclesComponent_Host_0, {}, {}, []);
export { CirclesComponentNgFactory as CirclesComponentNgFactory };
