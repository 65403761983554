import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/notification-subscription.types';
import * as NotificationSubscriptionsActions from '../../store/actions/notification-subscriptions.actions';
import * as NotificationsActions from '../actions/notifications.actions';

@Injectable()
export class NotificationSubscriptionsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadNotificationSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.notificationSubscriptions.LOAD_NOTIFICATION_SUBSCRIPTIONS),
    concatMap(() => this.api.get({ path: '/notification_subscriptions' }).pipe(
      map(data => new NotificationSubscriptionsActions.LoadNotificationSubscriptionsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  enableTTypeNotificationSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.notificationSubscriptions.ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION),
    map((action: NotificationSubscriptionsActions.EnableTransportTypeNotificationSubscription) => action.payload),
    concatMap(payload => this.api.post({
      path: '/notification_subscriptions', body: payload.body
    }).pipe(
      map(data => new NotificationSubscriptionsActions.EnableTransportTypeNotificationSubscriptionSuccess(data)),
      catchError(error => of(new NotificationSubscriptionsActions.EnableTransportTypeNotificationSubscriptionFailed(error)))
    )),
  );

  @Effect()
  disableTTypeNotificationSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.notificationSubscriptions.DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION),
    map((action: NotificationSubscriptionsActions.DisableTransportTypeNotificationSubscription) => action.payload),
    concatMap(payload => this.api.delete({
      path: '/notification_subscriptions/' + payload.id
    }).pipe(
      map(data => new NotificationSubscriptionsActions.DisableTransportTypeNotificationSubscriptionSuccess(data)),
      catchError(error => of(new NotificationSubscriptionsActions.DisableTransportTypeNotificationSubscriptionFailed(error)))
    )),
  );

  @Effect()
  enableCTypeNotificationSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.notificationSubscriptions.ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION),
    map((action: NotificationSubscriptionsActions.EnableContainerTypeNotificationSubscription) => action.payload),
    concatMap(payload => this.api.post({
      path: '/notification_subscriptions',
      body: payload.body
    }).pipe(
      map(data => new NotificationSubscriptionsActions.EnableContainerTypeNotificationSubscriptionSuccess(data)),
      catchError(error => of(new NotificationSubscriptionsActions.EnableContainerTypeNotificationSubscriptionFailed(error)))
    )),
  );

  @Effect()
  disableCTypeNotificationSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.notificationSubscriptions.DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION),
    map((action: NotificationSubscriptionsActions.DisableContainerTypeNotificationSubscription) => action.payload),
    concatMap(payload => this.api.delete({
      path: '/notification_subscriptions/' + payload.id
    }).pipe(
      map(data => new NotificationSubscriptionsActions.DisableContainerTypeNotificationSubscriptionSuccess(data)),
      catchError(error => of(new NotificationSubscriptionsActions.DisableContainerTypeNotificationSubscriptionFailed(error)))
    )),
  );

  @Effect()
  enableNotificationSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.notificationSubscriptions.ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION),
    concatMap(() => this.api.post({
      path: '/notification_subscriptions',
      body: { type: 'message_board' }
    }).pipe(
      map(data => new NotificationSubscriptionsActions.EnableMessageBoardMessagesNotificationSubscriptionSuccess(data)),
      catchError(error => of(new NotificationSubscriptionsActions.EnableMessageBoardMessagesNotificationSubscriptionFail(error)))
    )),
  );

  @Effect()
  disableNotificationSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.notificationSubscriptions.DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION),
    map((action: NotificationSubscriptionsActions.DisableMessageBoardMessagesNotificationSubscription) => action.payload),
    concatMap(payload => this.api.delete({
      path: '/notification_subscriptions/' + payload.id
    }).pipe(
      map(data => new NotificationSubscriptionsActions.DisableMessageBoardMessagesNotificationSubscriptionSuccess(data)),
      catchError(error => of(new NotificationSubscriptionsActions.DisableMessageBoardMessagesNotificationSubscriptionFail(error)))
    )),
  );
}
