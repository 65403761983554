import { DownloadService } from 'app/services/download.service';
import { PurchaseOrder, PaymentNoteStates } from 'app/core/store/models/payment-note.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { extract } from 'app/services/i18n.service';
import { Store } from '@ngrx/store';
import { ToastService } from 'app/services/toast.service';
import { State } from 'app/core/store/store.model';
import {
  LoadPurchaseOrders,
  UpdatePurchaseOrder,
  LoadFilteredPurchaseOrders,
  DeletePurchaseOrder
} from 'app/core/store/actions/purchase-order.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/purchase-order.types';
import { Router } from '@angular/router';
import * as DateFns from 'date-fns';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss']
})
export class PurchaseOrdersComponent implements OnInit, OnDestroy {
  purchaseOrders: PurchaseOrder[];
  filteredPurchaseOrders: PurchaseOrder[];
  isLoadingPurchaseOrders: boolean;
  totalElements = 0;
  PurchaseOrderStates = PaymentNoteStates;
  tableMessages = {
    emptyMessage: extract('No purchase orders to display'),
    totalMessage: extract('total')
  };
  placeholder = extract('Search name or VAT number');
  query: any;
  page: any;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router,
    private downloadService: DownloadService,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.purchaseOrders &&
          state.crm.purchaseOrders.purchaseOrders
        ) {
          return state.crm.purchaseOrders.purchaseOrders;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(purchaseOrderState => {
        if (!purchaseOrderState) {
          return;
        }
        this.purchaseOrders = [...purchaseOrderState];
        this.isLoadingPurchaseOrders = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.purchaseOrders &&
          state.crm.purchaseOrders.filteredPurchaseOrders
        ) {
          return state.crm.purchaseOrders.filteredPurchaseOrders;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(purchaseOrderState => {
        if (!purchaseOrderState) {
          return;
        }
        this.filteredPurchaseOrders = [...purchaseOrderState];
        this.isLoadingPurchaseOrders = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.purchaseOrders &&
          state.crm.purchaseOrders.totalFilteredPurchaseOrders
        ) {
          return state.crm.purchaseOrders.totalFilteredPurchaseOrders;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(totalElements => {
        if (!totalElements) {
          this.totalElements = 0;
          return;
        }
        this.totalElements = totalElements;
      });
    this.updates$
      .pipe(ofType(Types.purchaseOrder.LOAD_PURCHASE_ORDERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingPurchaseOrders = false;
        this.toastr.showDanger({
          message: extract('Failed to load purchase orders')
        });
      });
    this.updates$
      .pipe(ofType(Types.purchaseOrder.UPDATE_PURCHASE_ORDER_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update state')
        });
      });
    this.updates$
      .pipe(ofType(Types.purchaseOrder.UPDATE_PURCHASE_ORDER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoadingPurchaseOrders = true;
        this.toastr.showSuccess({
          message: extract('State updated successfully')
        });
        this.store.dispatch(new LoadPurchaseOrders());
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadPurchaseOrders());
    this.isLoadingPurchaseOrders = true;
  }

  getDaysLeft(dueDate) {
    return DateFns.differenceInCalendarDays(dueDate, new Date());
  }

  handleSearch(query, page?) {
    const p = page ? page : 1;
    if (!query) {
      this.query = null;
      this.store.dispatch(new LoadPurchaseOrders({ page: p }));
      return;
    }
    this.query = query;
    this.isLoadingPurchaseOrders = true;
    this.store.dispatch(new LoadFilteredPurchaseOrders({ query: query }));
  }

  setPage($event) {
    this.page = $event.offset + 1;
    this.handleSearch(this.query, this.page);
  }

  editPurchaseOrder(purchaseOrderId: string) {
    this.router.navigateByUrl(`/crm/purchase-orders/${purchaseOrderId}/edit`);
  }
  deletePurchaseOrder(purchaseOrderId: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message =
      extract('Are you sure you want to permanently delete this purchase order');
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.isLoadingPurchaseOrders = true;
      this.store.dispatch(new DeletePurchaseOrder({ purchaseOrderId }));
    });
  }

  downloadPurchaseOrderPDF(id: string, refNumber: string) {
    this.downloadService.downloadPDF(`/crm/purchase_order/download/${id}`, refNumber);
  }

  updatePurchaseOrderState(id: string, state: string) {
    this.store.dispatch(new UpdatePurchaseOrder({
      purchaseOrderId: id,
      body: { id, state },
    }));
  }

  getPurchaseOrderStateValue(state: string) {
    return state.replace('_', ' ');
  }

  getPurchaseOrderStateIcon(state) {
    switch (state) {
      case this.PurchaseOrderStates.INVOICE_RECEIVED:
        return 'fa-file-invoice-dollar';
      case this.PurchaseOrderStates.DRAFT:
        return 'fa-file-edit';
      case this.PurchaseOrderStates.SENT:
        return 'fa-envelope';
      case this.PurchaseOrderStates.PAID:
        return 'fa-check';
      default:
        break;
    }
  }

  ngOnDestroy() { }
}
