import { Route } from './core/route.service';
import { AllowedSubscriptionGuard } from './core/auth/route-guards/allowed-subscription.guard';
import { SamlService } from './services/saml.service';
import { UpdateEffects } from './core/store/effects/updates.effects';
import { IconService } from './services/icon.service';
import { MatchingModule } from './matching/matching.module';
import { DatatableService } from './services/datatable.service';
import { TmsModule } from './tms/tms.module';
import { I18nService } from './services/i18n.service';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { ActionsSubject } from '@ngrx/store';
import { AuthGuard, PlannerGuard, BetaUserGuard } from './core/user/user.authguard';
import { ApiInterceptor } from './core/api/api.interceptor';
import { APP_INITIALIZER, NgModule, Injector, Injectable, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { UpdateToastComponent } from './authentication/update-toast/update-toast.component';
import { AppConfig } from './app.config';
import { AuthService } from './core/user/user.auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Autocomplete } from './services/autocomplete.service';
import * as Sentry from '@sentry/browser';

// modules
import { CertiweightModule } from './certiweight/certiweight.module';
import { CertiweightBackOfficeModule } from './certiweight/backoffice/certiweight-back-office.module';
import { MarketplaceModule } from './marketplace/marketplace.module';
import { AppRoutingModule } from './app-routing.module';
import { ReloadModule } from './reload/reload.module';
import { ContactsModule } from './core/contacts/contacts.module';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { ToastService } from './services/toast.service';
import { DragulaModule } from 'ng2-dragula';
import { ErrorParserService } from './services/error-parser.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { RedirectComponent } from './redirect/redirect.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { CrmModule } from './crm/crm.module';
import { MultipleTenantGuard } from './core/auth/route-guards/multiple-tenant.guard';
import { AdminGuard } from './core/auth/route-guards/admin.guard';
import { DriverGuard } from './core/auth/route-guards/driver.guard';
import { SuperAdminGuard } from './core/auth/route-guards/super-admin.guard';
import { SubscriptionGuard } from './core/auth/route-guards/subscription.guard';
import { TenantGuard } from './core/auth/route-guards/tenant.guard';
import { DownloadService } from './services/download.service';
import { LocationService } from './services/location.service';
import { LoggedInGuard } from './core/auth/route-guards/logged-in.guard';
import { ValidationService } from './services/validation.service';
import { ConfirmActionModalComponent } from './shared/confirm-action-modal/confirm-action-modal.component';
import { SoftDeleteService } from './services/soft-delete.service';

// This is used to make drop-in environment files possible
export function startupServiceFactory(startupService: AppConfig): Function {
  return () => startupService.load().then(function() {
    Sentry.init({
      dsn: startupService.getConfig('sentryUrl')
    });
  });
}

export function HttpLoaderFactory(inj: Injector) {
  const http = inj.get(HttpClient);
  return new TranslateHttpLoader(http, './../assets/i18n/', '.json');
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    console.error(error);
    Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    RedirectComponent,
    SsoLoginComponent
  ],
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragulaModule.forRoot(),
    EffectsModule.forRoot([UpdateEffects]),
    HttpClientModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [Injector]
      }
    }),
    CoreModule,
    MarketplaceModule,
    ReloadModule,
    AuthenticationModule,
    TmsModule,
    DashboardModule,
    MatchingModule,
    CrmModule,
    CertiweightModule,
    CertiweightBackOfficeModule,
    ContactsModule,
    /* IMPORTANT: If you add another module, be sure to move the wildcard route '**'
     *  from the last module listed here and move it to the routing module of the module you're adding.
     *  If you don't do this, all the routes of the new module will redirect to 404.
     */
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  entryComponents: [UpdateToastComponent, ConfirmActionModalComponent],
  providers: [
    LoggedInGuard,
    DriverGuard,
    AuthGuard,
    AdminGuard,
    HttpUrlEncodingCodec,
    BetaUserGuard,
    SuperAdminGuard,
    PlannerGuard,
    TenantGuard,
    MultipleTenantGuard,
    AllowedSubscriptionGuard,
    SubscriptionGuard,
    Autocomplete,
    AuthService,
    SoftDeleteService,
    IconService,
    LocationService,
    DatatableService,
    ActionsSubject,
    Route,
    I18nService,
    ToastService,
    ErrorParserService,
    DownloadService,
    SamlService,
    ValidationService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
