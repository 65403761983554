/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../visit-form/visit-form.component.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../shared/datepicker-config";
import * as i5 from "../visit-form/visit-form.component";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../core/api/api.service";
import * as i8 from "../../../services/icon.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../services/toast.service";
import * as i11 from "./new-visit-modal.component";
import * as i12 from "@ngrx/effects";
var styles_NewVisitModalComponent = [];
var RenderType_NewVisitModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewVisitModalComponent, data: {} });
export { RenderType_NewVisitModalComponent as RenderType_NewVisitModalComponent };
export function View_NewVisitModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "card-header d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [["class", "align-self-center mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "btn ml-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 3, "app-visit-form", [], null, [[null, "visitFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visitFormSubmitted" === en)) {
        var pd_0 = (_co.createVisit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VisitFormComponent_0, i2.RenderType_VisitFormComponent)), i0.ɵprd(4608, null, i3.NgbDateAdapter, i4.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i3.NgbDateParserFormatter, i4.NgbDateCustomParserFormatter, []), i0.ɵdid(10, 704512, null, 0, i5.VisitFormComponent, [i6.Store, i7.ApiService, i3.NgbModal, i8.IconService, i9.ActivatedRoute, i10.ToastService, i3.NgbCalendar], { task: [0, "task"], primaryTaskDate: [1, "primaryTaskDate"], isLoading: [2, "isLoading"] }, { visitFormSubmitted: "visitFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.task; var currVal_3 = _co.primaryTaskDate; var currVal_4 = _co.isLoading; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Add new visit")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Close")); _ck(_v, 5, 0, currVal_1); }); }
export function View_NewVisitModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-visit-modal", [], null, null, null, View_NewVisitModalComponent_0, RenderType_NewVisitModalComponent)), i0.ɵdid(1, 245760, null, 0, i11.NewVisitModalComponent, [i6.Store, i12.Actions, i10.ToastService, i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewVisitModalComponentNgFactory = i0.ɵccf("app-new-visit-modal", i11.NewVisitModalComponent, View_NewVisitModalComponent_Host_0, { task: "task", primaryTaskDate: "primaryTaskDate" }, { visitCreated: "visitCreated" }, []);
export { NewVisitModalComponentNgFactory as NewVisitModalComponentNgFactory };
