import { Action } from '@ngrx/store';
import * as types from '../types/supplier.types';

export class LoadSuppliers implements Action {
  readonly type = types.supplier.LOAD_SUPPLIERS;
  constructor(public payload?: any) { }
}

export class LoadSuppliersFail implements Action {
  readonly type = types.supplier.LOAD_SUPPLIERS_FAILED;
  constructor(public payload: any) { }
}

export class LoadSuppliersSuccess implements Action {
  readonly type = types.supplier.LOAD_SUPPLIERS_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadFilteredSuppliers implements Action {
  readonly type = types.supplier.LOAD_FILTERED_SUPPLIERS;
  constructor(public payload?: any) { }
}

export class LoadFilteredSuppliersFail implements Action {
  readonly type = types.supplier.LOAD_FILTERED_SUPPLIERS_FAILED;
  constructor(public payload: any) { }
}

export class LoadFilteredSuppliersSuccess implements Action {
  readonly type = types.supplier.LOAD_FILTERED_SUPPLIERS_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateSupplier implements Action {
  readonly type = types.supplier.CREATE_SUPPLIER;
  constructor(public payload?: any) { }
}

export class CreateSupplierFail implements Action {
  readonly type = types.supplier.CREATE_SUPPLIER_FAILED;
  constructor(public payload: any) { }
}

export class CreateSupplierSuccess implements Action {
  readonly type = types.supplier.CREATE_SUPPLIER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class DeleteSupplier implements Action {
  readonly type = types.supplier.DELETE_SUPPLIER;
  constructor(public payload: { supplierId: string }) { }
}

export class DeleteSupplierFail implements Action {
  readonly type = types.supplier.DELETE_SUPPLIER_FAILED;
  constructor(public payload: any) { }
}

export class DeleteSupplierSuccess implements Action {
  readonly type = types.supplier.DELETE_SUPPLIER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdateSupplier implements Action {
  readonly type = types.supplier.UPDATE_SUPPLIER;
  constructor(
    public payload: {
      supplierId: string;
      supplier: any;
    }
  ) { }
}

export class UpdateSupplierFail implements Action {
  readonly type = types.supplier.UPDATE_SUPPLIER_FAILED;
  constructor(public payload: any) { }
}

export class UpdateSupplierSuccess implements Action {
  readonly type = types.supplier.UPDATE_SUPPLIER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class ClearSuppliers implements Action {
  readonly type = types.supplier.CLEAR;
  constructor(public payload?: any) { }
}

export type All =
  | LoadSuppliers
  | LoadSuppliersFail
  | LoadSuppliersSuccess
  | LoadFilteredSuppliers
  | LoadFilteredSuppliersFail
  | LoadFilteredSuppliersSuccess
  | CreateSupplier
  | CreateSupplierSuccess
  | CreateSupplierFail
  | DeleteSupplier
  | DeleteSupplierSuccess
  | DeleteSupplierFail
  | UpdateSupplier
  | UpdateSupplierSuccess
  | UpdateSupplierFail
  | ClearSuppliers;
