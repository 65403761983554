import { Action } from '@ngrx/store';
import * as types from '../types/marketplace.types';

export class LoadMarketPostDemands implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_DEMANDS;
  constructor(public payload?: any) { }
}

export class LoadMarketPostDemandsSuccess implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_DEMANDS_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadMarketPostDemandsFail implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_DEMANDS_FAILED;
  constructor(public payload?: any) { }
}

export class UpdateMarketPostDemand implements Action {
  readonly type = types.marketposts.UPDATE_MARKETPOSTS_DEMAND;
  constructor(public payload?: any) { }
}

export class UpdateMarketPostDemandSuccess implements Action {
  readonly type = types.marketposts.UPDATE_MARKETPOSTS_DEMAND_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class UpdateMarketPostDemandFail implements Action {
  readonly type = types.marketposts.UPDATE_MARKETPOSTS_DEMAND_FAILED;
  constructor(public payload?: any) { }
}

export class CreateMarketPostDemand implements Action {
  readonly type = types.marketposts.CREATE_MARKET_POST_DEMAND;
  constructor(public payload: any) { }
}

export class CreateMarketPostDemandSuccess implements Action {
  readonly type = types.marketposts.CREATE_DEMAND_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class CreateMarketPostDemandFail implements Action {
  readonly type = types.marketposts.CREATE_DEMAND_FAILED;
  constructor(public payload?: any) { }
}

export class ProposeMarketPostDemand implements Action {
  readonly type = types.marketposts.PROPOSE_MARKET_POST_DEMAND;
  constructor(public payload?: any) { }
}

export class ProposeMarketPostDemandSuccess implements Action {
  readonly type = types.marketposts.PROPOSE_MARKET_POST_DEMAND_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class ProposeMarketPostDemandFail implements Action {
  readonly type = types.marketposts.PROPOSE_MARKET_POST_DEMAND_FAILED;
  constructor(public payload?: any) { }
}

export class PublishMarketPostDemand implements Action {
  readonly type = types.marketposts.PUBLISH_MARKET_POST_DEMAND;
  constructor(public payload?: any) { }
}

export class PublishMarketPostDemandSuccess implements Action {
  readonly type = types.marketposts.PUBLISH_MARKET_POST_DEMAND_SUCCESS;
  constructor(public payload?: any) { }
}

export class PublishMarketPostDemandFail implements Action {
  readonly type = types.marketposts.PUBLISH_MARKET_POST_DEMAND_FAILED;
  constructor(public payload?: any) { }
}

export class UnpublishMarketPostDemand implements Action {
  readonly type = types.marketposts.UNPUBLISH_MARKET_POST_DEMAND;
  constructor(public payload?: any) { }
}

export class UnpublishMarketPostDemandSuccess implements Action {
  readonly type = types.marketposts.UNPUBLISH_MARKET_POST_DEMAND_SUCCESS;
  constructor(public payload?: any) { }
}

export class UnpublishMarketPostDemandFail implements Action {
  readonly type = types.marketposts.UNPUBLISH_MARKET_POST_DEMAND_FAILED;
  constructor(public payload?: any) { }
}

export class ReplyMarketPostDemand implements Action {
  readonly type = types.marketposts.REPLY_MARKET_POST_DEMAND;
  constructor(public payload?: any) { }
}

export class ReplyMarketPostDemandSuccess implements Action {
  readonly type = types.marketposts.REPLY_MARKET_POST_DEMAND_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class ReplyMarketPostDemandFail implements Action {
  readonly type = types.marketposts.REPLY_MARKET_POST_DEMAND_FAILED;
  constructor(public payload?: any) { }
}

export type All = LoadMarketPostDemands
                | LoadMarketPostDemandsSuccess
                | LoadMarketPostDemandsFail
                | CreateMarketPostDemand
                | CreateMarketPostDemandSuccess
                | CreateMarketPostDemandFail
                | UpdateMarketPostDemand
                | UpdateMarketPostDemandSuccess
                | UpdateMarketPostDemandFail
                | ProposeMarketPostDemand
                | ProposeMarketPostDemandSuccess
                | ProposeMarketPostDemandFail
                | PublishMarketPostDemand
                | PublishMarketPostDemandSuccess
                | PublishMarketPostDemandFail
                | ReplyMarketPostDemand
                | ReplyMarketPostDemandSuccess
                | ReplyMarketPostDemandFail
                | PublishMarketPostDemand
                | PublishMarketPostDemandSuccess
                | PublishMarketPostDemandFail
                | UnpublishMarketPostDemand
                | UnpublishMarketPostDemandSuccess
                | UnpublishMarketPostDemandFail;
