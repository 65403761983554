/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscriptions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./subscription/subscription.component.ngfactory";
import * as i3 from "./subscription/subscription.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "./subscriptions.component";
import * as i7 from "@ngrx/effects";
import * as i8 from "../../../user/user.auth";
import * as i9 from "../../../../services/toast.service";
import * as i10 from "@angular/router";
var styles_SubscriptionsComponent = [i0.styles];
var RenderType_SubscriptionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionsComponent, data: {} });
export { RenderType_SubscriptionsComponent as RenderType_SubscriptionsComponent };
function View_SubscriptionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-subscription", [], null, null, null, i2.View_SubscriptionComponent_0, i2.RenderType_SubscriptionComponent)), i1.ɵdid(1, 49152, null, 0, i3.SubscriptionComponent, [i4.Store], { subscriptionType: [0, "subscriptionType"], subscription: [1, "subscription"], credits: [2, "credits"], active: [3, "active"], isLoading: [4, "isLoading"], hasEnoughCredits: [5, "hasEnoughCredits"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.getSubscription(_v.context.$implicit); var currVal_2 = _co.credits; var currVal_3 = _co.hasActiveSubscription(_v.context.$implicit); var currVal_4 = _co.isLoading; var currVal_5 = _co.hasEnoughCredits(_v.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_SubscriptionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionsComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscriptionTypes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SubscriptionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-subscriptions", [], null, null, null, View_SubscriptionsComponent_0, RenderType_SubscriptionsComponent)), i1.ɵdid(1, 180224, null, 0, i6.SubscriptionsComponent, [i4.Store, i7.Actions, i8.AuthService, i9.ToastService, i10.Router], null, null)], null, null); }
var SubscriptionsComponentNgFactory = i1.ɵccf("app-cmp-subscriptions", i6.SubscriptionsComponent, View_SubscriptionsComponent_Host_0, {}, {}, []);
export { SubscriptionsComponentNgFactory as SubscriptionsComponentNgFactory };
