import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as TenantTypes from 'app/core/store/types/tenant.types';
import * as SessionTypes from 'app/core/store/types/session.types';
import { extract } from 'app/services/i18n.service';

// models
import { Tenant } from 'app/core/store/models/tenant.model';
import { State } from 'app/core/store/store.model';
import { LoginUser } from 'app/core/store/models/login.model';

// rxjs
import { Observable } from 'rxjs';
import { ToastService } from 'app/services/toast.service';
import { Login } from 'app/core/store/actions/session.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  loading = false;
  tenants$: Observable<Tenant[]>;
  isLoading: boolean;
  isLoadingSAML: boolean;

  constructor(
    private router: Router,
    private store: Store<State>,
    private updates$: Actions,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.message) {
        this.toastr.showSuccess({ message: params.message });
      }
      if (params && params.error) {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: extract('C-POINT user not recognized')
        });
      }
    });

    updates$
      .pipe(ofType(SessionTypes.session.LOGIN_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/user/tenants']);
      });

    updates$
      .pipe(ofType(TenantTypes.tenants.LOAD_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
      });

    updates$
      .pipe(ofType(
        SessionTypes.session.LOGIN_FAILED,
        TenantTypes.tenants.LOAD_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Please try again later'
        });
      });
  }

  title: 'Login';
  user = new LoginUser();
  state = {
    visible: false,
    message: '',
    type: '',
    icon: null
  };

  ngOnDestroy() { }

  async login() {
    this.isLoading = true;
    this.store.dispatch(new Login(this.user));
  }

  reset() {
    this.router.navigate(['user/reset']);
  }

  doSamlRedirect() {
    this.isLoadingSAML = true;
    this.router.navigate(['sso-login']);
  }
}
