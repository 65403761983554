<div class="login-background fullscreen overflow-sm-x-hidden overflow-y">
  <div class="row justify-content-center mt-5">
    <div class="col-sm-8 col-md-6 col-lg-4">
      <div data-cy="reset-success" class="card reset-card">
        <div class="card-body">
          <div class="col col-md-8">
            <h3>
              {{ 'A password reset email has been dispatched.' | translate }}
            </h3>
            <p class="text-muted">
              {{
                'Please check your mailbox. It should contain an email with a link to reset your password.'
                  | translate
              }}
            </p>
          </div>
        </div>
        <div class="card-footer">
          <a data-cy="return" routerLink="/" class="btn float-right">{{
            'Return to login' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
