<div class="container">
  <app-cmp-subscription
    *ngFor="let subscription of subscriptionTypes"
    [hasEnoughCredits]="hasEnoughCredits(subscription)"
    [subscription]="getSubscription(subscription)"
    [credits]="credits"
    [subscriptionType]="subscription"
    [isLoading]="isLoading"
    [active]="hasActiveSubscription(subscription)"
  >
  </app-cmp-subscription>
</div>
