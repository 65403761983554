import { Component } from '@angular/core';
import { Transport } from 'app/core/store/models/transport.model';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';

@Component({
  selector: 'app-copy-transport',
  templateUrl: './copy-transport.component.html',
})
export class CopyTransportComponent {
  transport: Transport;

  constructor(private store: Store<State>) {
    this.store
      .select(state => {
        if (state && state.transports && state.transports['transportCopy']) {
          return state.transports['transportCopy'];
        }
      })
      .subscribe((transportCopy: Transport) => {
        this.transport = transportCopy;
      });
  }
}
