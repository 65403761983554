import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DropTenant, Logout } from 'app/core/store/actions/session.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';
/**
 * The avatar component a small footer type thingy with a users gravatar.
 *
 * @export
 * @class AvatarComponent
 */
var AvatarComponent = /** @class */ (function () {
    function AvatarComponent(store, router) {
        var _this = this;
        this.store = store;
        this.router = router;
        this._showUsername = false;
        this._size = '';
        this.isAdmin = false;
        this.store.select('tenants')
            .pipe(untilDestroyed(this))
            .subscribe(function (tenants) { return (_this.tenants = tenants.userTenants); });
        this.user$ = this.store.select(function (state) { return state.user; });
        this.user$.subscribe(function (user) {
            if (user) {
                _this.isAdmin =
                    user.roles.filter(function (role) { return role['name'] === 'admin'; }).length !== 0;
            }
        });
    }
    Object.defineProperty(AvatarComponent.prototype, "showName", {
        get: function () {
            return this._showUsername;
        },
        set: function (showUsername) {
            this._showUsername = showUsername || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        set: function (size) {
            this._size = size || '';
        },
        enumerable: true,
        configurable: true
    });
    AvatarComponent.prototype.logout = function () {
        this.store.dispatch(new Logout());
    };
    AvatarComponent.prototype.login = function () {
        this.router.navigate(['/login']);
    };
    AvatarComponent.prototype.changeTenant = function () {
        this.store.dispatch(new DropTenant());
    };
    AvatarComponent.prototype.ngOnDestroy = function () { };
    return AvatarComponent;
}());
export { AvatarComponent };
