import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/visit.types';
import * as VisitsActions from '../actions/visits.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class VisitsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadVisits$: Observable<Action> = this.actions$.pipe(
    ofType(types.visits.LOAD_VISITS),
    map((action: VisitsActions.LoadVisits) => action.payload),
    concatMap(payload => {
      let path = `/visits?`;
      if (payload) {
        if (payload.fromDate) {
          path += `&scheduledAt[after]=${payload.fromDate}`;
        }
        if (payload.toDate) {
          path += `&scheduledAt[before]=${payload.toDate}%2023%3A59%3A59`;
        }
        if (payload.locationId) {
          path += `&transportTasks.location.id=${payload.locationId}`;
        }
        if (payload.driverId) {
          path += `&driver.id=${payload.driverId}`;
        }
        if (payload.page) {
          path += `&page=${payload.page}`;
        }
      }
      return this.api.get({ path }).pipe(
        map(data => new VisitsActions.LoadVisitsSuccess(data)),
        catchError(error => of(new VisitsActions.LoadVisitsFail(error)))
      );
    }),
  );

  @Effect()
  createVisit$: Observable<Action> = this.actions$.pipe(
    ofType(types.visits.CREATE_VISIT),
    map((action: VisitsActions.CreateVisit) => action.payload),
    concatMap(payload => this.api.post({
      path: '/visits', body: payload.body
    }).pipe(
      map(data => new VisitsActions.CreateVisitSuccess(data)),
      catchError(error => of(new VisitsActions.CreateVisitFail(error)))
    )),
  );

  @Effect()
  updateVisit$: Observable<Action> = this.actions$.pipe(
    ofType(types.visits.UPDATE_VISIT),
    map((action: VisitsActions.UpdateVisit) => action.payload),
    concatMap(payload => this.api.put({
      path: `/visits/${payload.visitId}`, body: payload.body
    }).pipe(
      map(data => new VisitsActions.UpdateVisitSuccess(data)),
      catchError(error => of(new VisitsActions.UpdateVisitFail(error)))
    )),
  );

  @Effect()
  updateVisitSequence$: Observable<Action> = this.actions$.pipe(
    ofType(types.visits.UPDATE_VISIT_SEQUENCE),
    map((action: VisitsActions.UpdateVisitSequence) => action.payload),
    concatMap(payload => this.api.post({
      path: '/bulk/visits', body: payload
    }).pipe(
      map(data => new VisitsActions.UpdateVisitSequenceSuccess(data)),
      catchError(error => of(new VisitsActions.UpdateVisitSequenceFail(error)))
    )),
  );

  @Effect()
  transitionVisitForward$: Observable<Action> = this.actions$.pipe(
    ofType(types.visits.TRANSITION_VISIT_FORWARD),
    map((action: VisitsActions.TransitionVisitForward) => action.payload),
    concatMap(payload => this.api.put({
      path: `/visits/${payload.visitId}/transition/forward`
    }).pipe(
      map(data => new VisitsActions.TransitionVisitForwardSuccess(data)),
      catchError(error => of(new VisitsActions.TransitionVisitForwardFail(error)))
    )),
  );

  @Effect()
  transitionVisitBack$: Observable<Action> = this.actions$.pipe(
    ofType(types.visits.TRANSITION_VISIT_BACK),
    map((action: VisitsActions.TransitionVisitBack) => action.payload),
    concatMap(payload => this.api.put({
      path: `/visits/${payload.visitId}/transition/back`
    }).pipe(
      map(data => new VisitsActions.TransitionVisitBackSuccess(data)),
      catchError(error => of(new VisitsActions.TransitionVisitBackFail(error)))
    )),
  );
}
