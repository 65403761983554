<form (ngSubmit)="onSubmit()" [formGroup]="tenantForm" class="pb-3 card-body">
  <div class="form-group">
    <label class="center-block">{{ 'Name' | translate }}</label>
    <input
      class="form-control"
      formControlName="name"
      data-cy="name"
      [ngClass]="{
        'is-invalid':
          tenantForm.controls['name'].invalid &&
          tenantForm.controls['name'].touched
      }"
    />
    <div class="invalid-feedback">{{ 'Name is required.' | translate }}</div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Address' | translate }}</label>
    <input
      class="form-control"
      formControlName="address"
      data-cy="address"
      [ngClass]="{
        'is-invalid':
          tenantForm.controls['address'].invalid &&
          tenantForm.controls['address'].touched
      }"
    />
    <div class="invalid-feedback">{{ 'Address is required.' | translate }}</div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{
          'Street and number' | translate
        }}</label>
        <input
          class="form-control"
          formControlName="streetAndNumber"
          data-cy="streetAndNumber"
          [ngClass]="{
            'is-invalid':
              tenantForm.controls['streetAndNumber'].invalid &&
              tenantForm.controls['streetAndNumber'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Street and number is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Zipcode' | translate }}</label>
        <input
          class="form-control"
          formControlName="zipcode"
          data-cy="zipcode"
          [ngClass]="{
            'is-invalid':
              tenantForm.controls['zipcode'].invalid &&
              tenantForm.controls['zipcode'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Zipcode is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'City' | translate }}</label>
        <input
          class="form-control"
          formControlName="city"
          data-cy="city"
          [ngClass]="{
            'is-invalid':
              tenantForm.controls['city'].invalid &&
              tenantForm.controls['city'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'City is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Phone number' | translate }}</label>
    <input
      class="form-control"
      formControlName="phoneNumber"
      data-cy="phoneNumber"
      [ngClass]="{
        'is-invalid':
          tenantForm.controls['phoneNumber'].invalid &&
          tenantForm.controls['phoneNumber'].touched
      }"
    />
    <div class="invalid-feedback">
      {{ 'Phone number is required.' | translate }}
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Bank account number' | translate }}</label>
    <input
      class="form-control"
      formControlName="bankAccountNumber"
      data-cy="bankAccountNumber"
      [ngClass]="{
        'is-invalid':
          tenantForm.controls['bankAccountNumber'].invalid &&
          tenantForm.controls['bankAccountNumber'].touched
      }"
    />
    <div class="invalid-feedback">
      {{ 'Bank account number is required.' | translate }}
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Email' | translate }}</label>
        <input
          class="form-control"
          formControlName="email"
          data-cy="email"
          [ngClass]="{
            'is-invalid':
              tenantForm.controls['email'].invalid &&
              tenantForm.controls['email'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Email is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Invoice Email' | translate }}</label>
        <input
          class="form-control"
          formControlName="invoiceEmail"
          data-cy="invoiceEmail"
          [ngClass]="{
            'is-invalid':
              tenantForm.controls['invoiceEmail'].invalid &&
              tenantForm.controls['invoiceEmail'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Invoice Email is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block"> {{ 'Country' | translate }}</label>
        <ng-select
          id="country"
          [items]="countries"
          bindLabel="label"
          bindValue="value"
          data-cy="countries"
          placeholder="{{ 'Select country' | translate }}"
          formControlName="country"
        >
        </ng-select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="center-block">
          {{ 'Prefered language' | translate }}</label
        >
        <ng-select
          id="language"
          [items]="languages"
          bindLabel="label"
          bindValue="value"
          data-cy="languages"
          placeholder="{{ 'Select Language' | translate }}"
          formControlName="locale"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'VAT number' | translate }}</label>
    <input
      class="form-control"
      formControlName="vatNumber"
      data-cy="vatNumber"
      [ngClass]="{
        'is-invalid':
          tenantForm.controls['vatNumber'].invalid &&
          tenantForm.controls['vatNumber'].touched
      }"
    />
    <div class="invalid-feedback">
      {{ 'VAT number is required.' | translate }}
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Customer code' | translate }}</label>
    <input
      class="form-control"
      formControlName="customerCode"
      data-cy="customerCode"
      [ngClass]="{
        'is-invalid':
          tenantForm.controls['customerCode'].invalid &&
          tenantForm.controls['customerCode'].touched
      }"
    />
    <div class="invalid-feedback">
      {{ 'Customer code is required.' | translate }}
    </div>
  </div>
  <div class="form-group">
    <label class="center-block">
      {{ 'Allowed subscriptions' | translate }}</label
    >
    <ng-select
      id="allowedSubscriptions"
      [items]="subscriptionTypes"
      bindLabel="name"
      placeholder="Select subscriptions"
      data-cy="allowedSubscriptions"
      formControlName="allowedSubscriptionTypes"
      [multiple]="true"
      [closeOnSelect]="false"
    >
    </ng-select>
  </div>
  <button
    class="btn btn-primary text-white"
    type="submit"
    data-cy="createTenantButton"
    [disabled]="tenantForm.invalid || tenantForm.pristine"
  >
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span> {{ 'Submit' | translate }} </span>
  </button>
</form>
