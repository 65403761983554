import { Alert } from 'app/core/store/models/alert.model';
import { Actions, ofType } from '@ngrx/effects';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Transaction } from 'app/core/store/models/transaction.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/transaction.types';
import { ToastService } from 'app/services/toast.service';
import { LoadAllTransactions, LoadTransactionPage } from 'app/core/store/actions/transactions.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-transactions',
  templateUrl: 'transactions.component.html',
  styleUrls: ['transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {
  alert: Alert;
  transactions: Transaction[];
  nextPage: string;
  loading = true;
  pdf: any;

  constructor(
    private store: Store<State>,
    private transactionsUpdates$: Actions,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadAllTransactions({ page: 1 }));
    this.store
      .select(state => state.allTransactions)
      .pipe(untilDestroyed(this))
      .subscribe(transactions => {
        this.nextPage = transactions['nextPage'];
        if (transactions['data']) {
          this.transactions = transactions['data'];
          this.loading = false;
        }
      });
  }

  ngOnInit() {
    this.transactionsUpdates$
      .pipe(ofType(Types.transactions.LOAD_ALL_TRANSACTIONS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to load transactions'
        });
        this.loading = false;
      });
  }

  ngOnDestroy() { }

  loadMoreTransactions() {
    this.loading = true;
    this.store.dispatch(new LoadTransactionPage({ page: this.nextPage }));
  }

  closeAlert() {
    this.alert = null;
  }
}
