var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as types from '../types/matching.types';
import * as MatchingActions from '../actions/matching.actions';
import { ApiService } from '../../api/api.service';
import { map, concatMap, catchError } from 'rxjs/operators';
var MatchingEffects = /** @class */ (function () {
    function MatchingEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadActiveMatchingPoolItems$ = this.actions$.pipe(ofType(types.matching.LOAD_ACTIVE_MATCHING_POOL_ITEMS), concatMap(function () { return _this.api.get({
            path: "/matching/my_matching_pool_items"
        }).pipe(map(function (data) { return new MatchingActions.LoadActiveMatchingPoolItemsSuccess(data); }), catchError(function (error) { return of(new MatchingActions.LoadActiveMatchingPoolItemsFail(error)); })); }));
        this.loadNextActiveMatchingPoolItemsPage$ = this.actions$.pipe(ofType(types.matching.LOAD_NEXT_ACTIVE_MATCHING_POOL_ITEMS_PAGE), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var page = payload && payload.page ? payload.page : 1;
            return _this.api.get({
                path: "/matching/my_matching_pool_items?page=" + page
            }).pipe(map(function (data) { return new MatchingActions.LoadNextActiveMatchingPoolItemsPageSuccess(data); }), catchError(function (error) { return of(new MatchingActions.LoadNextActiveMatchingPoolItemsPageFail(error)); }));
        }));
        this.enableMatching$ = this.actions$.pipe(ofType(types.matching.ENABLE_MATCHING), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/matching/" + payload.type + "/" + payload.id + "/enabled"
        }).pipe(map(function (data) { return new MatchingActions.EnableMatchinSuccess(data); }), catchError(function (error) { return of(new MatchingActions.EnableMatchinFail(error)); })); }));
        this.disableMatching$ = this.actions$.pipe(ofType(types.matching.DISABLE_MATCHING), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/matching/" + payload.type + "/" + payload.id + "/disabled"
        }).pipe(map(function (data) { return new MatchingActions.DisableMatchinSuccess(data); }), catchError(function (error) { return of(new MatchingActions.DisableMatchinFail(error)); })); }));
        this.createMatchReply$ = this.actions$.pipe(ofType(types.matching.CREATE_MATCH_REPLY), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/matching_pool_item_match_replies",
            body: payload
        }).pipe(map(function (data) { return new MatchingActions.CreateMatchReplySuccess(data); }), catchError(function (error) { return of(new MatchingActions.CreateMatchReplyFail(error)); })); }));
        this.deleteMatchOption$ = this.actions$.pipe(ofType(types.matching.DELETE_MATCHING_POOL_OPTION), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/matching_pool_item_matches/" + payload.matchOptionId
        }).pipe(map(function () { return new MatchingActions.DeleteMatchingPoolOptionSuccess(payload); }), catchError(function (error) { return of(new MatchingActions.DeleteMatchingPoolOptionFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MatchingEffects.prototype, "loadActiveMatchingPoolItems$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MatchingEffects.prototype, "loadNextActiveMatchingPoolItemsPage$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MatchingEffects.prototype, "enableMatching$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MatchingEffects.prototype, "disableMatching$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MatchingEffects.prototype, "createMatchReply$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MatchingEffects.prototype, "deleteMatchOption$", void 0);
    return MatchingEffects;
}());
export { MatchingEffects };
