import { State } from '../store.model';
import * as types from '../types/session.types';
import * as UserTypes from '../types/user.types';
import { Session } from '../models/session.model';
import * as SessionActions from '../actions/session.actions';

const INITIAL_STATE: Session | null = localStorage.getItem('session')
  ? JSON.parse(localStorage.getItem('session'))
  : null;

export function reducer(
  state = INITIAL_STATE,
  action: SessionActions.All
): Session {
  switch (action.type) {
    case types.session.LOGIN:
      return state;
    case types.session.LOGIN_SUCCEEDED:
      const session = action.payload;
      session.expires_at = String(
        Date.now() + Number(session.expires_in) * 1000
      );
      session.access_token_stored_at = String(Date.now());
      return Object.assign({}, state, session);
    case types.session.REFRESH_SUCCEEDED:
      const newSession = action.payload;
      newSession.expires_at = String(
        Date.now() + Number(newSession.expires_in) * 1000
      );
      newSession.access_token_stored_at = String(Date.now());
      newSession.tenant = state.tenant;
      localStorage.setItem('session', JSON.stringify(newSession));
      return Object.assign({}, state, newSession);
    case UserTypes.users.USER_LOGOUT:
      return Object.assign({}, INITIAL_STATE);
    case types.session.PICK_TENANT:
      return { ...state, tenant: action.payload };
    case types.session.DROP_TENANT:
      return Object.assign({}, state, { tenant: undefined });
    case types.session.LOGOUT:
      return null;
  }
  return state;
}

// Some functions for getting straight to the state yii-haw.
// Checkout CreateSelector for advanced shizzles
export const getSession = (state: State) => state.session;
export const getAccessToken = (state: State) => state.session.access_token;
export const getRefreshToken = (state: State) => state.session.refresh_token;
export const getAccessTokenExpiresAt = (state: State) =>
  state.session.expires_at;
export const getActiveTenant = (state: State) => state.session.tenant;
