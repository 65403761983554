import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import {
  DeleteCustomer,
  LoadCustomers,
  LoadFilteredCustomers,
} from 'app/core/store/actions/customer.actions';
import { Customer } from 'app/core/store/models/customer.model';
import { State } from 'app/core/store/store.model';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';
import { extract } from 'app/services/i18n.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnDestroy {
  title = extract('Customers');
  searchPlaceholder = extract('Search name or VAT number');
  customers: Customer[];
  filteredCustomers: Customer[];
  totalElements = 0;
  isLoadingCustomers: boolean;
  query: any;
  baseUrl = 'crm';

  constructor(
    private router: Router,
    private store: Store<State>,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.customers &&
          state.crm.customers.customers
        ) {
          return state.crm.customers.customers;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(customerState => {
        if (!customerState) {
          this.store.dispatch(new LoadCustomers());
          this.isLoadingCustomers = true;
          return;
        }
        this.customers = [...customerState];
        this.isLoadingCustomers = false;
      });

    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.customers &&
          state.crm.customers.filteredCustomers
        ) {
          return state.crm.customers.filteredCustomers;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(filteredCustomers => {
        if (!filteredCustomers) {
          return;
        }
        this.filteredCustomers = [...filteredCustomers];
        this.isLoadingCustomers = false;
      });

    if (this.route.parent && this.route.parent.parent) {
      this.route.parent.parent.url.pipe(untilDestroyed(this)).subscribe(segments => {
        if (segments.join('') === 'crm') {
          this.baseUrl = 'crm';
        } else if (segments.join('') === 'certiweight-backoffice') {
          this.baseUrl = 'certiweight-backoffice';
        }
      });
    }
  }

  handleSearch(query) {
    if (!query) {
      this.store.dispatch(new LoadCustomers());
      return;
    }
    this.isLoadingCustomers = true;
    this.query = query;
    this.store.dispatch(new LoadFilteredCustomers({ query: query }));
  }

  goToEditCustomer(customer) {
    this.router.navigateByUrl(`/${this.baseUrl}/customers/${customer.id}/edit`);
  }
  deleteCustomer(customer: Customer) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this customer?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(new DeleteCustomer({ customerId: customer.id }));
    });
  }

  ngOnDestroy() { }
}
