import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable()
export class AppConfig {

  public config: Object = null;

  constructor(
    private injector: Injector
  ) { }

  /**
   * getConfig getter for config data loaded from config file
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * load config file and assign values to config object
   */
  public load(): Promise<any> {
    const client = this.injector.get(HttpClient);

    return new Promise((resolve) => {
      client
        .get('assets/config.json')
        .pipe(tap((res) => {
          this.config = res;
        }))
        .subscribe(() => {
          resolve(true);
        });
    });
  }
}
