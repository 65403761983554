export const taxRate = {
  LOAD_TAX_RATES: '[Tax Rates] Load',
  LOAD_TAX_RATES_SUCCEEDED: '[Tax Rates] Load Success',
  LOAD_TAX_RATES_FAILED: '[Tax Rates] Load Fail',
  LOAD_FILTERED_TAX_RATES: '[Tax Rates] Load filtered',
  LOAD_FILTERED_TAX_RATES_SUCCEEDED: '[Tax Rates] Load filtered Success',
  LOAD_FILTERED_TAX_RATES_FAILED: '[Tax Rates] Load filtered Fail',
  CREATE_TAX_RATE: '[Tax Rate] Create',
  CREATE_TAX_RATE_SUCCEEDED: '[Tax Rate] Create Success',
  CREATE_TAX_RATE_FAILED: '[Tax Rate] Create Fail',
  DELETE_TAX_RATE: '[Tax Rate] Delete',
  DELETE_TAX_RATE_SUCCEEDED: '[Tax Rate] Delete Success',
  DELETE_TAX_RATE_FAILED: '[Tax Rate] Delete Fail',
  UPDATE_TAX_RATE: '[Tax Rate] Update',
  UPDATE_TAX_RATE_SUCCEEDED: '[Tax Rate] Update Success',
  UPDATE_TAX_RATE_FAILED: '[Tax Rate] Update Fail',
  CLEAR: '[Tax Rates] Clear'
};
