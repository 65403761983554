import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  MatchingPoolItem,
  MatchingTypes
} from 'app/core/store/models/matching.model';

@Component({
  selector: 'app-matching-pool-item',
  templateUrl: './matching-pool-item.component.html',
  styleUrls: ['./matching-pool-item.component.scss']
})
export class MatchingPoolItemComponent {
  MATCHLIMIT = 3;
  @Input()
  matchingPoolItem: MatchingPoolItem;
  @Input()
  isLoading: boolean;
  @Input()
  disabledMatch: string;

  @Output()
  submitReply = new EventEmitter<{
    matchingPoolItemMatch: string;
    text: string;
  }>();
  @Output()
  removeMatchItem = new EventEmitter();
  @Output()
  removeMatchOption = new EventEmitter<{
    matchItemId: string;
    matchOptionId: string;
  }>();

  id: string;

  handleSubmitReply(reply: { matchingPoolItemMatch: string; text: string }) {
    this.submitReply.emit(reply);
  }
  handleRemoveMatchItem() {
    let type = '';
    let id = '';
    if (this.matchingPoolItem.marketPostOffer) {
      type = MatchingTypes.MARKET_POST_OFFER;
      id = this.matchingPoolItem.marketPostOffer['id'];
    } else if (this.matchingPoolItem.marketPostDemand) {
      type = MatchingTypes.MARKET_POST_DEMAND;
      id = this.matchingPoolItem.marketPostDemand.id;
    } else {
      type = MatchingTypes.TRANSPORT;
      id = this.matchingPoolItem.transport.id;
    }
    this.id = id;
    this.disabledMatch = id;
    this.removeMatchItem.emit({
      matchItemId: id,
      matchType: type
    });
  }
  handleRemoveMatchOption(matchOptionId: string) {
    this.removeMatchOption.emit({
      matchItemId: this.matchingPoolItem.id,
      matchOptionId: matchOptionId
    });
  }
}
