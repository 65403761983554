<div class="m-3">
  <div class="card">
    <div class="card-header">
      <h3>{{ 'Edit container measurement' | translate }}</h3>
    </div>
    <div class="card-body">
      <app-container-measurement-form
        [data]="data" [isLoading]="isLoading"
        (formSubmitted)="onSubmit($event)"
      ></app-container-measurement-form>
    </div>
    <div class="col-8">
      <ngx-datatable
        class="bootstrap ml-0 mr-0 table table-striped table-condensed"
        [rows]="[data]"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [selectionType]="undefined">
        <ngx-datatable-column name="PrePaid" prop="site.prePaid">
          <ng-template ngx-datatable-cell-template let-value="value">
            <span class="fa-fw far fa-check-circle text-success" *ngIf="value"></span>
            <span class="fa-fw far fa-times-circle text-danger" *ngIf="!value"></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Avantida" prop="">
          <ng-template ngx-datatable-cell-template let-status="row.avantidaStatus" let-value="value">
            <span class="text-success" *ngIf="status === 'Accepted' ? 'text-success' : ''">{{ status }}</span>
            <span class="text-danger" *ngIf="status !== 'Sent' && status !== 'Accepted'">{{ status }}</span>
            <span *ngIf="status === 'Sent'">{{ status }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Send to Avantida" prop="sendToAvantida"></ngx-datatable-column>
        <ngx-datatable-column name="Inttra" prop="inttraStatus">
          <ng-template ngx-datatable-cell-template let-status="row.inttraStatus" let-value="value">
            <span class="text-success" *ngIf="status === 'Accepted' ? 'text-success' : ''">{{ status }}</span>
            <span class="text-danger" *ngIf="status !== 'Sent' && status !== 'Accepted'">{{ status }}</span>
            <span *ngIf="status === 'Sent'">{{ status }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Send To Inttra" prop="sendToInttra"></ngx-datatable-column>
      </ngx-datatable>

      <ul class="text-danger">
        <li *ngFor="let error of errors.liner">{{ error }}</li>
        <li *ngFor="let error of errors.site" >{{ error }}</li>
        <li *ngFor="let error of errors.terminal">{{ error }}</li>
      </ul>

    </div>
  </div>
</div>
