var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ProductTypes } from '../models/product.model';
import * as types from '../types/product.types';
var INITIAL_STATE = {
    customerProducts: null,
    filteredCustomerProducts: null,
    supplierProducts: null,
    filteredSupplierProducts: null
};
export function ProductsReducer(state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case types.product.LOAD_PRODUCTS_SUCCEEDED:
            var products = action.payload['hydra:member'];
            return Object.assign({}, {
                customerProducts: filterProductsByType(products, ProductTypes.CUSTOMER).slice(),
                filteredCustomerProducts: filterProductsByType(products, ProductTypes.CUSTOMER).slice(),
                supplierProducts: filterProductsByType(products, ProductTypes.SUPPLIER).slice(),
                filteredSupplierProducts: filterProductsByType(products, ProductTypes.SUPPLIER).slice()
            });
        case types.product.LOAD_FILTERED_PRODUCTS_SUCCEEDED:
            var filteredProducts = action.payload['hydra:member'];
            return Object.assign({}, state, {
                filteredCustomerProducts: filterProductsByType(filteredProducts, ProductTypes.CUSTOMER).slice(),
                filteredSupplierProducts: filterProductsByType(filteredProducts, ProductTypes.SUPPLIER).slice()
            });
        case types.product.UPDATE_PRODUCT_SUCCEEDED:
            var updatedProduct = action.payload;
            if (updatedProduct.type === ProductTypes.CUSTOMER) {
                var customerProducts = state.customerProducts.slice();
                var filteredCustomerProducts = state.filteredCustomerProducts.slice();
                var productIndex = customerProducts.findIndex(function (i) { return i.id === action.payload.id; });
                var tempProduct = Object.assign({}, state.customerProducts[productIndex], action.payload);
                customerProducts[productIndex] = tempProduct;
                var filteredProductIndex = filteredCustomerProducts.findIndex(function (i) { return i.id === action.payload.id; });
                var tempFilteredProduct = Object.assign({}, filteredCustomerProducts[filteredProductIndex], action.payload);
                filteredCustomerProducts[filteredProductIndex] = tempFilteredProduct;
                return Object.assign({}, state, {
                    customerProducts: customerProducts,
                    filteredCustomerProducts: filteredCustomerProducts
                });
            }
            if (updatedProduct.type === ProductTypes.SUPPLIER) {
                var supplierProducts = state.supplierProducts.slice();
                var filteredSupplierProducts = state.filteredSupplierProducts.slice();
                var productIndex = supplierProducts.findIndex(function (i) { return i.id === action.payload.id; });
                var tempProduct = Object.assign({}, state.supplierProducts[productIndex], action.payload);
                supplierProducts[productIndex] = tempProduct;
                var filteredProductIndex = filteredSupplierProducts.findIndex(function (i) { return i.id === action.payload.id; });
                var tempFilteredProduct = Object.assign({}, filteredSupplierProducts[filteredProductIndex], action.payload);
                filteredSupplierProducts[filteredProductIndex] = tempFilteredProduct;
                return Object.assign({}, state, {
                    supplierProducts: supplierProducts,
                    filteredSupplierProducts: filteredSupplierProducts
                });
            }
            break;
        case types.product.DELETE_PRODUCT_SUCCEEDED:
            return Object.assign({}, {
                customerProducts: state.customerProducts.filter(function (product) { return product.id !== action.payload; }).slice(),
                filteredCustomerProducts: state.filteredCustomerProducts.filter(function (product) { return product.id !== action.payload; }).slice(),
                supplierProducts: state.supplierProducts.filter(function (product) { return product.id !== action.payload; }).slice(),
                filteredSupplierProducts: state.filteredSupplierProducts.filter(function (product) { return product.id !== action.payload; }).slice()
            });
        case types.product.CLEAR:
            Object.keys(state).forEach(function (key) { return (state[key] = null); });
            return state;
    }
    return state;
}
function filterProductsByType(products, type) {
    if (!products) {
        return [];
    }
    return products.filter(function (product) { return product.type === type; }).map(function (product) { return (__assign({}, product, { parts: product.parts.sort(function (a, b) { return (a.id > b.id) - (b.id > a.id); }) })); });
}
