import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { AlertComponent } from './alert/alert.component';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from '../store/effects/notifications.effects';

@NgModule({
  declarations: [ NotificationsComponent, AlertComponent ],
  exports: [ NotificationsComponent, AlertComponent ],
  imports: [ CommonModule, SharedModule, EffectsModule, EffectsModule.forFeature([NotificationEffects]) ],
})

export class NotificationsModule { }
