import { TransportTask } from '../core/store/models/transport-task.model';
import { Injectable } from '@angular/core';
import { TransportType } from '../core/store/models/transport-types.model';

@Injectable()
export class IconService {
  constructor() { }

  getTransportTypeIcon(type: TransportType) {
    if (!type) {
      return;
    }
    switch (type.label) {
      case 'load':
        return 'fa-arrow-to-right';
      case 'unload':
        return 'fa-arrow-from-right';
      case 'shunt':
        return 'fa-route';
      case 'empty (hub)':
        return 'fa-warehouse-alt';
      case 'empty en route':
        return 'fa-road';
      default:
        break;
    }
  }
  getTransportTaskIcon(task: TransportTask) {
    if (!task) {
      return;
    }
    switch (task.taskType.label) {
      case 'pickup':
        return 'fa-arrow-from-bottom';
      case 'load':
        return 'fa-arrow-to-right';
      case 'unload':
        return 'fa-arrow-from-right';
      case 'dropoff':
        return 'fa-arrow-to-bottom';
      case 'from':
        return 'fa-map-marker-alt';
      case 'to':
        return 'fa-map-marker';
      default:
        break;
    }
  }
  getIconsForSubscriptionTypes(): string[] {
    return ['fa-money-bill-alt', 'fa-handshake'];
  }
}
