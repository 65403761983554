import { ProductEffects } from '../core/store/effects/product.effects';
import { CustomerEffects } from '../core/store/effects/customer.effects';
import { TmsReducers } from '../core/store/reducers/tms.reducers';
import { VisitsEffects } from '../core/store/effects/visits.effects';
import { CountryEffects } from '../core/store/effects/country.effects';
import { LocationEffects } from '../core/store/effects/location.effects';
import { EffectsModule } from '@ngrx/effects';
import { NewDriverComponent } from './drivers/new-driver/new-driver.component';
import { DriverListComponent } from './drivers/driver-list/driver-list.component';
import { EditDriverComponent } from './drivers/edit-driver/edit-driver.component';
import { DriverFormComponent } from './drivers/driver-form/driver-form.component';
import { SharedModule } from '../shared/shared.module';
import { TmsRoutingModule } from './tms-routing.module';
import { NgModule } from '@angular/core';

import { TmsComponent } from './tms.component';
import { TransportFormComponent } from './transports/transport-form/transport-form.component';
import { TransportListComponent } from './transports/transport-list/transport-list.component';
import { TransportDownloadModalComponent } from './transports/transport-download/transport-download-modal.component';
import { NewTransportComponent } from './transports/new-transport/new-transport.component';
import { EditTransportComponent } from './transports/edit-transport/edit-transport.component';
import { VisitsComponent } from './visits/visits.component';
import { NewVisitComponent } from './visits/new-visit/new-visit.component';
import { StoreModule } from '@ngrx/store';
import { DriverVisitsComponent } from './driver-visits/driver-visits.component';
import { DriverVisitComponent } from './driver-visit/driver-visit.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { EditVisitComponent } from './visits/edit-visit/edit-visit.component';
import { VisitFormComponent } from './visits/visit-form/visit-form.component';
import { NewDriverModalComponent } from './drivers/new-driver-modal/new-driver-modal.component';
import { EditDriverModalComponent } from './drivers/edit-driver-modal/edit-driver-modal.component';
import { VisitModalComponent } from './visits/visit-modal/visit-modal.component';
import { UserLocationFormComponent } from './locations/user-location-form/user-location-form.component';
import { NewUserLocationComponent } from './locations/new-user-location/new-user-location.component';
import { ManageUserLocationsComponent } from './locations/manage-user-locations/manage-user-locations.component';
import { EditUserLocationComponent } from './locations/edit-user-location/edit-user-location.component';
import { NewUserLocationModalComponent } from './locations/new-user-location-modal/new-user-location-modal.component';
import { EditUserLocationModalComponent } from './locations/edit-user-location-modal/edit-user-location-modal.component';
import { NewVisitModalComponent } from './visits/new-visit-modal/new-visit-modal.component';
import { EditVisitModalComponent } from './visits/edit-visit-modal/edit-visit-modal.component';
import { DragulaModule } from 'ng2-dragula';
import { ManageDriverScheduleComponent } from './manage-driver-schedule/manage-driver-schedule.component';
import { ConfirmPublishTransportComponent } from './transports/confirm-publish-transport/confirm-publish-transport.component';
import { ConfirmDeleteTransportComponent } from './transports/confirm-delete-transport/confirm-delete-transport.component';
import { PlanningComponent } from './planning/planning.component';
import { PlanningDayComponent } from './planning-day/planning-day.component';
import { ScheduleEffects } from '../core/store/effects/schedule.effects';
import { UnplannedTransportsComponent } from './unplanned-transports/unplanned-transports.component';
import { DriverTaskEffects } from '../core/store/effects/driver-task.effects';
import { VisitBarCodeComponent } from './visit-bar-code/visit-bar-code.component';
import { DriverTaskComponent } from './driver-task/driver-task.component';
import { TariffFormComponent } from './tariff-form/tariff-form.component';
import { NewTariffComponent } from './new-tariff/new-tariff.component';
import { NewTariffModalComponent } from './new-tariff-modal/new-tariff-modal.component';
import { EditTariffModalComponent } from './edit-tariff-modal/edit-tariff-modal.component';
import { TariffTableComponent } from './tariff-table/tariff-table.component';
import { NewCustomerModalComponent } from './new-customer-modal/new-customer-modal.component';
import { EditCustomerModalComponent } from './edit-customer-modal/edit-customer-modal.component';
import { TariffsComponent } from './tariffs/tariffs.component';
import { EditTariffComponent } from './edit-tariff/edit-tariff.component';
import { TransportTaskEffects } from '../core/store/effects/transport-task.effects';
import { TariffsTableComponent } from './tariffs-table/tariffs-table.component';
import { NewSupplierModalComponent } from './new-supplier-modal/new-supplier-modal.component';
import { EditSupplierModalComponent } from './edit-supplier-modal/edit-supplier-modal.component';
import { ContainerFormComponent } from './container-form/container-form.component';
import { CopyTransportComponent } from './copy-transport/copy-transport.component';
import { PublishDemandComponent } from './publish-demand/publish-demand.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { AttachmentComponent } from './attachment/attachment.component';
import { AttachmentEffects } from '../core/store/effects/attachment.effects';

@NgModule({
  imports: [
    SharedModule,
    NgxBarcode6Module,
    TmsRoutingModule,
    DragulaModule,
    FileUploadModule,
    StoreModule.forFeature('tms', TmsReducers),
    EffectsModule.forFeature([
      VisitsEffects,
      LocationEffects,
      ScheduleEffects,
      DriverTaskEffects,
      TransportTaskEffects,
      CountryEffects,
      CustomerEffects,
      ProductEffects,
      AttachmentEffects
    ])
  ],
  exports: [],
  declarations: [
    TmsComponent,
    DriverListComponent,
    NewDriverComponent,
    DriverFormComponent,
    EditDriverComponent,
    TransportFormComponent,
    TransportListComponent,
    NewTransportComponent,
    EditTransportComponent,
    VisitsComponent,
    NewVisitComponent,
    DriverVisitsComponent,
    DriverVisitComponent,
    EditVisitComponent,
    VisitFormComponent,
    NewDriverModalComponent,
    EditDriverModalComponent,
    VisitModalComponent,
    UserLocationFormComponent,
    NewUserLocationComponent,
    ManageUserLocationsComponent,
    EditUserLocationComponent,
    NewUserLocationModalComponent,
    EditUserLocationModalComponent,
    NewVisitModalComponent,
    EditVisitModalComponent,
    ManageDriverScheduleComponent,
    ConfirmPublishTransportComponent,
    ConfirmDeleteTransportComponent,
    PlanningComponent,
    PlanningDayComponent,
    UnplannedTransportsComponent,
    VisitBarCodeComponent,
    DriverTaskComponent,
    TariffFormComponent,
    NewTariffComponent,
    NewTariffModalComponent,
    EditTariffModalComponent,
    TariffTableComponent,
    NewCustomerModalComponent,
    EditCustomerModalComponent,
    TariffsComponent,
    EditTariffComponent,
    TariffsTableComponent,
    NewSupplierModalComponent,
    EditSupplierModalComponent,
    ContainerFormComponent,
    CopyTransportComponent,
    PublishDemandComponent,
    FileUploaderComponent,
    AttachmentComponent,
    TransportDownloadModalComponent
  ],
  entryComponents: [
    ConfirmPublishTransportComponent,
    PublishDemandComponent,
    ConfirmDeleteTransportComponent,
    NewVisitModalComponent,
    EditVisitModalComponent,
    NewDriverModalComponent,
    EditDriverModalComponent,
    NewUserLocationModalComponent,
    VisitBarCodeComponent,
    EditUserLocationModalComponent,
    NewTariffModalComponent,
    EditTariffModalComponent,
    NewCustomerModalComponent,
    EditCustomerModalComponent,
    NewSupplierModalComponent,
    EditSupplierModalComponent,
    TransportDownloadModalComponent
  ],
  providers: []
})
export class TmsModule { }
