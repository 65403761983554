<div class="card mb-3">
  <div class="card-body">
    <h2 class='text-center font-weight-light'>{{ '1. Choose a type' | translate }}</h2>
    <!-- Post type buttons -->
    <div class="btn-group btn-group-toggle d-flex justify-content-center"
      ngbRadioGroup
      name="postType"
      (ngModelChange)="postTypeChange($event)"
      [(ngModel)]="postType"
      [disabled]="transportOffers > 0 || transportDemands > 0">
      <label ngbButtonLabel
        data-cy="offerButton"
        class="btn-outline-primary">
        <input ngbButton
          type="radio"
          [value]="'offer'">
        <span class="icon">
          <i class="far fa-hand-holding"></i>
        </span>
        <span>{{ 'Offer' | translate }}</span>
      </label>
      <label ngbButtonLabel
        class="btn-outline-primary"
        data-cy="demandButton">
        <input ngbButton
          type="radio"
          [value]="'demand'">
        <span class="icon">
          <i class="far fa-question-circle"></i>
        </span>
        {{ 'Demand' | translate }}
      </label>
    </div>
    <p class="text-center text-muted"
      *ngIf="transportOffers > 0 || transportDemands > 0">{{ '(You can only add one type per post.)' | translate}}</p>
  </div>
</div>
