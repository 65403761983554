import { PaymentNote } from 'app/core/store/models/payment-note.model';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import * as DateFns from 'date-fns';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../../shared/datepicker-config';
import { Business } from 'app/core/store/models/business.model';

@Component({
  selector: 'app-credit-note-form',
  templateUrl: './credit-note-form.component.html',
  styleUrls: ['./credit-note-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class CreditNoteFormComponent implements OnChanges {
  @Input()
  customers: Business[];
  @Input()
  isLoadingCustomers: boolean;
  @Input()
  isLoading: boolean;
  @Input()
  taxRates: any[];
  @Input()
  isLoadingTaxRates: boolean;

  @Output()
  creditNoteFormSubmitted = new EventEmitter();
  @Output()
  onSaveAndDownloadAsPDF = new EventEmitter();
  @Output()
  onSaveAndPreview = new EventEmitter();

  creditNoteForm: FormGroup;
  taxRateExpr = new RegExp(/[0-9]{1,3}/);
  invoiceId: string;
  creditNoteId: string;
  addTagText = 'Add tax rate';
  invoice: PaymentNote;
  creditNote: PaymentNote;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private fb: FormBuilder,
  ) {
    // Get invoice id from query params
    this.route.queryParamMap.subscribe(data => {
      if (data) {
        this.invoiceId = data.get('id');
        if (!this.invoiceId) {
          return;
        }
        this.api
          .get({
            path: `/crm/invoices/${this.invoiceId}`
          })
          .subscribe((response: PaymentNote) => {
            response.items.forEach(item => delete item.id);
            this.invoice = response;
            this.updateFormIfReady();
          });
      }
    });
    // Get credit note id from params
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.creditNoteId = data.get('id');
        if (!this.creditNoteId) {
          return;
        }
        this.api
          .get({
            path: `/crm/credit_notes/${this.creditNoteId}`
          })
          .subscribe((response: PaymentNote) => {
            this.creditNote = response;
            this.updateFormIfReady();
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.customers) {
      this.updateFormIfReady();
    }
    if (changes && changes.taxRates) {
      this.updateFormIfReady();
    }
  }

  updateFormIfReady(): void {
    if (this.isComponentReady()) {
      this.createForm();
      this.patchForm();
    }
  }

  isComponentReady(): boolean {
    return (
      this.customers &&
      (this.invoice || this.creditNote) &&
      this.taxRates &&
      !this.creditNoteForm
    );
  }

  createForm() {
    this.creditNoteForm = this.fb.group({
      business: [null],
      date: [new Date()],
      notes: [null],
      items: this.fb.array([this.createCreditNoteItem()])
    });
  }

  get customerAddress() {
    if (
      !this.creditNoteForm ||
      !this.creditNoteForm.value ||
      !this.creditNoteForm.get('business').value
    ) {
      return;
    }
    return this.creditNoteForm.get('business').value['billingAddress'];
  }
  get customerMainContact() {
    if (
      !this.creditNoteForm ||
      !this.creditNoteForm.value ||
      !this.creditNoteForm.get('business').value
    ) {
      return;
    }
    return this.creditNoteForm.get('business').value['defaultContact'];
  }

  createCreditNoteItem(): FormGroup {
    const group = this.fb.group({
      id: null,
      description: [''],
      quantity: [1],
      unitPrice: [null],
      taxRate: [this.taxRates[0]],
      total: [null]
    });
    return group;
  }

  addCreditNoteItem(): void {
    const creditNoteItems = <FormArray>this.creditNoteForm.controls['items'];
    creditNoteItems.push(this.createCreditNoteItem());
  }
  removeCreditNoteItem(index): void {
    const creditNoteItems = <FormArray>this.creditNoteForm.controls['items'];
    creditNoteItems.removeAt(index);
  }

  createNewTaxRate = rate => {
    if (!this.taxRateExpr.test(rate)) {
      return null;
    }
    // TODO: add api call to create the new tag
    return rate;
  }

  /**
   * Duplicates the given creditNote item
   * @param index index of the creditNote item
   */
  duplicateItem(index) {
    this.addCreditNoteItem();
    const creditNoteItems = <FormArray>this.creditNoteForm.controls['items'];
    const item = Object.assign({}, creditNoteItems.at(index).value);
    creditNoteItems.at(creditNoteItems.value.length - 1).patchValue(item);
  }

  /**
   * Updates the total of the given creditNote item based on the quantity, unit pirce
   * and tax rate
   * @param index index of the creditNote item
   */
  updateTotal(index) {
    const creditNoteItems = <FormArray>this.creditNoteForm.controls['items'];
    const item = creditNoteItems.value[index];
    if (
      !item.quantity ||
      !item.unitPrice ||
      !item.taxRate ||
      !item.taxRate.rate
    ) {
      return;
    }
    item.total =
      +item.quantity * +item.unitPrice +
      ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate;
    creditNoteItems.at(index).setValue(item);
  }

  get total() {
    const creditNoteItems = this.creditNoteForm.controls['items'].value;
    return creditNoteItems
      .map(item => +item.total)
      .reduce((total1, total2) => total1 + total2);
  }
  get subTotal() {
    const creditNoteItems = this.creditNoteForm.controls['items'].value;
    return creditNoteItems
      .map(item => +item.quantity * +item.unitPrice)
      .reduce((total1, total2) => total1 + total2);
  }
  get taxes() {
    const creditNoteItems = this.creditNoteForm.controls['items'].value;
    if (creditNoteItems.some(item => !item.taxRate.rate)) {
      return;
    }
    return creditNoteItems
      .map(
        item => ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate
      )
      .reduce((total1, total2) => total1 + total2);
  }

  patchForm() {
    const paymentyNote = this.invoice
      ? this.invoice
      : this.creditNote
        ? this.creditNote
        : null;
    this.creditNoteForm.patchValue({
      business: paymentyNote.business,
      notes: paymentyNote.notes
    });
    paymentyNote.items.forEach(item => {
      const creditNoteItems = <FormArray>this.creditNoteForm.controls['items'];
      creditNoteItems.at(creditNoteItems.value.length - 1).patchValue({
        id: item.id,
        description: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        taxRate: item.taxRate,
        total: item['totalInclTaxes']
      });
      this.addCreditNoteItem();
    });
  }
  /**
   * Emits the creditNote form value
   */
  onSubmit() {
    if (this.creditNoteForm.invalid) {
      return;
    }
    const creditNoteFormValue = this.parseCreditFormValue();
    this.creditNoteFormSubmitted.emit(creditNoteFormValue);
  }

  parseCreditFormValue() {
    const creditNoteFormValue = JSON.parse(
      JSON.stringify(this.creditNoteForm.value)
    );

    creditNoteFormValue.items = creditNoteFormValue.items.filter(
      item => item.unitPrice || item.description || item.transport
    );
    creditNoteFormValue.Date = DateFns.setHours(creditNoteFormValue.date, 12);
    creditNoteFormValue.business = creditNoteFormValue.business['@id'];
    creditNoteFormValue.items.forEach(item => {
      item.taxRate = {
        id: item.taxRate['id']
      };
    });
    return creditNoteFormValue;
  }
  saveAndDownloadAsPDF() {
    if (this.creditNoteForm.invalid) {
      return;
    }
    const creditNoteFormValue = this.parseCreditFormValue();
    this.onSaveAndDownloadAsPDF.emit(creditNoteFormValue);
  }
  saveAndPreview() {
    if (this.creditNoteForm.invalid) {
      return;
    }
    const creditNoteFormValue = this.parseCreditFormValue();
    this.onSaveAndPreview.emit(creditNoteFormValue);
  }
}
