<div class="ml-3">
  <form
    *ngIf="userInfoForm"
    (ngSubmit)="onSubmit()"
    class="card-body"
    [formGroup]="userInfoForm"
  >
    <div class="form-group">
      <label>{{ 'First Name' | translate }}</label>
      <input
        data-cy="first-name"
        type="text"
        class="form-control"
        formControlName="firstName"
      />
    </div>
    <div class="form-group">
      <label>{{ 'Last Name' | translate }}</label>
      <input
        data-cy="last-name"
        type="text"
        class="form-control"
        formControlName="lastName"
      />
    </div>
    <div class="form-group">
      <label>{{ 'Email' | translate }}</label>
      <input
        data-cy="email"
        type="text"
        class="form-control"
        formControlName="email"
      />
    </div>
    <div class="form-group">
      <label>{{ 'Phone Number' | translate }}</label>
      <input
        data-cy="phone-number"
        type="text"
        class="form-control"
        formControlName="phoneNumber"
      />
    </div>
    <div class="form-group">
      <label class="center-block"> {{ 'Prefered language' | translate }}</label>
      <ng-select
        id="language"
        data-cy="language"
        [items]="languages"
        bindLabel="label"
        bindValue="value"
        placeholder="Select Language"
        formControlName="locale"
      >
      </ng-select>
    </div>
    <app-button [isLoading]="isLoading" [type]="'submit'">
      <span> {{ 'Submit' | translate }} </span>
    </app-button>
  </form>
</div>
