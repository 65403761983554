import { ToastService } from 'app/services/toast.service';
import { UpdateAddress } from 'app/core/store/actions/address.actions';
import { Address } from 'app/core/store/models/address.model';
import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/address.types';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-address-modal',
  templateUrl: './edit-address-modal.component.html',
})
export class EditAddressModalComponent implements OnDestroy {
  @Input()
  address: Address;

  countries: any[];
  isLoading: boolean;

  constructor(
    private store: Store<State>,
    private modal: NgbActiveModal,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countriesState => {
        if (!countriesState) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countriesState];
      });

    this.updates$
      .pipe(ofType(Types.address.UPDATE_ADDRESS_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.isLoading = false;
        this.modal.close(response['payload']);
      });
    this.updates$
      .pipe(ofType(Types.address.UPDATE_ADDRESS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.isLoading = false;
        this.toastr.showDanger({
          message: extract('Failed to update address')
        });
      });
  }

  updateAddress(addressFormValue) {
    this.store.dispatch(
      new UpdateAddress({
        addressId: this.address.id,
        body: addressFormValue
      })
    );
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
