<div class="m-3 card">
  <div class="card-title card-title-bb">
    <strong>{{ 'Create a new PSA account' | translate }}</strong>
  </div>
  <div class="card-body">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="form-group">
        <label for="id">{{ 'Company ID' | translate }}</label>
        <input class="form-control"
          formControlName="id" data-cy="id" />
      </div>
      <div class="form-group">
        <label for="name">{{ 'Company name' | translate }}</label>
        <input class="form-control"
        formControlName="name" data-cy="name" />
      </div>
      <div class="form-group">
        <label for="vatNumber">{{ 'Company VAT' | translate }}</label>
        <input class="form-control"
          formControlName="vatNumber" data-cy="vatNumber" />
      </div>
      <div class="form-group">
        <label for="creditsThreshold">{{ 'Credits threshold' | translate }}</label>
        <input class="form-control" type="number"
          formControlName="creditsThreshold" data-cy="creditsThreshold" />
      </div>
      <app-button
        [type]="'button'"
        [btnClass]="'btn-primary text-white'"
        (buttonClicked)="onSubmit()"
        [isLoading]="loading"
      >
        {{ 'Create' | translate }}
      </app-button>
    </form>
  </div>
</div>
