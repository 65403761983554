import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiService } from 'app/core/api/api.service';
import { Contact } from 'app/core/contacts/contact.model';
import { State } from 'app/core/store/store.model';
import { LoadContacts } from 'app/core/store/actions/contacts.actions';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { LoadLocationsByType } from 'app/core/store/actions/location.actions';
import { LoadWallet } from 'app/core/store/actions/wallet.actions';
import { DistanceBasedPriceItem, Liner, LinerReloadLocation, PriceTypes } from 'app/core/store/models/liner.model';
import { Location } from 'app/core/store/models/location.model';
import { ReloadRequest, ReloadRequestStatuses } from 'app/core/store/models/reload-request.model';
import * as LocationTypes from 'app/core/store/types/location.types';
import { HakkaMap } from 'app/marketplace/market-posts/market-post-map/map/hakka-map';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { ValidationService } from 'app/services/validation.service';
import {
  NgbDateNativeAdapter,
  NgbDateCustomParserFormatter
} from 'app/shared/datepicker-config';
import { untilDestroyed } from 'app/shared/rxjs-util';
import * as DateFns from 'date-fns';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-cmp-reload-form',
  templateUrl: 'reload-form.component.html',
  styleUrls: ['./reload-form.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class ReloadFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loading: boolean;
  @Input() reset: boolean = false;
  @Input() readonly: boolean = false;
  @Input() reloadRequest?: ReloadRequest = null;
  @Input() forLiner: boolean = false;

  @Output() reloadFormSubmitted = new EventEmitter<ReloadRequest>();
  @Output() forceApproveRequest = new EventEmitter();
  @Output() cancelRequest = new EventEmitter();

  importLocations: {label: string, id: string}[];
  exportLocations: {label: string, id: string}[];
  turnInLocations: Location[];
  requestCost: number = 0;
  reloadRequestForm: FormGroup;
  selectedLinerId?: string = null;
  originalFormVersion: boolean = true;
  liners: Liner[];
  tenants: Contact[];
  countries: { value: string; label: string }[];
  credits: number = 0;
  distanceBasedPrice: boolean = false;
  calculatingRequestDistance: boolean = false;
  requestDistance?: {
    unload_turn_in: number,
    turn_in_load: number,
    load_unload: number,
    unloadLocation: string,
    turnInLocation: string,
    loadLocation: string,
    total: number
  } = null;
  requestCostCalculated: boolean = true;
  requestStatuses: { value: string; label: string }[];
  hakkaMap: HakkaMap;
  markers = [];
  loadingLoadLocations: boolean = false;
  loadingUnloadLocations: boolean = false;

  constructor(private store: Store<State>,
    private fb: FormBuilder,
    private toastr: ToastService,
    private validationService: ValidationService,
    private locationService: LocationService,
    private api: ApiService,
    private modalService: NgbModal,
    private updates$: Actions
  ) {
    this.requestStatuses = ReloadRequestStatuses;
    this.store.dispatch(new LoadLiners());
    this.store.dispatch(new LoadLocationsByType({locationType: 'DEPOT'}));
    this.store.dispatch(new LoadContacts());
    this.store
      .select(state => {
        if (state.marketplace && state.marketplace.liners) {
          return state.marketplace.liners;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (liners) {
          this.liners = liners.filter(
            liner => liner.reload
          );
          if (this.reloadRequest) {
            this.handleTriangulationChange(this.reloadRequest.liner);
          }
        }
      });
    this.store
      .select(state => state.contacts)
      .pipe(untilDestroyed(this))
      .subscribe(addressBookEntries => {
        this.tenants = addressBookEntries;
      });
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countries => {
        if (!countries) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countries];
      });
    this.store.dispatch(new LoadWallet());
    this.store
      .select(state => {
        if (state && state.wallet) {
          return state.wallet.credits;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(credits => {
        if (credits !== undefined) {
          this.credits = credits;
        }
      });
    this.updates$
      .pipe(ofType(LocationTypes.location.LOAD_LOCATIONS_BY_LOCATION_TYPE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.turnInLocations = res.payload['hydra:member'];
        if (this.reloadRequest) {
          this.handleTriangulationChange(this.reloadRequest.liner);
        }
      });
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reset && changes.reset.currentValue) {
      this.createForm(true);
      this.reset = false;
    }
    if (changes.reloadRequest && changes.reloadRequest.currentValue && this.reloadRequest) {
      this.reloadRequestForm.patchValue({
        liner: this.reloadRequest.liner['id'],
        containerNr: this.reloadRequest.containerNr,
        exportReference: this.reloadRequest.exportReference,
        reloadDate: this.reloadRequest.reloadDate
          ? new Date(
            DateFns.getYear(this.reloadRequest.reloadDate),
            DateFns.getMonth(this.reloadRequest.reloadDate),
            DateFns.getDate(this.reloadRequest.reloadDate)
          )
          : null,
        loadDate: this.reloadRequest.loadDate,
        unloadDate: this.reloadRequest.unloadDate,
        remark: this.reloadRequest.remark,
      });
      if (this.reloadRequest.holderOfContainer) {
        this.reloadRequestForm.patchValue({
          holderOfContainer: this.reloadRequest.holderOfContainer['id']
        });
      }
      this.handleLinerChange(this.reloadRequest.liner);
      this.reloadRequestForm.patchValue({
        importLocation: this.reloadRequest.importLocation,
        exportLocation: this.reloadRequest.exportLocation
      });
      this.handleLocationChange(this.reloadRequest.liner);
      this.reloadRequestForm.patchValue({
        unloadLocation: this.reloadRequest.unloadLocation,
        turnInLocation: this.reloadRequest.turnInLocation ? this.reloadRequest.turnInLocation['id'] : null,
        loadLocation: this.reloadRequest.loadLocation
      });
      this.handleTriangulationChange(this.reloadRequest.liner);
    }
    if (changes.readonly) {
      if (changes.readonly.currentValue) {
        this.disableForm();
      } else {
        this.enableForm();
      }
    }
  }

  createForm(force: boolean = false) {
    if (this.reloadRequestForm && !force) {
      return;
    }
    this.reloadRequestForm = this.fb.group({
      liner: [null, Validators.required],
      importLocation: [null, Validators.required],
      exportLocation: [null, Validators.required],
      unloadLocation: [null],
      turnInLocation: [null],
      loadLocation: [null],
      containerNr: [null, Validators.required],
      holderOfContainer: [null],
      exportReference: [null, Validators.required],
      loadDate: [null, Validators.required],
      unloadDate: [null, Validators.required],
      reloadDate: [null],
      accept: [null],
      remark: [null]
    });
  }

  onSubmit() {
    const formValueCopy = JSON.parse(JSON.stringify(this.reloadRequestForm.value));
    if (formValueCopy.liner) {
      formValueCopy.liner = this.liners.find(
        liner => liner.id === formValueCopy.liner
      )['@id'];
    }

    if (formValueCopy.holderOfContainer) {
      formValueCopy.holerOfContainer = this.tenants.find(
        tenant => tenant['id'] === formValueCopy.holderOfContainer
      )['@id'];
    } else {
      delete formValueCopy.holderOfContainer;
    }

    if (!this.distanceBasedPrice) {
      formValueCopy.unloadLocation = null;
      formValueCopy.turnInLocation = null;
      formValueCopy.loadLocation = null;
    } else {
      if (formValueCopy.turnInLocation) {
        formValueCopy.turnInLocation = this.turnInLocations.find(
          turnInLocation => turnInLocation.id === formValueCopy.turnInLocation
        )['@id'];
      }
    }

    if (formValueCopy.reloadDate) {
      formValueCopy.reloadDate = DateFns.setHours(
        formValueCopy.reloadDate,
        12
      );
    }
    if (formValueCopy.loadDate) {
      formValueCopy.loadDate = DateFns.setHours(
        formValueCopy.loadDate,
        12
      );
    }
    if (formValueCopy.unloadDate) {
      formValueCopy.unloadDate = DateFns.setHours(
        formValueCopy.unloadDate,
        12
      );
    }

    this.reloadFormSubmitted.emit(formValueCopy);
  }

  handleLinerChange(selectedLiner?: Liner) {
    if (!selectedLiner) {
      this.selectedLinerId = this.reloadRequestForm.get('liner').value;
      selectedLiner = this.liners.find(liner => liner.id === this.selectedLinerId);
    }

    if (selectedLiner) {
      // populate import/export country selects
      this.importLocations = [];
      this.exportLocations = [];
      selectedLiner.reloadLocations.forEach((reloadLocation: LinerReloadLocation) => {
        const countryCode: string = reloadLocation.country;
        const countryLabel: string = this.countries.find(country => country.value === countryCode).label;
        if (reloadLocation.type === 'import') {
          this.importLocations.push({label: countryLabel, id: countryCode});
        } else if (reloadLocation.type === 'export') {
          this.exportLocations.push({label: countryLabel, id: countryCode});
        }
      });

      // update validators
      this.originalFormVersion = (selectedLiner.code === 'HYU' || selectedLiner.code === 'EVE');
      if (this.originalFormVersion) {
        this.reloadRequestForm.get('loadDate').setValidators(Validators.required);
        this.reloadRequestForm.get('unloadDate').setValidators(Validators.required);
        this.reloadRequestForm.get('reloadDate').setValidators(null);
        this.reloadRequestForm.get('accept').setValidators(null);
        this.reloadRequestForm.get('containerNr').setValidators(Validators.required);
        this.reloadRequestForm.get('exportReference').setValidators(Validators.required);
      } else {
        this.reloadRequestForm.get('loadDate').setValidators(null);
        this.reloadRequestForm.get('unloadDate').setValidators(null);
        this.reloadRequestForm.get('reloadDate').setValidators(Validators.required);
        this.reloadRequestForm.get('accept').setValidators(Validators.requiredTrue);
        this.reloadRequestForm.get('containerNr').setValidators([Validators.required, this.validationService.iso6346Validator()]);
        this.reloadRequestForm.get('exportReference').setValidators([Validators.required, , this.validationService.reloadExportLocationValidator()]);
      }
      this.reloadRequestForm.get('loadDate').updateValueAndValidity();
      this.reloadRequestForm.get('unloadDate').updateValueAndValidity();
      this.reloadRequestForm.get('reloadDate').updateValueAndValidity();
      this.reloadRequestForm.get('accept').updateValueAndValidity();
      this.reloadRequestForm.get('containerNr').updateValueAndValidity();
      this.reloadRequestForm.get('exportReference').updateValueAndValidity();

      this.handleLocationChange(selectedLiner);
    }
  }

  handleLocationChange(selectedLiner?: Liner) {
    this.distanceBasedPrice = false;

    if (!selectedLiner) {
      this.selectedLinerId = this.reloadRequestForm.get('liner').value;
      selectedLiner = this.liners.find(liner => liner.id === this.selectedLinerId);
    }

    if (selectedLiner) {
      // detect distance based pricing
      const reloadCountry: string = this.reloadRequestForm.get(selectedLiner.leadingReloadDesk + 'Location').value;
      selectedLiner.reloadLocations.forEach((reloadLocation: LinerReloadLocation) => {
        if (reloadLocation.type === selectedLiner.leadingReloadDesk &&
          reloadLocation.country === reloadCountry &&
          reloadLocation.priceType === PriceTypes.DISTANCE_BASED)
        {
          this.distanceBasedPrice = true;
        }
      });
    }

    // update validators of unload/turnIn/load locations
    if (this.distanceBasedPrice) {
      this.reloadRequestForm.get('unloadLocation').setValidators([Validators.required, this.validationService.reloadLocationGpsValidator()]);
      this.reloadRequestForm.get('turnInLocation').setValidators(Validators.required);
      this.reloadRequestForm.get('loadLocation').setValidators([Validators.required, this.validationService.reloadLocationGpsValidator()]);
    } else {
      this.reloadRequestForm.get('unloadLocation').setValidators(null);
      this.reloadRequestForm.get('turnInLocation').setValidators(null);
      this.reloadRequestForm.get('loadLocation').setValidators(null);
    }
    this.reloadRequestForm.get('unloadLocation').updateValueAndValidity();
    this.reloadRequestForm.get('turnInLocation').updateValueAndValidity();
    this.reloadRequestForm.get('loadLocation').updateValueAndValidity();

    this.handleTriangulationChange();
  }

  handleTriangulationChange(selectedLiner?: Liner) {
    if (!selectedLiner && this.liners) {
      this.selectedLinerId = this.reloadRequestForm.get('liner').value;
      selectedLiner = this.liners.find(liner => liner.id === this.selectedLinerId);
    }

    if (!this.readonly && this.distanceBasedPrice) {
      // calculate request distance
      setTimeout(() => {
        const unloadLocation = this.reloadRequestForm.get('unloadLocation').value;
        const loadLocation = this.reloadRequestForm.get('loadLocation').value;
        const turnInLocationId = this.reloadRequestForm.get('turnInLocation').value;
        let turnInLocation: Location = null;
        if (turnInLocationId && this.turnInLocations) {
          turnInLocation = this.turnInLocations.find(
            location => location.id === turnInLocationId
          );
        }
        if (unloadLocation && turnInLocation && loadLocation && unloadLocation.latitude && turnInLocation.latitude && loadLocation.latitude) {
          this.calculatingRequestDistance = true;
          const payload = {
            unloadLocation: {latitude: unloadLocation.latitude, longitude: unloadLocation.longitude},
            turnInLocation: {latitude: turnInLocation.latitude, longitude: turnInLocation.longitude},
            loadLocation: {latitude: loadLocation.latitude, longitude: loadLocation.longitude}
          }
          this.api.post({
            path: `/calculate_reload_distance`,
            body: payload
          }).toPromise().then(
            (distance: any) => {
              this.requestDistance = distance;
              this.calculatingRequestDistance = false;
              this.calculateRequestCost(selectedLiner);
            },
            () => {
              this.requestDistance = null;
              this.calculatingRequestDistance = false;
            }
          );
        } else {
          this.requestDistance = null;
        }
      }, 0);
    }

    this.calculateRequestCost(selectedLiner);
  }

  calculateRequestCost(selectedLiner: Liner) {
    this.requestCostCalculated = false;
    this.requestCost = 0;

    if (!selectedLiner) {
      return;
    }

    const reloadCountry: string = this.reloadRequestForm.get(selectedLiner.leadingReloadDesk + 'Location').value;

    if (!this.distanceBasedPrice) {
      selectedLiner.reloadLocations.forEach((reloadLocation: LinerReloadLocation) => {
        if (reloadLocation.type === selectedLiner.leadingReloadDesk &&
          reloadLocation.country === reloadCountry &&
          reloadLocation.priceType === PriceTypes.FIXED)
        {
          this.requestCost = reloadLocation.fixedPrice;
          this.requestCostCalculated = true;
        }
      });
      return;
    }

    if (this.requestDistance === null) {
      return;
    }

    selectedLiner.reloadLocations.forEach((reloadLocation: LinerReloadLocation) => {
      if (reloadLocation.type === selectedLiner.leadingReloadDesk &&
        reloadLocation.country === reloadCountry &&
        reloadLocation.priceType === PriceTypes.DISTANCE_BASED)
      {
        reloadLocation.distanceBasedPrice.forEach((item: DistanceBasedPriceItem) => {
          if (this.requestDistance.total >= item.from && this.requestDistance.total <= item.until) {
            this.requestCost = item.price;
            this.requestCostCalculated = true;
          }
        });
      }
    });
  }

  locationFormatter = (x: { name: String, latitude: number, longitude: number }) => {
    return x.name;
  }

  searchLoadLocations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        this.loadingLoadLocations = true;
        const obs: Observable<any> = this.locationService.location(term, true);
        obs.toPromise()
          .then(() => { this.loadingLoadLocations = false; })
          .catch(() => { this.loadingLoadLocations = false; });
        return obs;
      })
    )

  searchUnloadLocations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        this.loadingUnloadLocations = true;
        const obs: Observable<any> = this.locationService.location(term, true);
        obs.toPromise()
          .then(() => { this.loadingUnloadLocations = false; })
          .catch(() => { this.loadingUnloadLocations = false; });
        return obs;
      })
    )

  disableForm()
  {
    this.createForm();
    this.reloadRequestForm.disable();
  }

  enableForm()
  {
    this.createForm();
    this.reloadRequestForm.enable();
    this.handleTriangulationChange();
  }

  showTermsAndConditionsPage(template: any) {
    this.modalService.open(template, {size: 'lg', windowClass: 'modal-terms-and-conditions'});
  }

  showReloadDistanceBreakdown(template: any) {
    this.requestDistance.unloadLocation = this.reloadRequestForm.get('unloadLocation').value.name;
    this.requestDistance.loadLocation = this.reloadRequestForm.get('loadLocation').value.name;
    const turnInLocationId = this.reloadRequestForm.get('turnInLocation').value;
    this.requestDistance.turnInLocation = this.turnInLocations.find(
      location => location.id === turnInLocationId
    )['name'];
    this.modalService.open(template, {size: 'lg', windowClass: 'modal-reload-distance'});
    this.hakkaMap = new HakkaMap('reload-triangulation-map');
    this.updateMap();
  }

  formatRequestStatus(status?: string) {
    if (status) {
      const requestStatus: {label: string, value: string}  = this.requestStatuses.find(
        requestStatus => requestStatus.value === status
      );
      return requestStatus? requestStatus.label : status;
    }
    return status;
  }

  isForceApproveButtonShown() {
    return (this.readonly && !this.originalFormVersion && this.forLiner && this.reloadRequest &&
      this.reloadRequest.requestStatus === 'declined' && this.reloadRequest.liner.code === 'MSC');
  }

  isCancelButtonShown() {
    return (
      // for company
      (this.readonly && !this.originalFormVersion && this.reloadRequest &&
        this.reloadRequest.requestStatus === 'approved' && this.reloadRequest.liner.code === 'MSC' &&
        moment(this.reloadRequest.reloadDate).isSameOrAfter(moment(), 'day')
      )
      || // for liner
      (this.forLiner && this.readonly && !this.originalFormVersion && this.reloadRequest &&
        (this.reloadRequest.requestStatus === 'approved' || this.reloadRequest.requestStatus === 'forced_approved') &&
        this.reloadRequest.liner.code === 'MSC'
      )
    );
  }

  updateMarker(lon, lat, id?) {
    const i = this.markers.length;
    this.markers[i] = this.hakkaMap.generateFeature(
      {
        lat: lat,
        lon: lon
      },
      id || ''
    );
    this.hakkaMap.sources['markers']
      .getFeaturesCollection()
      .push(this.markers[i]);
  }

  updateMap() {
    const unloadLocation = this.reloadRequestForm.get('unloadLocation').value;
    const loadLocation = this.reloadRequestForm.get('loadLocation').value;
    const turnInLocationId = this.reloadRequestForm.get('turnInLocation').value;
    let turnInLocation: Location = null;
    if (turnInLocationId && this.turnInLocations) {
      turnInLocation = this.turnInLocations.find(
        location => location.id === turnInLocationId
      );
    }
    this.hakkaMap.sources['markers'].getFeaturesCollection().clear();
    this.updateMarker(unloadLocation.longitude, unloadLocation.latitude);
    this.updateMarker(turnInLocation.longitude, turnInLocation.latitude);
    this.updateMarker(loadLocation.longitude, loadLocation.latitude);
    this.hakkaMap.fitBounds();
  }

  ngOnDestroy() { }
}
