var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/reload.types';
import * as ReloadActions from '../actions/reload.actions';
import { ApiService } from 'app/core/api/api.service';
var ReloadEffects = /** @class */ (function () {
    function ReloadEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadAllReloadRequests$ = this.actions$.pipe(ofType(types.reload.LOAD_ALL_RELOAD_REQUESTS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            var page = payload && payload.page ? payload.page : 1;
            var forLiner = payload && payload.forLiner ? payload.forLiner : false;
            var status = payload && payload.status ? payload.status : false;
            var url = null;
            if (forLiner) {
                url = "/container_reload_requests_for_liner?page=" + page;
            }
            else {
                url = "/container_reload_requests_for_transport_company?page=" + page;
            }
            if (query) {
                url = url + "&liner.name=" + query + "&liner.shortName=" + query + "&containerNr=" + query + "&exportReference=" + query;
            }
            if (status) {
                url = url + "&requestStatus=" + status;
            }
            return _this.api.get({ path: url }).pipe(map(function (data) { return new ReloadActions.LoadAllReloadRequestsSuccess(data); }), catchError(function (error) { return of(new ReloadActions.LoadAllReloadRequestsFailed(error)); }));
        }));
        this.requestReload$ = this.actions$.pipe(ofType(types.reload.CREATE_RELOAD_REQUEST), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/container_reload_requests', body: payload.body
        }).pipe(map(function (data) { return new ReloadActions.CreateReloadRequestSuccess(data); }), catchError(function (error) { return of(new ReloadActions.CreateReloadRequestFail(error)); })); }));
        this.updateReloadRequest$ = this.actions$.pipe(ofType(types.reload.UPDATE_RELOAD_REQUEST), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/container_reload_requests/' + payload.requestId,
            body: payload.request
        }).pipe(map(function (data) { return new ReloadActions.UpdateReloadRequestSuccess(data); }), catchError(function (error) { return of(new ReloadActions.UpdateReloadRequestFail(error)); })); }));
        this.forceApproveReloadRequest$ = this.actions$.pipe(ofType(types.reload.FORCE_APPROVE_RELOAD_REQUEST), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/container_reload_requests/' + payload.requestId + '/force_approve'
        }).pipe(map(function (data) { return new ReloadActions.ForceApproveReloadRequestSuccess(data); }), catchError(function (error) { return of(new ReloadActions.ForceApproveReloadRequestFail(error)); })); }));
        this.cancelReloadRequest$ = this.actions$.pipe(ofType(types.reload.CANCEL_RELOAD_REQUEST), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/container_reload_requests/' + payload.requestId + '/cancel',
        }).pipe(map(function (data) { return new ReloadActions.CancelReloadRequestSuccess(data); }), catchError(function (error) { return of(new ReloadActions.CancelReloadRequestFail(error)); })); }));
        this.cancelReloadRequestByLiner$ = this.actions$.pipe(ofType(types.reload.CANCEL_RELOAD_REQUEST_BY_LINER), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/container_reload_requests/' + payload.requestId + '/cancel_by_liner',
        }).pipe(map(function (data) { return new ReloadActions.CancelReloadRequestSuccess(data); }), catchError(function (error) { return of(new ReloadActions.CancelReloadRequestFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ReloadEffects.prototype, "loadAllReloadRequests$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ReloadEffects.prototype, "requestReload$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ReloadEffects.prototype, "updateReloadRequest$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ReloadEffects.prototype, "forceApproveReloadRequest$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ReloadEffects.prototype, "cancelReloadRequest$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ReloadEffects.prototype, "cancelReloadRequestByLiner$", void 0);
    return ReloadEffects;
}());
export { ReloadEffects };
