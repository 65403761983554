<div class="card border-primary" id="post-summary">
  <div class="card-body">
    <h2 class="font-weight-light">{{ 'Your new post' | translate }}</h2>
    <p class="font-weight-light">
      <span>{{ transportOffers.length | i18nPlural: offersMapping }}</span>
      <span>{{ transportDemands.length | i18nPlural: demandsMapping }}</span>
      <span *ngIf="transportOffers.length === 0 && transportDemands.length === 0">{{ postType | translate | uppercase }}</span>
    </p>
    <ul class="list-group pre-scrollable">
      <li class="text-center list-group-item" *ngIf="transportOffers.length === 0 && transportDemands.length === 0">
        {{ 'Transports get added here' | translate }}
      </li>

      <li class="list-group-item" *ngFor="let transport of transportOffers; let i = index" [ngClass]="{'active': i === editPostindex}">
        <div class="row">
          <div class="col">
            <span>
              <div class="d-flex justify-content-between">
                <span *ngIf="transport.transportType">{{ getTransportTypeLabel(transport.transportType) | titlecase | translate }}</span>
                <span *ngIf="transport.container.containerType">{{ getContainerTypeLabel(transport.container.containerType) | translate }}</span>
                <span *ngIf="transport.container.liner">{{ getLinerLabel(transport.container.liner) | translate }}</span>
              </div>
              <p style="margin-bottom: 0px;" *ngFor="let task of transport.transportTasks; let i = index">
                <span *ngIf="task && task.location">{{ task.location.shortName ? task.location.shortName : task.location.name ? task.location.name : task.location}}</span>
                <i class="far fa-arrow-right" *ngIf="task && task.location && i !== transport.transportTasks.length -1"></i>
              </p>
            </span>
          </div>
          <div class="col-1">
            <span class="float-right">
              <i class="far fa-edit" (click)="editTransportOffer(transport, i)"></i>
              <i class="far fa-trash-alt text-danger" (click)="removeTransportOffer(i)"></i>
            </span>
          </div>
        </div>
      </li>

      <li class="list-group-item" *ngFor="let transport of transportDemands; let i = index" [ngClass]="{'active': i === editPostindex}">
        <div class="row">
          <div class="col">
            <span>
              <div class="d-flex justify-content-between">
                <span *ngIf="transport.transportType">{{ transport.transportType.label | translate }}</span>
                <span *ngIf="transport.container.containerType">{{ getContainerTypeLabel(transport.container.containerType) | translate }}</span>
                <span *ngIf="transport.container.liner">{{ getLinerLabel(transport.container.liner) | translate }}</span>
              </div>
              <p style="margin-bottom: 0px;">
                <span *ngIf="transport.originLocation">{{ transport.originLocation.shortName ? transport.originLocation.shortName : transport.originLocation.name
                  ? transport.originLocation.name : transport.originLocation }}</span>
                <i *ngIf="transport.destinationLocation" class="far fa-arrow-right"></i>
              </p>
              <p style="margin-bottom: 0px;">
                <span *ngIf="transport.destinationLocation">{{ transport.destinationLocation.shortName ? transport.destinationLocation.shortName : transport.destinationLocation.name
                  ? transport.destinationLocation.name : transport.destinationLocation }}</span>
              </p>
            </span>
          </div>
          <div class="col-1">
            <span class="float-right">
              <i class="far fa-edit" (click)="editTransportDemand(transport, i)"></i>
              <i class="far fa-trash-alt text-danger" (click)="removeTransportDemand(i)"></i>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
