export const invoice = {
  LOAD_INVOICE: '[Invoices] Load One',
  LOAD_INVOICE_SUCCEEDED: '[Invoices] Load One Success',
  LOAD_INVOICE_FAILED: '[Invoices] Load One Fail',
  LOAD_INVOICES: '[Invoices] Load',
  LOAD_INVOICES_SUCCEEDED: '[Invoices] Load Success',
  LOAD_INVOICES_FAILED: '[Invoices] Load Fail',
  LOAD_FILTERED_INVOICES: '[Invoices] Load filtered',
  LOAD_FILTERED_INVOICES_SUCCEEDED: '[Invoices] Load filtered Success',
  LOAD_FILTERED_INVOICES_FAILED: '[Invoices] Load filtered Fail',
  CREATE_INVOICE: '[Invoice] Create',
  CREATE_INVOICE_SUCCEEDED: '[Invoice] Create Success',
  CREATE_INVOICE_FAILED: '[Invoice] Create Fail',
  DELETE_INVOICE: '[Invoice] Delete',
  DELETE_INVOICE_SUCCEEDED: '[Invoice] Delete Success',
  DELETE_INVOICE_FAILED: '[Invoice] Delete Fail',
  UPDATE_INVOICE: '[Invoice] Update',
  UPDATE_INVOICE_SUCCEEDED: '[Invoice] Update Success',
  UPDATE_INVOICE_FAILED: '[Invoice] Update Fail',
  CLEAR: '[Invoice] Clear'
};
