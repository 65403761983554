import { Action } from '@ngrx/store';
import * as types from '../types/market-post.types';

export class LoadMarketposts implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS;
  constructor(public payload?: any) { }
}

export class LoadMarketpostsSuccess implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadMarketpostsFailed implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_FAILED;
  constructor(public payload?: any) { }
}

export class LoadMarketpostsCount implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_COUNT;
  constructor(public payload?: any) { }
}

export class LoadMarketpostsCountSuccess implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_COUNT_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadMarketpostsCountFailed implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_COUNT_FAILED;
  constructor(public payload?: any) { }
}

export class ClearMarketposts implements Action {
  readonly type = types.marketposts.CLEAR;
  constructor(public payload?: any) { }
}

export type All =
  | LoadMarketposts
  | LoadMarketpostsSuccess
  | LoadMarketpostsFailed
  | LoadMarketpostsCount
  | LoadMarketpostsCountSuccess
  | LoadMarketpostsCountFailed
  | ClearMarketposts;
