import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action } from '@ngrx/store';

import * as types from '../types/transport-task.types';
import * as TransportTaskActions from '../actions/transport-task.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class TransportTaskEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  deleteAddress$: Observable<Action> = this.actions$.pipe(
    ofType(types.transportTask.DELETE_TRANSPORT_TASK),
    map((transportTask: TransportTaskActions.DeleteTransportTask) => transportTask.payload),
    concatMap(payload => this.api.delete({
      path: `/transport_tasks/${payload.transportTaskId}`
    }).pipe(
      map(() => new TransportTaskActions.DeleteTransportTaskSuccess(payload)),
      catchError(error => of(new TransportTaskActions.DeleteTransportTaskFail(error)))
    )),
  );

  @Effect()
  updateTransportTask$: Observable<Action> = this.actions$.pipe(
    ofType(types.transportTask.UPDATE_TRANSPORT_TASK),
    map((action: TransportTaskActions.UpdateTransportTask) => action.payload),
    concatMap(payload => this.api.put({
      path: `/transport_tasks/${payload.transportTaskId}`,
      body: payload.body
    }).pipe(
      map(data => new TransportTaskActions.UpdateTransportTaskSuccess(data)),
      catchError(error => of(new TransportTaskActions.UpdateTransportTaskFail(error)))
    )),
  );
}
