import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbDateStruct, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as DateFns from 'date-fns';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LocationService } from 'app/services/location.service';
import { Transport } from 'app/core/store/models/transports.model';
import { NgbDateNativeAdapter, NgbDateCustomParserFormatter } from '../../shared/datepicker-config';

@Component({
  selector: 'app-demand-locations-form',
  templateUrl: './demand-locations-form.component.html',
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DemandLocationsFormComponent implements OnInit, OnChanges {
  @Input()
  transport: Transport;
  @Input()
  submitAttempt: boolean;

  @Output()
  demandLocationsFormValueChanged = new EventEmitter<any>();

  demandLocationsForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    if (!this.demandLocationsForm) {
      this.createForm();
    }
    this.demandLocationsForm.valueChanges.subscribe(demandLocationsFormValue =>
      this.demandLocationsFormValueChanged.emit(demandLocationsFormValue)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.demandLocationsForm) {
      this.createForm();
    }

    if (changes && changes.transport && changes.transport.firstChange) {
      const transport = changes.transport.currentValue;
      const originLocation = this.getOriginLocation(transport);
      const destinationLocation = this.getDestinationLocation(transport);

      if (originLocation) {
        this.demandLocationsForm.patchValue({
          originLocation: originLocation.location,
          originStartDate: originLocation.startDate,
          originStartTime: originLocation.startTime,
          originEndDate: originLocation.endDate,
          originEndTime: originLocation.endTime,
          originTimeIntervalStartTimeSpecified:
            originLocation.startDateTimeSpecified,
          originTimeIntervalEndTimeSpecified:
            originLocation.endDateTimeSpecified
        });
      }
      if (destinationLocation) {
        this.demandLocationsForm.patchValue({
          destinationLocation: destinationLocation.location,
          destinationStartDate: destinationLocation.startDate,
          destinationStartTime: destinationLocation.startTime,
          destinationEndDate: destinationLocation.endDate,
          destinationEndTime: destinationLocation.endTime,
          destinationTimeIntervalStartTimeSpecified:
            destinationLocation.startDateTimeSpecified,
          destinationTimeIntervalEndTimeSpecified:
            destinationLocation.endDateTimeSpecified
        });
      }
      this.demandLocationsFormValueChanged.emit(this.demandLocationsForm.value);
    }
  }

  createForm() {
    this.demandLocationsForm = this.fb.group({
      originLocation: [null],
      originStartDate: [null],
      originStartTime: [null],
      originEndDate: [null],
      originEndTime: [null],
      originTimeIntervalStartTimeSpecified: [false],
      originTimeIntervalEndTimeSpecified: [false],
      destinationLocation: [null],
      destinationStartDate: [null],
      destinationStartTime: [null],
      destinationEndDate: [null],
      destinationEndTime: [null],
      destinationTimeIntervalStartTimeSpecified: [false],
      destinationTimeIntervalEndTimeSpecified: [false]
    });
  }

  /**
   *
   * @param date date object given by the datepicker
   * @param current the current year and month
   * @returns boolean if given date is before today
   */
  getDisabledDates(date: NgbDateStruct): boolean {
    const today = new Date();
    let d = new Date(date.year, date.month, date.day);
    d = DateFns.subMonths(d, 1);
    d = DateFns.addDays(d, 1);
    return DateFns.isBefore(d, today);
  }

  locationFormatter = (x: { name: String }) => {
    return x.name;
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.location(term);
      })
    )

  hasAtLeastOneLocation() {
    return (
      this.demandLocationsForm.get('originLocation').value ||
      this.demandLocationsForm.get('destinationLocation').value
    );
  }

  getOriginLocation(transport: Transport) {
    let task = null;
    switch (transport.transportType.code) {
      case 'load':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'pickup'
        );
        break;
      case 'unload':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'unload'
        );
        break;
      case 'shunt':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'dropoff'
        );
        break;
      default:
        break;
    }
    if (!task) {
      return;
    }
    return task;
  }

  getDestinationLocation(transport: Transport) {
    let task = null;
    switch (transport.transportType.code) {
      case 'load':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'load'
        );
        break;
      case 'unload':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'dropoff'
        );
        break;
      case 'shunt':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'pickup'
        );
        break;
      default:
        break;
    }
    if (!task) {
      return;
    }
    return task;
  }

  handleOriginChange(transportTaskValue) {
    this.demandLocationsForm.patchValue({
      originLocation: transportTaskValue.location,
      originStartDate: transportTaskValue.startDate,
      originStartTime: transportTaskValue.startTime,
      originEndDate: transportTaskValue.endDate,
      originEndTime: transportTaskValue.endTime,
      originTimeIntervalStartTimeSpecified:
        transportTaskValue.startDateTimeSpecified,
      originTimeIntervalEndTimeSpecified:
        transportTaskValue.endDateTimeSpecified
    });
  }
  handleDestinationChange(transportTaskValue) {
    this.demandLocationsForm.patchValue({
      destinationLocation: transportTaskValue.location,
      destinationStartDate: transportTaskValue.startDate,
      destinationStartTime: transportTaskValue.startTime,
      destinationEndDate: transportTaskValue.endDate,
      destinationEndTime: transportTaskValue.endTime,
      destinationTimeIntervalStartTimeSpecified:
        transportTaskValue.startDateTimeSpecified,
      destinationTimeIntervalEndTimeSpecified:
        transportTaskValue.endDateTimeSpecified
    });
  }
}
