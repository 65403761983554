<div class="login-background fullscreen overflow-sm-x-hidden overflow-y">
  <div class="row justify-content-center mt-5">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <img src="/assets/img/logo.png" alt="Hakka" class="login-logo" />
          <p class="text-muted">
            {{
              'Enter your email address and password to access the platform.'
                | translate
            }}
          </p>
          <div class="alert {{ state?.type }}" *ngIf="state?.visible">
            <i *ngIf="state?.icon" class="far {{ state?.icon }}"></i> &nbsp;{{
              state.message | translate
            }}
          </div>
          <form #loginForm="ngForm">
            <div class="form-group">
              <label for="login-user">{{ 'Email' | translate }}</label>
              <input
                [(ngModel)]="user.mail"
                id="username"
                data-cy="username"
                name="mail"
                type="email"
                placeholder="johndoe@gmail.com"
                autofocus="autofocus"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="login-pass">{{ 'Password' | translate }}</label>
              <input
                [(ngModel)]="user.pass"
                id="password"
                data-cy="password"
                name="pass"
                type="password"
                placeholder="{{ 'Enter password' | translate }}"
                class="form-control form-control"
              />
            </div>
            <button
              type="submit"
              (click)="login()"
              data-cy="loginButton"
              class="btn btn-primary text-white"
              [disabled]="isLoading"
            >
              <span
                translate
                class="btn__label"
                [ngClass]="{ invisible: isLoading }"
                >{{ 'Login' | translate }}</span
              >
              <i
                class="fa fa-spinner fa-spin btn__loader"
                [ngClass]="{ visible: isLoading }"
              ></i>
            </button>
            <div class="float-right">
              <a routerLink="/user/reset" class="btn float-right"
                >{{ 'Forgot password' | translate }} ?</a
              >
            </div>
            <div class="form-row mt-3">
              <div class="col">
                <button
                  type="submit"
                  (click)="doSamlRedirect()"
                  class="btn btn-block sso-button"
                  [disabled]="isLoading || isLoadingSAML"
                >
                  <img
                    src="/assets/img/cpoint-logo.png"
                    id="cpoint-logo"
                    alt="c-point logo"
                    [ngClass]="{ invisible: isLoadingSAML }"
                  />
                  <span
                    translate
                    class="btn__label"
                    [ngClass]="{ invisible: isLoadingSAML }"
                    >{{ 'LOGIN WITH C-POINT' | translate }}</span
                  >
                  <i
                    class="fa fa-spinner fa-spin btn__loader"
                    [ngClass]="{ visible: isLoadingSAML }"
                  ></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
