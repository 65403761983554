import { EditCircle, LoadCircles } from 'app/core/store/actions/circles.actions';
import { Circle } from 'ol/style/circle';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from 'app/core/store/store.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-edit-cirle',
  templateUrl: 'edit-circle.component.html'
})
export class EditCircleComponent implements OnInit, OnDestroy {
  circleId: string;
  circle: Circle;
  loading = true;
  constructor(
    private route: ActivatedRoute,
    private store: Store<State>
  ) { }

  ngOnInit() {
    this.circleId = this.route.snapshot.paramMap.get('id');
    if (this.circleId) {
      this.store.dispatch(new LoadCircles());
      this.store
        .select(state => state.circles)
        .pipe(untilDestroyed(this))
        .subscribe(circles => {
          if (circles && circles.length > 0) {
            this.circle = circles.find(c => c.id === this.circleId);
            this.loading = false;
          }
        });
    }
  }

  updateCircle(body) {
    this.store.dispatch(new EditCircle({
      id: this.circleId,
      body: body
    }));
  }

  ngOnDestroy() { }
}
