var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/market-post.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as MarketpostActions from '../actions/market-posts.actions';
import { ApiService } from '../../api/api.service';
var MarketpostEffects = /** @class */ (function () {
    function MarketpostEffects(api, actions$, encoder) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.encoder = encoder;
        this.loadMarketposts$ = this.actions$.pipe(ofType(types.marketposts.LOAD_MARKETPOSTS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/search?q=' + _this.encoder.encodeValue(payload)
        }).pipe(map(function (data) { return new MarketpostActions.LoadMarketpostsSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.loadMarketpostsCount$ = this.actions$.pipe(ofType(types.marketposts.LOAD_MARKETPOSTS_COUNT), concatMap(function () { return _this.api.get({ path: "/dashboard/marketplace/status" }).pipe(map(function (data) { return new MarketpostActions.LoadMarketpostsCountSuccess(data); }), catchError(function (error) { return of(new MarketpostActions.LoadMarketpostsCountFailed(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketpostEffects.prototype, "loadMarketposts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketpostEffects.prototype, "loadMarketpostsCount$", void 0);
    return MarketpostEffects;
}());
export { MarketpostEffects };
