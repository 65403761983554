import { Component } from '@angular/core';
import { extract } from '../services/i18n.service';

@Component({
  selector: 'app-cmp-reload',
  templateUrl: 'reload.component.html',
})
export class ReloadComponent {
  title = extract('Reload');
  routes = [
    {
      url: '/reload/reload-requests',
      title: extract('Reload Requests'),
      description: extract('Manage your Reload Requests'),
      icon: 'fa-recycle'
    },
    {
      url: '/reload/new-reload-request',
      title: extract('New Reload Request'),
      description: extract('Create a new Reload Request'),
      icon: 'fa-plus'
    },
  ];
}
