import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';

import * as CertiweightActions from '../actions/certiweight.actions';
import * as types from '../types/certiweight.types';

@Injectable()
export class CertiweightEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadDefaultCertificatePrice$: Observable<Action> = this.actions$.pipe(
    ofType(types.certiweight.LOAD_DEFAULT_CERTIFICATE_PRICE),
    concatMap(() => this.api.get({
      path: '/subscription_type_actions?code=CERTIWEIGHT_BUY_CERTIFICATE'
    }).pipe(
      map(data => new CertiweightActions.LoadDefaultCertificatePriceSuccess(data)),
      catchError(error => of(new CertiweightActions.LoadDefaultCertificatePriceFail(error)))
    )),
  );
}
