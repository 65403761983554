import { schedule } from '../types/schedule.types';
import { Action } from '@ngrx/store';

export class LoadSchedule implements Action {
  readonly type = schedule.LOAD_SCHEDULE;
  constructor(
    public payload: {
      fromDate: string;
      toDate: string;
    }
  ) {}
}

export class LoadScheduleSuccess implements Action {
  readonly type = schedule.LOAD_SCHEDULE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadScheduleFail implements Action {
  readonly type = schedule.LOAD_SCHEDULE_FAILED;
  constructor(public payload?: any) {}
}

export class LoadUnplannedTransports implements Action {
  readonly type = schedule.LOAD_UNPLANNED_TRANSPORTS;
  constructor(
    public payload: {
      page?: string;
      query?: string;
      fromDate: string;
      toDate: string;
    }
  ) {}
}

export class LoadUnplannedTransportsSuccess implements Action {
  readonly type = schedule.LOAD_UNPLANNED_TRANSPORTS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadUnplannedTransportsFail implements Action {
  readonly type = schedule.LOAD_UNPLANNED_TRANSPORTS_FAILED;
  constructor(public payload?: any) {}
}

export class LoadNextUnplannedTransportsPage implements Action {
  readonly type = schedule.LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE;
  constructor(
    public payload: {
      page: string | number;
      query?: string;
      fromDate: string;
      toDate: string;
    }
  ) {}
}

export class LoadNextUnplannedTransportsPageSuccess implements Action {
  readonly type = schedule.LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadNextUnplannedTransportsPageFail implements Action {
  readonly type = schedule.LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE_FAILED;
  constructor(public payload?: any) {}
}

export class AddToSchedule implements Action {
  readonly type = schedule.ADD_TO_SCHEDULE;
  constructor(
    public payload: {
      date: string;
      driver: string;
      transportTasks: string[];
    }
  ) {}
}

export class AddToScheduleSuccess implements Action {
  readonly type = schedule.ADD_TO_SCHEDULE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class AddToScheduleFail implements Action {
  readonly type = schedule.ADD_TO_SCHEDULE_FAILED;
  constructor(public payload?: any) {}
}

export class RemoveFromSchedule implements Action {
  readonly type = schedule.REMOVE_FROM_SCHEDULE;
  constructor(
    public payload: {
      transportTasks: string[];
    }
  ) {}
}

export class RemoveFromScheduleSuccess implements Action {
  readonly type = schedule.REMOVE_FROM_SCHEDULE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class RemoveFromScheduleFail implements Action {
  readonly type = schedule.REMOVE_FROM_SCHEDULE_FAILED;
  constructor(public payload?: any) {}
}

export class UpdateScheduleSequence implements Action {
  readonly type = schedule.UPDATE_SCHEDULE_SEQUENCE;
  constructor(public payload: any) {}
}

export class UpdateScheduleSequenceSuccess implements Action {
  readonly type = schedule.UPDATE_SCHEDULE_SEQUENCE_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class UpdateScheduleSequenceFail implements Action {
  readonly type = schedule.UPDATE_SCHEDULE_SEQUENCE_FAILED;
  constructor(public payload?: any) {}
}

export type All =
  | LoadSchedule
  | LoadScheduleSuccess
  | LoadScheduleFail
  | LoadUnplannedTransports
  | LoadUnplannedTransportsFail
  | LoadUnplannedTransportsSuccess
  | LoadNextUnplannedTransportsPage
  | LoadNextUnplannedTransportsPageFail
  | LoadNextUnplannedTransportsPageSuccess
  | AddToSchedule
  | AddToScheduleSuccess
  | AddToScheduleFail
  | RemoveFromSchedule
  | RemoveFromScheduleSuccess
  | RemoveFromScheduleFail
  | UpdateScheduleSequence
  | UpdateScheduleSequenceSuccess
  | UpdateScheduleSequenceFail;
