/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-supplier.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/customer-form/customer-form.component.ngfactory";
import * as i3 from "../../shared/customer-form/customer-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./new-supplier.component";
import * as i6 from "@ngrx/effects";
import * as i7 from "../../services/toast.service";
import * as i8 from "@ngrx/store";
import * as i9 from "@angular/router";
var styles_NewSupplierComponent = [i0.styles];
var RenderType_NewSupplierComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewSupplierComponent, data: {} });
export { RenderType_NewSupplierComponent as RenderType_NewSupplierComponent };
export function View_NewSupplierComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-customer-form", [], null, [[null, "customerFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("customerFormSubmitted" === en)) {
        var pd_0 = (_co.createNewSupplier($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerFormComponent_0, i2.RenderType_CustomerFormComponent)), i1.ɵdid(2, 573440, null, 0, i3.CustomerFormComponent, [i4.FormBuilder], { isLoading: [0, "isLoading"], countries: [1, "countries"] }, { customerFormSubmitted: "customerFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = _co.countries; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NewSupplierComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-supplier", [], null, null, null, View_NewSupplierComponent_0, RenderType_NewSupplierComponent)), i1.ɵdid(1, 245760, null, 0, i5.NewSupplierComponent, [i6.Actions, i7.ToastService, i8.Store, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewSupplierComponentNgFactory = i1.ɵccf("app-new-supplier", i5.NewSupplierComponent, View_NewSupplierComponent_Host_0, {}, {}, []);
export { NewSupplierComponentNgFactory as NewSupplierComponentNgFactory };
