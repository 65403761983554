import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadAllTransactions } from 'app/core/store/actions/transactions.actions';
import { RequestRechargeWallet } from 'app/core/store/actions/wallet.actions';
import { State } from 'app/core/store/store.model';
import * as WalletTypes from 'app/core/store/types/wallet.types';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-reload-credits',
  templateUrl: 'reload-credits.component.html'
})
export class ReloadCreditsComponent implements OnInit, OnDestroy {
  rechargeForm: FormGroup;
  loading = true;
  recharging = false;
  amountVisible = false;

  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
    private rechargeUpdates$: Actions,
    private toastr: ToastService
  ) {
    rechargeUpdates$
      .pipe(ofType(WalletTypes.wallet.RECHARGE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.rechargeForm.reset();
        this.recharging = false;
        this.toastr.showSuccess({ message: 'Recharge succeeded!' });
        this.store.dispatch(new LoadAllTransactions());
      });
    rechargeUpdates$
      .pipe(ofType(WalletTypes.wallet.RECHARGE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.recharging = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Recharge failed!'
        });
      });
    rechargeUpdates$
      .pipe(ofType(WalletTypes.wallet.REQUEST_RECHARGE_WALLET_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        window.location.href = data['payload']['url'];
      });
  }

  ngOnInit() {
    this.rechargeForm = this.fb.group({
      credits: [250, [Validators.required, Validators.min(20), Validators.max(750000)]]
    });
  }

  ngOnDestroy() { }

  showAmount() {
    this.amountVisible = true;
  }

  setAmount(amount: Number) {
    this.rechargeForm.setValue({ credits: amount });
  }

  onSubmit() {
    this.recharging = true;
    const body = {
      amount: this.rechargeForm.value.credits,
      description: 'Hakka Credits'
    };
    this.store.dispatch(new RequestRechargeWallet(body));
  }
}
