import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { Product } from 'app/core/store/models/product.model';
import { extract } from 'app/services/i18n.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tariffs-table',
  templateUrl: './tariffs-table.component.html',
})
export class TariffsTableComponent {
  @ViewChild('tariffTable', { static: false })
  table: any;
  @Input()
  isLoading: boolean;
  @Input()
  tariffs: Product[];
  @Input()
  totalElements: number;
  @Output()
  deleteTariff = new EventEmitter();

  tableMessages = {
    emptyMessage: extract('No tariffs to display'),
    totalMessage: extract('total')
  };
  constructor(private router: Router) { }

  editTariff(tariffId: string) {
    this.router.navigateByUrl(`/crm/tariffs/${tariffId}/edit`);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  handleDelete(tariff: Product) {
    this.deleteTariff.emit(tariff.id);
  }
}
