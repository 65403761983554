/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../contact-form/contact-form.component.ngfactory";
import * as i3 from "../contact-form/contact-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./contact-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
var styles_ContactModalComponent = [];
var RenderType_ContactModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContactModalComponent, data: {} });
export { RenderType_ContactModalComponent as RenderType_ContactModalComponent };
export function View_ContactModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-contact-form", [], null, [[null, "contactFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contactFormSubmitted" === en)) {
        var pd_0 = (_co.createContact($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ContactFormComponent_0, i2.RenderType_ContactFormComponent)), i0.ɵdid(4, 573440, null, 0, i3.ContactFormComponent, [i4.FormBuilder], null, { contactFormSubmitted: "contactFormSubmitted" })], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Add new contact")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ContactModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-contact-modal", [], null, null, null, View_ContactModalComponent_0, RenderType_ContactModalComponent)), i0.ɵdid(1, 49152, null, 0, i5.ContactModalComponent, [i6.NgbActiveModal], null, null)], null, null); }
var ContactModalComponentNgFactory = i0.ɵccf("app-contact-modal", i5.ContactModalComponent, View_ContactModalComponent_Host_0, {}, {}, []);
export { ContactModalComponentNgFactory as ContactModalComponentNgFactory };
