import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationComponent } from './authentication.component';
import { LoginComponent } from './login/login.component';
import { ResetComponent } from './reset/reset.component';
import { ResetUpdateComponent } from './reset/update/reset.update.component';
import { ResetSuccessComponent } from './reset/success/reset.success.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { UpdateToastComponent } from './update-toast/update-toast.component';

@NgModule({
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    RouterModule,
    TranslateModule,
    FormsModule
  ],
  declarations: [
    AuthenticationComponent,
    LoginComponent,
    ResetComponent,
    ResetUpdateComponent,
    ResetSuccessComponent,
    UpdateToastComponent
  ]
})
export class AuthenticationModule {}
