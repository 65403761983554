import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { ClearCircles } from '../store/actions/circles.actions';
import { ClearCreditNotes } from '../store/actions/credit-note.actions';
import { ClearCustomers } from '../store/actions/customer.actions';
import { ClearInvoices } from '../store/actions/invoice.actions';
import { ClearAddressBookLocations } from '../store/actions/location.actions';
import { ClearMarketposts } from '../store/actions/market-posts.actions';
import { ClearMatching } from '../store/actions/matching.actions';
import { ClearMessages } from '../store/actions/messages.actions';
import { ClearProducts } from '../store/actions/product.actions';
import { ClearSubscriptions, LoadSubscriptions } from '../store/actions/subscriptions.actions';
import { ClearTaxRates } from '../store/actions/tax-rate.actions';
import { ClearTenantSettings } from '../store/actions/tenant-settings.actions';
import { ClearTenants } from '../store/actions/tenants.actions';
import { ClearCreditTransactions } from '../store/actions/transactions.actions';
import { ClearTransports } from '../store/actions/transports.actions';
import { ClearUserContexts } from '../store/actions/user-contexts.actions';
import { ClearUser, LoadUser } from '../store/actions/user.actions';
import { ClearWallet } from '../store/actions/wallet.actions';
import { Subscription } from '../store/models/subscriptions.model';
import { Tenant } from '../store/models/tenant.model';
import { UserContext } from '../store/models/user-contexts.model';
import { State } from '../store/store.model';
import { LoadTenants } from '../tenant/tenants.actions';

@Injectable()
export class AuthService {
  protected redirectUrl: string;

  constructor(private store: Store<State>) { }

  public hasActiveSession(): Observable<boolean> {
    const localStorageSession = JSON.parse(localStorage.getItem('session'));
    if (!localStorageSession) {
      return of(false);
    }
    return of(!!localStorageSession.access_token);
  }

  public hasActiveTenant(): Observable<boolean> {
    const localStorageSession = JSON.parse(localStorage.getItem('session'));
    return of(localStorageSession && !!localStorageSession.tenant);
  }

  public getActiveTenant(): string {
    const session = JSON.parse(localStorage.getItem('session'));
    if (!session || !session.tenant) {
      return;
    }
    return session.tenant;
  }

  public isOnlyDriver(user: UserContext): boolean {
    return user.roles.filter(role => role.code === 'DRIVER').length > 0 &&
      user.roles.length === 1;
  }

  public isAdmin(user: UserContext): boolean {
    return user.roles.filter(role => role.code === 'ADMIN').length > 0;
  }

  public isSuperAdmin(userContext: UserContext): boolean {
    return userContext && userContext.user && userContext.user.superAdmin;
  }

  public isBetaUser(user: UserContext): boolean {
    return user.roles.filter(role => role.code === 'BETA_USER').length > 0;
  }

  public getUserTenantsFromStoreOrAPI(): Observable<Tenant[]> {
    return this.store
      .select(state => state.tenants.userTenants)
      .pipe(
        tap(data => {
          if (data === null) {
            this.store.dispatch(new LoadTenants());
          }
        }),
        filter(data => data !== null),
        take(1)
      );
  }

  public getFromStoreOrAPI(): Observable<UserContext> {
    return this.store
      .select(state => state.user)
      .pipe(
        tap((data: any) => {
          if (data['@id'] === null) {
            this.store.dispatch(new LoadUser());
          }
        }),
        filter((data: any) => data['@id'] !== null),
        take(1)
      );
  }

  public getSubscriptionsFromStoreOrAPI(): Observable<Subscription[]> {
    return this.store
      .select(state => state.subscriptions)
      .pipe(
        tap((data: any) => {
          if (!data) {
            this.store.dispatch(new LoadSubscriptions());
          }
        }),
        filter(data => data !== null)
      );
  }

  public clearStoreOnLogout() {
    this.store.dispatch(new ClearTenants());
  }

  public clearStore() {
    this.store.dispatch(new ClearUser());
    this.store.dispatch(new ClearTenantSettings());
    this.store.dispatch(new ClearUserContexts());
    this.store.dispatch(new ClearWallet());
    this.store.dispatch(new ClearSubscriptions());
    this.store.dispatch(new ClearCreditTransactions());
    this.store.dispatch(new ClearTransports());
    this.store.dispatch(new ClearMessages());
    this.store.dispatch(new ClearMarketposts());
    this.store.dispatch(new ClearCircles());
    this.store.dispatch(new ClearAddressBookLocations());
    this.store.dispatch(new ClearMatching());
    this.store.dispatch(new ClearInvoices());
    this.store.dispatch(new ClearCreditNotes());
    this.store.dispatch(new ClearCustomers());
    this.store.dispatch(new ClearProducts());
    this.store.dispatch(new ClearTaxRates());
  }
}
