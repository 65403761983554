import { Injectable } from '@angular/core';
import * as DateFns from 'date-fns';
import * as fileSaver from 'file-saver';

import { ApiService } from '../core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private api: ApiService) { }

  downloadPDF(url: string, name?: string, locale: string = 'en') {
    return this.api.get(
      { path: url },
      {
        'Content-Type': 'application/json',
        'Accept-Language': locale,
        'Accept': 'application/pdf'
      },
      'blob'
    ).toPromise().then(data => {
      const blob = new Blob([<any>data], { type: 'application/pdf' });
      name = name || DateFns.format(new Date(), 'DD-MM-YYYY_hh:mm:ss');
      fileSaver.saveAs(blob, name + '.pdf');
    });
  }

  downloadAttachment(name: string, id: string) {
    return this.api.get(
      { path: `/attachments/${id}/download` },
      { 'Content-Type': 'application/json', Accept: 'application/pdf' },
      'blob'
    ).toPromise().then(data => {
      const blob = new Blob([<any>data], { type: data['type'] });
      name = name || DateFns.format(new Date(), 'DD-MM-YYYY_hh:mm:ss');
      fileSaver.saveAs(blob, name);
    });
  }

  downloadXLSX(url: string, name?: string, locale: string = 'en') {
    return this.api.get(
      { path: url },
      { 'Content-Type': 'application/json', 'Accept-Language': locale },
      'blob'
    ).toPromise().then(data => {
      const blob = new Blob([<any>data], { type: 'text/csv' });
      name = name + `_${locale}.xlsx` || DateFns.format(new Date(), 'DD-MM-YYYY_hh:mm:ss') + `_${locale}.xlsx`;
      fileSaver.saveAs(blob, name);
    });
  }
}
