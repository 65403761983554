import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import { Liner } from 'app/core/store/models/liner.model';
import { ContainerType } from 'app/core/store/models/container-types.model';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { Autocomplete } from 'app/services/autocomplete.service';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { TransportType } from 'app/core/store/models/transport-types.model';
import {
  Component,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { SearchService } from 'app/services/search.service';

// rxjs
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  NgbCalendar,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import * as FilterTypes from 'app/core/store/types/filter.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss']
})
export class FilterComponent implements OnDestroy {
  @Output()
  filtered = new EventEmitter<boolean>();

  transportTypes: TransportType[];
  containerTypes: ContainerType[];
  liners: Liner[];
  selectedContainerTypes = [];
  selectedTransportTypes = [];
  selectedLiners = [];
  minWeight: number;
  maxWeight: number;
  reference: string;
  marketPostOffer: boolean;
  marketPostDemand: boolean;
  transportPanelVisible = false;
  containerPanelVisible = false;
  linerPanelVisible = false;
  dateRangePanelVisible = false;
  showAlert = true;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;

  constructor(
    private store: Store<State>,
    private autocompleteService: Autocomplete,
    private searchService: SearchService,
    private calendar: NgbCalendar,
    private updates$: Actions
  ) {
    this.updates$
      .pipe(ofType(FilterTypes.filter.CLEAR_FILTER))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.clearFilter());

    if (JSON.parse(localStorage.getItem('minDate'))) {
      this.fromDate = JSON.parse(localStorage.getItem('minDate'));
      this.minDate = JSON.parse(localStorage.getItem('minDate'));
    } else {
      this.fromDate = calendar.getToday();
    }
    if (JSON.parse(localStorage.getItem('maxDate'))) {
      this.toDate = JSON.parse(localStorage.getItem('maxDate'));
      this.maxDate = JSON.parse(localStorage.getItem('maxDate'));
    }
    const mpo = localStorage.getItem('marketPostOffer');
    this.marketPostOffer = mpo === 'true';
    const mpd = localStorage.getItem('marketPostDemand');
    this.marketPostDemand = mpd === 'true';
    this.minWeight = Number.parseInt(localStorage.getItem('minWeight'));
    this.maxWeight = Number.parseInt(localStorage.getItem('maxWeight'));
    if (JSON.parse(localStorage.getItem('containerTypes'))) {
      this.selectedContainerTypes = JSON.parse(
        localStorage.getItem('containerTypes')
      );
    }
    if (JSON.parse(localStorage.getItem('liners'))) {
      this.selectedLiners = JSON.parse(localStorage.getItem('liners'));
    }
    if (JSON.parse(localStorage.getItem('transportTypes'))) {
      this.selectedTransportTypes = JSON.parse(
        localStorage.getItem('transportTypes')
      );
    }
    this.reference = localStorage.getItem('reference');
    this.store
      .select(state => state.marketplace.transportTypes)
      .pipe(untilDestroyed(this))
      .subscribe(transportTypes => {
        if (transportTypes && transportTypes.length > 0) {
          this.transportTypes = transportTypes.map(transportType => {
            const temp = transportType;
            temp['checked'] = this.selectedTransportTypes.find(
              t => t === transportType.id
            )
              ? !!this.selectedTransportTypes.find(t => t === transportType.id)
              : false;
            return temp;
          });
          this.filter();
        }
      });
    this.store.dispatch(new LoadLiners());
    this.store.dispatch(new LoadContainerTypes());
    this.store.dispatch(new LoadTransportTypes());
    this.store
      .select(state => {
        if (state && state.marketplace) {
          return state.marketplace.liners;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (liners) {
          const temp = JSON.parse(JSON.stringify(liners));
          this.liners = temp.map(liner => {
            if (liner.shortName) {
              liner.name = liner.name + ' (' + liner.shortName + ')';
            }
            return liner;
          });
        }
      });
    this.store
      .select(state => {
        if (state) {
          return state.containerTypes;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(cTypes => {
        if (cTypes) {
          this.containerTypes = cTypes;
        }
      });
    this.filter();
  }

  searchContainerType = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.autocompleteService.search('container_type', term);
      })
    )
  formatter = (x: { label: string }) => x.label;

  searchLiner = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.autocompleteService.search('liner', term);
      })
    )

  onSelectContainerType() {
    // add containerTypes to localstorage
    localStorage.setItem(
      'containerTypes',
      JSON.stringify(this.selectedContainerTypes)
    );
    this.filter();
  }

  onSelectLiner() {
    // add liners to localstorage
    localStorage.setItem('liners', JSON.stringify(this.selectedLiners));
    this.filter();
  }

  onRemoveContainerType() {
    // replace containerTypes with new value in localStorage
    localStorage.removeItem('containerTypes');
    localStorage.setItem(
      'containerTypes',
      JSON.stringify(this.selectedContainerTypes)
    );
    this.filter();
  }

  onRemoveLiner() {
    // replace liners with new value in localStorage
    localStorage.removeItem('liners');
    localStorage.setItem('liners', JSON.stringify(this.selectedLiners));
    this.filter();
  }

  filter() {
    const tTypes = [];
    if (this.transportTypes && this.transportTypes.length > 0) {
      this.selectedTransportTypes.forEach(t => {
        tTypes.push(
          this.transportTypes.find(transportT => transportT.id === t)
        );
      });
    }

    const minDate = JSON.parse(localStorage.getItem('minDate'));
    const maxDate = JSON.parse(localStorage.getItem('maxDate'));

    if (
      (!this.selectedContainerTypes ||
        this.selectedContainerTypes.length === 0) &&
      (!this.selectedTransportTypes ||
        this.selectedTransportTypes.length === 0) &&
      (!this.selectedLiners || this.selectedLiners.length === 0) &&
      !this.minWeight &&
      !this.maxWeight &&
      !this.reference &&
      !this.marketPostDemand &&
      !this.marketPostOffer &&
      !this.minDate &&
      !this.maxDate
    ) {
      localStorage.removeItem('fq');
    }
    this.filtered.emit(true);
    this.searchService.filter(
      this.selectedContainerTypes,
      tTypes,
      this.minWeight,
      this.maxWeight,
      this.reference,
      this.marketPostOffer,
      this.marketPostDemand,
      this.selectedLiners,
      maxDate,
      minDate
    );
  }

  toggleTType(transportType) {
    const index = this.selectedTransportTypes.indexOf(transportType.id);
    if (index === -1) {
      this.selectedTransportTypes.push(transportType.id);
    } else {
      this.selectedTransportTypes.splice(index, 1);
    }
    localStorage.setItem(
      'transportTypes',
      JSON.stringify(this.selectedTransportTypes)
    );
    this.filter();
  }

  mpoSelected() {
    localStorage.setItem('marketPostOffer', String(this.marketPostOffer));
    this.filter();
  }
  mpdSelected() {
    localStorage.setItem('marketPostDemand', String(this.marketPostDemand));
    this.filter();
  }
  minWeightChanged() {
    localStorage.setItem('minWeight', String(this.minWeight));
    this.filter();
  }
  maxWeightChanged() {
    localStorage.setItem('maxWeight', String(this.maxWeight));
    this.filter();
  }

  referenceChanged() {
    localStorage.setItem('reference', String(this.reference));
    this.filter();
  }

  handleDateSelection(dateSelectionEvent: {
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
  }) {
    if (!dateSelectionEvent.fromDate) {
      this.minDate = null;
      localStorage.removeItem('minDate');
    } else {
      localStorage.setItem(
        'minDate',
        JSON.stringify(dateSelectionEvent.fromDate)
      );
      this.minDate = dateSelectionEvent.fromDate;
    }
    this.maxDate = dateSelectionEvent.toDate
      ? dateSelectionEvent.toDate
      : dateSelectionEvent.fromDate;
    localStorage.setItem('maxDate', JSON.stringify(this.maxDate));
    this.filter();
  }

  clearFilter() {
    // Clear localstorage
    localStorage.removeItem('marketPostOffer');
    localStorage.removeItem('marketPostDemand');
    localStorage.removeItem('transportTypes');
    localStorage.removeItem('minWeight');
    localStorage.removeItem('maxWeight');
    localStorage.removeItem('reference');
    localStorage.removeItem('containerTypes');
    localStorage.removeItem('liners');
    localStorage.removeItem('maxDate');
    localStorage.removeItem('minDate');

    // Set facets to default
    this.selectedContainerTypes = [];
    this.selectedTransportTypes = [];
    if (this.transportTypes) {
      this.transportTypes.map(tt => (tt['checked'] = false));
    }
    this.selectedLiners = [];
    this.minWeight = null;
    this.maxWeight = null;
    this.reference = null;
    this.marketPostOffer = false;
    this.marketPostDemand = false;
    this.maxDate = null;
    const today = new Date();
    this.minDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    };
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
  }

  ngOnDestroy() { }
}
