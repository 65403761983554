/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./map.component";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
function View_MapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.showAlert = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "far fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "warning"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Warning: posts don't refresh with the map open")); _ck(_v, 4, 0, currVal_2); }); }
export function View_MapComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pl-3 pr-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "map"], ["id", "map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "map-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-primary text-white"], ["id", "updateMapButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateMarketpostsByGeoBoundBox() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "far fa-undo"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showAlert; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("Search in this area")); _ck(_v, 7, 0, currVal_1); }); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵdid(1, 638976, null, 0, i6.MapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i1.ɵccf("app-cmp-map", i6.MapComponent, View_MapComponent_Host_0, { posts: "posts", updateSize: "updateSize" }, { updateByGeoBoundBox: "updateByGeoBoundBox" }, []);
export { MapComponentNgFactory as MapComponentNgFactory };
