<form
  (ngSubmit)="onSubmit()"
  [formGroup]="contactForm"
  class="p-3 grid-container"
>
  <div class="form-group">
    <input
      class="form-control"
      formControlName="salutation"
      placeholder="Salutation"
    />
    <div class="invalid-feedback">
      {{ 'Salutation is required.' | translate }}
    </div>
  </div>
  <div class="split-fg">
    <div class="form-group">
      <input
        class="form-control"
        formControlName="firstName"
        placeholder="First name"
      />
      <div class="invalid-feedback">
        {{ 'First name is required.' | translate }}
      </div>
    </div>
    <div class="form-group">
      <input
        class="form-control"
        formControlName="lastName"
        placeholder="Last name"
      />
      <div class="invalid-feedback">
        {{ 'Last name is required.' | translate }}
      </div>
    </div>
  </div>
  <div class="form-group">
    <input
      class="form-control"
      formControlName="email"
      type="email"
      placeholder="E-mail address"
    />
    <div class="invalid-feedback">{{ 'E-mail is required.' | translate }}</div>
  </div>
  <div class="form-group">
    <input
      class="form-control"
      formControlName="telephone"
      placeholder="Phone number"
    />
    <div class="invalid-feedback">
      {{ 'Phone number is required.' | translate }}
    </div>
  </div>
  <div class="form-group">
    <input
      class="form-control"
      formControlName="designation"
      placeholder="Function (e.g. Manager)"
    />
    <div class="invalid-feedback">
      {{ 'Function is required.' | translate }}
    </div>
  </div>
  <div class="form-group">
    <input
      class="form-control"
      formControlName="department"
      placeholder="Department (e.g. Sales)"
    />
    <div class="invalid-feedback">
      {{ 'Department is required.' | translate }}
    </div>
  </div>

  <div id="submit">
    <app-button [isLoading]="isLoading" [type]="'submit'">
      <span *ngIf="!contact">{{ 'Create' | translate }}</span>
      <span *ngIf="contact">{{ 'Save' | translate }}</span>
    </app-button>
  </div>
</form>
