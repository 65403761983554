import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { Transport } from 'app/core/store/models/transports.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import { TransportType } from 'app/core/store/models/transport-types.model';
import * as moment from 'moment';
import { CreateMarketPostDemand } from 'app/core/store/actions/market-post-demand.actions';
import { Liner } from 'app/core/store/models/liner.model';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-publish-demand',
  templateUrl: './publish-demand.component.html',
  styleUrls: ['./publish-demand.component.scss']
})
export class PublishDemandComponent implements OnChanges, OnDestroy {
  @Input()
  transport: Transport;

  submitAttempt: boolean;
  containerValid: boolean;
  needsConfirmation = true;
  comment = '';
  container: any;
  genset: boolean;
  isReefer: boolean;
  transportTasks: any;
  isLoading: boolean;
  transportTypes: TransportType[];
  containerTypes: any[];
  liners: Liner[];

  constructor(public activeModal: NgbActiveModal, private store: Store<State>) {
    store.dispatch(new LoadTransportTypes());
    store
      .select(state => state.marketplace.transportTypes)
      .pipe(untilDestroyed(this))
      .subscribe(transportTypes => {
        if (transportTypes) {
          this.transportTypes = transportTypes;
        }
      });
    store.dispatch(new LoadLiners());
    store
      .select(state => state.marketplace.liners)
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (liners) {
          this.liners = liners;
        }
      });
    store.dispatch(new LoadContainerTypes());
    store
      .select(state => state.containerTypes)
      .pipe(untilDestroyed(this))
      .subscribe(containerTypes => {
        if (containerTypes) {
          this.containerTypes = containerTypes;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.transport && changes.transport.firstChange) {
    }
  }

  ngOnDestroy() { }

  getInverseTransportType(transport: Transport): TransportType {
    let type = null;
    switch (transport.transportType.code) {
      case 'load':
        type = this.transportTypes.find(
          transportType => transportType.code === 'unload'
        );
        break;
      case 'unload':
        type = this.transportTypes.find(
          transportType => transportType.code === 'load'
        );
        break;
      case 'shunt':
        type = this.transportTypes.find(
          transportType => transportType.code === 'shunt'
        );
        break;
    }
    return type;
  }

  getContainer(transport) {
    if (transport && transport.container) {
      const container = Object.assign({}, transport.container);
      container.liner = container.liner.id;
      container.containerType = container.containerType.id;
      this.container = this.container ? this.container : container;
      return container;
    }
  }

  handleDemandLocationsFormValueChange(demandLocationsFormValue) {
    this.transportTasks = demandLocationsFormValue;
  }

  handleContainerFormStatusChanged(containerFormStatus) {
    this.containerValid = containerFormStatus === 'VALID';
  }
  handleContainerFormValueChanged(containerFormValue) {
    if (!containerFormValue) {
      return;
    }
    if (containerFormValue.invalid) {
      this.containerValid = false;
    }
    this.containerValid = true;
    this.container = containerFormValue;
  }

  handleIsReefer(isReefer) {
    if (isReefer) {
      this.isReefer = isReefer;
    } else {
      this.isReefer = isReefer;
      this.genset = false;
    }
  }

  publishDemand() {
    this.container.liner = this.liners.find(
      liner => liner.id === this.container.liner
    )['@id'];
    this.container.containerType = this.containerTypes.find(
      containerType => containerType.id === this.container.containerType
    )['@id'];
    const originTimeIntervalStart = this.transportTasks
      .originTimeIntervalStartTimeSpecified
      ? moment(this.transportTasks.originStartDate)
        .hours(this.transportTasks.originStartTime.hour)
        .minutes(this.transportTasks.originStartTime.minute)
        .seconds(0)
        .format()
      : moment(this.transportTasks.originStartDate).format();
    const originTimeIntervalEnd = this.transportTasks
      .originTimeIntervalEndTimeSpecified
      ? moment(this.transportTasks.originEndDate)
        .hours(this.transportTasks.originEndTime.hour)
        .minutes(this.transportTasks.originEndTime.minute)
        .seconds(0)
        .format()
      : moment(this.transportTasks.originEndDate).format();
    const destinationTimeIntervalStart = this.transportTasks
      .destinationTimeIntervalStartTimeSpecified
      ? moment(this.transportTasks.destinationStartDate)
        .hours(this.transportTasks.destinationStartTime.hour)
        .minutes(this.transportTasks.destinationStartTime.minute)
        .seconds(0)
        .format()
      : moment(this.transportTasks.destinationStartDate).format();
    const destinationTimeIntervalEnd = this.transportTasks
      .destinationTimeIntervalEndTimeSpecified
      ? moment(this.transportTasks.destinationEndDate)
        .hours(this.transportTasks.destinationEndTime.hour)
        .minutes(this.transportTasks.destinationEndTime.minute)
        .seconds(0)
        .format()
      : moment(this.transportTasks.destinationEndDate).format();
    const demand = {
      container: this.container,
      comment: this.comment,
      destinationLocation: this.transportTasks.destinationLocation,
      originLocation: this.transportTasks.originLocation,
      originTimeIntervalStart,
      originTimeIntervalEnd,
      destinationTimeIntervalStart,
      destinationTimeIntervalEnd,
      originTimeIntervalStartTimeSpecified: this.transportTasks
        .originTimeIntervalStartTimeSpecified,
      originTimeIntervalEndTimeSpecified: this.transportTasks
        .originTimeIntervalEndTimeSpecified,
      destinationTimeIntervalStartTimeSpecified: this.transportTasks
        .destinationTimeIntervalStartTimeSpecified,
      destinationTimeIntervalEndTimeSpecified: this.transportTasks
        .destinationTimeIntervalEndTimeSpecified,
      transportType: this.getInverseTransportType(this.transport)['@id']
    };
    const body = {
      marketPostDemands: [demand],
      shareType: '',
      circles: []
    };
    this.store.dispatch(new CreateMarketPostDemand(body));
    this.isLoading = true;
  }
}
