import { Actions, ofType } from '@ngrx/effects';
import { MatchingPoolItem } from '../core/store/models/matching.model';
import {
  LoadActiveMatchingPoolItems,
  CreateMatchReply,
  DisableMatching,
  DeleteMatchingPoolOption,
  LoadNextActiveMatchingPoolItemsPage
} from '../core/store/actions/matching.actions';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../core/store/store.model';
import * as MatchingTypes from '../core/store/types/matching.types';
import { ToastService } from '../services/toast.service';
import { extract } from '../services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
})
export class MatchingComponent implements OnDestroy {
  title = extract('Matching');
  matchCandidates: MatchingPoolItem[];
  isLoadingMatchCandidates = true;
  isLoading: boolean;
  disabledMatch: string;
  nextPage: string;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadActiveMatchingPoolItems());
    // Get all active matchpool items
    this.store
      .select(state => state.matching)
      .pipe(untilDestroyed(this))
      .subscribe(matchingState => {
        if (
          matchingState &&
          matchingState['matching'] &&
          matchingState['matching'].activeMatchingPoolItems
        ) {
          // Add showAll (false) and showMatches (true) boolean to each item
          const temp = matchingState['matching'].activeMatchingPoolItems;
          this.nextPage = matchingState['matching'].next || null;
          temp.forEach(mc => {
            mc['showAll'] = false;
            mc['showMatches'] = true;
            mc['visible'] = true;
            mc.matchOptions.map(mo => (mo.visible = true));
          });
          this.matchCandidates = [...temp];
          this.isLoadingMatchCandidates = false;
          this.toastr.clear();
          if (this.disabledMatch) {
            this.disabledMatch = null;
          }
        }
      });

    this.updates$
      .pipe(ofType(MatchingTypes.matching.CREATE_MATCH_REPLY_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Reply sent!')
        });
      });

    this.updates$
      .pipe(ofType(MatchingTypes.matching.CREATE_MATCH_REPLY_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to send reply')
        });
      });

    this.updates$
      .pipe(ofType(MatchingTypes.matching.DISABLE_MATCHING_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Removed match from list!')
        });
        // this.store.dispatch(new LoadActiveMatchingPoolItems());
      });
    this.updates$
      .pipe(ofType(MatchingTypes.matching.DISABLE_MATCHING_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          message: extract('Faild to remove match from list.')
        });
        this.disabledMatch = null;
      });

    this.updates$
      .pipe(ofType(MatchingTypes.matching.DELETE_MATCHING_POOL_OPTION_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Removed option from list!')
        });
        // this.store.dispatch(new LoadActiveMatchingPoolItems());
      });
    this.updates$
      .pipe(ofType(
        MatchingTypes.matching.DELETE_MATCHING_POOL_OPTION_FAILED,
        MatchingTypes.matching.DISABLE_MATCHING_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          message: extract('Failed to remove item from list!')
        });
      });
  }

  /**
   * Sends a reply to the owner of the match option
   * @param reply
   */
  handleSendReply(reply: { matchingPoolItemMatch: string; text: string }) {
    this.isLoading = true;
    this.store.dispatch(new CreateMatchReply(reply));
  }

  /**
   * removes the matching pool item from the list
   * @param matchItemId
   */
  handleRemoveMatchItem(event: { matchItemId: string; matchType: string }) {
    this.store.dispatch(
      new DisableMatching({
        type: event.matchType,
        id: event.matchItemId
      })
    );
  }

  /**
   * removes the match option from the options list
   * @param matchItemId
   * @param matchOptionId
   */
  handleRemoveMatchOption(matchInfo: {
    matchItemId: string;
    matchOptionId: string;
  }) {
    // Delete match option
    this.store.dispatch(
      new DeleteMatchingPoolOption({
        matchId: matchInfo.matchItemId,
        matchOptionId: matchInfo.matchOptionId
      })
    );
  }

  onScrollDown() {
    if (!this.nextPage) {
      return;
    }
    this.toastr.showInfoLoading({
      message: extract('Loading extra matches...')
    });
    this.store.dispatch(
      new LoadNextActiveMatchingPoolItemsPage({ page: this.nextPage })
    );
  }

  ngOnDestroy() { }
}
