import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class SamlService {
  private samlIdp: string;
  private automaticRedirect = false;

  constructor(
    private environment: AppConfig,
    private router: Router
  ) {
    const samlObject = environment.getConfig('saml2');
    if (!samlObject) {
      return;
    }

    this.samlIdp = samlObject['identityProviderEntityId'];
    this.automaticRedirect = samlObject['automaticRedirect'];
  }

  isSamlEnabled(): boolean {
    return this.samlIdp != null;
  }

  isSamlAutomaticRedirectEnabled(): boolean {
    return this.isSamlEnabled() && this.automaticRedirect;
  }

  performLoginSAML2SSO() {
    // Get redirect url convoluted because angular limitations
    const currentAbsoluteUrl = window.location.href;
    const currentRelativeUrl = this.router.url;
    const index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    let targetBaseUrl = currentAbsoluteUrl.substring(0, index);
    let redirectUrl = targetBaseUrl + '/user/tenants';

    let oauthClientId = this.environment.getConfig('clientId');
    const idp = this.samlIdp;

    targetBaseUrl = window.btoa(targetBaseUrl);
    redirectUrl = window.btoa(redirectUrl);
    oauthClientId = window.btoa(oauthClientId);

    const url = `${this.environment.getConfig(
      'apiUrl'
    )}/saml/login_redirect/${targetBaseUrl}/${oauthClientId}/${redirectUrl}?idp=${idp}`;
    return url;
  }

  performLogoutSAML2SSO() {
    // Get redirect url convoluted because angular limitations
    const currentAbsoluteUrl = window.location.href;
    const currentRelativeUrl = this.router.url;
    const index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    const targetBaseUrl = currentAbsoluteUrl.substring(0, index);
    let redirectUrl = targetBaseUrl + '/login';

    const idp = window.btoa(this.samlIdp);
    redirectUrl = window.btoa(redirectUrl);

    const url = `${this.environment.getConfig(
      'apiUrl'
    )}/saml/logout/${idp}/${redirectUrl}`;
    return url;
  }
}
