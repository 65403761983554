import { Router } from '@angular/router';
import { LoadSubscriptionTypes } from '../../store/actions/subscriptions.actions';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/store.model';
import { SubscriptionType } from '../../store/models/subscriptions.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-subscription-types',
  templateUrl: './manage-subscription-types.component.html',
})
export class ManageSubscriptionTypesComponent implements OnInit, OnDestroy {
  subscriptionTypes: SubscriptionType[];
  totalItems: number;
  isLoading: boolean;

  constructor(private store: Store<State>, private router: Router) { }

  ngOnInit() {
    this.store.dispatch(new LoadSubscriptionTypes());
    this.isLoading = true;
    this.store
      .select(state => state.subscriptionTypes)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptionTypes => {
        if (subscriptionTypes) {
          this.subscriptionTypes = subscriptionTypes;
          this.totalItems = this.subscriptionTypes.length || 0;
          this.isLoading = false;
        }
      });
  }

  ngOnDestroy() { }

  edit(id) {
    this.router.navigateByUrl(`superadmin/subscriptionTypes/${id}/edit`);
  }

  goToNewSubscriptionType() {
    this.router.navigateByUrl(`superadmin/subscriptionTypes/new`);
  }
}
