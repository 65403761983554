import { LoadPurchaseOrders, UpdatePurchaseOrder } from 'app/core/store/actions/purchase-order.actions';
import { PurchaseOrder } from 'app/core/store/models/payment-note.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Supplier } from 'app/core/store/models/supplier.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';
import { LoadSuppliers } from 'app/core/store/actions/supplier.actions';
import { LoadTaxRates } from 'app/core/store/actions/tax-rate.actions';
import * as PurchaseOrderTypes from 'app/core/store/types/purchase-order.types';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import * as TaxRateTypes from 'app/core/store/types/tax-rate.types';
import { extract } from 'app/services/i18n.service';
import { TaxRate } from 'app/core/store/models/tax-rate.model';
import { DownloadService } from 'app/services/download.service';
import {
  LoadPurchaseOrderableTransports,
  LoadNextPurchaseOrderableTransportsPage
} from 'app/core/store/actions/transports.actions';
import { ApiService } from 'app/core/api/api.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-purchase-order',
  templateUrl: './edit-purchase-order.component.html',
})
export class EditPurchaseOrderComponent implements OnInit, OnDestroy {
  purchaseOrder: PurchaseOrder;
  id: string;
  suppliers: Supplier[];
  isLoadingSuppliers: boolean;
  taxRates: TaxRate[];
  isLoadingTaxRates: boolean;
  isLoading: boolean;
  query: string;
  since?: string;
  until?: string;
  selectedSupplierId: string;
  filteredTransports: any[];
  transports: any;
  selectedTransports: string[] = [];
  downloadPDFWhenReady: boolean;
  nextPage: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private updates$: Actions,
    private store: Store<State>,
    private downloadService: DownloadService,
    private toastr: ToastService,
    private api: ApiService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.getPurchaseOrderById(this.id);
      }
    });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.purchaseOrderableTransports &&
          state.crm.purchaseOrderableTransports.purchaseOrderableTransports
        ) {
          return state.crm.purchaseOrderableTransports
            .purchaseOrderableTransports;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(purchaseOrderableTransports => {
        if (!purchaseOrderableTransports) {
          return;
        }
        this.transports = [...purchaseOrderableTransports];

        // Filter out selected transports so they can't be selected twice
        if (this.selectedTransports.length > 0) {
          this.selectedTransports.forEach(transportId => {
            this.filteredTransports = this.transports.filter(
              t => t.id !== transportId
            );
          });
        } else {
          this.filteredTransports = [...purchaseOrderableTransports];
        }
        this.toastr.clear();
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.purchaseOrderableTransports &&
          state.crm.purchaseOrderableTransports
            .nextPurchaseOrderableTransportPage
        ) {
          return state.crm.purchaseOrderableTransports
            .nextPurchaseOrderableTransportPage;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(nextPage => {
        this.nextPage = nextPage;
      });
    this.store
      .select(state => state.crm.suppliers)
      .pipe(untilDestroyed(this))
      .subscribe(suppliersState => {
        if (!suppliersState || !suppliersState.suppliers) {
          this.store.dispatch(new LoadSuppliers());
          this.isLoadingSuppliers = true;
          return;
        }
        this.suppliers = suppliersState.suppliers;
        this.isLoadingSuppliers = false;
      });
    this.store
      .select(state => state.crm.taxRates)
      .pipe(untilDestroyed(this))
      .subscribe(taxRatesState => {
        if (!taxRatesState || !taxRatesState.taxRates) {
          this.store.dispatch(new LoadTaxRates());
          this.isLoadingTaxRates = true;
          return;
        }
        this.taxRates = taxRatesState.taxRates;
        this.isLoadingTaxRates = false;
      });
    this.updates$
      .pipe(ofType(PurchaseOrderTypes.purchaseOrder.UPDATE_PURCHASE_ORDER_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update purchase order!')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(PurchaseOrderTypes.purchaseOrder.UPDATE_PURCHASE_ORDER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.store.dispatch(new LoadPurchaseOrders());
        this.toastr.showSuccess({
          message: extract('Purchase order updated successfully!')
        });
        if (this.downloadPDFWhenReady) {
          this.downloadService.downloadPDF(
            `/crm/purchase_order/download/${response['payload'].id}`,
            response['payload'].number,
          ).then(() => {
            this.router.navigateByUrl('/crm/purchase-orders');
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          });
        } else {
          this.router.navigateByUrl('/crm/purchase-orders');
          this.isLoading = false;
        }
      });
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.LOAD_SUPPLIERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load suppliers')
        });
        this.isLoadingSuppliers = false;
      });
    this.updates$
      .pipe(ofType(TaxRateTypes.taxRate.LOAD_TAX_RATES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load tax rates')
        });
        this.isLoadingTaxRates = false;
      });
  }

  ngOnInit() {
    this.store.dispatch(new LoadPurchaseOrderableTransports());
  }
  getPurchaseOrderById(purchaseOrderId: string) {
    this.api
      .get({ path: `/crm/purchase_orders/${purchaseOrderId}` })
      .pipe(take(1))
      .subscribe((res: PurchaseOrder) => {
        this.purchaseOrder = res;

        // Add transport items to selected transports
        this.selectedTransports = this.purchaseOrder.items
          .filter(item => item.transport)
          .map(item => item.transport['id']);

        if (this.transports && this.selectedTransports.length > 0) {
          this.selectedTransports.forEach(transportId => {
            this.filteredTransports = this.transports.filter(
              t => t.id !== transportId
            );
          });
        }

        this.selectedSupplierId = this.purchaseOrder['business']['id'];

        this.store.dispatch(
          new LoadPurchaseOrderableTransports({
            suppliers: this.selectedSupplierId
          })
        );
      });
  }
  updatePurchaseOrder(purchaseOrderFormValue) {
    this.store.dispatch(
      new UpdatePurchaseOrder({
        purchaseOrderId: this.id,
        body: purchaseOrderFormValue
      })
    );
    this.isLoading = true;
  }

  handleSearchTransports(payload: { query?: string, since?: string, until?: string }) {
    this.query = payload.query;
    this.since = payload.since;
    this.until = payload.until;
    this.filterTransports(this.selectedSupplierId, this.query, this.since, this.until);
  }

  handleSelectSupplier(supplierId: string | null) {
    // Clear selected transports if supplier is deselected
    if (!supplierId) {
      this.selectedTransports.forEach(transportId =>
        this.handleRemoveTransport(transportId)
      );
      this.selectedTransports = [];
    }
    this.selectedSupplierId = supplierId;
    this.filterTransports(this.selectedSupplierId, this.query, this.since, this.until);
  }

  filterTransports(supplierId: string, query: string, since?: string, until?: string) {
    this.store.dispatch(
      new LoadPurchaseOrderableTransports({
        query: query,
        since: since,
        until: until,
        suppliers: supplierId
      })
    );
  }

  handleSelectTransport(transportId: string) {
    this.selectedTransports.push(transportId);
    this.filteredTransports = this.filteredTransports.filter(t => t.id !== transportId);
  }

  handleRemoveTransport(transportId: string) {
    this.selectedTransports.filter(t => t !== transportId);

    let transport = this.transports.find(t => t.id === transportId);

    // if transport is not found it can be already purchase ordered
    if (!transport) {
      const item = this.purchaseOrder.items
        .filter(i => i.transport)
        .find(i => i.transport['id'] === transportId);
      transport = item ? item.transport : null;
    }
    // Only insert transport if it is not already present
    if (transport && !this.filteredTransports.find(t => t.id === transportId)) {
      this.filteredTransports.unshift(transport);
    }
  }

  updateAndDownloadAsPDF(purchaseOrderFormValue) {
    this.updatePurchaseOrder(purchaseOrderFormValue);
    this.downloadPDFWhenReady = true;
  }

  loadNextTransportsPage() {
    this.store.dispatch(
      new LoadNextPurchaseOrderableTransportsPage({
        page: this.nextPage,
        query: this.query,
        since: this.since,
        until: this.until,
        suppliers: this.selectedSupplierId
      })
    );
    this.toastr.showInfoLoading({
      message: extract('Loading extra transports...')
    });
  }

  ngOnDestroy() { }
}
