import { Action } from '@ngrx/store';
import * as types from '../types/product.types';
import { Product } from '../models/product.model';

export class LoadProducts implements Action {
  readonly type = types.product.LOAD_PRODUCTS;
  constructor(public payload?: any) {}
}

export class LoadProductsFail implements Action {
  readonly type = types.product.LOAD_PRODUCTS_FAILED;
  constructor(public payload: any) {}
}

export class LoadProductsSuccess implements Action {
  readonly type = types.product.LOAD_PRODUCTS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadFilteredProducts implements Action {
  readonly type = types.product.LOAD_FILTERED_PRODUCTS;
  constructor(public payload?: any) {}
}

export class LoadFilteredProductsFail implements Action {
  readonly type = types.product.LOAD_FILTERED_PRODUCTS_FAILED;
  constructor(public payload: any) {}
}

export class LoadFilteredProductsSuccess implements Action {
  readonly type = types.product.LOAD_FILTERED_PRODUCTS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class CreateProduct implements Action {
  readonly type = types.product.CREATE_PRODUCT;
  constructor(public payload?: any) {}
}

export class CreateProductFail implements Action {
  readonly type = types.product.CREATE_PRODUCT_FAILED;
  constructor(public payload: any) {}
}

export class CreateProductSuccess implements Action {
  readonly type = types.product.CREATE_PRODUCT_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteProduct implements Action {
  readonly type = types.product.DELETE_PRODUCT;
  constructor(public payload: { productId: string }) {}
}

export class DeleteProductFail implements Action {
  readonly type = types.product.DELETE_PRODUCT_FAILED;
  constructor(public payload: any) {}
}

export class DeleteProductSuccess implements Action {
  readonly type = types.product.DELETE_PRODUCT_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateProduct implements Action {
  readonly type = types.product.UPDATE_PRODUCT;
  constructor(
    public payload: {
      productId: string;
      body: Product;
    }
  ) {}
}

export class UpdateProductFail implements Action {
  readonly type = types.product.UPDATE_PRODUCT_FAILED;
  constructor(public payload: any) {}
}

export class UpdateProductSuccess implements Action {
  readonly type = types.product.UPDATE_PRODUCT_SUCCEEDED;
  constructor(public payload: any) {}
}

export class ClearProducts implements Action {
  readonly type = types.product.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadProducts
  | LoadProductsFail
  | LoadProductsSuccess
  | LoadFilteredProducts
  | LoadFilteredProductsFail
  | LoadFilteredProductsSuccess
  | CreateProduct
  | CreateProductSuccess
  | CreateProductFail
  | DeleteProduct
  | DeleteProductSuccess
  | DeleteProductFail
  | UpdateProduct
  | UpdateProductSuccess
  | UpdateProductFail
  | ClearProducts;
