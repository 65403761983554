<div id="message-board" class="row" [ngClass]="{'ml-1': url !== 'marketplace', 'mt-3': url !== 'marketplace', 'mr-1': url !== 'marketplace'}">
  <div class="col">
    <div class="card">
      <h4 class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <app-message-board-messages-subscription-toggle [messageBoardMessageSubscription]="messageBoardMessageSubscription"></app-message-board-messages-subscription-toggle>
          <span class="ml-1 mr-1">
            {{ 'Message Board' | translate }}
          </span>
          <a routerLink="{{url === 'marketplace' ? '/marketplace/chat' : '/marketplace'}}">
            <i class="far" [ngClass]="url === 'marketplace' ?  'fa-expand-alt' : 'fa-compress-alt'"></i>
          </a>
        </div>
      </h4>
      <div class="card-body pre-scrollable">
        <app-cmp-message-input [loading]="loading" (messageSubmitted)="onSubmit($event)"></app-cmp-message-input>
        <div class="mt-3" *ngFor="let message of (messages$ | async)">
          <app-cmp-message [message]="message" (replySubmited)="submitReply($event)" (replying)="setReplying($event)"></app-cmp-message>
        </div>
      </div>
    </div>
  </div>
</div>
