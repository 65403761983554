import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { ApiService } from 'app/core/api/api.service';
import { LoadDefaultCertificatePrice } from 'app/core/store/actions/certiweight.actions';
import { State } from 'app/core/store/store.model';
import { DownloadService } from 'app/services/download.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-certificate-detail',
  templateUrl: './certificate-detail.component.html',
})
export class CertificateDetailComponent implements OnDestroy {
  data: any;
  certificate: any;
  id: string;
  defaultPrice: number;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private downloadService: DownloadService,
    private toastr: ToastService,
    private store: Store<State>,
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api.get({
          path: '/certificates/' + this.id
        }).toPromise().then((x: any) => {
          this.certificate = x;
          this.data = x.containerMeasurement;
        });
      }
    });

    this.store
      .select('certiweight')
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.defaultPrice) {
          this.store.dispatch(new LoadDefaultCertificatePrice());
          return;
        }
        this.defaultPrice = result.defaultPrice;
      });
  }

  download() {
    this.downloadService.downloadPDF(
      `/certificate/${this.id}/download`
    );
  }

  sendEmail() {
    this.api.post({
      path: `/certificate/${this.id}/send_email`
    }).toPromise().then(
      () => this.toastr.showSuccess({
        message: extract('Successfully sent e-mail'),
      }),
      () => this.toastr.showDanger({
        title: extract('Something went wrong'),
        message: extract('Failed to send e-mail'),
      })
    );
  }

  ngOnDestroy(): void { }
}
