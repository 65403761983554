<form (ngSubmit)="onSubmit()" [formGroup]="subscriptionTypeForm" class="pb-3">
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Name' | translate }}</label>
        <input
          class="form-control"
          formControlName="name"
          [ngClass]="{
            'is-invalid':
              subscriptionTypeForm.controls['name'].invalid &&
              subscriptionTypeForm.controls['name'].touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Name is required.' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Code' | translate }}</label>
        <input
          type="text"
          class="form-control"
          formControlName="code"
          [ngClass]="{
            'is-invalid':
              subscriptionTypeForm.controls['code'].invalid &&
              subscriptionTypeForm.controls['code'].touched
          }"
        />
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Payment Type' | translate }}</label>
        <ng-select
          id="paymentType"
          [items]="paymentTypes"
          bindLabel="name"
          bindValue="name"
          placeholder="{{ 'Select payment type' | translate }}"
          formControlName="paymentType"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Credits' | translate }}</label>
        <input
          type="number"
          class="form-control"
          formControlName="credits"
          [ngClass]="{
            'is-invalid':
              subscriptionTypeForm.controls['credits'].invalid &&
              subscriptionTypeForm.controls['credits'].touched
          }"
        />
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <label class="center-block">{{ 'Actions' | translate }}</label>
      <div
        formArrayName="subscriptionTypeActions"
        class="mb-3"
        *ngFor="
          let action of subscriptionTypeForm.get('subscriptionTypeActions')[
            'controls'
          ];
          let i = index
        "
      >
        <div [formGroupName]="i" class="form-row">
          <div class="col-6">
            <input
              class="form-control"
              formControlName="name"
              disabled="true"
              placeholder="action name"
            />
          </div>
          <div class="col-6">
            <input
              class="form-control"
              type="number"
              formControlName="credits"
              placeholder="credits"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            formControlName="displayOnWebsite"
          />
          <span data-cy="display-on-website" class="custom-control-label">{{
            'Display on website' | translate
          }}</span>
        </label>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{ 'Website icon' | translate }}</label>
        <input
          type="text"
          class="form-control"
          formControlName="websiteIcon"
          placeholder="{{ 'Website icon' }}"
        />
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <div class="form-group">
        <label class="center-block">{{
          'Website description' | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          formControlName="websiteDescription"
          [ngClass]="{
            'is-invalid':
              subscriptionTypeForm.controls['websiteDescription'].invalid &&
              subscriptionTypeForm.controls['websiteDescription'].touched
          }"
        />
      </div>
    </div>
  </div>
  <button
    class="btn btn-primary text-white"
    [disabled]="
      isLoading || subscriptionTypeForm.invalid || subscriptionTypeForm.pristine
    "
  >
    <span translate class="btn__label" [ngClass]="{ invisible: isLoading }">{{
      subscriptionType ? 'Save' : ('Create Subscription Type' | translate)
    }}</span>
    <i
      class="fa fa-spinner fa-spin btn__loader"
      [ngClass]="{ visible: isLoading }"
    ></i>
  </button>
</form>
