<div class="card">
  <div class="card-header">
    <h3>
      {{ 'Are you sure you want to approve this registration request?' | translate }}
    </h3>
  </div>
  <div class="card-body">
    <p>{{ 'A new tenant with one user and user context will be created and e-mails will be sent out.' | translate }}</p>
    <div class="d-flex justify-content-between">
      <button class="btn btn-default" (click)="activeModal.close()">{{ 'No' | translate }}</button>
      <button class="btn btn-danger text-white" (click)="activeModal.close(true)">{{ 'Yes' | translate }}</button>
    </div>
  </div>
</div>
