import * as UserContextsActions from '../actions/user-contexts.actions';
import { UserContext } from '../models/user-contexts.model';
import { User } from '../models/user.model';
import * as types from '../types/user-context.types';

export interface UserContextState {
  data: UserContext[];
  totalElements: number;
  page: number;
  nextPage: string | null;
  previousPage: string | null;
  lastPage: string | null;
}

const INITIAL_STATE = {
  data: null,
  totalElements: 0,
  page: 1,
  nextPage: null,
  previousPage: null,
  lastPage: null
};
const INITIAL_DRIVERS_STATE = {
  data: null,
  totalElements: 0,
  page: 1,
  nextPage: null,
  previousPage: null,
  lastPage: null
};

export function reducer(state = INITIAL_STATE, action: UserContextsActions.All): UserContextState {
  switch (action.type) {
    case types.userContexts.LOAD_SUCCEEDED:
      return {
        data: [...action.payload['hydra:member']],
        page: action.payload['hydra:view']
          ? parseInt(
            action.payload['hydra:view']['@id'].slice(
              action.payload['hydra:view']['@id'].indexOf('=') + 1,
              action.payload['hydra:view']['@id'].length
            ),
            10
          )
          : 1,
        totalElements: action.payload['hydra:totalItems'],
        nextPage:
          action.payload['hydra:view'] &&
            action.payload['hydra:view']['hydra:next']
            ? action.payload['hydra:view']['hydra:next'].slice(
              action.payload['hydra:view']['hydra:next'].indexOf('=') + 1,
              action.payload['hydra:view']['hydra:next'].length
            )
            : null,
        previousPage:
          action.payload['hydra:view'] &&
            action.payload['hydra:view']['hydra:previous']
            ? action.payload['hydra:view']['hydra:previous'].slice(
              action.payload['hydra:view']['hydra:previous'].indexOf('=') + 1,
              action.payload['hydra:view']['hydra:previous'].length
            )
            : null,
        lastPage:
          action.payload['hydra:view'] &&
            action.payload['hydra:view']['hydra:last']
            ? action.payload['hydra:view']['hydra:last'].slice(
              action.payload['hydra:view']['hydra:last'].indexOf('=') + 1,
              action.payload['hydra:view']['hydra:last'].length
            )
            : null
      };
    case types.userContexts.CREATE_SUCCEEDED:
      state.data = state.data
        ? [...state.data, action.payload]
        : [action.payload];
      state.totalElements = state.totalElements ? state.totalElements + 1 : 1;
      return Object.assign({}, state);
    case types.userContexts.UPDATE_SUCCEEDED:
      if (!state.data) {
        return state;
      }
      state.data = state.data.map(item => {
        return item.id === action.payload.id
          ? Object.assign({}, item, action.payload)
          : item;
      });
      return Object.assign({}, state);
    case types.userContexts.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return state;
  }
  return state;
}

export function SingleUserContextReducer(state = null, action: UserContextsActions.All): UserContext {
  switch (action.type) {
    case types.userContexts.LOAD_SINGLE_SUCCEEDED:
      return action.payload;
  }
  return state;
}

export function DriversReducer(state = INITIAL_DRIVERS_STATE, action: UserContextsActions.All) {
  switch (action.type) {
    case types.userContexts.LOAD_DRIVERS_SUCCEEDED:
      return [...action.payload];
  }
  return state;
}
