import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from '../auth/route-guards/admin.guard';
import { MultipleTenantGuard } from '../auth/route-guards/multiple-tenant.guard';
import { TenantPickerComponent } from '../tenant/tenant-picker.component';
import { AuthGuard } from './user.authguard';

@NgModule({
  imports: [RouterModule.forRoot([
    {
      path: 'user/tenants',
      component: TenantPickerComponent,
      canActivate: [AuthGuard, MultipleTenantGuard]
    }
  ], { initialNavigation: false })],
  exports: [],
  providers: []
})
export class UserRoutingModule { }
