<div class="card p-3">
  <app-card-spinner *ngIf="contact && contact['isLoading']"></app-card-spinner>
  <div class="d-flex justify-content-between">
    <span>
      <span class="mr-1">{{ contact?.salutation }}</span>
      <span class="mr-1">{{ contact?.firstName }}</span>
      <span>{{ contact?.lastName }}</span>
    </span>
    <span>
      <span
        *ngIf="contact"
        class="edit-icon"
        (click)="launchEditContactModal()"
      >
        <i class="far fa-fw fa-pencil"></i>
      </span>
      &nbsp;
      <span
        *ngIf="contact && !mainContact"
        class="edit-icon"
        (click)="handleDeleteContact()"
      >
        <i class="far fa-fw fa-trash-alt"></i>
      </span>
    </span>
  </div>
  <div>{{ contact?.email }}</div>
  <div>
    <span class="mr-1">{{ contact?.telephone }}</span>
    <span>{{ contact?.designation }}</span>
  </div>
  <div>{{ contact?.department }}</div>
  <div class="right" *ngIf="mainContact">
    <i class="bg-success text-white far fa-check fa-fw mr-1"></i>
    <span>{{ 'Main contact' | translate }}</span>
  </div>
  <div
    class="right update-main-contact"
    *ngIf="!mainContact"
    (click)="setAsMainContact()"
  >
    <i class="far fa-check"></i>
    <span>{{ 'Set as main contact' | translate }}</span>
  </div>
</div>
