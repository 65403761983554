var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LoadMessages } from '../actions/messages.actions';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, Subject, interval, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as types from '../types/update.types';
import * as UpdateActions from '../actions/update.actions';
import * as moment from 'moment';
import { ApiService } from '../../api/api.service';
import { REFRESH_RATE_MS } from '../../../constants';
var UpdateEffects = /** @class */ (function () {
    function UpdateEffects(api, actions$, store) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.store = store;
        this.destroy$ = new Subject();
        this.loadUpdates$ = this.actions$.pipe(ofType(types.updates.LOAD_UPDATES), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var requestPayload = payload ? payload : {};
            var now = moment()
                .subtract(2, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss');
            var timestamp = '';
            _this.store
                .select(function (state) { return state.updates; })
                .subscribe(function (data) { return (timestamp = data ? data : now); });
            // Default timestamp to now
            if (!requestPayload.timestamp) {
                requestPayload.timestamp = encodeURIComponent(timestamp);
            }
            return _this.api
                .get({ path: "/status/" + requestPayload.timestamp })
                .pipe(map(function (data) {
                /*
                * Loop over different possible updated entities
                * and mark them outdated or reload them.
                */
                if (data['message_board_message']) {
                    _this.store.dispatch(new LoadMessages());
                }
                return new UpdateActions.LoadUpdatesSuccess(now);
            }), catchError(function (error) { return of(new UpdateActions.LoadUpdatesFail(error)); }));
        }));
        // Call load updates effect on interval
        this.startLoadUpdates$ = this.actions$.pipe(ofType(types.updates.START_LOAD_UPDATES), switchMap(function () {
            _this.destroy$ = new Subject();
            return interval(REFRESH_RATE_MS)
                .pipe(takeUntil(_this.destroy$))
                .pipe(map(function () { return _this.store.dispatch(new UpdateActions.LoadUpdates()); }));
        }));
        // Stop load updates
        this.stopLoadUpdates$ = this.actions$.pipe(ofType(types.updates.STOP_LOAD_UPDATES), map(function () {
            _this.destroy$.next(true);
            _this.destroy$.complete();
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UpdateEffects.prototype, "loadUpdates$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UpdateEffects.prototype, "startLoadUpdates$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UpdateEffects.prototype, "stopLoadUpdates$", void 0);
    return UpdateEffects;
}());
export { UpdateEffects };
