import { Component } from '@angular/core';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
})
export class SuperAdminComponent {
  tenantsRoutes = [
    {
      url: '/superadmin/tenants/create',
      title: 'Create Tenant',
      description: 'Create a new tenant.'
    },
    {
      url: '/superadmin/tenants',
      title: 'Manage Tenants',
      description: 'Manage all tenants.'
    },
    {
      url: '/superadmin/registration-requests',
      title: 'Manage Registration requests',
      description: 'Manage all Registration requests.'
    },
    {
      url: '/superadmin/psa-accounts/create',
      title: 'Create a PSA account',
      description: 'Create a new PSA account.'
    },
    {
      url: '/superadmin/psa-accounts',
      title: 'Manage PSA account links',
      description: 'Manage all PSA account links.'
    }
  ];
  locationsRoutes = [
    {
      url: '/superadmin/locations',
      title: 'Manage Locations',
      description: 'Manage all locations.'
    },
    {
      url: '/superadmin/locations/new',
      title: 'New Location',
      description: 'Create a new location.'
    }
  ];
  containerTypesRoutes = [
    {
      url: '/superadmin/containerTypes',
      title: 'Manage Container Types',
      description: 'Manage all container types.'
    },
    {
      url: '/superadmin/containerTypes/new',
      title: 'New Container Type',
      description: 'Create a new container type.'
    }
  ];
  linersRoutes = [
    {
      url: '/superadmin/liners',
      title: 'Manage Liners',
      description: 'Manage all liners.'
    },
    {
      url: '/superadmin/liners/new',
      title: 'New Liner',
      description: 'Create a new liner.'
    },
    {
      url: '/superadmin/shipping-agents',
      title: 'Manage Shipping Agents',
      description: 'Manage all Shipping Agents.'
    },
    {
      url: '/superadmin/shipping-agents/new',
      title: 'New Shipping Agent',
      description: 'Create a new Shipping Agent.'
    }
  ];
  walletsRoutes = [
    {
      url: '/superadmin/wallets',
      title: 'Manage Wallets',
      description: 'Manage all wallets.'
    }
  ];
  betaUsersRoutes = [
    {
      url: '/superadmin/beta-users',
      title: 'Manage Beta Users',
      description: 'Manage all beta users.'
    }
  ];
  subscriptionRoutes = [
    {
      url: '/superadmin/subscriptions',
      title: 'Manage Subscriptions',
      description: 'Manage all subscriptions.'
    }
  ];
  certiweightRoutes = [
    {
      url: '/superadmin/certiweight',
      title: 'Manage Certiweight measurements',
      description: 'Manage all Certiweight measurements.'
    },
    {
      url: '/superadmin/certiweight/new',
      title: 'New Certiweight measurement',
      description: 'Create a new Certiweight measurement.'
    },
  ];
}
