import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiService } from 'app/core/api/api.service';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { State } from 'app/core/store/store.model';
import { Liner, LinerReloadContainerService, LinerReloadLocation, PriceTypes } from 'app/core/store/models/liner.model';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-liner-form',
  templateUrl: './liner-form.component.html',
  styleUrls: ['./liner-form.component.scss']
})
export class LinerFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() liner: Liner;
  @Output() linerFormSubmitted = new EventEmitter<Location>();

  linerForm: FormGroup;
  loading = false;
  countries: { value: string; label: string }[];
  reloadServices: LinerReloadContainerService[] = [];
  priceTypes: { value: PriceTypes; label: string}[] = [
    {value: PriceTypes.FIXED, label: "Fixed price"},
    {value: PriceTypes.DISTANCE_BASED, label: "Distance based price"}
  ];

  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
    private api: ApiService,
  ) {
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countries => {
        if (!countries) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countries];
      });
    this.api.get({ path: '/liner_reload_container_services' }).subscribe((data: any) => {
      let reloadServices = [];
      data['hydra:member'].forEach((service: LinerReloadContainerService) => {
        reloadServices.push(service);
      });
      this.reloadServices = reloadServices;
    });
  }

  ngOnInit() {
    if (!this.linerForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.liner && changes.liner.currentValue) {
      if (!this.linerForm) {
        this.createForm();
      }
      const liner = changes.liner.currentValue;
      this.linerForm.patchValue({
        name: changes.liner.currentValue.name,
        shortName: changes.liner.currentValue.shortName,
        reload: changes.liner.currentValue.reload,
        leadingReloadDesk: changes.liner.currentValue.leadingReloadDesk,
      });

      if (liner.aliases && liner.aliases.length > 0) {
        this.removeAlias(0);
        liner.aliases.forEach(() => {
          this.addAlias();
        });
        this.linerForm.patchValue({
          aliases: liner.aliases
        });
      }

      if (liner.reloadLocations && liner.reloadLocations.length > 0) {
        this.removeReloadLocation(0);
        liner.reloadLocations.forEach((reloadLocation: LinerReloadLocation, index: number) => {
          this.addReloadLocation();
          if (reloadLocation.distanceBasedPrice && reloadLocation.distanceBasedPrice.length > 0) {
            reloadLocation.distanceBasedPrice.forEach(() => {
              this.addPriceItem(index);
            });
          }
        });
        this.linerForm.patchValue({
          reloadLocations: liner.reloadLocations
        });
      }
    }
  }

  createAlias(): FormGroup {
    return this.fb.group({
      code: null,
      label: null
    });
  }

  addAlias(): void {
    const aliases = <FormArray>this.linerForm.controls['aliases'];
    aliases.push(this.createAlias());
  }


  removeAlias(index: number): void {
    const aliases = <FormArray>this.linerForm.controls['aliases'];
    aliases.removeAt(index);
  }

  createForm() {
    this.linerForm = this.fb.group({
      name: [],
      shortName: [],
      reload: [null],
      leadingReloadDesk: [null],
      reloadLocations: this.fb.array([this.createReloadLocation()]),
      aliases: this.fb.array([this.createAlias()])
    });
  }

  createReloadLocation(): FormGroup {
    return this.fb.group({
      country: [null],
      type: [null],
      reloadService: [null],
      priceType: [null],
      fixedPrice: [null],
      distanceBasedPrice: this.fb.array([this.createPriceItem()])
    });
  }

  createPriceItem(): FormGroup {
    const group = this.fb.group({
      from: [null],
      until: [null],
      price: [null]
    });
    return group;
  }

  addReloadLocation(): void {
    const locations = <FormArray>this.linerForm.controls['reloadLocations'];
    locations.push(this.createReloadLocation());
  }

  removeReloadLocation(index: number): void {
    const locations = <FormArray>this.linerForm.controls['reloadLocations'];
    locations.removeAt(index);
    this.linerForm.markAsDirty();
  }

  addPriceItem(i: number): void {
    const reloadLocations = <FormArray>this.linerForm.get('reloadLocations');
    const reloadLocation = reloadLocations.controls[i];
    const distanceBasedPrice = <FormArray>reloadLocation.get('distanceBasedPrice');
    distanceBasedPrice.push(this.createPriceItem());
  }

  removePriceItem(i: number, index: number): void {
    const reloadLocations = <FormArray>this.linerForm.get('reloadLocations');
    const reloadLocation = reloadLocations.controls[i];
    const distanceBasedPrice = <FormArray>reloadLocation.get('distanceBasedPrice');
    distanceBasedPrice.removeAt(index);
  }

  onSubmit() {
    const value = this.linerForm.value;

    // Filter out empty reload locations
    value['reloadLocations'] = value['reloadLocations'].filter(
      (location: LinerReloadLocation) => location.country && location.type
    );

    // Filter out empty aliases
    value['aliases'] = value['aliases'].filter(
        alias => alias.label && alias.code
    );

    value['reloadLocations'].forEach((location) => {
      // convert price to string
      if (location.priceType === PriceTypes.FIXED) {
        if (location.fixedPrice !== null) {
          location.fixedPrice = location.fixedPrice.toString();
        }
        location.distanceBasedPrice = null;
      // normalize distance based price items
      } else if (location.priceType === PriceTypes.DISTANCE_BASED) {
        let distanceBasedPrice = [];
        if (location.distanceBasedPrice !== null) {
          location.distanceBasedPrice.forEach((priceItem) => {
            if (priceItem.from !== null && priceItem.until !== null && priceItem.price !== null) {
              distanceBasedPrice.push(priceItem);
            }
          });
          if (distanceBasedPrice.length === 0) {
            distanceBasedPrice = null;
          }
          location.distanceBasedPrice = distanceBasedPrice;
        }
        location.fixedPrice = null;
      } else {
        location.fixedPrice = null;
        location.distanceBasedPrice = null;
      }

      // only send ID of reload service
      if (location.reloadService) {
        location.reloadService = location.reloadService['@id'];
      }
    });

    this.linerFormSubmitted.emit(value);
  }

  ngOnDestroy() { }
}
