import { ApiInterceptor } from '../api/api.interceptor';
import { EditCircleComponent } from './circles/edit-circle/edit-circle.component';
import { CircleComponent } from './circles/circle/circle.component';
import { CircleListComponent } from './circles/circle-list/circle-list.component';
import { NewCircleComponent } from './circles/new-circle/new-circle.component';
import { UserRoutingModule } from './user-routing.module';
import { TenantListComponent } from '../tenant/tenant-list/tenant-list.component';
import { CircleEffects } from '../store/effects/circles.effects';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TenantPickerComponent } from '../tenant/tenant-picker.component';
import { TenantEffects } from '../store/effects/tenants.effects';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';

import { AvatarComponent } from './avatar/avatar.component';
import { CirclesComponent } from './circles/circles.component';
import { UserEffects } from '../store/effects/user.effects';
import { SessionEffects } from '../store/effects/session.effects';

@NgModule({
  declarations: [
    AvatarComponent,
    TenantPickerComponent,
    TenantListComponent,
    CirclesComponent,
    CircleComponent,
    NewCircleComponent,
    EditCircleComponent,
    CircleListComponent
  ],
  exports: [AvatarComponent, CirclesComponent],
  imports: [
    SharedModule,
    EffectsModule,
    RouterModule,
    TranslateModule,
    UserRoutingModule,
    FormsModule,
    EffectsModule.forFeature([
      UserEffects,
      TenantEffects,
      CircleEffects,
      SessionEffects
    ])
  ],
  providers: [ApiInterceptor]
})
export class UserModule { }
