import * as DateFns from 'date-fns';
import * as fileSaver from 'file-saver';
import { ApiService } from '../core/api/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../core/api/api.service";
var DownloadService = /** @class */ (function () {
    function DownloadService(api) {
        this.api = api;
    }
    DownloadService.prototype.downloadPDF = function (url, name, locale) {
        if (locale === void 0) { locale = 'en'; }
        return this.api.get({ path: url }, {
            'Content-Type': 'application/json',
            'Accept-Language': locale,
            'Accept': 'application/pdf'
        }, 'blob').toPromise().then(function (data) {
            var blob = new Blob([data], { type: 'application/pdf' });
            name = name || DateFns.format(new Date(), 'DD-MM-YYYY_hh:mm:ss');
            fileSaver.saveAs(blob, name + '.pdf');
        });
    };
    DownloadService.prototype.downloadAttachment = function (name, id) {
        return this.api.get({ path: "/attachments/" + id + "/download" }, { 'Content-Type': 'application/json', Accept: 'application/pdf' }, 'blob').toPromise().then(function (data) {
            var blob = new Blob([data], { type: data['type'] });
            name = name || DateFns.format(new Date(), 'DD-MM-YYYY_hh:mm:ss');
            fileSaver.saveAs(blob, name);
        });
    };
    DownloadService.prototype.downloadXLSX = function (url, name, locale) {
        if (locale === void 0) { locale = 'en'; }
        return this.api.get({ path: url }, { 'Content-Type': 'application/json', 'Accept-Language': locale }, 'blob').toPromise().then(function (data) {
            var blob = new Blob([data], { type: 'text/csv' });
            name = name + ("_" + locale + ".xlsx") || DateFns.format(new Date(), 'DD-MM-YYYY_hh:mm:ss') + ("_" + locale + ".xlsx");
            fileSaver.saveAs(blob, name);
        });
    };
    DownloadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DownloadService_Factory() { return new DownloadService(i0.ɵɵinject(i1.ApiService)); }, token: DownloadService, providedIn: "root" });
    return DownloadService;
}());
export { DownloadService };
