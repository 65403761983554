export const transactions = {
  LOAD_CREDIT_TRANSACTIONS: '[Credit Transactions] Load',
  LOAD_CREDIT_TRANSACTIONS_SUCCEEDED: '[Credit Transactions] Load Success',
  LOAD_CREDIT_TRANSACTIONS_FAILED: '[Credit Transactions] Load Fail',
  LOAD_ALL_TRANSACTIONS: '[All Transactions] Load',
  LOAD_ALL_TRANSACTIONS_SUCCEEDED: '[All Transactions] Load Success',
  LOAD_ALL_TRANSACTIONS_FAILED: '[All Transactions] Load Fail',
  LOAD_TRANSACTION_PAGE: '[Transactions] Load Page',
  LOAD_TRANSACTION_PAGE_SUCCEEDED: '[Transactions] Load Page Success',
  LOAD_TRANSACTION_PAGE_FAILED: '[Transactions] Load Page Fail',
  CLEAR: '[Transactions] Clear'
};
