<div class="card p-3">
  <div>
    <div class="align-self-center">
      <i class="far fa-fw" [ngClass]="getTaskIcon(task)"></i>
      <span>{{
        task.privateLocation.shortName || task.privateLocation.name
      }}</span>
    </div>
    <div *ngIf="task.privateLocation && task.privateLocation.fullAddress">
      {{ task.privateLocation?.fullAddress }}
    </div>
    <div>
      <span
        *ngIf="transport.container?.liner || transport.container?.containerType"
      >
        <span class="mr-3">{{ transport.container?.liner?.shortName }}</span>
        <span class="mr-3">{{
          transport.container?.containerType?.label
        }}</span>
        <span
          *ngIf="transport.container && transport.container.weight"
          class="mr-3"
          >{{ transport.container?.weight }} (T)</span
        >
      </span>
      <div *ngIf="transport.container?.number || true">
        <span>{{ transport.container.number }}</span>
      </div>
    </div>
    <div *ngIf="task.reference">
      <span>{{ task.reference }}</span>
    </div>
    <div>
      <div *ngIf="transport.goods">{{ transport.goods }}</div>
    </div>
    <div class="button-grid">
      <app-button
        class="button__barcode"
        *ngIf="task.visit && task.visit.barCode"
        class="mr-1"
        (buttonClicked)="launchBarcodeModal(task)"
        [btnClass]="'btn'"
        [isLoading]="isGoingForward"
        [type]="'button'"
      >
        <i class="far fa-fw fa-barcode"></i> {{ 'Barcode' | translate }}
      </app-button>
      <app-button
        class="button__stop"
        class="mr-3"
        *ngIf="task.status === 'in_progress'"
        (buttonClicked)="transitionBack.emit(task.id); isGoingBack = true"
        [btnClass]="'btn-warning'"
        [type]="'button'"
        [isLoading]="isGoingBack"
      >
        <i class="far fa-fw fa-undo-alt"></i> {{ 'Stop' | translate }}
      </app-button>
      <app-button
        class="button__undo"
        class="mr-3"
        *ngIf="task.status === 'completed'"
        (buttonClicked)="transitionBack.emit(task.id); isGoingBack = true"
        [btnClass]="'btn-danger'"
        [isLoading]="isGoingBack"
        [type]="'button'"
      >
        <i class="far fa-fw fa-undo-alt"></i>
        {{ 'Undo completion' | translate }}
      </app-button>
      <app-button
        class="button__start"
        *ngIf="task.status === 'new'"
        (buttonClicked)="transitionForward.emit(task.id); isGoingForward = true"
        [btnClass]="'btn-primary'"
        [isLoading]="isGoingForward"
        [type]="'button'"
      >
        <i class="far fa-fw fa-play"></i> {{ 'Start' | translate }}
      </app-button>
      <app-button
        class="button__complete"
        *ngIf="task.status === 'in_progress'"
        (buttonClicked)="transitionForward.emit(task.id); isGoingForward = true"
        [btnClass]="'btn-primary'"
        [isLoading]="isGoingForward"
        [type]="'button'"
      >
        <i class="far fa-fw fa-check"></i> {{ 'Complete' | translate }}
      </app-button>
    </div>
  </div>
</div>
