export const notificationSubscriptions = {
  LOAD_NOTIFICATION_SUBSCRIPTIONS: '[Notification Subscriptions] Load',
  LOAD_NOTIFICATION_SUBSCRIPTIONS_SUCCEEDED:
    '[Notification Subscriptions] Load Success',
  LOAD_NOTIFICATION_SUBSCRIPTIONS_FAILED:
    '[Notification Subscriptions] Load Fail',
  ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION:
    '[Notification Subscription] Enable Transport Type',
  ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED:
    '[Notification Subscription] Enable Transport Type Success',
  ENABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED:
    '[Notification Subscription] Enable Transport Type Fail',
  DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION:
    '[Notification Subscription] Disable Transport Type',
  DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED:
    '[Notification Subscription] Disable Transport Type Success',
  DISABLE_TRANSPORT_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED:
    '[Notification Subscription] Disable Transport Type Fail',
  ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION:
    '[Notification Subscription] Enable Container Type',
  ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED:
    '[Notification Subscription] Enable Container Type Success',
  ENABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED:
    '[Notification Subscription] Enable Container Type Fail',
  DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION:
    '[Notification Subscription] Disable Container Type',
  DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_SUCCEEDED:
    '[Notification Subscription] Disable Container Type Success',
  DISABLE_CONTAINER_TYPE_NOTIFICATION_SUBSCRIPTION_FAILED:
    '[Notification Subscription] Disable Container Type Fail',
  ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION:
    '[Message Board Notification Subscription] Enable',
  ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED:
    '[Message Board Notification Subscription] Enable Success',
  ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_FAILED:
    '[Message Board Notification Subscription] Enable Fail',
  DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION:
    '[Message Board Notification Subscription] Disable',
  DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED:
    '[Message Board Notification Subscription] Disable Success',
  DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_FAILED:
    '[Message Board Notification Subscription] Disable Fail',
  CLEAR: '[Notification Subscriptions] Clear'
};
