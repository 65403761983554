import { Action } from '@ngrx/store';
import * as types from '../types/subscription.types';

export class LoadSubscriptions implements Action {
  readonly type = types.subscriptions.LOAD_SUBSCRIPTIONS;
  constructor(public payload?: any) {}
}

export class LoadSubscriptionsSuccess implements Action {
  readonly type = types.subscriptions.LOAD_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadSubscriptionsFail implements Action {
  readonly type = types.subscriptions.LOAD_FAILED;
  constructor(public payload: any) {}
}

export class LoadSubscriptionTypes implements Action {
  readonly type = types.subscriptions.LOAD_SUBSCRIPTION_TYPES;
  constructor(public payload?: any) {}
}

export class LoadSubscriptionTypesSuccess implements Action {
  readonly type = types.subscriptions.LOAD_TYPES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadSubscriptionTypesFail implements Action {
  readonly type = types.subscriptions.LOAD_TYPES_FAILED;
  constructor(public payload: any) {}
}

export class StartSubscription implements Action {
  readonly type = types.subscriptions.START_SUBSCRIPTION;
  constructor(public payload: any) {}
}

export class StartSubscriptionSuccess implements Action {
  readonly type = types.subscriptions.START_SUCCEEDED;
  constructor(public payload: any) {}
}

export class StartSubscriptionFail implements Action {
  readonly type = types.subscriptions.START_FAILED;
  constructor(public payload: any) {}
}

export class StopSubscription implements Action {
  readonly type = types.subscriptions.STOP_SUBSCRIPTION;
  constructor(public payload: any) {}
}

export class StopSubscriptionSuccess implements Action {
  readonly type = types.subscriptions.STOP_SUCCEEDED;
  constructor(public payload: any) {}
}

export class StopSubscriptionFail implements Action {
  readonly type = types.subscriptions.STOP_FAILED;
  constructor(public payload: any) {}
}

export class ClearSubscriptions implements Action {
  readonly type = types.subscriptions.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadSubscriptions
  | LoadSubscriptionsSuccess
  | LoadSubscriptionsFail
  | LoadSubscriptionTypes
  | LoadSubscriptionTypesSuccess
  | LoadSubscriptionTypesFail
  | StartSubscription
  | StartSubscriptionSuccess
  | StartSubscriptionFail
  | StopSubscription
  | StopSubscriptionSuccess
  | StopSubscriptionFail
  | ClearSubscriptions;
