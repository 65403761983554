import { MyOffersComponent } from '../../marketplace/my-posts/my-offers/my-offers.component';
import { MarketPostOfferRepliesComponent } from './market-post-offer-replies/market-post-offer-replies.component';
import { MarketPostDemandRepliesComponent } from './market-post-demand-replies/market-post-demand-replies.component';
import { CompanyPostsComponent } from './company-posts.component';
import { AuthGuard } from '../user/user.authguard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyDemandsComponent } from '../../marketplace/my-posts/my-demands/my-demands.component';
import { Route } from '../route.service';
import { DriverGuard } from '../auth/route-guards/driver.guard';
import { SubscriptionGuard } from '../auth/route-guards/subscription.guard';

const defaultDashboardGuards = [AuthGuard, SubscriptionGuard, DriverGuard];
const routes: Routes = Route.withShell([
  {
    path: 'company-posts',
    component: CompanyPostsComponent,
    canActivate: [...defaultDashboardGuards],
    data: { subscriptionCode: 'MARKETPLACE' }
  },
  {
    path: 'company-posts/demands/replies',
    component: MarketPostDemandRepliesComponent,
    canActivate: [...defaultDashboardGuards],
    data: { subscriptionCode: 'MARKETPLACE' }
  },
  {
    path: 'company-posts/offers/replies',
    component: MarketPostOfferRepliesComponent,
    canActivate: [...defaultDashboardGuards],
    data: { subscriptionCode: 'MARKETPLACE' }
  },
  {
    path: 'company-posts/demands',
    component: MyDemandsComponent,
    canActivate: [...defaultDashboardGuards],
    data: { subscriptionCode: 'MARKETPLACE' }
  },
  {
    path: 'company-posts/offers',
    component: MyOffersComponent,
    canActivate: [...defaultDashboardGuards],
    data: { subscriptionCode: 'MARKETPLACE' }
  }
]);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [],
  providers: []
})
export class CompanyPostsRoutingModule { }
