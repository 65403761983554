/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../subscription-type-form/subscription-type-form.component.ngfactory";
import * as i2 from "../subscription-type-form/subscription-type-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../services/icon.service";
import * as i5 from "./edit-subscription-type.component";
import * as i6 from "@angular/router";
import * as i7 from "../../api/api.service";
import * as i8 from "../../../services/toast.service";
var styles_EditSubscriptionTypeComponent = [];
var RenderType_EditSubscriptionTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditSubscriptionTypeComponent, data: {} });
export { RenderType_EditSubscriptionTypeComponent as RenderType_EditSubscriptionTypeComponent };
export function View_EditSubscriptionTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "card p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-subscription-type-form", [], null, [[null, "subscriptionTypeFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("subscriptionTypeFormSubmitted" === en)) {
        var pd_0 = (_co.updateSubscriptionType($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SubscriptionTypeFormComponent_0, i1.RenderType_SubscriptionTypeFormComponent)), i0.ɵdid(3, 638976, null, 0, i2.SubscriptionTypeFormComponent, [i3.FormBuilder, i4.IconService], { subscriptionType: [0, "subscriptionType"], isLoading: [1, "isLoading"] }, { subscriptionTypeFormSubmitted: "subscriptionTypeFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subscriptionType; var currVal_1 = _co.isLoading; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_EditSubscriptionTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-subscription-type", [], null, null, null, View_EditSubscriptionTypeComponent_0, RenderType_EditSubscriptionTypeComponent)), i0.ɵdid(1, 49152, null, 0, i5.EditSubscriptionTypeComponent, [i6.ActivatedRoute, i7.ApiService, i6.Router, i8.ToastService], null, null)], null, null); }
var EditSubscriptionTypeComponentNgFactory = i0.ɵccf("app-edit-subscription-type", i5.EditSubscriptionTypeComponent, View_EditSubscriptionTypeComponent_Host_0, {}, {}, []);
export { EditSubscriptionTypeComponentNgFactory as EditSubscriptionTypeComponentNgFactory };
