var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
var NewPsaAccountComponent = /** @class */ (function () {
    function NewPsaAccountComponent(api, fb, toastr, router) {
        this.api = api;
        this.fb = fb;
        this.toastr = toastr;
        this.router = router;
        this.loading = false;
        this.form = this.fb.group({
            id: [null, Validators.required],
            name: [null, Validators.required],
            vatNumber: [null, Validators.required],
            creditsThreshold: [null, Validators.required],
        });
    }
    NewPsaAccountComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.form.invalid) {
            this.form.get('id').markAsTouched();
            return;
        }
        this.loading = true;
        this.api.post({
            path: '/psa_companies',
            params: { headers: { 'Content-Type': 'application/ld+json' } },
            body: __assign({}, this.form.value, { creditsThreshold: this.form.value.creditsThreshold.toString(), blocked: true }),
        }).toPromise().then(function () {
            _this.toastr.showSuccess({
                message: extract('PSA account created'),
            });
            _this.router.navigateByUrl('/superadmin/psa-accounts');
        }, function () {
            _this.toastr.showDanger({
                message: extract('Failed to create PSA account'),
            });
            _this.loading = false;
        });
    };
    return NewPsaAccountComponent;
}());
export { NewPsaAccountComponent };
