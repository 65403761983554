import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import { Container } from 'app/core/store/models/container.model';
import { Liner } from 'app/core/store/models/liner.model';
import { ContainerType } from 'app/core/store/models/container-types.model';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { ValidationService } from 'app/services/validation.service';

@Component({
  selector: 'app-container-form',
  templateUrl: './container-form.component.html',
  styleUrls: ['./container-form.component.scss']
})
export class ContainerFormComponent implements OnInit, OnChanges {
  @Input()
  container: Container;
  @Input()
  needsConfirmation: boolean;
  @Input()
  submitAttempt: boolean;
  @Input()
  liners: Liner[];
  @Input()
  containerTypes: ContainerType[];

  @Output()
  containerFormValueChanged = new EventEmitter<any>();
  @Output()
  containerFormStatusChanged = new EventEmitter<any>();
  @Output()
  reeferStatusChanged = new EventEmitter<boolean>();

  containerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private validationService: ValidationService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }

    if (changes.submitAttempt && changes.submitAttempt.currentValue) {
      if (!changes.submitAttempt.currentValue) {
        this.containerForm.get('liner').markAsTouched();
        this.containerForm.get('containerType').markAsTouched();
      }
    }

    if (changes.container && changes.container.currentValue) {
      const container = changes.container.currentValue.value
        ? changes.container.currentValue.value
        : changes.container.currentValue;
      this.createForm();
      if (
        container &&
        container.liner &&
        container.containerType &&
        !this.containerForm.get('liner').value
      ) {
        this.containerForm.patchValue(container);
        this.containerFormStatusChanged.emit(this.containerForm.status);
        this.containerFormValueChanged.emit(this.containerForm.value);
      }
    }

    if (changes.containerTypes && changes.containerTypes.currentValue) {
      if (this.containerForm) {
        this.checkContainerTypeReeferStatus(
          this.containerForm.get('containerType').value,
          this.containerTypes
        );
      }
    }
  }

  ngOnInit() {
    this.createForm();
    this.containerForm.statusChanges.subscribe(containerFormStatus =>
      this.containerFormStatusChanged.emit(containerFormStatus)
    );
    this.containerForm.valueChanges.subscribe(containerFormValue => {
      if (this.containerForm.get('number').value === '') {
        this.containerForm.get('number').setValue(null);
        return;
      }
      this.containerFormValueChanged.emit(containerFormValue);
      this.checkContainerTypeReeferStatus(
        containerFormValue.containerType,
        this.containerTypes
      );
    });
    this.store.dispatch(new LoadContainerTypes());
    this.store.dispatch(new LoadLiners());
  }

  checkContainerTypeReeferStatus(containerType: string, containerTypes: any[]) {
    if (!containerType || !containerTypes) {
      return;
    }

    const ct = containerTypes.find(
      containerT => containerT.id === containerType
    );
    this.reeferStatusChanged.emit(ct.reefer);
  }

  createForm() {
    if (this.containerForm) {
      return;
    }
    this.containerForm = this.fb.group({
      containerType: [null, Validators.required],
      liner: [null, Validators.required],
      weight: null,
      adr: false,
      number: [null, this.validationService.iso6346Validator()]
    });
  }
}
