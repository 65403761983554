/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./attachment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../shared/button/button.component.ngfactory";
import * as i4 from "../../shared/button/button.component";
import * as i5 from "@angular/common";
import * as i6 from "./attachment.component";
import * as i7 from "@ngrx/store";
import * as i8 from "@ngrx/effects";
import * as i9 from "../../services/download.service";
import * as i10 from "@ng-bootstrap/ng-bootstrap";
var styles_AttachmentComponent = [i0.styles];
var RenderType_AttachmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AttachmentComponent, data: {} });
export { RenderType_AttachmentComponent as RenderType_AttachmentComponent };
function View_AttachmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["title", 1]], null, 5, "input", [["class", "form-control"], ["id", "title"], ["name", "title"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"], [null, "keyup.enter"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.attachmentTitle = $event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.updateAttachment() !== false);
        ad = (pd_5 && ad);
    } if (("keyup.enter" === en)) {
        var pd_6 = (_co.updateAttachment() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "title"; var currVal_8 = _co.attachmentTitle; _ck(_v, 3, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_AttachmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "attachment--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachmentTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_AttachmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enableEditMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-fw fa-pencil"]], null, null, null, null, null))], null, null); }
function View_AttachmentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-button", [], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.updateAttachment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i4.ButtonComponent, [], { btnClass: [0, "btnClass"], type: [1, "type"], isLoading: [2, "isLoading"] }, { buttonClicked: "buttonClicked" }), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "far fa-fw fa-save"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn-sm"; var currVal_1 = "button"; var currVal_2 = _co.isUpdating; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AttachmentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { title: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "attachment"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "pl-3 pr-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "attachment--actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentComponent_3)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentComponent_4)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "app-button", [], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.downloadAttachment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(16, 49152, null, 0, i4.ButtonComponent, [], { btnClass: [0, "btnClass"], type: [1, "type"], isLoading: [2, "isLoading"] }, { buttonClicked: "buttonClicked" }), (_l()(), i1.ɵeld(17, 0, null, 0, 0, "i", [["class", "far fa-fw fa-download"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "app-button", [], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.deleteAttachment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(19, 49152, null, 0, i4.ButtonComponent, [], { btnClass: [0, "btnClass"], type: [1, "type"], isLoading: [2, "isLoading"] }, { buttonClicked: "buttonClicked" }), (_l()(), i1.ɵeld(20, 0, null, 0, 0, "i", [["class", "far fa-fw fa-trash-alt"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editMode; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.editMode; _ck(_v, 6, 0, currVal_1); var currVal_3 = !_co.editMode; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.editMode; _ck(_v, 14, 0, currVal_4); var currVal_5 = "btn-sm"; var currVal_6 = "button"; var currVal_7 = _co.isDownloading; _ck(_v, 16, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = "btn-sm"; var currVal_9 = "button"; var currVal_10 = _co.isDeleting; _ck(_v, 19, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.attachment.createdAt, "dd/MM/yyyy hh:mm")); _ck(_v, 8, 0, currVal_2); }); }
export function View_AttachmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-attachment", [], null, null, null, View_AttachmentComponent_0, RenderType_AttachmentComponent)), i1.ɵdid(1, 770048, null, 0, i6.AttachmentComponent, [i7.Store, i8.Actions, i9.DownloadService, i10.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AttachmentComponentNgFactory = i1.ɵccf("app-attachment", i6.AttachmentComponent, View_AttachmentComponent_Host_0, { attachment: "attachment" }, {}, []);
export { AttachmentComponentNgFactory as AttachmentComponentNgFactory };
