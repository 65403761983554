import { Action } from '@ngrx/store';
import * as types from '../types/tax-rate.types';
import { TaxRate } from '../models/tax-rate.model';

export class LoadTaxRates implements Action {
  readonly type = types.taxRate.LOAD_TAX_RATES;
  constructor(public payload?: any) {}
}

export class LoadTaxRatesFail implements Action {
  readonly type = types.taxRate.LOAD_TAX_RATES_FAILED;
  constructor(public payload: any) {}
}

export class LoadTaxRatesSuccess implements Action {
  readonly type = types.taxRate.LOAD_TAX_RATES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadFilteredTaxRates implements Action {
  readonly type = types.taxRate.LOAD_FILTERED_TAX_RATES;
  constructor(public payload?: any) {}
}

export class LoadFilteredTaxRatesFail implements Action {
  readonly type = types.taxRate.LOAD_FILTERED_TAX_RATES_FAILED;
  constructor(public payload: any) {}
}

export class LoadFilteredTaxRatesSuccess implements Action {
  readonly type = types.taxRate.LOAD_FILTERED_TAX_RATES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class CreateTaxRate implements Action {
  readonly type = types.taxRate.CREATE_TAX_RATE;
  constructor(public payload?: any) {}
}

export class CreateTaxRateFail implements Action {
  readonly type = types.taxRate.CREATE_TAX_RATE_FAILED;
  constructor(public payload: any) {}
}

export class CreateTaxRateSuccess implements Action {
  readonly type = types.taxRate.CREATE_TAX_RATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteTaxRate implements Action {
  readonly type = types.taxRate.DELETE_TAX_RATE;
  constructor(
    public payload: {
      taxRateId: string;
    }
  ) {}
}

export class DeleteTaxRateFail implements Action {
  readonly type = types.taxRate.DELETE_TAX_RATE_FAILED;
  constructor(public payload: any) {}
}

export class DeleteTaxRateSuccess implements Action {
  readonly type = types.taxRate.DELETE_TAX_RATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateTaxRate implements Action {
  readonly type = types.taxRate.UPDATE_TAX_RATE;
  constructor(
    public payload?: {
      taxRateId: string;
      body: TaxRate;
    }
  ) {}
}

export class UpdateTaxRateFail implements Action {
  readonly type = types.taxRate.UPDATE_TAX_RATE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateTaxRateSuccess implements Action {
  readonly type = types.taxRate.UPDATE_TAX_RATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class ClearTaxRates implements Action {
  readonly type = types.taxRate.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadTaxRates
  | LoadTaxRatesFail
  | LoadTaxRatesSuccess
  | LoadFilteredTaxRates
  | LoadFilteredTaxRatesFail
  | LoadFilteredTaxRatesSuccess
  | CreateTaxRate
  | CreateTaxRateSuccess
  | CreateTaxRateFail
  | DeleteTaxRate
  | DeleteTaxRateSuccess
  | DeleteTaxRateFail
  | UpdateTaxRate
  | UpdateTaxRateSuccess
  | UpdateTaxRateFail
  | ClearTaxRates;
