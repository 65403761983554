import { AllowedSubscriptionGuard } from '../auth/route-guards/allowed-subscription.guard';
import { AuthGuard } from '../user/user.authguard';
import { Routes } from '@angular/router';
import { ContactsComponent } from './contacts.component';
import { Route } from '../route.service';
import { NotFoundComponent } from '../not-found/not-found.component';
import { DriverGuard } from '../auth/route-guards/driver.guard';
import { TenantGuard } from '../auth/route-guards/tenant.guard';
import { SubscriptionGuard } from '../auth/route-guards/subscription.guard';
var ɵ0 = { subscriptionCode: 'MARKETPLACE' };
var routes = Route.withShell([
    {
        path: 'contacts',
        component: ContactsComponent,
        canActivate: [
            AuthGuard,
            TenantGuard,
            DriverGuard,
            SubscriptionGuard,
            AllowedSubscriptionGuard
        ],
        data: ɵ0
    },
    {
        path: '**',
        redirectTo: '404'
    },
    { path: '404', component: NotFoundComponent }
]);
/**
 * Module that pretty much just exports the contacts module.
 *
 * @export
 * @class ContactsRoutingModule
 */
var ContactsRoutingModule = /** @class */ (function () {
    function ContactsRoutingModule() {
    }
    return ContactsRoutingModule;
}());
export { ContactsRoutingModule };
export { ɵ0 };
