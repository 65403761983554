import { LoadSubscriptionTypes } from '../../store/actions/subscriptions.actions';
import { SubscriptionType } from '../../store/models/subscriptions.model';
import { ToastService } from 'app/services/toast.service';
import { I18nService } from 'app/services/i18n.service';
import { Tenant } from '../../store/models/tenant.model';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApiService } from '../../api/api.service';
import { State } from '../../store/store.model';
import { Alert } from '../../store/models/alert.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/tenant.types';
import {
  LoadAllTenants,
  UpdateTenant
} from '../../store/actions/tenants.actions';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-tenant',
  templateUrl: './edit-tenant.component.html',
})
export class EditTenantComponent implements OnInit, OnDestroy {
  tenant: Tenant;
  alert: Alert;
  languages;
  loading;
  countries: { value: string; label: string }[];
  dataTypes;
  subscriptionTypes: SubscriptionType[];

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private route: ActivatedRoute,
    private i18nService: I18nService,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadSubscriptionTypes());
    this.route.paramMap.subscribe(params => {
      const tenantId = params.get('id');
      if (tenantId) {
        this.store
          .select(state => state.tenants)
          .pipe(untilDestroyed(this))
          .subscribe(tenants => {
            if (tenants && tenants['data'] && !this.tenant) {
              this.tenant = tenants['data'].find(
                t => t.id === params.get('id')
              );
              if (!this.tenant) {
                this.api
                  .get({
                    path: `/tenants/${tenantId}`
                  })
                  .subscribe((data: Tenant) => (this.tenant = data));
              }
            } else {
              this.store.dispatch(new LoadAllTenants());
            }
          });
      }
    });
    this.store
      .select(state => state.subscriptionTypes)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptionTypesState => {
        if (subscriptionTypesState) {
          this.subscriptionTypes = subscriptionTypesState;
        }
      });
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countries => {
        if (!countries) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countries];
      });
    this.languages = this.i18nService.languages;
  }

  ngOnInit() {
    this.updates$
      .pipe(ofType(Types.tenants.UPDATE_TENANT_SUCCEEDED))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Updated tenant successfully'
        });
      });
    this.updates$.pipe(ofType(Types.tenants.UPDATE_TENANT_FAILED)).subscribe(() => {
      this.toastr.showSuccess({
        title: 'Something went wrong',
        message: 'Failed to update tenant'
      });
    });
  }

  ngOnDestroy() { }

  closeAlert() {
    this.alert = null;
  }

  onSubmit(tenantFormValue) {
    this.store.dispatch(
      new UpdateTenant({
        id: this.tenant.id,
        body: tenantFormValue
      })
    );
  }
}
