import { Product, ProductTypes, ProductPart } from 'app/core/store/models/product.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Input
} from '@angular/core';

@Component({
  selector: 'app-tariff-form',
  templateUrl: './tariff-form.component.html',
  styleUrls: ['./tariff-form.component.scss']
})
export class TariffFormComponent implements OnInit, OnChanges {
  @Input()
  searchTerm: string;
  @Input()
  tariffType: string;
  @Input()
  showTariffTypeButtons = true;
  @Input()
  isLoading: boolean;
  @Input()
  tariff: Product;
  @Output()
  tariffFormSubmitted = new EventEmitter();

  @ViewChild('tariffTable', { static: false })
  tariffTable;

  tariffForm: FormGroup;
  PRODUCT_TYPES = ProductTypes;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (!this.tariffForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (!this.tariffForm) {
      this.createForm();
    }
    if (changes.searchTerm) {
      const name = changes.searchTerm.currentValue;
      this.tariffForm.patchValue({
        label: name
      });
    }
    if (changes.tariffType) {
      this.tariffForm.patchValue({
        type: changes.tariffType.currentValue
      });
    }
    if (changes.tariff) {
      let tariff = Object.assign({}, changes.tariff.currentValue);
      tariff = {
        type: tariff.type,
        label: tariff.label,
        parts: tariff.parts.map((part: ProductPart) => {
          return {
            id: part.id,
            description: part.description,
            quantity: part.quantity,
            unitPrice: part.unitPrice
          };
        }),
        price: tariff.price
      };
      this.tariffForm.setValue(tariff);
    }
  }

  createForm() {
    this.tariffForm = this.fb.group({
      type: [this.PRODUCT_TYPES.CUSTOMER],
      label: [null, Validators.required],
      parts: [[], Validators.required],
      price: [null]
    });
  }

  onSubmit() {
    const tariffFormValue = JSON.parse(JSON.stringify(this.tariffForm.value));
    const count: number = tariffFormValue.parts.length;

    // check that all tariff table lines except last one are valid
    let tariffTableValid = true;
    for (let i = 0; i < count - 1; i++) {
      if (!tariffFormValue.parts[i].description || !tariffFormValue.parts[i].unitPrice || !tariffFormValue.parts[i].quantity) {
        tariffTableValid = false;
      }
    }
    if (this.tariffForm.invalid || !tariffTableValid) {
      this.tariffForm.get('label').markAsTouched();
      this.tariffTable.onSubmit();
      return;
    }

    // remove last tariff table line if invalid
    if (!tariffFormValue.parts[count - 1].description ||
      !tariffFormValue.parts[count - 1].unitPrice ||
      !tariffFormValue.parts[count - 1].quantity
    ) {
      tariffFormValue.parts.pop();
    }

    this.tariffFormSubmitted.emit(tariffFormValue);
  }
  handleTariffChange(tariffValue) {
    this.tariffForm.patchValue({
      price: this.calculateprice(tariffValue['parts']),
      parts: tariffValue.parts
    });
  }

  calculateprice(tariffParts: ProductPart[]) {
    tariffParts = JSON.parse(
      JSON.stringify(
        tariffParts.filter(part => part.quantity && part.unitPrice)
      )
    );
    if (!tariffParts || tariffParts.length === 0) {
      return 0;
    }
    if (tariffParts.length === 1) {
      return +tariffParts[0].quantity * tariffParts[0].unitPrice;
    }
    return tariffParts
      .map(part => +part.quantity * part.unitPrice)
      .reduce((part1, part2) => part1 + part2);
  }
}
