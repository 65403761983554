import { ToastService } from 'app/services/toast.service';
import { ApiService } from '../../api/api.service';
import { Component } from '@angular/core';
import { Alert } from '../../store/models/alert.model';

@Component({
  selector: 'app-new-liner',
  templateUrl: './new-liner.component.html',
})
export class NewLinerComponent {
  alert: Alert;

  constructor(private api: ApiService, private toastr: ToastService) { }

  createNewLiner(linerFormValue) {
    this.api.post({ path: '/liners', body: linerFormValue }).subscribe(
      res => {
        this.toastr.showSuccess({
          message: 'Liner created!'
        });
      },
      err => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: err
        });
      }
    );
  }
}
