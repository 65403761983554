import * as types from '../types/country.types';
import * as WalletActions from '../actions/country.actions';

const INITIAL_STATE = null;

export function reducer(state = INITIAL_STATE, action: WalletActions.All) {
  switch (action.type) {
    case types.country.LOAD_COUNTRIES_SUCCEEDED:
      return [...action.payload];
  }
  return state;
}
