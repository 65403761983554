import { Action } from '@ngrx/store';
import * as types from '../types/driver-task.types';

export class LoadDriverTasks implements Action {
  readonly type = types.drivers.LOAD_DRIVER_TASKS;
  constructor(public payload?: any) {}
}

export class LoadDriverTasksSuccess implements Action {
  readonly type = types.drivers.LOAD_DRIVER_TASKS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadDriverTasksFail implements Action {
  readonly type = types.drivers.LOAD_DRIVER_TASKS_FAILED;
  constructor(public payload: any) {}
}

export type All =
  | LoadDriverTasks
  | LoadDriverTasksSuccess
  | LoadDriverTasksFail;
