import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadCreditTransactions } from 'app/core/store/actions/transactions.actions';
import { Transaction } from 'app/core/store/models/transaction.model';
import { State } from 'app/core/store/store.model';
import { extract } from 'app/services/i18n.service';
import { DownloadService } from 'app/services/download.service';
import * as moment from 'moment';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-credit-invoices',
  templateUrl: './credit-invoices.component.html',
})
export class CreditInvoicesComponent implements OnInit, OnDestroy {
  creditTransactions: Transaction[];
  totalElements = 0;
  tableMessages = {
    emptyMessage: extract('No invoices to display'),
    totalMessage: extract('total')
  };
  page: any;
  pageSize = 5;
  isLoadingId: any;

  constructor(
    private store: Store<State>,
    private downloadService: DownloadService
  ) {
    this.store
      .select(state => state.creditTransactions)
      .pipe(untilDestroyed(this))
      .subscribe(creditTransactionsState => {
        if (!creditTransactionsState['data']) {
          return;
        }
        this.creditTransactions = creditTransactionsState['data'];
        this.totalElements = creditTransactionsState['totalElements'];
        this.page = creditTransactionsState['nextPage'];
      });
  }

  ngOnInit() {
    this.store.dispatch(
      new LoadCreditTransactions({
        pageSize: this.pageSize
      })
    );
  }
  setPage(pageEvent) {
    this.page = pageEvent.offset + 1;
    this.store.dispatch(
      new LoadCreditTransactions({
        pageSize: this.pageSize,
        page: this.page
      })
    );
  }

  getInvoice(transaction) {
    this.isLoadingId = transaction.id;
    const invoiceDate = moment(transaction.created).format('DD/MM/YYYY');
    const filename = `hakka_invoice_${invoiceDate}.pdf`;
    this.downloadService
      .downloadPDF(`/credit_transactions/${transaction.id}/invoice`, filename)
      .then(() => (this.isLoadingId = null))
      .catch(() => (this.isLoadingId = null));
  }

  ngOnDestroy() { }
}
