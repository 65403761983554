<div class="row no-gutters">
  <div class="col-10">
    <div class="planning">
      <app-datepicker
        (dayChange)="dayChange.next($event)"
        [(today)]="today"
      ></app-datepicker>
      <div class="date sticky-dates text-white">
        {{ dateFromToday(0) | date: 'EEE dd MMM yyyy' }}
      </div>
      <div class="date sticky-dates text-white">
        {{ dateFromToday(1) | date: 'EEE dd MMM yyyy' }}
      </div>
      <div class="date sticky-dates text-white">
        {{ dateFromToday(2) | date: 'EEE dd MMM yyyy' }}
      </div>
      <div class="date sticky-dates text-white">
        {{ dateFromToday(3) | date: 'EEE dd MMM yyyy' }}
      </div>
      <div class="date sticky-dates text-white">
        {{ dateFromToday(4) | date: 'EEE dd MMM yyyy' }}
      </div>
      <ng-template ngFor let-driver [ngForOf]="drivers">
        <app-user-info
          class="card p-3"
          [user]="driver?.user"
          [licensePlate]="driver?.licensePlate"
        ></app-user-info>
        <app-planning-day
          *ngFor="let date of dates; let i = index"
          class="card planning-day"
          [attr.driver-id]="driver.id"
          [attr.date]="date"
          dragula="{{ dragulaTransports }}"
          [(dragulaModel)]="driver.plannedTransports[getParsedDate(dates[i])]"
          [transports]="getPlannedTransports(driver, dates[i])"
          [driverId]="driver.id"
          [date]="date"
        ></app-planning-day>
      </ng-template>
      <button class="btn" id="addDriverButton" (click)="launchNewDriverModal()">
        <i class="far fa-fw fa-plus"></i>
        <span>{{ 'Add new driver' | translate }}</span>
      </button>
    </div>
  </div>
  <div class="col-2 unplanned-transports p-2">
    <div class="sticky-transports">
      <div class="text-center m-2">
        <h4>{{ 'Unplanned' | translate }}</h4>
      </div>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'Search transports' | translate }}"
          ngbTooltip="{{
            'Search location, liner or container type' | translate
          }}"
          [(ngModel)]="query"
          (keyup)="search($event)"
          aria-label="Filter transports"
          aria-describedby="basic-addon2"
        />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">
            <i class="far fa-fw fa-search"></i>
          </span>
        </div>
      </div>
      <div class="pre-scrollable">
        <div
          data-infinite-scroll
          [infiniteScrollDistance]="2"
          [infiniteScrollUpDistance]="1.5"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScrollDown()"
          [scrollWindow]="false"
          [fromRoot]="true"
          [infiniteScrollContainer]="'.pre-scrollable'"
        >
          <div
            id="unplanned-transports-list"
            [(dragulaModel)]="transports"
            dragula="{{ dragulaTransports }}"
          >
            <app-transport-info
              class="transport"
              *ngFor="let transport of transports"
              [transportId]="transport.id"
              [transport]="transport"
            ></app-transport-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
