var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/invoice.types';
import * as InvoiceActions from '../actions/invoice.actions';
import { ApiService } from '../../api/api.service';
var InvoiceEffects = /** @class */ (function () {
    function InvoiceEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadInvoice$ = this.actions$.pipe(ofType(types.invoice.LOAD_INVOICE), map(function (action) { return action.payload; }), concatMap(function (id) {
            return _this.api.get({ path: "/crm/invoices/" + id }).pipe(map(function (data) { return new InvoiceActions.LoadInvoiceSuccess(data); }), catchError(function (error) { return of(new InvoiceActions.LoadInvoiceFail(error)); }));
        }));
        this.loadInvoices$ = this.actions$.pipe(ofType(types.invoice.LOAD_INVOICES), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var page = payload && payload.page ? payload.page : 1;
            return _this.api.get({ path: "/crm/invoices?page=" + page }).pipe(map(function (data) { return new InvoiceActions.LoadInvoicesSuccess(data); }), catchError(function (error) { return of(new InvoiceActions.LoadInvoicesFail(error)); }));
        }));
        this.loadFilteredInvoices$ = this.actions$.pipe(ofType(types.invoice.LOAD_FILTERED_INVOICES), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            var page = payload && payload.page ? payload.page : 1;
            return _this.api.get({
                path: "/crm/invoices?business.label=" + query + "&number=" + query + "&page=" + page
            }).pipe(map(function (data) { return new InvoiceActions.LoadFilteredInvoicesSuccess(data); }), catchError(function (error) { return of(new InvoiceActions.LoadFilteredInvoicesFail(error)); }));
        }));
        this.createInvoice$ = this.actions$.pipe(ofType(types.invoice.CREATE_INVOICE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/crm/invoices", body: payload
        }).pipe(map(function (data) { return new InvoiceActions.CreateInvoiceSuccess(data); }), catchError(function (error) { return of(new InvoiceActions.CreateInvoiceFail(error)); })); }));
        this.updateInvoice$ = this.actions$.pipe(ofType(types.invoice.UPDATE_INVOICE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/invoices/" + payload.invoiceId,
            body: payload.body
        }).pipe(map(function (data) { return new InvoiceActions.UpdateInvoiceSuccess(data); }), catchError(function (error) { return of(new InvoiceActions.UpdateInvoiceFail(error)); })); }));
        this.deleteInvoice$ = this.actions$.pipe(ofType(types.invoice.DELETE_INVOICE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/invoices/" + payload.invoiceId
        }).pipe(map(function () { return new InvoiceActions.DeleteInvoiceSuccess(payload.invoiceId); }), catchError(function (error) { return of(new InvoiceActions.DeleteInvoiceFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], InvoiceEffects.prototype, "loadInvoice$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], InvoiceEffects.prototype, "loadInvoices$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], InvoiceEffects.prototype, "loadFilteredInvoices$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], InvoiceEffects.prototype, "createInvoice$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], InvoiceEffects.prototype, "updateInvoice$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], InvoiceEffects.prototype, "deleteInvoice$", void 0);
    return InvoiceEffects;
}());
export { InvoiceEffects };
