import { Actions, ofType } from '@ngrx/effects';
import { Component, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/marketplace.types';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-message-input',
  templateUrl: 'message-input.component.html',
  styleUrls: ['message-input.component.scss']
})
export class MessageInputComponent implements OnDestroy {
  @Input()
  loading = false;
  @Input()
  reply = false;
  @Output()
  messageSubmitted = new EventEmitter<string>();
  message = '';

  constructor(
    private createUpdates$: Actions,
    private toastr: ToastService
  ) {
    createUpdates$
      .pipe(ofType(Types.messages.CREATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.message = '';
      });
    createUpdates$
      .pipe(ofType(Types.messages.CREATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: error['message']
        });
        this.loading = false;
      });
    createUpdates$
      .pipe(ofType(Types.messages.CREATE_REPLY_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
      });
    createUpdates$
      .pipe(ofType(Types.messages.CREATE_REPLY_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
      });
  }

  ngOnDestroy() { }

  onSubmit() {
    if (this.message.length !== 0) {
      if (!this.loading) {
        this.messageSubmitted.emit(this.message);
        this.loading = true;
      }
    } else {
      this.toastr.showDanger({
        title: 'Something went wrong',
        message: 'Your message can\'t be empty.'
      });
    }
  }
}
