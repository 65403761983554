import { LoadLocationTypes } from '../core/store/actions/location.actions';
import {
  LocationTypes,
  LocationType
} from '../core/store/models/location-type.model';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
// Models
import { Liner } from '../core/store/models/liner.model';
import { State } from '../core/store/store.model';
import { ContainerType } from '../core/store/models/container-types.model';
import { TransportType } from '../core/store/models/transport-types.model';

@Injectable()
export class MarketpostParserService {
  private _containerTypes: ContainerType[];
  private _liners: Liner[];
  transportTypes: TransportType[];
  locationTypes: LocationType[];

  constructor(private store: Store<State>) {
    this.store
      .select(state => state.tms.locations)
      .subscribe(locationState => {
        if (locationState && locationState.locationTypes) {
          this.locationTypes = locationState.locationTypes;
        } else {
          this.store.dispatch(new LoadLocationTypes());
        }
      });
    this.store
      .select(state => state.containerTypes)
      .subscribe(containerTypes => {
        if (containerTypes && !this.containerTypes) {
          this.containerTypes = containerTypes;
        }
      });

    this.store
      .select(state => {
        if (state.marketplace && state.marketplace.liners) {
          return state.marketplace.liners;
        }
      })
      .subscribe(liners => {
        if (liners && !this.liners) {
          this.liners = liners;
        }
      });

    this.store.select(state => {
      if (state.marketplace && state.marketplace.transportTypes) {
        this.transportTypes = state.marketplace.transportTypes;
      }
    });
  }

  get containerTypes(): any[] {
    return this._containerTypes;
  }

  set containerTypes(containerTypes: any[]) {
    this._containerTypes = containerTypes;
  }

  get liners(): any[] {
    return this._liners;
  }

  set liners(liners: any[]) {
    this._liners = liners;
  }

  parseTransportOffer(offer) {
    const transport = JSON.parse(JSON.stringify(offer));
    transport.attachments =
      transport.attachments && transport.attachments.length > 0
        ? transport.attachments.map(attachment =>
            attachment['@id'] ? attachment['@id'] : attachment
          )
        : [];
    transport.transportTasks = transport.transportTasks.filter(t => t.location);
    transport.transportTasks.forEach((task, x) => {
      // account for free text locations
      const location = {
        name: task.location.name || task.location,
        latitude: task.location.latitude,
        longitude: task.location.longitude
      };
      if (task.location.id) {
        location['id'] = task.location.id;
      }

      /**
       * If the location does not have a latitude (or longitude) the location is a user input location and
       * should get the correct locationType
       */
      if (!location.latitude && !location.longitude && !location['id']) {
        const locationType = this.locationTypes.find(
          lt => lt.code === LocationTypes.TRASH
        );
        if (locationType) {
          location['locationType'] = locationType['@id'];
        }
      }
      transport['transportTasks'][x]['location'] = location;

      if (task.privateLocation) {
        const privateLocation = {
          id: task.privateLocation
        };
        transport['transportTasks'][x]['privateLocation'] = privateLocation;
      }

      // set the correct dates and times
      let startDate = task.startDate;
      const startTime = task.startTime;
      let endDate = task.endDate;
      const endTime = task.endTime;

      if (!startDate && !startTime) {
        delete task.startDate;
        delete task.startTime;
      } else if (!startDate && startTime) {
        startDate = moment()
          .hours(startTime.hour)
          .minutes(startTime.minute)
          .seconds(0);
        transport['transportTasks'][x]['startDate'] = moment(
          startDate
        ).format();
        transport['transportTasks'][x]['startDateTimeSpecified'] = true;
      } else if (startDate && startTime) {
        startDate = moment(startDate)
          .hours(startTime.hour)
          .minutes(startTime.minute)
          .seconds(0);
        transport['transportTasks'][x]['startDate'] = moment(
          startDate
        ).format();
        transport['transportTasks'][x]['startDateTimeSpecified'] = true;
      } else if (startDate && !startTime) {
        startDate = moment(startDate)
          .hours(0)
          .minutes(0)
          .seconds(0);
        transport['transportTasks'][x]['startDate'] = moment(
          startDate
        ).format();
        transport['transportTasks'][x]['startDateTimeSpecified'] = false;
      }

      if (!endDate && !endTime) {
        delete task.endDate;
        delete task.endTime;
      } else if (!endDate && endTime) {
        endDate = moment()
          .hours(endTime.hour)
          .minutes(endTime.minute)
          .seconds(0);
        transport['transportTasks'][x]['endDate'] = moment(endDate).format();
        transport['transportTasks'][x]['endDateTimeSpecified'] = true;
      } else if (endDate && endTime) {
        endDate = moment(endDate)
          .hours(endTime.hour)
          .minutes(endTime.minute)
          .seconds(0);
        transport['transportTasks'][x]['endDate'] = moment(endDate).format();
        transport['transportTasks'][x]['endDateTimeSpecified'] = true;
      } else if (endDate && !endTime) {
        endDate = moment(endDate)
          .hours(0)
          .minutes(0)
          .seconds(0);
        transport['transportTasks'][x]['endDate'] = moment(endDate).format();
        transport['transportTasks'][x]['endDateTimeSpecified'] = false;
      }

      delete task.endTime;
      delete task.startTime;
      if (task['taskType'] && task['taskType']['@id']) {
        transport['transportTasks'][x]['taskType'] = task['taskType']['@id'];
      }
    });
    // map container with correct values
    if (typeof transport.container.weight === 'string') {
      transport.container.weight =
        transport.container.weight.length === 0
          ? null
          : transport.container.weight;
    }
    if (transport.container.weight) {
      transport.container.weight = parseFloat(transport.container.weight);
    }

    if (transport.container.containerType) {
      transport.container.containerType = this.containerTypes.find(
        cType => cType.id === transport.container.containerType
      )['@id'];
    }

    if (transport.container.liner) {
      transport.container.liner = this.liners.find(
        liner => liner.id === transport.container.liner
      )['@id'];
    }

    if (transport.container.adr) {
      transport.container.adr = transport.container.adr;
    }

    if (transport.transportType) {
      transport.transportType = transport.transportType['@id'];
    }

    if (!transport.genset) {
      transport.genset = false;
    }
    this.delete_null_properties(transport, true);

    // This enables customer and contact to be deleted on update
    transport.customer = transport.customer || null;
    transport.contact = transport.contact || null;
    return transport;
  }

  parseTransportDemand(demand) {
    const transport = JSON.parse(JSON.stringify(demand));

    if (
      transport.transportType &&
      typeof transport.transportType === 'object'
    ) {
      transport.transportType = transport.transportType['@id'];
    }

    if (transport.container.containerType) {
      transport.container.containerType = this.containerTypes.find(
        cType => cType.id === transport.container.containerType
      )['@id'];
    }

    if (transport.container.weight) {
      transport.container.weight = parseFloat(transport.container.weight);
    }
    if (transport.container.liner) {
      transport.container.liner = this.liners.find(
        liner => liner.id === transport.container.liner
      )['@id'];
    }
    // get origin date and time
    let originStartDate = transport.originStartDate;
    let originEndDate = transport.originEndDate;
    const originStartTime = transport.originStartTime;
    const originEndTime = transport.originEndTime;
    // get destination date and time
    let destinationStartDate = transport.destinationStartDate;
    let destinationEndDate = transport.destinationEndDate;
    const destinationStartTime = transport.destinationStartTime;
    const destinationEndTime = transport.destinationEndTime;

    if (!originStartDate && originStartTime) {
      originStartDate = moment()
        .hours(originStartTime.hour)
        .minutes(originStartTime.minute)
        .seconds(0);
      transport['originTimeIntervalStart'] = moment(originStartDate).format();
      transport['originTimeIntervalStartTimeSpecified'] = true;
    } else if (originStartDate && originStartTime) {
      originStartDate = moment(originStartDate)
        .hours(originStartTime.hour)
        .minutes(originStartTime.minute)
        .seconds(0);
      transport['originTimeIntervalStart'] = moment(originStartDate).format();
      transport['originTimeIntervalStartTimeSpecified'] = true;
    } else if (originStartDate && !originStartTime) {
      originStartDate = moment(originStartDate)
        .hours(0)
        .minutes(0)
        .seconds(0);
      transport['originTimeIntervalStart'] = moment(originStartDate).format();
      transport['originTimeIntervalStartTimeSpecified'] = false;
    }

    if (!originEndDate && originEndTime) {
      originEndDate = moment()
        .hours(originEndTime.hour)
        .minutes(originEndTime.minute)
        .seconds(0);
      transport['originTimeIntervalEnd'] = moment(originEndDate).format();
      transport['originTimeIntervalEndTimeSpecified'] = true;
    } else if (originEndDate && originEndTime) {
      originEndDate = moment(originEndDate)
        .hours(originEndTime.hour)
        .minutes(originEndTime.minute)
        .seconds(0);
      transport['originTimeIntervalEnd'] = moment(originEndDate).format();
      transport['originTimeIntervalEndTimeSpecified'] = true;
    } else if (originEndDate && !originEndTime) {
      originEndDate = moment(originEndDate)
        .hours(0)
        .minutes(0)
        .seconds(0);
      transport['originTimeIntervalEnd'] = moment(originEndDate).format();
      transport['originTimeIntervalEndTimeSpecified'] = false;
    }

    if (!destinationStartDate && destinationStartTime) {
      destinationStartDate = moment()
        .hours(destinationStartTime.hour)
        .minutes(destinationStartTime.minute)
        .seconds(0);
      transport['destinationTimeIntervalStart'] = moment(
        destinationStartDate
      ).format();
      transport['destinationTimeIntervalStartTimeSpecified'] = true;
    } else if (destinationStartDate && destinationStartTime) {
      destinationStartDate = moment(destinationStartDate)
        .hours(destinationStartTime.hour)
        .minutes(destinationStartTime.minute)
        .seconds(0);
      transport['destinationTimeIntervalStart'] = moment(
        destinationStartDate
      ).format();
      transport['destinationTimeIntervalStartTimeSpecified'] = true;
    } else if (destinationStartDate && !destinationStartTime) {
      destinationStartDate = moment(destinationStartDate)
        .hours(0)
        .minutes(0)
        .seconds(0);
      transport['destinationTimeIntervalStart'] = moment(
        destinationStartDate
      ).format();
      transport['destinationTimeIntervalStartTimeSpecified'] = false;
    }

    if (!destinationEndDate && destinationEndTime) {
      destinationEndDate = moment()
        .hours(destinationEndTime.hour)
        .minutes(destinationEndTime.minute)
        .seconds(0);
      transport['destinationTimeIntervalEnd'] = moment(
        destinationEndDate
      ).format();
      transport['destinationTimeIntervalEndTimeSpecified'] = true;
    } else if (destinationEndDate && destinationEndTime) {
      destinationEndDate = moment(destinationEndDate)
        .hours(destinationEndTime.hour)
        .minutes(destinationEndTime.minute)
        .seconds(0);
      transport['destinationTimeIntervalEnd'] = moment(
        destinationEndDate
      ).format();
      transport['destinationTimeIntervalEndTimeSpecified'] = true;
    } else if (destinationEndDate && !destinationEndTime) {
      destinationEndDate = moment(destinationEndDate)
        .hours(0)
        .minutes(0)
        .seconds(0);
      transport['destinationTimeIntervalEnd'] = moment(
        destinationEndDate
      ).format();
      transport['destinationTimeIntervalEndTimeSpecified'] = false;
    }

    this.delete_null_properties(transport, true);
    return transport;
  }

  delete_null_properties(object, recurse) {
    for (const i in object) {
      if (object[i] === null) {
        delete object[i];
      } else if (recurse && typeof object[i] === 'object') {
        this.delete_null_properties(object[i], recurse);
      }
    }
  }
}
