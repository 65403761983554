export const visits = {
  LOAD_VISITS: '[Visits] Load',
  LOAD_VISITS_SUCCEEDED: '[Visits] Load Visits Success',
  LOAD_VISITS_FAILED: '[Visits] Load Visits Fail',
  CREATE_VISIT: '[Visit] Create',
  CREATE_VISIT_SUCCEEDED: '[Visit] Create Visit Success',
  CREATE_VISIT_FAILED: '[Visit] Create Visits Fail',
  COMPLETE_VISIT: '[Visit] Complete',
  COMPLETE_VISIT_SUCCEEDED: '[Visit] Complete Visit Success',
  COMPLETE_VISIT_FAILED: '[Visit] Complete Visits Fail',
  REVERT_COMPLETE_VISIT: '[Visit] Revert Complete',
  REVERT_COMPLETE_VISIT_SUCCEEDED: '[Visit] Revert Complete Visit Success',
  REVERT_COMPLETE_VISIT_FAILED: '[Visit] Revert Complete Visits Fail',
  CLEAR: '[Visits] Clear'
};
