/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../new-marketpost/new-marketpost-offer/new-marketpost-offer.component.ngfactory";
import * as i6 from "../../../shared/datepicker-config";
import * as i7 from "../../new-marketpost/new-marketpost-offer/new-marketpost-offer.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../../services/autocomplete.service";
import * as i10 from "../../../services/location.service";
import * as i11 from "../../../services/icon.service";
import * as i12 from "../../new-marketpost/new-marketpost-demand/new-marketpost-demand.component.ngfactory";
import * as i13 from "../../new-marketpost/new-marketpost-demand/new-marketpost-demand.component";
import * as i14 from "./edit-market-post.component";
import * as i15 from "@angular/router";
import * as i16 from "@ngrx/store";
import * as i17 from "../../../core/api/api.service";
import * as i18 from "../../../services/marketpost-parser.service";
import * as i19 from "@ngrx/effects";
import * as i20 from "../../../services/toast.service";
var styles_EditMarketPostComponent = [];
var RenderType_EditMarketPostComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditMarketPostComponent, data: {} });
export { RenderType_EditMarketPostComponent as RenderType_EditMarketPostComponent };
function View_EditMarketPostComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_NgbAlert_0, i1.RenderType_NgbAlert)), i0.ɵdid(1, 638976, null, 0, i2.NgbAlert, [i2.NgbAlertConfig, i0.Renderer2, i0.ElementRef], { type: [0, "type"] }, { close: "close" }), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "i", [["class", "far"]], null, null, null, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.alert.type; _ck(_v, 1, 0, currVal_1); var currVal_2 = "far"; var currVal_3 = _co.alert.icon; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_4 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.alert.message)); _ck(_v, 6, 0, currVal_4); }); }
function View_EditMarketPostComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-cmp-new-marketpost-offer", [], null, [[null, "transportOfferAdded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("transportOfferAdded" === en)) {
        var pd_0 = (_co.transportOfferAdded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NewMarketpostOfferComponent_0, i5.RenderType_NewMarketpostOfferComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i6.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i6.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 638976, null, 0, i7.NewMarketpostOfferComponent, [i8.FormBuilder, i9.Autocomplete, i10.LocationService, i2.NgbModal, i11.IconService], { title: [0, "title"], transportTypes: [1, "transportTypes"], liners: [2, "liners"], containerTypes: [3, "containerTypes"], transportOffer: [4, "transportOffer"], index: [5, "index"] }, { transportOfferAdded: "transportOfferAdded" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Edit your transport")); var currVal_1 = _co.transportTypes; var currVal_2 = _co.liners; var currVal_3 = _co.containerTypes; var currVal_4 = _co.post; var currVal_5 = 0; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_EditMarketPostComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-cmp-new-marketpost-demand", [], null, [[null, "transportDemandAdded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("transportDemandAdded" === en)) {
        var pd_0 = (_co.transportDemandAdded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_NewMarketpostDemandComponent_0, i12.RenderType_NewMarketpostDemandComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i6.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i6.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 638976, null, 0, i13.NewMarketpostDemandComponent, [i8.FormBuilder, i9.Autocomplete, i10.LocationService, i2.NgbModal, i11.IconService], { title: [0, "title"], transportDemand: [1, "transportDemand"], index: [2, "index"], liners: [3, "liners"], containerTypes: [4, "containerTypes"], transportTypes: [5, "transportTypes"] }, { transportDemandAdded: "transportDemandAdded" }), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Edit your tranport")); var currVal_1 = _co.post; var currVal_2 = 0; var currVal_3 = _co.liners; var currVal_4 = _co.containerTypes; var currVal_5 = _co.transportTypes; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_EditMarketPostComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditMarketPostComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditMarketPostComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditMarketPostComponent_3)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type === "offers"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.type === "demands"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_EditMarketPostComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-edit-market-post", [], null, null, null, View_EditMarketPostComponent_0, RenderType_EditMarketPostComponent)), i0.ɵdid(1, 4374528, null, 0, i14.EditMarketPostComponent, [i15.ActivatedRoute, i3.Location, i16.Store, i17.ApiService, i18.MarketpostParserService, i19.Actions, i20.ToastService], null, null)], null, null); }
var EditMarketPostComponentNgFactory = i0.ɵccf("app-cmp-edit-market-post", i14.EditMarketPostComponent, View_EditMarketPostComponent_Host_0, {}, {}, []);
export { EditMarketPostComponentNgFactory as EditMarketPostComponentNgFactory };
