<div class="tenant-information-grid">
  <div class="card-body">
    <div *ngIf="loading" class="progress mb-2">
      <div
        role="progressbar"
        style="width: 100%"
        class="progress-bar progress-bar-striped progress-bar-animated bg-info"
      ></div>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="tenantSettingsForm">
      <div class="form-group">
        <label
          >{{ 'VAT number' | translate }}
          <div class="text-muted text-small">
            {{ '(contact hakka if you want to update your VAT number)' }}
          </div></label
        >
        <input class="form-control" formControlName="vatNumber" type="text" />
      </div>
      <div class="form-group">
        <label>{{ 'Company Name' | translate }}</label>
        <input
          class="form-control"
          formControlName="name"
          type="text"
          [ngClass]="{
            'is-invalid':
              tenantSettingsForm.controls['name'].invalid &&
              tenantSettingsForm.controls['name'].touched
          }"
        />
      </div>
      <div class="form-group">
        <label>{{ 'Email' | translate }}</label>
        <input
          class="form-control"
          formControlName="email"
          type="email"
          [ngClass]="{
            'is-invalid':
              tenantSettingsForm.controls['email'].invalid &&
              tenantSettingsForm.controls['email'].touched
          }"
        />
      </div>
      <div class="form-group">
        <label>{{ 'Invoice Email' | translate }}</label>
        <input
          class="form-control"
          formControlName="invoiceEmail"
          type="email"
          [ngClass]="{
            'is-invalid':
              tenantSettingsForm.controls['invoiceEmail'].invalid &&
              tenantSettingsForm.controls['invoiceEmail'].touched
          }"
        />
      </div>
      <div class="form-group">
        <label>{{ 'Bank account number' | translate }}</label>
        <input
          class="form-control"
          formControlName="bankAccountNumber"
          type="string"
          [ngClass]="{
            invalid:
              tenantSettingsForm.controls['bankAccountNumber'].invalid &&
              tenantSettingsForm.controls['bankAccountNumber'].touched
          }"
        />
      </div>
      <div class="form-group">
        <label>{{ 'Phone Number' | translate }}</label>
        <input
          class="form-control"
          formControlName="phoneNumber"
          type="tel"
          [ngClass]="{
            'is-invalid':
              tenantSettingsForm.controls['phoneNumber'].invalid &&
              tenantSettingsForm.controls['phoneNumber'].touched
          }"
        />
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="center-block">{{
              'Street and number' | translate
            }}</label>
            <input
              class="form-control"
              formControlName="streetAndNumber"
              data-cy="streetAndNumber"
            />
            <div class="invalid-feedback">
              {{ 'Street and number is required.' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label class="center-block">{{ 'Zipcode' | translate }}</label>
            <input
              class="form-control"
              formControlName="zipcode"
              data-cy="zipcode"
            />
            <div class="invalid-feedback">
              {{ 'Zipcode is required.' | translate }}
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="center-block">{{ 'City' | translate }}</label>
            <input class="form-control" formControlName="city" data-cy="city" />
            <div class="invalid-feedback">
              {{ 'City is required.' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ 'Prefered Language' | translate }}</label>
        <ng-select
          id="prefered-language"
          [items]="languages"
          bindLabel="label"
          bindValue="value"
          placeholder="Select Prefered Language"
          formControlName="locale"
        >
        </ng-select>
      </div>
      <button
        class="btn btn-primary text-white"
        type="submit"
        [disabled]="tenantSettingsForm.invalid || tenantSettingsForm.pristine"
      >
        <i class="far fa-spinner fa-spin" *ngIf="submitting"></i>
        <i class="far fa-fw fa-save"></i>
        <span class="d-none d-sm-inline">{{ 'Submit' | translate }}</span>
      </button>
    </form>
  </div>
</div>
