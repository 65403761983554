/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./daterangepicker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./daterangepicker.component";
var styles_DaterangepickerComponent = [i0.styles];
var RenderType_DaterangepickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DaterangepickerComponent, data: {} });
export { RenderType_DaterangepickerComponent as RenderType_DaterangepickerComponent };
function View_DaterangepickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "custom-day"]], [[2, "focused", null], [2, "range", null], [2, "faded", null], [2, "outside", null], [2, "text-muted", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.hoveredDate = _v.context.date) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.hoveredDate = null) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.focused; var currVal_1 = (((_co.isFrom(_v.context.date) || _co.isTo(_v.context.date)) || _co.isInside(_v.context.date)) || _co.isHovered(_v.context.date)); var currVal_2 = (_co.isHovered(_v.context.date) || _co.isInside(_v.context.date)); var currVal_3 = _co.markDisabled(_v.context.date); var currVal_4 = _co.markDisabled(_v.context.date); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.date.day; _ck(_v, 1, 0, currVal_5); }); }
export function View_DaterangepickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngb-datepicker", [["outsideDays", "hidden"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onDateSelection($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbDatepicker_0, i2.RenderType_NgbDatepicker)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.NgbDatepicker]), i1.ɵprd(512, null, i4.ɵs, i4.ɵs, [i4.NgbCalendar, i4.NgbDatepickerI18n]), i1.ɵprd(512, null, i4.ɵt, i4.ɵt, [i4.ɵs, i4.NgbCalendar]), i1.ɵdid(4, 4964352, [["dp", 4]], 0, i4.NgbDatepicker, [i4.ɵt, i4.ɵs, i4.NgbCalendar, i4.NgbDatepickerI18n, i4.NgbDatepickerConfig, i1.ChangeDetectorRef, i1.ElementRef, i4.NgbDateAdapter, i1.NgZone], { dayTemplate: [0, "dayTemplate"], displayMonths: [1, "displayMonths"], minDate: [2, "minDate"], outsideDays: [3, "outsideDays"] }, { select: "select" }), (_l()(), i1.ɵand(0, [["t", 2]], null, 0, null, View_DaterangepickerComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5); var currVal_1 = _co.displayMonths; var currVal_2 = _co.minDate; var currVal_3 = "hidden"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DaterangepickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-daterangepicker", [], null, null, null, View_DaterangepickerComponent_0, RenderType_DaterangepickerComponent)), i1.ɵdid(1, 49152, null, 0, i5.DaterangepickerComponent, [], null, null)], null, null); }
var DaterangepickerComponentNgFactory = i1.ɵccf("app-daterangepicker", i5.DaterangepickerComponent, View_DaterangepickerComponent_Host_0, { fromDate: "fromDate", toDate: "toDate", markDisabledBeforeMindate: "markDisabledBeforeMindate", displayMonths: "displayMonths", minDate: "minDate" }, { dateSelected: "dateSelected" }, []);
export { DaterangepickerComponentNgFactory as DaterangepickerComponentNgFactory };
