import { MatchingComponent } from './matching.component';
import { AuthGuard } from '../core/user/user.authguard';
import { Routes } from '@angular/router';
import { Route } from '../core/route.service';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';
var defaultMatchingGaurds = [
    AuthGuard,
    TenantGuard,
    SubscriptionGuard,
    DriverGuard,
    AllowedSubscriptionGuard
];
var ɵ0 = { subscriptionCode: 'MATCHING' }, ɵ1 = { subscriptionCode: 'MATCHING' };
var routes = Route.withShell([
    {
        path: 'matching',
        canActivate: defaultMatchingGaurds.slice(),
        data: ɵ0,
        children: [
            {
                path: '',
                component: MatchingComponent,
                canActivate: defaultMatchingGaurds.slice(),
                data: ɵ1
            }
        ]
    }
]);
var MatchingRoutingModule = /** @class */ (function () {
    function MatchingRoutingModule() {
    }
    return MatchingRoutingModule;
}());
export { MatchingRoutingModule };
export { ɵ0, ɵ1 };
