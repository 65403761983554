<form
  *ngIf="customerForm"
  (ngSubmit)="onSubmit()"
  [formGroup]="customerForm"
  class="p-3 grid-container"
>
  <div class="form-group" id="vat-number-fg">
    <input
      class="form-control"
      formControlName="vatNumber"
      data-cy="vatNumber"
      placeholder="{{ 'VAT Number' | translate }}"
      [ngClass]="{
        'is-invalid':
          customerForm.controls['vatNumber'].invalid &&
          customerForm.controls['vatNumber'].touched
      }"
    />
    <div class="invalid-feedback">
      {{ 'VAT number is required.' | translate }}
    </div>
  </div>
  <div class="form-group" id="name-fg">
    <input
      class="form-control"
      formControlName="label"
      type="text"
      placeholder="{{ 'Name' | translate }}"
      data-cy="label"
      [ngClass]="{
        'is-invalid':
          customerForm.controls['label'].invalid &&
          customerForm.controls['label'].touched
      }"
    />
    <div class="invalid-feedback">
      {{ 'Company name is required.' | translate }}
    </div>
  </div>
  <div class="company-info">
    <div id="billing-address" formGroupName="billingAddress">
      <span class="form-section-title">{{
        'Billing address' | translate
      }}</span>
      <div class="form-group" id="attention-fg">
        <input
          class="form-control"
          formControlName="attention"
          type="text"
          data-cy="billingAddressAttention"
          placeholder="{{ 'Attention' | translate }}"
          [ngClass]="{
            'is-invalid':
              customerForm.get('billingAddress.attention').invalid &&
              customerForm.get('billingAddress.attention').touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Attention is required.' | translate }}
        </div>
      </div>
      <div class="form-group" id="street-and-number-fg">
        <input
          class="form-control"
          formControlName="streetAndNumber"
          data-cy="billingAddressStreetAndNumber"
          placeholder="{{ 'Street + number' | translate }}"
          [ngClass]="{
            'is-invalid':
              customerForm.get('billingAddress.streetAndNumber')?.invalid &&
              customerForm.get('billingAddress.streetAndNumber').touched
          }"
        />
        <div class="invalid-feedback">
          {{ 'Street and number is required.' | translate }}
        </div>
      </div>
      <div class="split-fg">
        <div class="form-group" id="zipcode-fg">
          <input
            class="form-control"
            formControlName="zipCode"
            data-cy="billingAddressZipCode"
            placeholder="{{ 'Zip Code' | translate }}"
            [ngClass]="{
              'is-invalid':
                customerForm.get('billingAddress.zipCode').invalid &&
                customerForm.get('billingAddress.zipCode').touched
            }"
          />
          <div class="invalid-feedback">
            {{ 'Zip Code is required.' | translate }}
          </div>
        </div>
        <div class="form-group" id="city-fg">
          <input
            class="form-control"
            formControlName="city"
            data-cy="billingAddressCity"
            placeholder="{{ 'City' | translate }}"
            [ngClass]="{
              'is-invalid':
                customerForm.get('billingAddress.city').invalid &&
                customerForm.get('billingAddress.city').touched
            }"
          />
          <div class="invalid-feedback">
            {{ 'City is required.' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group" id="country-fg">
        <ng-select
          id="country"
          [items]="countries"
          data-cy="billingAddressCountry"
          bindLabel="label"
          bindValue="value"
          placeholder="{{ 'Select country' | translate }}"
          formControlName="country"
          [ngClass]="{
            'is-invalid':
              customerForm.get('billingAddress.country').invalid &&
              customerForm.get('billingAddress.country').touched
          }"
        >
        </ng-select>
        <div class="invalid-feedback">
          {{ 'Country is required.' | translate }}
        </div>
      </div>
      <div class="form-group">
        <input
          class="form-control"
          formControlName="telephone"
          type="tel"
          data-cy="billingAddressPhoneNumber"
          placeholder="{{ 'Phone number' | translate }}"
        />
        <div class="invalid-feedback">
          {{ 'Phone number is required.' | translate }}
        </div>
      </div>
    </div>
    <div id="main-contact-info">
      <div formGroupName="defaultContact">
        <span class="form-section-title">{{ 'Main contact' | translate }}</span>
        <div class="form-group">
          <input
            class="form-control"
            formControlName="salutation"
            data-cy="defaultContactSalutation"
            placeholder="{{ 'Salutation' | translate }}"
            [ngClass]="{
              'is-invalid':
                customerForm.get('defaultContact.salutation').invalid &&
                customerForm.get('defaultContact.salutation').touched
            }"
          />
          <div class="invalid-feedback">
            {{ 'Salutation is required.' | translate }}
          </div>
        </div>
        <div class="split-fg">
          <div class="form-group">
            <input
              class="form-control"
              formControlName="firstName"
              data-cy="defaultContactFirstName"
              type="text"
              placeholder="{{ 'First name' | translate }}"
              [ngClass]="{
                'is-invalid':
                  customerForm.get('defaultContact.firstName').invalid &&
                  customerForm.get('defaultContact.firstName').touched
              }"
            />
            <div class="invalid-feedback">
              {{ 'First name is required.' | translate }}
            </div>
          </div>
          <div class="form-group">
            <input
              class="form-control"
              formControlName="lastName"
              type="text"
              data-cy="defaultContactLastName"
              placeholder="{{ 'Last name' | translate }}"
              [ngClass]="{
                'is-invalid':
                  customerForm.get('defaultContact.lastName').invalid &&
                  customerForm.get('defaultContact.lastName').touched
              }"
            />
            <div class="invalid-feedback">
              {{ 'Last name is required.' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <input
            class="form-control"
            formControlName="email"
            type="email"
            data-cy="defaultContactEmail"
            placeholder="{{ 'E-mail address' | translate }}"
            [ngClass]="{
              'is-invalid':
                customerForm.get('defaultContact.email').invalid &&
                customerForm.get('defaultContact.email').touched
            }"
          />
          <div class="invalid-feedback">
            {{ 'E-mail is required.' | translate }}
          </div>
        </div>
        <div class="form-group">
          <input
            class="form-control"
            formControlName="telephone"
            type="tel"
            data-cy="defaultContactPhoneNumber"
            placeholder="{{ 'Phone number' | translate }}"
          />
          <div class="invalid-feedback">
            {{ 'Phone number is required.' | translate }}
          </div>
        </div>
        <div class="form-group">
          <input
            class="form-control"
            formControlName="designation"
            text="text"
            data-cy="defaultContactFunction"
            placeholder="{{ 'Function (e.g. Manager)' | translate }}"
          />
          <div class="invalid-feedback">
            {{ 'Function is required.' | translate }}
          </div>
        </div>
        <div class="form-group">
          <input
            class="form-control"
            formControlName="department"
            type="text"
            data-cy="defaultContactDepartment"
            placeholder="{{ 'Department (e.g. Sales)' | translate }}"
          />
          <div class="invalid-feedback">
            {{ 'Department is required.' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="submit">
    <app-button [type]="'submit'" id="submit-button" [isLoading]="isLoading">
      <span
        data-cy="submitCustomerButton"
        class="text-white"
        *ngIf="!customer; else edit"
        >{{ 'Create' | translate }}</span
      >
      <ng-template #edit>
        <span class="text-white">{{ 'Update' | translate }}</span>
      </ng-template>
    </app-button>
  </div>
</form>
