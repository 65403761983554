import { Action } from '@ngrx/store';
import * as types from '../types/reload.types';
import { ReloadRequest } from '../models/reload-request.model';

export class LoadAllReloadRequests implements Action {
  readonly type = types.reload.LOAD_ALL_RELOAD_REQUESTS;
  constructor(
    public payload?: {
      page?: number;
      query?: string;
      forLiner?: boolean;
      status?: string;
    }
  ) {}
}

export class LoadAllReloadRequestsSuccess implements Action {
  readonly type = types.reload.LOAD_ALL_SUCCEEDED;
  constructor(public payload: ReloadRequest) {}
}

export class LoadAllReloadRequestsFailed implements Action {
  readonly type = types.reload.LOAD_ALL_FAILED;
  constructor(public payload: any) {}
}

export class CreateReloadRequest implements Action {
  readonly type = types.reload.CREATE_RELOAD_REQUEST;
  constructor(public payload: any) { }
}

export class CreateReloadRequestSuccess implements Action {
  readonly type = types.reload.CREATE_RELOAD_REQUEST_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateReloadRequestFail implements Action {
  readonly type = types.reload.CREATE_RELOAD_REQUEST_FAILED;
  constructor(public payload?: any) { }
}

export class UpdateReloadRequest implements Action {
  readonly type = types.reload.UPDATE_RELOAD_REQUEST;
  constructor(
    public payload: {
      requestId: string;
      request: ReloadRequest;
    }
  ) { }
}

export class UpdateReloadRequestSuccess implements Action {
  readonly type = types.reload.UPDATE_RELOAD_REQUEST_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdateReloadRequestFail implements Action {
  readonly type = types.reload.UPDATE_RELOAD_REQUEST_FAILED;
  constructor(public payload: any) { }
}

export class ForceApproveReloadRequest implements Action {
  readonly type = types.reload.FORCE_APPROVE_RELOAD_REQUEST;
  constructor(
    public payload: {
      requestId: string;
    }
  ) { }
}

export class ForceApproveReloadRequestSuccess implements Action {
  readonly type = types.reload.FORCE_APPROVE_RELOAD_REQUEST_SUCCEEDED;
  constructor(public payload: any) { }
}

export class ForceApproveReloadRequestFail implements Action {
  readonly type = types.reload.FORCE_APPROVE_RELOAD_REQUEST_FAILED;
  constructor(public payload: any) { }
}

export class CancelReloadRequest implements Action {
  readonly type = types.reload.CANCEL_RELOAD_REQUEST;
  constructor(
    public payload: {
      requestId: string;
    }
  ) { }
}

export class CancelReloadRequestByLiner implements Action {
  readonly type = types.reload.CANCEL_RELOAD_REQUEST_BY_LINER;
  constructor(
    public payload: {
      requestId: string;
    }
  ) { }
}

export class CancelReloadRequestSuccess implements Action {
  readonly type = types.reload.CANCEL_RELOAD_REQUEST_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CancelReloadRequestFail implements Action {
  readonly type = types.reload.CANCEL_RELOAD_REQUEST_FAILED;
  constructor(public payload: any) { }
}

export type All
  = LoadAllReloadRequests
  | LoadAllReloadRequestsSuccess
  | LoadAllReloadRequestsFailed
  | CreateReloadRequest
  | CreateReloadRequestSuccess
  | CreateReloadRequestFail
  | UpdateReloadRequest 
  | UpdateReloadRequestSuccess
  | UpdateReloadRequestFail
  | ForceApproveReloadRequest 
  | ForceApproveReloadRequestSuccess
  | ForceApproveReloadRequestFail
  | CancelReloadRequest 
  | CancelReloadRequestSuccess
  | CancelReloadRequestFail
  | CancelReloadRequestByLiner;
