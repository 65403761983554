/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../reload-form/reload-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../shared/datepicker-config";
import * as i4 from "../reload-form/reload-form.component";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/forms";
import * as i7 from "../../services/toast.service";
import * as i8 from "../../services/validation.service";
import * as i9 from "../../services/location.service";
import * as i10 from "../../core/api/api.service";
import * as i11 from "@ngrx/effects";
import * as i12 from "./new-reload-request.component";
import * as i13 from "@angular/router";
import * as i14 from "@ngx-translate/core";
var styles_NewReloadRequestComponent = [];
var RenderType_NewReloadRequestComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewReloadRequestComponent, data: {} });
export { RenderType_NewReloadRequestComponent as RenderType_NewReloadRequestComponent };
export function View_NewReloadRequestComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-cmp-reload-form", [], null, [[null, "reloadFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reloadFormSubmitted" === en)) {
        var pd_0 = (_co.reloadFormSubmitted($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ReloadFormComponent_0, i1.RenderType_ReloadFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i3.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 770048, null, 0, i4.ReloadFormComponent, [i5.Store, i6.FormBuilder, i7.ToastService, i8.ValidationService, i9.LocationService, i10.ApiService, i2.NgbModal, i11.Actions], { loading: [0, "loading"], reset: [1, "reset"] }, { reloadFormSubmitted: "reloadFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.reset; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_NewReloadRequestComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-new-reload-request", [], null, null, null, View_NewReloadRequestComponent_0, RenderType_NewReloadRequestComponent)), i0.ɵdid(1, 180224, null, 0, i12.NewReloadRequestComponent, [i5.Store, i11.Actions, i7.ToastService, i13.Router, i14.TranslateService], null, null)], null, null); }
var NewReloadRequestComponentNgFactory = i0.ɵccf("app-cmp-new-reload-request", i12.NewReloadRequestComponent, View_NewReloadRequestComponent_Host_0, {}, {}, []);
export { NewReloadRequestComponentNgFactory as NewReloadRequestComponentNgFactory };
