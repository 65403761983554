/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-user-location.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-location-form/user-location-form.component.ngfactory";
import * as i3 from "../user-location-form/user-location-form.component";
import * as i4 from "../../../services/location.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../services/toast.service";
import * as i7 from "./new-user-location.component";
import * as i8 from "@ngrx/effects";
import * as i9 from "@angular/router";
var styles_NewUserLocationComponent = [i0.styles];
var RenderType_NewUserLocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewUserLocationComponent, data: {} });
export { RenderType_NewUserLocationComponent as RenderType_NewUserLocationComponent };
export function View_NewUserLocationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "new-user-location-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-user-location-form", [], null, [[null, "userLocationFormSubmitted"], [null, "addressChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("userLocationFormSubmitted" === en)) {
        var pd_0 = (_co.createNewUserLocation($event) !== false);
        ad = (pd_0 && ad);
    } if (("addressChanged" === en)) {
        var pd_1 = (_co.updateMarkerByAddress($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_UserLocationFormComponent_0, i2.RenderType_UserLocationFormComponent)), i1.ɵdid(2, 770048, null, 0, i3.UserLocationFormComponent, [i4.LocationService, i5.Store, i6.ToastService], { isLoading: [0, "isLoading"], coordinates: [1, "coordinates"] }, { userLocationFormSubmitted: "userLocationFormSubmitted", addressChanged: "addressChanged" }), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "div", [["class", "map mb-3"], ["id", "user-location-map"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = _co.coordinates; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NewUserLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-user-location", [], null, null, null, View_NewUserLocationComponent_0, RenderType_NewUserLocationComponent)), i1.ɵdid(1, 245760, null, 0, i7.NewUserLocationComponent, [i5.Store, i8.Actions, i6.ToastService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewUserLocationComponentNgFactory = i1.ɵccf("app-new-user-location", i7.NewUserLocationComponent, View_NewUserLocationComponent_Host_0, {}, {}, []);
export { NewUserLocationComponentNgFactory as NewUserLocationComponentNgFactory };
