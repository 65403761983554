import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/container-type.types';
import * as ContainerTypesActions from '../actions/container-types.actions';
import * as NotificationsActions from '../actions/notifications.actions';

@Injectable()
export class ContainerTypesEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadContainerTypes$: Observable<Action> = this.actions$.pipe(
    ofType(types.containerTypes.LOAD_CONTAINER_TYPES),
    map((action: ContainerTypesActions.LoadContainerTypes) => action.payload),
    concatMap(() => this.api.get({ path: '/container_types' }).pipe(
      map(data => new ContainerTypesActions.LoadContainerTypesSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );
}
