<app-cmp-navbar
  [navbarTitle]="'Manage Tenants'"
  [showButton]="true"
  [buttonText]="'Download as CSV'"
  [buttonIcon]="'fa-download'"
  [buttonType]="'btn-info'"
  (buttonClicked)="downloadAsCSV()"
></app-cmp-navbar>
<ngx-datatable
  class="bootstrap row ml-0 mr-0"
  [rows]="tenants"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="'auto'"
  [externalPaging]="false"
  [count]="totalElements"
  [limit]="30"
  [loadingIndicator]="loading"
>
  <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
  <ngx-datatable-column name="Credits" prop="wallet">
    <ng-template ngx-datatable-cell-template let-value="value">
      <span>{{ value.credits }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Allowed Subs" prop="allowedSubscriptionTypes">
    <ng-template ngx-datatable-cell-template let-value="value">
      <div *ngFor="let subscription of value">
        <span>{{ subscription.name }}</span>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Subscriptions" prop="subscriptions">
    <ng-template ngx-datatable-cell-template let-value="value">
      <div
        *ngFor="let subscription of value"
        class="d-flex justify-content-between"
      >
        <span>{{ subscription.subscriptionType.name }}</span>
        <span>{{ getSubscriptionStatus(subscription) }}</span>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="" prop="enabled" maxWidth="50px">
    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
      <button class="btn btn-warning" *ngIf="value" (click)="disable(row.id)">
        {{ 'Disable' | translate }}
      </button>
      <button class="btn btn-success" *ngIf="!value" (click)="enable(row.id)">
        {{ 'Enable' | translate }}
      </button>
      <button class="btn btn-info" (click)="edit(row.id)">
        <i class="far fa-pencil"></i> <span>{{ 'Edit' | translate }}</span>
      </button>
      <button class="btn btn-danger" (click)="delete(row.id)">
        <i class="far fa-bomb"></i> <span>{{ 'Delete' | translate }}</span>
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
