import {
  LoadSchedule,
  LoadUnplannedTransports,
  AddToSchedule,
  RemoveFromSchedule,
  LoadNextUnplannedTransportsPage,
  UpdateScheduleSequence
} from 'app/core/store/actions/schedule.actions';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Driver } from 'app/core/store/models/driver.model';
import { LoadDrivers } from 'app/core/store/actions/driver.actions';
import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewDriverModalComponent } from '../drivers/new-driver-modal/new-driver-modal.component';
import { DragulaService } from 'ng2-dragula';
import { Subscription, Subject } from 'rxjs';
import * as ScheduleTypes from 'app/core/store/types/schedule.types';
import { Actions, ofType } from '@ngrx/effects';
import { extract } from 'app/services/i18n.service';
import { Transport } from 'app/core/store/models/transports.model';
import { ToastService } from 'app/services/toast.service';
import * as DateFns from 'date-fns';
import * as autoScroll from 'dom-autoscroller';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements AfterViewInit, OnDestroy {
  title = extract('Planning');
  today = new Date();
  drivers: Driver[] = [];
  transports: Transport[] = [];
  subs = new Subscription();
  dayChange = new Subject();
  searchTextChanged = new Subject();
  isLoading: boolean;
  dragulaTransports = 'dragulaTransports';
  dates = [];
  page = 1;
  query = '';
  schedule: any[];
  searching: boolean;
  loadingItems = [];
  nextPage: number;
  scroll: any;
  scrollElements: any;

  constructor(
    private dragulaService: DragulaService,
    private store: Store<State>,
    private modalService: NgbModal,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.destroyDragulaGroupIfExists(this.dragulaTransports);
    // Disable dragging and dropping of elements that are loading
    this.dragulaService.createGroup(this.dragulaTransports, {
      moves: el => {
        const firstChild = el.firstChild as HTMLElement;
        return !firstChild.classList.contains('is-loading');
      }
    });

    this.setDates();
    this.store.dispatch(
      new LoadDrivers({ includeSuperAdmins: 0, roleName: 'DRIVER' })
    );
    const endDate = DateFns.addDays(this.today, 4);
    this.loadTransportsBySelectedDate(this.today, endDate);
    this.loadScheduleBySelectedDate(this.today, endDate);
    this.store
      .select(state => state.drivers)
      .pipe(untilDestroyed(this))
      .subscribe(driversState => {
        if (driversState && driversState.drivers) {
          this.drivers = driversState.drivers;
          this.addDatesToDrivers();
          this.assignScheduleToDrivers();
        }
      });
    this.store
      .select(state => {
        if (state.tms.schedule && state.tms.schedule.unplannedTransports) {
          return state.tms.schedule.unplannedTransports;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(unplannedTransports => {
        if (!unplannedTransports) {
          return;
        }
        this.transports = [...unplannedTransports];
        this.isLoading = false;
        this.toastr.clear();
        this.dragulaTransports = 'dragulaTransports'; // enables dragging and dropping
      });
    this.store
      .select(state => {
        if (
          state.tms.schedule &&
          state.tms.schedule.nextUnplannedTransportsPage
        ) {
          return state.tms.schedule.nextUnplannedTransportsPage;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(nextPage => {
        if (!nextPage) {
          return;
        }
        this.nextPage = nextPage;
      });
    this.store
      .select(state => {
        if (state.tms.schedule && state.tms.schedule.schedule) {
          return state.tms.schedule.schedule;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(schedule => {
        if (!schedule) {
          return;
        }
        this.schedule = [...schedule];
        this.isLoading = false;
        this.dragulaTransports = 'dragulaTransports'; // enables dragging and dropping
        this.assignScheduleToDrivers();
      });

    /**
     * Don't send a load request each time the use changes the date.
     * This way we prevent the server getting flooded with requests.
     */
    this.dayChange
      .pipe(debounceTime(200))
      .pipe(untilDestroyed(this))
      .subscribe((data: { fromDate: string; toDate: string }) => {
        this.today = new Date(data.fromDate);
        this.loadTransportsBySelectedDate(data.fromDate, data.toDate);
        this.loadScheduleBySelectedDate(data.fromDate, data.toDate);
        this.setDates();
        this.addDatesToDrivers();
      });

    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(() =>
          this.loadTransportsBySelectedDate(this.today, this.dateFromToday(4))
        )
      )
      .subscribe(() => { });

    this.subs.add(
      this.dragulaService
        .dropModel()
        .pipe(untilDestroyed(this))
        .subscribe(({ target, item }) => {
          if (!item) {
            return;
          }
          if (target.id === 'unplanned-transports-list') {
            const transportTaskIds = item.transportTasks.map(tt => tt.id);
            this.store.dispatch(
              new RemoveFromSchedule({
                transportTasks: transportTaskIds
              })
            );
            item['isLoading'] = true;
            this.loadingItems.push(item);
          } else {
            const driverId = target.getAttribute('driver-id');
            const date = DateFns.format(
              target.getAttribute('date'),
              'YYYY-MM-DD'
            );
            const transportTaskIds = item.transportTasks.map(tt => tt.id);

            item['isLoading'] = true;
            this.loadingItems.push(item);
            this.store.dispatch(
              new AddToSchedule({
                driver: driverId,
                date: date,
                transportTasks: transportTaskIds
              })
            );
          }
        })
    );
    this.updates$
      .pipe(ofType(ScheduleTypes.schedule.ADD_TO_SCHEDULE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        const payload = res['payload'];
        const driverId = payload.driverId;
        const date = payload.date;
        let transportTaskIds = payload.transportTaskIds;
        const transportIds = payload.transportIds;

        // Update schedule sequence
        this.updateScheduleSequence(driverId, this.getParsedDate(date));
        // Remove isLoading from transport
        this.removeIsLoadingFromTransport(transportIds[0]);
        if (!driverId) {
          return;
        }
        const driver = this.drivers.find(d => d.id === driverId);
        if (!driver) {
          return;
        }
        const transports =
          driver['plannedTransports'][this.getParsedDate(date)];
        const transportIndex = transports.findIndex(
          t => t.id === transportIds[0]
        );
        const transport = transports[transportIndex];
        if (!transport) {
          return;
        }
        transportTaskIds.forEach(transportTaskId => {
          // Check if transport task is part of transport
          let transportTask = transport.transportTasks.find(
            tTask => tTask.id === transportTaskId
          );
          if (!transportTask) {
            return;
          }
          transportTask['assignedTo'] = driver;
          // Add badge color
          transportTask = this.validateTransportTask(transportTask, driverId);
          // Since the task has been found, we can remove it from the array
          transportTaskIds = transportTaskIds.filter(
            id => id !== transportTaskId
          );
        });
        transport['isLoading'] = false;
        // Update transport by object.assign so we avoid reference bugs
        transports[transportIndex] = Object.assign({}, transport);
      });
    this.updates$
      .pipe(ofType(ScheduleTypes.schedule.REMOVE_FROM_SCHEDULE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        const payload = res['payload'];
        let transportTaskIds = payload.transportTaskIds;
        const transportIds = payload.transportIds;

        // remove isLoading from transport
        this.removeIsLoadingFromTransport(transportIds[0]);

        // set correct badge color on transport tasks
        if (res['payload']['driverId']) {
          return;
        }
        transportIds.forEach(transportId => {
          const transportIndex = this.transports.findIndex(
            t => t.id === transportIds[0]
          );
          const transport = this.transports.find(t => t.id === transportId);
          if (!transport || !transportTaskIds || transportIds.length === 0) {
            return;
          }
          transport['isLoading'] = false;
          transportTaskIds.forEach(transportTaskId => {
            // Check if transport task is part of transport
            const transportTask = transport.transportTasks.find(
              tTask => tTask.id === transportTaskId
            );
            if (!transportTask) {
              return;
            }
            // Remove error
            delete transportTask['error'];
            // Reomve badge color
            delete transportTask['badgeColor'];
            // Since the task has been found, we can remove it from the array
            transportTaskIds = transportTaskIds.filter(
              id => id !== transportTaskId
            );
          });
          this.transports[transportIndex] = Object.assign({}, transport);
        });
      });
  }

  search($event) {
    this.searchTextChanged.next($event.target.value);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeScroll();
    }, 500);
  }
  initializeScroll() {
    const elements = Array.from(document.querySelectorAll('.planning'));
    // Enable autoscrolling
    this.scrollElements = autoScroll([...elements], {
      margin: 100,
      maxSpeed: 30,
      scrollWhenOutside: false,
      autoScroll: function() {
        // Only scroll when the pointer is down
        return this.down;
      }
    });
  }

  destroyDragulaGroupIfExists(group: string): any {
    if (!!this.dragulaService.find(group)) {
      this.dragulaService.destroy(group);
    }
  }

  updateScheduleSequence(driverId: string, date: string) {
    const driver = this.drivers.find(d => d.id === driverId);
    if (!driver) {
      return;
    }
    const plannedTransports = driver['plannedTransports'][date];
    if (!plannedTransports) {
      return;
    }

    // {id: transportTaskId, sequence:1 }
    const sequence = [];
    let index = 0;
    plannedTransports.forEach((transport: Transport) => {
      transport.transportTasks.forEach(task => {
        sequence.push({
          id: task.id,
          sequence: index
        });
        index++;
      });
    });
    this.store.dispatch(new UpdateScheduleSequence(sequence));
  }
  removeIsLoadingFromTransport(transportId: string) {
    this.loadingItems = this.loadingItems.filter(
      item => item.id !== transportId
    );
  }

  validateTransportTask(task, driverId) {
    if (
      task['visit'] && // Task has a visit
      task['assignedTo'] && // Task is assigned to a driver
      driverId &&
      task['visit'].driver &&
      task['visit'].driver.id &&
      task['visit'].driver.id !== driverId
    ) {
      task['error'] = extract(
        'This task has a prenotification that is assigned to another driver'
      );
      task['badgeColor'] = 'badge-danger';
      return task;
    }
    task['badgeColor'] = 'badge-success';
    task['error'] = null;
    return task;
  }
  assignScheduleToDrivers() {
    if (!this.schedule || this.drivers.length === 0) {
      return;
    }
    const that = this;
    this.drivers.forEach(driver => {
      // Clear current driver schedule
      Object.keys(driver['plannedTransports']).forEach(k => {
        driver['plannedTransports'][k] = [];
      });

      let driverSchedule = that.schedule.filter(s => s.driverId === driver.id);
      if (driverSchedule.length === 0) {
        return;
      }

      driverSchedule = driverSchedule[0]['schedule'];
      // Loop over the schedule and assign the correct badge color the the tasks
      // that are assigned to the current driver
      Object.keys(driverSchedule).forEach(k => {
        driverSchedule[k].forEach((transport: Transport) => {
          transport.transportTasks.forEach(task => {
            task = this.validateTransportTask(task, driver.id);
          });
        });
      });

      driver['plannedTransports'] = Object.assign(
        {},
        driver['plannedTransports'],
        driverSchedule
      );
    });
  }

  addDatesToDrivers() {
    this.drivers.forEach(driver => {
      if (!driver['plannedTransports']) {
        driver['plannedTransports'] = {};
      }
      this.dates.forEach(date => {
        if (!driver['plannedTransports'][this.getParsedDate(date)]) {
          driver['plannedTransports'][this.getParsedDate(date)] = [];
        }
      });
    });
  }
  getPlannedTransports(driver, date) {
    return driver['plannedTransports'][this.getParsedDate(date)];
  }

  getParsedDate(date) {
    return DateFns.format(date, 'YYYY-MM-DD');
  }

  dateFromToday(days: number) {
    return DateFns.addDays(this.today, days);
  }
  setDates() {
    const days = 5;
    const tempDates = [];
    for (let index = 0; index < days; index++) {
      const date = this.dateFromToday(index);
      tempDates.push(date);
    }
    this.dates = [...tempDates];
  }

  updatePlanning(event) {
    let transportIds = [];
    event['transports'].forEach((transport: Transport) => {
      const transportTasks = transport.transportTasks.map(
        transportTask => transportTask.id
      );
      if (transportIds.length === 0) {
        transportIds = [...transportTasks];
      } else {
        transportIds = [...transportTasks, ...transportIds];
      }
    });
    this.store.dispatch(
      new AddToSchedule({
        driver: event['driverId'],
        date: DateFns.format(event['date'], 'YYYY-MM-DD'),
        transportTasks: transportIds
      })
    );
  }

  addDay() {
    this.today = DateFns.addDays(this.today, 1);
    const date = DateFns.addDays(this.today, 4);
    this.dayChange.next({ fromDate: date, toDate: date });
  }
  subtractDay() {
    this.today = DateFns.subDays(this.today, 1);
    this.dayChange.next({ fromDate: this.today, toDate: this.today });
  }

  loadScheduleBySelectedDate(startDate, endDate) {
    this.isLoading = true;
    this.store.dispatch(
      new LoadSchedule({
        fromDate: `${DateFns.getYear(startDate)}-${DateFns.getMonth(startDate) +
          1}-${DateFns.getDate(startDate)}`,
        toDate: `${DateFns.getYear(endDate)}-${DateFns.getMonth(endDate) +
          1}-${DateFns.getDate(endDate)}`
      })
    );
  }

  loadTransportsBySelectedDate(startDate, endDate) {
    this.isLoading = true;
    // this.dragulaTransports = null; // disables dagging and dropping
    this.store.dispatch(
      new LoadUnplannedTransports({
        fromDate: `${DateFns.getYear(startDate)}-${DateFns.getMonth(startDate) +
          1}-${DateFns.getDate(startDate)}`,
        toDate: `${DateFns.getYear(endDate)}-${DateFns.getMonth(endDate) +
          1}-${DateFns.getDate(endDate)}`,
        page: this.page.toString(),
        query: this.query
      })
    );
  }

  launchNewDriverModal() {
    this.modalService.open(NewDriverModalComponent);
  }

  onScrollDown() {
    if (!this.nextPage) {
      return;
    }
    const startDate = this.today;
    const endDate = DateFns.addDays(this.today, 4);
    this.store.dispatch(
      new LoadNextUnplannedTransportsPage({
        page: this.nextPage,
        query: this.query,
        fromDate: `${DateFns.getYear(startDate)}-${DateFns.getMonth(startDate) +
          1}-${DateFns.getDate(startDate)}`,
        toDate: `${DateFns.getYear(endDate)}-${DateFns.getMonth(endDate) +
          1}-${DateFns.getDate(endDate)}`
      })
    );
    this.toastr.showInfoLoading({
      message: extract('Loading extra transports...')
    });
  }

  ngOnDestroy() {
    this.destroyDragulaGroupIfExists(this.dragulaTransports);
  }
}
