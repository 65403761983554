var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { catchError, concatMap, map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as types from '../types/contacts.types';
import * as ContactsActions from '../actions/contacts.actions';
import { ApiService } from '../../api/api.service';
var ContactsEffects = /** @class */ (function () {
    function ContactsEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadContacts$ = this.actions$.pipe(ofType(types.contacts.LOAD_CONTACTS), map(function (action) { return action.payload; }), concatMap(function () { return _this.api.get({ path: '/contacts' }).pipe(map(function (data) { return new ContactsActions.LoadContactsSuccess(data); }), catchError(function (error) { return of(new ContactsActions.LoadContactsSuccess(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ContactsEffects.prototype, "loadContacts$", void 0);
    return ContactsEffects;
}());
export { ContactsEffects };
