import { Transport } from 'app/core/store/models/transports.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-planning-day',
  templateUrl: './planning-day.component.html',
})
export class PlanningDayComponent {
  @Input()
  driverId: string;
  @Input()
  date: string;
  @Input()
  transports: Transport[];
  @Output()
  planningUpdated = new EventEmitter<{
    driverId: string;
    date: string;
  }>();
}
