<div class="p-3">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="center-block" for="privateLocation">{{
          'Name' | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          data-cy="privateLocationName"
          [(ngModel)]="privateLocationName"
          [ngClass]="{ 'is-invalid': submitAttempt && !privateLocationName }"
          placeholder="{{ 'Enter private location name here' | translate }}"
        />
      </div>
      <div class="form-group">
        <label class="center-block" for="privateLocation">{{
          'Address' | translate
        }}</label>
        <input
          placeholder="{{ 'Enter address here' | translate }}"
          [(ngModel)]="privateLocationAddress"
          [ngClass]="{
            'is-invalid': submitAttempt && !privateLocationAddress
          }"
          (ngModelChange)="
            updatePublicLocation();
            handleAddressChange();
            updatePrivateLocationGeoPoints()
          "
          data-cy="privateLocationAddress"
          id="privateLocationAddress"
          class="form-control"
          [ngbTypeahead]="searchAddress"
          [resultFormatter]="locationService.addressFormatter"
          [inputFormatter]="locationService.addressFormatter"
        />
      </div>
      <div class="mt-3 mb-3 coordinates">
        <input
          [ngClass]="{
            'is-invalid': submitAttempt && !privateLocationLatitude
          }"
          type="text"
          class="form-control"
          disabled="true"
          [(ngModel)]="privateLocationLatitude"
          placeholder="{{ 'Latitude' | translate }}"
        />
        <input
          [ngClass]="{
            'is-invalid': submitAttempt && !privateLocationLongitude
          }"
          type="text"
          disabled="true"
          class="form-control"
          [(ngModel)]="privateLocationLongitude"
          placeholder="{{ 'Longitude' | translate }}"
        />
        <div
          *ngIf="
            submitAttempt &&
            !userLocation &&
            (!privateLocationLatitude || !privateLocationLongitude)
          "
          class="text-danger"
        >
          {{
            'Please make sure we can find your location by selecting a location on the map'
              | translate
          }}
        </div>
      </div>
      <div class="form-group">
        <label class="center-block" for="publicLocation"
          >{{ 'Location visible to others' | translate }}
          <span
            class="icon"
            ngbTooltip="{{
              'The exact address is never shared but instead this public location is used for matching and marketplace publications if necessary.'
                | translate
            }}"
          >
            <i class="far fa-question-circle"></i>
          </span>
        </label>
        <div class="input-group location-input">
          <input
            placeholder="{{
              'Enter location visible to others here' | translate
            }}"
            [ngClass]="{ 'is-invalid': submitAttempt && !publicLocation }"
            [(ngModel)]="publicLocation"
            id="publicLocation"
            class="form-control"
            [ngbTypeahead]="searchLocation"
            data-cy="publicLocation"
            [resultFormatter]="locationService.locationFormatter"
            [inputFormatter]="locationService.locationFormatter"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i
                *ngIf="!isLoadingPublicLocation"
                class="far fa-location-arrow"
              ></i>
              <i
                *ngIf="isLoadingPublicLocation"
                class="far fa-spinner fa-spin"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
  <div class="row">
    <div class="col">
      <button
        class="button btn btn-primary text-white"
        data-cy="saveLocationButton"
        (click)="onSubmit()"
      >
        <span
          translate
          class="btn__label"
          [ngClass]="{ invisible: isLoading }"
          >{{ 'Submit' | translate }}</span
        >
        <i
          class="fa fa-spinner fa-spin btn__loader"
          [ngClass]="{ visible: isLoading }"
        ></i>
      </button>
    </div>
  </div>
</div>
