<div class="m-3">
  <ngb-alert *ngIf="alert" [type]="alert.type">
    <i class="fa" [ngClass]="alert.icon"></i>
    <span>{{ alert.message['statusText'] ? alert.message['statusText'] : alert.message | json }}</span>
  </ngb-alert>
  <div class="card">
    <div class="card-header">
      <h3>{{ 'New Location' | translate }}</h3>
    </div>
    <div class="card-body">
      <app-location-form (locationFormSubmitted)="createLocation($event)"></app-location-form>
    </div>
  </div>
</div>
