var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoadDefaultCertificatePrice } from 'app/core/store/actions/certiweight.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { AuthService } from 'app/core/user/user.auth';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
var BuyCertificateFormComponent = /** @class */ (function () {
    function BuyCertificateFormComponent(fb, store, toastr, authService) {
        var _this = this;
        this.fb = fb;
        this.store = store;
        this.toastr = toastr;
        this.authService = authService;
        this.onSubmit = new EventEmitter();
        this.store
            .select('certiweight')
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result.defaultPrice) {
                _this.store.dispatch(new LoadDefaultCertificatePrice());
                return;
            }
            _this.defaultPrice = result.defaultPrice;
        });
        this.store.select('marketplace', 'liners')
            .pipe(untilDestroyed(this))
            .subscribe(function (liners) {
            if (!liners) {
                _this.store.dispatch(new LoadLiners());
                return;
            }
            _this.liners = liners;
        });
        this.store.select('user', 'user')
            .pipe(untilDestroyed(this))
            .subscribe(function (state) {
            if (state) {
                _this.user = state;
                _this.patchForm();
            }
        });
        this.store.select('tenants', 'userTenants')
            .pipe(untilDestroyed(this))
            .subscribe(function (state) {
            if (state) {
                _this.tenant = state.find(function (x) { return x.id === _this.authService.getActiveTenant(); });
                _this.patchForm();
            }
        });
    }
    BuyCertificateFormComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group({
            liner: [this.data.liner['@id'], Validators.required],
            bookingReference: [this.data.bookingReference, Validators.required],
            clientReference: [''],
            sendVGM: [true],
            acceptTC: [null, Validators.required],
            shipperContact: this.fb.group({
                name: [null, Validators.required],
                company: [null, Validators.required],
                address: [null, Validators.required],
            }),
        });
        this.patchForm();
    };
    BuyCertificateFormComponent.prototype.ngOnDestroy = function () { };
    BuyCertificateFormComponent.prototype.patchForm = function () {
        if (!this.form || !this.tenant || !this.user) {
            return;
        }
        var tenant = this.tenant;
        this.form.patchValue({
            shipperContact: {
                name: this.user.firstName + " " + this.user.lastName,
                company: tenant.name,
                address: (tenant.streetAndNumber || '') + ", " + (tenant.zipcode || '') + " " + (tenant.city || '') + ", " + (tenant.country || ''),
            },
        });
    };
    BuyCertificateFormComponent.prototype.showTaC = function () {
        this.toastr.showInfo({
            title: 'Not implemented yet',
        });
    };
    BuyCertificateFormComponent.prototype.submit = function () {
        this.onSubmit.emit(__assign({}, this.form.value, { liner: this.form.value.liner.replace('/api/v1/liners/', ''), clientReference: this.form.value.clientReference || '' }));
    };
    return BuyCertificateFormComponent;
}());
export { BuyCertificateFormComponent };
