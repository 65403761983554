import { Action } from '@ngrx/store';
import * as types from '../types/credit-note.types';

export class LoadCreditNotes implements Action {
  readonly type = types.creditNote.LOAD_CREDIT_NOTES;
  constructor(public payload?: any) { }
}

export class LoadCreditNotesFail implements Action {
  readonly type = types.creditNote.LOAD_CREDIT_NOTES_FAILED;
  constructor(public payload: any) { }
}

export class LoadCreditNotesSuccess implements Action {
  readonly type = types.creditNote.LOAD_CREDIT_NOTES_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadFilteredCreditNotes implements Action {
  readonly type = types.creditNote.LOAD_FILTERED_CREDIT_NOTES;
  constructor(public payload?: any) { }
}

export class LoadFilteredCreditNotesFail implements Action {
  readonly type = types.creditNote.LOAD_FILTERED_CREDIT_NOTES_FAILED;
  constructor(public payload: any) { }
}

export class LoadFilteredCreditNotesSuccess implements Action {
  readonly type = types.creditNote.LOAD_FILTERED_CREDIT_NOTES_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateCreditNote implements Action {
  readonly type = types.creditNote.CREATE_CREDIT_NOTE;
  constructor(public payload?: any) { }
}

export class CreateCreditNoteFail implements Action {
  readonly type = types.creditNote.CREATE_CREDIT_NOTE_FAILED;
  constructor(public payload: any) { }
}

export class CreateCreditNoteSuccess implements Action {
  readonly type = types.creditNote.CREATE_CREDIT_NOTE_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdateCreditNote implements Action {
  readonly type = types.creditNote.UPDATE_CREDIT_NOTE;
  constructor(
    public payload?: {
      creditNoteId: string;
      body: any;
    }
  ) { }
}

export class UpdateCreditNoteFail implements Action {
  readonly type = types.creditNote.UPDATE_CREDIT_NOTE_FAILED;
  constructor(public payload: any) { }
}

export class UpdateCreditNoteSuccess implements Action {
  readonly type = types.creditNote.UPDATE_CREDIT_NOTE_SUCCEEDED;
  constructor(public payload: any) { }
}

export class DeleteCreditNote implements Action {
  readonly type = types.creditNote.DELETE_CREDIT_NOTE;
  constructor(
    public payload?: {
      creditNoteId: string;
    }
  ) { }
}

export class DeleteCreditNoteFail implements Action {
  readonly type = types.creditNote.DELETE_CREDIT_NOTE_FAILED;
  constructor(public payload: any) { }
}

export class DeleteCreditNoteSuccess implements Action {
  readonly type = types.creditNote.DELETE_CREDIT_NOTE_SUCCEEDED;
  constructor(public payload: any) { }
}

export class ClearCreditNotes implements Action {
  readonly type = types.creditNote.CLEAR;
  constructor(public payload?: any) { }
}

export type All =
  | LoadCreditNotes
  | LoadCreditNotesFail
  | LoadCreditNotesSuccess
  | LoadFilteredCreditNotes
  | LoadFilteredCreditNotesFail
  | LoadFilteredCreditNotesSuccess
  | CreateCreditNote
  | CreateCreditNoteSuccess
  | CreateCreditNoteFail
  | DeleteCreditNote
  | DeleteCreditNoteSuccess
  | DeleteCreditNoteFail
  | ClearCreditNotes;
