import { Action } from '@ngrx/store';
import * as types from '../types/container-type.types';

export class LoadContainerTypes implements Action {
  readonly type = types.containerTypes.LOAD_CONTAINER_TYPES;
  constructor(public payload?: any) {}
}

export class LoadContainerTypesSuccess implements Action {
  readonly type = types.containerTypes.LOAD_CONTAINER_TYPES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadContainerTypesFail implements Action {
  readonly type = types.containerTypes.LOAD_CONTAINER_TYPES_FAILED;
  constructor(public payload?: any) {}
}

export type All =
  | LoadContainerTypes
  | LoadContainerTypesSuccess
  | LoadContainerTypesFail;
