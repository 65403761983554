import { Injectable } from '@angular/core';

@Injectable()
export class SoftDeleteService {
  addElementToArrayIfNotExists(element: any, array: Array<any>): Array<any> {
    if (!element) {
      return array;
    }
    const index = array.findIndex(el => el.id === element.id);
    if (index !== -1) {
      array[index] = element;
      return array;
    }
    array.push(element);
    return array;
  }
}
