import { ContainerType } from 'app/core/store/models/container-types.model';
import { Container } from 'app/core/store/models/container.model';
import { Liner } from 'app/core/store/models/liner.model';
import { IconService } from 'app/services/icon.service';
import { ToastService } from 'app/services/toast.service';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Alert } from 'app/core/store/models/alert.model';
import { MarketpostParserService } from 'app/services/marketpost-parser.service';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UpdateTransport } from 'app/core/store/actions/transports.actions';
import { Transport } from 'app/core/store/models/transport.model';
import { LoadTransportTypes } from 'app/core/store/actions/transport-types.actions';
import * as Types from 'app/core/store/types/transport.types';
import * as CustomerTypes from 'app/core/store/types/customer.types';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import { EnableMatching, DisableMatching } from 'app/core/store/actions/matching.actions';
import { MatchingTypes } from 'app/core/store/models/matching.model';
import { extract } from 'app/services/i18n.service';
import { LoadCustomers } from 'app/core/store/actions/customer.actions';
import { Customer } from 'app/core/store/models/customer.model';
import { Product } from 'app/core/store/models/product.model';
import { LoadProducts } from 'app/core/store/actions/product.actions';
import { ApiService } from 'app/core/api/api.service';
import { LoadSuppliers } from 'app/core/store/actions/supplier.actions';
import { Supplier } from 'app/core/store/models/supplier.model';
import { SoftDeleteService } from 'app/services/soft-delete.service';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { LoadContainerTypes } from 'app/core/store/actions/container-types.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-transport',
  templateUrl: './edit-transport.component.html',
})
export class EditTransportComponent implements OnInit, OnDestroy {
  title = extract('Edit transport');
  alert: Alert;
  transportTypes: any[];
  tmsTransportTypes = ['load', 'unload', 'shunt'];
  transport: Transport;
  transportId: string;
  loading: boolean;
  matchingEnabled: any;
  storeSubscription: Subscription;
  routeSub: Subscription;
  isLoadingCustomers: boolean;
  customers: Customer[];
  allCustomers: Customer[];
  suppliers: Supplier[];
  allSuppliers: Supplier[];
  liners: Liner[];
  containerTypes: ContainerType[];
  allContainerTypes: ContainerType[];
  allLiners: Liner[];
  tariffs: Product[];
  allTariffs: Product[];
  supplierTariffs: Product[];
  allSupplierTariffs: Product[];
  isLoadingTariffs: boolean;
  isLoadingSuppliers: boolean;
  isLoadingLiners: boolean;
  isLoadingContainerTypes: boolean;

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private parserService: MarketpostParserService,
    private updates$: Actions,
    private router: Router,
    private toastr: ToastService,
    private iconService: IconService,
    private api: ApiService,
    private softDeleteService: SoftDeleteService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(data => {
      if (!data) {
        return;
      }
      this.transportId = data.get('id');
      if (!this.transportId) {
        return;
      }
      this.getTransportById(this.transportId);
      this.updateCustomersArray(this.transport, this.customers);
      this.updateSuppliersArray(this.transport, this.suppliers);
    });
    this.store
      .select(state => state.marketplace.transportTypes)
      .pipe(untilDestroyed(this))
      .subscribe(transportTypes => {
        if (transportTypes && transportTypes.length !== 0) {
          // Filter out transport types that are not available in TMS
          const temp = transportTypes.filter(
            transportType =>
              this.tmsTransportTypes.indexOf(transportType.label) !== -1
          );
          temp.forEach(type => {
            type['transportTypeTasks'] = type['transportTypeTasks'].map(
              task => {
                return {
                  taskType: {
                    '@id': task.taskType['@id'],
                    icon: this.getTaskIcon(task),
                    id: task.taskType.id,
                    label: task.taskType.code,
                    code: task.taskType.code
                  },
                  location: null,
                  startDate: null,
                  startTime: null,
                  endDate: null,
                  endTime: null,
                  startDateTimeSpecified: false,
                  endDateTimeSpecified: false
                };
              }
            );
          });
          this.transportTypes = [...temp];
        } else {
          this.store.dispatch(new LoadTransportTypes());
        }
      });
    this.store
      .select(state => state.crm.customers)
      .pipe(untilDestroyed(this))
      .subscribe(customersState => {
        if (!customersState || !customersState.customers) {
          this.store.dispatch(new LoadCustomers());
          this.isLoadingCustomers = true;
          return;
        }
        this.customers = [...customersState.customers];
        this.isLoadingCustomers = false;
        this.updateCustomersArray(this.transport, this.customers);
      });
    this.store
      .select(state => state.crm.suppliers)
      .pipe(untilDestroyed(this))
      .subscribe(supplierState => {
        if (!supplierState || !supplierState.suppliers) {
          this.store.dispatch(new LoadSuppliers());
          this.isLoadingSuppliers = true;
          return;
        }
        this.suppliers = [...supplierState.suppliers];
        this.isLoadingSuppliers = false;
        this.updateSuppliersArray(this.transport, this.suppliers);
      });
    this.store
      .select(state => state.marketplace.liners)
      .pipe(untilDestroyed(this))
      .subscribe(linersState => {
        if (!linersState) {
          this.store.dispatch(new LoadLiners());
          this.isLoadingLiners = true;
          return;
        }
        this.liners = [...linersState];
        this.isLoadingLiners = false;
        if (this.transport) {
          this.updateLinersArray(this.transport.container, this.liners);
          this.parserService.liners = this.allLiners;
        }
      });
    this.store
      .select(state => state.containerTypes)
      .pipe(untilDestroyed(this))
      .subscribe(containertTypesState => {
        if (!containertTypesState) {
          this.store.dispatch(new LoadContainerTypes());
          this.isLoadingContainerTypes = true;
          return;
        }
        this.containerTypes = [...containertTypesState];
        this.isLoadingContainerTypes = false;
        if (this.transport) {
          this.updateContainerTypesArray(
            this.transport.container,
            this.containerTypes
          );
          this.parserService.containerTypes = this.allContainerTypes;
        }
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.products &&
          state.crm.products.customerProducts
        ) {
          return state.crm.products.customerProducts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(productsState => {
        if (!productsState) {
          this.store.dispatch(new LoadProducts());
          this.isLoadingTariffs = true;
          return;
        }
        this.tariffs = [...productsState];
        this.isLoadingTariffs = false;
        if (this.transport) {
          this.updateTariffsArray(this.transport, this.tariffs);
        }
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.products &&
          state.crm.products.supplierProducts
        ) {
          return state.crm.products.supplierProducts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(productsState => {
        if (!productsState) {
          this.store.dispatch(new LoadProducts());
          this.isLoadingTariffs = true;
          return;
        }
        this.supplierTariffs = [...productsState];
        this.isLoadingTariffs = false;
        if (this.transport) {
          this.updateSupplierTariffsArray(this.transport, this.supplierTariffs);
        }
      });
    this.updates$
      .pipe(ofType(CustomerTypes.customer.LOAD_CUSTOMERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load customers')
        });
        this.isLoadingCustomers = false;
      });
    this.updates$
      .pipe(ofType(CustomerTypes.customer.CREATE_CUSTOMER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadCustomers());
      });
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.CREATE_SUPPLIER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadSuppliers());
      });
    this.updates$
      .pipe(ofType(Types.transports.UPDATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const transport = result['payload'] as Transport;
        if (this.matchingEnabled && !transport.partOfMatchingPoolItem) {
          this.enableMatching(transport.id);
        } else if (!this.matchingEnabled && transport.partOfMatchingPoolItem) {
          this.disableMatching(transport.id);
        }

        this.loading = false;
        this.toastr.showSuccess({
          message: extract('Update succeeded!')
        });

        // Navigate to the transports page on the day of the primary task date
        this.router.navigate([
          `tms/transports`,
          { date: transport.primaryTaskDate }
        ]);
      });
    this.updates$
      .pipe(ofType(Types.transports.UPDATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.loading = false;
        this.toastr.showDanger({
          message: extract('Failed to update transport'),
          title: extract('Something went wrong')
        });
      });
  }

  /**
   * Updates the existing transport
   * @param transportFormValue value emitted from the transport component
   */
  updateTransport(transportFormValue) {
    const body = this.parserService.parseTransportOffer(transportFormValue);
    this.store.dispatch(
      new UpdateTransport({
        id: this.transportId,
        body: body
      })
    );
    this.loading = true;
  }
  getTransportById(id: string) {
    this.api.get({ path: `/transports/${id}` }).toPromise().then((res: Transport) => {
      this.transport = res;
      this.updateCustomersArray(this.transport, this.customers);
      this.updateSuppliersArray(this.transport, this.suppliers);
      this.updateLinersArray(this.transport.container, this.liners);
      this.updateContainerTypesArray(this.transport.container, this.containerTypes);
      this.updateSupplierTariffsArray(this.transport, this.supplierTariffs);
      this.updateTariffsArray(this.transport, this.tariffs);
      this.parserService.liners = this.allLiners;
      this.parserService.containerTypes = this.allContainerTypes;
    });
  }

  updateTariffsArray(transport: Transport, tariffs: Product[]) {
    if (!transport || !tariffs) {
      return;
    }
    this.allTariffs = this.softDeleteService.addElementToArrayIfNotExists(
      transport.tariff,
      tariffs
    );
  }
  updateSupplierTariffsArray(transport: Transport, supplierTariffs: Product[]) {
    if (!transport || !supplierTariffs) {
      return;
    }
    this.allSupplierTariffs = this.softDeleteService.addElementToArrayIfNotExists(
      transport.supplierTariff,
      supplierTariffs
    );
  }
  updateCustomersArray(transport: Transport, customers: Customer[]) {
    if (!transport || !customers) {
      return;
    }
    this.allCustomers = this.softDeleteService.addElementToArrayIfNotExists(
      transport.customer,
      customers
    );
  }

  updateLinersArray(container: Container, liners: Liner[]) {
    if (!container || !liners) {
      return;
    }
    this.allLiners = this.softDeleteService.addElementToArrayIfNotExists(
      container.liner,
      liners
    );
  }

  updateContainerTypesArray(
    container: Container,
    containerTypes: ContainerType[]
  ) {
    if (!container || !containerTypes) {
      return;
    }
    this.allContainerTypes = this.softDeleteService.addElementToArrayIfNotExists(
      container.liner,
      containerTypes
    );
  }

  updateSuppliersArray(transport: Transport, suppliers: Supplier[]) {
    if (!transport || !suppliers) {
      return;
    }
    this.allSuppliers = this.softDeleteService.addElementToArrayIfNotExists(
      transport.supplier,
      suppliers
    );
  }

  toggleMatchingState(matchingEnabled: boolean) {
    this.matchingEnabled = matchingEnabled;
  }

  enableMatching(transportId: string) {
    this.store.dispatch(
      new EnableMatching({
        type: MatchingTypes.TRANSPORT,
        id: transportId
      })
    );
  }
  disableMatching(transportId: string) {
    this.store.dispatch(
      new DisableMatching({
        type: MatchingTypes.TRANSPORT,
        id: transportId
      })
    );
  }

  getTaskIcon(task) {
    return this.iconService.getTransportTaskIcon(task);
  }

  ngOnDestroy() { }
}
