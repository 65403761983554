import { LoadSubscriptions } from 'app/core/store/actions/subscriptions.actions';
import { REFRESH_RATE_MS } from '../../constants';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { SearchService } from 'app/services/search.service';
import { User } from 'app/core/store/models/user.model';
import { Observable, interval } from 'rxjs';
import { EnableMatching, DisableMatching } from 'app/core/store/actions/matching.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as MatchTypes from 'app/core/store/types/matching.types';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-market-posts',
  templateUrl: './market-posts.component.html',
  styleUrls: ['market-posts-component.scss']
})
export class MarketPostsComponent implements OnInit, OnDestroy {
  @ViewChild('postsTable', { static: false })
  table: any;

  @Input() cardView;
  @Input() filtered = false;
  @Input() showFilter;
  @Input() showMB;
  marketposts$: any[] = [];
  marketpostsResults: any[];
  user$: Observable<User>;
  getPostInterval: any;
  hasActiveMatchingSubscription: boolean;

  throttle = 300;
  scrollDistance = 2;
  scrollUpDistance = 2;
  constructor(
    private store: Store<State>,
    private postUpdates: Actions,
    private toastr: ToastService,
    private searchService: SearchService
  ) {
    const showFilter = localStorage.getItem('showFilter');
    this.postUpdates
      .pipe(ofType(MatchTypes.matching.ENABLE_MATCHING_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        toastr.showSuccess({
          message: extract('Successfully enabled matching!')
        });
        this.marketpostsResults.find(
          mp => mp._id === response['payload'].id
        ).matching = true;
      });
    this.postUpdates
      .pipe(ofType(MatchTypes.matching.DISABLE_MATCHING_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        toastr.showSuccess({
          message: extract('Successfully disabled matching!')
        });
        this.marketpostsResults.find(
          mp => mp._id === response['payload'].id
        ).matching = false;
      });
  }

  ngOnInit() {
    this.getPostInterval = interval(REFRESH_RATE_MS)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getMarketPosts());
    this.store
      .select(state => state.marketplace.marketposts)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.marketpostsResults = state.map(mp => {
            const marketP = mp['_source'];
            marketP['_id'] = mp['_id'];
            return marketP;
          });
          const marketpostsAmount = this.marketposts$.length;
          if (this.marketposts$ && marketpostsAmount >= 25) {
            this.marketposts$ = this.marketpostsResults.slice(
              0,
              marketpostsAmount
            );
          } else {
            this.marketposts$ = this.marketpostsResults.slice(0, 25);
          }
        }
      });
    this.store
      .select(state => state.subscriptions)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptionState => {
        if (!subscriptionState) {
          this.store.dispatch(new LoadSubscriptions());
          return;
        }
        this.hasActiveMatchingSubscription =
          subscriptionState.filter(
            subscription => subscription.subscriptionType.code === 'MATCHING'
          ).length > 0;
      });
  }

  getMarketPosts() {
    // Check if the user has picked a tenant before executing call. This prevents the call from being executed
    // When on the tenant picker page.
    const session = JSON.parse(localStorage.getItem('session'));
    const hasActiveSubscription = JSON.parse(
      localStorage.getItem('hasActiveMARKETPLACESubscription')
    );
    if (
      !this.showFilter &&
      session &&
      session['tenant'] &&
      hasActiveSubscription
    ) {
      this.searchService.filter();
    }
  }

  trackById(index, marketpost) {
    return marketpost._id;
  }

  onScrollDown() {
    this.marketposts$ = [
      ...this.marketposts$,
      ...this.marketpostsResults.slice(
        this.marketposts$.length,
        this.marketposts$.length + 20
      )
    ];
  }

  handleEnableMatching(marketpost: { type: string; id: string }) {
    this.store.dispatch(
      new EnableMatching({
        type: marketpost.type,
        id: marketpost.id
      })
    );
  }
  handleDisableMatching(marketpost: { type: string; id: string }) {
    this.store.dispatch(
      new DisableMatching({
        type: marketpost.type,
        id: marketpost.id
      })
    );
  }

  ngOnDestroy() {
    clearInterval(this.getPostInterval);
  }
}
