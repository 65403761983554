export const ELASTIC_SEARCH_RESPONSE_SIZE = 1000;
export const REFRESH_RATE_MS = 2000;
export const COUNTRIES = [
  {
    label: 'Belgium',
    value: 'BE'
  }, {
    label: 'The Netherlands',
    value: 'NL'
  }, {
    label: 'France',
    value: 'FR'
  }, {
    label: 'Bulgaria',
    value: 'BG'
  }
];
