import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/subscription.types';
import * as NotificationsActions from '../../store/actions/notifications.actions';
import * as SubscriptionsActions from '../../store/actions/subscriptions.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class SubscriptionsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadSubscriptionTypes$: Observable<Action> = this.actions$.pipe(
    ofType(types.subscriptions.LOAD_SUBSCRIPTION_TYPES),
    concatMap(() => this.api.get({
      path: '/subscription_types'
    }).pipe(
      map(data => new SubscriptionsActions.LoadSubscriptionTypesSuccess(data)),
      catchError(error => of(new SubscriptionsActions.LoadSubscriptionTypesFail(error)))
    )),
  );

  @Effect()
  loadSubscriptions$: Observable<Action> = this.actions$.pipe(
    ofType(types.subscriptions.LOAD_SUBSCRIPTIONS),
    concatMap(() => this.api.get({
      path: '/subscriptions'
    }).pipe(
      map(data => new SubscriptionsActions.LoadSubscriptionsSuccess(data)),
      catchError(error => of(new NotificationsActions.ErrorNotification(error)))
    )),
  );

  @Effect()
  startSubscription$: Observable<Action> = this.actions$.pipe(
    ofType(types.subscriptions.START_SUBSCRIPTION),
    map((action: SubscriptionsActions.StartSubscription) => action.payload),
    concatMap(payload => this.api.post({
      path: '/subscription_types/' + payload + '/start', body: {}
    }).pipe(
      map(data => new SubscriptionsActions.StartSubscriptionSuccess(data)),
      catchError(error => of(new SubscriptionsActions.StartSubscriptionFail(error)))
    )),
  );

  @Effect()
  stopSubscription$: Observable<Action> = this.actions$.pipe(
    ofType(types.subscriptions.STOP_SUBSCRIPTION),
    map((action: SubscriptionsActions.StopSubscription) => action.payload),
    concatMap(payload => this.api.post({
      path: '/subscription_types/' + payload + '/stop', body: {}
    }).pipe(
      map(data => new SubscriptionsActions.StopSubscriptionSuccess(data)),
      catchError(error => of(new SubscriptionsActions.StopSubscriptionFail(error)))
    )),
  );
}
