import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EditCircle, DeleteCircle, LoadCircles } from 'app/core/store/actions/circles.actions';
import { Circle } from 'app/core/store/models/circles.model';
import * as Types from 'app/core/store/types/circle.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-circle-list',
  templateUrl: 'circle-list.component.html',
  styleUrls: ['circle-list.component.scss']
})
export class CircleListComponent implements OnInit, OnDestroy {
  @Input()
  loading: boolean;
  @Input()
  circles$: Observable<Circle[]>;
  noCircles: boolean;
  membersMapping: { [k: string]: string } = {
    '=0': 'No Members.',
    '=1': '1 Member',
    other: '# Members'
  };

  constructor(
    private store: Store<State>,
    private circleUpdates$: Actions,
    private toastr: ToastService
  ) {
    circleUpdates$
      .pipe(ofType(Types.circles.EDIT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Edit successful!'
        });
        this.store.dispatch(new LoadCircles());
      });

    circleUpdates$
      .pipe(ofType(Types.circles.DELETE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Delete successful!'
        });
        this.store.dispatch(new LoadCircles());
      });

    circleUpdates$
      .pipe(ofType(Types.circles.DELETE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Delete failed!'
        });
      });
    circleUpdates$
      .pipe(ofType(Types.circles.EDIT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Update failed!'
        });
      });
  }
  ngOnInit() {
    this.circles$.subscribe(data => {
      this.noCircles = data && data.length === 0;
    });
  }

  ngOnDestroy() { }

  deleteCircle(circle) {
    this.store.dispatch(new DeleteCircle(circle.id));
  }

  saveCircle($event, circle) {
    const body = {
      name: circle.name,
      members: circle.members.map(member => member['@id'])
    };
    this.store.dispatch(
      new EditCircle({
        id: circle.id,
        body: body
      })
    );
  }
}
