import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApiService } from 'app/core/api/api.service';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { ContainerMeasurement } from 'app/core/store/models/container-measurement.model';
import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
import * as moment from 'moment';

@Component({
  selector: 'app-container-measurement-form',
  templateUrl: './measurement-form.component.html',
})
export class ContainerMeasurementFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  data: ContainerMeasurement;
  @Input()
  isLoading: boolean;
  @Output()
  formSubmitted = new EventEmitter<ContainerMeasurement>();

  liners: any[];
  weighingSites: any[];
  terminals: any[];
  form: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private store: Store<State>,
    private toastService: ToastService,
  ) {
    this.store.select(state => state.marketplace.liners)
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (!liners) {
          this.store.dispatch(new LoadLiners());
          return;
        }
        this.liners = liners;
      });
    this.api.get({
      path: `/locations?locationType.code=WEIGHT&order[name]=asc`
    }).subscribe(data => {
      this.weighingSites = data['hydra:member'];
    });
    this.api.get({
      path: `/locations?locationType.code=TERMINAL&order[name]=asc`
    }).subscribe(data => {
      this.terminals = data['hydra:member'];
    });
  }

  ngOnInit() {
    if (!this.form) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      if (!this.form) {
        this.createForm();
      }
      const date = moment.utc(changes.data.currentValue.announcedOn).toObject();
      this.form.patchValue({
        ...changes.data.currentValue,
        liner: (changes.data.currentValue.liner ? changes.data.currentValue.liner['@id'] : null),
        terminal: (changes.data.currentValue.terminal ? changes.data.currentValue.terminal['@id'] : null),
        site: (changes.data.currentValue.site ? changes.data.currentValue.site['@id'] : null),
        announcedOn: { year: date.years, month: date.months, day: date.date },
        announcedOnTime: { hour: date.hours, minute: date.minutes },
      });
    }
  }

  ngOnDestroy() { }

  createForm() {
    this.form = this.fb.group({
      announcedOn: [null],
      announcedOnTime: [null],
      bookingReference: [null],
      liner: [null],
      containerNumber: [null],
      data: [null],
      measurementKey: [null],
      sealNumber: [null],
      site: [null],
      terminal: [null],
      weight: [null],
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.formSubmitted.emit({
      ...this.form.value,
      announcedOn: {
        ...this.form.value.announcedOn,
        ...this.form.value.announcedOnTime,
      },
    });
  }

  sendToAvantida() {
    this.api.post({
      path: `/container_measurements/send/${this.data.id}/avantida`,
    }).subscribe(data => {
      if (data['ok']) {
        this.toastService.showSuccess({title: 'Success', message: 'Data sent to Avantida'});
      } else {
        this.toastService.showDanger({title: 'Error', message: 'Something went wrong'});
      }
    });
  }
}
