import { Address } from '../models/address.model';
import { Action } from '@ngrx/store';
import * as types from '../types/address.types';

export class UpdateAddress implements Action {
  readonly type = types.address.UPDATE_ADDRESS;
  constructor(
    public payload?: {
      addressId: string;
      body: Address;
    }
  ) {}
}

export class UpdateAddressFail implements Action {
  readonly type = types.address.UPDATE_ADDRESS_FAILED;
  constructor(public payload: any) {}
}

export class UpdateAddressSuccess implements Action {
  readonly type = types.address.UPDATE_ADDRESS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteAddress implements Action {
  readonly type = types.address.DELETE_ADDRESS;
  constructor(
    public payload?: {
      businessId: string;
      addressId: string;
    }
  ) {}
}

export class DeleteAddressFail implements Action {
  readonly type = types.address.DELETE_ADDRESS_FAILED;
  constructor(public payload: any) {}
}

export class DeleteAddressSuccess implements Action {
  readonly type = types.address.DELETE_ADDRESS_SUCCEEDED;
  constructor(public payload: any) {}
}

export type All =
  | UpdateAddress
  | UpdateAddressFail
  | UpdateAddressSuccess
  | DeleteAddress
  | DeleteAddressFail
  | DeleteAddressSuccess;
