<div class="m-3">
  <div class="h2">
    {{ 'Thank you for buying this certificate' | translate }}
  </div>

  <button class="btn btn-primary mr-1 text-white" (click)="download()">
    <i class="far fa-fw fa-file-download"></i>
    <span>Download PDF</span>
  </button>
  <button class="btn btn-primary mr-1 text-white" (click)="sendEmail()">
    <i class="far fa-fw fa-paper-plane"></i>
    <span>Send PDF to my e-mail</span>
  </button>

  <div class="mt-3 row" *ngIf="data && certificate">
    <div class="col">
      <div class="row">
        <div class="offset-3 col"><h4>{{ 'Container details' | translate }}</h4></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Container number' | translate }}</small></div>
        <div class="col-8">{{ data.containerNumber }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Seal number' | translate }}</small></div>
        <div class="col-8">{{ data.sealNumber }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Weight' | translate }}</small></div>
        <div class="col-8">{{ data.weight }} kg</div>
      </div>

      <div class="row mt-3">
        <div class="offset-3 col"><h4>{{ 'Weighing details' | translate }}</h4></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Weighed at' | translate }}</small></div>
        <div class="col-8">{{ data.site?.name }}, {{ data.announcedOn | amCalendar }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Price' | translate }}</small></div>
        <div class="col-8">{{ data.site?.servicePrice || defaultPrice }} €</div>
      </div>
    </div>

    <div class="col">
      <div class="row">
        <div class="offset-3 col"><h4>{{ 'Liner details' | translate }}</h4></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Liner' | translate }}</small></div>
        <div class="col-8">{{ certificate.liner.shortName }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Export booking reference' | translate }}</small></div>
        <div class="col-8">{{ certificate.bookingReference }}</div>
      </div>

      <div class="row mt-3">
        <div class="offset-3 col"><h4>{{ 'Customer details' | translate }}</h4></div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Client reference' | translate }}</small></div>
        <div class="col-8">{{ certificate.clientReference }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Shipper contact person' | translate }}</small></div>
        <div class="col-8">{{ certificate.shipperContact.name }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Shipper company' | translate }}</small></div>
        <div class="col-8">{{ certificate.shipperContact.company }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-right"><small class="text-muted">{{ 'Shipper address' | translate }}</small></div>
        <div class="col-8">
          {{ certificate.shipperContact.address }}
        </div>
      </div>
    </div>
  </div>
</div>
