<div *ngIf="transport && transport.reference">
  {{ 'Transport:' | translate }}
  <span class="font-weight-bold">{{ transport.reference }}</span>
</div>
<form *ngIf="transportForm" (ngSubmit)="onSubmit()" [formGroup]="transportForm">
  <div *ngIf="!needsConfirmation">
    <div
      ngbRadioGroup="ngbRadioGroup"
      name="transportTypeRadio"
      formControlName="transportType"
      ngDefaultControl="ngDefaultControl"
      class="d-none d-md-flex btn-group btn-group-toggle justify-content-center form-group"
    >
      <label
        ngbButtonLabel="ngbButtonLabel"
        *ngFor="let type of transportTypes"
        class="btn-outline-primary"
      >
        <input
          ngbButton="ngbButton"
          type="radio"
          [value]="type"
          (click)="setTransportTasks(type['transportTypeTasks'])"
        />
        <span class="icon">
          <i class="far" [ngClass]="getTypeIcon(type)"></i>
        </span>
        <span>{{ type.label | titlecase | translate }}</span>
      </label>
    </div>
  </div>
  <app-transport-tasks
    [submitAttempt]="submitAttempt"
    [needsConfirmation]="needsConfirmation"
    [transportTasksAreValid]="transportTasksAreValid"
    [transportTasks]="transportTasks.value"
    [showPublicLocationField]="showPublicLocationField"
    (transportTasksChange)="transportTasksChange($event)"
    (transportTasksFormSubmitted)="onSubmit($event)"
  >
  </app-transport-tasks>
  <ngb-tabset [destroyOnHide]="false" [justify]="'justified'">
    <ngb-tab>
      <ng-template ngbTabTitle>
        <span [class.text-danger]="this.submitAttempt && !this.containerValid">
          <i class="far fa-fw fa-container-storage"></i>&nbsp;
          <span>{{ 'Container &amp; goods' | translate }}</span> &nbsp;
          <i
            *ngIf="this.submitAttempt && !this.containerValid"
            class="far fa-fw fa-exclamation-triangle"
          ></i>
        </span>
      </ng-template>
      <ng-template ngbTabContent>
        <div class="container-tab-grid">
          <app-container-form
            [needsConfirmation]="needsConfirmation"
            [container]="container"
            [liners]="liners"
            [containerTypes]="containerTypes"
            [submitAttempt]="submitAttempt"
            (reeferStatusChanged)="handleIsReefer($event)"
            (containerFormValueChanged)="
              handleContainerFormValueChanged($event)
            "
            (containerFormStatusChanged)="
              handleContainerFormStatusChanged($event)
            "
          ></app-container-form>
          <div>
            <div class="checkbox-column">
              <label class="custom-control custom-checkbox genset-checkbox">
                <input
                  type="checkbox"
                  id="genset-input"
                  class="custom-control-input"
                  formControlName="genset"
                />
                <span class="custom-control-label">{{
                  'Genset' | translate
                }}</span>
              </label>
            </div>
            <input
              *ngIf="!needsConfirmation"
              type="text"
              class="form-control mb-3"
              placeholder="{{ 'Goods' | translate }}"
              data-cy="goods"
              formControlName="goods"
            />
            <input
              *ngIf="!needsConfirmation"
              type="text"
              class="form-control mb-3"
              placeholder="{{ 'Customs' | translate }}"
              formControlName="customs"
              data-cy="customs"
            />
            <input
              *ngIf="!needsConfirmation"
              type="text"
              class="form-control mb-3"
              placeholder="{{ 'Ship' | translate }}"
              formControlName="ship"
              data-cy="ship"
            />
            <input
              *ngIf="!needsConfirmation"
              type="text"
              class="form-control mb-3"
              placeholder="{{ 'Bill of lading' | translate }}"
              formControlName="billOfLading"
              data-cy="billOfLading"
            />
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab *ngIf="!needsConfirmation">
      <ng-template ngbTabTitle>
        <span [class.text-danger]="this.submitAttempt && !this.customerValid">
          <i class="far fa-fw fa-user"></i>&nbsp;
          <span data-cy="customerTab">{{ 'Customer' | translate }}</span> &nbsp;
          <i
            *ngIf="this.submitAttempt && !this.customerValid"
            class="far fa-fw fa-exclamation-triangle"
          ></i>
        </span>
      </ng-template>
      <ng-template ngbTabContent>
        <div class="row pb-3">
          <div class="col-8">
            <div class="tab-section-title">
              {{ 'Price' | translate }}
              <span *ngIf="transport && transport.invoiced">-
                {{ 'you can\'t change the tariff of a transport that has already been invoiced' | translate }}
              </span>
            </div>
            <div>
              <div class="quick-add-grid">
                <div *ngIf="!transport || !transport.invoiced">
                  <ng-select
                          [items]="customerTariffs"
                          [loading]="isLoadingTariffs"
                          formControlName="selectedCustomerTariffTemplate"
                          (change)="handleCustomerTariffSelect($event)"
                          data-cy="tariff"
                          placeholder="{{
                    'Select customer tariff template' | translate
                  }}"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.label }} - {{ item.price | currency: 'EUR':'code' }}
                    </ng-template>
                    <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                            let-search="searchTerm"
                    >
                      {{ item.label }} - {{ item.price | currency: 'EUR':'code' }}
                    </ng-template>
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                      <div
                              class="ng-option disabled"
                              (click)="
                        openNewTariffModal(ProductTypes.CUSTOMER, searchTerm)
                      "
                      >
                        {{ 'Tariff not found; click here to add it' | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div *ngIf="transport && transport.invoiced">
                  <input
                    type="text"
                    class="form-control"
                    readonly
                    value="{{ transport.tariff.label }} - {{ transport.tariff.price | currency: 'EUR':'code' }}">
                </div>

                <button
                  class="text-white btn btn-sm btn-primary"
                  type="button"
                  data-cy="quickAddCustomerTariffButton"
                  [disabled]="transport && transport.invoiced"
                  (click)="openNewTariffModal(ProductTypes.CUSTOMER)"
                >
                  <span class="icon"> <i class="far fa-plus"></i> </span>
                </button>
                <div></div>
              </div>
              <app-tariff-table
                #tariffTable
                *ngIf="transportForm.get('tariff').value"
                [selectedTemplate]="
                  transportForm.get('selectedCustomerTariffTemplate').value
                "
                [tariff]="transportForm.get('tariff').value"
                [disabled]="transport && transport.invoiced"
                (tariffChanged)="handleCustomerTariffChange($event)"
              ></app-tariff-table>
            </div>
          </div>
          <div class="col-4" [hidden]="needsConfirmation">
            <div class="tab-section-title">{{ 'Customer' | translate }}</div>
            <div class="mb-3 quick-add-grid">
              <div *ngIf="!transport || !transport.invoiced">
                <ng-select
                  id="customer"
                  [items]="customers"
                  bindLabel="label"
                  [loading]="isLoadingCustomers"
                  bindValue="id"
                  data-cy="customer"
                  placeholder="{{ 'Select customer' | translate }}"
                  (change)="updateContact()"
                  formControlName="customer"
                >
                  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                    <div
                      class="ng-option disabled"
                      (click)="openNewCustomerModal(searchTerm)"
                    >
                      {{ 'Customer not found; click here to add it' | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div *ngIf="transport && transport.invoiced">
                <input
                        type="text"
                        class="form-control"
                        readonly
                        value="{{ transport.customer.label }}">
              </div>
              <button
                *ngIf="
                  transportForm.get('customer').value;
                  else quickAddIsEnabled
                "
                [disabled]="!transport || !transport.invoiced"
                (click)="openEditCustomerModal()"
                class="text-white btn btn-sm btn-info"
                type="button"
              >
                <span class="icon"> <i class="far fa-pencil"></i> </span>
              </button>
              <ng-template #quickAddIsEnabled>
                <button
                  class="text-white btn btn-sm btn-primary"
                  type="button"
                  data-cy="quickAddCustomerButton"
                  (click)="openNewCustomerModal()"
                >
                  <span class="icon"> <i class="far fa-plus"></i> </span>
                </button>
              </ng-template>
            </div>
            <div *ngIf="!transport || !transport.invoiced">
              <ng-select
                class="mb-3"
                id="customer"
                [items]="selectedCustomerContacts"
                bindLabel="label"
                bindValue="id"
                placeholder="{{ 'Select contact' | translate }}"
                formControlName="contact"
              >
                <ng-template ng-label-tmp let-item="item">
                  <b>
                    {{ item.firstName }} {{ item.lastName }}
                    <span *ngIf="item.designation">- {{ item.designation }}</span>
                    <span *ngIf="item.telephone"> - {{ item.telephone }}</span>
                  </b>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <b>
                    {{ item.firstName }} {{ item.lastName }}
                    <span *ngIf="item.designation">- {{ item.designation }}</span>
                    <span *ngIf="item.telephone"> - {{ item.telephone }}</span>
                  </b>
                </ng-template>
                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                  <div
                    class="ng-option disabled"
                    (click)="openNewCustomerModal(searchTerm)"
                  >
                    <span *ngIf="!transportForm.get('customer').value">{{
                      'Please select a customer' | translate
                    }}</span>
                    <span
                      *ngIf="
                        transportForm.get('customer').value &&
                        transportForm.get('customer').value.contacts.length === 0
                      "
                      >{{ 'Customer has no contacts' | translate }}</span
                    >
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div  *ngIf="transport && transport.invoiced"
                  class="mb-3 form-control"
                  readonly>
                <b *ngIf="customerContact">
                  {{ customerContact.firstName }} {{ customerContact.lastName }}
                  <span *ngIf="customerContact.designation">- {{ customerContact.designation }}</span>
                  <span *ngIf="customerContact.telephone"> - {{ customerContact.telephone }}</span>
                </b>
            </div>
            <div>
              <input
                      [readOnly]="transport && transport.invoiced"
                      type="text"
                      class="form-control"
                      placeholder="{{ 'Customer reference' | translate }}"
                      formControlName="customerReference"
                      data-cy="customerReference"
              />
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab *ngIf="!needsConfirmation">
      <ng-template ngbTabTitle>
        <span [class.text-danger]="this.submitAttempt && !this.supplierValid">
          <i class="far fa-fw fa-hand-holding-usd"></i>&nbsp;
          <span data-cy="supplierTab">{{ 'Supplier' | translate }}</span> &nbsp;
          <i
            *ngIf="this.submitAttempt && !this.supplierValid"
            class="far fa-fw fa-exclamation-triangle"
          ></i>
        </span>
      </ng-template>
      <ng-template ngbTabContent>
        <div class="row pb-3">
          <div class="col-8">
            <div class="tab-section-title">{{ 'Price' | translate }}
              <span *ngIf="transport && transport.purchased">-
                {{ 'you can\'t change the supplier tariff of a transport that already has a purchase order' | translate }}
              </span>
            </div>
            <div>
              <div class="quick-add-grid">
                <div *ngIf="!transport || !transport.purchased">

                  <ng-select
                          [items]="supplierTariffs"
                          [loading]="isLoadingTariffs"
                          formControlName="selectedSupplierTariffTemplate"
                          (change)="handleSupplierTariffSelect($event)"
                          data-cy="priceTabsupplierTariff"
                          placeholder="{{ 'Select tariff template' | translate }}"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.label }} - {{ item.price | currency: 'EUR':'code' }}
                    </ng-template>
                    <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-index="index"
                            let-search="searchTerm"
                    >
                      {{ item.label }} - {{ item.price | currency: 'EUR':'code' }}
                    </ng-template>
                    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                      <div
                              class="ng-option disabled"
                              (click)="
                        openNewTariffModal(ProductTypes.SUPPLIER, searchTerm)
                      "
                      >
                        {{ 'Tariff not found; click here to add it' | translate }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div *ngIf="transport && transport.purchased">
                  <input
                          type="text"
                          class="form-control"
                          readonly
                          value="{{ transport.supplierTariff.label }} - {{ transport.supplierTariff.price | currency: 'EUR':'code' }}">
                </div>
                <button
                  [disabled]="transport && transport.purchased"
                  class="text-white btn btn-sm btn-primary"
                  type="button"
                  data-cy="quickAddSupplierTariffButton"
                  (click)="openNewTariffModal(ProductTypes.SUPPLIER)"
                >
                  <span class="icon"> <i class="far fa-plus"></i> </span>
                </button>
                <div></div>
              </div>
              <app-tariff-table
                [disabled]="transport && transport.purchased"
                #supplierTariffTable
                *ngIf="transportForm.get('supplierTariff').value"
                [selectedTemplate]="
                  transportForm.get('selectedSupplierTariffTemplate').value
                "
                [tariff]="transportForm.get('supplierTariff').value"
                (tariffChanged)="handleSupplierTariffChange($event)"
              ></app-tariff-table>
            </div>
          </div>
          <div class="col-4" [hidden]="needsConfirmation">
            <div class="tab-section-title">{{ 'Supplier' | translate }}</div>
            <div class="mb-3 quick-add-grid">
              <div *ngIf="!transport || !transport.purchased">
                <ng-select
                  id="supplier"
                  [items]="suppliers"
                  bindLabel="label"
                  [loading]="isLoadingSuppliers"
                  bindValue="id"
                  data-cy="supplier"
                  placeholder="{{ 'Select supplier' | translate }}"
                  (change)="updateSupplierContact()"
                  formControlName="supplier"
                >
                  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                    <div
                      class="ng-option disabled"
                      (click)="openNewSupplierModal(searchTerm)"
                    >
                      {{ 'Supplier not found; click here to add it' | translate }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div *ngIf="transport && transport.purchased">
                <input
                        type="text"
                        class="form-control"
                        readonly
                        value="{{ transport.supplier.label }}">
              </div>
              <button
                *ngIf="
                  transportForm.get('supplier').value;
                  else quickAddIsEnabled
                "
                [disabled]="transport && transport.purchased"
                (click)="openEditSupplierModal()"
                class="text-white btn btn-sm btn-info"
                type="button"
              >
                <span class="icon"> <i class="far fa-pencil"></i> </span>
              </button>
              <ng-template #quickAddIsEnabled>
                <button
                  [disabled]="transport && transport.purchased"
                  class="text-white btn btn-sm btn-primary"
                  type="button"
                  data-cy="quickAddSupplierButton"
                  (click)="openNewSupplierModal()"
                >
                  <span class="icon"> <i class="far fa-plus"></i> </span>
                </button>
              </ng-template>
            </div>
            <div *ngIf="!transport || !transport.purchased">
              <ng-select
                      class="mb-3"
                      id="supplier"
                      [items]="selectedSupplierContacts"
                      bindLabel="label"
                      bindValue="id"
                      placeholder="{{ 'Select contact' | translate }}"
                      formControlName="supplierContact"
              >
                <ng-template ng-label-tmp let-item="item">
                  <b>
                    {{ item.firstName }} {{ item.lastName }}
                    <span *ngIf="item.designation">- {{ item.designation }}</span>
                    <span *ngIf="item.telephone"> - {{ item.telephone }}</span>
                  </b>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <b>
                    {{ item.firstName }} {{ item.lastName }}
                    <span *ngIf="item.designation">- {{ item.designation }}</span>
                    <span *ngIf="item.telephone"> - {{ item.telephone }}</span>
                  </b>
                </ng-template>
                <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                  <div
                          class="ng-option disabled"
                          (click)="openNewCustomerModal(searchTerm)"
                  >
                  <span *ngIf="!transportForm.get('supplier').value">{{
                    'Please select a supplier' | translate
                    }}</span>
                    <span
                            *ngIf="
                      transportForm.get('supplier').value &&
                      transportForm.get('supplier').value.contacts.length === 0
                    "
                    >{{ 'Supplier has no contacts' | translate }}</span
                    >
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="transport && transport.purchased"
                 class="mb-3 form-control"
                 readonly>
              <b *ngIf="supplierContact">
                {{ supplierContact.firstName }} {{ supplierContact.lastName }}
                <span *ngIf="supplierContact.designation">- {{ supplierContact.designation }}</span>
                <span *ngIf="supplierContact.telephone"> - {{ supplierContact.telephone }}</span>
              </b>
            </div>

            <input
              [readOnly]="transport && transport.purchased"
              type="text"
              class="form-control"
              placeholder="{{ 'Supplier reference' | translate }}"
              formControlName="supplierReference"
              data-cy="supplierReference"
            />
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab>
      <ng-template ngbTabTitle>
        <i class="far fa-fw fa-comment"></i>&nbsp;
        <span data-cy="commentTab">{{ 'Comment' | translate }}</span>
      </ng-template>
      <ng-template ngbTabContent>
        <div *ngIf="!needsConfirmation">
          <label>{{ 'Comment' | translate }}</label>
          <span class="text-muted" id="private-comment-length"
            >({{ privateComment.length }} / 256)</span
          >
          <textarea
            class="form-control"
            id="private-comment"
            data-cy="privateComment"
            maxlength="256"
            formControlName="privateComment"
            cols="20"
            rows="4"
          ></textarea>
        </div>
        <div>
          <label>{{ 'Public comment' | translate }}</label>
          <span class="text-muted" id="message-length"
            >({{ comment.length }} / 256)</span
          >
          <textarea
            class="form-control"
            id="comment"
            data-cy="comment"
            maxlength="256"
            formControlName="comment"
            cols="20"
            rows="4"
          ></textarea>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab *ngIf="!needsConfirmation">
      <ng-template ngbTabTitle>
        <i class="far fa-fw fa-file-upload"></i>&nbsp;
        <span data-cy="attachmentsTab">{{ 'Attachments' | translate }}</span>
      </ng-template>
      <ng-template ngbTabContent>
        <div class="file-uploader">
          <div class="files">
            <app-attachment
              *ngFor="let attachment of transportForm.get('attachments').value"
              [attachment]="attachment"
            ></app-attachment>
          </div>
          <div class="dropzone">
            <app-file-uploader
              (fileUploadSuccess)="handleFileUploadSuccess($event)"
            ></app-file-uploader>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
  <span class="form-inline mt-3">
    <button
      class="btn btn-primary text-white"
      data-cy="submitButton"
      [disabled]="loading"
      type="submit"
    >
      <span translate class="btn__label" [ngClass]="{ invisible: loading }">
        <span>{{
          transport ? ('Save' | translate) : ('Submit' | translate)
        }}</span>
      </span>
      <i
        class="fa fa-spinner fa-spin btn__loader"
        [ngClass]="{ visible: loading }"
      ></i>
    </button>
    <button
      class="ml-3 btn btn-dark"
      *ngIf="!needsConfirmation"
      [hidden]="transport"
      (click)="onSubmit(true)"
      [disabled]="loading"
      data-cy="submitAndContinueButton"
      type="button"
    >
      <span translate class="btn__label" [ngClass]="{ invisible: loading }">{{
        'Submit & continue' | translate
      }}</span>
      <i
        class="fa fa-spinner fa-spin btn__loader"
        [ngClass]="{ visible: loading }"
      ></i>
    </button>
    <label
      class="ml-3 custom-control custom-checkbox"
      [class.disabled]="!hasActiveMatchingSubscription"
      ngbTooltip="{{
        !hasActiveMatchingSubscription
          ? 'If you have a Matching subscription, Hakka will find interesting transports for you'
          : 'Enable or disable matching for this transport'
      }}"
    >
      <input
        type="checkbox"
        id="matching-check"
        class="custom-control-input"
        [disabled]="!hasActiveMatchingSubscription"
        [checked]="
          transport ? transport.partOfMatchingPoolItem : isMatchingEnabled
        "
        (change)="isMatchingEnabled = !isMatchingEnabled"
      />
      <span class="custom-control-label"
        >{{ 'Matching' | translate }} <i class="far 45 fa-bolt"></i>
      </span>
    </label>
  </span>
</form>
