import * as types from '../types/liner.types';
import * as LinerActions from '../actions/liner.actions';
import { Liner } from '../models/liner.model';

const INITIAL_STATE = null;

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.

// A higher-order reducer: accepts an animal type and returns a reducer
// that only responds to actions for that particular animal type.
export function LinersReducer(
  state = INITIAL_STATE,
  action: LinerActions.All
): Liner[] {
  switch (action.type) {
    case types.liner.LOAD_LINERS_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}
