import { Circle } from 'app/core/store/models/circles.model';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Renderer,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Autocomplete } from 'app/services/autocomplete.service';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/circle.types';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-new-circle',
  templateUrl: 'new-circle.component.html',
  styleUrls: ['new-circle.component.scss']
})
export class NewCircleComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  formTitle: string;
  @Input()
  circle: Circle;
  @Input()
  buttonText: string;
  @Input()
  circles: Circle[];

  @Output()
  circleSubmitted = new EventEmitter<{
    name: string;
    members: {};
  }>();
  circleForm: FormGroup;
  loading = true;
  loadingCircle = false;
  editMode = false;
  circleId: String;
  submitting = false;
  success = false;

  constructor(
    private fb: FormBuilder,
    private autocompleteService: Autocomplete,
    private renderer: Renderer,
    private createUpdates$: Actions,
    private editUpdates$: Actions,
    private router: Router,
    private toastr: ToastService
  ) {
    createUpdates$
      .pipe(ofType(Types.circles.CREATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.createForm();
        this.submitting = false;
        this.toastr.showSuccess({
          message: 'Circle successfully added!'
        });
        this.success = true;
      });

    editUpdates$
      .pipe(ofType(Types.circles.EDIT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.submitting = false;
        this.router.navigate(['/settings/circles']);
      });
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.circle && changes.circle.currentValue) {
      this.createForm();
      this.circleForm.patchValue({
        name: changes.circle.currentValue.name
      });
      changes.circle.currentValue.members.forEach(member => {
        this.pushMemberToControl({
          label: member.name,
          id: member.id
        });
      });
    }
  }
  createForm() {
    this.circleForm = this.fb.group({
      name: ['', Validators.required],
      members: this.fb.array([])
    });
  }

  get name() {
    return this.circleForm.get('name');
  }
  get members(): FormArray {
    return this.circleForm.get('members') as FormArray;
  }
  addMember(event, input) {
    event.preventDefault();
    // Check if members contain the selected member
    this.pushMemberToControl(event.item);
    // clear the input field
    this.renderer.setElementProperty(input, 'value', '');
  }

  pushMemberToControl(item) {
    const control = <FormArray>this.circleForm.controls['members'];
    if (!control.value.find(m => m.label === item.label)) {
      control.push(this.fb.group(item));
    }
  }

  removeMember(index) {
    this.members.removeAt(index);
  }
  onSubmit() {
    const body = {
      name: this.circleForm.get('name').value,
      members: this.circleForm.get('members').value.map(m => {
        return '/api/v1/tenants/' + m.id;
      })
    };
    this.submitting = true;
    this.circleSubmitted.emit(body);
  }

  formatter = (x: { label: string }) => x.label;

  searchMember = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.autocompleteService.search('member', term);
      })
    )

  checkUniqueName() {
    if (this.circles) {
      if (
        this.circles.some(
          circle =>
            circle.name.toLowerCase() ===
            this.circleForm.get('name').value.toLowerCase()
        )
      ) {
        this.circleForm.get('name').setErrors({ notUnique: true });
        return true;
      } else {
        return false;
      }
    }
  }
}
