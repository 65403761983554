import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api/api.service';
import { SubscriptionType } from '../../store/models/subscriptions.model';
import { Component } from '@angular/core';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-edit-subscription-type',
  templateUrl: './edit-subscription-type.component.html',
})
export class EditSubscriptionTypeComponent {
  subscriptionType: SubscriptionType;
  id: string;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private toastr: ToastService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api
          .get({
            path: '/subscription_types/' + this.id
          })
          .subscribe((response: SubscriptionType) => {
            this.subscriptionType = response;
          });
      }
    });
  }

  updateSubscriptionType(subscriptionTypeFormValue) {
    this.isLoading = true;
    this.api
      .put({
        path: `/subscription_types/${this.id}`,
        body: subscriptionTypeFormValue
      })
      .subscribe(
        res => {
          this.isLoading = false;
          this.toastr.showSuccess({
            message: 'Update subscription type successful'
          });
          this.router.navigateByUrl(`/superadmin`);
        },
        err => {
          this.isLoading = false;
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: 'Failed to update subscription type'
          });
        }
      );
  }
}
