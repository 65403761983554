<div class="card">
  <div class="card-header">
    <h3>
      {{ 'Are you sure you want to delete this transport?' | translate }}
    </h3>
  </div>
  <div class="card-body">
    <p>{{ 'This transport has one or more prenotifications attached to it.' | translate }}</p>
    <p>{{ 'Deleting this transport will also delete the task from that prenotification.' | translate }}</p>
    <div class="d-flex justify-content-between">
      <button class="btn btn-danger" (click)="activeModal.close()">{{ 'Cancel' | translate }}</button>
      <button class="btn btn-success text-white" (click)="activeModal.close(true)">{{ 'Confirm' | translate }}</button>
    </div>
  </div>
</div>
