import { AuthGuard, PlannerGuard } from '../core/user/user.authguard';
import { MarketPostDemandDetailComponent } from './market-posts/market-post-demand-detail/market-post-demand-detail.component';
import { MessageBoardComponent } from './message-board/message-board.component';
import { MarketPostMapComponent } from './market-posts/market-post-map/market-post-map.component';
import { NewMarketpostComponent } from './new-marketpost/new-marketpost.component';
import { Routes } from '@angular/router';
import { MarketplaceComponent } from './marketplace.component';
import { MarketPostOfferDetailComponent } from './market-posts/market-post-offer-detail/market-post-offer-detail.component';
import { NoSubscriptionNotificationComponent } from './no-subscription-notification/no-subscription-notification.component';
import { EditMarketPostComponent } from './market-posts/edit-market-post/edit-market-post.component';
import { Route } from '../core/route.service';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';
var ɵ0 = { subscriptionCode: 'MARKETPLACE' }, ɵ1 = { subscriptionCode: 'MARKETPLACE' }, ɵ2 = { subscriptionCode: 'MARKETPLACE' }, ɵ3 = { subscriptionCode: 'MARKETPLACE' }, ɵ4 = { subscriptionCode: 'MARKETPLACE' }, ɵ5 = { subscriptionCode: 'MARKETPLACE' }, ɵ6 = { subscriptionCode: 'MARKETPLACE' };
var routes = Route.withShell([
    {
        path: 'marketplace',
        canActivate: [
            AuthGuard,
            TenantGuard,
            SubscriptionGuard,
            AllowedSubscriptionGuard,
            DriverGuard
        ],
        data: ɵ0,
        children: [
            {
                path: '',
                component: MarketplaceComponent
            },
            {
                path: 'create',
                component: NewMarketpostComponent,
                canActivate: [
                    AuthGuard,
                    TenantGuard,
                    PlannerGuard,
                    SubscriptionGuard,
                    AllowedSubscriptionGuard,
                    DriverGuard
                ],
                data: ɵ1
            },
            {
                path: 'offers',
                redirectTo: '/company-posts',
                pathMatch: 'full'
            },
            {
                path: 'demands',
                redirectTo: '/company-posts',
                pathMatch: 'full'
            },
            {
                path: ':type/:id/edit',
                component: EditMarketPostComponent,
                canActivate: [
                    AuthGuard,
                    TenantGuard,
                    PlannerGuard,
                    SubscriptionGuard,
                    AllowedSubscriptionGuard,
                    DriverGuard
                ],
                data: ɵ2
            },
            {
                path: 'chat',
                component: MessageBoardComponent,
                canActivate: [
                    AuthGuard,
                    TenantGuard,
                    PlannerGuard,
                    SubscriptionGuard,
                    AllowedSubscriptionGuard,
                    DriverGuard
                ],
                data: ɵ3
            },
            {
                path: 'map',
                component: MarketPostMapComponent,
                canActivate: [
                    AuthGuard,
                    TenantGuard,
                    PlannerGuard,
                    SubscriptionGuard,
                    AllowedSubscriptionGuard,
                    DriverGuard
                ],
                data: ɵ4
            },
            {
                path: 'offers/:id/reply',
                component: MarketPostOfferDetailComponent,
                canActivate: [
                    AuthGuard,
                    TenantGuard,
                    PlannerGuard,
                    SubscriptionGuard,
                    AllowedSubscriptionGuard,
                    DriverGuard
                ],
                data: ɵ5
            },
            {
                path: 'demands/:id/reply',
                component: MarketPostDemandDetailComponent,
                canActivate: [
                    AuthGuard,
                    TenantGuard,
                    PlannerGuard,
                    SubscriptionGuard,
                    AllowedSubscriptionGuard,
                    DriverGuard
                ],
                data: ɵ6
            }
        ]
    },
    {
        path: 'subscribe',
        component: NoSubscriptionNotificationComponent,
        canActivate: [AuthGuard]
    }
]);
var MarketplaceRoutingModule = /** @class */ (function () {
    function MarketplaceRoutingModule() {
    }
    return MarketplaceRoutingModule;
}());
export { MarketplaceRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
