import { EditContactModalComponent } from '../edit-contact-modal/edit-contact-modal.component';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessContact } from 'app/core/store/models/business-contact.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  @Input()
  contact: BusinessContact;
  @Input()
  mainContact: boolean;
  @Output()
  updateMainContact = new EventEmitter<string>();
  @Output()
  deleteContact = new EventEmitter<string>();

  constructor(private modalService: NgbModal) { }

  setAsMainContact(): any {
    this.updateMainContact.emit(this.contact.id);
  }

  launchEditContactModal() {
    const modalRef = this.modalService.open(EditContactModalComponent);
    modalRef.componentInstance.contact = this.contact;
    modalRef.result.then(result => {
      this.contact = Object.assign({}, result);
    });
  }

  handleDeleteContact() {
    this.deleteContact.emit(this.contact.id);
  }
}
