import {
  LoadTaxRates,
  UpdateTaxRate
} from 'app/core/store/actions/tax-rate.actions';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';
import { TaxRate } from 'app/core/store/models/tax-rate.model';
import * as Types from 'app/core/store/types/tax-rate.types';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-tax-rate',
  templateUrl: './edit-tax-rate.component.html',
})
export class EditTaxRateComponent implements OnDestroy {
  id: string;
  taxRate: TaxRate;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private updates$: Actions,
    private store: Store<State>,
    private toastr: ToastService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.store
          .select(state => state.crm.taxRates)
          .pipe(untilDestroyed(this))
          .subscribe(taxRatesState => {
            if (!taxRatesState || !taxRatesState.taxRates) {
              this.store.dispatch(new LoadTaxRates());
              return;
            }
            this.taxRate = taxRatesState.taxRates.find(c => c.id === this.id);
          });
      }
    });
    this.updates$
      .pipe(ofType(Types.taxRate.UPDATE_TAX_RATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update tax rate!')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(Types.taxRate.UPDATE_TAX_RATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Tax rate updated successfully!')
        });
        this.store.dispatch(new LoadTaxRates());
        this.router.navigateByUrl('/crm/tax-rates');
        this.isLoading = false;
      });
  }

  updateTaxRate(taxRateFormValue) {
    this.isLoading = true;
    this.store.dispatch(
      new UpdateTaxRate({
        taxRateId: this.id,
        body: taxRateFormValue
      })
    );
  }

  ngOnDestroy() { }
}
