import { Alert } from '../../store/models/alert.model';
import { ApiService } from '../../api/api.service';
import { Router } from '@angular/router';
import { LoadLiners } from '../../store/actions/liner.actions';
import { State } from '../../store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import { Liner } from '../../store/models/liner.model';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-liners',
  templateUrl: './manage-liners.component.html',
})
export class ManageLinersComponent implements OnDestroy {
  liners: Liner[];
  alert: Alert;
  loading = false;

  constructor(
    private store: Store<State>,
    private router: Router,
    private api: ApiService,
    private toastr: ToastService
  ) {
    this.loading = true;
    this.store.dispatch(new LoadLiners());
    this.store.select(state => state.marketplace.liners)
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (liners) {
          this.loading = false;
          this.liners = liners;
        }
      });
  }

  ngOnDestroy() { }

  edit(id) {
    this.router.navigate(['superadmin/liners', id, 'edit']);
  }

  delete(id) {
    this.api.delete({ path: '/liners/' + id }).subscribe(
      () => {
        this.toastr.showSuccess({
          message: 'Liner deleted!'
        });
        this.loading = true;
        this.store.dispatch(new LoadLiners());
      },
      () => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to delete liner'
        });
      }
    );
  }
}
