import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DriverGuard } from 'app/core/auth/route-guards/driver.guard';
import { TenantGuard } from 'app/core/auth/route-guards/tenant.guard';
import { Route } from 'app/core/route.service';
import { AuthGuard } from 'app/core/user/user.authguard';

import { DashboardComponent } from './dashboard.component';

const defaultTmsGaurds = [AuthGuard, TenantGuard, DriverGuard];
const routes: Routes = Route.withShell([
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [...defaultTmsGaurds]
  }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}

export const routedComponents = [DashboardComponent];
