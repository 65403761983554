import { catchError, concatMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';

import * as types from '../types/country.types';
import * as CounrtyActions from '../actions/country.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class CountryEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadCountries$: Observable<Action> = this.actions$.pipe(
    ofType(types.country.LOAD_COUNTRIES),
    concatMap(() => this.api.get({ path: '/countries' }).pipe(
      map(data => new CounrtyActions.LoadCountriesSuccess(data)),
      catchError(error => of(new CounrtyActions.LoadCountriesFail(error)))
    )),
  );
}
