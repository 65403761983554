import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { ToastService } from 'app/services/toast.service';
import { Alert } from 'app/core/store/models/alert.model';

@Component({
  selector: 'app-edit-shipping-agent',
  templateUrl: './edit-shipping-agent.component.html',
})
export class EditShippingAgentComponent {
  shippingAgent: Object;
  alert: Alert;
  id: string;
  constructor(private route: ActivatedRoute, private api: ApiService, private toastr: ToastService) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api
          .get({
            path: '/shipping_agents/' + this.id
          })
          .subscribe(response => {
            this.shippingAgent = response;
          });
      }
    });
  }

  updateShippingAgent(shippingAgentFormValue) {
    this.api
      .put({
        path: '/shipping_agents/' + this.id,
        body: shippingAgentFormValue
      })
      .subscribe(
        res => {
          this.toastr.showSuccess({
            message: 'Shipping Agent saved.',
          });
        },
        err => {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: err
          });
        }
      );
  }
}
