import { Component, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-certiweight-measurement',
  templateUrl: './edit-measurement.component.html'
})
export class EditCertiweightMeasurementComponent {
  isLoading = false;
  countries: any[];
  data: any;
  id: string;
  errors: any;

  constructor(
    private api: ApiService,
    private toastr: ToastService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.resetErrors();
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api.get({
          path: `/container_measurements/${this.id}`,
        }).toPromise().then(response => {
          this.data = response;
          this.resetErrors();
          this.checkLiner();
          this.checkSite();
          this.checkTerminal();
        });
      }
    });
  }

  onSubmit(value) {
    let data;
    try {
      data = JSON.parse(value.data);
    } catch (e) {
      data = value.data;
    }

    this.isLoading = true;
    this.api.put({
      path: `/container_measurements/${this.id}`,
      params: { headers: { 'Content-Type': 'application/ld+json' } },
      body: {
        ...value,
        announcedOn: moment(value.announcedOn).format(),
        weight: value.weight.toString(),
        data: data,
      },
    }).toPromise().then(
      () => {
        this.toastr.showSuccess({
          message: extract('Measurement updated'),
        });
        this.router.navigateByUrl('/superadmin/certiweight');
      },
      err => {
        this.toastr.showDanger({
          title: extract('Failed to update measurement'),
          message: err,
        });
        this.isLoading = false;
      },
    );

  }
  checkSite() {
    if (!this.data.site) {
      this.errors.site.push('Measurement doesnt have site!');
    }
    return false;
  }

  checkLiner() {
    if (this.data.liner && !this.data.liner.aliases.find(a => a.code === 'SCAC')) {
      this.errors.liner.push('Liner has no SCAC alias!');
    }
    return false;

  }

  checkTerminal() {
    if (this.data.terminal && !this.data.terminal.aliases.find(a => a.code === 'SCAC')) {
      this.errors.terminal.push('Terminal has no SCAC alias!');
    }

    return false;
  }

  resetErrors() {
    this.errors = {
      liner: [],
      site: [],
      terminal: []
    };
  }
}
