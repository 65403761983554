import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { loadPreannouncements, updatePreannouncement } from 'app/core/store/actions/preannouncement.actions';
import { Preannouncement } from 'app/core/store/models/preannouncement.model';
import { State } from 'app/core/store/store.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-preannouncement',
  templateUrl: './edit-preannouncement.component.html',
})
export class EditPreannouncementComponent implements OnDestroy {
  preannouncement: Preannouncement;
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private updates$: Actions
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.store
          .select(state => state.preannouncements)
          .pipe(untilDestroyed(this))
          .subscribe(state => {
            if (!state) {
              this.store.dispatch(loadPreannouncements());
              return;
            }
            this.preannouncement = state.find(c => c.id === data.get('id'));
          });
      }
    });

    // on edit success, redirect
  }

  submit(data) {
    this.store.dispatch(updatePreannouncement({
      id: this.preannouncement.id,
      data
    }));
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
