/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../transport-form/transport-form.component.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../shared/datepicker-config";
import * as i5 from "../transport-form/transport-form.component";
import * as i6 from "@angular/forms";
import * as i7 from "@ngrx/store";
import * as i8 from "../../../services/icon.service";
import * as i9 from "@ngrx/effects";
import * as i10 from "../../../services/toast.service";
import * as i11 from "./confirm-publish-transport.component";
import * as i12 from "../../../services/marketpost-parser.service";
import * as i13 from "../../../services/soft-delete.service";
var styles_ConfirmPublishTransportComponent = [];
var RenderType_ConfirmPublishTransportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmPublishTransportComponent, data: {} });
export { RenderType_ConfirmPublishTransportComponent as RenderType_ConfirmPublishTransportComponent };
export function View_ConfirmPublishTransportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "card-header d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [["class", "align-self-center mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "btn ml-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "m-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "app-transport-form", [], null, [[null, "transportFormSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("transportFormSubmitted" === en)) {
        var pd_0 = (_co.publishToMarketplace($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TransportFormComponent_0, i2.RenderType_TransportFormComponent)), i0.ɵprd(4608, null, i3.NgbDateAdapter, i4.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i3.NgbDateParserFormatter, i4.NgbDateCustomParserFormatter, []), i0.ɵdid(11, 770048, null, 0, i5.TransportFormComponent, [i6.FormBuilder, i7.Store, i8.IconService, i3.NgbModal, i9.Actions, i10.ToastService], { transportTypes: [0, "transportTypes"], transport: [1, "transport"], liners: [2, "liners"], containerTypes: [3, "containerTypes"], loading: [4, "loading"], needsConfirmation: [5, "needsConfirmation"] }, { transportFormSubmitted: "transportFormSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.transportTypes; var currVal_3 = _co.transport; var currVal_4 = _co.allLiners; var currVal_5 = _co.allContainerTypes; var currVal_6 = _co.isLoading; var currVal_7 = true; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Confirm marketplace publication")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Close")); _ck(_v, 5, 0, currVal_1); }); }
export function View_ConfirmPublishTransportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-publish-transport", [], null, null, null, View_ConfirmPublishTransportComponent_0, RenderType_ConfirmPublishTransportComponent)), i0.ɵdid(1, 180224, null, 0, i11.ConfirmPublishTransportComponent, [i10.ToastService, i7.Store, i9.Actions, i3.NgbActiveModal, i12.MarketpostParserService, i13.SoftDeleteService], null, null)], null, null); }
var ConfirmPublishTransportComponentNgFactory = i0.ɵccf("app-confirm-publish-transport", i11.ConfirmPublishTransportComponent, View_ConfirmPublishTransportComponent_Host_0, { transport: "transport", transportId: "transportId" }, {}, []);
export { ConfirmPublishTransportComponentNgFactory as ConfirmPublishTransportComponentNgFactory };
