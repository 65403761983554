import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../../user/user.auth';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastService
  ) { }

  canActivate(): Observable<boolean> {
    // https://toddmotto.com/preloading-ngrx-store-route-guards
    return this.authService.getFromStoreOrAPI().pipe(
      map(data => {
        const isAdmin = this.authService.isAdmin(data);
        if (!isAdmin) {
          this.router.navigate(['/dashboard']);
          this.toastr.showDanger({
            title: extract('Not allowed'),
            message: extract('Only Admins can access this page.')
          });
        }
        return isAdmin;
      }),
      catchError(() => of(false))
    );
  }
}
