import * as types from '../types/role.types';
import * as RolesActions from '../actions/roles.actions';

const INITIAL_STATE = null;

export function reducer(state = INITIAL_STATE, action: RolesActions.All) {
  switch (action.type) {
    case types.roles.LOAD_SUCCEEDED:
      return [...action.payload['hydra:member']];
  }
  return state;
}
