var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/circle.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as CircleActions from '../actions/circles.actions';
import { ApiService } from '../../api/api.service';
var CircleEffects = /** @class */ (function () {
    function CircleEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadCircles$ = this.actions$.pipe(ofType(types.circles.LOAD_CIRCLES), concatMap(function () { return _this.api.get({ path: '/circles' }).pipe(map(function (data) { return new CircleActions.LoadCirclesSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.createCircle$ = this.actions$.pipe(ofType(types.circles.CREATE_CIRCLE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/circles', body: payload
        }).pipe(map(function (data) { return new CircleActions.CreateCircleSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.deleteCircle$ = this.actions$.pipe(ofType(types.circles.DELETE_CIRCLE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: '/circles/' + payload
        }).pipe(map(function (data) { return new CircleActions.DeleteCircleSuccess(data); }), catchError(function (error) { return of(new CircleActions.DeleteCircleFail(error)); })); }));
        this.editCircle$ = this.actions$.pipe(ofType(types.circles.EDIT_CIRCLE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/circles/' + payload.id, body: payload.body
        }).pipe(map(function (data) { return new CircleActions.EditCircleSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CircleEffects.prototype, "loadCircles$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CircleEffects.prototype, "createCircle$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CircleEffects.prototype, "deleteCircle$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CircleEffects.prototype, "editCircle$", void 0);
    return CircleEffects;
}());
export { CircleEffects };
