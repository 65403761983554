import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SubscriptionType } from '../../store/models/subscriptions.model';
import { IconService } from 'app/services/icon.service';

const PAYMENT_TYPES = [
  {
    name: 'Fixed'
  },
  {
    name: 'Transactional'
  }
];
@Component({
  selector: 'app-subscription-type-form',
  templateUrl: './subscription-type-form.component.html',
})
export class SubscriptionTypeFormComponent implements OnInit, OnChanges {
  @Input() subscriptionType: SubscriptionType;
  @Input() isLoading: boolean;
  @Output() subscriptionTypeFormSubmitted = new EventEmitter();

  subscriptionTypeForm: FormGroup;
  paymentTypes = PAYMENT_TYPES;
  websiteIcons: string[] = [];

  constructor(private fb: FormBuilder, private iconService: IconService) {
    this.websiteIcons = this.iconService.getIconsForSubscriptionTypes();
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.subscriptionType &&
      changes.subscriptionType.currentValue
    ) {
      if (!this.subscriptionTypeForm) {
        this.createForm();
      }
      const subType = changes.subscriptionType.currentValue;
      this.subscriptionTypeForm.patchValue({
        name: subType.name,
        code: subType.code,
        paymentType: this.paymentTypes.find(
          type => type.name.toUpperCase() === subType.paymentType.toUpperCase()
        ).name,
        credits: subType.credits,
        displayOnWebsite: subType.displayOnWebsite,
        websiteIcon: subType.websiteIcon,
        websiteDescription: subType.websiteDescription
      });

      if (
        subType.subscriptionTypeActions &&
        subType.subscriptionTypeActions.length > 0
      ) {
        this.removeAction(0);
        subType.subscriptionTypeActions.forEach(action => {
          this.addAction();
        });
        this.subscriptionTypeForm.patchValue({
          subscriptionTypeActions: subType.subscriptionTypeActions
        });
      }
    }
  }

  /**
   * Initializes the form
   */
  createForm() {
    this.subscriptionTypeForm = this.fb.group({
      name: '',
      code: '',
      credits: [null],
      paymentType: this.paymentTypes[0].name,
      subscriptionTypeActions: this.fb.array([this.createAction()]),
      displayOnWebsite: false,
      websiteIcon: null,
      websiteDescription: null,
    });
  }

  createAction(): FormGroup {
    return this.fb.group({
      code: [null],
      name: [null],
      credits: [null]
    });
  }

  addAction(): void {
    const actions = <FormArray>(
      this.subscriptionTypeForm.controls['subscriptionTypeActions']
    );
    actions.push(this.createAction());
  }
  removeAction(index): void {
    const actions = <FormArray>(
      this.subscriptionTypeForm.controls['subscriptionTypeActions']
    );
    actions.removeAt(index);
  }

  onSubmit() {
    const body = this.subscriptionTypeForm.value;

    body.credits = body.credits.toString();

    // Remove empty actions
    body.subscriptionTypeActions = body.subscriptionTypeActions.filter(
      action => action.name || action.credits
    );
    // Cast each action to string so the backend accepts them
    body['subscriptionTypeActions'] = body['subscriptionTypeActions'].map(
      action => {
        return {
          id: this.subscriptionType.subscriptionTypeActions.find(
            subTypeAction => subTypeAction.code === action.code
          )['@id'],
          credits: action.credits.toString()
        };
      }
    );
    this.subscriptionTypeFormSubmitted.emit(body);
  }
}
