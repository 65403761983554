import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/marketplace.types';
import { SearchService } from 'app/services/search.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-my-demands',
  templateUrl: 'my-demands.component.html',
})
export class MyDemandsComponent implements OnDestroy {
  navbarTitle = 'Company Demands';
  demands: any[];
  loading = true;
  selected: any[] = [];
  alert: {
    type: string;
    message: string;
    icon?: string;
  };
  tenantId = '';

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private searchService: SearchService,
    private toastr: ToastService
  ) {
    this.searchService.resetSearch();
    this.searchService.filter();

    this.store
      .select(state => state.session)
      .pipe(untilDestroyed(this))
      .subscribe(session => {
        if (session) {
          this.tenantId = session.tenant;
        }
      });
    this.store
      .select(state => {
        if (state.marketplace) {
          return state.marketplace.marketposts;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(marketposts => {
        if (marketposts) {
          this.demands = marketposts.map(mp => mp._source).filter(
            mp =>
              mp.marketPostType === 'demand' &&
              mp['tenant'].id === this.tenantId
          );
          this.loading = false;
        }
      });

    updates$
      .pipe(ofType(Types.marketposts.LOAD_MARKETPOSTS_DEMANDS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to load marketpost demands'
        });
        this.loading = false;
      });
    updates$
      .pipe(ofType(Types.marketposts.UNPUBLISH_MARKET_POST_DEMAND_SUCCESS))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Demand deleted successfully!'
        });
        this.loading = false;
        this.searchService.filter();
      });
    updates$
      .pipe(ofType(Types.marketposts.UNPUBLISH_MARKET_POST_DEMAND_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: data.toString()
        });
        this.loading = false;
      });
  }

  ngOnDestroy() { }

  closeAlert() {
    this.alert = null;
  }
}
