import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { deletePreannouncement, loadPreannouncements } from 'app/core/store/actions/preannouncement.actions';
import { Preannouncement } from 'app/core/store/models/preannouncement.model';
import { State } from 'app/core/store/store.model';
import { extract } from 'app/services/i18n.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-preannouncements',
  templateUrl: './preannouncements.component.html',
})
export class PreannouncementsComponent implements OnDestroy {
  preannouncements: Preannouncement[];
  isLoading: boolean;

  constructor(
    private router: Router,
    private store: Store<State>,
    private modalService: NgbModal
  ) {
    this.store
      .select(state => state.preannouncements)
      .pipe(untilDestroyed(this))
      .subscribe(preannouncementState => {
        if (!preannouncementState) {
          this.store.dispatch(loadPreannouncements());
          this.isLoading = true;
          return;
        }
        this.preannouncements = [...preannouncementState];
        this.isLoading = false;
      });
  }

  goToEdit(preannouncement) {
    this.router.navigateByUrl(`/crm/preannouncements/${preannouncement.id}/edit`);
  }

  delete(preannouncement: Preannouncement) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this preannouncement?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.store.dispatch(deletePreannouncement({ id: preannouncement.id }));
    });
  }

  ngOnDestroy() { }
}
