import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { LoadInvoice, LoadInvoices, UpdateInvoice } from 'app/core/store/actions/invoice.actions';
import { PaymentNote } from 'app/core/store/models/payment-note.model';
import {
  LoadInvoiceableTransports,
  LoadNextInvoiceableTransportsPage
} from 'app/core/store/actions/transports.actions';
import { LoadCustomers } from 'app/core/store/actions/customer.actions';
import { LoadTaxRates } from 'app/core/store/actions/tax-rate.actions';
import * as InvoiceTypes from 'app/core/store/types/invoice.types';
import * as CustmomerTypes from 'app/core/store/types/customer.types';
import * as TaxRateTypes from 'app/core/store/types/tax-rate.types';
import * as TransportTypes from 'app/core/store/types/transport.types';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { TaxRate } from 'app/core/store/models/tax-rate.model';
import { DownloadService } from 'app/services/download.service';
import { Business } from 'app/core/store/models/business.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
})
export class EditInvoiceComponent implements OnDestroy {
  id: string;
  invoice: PaymentNote;
  customers: Business[];
  transports: any[];
  filteredTransports: any[];
  selectedTransports: string[] = [];
  isLoadingCustomers: boolean;
  taxRates: TaxRate[];
  isLoadingTaxRates: boolean;
  isLoadingTransports: boolean;
  query = '';
  since?: string;
  until?: string;
  isLoading: boolean;
  downloadPDFWhenReady: boolean;
  selectedCustomerId: string;
  nextPage: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private updates$: Actions,
    private store: Store<State>,
    private downloadService: DownloadService,
    private toastr: ToastService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.store
          .select(state => state.crm.invoices)
          .pipe(untilDestroyed(this))
          .subscribe(s => {
            if (!s || !s.invoices || !s.invoices.find(c => c.id === this.id)) {
              this.store.dispatch(new LoadInvoice(this.id));
              return;
            }

            // re-hydrate entity
            this.invoice = s.invoices.find(c => c.id === this.id);
            if (!this.invoice.items) {
              this.store.dispatch(new LoadInvoice(this.id));
              return;
            }

            // Add transport items to selected transports
            this.selectedTransports = this.invoice.items
              .filter(item => item.transport)
              .map(item => item.transport['id']);

            if (this.transports && this.selectedTransports.length > 0) {
              this.selectedTransports.forEach(transportId => {
                this.filteredTransports = this.transports.filter(
                  t => t.id !== transportId
                );
              });
            }

            this.selectedCustomerId = this.invoice.business['id'];

            this.store.dispatch(new LoadInvoiceableTransports({
              customers: this.selectedCustomerId
            }));
          });
      }
    });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.invoiceableTransports &&
          state.crm.invoiceableTransports.invoiceableTransports
        ) {
          return state.crm.invoiceableTransports.invoiceableTransports;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(invoiceableTransportsState => {
        if (!invoiceableTransportsState) {
          return;
        }
        this.transports = [...invoiceableTransportsState];

        // Filter out selected transports so they can't be selected twice
        if (this.selectedTransports.length > 0) {
          this.selectedTransports.forEach(transportId => {
            this.filteredTransports = this.transports.filter(
              t => t.id !== transportId
            );
          });
        } else {
          this.filteredTransports = [...invoiceableTransportsState];
        }
        this.toastr.clear();
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.invoiceableTransports &&
          state.crm.invoiceableTransports.nextInvoiceableTransportPage
        ) {
          return state.crm.invoiceableTransports.nextInvoiceableTransportPage;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(nextPage => {
        this.nextPage = nextPage;
      });

    this.store
      .select(state => state.crm.customers)
      .pipe(untilDestroyed(this))
      .subscribe(customersState => {
        if (!customersState || !customersState.customers) {
          this.store.dispatch(new LoadCustomers());
          this.isLoadingCustomers = true;
          return;
        }
        this.customers = customersState.customers;
        this.isLoadingCustomers = false;
      });
    this.store
      .select(state => state.crm.taxRates)
      .pipe(untilDestroyed(this))
      .subscribe(taxRatesState => {
        if (!taxRatesState || !taxRatesState.taxRates) {
          this.store.dispatch(new LoadTaxRates());
          this.isLoadingTaxRates = true;
          return;
        }
        this.taxRates = taxRatesState.taxRates;
        this.isLoadingTaxRates = false;
      });
    this.updates$
      .pipe(ofType(InvoiceTypes.invoice.UPDATE_INVOICE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to update invoice!')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(InvoiceTypes.invoice.UPDATE_INVOICE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.store.dispatch(new LoadInvoices());
        this.toastr.showSuccess({
          message: extract('Invoice updated successfully!')
        });
        if (this.downloadPDFWhenReady) {
          this.downloadService.downloadPDF(
            `/crm/invoice/download/${response['payload'].id}`,
            response['payload'].number,
          ).then(() => {
            this.router.navigateByUrl('/crm/invoices');
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          });
        } else {
          this.router.navigateByUrl('/crm/invoices');
          this.isLoading = false;
        }
      });
    this.updates$
      .pipe(ofType(CustmomerTypes.customer.LOAD_CUSTOMERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load customers')
        });
        this.isLoadingCustomers = false;
      });
    this.updates$
      .pipe(ofType(TaxRateTypes.taxRate.LOAD_TAX_RATES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load tax rates')
        });
        this.isLoadingTaxRates = false;
      });
    this.updates$
      .pipe(ofType(TransportTypes.transports.LOAD_INVOICEABLE_TRANSPORTS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load transports')
        });
        this.isLoadingTransports = false;
      });
  }

  handleSearchTransports(payload: { query?: string, since?: string, until?: string }) {
    this.query = payload.query;
    this.since = payload.since;
    this.until = payload.until;
    this.filterTransports(this.selectedCustomerId, this.query, this.since, this.until);
  }

  handleSelectCustomer(customerId: string | null) {
    // Clear selected transports if customer is deselected
    if (!customerId) {
      this.selectedTransports.forEach(transportId =>
        this.handleRemoveTransport(transportId)
      );
      this.selectedTransports = [];
    }
    this.selectedCustomerId = customerId;
    this.filterTransports(this.selectedCustomerId, this.query, this.since, this.until);
  }

  filterTransports(customerId: string, query: string, since?: string, until?: string) {
    this.store.dispatch(
      new LoadInvoiceableTransports({
        query: query,
        since: since,
        until: until,
        customers: customerId
      })
    );
  }

  handleSelectTransport(transportId: string) {
    this.selectedTransports.push(transportId);
    this.filteredTransports = this.filteredTransports.filter(t => t.id !== transportId);
  }
  handleRemoveTransport(transportId: string) {
    this.selectedTransports.filter(t => t !== transportId);

    let transport = this.transports.find(t => t.id === transportId);

    // if transport is not found it can be already invoiced
    if (!transport) {
      const item = this.invoice.items
        .filter(i => i.transport)
        .find(i => i.transport['id'] === transportId);
      transport = item ? item.transport : null;
    }
    // Only insert transport if it is not already present
    if (transport && !this.filteredTransports.find(t => t.id === transportId)) {
      this.filteredTransports.unshift(transport);
    }
  }

  updateInvoice(invoiceFormValue) {
    this.store.dispatch(
      new UpdateInvoice({
        invoiceId: this.id,
        body: invoiceFormValue
      })
    );
    this.isLoading = true;
  }

  updateAndDownloadAsPDF(invoiceFormValue) {
    this.updateInvoice(invoiceFormValue);
    this.downloadPDFWhenReady = true;
  }

  loadNextTransportsPage() {
    this.store.dispatch(
      new LoadNextInvoiceableTransportsPage({
        page: this.nextPage,
        query: this.query,
        since: this.since,
        until: this.until,
        customers: this.selectedCustomerId
      })
    );
    this.toastr.showInfoLoading({
      message: extract('Loading extra transports...')
    });
  }

  ngOnDestroy() { }
}
