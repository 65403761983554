import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as DateFns from 'date-fns';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input()
  today: Date = new Date();
  // if you pass a toDate, then it becomes selectable and that value is used
  @Input()
  toDate?: Date = null;
  // otherwise toDate is calculated as today + toDateOffset days
  @Input()
  toDateOffset: number = 4;

  visibleFrom: boolean = false;
  visibleTo: boolean = false;

  // only used to set initial values and keep the selected ones
  fromDateModel: NgbDateStruct;
  toDateModel?: NgbDateStruct;

  @Output()
  dayChange: EventEmitter<{fromDate: Date, toDate: Date}> = new EventEmitter();

  ngOnInit() {
    this.updateModels();
  }

  addDay() {
    this.today = DateFns.addDays(this.today, 1);
    if (this.toDate) {
      this.toDate = DateFns.addDays(this.toDate, 1);
      this.dayChange.emit({ fromDate: this.today, toDate: this.toDate });
    } else {
      const toDate: Date = DateFns.addDays(this.today, this.toDateOffset);
      this.dayChange.emit({ fromDate: this.today, toDate: toDate });
    }
    this.updateModels();
  }

  subtractDay() {
    this.today = DateFns.subDays(this.today, 1);
    if (this.toDate) {
      this.toDate = DateFns.subDays(this.toDate, 1);
      this.dayChange.emit({ fromDate: this.today, toDate: this.toDate });
    } else {
      const toDate: Date = DateFns.addDays(this.today, this.toDateOffset);
      this.dayChange.emit({ fromDate: this.today, toDate: toDate });
    }
    this.updateModels();
  }

  onFromDateSelection(date: NgbDateStruct) {
    this.today = new Date(date.year, date.month - 1, date.day);
    const toDate: Date = (this.toDate)
      ? this.toDate
      : DateFns.addDays(this.today, this.toDateOffset);
    this.dayChange.emit({ fromDate: this.today, toDate: toDate });
  }

  onToDateSelection(date: NgbDateStruct) {
    this.toDate = new Date(date.year, date.month - 1, date.day);
    this.dayChange.emit({ fromDate: this.today, toDate: this.toDate });
  }

  updateModels()
  {
    this.fromDateModel = {
      day: this.today.getDate(),
      month: this.today.getMonth() + 1,
      year: this.today.getFullYear()
    };
    if (this.toDate) {
      this.toDateModel = {
        day: this.toDate.getDate(),
        month: this.toDate.getMonth() + 1,
        year: this.toDate.getFullYear()
      };
    }
  }
}
