import { Action } from '@ngrx/store';

import * as types from '../types/market-post.types';

export class LoadMarketPostDemandReplies implements Action {
  readonly type = types.marketPostDemandReplies.LOAD_MARKET_POST_DEMAND_REPLIES;
  constructor(public payload?: any) {}
}

export class LoadMarketPostDemandRepliesSuccess implements Action {
  readonly type =
    types.marketPostDemandReplies.LOAD_MARKET_POST_DEMAND_REPLIES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadMarketPostDemandRepliesFail implements Action {
  readonly type =
    types.marketPostDemandReplies.LOAD_MARKET_POST_DEMAND_REPLIES_FAILED;
  constructor(public payload: any) {}
}

export type All =
  | LoadMarketPostDemandReplies
  | LoadMarketPostDemandRepliesSuccess
  | LoadMarketPostDemandRepliesFail;
