import { Md5 } from 'ts-md5/dist/md5';

import * as UserActions from '../actions/user.actions';
import { UserContext } from '../models/user-contexts.model';
import * as types from '../types/user.types';

export type UserState = UserContext & { avatar: string; };

const INITIAL_STATE: UserState = {
  '@id': null,
  id: null,
  '@type': 'User',
  roles: [],
  userContextGroups: null,
  phoneNumber: '',
  locale: 'nl',
  avatar: null,
  user: null,
};

export function reducer(state = INITIAL_STATE, action: UserActions.All): UserState {
  switch (action.type) {
    case types.users.LOAD_SUCCEEDED:
      const user = action.payload;
      // Set the user avatar url
      user.avatar =
        'https://www.gravatar.com/avatar/' +
        Md5.hashStr(user.user.email).toString() +
        '.jpg';
      return Object.assign({}, state, user);
    case types.users.USER_LOGOUT:
      return null;
    case types.users.CLEAR:
      return {
        '@id': null,
        id: null,
        '@type': 'User',
        roles: [],
        userContextGroups: null,
        phoneNumber: '',
        locale: 'nl',
        avatar: null,
        user: null,
      };
  }
  return state;
}
