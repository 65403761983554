import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'app/services/toast.service';
import { Visit } from 'app/core/store/models/visits.model';
import { ApiService } from 'app/core/api/api.service';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import * as VisitTypes from 'app/core/store/types/visit.types';
import { UpdateVisit } from 'app/core/store/actions/visits.actions';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-visit',
  templateUrl: './edit-visit.component.html',
})
export class EditVisitComponent implements OnDestroy {
  title = extract('Edit visit');
  visit: Visit;
  id: string;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private api: ApiService,
    private toastr: ToastService,
    private router: Router,
    private updates$: Actions
  ) {
    // TODO: refactor this so it uses the store
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api
          .get({
            path: '/visits/' + this.id
          })
          .subscribe((response: Visit) => {
            this.visit = response;
          });
      }
    });
    this.updates$
      .pipe(ofType(VisitTypes.visits.UPDATE_VISIT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Prenotification updated sucessfully')
        });
        this.router.navigateByUrl('/tms/prenotifications');
      });
    this.updates$
      .pipe(ofType(VisitTypes.visits.UPDATE_VISIT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  updateVisit(visitFormValue) {
    this.isLoading = true;
    this.store.dispatch(
      new UpdateVisit({
        visitId: this.id,
        body: visitFormValue
      })
    );
  }

  ngOnDestroy() { }
}
