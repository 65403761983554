import { State } from '../store/store.model';
import { Store } from '@ngrx/store';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { AuthService } from '../user/user.auth';
import { I18nService } from 'app/services/i18n.service';
import { LoadUser } from '../store/actions/user.actions';
import { Tenant } from '../store/models/tenant.model';
import { UserContext } from '../store/models/user-contexts.model';
import { Role, Roles } from '../store/models/roles.model';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnDestroy {
  @Input()
  opened;
  routes = [
    {
      url: '/dashboard',
      title: 'Dashboard',
      icon: 'fa-compass',
      allowedBySubscriptions: ['ALL']
    },
    {
      url: '/marketplace',
      title: 'Marketplace',
      icon: 'fa-truck',
      allowedBySubscriptions: ['MARKETPLACE']
    },
    {
      url: '/marketplace/create',
      title: 'New Market Post',
      icon: 'fa-plus',
      allowedBySubscriptions: ['MARKETPLACE']
    },
    {
      url: '/company-posts',
      title: 'Company Posts',
      icon: 'fa-list',
      allowedBySubscriptions: ['MARKETPLACE']
    },
    {
      url: '/reload',
      title: 'Reload',
      icon: 'fa-recycle',
      allowedBySubscriptions: ['RELOAD']
    },
    {
      url: '/reload-back-office',
      title: 'Reload Back Office',
      icon: 'fa-recycle',
      allowedBySubscriptions: ['RELOAD_BACK_OFFICE']
    },
    {
      url: '/contacts',
      title: 'Companies',
      icon: 'fa-address-book',
      allowedBySubscriptions: ['MARKETPLACE']
    },
    {
      url: '/crm',
      title: 'CRM',
      icon: 'fa-file-invoice-dollar',
      allowedBySubscriptions: ['TMS']
    },
    {
      url: '/tms',
      title: 'Planning',
      icon: 'fa-calendar-alt',
      allowedBySubscriptions: ['TMS']
    },
    {
      url: '/matching',
      title: 'Matching',
      icon: 'fa-bolt',
      allowedBySubscriptions: ['MATCHING']
    },
    {
      url: '/certiweight',
      title: 'Certiweight',
      icon: 'fa-weight-hanging',
      allowedBySubscriptions: ['CERTIWEIGHT']
    },
    {
      url: '/certiweight-backoffice',
      title: 'Certiweight Back Office',
      icon: 'fa-balance-scale-right',
      allowedBySubscriptions: ['CERTIWEIGHT_BACK_OFFICE']
    },
    {
      url: '/settings',
      title: 'Settings',
      icon: 'fa-cog',
      allowedBySubscriptions: ['ALL']
    },
    {
      url: '/driver/tasks',
      title: 'Visits',
      icon: 'fa-clipboard-list',
      roles: ['driver'],
      allowedBySubscriptions: ['ALL']
    },
    {
      url: '/settings/me',
      title: 'Settings',
      icon: 'fa-user-cog',
      roles: ['driver'],
      allowedBySubscriptions: ['ALL']
    },
    {
      url: '/superadmin',
      title: 'Super Admin',
      icon: 'fa-star',
      roles: ['Super Admin'],
      allowedBySubscriptions: ['ALL']
    }
  ];
  isSuperAdmin = false;
  allowedSubscriptions: any[];
  user: UserContext;

  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private i18nService: I18nService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.store
      .select(state => state.user)
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        if (!user) {
          this.store.dispatch(new LoadUser());
          return;
        }
        this.user = user;
        if (user && user.locale) {
          this.i18nService.language = user.locale;
        }
        this.isSuperAdmin = this.authService.isSuperAdmin(user);

        this.authService
          .getUserTenantsFromStoreOrAPI()
          .pipe(take(1))
          .subscribe(tenants => {
            const tenant: Tenant = tenants.find(
              t => t.id === this.authService.getActiveTenant()
            );
            if (!tenant) {
              return;
            }
            this.allowedSubscriptions = [
              ...tenant.allowedSubscriptionTypes.map(st => st.code)
            ];
            this.changeDetector.markForCheck();
          });
      });
  }

  checkRouteAndRole(route) {
    if (!this.user) {
      return;
    }
    // If a route contains no roles, show it if the user is not a driver or is a driver and has other roles
    if (!route.roles) {
      const isDriver =
        this.user.roles.filter((role: Role) => role.code === Roles.DRIVER)
          .length > 0;
      return !isDriver || (isDriver && this.user.roles.length > 1);
    }

    // If a route is only for drivers, show it if the user is a driver or has other roles
    if (route.roles.indexOf('driver') !== -1) {
      return (
        this.user.roles.filter((role: Role) => role.code === Roles.DRIVER)
          .length > 0
      );
    }

    if (route.roles && route.roles.indexOf('Super Admin') !== -1) {
      return this.isSuperAdmin;
    }
    return false;
  }

  routeIsAllowedBySubscription(route) {
    return route.allowedBySubscriptions.some(subscriptionCode => {
      if (subscriptionCode === 'ALL') {
        return true;
      }
      if (
        !this.allowedSubscriptions ||
        this.allowedSubscriptions.length === 0
      ) {
        return false;
      }
      return this.allowedSubscriptions.indexOf(subscriptionCode) !== -1;
    });
  }

  ngOnDestroy() { }
}
