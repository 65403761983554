/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./user-info.component";
var styles_UserInfoComponent = [i0.styles];
var RenderType_UserInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserInfoComponent, data: {} });
export { RenderType_UserInfoComponent as RenderType_UserInfoComponent };
function View_UserInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.licensePlate; _ck(_v, 1, 0, currVal_0); }); }
export function View_UserInfoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "user__initals text-white"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "font-weight-bold"]], [[2, "user-name-center", null]], null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " ", " "])), i1.ɵppd(8, 1), i1.ɵppd(9, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserInfoComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.licensePlate; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.getUserInitials(_co.user))); _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.licensePlate; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 1), ((_co.user == null) ? null : _co.user.firstName))); var currVal_3 = i1.ɵunv(_v, 7, 1, _ck(_v, 9, 0, i1.ɵnov(_v, 1), ((_co.user == null) ? null : _co.user.lastName))); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_UserInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-info", [], null, null, null, View_UserInfoComponent_0, RenderType_UserInfoComponent)), i1.ɵdid(1, 49152, null, 0, i3.UserInfoComponent, [], null, null)], null, null); }
var UserInfoComponentNgFactory = i1.ɵccf("app-user-info", i3.UserInfoComponent, View_UserInfoComponent_Host_0, { user: "user", licensePlate: "licensePlate" }, {}, []);
export { UserInfoComponentNgFactory as UserInfoComponentNgFactory };
