import { Go } from '../actions/router.actions';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/tenant.types';
import * as TenantActions from '../actions/tenants.actions';

@Injectable()
export class TenantEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadTenants$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenants.LOAD_TENANTS),
    map((action: TenantActions.LoadTenants) => action.payload),
    concatMap(payload => this.api.get({
      path: '/me/tenants'
    }).pipe(
      map(data => new TenantActions.LoadTenantsSuccess(data)),
      tap(data => {
        if (payload && payload.onSuccess) {
          payload.onSuccess(data);
        }
        if (data.payload['hydra:totalItems'] === 1) {
          return new Go({ path: ['/marketplace'] });
        }
      }),
      catchError(error => {
        if (payload && payload.onFailure) {
          payload.onFailure(error);
        }
        return of(new TenantActions.LoadTenantsFailed(error));
      })
    ))
  );

  @Effect()
  loadAllTenants$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenants.LOAD_ALL_TENANTS),
    concatMap(() => this.api.get({ path: '/tenants' }).pipe(
      map(data => new TenantActions.LoadAllTenantsSuccess(data)),
      catchError(error => of(new TenantActions.LoadTenantsFailed(error)))
    )),
  );

  @Effect()
  createTenant$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenants.CREATE_TENANT),
    map((action: TenantActions.CreateTenant) => action.payload),
    concatMap(payload => this.api.post({ path: '/tenants', body: payload }).pipe(
      map(data => new TenantActions.CreateTenantSuccess(data)),
      catchError(error => of(new TenantActions.CreateTenantFail(error)))
    )),
  );

  @Effect()
  enableTenant$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenants.ENABLE_TENANT),
    map((action: TenantActions.EnableTenant) => action.payload),
    concatMap(payload => this.api.post({
      path: '/tenants/' + payload + '/enable', body: {}
    }).pipe(
      map(data => new TenantActions.EnableTenantSuccess(data)),
      catchError(error => of(new TenantActions.EnableTenantFail(error)))
    )),
  );

  @Effect()
  disableTenant$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenants.DISABLE_TENANT),
    map((action: TenantActions.DisableTenant) => action.payload),
    concatMap(payload => this.api.post({
      path: '/tenants/' + payload + '/disable', body: {}
    }).pipe(
      map(data => new TenantActions.DisableTenantSuccess(data)),
      catchError(error => of(new TenantActions.DisableTenantFail(error)))
    )),
  );

  @Effect()
  deleteTenant$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenants.DELETE_TENANT),
    map((action: TenantActions.DeleteTenant) => action.payload),
    concatMap(payload => this.api.delete({
      path: '/tenants/' + payload, body: {}
    }).pipe(
      map(data => new TenantActions.DeleteTenantSuccess(data)),
      catchError(error => of(new TenantActions.DeleteTenantFail(error)))
    )),
  );

  @Effect()
  updateTenant$: Observable<Action> = this.actions$.pipe(
    ofType(types.tenants.UPDATE_TENANT),
    map((action: TenantActions.UpdateTenant) => action.payload),
    concatMap(payload => this.api.put({
      path: '/tenants/' + payload.id, body: payload.body
    }).pipe(
      map(data => new TenantActions.UpdateTenantSuccess(data)),
      catchError(error => of(new TenantActions.UpdateTenantFail(error)))
    )),
  );
}
