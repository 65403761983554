import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Attachment } from 'app/core/store/models/attachment.model';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import {
  DeleteAttachment,
  UpdateAttachment
} from 'app/core/store/actions/attachment.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as AttachmentTypes from 'app/core/store/types/attachment.types';
import { DownloadService } from 'app/services/download.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  attachment: Attachment;

  titleRef: ElementRef;
  editMode = false;
  attachmentTitle: string;

  isDeleting: boolean;
  isDownloading: boolean;
  isUpdating: boolean;

  @ViewChild('title', { static: false })
  set title(elRef: ElementRef) {
    if (elRef) {
      this.titleRef = elRef;
    }
  }

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private downloadService: DownloadService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.updates$
      .pipe(ofType(
        AttachmentTypes.attachment.DELETE_ATTACHMENT_SUCCEEDED,
        AttachmentTypes.attachment.DELETE_ATTACHMENT_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isDeleting = false;
      });
    this.updates$
      .pipe(ofType(
        AttachmentTypes.attachment.UPDATE_ATTACHMENT_SUCCEEDED,
        AttachmentTypes.attachment.UPDATE_ATTACHMENT_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.editMode = false;
        this.isUpdating = false;
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }

    if (changes.attachment) {
      this.attachmentTitle = changes.attachment.currentValue.title;
    }
  }

  deleteAttachment() {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this attachment?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.isDeleting = true;
      this.store.dispatch(
        new DeleteAttachment({
          attachmentId: this.attachment.id
        })
      );
    });
  }

  updateAttachment() {
    const body = Object.assign({}, this.attachment, {
      title: this.attachmentTitle
    });
    this.isUpdating = true;
    this.store.dispatch(
      new UpdateAttachment({
        attachmentId: this.attachment.id,
        body
      })
    );
  }

  downloadAttachment() {
    this.isDownloading = true;
    this.downloadService
      .downloadAttachment(this.attachment.filename, this.attachment.id)
      .then(() => (this.isDownloading = false));
  }

  enableEditMode() {
    this.editMode = true;
    setTimeout(() => {
      this.titleRef.nativeElement.focus();
    });
  }

  ngOnDestroy() { }
}
