export interface SubscriptionType {
  '@id': string;
  '@type': string;
  name: string;
  beta: boolean;
  code: string;
  credits: string;
  id: string;
  paymentType: string;
  subscriptionTypeActions: SubscriptionTypeAction[];
  displayOnWebsite: boolean;
  websiteDescription?: string;
  websiteIcon?: string;
}

export interface SubscriptionTypeAction {
  id: string;
  code: string;
  name: string;
  credits: string;
}

export interface Subscription {
  '@context': string;
  '@id': string;
  '@type': string;
  subscriptionType: SubscriptionType;
  startDate: string;
  endDate: string;
  autoRenew: boolean;
  id: string;
}

export enum SubscriptionTypes {
  TMS = 'TMS',
  MATCHING = 'MATCHING',
  MARKETPLACE = 'MARKETPLACE',
  PSA_TRUCK_VISITS = 'PSA_TRUCK_VISITS',
  RELOAD = 'RELOAD',
}

export enum MatchingSubscriptionTypeActions {
  MATCHING_MATCH_MARKET_POST = 'MATCHING_MATCH_MARKET_POST'
}
