import { Injectable } from '@angular/core';
import { Autocomplete } from './autocomplete.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/api/api.service';

@Injectable()
export class LocationService {
  constructor(
    private autocompleteService: Autocomplete,
    private api: ApiService
  ) { }
  location(term: string, onlyPublicLocations = false): Observable<any> {
    const options = {
      include_internal_locations: !onlyPublicLocations,
      cities_only: true
    };
    const results = this.autocompleteService
      .search('location', term, options)
      .pipe(map(locations => {
        if (locations) {
          return locations.features.map(location => {
            const properties = location.properties;
            const geometry = location.geometry;
            let name = properties.name;
            if (properties.postcode) {
              name += ` (${properties.postcode})`;
            }
            if (properties.country) {
              name += `, ${properties.country}`;
            }
            return {
              id: properties['@id'],
              shortName: properties.short_name || '',
              name,
              country: properties.country || null,
              longitude: geometry.coordinates[0],
              latitude: geometry.coordinates[1]
            };
          });
        }
      }));
    return results;
  }

  locationFormatter = (x: { name: String; postcode?: String }) => {
    return x.postcode ? x.name + ' (' + x.postcode + ')' : x.name;
  }

  privateLocation(term: string): Observable<any> {
    const options = {
      include_internal_locations: true,
      cities_only: true
    };
    const results = this.autocompleteService
      .search('location', term, options)
      .pipe(map(locations => {
        if (locations) {
          return locations.features.map(location => {
            if (location.properties.visibility === 'private') {
              return {
                id: location.properties.id,
                shortName: location.properties.short_name || null,
                name: location.properties.name || null,
                address: location.properties.address || null,
                longitude: location.geometry.coordinates[0],
                latitude: location.geometry.coordinates[1],
                public_location: location.properties.public_location || null,
                visibility: location.properties.visibility
              };
            } else {
              return {
                id: location.properties['@id'],
                shortName: location.properties.short_name || '',
                name: location.properties.postcode
                  ? location.properties.name +
                  ' (' +
                  location.properties.postcode +
                  ')'
                  : location.properties.name,
                longitude: location.geometry.coordinates[0],
                latitude: location.geometry.coordinates[1],
                visibility: location.properties.visibility
              };
            }
          });
        }
      }));
    return results;
  }

  depot(term: string): Observable<any> {
    const options = {
      depots_only: true
    };
    const results = this.autocompleteService
      .search('location', term, options)
      .pipe(map(locations => {
        if (locations) {
          return locations.features.map(location => {
            const properties = location.properties;
            const geometry = location.geometry;
            let name = properties.name;
            if (properties.postcode) {
              name += ` (${properties.postcode})`;
            }
            if (properties.country) {
              name += `, ${properties.country}`;
            }
            return {
              id: properties['@id'],
              shortName: properties.short_name || '',
              name,
              country: properties.country || null,
              longitude: geometry.coordinates[0],
              latitude: geometry.coordinates[1]
            };
          });
        }
      }));
    return results;
  }

  reverseGeocode(latitude: string, longitude: string) {
    return this.api.get({ path: `/reverse-geocode/${longitude}/${latitude}` });
  }

  address(term: string): Observable<any> {
    const results = this.autocompleteService
      .search('location', term)
      .pipe(map(locations => {
        if (locations) {
          return (
            locations.features
              // .filter(location => location.properties.street)
              .map(location => {
                return {
                  id: location.properties['@id'],
                  street:
                    location.properties.street ||
                    location.properties.name ||
                    '',
                  housenumber: location.properties.housenumber || '',
                  postcode: location.properties.postcode || '',
                  city: location.properties.city || '',
                  longitude: location.geometry.coordinates[0],
                  latitude: location.geometry.coordinates[1]
                };
              })
          );
        }
      }));
    return results;
  }

  addressFormatter = (
    x:
      | {
        street: string;
        housenumber?: string;
        postcode?: string;
        city?: string;
      }
      | string
  ) => {
    if (typeof x === 'string') {
      return x;
    }
    if (x['fullAddress']) {
      return x['fullAddress'];
    }
    return `${x.street ? x.street : ''} ${x.housenumber ? x.housenumber : ''} ${
      x.postcode ? x.postcode : ''
      } ${x.city ? x.city : ''}`;
  }
}
