import { SamlService } from '../services/saml.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sso-login',
  template: '',
})
export class SsoLoginComponent implements OnInit {
  constructor(private samlService: SamlService) { }

  ngOnInit() {
    const url = this.samlService.performLoginSAML2SSO();
    if (url !== undefined) {
      window.location.href = url;
    }
  }
}
