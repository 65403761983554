var ConfidenceScoreComponent = /** @class */ (function () {
    function ConfidenceScoreComponent() {
    }
    Object.defineProperty(ConfidenceScoreComponent.prototype, "confidencePercentage", {
        get: function () {
            return this.confidence * 100;
        },
        enumerable: true,
        configurable: true
    });
    return ConfidenceScoreComponent;
}());
export { ConfidenceScoreComponent };
