import { EditCustomerModalComponent } from '../../edit-customer-modal/edit-customer-modal.component';
import { IconService } from 'app/services/icon.service';
import { Store } from '@ngrx/store';
import { FormBuilder, Validators, } from '@angular/forms';
import { OnChanges, OnInit, EventEmitter, SimpleChanges, OnDestroy, } from '@angular/core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadSubscriptions } from 'app/core/store/actions/subscriptions.actions';
import { NewTariffModalComponent } from '../../new-tariff-modal/new-tariff-modal.component';
import { ProductTypes } from 'app/core/store/models/product.model';
import { NewCustomerModalComponent } from '../../new-customer-modal/new-customer-modal.component';
import { NewSupplierModalComponent } from '../../new-supplier-modal/new-supplier-modal.component';
import { EditSupplierModalComponent } from '../../edit-supplier-modal/edit-supplier-modal.component';
import { Actions, ofType } from '@ngrx/effects';
import * as AttachmentTypes from 'app/core/store/types/attachment.types';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { DeleteTransportTask } from 'app/core/store/actions/transport-task.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';
var TransportFormComponent = /** @class */ (function () {
    function TransportFormComponent(fb, store, iconService, modalService, updates$, toastService) {
        this.fb = fb;
        this.store = store;
        this.iconService = iconService;
        this.modalService = modalService;
        this.updates$ = updates$;
        this.toastService = toastService;
        this.transportFormSubmitted = new EventEmitter();
        this.matchingStateChanged = new EventEmitter();
        this.submitAndContinueEnabled = new EventEmitter();
        this.containerValid = false;
        this.customerValid = true;
        this.supplierValid = true;
        this.isMatchingEnabled = false;
        this.isLoadingTariffs = false;
        this.ProductTypes = ProductTypes;
    }
    TransportFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new LoadSubscriptions());
        this.store
            .select(function (state) { return state.subscriptions; })
            .pipe(untilDestroyed(this))
            .subscribe(function (subscriptions) {
            if (subscriptions) {
                _this.hasActiveMarketplaceSubscription =
                    subscriptions.filter(function (subscription) {
                        return subscription.subscriptionType.code === 'MARKETPLACE';
                    }).length !== 0;
                _this.hasActiveMatchingSubscription =
                    subscriptions.filter(function (subscription) { return subscription.subscriptionType.code === 'MATCHING'; }).length !== 0;
                _this.isMatchingEnabled = _this.hasActiveMatchingSubscription;
            }
        });
        this.updates$
            .pipe(ofType(AttachmentTypes.attachment.DELETE_ATTACHMENT_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function (data) {
            var attachments = _this.transportForm.get('attachments').value;
            attachments = attachments.filter(function (attachment) { return attachment.id !== data['payload'].attachmentId; });
            _this.transportForm.patchValue({
                attachments: attachments
            });
            _this.toastService.showSuccess({
                message: extract('Attachment deleted successfully!')
            });
        });
    };
    TransportFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes &&
            changes.transportTypes &&
            changes.transportTypes.currentValue) {
            var transportTypes = changes.transportTypes.currentValue;
            if (!this.transportForm) {
                this.createForm();
                this.setTransportTasks(this.transportForm.get('transportType').value['transportTypeTasks']);
            }
            if (this.transport) {
                this.updateTransportForm(this.transport, transportTypes);
            }
        }
        if (changes && changes.transport && changes.transport.currentValue) {
            var transport = changes.transport.currentValue;
            if (!this.transportForm && this.transportTypes) {
                this.createForm();
            }
            if (this.transportTypes) {
                this.updateTransportForm(transport, this.transportTypes);
            }
        }
        this.cacheCustomerContact();
        this.cacheSupplierContact();
    };
    TransportFormComponent.prototype.ngOnDestroy = function () { };
    TransportFormComponent.prototype.updateTransportForm = function (transport, transportTypes) {
        var tTasks = transportTypes.find(function (type) { return type.id === transport.transportType.id; })['transportTypeTasks'];
        this.setTransportTasks(tTasks);
        this.patchTransportForm(transport);
    };
    TransportFormComponent.prototype.createForm = function () {
        this.transportForm = this.fb.group({
            transportType: [this.transportTypes[0], Validators.required],
            transportTasks: [[]],
            container: [
                this.fb.group({
                    containerType: [null, Validators.required],
                    liner: [null, Validators.required],
                    weight: null,
                    adr: false,
                    number: [null]
                })
            ],
            reference: null,
            goods: null,
            ship: null,
            attachments: null,
            billOfLading: null,
            customs: null,
            genset: [{ value: null, disabled: true }],
            privateComment: '',
            comment: '',
            // Customer related fields
            customer: null,
            customerReference: null,
            tariff: null,
            contact: null,
            selectedCustomerTariffTemplate: null,
            // Supplier related fields
            supplier: null,
            supplierReference: null,
            supplierTariff: null,
            supplierContact: null,
            selectedSupplierTariffTemplate: null
        });
    };
    Object.defineProperty(TransportFormComponent.prototype, "transportTasks", {
        get: function () {
            if (this.transportForm) {
                return this.transportForm.get('transportTasks');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportFormComponent.prototype, "selectedSupplierContacts", {
        get: function () {
            var _this = this;
            if (!this.suppliers) {
                return;
            }
            if (!this.transportForm.get('supplier').value) {
                return;
            }
            var supplier = this.suppliers.find(function (c) { return c.id === _this.transportForm.get('supplier').value; });
            if (!supplier) {
                return;
            }
            return supplier.contacts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportFormComponent.prototype, "selectedCustomerContacts", {
        get: function () {
            var _this = this;
            if (!this.customers) {
                return;
            }
            if (!this.transportForm.get('customer').value) {
                return;
            }
            var customer = this.customers.find(function (c) { return c.id === _this.transportForm.get('customer').value; });
            if (!customer) {
                return;
            }
            return customer.contacts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportFormComponent.prototype, "privateComment", {
        get: function () {
            if (this.transportForm) {
                return this.transportForm.get('privateComment').value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportFormComponent.prototype, "comment", {
        get: function () {
            if (this.transportForm) {
                return this.transportForm.get('comment').value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportFormComponent.prototype, "transportType", {
        get: function () {
            if (this.transportForm) {
                return this.transportForm.get('transportType').value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportFormComponent.prototype, "container", {
        get: function () {
            if (this.transportForm) {
                return this.transportForm.get('container').value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TransportFormComponent.prototype, "showPublicLocationField", {
        /**
         * Private location field should only be shown when a user has either:
         * - A marketplace subscription
         * - A matching subscription
         */
        get: function () {
            return (this.hasActiveMarketplaceSubscription ||
                this.hasActiveMatchingSubscription);
        },
        enumerable: true,
        configurable: true
    });
    TransportFormComponent.prototype.updateContact = function () {
        if (!this.transportForm.get('customer').value) {
            this.transportForm.get('contact').patchValue(null);
            return;
        }
        this.transportForm
            .get('contact')
            .patchValue(this.selectedCustomerContacts[0]['id']);
    };
    TransportFormComponent.prototype.updateSupplierContact = function () {
        if (!this.transportForm.get('supplier').value) {
            this.transportForm.get('supplierContact').patchValue(null);
            return;
        }
        this.transportForm
            .get('supplierContact')
            .patchValue(this.selectedSupplierContacts[0]['id']);
    };
    TransportFormComponent.prototype.onSubmit = function (submitAndContinue) {
        var body = this.transportForm.value;
        this.submitAttempt = true;
        // check that all tariff table lines except last one are valid
        // TODO move somewhere else so we can update this.customerValid & this.supplierValid when tariff tables inputs change
        var tariffPartsCount = (body.tariff && body.tariff.parts) ? body.tariff.parts.length : 0;
        var supplierTariffPartsCount = (body.supplierTariff && body.supplierTariff.parts) ? body.supplierTariff.parts.length : 0;
        var tariffTableValid = true;
        var supplierTableValid = true;
        for (var i = 0; i < tariffPartsCount - 1; i++) {
            if (!body.tariff.parts[i].description || !body.tariff.parts[i].unitPrice || !body.tariff.parts[i].quantity) {
                tariffTableValid = false;
            }
        }
        for (var i = 0; i < supplierTariffPartsCount - 1; i++) {
            if (!body.supplierTariff.parts[i].description || !body.supplierTariff.parts[i].unitPrice || !body.supplierTariff.parts[i].quantity) {
                supplierTableValid = false;
            }
        }
        if (!tariffTableValid) {
            this.customerValid = false;
            this.tariffTable.onSubmit();
        }
        else {
            this.customerValid = true;
        }
        if (!supplierTableValid) {
            this.supplierValid = false;
            this.supplierTariffTable.onSubmit();
        }
        else {
            this.supplierValid = true;
        }
        if (!this.containerValid || !this.hasAtLeastOneLocation || !tariffTableValid || !supplierTableValid) {
            return;
        }
        if (body.container && body.container.value) {
            body.container = body.container.value;
        }
        // remove last tariff table lines if invalid
        if (body.tariff && (!body.tariff.parts[tariffPartsCount - 1].description ||
            !body.tariff.parts[tariffPartsCount - 1].unitPrice ||
            !body.tariff.parts[tariffPartsCount - 1].quantity)) {
            body.tariff.parts.pop();
        }
        if (body.supplierTariff && (!body.supplierTariff.parts[supplierTariffPartsCount - 1].description ||
            !body.supplierTariff.parts[supplierTariffPartsCount - 1].unitPrice ||
            !body.supplierTariff.parts[supplierTariffPartsCount - 1].quantity)) {
            body.supplierTariff.parts.pop();
        }
        if (!this.transport && body.tariff && body.tariff.id) {
            delete body.tariff.id;
        }
        if (!this.transport && body.supplierTariff && body.supplierTariff.id) {
            delete body.supplierTariff.id;
        }
        if (body.supplier && this.suppliers) {
            var supplier = this.suppliers.find(function (c) { return c['@id'] === body.supplier; });
            if (!supplier) {
                supplier = this.suppliers.find(function (c) { return c.id === body.supplier; });
            }
            // Hack to get the @id from the supplier, this can be removed if the accept headers in the POST request include ld+json
            body.supplier = supplier['@id'] || supplier.defaultContact['supplier'];
            var supplierContact = supplier.contacts.find(function (contact) { return contact['@id'] === body.supplierContact; });
            if (!supplierContact) {
                supplierContact = supplier.contacts.find(function (contact) { return contact['id'] === body.supplierContact; });
            }
            if (supplierContact && supplierContact['@id']) {
                body.supplierContact = supplierContact['@id'];
            }
            else {
                // Hack continues here
                var splitSupplier = body.supplier.split('/'); // Split url
                splitSupplier[splitSupplier.length - 1] = body.supplierContact; // Set id to contact id
                splitSupplier[splitSupplier.length - 2] = 'contacts'; // Set resource to contacts
                body.supplierContact = splitSupplier.join('/');
            }
        }
        if (body.customer && this.customers) {
            var customer = this.customers.find(function (c) { return c['@id'] === body.customer; });
            if (!customer) {
                customer = this.customers.find(function (c) { return c.id === body.customer; });
            }
            // Hack to get the @id from the customer, this can be removed if the accept headers in the POST request include ld+json
            body.customer = customer['@id'] || customer.defaultContact['customer'];
            var customerContact = customer.contacts.find(function (contact) { return contact['@id'] === body.contact; });
            if (!customerContact) {
                customerContact = customer.contacts.find(function (contact) { return contact['id'] === body.contact; });
            }
            if (customerContact['@id']) {
                body.contact = customerContact['@id'];
            }
            else {
                // Hack continues here
                var splitCustomer = body.customer.split('/'); // Split url
                splitCustomer[splitCustomer.length - 1] = body.contact; // Set id to contact id
                splitCustomer[splitCustomer.length - 2] = 'contacts'; // Set resource to contacts
                body.contact = splitCustomer.join('/');
            }
        }
        this.transportFormSubmitted.emit(body);
        this.matchingStateChanged.emit(this.isMatchingEnabled);
        if (!submitAndContinue) {
            return;
        }
        this.submitAndContinueEnabled.emit(submitAndContinue);
    };
    TransportFormComponent.prototype.setTransportTasks = function (transportTasks) {
        var _this = this;
        var tasks = this.transportForm.get('transportTasks').value;
        tasks.forEach(function (task) {
            var transportTask = transportTasks.find(function (t) { return t.taskType.code === task.taskType.code; });
            if (transportTask) {
                Object.assign(transportTask, task);
            }
            else if (task['id'] !== undefined) {
                // delete unrelated transport tasks
                _this.store.dispatch(new DeleteTransportTask({
                    transportTaskId: task['id']
                }));
            }
        });
        this.transportForm.get('transportTasks').setValue(transportTasks);
    };
    TransportFormComponent.prototype.transportTasksChange = function (event) {
        this.setTransportTasks(event.tasks);
        this.hasAtLeastOneLocation = event.valid;
    };
    TransportFormComponent.prototype.patchTransportForm = function (offer) {
        var _this = this;
        this.isMatchingEnabled = offer.partOfMatchingPoolItem;
        // patch form
        this.transportForm.patchValue({
            reference: offer.reference,
            privateComment: offer.privateComment,
            attachments: offer.attachments,
            comment: offer.comment,
            genset: offer.genset,
            quantity: offer.quantity ? offer.quantity : 1,
            goods: offer.goods,
            ship: offer.ship,
            billOfLading: offer.billOfLading,
            tariff: offer.tariff,
            supplierTariff: offer.supplierTariff,
            selectedCustomerTariffTemplate: offer.tariff,
            selectedSupplierTariffTemplate: offer.supplierTariff,
            customs: offer.customs,
            customer: offer.customer ? offer.customer.id : null,
            supplier: offer.supplier ? offer.supplier.id : null,
            contact: offer.contact ? offer.contact.id : null,
            supplierContact: offer.supplierContact ? offer.supplierContact.id : null,
            customerReference: offer.customerReference,
            supplierReference: offer.supplierReference
        });
        if (offer.container) {
            this.transportForm.patchValue({
                container: {
                    weight: offer.container.weight ? offer.container.weight : null,
                    adr: offer.container.adr,
                    containerType: offer.container.containerType
                        ? offer.container.containerType.id
                        : null,
                    liner: offer.container.liner ? offer.container.liner.id : null,
                    number: offer.container.number ? offer.container.number : null
                }
            });
        }
        if (typeof offer.transportType === 'string') {
            this.transportForm
                .get('transportType')
                .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === offer.transportType; }));
        }
        else {
            this.transportForm
                .get('transportType')
                .setValue(this.transportTypes.find(function (tt) { return tt['@id'] === offer.transportType['@id']; }));
        }
        offer.transportTasks = offer.transportTasks.map(function (task) {
            return {
                id: task.id ? task.id : null,
                taskType: task.taskType ? task.taskType : null,
                location: task.location,
                privateLocation: task.privateLocation
                    ? typeof task.privateLocation === 'string'
                        ? task.privateLocation
                        : task.privateLocation.id
                    : null,
                startDateTimeSpecified: task.startDateTimeSpecified,
                endDateTimeSpecified: task.endDateTimeSpecified,
                reference: task.reference,
                startDate: task.startDate
                    ? new Date(moment(task.startDate).year(), moment(task.startDate).month(), moment(task.startDate).date())
                    : null,
                startTime: task.startTime
                    ? task.startTime
                    : task.startDate && task.startDateTimeSpecified
                        ? {
                            hour: moment(task.startDate).hours(),
                            minute: moment(task.startDate).minutes(),
                            second: 0
                        }
                        : null,
                endDate: task.endDate
                    ? new Date(moment(task.endDate).year(), moment(task.endDate).month(), moment(task.endDate).date())
                    : null,
                endTime: task.endTime
                    ? task.endTime
                    : task.endDate && task.endDateTimeSpecified
                        ? {
                            hour: moment(task.endDate).hours(),
                            minute: moment(task.endDate).minutes(),
                            second: 0
                        }
                        : null
            };
        });
        offer.transportTasks.forEach(function (task) {
            var index = _this.transportForm.get('transportTasks')['value'].indexOf(_this.transportForm.get('transportTasks')['value'].find(function (control) {
                return (control.taskType['@id'] ===
                    (task.taskType['@id'] ? task.taskType['@id'] : task.taskType));
            }));
            if (index !== -1) {
                var tasks = _this.transportTasks.value.slice();
                tasks[index] = task;
                _this.transportForm.get('transportTasks').setValue(tasks.slice());
            }
        });
        this.hasAtLeastOneLocation = offer.transportTasks.some(function (task) { return task.location; });
    };
    TransportFormComponent.prototype.handleContainerFormStatusChanged = function (containerFormStatus) {
        this.containerValid = containerFormStatus === 'VALID';
    };
    TransportFormComponent.prototype.handleContainerFormValueChanged = function (containerFormValue) {
        if (!containerFormValue) {
            return;
        }
        this.transportForm.get('container').patchValue(containerFormValue);
    };
    TransportFormComponent.prototype.handleIsReefer = function (isReefer) {
        if (isReefer) {
            this.transportForm.get('genset').enable();
        }
        else {
            this.transportForm.get('genset').patchValue(false);
            this.transportForm.get('genset').disable();
        }
    };
    TransportFormComponent.prototype.getTypeIcon = function (type) {
        return this.iconService.getTransportTypeIcon(type);
    };
    TransportFormComponent.prototype.openEditCustomerModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(EditCustomerModalComponent);
        modalRef.componentInstance.customer = this.customers.find(function (c) { return c.id === _this.transportForm.get('customer').value; });
        modalRef.result.then(function (result) {
            _this.customers = _this.customers.filter(function (c) { return c.id !== result.id; });
            _this.customers = [result].concat(_this.customers);
            _this.transportForm.get('customer').setValue(result.id);
            _this.transportForm.get('contact').setValue(result.defaultContact.id);
        });
    };
    TransportFormComponent.prototype.openEditSupplierModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(EditSupplierModalComponent);
        modalRef.componentInstance.supplier = this.suppliers.find(function (s) { return s.id === _this.transportForm.get('supplier').value; });
        modalRef.result.then(function (result) {
            _this.suppliers = _this.suppliers.filter(function (s) { return s.id !== result.id; });
            _this.customers = [result].concat(_this.customers);
            _this.transportForm.get('supplier').setValue(result.id);
            _this.transportForm
                .get('supplierContact')
                .setValue(result.defaultContact.id);
        });
    };
    TransportFormComponent.prototype.openNewSupplierModal = function (searchTerm) {
        var _this = this;
        var modalRef = this.modalService.open(NewSupplierModalComponent);
        if (searchTerm) {
            modalRef.componentInstance.query = searchTerm;
        }
        modalRef.result.then(function (result) {
            if (!_this.suppliers) {
                _this.suppliers = [];
            }
            _this.suppliers = [result].concat(_this.suppliers);
            _this.transportForm.get('supplier').setValue(result.id);
            _this.transportForm
                .get('supplierContact')
                .setValue(result.defaultContact.id);
        });
    };
    TransportFormComponent.prototype.openNewCustomerModal = function (searchTerm) {
        var _this = this;
        var modalRef = this.modalService.open(NewCustomerModalComponent);
        if (searchTerm) {
            modalRef.componentInstance.query = searchTerm;
        }
        modalRef.result.then(function (result) {
            if (!_this.customers) {
                _this.customers = [];
            }
            _this.customers = [result].concat(_this.customers);
            _this.transportForm.get('customer').setValue(result.id);
            _this.transportForm.get('contact').setValue(result.defaultContact.id);
        });
    };
    TransportFormComponent.prototype.openNewTariffModal = function (productType, searchTerm) {
        var _this = this;
        var modalRef = this.modalService.open(NewTariffModalComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.showTariffTypeButtons = false;
        modalRef.componentInstance.tariffType = productType;
        if (searchTerm) {
            modalRef.componentInstance.searchTerm = searchTerm;
        }
        modalRef.result.then(function (result) {
            if (productType === ProductTypes.CUSTOMER) {
                if (!_this.customerTariffs) {
                    _this.customerTariffs = [];
                }
                _this.customerTariffs = [result].concat(_this.customerTariffs);
                _this.handleCustomerTariffSelect(result);
                _this.transportForm
                    .get('selectedCustomerTariffTemplate')
                    .setValue(result);
            }
            else if (productType === ProductTypes.SUPPLIER) {
                if (!_this.supplierTariffs) {
                    _this.supplierTariffs = [];
                }
                _this.supplierTariffs = [result].concat(_this.supplierTariffs);
                _this.handleSupplierTariffSelect(result);
                _this.transportForm
                    .get('selectedSupplierTariffTemplate')
                    .setValue(result);
            }
        });
    };
    TransportFormComponent.prototype.handleCustomerTariffSelect = function (tariffValue) {
        this.selectedTariff = tariffValue;
        if (this.transport && this.transport.invoiced) {
            this.toastService.showWarning({
                message: extract('You can\'t change the tariff of a transport that has already been invoiced.')
            });
            return;
        }
        if (!tariffValue) {
            this.transportForm.get('tariff').setValue(null);
            return;
        }
        this.handleCustomerTariffChange(tariffValue);
    };
    TransportFormComponent.prototype.handleCustomerTariffChange = function (tariffValue) {
        this.transportForm.get('tariff').patchValue({
            label: tariffValue.label,
            price: tariffValue.price,
            parts: tariffValue.parts,
            type: ProductTypes.CUSTOMER,
            template: false
        });
    };
    TransportFormComponent.prototype.handleSupplierTariffSelect = function (tariffValue) {
        this.selectedSupplierTariff = tariffValue;
        if (this.transport && this.transport.purchased) {
            this.toastService.showWarning({
                message: extract('You can\'t change the tariff of a transport that has already been invoiced.')
            });
            return;
        }
        if (!tariffValue) {
            this.transportForm.get('supplierTariff').setValue(null);
            return;
        }
        this.handleSupplierTariffChange(tariffValue);
    };
    TransportFormComponent.prototype.handleSupplierTariffChange = function (tariffValue) {
        this.transportForm.get('supplierTariff').patchValue({
            label: tariffValue.label,
            price: tariffValue.price,
            parts: tariffValue.parts,
            type: ProductTypes.SUPPLIER,
            template: false
        });
    };
    Object.defineProperty(TransportFormComponent.prototype, "transportTasksAreValid", {
        get: function () {
            return this.transportForm.value['transportTasks'].some(function (task) { return task.privateLocation; });
        },
        enumerable: true,
        configurable: true
    });
    TransportFormComponent.prototype.handleFileUploadSuccess = function (attachment) {
        var attachments = this.transportForm.get('attachments').value;
        if (!attachments) {
            attachments = [];
        }
        attachments.push(attachment);
        this.transportForm.patchValue({
            attachments: attachments
        });
    };
    TransportFormComponent.prototype.cacheCustomerContact = function () {
        var _this = this;
        if (this.selectedCustomerContacts && this.transportForm) {
            this.customerContact = this.selectedCustomerContacts.find(function (contact) { return contact['id'] === _this.transportForm.get('contact').value; });
        }
    };
    TransportFormComponent.prototype.cacheSupplierContact = function () {
        var _this = this;
        if (this.selectedSupplierContacts && this.transportForm) {
            this.supplierContact = this.selectedSupplierContacts.find(function (contact) { return contact['id'] === _this.transportForm.get('supplierContact').value; });
        }
    };
    return TransportFormComponent;
}());
export { TransportFormComponent };
