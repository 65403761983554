import { Action } from '@ngrx/store';
import * as types from '../types/user-context.types';

export class LoadUserContexts implements Action {
  readonly type = types.userContexts.LOAD_USER_CONTEXTS;
  constructor(public payload?: any) {}
}

export class LoadUserContext implements Action {
  readonly type = types.userContexts.LOAD_USER_CONTEXT;
  constructor(public payload?: any) {}
}

export class LoadUserContextsSuccess implements Action {
  readonly type = types.userContexts.LOAD_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadSingleUserContextSuccess implements Action {
  readonly type = types.userContexts.LOAD_SINGLE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadUserContextsFail implements Action {
  readonly type = types.userContexts.LOAD_FAILED;
  constructor(public payload: any) {}
}

export class CreateUserContext implements Action {
  readonly type = types.userContexts.CREATE_USER_CONTEXT;
  constructor(public payload: any) {}
}

export class CreateUserContextSuccess implements Action {
  readonly type = types.userContexts.CREATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class CreateUserContextFail implements Action {
  readonly type = types.userContexts.CREATE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateUserContext implements Action {
  readonly type = types.userContexts.UPDATE_USER_CONTEXT;
  constructor(public payload: any) {}
}

export class UpdateUserContextSuccess implements Action {
  readonly type = types.userContexts.UPDATE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateUserContextFail implements Action {
  readonly type = types.userContexts.UPDATE_FAILED;
  constructor(public payload: any) {}
}

export class EnableUserContext implements Action {
  readonly type = types.userContexts.ENABLE_USER_CONTEXT;
  constructor(public payload: any) {}
}

export class EnableUserContextSuccess implements Action {
  readonly type = types.userContexts.ENABLE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class EnableUserContextFail implements Action {
  readonly type = types.userContexts.ENABLE_FAILED;
  constructor(public payload: any) {}
}

export class DisableUserContext implements Action {
  readonly type = types.userContexts.DISABLE_USER_CONTEXT;
  constructor(public payload: any) {}
}

export class DisableUserContextSuccess implements Action {
  readonly type = types.userContexts.DISABLE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DisableUserContextFail implements Action {
  readonly type = types.userContexts.DISABLE_FAILED;
  constructor(public payload: any) {}
}

export class DeleteUserContext implements Action {
  readonly type = types.userContexts.DELETE_USER_CONTEXT;
  constructor(public payload: any) {}
}

export class DeleteUserContextSuccess implements Action {
  readonly type = types.userContexts.DELETE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class DeleteUserContextFail implements Action {
  readonly type = types.userContexts.DELETE_FAILED;
  constructor(public payload: any) {}
}

export class LoadDrivers implements Action {
  readonly type = types.userContexts.LOAD_DRIVERS;
  constructor(public payload: any) {}
}

export class LoadDriversSuccess implements Action {
  readonly type = types.userContexts.LOAD_DRIVERS_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadDriversFail implements Action {
  readonly type = types.userContexts.LOAD_DRIVERS_FAILED;
  constructor(public payload: any) {}
}

export class ClearUserContexts implements Action {
  readonly type = types.userContexts.CLEAR;
  constructor(public payload?: any) {}
}

export type All =
  | LoadUserContexts
  | LoadUserContexts
  | LoadUserContextsSuccess
  | LoadSingleUserContextSuccess
  | LoadUserContextsFail
  | CreateUserContext
  | CreateUserContextSuccess
  | CreateUserContextFail
  | UpdateUserContext
  | UpdateUserContextSuccess
  | UpdateUserContextFail
  | EnableUserContext
  | EnableUserContextSuccess
  | EnableUserContextFail
  | DisableUserContext
  | DisableUserContextSuccess
  | DisableUserContextFail
  | DeleteUserContext
  | DeleteUserContextSuccess
  | DeleteUserContextFail
  | LoadDrivers
  | LoadDriversSuccess
  | LoadDriversFail
  | ClearUserContexts;
