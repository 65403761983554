<form
  *ngIf="taxRateForm"
  (ngSubmit)="onSubmit()"
  [formGroup]="taxRateForm"
  class="p-3"
>
  <div class="form-group">
    <label class="center-block">{{ 'Label' | translate }}</label>
    <input
      class="form-control"
      data-cy="label"
      formControlName="label"
      [ngClass]="{
        'is-invalid':
          taxRateForm.controls['label'].invalid &&
          taxRateForm.controls['label'].touched
      }"
    />
  </div>
  <div class="form-group">
    <label class="center-block">{{ 'Rate' | translate }}</label>
    <input
      class="form-control"
      formControlName="rate"
      data-cy="rate"
      type="number"
      [ngClass]="{
        'is-invalid':
          taxRateForm.controls['rate'].invalid &&
          taxRateForm.controls['rate'].touched
      }"
    />
  </div>
  <app-button data-cy="submitButton" [isLoading]="isLoading" [type]="'submit'">
    <span> {{ 'Submit' | translate }} </span>
  </app-button>
</form>
