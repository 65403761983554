var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as types from '../types/schedule.types';
import * as ScheduleActions from '../actions/schedule.actions';
import { ApiService } from '../../api/api.service';
import { map, concatMap, catchError } from 'rxjs/operators';
var ScheduleEffects = /** @class */ (function () {
    function ScheduleEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadSchedule$ = this.actions$.pipe(ofType(types.schedule.LOAD_SCHEDULE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/tms/schedule?from=" + payload.fromDate + "&to=" + payload.toDate
        }).pipe(map(function (data) { return new ScheduleActions.LoadScheduleSuccess(data); }), catchError(function (error) { return of(new ScheduleActions.LoadScheduleFail(error)); })); }));
        this.loadUnplannedTransports$ = this.actions$.pipe(ofType(types.schedule.LOAD_UNPLANNED_TRANSPORTS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/tms/schedule/unplanned-transports?page=" + (payload.page ||
                1) + "&query=" + (payload.query || '') + "&from=" + payload.fromDate + "&to=" + payload.toDate
        }).pipe(map(function (data) { return new ScheduleActions.LoadUnplannedTransportsSuccess(data); }), catchError(function (error) { return of(new ScheduleActions.LoadUnplannedTransportsFail(error)); })); }));
        this.loadNextUnplannedTransportsPage$ = this.actions$.pipe(ofType(types.schedule.LOAD_NEXT_UNPLANNED_TRANSPORTS_PAGE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: "/tms/schedule/unplanned-transports?page=" + (payload.page ||
                1) + "&query=" + (payload.query || '') + "&from=" + payload.fromDate + "&to=" + payload.toDate
        }).pipe(map(function (data) { return new ScheduleActions.LoadNextUnplannedTransportsPageSuccess(data); }), catchError(function (error) { return of(new ScheduleActions.LoadNextUnplannedTransportsPageFail(error)); })); }));
        this.addToSchedule$ = this.actions$.pipe(ofType(types.schedule.ADD_TO_SCHEDULE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/tms/schedule/add",
            body: payload
        }).pipe(map(function (data) { return new ScheduleActions.AddToScheduleSuccess(data); }), catchError(function (error) { return of(new ScheduleActions.AddToScheduleFail(error)); })); }));
        this.removeFromSchedule$ = this.actions$.pipe(ofType(types.schedule.REMOVE_FROM_SCHEDULE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/tms/schedule/remove",
            body: payload
        }).pipe(map(function (data) { return new ScheduleActions.RemoveFromScheduleSuccess(data); }), catchError(function (error) { return of(new ScheduleActions.RemoveFromScheduleFail(error)); })); }));
        this.updateScheduleSequence$ = this.actions$.pipe(ofType(types.schedule.UPDATE_SCHEDULE_SEQUENCE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/tms/schedule/sequence/update",
            body: payload
        }).pipe(map(function (data) { return new ScheduleActions.UpdateScheduleSequenceSuccess(data); }), catchError(function (error) { return of(new ScheduleActions.UpdateScheduleSequenceFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ScheduleEffects.prototype, "loadSchedule$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ScheduleEffects.prototype, "loadUnplannedTransports$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ScheduleEffects.prototype, "loadNextUnplannedTransportsPage$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ScheduleEffects.prototype, "addToSchedule$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ScheduleEffects.prototype, "removeFromSchedule$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], ScheduleEffects.prototype, "updateScheduleSequence$", void 0);
    return ScheduleEffects;
}());
export { ScheduleEffects };
