import { NotificationSubscription } from 'app/core/store/models/notification-subscriptions.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-board-messages-subscription',
  templateUrl: './message-board-messages-subscription.component.html',
})
export class MessageBoardMessagesSubscriptionComponent {
  @Input() messageBoardMessageSubscription: NotificationSubscription;
}
