import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  createPreannouncement, createPreannouncementSuccess, loadPreannouncements
} from 'app/core/store/actions/preannouncement.actions';
import { State } from 'app/core/store/store.model';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-preannouncement',
  templateUrl: './new-preannouncement.component.html',
})
export class NewPreannouncementComponent implements OnDestroy {
  isLoading: boolean;

  constructor(
    private updates$: Actions,
    private toastr: ToastService,
    private store: Store<State>,
    private router: Router
  ) {
    this.updates$
      .pipe(ofType(createPreannouncementSuccess))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Preannouncement created!')
        });
        this.store.dispatch(loadPreannouncements());
        this.isLoading = true;
        this.router.navigate(['certiweight-backoffice/preannouncements']);
      });
  }

  submit(formValue) {
    this.store.dispatch(createPreannouncement(formValue));
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
