import { StartLoadUpdates, StopLoadUpdates } from 'app/core/store/actions/update.actions';
import { ToastService } from 'app/services/toast.service';
import { NotificationSubscription } from 'app/core/store/models/notification-subscriptions.model';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { State } from 'app/core/store/store.model';
import { Message } from 'app/core/store/models/messages.model';
import { LoadMessages, CreateMessage, CreateReply } from 'app/core/store/actions/messages.actions';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/marketplace.types';
import * as NotificationSubscriptionTypes from 'app/core/store/types/notification-subscription.types';
import { untilDestroyed } from 'app/shared/rxjs-util';

// rxjs
import { Observable } from 'rxjs';
import { LoadNotificationSubscriptions } from 'app/core/store/actions/notification-subscriptions.actions';

@Component({
  selector: 'app-cmp-message-board',
  templateUrl: 'message-board.component.html',
  styleUrls: ['message-board.component.scss']
})
export class MessageBoardComponent implements OnInit, OnDestroy {
  messageBoardMessageSubscription: NotificationSubscription;
  replying: boolean;
  messageLength = 0;
  messageForm: FormGroup;
  messages$: Observable<Message[]>;
  loading = false;
  url;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private createUpdates$: Actions,
    private route: ActivatedRoute,
    private toastr: ToastService
  ) {
    this.store.dispatch(new LoadNotificationSubscriptions());
    this.store
      .select(state => state.notificationSubscriptions)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptions => {
        if (subscriptions) {
          const mbs = subscriptions.filter(s => s.type === 'message_board');
          this.messageBoardMessageSubscription =
            mbs.length !== 0 ? mbs[0] : null;
        }
      });
    this.route.url.subscribe(data => {
      if (!data || !data[data.length - 1]) {
        this.url = 'marketplace';
        return;
      }
      this.url = data[data.length - 1].path;
      if (this.url !== 'marketplace') {
        this.store.dispatch(new StartLoadUpdates());
      }
    });

    this.messages$ = this.store
      .select(state => state.marketplace.messages)
      .pipe(untilDestroyed(this));
    createUpdates$
      .pipe(ofType(Types.messages.LOAD_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.loading = false;
        if (
          data['payload']['name'] === 'HttpErrorResponse' &&
          data['payload']['statusText'] === 'Unknown Error'
        ) {
          console.log('Unknown error');
        } else {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message:
              'Something went wrong while loading the messages. Please try again at a later time or contact us at support@hakka.eu'
          });
        }
      });
    createUpdates$
      .pipe(ofType(Types.messages.CREATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
      });
    createUpdates$
      .pipe(ofType(Types.messages.CREATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message:
            'Something went wrong while loading the messages. Please try again at a later time or contact us at support@hakka.eu'
        });
      });
    createUpdates$
      .pipe(ofType(Types.messages.CREATE_REPLY_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message:
            'Something went wrong while creating your reply. Please try again at a later time or contact us at support@hakka.eu'
        });
      });
    createUpdates$
      .pipe(ofType(
        NotificationSubscriptionTypes.notificationSubscriptions
          .DISABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED,
        NotificationSubscriptionTypes.notificationSubscriptions
          .ENABLE_MESSAGE_BOARD_NOTIFICATION_SUBSCRIPTION_SUCCEEDED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new LoadNotificationSubscriptions());
      });
  }

  ngOnInit() {
    this.createForm();
    this.store.dispatch(new LoadMessages());
  }

  createForm() {
    this.messageForm = this.fb.group({
      message: ['', Validators.required]
    });
  }

  onSubmit($event) {
    this.store.dispatch(
      new CreateMessage({
        message: $event
      })
    );
    this.loading = true;
  }

  submitReply(messageReply) {
    this.store.dispatch(
      new CreateReply({
        id: messageReply.messageId,
        body: {
          message: messageReply.reply
        }
      })
    );
  }

  calculateLength($event) {
    this.messageLength = $event.target.value.length;
  }
  setReplying(replying: boolean) {
    this.replying = replying;
  }

  ngOnDestroy() {
    this.store.dispatch(new StopLoadUpdates());
  }
}
