import { LocationTypes } from 'app/core/store/models/location-type.model';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Location } from 'app/core/store/models/location.model';
import { Component, OnInit, Output, OnDestroy, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LoadLocationTypes } from 'app/core/store/actions/location.actions';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { LocationService } from 'app/services/location.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-user-location-form',
  templateUrl: './user-location-form.component.html',
  styleUrls: ['./user-location-form.component.scss']
})
export class UserLocationFormComponent implements OnInit, OnChanges, OnDestroy {
  @Output()
  userLocationFormSubmitted = new EventEmitter();
  @Output()
  addressChanged = new EventEmitter();
  @Input()
  isLoading: boolean;
  @Input()
  userLocation;
  @Input()
  coordinates;

  privateLocationName: string;
  privateLocationAddress: any;
  privateLocationLatitude: any;
  privateLocationLongitude: any;
  publicLocation: Location;
  locationTypeId: string;
  isLoadingPublicLocation = false;
  submitAttempt: boolean;

  constructor(
    public locationService: LocationService,
    private store: Store<State>,
    private toastr: ToastService
  ) { }

  ngOnInit() {
    this.store.dispatch(new LoadLocationTypes());
    this.store
      .select(state => state.tms.locations)
      .pipe(untilDestroyed(this))
      .subscribe(locationState => {
        if (locationState && locationState.locationTypes) {
          const temp = locationState.locationTypes.find(
            lt => lt.code === LocationTypes.USER
          );
          if (temp) {
            this.locationTypeId = temp['@id'];
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.userLocation && changes.userLocation.currentValue) {
      const location = Object.assign({}, changes.userLocation.currentValue);
      this.privateLocationName = location.name;
      this.privateLocationAddress = location;
      this.privateLocationLatitude = location.latitude;
      this.privateLocationLongitude = location.longitude;
      this.publicLocation = location.publicLocation;
    }
    if (changes && changes.coordinates && changes.coordinates.currentValue) {
      const coordinates = Object.assign({}, changes.coordinates.currentValue);
      const lat = coordinates[1].toString();
      const lon = coordinates[0].toString();

      // If address doesn't have a value: set value by reverse geo
      if (!this.privateLocationAddress) {
        this.setAddressByReverseGeoCoding(lat, lon);
        return;
      }
      if (typeof this.privateLocationAddress === 'string') {
        this.privateLocationAddress = {
          fullAddress: this.privateLocationAddress
        };
      }
      this.updateLatitudeAndLongitude(lat, lon);
    }
  }

  ngOnDestroy() { }

  setAddressByReverseGeoCoding(latitude: string, longitude: string) {
    this.locationService.reverseGeocode(latitude, longitude).subscribe(data => {
      const address = data['features'][0].properties;
      this.privateLocationAddress = address;
      this.updateLatitudeAndLongitude(latitude, longitude);
    });
  }
  updateLatitudeAndLongitude(lat, lon) {
    this.privateLocationAddress = Object.assign(
      {},
      this.privateLocationAddress,
      {
        latitude: lat,
        longitude: lon
      }
    );
    this.privateLocationAddress.latitude = lat;
    this.privateLocationAddress.longitude = lon;
    this.privateLocationLatitude = lat;
    this.privateLocationLongitude = lon;
  }

  onSubmit() {
    if (!this.locationTypeId) {
      const warningTitle = extract('Something went wrong');
      const warningMessage = extract('Please try again later');
      this.toastr.showWarning({
        title: warningTitle as string,
        message: warningMessage as string
      });
      return;
    }
    this.submitAttempt = true;
    if (
      !this.userLocation &&
      (!this.privateLocationName ||
        !this.privateLocationAddress ||
        !this.privateLocationLatitude ||
        !this.privateLocationLongitude ||
        !this.publicLocation ||
        !this.publicLocation.latitude)
    ) {
      return;
    }
    const body = {
      name: this.privateLocationName,
      fullAddress: this.locationService.addressFormatter(
        this.privateLocationAddress
      ),
      latitude:
        this.privateLocationAddress['latitude'] || this.privateLocationLatitude,
      longitude:
        this.privateLocationAddress['longitude'] ||
        this.privateLocationLongitude,
      publicLocation: this.publicLocation,
      locationType: this.locationTypeId
    };
    Object.keys(body.publicLocation).forEach(key => {
      if (body.publicLocation[key] === null) {
        delete body.publicLocation[key];
      }
    });
    this.userLocationFormSubmitted.emit(body);
  }
  handleAddressChange() {
    if (this.privateLocationAddress.latitude) {
      this.addressChanged.emit(this.privateLocationAddress);
    }
  }

  /**
   * Patch the public location with a suggestion based on the private location
   */
  updatePublicLocation() {
    if (
      !this.privateLocationAddress ||
      !this.privateLocationAddress.latitude ||
      !this.privateLocationAddress.longitude
    ) {
      return;
    }
    this.isLoadingPublicLocation = true;
    this.searchLocation(
      of(
        `${this.privateLocationAddress['city']} (${
        this.privateLocationAddress['postcode']
        })`
      )
    ).subscribe(results => {
      this.publicLocation = results[0];
      this.isLoadingPublicLocation = false;
    });
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.location(term);
      })
    )
  searchAddress = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.address(term);
      })
    )

  updatePrivateLocationGeoPoints() {
    if (!this.privateLocationAddress) {
      return;
    }
    this.privateLocationLatitude = this.privateLocationAddress.latitude
      ? this.privateLocationAddress.latitude
      : this.privateLocationLatitude;
    this.privateLocationLongitude = this.privateLocationAddress.longitude
      ? this.privateLocationAddress.longitude
      : this.privateLocationLongitude;
  }
}
