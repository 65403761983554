import { Action } from '@ngrx/store';
import * as types from '../types/business-contact.types';
import { BusinessContact } from '../models/business-contact.model';

export class DeleteBusinessContact implements Action {
  readonly type = types.BusinessContact.DELETE_BUSINESS_CONTACT;
  constructor(
    public payload: {
      businessId: string;
      businessContactId: string;
    }
  ) {}
}

export class DeleteBusinessContactFail implements Action {
  readonly type = types.BusinessContact.DELETE_BUSINESS_CONTACT_FAILED;
  constructor(public payload: any) {}
}

export class DeleteBusinessContactSuccess implements Action {
  readonly type = types.BusinessContact.DELETE_BUSINESS_CONTACT_SUCCEEDED;
  constructor(public payload: any) {}
}
export class UpdateContact implements Action {
  readonly type = types.BusinessContact.UPDATE_BUSINESS_CONTACT;
  constructor(
    public payload?: {
      contactId: string;
      body: BusinessContact;
    }
  ) {}
}

export class UpdateContactFail implements Action {
  readonly type = types.BusinessContact.UPDATE_BUSINESS_CONTACT_FAILED;
  constructor(public payload: any) {}
}

export class UpdateContactSuccess implements Action {
  readonly type = types.BusinessContact.UPDATE_BUSINESS_CONTACT_SUCCEEDED;
  constructor(public payload: any) {}
}

export type All =
  | UpdateContact
  | UpdateContactFail
  | UpdateContactSuccess
  | DeleteBusinessContact
  | DeleteBusinessContactFail
  | DeleteBusinessContactSuccess;
