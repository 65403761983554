import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../user/user.auth';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  opened = false;
  title = 'Hakka';
  showNav = true;

  /**
   * Creates an instance of AppComponent.
   * @param {AuthService} authService Authorization service
   * @param {TranslateService} translate Translation service
   *
   * @memberOf AppComponent
   */
  constructor(private authService: AuthService) { }

  /**
   * Function to check if the sidebar should be shown or not
   *
   * @returns {boolean}
   *
   * @memberOf AppComponent
   */
  showNavigation(): Observable<boolean> {
    return this.authService.hasActiveTenant();
  }

  /**
   * Function to check if the header should be shown or not
   *
   * @returns {boolean}
   *
   * @memberOf AppComponent
   */
  showHeader(): Observable<boolean> {
    return this.showNavigation();
  }

  /**
   * Function to show/hide the navigation. Used on mobile
   *
   * @memberOf AppComponent
   */
  toggleOffCanvas() {
    this.showNav = !this.showNav;
  }

  toggleSidebar() {
    this.opened = !this.opened;
  }
}
