import { MarketpostParserService } from '../services/marketpost-parser.service';
import { EditMarketPostComponent } from './market-posts/edit-market-post/edit-market-post.component';
import { MessageInputComponent } from './message-board/message-input.component/message-input.component';
import { LinerEffects } from '../core/store/effects/liner.effects';
import { MessageComponent } from './message-board/message/message.component';
import { MarketPostDemandDetailComponent } from './market-posts/market-post-demand-detail/market-post-demand-detail.component';
import { MapComponent } from './market-posts/market-post-map/map/map.component';
import { NewTransportListComponent } from './new-marketpost/new-transport-list/new-transport-list.component';
// Authentication, Routing
import { CoreModule } from '../core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { FilterComponent } from './filter/filter.component';
import { MarketplaceComponent } from './marketplace.component';
import { MarketPlaceReducers } from '../core/store/reducers/marketplace.reducers';
import { MarketplaceRoutingModule } from './marketplace-routing.module';
import { MarketPostDemandEffects } from '../core/store/effects/market-post-demand.effects';
import { MarketpostEffects } from '../core/store/effects/market-posts.effects';
import { MarketPostMapComponent } from './market-posts/market-post-map/market-post-map.component';
import { MarketPostOffersEffects } from '../core/store/effects/market-post-offer.effects';
import { MarketPostRowComponent } from './market-posts/market-post-row/market-post-row.component';
import { MarketPostsComponent } from './market-posts/market-posts.component';
import { MarketToolbarComponent } from './market-toolbar/market-toolbar.component';
import { MessageBoardComponent } from './message-board/message-board.component';
import { MessagesEffects } from '../core/store/effects/messages.effects';
import { NewMarketpostComponent } from '../marketplace/new-marketpost/new-marketpost.component';
import { NewMarketpostDemandComponent } from './new-marketpost/new-marketpost-demand/new-marketpost-demand.component';
import { NewMarketpostOfferComponent } from './new-marketpost/new-marketpost-offer/new-marketpost-offer.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SharePostComponent } from './new-marketpost/share-post/share-post.component';
import { StoreModule } from '@ngrx/store';
import { TransportTypesEffects } from '../core/store/effects/transport-types.effects';
import { MarketPostOfferDetailComponent } from './market-posts/market-post-offer-detail/market-post-offer-detail.component';
import { NewMarketpostTypeComponent } from './new-marketpost/new-marketpost-type/new-marketpost-type.component';
import { NoSubscriptionNotificationComponent } from './no-subscription-notification/no-subscription-notification.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    FilterComponent,
    MapComponent,
    MarketplaceComponent,
    MarketPostDemandDetailComponent,
    MarketPostMapComponent,
    MarketPostOfferDetailComponent,
    MarketPostRowComponent,
    MarketPostsComponent,
    MarketToolbarComponent,
    EditMarketPostComponent,
    MessageBoardComponent,
    MessageInputComponent,
    MessageComponent,
    NewMarketpostComponent,
    NewMarketpostDemandComponent,
    NewTransportListComponent,
    NewMarketpostOfferComponent,
    NewMarketpostTypeComponent,
    NoSubscriptionNotificationComponent,
    SharePostComponent
  ],
  imports: [
    NgSelectModule,
    NgxDatatableModule,
    RouterModule,
    MarketplaceRoutingModule,
    CoreModule,
    SharedModule,
    StoreModule.forFeature('marketplace', MarketPlaceReducers),
    EffectsModule.forFeature([
      LinerEffects,
      TransportTypesEffects,
      MarketpostEffects,
      MarketPostOffersEffects,
      MarketPostDemandEffects,
      MessagesEffects
    ])
  ],
  providers: [MarketpostParserService],
  bootstrap: [MarketplaceComponent]
})
export class MarketplaceModule { }
