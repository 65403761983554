import { Action } from '@ngrx/store';

import * as types from '../types/market-post.types';

export class LoadMarketPostOfferReplies implements Action {
  readonly type = types.marketPostOfferReplies.LOAD_MARKET_POST_OFFER_REPLIES;
  constructor(public payload?: any) {}
}

export class LoadMarketPostOfferRepliesSuccess implements Action {
  readonly type =
    types.marketPostOfferReplies.LOAD_MARKET_POST_OFFER_REPLIES_SUCCEEDED;
  constructor(public payload: any) {}
}

export class LoadMarketPostOfferRepliesFail implements Action {
  readonly type =
    types.marketPostOfferReplies.LOAD_MARKET_POST_OFFER_REPLIES_FAILED;
  constructor(public payload: any) {}
}

export type All =
  | LoadMarketPostOfferReplies
  | LoadMarketPostOfferRepliesSuccess
  | LoadMarketPostOfferRepliesFail;
