export interface Role {
  '@id': string;
  '@type': string;
  name: string;
  code: string;
  id: string;
}

export enum Roles {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  DRIVER = 'DRIVER',
  BETA_USER = 'BETA_USER',
  PLANNER = 'PLANNER'
}
