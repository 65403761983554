<div class="card">
  <h5 class="card-title d-flex justify-content-between">
    <span>
      {{ 'Filter' | translate }}
    </span>
  </h5>
  <div class="pl-3 pr-3">
    <ngb-alert *ngIf="showAlert" (close)="showAlert = false" [type]="'warning'">
      <i class="far fa-exclamation-triangle"></i>
      <span>{{
        "Warning: posts don't refresh with the filter open" | translate
      }}</span>
    </ngb-alert>
  </div>
  <ngb-accordion
    class="p-1"
    #acc="ngbAccordion"
    id="marketplace-filter"
    activeIds="ngb-panel-0, ngb-panel-1, ngb-panel-2, ngb-panel-3"
  >
    <ngb-panel>
      <ng-template ngbPanelTitle id="filter-date-range-panel">
        <div
          (click)="dateRangePanelVisible = !dateRangePanelVisible"
          class="d-flex justify-content-between"
        >
          <span>{{ 'Date range' | translate }}</span>
          <span class="icon">
            <i
              class="far pull-right"
              [ngClass]="{
                'fa-chevron-up': dateRangePanelVisible,
                'fa-chevron-down': !dateRangePanelVisible
              }"
            ></i>
          </span>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex justify-content-center">
          <app-daterangepicker
            (dateSelected)="handleDateSelection($event)"
            [displayMonths]="1"
            [markDisabledBeforeMindate]="true"
            [minDate]="calendar.getToday()"
            [fromDate]="fromDate"
            [toDate]="toDate"
          ></app-daterangepicker>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle id="filter-market-post-panel">
        <div
          (click)="marketPostPanelVisible = !marketPostPanelVisible"
          class="d-flex justify-content-between"
        >
          <span>{{ 'Post type' | translate }}</span>
          <span class="icon">
            <i
              class="far pull-right"
              [ngClass]="{
                'fa-chevron-up': marketPostPanelVisible,
                'fa-chevron-down': !marketPostPanelVisible
              }"
            ></i>
          </span>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <label class="custom-control custom-checkbox" id="offer-label">
          <input
            type="checkbox"
            id="filter-offer-checkbox"
            class="custom-control-input"
            [(ngModel)]="marketPostOffer"
            (ngModelChange)="mpoSelected()"
          />
          <span class="custom-control-label" data-cy="offerCheckbox">{{
            'Offer' | translate
          }}</span>
        </label>
        <label class="custom-control custom-checkbox" id="demand-label">
          <input
            type="checkbox"
            id="filter-demand-checkbox"
            class="custom-control-input"
            [(ngModel)]="marketPostDemand"
            (ngModelChange)="mpdSelected()"
          />
          <span class="custom-control-label">{{ 'Demand' | translate }}</span>
        </label>
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle id="filter-transport-panel">
        <div
          (click)="transportPanelVisible = !transportPanelVisible"
          class="d-flex justify-content-between"
        >
          <span data-cy="transportPanelToggle">{{
            'Transport' | translate
          }}</span>
          <span class="icon">
            <i
              class="far pull-right"
              [ngClass]="{
                'fa-chevron-up': transportPanelVisible,
                'fa-chevron-down': !transportPanelVisible
              }"
            ></i>
          </span>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngFor="let transportType of transportTypes">
          <label class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              [(ngModel)]="transportType.checked"
              (click)="toggleTType(transportType)"
            />
            <span class="custom-control-label">{{
              transportType.label | titlecase | translate
            }}</span>
          </label>
        </div>
        <div class="form-group">
          <label>{{ 'Reference' | translate }}</label>
          <input
            type="text"
            id="filter-reference-input"
            class="form-control"
            [(ngModel)]="reference"
            (ngModelChange)="referenceChanged()"
          />
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle id="filter-container-panel">
        <div
          (click)="containerPanelVisible = !containerPanelVisible"
          class="d-flex justify-content-between"
        >
          <span>{{ 'Container' | translate }}</span>
          <span class="icon">
            <i
              class="far pull-right"
              [ngClass]="{
                'fa-chevron-up': containerPanelVisible,
                'fa-chevron-down': !containerPanelVisible
              }"
            ></i>
          </span>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="form-group">
          <div class="form-row">
            <label class="col"> {{ 'Weight' | translate }}</label>
          </div>
          <div class="form-row">
            <div class="col">
              <input
                type="number"
                id="filter-min-weight-input"
                class="form-control"
                [(ngModel)]="minWeight"
                (ngModelChange)="minWeightChanged()"
                placeholder="min"
              />
            </div>
            <div class="col">
              <input
                type="number"
                id="filter-max-weight-input"
                class="form-control"
                [(ngModel)]="maxWeight"
                (ngModelChange)="maxWeightChanged()"
                placeholder="max"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>{{ 'Container Type' | translate }}</label>
          <ng-select
            [items]="containerTypes"
            bindLabel="label"
            multiple="true"
            placeholder="{{ 'Select Container Type' | translate }}"
            [(ngModel)]="selectedContainerTypes"
            (ngModelChange)="onSelectContainerType()"
            (remove)="onRemoveContainerType()"
          >
          </ng-select>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel>
      <ng-template ngbPanelTitle id="filter-liner-panel">
        <div
          (click)="linerPanelVisible = !linerPanelVisible"
          class="d-flex justify-content-between"
        >
          <span>{{ 'Liner' | translate }}</span>
          <span class="icon">
            <i
              class="far pull-right"
              [ngClass]="{
                'fa-chevron-up': linerPanelVisible,
                'fa-chevron-down': !linerPanelVisible
              }"
            ></i>
          </span>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="form-group">
          <label>{{ 'Name' | translate }}</label>
          <ng-select
            [items]="liners"
            bindLabel="name"
            multiple="true"
            placeholder="{{ 'Select Liner' | translate }}"
            [(ngModel)]="selectedLiners"
            (ngModelChange)="onSelectLiner()"
            (remove)="onRemoveLiner()"
          >
          </ng-select>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
