/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./welcome.component";
var styles_WelcomeComponent = [];
var RenderType_WelcomeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WelcomeComponent, data: {} });
export { RenderType_WelcomeComponent as RenderType_WelcomeComponent };
export function View_WelcomeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Access denied"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your company doesn't have any active subscription."]))], null, null); }
export function View_WelcomeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-welcome", [], null, null, null, View_WelcomeComponent_0, RenderType_WelcomeComponent)), i0.ɵdid(1, 49152, null, 0, i1.WelcomeComponent, [], null, null)], null, null); }
var WelcomeComponentNgFactory = i0.ɵccf("app-cmp-welcome", i1.WelcomeComponent, View_WelcomeComponent_Host_0, {}, {}, []);
export { WelcomeComponentNgFactory as WelcomeComponentNgFactory };
