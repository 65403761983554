/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./preannouncement-form.component.ngfactory";
import * as i2 from "./preannouncement-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "./new-preannouncement.component";
import * as i5 from "@ngrx/effects";
import * as i6 from "../../services/toast.service";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
var styles_NewPreannouncementComponent = [];
var RenderType_NewPreannouncementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewPreannouncementComponent, data: {} });
export { RenderType_NewPreannouncementComponent as RenderType_NewPreannouncementComponent };
export function View_NewPreannouncementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-preannouncement-form", [], null, [[null, "formSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formSubmitted" === en)) {
        var pd_0 = (_co.submit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PreannouncementFormComponent_0, i1.RenderType_PreannouncementFormComponent)), i0.ɵdid(1, 573440, null, 0, i2.PreannouncementFormComponent, [i3.FormBuilder], { isLoading: [0, "isLoading"] }, { formSubmitted: "formSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NewPreannouncementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-preannouncement", [], null, null, null, View_NewPreannouncementComponent_0, RenderType_NewPreannouncementComponent)), i0.ɵdid(1, 180224, null, 0, i4.NewPreannouncementComponent, [i5.Actions, i6.ToastService, i7.Store, i8.Router], null, null)], null, null); }
var NewPreannouncementComponentNgFactory = i0.ɵccf("app-new-preannouncement", i4.NewPreannouncementComponent, View_NewPreannouncementComponent_Host_0, {}, {}, []);
export { NewPreannouncementComponentNgFactory as NewPreannouncementComponentNgFactory };
