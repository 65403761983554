import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { LoadCountries } from 'app/core/store/actions/country.actions';
import { CreateCustomer, LoadCustomers } from 'app/core/store/actions/customer.actions';
import { State } from 'app/core/store/store.model';
import * as CustomerTypes from 'app/core/store/types/customer.types';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit, OnDestroy {
  countries: any[];
  title = extract('Create client');
  isLoading: boolean;
  redirectUrl = 'crm/customers';

  constructor(
    private updates$: Actions,
    private toastr: ToastService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.updates$
      .pipe(ofType(CustomerTypes.customer.CREATE_CUSTOMER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Customer created!')
        });
        this.store.dispatch(new LoadCustomers());
        this.isLoading = true;
        this.router.navigate([this.redirectUrl]);
      });
    if (this.route.parent && this.route.parent.parent) {
      this.route.parent.parent.url.pipe(untilDestroyed(this)).subscribe(segments => {
        if (segments.join('') === 'crm') {
          this.redirectUrl = 'crm/customers';
        } else if (segments.join('') === 'certiweight-backoffice') {
          this.redirectUrl = 'certiweight-backoffice/customers';
        }
      });
    }
  }

  ngOnInit() {
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countriesState => {
        if (!countriesState) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countriesState];
      });
  }

  createNewCustomer(customerFormValue) {
    this.store.dispatch(new CreateCustomer(customerFormValue));
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
