import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { NewInvoiceComponent } from './new-invoice/new-invoice.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { AuthGuard } from '../core/user/user.authguard';
import { Routes } from '@angular/router';
import { Route } from '../core/route.service';
import { CrmComponent } from './crm.component';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
import { CustomersComponent } from './customers/customers.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { NewCreditNoteComponent } from './new-credit-note/new-credit-note.component';
import { CreditNotesComponent } from './credit-notes/credit-notes.component';
import { EditCreditNoteComponent } from './edit-credit-note/edit-credit-note.component';
import { TaxRatesComponent } from './tax-rates/tax-rates.component';
import { NewTaxRateComponent } from './new-tax-rate/new-tax-rate.component';
import { EditTaxRateComponent } from './edit-tax-rate/edit-tax-rate.component';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { TariffsComponent } from '../tms/tariffs/tariffs.component';
import { NewTariffComponent } from '../tms/new-tariff/new-tariff.component';
import { EditTariffComponent } from '../tms/edit-tariff/edit-tariff.component';
import { NewSupplierComponent } from './new-supplier/new-supplier.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { EditSupplierComponent } from './edit-supplier/edit-supplier.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { NewPurchaseOrderComponent } from './new-purchase-order/new-purchase-order.component';
import { EditPurchaseOrderComponent } from './edit-purchase-order/edit-purchase-order.component';
var ɵ0 = { subscriptionCode: 'TMS' };
var routes = Route.withShell([
    {
        path: 'crm',
        canActivate: [AuthGuard, TenantGuard, SubscriptionGuard],
        data: ɵ0,
        children: [
            {
                path: '',
                component: CrmComponent
            },
            {
                path: 'customers',
                children: [
                    {
                        path: '',
                        component: CustomersComponent
                    },
                    {
                        path: 'new',
                        component: NewCustomerComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditCustomerComponent
                    }
                ]
            },
            {
                path: 'suppliers',
                children: [
                    {
                        path: '',
                        component: SuppliersComponent
                    },
                    {
                        path: 'new',
                        component: NewSupplierComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditSupplierComponent
                    }
                ]
            },
            {
                path: 'invoices',
                children: [
                    {
                        path: '',
                        component: InvoicesComponent
                    },
                    {
                        path: 'new',
                        component: NewInvoiceComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditInvoiceComponent
                    }
                ]
            },
            {
                path: 'purchase-orders',
                children: [
                    {
                        path: '',
                        component: PurchaseOrdersComponent
                    },
                    {
                        path: 'new',
                        component: NewPurchaseOrderComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditPurchaseOrderComponent
                    }
                ]
            },
            {
                path: 'credit-notes',
                children: [
                    {
                        path: '',
                        component: CreditNotesComponent
                    },
                    {
                        path: 'new',
                        component: NewCreditNoteComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditCreditNoteComponent
                    }
                ]
            },
            {
                path: 'tax-rates',
                children: [
                    {
                        path: '',
                        component: TaxRatesComponent
                    },
                    {
                        path: 'new',
                        component: NewTaxRateComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditTaxRateComponent
                    }
                ]
            },
            {
                path: 'tariffs',
                children: [
                    {
                        path: '',
                        component: TariffsComponent
                    },
                    {
                        path: 'new',
                        component: NewTariffComponent
                    },
                    {
                        path: ':id/edit',
                        component: EditTariffComponent
                    }
                ]
            }
        ]
    }
]);
var CrmRoutingModule = /** @class */ (function () {
    function CrmRoutingModule() {
    }
    return CrmRoutingModule;
}());
export { CrmRoutingModule };
export { ɵ0 };
