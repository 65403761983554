import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';

export function untilDestroyed<T extends OnDestroy, X>(instance: T):
  ((source: Observable<X>) => Observable<X>) {
  return (source: Observable<X>) => {
    const originalDestroy = instance.ngOnDestroy;
    if (!instance['__takeUntilDestroy']) {
      instance['__takeUntilDestroy'] = new Subject();

      instance.ngOnDestroy = function() {
        originalDestroy.apply(this, arguments);
        instance['__takeUntilDestroy'].next(true);
        instance['__takeUntilDestroy'].complete();
      };
    }
    return source.pipe(takeUntil<X>(instance['__takeUntilDestroy']));
  };
}
