import { ToastService } from 'app/services/toast.service';
import { ApiService } from './api.service';
import { Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Logout, RefreshSuccess } from '../store/actions/session.actions';
import { ErrorParserService } from 'app/services/error-parser.service';
var ApiInterceptor = /** @class */ (function () {
    function ApiInterceptor(store, toastr, errorParser, injector) {
        var _this = this;
        this.store = store;
        this.toastr = toastr;
        this.errorParser = errorParser;
        this.injector = injector;
        this.isRefreshingToken = false;
        this.tokenSubject = new BehaviorSubject(null);
        this.noAuthUrls = [
            'komoot',
            'reset',
            '/code'
            // 'oauth/v2/token',
        ];
        this.store.select('session').subscribe(function (stateSession) {
            _this.session = stateSession;
        });
    }
    Object.defineProperty(ApiInterceptor.prototype, "isRefreshing", {
        get: function () {
            return this.isRefreshingToken;
        },
        set: function (isRefreshing) {
            this.isRefreshingToken = isRefreshing;
        },
        enumerable: true,
        configurable: true
    });
    ApiInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        // Headers that are set on GET requests
        var getHeaders = {};
        // Headers that are set on POST requests
        var postHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };
        var putHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };
        if (request.headers && request.headers.keys().length === 0) {
            switch (request.method) {
                case 'GET':
                    request = request.clone({ setHeaders: getHeaders });
                    break;
                case 'POST':
                    request = request.clone({ setHeaders: postHeaders });
                    break;
                case 'PUT':
                    request = request.clone({ setHeaders: putHeaders });
                    break;
                case 'DELETE':
                    request = request.clone({ setHeaders: getHeaders });
                    break;
                default:
                    request = request.clone({ setHeaders: getHeaders });
                    break;
            }
        }
        return next
            .handle(this.setAuthHeaders(request, this.session ? this.session.access_token : null))
            .pipe(catchError(function (error) {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 401:
                        return _this.handle401Error(request, next);
                    case 400:
                        if (error &&
                            error.error &&
                            error.error['error_description'] &&
                            error.error['error_description'] === 'Invalid refresh token') {
                            _this.store.dispatch(new Logout());
                        }
                        var message = _this.errorParser.getHumanReadableErrorMessage(error);
                        if (message) {
                            if (error && error.error && error.error.type === 'warning') {
                                _this.toastr.showWarning({
                                    message: message,
                                    title: 'Warning'
                                });
                            }
                            else {
                                _this.toastr.showDanger({
                                    message: message,
                                    title: 'Something went wrong'
                                });
                            }
                        }
                        return throwError(error);
                    case 403:
                        return throwError('');
                    default:
                        return throwError(error);
                }
            }
            else {
                return throwError(error);
            }
        }));
    };
    // See: https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/
    ApiInterceptor.prototype.handle401Error = function (req, next) {
        var _this = this;
        var sesch = localStorage.getItem('session');
        if (this.session && sesch) {
            if (!this.isRefreshing) {
                this.isRefreshingToken = true;
                // Reset here so that the following requests wait until the token
                // comes back from the refreshToken call.
                this.tokenSubject.next(null);
                var body = new URLSearchParams();
                body.append('refresh_token', this.session.refresh_token);
                var options = {
                    body: body
                };
                return this.injector
                    .get(ApiService)
                    .refreshToken(options)
                    .pipe(switchMap(function (authObj) {
                    if (authObj['access_token']) {
                        var newToken = authObj['access_token'];
                        _this.tokenSubject.next(newToken);
                        _this.isRefreshing = false;
                        localStorage.removeItem('session');
                        localStorage.setItem('session', JSON.stringify(authObj));
                        _this.store.dispatch(new RefreshSuccess(authObj));
                        return next.handle(_this.setAuthHeaders(req, newToken));
                    }
                }), catchError(function (err) {
                    _this.tokenSubject.next('cancel');
                    _this.isRefreshing = false;
                    _this.store.dispatch(new Logout());
                    localStorage.removeItem('session');
                    _this.toastr.showDanger({
                        title: 'Something went wrong',
                        message: err.error['hydra:description'] ||
                            err.error['detail'] ||
                            err.error.error_description ||
                            err.message
                    });
                    return of(err);
                }));
            }
            else {
                return this.tokenSubject.pipe(filter(function (token) { return token != null; }), take(1), switchMap(function (token) {
                    if (token === 'cancel') {
                        return;
                    }
                    return next.handle(_this.setAuthHeaders(req, token));
                }));
            }
        }
        else {
            return of(false);
        }
    };
    ApiInterceptor.prototype.setAuthHeaders = function (request, token) {
        // Check if request url need authentication headers
        if (!this.noAuthUrls.some(function (url) { return request.url.indexOf(url) !== -1; }) &&
            !request.headers.has('Authorization')) {
            request = request.clone({
                headers: request.headers.append('Authorization', 'Bearer ' + token)
            });
            if (!request.headers.has('X-tenant-id') &&
                this.session &&
                this.session.tenant) {
                request = request.clone({
                    headers: request.headers.append('X-tenant-id', this.session.tenant)
                });
            }
        }
        return request;
    };
    return ApiInterceptor;
}());
export { ApiInterceptor };
