/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/transport-info/transport-info.component.ngfactory";
import * as i2 from "../../shared/transport-info/transport-info.component";
import * as i3 from "../../services/icon.service";
import * as i4 from "@angular/common";
import * as i5 from "./planning-day.component";
var styles_PlanningDayComponent = [];
var RenderType_PlanningDayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PlanningDayComponent, data: {} });
export { RenderType_PlanningDayComponent as RenderType_PlanningDayComponent };
function View_PlanningDayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-transport-info", [], null, null, null, i1.View_TransportInfoComponent_0, i1.RenderType_TransportInfoComponent)), i0.ɵdid(1, 573440, null, 0, i2.TransportInfoComponent, [i3.IconService], { transport: [0, "transport"], planned: [1, "planned"], driverId: [2, "driverId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = true; var currVal_2 = _co.driverId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PlanningDayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlanningDayComponent_1)), i0.ɵdid(1, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transports; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PlanningDayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-planning-day", [], null, null, null, View_PlanningDayComponent_0, RenderType_PlanningDayComponent)), i0.ɵdid(1, 49152, null, 0, i5.PlanningDayComponent, [], null, null)], null, null); }
var PlanningDayComponentNgFactory = i0.ɵccf("app-planning-day", i5.PlanningDayComponent, View_PlanningDayComponent_Host_0, { driverId: "driverId", date: "date", transports: "transports" }, { planningUpdated: "planningUpdated" }, []);
export { PlanningDayComponentNgFactory as PlanningDayComponentNgFactory };
