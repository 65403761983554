export const users = {
  LOAD_USER: '[User] Load',
  LOAD_SUCCEEDED: '[User] Load Success',
  LOAD_FAILED: '[User] Load Fail',
  RESET: '[User] Reset',
  RESET_SUCCEEDED: '[User] Reset Success',
  RESET_FAILED: '[User] Reset Failed',
  RESET_UPDATE: '[User] Reset Update',
  RESET_UPDATE_SUCCEEDED: '[User] Reset Update Success',
  RESET_UPDATE_FAILED: '[User] Reset Update Failed',
  UPDATE_USER: '[User] Update',
  UPDATE_USER_SUCCEEDED: '[User] Update Success',
  UPDATE_USER_FAILED: '[User] Update Fail',
  USER_LOGOUT: '[User] Logout',
  CLEAR: '[User] Clear'
};
