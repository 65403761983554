export const location = {
  LOAD_LOCATIONS: '[Locations] Load',
  LOAD_LOCATIONS_SUCCEEDED: '[Locations] Load Success',
  LOAD_LOCATIONS_FAILED: '[Locations]: Load Fail',
  LOAD_UNCHECKED_LOCATIONS: '[Locations] Load Unchecked',
  LOAD_UNCHECKED_LOCATIONS_SUCCEEDED: '[Locations] Load Unchecked Success',
  LOAD_UNCHECKED_LOCATIONS_FAILED: '[Locations]: Load Unchecked Fail',
  LOAD_LOCATIONS_BY_LOCATION_TYPE: '[Locations] Load By Type',
  LOAD_LOCATIONS_BY_LOCATION_TYPE_SUCCEEDED: '[Locations] Load By Type Success',
  LOAD_LOCATIONS_BY_LOCATION_TYPE_FAILED: '[Locations]: Load By Type Fail',
  LOAD_ADDRESS_BOOK_LOCATIONS: '[Locations] Load Address Book',
  LOAD_ADDRESS_BOOK_LOCATIONS_SUCCEEDED:
    '[Locations] Load Address Book Success',
  LOAD_ADDRESS_BOOK_LOCATIONS_FAILED: '[Locations] Load Address Book Fail',
  LOAD_PRENOTIFIABLE_LOCATIONS: '[Locations] Load prenotifiable locations',
  LOAD_PRENOTIFIABLE_LOCATIONS_SUCCEEDED:
    '[Locations] Load prenotifiable locations Success',
  LOAD_PRENOTIFIABLE_LOCATIONS_FAILED:
    '[Locations] Load prenotifiable locations Fail',
  CREATE_USER_LOCATION: '[Locations] Create User',
  CREATE_USER_LOCATION_SUCCEEDED: '[Locations] Create User Success',
  CREATE_USER_LOCATION_FAILED: '[Locations] Create User Fail',
  UPDATE_USER_LOCATION: '[Locations] Update User',
  UPDATE_USER_LOCATION_SUCCEEDED: '[Locations] Update User Success',
  UPDATE_USER_LOCATION_FAILED: '[Locations] Update User Fail',
  DELETE_USER_LOCATION: '[Locations] Delete User',
  DELETE_USER_LOCATION_SUCCEEDED: '[Locations] Delete User Success',
  DELETE_USER_LOCATION_FAILED: '[Locations] Delete User Fail',
  DELETE_LOCATION: '[Locations] Delete',
  DELETE_LOCATION_SUCCEEDED: '[Locations] Delete Success',
  DELETE_LOCATION_FAILED: '[Locations] Delete Fail',
  LOAD_LOCATION_TYPES: '[Locations] Load types',
  LOAD_LOCATION_TYPES_SUCCEEDED: '[Locations] Load Types Success',
  LOAD_LOCATION_TYPES_FAILED: '[Locations] Load Types Fail',
  CLEAR_ADDRESS_BOOK_LOCATIONS: '[Locations] Clear address book'
};
