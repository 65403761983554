import { VectorLayer } from 'ol/layer/vector';
import { HakkaMap } from '../../marketplace/market-posts/market-post-map/map/hakka-map';
import { Location } from 'app/core/store/models/location.model';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/location.types';
import { LocationService } from 'app/services/location.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-unchecked-location',
  templateUrl: './unchecked-location.component.html',
  styleUrls: ['./unchecked-location.component.scss']
})
export class UncheckedLocationComponent implements OnInit, OnDestroy {
  @Input()
  location: Location;

  @Input()
  query: any;

  @Output()
  updateLocation = new EventEmitter();
  @Output()
  skipLocation = new EventEmitter();

  hakkaMap: HakkaMap;
  markerLayer: VectorLayer;
  markers = [];
  geometries = [];
  coordinates: any;
  submitAttempt: boolean;
  isLoadingPublicLocation: boolean;
  privateLocationName: string;
  privateLocationAddress: any;
  publicLocation: Location;
  isLoading: boolean;

  constructor(
    private updates$: Actions,
    public locationService: LocationService
  ) {

    // Reset variables after success
    updates$
      .pipe(ofType(Types.location.UPDATE_USER_LOCATION_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.query = null;
        this.coordinates = null;
        this.submitAttempt = false;
        this.publicLocation = null;
        this.isLoading = false;
      });
    updates$
      .pipe(ofType(Types.location.UPDATE_USER_LOCATION_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.submitAttempt = false;
        this.isLoading = false;
      });
  }

  ngOnInit() {
    this.hakkaMap = new HakkaMap('map');
    this.markerLayer = this.hakkaMap.getLayerById('markers');
    this.hakkaMap.clickCoordinates.subscribe(coord => {
      this.coordinates = coord;
      this.updateMarker(coord[0], coord[1]);
    });
  }

  searchAddress = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.address(term);
      })
    )

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.location(term);
      })
    )
  /**
   * Patch the public location with a suggestion based on the private location
   */
  updatePublicLocation() {
    if (!this.query || !this.query.latitude || !this.query.longitude) {
      return;
    }
    this.isLoadingPublicLocation = true;
    this.searchLocation(
      of(`${this.query['city']} (${this.query['postcode']})`)
    ).subscribe(results => {
      this.publicLocation = results[0];
      this.isLoadingPublicLocation = false;
    });
  }
  updateMarkerByModel() {
    if (!this.query || !this.query.latitude || !this.query.longitude) {
      return;
    }
    const longitude = +this.query.longitude;
    const latitude = +this.query.latitude;
    this.updateMarker(longitude, latitude);
    this.hakkaMap.setCenter(longitude, latitude, 18);
    this.coordinates = [longitude, latitude];
  }
  updateMarker(lon, lat, id?) {
    this.markers[0] = this.hakkaMap.generateFeature(
      {
        lat: lat,
        lon: lon
      },
      id || ''
    );
    // this.hakkaMap.sources['markers'].clear();
    this.hakkaMap.sources['markers'].getFeaturesCollection().clear();
    this.hakkaMap.sources['markers']
      .getFeaturesCollection()
      .push(this.markers[0]);
  }

  confirmLocation() {
    this.submitAttempt = true;
    if (!this.coordinates || !this.publicLocation) {
      return;
    }
    this.isLoading = true;
    const body = {
      longitude: this.coordinates[0].toString(),
      latitude: this.coordinates[1].toString(),
      fullAddress: this.locationService.addressFormatter(this.query),
      publicLocation: this.publicLocation
    };
    this.updateLocation.emit(body);
  }

  handleSkipLocation() {
    this.skipLocation.emit(true);
  }

  ngOnDestroy() { }
}
