/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../purchase-order-form/purchase-order-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../shared/datepicker-config";
import * as i4 from "../purchase-order-form/purchase-order-form.component";
import * as i5 from "../../services/icon.service";
import * as i6 from "@angular/forms";
import * as i7 from "./new-purchase-order.component";
import * as i8 from "@ngrx/store";
import * as i9 from "@angular/router";
import * as i10 from "@ngrx/effects";
import * as i11 from "../../services/toast.service";
import * as i12 from "../../services/download.service";
var styles_NewPurchaseOrderComponent = [];
var RenderType_NewPurchaseOrderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewPurchaseOrderComponent, data: {} });
export { RenderType_NewPurchaseOrderComponent as RenderType_NewPurchaseOrderComponent };
export function View_NewPurchaseOrderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-purchase-order-form", [], null, [[null, "searchTransports"], [null, "selectTransport"], [null, "supplierSelected"], [null, "removeTransport"], [null, "loadNextTransportPage"], [null, "purchaseOrderFormSubmitted"], [null, "onSaveAndDownloadAsPDF"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchTransports" === en)) {
        var pd_0 = (_co.handleSearchTransports($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectTransport" === en)) {
        var pd_1 = (_co.handleSelectTransport($event) !== false);
        ad = (pd_1 && ad);
    } if (("supplierSelected" === en)) {
        var pd_2 = (_co.handleSelectSupplier($event) !== false);
        ad = (pd_2 && ad);
    } if (("removeTransport" === en)) {
        var pd_3 = (_co.handleRemoveTransport($event) !== false);
        ad = (pd_3 && ad);
    } if (("loadNextTransportPage" === en)) {
        var pd_4 = (_co.loadNextTransportsPage($event) !== false);
        ad = (pd_4 && ad);
    } if (("purchaseOrderFormSubmitted" === en)) {
        var pd_5 = (_co.createNewPurchaseOrder($event) !== false);
        ad = (pd_5 && ad);
    } if (("onSaveAndDownloadAsPDF" === en)) {
        var pd_6 = (_co.createAndDownloadAsPDF($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i1.View_PurchaseOrderFormComponent_0, i1.RenderType_PurchaseOrderFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i2.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 573440, null, 0, i4.PurchaseOrderFormComponent, [i5.IconService, i6.FormBuilder, i2.NgbModal], { suppliers: [0, "suppliers"], isLoadingSuppliers: [1, "isLoadingSuppliers"], taxRates: [2, "taxRates"], isLoadingTaxRates: [3, "isLoadingTaxRates"], isLoading: [4, "isLoading"], transports: [5, "transports"] }, { purchaseOrderFormSubmitted: "purchaseOrderFormSubmitted", onSaveAndDownloadAsPDF: "onSaveAndDownloadAsPDF", searchTransports: "searchTransports", selectTransport: "selectTransport", removeTransport: "removeTransport", supplierSelected: "supplierSelected", loadNextTransportPage: "loadNextTransportPage" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suppliers; var currVal_1 = _co.isLoadingSuppliers; var currVal_2 = _co.taxRates; var currVal_3 = _co.isLoadingTaxRates; var currVal_4 = _co.isLoading; var currVal_5 = _co.filteredTransports; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_NewPurchaseOrderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-purchase-order", [], null, null, null, View_NewPurchaseOrderComponent_0, RenderType_NewPurchaseOrderComponent)), i0.ɵdid(1, 245760, null, 0, i7.NewPurchaseOrderComponent, [i8.Store, i9.Router, i10.Actions, i11.ToastService, i12.DownloadService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewPurchaseOrderComponentNgFactory = i0.ɵccf("app-new-purchase-order", i7.NewPurchaseOrderComponent, View_NewPurchaseOrderComponent_Host_0, {}, {}, []);
export { NewPurchaseOrderComponentNgFactory as NewPurchaseOrderComponentNgFactory };
