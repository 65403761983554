import { PickTenant } from '../../store/actions/session.actions';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../../user/user.auth';
import { State } from '../../store/store.model';

@Injectable()
export class MultipleTenantGuard implements CanActivate {
  /**
   * @constructor
   * @param authService { AuthSerice } The authentication service boii
   * @param router { Router } Router injectable from @angular/router
   */
  constructor(
    private router: Router,
    private store: Store<State>,
    private authService: AuthService
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService
      .getUserTenantsFromStoreOrAPI()
      .pipe(
        switchMap(data => {
          if (data) {
            if (data.length === 1) {
              this.store.dispatch(new PickTenant(data[0].id));
              this.router.navigate(['/dashboard']);
              return of(false);
            } else {
              return of(true);
            }
          }
        }),
        catchError(() => {
          return of(false);
        })
      );
  }
}
