import { IconService } from 'app/services/icon.service';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as DateFns from 'date-fns';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NewTaxRateModalComponent } from '../new-tax-rate-modal/new-tax-rate-modal.component';
var PurchaseOrderFormComponent = /** @class */ (function () {
    function PurchaseOrderFormComponent(iconService, fb, modalService) {
        var _this = this;
        this.iconService = iconService;
        this.fb = fb;
        this.modalService = modalService;
        this.purchaseOrderFormSubmitted = new EventEmitter();
        this.onSaveAndDownloadAsPDF = new EventEmitter();
        this.searchTransports = new EventEmitter();
        this.selectTransport = new EventEmitter();
        this.removeTransport = new EventEmitter();
        this.supplierSelected = new EventEmitter();
        this.loadNextTransportPage = new EventEmitter();
        this.taxRateExpr = new RegExp(/[0-9]{1,3}/);
        this.addTagText = 'Add tax rate';
        this.query = '';
        this.searchTextChanged = new Subject();
        this.showDateSelection = false;
        this.searchTextChanged
            .pipe(debounceTime(500), distinctUntilChanged(), map(function (search) { return _this.searchTransports.emit({ query: search.toString(), since: _this.since, until: _this.until }); }))
            .subscribe(function () { });
    }
    PurchaseOrderFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.taxRates && changes.taxRates.currentValue) {
            // Only create form if it doesn't exist
            if (!this.purchaseOrderForm) {
                this.createForm();
                if (this.purchaseOrder) {
                    this.patchForm();
                }
            }
        }
        if (changes && changes.purchaseOrder) {
            // Only create form if it doesn't exist and tax rates are loaded
            if (!this.purchaseOrderForm && this.taxRates) {
                this.createForm();
            }
            if (this.purchaseOrderForm &&
                // !this.purchaseOrderForm.value.date &&
                this.taxRates) {
                this.patchForm();
            }
        }
    };
    PurchaseOrderFormComponent.prototype.patchForm = function () {
        var _this = this;
        if (!this.purchaseOrder) {
            return;
        }
        this.purchaseOrderForm.patchValue({
            business: this.purchaseOrder.business,
            notes: this.purchaseOrder.notes,
            date: new Date(DateFns.getYear(this.purchaseOrder.date), DateFns.getMonth(this.purchaseOrder.date), DateFns.getDate(this.purchaseOrder.date))
        });
        this.purchaseOrder.items.forEach(function (item) {
            var puchaseOrderItems = (_this.purchaseOrderForm.controls['items']);
            if (item.transport) {
                puchaseOrderItems.at(puchaseOrderItems.value.length - 1).patchValue({
                    transport: item.transport
                });
            }
            puchaseOrderItems.at(puchaseOrderItems.value.length - 1).patchValue({
                id: item.id,
                description: item.description,
                quantity: item.quantity,
                unitPrice: item.unitPrice,
                taxRate: item.taxRate,
                total: item['totalInclTaxes'].toFixed(2)
            });
            _this.addPurchaseOrderItem();
        });
    };
    PurchaseOrderFormComponent.prototype.createForm = function () {
        this.purchaseOrderForm = this.fb.group({
            business: [null, Validators.required],
            date: [new Date(), Validators.required],
            notes: [null],
            items: this.fb.array([this.createPurchaseOrderItem()])
        });
    };
    Object.defineProperty(PurchaseOrderFormComponent.prototype, "supplierAddress", {
        get: function () {
            if (!this.purchaseOrderForm ||
                !this.purchaseOrderForm.value ||
                !this.purchaseOrderForm.get('business').value) {
                return;
            }
            return this.purchaseOrderForm.get('business').value['billingAddress'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PurchaseOrderFormComponent.prototype, "supplierMainContact", {
        get: function () {
            if (!this.purchaseOrderForm ||
                !this.purchaseOrderForm.value ||
                !this.purchaseOrderForm.get('business').value) {
                return;
            }
            return this.purchaseOrderForm.get('business').value['defaultContact'];
        },
        enumerable: true,
        configurable: true
    });
    PurchaseOrderFormComponent.prototype.createPurchaseOrderItem = function () {
        var group = this.fb.group({
            id: null,
            description: [''],
            quantity: [1],
            transport: [null],
            unitPrice: [0],
            taxRate: [this.taxRates[0], Validators.required],
            total: [{ value: 0, disabled: true }]
        });
        return group;
    };
    PurchaseOrderFormComponent.prototype.addPurchaseOrderItem = function () {
        var purchaseOrderItems = (this.purchaseOrderForm.controls['items']);
        purchaseOrderItems.push(this.createPurchaseOrderItem());
    };
    PurchaseOrderFormComponent.prototype.removePurchaseOrderItem = function (index) {
        var purchaseOrderItems = (this.purchaseOrderForm.controls['items']);
        var item = purchaseOrderItems.value[index];
        if (item.transport) {
            this.removeTransport.emit(item.transport.id);
        }
        purchaseOrderItems.removeAt(index);
    };
    PurchaseOrderFormComponent.prototype.handleSupplierSelected = function () {
        var supplier = this.purchaseOrderForm.get('business').value;
        var supplierId = supplier && supplier.id ? supplier.id : null;
        this.supplierSelected.emit(supplierId);
        // Remove all selected transports
        var purchaseOrderItems = (this.purchaseOrderForm.controls['items']);
        purchaseOrderItems.value.forEach(function (control) {
            if (!control.transport) {
                return;
            }
            if (control.transport.supplier &&
                control.transport.supplier.id === supplierId) {
                return;
            }
            var index = purchaseOrderItems.value.indexOf(control);
            purchaseOrderItems.removeAt(index);
        });
    };
    PurchaseOrderFormComponent.prototype.openTaxRateModal = function (itemIndex, rate) {
        var _this = this;
        var modalRef = this.modalService.open(NewTaxRateModalComponent);
        modalRef.result.then(function (result) {
            var purchaseOrderItems = (_this.purchaseOrderForm.controls['items']);
            purchaseOrderItems.at(itemIndex).patchValue({
                taxRate: result
            });
        });
    };
    PurchaseOrderFormComponent.prototype.search = function ($event) {
        this.searchTextChanged.next($event.target.value);
    };
    /**
     * Duplicates the given purchaseOrder item
     * @param index index of the purchaseOrder item
     */
    PurchaseOrderFormComponent.prototype.duplicateItem = function (index) {
        this.addPurchaseOrderItem();
        var purchaseOrderItems = (this.purchaseOrderForm.controls['items']);
        var item = Object.assign({}, purchaseOrderItems.at(index).value);
        purchaseOrderItems.at(purchaseOrderItems.value.length - 1).patchValue(item);
    };
    /**
     * Updates the total of the given purchaseOrder item based on the quantity, unit pirce
     * and tax rate
     * @param index index of the purchaseOrder item
     */
    PurchaseOrderFormComponent.prototype.updateTotal = function (index) {
        var purchaseOrderItems = (this.purchaseOrderForm.controls['items']);
        var item = purchaseOrderItems.value[index];
        if (!item.quantity ||
            !item.unitPrice ||
            !item.taxRate ||
            !item.taxRate.rate) {
            return;
        }
        item.total =
            +item.quantity * +item.unitPrice +
                ((+item.quantity * +item.unitPrice) / 100) * +item.taxRate.rate;
        purchaseOrderItems.at(index).setValue(item);
    };
    Object.defineProperty(PurchaseOrderFormComponent.prototype, "total", {
        get: function () {
            var purchaseOrderItems = this.purchaseOrderForm.controls['items'].value;
            return purchaseOrderItems
                .map(function (item) { return +item.total; })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PurchaseOrderFormComponent.prototype, "subTotal", {
        get: function () {
            var purchaseOrderItems = this.purchaseOrderForm.controls['items'].value;
            return purchaseOrderItems
                .map(function (item) { return +item.quantity * +item.unitPrice; })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PurchaseOrderFormComponent.prototype, "taxes", {
        get: function () {
            var purchaseOrderItems = this.purchaseOrderForm.controls['items'].value;
            return purchaseOrderItems
                .map(function (item) {
                // If an item doesn't have a tax rate it is invalid
                if (!item.taxRate || !item.taxRate.rate) {
                    return 0;
                }
                return (((+item.quantity * +item.unitPrice) / 100) * +item.taxRate['rate']);
            })
                .reduce(function (total1, total2) { return total1 + total2; });
        },
        enumerable: true,
        configurable: true
    });
    PurchaseOrderFormComponent.prototype.handleTransportSelected = function (transport) {
        if (!transport.supplierTariff) {
            return;
        }
        var description = transport.supplierTariff['label'];
        var unitPrice = transport.supplierTariff['price'];
        var purchaseOrderItems = (this.purchaseOrderForm.controls['items']);
        var index = purchaseOrderItems.value.length - 1;
        if (purchaseOrderItems.at(index).get('description').value) {
            this.addPurchaseOrderItem();
            index++;
        }
        var item = {
            description: description,
            unitPrice: unitPrice,
            transport: transport
        };
        purchaseOrderItems.at(index).patchValue(item);
        this.updateTotal(index);
        this.addPurchaseOrderItem();
        // Select customer if transport has one so we can only add transports of exactly one customer
        if (transport.supplier && transport.supplier['id'] && !this.purchaseOrderForm.get('business').value) {
            this.purchaseOrderForm.patchValue({
                business: transport.supplier
            });
            this.handleSupplierSelected();
        }
        this.selectTransport.emit(transport.id);
    };
    /**
     * Emits the purchaseOrder form value
     */
    PurchaseOrderFormComponent.prototype.onSubmit = function () {
        if (this.purchaseOrderForm.invalid) {
            this.markFormGroupAsTouched(this.purchaseOrderForm);
            return;
        }
        var purchaseOrderFormValue = this.parsePurchaseOrderFormValue();
        this.purchaseOrderFormSubmitted.emit(purchaseOrderFormValue);
    };
    PurchaseOrderFormComponent.prototype.markFormGroupAsTouched = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.markFormGroupAsTouched(control);
            }
        });
    };
    PurchaseOrderFormComponent.prototype.parsePurchaseOrderFormValue = function () {
        var purchaseOrderFormValue = JSON.parse(JSON.stringify(this.purchaseOrderForm.value));
        purchaseOrderFormValue.items = purchaseOrderFormValue.items.filter(function (item) { return item.description || item.transport; });
        purchaseOrderFormValue.date = DateFns.setHours(purchaseOrderFormValue.date, 12);
        purchaseOrderFormValue.business = purchaseOrderFormValue.business
            ? purchaseOrderFormValue.business['@id']
            : null;
        purchaseOrderFormValue.items.forEach(function (item) {
            item.taxRate = {
                id: item.taxRate['id']
            };
            if (!item.transport) {
                return;
            }
            item.transport = {
                id: item.transport['id']
            };
        });
        return purchaseOrderFormValue;
    };
    PurchaseOrderFormComponent.prototype.getTransportIcon = function (transport) {
        return this.iconService.getTransportTypeIcon(transport.transportType);
    };
    PurchaseOrderFormComponent.prototype.saveAndDownloadAsPDF = function () {
        if (this.purchaseOrderForm.invalid) {
            return;
        }
        var purchaseOrderFormValue = this.parsePurchaseOrderFormValue();
        this.onSaveAndDownloadAsPDF.emit(purchaseOrderFormValue);
    };
    PurchaseOrderFormComponent.prototype.getPrimaryTaskLocation = function (transport) {
        var primaryTask = transport.transportTasks.find(function (t) { return t.primaryTask; });
        if (!primaryTask.location) {
            return;
        }
        return primaryTask.location.name;
    };
    PurchaseOrderFormComponent.prototype.getPrimaryTaskDate = function (transport) {
        return transport.primaryTaskDateTimeSpecified
            ? moment(transport.primaryTaskDate).format("DD/MM HH:mm")
            : moment(transport.primaryTaskDate).format("DD/MM");
    };
    PurchaseOrderFormComponent.prototype.onScrollDown = function () {
        this.loadNextTransportPage.emit(true);
    };
    PurchaseOrderFormComponent.prototype.handleDateSelection = function (dateSelectionEvent) {
        var sinceDateStruct = (dateSelectionEvent.fromDate) ? dateSelectionEvent.fromDate : null;
        var untilDateStruct = (dateSelectionEvent.toDate) ? dateSelectionEvent.toDate : null;
        if (sinceDateStruct !== null && untilDateStruct !== null) {
            var sinceDate = moment(sinceDateStruct.year + '-' + sinceDateStruct.month + '-' + sinceDateStruct.day, 'YYYY-MM-DD');
            var untilDate = moment(untilDateStruct.year + '-' + untilDateStruct.month + '-' + untilDateStruct.day, 'YYYY-MM-DD');
            this.since = sinceDate.format('YYYY-MM-DD');
            this.until = untilDate.format('YYYY-MM-DD');
            this.dateSelection = sinceDate.format('DD/MM/YYYY') + ' - ' + untilDate.format('DD/MM/YYYY');
            this.showDateSelection = false;
            this.searchTransports.emit({ query: this.query, since: this.since, until: this.until });
        }
        else {
            this.since = null;
            this.until = null;
        }
    };
    return PurchaseOrderFormComponent;
}());
export { PurchaseOrderFormComponent };
