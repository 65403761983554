import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { catchError, map, scan, startWith, switchMap } from 'rxjs/operators';

import { ApiService } from 'app/core/api/api.service';
import { PsaCompany } from 'app/core/store/models/psa-company.model';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { NgbDateNativeAdapter, NgbDateCustomParserFormatter } from 'app/shared/datepicker-config';

import { AccountActionHistoryComponent } from './account-action-history.component';
import { EditCreditThresholdComponent } from './edit-credit-threshold.component';

@Component({
  selector: 'app-manage-psa-accounts',
  templateUrl: './psa-accounts.component.html',
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class ManagePsaAccountsComponent {
  companies$: Observable<PsaCompany[]>;
  modifyEvents$ = new Subject<[string, object]>();
  reportForm: FormGroup;
  loadingReport = false;
  loading = false;

  constructor(
    private api: ApiService,
    private toastr: ToastService,
    private modalService: NgbModal,
    private location: Location,
    private fb: FormBuilder,
  ) {
    this.loading = true;
    this.companies$ = this.api.get({ path: '/psa_companies' }).pipe(
      map(x => {
        this.loading = false;
        return <PsaCompany[]>x['hydra:member'];
      }),
      catchError(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to load PSA companies'),
        });
        return [];
      }),
      switchMap((cs: PsaCompany[]) => this.modifyEvents$.pipe(
        scan(
          (companies: PsaCompany[], [modifiedId, modification]: [string, object]) =>
            companies.map(company => {
              if (company.id === modifiedId) {
                return Object.assign({}, company, modification);
              }
              return company;
            }),
          cs
        ),
        startWith(cs),
      ))
    );

    if (!this.reportForm) {
      this.createForm();
    }
  }

  editCreditThreshold(row) {
    const modalRef = this.modalService.open(EditCreditThresholdComponent);
    modalRef.componentInstance.credits = row.creditsThreshold;
    modalRef.result.then(creditsThreshold => this.api.put({
      path: `/psa_companies/${row.id}`,
      body: { creditsThreshold },
      params: { headers: { 'Content-Type': 'application/ld+json' } },
    }).toPromise().then(
      () => this.modifyEvents$.next([row.id, { creditsThreshold }]),
      () => this.toastr.showDanger({
        title: extract('Something went wrong'),
        message: extract('Failed to modify credits threshold'),
      })
    ));
  }

  showActionHistory(row) {
    const modalRef = this.modalService.open(AccountActionHistoryComponent);
    modalRef.componentInstance.account = row;
  }

  unlink(row) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'This action will unlink the PSA account from the Hakka account and send a message to the terminal to block the account'
    );
    modalRef.componentInstance.confirmButtonText = extract('Unlink account');
    modalRef.result.then(() => this.api.post({
      path: '/unlink-psa-account',
      body: { id: row.id },
      params: { headers: { 'Content-Type': 'application/ld+json' } },
    }).toPromise().then(
      () => this.modifyEvents$.next([row.id, { tenant: null, blocked: true }]),
      () => this.toastr.showDanger({
        title: extract('Something went wrong'),
        message: extract('Failed to unlink tenant from PSA company'),
      })
    ));
  }

  navigateBack(): void {
    this.location.back();
  }

  createForm(): void {
    const today = new Date();
    const weekAgo = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));

    this.reportForm = this.fb.group({
      sinceDate: [weekAgo, Validators.required],
      untilDate: [today, Validators.required]
    });
  }

  onReportFormSubmit(): void {
    if (this.reportForm.valid) {
      const since: string = moment(this.reportForm.value.sinceDate).format('YYYY-MM-DD');
      const until: string = moment(this.reportForm.value.untilDate).format('YYYY-MM-DD');

      if (since > until) {
        this.reportForm.get('untilDate').setErrors({'incorrect': true});;
        return;
      }

      this.loadingReport = true;
      this.api.get(
        { path: '/export/psa-transactions-report/csv?since=' + since + '&until=' + until },
        {
          'Content-Type': 'application/json',
          Accept: 'text/csv'
        },
        'blob'
      ).subscribe((data: any) => {
        fileSaver.saveAs(
          new Blob([data], { type: 'text/csv' }),
          'psa_transactions_report_' + moment().format('DD_MM_YYYY') + '.csv',
        );
        this.loadingReport = false;
      }, () => {
        this.loadingReport = false;
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: 'Download failed'
          });
      });
    } else {
      this.reportForm.get('sinceDate').markAsTouched();
      this.reportForm.get('untilDate').markAsTouched();
    }
  }
}
