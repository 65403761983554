<div class="grid-container p-3">
  <div class="form-group" id="vat-number-fg">
    <input
      class="form-control"
      disabled="true"
      value="{{ supplier?.vatNumber }}"
      placeholder="VAT Number"
    />
  </div>
  <div class="form-group" id="name-fg">
    <input
      class="form-control"
      disabled="true"
      value="{{ supplier?.label }}"
      placeholder="Name"
    />
  </div>
  <div id="contact-address">
    <span id="billing-address-title" class="form-section-title">{{
      'Billing address' | translate
    }}</span>
    <span id="new-address-button" (click)="launchNewAddressModal()">
      <i class="bg-success text-white far fa-fw fa-plus"></i>
      {{ 'Add new address' | translate }}</span
    >
    <div id="addresses">
      <app-address
        [address]="supplier?.billingAddress"
        [billingAddress]="true"
      ></app-address>
      <app-address
        *ngFor="let address of filteredAddresses"
        [address]="address"
        (updateBillingAddress)="handleUpdateBillingAddress($event)"
        (deleteAddress)="handleDeleteAddress($event)"
      ></app-address>
    </div>
    <span id="contacts-title" class="form-section-title">{{
      'Contacts' | translate
    }}</span>
    <span id="new-contact-button" (click)="launchNewContactModal()">
      <i class="bg-success text-white far fa-fw fa-plus"></i>
      {{ 'Add new contact' | translate }}</span
    >
    <div id="contacts">
      <app-contact
        [contact]="supplier?.defaultContact"
        [mainContact]="true"
      ></app-contact>
      <app-contact
        *ngFor="let contact of filteredContacts"
        [contact]="contact"
        (updateMainContact)="handleUpdateMainContact($event)"
        (deleteContact)="handleDeleteContact($event)"
      ></app-contact>
    </div>
  </div>
</div>
