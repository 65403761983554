import { ToastService } from 'app/services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api/api.service';
import { Component } from '@angular/core';
import { Alert } from '../../store/models/alert.model';

@Component({
  selector: 'app-edit-liner',
  templateUrl: './edit-liner.component.html',
})
export class EditLinerComponent {
  id: string;
  alert: Alert;
  liner: any;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private toastr: ToastService
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api
          .get({
            path: '/liners/' + this.id
          })
          .subscribe(response => {
            this.liner = response;
          });
      }
    });
  }

  updateLiner(linerFormValue) {
    this.api
      .put({
        path: '/liners/' + this.id,
        body: linerFormValue
      })
      .subscribe(
        () => {
          this.toastr.showSuccess({
            message: 'Liner saved.'
          });
        },
        err => {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: err
          });
        }
      );
  }
}
