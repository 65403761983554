import { Action } from '@ngrx/store';
import * as types from '../types/marketplace.types';

export class LoadMarketPostOffers implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_OFFERS;
  constructor(public payload?: any) { }
}

export class LoadMarketPostOffersSuccess implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_OFFERS_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadMarketPostOffersFail implements Action {
  readonly type = types.marketposts.LOAD_MARKETPOSTS_OFFERS_FAILED;
  constructor(public payload?: any) { }
}

export class CreateMarketPostOffer implements Action {
  readonly type = types.marketposts.CREATE_MARKET_POST_OFFER;
  constructor(public payload: any) { }
}

export class CreateMarketPostOfferSuccess implements Action {
  readonly type = types.marketposts.CREATE_OFFER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateMarketPostOfferFail implements Action {
  readonly type = types.marketposts.CREATE_OFFER_FAILED;
  constructor(public payload: any) { }
}

export class UpdateMarketPostOffer implements Action {
  readonly type = types.marketposts.UPDATE_MARKETPOSTS_OFFER;
  constructor(public payload: any) { }
}

export class UpdateMarketPostOfferSuccess implements Action {
  readonly type = types.marketposts.UPDATE_MARKETPOSTS_OFFER_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class UpdateMarketPostOfferFail implements Action {
  readonly type = types.marketposts.UPDATE_MARKETPOSTS_OFFER_FAILED;
  constructor(public payload?: any) { }
}

export class AcceptMarketPostOffer implements Action {
  readonly type = types.marketposts.ACCEPT_MARKET_POST_OFFER;
  constructor(public payload: any) { }
}

export class AcceptMarketPostOfferSuccess implements Action {
  readonly type = types.marketposts.ACCEPT_MARKET_POST_OFFER_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class AcceptMarketPostOfferFail implements Action {
  readonly type = types.marketposts.ACCEPT_MARKET_POST_OFFER_FAILED;
  constructor(public payload?: any) { }
}

export class PublishMarketPostOffer implements Action {
  readonly type = types.marketposts.PUBLISH_MARKET_POST_OFFER;
  constructor(public payload: any) { }
}

export class PublishMarketPostOfferSuccess implements Action {
  readonly type = types.marketposts.PUBLISH_MARKET_POST_OFFER_SUCCESS;
  constructor(public payload?: any) { }
}

export class PublishMarketPostOfferFail implements Action {
  readonly type = types.marketposts.PUBLISH_MARKET_POST_OFFER_FAILED;
  constructor(public payload?: any) { }
}

export class UnpublishMarketPostOffer implements Action {
  readonly type = types.marketposts.UNPUBLISH_MARKET_POST_OFFER;
  constructor(public payload: any) { }
}

export class UnpublishMarketPostOfferSuccess implements Action {
  readonly type = types.marketposts.UNPUBLISH_MARKET_POST_OFFER_SUCCESS;
  constructor(public payload?: any) { }
}

export class UnpublishMarketPostOfferFail implements Action {
  readonly type = types.marketposts.UNPUBLISH_MARKET_POST_OFFER_FAILED;
  constructor(public payload?: any) { }
}

export class ReplyMarketPostOffer implements Action {
  readonly type = types.marketposts.REPLY_MARKET_POST_OFFER;
  constructor(public payload?: any) { }
}

export class ReplyMarketPostOfferSuccess implements Action {
  readonly type = types.marketposts.REPLY_MARKET_POST_OFFER_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class ReplyMarketPostOfferFail implements Action {
  readonly type = types.marketposts.REPLY_MARKET_POST_OFFER_FAILED;
  constructor(public payload?: any) { }
}
export type All
  = CreateMarketPostOffer
  | CreateMarketPostOfferSuccess
  | CreateMarketPostOfferFail
  | UpdateMarketPostOffer
  | UpdateMarketPostOfferSuccess
  | UpdateMarketPostOfferFail
  | LoadMarketPostOffers
  | LoadMarketPostOffersSuccess
  | LoadMarketPostOffersFail
  | AcceptMarketPostOffer
  | AcceptMarketPostOfferSuccess
  | AcceptMarketPostOfferFail
  | PublishMarketPostOffer
  | PublishMarketPostOfferSuccess
  | PublishMarketPostOfferFail
  | ReplyMarketPostOffer
  | ReplyMarketPostOfferSuccess
  | ReplyMarketPostOfferFail
  | UnpublishMarketPostOffer
  | UnpublishMarketPostOfferSuccess
  | UnpublishMarketPostOfferFail;
