<app-cmp-navbar [navbarTitle]="'Manage Beta Users'"></app-cmp-navbar>
<div class="container">
  <div class="card">
    <ng-select class="m-3" id="tenants" [loading]="isLoadingTenants" [(ngModel)]="tenantId" [items]="tenants" bindLabel="name"
      bindValue="tenant_id" placeholder="Select Tenant" (ngModelChange)="handleSelection($event)">
    </ng-select>
    <ngb-alert class="m-3" [type]="'info'" *ngIf="filteredUserContexts && filteredUserContexts.length === 0">{{ 'This tenant has no users' | translate }}</ngb-alert>
    <ul class="list-group list-group-flush">
      <li class="list-group-item d-flex justify-content-between" *ngFor="let userContext of filteredUserContexts">
        <span>
          {{ userContext.user.firstName }} {{ userContext.user.lastName }}
        </span>
        <ng-toggle onColor="{{model.onColor}}" onText="{{model.onText}}" offColor="{{model.offColor}}" offText="{{model.offText}}"
          [disabled]="model.disabled" [size]="model.size" [value]="isBetaUser(userContext)" (click)="toggleBetaUser(userContext)">
        </ng-toggle>
      </li>
    </ul>
  </div>
</div>
