import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/core/api/api.service';
import { PsaAction, PsaCompany } from 'app/core/store/models/psa-company.model';

@Component({
  selector: 'app-account-action-history-modal',
  templateUrl: './account-action-history.component.html'
})
export class AccountActionHistoryComponent implements OnInit {
  account: PsaCompany;
  actions: PsaAction[] = [];
  loading = true;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.get({
      path: `/psa_companies/${this.account.id}/with_actions`
    }).toPromise().then(x => {
      this.loading = false;
      this.actions = <PsaAction[]>x['psaCompanyActions'];
    });
  }
}
