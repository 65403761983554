import { FormBuilder, Validators } from '@angular/forms';
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
var TariffTableComponent = /** @class */ (function () {
    function TariffTableComponent(fb) {
        this.fb = fb;
        this.tariffChanged = new EventEmitter();
    }
    TariffTableComponent.prototype.ngOnInit = function () {
        if (!this.tariffForm) {
            this.createForm();
            this.onChanges();
        }
    };
    TariffTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes &&
            changes.selectedTemplate &&
            changes.selectedTemplate.currentValue) {
            var tariff = Object.assign({}, changes.selectedTemplate.currentValue);
            tariff = {
                id: tariff.id,
                label: tariff.label,
                parts: tariff.parts.map(function (part) {
                    return {
                        description: part.description,
                        quantity: part.quantity,
                        unitPrice: part.unitPrice,
                        itemTotal: +part.quantity * part.unitPrice
                    };
                }),
                price: tariff.price
            };
            this.createForm();
            for (var index = 1; index < tariff.parts.length; index++) {
                this.addTariffItem();
            }
            this.tariffForm.patchValue({
                id: tariff.id,
                label: tariff.label,
                parts: tariff.parts,
                price: tariff.price
            });
            this.onChanges();
        }
    };
    TariffTableComponent.prototype.onChanges = function () {
        var _this = this;
        this.tariffForm.valueChanges.subscribe(function (tariffFormValue) {
            var value = Object.assign({}, tariffFormValue);
            _this.tariffChanged.emit(value);
        });
    };
    TariffTableComponent.prototype.createForm = function () {
        this.tariffForm = this.fb.group({
            id: [null],
            label: [null, Validators.required],
            parts: this.fb.array([this.createTariffItem()]),
            price: [null]
        });
    };
    TariffTableComponent.prototype.createTariffItem = function () {
        var group = this.fb.group({
            description: [null, Validators.required],
            quantity: [1, Validators.required],
            unitPrice: [null, Validators.required],
            itemTotal: [null]
        });
        return group;
    };
    TariffTableComponent.prototype.addTariffItem = function () {
        var tariffItems = this.tariffForm.controls['parts'];
        tariffItems.push(this.createTariffItem());
    };
    TariffTableComponent.prototype.removeTariffItem = function (index) {
        var tariffItems = this.tariffForm.controls['parts'];
        tariffItems.removeAt(index);
    };
    TariffTableComponent.prototype.updateItemTotal = function (index) {
        var part = this.tariffForm.get('parts').value[index];
        var unitPrice = part['unitPrice'];
        var quantity = part['quantity'];
        var total = unitPrice * quantity;
        var tariffItems = this.tariffForm.controls['parts'];
        tariffItems.at(index).patchValue({
            itemTotal: total
        });
    };
    Object.defineProperty(TariffTableComponent.prototype, "total", {
        get: function () {
            if (!this.tariffForm) {
                return;
            }
            var tariffParts = JSON.parse(JSON.stringify(this.tariffForm.controls['parts'].value.filter(function (part) { return part.quantity && part.unitPrice; })));
            if (!tariffParts || tariffParts.length === 0) {
                return 0;
            }
            if (tariffParts.length === 1) {
                return tariffParts[0].quantity * tariffParts[0].unitPrice;
            }
            return tariffParts
                .map(function (part) { return part.quantity * part.unitPrice; })
                .reduce(function (part1, part2) { return part1 + part2; });
        },
        enumerable: true,
        configurable: true
    });
    TariffTableComponent.prototype.onSubmit = function () {
        // if no lines except last one exist, add one
        // mark all required empty inputs as touched (except last line)
        var tariffItems = this.tariffForm.controls['parts'];
        if (tariffItems.length === 1) {
            this.addTariffItem();
        }
        for (var i = 0; i < tariffItems.length - 1; i++) {
            var group = tariffItems.controls[i];
            group.controls.description.markAsTouched();
            group.controls.quantity.markAsTouched();
            group.controls.unitPrice.markAsTouched();
        }
    };
    return TariffTableComponent;
}());
export { TariffTableComponent };
