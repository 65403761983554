import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../user/user.auth';

@Injectable()
export class DriverGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.getFromStoreOrAPI().pipe(
      map(data => {
        const isSuperAdmin = this.authService.isSuperAdmin(data);
        if (!isSuperAdmin) {
          const isOnlyDriver = this.authService.isOnlyDriver(data);
          if (isOnlyDriver) {
            this.router.navigate(['/driver/tasks']);
          }
          return !isOnlyDriver;
        }
        return isSuperAdmin;
      }),
      catchError(() => of(false)),
    );
  }
}
