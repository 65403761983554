var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/marketplace.types';
import * as MarketPostDemandsActions from '../actions/market-post-demand.actions';
import { ApiService } from '../../api/api.service';
var MarketPostDemandEffects = /** @class */ (function () {
    function MarketPostDemandEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadMarketPostDemands$ = this.actions$.pipe(ofType(types.marketposts.LOAD_MARKETPOSTS_DEMANDS), concatMap(function () { return _this.api.get({
            path: '/market_post_demands'
        }).pipe(map(function (data) { return new MarketPostDemandsActions.LoadMarketPostDemandsSuccess(data); }), catchError(function (error) { return of(new MarketPostDemandsActions.LoadMarketPostDemandsFail(error)); })); }));
        this.createMarketPostDemand$ = this.actions$.pipe(ofType(types.marketposts.CREATE_MARKET_POST_DEMAND), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/market_post_demand_collections', body: payload
        }).pipe(map(function (data) { return new MarketPostDemandsActions.CreateMarketPostDemandSuccess(data); }), catchError(function (error) { return of(new MarketPostDemandsActions.CreateMarketPostDemandFail(error)); })); }));
        this.replyMarketPostDemand$ = this.actions$.pipe(ofType(types.marketposts.REPLY_MARKET_POST_DEMAND), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: "/market_post_demand_replies",
            body: payload.body
        }).pipe(map(function (data) { return new MarketPostDemandsActions.ReplyMarketPostDemandSuccess(data); }), catchError(function (error) { return of(new MarketPostDemandsActions.ReplyMarketPostDemandFail(error)); })); }));
        this.publishMarketPostDemand$ = this.actions$.pipe(ofType(types.marketposts.PUBLISH_MARKET_POST_DEMAND), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/market_post_demands/' + payload.id + '/publish'
        }).pipe(map(function (data) { return new MarketPostDemandsActions.PublishMarketPostDemandSuccess(data); }), catchError(function (error) { return of(new MarketPostDemandsActions.PublishMarketPostDemandFail(error)); })); }));
        this.unpublishMarketPostDemand$ = this.actions$.pipe(ofType(types.marketposts.UNPUBLISH_MARKET_POST_DEMAND), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/market_post_demands/' + payload.id + '/unpublish'
        }).pipe(map(function (data) { return new MarketPostDemandsActions.UnpublishMarketPostDemandSuccess(data); }), catchError(function (error) { return of(new MarketPostDemandsActions.UnpublishMarketPostDemandFail(error)); })); }));
        this.updateMarketPostDemand$ = this.actions$.pipe(ofType(types.marketposts.UPDATE_MARKETPOSTS_DEMAND), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/market_post_demands/' + payload.id, body: payload.body
        }).pipe(map(function (data) { return new MarketPostDemandsActions.UpdateMarketPostDemandSuccess(data); }), catchError(function (error) { return of(new MarketPostDemandsActions.UpdateMarketPostDemandFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostDemandEffects.prototype, "loadMarketPostDemands$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostDemandEffects.prototype, "createMarketPostDemand$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostDemandEffects.prototype, "replyMarketPostDemand$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostDemandEffects.prototype, "publishMarketPostDemand$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostDemandEffects.prototype, "unpublishMarketPostDemand$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], MarketPostDemandEffects.prototype, "updateMarketPostDemand$", void 0);
    return MarketPostDemandEffects;
}());
export { MarketPostDemandEffects };
