<h4 class="mt-3 ml-3 mr-3 mb-2">{{ 'Request details' | translate }}</h4>

<app-cmp-reload-form
  (reloadFormSubmitted)="reloadFormSubmitted($event)"
  (forceApproveRequest)="forceApproveRequest()"
  (cancelRequest)="cancelRequest()"
  [loading]="loading"
  [readonly]="true"
  [reloadRequest]="reloadRequest"
  [forLiner]="forLiner">
</app-cmp-reload-form>

<h4 class="ml-3 mr-3 mt-1 mb-0" *ngIf="reloadRequest && reloadRequest.requestStatus && reloadRequest.history">{{ 'History' | translate }}</h4>
<ngx-datatable
  *ngIf="reloadRequest && reloadRequest.history && reloadRequest.requestStatus"
  class="bootstrap reload-request-history row mt-0 pt-0 ml-3 mr-3"
  [rows]="reloadRequest.history"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="false"
  [rowHeight]="'auto'"
  [selectionType]="undefined"
  [sorts]="[{prop: 'creationDate', dir: 'desc'}]"
>
  <ngx-datatable-column [cellClass]="getStatusClass" name="{{ 'Status' | translate }}" prop="requestStatus">
    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
      <span>{{ formatRequestStatus(value) }}</span>
      <span *ngIf="value === 'declined' && row.requestStatusDetail"> ({{ ('reload.api.message.' + row.requestStatusDetail) | translate }})</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'Date' | translate }}" prop="creationDate">
    <ng-template ngx-datatable-cell-template let-value="value">
      <span>{{ value | date: 'dd-MM-yyyy HH:mm:ss' }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ 'Company' | translate }}" prop="createdBy.name">
  </ngx-datatable-column>
</ngx-datatable>