<div class="card text-center mb-3" *ngIf="hasEnoughCredits()">
  <div class="card-body">
    <div class="mb-2">
      <h2>{{ 'Add credits to your wallet' | translate }}</h2>
      <div class="alert alert-danger text-center mt-3">
        <span>
          {{ 'There are too few credits to start a subscription. You have to purchase credits first before starting a subscription.' | translate }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-cmp-credit-amount [wallet]="wallet"></app-cmp-credit-amount>
      </div>
      <div class="col">
        <app-cmp-reload-credits></app-cmp-reload-credits>
      </div>
    </div>
  </div>
</div>
<div class="card text-center mb-3" *ngIf="!hasActiveSubscription()">
  <div class="card-body">
    <h2 class="mb-3">{{ 'Start a subscription' | translate }}</h2>
    <div class="d-flex justify-content-center">
      <app-cmp-subscription
        [subscription]="subscription"
        [hasEnoughCredits]="hasEnoughCredits()"
        [credits]="wallet?.credits"
        [subscriptionType]="subscriptionType"
        [active]="hasActiveSubscription(subscription)">
      </app-cmp-subscription>
    </div>
  </div>
</div>
