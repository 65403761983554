import { LoadSuppliers, UpdateSupplier } from 'app/core/store/actions/supplier.actions';
import { Store } from '@ngrx/store';
import { OnInit, OnDestroy } from '@angular/core';
import { extract } from 'app/services/i18n.service';
import { ActivatedRoute } from '@angular/router';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import { Actions, ofType } from '@ngrx/effects';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { DeleteBusinessContact } from 'app/core/store/actions/business-contact.actions';
import { DeleteAddress } from 'app/core/store/actions/address.actions';
import { ConfirmActionModalComponent } from '../../shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';
var EditSupplierComponent = /** @class */ (function () {
    function EditSupplierComponent(route, store, modalService, updates$) {
        var _this = this;
        this.route = route;
        this.store = store;
        this.modalService = modalService;
        this.updates$ = updates$;
        this.title = extract('Edit supplier');
        this.route.paramMap.subscribe(function (data) {
            if (data) {
                _this.id = data.get('id');
                _this.store
                    .select(function (state) { return state.crm.suppliers; })
                    .pipe(untilDestroyed(_this))
                    .subscribe(function (supplierState) {
                    if (!supplierState || !supplierState.suppliers) {
                        _this.store.dispatch(new LoadSuppliers());
                        return;
                    }
                    _this.suppliers = supplierState.suppliers.slice();
                    _this.supplier = supplierState.suppliers.find(function (c) { return c.id === _this.id; });
                });
            }
        });
        this.updates$
            .pipe(ofType(SupplierTypes.supplier.LOAD_SUPPLIERS_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (_this.supplier || !_this.id || !_this.suppliers) {
                return;
            }
            _this.supplier = _this.suppliers.find(function (c) { return c.id === _this.id; });
        });
        this.updates$
            .pipe(ofType(SupplierTypes.supplier.UPDATE_SUPPLIER_SUCCEEDED))
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.supplier = _this.suppliers.find(function (c) { return c.id === result['payload'].id; });
            _this.supplier.billingAddress['isLoading'] = false;
            _this.supplier.defaultContact['isLoading'] = false;
        });
    }
    EditSupplierComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store
            .select(function (state) { return state.tms.countries; })
            .pipe(untilDestroyed(this))
            .subscribe(function (countriesState) {
            if (!countriesState) {
                _this.store.dispatch(new LoadCountries());
                return;
            }
            _this.countries = countriesState.slice();
        });
    };
    EditSupplierComponent.prototype.updateSupplier = function () {
        this.store.dispatch(new UpdateSupplier({
            supplierId: this.supplier.id,
            supplier: this.supplier
        }));
    };
    EditSupplierComponent.prototype.handleUpdateBillingAddress = function (addressId) {
        var updatedBillingAddress = this.supplier.addresses.find(function (a) { return a['id'] === addressId; });
        updatedBillingAddress['isLoading'] = true;
        this.supplier.billingAddress = updatedBillingAddress;
        this.updateSupplier();
    };
    EditSupplierComponent.prototype.handleUpdateMainContact = function (supplierId) {
        var updatedDefaultContact = this.supplier.contacts.find(function (c) { return c['id'] === supplierId; });
        updatedDefaultContact['isLoading'] = true;
        this.supplier.defaultContact = updatedDefaultContact;
        this.updateSupplier();
    };
    EditSupplierComponent.prototype.launchNewAddressModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(AddressModalComponent);
        modalRef.result.then(function (result) {
            _this.supplier.addresses.push(result);
            _this.updateSupplier();
        });
    };
    EditSupplierComponent.prototype.launchNewContactModal = function () {
        var _this = this;
        var modalRef = this.modalService.open(ContactModalComponent);
        modalRef.result.then(function (result) {
            _this.supplier.contacts.push(result);
            _this.updateSupplier();
        });
    };
    EditSupplierComponent.prototype.handleDeleteContact = function (contactId) {
        var _this = this;
        var modalRef = this.modalService.open(ConfirmActionModalComponent);
        modalRef.componentInstance.message = extract('Are you sure you want to permanently delete this contact?');
        modalRef.result.then(function () {
            _this.store.dispatch(new DeleteBusinessContact({
                businessId: _this.supplier.id,
                businessContactId: contactId
            }));
        });
    };
    EditSupplierComponent.prototype.handleDeleteAddress = function (addressId) {
        var _this = this;
        var modalRef = this.modalService.open(ConfirmActionModalComponent);
        modalRef.componentInstance.message = extract('Are you sure you want to permanently delete this address?');
        modalRef.result.then(function () {
            _this.store.dispatch(new DeleteAddress({
                businessId: _this.supplier.id,
                addressId: addressId
            }));
        });
    };
    Object.defineProperty(EditSupplierComponent.prototype, "filteredAddresses", {
        get: function () {
            if (!this.supplier) {
                return;
            }
            var billingAddress = this.supplier.billingAddress;
            return this.supplier.addresses.filter(function (address) { return address['id'] !== billingAddress['id']; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditSupplierComponent.prototype, "filteredContacts", {
        get: function () {
            if (!this.supplier || !this.supplier.contacts) {
                return;
            }
            var defaultContact = this.supplier.defaultContact;
            return this.supplier.contacts.filter(function (contact) { return contact['id'] !== defaultContact['id']; });
        },
        enumerable: true,
        configurable: true
    });
    EditSupplierComponent.prototype.ngOnDestroy = function () { };
    return EditSupplierComponent;
}());
export { EditSupplierComponent };
