var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { catchError, concatMap, map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as types from '../types/address.types';
import * as AddressActions from '../actions/address.actions';
import { ApiService } from '../../api/api.service';
var AddressEffects = /** @class */ (function () {
    function AddressEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.updateAddress$ = this.actions$.pipe(ofType(types.address.UPDATE_ADDRESS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/crm/addresses/" + payload.addressId,
            body: payload.body
        }).pipe(map(function (data) { return new AddressActions.UpdateAddressSuccess(data); }), catchError(function (error) { return of(new AddressActions.UpdateAddressFail(error)); })); }));
        this.deleteAddress$ = this.actions$.pipe(ofType(types.address.DELETE_ADDRESS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/crm/addresses/" + payload.addressId
        }).pipe(map(function () { return new AddressActions.DeleteAddressSuccess(payload); }), catchError(function (error) { return of(new AddressActions.DeleteAddressFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AddressEffects.prototype, "updateAddress$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AddressEffects.prototype, "deleteAddress$", void 0);
    return AddressEffects;
}());
export { AddressEffects };
