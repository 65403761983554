/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-board.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./message/message.component.ngfactory";
import * as i3 from "./message/message.component";
import * as i4 from "@angular/common";
import * as i5 from "../../core/admin/tenant-settings/notification-subscriptions/message-board-messages-subscription/message-board-messages-subscription-toggle/message-board-messages-subscription-toggle.component.ngfactory";
import * as i6 from "../../core/admin/tenant-settings/notification-subscriptions/message-board-messages-subscription/message-board-messages-subscription-toggle/message-board-messages-subscription-toggle.component";
import * as i7 from "@ngrx/store";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/router";
import * as i10 from "./message-input.component/message-input.component.ngfactory";
import * as i11 from "./message-input.component/message-input.component";
import * as i12 from "@ngrx/effects";
import * as i13 from "../../services/toast.service";
import * as i14 from "./message-board.component";
import * as i15 from "@angular/forms";
var styles_MessageBoardComponent = [i0.styles];
var RenderType_MessageBoardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageBoardComponent, data: {} });
export { RenderType_MessageBoardComponent as RenderType_MessageBoardComponent };
function View_MessageBoardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cmp-message", [], null, [[null, "replySubmited"], [null, "replying"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("replySubmited" === en)) {
        var pd_0 = (_co.submitReply($event) !== false);
        ad = (pd_0 && ad);
    } if (("replying" === en)) {
        var pd_1 = (_co.setReplying($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MessageComponent_0, i2.RenderType_MessageComponent)), i1.ɵdid(2, 49152, null, 0, i3.MessageComponent, [], { message: [0, "message"] }, { replySubmited: "replySubmited", replying: "replying" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MessageBoardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "row"], ["id", "message-board"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "ml-1": 0, "mt-3": 1, "mr-1": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 19, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 18, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "h4", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "d-flex justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-message-board-messages-subscription-toggle", [], null, null, null, i5.View_MessageBoardMessagesSubscriptionToggleComponent_0, i5.RenderType_MessageBoardMessagesSubscriptionToggleComponent)), i1.ɵdid(9, 638976, null, 0, i6.MessageBoardMessagesSubscriptionToggleComponent, [i7.Store], { messageBoardMessageSubscription: [0, "messageBoardMessageSubscription"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "ml-1 mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "i", [["class", "far"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "card-body pre-scrollable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-cmp-message-input", [], null, [[null, "messageSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("messageSubmitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MessageInputComponent_0, i10.RenderType_MessageInputComponent)), i1.ɵdid(20, 180224, null, 0, i11.MessageInputComponent, [i12.Actions, i13.ToastService], { loading: [0, "loading"] }, { messageSubmitted: "messageSubmitted" }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MessageBoardComponent_1)), i1.ɵdid(22, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; var currVal_1 = _ck(_v, 3, 0, (_co.url !== "marketplace"), (_co.url !== "marketplace"), (_co.url !== "marketplace")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.messageBoardMessageSubscription; _ck(_v, 9, 0, currVal_2); var currVal_6 = i1.ɵinlineInterpolate(1, "", ((_co.url === "marketplace") ? "/marketplace/chat" : "/marketplace"), ""); _ck(_v, 14, 0, currVal_6); var currVal_7 = "far"; var currVal_8 = ((_co.url === "marketplace") ? "fa-expand-alt" : "fa-compress-alt"); _ck(_v, 17, 0, currVal_7, currVal_8); var currVal_9 = _co.loading; _ck(_v, 20, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform(_co.messages$)); _ck(_v, 22, 0, currVal_10); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("Message Board")); _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 14).target; var currVal_5 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_4, currVal_5); }); }
export function View_MessageBoardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-message-board", [], null, null, null, View_MessageBoardComponent_0, RenderType_MessageBoardComponent)), i1.ɵdid(1, 245760, null, 0, i14.MessageBoardComponent, [i15.FormBuilder, i7.Store, i12.Actions, i9.ActivatedRoute, i13.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageBoardComponentNgFactory = i1.ɵccf("app-cmp-message-board", i14.MessageBoardComponent, View_MessageBoardComponent_Host_0, {}, {}, []);
export { MessageBoardComponentNgFactory as MessageBoardComponentNgFactory };
