/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./about.component";
var styles_AboutComponent = [];
var RenderType_AboutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AboutComponent, data: {} });
export { RenderType_AboutComponent as RenderType_AboutComponent };
export function View_AboutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Hakka"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Version "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "version"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["0.4.4"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\nAngular ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version.full; _ck(_v, 7, 0, currVal_0); }); }
export function View_AboutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-about", [], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i0.ɵdid(1, 49152, null, 0, i1.AboutComponent, [], null, null)], null, null); }
var AboutComponentNgFactory = i0.ɵccf("app-cmp-about", i1.AboutComponent, View_AboutComponent_Host_0, {}, {}, []);
export { AboutComponentNgFactory as AboutComponentNgFactory };
