export const drivers = {
  LOAD_DRIVERS: '[Drivers] Load',
  LOAD_DRIVERS_SUCCEEDED: '[Drivers] Load Success',
  LOAD_DRIVERS_FAILED: '[Drivers] Load Fail',
  CREATE_DRIVER: '[Driver] Create',
  CREATE_DRIVER_SUCCEEDED: '[Driver] Create Success',
  CREATE_DRIVER_FAILED: '[Driver] Create Fail',
  UPDATE_DRIVER: '[Driver] Update',
  UPDATE_DRIVER_SUCCEEDED: '[Driver] Update Success',
  UPDATE_DRIVER_FAILED: '[Driver] Update Fail',
  ENABLE_DRIVER: '[Driver] Enable',
  ENABLE_DRIVER_SUCCEEDED: '[Driver] Enable Success',
  ENABLE_DRIVER_FAILED: '[Driver] Enable Fail',
  DISABLE_DRIVER: '[Driver] Disable',
  DISABLE_DRIVER_SUCCEEDED: '[Driver] Disable Success',
  DISABLE_DRIVER_FAILED: '[Driver] Disable Fail',
  DELETE_DRIVER: '[Driver] Delete',
  DELETE_DRIVER_SUCCEEDED: '[Driver] Delete Success',
  DELETE_DRIVER_FAILED: '[Driver] Delete Fail',
  CLEAR_DRIVERS: '[Drivers] Clear',
  UNCLEAR_DRIVERS: '[Drivers] Unclear',
  LOAD_DRIVER_TASKS: '[Driver] Load Tasks',
  LOAD_DRIVER_TASKS_SUCCEEDED: '[Driver] Load Tasks Success',
  LOAD_DRIVER_TASKS_FAILED: '[Driver] Load Tasks Fail'
};
