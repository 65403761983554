import { Component, OnInit, OnDestroy } from '@angular/core';
import { Supplier } from 'app/core/store/models/supplier.model';
import { TaxRate } from 'app/core/store/models/tax-rate.model';
import { Transport } from 'app/core/store/models/transport.model';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Router } from '@angular/router';
import { LoadTaxRates } from 'app/core/store/actions/tax-rate.actions';
import { LoadSuppliers } from 'app/core/store/actions/supplier.actions';
import { ToastService } from 'app/services/toast.service';
import {
  LoadPurchaseOrderableTransports,
  LoadNextPurchaseOrderableTransportsPage
} from 'app/core/store/actions/transports.actions';
import { extract } from 'app/services/i18n.service';
import { CreatePurchaseOrder, LoadPurchaseOrders } from 'app/core/store/actions/purchase-order.actions';
import { Actions, ofType } from '@ngrx/effects';
import * as PurchaseOrderTypes from 'app/core/store/types/purchase-order.types';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import * as TaxRateTypes from 'app/core/store/types/tax-rate.types';
import * as TransportTypes from 'app/core/store/types/transport.types';
import { DownloadService } from 'app/services/download.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-purchase-order',
  templateUrl: './new-purchase-order.component.html',
})
export class NewPurchaseOrderComponent implements OnInit, OnDestroy {
  suppliers: Supplier[];
  isLoadingSuppliers: boolean;
  taxRates: TaxRate[];
  isLoadingTaxRates: boolean;
  transports: Transport[];
  filteredTransports: Transport[];
  selectedTransports: string[] = [];
  query: string;
  since?: string;
  until?: string;
  nextPage: number;
  selectedSupplierId: string;
  isLoading: boolean;
  isLoadingTransports: boolean;
  downloadPDFWhenReady: any;

  constructor(
    private store: Store<State>,
    private router: Router,
    private updates$: Actions,
    private toastr: ToastService,
    private downloadService: DownloadService
  ) { }

  ngOnInit() {
    this.store.dispatch(new LoadPurchaseOrderableTransports());
    this.store
      .select(state => state.crm.suppliers)
      .pipe(untilDestroyed(this))
      .subscribe(suppliersState => {
        if (!suppliersState || !suppliersState.suppliers) {
          this.store.dispatch(new LoadSuppliers());
          this.isLoadingSuppliers = true;
          return;
        }
        this.suppliers = suppliersState.suppliers;
        this.isLoadingSuppliers = false;
      });
    this.store
      .select(state => state.crm.taxRates)
      .pipe(untilDestroyed(this))
      .subscribe(taxRatesState => {
        if (!taxRatesState || !taxRatesState.taxRates) {
          this.store.dispatch(new LoadTaxRates());
          this.isLoadingTaxRates = true;
          return;
        }
        this.taxRates = taxRatesState.taxRates;
        this.isLoadingTaxRates = false;
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.purchaseOrderableTransports &&
          state.crm.purchaseOrderableTransports.purchaseOrderableTransports
        ) {
          return state.crm.purchaseOrderableTransports
            .purchaseOrderableTransports;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(purchaseOrderableTransportsState => {
        if (!purchaseOrderableTransportsState) {
          return;
        }
        this.transports = [...purchaseOrderableTransportsState];

        // Filter out selected transports so they can't be selected twice
        if (this.selectedTransports.length > 0) {
          this.selectedTransports.forEach(transportId => {
            this.filteredTransports = this.transports.filter(
              t => t.id !== transportId
            );
          });
        } else {
          this.filteredTransports = [...purchaseOrderableTransportsState];
        }
        this.toastr.clear();
      });
    this.store
      .select(state => {
        if (
          state &&
          state.crm &&
          state.crm.purchaseOrderableTransports &&
          state.crm.purchaseOrderableTransports
            .nextPurchaseOrderableTransportPage
        ) {
          return state.crm.purchaseOrderableTransports
            .nextPurchaseOrderableTransportPage;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(nextPage => {
        this.nextPage = nextPage;
      });

    this.updates$
      .pipe(ofType(PurchaseOrderTypes.purchaseOrder.CREATE_PURCHASE_ORDER_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(PurchaseOrderTypes.purchaseOrder.CREATE_PURCHASE_ORDER_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.store.dispatch(new LoadPurchaseOrders());
        this.toastr.showSuccess({
          message: extract('Purchase order created successfully!')
        });
        if (this.downloadPDFWhenReady) {
          this.downloadService.downloadPDF(
            `/crm/purchase_order/download/${response['payload'].id}`,
            response['payload'].number,
          ).then(() => {
            this.router.navigateByUrl('/crm/purchase-orders');
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          });
        }
        this.router.navigateByUrl('/crm/purchase-orders');
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.LOAD_SUPPLIERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load suppliers')
        });
        this.isLoadingSuppliers = false;
      });
    this.updates$
      .pipe(ofType(TaxRateTypes.taxRate.LOAD_TAX_RATES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load tax rates')
        });
        this.isLoadingTaxRates = false;
      });
    this.updates$
      .pipe(ofType(TransportTypes.transports.LOAD_INVOICEABLE_TRANSPORTS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load transports')
        });
        this.isLoadingTransports = false;
      });
  }
  handleSearchTransports(payload: { query?: string, since?: string, until?: string }) {
    this.query = payload.query;
    this.since = payload.since;
    this.until = payload.until;
    this.filterTransports(this.selectedSupplierId, this.query, this.since, this.until);
  }

  handleSelectSupplier(supplierId: string | null) {
    // Clear selected transports if supplier is deselected
    if (!supplierId) {
      this.selectedTransports.forEach(transportId =>
        this.handleRemoveTransport(transportId)
      );
      this.selectedTransports = [];
    }
    this.selectedSupplierId = supplierId;
    this.filterTransports(this.selectedSupplierId, this.query, this.since, this.until);
  }

  filterTransports(supplierId: string, query: string, since?: string, until?: string) {
    this.store.dispatch(
      new LoadPurchaseOrderableTransports({
        query: query,
        since: since,
        until: until,
        suppliers: supplierId
      })
    );
  }

  handleSelectTransport(transportId: string) {
    this.selectedTransports.push(transportId);
    this.filteredTransports = this.filteredTransports.filter(t => t.id !== transportId);
  }
  handleRemoveTransport(transportId: string) {
    this.selectedTransports.filter(t => t !== transportId);
    const transport = this.transports.find(t => t.id === transportId);

    // Only insert transport if it is not already present
    if (transport && !this.filteredTransports.find(t => t.id === transportId)) {
      this.filteredTransports.unshift(transport);
    }
  }

  loadNextTransportsPage(event) {
    this.store.dispatch(
      new LoadNextPurchaseOrderableTransportsPage({
        page: this.nextPage,
        query: this.query,
        since: this.since,
        until: this.until,
        suppliers: this.selectedSupplierId
      })
    );
    this.toastr.showInfoLoading({
      message: extract('Loading extra transports...')
    });
  }

  createAndDownloadAsPDF(purchaseOrderFormValue) {
    this.createNewPurchaseOrder(purchaseOrderFormValue);
    this.downloadPDFWhenReady = true;
  }

  createNewPurchaseOrder(purchaseOrderFormValue) {
    this.isLoading = true;
    this.store.dispatch(new CreatePurchaseOrder(purchaseOrderFormValue));
  }

  ngOnDestroy() { }
}
