var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApiService } from 'app/core/api/api.service';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
import * as moment from 'moment';
var ContainerMeasurementFormComponent = /** @class */ (function () {
    function ContainerMeasurementFormComponent(fb, api, store, toastService) {
        var _this = this;
        this.fb = fb;
        this.api = api;
        this.store = store;
        this.toastService = toastService;
        this.formSubmitted = new EventEmitter();
        this.loading = false;
        this.store.select(function (state) { return state.marketplace.liners; })
            .pipe(untilDestroyed(this))
            .subscribe(function (liners) {
            if (!liners) {
                _this.store.dispatch(new LoadLiners());
                return;
            }
            _this.liners = liners;
        });
        this.api.get({
            path: "/locations?locationType.code=WEIGHT&order[name]=asc"
        }).subscribe(function (data) {
            _this.weighingSites = data['hydra:member'];
        });
        this.api.get({
            path: "/locations?locationType.code=TERMINAL&order[name]=asc"
        }).subscribe(function (data) {
            _this.terminals = data['hydra:member'];
        });
    }
    ContainerMeasurementFormComponent.prototype.ngOnInit = function () {
        if (!this.form) {
            this.createForm();
        }
    };
    ContainerMeasurementFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.data && changes.data.currentValue) {
            if (!this.form) {
                this.createForm();
            }
            var date = moment.utc(changes.data.currentValue.announcedOn).toObject();
            this.form.patchValue(__assign({}, changes.data.currentValue, { liner: (changes.data.currentValue.liner ? changes.data.currentValue.liner['@id'] : null), terminal: (changes.data.currentValue.terminal ? changes.data.currentValue.terminal['@id'] : null), site: (changes.data.currentValue.site ? changes.data.currentValue.site['@id'] : null), announcedOn: { year: date.years, month: date.months, day: date.date }, announcedOnTime: { hour: date.hours, minute: date.minutes } }));
        }
    };
    ContainerMeasurementFormComponent.prototype.ngOnDestroy = function () { };
    ContainerMeasurementFormComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            announcedOn: [null],
            announcedOnTime: [null],
            bookingReference: [null],
            liner: [null],
            containerNumber: [null],
            data: [null],
            measurementKey: [null],
            sealNumber: [null],
            site: [null],
            terminal: [null],
            weight: [null],
        });
    };
    ContainerMeasurementFormComponent.prototype.onSubmit = function () {
        if (this.form.invalid) {
            return;
        }
        this.formSubmitted.emit(__assign({}, this.form.value, { announcedOn: __assign({}, this.form.value.announcedOn, this.form.value.announcedOnTime) }));
    };
    ContainerMeasurementFormComponent.prototype.sendToAvantida = function () {
        var _this = this;
        this.api.post({
            path: "/container_measurements/send/" + this.data.id + "/avantida",
        }).subscribe(function (data) {
            if (data['ok']) {
                _this.toastService.showSuccess({ title: 'Success', message: 'Data sent to Avantida' });
            }
            else {
                _this.toastService.showDanger({ title: 'Error', message: 'Something went wrong' });
            }
        });
    };
    return ContainerMeasurementFormComponent;
}());
export { ContainerMeasurementFormComponent };
