import { LoadContacts } from '../../store/actions/contacts.actions';
import { ApiService } from '../../api/api.service';
import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { State } from '../../store/store.model';
import { UserContext } from '../../store/models/user-contexts.model';
import { Role, Roles } from '../../store/models/roles.model';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-beta-users',
  templateUrl: './manage-beta-users.component.html',
})
export class ManageBetaUsersComponent implements OnInit, OnDestroy {
  ROLES = Roles;
  tenants: any[];
  userContexts: UserContext[];
  tenantId: string;
  isLoadingTenants = true;
  model: any = {
    onColor: 'primary',
    offColor: 'secondary',
    onText: 'Beta User',
    offText: 'Not Beta',
    disabled: false,
    size: '',
    value: false
  };
  filteredUserContexts: UserContext[];
  constructor(
    private store: Store<State>,
    private apiService: ApiService,
    private toastr: ToastService
  ) { }

  ngOnInit() {
    this.store.dispatch(new LoadContacts());
    this.store.select(state => state.contacts)
      .pipe(untilDestroyed(this))
      .subscribe(tenants => {
        if (tenants) {
          this.tenants = tenants;
          this.isLoadingTenants = false;

          if (this.tenants.length === 0) {
            return;
          }
          this.tenantId = tenants[0].tenant_id;
          this.apiService
            .get({ path: `/all_user_contexts?tenant_id=${this.tenantId}` })
            .subscribe((userContexts: UserContext[]) => {
              this.userContexts = userContexts['hydra:member'];
              this.filteredUserContexts = [...this.userContexts];
            });
        }
      });
  }

  ngOnDestroy() { }

  /**
   * Filter usercontext on selected tenant
   * @param tenant selected tenant object
   */
  handleSelection(tenant_id) {
    this.apiService
      .get({ path: `/all_user_contexts?tenant_id=${tenant_id}` })
      .subscribe((userContexts: UserContext[]) => {
        this.filteredUserContexts = [...userContexts['hydra:member']];
      });
  }

  /**
   * Checks if the userContext has the beta user role
   * @param userContext
   * @returns boolean
   */
  isBetaUser(userContext): boolean {
    return userContext.roles.some(
      (role: Role) => role.code === this.ROLES.BETA_USER
    );
  }

  /**
   * Adds or removes the beta user role from the userContext
   * @param userContext
   */
  toggleBetaUser(userContext): void {
    if (this.isBetaUser(userContext)) {
      // Remove beta user role
      this.apiService
        .put({
          path: `/user_contexts/${userContext.id}/force_remove_role/BETA_USER`
        })
        .subscribe(
          response => {
            this.toastr.showSuccess({
              message: 'Removed beta role from user'
            });
          },
          error => {
            this.toastr.showDanger({
              title: 'Something went wrong',
              message: 'Failed to remove beta role to user'
            });
          }
        );
    } else {
      // Add beta user role
      this.apiService
        .post({
          path: `/user_contexts/${userContext.id}/force_add_role/BETA_USER`
        })
        .subscribe(
          response => {
            this.toastr.showSuccess({
              message: 'Added beta role to user'
            });
          },
          error => {
            this.toastr.showDanger({
              title: 'Something went wrong',
              message: 'Failed to add beta role to user'
            });
          }
        );
    }
  }
}
