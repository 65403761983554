import { Action } from '@ngrx/store';
import * as types from '../types/purchase-order.types';

export class LoadPurchaseOrders implements Action {
  readonly type = types.purchaseOrder.LOAD_PURCHASE_ORDERS;
  constructor(public payload?: any) { }
}

export class LoadPurchaseOrdersFail implements Action {
  readonly type = types.purchaseOrder.LOAD_PURCHASE_ORDERS_FAILED;
  constructor(public payload: any) { }
}

export class LoadPurchaseOrdersSuccess implements Action {
  readonly type = types.purchaseOrder.LOAD_PURCHASE_ORDERS_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadFilteredPurchaseOrders implements Action {
  readonly type = types.purchaseOrder.LOAD_FILTERED_PURCHASE_ORDERS;
  constructor(public payload?: any) { }
}

export class LoadFilteredPurchaseOrdersFail implements Action {
  readonly type = types.purchaseOrder.LOAD_FILTERED_PURCHASE_ORDERS_FAILED;
  constructor(public payload: any) { }
}

export class LoadFilteredPurchaseOrdersSuccess implements Action {
  readonly type = types.purchaseOrder.LOAD_FILTERED_PURCHASE_ORDERS_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreatePurchaseOrder implements Action {
  readonly type = types.purchaseOrder.CREATE_PURCHASE_ORDER;
  constructor(public payload?: any) { }
}

export class CreatePurchaseOrderFail implements Action {
  readonly type = types.purchaseOrder.CREATE_PURCHASE_ORDER_FAILED;
  constructor(public payload: any) { }
}

export class CreatePurchaseOrderSuccess implements Action {
  readonly type = types.purchaseOrder.CREATE_PURCHASE_ORDER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class DeletePurchaseOrder implements Action {
  readonly type = types.purchaseOrder.DELETE_PURCHASE_ORDER;
  constructor(public payload: { purchaseOrderId: string }) { }
}

export class DeletePurchaseOrderFail implements Action {
  readonly type = types.purchaseOrder.DELETE_PURCHASE_ORDER_FAILED;
  constructor(public payload: any) { }
}

export class DeletePurchaseOrderSuccess implements Action {
  readonly type = types.purchaseOrder.DELETE_PURCHASE_ORDER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class UpdatePurchaseOrder implements Action {
  readonly type = types.purchaseOrder.UPDATE_PURCHASE_ORDER;
  constructor(
    public payload?: {
      purchaseOrderId: string;
      body: any;
    }
  ) { }
}

export class UpdatePurchaseOrderFail implements Action {
  readonly type = types.purchaseOrder.UPDATE_PURCHASE_ORDER_FAILED;
  constructor(public payload: any) { }
}

export class UpdatePurchaseOrderSuccess implements Action {
  readonly type = types.purchaseOrder.UPDATE_PURCHASE_ORDER_SUCCEEDED;
  constructor(public payload: any) { }
}

export class ClearPurchaseOrders implements Action {
  readonly type = types.purchaseOrder.CLEAR;
  constructor(public payload?: any) { }
}

export type All =
  | LoadPurchaseOrders
  | LoadPurchaseOrdersFail
  | LoadPurchaseOrdersSuccess
  | LoadFilteredPurchaseOrders
  | LoadFilteredPurchaseOrdersFail
  | LoadFilteredPurchaseOrdersSuccess
  | CreatePurchaseOrder
  | CreatePurchaseOrderSuccess
  | CreatePurchaseOrderFail
  | DeletePurchaseOrder
  | DeletePurchaseOrderSuccess
  | DeletePurchaseOrderFail
  | UpdatePurchaseOrder
  | UpdatePurchaseOrderSuccess
  | UpdatePurchaseOrderFail
  | ClearPurchaseOrders;
