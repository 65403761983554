import Map from 'ol/map';
import Point from 'ol/geom/point';
import Style from 'ol/style/style';
import Fill from 'ol/style/fill';
import LineString from 'ol/geom/linestring';
import Circle from 'ol/style/circle';
import Stroke from 'ol/style/stroke';
import Feature from 'ol/feature';
import Collection from 'ol/collection';
import VectorLayer from 'ol/layer/vector';
import VectorSource from 'ol/source/vector';
import ClusterSource from 'ol/source/cluster';
import Text from 'ol/style/text';
import proj from 'ol/proj';
import extent from 'ol/extent';
import XYZ from 'ol/source/xyz';
import TileLayer from 'ol/layer/tile';
import View from 'ol/view';
import * as arc from './arc';
import { Subject } from 'rxjs';

/**
 * Quick interface to set what to pass to a new vector layer
 *
 * @interface VectorLayerOptions
 */
interface VectorLayerOptions {
  id: string;
  source: VectorSource;
  style: (feature: Feature, resolution: number) => any;
  updateWhileAnimating: boolean;
  useSpatialIndex: boolean;
}

/**
 * Service class that renders a map, has utility functions to add layers and stuff.
 * Uses openlayers a lot
 *
 * @export
 * @class HakkaMap
 */
export class HakkaMap {
  map: Map;
  layers: any[];
  sources: VectorSource[];
  featureCollection: Collection = new Collection();
  arcCollection: Collection = new Collection();
  cachedStyles: {
    markers?: Style;
    arc?: Style;
    clusters?: Style[];
  };
  clickCoordinates = new Subject();

  constructor(target: string) {
    //  Setup initial variables
    this.layers = [];
    this.sources = [];

    this.cachedStyles = {
      clusters: []
    };

    this.sources['markers'] = new VectorSource({
      features: this.featureCollection
    });

    this.sources['clusters'] = new ClusterSource({
      distance: 50,
      source: this.sources['markers']
    });

    this.sources['arcs'] = new VectorSource({
      features: this.arcCollection
    });

    // Create the map
    this.map = new Map({
      target: target,
      layers: [],
      view: new View({
        projection: 'EPSG:3857',
        center: proj.fromLonLat([6.689154, 51.200025]),
        maxZoom: 22,
        minZoom: 0,
        zoom: 6,
        zoomFactor: 2
      })
    });

    this.map.on('singleclick', e => this.setClickCoordinates(e));
    // The base layer
    this.addBaseLayer();

    // The arcs layer
    this.addVectorLayer({
      id: 'arcs',
      updateWhileAnimating: false,
      style: feature => {
        if (this.cachedStyles.arc) {
          return this.cachedStyles.arc;
        } else {
          const style = new Style({
            stroke: new Stroke({
              color: (getComputedStyle && getComputedStyle(document.body)
                      ? getComputedStyle(document.body).getPropertyValue('--primary')
                      : null) || '#72d207',
              width: 3
            })
          });
          this.cachedStyles.arc = style;
          return style;
        }
      },
      source: this.sources['arcs'],
      useSpatialIndex: false
    });

    this.addVectorLayer({
      id: 'markers',
      updateWhileAnimating: true,
      style: (feature: Feature) => {
        const size = feature.get('features').length;
        let featureStyle = this.cachedStyles.clusters[size];
        if (!featureStyle) {
          if (size > 1) {
            featureStyle = new Style({
              image: new Circle({
                radius: 10,
                stroke: new Stroke({
                  color: '#fff'
                }),
                fill: new Fill({
                  color: '#3399CC'
                })
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: '#fff'
                })
              })
            });
          } else {
            featureStyle = new Style({
              image: new Circle({
                radius: 10,
                stroke: new Stroke({
                  color: '#fff'
                }),
                fill: new Fill({
                  color: '#CC9933'
                })
              })
            });
          }
          this.cachedStyles.clusters[size] = featureStyle;
          return featureStyle;
        } else {
          return featureStyle;
        }
      },
      source: this.sources['clusters'],
      useSpatialIndex: true
    });
  }

  addBaseLayer() {
    const baseLayer = new TileLayer({
      id: 'base',
      updateWhileAnimating: true,
      wrapX: false,
      source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      })
    });

    this.addLayer('base', baseLayer);
  }

  addVectorLayer(options: VectorLayerOptions) {
    const layer = new VectorLayer(options);
    this.addLayer(options.id, layer);
  }

  addLayer(id: string, layer: any) {
    this.layers[id] = layer;
    this.map.addLayer(this.layers[id]);
  }

  /**
   * Returns a layer
   *
   * @param {string} id
   * @returns
   *
   * @memberOf HakkaMap
   */
  getLayerById(id: string) {
    return this.layers[id];
  }

  /**
   * Gets a bunch of features and calls the drawArc function between them
   *
   * @param {Feature[]} features The list of features
   *
   * @memberOf HakkaMap
   */
  connectFeatures(features: any[]) {
    if (features.length > 0) {
      for (let i = 0; i < features.length - 1; i++) {
        const current = features[i];
        const next = features[i + 1];
        return this.drawArc(current, next);
      }
    }
  }

  setClickCoordinates(evt) {
    const coord = proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    this.clickCoordinates.next(coord);
  }

  /**
   * Gets a coordinates object and id and returns an ol.Feature
   * Gets a bunch of features and calls the drawArc function between them
   *
   * @param {any} coordinates
   * @param {any} id
   * @returns Feature
   *
   * @memberOf HakkaMap
   */
  generateFeature(coordinates, id): Feature {
    if (coordinates) {
      const point = new Point(
        proj.transform(
          [Number(coordinates.lon), Number(coordinates.lat)],
          'EPSG:4326',
          'EPSG:3857'
        )
      );
      return new Feature({
        geometry: point,
        id: id
      });
    }
  }

  /**
   * Draws an arc between two points
   *
   * @param {ol.coordinate} start
   * @param {ol.coordinate} end
   *
   * @memberOf HakkaMap
   */
  drawArc(start, end) {
    const arcs = [];
    if (start && end) {
      // create an arc circle between the two locations
      const arcGenerator = new arc.GreatCircle(
        { x: start.lon, y: start.lat },
        { x: end.lon, y: end.lat },
        {}
      );

      const arcLine = arcGenerator.Arc(100, { offset: 10 });
      if (arcLine.geometries.length === 1) {
        const line = new LineString(arcLine.geometries[0].coords);
        line.transform(proj.get('EPSG:4326'), proj.get('EPSG:3857'));

        const feature = new Feature({
          geometry: line,
          finished: false
        });

        // add the feature with a delay so that the animation
        // for all features does not start at the same time
        arcs.push(feature);
      }
    }
    return arcs;
  }

  /**
   * Gets the top left coordinate of the current view
   *
   * @returns ol.coordinate
   *
   * @memberOf HakkaMap
   */
  getTopLeft() {
    return proj.toLonLat(
      extent.getTopLeft(this.map.getView().calculateExtent())
    );
  }

  /**
   * Gets the bottom right coordinate of the current view
   *
   * @returns ol.coordinate
   *
   * @memberOf HakkaMap
   */
  getBottomRight() {
    return proj.toLonLat(
      extent.getBottomRight(this.map.getView().calculateExtent())
    );
  }

  /**
   *
   * @param lon: Number | String
   * @param lat: Number | String
   * @param zoomLevel: Number
   */
  setCenter(lon: number | string, lat: number | string, zoomLevel: number) {
    this.map
      .getView()
      .setCenter(
        proj.transform([Number(lon), Number(lat)], 'EPSG:4326', 'EPSG:3857')
      );
    this.map.getView().setZoom(zoomLevel);
  }

  /**
   * Fits the markers on the map, re-centering and zooming as much as possible
   */
  fitBounds() {
    const markers: VectorSource = this.sources['markers'];
    this.map.getView().fit(markers.getExtent(), this.map.getSize());
  }
}
