/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datepicker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./datepicker.component";
var styles_DatepickerComponent = [i0.styles];
var RenderType_DatepickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatepickerComponent, data: {} });
export { RenderType_DatepickerComponent as RenderType_DatepickerComponent };
function View_DatepickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "ngb-datepicker", [["class", "dropdown-menu show p-0"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "select"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        _co.onFromDateSelection($event);
        var pd_0 = ((_co.visibleFrom = false) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.fromDateModel = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NgbDatepicker_0, i2.RenderType_NgbDatepicker)), i1.ɵprd(512, null, i3.ɵs, i3.ɵs, [i3.NgbCalendar, i3.NgbDatepickerI18n]), i1.ɵprd(512, null, i3.ɵt, i3.ɵt, [i3.ɵs, i3.NgbCalendar]), i1.ɵdid(4, 4964352, null, 0, i3.NgbDatepicker, [i3.ɵt, i3.ɵs, i3.NgbCalendar, i3.NgbDatepickerI18n, i3.NgbDatepickerConfig, i1.ChangeDetectorRef, i1.ElementRef, i3.NgbDateAdapter, i1.NgZone], null, { select: "select" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.NgbDatepicker]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_7 = _co.fromDateModel; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DatepickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0-\u00A0"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "pointer"], ["data-cy", "dateTo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.visibleFrom = false;
        var pd_0 = ((_co.visibleTo = !_co.visibleTo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.toDate, "dd MMM yyyy")); _ck(_v, 3, 0, currVal_0); }); }
function View_DatepickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "input-group offset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "ngb-datepicker", [["class", "dropdown-menu show p-0"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "select"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        _co.onToDateSelection($event);
        var pd_0 = ((_co.visibleTo = false) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.toDateModel = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NgbDatepicker_0, i2.RenderType_NgbDatepicker)), i1.ɵprd(512, null, i3.ɵs, i3.ɵs, [i3.NgbCalendar, i3.NgbDatepickerI18n]), i1.ɵprd(512, null, i3.ɵt, i3.ɵt, [i3.ɵs, i3.NgbCalendar]), i1.ɵdid(4, 4964352, null, 0, i3.NgbDatepicker, [i3.ɵt, i3.ɵs, i3.NgbCalendar, i3.NgbDatepickerI18n, i3.NgbDatepickerConfig, i1.ChangeDetectorRef, i1.ElementRef, i3.NgbDateAdapter, i1.NgZone], null, { select: "select" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.NgbDatepicker]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_7 = _co.toDateModel; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DatepickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "sticky-dates d-flex justify-content-between align-items-center"], ["id", "datepicker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.visibleFrom = false;
        _co.visibleTo = false;
        var pd_0 = (_co.subtractDay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "far fa-fw fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "pointer"], ["data-cy", "date"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.visibleTo = false;
        var pd_0 = ((_co.visibleFrom = !_co.visibleFrom) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_2)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatepickerComponent_3)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.visibleFrom = false;
        _co.visibleTo = false;
        var pd_0 = (_co.addDay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "far fa-fw fa-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.visibleFrom; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.toDate; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.visibleTo; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.today, "dd MMM yyyy")); _ck(_v, 7, 0, currVal_0); }); }
export function View_DatepickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-datepicker", [], null, null, null, View_DatepickerComponent_0, RenderType_DatepickerComponent)), i1.ɵdid(1, 114688, null, 0, i6.DatepickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DatepickerComponentNgFactory = i1.ɵccf("app-datepicker", i6.DatepickerComponent, View_DatepickerComponent_Host_0, { today: "today", toDate: "toDate", toDateOffset: "toDateOffset" }, { dayChange: "dayChange" }, []);
export { DatepickerComponentNgFactory as DatepickerComponentNgFactory };
