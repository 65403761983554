import { PurchaseOrder } from '../models/payment-note.model';
import * as types from '../types/purchase-order.types';
import * as PurchaseOrderActions from '../actions/purchase-order.actions';

export interface PurchaseOrdersState {
  purchaseOrders: PurchaseOrder[];
  filteredPurchaseOrders: PurchaseOrder[];
  totalFilteredPurchaseOrders: number;
}

const INITIAL_STATE = {
  purchaseOrders: null,
  filteredPurchaseOrders: null,
  totalFilteredPurchaseOrders: null
};

export function PurchaseOrdersReducer(
  state = INITIAL_STATE,
  action: PurchaseOrderActions.All
): PurchaseOrdersState {
  switch (action.type) {
    case types.purchaseOrder.LOAD_PURCHASE_ORDERS_SUCCEEDED:
      state.purchaseOrders = [...action.payload['hydra:member']];
      state.filteredPurchaseOrders = [...action.payload['hydra:member']];
      state.totalFilteredPurchaseOrders = action.payload['hydra:totalItems'];
      return Object.assign({}, state);
    case types.purchaseOrder.LOAD_FILTERED_PURCHASE_ORDERS_SUCCEEDED:
      state.filteredPurchaseOrders = [...action.payload['hydra:member']];
      state.totalFilteredPurchaseOrders = action.payload['hydra:totalItems'];
      return Object.assign({}, state);
    case types.purchaseOrder.UPDATE_PURCHASE_ORDER_SUCCEEDED:
      if (!state || !state.purchaseOrders || !state.filteredPurchaseOrders) {
        return state;
      }
      let purchaseOrders = [...state.purchaseOrders];
      let filteredPurchaseOrders = [...state.filteredPurchaseOrders];

      const purchaseOrderIndex = purchaseOrders.findIndex(
        i => i.id === action.payload.id
      );
      const tempPurchaseOrder = Object.assign(
        {},
        state.purchaseOrders[purchaseOrderIndex],
        action.payload
      );
      purchaseOrders[purchaseOrderIndex] = tempPurchaseOrder;
      const filteredPurchaseOrderIndex = filteredPurchaseOrders.findIndex(
        i => i.id === action.payload.id
      );
      const tempFilteredPurchaseOrder = Object.assign(
        {},
        filteredPurchaseOrders[filteredPurchaseOrderIndex],
        action.payload
      );
      filteredPurchaseOrders[
        filteredPurchaseOrderIndex
      ] = tempFilteredPurchaseOrder;
      return Object.assign({}, state, {
        purchaseOrders,
        filteredPurchaseOrders
      });
    case types.purchaseOrder.DELETE_PURCHASE_ORDER_SUCCEEDED:
      if (!state || !state.purchaseOrders || !state.filteredPurchaseOrders) {
        return state;
      }
      purchaseOrders = [
        ...state.purchaseOrders.filter(
          (purchaseOrder: PurchaseOrder) => purchaseOrder.id !== action.payload
        )
      ];
      filteredPurchaseOrders = [
        ...state.filteredPurchaseOrders.filter(
          (purchaseOrder: PurchaseOrder) => purchaseOrder.id !== action.payload
        )
      ];
      return Object.assign({}, state, {
        purchaseOrders,
        filteredPurchaseOrders
      });
    case types.purchaseOrder.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
  }
  return state;
}
