import { Component } from '@angular/core';
import { extract } from '../services/i18n.service';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
})
export class CrmComponent {
  routes = [
    {
      url: '/crm/invoices',
      title: extract('Invoices'),
      description: extract('Manage the invoices of your company'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-file-invoice-dollar'
    },
    {
      url: '/crm/invoices/new',
      title: extract('New invoice'),
      description: extract('Quick link to create a new invoice'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-plus'
    },
    {
      url: '/crm/purchase-orders',
      title: extract('Purchase Orders'),
      description: extract('Manage the purchase orders of your company'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-file-invoice-dollar'
    },
    {
      url: '/crm/purchase-orders/new',
      title: extract('New purchase order'),
      description: extract('Quick link to create a new purchase order'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-plus'
    },
    {
      url: '/crm/customers',
      title: extract('Customers'),
      description: extract('Manage the customers of your company'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-user-tie'
    },
    {
      url: '/crm/customers/new',
      title: extract('New customer'),
      description: extract('Quick link to create a new customer'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-plus'
    },
    {
      url: '/crm/suppliers',
      title: extract('Suppliers'),
      description: extract('Manage the suppliers of your company'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-user-tie'
    },
    {
      url: '/crm/suppliers/new',
      title: extract('New supplier'),
      description: extract('Quick link to create a new supplier'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-plus'
    },
    {
      url: '/crm/tariffs',
      title: extract('Tariffs'),
      description: extract('Manage your tariffs'),
      icon: 'fa-hand-holding-usd'
    },
    {
      url: '/crm/tariffs/new',
      title: extract('Add new tariff'),
      description: extract('Create a new tariff'),
      icon: 'fa-plus'
    },
    {
      url: '/crm/tax-rates',
      title: extract('Tax rates'),
      description: extract('Manage the tax rates of your company'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-money-bill-wave'
    },
    {
      url: '/crm/tax-rates/new',
      title: extract('New tax rate'),
      description: extract('Quick link to create a new tax rate'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-plus'
    },
    {
      url: '/crm/credit-notes',
      title: extract('Credit notes'),
      description: extract('Manage the credit notes of your company'),
      allowedSubscriptions: ['TMS'],
      icon: 'fa-file-invoice'
    }
  ];
}
