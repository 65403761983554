import { Action } from '@ngrx/store';

import * as types from '../types/marketplace.types';

export class LoadMessages implements Action {
  readonly type = types.messages.LOAD_MESSAGES;
  constructor(public payload?: any) { }
}

export class LoadMessagesSuccess implements Action {
  readonly type = types.messages.LOAD_SUCCEEDED;
  constructor(public payload: any) { }
}

export class LoadMessagesFailed implements Action {
  readonly type = types.messages.LOAD_FAILED;
  constructor(public payload: any) { }
}

export class CreateMessage implements Action {
  readonly type = types.messages.CREATE_MESSAGE;
  constructor(public payload: any) { }
}

export class CreateMessageSuccess implements Action {
  readonly type = types.messages.CREATE_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateMessageFailed implements Action {
  readonly type = types.messages.CREATE_FAILED;
  constructor(public payload: any) { }
}

export class CreateReply implements Action {
  readonly type = types.messages.CREATE_REPLY;
  constructor(public payload: any) { }
}

export class CreateReplySuccess implements Action {
  readonly type = types.messages.CREATE_REPLY_SUCCEEDED;
  constructor(public payload: any) { }
}

export class CreateReplyFailed implements Action {
  readonly type = types.messages.CREATE_REPLY_FAILED;
  constructor(public payload: any) { }
}

export class ClearMessages implements Action {
  readonly type = types.messages.CLEAR;
  constructor(public payload?: any) { }
}

export type All
  = LoadMessages
  | LoadMessagesSuccess
  | LoadMessagesFailed
  | CreateMessage
  | CreateMessageSuccess
  | CreateMessageFailed
  | CreateReply
  | CreateReplySuccess
  | CreateReplyFailed
  | ClearMessages;
