/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ribbon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ribbon.component";
var styles_RibbonComponent = [i0.styles];
var RenderType_RibbonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RibbonComponent, data: {} });
export { RenderType_RibbonComponent as RenderType_RibbonComponent };
export function View_RibbonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ribbon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 2, 0, currVal_0); }); }
export function View_RibbonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ribbon", [], null, null, null, View_RibbonComponent_0, RenderType_RibbonComponent)), i1.ɵdid(1, 49152, null, 0, i2.RibbonComponent, [], null, null)], null, null); }
var RibbonComponentNgFactory = i1.ɵccf("app-ribbon", i2.RibbonComponent, View_RibbonComponent_Host_0, { text: "text" }, {}, []);
export { RibbonComponentNgFactory as RibbonComponentNgFactory };
