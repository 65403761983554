var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/transport.types';
import * as NotificationsActions from '../actions/notifications.actions';
import * as TransportActions from '../actions/transports.actions';
import { ApiService } from '../../api/api.service';
var TransportEffects = /** @class */ (function () {
    function TransportEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.loadTransports$ = this.actions$.pipe(ofType(types.transports.LOAD_TRANSPORTS), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/transports?createdOnMarketplace=0&page=' +
                (payload ? payload.page : 1)
        }).pipe(map(function (data) { return new TransportActions.LoadTransportsSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.loadInvoiceableTransports$ = this.actions$.pipe(ofType(types.transports.LOAD_INVOICEABLE_TRANSPORTS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            var since = payload && payload.since ? payload.since : '';
            var until = payload && payload.until ? payload.until : '';
            var customers = payload && payload.customers ? payload.customers : null;
            var url = "/tms/crm/payment-notable-transports/invoice?query=" + query + "&since=" + since + "&until=" + until;
            if (customers) {
                url = url + "&customers[]=" + customers;
            }
            return _this.api.get({ path: url }).pipe(map(function (data) { return new TransportActions.LoadInvoiceableTransportsSuccess(data); }), catchError(function (error) { return of(new TransportActions.LoadInvoiceableTransportsFail(error)); }));
        }));
        this.loadNextInvoiceableTransportsPage$ = this.actions$.pipe(ofType(types.transports.LOAD_NEXT_INVOICEABLE_TRANSPORTS_PAGE), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            var since = payload && payload.since ? payload.since : '';
            var until = payload && payload.until ? payload.until : '';
            var page = payload && payload.page ? payload.page : '';
            var customers = payload && payload.customers ? payload.customers : null;
            var url = "/tms/crm/payment-notable-transports/invoice?page=" + page + "&query=" + query + "&since=" + since + "&until=" + until;
            if (customers) {
                url = url + "&customers[]=" + customers;
            }
            return _this.api.get({ path: url }).pipe(map(function (data) { return new TransportActions.LoadNextInvoiceableTransportsPageSuccess(data); }), catchError(function (error) { return of(new TransportActions.LoadNextInvoiceableTransportsPageSuccess(error)); }));
        }));
        this.loadPurchaseOrderableTransports$ = this.actions$.pipe(ofType(types.transports.LOAD_PURCHASE_ORDERABLE_TRANSPORTS), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            var since = payload && payload.since ? payload.since : '';
            var until = payload && payload.until ? payload.until : '';
            var suppliers = payload && payload.suppliers ? payload.suppliers : null;
            var url = "/tms/crm/payment-notable-transports/purchase_order?query=" + query + "&since=" + since + "&until=" + until;
            if (suppliers) {
                url = url + "&suppliers[]=" + suppliers;
            }
            return _this.api.get({ path: url }).pipe(map(function (data) { return new TransportActions.LoadPurchaseOrderableTransportsSuccess(data); }), catchError(function (error) { return of(new TransportActions.LoadPurchaseOrderableTransportsFail(error)); }));
        }));
        this.loadNextPurchaseOrderableTransportsPage$ = this.actions$.pipe(ofType(types.transports.LOAD_NEXT_PURCHASE_ORDERABLE_TRANSPORTS_PAGE), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var query = payload && payload.query ? payload.query : '';
            var since = payload && payload.since ? payload.since : '';
            var until = payload && payload.until ? payload.until : '';
            var page = payload && payload.page ? payload.page : '';
            var suppliers = payload && payload.suppliers ? payload.suppliers : null;
            var url = "/tms/crm/payment-notable-transports/purchase_order?page=" + page + "&query=" + query + "&since=" + since + "&until=" + until;
            if (suppliers) {
                url = url + "&suppliers[]=" + suppliers;
            }
            return _this.api.get({ path: url }).pipe(map(function (data) { return new TransportActions.LoadNextPurchaseOrderableTransportsPageSuccess(data); }), catchError(function (error) { return of(new TransportActions.LoadNextPurchaseOrderableTransportsPageSuccess(error)); }));
        }));
        this.loadTransportsByDaterange$ = this.actions$.pipe(ofType(types.transports.LOAD_TRANSPORTS_BY_DATERANGE), map(function (action) { return action.payload; }), concatMap(function (payload) {
            var fromDate = payload && payload.fromDate ? payload.fromDate : '';
            var toDate = payload && payload.toDate ? payload.toDate : '';
            var page = payload && payload.page ? payload.page : 1;
            var query = payload && payload.query ? payload.query : '';
            var url = "/transports?order[primaryTaskDate]=DESC&createdOnMarketplace=0&primaryTaskDate[after]=" + fromDate + "&primaryTaskDate[before]=" + toDate + "%2023%3A59%3A59&page=" + page;
            if (query) {
                url = url + "&customer.label=" + query + "&customerReference=" + query + "&container.number=" + query + "&reference=" + query;
            }
            return _this.api.get({ path: url }).pipe(map(function (data) { return new TransportActions.LoadTransportsSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); }));
        }));
        this.createTransportTasks$ = this.actions$.pipe(ofType(types.transports.CREATE_TRANSPORT_TASK), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/transport_tasks', body: payload
        }).pipe(map(function (data) { return new TransportActions.CreateTransportTaskSuccess(data); }), catchError(function (error) { return of(new NotificationsActions.ErrorNotification(error)); })); }));
        this.checkTransportTaskReference$ = this.actions$.pipe(ofType(types.transports.CHECK_TRANSPORT_TASK_REFERENCE), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.get({
            path: '/transport_tasks/' + payload.id + '/check_reference'
        }).pipe(map(function (data) { return new TransportActions.CheckTransportTaskReferenceSuccess(data); }), catchError(function (error) { return of(new TransportActions.CheckTransportTaskReferenceFail(error)); })); }));
        this.createTransport$ = this.actions$.pipe(ofType(types.transports.CREATE_TRANSPORT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.post({
            path: '/transports', body: payload
        }).pipe(map(function (data) { return new TransportActions.CreateTransportSuccess(data); }), catchError(function (error) { return of(new TransportActions.CreateTransportFail(error)); })); }));
        this.transitionTransportTaskForward$ = this.actions$.pipe(ofType(types.transports.TRANSITION_TRANSPORT_TASK_FORWARD), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/transport_tasks/" + payload.transportTaskId + "/transition/forward"
        }).pipe(map(function (data) { return new TransportActions.TransitionTransportTaskForwardSuccess(data); }), catchError(function (error) { return of(new TransportActions.TransitionTransportTaskForwardFail(error)); })); }));
        this.transitionTransportTaskBack$ = this.actions$.pipe(ofType(types.transports.TRANSITION_TRANSPORT_TASK_BACK), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/transport_tasks/" + payload.transportTaskId + "/transition/back"
        }).pipe(map(function (data) { return new TransportActions.TransitionTransportTaskBackSuccess(data); }), catchError(function (error) { return of(new TransportActions.TransitionTransportTaskBackFail(error)); })); }));
        this.updateTransport$ = this.actions$.pipe(ofType(types.transports.UPDATE_TRANSPORT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: '/transports/' + payload.id, body: payload.body
        }).pipe(map(function (data) { return new TransportActions.UpdateTransportSuccess(data); }), catchError(function (error) { return of(new TransportActions.UpdateTransportFail(error)); })); }));
        this.deleteTransport$ = this.actions$.pipe(ofType(types.transports.DELETE_TRANSPORT), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: '/transports/' + payload.id
        }).pipe(map(function (data) { return new TransportActions.DeleteTransportSuccess(data); }), catchError(function (error) { return of(new TransportActions.DeleteTransportFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "loadTransports$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "loadInvoiceableTransports$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "loadNextInvoiceableTransportsPage$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "loadPurchaseOrderableTransports$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "loadNextPurchaseOrderableTransportsPage$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "loadTransportsByDaterange$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "createTransportTasks$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "checkTransportTaskReference$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "createTransport$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "transitionTransportTaskForward$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "transitionTransportTaskBack$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "updateTransport$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportEffects.prototype, "deleteTransport$", void 0);
    return TransportEffects;
}());
export { TransportEffects };
