import { AuthGuard, PlannerGuard } from '../core/user/user.authguard';
import { MarketPostDemandDetailComponent } from './market-posts/market-post-demand-detail/market-post-demand-detail.component';
import { MessageBoardComponent } from './message-board/message-board.component';
import { MarketPostMapComponent } from './market-posts/market-post-map/market-post-map.component';
import { NewMarketpostComponent } from './new-marketpost/new-marketpost.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketplaceComponent } from './marketplace.component';
import { MarketPostOfferDetailComponent } from './market-posts/market-post-offer-detail/market-post-offer-detail.component';
import { NoSubscriptionNotificationComponent } from './no-subscription-notification/no-subscription-notification.component';
import { EditMarketPostComponent } from './market-posts/edit-market-post/edit-market-post.component';
import { Route } from '../core/route.service';
import { DriverGuard } from '../core/auth/route-guards/driver.guard';
import { SubscriptionGuard } from '../core/auth/route-guards/subscription.guard';
import { TenantGuard } from '../core/auth/route-guards/tenant.guard';
import { AllowedSubscriptionGuard } from '../core/auth/route-guards/allowed-subscription.guard';

const routes: Routes = Route.withShell([
  {
    path: 'marketplace',
    canActivate: [
      AuthGuard,
      TenantGuard,
      SubscriptionGuard,
      AllowedSubscriptionGuard,
      DriverGuard
    ],
    data: { subscriptionCode: 'MARKETPLACE' },
    children: [
      {
        path: '',
        component: MarketplaceComponent
      },
      {
        path: 'create',
        component: NewMarketpostComponent,
        canActivate: [
          AuthGuard,
          TenantGuard,
          PlannerGuard,
          SubscriptionGuard,
          AllowedSubscriptionGuard,
          DriverGuard
        ],
        data: { subscriptionCode: 'MARKETPLACE' }
      },
      {
        path: 'offers',
        redirectTo: '/company-posts',
        pathMatch: 'full'
      },
      {
        path: 'demands',
        redirectTo: '/company-posts',
        pathMatch: 'full'
      },
      {
        path: ':type/:id/edit',
        component: EditMarketPostComponent,
        canActivate: [
          AuthGuard,
          TenantGuard,
          PlannerGuard,
          SubscriptionGuard,
          AllowedSubscriptionGuard,
          DriverGuard
        ],
        data: { subscriptionCode: 'MARKETPLACE' }
      },
      {
        path: 'chat',
        component: MessageBoardComponent,
        canActivate: [
          AuthGuard,
          TenantGuard,
          PlannerGuard,
          SubscriptionGuard,
          AllowedSubscriptionGuard,
          DriverGuard
        ],
        data: { subscriptionCode: 'MARKETPLACE' }
      },
      {
        path: 'map',
        component: MarketPostMapComponent,
        canActivate: [
          AuthGuard,
          TenantGuard,
          PlannerGuard,
          SubscriptionGuard,
          AllowedSubscriptionGuard,
          DriverGuard
        ],
        data: { subscriptionCode: 'MARKETPLACE' }
      },
      {
        path: 'offers/:id/reply',
        component: MarketPostOfferDetailComponent,
        canActivate: [
          AuthGuard,
          TenantGuard,
          PlannerGuard,
          SubscriptionGuard,
          AllowedSubscriptionGuard,
          DriverGuard
        ],
        data: { subscriptionCode: 'MARKETPLACE' }
      },
      {
        path: 'demands/:id/reply',
        component: MarketPostDemandDetailComponent,
        canActivate: [
          AuthGuard,
          TenantGuard,
          PlannerGuard,
          SubscriptionGuard,
          AllowedSubscriptionGuard,
          DriverGuard
        ],
        data: { subscriptionCode: 'MARKETPLACE' }
      }
    ]
  },
  {
    path: 'subscribe',
    component: NoSubscriptionNotificationComponent,
    canActivate: [AuthGuard]
  }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class MarketplaceRoutingModule {}
