import { Tenant } from 'app/core/store/models/tenant.model';
import {
  SubscriptionType,
  Subscription,
  SubscriptionTypes
} from 'app/core/store/models/subscriptions.model';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import * as Types from 'app/core/store/types/subscription.types';
import { AuthService } from '../../../user/user.auth';
import { ToastService } from 'app/services/toast.service';
import { LoadWallet } from 'app/core/store/actions/wallet.actions';
import { LoadSubscriptions } from 'app/core/store/actions/subscriptions.actions';
import { Router } from '@angular/router';
import { LoadTenants } from '../../../tenant/tenants.actions';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-subscriptions',
  templateUrl: 'subscriptions.component.html',
  styleUrls: ['subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnDestroy {
  isLoading: boolean;
  title = extract('Subscriptions');
  subscriptionTypes: SubscriptionType[];
  credits;
  subscriptions: Subscription[];
  isBetaUser = false;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private authService: AuthService,
    private toastr: ToastService,
    private router: Router
  ) {
    this.store.dispatch(new LoadWallet());
    this.store
      .select(state => {
        if (state && state.wallet) {
          return state.wallet.credits;
        }
      })
      .pipe(untilDestroyed(this))
      .subscribe(credits => {
        if (credits !== undefined) {
          this.credits = credits;
        }
      });
    this.store
      .select(state => state.tenants.userTenants)
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (!state) {
          this.store.dispatch(new LoadTenants());
          return;
        }
        const tenant: Tenant = state.find(
          t => t.id === this.authService.getActiveTenant()
        );
        if (!tenant || !tenant.allowedSubscriptionTypes) {
          return;
        }
        this.subscriptionTypes = tenant.allowedSubscriptionTypes;
      });

    this.store.dispatch(new LoadSubscriptions());
    this.store
      .select('subscriptions')
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        if (state) {
          this.subscriptions = state;
        }
      });

    updates$
      .pipe(ofType(Types.subscriptions.START_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to start subscription'
        });
        this.isLoading = false;
      });
    updates$
      .pipe(ofType(Types.subscriptions.STOP_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to stop subscription'
        });
        this.isLoading = false;
      });
    updates$
      .pipe(ofType(Types.subscriptions.START_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.toastr.showSuccess({
          message: 'Subscription successfully started'
        });
        this.store.dispatch(new LoadSubscriptions());
        this.isLoading = false;
        if (
          data['payload']['subscriptionType'].code ===
          SubscriptionTypes.MATCHING
        ) {
          this.router.navigate([
            '/settings/circles',
            {
              matching: true
            }
          ]);
        }
      });
    updates$
      .pipe(ofType(Types.subscriptions.STOP_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'Subscription successfully stopped'
        });
        this.store.dispatch(new LoadSubscriptions());
        this.isLoading = false;
      });
  }

  hasActiveSubscription(subscription) {
    if (this.subscriptions && this.subscriptions.length > 0) {
      const sub = this.subscriptions.find(
        s => s.subscriptionType.id === subscription.id
      );
      if (sub) {
        return moment().isBefore(moment(sub.endDate));
      }
    }
  }

  getSubscription(subscription) {
    if (this.subscriptions && this.subscriptions.length > 0) {
      const sub = this.subscriptions.find(
        s => s.subscriptionType.id === subscription.id
      );
      return sub ? sub : {};
    } else {
      return {};
    }
  }

  hasEnoughCredits(subscriptionType): boolean {
    if (this.credits !== undefined && subscriptionType) {
      return this.credits < +subscriptionType.credits; // + converts strings to numbers
    }
    return false;
  }

  ngOnDestroy() { }
}
