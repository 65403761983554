/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../new-circle/new-circle.component.ngfactory";
import * as i2 from "../new-circle/new-circle.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../services/autocomplete.service";
import * as i5 from "@ngrx/effects";
import * as i6 from "@angular/router";
import * as i7 from "../../../../services/toast.service";
import * as i8 from "./edit-circle.component";
import * as i9 from "@ngrx/store";
var styles_EditCircleComponent = [];
var RenderType_EditCircleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditCircleComponent, data: {} });
export { RenderType_EditCircleComponent as RenderType_EditCircleComponent };
export function View_EditCircleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mr-3 ml-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-cmp-new-circle", [], null, [[null, "circleSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("circleSubmitted" === en)) {
        var pd_0 = (_co.updateCircle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_NewCircleComponent_0, i1.RenderType_NewCircleComponent)), i0.ɵdid(2, 770048, null, 0, i2.NewCircleComponent, [i3.FormBuilder, i4.Autocomplete, i0.Renderer, i5.Actions, i5.Actions, i6.Router, i7.ToastService], { formTitle: [0, "formTitle"], circle: [1, "circle"], buttonText: [2, "buttonText"] }, { circleSubmitted: "circleSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Edit Circle"; var currVal_1 = _co.circle; var currVal_2 = "Save"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_EditCircleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-cmp-edit-cirle", [], null, null, null, View_EditCircleComponent_0, RenderType_EditCircleComponent)), i0.ɵdid(1, 245760, null, 0, i8.EditCircleComponent, [i6.ActivatedRoute, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditCircleComponentNgFactory = i0.ɵccf("app-cmp-edit-cirle", i8.EditCircleComponent, View_EditCircleComponent_Host_0, {}, {}, []);
export { EditCircleComponentNgFactory as EditCircleComponentNgFactory };
