import { Router } from '@angular/router';
import { ToastService } from 'app/services/toast.service';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { IconService } from 'app/services/icon.service';
import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { TransportTask } from 'app/core/store/models/transport-task.model';
import {
  CheckTransportTaskReference,
  TransitionTransportTaskForward,
  TransitionTransportTaskBack
} from 'app/core/store/actions/transports.actions';
import * as Types from 'app/core/store/types/transport.types';
import { Actions, ofType } from '@ngrx/effects';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-transport-tasks-table',
  templateUrl: './transport-tasks-table.component.html',
  styleUrls: ['./transport-tasks-table.component.scss']
})
export class TransportTasksTableComponent implements OnChanges, OnDestroy {
  @Input()
  showStatus = true;
  @Input()
  showReference = true;
  @Input()
  visitStatus: string;
  @Input()
  transportTasks: TransportTask[];
  @Input()
  showDropdown: boolean;
  @Input()
  primaryTaskDate: string;
  @Input()
  checkingTaskRefereces;
  @Input()
  taskIndexes;
  @Input()
  transportIsOwnedByCurrentTenant: boolean;
  @Output()
  referenceChecked = new EventEmitter<boolean>();
  @Output()
  taskStatusChanged = new EventEmitter<boolean>();

  isLoading: boolean;
  tasksInTransition: string[] = [];
  referenceCheckCounter: number;

  constructor(
    private iconService: IconService,
    private store: Store<State>,
    private updates$: Actions,
    private router: Router,
    private toastr: ToastService
  ) {
    this.updates$
      .pipe(ofType(Types.transports.CHECK_TRANSPORT_TASK_REFERENCE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.referenceCheckCounter = this.referenceCheckCounter - 1;
        if (this.referenceCheckCounter === 0) {
          this.isLoading = false;
          this.toastr.showSuccess({
            message: extract('Reference checked successfully')
          });
          this.referenceChecked.emit(true);
        }
      });
    this.updates$
      .pipe(ofType(Types.transports.CHECK_TRANSPORT_TASK_REFERENCE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.tasksInTransition.shift();
      });
    this.updates$
      .pipe(ofType(
        Types.transports.TRANSITION_TRANSPORT_TASK_BACK_SUCCEEDED,
        Types.transports.TRANSITION_TRANSPORT_TASK_FORWARD_SUCCEEDED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.tasksInTransition.shift();
      });
    this.updates$
      .pipe(ofType(
        Types.transports.TRANSITION_TRANSPORT_TASK_BACK_FAILED,
        Types.transports.TRANSITION_TRANSPORT_TASK_FORWARD_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.tasksInTransition.shift();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskIndexes && changes.taskIndexes.currentValue) {
      if (changes.taskIndexes.currentValue.length === 0) {
        this.isLoading = false;
      } else {
        this.isLoading = true;
      }
    }
  }
  getTaskIcon(task: TransportTask) {
    return this.iconService.getTransportTaskIcon(task);
  }

  goToNewPrenotification(task) {
    this.router.navigateByUrl(`/tms/prenotifications/new;task=${task.id}`);
  }

  goToEditPrenotification(task) {
    this.router.navigateByUrl(`/tms/prenotifications/${task.visit.id}/edit`);
  }

  /**
   * Checks if the transport has at least one reference
   * @param transport
   */
  transportHasReference(): boolean {
    return this.transportTasks.some(task => !!task.reference);
  }

  checkTaskReference(task) {
    if (!this.referenceCheckCounter) {
      this.referenceCheckCounter = 1;
    }
    if (!this.taskIndexes) {
      this.taskIndexes = [];
    }
    this.taskIndexes = [...this.taskIndexes, task.id];
    this.isLoading = true;
    this.store.dispatch(
      new CheckTransportTaskReference({
        id: task.id
      })
    );
  }

  /**
   * Only show private location if the transport is owned by the current tenant; else show the public location
   */
  location(task: TransportTask) {
    if (!this.transportTasks || this.transportTasks.length === 0) {
      return;
    }
    if (task.readableLocation) {
      return task.readableLocation.shortName
        ? task.readableLocation.shortName
        : task.readableLocation.name
          ? task.readableLocation.name
          : task.readableLocation;
    }
    if (this.transportIsOwnedByCurrentTenant && task.privateLocation) {
      return task.privateLocation.shortName
        ? task.privateLocation.shortName
        : task.privateLocation.name
          ? task.privateLocation.name
          : task.privateLocation;
    }
    return task.location.shortName
      ? task.location.shortName
      : task.location.name
        ? task.location.name
        : task.location;
  }

  getVerificationResult(task: TransportTask) {
    if (!task.referenceCheckedAt) {
      return extract('Reference not yet verified');
    }
    if (task.referenceCheckedAt && task.referenceVerified) {
      return extract('Reference verification successful');
    }
    return task.referenceVerificationResult;
  }

  showChangeTaskStatusDropdown(task: TransportTask) {
    return ['new', 'in_progress', 'completed'].includes(task.status);
  }

  transitionTaskStatus(task: TransportTask, direction: string) {
    this.tasksInTransition.push(task.id);
    if (direction === 'forward') {
      this.store.dispatch(
        new TransitionTransportTaskForward({ transportTaskId: task.id })
      );
    } else if (direction === 'back') {
      this.store.dispatch(
        new TransitionTransportTaskBack({ transportTaskId: task.id })
      );
    }
  }

  isTaskInTransition(task: TransportTask) {
    return this.tasksInTransition.includes(task.id);
  }

  ngOnDestroy() { }
}
