import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, interval } from 'rxjs';
import { Component, AfterViewInit, isDevMode } from '@angular/core';
import { AuthService } from './core/user/user.auth';
import { I18nService, extract } from './services/i18n.service';
import { SwUpdate } from '@angular/service-worker';
import { UpdateToastComponent } from '../app/authentication/update-toast/update-toast.component';

// declare google analytics
declare const gtag: any;
/**
 * Hakka !
 *
 * @export
 * @class AppComponent The actual component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  opened = false;
  title = 'Hakka';
  showNav = true;

  /**
   * Creates an instance of AppComponent.
   * @param {AuthService} authService Authorization service
   * @param {TranslateService} translate Translation service
   *
   * @memberOf AppComponent
   */
  constructor(
    private authService: AuthService,
    private i18nService: I18nService,
    private router: Router,
    private updates: SwUpdate,
    private toastr: ToastrService
  ) {
    this.i18nService.init('en_GB', ['en_GB', 'nl_BE', 'fr_BE', 'es_ES']);
    const lang = localStorage.getItem('language');
    if (lang) {
      this.i18nService.language = lang;
    }
    // Only check for updates in prod mode.
    if (!isDevMode() && this.updates.isEnabled) {
      // check for updates every minute
      interval(60000).subscribe(() => updates.checkForUpdate());
      updates.available.subscribe(event => {
        if (!event) {
          return;
        }
        if (event['available']) {
          const title = extract('Update is available');
          const message = extract(
            'A newer version of Hakka is available. Click here to update!'
          );
          const options = {
            disableTimeOut: true,
            closeButton: true,
            toastComponent: UpdateToastComponent,
            positionClass: 'toast-bottom-right'
          };
          this.toastr.info(message, title, options);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate();
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('set', 'page', event.urlAfterRedirects);
        gtag('send', 'pageview');
      }
    });
  }

  /**
   * Function to check if the sidebar should be shown or not
   *
   * @returns {boolean}
   *
   * @memberOf AppComponent
   */
  showNavigation(): Observable<boolean> {
    return this.authService.hasActiveTenant();
  }

  /**
   * Function to check if the header should be shown or not
   *
   * @returns {boolean}
   *
   * @memberOf AppComponent
   */
  showHeader(): Observable<boolean> {
    return this.showNavigation();
  }

  /**
   * Function to show/hide the navigation. Used on mobile
   *
   * @memberOf AppComponent
   */
  toggleOffCanvas() {
    this.showNav = !this.showNav;
  }

  toggleSidebar() {
    this.opened = !this.opened;
  }
}
