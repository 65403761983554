<div class="m-3">
  <div class="card">
    <ngx-datatable
      class="bootstrap ml-0 mr-0"
      [rows]="containerTypes"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [selectionType]="undefined"
      [loadingIndicator]="loading"
    >
      <ngx-datatable-column name="Label" prop="label"></ngx-datatable-column>
      <ngx-datatable-column name="Length" prop="length"></ngx-datatable-column>
      <ngx-datatable-column name="Code" prop="code"></ngx-datatable-column>
      <ngx-datatable-column name="" prop="">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <button class="btn btn-info" (click)="edit(row.id)">
            <i class="far fa-fw fa-pencil"></i>
            <span>{{ 'Edit' | translate }}</span>
          </button>
          &nbsp;
          <!-- <button class="btn" (click)="delete(row.id)">
            <i class="far fa-fw fa-trash-alt"></i>
            <span>{{ 'Delete' | translate }}</span>
          </button> -->
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
