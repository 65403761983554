import { Action } from '@ngrx/store';
import * as types from '../types/wallet.types';

export class LoadWallet implements Action {
  readonly type = types.wallet.LOAD_WALLET;
  constructor(public payload?: any) {}
}

export class LoadWalletFail implements Action {
  readonly type = types.wallet.LOAD_FAILED;
  constructor(public payload: any) {}
}

export class LoadWalletSuccess implements Action {
  readonly type = types.wallet.LOAD_SUCCEEDED;
  constructor(public payload: any) {}
}

export class RechargeWallet implements Action {
  readonly type = types.wallet.RECHARGE_WALLET;
  constructor(public payload: any) {}
}

export class RechargeWalletSuccess implements Action {
  readonly type = types.wallet.RECHARGE_SUCCEEDED;
  constructor(public payload: any) {}
}

export class RechargeWalletFail implements Action {
  readonly type = types.wallet.RECHARGE_FAILED;
  constructor(public payload: any) {}
}

export class AddCreditsToWallet implements Action {
  readonly type = types.wallet.ADD_CREDITS_TO_WALLET;
  constructor(public payload: any) {}
}

export class AddCreditsToWalletSuccess implements Action {
  readonly type = types.wallet.ADD_CREDITS_TO_WALLET_SUCCEEDED;
  constructor(public payload: any) {}
}

export class AddCreditsToWalletFail implements Action {
  readonly type = types.wallet.ADD_CREDITS_TO_WALLET_FAILED;
  constructor(public payload: any) {}
}

export class SubtractCreditsFromWallet implements Action {
  readonly type = types.wallet.SUBTRACT_CREDITS_FROM_WALLET;
  constructor(public payload: any) {}
}

export class SubtractCreditsFromWalletSuccess implements Action {
  readonly type = types.wallet.SUBTRACT_CREDITS_FROM_WALLET_SUCCEEDED;
  constructor(public payload: any) {}
}

export class SubtractCreditsFromWalletFail implements Action {
  readonly type = types.wallet.SUBTRACT_CREDITS_FROM_WALLET_FAILED;
  constructor(public payload: any) {}
}

export class RequestRechargeWallet implements Action {
  readonly type = types.wallet.REQUEST_RECHARGE_WALLET;
  constructor(public payload: any) {}
}

export class RequestRechargeWalletSuccess implements Action {
  readonly type = types.wallet.REQUEST_RECHARGE_WALLET_SUCCEEDED;
  constructor(public payload: any) {}
}

export class RequestRechargeWalletFail implements Action {
  readonly type = types.wallet.REQUEST_RECHARGE_WALLET_FAILED;
  constructor(public payload: any) {}
}

export class ClearWallet implements Action {
  readonly type = types.wallet.CLEAR;
  constructor(public payload?: any) {}
}

export class UpdateWallet implements Action {
  readonly type = types.wallet.UPDATE_WALLET;
  constructor(public payload: any) {}
}

export class UpdateWalletSuccess implements Action {
  readonly type = types.wallet.UPDATE_WALLET_SUCCEEDED;
  constructor(public payload: any) {}
}

export class UpdateWalletFail implements Action {
  readonly type = types.wallet.UPDATE_WALLET_FAILED;
  constructor(public payload: any) {}
}

export type All =
  | LoadWallet
  | LoadWalletFail
  | LoadWalletSuccess
  | RechargeWallet
  | RechargeWalletFail
  | RechargeWalletSuccess
  | RequestRechargeWallet
  | RequestRechargeWalletFail
  | RequestRechargeWalletSuccess
  | ClearWallet;
