import { PaymentNote, CreditNote } from '../models/payment-note.model';
import * as types from '../types/credit-note.types';
import * as CreditNoteActions from '../actions/credit-note.actions';

export interface CreditNotesState {
  creditNotes: PaymentNote[];
  filteredCreditNotes: PaymentNote[];
  totalFilteredCreditNotes: number;
}

const INITIAL_STATE = {
  creditNotes: null,
  filteredCreditNotes: null,
  totalFilteredCreditNotes: null
};

export function CreditNotesReducer(
  state = INITIAL_STATE,
  action: CreditNoteActions.All
): CreditNotesState {
  switch (action.type) {
    case types.creditNote.LOAD_CREDIT_NOTES_SUCCEEDED:
      state.creditNotes = [...action.payload['hydra:member']];
      state.filteredCreditNotes = [...action.payload['hydra:member']];
      state.totalFilteredCreditNotes = action.payload['hydra:totalItems'];
      return Object.assign({}, state);
    case types.creditNote.LOAD_FILTERED_CREDIT_NOTES_SUCCEEDED:
      state.filteredCreditNotes = [...action.payload['hydra:member']];
      state.totalFilteredCreditNotes = action.payload['hydra:totalItems'];
      return Object.assign({}, state);
    case types.creditNote.UPDATE_CREDIT_NOTE_SUCCEEDED:
      if (!state || !state.creditNotes || !state.filteredCreditNotes) {
        return state;
      }
      let creditNotes = [...state.creditNotes];
      let filteredCreditNotes = [...state.filteredCreditNotes];

      const creditNoteIndex = creditNotes.findIndex(
        i => i.id === action.payload.id
      );
      const tempCreditNote = Object.assign(
        {},
        state.creditNotes[creditNoteIndex],
        action.payload
      );
      creditNotes[creditNoteIndex] = tempCreditNote;
      const filteredCreditNoteIndex = filteredCreditNotes.findIndex(
        i => i.id === action.payload.id
      );
      const tempFilteredCreditNote = Object.assign(
        {},
        filteredCreditNotes[filteredCreditNoteIndex],
        action.payload
      );
      filteredCreditNotes[filteredCreditNoteIndex] = tempFilteredCreditNote;
      return Object.assign({}, state, {
        creditNotes,
        filteredCreditNotes
      });
    case types.creditNote.DELETE_CREDIT_NOTE_SUCCEEDED:
      if (!state || !state.creditNotes || !state.filteredCreditNotes) {
        return state;
      }
      creditNotes = [
        ...state.creditNotes.filter(
          (creditNote: CreditNote) => creditNote.id !== action.payload
        )
      ];
      filteredCreditNotes = [
        ...state.filteredCreditNotes.filter(
          (creditNote: CreditNote) => creditNote.id !== action.payload
        )
      ];
      return Object.assign({}, state, {
        creditNotes,
        filteredCreditNotes
      });
    case types.creditNote.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
  }
  return state;
}
