import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Preannouncement } from 'app/core/store/models/preannouncement.model';

@Component({
  selector: 'app-preannouncement-form',
  templateUrl: './preannouncement-form.component.html',
})
export class PreannouncementFormComponent implements OnChanges {
  @Input()
  isLoading: boolean;
  @Input()
  preannouncement: Preannouncement;
  @Output()
  formSubmitted = new EventEmitter();
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      label: [null, Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.preannouncement && changes.preannouncement.currentValue) {
      this.form.patchValue(changes.preannouncement.currentValue);
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.formSubmitted.emit(this.form.value);
  }
}
