import { Liner } from './liner.model';
import { Location } from './location.model';
import { Tenant } from './tenant.model';
import { Transaction } from './transaction.model';
import { UserContext } from './user-contexts.model';

export interface ReloadRequest {
  '@id': string;
  id: string;
  liner: Liner;
  importLocation: string;
  exportLocation: string;
  unloadLocation?: { latitude: number, longitude: number, name: string };
  turnInLocation?: Location;
  loadLocation?: { latitude: number, longitude: number, name: string };
  holderOfContainer?: Tenant;
  containerNr: string;
  exportReference: string;
  loadDate: string;
  unloadDate: string;
  reloadDate: string;
  remark?: string;
  tenant: Tenant;
  requestStatus?: string;
  requestStatusDetail?: string;
  reloadRequestTransaction?: Transaction;
  creationDate: string;
  history?: ReloadRequestHistory[];
  userContext?: UserContext;
}

export interface ReloadRequestHistory {
  id: string;
  requestStatus: string;
  requestStatusDetail?: string;
  creationDate: string;
  createdBy: Tenant;
}

export const ReloadRequestStatuses = [
  {label: 'Approved', value: 'approved'},
  {label: 'Forced approved', value: 'forced_approved'},
  {label: 'Declined', value: 'declined'},
  {label: 'Cancelled', value: 'cancelled'},
];