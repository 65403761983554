<div class="card">
  <div class="card-title" (click)="toggleBody()">
    <span>
      <div>
        <span>
          {{ circle.name }}
          <i
            class="far fa-fw fa-question-circle"
            ngbTooltip="{{ 'You will only get matches from companies in this circle.
          If this circle is empty you will receive matches from all companies.' | translate }}"
            *ngIf="circle.name === 'Matching'"
          ></i>
        </span>
        <span class="float-right">
          <span class="icon">
            <a [routerLink]="['/settings/circles/', circle.id, 'edit']">
              <i class="far fa-edit" ngbTooltip="{{ 'Edit' | translate }}"></i>
            </a>
          </span>
          <span
            class="icon"
            [ngStyle]="{ cursor: isMatchingCircle ? 'not-allowed' : 'pointer' }"
          >
            <i
              class="far fa-trash-alt text-danger"
              [ngStyle]="{
                cursor: isMatchingCircle ? 'not-allowed' : 'pointer'
              }"
              ngbTooltip="{{ isMatchingCircle ? 'You can\'t delete the matching circle' : 'Delete' |translate }}"
              (click)="deleteCircle()"
            ></i>
          </span>
        </span>
      </div>
      <small class="text-muted">
        {{ circle?.members.length | i18nPlural: membersMapping | translate }}
      </small>
    </span>
  </div>
  <div
    class="card-body circle-members"
    *ngIf="showBody && circle.members.length > 0"
  >
    <div
      *ngFor="let member of circle.members"
      class="d-flex justify-content-between"
    >
      <span>{{ member.name }}</span>
      <i
        class="far fa-times text-danger"
        ngbTooltip="{{ 'Remove' | translate }}"
        (click)="removeMember(member)"
      ></i>
    </div>
  </div>
</div>
