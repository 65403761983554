import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';

import * as types from '../types/market-post.types';
import * as MarketPostDemandRepliesActions from '../actions/market-post-demand-replies.actions';

@Injectable()
export class MarketPostDemandRepliesEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadMarketPostDemandReplies$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketPostDemandReplies.LOAD_MARKET_POST_DEMAND_REPLIES),
    concatMap(() => this.api.get({ path: '/market_post_demand_replies' }).pipe(
      map(data => new MarketPostDemandRepliesActions.LoadMarketPostDemandRepliesSuccess(data)),
      catchError(error => of(new MarketPostDemandRepliesActions.LoadMarketPostDemandRepliesFail(error)))
    )),
  );
}
