import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Location } from 'app/core/store/models/location.model';
import { ApiService } from 'app/core/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { State } from 'app/core/store/store.model';
import { UpdateUserLocation } from 'app/core/store/actions/location.actions';
import * as LocationTypes from 'app/core/store/types/location.types';
import { extract } from 'app/services/i18n.service';
import { HakkaMap } from '../../../marketplace/market-posts/market-post-map/map/hakka-map';
import { VectorLayer } from 'ol/layer/vector';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-edit-user-location',
  templateUrl: './edit-user-location.component.html',
  styleUrls: ['./edit-user-location.component.scss']
})
export class EditUserLocationComponent implements OnInit, OnDestroy {
  id: string;
  location: Location;
  isLoading: boolean;

  hakkaMap: HakkaMap;
  markerLayer: VectorLayer;
  markers = [];
  geometries = [];
  coordinates: any;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router
  ) {
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.isLoading = true;
        this.api
          .get({
            path: `/locations/${this.id}`
          })
          .subscribe((response: Location) => {
            this.location = response;
            this.updateMarkerByAddress(this.location);
            this.isLoading = false;
          });
      }
    });

    this.updates$
      .pipe(ofType(LocationTypes.location.UPDATE_USER_LOCATION_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Location updated successfully')
        });
        this.router.navigateByUrl('/settings/locations');
      });
    this.updates$
      .pipe(ofType(LocationTypes.location.UPDATE_USER_LOCATION_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to update location')
        });
      });
  }

  ngOnInit() {
    this.hakkaMap = new HakkaMap('user-location-map');
    this.markerLayer = this.hakkaMap.getLayerById('markers');
    this.hakkaMap.clickCoordinates.subscribe(coord => {
      this.coordinates = coord;
      this.updateMarker(coord[0], coord[1]);
    });
  }
  ngOnDestroy() { }

  updateMarkerByAddress(address) {
    if (!address) {
      return;
    }
    if (address.latitude && address.longitude) {
      const longitude = +address.longitude;
      const latitude = +address.latitude;
      this.updateMarker(longitude, latitude);
      this.hakkaMap.setCenter(longitude, latitude, 18);
      this.coordinates = [longitude, latitude];
    }
  }
  updateMarker(lon, lat, id?) {
    this.markers[0] = this.hakkaMap.generateFeature(
      {
        lat: lat,
        lon: lon
      },
      id || ''
    );
    // this.hakkaMap.sources['markers'].clear();
    this.hakkaMap.sources['markers'].getFeaturesCollection().clear();
    this.hakkaMap.sources['markers']
      .getFeaturesCollection()
      .push(this.markers[0]);
  }
  updateUserLocation(userLocationFormValue) {
    this.isLoading = true;
    this.store.dispatch(
      new UpdateUserLocation({
        locationId: this.id,
        location: userLocationFormValue
      })
    );
  }
}
