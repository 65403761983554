<form class="card-body mt-0 pt-0 pb-0"
  (ngSubmit)="onSubmit()"
  [formGroup]="reloadRequestForm">
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group">
        <label class="center-block">{{ 'Liner' | translate }}</label>
        <ng-select id="liner"
          [items]="liners"
          bindLabel="name"
          bindValue="id"
          placeholder="{{ 'Select liner' | translate }}"
          formControlName="liner"
          (change)="handleLinerChange()"
          data-cy="liners">
        </ng-select>
        <div class="invalid-feedback">
          {{ 'Liner is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="center-block">{{ 'Container Number' | translate }}</label>
        <input class="form-control"
          formControlName="containerNr"
          id="containerNr"
          [ngClass]="{'is-invalid': reloadRequestForm.controls['containerNr'].invalid && reloadRequestForm.controls['containerNr'].touched}"

          data-cy="containerNumber">
        <div class="invalid-feedback">
          <p *ngIf="originalFormVersion">{{ 'Container Number is required.' | translate }}</p>
          <p *ngIf="!originalFormVersion">
            {{ "Container number doesn't have the correct ISO 6346 format." | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="center-block">{{ 'Export Reference' | translate }}</label>
        <input class="form-control"
          placeholder="{{ 'Export reference' | translate }}"
          formControlName="exportReference"
          id="exportReference"
          [ngClass]="{'is-invalid': reloadRequestForm.controls['exportReference'].invalid && reloadRequestForm.controls['exportReference'].touched}"

          data-cy="exportReference">
        <div class="invalid-feedback">
          {{ "Export Reference doesn't have the correct format." | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="center-block">{{ 'Import Location' | translate }}</label>
        <ng-select id="importLocation"
          [items]="importLocations"
          bindLabel="label"
          bindValue="id"
          placeholder="{{ 'Select import location' | translate }}"
          formControlName="importLocation"
          (change)="handleLocationChange()"
          data-cy="importLocation">
        </ng-select>
        <div class="invalid-feedback">
          {{ 'Import Location is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="center-block">{{ 'Export Location' | translate }}</label>
        <ng-select id="importLocation"
          [items]="exportLocations"
          bindLabel="label"
          bindValue="id"
          placeholder="{{ 'Select export location' | translate }}"
          formControlName="exportLocation"
          (change)="handleLocationChange()"
          data-cy="exportLocation">
        </ng-select>
        <div class="invalid-feedback">
          {{ 'Export Location is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="!originalFormVersion && distanceBasedPrice">
      <div class="form-group">
        <label class="center-block">{{ 'Unload location' | translate }}</label>
        <div class="input-group location-input">
          <input placeholder="{{ 'Enter location here' | translate }}"
            id="unloadLocation"
            formControlName="unloadLocation"
            class="form-control"
            [ngClass]="{'is-invalid': reloadRequestForm.controls['unloadLocation'].invalid && reloadRequestForm.controls['unloadLocation'].touched}"
            [ngbTypeahead]="searchUnloadLocations"
            (selectItem)="handleTriangulationChange()"
            [resultFormatter]="locationFormatter"
            [inputFormatter]="locationFormatter"
            data-cy="unloadLocation"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="far" [ngClass]="{'fa-spinner fa-spin': loadingUnloadLocations, 'fa-location-arrow': !loadingUnloadLocations}"></i>
            </span>
          </div>
          <div class="invalid-feedback">
            {{ 'Unload location is required.' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="!originalFormVersion && distanceBasedPrice">
      <div class="form-group">
        <label class="center-block">{{ 'Turn in location' | translate }}</label>
        <ng-select id="turnInLocation"
          [items]="turnInLocations"
          bindLabel="name"
          bindValue="id"
          placeholder="{{ 'Select location' | translate }}"
          formControlName="turnInLocation"
          (change)="handleTriangulationChange()"
          data-cy="turnInLocation">
        </ng-select>
        <div class="invalid-feedback">
          {{ 'Turn in location is required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="!originalFormVersion && distanceBasedPrice">
      <div class="form-group">
        <label class="center-block">{{ 'Load location' | translate }}</label>
        <div class="input-group location-input">
          <input placeholder="{{ 'Enter location here' | translate }}"
            id="loadLocation"
            formControlName="loadLocation"
            class="form-control"
            [ngClass]="{'is-invalid': reloadRequestForm.controls['loadLocation'].invalid && reloadRequestForm.controls['loadLocation'].touched}"
            [ngbTypeahead]="searchLoadLocations"
            (selectItem)="handleTriangulationChange()"
            [resultFormatter]="locationFormatter"
            [inputFormatter]="locationFormatter"
            data-cy="loadLocation"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="far" [ngClass]="{'fa-spinner fa-spin': loadingLoadLocations, 'fa-location-arrow': !loadingLoadLocations}"></i>
            </span>
          </div>
          <div class="invalid-feedback">
            {{ 'Load location is required.' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="originalFormVersion">
      <div class="form-group">
        <label class="center-block">{{ 'Container Holder' | translate }}</label>
        <ng-select id="tenant"
          [items]="tenants"
          bindLabel="name"
          bindValue="id"
          placeholder="{{ 'Select company' | translate }}"
          formControlName="holderOfContainer"
          data-cy="companies">
        </ng-select>
      </div>
    </div>
    <div class="col-md-6" *ngIf="originalFormVersion">
      <div class="form-group">
        <label class="center-block">{{ 'Load Date' | translate }}</label>
        <div class="input-group mr-1">
          <input class="form-control"
            placeholder="dd/mm/yyyy"
            name="d2"
            formControlName="loadDate"
            ngbDatepicker
            placement="bottom-left"
            #d2="ngbDatepicker"
            [ngClass]="{'is-invalid': reloadRequestForm.controls['loadDate'].invalid && reloadRequestForm.controls['loadDate'].touched}"

            data-cy="loadDate">
          <div class="input-group-append">
            <button class="btn btn-outline-dark"
              type="button"
              (click)="d2.toggle()">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div class="invalid-feedback">
          {{ 'Load Date required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="originalFormVersion">
      <div class="form-group">
        <label class="center-block">{{ 'Unload Date' | translate }}</label>
        <div class="input-group mr-1">
          <input class="form-control"
            placeholder="dd/mm/yyyy"
            name="d3"
            formControlName="unloadDate"
            ngbDatepicker
            placement="bottom-left"
            #d3="ngbDatepicker"
            [ngClass]="{'is-invalid': reloadRequestForm.controls['unloadDate'].invalid && reloadRequestForm.controls['unloadDate'].touched}"

            data-cy="unloadDate">
          <div class="input-group-append">
            <button class="btn btn-outline-dark"
              type="button"
              (click)="d3.toggle()">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div class="invalid-feedback">
          {{ 'Unload Date required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="!originalFormVersion">
      <div class="form-group">
        <label class="center-block">{{ 'Reload Date' | translate }}</label>
        <div class="input-group mr-1">
          <input class="form-control"
            placeholder="dd/mm/yyyy"
            name="d4"
            formControlName="reloadDate"
            ngbDatepicker
            placement="bottom-left"
            #d4="ngbDatepicker"
            [ngClass]="{'is-invalid': reloadRequestForm.controls['reloadDate'].invalid && reloadRequestForm.controls['reloadDate'].touched}"

            data-cy="reloadDate">
          <div class="input-group-append">
            <button class="btn btn-outline-dark"
              type="button"
              (click)="d4.toggle()">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div class="invalid-feedback">
          {{ 'Reload Date required.' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="center-block">{{ 'Comment' | translate }}</label>
        <input class="form-control"
          placeholder="{{ 'Comment' | translate }}"
          formControlName="remark"
          id="remark"
          [ngClass]="{'is-invalid': reloadRequestForm.controls['remark'].invalid && reloadRequestForm.controls['remark'].touched}"

          data-cy="remark">
      </div>
    </div>
  </div>
  <ngb-alert
    *ngIf="reloadRequest && reloadRequest.requestStatus && !originalFormVersion"
    [dismissible]="false"
    [type]="(reloadRequest.requestStatus === 'declined' ||
      reloadRequest.requestStatus === 'cancelled'
    ) ? 'warning' : 'success'"
  >
    <i *ngIf="reloadRequest.requestStatus === 'declined' ||
        reloadRequest.requestStatus === 'cancelled'"
      class="far fa-exclamation-triangle"
    ></i>
    <i *ngIf="reloadRequest.requestStatus !== 'declined' &&
        reloadRequest.requestStatus !== 'cancelled'"
      class="far fa-check"
    ></i>
    <span>
      {{ 'Status' | translate }}: {{ formatRequestStatus(reloadRequest.requestStatus) }}
      <span *ngIf="reloadRequest.requestStatus === 'declined'"> ({{ ('reload.api.message.' + reloadRequest.requestStatusDetail) | translate }})</span>
      <span *ngIf="(reloadRequest.requestStatus === 'approved' || reloadRequest.requestStatus === 'forced_approved') && reloadRequest.reloadRequestTransaction"> ({{ reloadRequest.reloadRequestTransaction.credits | currency: 'EUR' }})</span>
    </span>
  </ngb-alert>
  <div class="form-group" *ngIf="!readonly && !originalFormVersion">
    <label class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        formControlName="accept"
      />
      <span class="custom-control-label" data-cy="i-agree-with-these-conditions">
        {{ 'I agree with' | translate }} <a href="javascript:void(0)" (click)="showTermsAndConditionsPage(termsAndConditionsModal)">{{ 'Terms and Conditions' | translate }}</a>
      </span>
    </label>
  </div>
  <button *ngIf="!readonly"
    [disabled]="loading || reloadRequestForm.invalid || (!originalFormVersion && requestCost && requestCost > credits) || (!originalFormVersion && !requestCostCalculated)"
    class="btn btn-primary text-white mb-3"
    type="submit"
    data-cy="requestReloadButton"
  >
    <span class="icon">
      <i class="far" [ngClass]="{'fa-spin': loading, 'fa-recycle': !reloadRequest, 'fa-redo': reloadRequest}"></i>
    </span>
    <span>{{ (reloadRequest ? 'Retry' : 'Request Reload') | translate }}</span>
    <span *ngIf="!originalFormVersion && !forLiner && requestCost && !calculatingRequestDistance"> ({{ requestCost | currency: 'EUR'}})</span>
    <span *ngIf="!originalFormVersion && !forLiner && calculatingRequestDistance" class="icon"> <i class="far fa-spin fa-spinner"></i></span>
  </button>
  <button *ngIf="readonly && !originalFormVersion && !forLiner && reloadRequest && reloadRequest.requestStatus === 'declined'"
    class="btn btn-warning text-white mb-3"
    type="button"
    (click)="readonly = false; enableForm();"
    data-cy="editButton"
  >
    <span class="icon">
      <i class="far fa-pencil"></i>
    </span>
    <span>{{ 'Edit' | translate }}</span>
  </button>
  <button *ngIf="isForceApproveButtonShown()"
    class="btn btn-primary text-white mb-3"
    [disabled]="loading"
    type="button"
    (click)="forceApproveRequest.emit()"
    data-cy="forceApproveButton"
  >
    <span class="icon">
      <i class="far" [ngClass]="{'fa-spinner fa-spin': loading, 'fa-check': !loading}"></i>
    </span>
    <span>{{ 'Force approval' | translate }}</span>
  </button>
  <button *ngIf="isCancelButtonShown()"
    class="btn btn-danger text-white mb-3 mr-1"
    [disabled]="loading"
    type="button"
    (click)="cancelRequest.emit()"
    data-cy="cancelButton"
  >
    <span class="icon">
      <i class="far" [ngClass]="{'fa-spinner fa-spin': loading, 'fa-trash-alt': !loading}"></i>
    </span>
    <span>{{ 'Cancel' | translate }}</span>
  </button>
  <ngb-alert [dismissible]="false" *ngIf="!readonly && !originalFormVersion && !forLiner && requestCost && requestCost > credits" [type]="'danger'">
    <i class="far fa-exclamation-triangle"></i>
    <span>{{ 'You have insufficient credits.' | translate }} {{ 'Check the credit balance, add new credits.' | translate }}</span>
  </ngb-alert>
  <ngb-alert [dismissible]="false" *ngIf="!readonly && originalFormVersion" [type]="'warning'">
    <i class="far fa-exclamation-triangle"></i>
    <span>{{ 'Warning. The liner can charge you a fee for this request.' | translate }}</span>
  </ngb-alert>
  <ngb-alert [dismissible]="false" *ngIf="!readonly && !originalFormVersion && !forLiner && distanceBasedPrice && !calculatingRequestDistance && requestDistance && requestCost" [type]="'info'">
    <i class="far fa-question-circle"></i>
    <span>
      {{ 'The price is based on the distance you will save with this Reload.' | translate }}
      <br>
      {{ 'The locations are used to calculate your price.' | translate }}
      <a href="javascript:void(0)" (click)="showReloadDistanceBreakdown(reloadDistanceBreakdownModal)">
        {{ 'Click here to view the details.' | translate }}
      </a>
      <br>
      {{ 'The liner may request the CMR corresponding to this Reload, to verify the actual distance saved.' | translate }}
    </span>
  </ngb-alert>
</form>

<ng-template #reloadDistanceBreakdownModal let-close="dismiss">
  <div class="card">
    <div class="card-body">
      <table class="table">
        <tbody>
          <tr><td>+ {{ requestDistance.unloadLocation }} → {{ requestDistance.turnInLocation }} = {{ requestDistance.unload_turn_in | number:'1.2-2' }}KM</td></tr>
          <tr><td>+ {{ requestDistance.turnInLocation }} → {{ requestDistance.loadLocation }} = {{ requestDistance.turn_in_load | number:'1.2-2' }}KM</td></tr>
          <tr><td>- {{ requestDistance.unloadLocation }} → {{ requestDistance.loadLocation }} = {{ requestDistance.unload_load | number:'1.2-2' }}KM</td></tr>
        </tbody>
        <tfoot>
          <tr><th>{{ requestDistance.total | number:'1.2-2' }} {{ 'Saved empty kilometers' | translate }} = {{ requestCost | currency: 'EUR' }}</th></tr>
        </tfoot>
      </table>
      <div class="map mb-3" id="reload-triangulation-map"></div>
      <div class="d-flex justify-content-between">
        <button class="btn btn-default" (click)="close()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #termsAndConditionsModal let-close="dismiss">
  <div class="card">
    <div class="card-body">
      <div class="">
        <h6><b><u>GENERAL TERMS AND CONDITIONS GOVERNING THE USE OF THIS WEBSITE AND THE RELOAD OF MSC EQUIPMENT</u></b></h6>

        <h6><b><u>PREAMBLE</u></b></h6>
        <p>These general terms and conditions govern the use of this website and determine the rights and obligations of <b><u>MSC Geneva SA</u></b>, 12-14 Chemin Rieu, 1208 Geneva, Switzerland (hereinafter named “<b>MSC</b>”)  and of the party using this website for the purpose of making a request for the reload of a MSC container  ( hereinafter named  “<b>requesting party</b>”). By using this website, the “requesting party” accepts these general terms and conditions in full.</p>

        <h6><b><u>PURPOSE AND SCOPE</u></b></h6>
        <p>Under certain conditions and subject to their express approval, MSC can grant to the “requesting party”, making an application via this website for the reload of an empty MSC container out of import shipment for another export shipment, the request to reload this MSC container without previously returning same to a depot for inspection /repair/cleaning.
        MSC is at liberty to grant or refuse the request to reload MSC equipment – with or without cause - to impose certain conditions for the reload, or to limit the reload to a specific type /size of containers.</p>
          
        <h6><b><u>LIABILITIES</u></b></h6>
        <p>Provided MSC has granted the request for reload of a MSC container to the “requesting party”, the latter becomes fully and solely liable for the internal and external condition of the MSC equipment. By reusing the MSC equipment, the “requesting party” expressly confirms to have inspected the equipment and agrees/accepts this  equipment to be in clean, dry, odorless and good condition, i.e. free from any defects/ damage,  and does therefore not require any repair, inspection or cleaning. Furthermore by reusing the MSC equipment, the “requesting party” expressly acknowledges that the equipment meets with all the requirements for the export shipment of this equipment (such as - but not limited to - particular type of the container, maximum allowed payload, presence of a valid CSC plate, presence of ACEP label etc.)
        By consequence the “requesting party” confirms to be solely liable for the condition of the MSC equipment reloaded for export purposes and to hold harmless and indemnify MSC for all costs and consequences in case that the equipment would be refused at the load premises of the supplier or in case the cargo would appear to have suffered damage as a result of the condition of the MSC equipment reloaded for export shipment.
        In general the “requesting party” agrees to indemnify and hold harmless MSC in respect of any liability, loss, damage or expenses of whatsoever nature which MSC may sustain by reason of granting reload of MSC equipment to the “requesting party” without any previous inspection at a depot.</p>
          
        <h6><b><u>UNAUTHORIZED RELOAD</u></b></h6>
        <p>The reload of MSC equipment by the “requesting party” without previous express acceptance by MSC will constitute unlawful and unauthorized reload and will give rise to a penalty/fine of EUR 200,- (two hundred) to be charged by MSC to the “requesting  party”.
        All conditions – applicable in case of an authorized reload - will of course remain valid and binding the “requesting party” in case of unauthorized reload.</p>

        <h6><b><u>RELOAD FEE IN CASE OF AUTHORIZED RELOAD</u></b></h6>
        <p>The “requesting party” accepts to pay to MSC a fixed by distance based reload fee , agreed between the “requesting party” and MSC and resulting from the authorization for reload given by MSC.</p>
          
        <h6><b><u>DEMURRAGES/DETENTION CHARGES</u></b></h6>
        <p>In case of authorized reload of MSC equipment, the date of reload made by the “requesting party” will be considered as the date on which the import leg ends and the date on which the export leg starts, particularly enabling MSC to determine the date until which respectively as from which demurrages/detention charges should be calculated and invoiced to the import respectively export client. In case of dispute regarding this date, the “requesting party” has the burden of proof and agrees to indemnify MSC for the demurrages/detention charges which would appear to be unrecoverable from the import or export client due to this dispute.</p>
          
        <h6><b><u>LAW AND JURISDICTION</u></b></h6>
        <p>Failing amicable settlement, any disputes regarding the contents and/or interpretation of subject general terms and conditions will be submitted to the jurisdiction of the commercial courts of <b>Antwerp</b>, with <b>Belgian law</b> to be applied.</p>
          
        <h6><b><u>DISCLAIMER</u></b></h6>
        <p>Whilst MSC endeavor to ensure that the information on this website is correct, MSC does not warrant its completeness or accuracy, nor does MSC commit ensuring that the website remains available or that the material on this website is kept up to date. MSC cannot be held liable for incompleteness and/or incorrectness of the contents of this website and for the information available on this website.
        MSC will not be liable for any direct, indirect or consequential loss or damage arising under these terms and conditions or in connection with this website, except for liability which cannot be excluded under applicable law.</p>
        <button class="btn btn-default" (click)="close()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>