<div>
  <span class="d-flex justify-content-between align-items-center">
    <span>
      <span class="badge badge-demand">{{ 'Demand' | translate }}</span>
      <span class="ml-1">{{ tenant?.name }}</span>
    </span>
    <span>{{ demand.transportType?.label | titlecase | translate }}</span>
    <span>{{ demand.container?.containerType?.label }}</span>
    <span>{{ demand.container?.liner?.name | titlecase }}</span>
    <span
      >{{ demand.container?.weight }}
      <span *ngIf="demand.container?.weight">T</span>
    </span>
    <span>
      <span class="icon" *ngIf="demand.genset">
        <i class="far fa-snowflake text-info"></i>
      </span>
      <span class="icon" *ngIf="demand.container?.adr">
        <i class="far fa-exclamation-triangle text-danger"></i>
      </span>
    </span>
    <span *ngIf="!isMatchingPoolOption">{{
      demand.reference | titlecase
    }}</span>
    <ng-content></ng-content>
  </span>
  <!-- Origin -->
  <app-location
    *ngIf="
      demand.originLocation ||
      demand.originTimeIntervalStart ||
      demand.originTimeIntervalEnd
    "
    [type]="'origin'"
    [location]="demand.originLocation"
    [timeIntervalStart]="demand.originTimeIntervalStart"
    [timeIntervalEnd]="demand.originTimeIntervalEnd"
    [timeIntervalStartTimeSpecified]="
      demand.originTimeIntervalStartTimeSpecified
    "
    [timeIntervalEndTimeSpecified]="demand.originTimeIntervalEndTimeSpecified"
  ></app-location>
  <!-- Destination -->
  <app-location
    *ngIf="
      demand.destinationLocation ||
      demand.destinationTimeIntervalStart ||
      demand.destinationTimeIntervalEnd
    "
    [type]="'destination'"
    [location]="demand.destinationLocation"
    [timeIntervalStart]="demand.destinationTimeIntervalStart"
    [timeIntervalEnd]="demand.destinationTimeIntervalEnd"
    [timeIntervalStartTimeSpecified]="
      demand.destinationTimeIntervalStartTimeSpecified
    "
    [timeIntervalEndTimeSpecified]="
      demand.destinationTimeIntervalEndTimeSpecified
    "
  ></app-location>
  <div class="pt-2 text-smaller d-flex justify-content-between">
    <div>{{ demand.comment }}</div>
    <ng-content select="app-confidence-score"></ng-content>
  </div>
</div>
