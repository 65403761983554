<div class="m-3">
  <ngb-alert *ngIf="alert" [type]="alert.type" (close)="alert = null">{{ alert.message | translate }}</ngb-alert>
  <div class="card">
    <ngx-datatable
      class="bootstrap ml-0 mr-0"
      [rows]="liners"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [selectionType]="undefined"
      [loadingIndicator]="loading"
    >
      <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
      <ngx-datatable-column name="Short Name" prop="shortName"></ngx-datatable-column>
      <ngx-datatable-column name="" prop="">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <button class="btn btn-info" (click)="edit(row.id)">
            <i class="far fa-pencil"></i>
            <span>{{ 'Edit' | translate }}</span>
          </button>
          <button class="btn btn-danger" (click)="delete(row.id)">
            <i class="far fa-bomb"></i>
            <span>{{ 'Delete' | translate }}</span>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
