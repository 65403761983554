<div class="attachment">
  <input
    *ngIf="editMode"
    class="form-control"
    type="text"
    name="title"
    id="title"
    #title
    [(ngModel)]="attachmentTitle"
    (blur)="updateAttachment()"
    (keyup.enter)="updateAttachment()"
  />
  <span *ngIf="!editMode" class="attachment--title">{{ attachmentTitle }}</span>
  <span class="pl-3 pr-3">{{
    attachment.createdAt | date: 'dd/MM/yyyy hh:mm'
  }}</span>
  <div class="attachment--actions">
    <button
      type="button"
      *ngIf="!editMode"
      class="btn btn-sm"
      (click)="enableEditMode()"
    >
      <i class="far fa-fw fa-pencil"></i>
    </button>
    <app-button
      *ngIf="editMode"
      [btnClass]="'btn-sm'"
      [isLoading]="isUpdating"
      (buttonClicked)="updateAttachment()"
      [type]="'button'"
    >
      <i class="far fa-fw fa-save"></i>
    </app-button>
    <app-button
      [btnClass]="'btn-sm'"
      [isLoading]="isDownloading"
      (buttonClicked)="downloadAttachment()"
      [type]="'button'"
    >
      <i class="far fa-fw fa-download"></i>
    </app-button>
    <app-button
      [btnClass]="'btn-sm'"
      [isLoading]="isDeleting"
      (buttonClicked)="deleteAttachment()"
      [type]="'button'"
    >
      <i class="far fa-fw fa-trash-alt"></i>
    </app-button>
  </div>
</div>
