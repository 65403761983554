import { Actions, ofType } from '@ngrx/effects';
import * as UserTypes from 'app/core/store/types/user.types';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResetUserUpdate } from 'app/core/store/actions/user.actions';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

interface IAlert {
  icon: string;
  type: string;
  message: string;
}

@Component({
  selector: 'app-cmp-reset-update',
  templateUrl: './reset.update.component.html'
})
export class ResetUpdateComponent implements OnDestroy {
  code: string;
  user: string;

  newPass1: '';
  newPass2: '';

  alert: IAlert;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private store: Store<State>,
    private router: Router,
    private resetUpdates$: Actions,
    private resetFailed$: Actions,
    private toastr: ToastService
  ) {
    this.route.params.subscribe((params: Params) => {
      this.code = params['code'];
      this.user = params['user'];
    });
    resetUpdates$
      .pipe(ofType(UserTypes.users.RESET_UPDATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showSuccess({
          message: 'Reset successful!'
        });
        const message = 'Reset Password Successful! Please log in to continue.';
        this.router.navigate(['/login'], { queryParams: { message: message } });
      });

    resetFailed$
      .pipe(ofType(UserTypes.users.RESET_UPDATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.loading = false;
        if (data['payload'].error && data['payload'].error.violations) {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: data['payload'].error.violations[0].message
          });
        } else if (
          data['payload'].error &&
          data['payload'].error.detail === 'Reset code already used.'
        ) {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message:
              'This code is already used, please login with your password or request a new password.'
          });
        } else {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message:
              'Something went wrong while resetting your password. Please ' +
              'request a new reset and try again. If you keep encountering ' +
              'this issue, contact us at support@hakka.eu.'
          });
        }
      });
  }

  ngOnDestroy() { }

  reset() {
    if (this.newPass1 === this.newPass2) {
      this.store.dispatch(
        new ResetUserUpdate({
          code: this.code,
          user: this.user,
          password: this.newPass1
        })
      );
      this.loading = true;
    } else {
      this.alert = {
        icon: 'fa-exclamation-triangle',
        type: 'danger',
        message: 'Your passwords do not match'
      };
    }
  }

  closeAlert(alert: IAlert) {
    this.alert = null;
  }
}
