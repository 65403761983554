import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
// models
import { Liner } from 'app/core/store/models/liner.model';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { ContainerType } from 'app/core/store/models/container-types.model';

@Component({
  selector: 'app-cmp-new-transport-list',
  templateUrl: 'new-transport-list.component.html',
  styleUrls: [
    'new-transport-list.component.scss',
    '../new-marketpost.component.scss'
  ],
  providers: []
})
export class NewTransportListComponent {
  reversedOffers: any;
  reversedDemands: any;

  @Input() transportOffers;

  @Input() transportDemands;

  @Input() postType;

  @Input() containerTypes: ContainerType[];

  @Input() liners: Liner[];

  @Input() transportTypes: TransportType[];

  @Input() editPostindex: number;

  @Output() transportOffer = new EventEmitter<any>();

  @Output() transportDemand = new EventEmitter<any>();

  offersMapping: { [k: string]: string } = {
    '=0': '',
    '=1': '1 OFFER',
    other: '# OFFERS'
  };
  demandsMapping: { [k: string]: string } = {
    '=0': '',
    '=1': '1 DEMAND',
    other: '# DEMANDS'
  };

  removeTransportDemand(i) {
    this.transportDemands.splice(i, 1);
  }
  editTransportDemand(transport, index) {
    this.editPostindex = index;
    this.transportDemand.emit({ transport: transport, index: index });
  }
  removeTransportOffer(i) {
    this.transportOffers.splice(i, 1);
  }
  editTransportOffer(transport, index) {
    this.transportOffer.emit({
      transport: transport,
      index: index
    });
  }

  getTransportTypeLabel(transportType): string {
    const tType = this.transportTypes.find(tt => tt['@id'] === transportType);
    return tType.label;
  }

  getContainerTypeLabel(containerType): string {
    const cType = this.containerTypes.find(ct => ct['@id'] === containerType);
    return cType.label;
  }

  getLinerLabel(liner): string {
    const selectedLiner = this.liners.find(l => l['@id'] === liner);
    return selectedLiner.shortName || selectedLiner.name;
  }
}
