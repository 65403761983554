var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as types from '../types/session.types';
import * as UserTypes from '../types/user.types';
var INITIAL_STATE = localStorage.getItem('session')
    ? JSON.parse(localStorage.getItem('session'))
    : null;
export function reducer(state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case types.session.LOGIN:
            return state;
        case types.session.LOGIN_SUCCEEDED:
            var session = action.payload;
            session.expires_at = String(Date.now() + Number(session.expires_in) * 1000);
            session.access_token_stored_at = String(Date.now());
            return Object.assign({}, state, session);
        case types.session.REFRESH_SUCCEEDED:
            var newSession = action.payload;
            newSession.expires_at = String(Date.now() + Number(newSession.expires_in) * 1000);
            newSession.access_token_stored_at = String(Date.now());
            newSession.tenant = state.tenant;
            localStorage.setItem('session', JSON.stringify(newSession));
            return Object.assign({}, state, newSession);
        case UserTypes.users.USER_LOGOUT:
            return Object.assign({}, INITIAL_STATE);
        case types.session.PICK_TENANT:
            return __assign({}, state, { tenant: action.payload });
        case types.session.DROP_TENANT:
            return Object.assign({}, state, { tenant: undefined });
        case types.session.LOGOUT:
            return null;
    }
    return state;
}
// Some functions for getting straight to the state yii-haw.
// Checkout CreateSelector for advanced shizzles
export var getSession = function (state) { return state.session; };
export var getAccessToken = function (state) { return state.session.access_token; };
export var getRefreshToken = function (state) { return state.session.refresh_token; };
export var getAccessTokenExpiresAt = function (state) {
    return state.session.expires_at;
};
export var getActiveTenant = function (state) { return state.session.tenant; };
