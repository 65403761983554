import { DownloadService } from 'app/services/download.service';
import { CreateCreditNote, LoadCreditNotes } from 'app/core/store/actions/credit-note.actions';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Router } from '@angular/router';
import { LoadCustomers } from 'app/core/store/actions/customer.actions';
import { Customer } from 'app/core/store/models/customer.model';
import { LoadTaxRates } from 'app/core/store/actions/tax-rate.actions';
import * as CustmomerTypes from 'app/core/store/types/customer.types';
import * as TaxRateTypes from 'app/core/store/types/tax-rate.types';
import * as CreditNoteTypes from 'app/core/store/types/credit-note.types';
import { Actions, ofType } from '@ngrx/effects';
import { ToastService } from 'app/services/toast.service';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-credit-note',
  templateUrl: './new-credit-note.component.html',
})
export class NewCreditNoteComponent implements OnDestroy {
  isLoadingCustomers: boolean;
  customers: Customer[];
  isLoadingTaxRates: boolean;
  taxRates: any[];
  isLoading: boolean;
  downloadCreditNoteWhenReady: boolean;

  constructor(
    private store: Store<State>,
    private router: Router,
    private updates$: Actions,
    private toastr: ToastService,
    private downloadService: DownloadService
  ) {
    this.store
      .select(state => state.crm.customers)
      .pipe(untilDestroyed(this))
      .subscribe(customersState => {
        if (!customersState || !customersState.customers) {
          this.store.dispatch(new LoadCustomers());
          this.isLoadingCustomers = true;
          return;
        }
        this.customers = customersState.customers;
        this.isLoadingCustomers = false;
      });
    this.store
      .select(state => state.crm.taxRates)
      .pipe(untilDestroyed(this))
      .subscribe(taxRatesState => {
        if (!taxRatesState || !taxRatesState.taxRates) {
          this.store.dispatch(new LoadTaxRates());
          this.isLoadingTaxRates = true;
          return;
        }
        this.taxRates = taxRatesState.taxRates;
        this.isLoadingTaxRates = false;
      });
    this.updates$
      .pipe(ofType(CreditNoteTypes.creditNote.CREATE_CREDIT_NOTE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to create credit note!')
        });
        this.isLoading = false;
      });
    this.updates$
      .pipe(ofType(CreditNoteTypes.creditNote.CREATE_CREDIT_NOTE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.store.dispatch(new LoadCreditNotes());
        this.toastr.showSuccess({
          message: extract('Credit note created successfully!')
        });
        if (this.downloadCreditNoteWhenReady) {
          this.downloadService.downloadPDF(
            `/crm/credit_note/download/${response['payload'].id}`,
            response['payload'].number
          ).then(() => {
            this.router.navigateByUrl('/crm/credit-notes');
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          });
        }
        this.router.navigateByUrl('/crm/credit-notes');
      });
    this.updates$
      .pipe(ofType(CustmomerTypes.customer.LOAD_CUSTOMERS_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load customers')
        });
        this.isLoadingCustomers = false;
      });
    this.updates$
      .pipe(ofType(TaxRateTypes.taxRate.LOAD_TAX_RATES_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Failed to load tax rates')
        });
        this.isLoadingTaxRates = false;
      });
  }

  createCreditNote(creditNoteFormValue) {
    this.store.dispatch(new CreateCreditNote(creditNoteFormValue));
    this.isLoading = true;
  }

  createAndDownloadCreditNote(creditNoteFormValue) {
    this.createCreditNote(creditNoteFormValue);
    this.downloadCreditNoteWhenReady = true;
  }

  ngOnDestroy() { }
}
