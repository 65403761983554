import { Component, Input, OnDestroy } from '@angular/core';
import { Store, ActionsSubject } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadUserContexts } from 'app/core/store/actions/user-contexts.actions';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-user-contexts-table',
  templateUrl: './user-contexts-table.component.html',
})
export class UserContextsTableComponent implements OnDestroy {
  @Input()
  userContexts;
  @Input()
  totalElements: Number = 0;
  @Input()
  driver = false;
  @Input()
  isLoading: boolean;

  next: string;
  loading = false;

  constructor(
    private store: Store<State>,
    private actions: ActionsSubject,
    private router: Router
  ) {
    this.actions
      .pipe(
        untilDestroyed(this),
        filter(
          action =>
            action.type === '[UserContexts] Disable Success' ||
            action.type === '[UserContexts] Enable Success'
        )
      )
      .subscribe(() => {
        this.isLoading = false;
        this.store.dispatch(new LoadUserContexts());
      });
  }

  setPage($event) {
    this.store.dispatch(new LoadUserContexts({ page: $event.offset + 1 }));
  }

  goToCreate() {
    this.router.navigateByUrl('/settings/users/create', {
      skipLocationChange: true
    });
  }

  ngOnDestroy() { }
}
