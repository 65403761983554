import { Supplier } from '../models/supplier.model';
import * as types from '../types/supplier.types';
import * as businessContactTypes from '../types/business-contact.types';
import * as addressTypes from '../types/address.types';
import * as SupplierActions from '../actions/supplier.actions';
import { BusinessContact } from '../models/business-contact.model';
import { Address } from '../models/address.model';

export interface SuppliersState {
  suppliers: Supplier[];
  filteredSuppliers: Supplier[];
}

const INITIAL_STATE = {
  suppliers: null,
  filteredSuppliers: null
};

export function SuppliersReducer(
  state = INITIAL_STATE,
  action: SupplierActions.All
): SuppliersState {
  switch (action.type) {
    case types.supplier.LOAD_SUPPLIERS_SUCCEEDED:
      return Object.assign({}, state, {
        suppliers: [...action.payload['hydra:member']],
        filteredSuppliers: [...action.payload['hydra:member']]
      });
    case types.supplier.LOAD_FILTERED_SUPPLIERS_SUCCEEDED:
      return Object.assign({}, state, {
        filteredSuppliers: [...action.payload['hydra:member']]
      });
    case types.supplier.UPDATE_SUPPLIER_SUCCEEDED:
      if (!state || !state.suppliers || !state.filteredSuppliers) {
        return state;
      }
      let suppliers = [...state.suppliers];
      let filteredSuppliers = [...state.filteredSuppliers];

      let supplierIndex = suppliers.findIndex(i => i.id === action.payload.id);
      let tempSupplier = Object.assign(
        {},
        state.suppliers[supplierIndex],
        action.payload
      );
      suppliers[supplierIndex] = tempSupplier;
      let filteredSupplierIndex = filteredSuppliers.findIndex(
        i => i.id === action.payload.id
      );
      let tempFilteredSupplier = Object.assign(
        {},
        filteredSuppliers[filteredSupplierIndex],
        action.payload
      );
      filteredSuppliers[filteredSupplierIndex] = tempFilteredSupplier;
      return Object.assign({}, state, {
        suppliers,
        filteredSuppliers
      });
    case types.supplier.DELETE_SUPPLIER_SUCCEEDED:
      if (!state || !state.suppliers || !state.filteredSuppliers) {
        return state;
      }
      suppliers = [
        ...state.suppliers.filter(
          (supplier: Supplier) => supplier.id !== action.payload
        )
      ];
      filteredSuppliers = [
        ...state.filteredSuppliers.filter(
          (supplier: Supplier) => supplier.id !== action.payload
        )
      ];
      return Object.assign({}, state, {
        suppliers,
        filteredSuppliers
      });
    case businessContactTypes.BusinessContact.DELETE_BUSINESS_CONTACT_SUCCEEDED:
      if (!state || !state.suppliers || !state.filteredSuppliers) {
        return state;
      }
      suppliers = [...state.suppliers];
      filteredSuppliers = [...state.filteredSuppliers];
      supplierIndex = suppliers.findIndex(
        i => i.id === action.payload.businessId
      );
      filteredSupplierIndex = filteredSuppliers.findIndex(
        i => i.id === action.payload.businessId
      );
      if (supplierIndex !== -1) {
        tempSupplier = Object.assign(
          {},
          state.suppliers[supplierIndex]
        ) as Supplier;
        tempSupplier.contacts = tempSupplier.contacts.filter(
          (c: BusinessContact) => c.id !== action.payload.businessContactId
        );
        suppliers[supplierIndex] = tempSupplier;
      }

      if (filteredSupplierIndex !== -1) {
        tempFilteredSupplier = Object.assign(
          {},
          state.filteredSuppliers[filteredSupplierIndex]
        ) as Supplier;
        tempFilteredSupplier.contacts = tempFilteredSupplier.contacts.filter(
          (c: BusinessContact) => c.id !== action.payload.businessContactId
        );
        filteredSuppliers[filteredSupplierIndex] = tempFilteredSupplier;
      }

      return Object.assign({}, state, {
        suppliers,
        filteredSuppliers
      });
    case addressTypes.address.DELETE_ADDRESS_SUCCEEDED:
      if (!state || !state.suppliers || !state.filteredSuppliers) {
        return state;
      }
      suppliers = [...state.suppliers];
      filteredSuppliers = [...state.filteredSuppliers];
      supplierIndex = suppliers.findIndex(
        i => i.id === action.payload.businessId
      );
      filteredSupplierIndex = filteredSuppliers.findIndex(
        i => i.id === action.payload.businessId
      );
      if (supplierIndex !== -1) {
        tempSupplier = Object.assign(
          {},
          state.suppliers[supplierIndex]
        ) as Supplier;
        tempSupplier.addresses = tempSupplier.addresses.filter(
          (a: Address) => a.id !== action.payload.addressId
        );
        suppliers[supplierIndex] = tempSupplier;
      }

      if (filteredSupplierIndex !== -1) {
        tempFilteredSupplier = Object.assign(
          {},
          state.filteredSuppliers[filteredSupplierIndex]
        ) as Supplier;
        tempFilteredSupplier.addresses = tempFilteredSupplier.addresses.filter(
          (a: Address) => a.id !== action.payload.addressId
        );
        filteredSuppliers[filteredSupplierIndex] = tempFilteredSupplier;
      }

      return Object.assign({}, state, {
        suppliers,
        filteredSuppliers
      });
    case types.supplier.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
  }
  return state;
}
