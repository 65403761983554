var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import * as types from '../types/transport-task.types';
import * as TransportTaskActions from '../actions/transport-task.actions';
import { ApiService } from '../../api/api.service';
var TransportTaskEffects = /** @class */ (function () {
    function TransportTaskEffects(api, actions$) {
        var _this = this;
        this.api = api;
        this.actions$ = actions$;
        this.deleteAddress$ = this.actions$.pipe(ofType(types.transportTask.DELETE_TRANSPORT_TASK), map(function (transportTask) { return transportTask.payload; }), concatMap(function (payload) { return _this.api.delete({
            path: "/transport_tasks/" + payload.transportTaskId
        }).pipe(map(function () { return new TransportTaskActions.DeleteTransportTaskSuccess(payload); }), catchError(function (error) { return of(new TransportTaskActions.DeleteTransportTaskFail(error)); })); }));
        this.updateTransportTask$ = this.actions$.pipe(ofType(types.transportTask.UPDATE_TRANSPORT_TASK), map(function (action) { return action.payload; }), concatMap(function (payload) { return _this.api.put({
            path: "/transport_tasks/" + payload.transportTaskId,
            body: payload.body
        }).pipe(map(function (data) { return new TransportTaskActions.UpdateTransportTaskSuccess(data); }), catchError(function (error) { return of(new TransportTaskActions.UpdateTransportTaskFail(error)); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportTaskEffects.prototype, "deleteAddress$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], TransportTaskEffects.prototype, "updateTransportTask$", void 0);
    return TransportTaskEffects;
}());
export { TransportTaskEffects };
