import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/purchase-order.types';
import * as PurchaseOrderActions from '../actions/purchase-order.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class PurchaseOrderEffects {
  constructor(private api: ApiService, private actions$: Actions) { }

  @Effect()
  loadPurchaseOrders$: Observable<Action> = this.actions$.pipe(
    ofType(types.purchaseOrder.LOAD_PURCHASE_ORDERS),
    map((action: PurchaseOrderActions.LoadPurchaseOrders) => action.payload),
    concatMap(payload => {
      const page = payload && payload.page ? payload.page : 1;
      return this.api.get({ path: `/crm/purchase_orders?page=${page}` }).pipe(
        map(data => new PurchaseOrderActions.LoadPurchaseOrdersSuccess(data)),
        catchError(error => of(new PurchaseOrderActions.LoadPurchaseOrdersFail(error)))
      );
    }),
  );

  @Effect()
  loadFilteredPurchaseOrders$: Observable<Action> = this.actions$.pipe(
    ofType(types.purchaseOrder.LOAD_FILTERED_PURCHASE_ORDERS),
    map((action: PurchaseOrderActions.LoadFilteredPurchaseOrders) => action.payload),
    concatMap(payload => {
      const query = payload && payload.query ? payload.query : '';
      const page = payload && payload.page ? payload.page : 1;
      return this.api.get({
        path: `/crm/purchase_orders?page=${page}&business.label=${query}&number=${query}&notes=${query}`
      }).pipe(
        map(data => new PurchaseOrderActions.LoadFilteredPurchaseOrdersSuccess(data)),
        catchError(error => of(new PurchaseOrderActions.LoadFilteredPurchaseOrdersFail(error)))
      );
    }),
  );

  @Effect()
  createPurchaseOrder$: Observable<Action> = this.actions$.pipe(
    ofType(types.purchaseOrder.CREATE_PURCHASE_ORDER),
    map((action: PurchaseOrderActions.CreatePurchaseOrder) => action.payload),
    concatMap(payload => this.api.post({
      path: `/crm/purchase_orders`, body: payload
    })),
    map(data => new PurchaseOrderActions.CreatePurchaseOrderSuccess(data)),
    catchError(error => of(new PurchaseOrderActions.CreatePurchaseOrderFail(error)))
  );

  @Effect()
  updatePurchaseOrder$: Observable<Action> = this.actions$.pipe(
    ofType(types.purchaseOrder.UPDATE_PURCHASE_ORDER),
    map((action: PurchaseOrderActions.UpdatePurchaseOrder) => action.payload),
    concatMap(payload => this.api.put({
      path: `/crm/purchase_orders/${payload.purchaseOrderId}`,
      body: payload.body
    }).pipe(
      map(data => new PurchaseOrderActions.UpdatePurchaseOrderSuccess(data)),
      catchError(error => of(new PurchaseOrderActions.UpdatePurchaseOrderFail(error)))
    )),
  );

  @Effect()
  deletePurchaseOrder$: Observable<Action> = this.actions$.pipe(
    ofType(types.purchaseOrder.DELETE_PURCHASE_ORDER),
    map((action: PurchaseOrderActions.DeletePurchaseOrder) => action.payload),
    concatMap(payload => this.api.delete({
      path: `/crm/purchase_orders/${payload.purchaseOrderId}`
    }).pipe(
      map(() => new PurchaseOrderActions.DeletePurchaseOrderSuccess(payload.purchaseOrderId)),
      catchError(error => of(new PurchaseOrderActions.DeletePurchaseOrderFail(error)))
    )),
  );
}
