/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../credit-note-form/credit-note-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../shared/datepicker-config";
import * as i4 from "../credit-note-form/credit-note-form.component";
import * as i5 from "@angular/router";
import * as i6 from "../../core/api/api.service";
import * as i7 from "@angular/forms";
import * as i8 from "./new-credit-note.component";
import * as i9 from "@ngrx/store";
import * as i10 from "@ngrx/effects";
import * as i11 from "../../services/toast.service";
import * as i12 from "../../services/download.service";
var styles_NewCreditNoteComponent = [];
var RenderType_NewCreditNoteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewCreditNoteComponent, data: {} });
export { RenderType_NewCreditNoteComponent as RenderType_NewCreditNoteComponent };
export function View_NewCreditNoteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-credit-note-form", [], null, [[null, "creditNoteFormSubmitted"], [null, "onSaveAndDownloadAsPDF"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("creditNoteFormSubmitted" === en)) {
        var pd_0 = (_co.createCreditNote($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSaveAndDownloadAsPDF" === en)) {
        var pd_1 = (_co.createAndDownloadCreditNote($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CreditNoteFormComponent_0, i1.RenderType_CreditNoteFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i2.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 573440, null, 0, i4.CreditNoteFormComponent, [i5.ActivatedRoute, i6.ApiService, i7.FormBuilder], { customers: [0, "customers"], isLoading: [1, "isLoading"], taxRates: [2, "taxRates"] }, { creditNoteFormSubmitted: "creditNoteFormSubmitted", onSaveAndDownloadAsPDF: "onSaveAndDownloadAsPDF" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customers; var currVal_1 = _co.isLoading; var currVal_2 = _co.taxRates; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_NewCreditNoteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-credit-note", [], null, null, null, View_NewCreditNoteComponent_0, RenderType_NewCreditNoteComponent)), i0.ɵdid(1, 180224, null, 0, i8.NewCreditNoteComponent, [i9.Store, i5.Router, i10.Actions, i11.ToastService, i12.DownloadService], null, null)], null, null); }
var NewCreditNoteComponentNgFactory = i0.ɵccf("app-new-credit-note", i8.NewCreditNoteComponent, View_NewCreditNoteComponent_Host_0, {}, {}, []);
export { NewCreditNoteComponentNgFactory as NewCreditNoteComponentNgFactory };
