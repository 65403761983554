<div class="row mr-0">
  <app-cmp-market-toolbar
    class="col-12"
    [map]="false"
    [filters]="true"
    [search]="true"
    [newPost]="true"
    [messages]="true"
    [viewToggle]="true"
    (viewToggled)="viewToggled($event)"
    (messageBoardToggled)="messageBoardToggled($event)"
    (mapToggled)="mapToggled($event)"
    (filterToggled)="filterToggled($event)"
  >
  </app-cmp-market-toolbar>
</div>
<div class="row mr-0">
  <div class="col">
    <div
      class="search-results"
      style="height: 100%;"
      data-infinite-scroll
      debounce
      [infiniteScrollDistance]="8"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScrollDown()"
    >
      <div class="row">
        <div class="col-md-3" *ngIf="showFilter">
          <div class="row">
            <div class="col pr-0">
              <app-cmp-filter
                (filtered)="filteredEvent($event)"
              ></app-cmp-filter>
            </div>
          </div>
        </div>
        <div
          [ngClass]="{
            'col-9':
              (showFilter && !showMessageBoard) ||
              (!showFilter && showMessageBoard),
            'col-12': !showFilter && !showMessageBoard,
            'col-6': showFilter && showMessageBoard
          }"
        >
          <div class="row">
            <div class="col-12 mb-4" *ngIf="showMap">
              <app-cmp-market-post-map
                [updateSize]="[showFilter, showMessageBoard]"
              ></app-cmp-market-post-map>
            </div>
            <div class="col">
              <app-cmp-market-posts
                [showFilter]="showFilter"
                [showMB]="showMessageBoard"
                [cardView]="cardView"
                [filtered]="filtered"
              ></app-cmp-market-posts>
            </div>
          </div>
        </div>
        <div class="col-md-3" *ngIf="showMessageBoard">
          <div class="sticky">
            <app-cmp-message-board></app-cmp-message-board>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
