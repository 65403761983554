<div class="card" *ngIf="psa$ | async as psa">
  <h3 class="card-header">{{ 'PSA ePortal account status' | translate }}</h3>
  <div class="card-body container-fluid" *ngIf="wallet$ | async as wallet">
    <div class="row">

      <div class="col">
        <table border="0">
          <tbody>
            <tr>
              <td>{{ 'ePortal account' | translate }}:</td>
              <td>{{ psa.id }}</td>
            </tr>
            <tr>
              <td>{{ 'Available credits' | translate }}:</td>
              <td>{{ wallet.credits }}</td>
            </tr>
            <tr>
              <td>{{ 'Threshold' | translate }}:</td>
              <td>{{ psa.creditsThreshold }}</td>
            </tr>
            <tr>
              <td>{{ 'Status' | translate }}:</td>
              <td *ngIf="psa.blocked" class="text-red">
                {{ 'Blocked' | translate }}
              </td>
              <td *ngIf="!psa.blocked" class="text-green">
                {{ 'Active' | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col status-display" *ngIf="wallet.warningThreshold || psa.creditsThreshold as warn">
        <i class="fas fa-fw fa-check"
          *ngIf="wallet.credits >= warn"></i>
        <i class="fas fa-fw fa-exclamation"
          *ngIf="wallet.credits < warn && wallet.credits >= psa.creditsThreshold"></i>
        <i class="fas fa-fw fa-times"
          *ngIf="wallet.credits < psa.creditsThreshold"></i>
      </div>
    </div>
    <p class="pt-2 mb-0" *ngIf="wallet.credits >= psa.creditsThreshold">
      {{ reminderMessage$ | async }}
    </p>
    <p class="pt-2" *ngIf="wallet.credits < psa.creditsThreshold">
      {{ warningMessage$ | async }}
    </p>
  </div>
</div>
