import { IconService } from 'app/services/icon.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {
  NgbDateNativeAdapter,
  NgbDateCustomParserFormatter
} from 'app/shared/datepicker-config';
import { Liner } from 'app/core/store/models/liner.model';
import { ContainerType } from 'app/core/store/models/container-types.model';
import { Autocomplete } from 'app/services/autocomplete.service';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { TransportType } from 'app/core/store/models/transport-types.model';
import { Observable } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import * as DateFns from 'date-fns';

// rxjs
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  NgbModal,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from 'app/services/location.service';

@Component({
  selector: 'app-cmp-new-marketpost-offer',
  templateUrl: 'new-marketpost-offer.component.html',
  styleUrls: [
    'new-marketpost-offer.component.scss',
    '../new-marketpost.component.scss'
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class NewMarketpostOfferComponent implements OnInit, OnChanges {
  @Input()
  title: string;
  @Input()
  transportTypes: TransportType[];
  @Input()
  liners: Liner[];
  @Input()
  containerTypes: ContainerType[];
  @Input()
  transportOffer: Transport;
  @Input()
  index: number;
  @Output()
  transportOfferAdded = new EventEmitter<any>();

  transportForm: FormGroup;
  savedChanges = false;
  edit: boolean;

  constructor(
    private fb: FormBuilder,
    private autocompleteService: Autocomplete,
    public locationService: LocationService,
    private modalService: NgbModal,
    private iconService: IconService
  ) { }

  ngOnInit() {
    if (!this.transportForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transportOffer && changes.transportOffer.currentValue) {
      this.savedChanges = false;
      if (this.transportTypes) {
        this.createForm();
        this.setTransportTasks();
        this.patchTransportForm(changes.transportOffer.currentValue);
      }
    }
    if (changes.transportTypes && changes.transportTypes.currentValue) {
      if (!this.transportOffer) {
        this.createForm();
        this.setTransportTasks();
      } else if (this.transportOffer) {
        this.patchTransportForm(this.transportOffer);
      }
    }
  }

  createForm() {
    this.transportForm = this.fb.group({
      container: this.fb.group({
        containerType: [null],
        liner: [null],
        weight: [null, this.numberValidator(/^((\d+\.?|\.(?=\d))?\d{0,9})$/)],
        adr: [false]
      }),
      reference: [''],
      genset: [{ value: false, disabled: true }],
      transportType: [
        this.transportTypes && this.transportTypes.length !== 0
          ? this.transportTypes[0]
          : null,
        Validators.required
      ],
      // TransportTasks depend on the transport type.
      transportTasks: this.fb.array([]),
      quantity: [1, Validators.min(0)],
      comment: ['']
    });
  }

  get transportTasks(): FormArray {
    if (this.transportForm) {
      return this.transportForm.get('transportTasks') as FormArray;
    }
  }

  get comment() {
    return this.transportForm.get('comment').value;
  }

  get weight() {
    return this.transportForm.get('container.weight');
  }

  getContainerType(containerType): ContainerType {
    if (this.containerTypes) {
      return this.containerTypes.find(ct => ct['@id'] === containerType);
    }
  }
  setTransportTasks() {
    if (this.transportForm.get('transportType').value) {
      const tasks = this.transportForm.get('transportType').value[
        'transportTypeTasks'
      ];
      if (tasks) {
        const taskFGs = tasks.map(task =>
          this.fb.group({
            taskType: this.fb.group({
              '@id': task.taskType['@id'],
              icon: this.getTaskIcon(task),
              id: task.taskType.id,
              label: task.taskType.code
            }),
            location: [],
            startDate: [],
            startTime: [],
            endDate: [],
            endTime: [],
            startDateTimeSpecified: [false],
            endDateTimeSpecified: [false]
          })
        );
        const taskFormArray = this.fb.array(taskFGs);
        this.transportForm.setControl('transportTasks', taskFormArray);
      }
    }
  }

  selectContainerType(cType) {
    this.checkReefer(cType);
  }
  onSubmit() {
    const payload = {
      transport: JSON.parse(JSON.stringify(this.transportForm.value)),
      index: this.index
    };
    this.transportOfferAdded.emit(payload);
    // this fixes the transport tasks being reset after submit
    const tasks = this.transportForm.get('transportTasks').value;
    tasks.forEach(task => {
      const tasksInfo = this.transportForm.get('transportType').value[
        'transportTypeTasks'
      ];
      const taskInfo = tasksInfo.find(t => t['@id'] === task.taskType['id']);
    });
    this.transportForm.get('transportTasks').patchValue(tasks);
    if (this.transportOffer) {
      this.savedChanges = true;
      this.transportOffer = null;
    }
  }

  // Autocomplete container_type
  searchMapping(searchType) {
    return searchType === 'searchContainerType'
      ? this.searchContainerType
      : this.searchLiner;
  }

  searchContainerType = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.autocompleteService.search('container_type', term);
      })
    )
  formatter = (x: { label: string }) => x.label;

  // Autocomplete liner
  searchLiner = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.autocompleteService.search('liner', term);
      })
    )

  locationFormatter = (x: { name: String }) => {
    return x.name;
  }

  searchLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        return this.locationService.location(term);
      })
    )

  getTaskIcon(task) {
    return this.iconService.getTransportTaskIcon(task);
  }
  getTypeIcon(type) {
    return this.iconService.getTransportTypeIcon(type);
  }

  checkReefer($event) {
    if ($event) {
      $event.is_reefer || $event.code.toLowerCase().indexOf('reefer') !== -1
        ? this.transportForm.controls['genset'].enable()
        : this.transportForm.controls['genset'].disable();
    }
  }

  hasAtLeastOneLocation() {
    return this.transportTasks.controls.some(task => task.value.location);
  }

  open(event, content) {
    event.preventDefault();
    this.modalService.open(content);
  }

  setTransportType(type) {
    this.transportForm.get('transportType').patchValue(type);
    this.setTransportTasks();
  }

  /**
   * Custom validator that uses a regex to check if a formcontrol is a number or decimal.
   * @param numberRe regex that checkes if the formcontrol value is a number or a decimal.
   */
  numberValidator(numberRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value === null) {
        return null;
      }
      const notNumber = numberRe.test(control.value);
      return notNumber ? null : { notNumber: { value: control.value } };
    };
  }

  patchTransportForm(offer) {
    this.edit = true;
    // patch form
    this.transportForm.patchValue({
      container: {
        weight: offer.container.weight ? offer.container.weight : null,
        adr: offer.container.adr
      },
      reference: offer.reference,
      comment: offer.comment,
      genset: offer.genset,
      quantity: offer.quantity ? offer.quantity : 1
    });
    if (offer.container.liner && this.liners) {
      this.transportForm.get('container').patchValue({
        liner: this.liners.find(liner => liner['@id'] === offer.container.liner)
          .id
      });
    } else {
      this.transportForm.get('container').patchValue({
        liner: null
      });
    }
    if (offer.container.containerType && this.containerTypes) {
      this.transportForm.get('container').patchValue({
        containerType: this.containerTypes.find(
          ct => ct['@id'] === offer.container.containerType
        ).id
      });
    } else {
      this.transportForm.get('container').patchValue({
        containerType: null
      });
    }
    if (typeof offer.transportType === 'string') {
      this.transportForm
        .get('transportType')
        .setValue(
          this.transportTypes.find(tt => tt['@id'] === offer.transportType)
        );
    } else {
      this.transportForm
        .get('transportType')
        .setValue(
          this.transportTypes.find(
            tt => tt['@id'] === offer.transportType['@id']
          )
        );
    }
    this.setTransportTasks();
    offer.transportTasks = offer.transportTasks.map(task => {
      return {
        taskType: task.taskType ? task.taskType : null,
        location: task.location ? task.location : null,
        startDateTimeSpecified: task.startDateTimeSpecified,
        endDateTimeSpecified: task.endDateTimeSpecified,
        startDate: task.startDate
          ? new Date(
            moment(task.startDate).year(),
            moment(task.startDate).month(),
            moment(task.startDate).date()
          )
          : null,
        startTime:
          task.startDate && task.startDateTimeSpecified && !task.startTime
            ? {
              hour: moment(task.startDate).hours(),
              minute: moment(task.startDate).minutes(),
              second: 0
            }
            : task.startTime || null,
        endDate: task.endDate
          ? new Date(
            moment(task.endDate).year(),
            moment(task.endDate).month(),
            moment(task.endDate).date()
          )
          : null,
        endTime:
          task.endDate && task.endDateTimeSpecified && !task.endTime
            ? {
              hour: moment(task.endDate).hours(),
              minute: moment(task.endDate).minutes(),
              second: 0
            }
            : task.endTime || null
      };
    });
    offer.transportTasks.forEach(task => {
      const index = this.transportForm
        .get('transportTasks')
      ['controls'].indexOf(
        this.transportForm.get('transportTasks')['controls'].find(control => {
          return (
            control.value.taskType['@id'] ===
            (task.taskType['@id'] ? task.taskType['@id'] : task.taskType)
          );
        })
      );
      if (index !== -1) {
        this.transportTasks.at(index).patchValue(task);
      }
    });
  }

  /**
   *
   * @param date date object given by the datepicker
   * @param current the current year and month
   * @returns boolean if given date is before today
   */
  getDisabledDates(date: NgbDateStruct): boolean {
    const today = new Date();
    let d = new Date(date.year, date.month, date.day);
    d = DateFns.subMonths(d, 1);
    d = DateFns.addDays(d, 1);
    return DateFns.isBefore(d, today);
  }
}
