import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { ApiService } from 'app/core/api/api.service';
import { LoadShippingAgents } from 'app/core/store/actions/shipping-agent.actions';
import { ShippingAgent } from 'app/core/store/models/shipping-agent.model';
import { State } from 'app/core/store/store.model';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-manage-shipping-agents',
  templateUrl: './manage-shipping-agents.component.html',
  styleUrls: ['./manage-shipping-agents.component.scss']
})
export class ManageShippingAgentsComponent implements OnDestroy {
  totalItems: number;
  shippingAgents: ShippingAgent[];
  loading = false;

  constructor(
    private store: Store<State>,
    private router: Router,
    private api: ApiService,
    private toastr: ToastService,
    private modalService: NgbModal,
  ) {
    this.loading = true;
    this.store.dispatch(new LoadShippingAgents());
    this.store
      .select(state => state.shippingAgents)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (data) {
          this.loading = false;
          this.shippingAgents = data;
          this.totalItems = data.length;
        }
      });
  }

  edit(id: string) {
    this.router.navigate(['superadmin/shipping-agents', id, 'edit']);
  }
  delete(id: string) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract('Confirmation required');
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => this.api.put({
        path: '/shipping_agents/' + id,
        body: { tenants: [] }
      })
      .subscribe(
        () => this.api.delete({ path: `/shipping_agents/${id}` }).subscribe(
          () => {
            this.loading = true;
            this.store.dispatch(new LoadShippingAgents());
            this.toastr.showSuccess({
              message: 'Shipping Agent deleted!'
            });
          },
          () => {
            this.toastr.showDanger({
              title: 'Something went wrong',
              message: 'Failed to delete Shipping Agent'
            });
          }
        ),
        err => {
          this.toastr.showDanger({
            title: 'Something went wrong',
            message: err
          });
        }
      ));
  }

  ngOnDestroy() { }
}
