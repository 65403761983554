/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../purchase-order-form/purchase-order-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../shared/datepicker-config";
import * as i4 from "../purchase-order-form/purchase-order-form.component";
import * as i5 from "../../services/icon.service";
import * as i6 from "@angular/forms";
import * as i7 from "./edit-purchase-order.component";
import * as i8 from "@angular/router";
import * as i9 from "@ngrx/effects";
import * as i10 from "@ngrx/store";
import * as i11 from "../../services/download.service";
import * as i12 from "../../services/toast.service";
import * as i13 from "../../core/api/api.service";
var styles_EditPurchaseOrderComponent = [];
var RenderType_EditPurchaseOrderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditPurchaseOrderComponent, data: {} });
export { RenderType_EditPurchaseOrderComponent as RenderType_EditPurchaseOrderComponent };
export function View_EditPurchaseOrderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-purchase-order-form", [], null, [[null, "purchaseOrderFormSubmitted"], [null, "onSaveAndDownloadAsPDF"], [null, "searchTransports"], [null, "selectTransport"], [null, "removeTransport"], [null, "supplierSelected"], [null, "loadNextTransportPage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("purchaseOrderFormSubmitted" === en)) {
        var pd_0 = (_co.updatePurchaseOrder($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSaveAndDownloadAsPDF" === en)) {
        var pd_1 = (_co.updateAndDownloadAsPDF($event) !== false);
        ad = (pd_1 && ad);
    } if (("searchTransports" === en)) {
        var pd_2 = (_co.handleSearchTransports($event) !== false);
        ad = (pd_2 && ad);
    } if (("selectTransport" === en)) {
        var pd_3 = (_co.handleSelectTransport($event) !== false);
        ad = (pd_3 && ad);
    } if (("removeTransport" === en)) {
        var pd_4 = (_co.handleRemoveTransport($event) !== false);
        ad = (pd_4 && ad);
    } if (("supplierSelected" === en)) {
        var pd_5 = (_co.handleSelectSupplier($event) !== false);
        ad = (pd_5 && ad);
    } if (("loadNextTransportPage" === en)) {
        var pd_6 = (_co.loadNextTransportsPage() !== false);
        ad = (pd_6 && ad);
    } return ad; }, i1.View_PurchaseOrderFormComponent_0, i1.RenderType_PurchaseOrderFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i2.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(3, 573440, null, 0, i4.PurchaseOrderFormComponent, [i5.IconService, i6.FormBuilder, i2.NgbModal], { suppliers: [0, "suppliers"], taxRates: [1, "taxRates"], isLoadingTaxRates: [2, "isLoadingTaxRates"], isLoading: [3, "isLoading"], transports: [4, "transports"], purchaseOrder: [5, "purchaseOrder"] }, { purchaseOrderFormSubmitted: "purchaseOrderFormSubmitted", onSaveAndDownloadAsPDF: "onSaveAndDownloadAsPDF", searchTransports: "searchTransports", selectTransport: "selectTransport", removeTransport: "removeTransport", supplierSelected: "supplierSelected", loadNextTransportPage: "loadNextTransportPage" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.suppliers; var currVal_1 = _co.taxRates; var currVal_2 = _co.isLoadingTaxRates; var currVal_3 = _co.isLoading; var currVal_4 = _co.filteredTransports; var currVal_5 = _co.purchaseOrder; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_EditPurchaseOrderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-purchase-order", [], null, null, null, View_EditPurchaseOrderComponent_0, RenderType_EditPurchaseOrderComponent)), i0.ɵdid(1, 245760, null, 0, i7.EditPurchaseOrderComponent, [i8.ActivatedRoute, i8.Router, i9.Actions, i10.Store, i11.DownloadService, i12.ToastService, i13.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditPurchaseOrderComponentNgFactory = i0.ɵccf("app-edit-purchase-order", i7.EditPurchaseOrderComponent, View_EditPurchaseOrderComponent_Host_0, {}, {}, []);
export { EditPurchaseOrderComponentNgFactory as EditPurchaseOrderComponentNgFactory };
