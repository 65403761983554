<div
  class="card bg-white rounded transport-info shadow-sm "
  [class.planned]="planned"
  [class.is-loading]="transport && transport['isLoading']"
>
  <div class="card-header d-flex justify-content-between">
    <div>
      <i class="far fa-fw" [ngClass]="getTransportIcon()"></i> &nbsp;
      <small>{{
        transport?.transportType?.label | translate | titlecase
      }}</small>
    </div>
    <div>
      <span>{{ transport?.primaryTaskDate | date: 'dd/MM' }}</span
      >&nbsp;
      <span *ngIf="transport?.primaryTaskDateTimeSpecified">{{
        transport?.primaryTaskDate | date: 'HH:mm'
      }}</span>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="transport && transport['isLoading']" class="loading-wrapper">
      <div class="spinner"></div>
    </div>
    <div class="d-flex justify-content-between mb-2">
      <small></small>
      <small ngbTooltip="{{ 'Edit transport' | translate }}" placement="left">
        <a [routerLink]="['/tms/transports', transport.id, 'edit']">{{
          transport.reference
        }}</a>
      </small>
    </div>
    <div
      *ngIf="transport?.container?.liner || transport?.container?.containerType"
      class="d-flex justify-content-between mb-2"
    >
      <small>{{ transport.container?.liner?.shortName }}</small>
      <small>{{ transport.container?.containerType?.label }}</small>
    </div>
    <div>
      <span *ngFor="let task of transport?.transportTasks; let i = index">
        <span class="badge" [ngClass]="task['badgeColor'] || 'badge-secondary'">
          <span *ngIf="task.location && !task.privateLocation">{{
            task.location?.shortName || task.location?.name
          }}</span>
          <span *ngIf="task.privateLocation"
            >{{ task.privateLocation.shortName || task.privateLocation.name }}
            <span
              *ngIf="
                DISPLAY_PUBLIC_LOC_TRANSPORT_TASKS.indexOf(
                  task.taskType.code
                ) !== -1 && task.location
              "
              >({{ task.location.name }})</span
            ></span
          >
          <span *ngIf="!task.privateLocation && !task.location">{{
            'N.A.' | translate
          }}</span>
        </span>
        <i *ngIf="i !== numberOfTasks - 1" class="far fa-fw fa-angle-right"></i>
      </span>
    </div>
    <div *ngIf="error">
      <span class="text-danger">{{ error }}</span>
    </div>
  </div>
  <ng-content></ng-content>
</div>
