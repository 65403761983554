import { ToastService } from 'app/services/toast.service';
import { Actions, ofType } from '@ngrx/effects';
import { VectorLayer } from 'ol/layer/vector';
import { CreateUserLocation } from 'app/core/store/actions/location.actions';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { State } from 'app/core/store/store.model';
import * as UserLocationTypes from 'app/core/store/types/location.types';
import { Router } from '@angular/router';
import { extract } from 'app/services/i18n.service';
import { HakkaMap } from '../../../marketplace/market-posts/market-post-map/map/hakka-map';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-user-location',
  templateUrl: './new-user-location.component.html',
  styleUrls: ['./new-user-location.component.scss']
})
export class NewUserLocationComponent implements OnInit, OnDestroy {
  title = extract('Add New Location');
  isLoading: boolean;

  hakkaMap: HakkaMap;
  markerLayer: VectorLayer;
  markers = [];
  geometries = [];
  coordinates: any;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    private router: Router
  ) {
    this.updates$
      .pipe(ofType(UserLocationTypes.location.CREATE_USER_LOCATION_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showSuccess({
          message: extract('Successfully created location')
        });
        this.router.navigateByUrl('/settings/locations');
      });
    this.updates$
      .pipe(ofType(UserLocationTypes.location.CREATE_USER_LOCATION_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to create location')
        });
      });
  }

  ngOnInit() {
    this.hakkaMap = new HakkaMap('user-location-map');
    this.markerLayer = this.hakkaMap.getLayerById('markers');
    this.hakkaMap.clickCoordinates.subscribe(coord => {
      this.coordinates = coord;
      this.updateMarker(coord[0], coord[1]);
    });
  }

  updateMarkerByAddress(address) {
    if (!address) {
      return;
    }
    if (address.latitude && address.longitude) {
      const longitude = +address.longitude;
      const latitude = +address.latitude;
      this.updateMarker(longitude, latitude);
      this.hakkaMap.setCenter(longitude, latitude, 18);
      this.coordinates = [longitude, latitude];
    }
  }
  updateMarker(lon, lat, id?) {
    this.markers[0] = this.hakkaMap.generateFeature(
      {
        lat: lat,
        lon: lon
      },
      id || ''
    );
    // this.hakkaMap.sources['markers'].clear();
    this.hakkaMap.sources['markers'].getFeaturesCollection().clear();
    this.hakkaMap.sources['markers']
      .getFeaturesCollection()
      .push(this.markers[0]);
  }

  ngOnDestroy() { }

  createNewUserLocation(userLocationFormValue) {
    this.isLoading = true;
    this.store.dispatch(new CreateUserLocation(userLocationFormValue));
  }
}
