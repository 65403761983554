export const session = {
  LOGIN: '[Session] Login',
  LOGIN_SUCCEEDED: '[Session] Login Success',
  LOGIN_FAILED: '[Session] Login Failed',
  REFRESH: '[Session] Refresh',
  REFRESH_SUCCEEDED: '[Session] Refresh Success',
  REFRESH_FAILED: '[Session] Refresh Fail',
  LOGOUT: '[Session] Logout',
  PICK_TENANT: '[Session] Set Tenant',
  DROP_TENANT: '[Session] Drop active tenant'
};
