import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { PsaCompany } from 'app/core/store/models/psa-company.model';
import { Wallet } from 'app/core/store/models/wallet.model';
import { State } from 'app/core/store/store.model';
import { AuthService } from 'app/core/user/user.auth';

@Component({
  selector: 'app-psa-status',
  templateUrl: './psa-status.component.html',
  styleUrls: ['./psa-status.component.scss']
})
export class PsaStatusComponent {
  psa$: Observable<PsaCompany>;
  wallet$: Observable<Wallet>;
  reminderMessage$: Observable<string>;
  warningMessage$: Observable<string>;

  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    this.psa$ = this.store.select(state => {
      const tenant = state.tenants.userTenants
        .find(t => t.id === this.authService.getActiveTenant());
      return tenant ? tenant.psaCompany : <PsaCompany>{
        id: '', name: '', vatNumber: '', tenant: null, blocked: false,
        creditsThreshold: '0',
      };
    });
    this.wallet$ = this.store.select(state => state.wallet);
  }

  ngOnInit() {
    this.psa$.subscribe((psa: PsaCompany) => {
      this.reminderMessage$ = this.translate.get(
        `Make sure to top up your Hakka Wallet in time. Your PSA ePortal account will be blocked if your account balance drops below €{{threshold}}.`,
        { threshold: psa.creditsThreshold }
      );

      this.warningMessage$ = this.translate.get(
        `Your credit balance dropped below €{{threshold}}. Please recharge your Hakka Wallet above €{{threshold}} to un-block your ePortal account.`,
        { threshold: psa.creditsThreshold }
      );
    })
  }
}
