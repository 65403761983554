import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from 'app/core/api/api.service';
import { DeleteLocation } from 'app/core/store/actions/location.actions';
import { LocationTypes } from 'app/core/store/models/location-type.model';
import * as types from 'app/core/store/types/location.types';
import { extract } from 'app/services/i18n.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
var ManageLocationsComponent = /** @class */ (function () {
    function ManageLocationsComponent(api, router, modalService, store, updates$) {
        var _this = this;
        this.api = api;
        this.router = router;
        this.modalService = modalService;
        this.store = store;
        this.updates$ = updates$;
        this.locationTypes = LocationTypes;
        this.locationType$ = new BehaviorSubject(LocationTypes.OTHER);
        this.loading = false;
        this.locations$ = combineLatest(this.locationType$, this.updates$.pipe(ofType(types.location.DELETE_LOCATION_SUCCEEDED), startWith(null))).pipe(switchMap(function (_a) {
            var locationType = _a[0];
            _this.loading = true;
            return _this.api.get({
                path: "/locations?locationType.code=" + locationType + "&order[name]=asc"
            });
        }), map(function (data) {
            _this.loading = false;
            return data['hydra:member'];
        }));
    }
    ManageLocationsComponent.prototype.edit = function (id) {
        this.router.navigate(['superadmin/locations', id, 'edit']);
    };
    ManageLocationsComponent.prototype.deleteLocation = function (locationId) {
        var _this = this;
        var modalRef = this.modalService.open(ConfirmActionModalComponent);
        modalRef.componentInstance.message = extract('Are you sure you want to permanently delete this location?');
        modalRef.componentInstance.confirmButtonText = extract('Delete');
        modalRef.result.then(function () {
            _this.store.dispatch(new DeleteLocation({ locationId: locationId }));
        });
    };
    ManageLocationsComponent.prototype.ngOnDestroy = function () { };
    return ManageLocationsComponent;
}());
export { ManageLocationsComponent };
