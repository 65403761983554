import { Notification } from '../models/notifications.model';
import * as types from '../types/notification.types';
import * as NotificationActions from '../actions/notifications.actions';

const INITIAL_STATE: Notification[] = [];

/**
 * Reduces NotificationActions to the store
 * @param state The notification state slice
 * @param action The actual action
 */
export function reducer(
  state = INITIAL_STATE,
  action: NotificationActions.All
): Notification[] {
  switch (action.type) {
    // Add a new notification to the array
    case types.notifications.ADD_NOTIFICATION:
      return [...state, Object.assign({}, action.payload)];

    // Filter out the passed notification
    case types.notifications.DISMISS_NOTIFICATION:
      return state.filter(
        (notification: Notification) =>
          notification.message !== action.payload.message
      );
  }
  return state;
}
