import * as types from '../types/tenant-settings.types';
import * as TenantSettingsActions from '../actions/tenant-settings.actions';

const INITIAL_STATE = null;

/**
 * Reducer for all Tenant specific settings
 *
 * @export
 * @param {any} [state=INITIAL_STATE]
 * @param {TenantSettingsActions.All} action
 * @returns
 */
export function reducer(
  state = INITIAL_STATE,
  action: TenantSettingsActions.All
) {
  switch (action.type) {
    case types.tenantSettings.LOAD_SUCCEEDED:
      return [...action.payload['hydra:member']];
    case types.tenantSettings.UPDATE_SUCCEEDED:
      return state;
    case types.tenantSettings.CLEAR:
      return null;
  }
  return state;
}
