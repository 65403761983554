import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { ApiService } from 'app/core/api/api.service';
import { ReloadRequest, ReloadRequestStatuses } from 'app/core/store/models/reload-request.model';
import * as Types from 'app/core/store/types/reload.types';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import { untilDestroyed } from 'app/shared/rxjs-util';
import {
  CancelReloadRequest,
  CancelReloadRequestByLiner,
  CancelReloadRequestSuccess,
  ForceApproveReloadRequest,
  ForceApproveReloadRequestSuccess,
  UpdateReloadRequestSuccess,
  UpdateReloadRequest
} from 'app/core/store/actions/reload.actions';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'app-cmp-reload-request',
  templateUrl: 'reload-request.component.html',
  styleUrls: ['./reload-request.component.scss']
})
export class ReloadRequestComponent implements OnDestroy {
  title = extract('Reload Request');
  id: string
  reloadRequest: ReloadRequest;
  forLiner: boolean = false;
  loading: boolean;
  requestStatuses: { value: string; label: string }[];

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private route: ActivatedRoute,
    private toastr: ToastService,
    private router: Router,
    private updates$: Actions,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
    this.forLiner = this.router.url.includes('/reload-back-office/reload-requests/');
    this.requestStatuses = ReloadRequestStatuses;
    this.route.paramMap.subscribe(data => {
      if (data) {
        this.id = data.get('id');
        this.api
          .get({
            path: '/container_reload_requests/' + this.id
          })
          .subscribe((response: ReloadRequest) => {
            if (response.liner.code === 'HYU' || response.liner.code === 'EVE') {
              this.toastr.showWarning({
                title: this.translate.instant('Not allowed')
              });
              this.goBackToOverviewPage();
            }
            this.reloadRequest = response;
          });
      }
    });

    updates$
      .pipe(ofType(Types.reload.UPDATE_RELOAD_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((action: UpdateReloadRequestSuccess) => {
        this.loading = false;
        if (action.payload && action.payload.requestStatus === 'declined') {
          this.toastr.showWarning({
            title: 'Reload request failed!',
            message: this.translate.instant('reload.api.message.' + action.payload.requestStatusDetail)
          });
        } else {
          this.toastr.showSuccess({
            message: 'Reload requested!'
          });
        }
        this.goBackToOverviewPage();
      });
    updates$
      .pipe(ofType(Types.reload.UPDATE_RELOAD_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Reload request failed!'
        });
      });
    this.updates$
      .pipe(ofType(Types.reload.FORCE_APPROVE_RELOAD_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((action: ForceApproveReloadRequestSuccess) => {
        this.loading = false;
        if (action.payload && action.payload.requestStatus === 'forced_approved') {
          this.toastr.showSuccess({
            message: extract('Reload request forced approved!')
          });
          this.goBackToOverviewPage();
        } else {
          this.toastr.showWarning({
            title: 'Reload request approval failed!',
            message: this.translate.instant('reload.api.message.' + action.payload.requestStatusDetail)
          });
        }
      });
    this.updates$
      .pipe(ofType(Types.reload.FORCE_APPROVE_RELOAD_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.toastr.showDanger({
          title: extract('Reload request approval failed!')
        });
      });
    this.updates$
      .pipe(ofType(Types.reload.CANCEL_RELOAD_REQUEST_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe((action: CancelReloadRequestSuccess) => {
        this.loading = false;
        if (action.payload && action.payload.requestStatus === 'cancelled') {
          this.toastr.showSuccess({
            title: extract('Reload request cancelled!')
          });
          this.goBackToOverviewPage();
        } else {
          this.toastr.showWarning({
            message: 'Reload request cancellation failed!',
          });
        }
      });
    this.updates$
      .pipe(ofType(Types.reload.CANCEL_RELOAD_REQUEST_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
      });
  }

  reloadFormSubmitted(reloadFormValue: any) {
    this.loading = true;
    this.store.dispatch(
      new UpdateReloadRequest({
        requestId: this.id,
        request: reloadFormValue
      })
    );
    this.loading = true;
  }

  forceApproveRequest() {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract('Are you sure you want to force the approval of this reload request?');
    modalRef.componentInstance.confirmButtonText = extract('Yes');
    modalRef.result.then(() => {
      this.loading = true;
      this.store.dispatch(new ForceApproveReloadRequest({ requestId: this.reloadRequest.id }));
    });
  }

  cancelRequest() {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract('Are you sure you want to cancel this reload request?');
    modalRef.componentInstance.confirmButtonText = extract('Yes');
    modalRef.result.then(() => {
      this.loading = true;
      if (this.forLiner) {
        this.store.dispatch(new CancelReloadRequestByLiner({ requestId: this.reloadRequest.id }));
      } else {
        this.store.dispatch(new CancelReloadRequest({ requestId: this.reloadRequest.id }));
      }
    });
  }

  formatRequestStatus(status?: string) {
    if (status) {
      const requestStatus: {label: string, value: string}  = this.requestStatuses.find(
        requestStatus => requestStatus.value === status
      );
      return requestStatus? requestStatus.label : status;
    }
    return status;
  }

  getStatusClass = (cell: any) => {
    let classes = {}

    if (cell.value) {
      classes['reload_request_' + cell.value] = true;
    }

    return classes;
  }

  goBackToOverviewPage()
  {
    if (this.forLiner) {
      this.router.navigateByUrl(`/reload-back-office/reload-requests`);
    } else {
      this.router.navigateByUrl(`/reload/reload-requests`);
    }
  }

  ngOnDestroy() { }
}
