<div class="form-row">
  <div class="col" *ngFor="let task of transportTasks; let i = index">
    <div class="card mb-3">
      <div class="card-body">
        <!-- Task Type -->
        <h4 class="d-flex justify-content-center">
          <i class="far mr-2" [ngClass]="getTaskIcon(task)"></i>
          <span *ngIf="task.taskType.label; else noLabel">
            {{ task.taskType.label | titlecase | translate }}
          </span>
          <ng-template #noLabel>
            {{ task.taskType.code | titlecase | translate }}
          </ng-template>
        </h4>
        <!-- Location row -->
        <div class="form-row mb-3">
          <div class="col">
            <!-- Private Location -->
            <div class="form-row">
              <div class="col-10">
                <ng-select
                  [ngClass]="{
                    'is-invalid': submitAttempt && !transportTasksAreValid
                  }"
                  id="privateLocation"
                  (change)="privateLocationChange($event, i)"
                  [items]="addressBookLocations"
                  [required]="true"
                  bindLabel="name"
                  data-cy="transportTaskLocation"
                  [loading]="isLoadingAddressBookLocations"
                  bindValue="id"
                  placeholder="{{ 'Select planning location' | translate }}"
                  [(ngModel)]="task.privateLocation"
                >
                  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                    <div
                      class="ng-option disabled"
                      (click)="openNewUserLocationModal(i, searchTerm)"
                    >
                      {{
                        'Location not found; click here to add it' | translate
                      }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-2">
                <div
                  *ngIf="task.privateLocation; else quickAddIsEnabled"
                  translate
                  ngbTooltip="{{
                    isPublicLocation(task.privateLocation)
                      ? 'You can\'t edit public locations'
                      : 'Edit location'
                  }}"
                >
                  <button
                    [disabled]="isPublicLocation(task.privateLocation)"
                    (click)="openEditUserLocationModal(i, task.privateLocation)"
                    class="float-right ml-1 text-white btn btn-sm btn-info"
                    type="button"
                  >
                    <span class="icon"> <i class="far fa-pencil"></i> </span>
                  </button>
                </div>
                <ng-template #quickAddIsEnabled>
                  <div ngbTooltip="{{ 'New location' | translate }}">
                    <button
                      data-cy="quickAddLocationButton"
                      class="float-right ml-1 text-white btn btn-sm btn-primary"
                      type="button"
                      (click)="openNewUserLocationModal(i)"
                    >
                      <span class="icon"> <i class="far fa-plus"></i> </span>
                    </button>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!-- Public Location -->
        <div class="form-row mb-3" *ngIf="showPublicLocationField">
          <div class="col">
            <div
              [ngClass]="{
                'is-invalid': submitAttempt && !task.privateLocation
              }"
              class="input-group location-input"
            >
              <input
                placeholder="{{
                  'Enter marketplace location here' | translate
                }}"
                [(ngModel)]="task.location"
                (ngModelChange)="tTasksChange()"
                id="location_{{ i }}"
                [disabled]="true"
                class="form-control"
                [inputFormatter]="locationService.locationFormatter"
                [ngbTypeahead]="searchPrivateLocation"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="far fa-location-arrow"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Start Date & Time -->
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <span class="d-flex justify-content-between">
                <div class="input-group mr-1">
                  <input
                    class="form-control"
                    placeholder="{{ 'From date (dd/mm/yyyy)' | translate }}"
                    name="d2"
                    [(ngModel)]="task.startDate"
                    (ngModelChange)="tTasksChange()"
                    (click)="d2.toggle()"
                    [markDisabled]="getDisabledDates"
                    data-cy="transportTaskStartDate"
                    ngbDatepicker
                    placement="bottom-left"
                    #d2="ngbDatepicker"
                    [autoClose]="true"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      data-cy="transportTaskStartDateToggle"
                      (click)="d2.toggle()"
                    >
                      <i class="far fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div class="invalid-feedback">
                    {{
                      'Please use dd/mm/yyyy to format your dates.' | translate
                    }}
                  </div>
                </div>
                <ngb-timepicker
                  [(ngModel)]="task.startTime"
                  (ngModelChange)="timeChange(task)"
                  data-cy="transportTaskStartTime"
                  [spinners]="false"
                ></ngb-timepicker>
              </span>
            </div>
          </div>
        </div>
        <!-- End Date & Time -->
        <div class="form-row">
          <div class="col">
            <div class="form-group" [class.mb-0]="needsConfirmation">
              <span class="d-flex justify-content-between">
                <div class="input-group mr-1">
                  <input
                    class="form-control"
                    placeholder="{{ 'To date (dd/mm/yyyy)' | translate }}"
                    name="d3"
                    [(ngModel)]="task.endDate"
                    ngbDatepicker
                    (ngModelChange)="tTasksChange()"
                    (click)="d3.toggle()"
                    [markDisabled]="getDisabledDates"
                    placement="bottom-left"
                    data-cy="transportTaskEndDate"
                    #d3="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-dark"
                      type="button"
                      data-cy="transportTaskEndDateToggle"
                      (click)="d3.toggle()"
                    >
                      <i class="far fa-calendar-alt"></i>
                    </button>
                  </div>
                  <div class="invalid-feedback">
                    {{
                      'Please use dd/mm/yyyy to format your dates.' | translate
                    }}
                  </div>
                </div>
                <ngb-timepicker
                  [(ngModel)]="task.endTime"
                  (ngModelChange)="timeChange(task)"
                  data-cy="transportTaskEndTime"
                  [spinners]="false"
                ></ngb-timepicker>
              </span>
            </div>
          </div>
        </div>
        <div class="form-row" [hidden]="needsConfirmation">
          <div class="col">
            <div class="form-group input-group mb-0">
              <input
                class="form-control"
                type="text"
                placeholder="{{ 'Enter' | translate }} {{
                  task.taskType.label | titlecase | translate
                }} {{ 'reference here.' | translate }}"
                [(ngModel)]="task.reference"
                id="task-reference"
                data-cy="transportTaskReference"
                (ngModelChange)="tTasksChange()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
