var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { ApiService } from 'app/core/api/api.service';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';
import * as moment from 'moment';
var NewCertiweightMeasurementComponent = /** @class */ (function () {
    function NewCertiweightMeasurementComponent(api, toastr, router) {
        this.api = api;
        this.toastr = toastr;
        this.router = router;
        this.isLoading = false;
    }
    NewCertiweightMeasurementComponent.prototype.onSubmit = function (value) {
        var _this = this;
        var data;
        try {
            data = JSON.parse(value.data);
        }
        catch (e) {
            data = value.data;
        }
        this.isLoading = true;
        this.api.post({
            path: '/container_measurements',
            params: { headers: { 'Content-Type': 'application/ld+json' } },
            body: __assign({}, value, { announcedOn: moment(value.announcedOn).format(), weight: value.weight.toString(), data: data }),
        }).toPromise().then(function () {
            _this.toastr.showSuccess({
                message: extract('Measurement created'),
            });
            _this.router.navigateByUrl('/superadmin/certiweight');
        }, function () {
            _this.toastr.showDanger({
                message: extract('Failed to create measurement'),
            });
            _this.isLoading = false;
        });
    };
    return NewCertiweightMeasurementComponent;
}());
export { NewCertiweightMeasurementComponent };
