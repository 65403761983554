var CreditAmountComponent = /** @class */ (function () {
    function CreditAmountComponent() {
        this.loading = false;
    }
    Object.defineProperty(CreditAmountComponent.prototype, "total", {
        get: function () {
            if (!this.wallet) {
                return 0;
            }
            return +this.wallet.credits + this.wallet.reservedCredits;
        },
        enumerable: true,
        configurable: true
    });
    return CreditAmountComponent;
}());
export { CreditAmountComponent };
