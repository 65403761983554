export const registrationRequests = {
  LOAD_ALL_REGISTRATION_REQUESTS: '[Registration requests] Load All',
  LOAD_ALL_SUCCEEDED: '[Registration requests] Load All Success',
  LOAD_ALL_FAILED: '[Registration requests] Load All Fail',
  LOAD_ONE_REGISTRATION_REQUEST: '[Registration requests] Load One',
  LOAD_ONE_SUCCEEDED: '[Registration requests] Load One Success',
  LOAD_ONE_FAILED: '[Registration requests] Load One Fail',
  UPDATE_REGISTRATION_REQUEST: '[RegistrationRequest] Update',
  UPDATE_REGISTRATION_REQUEST_SUCCEEDED: '[RegistrationRequest] Update Success',
  UPDATE_REGISTRATION_REQUEST_FAILED: '[RegistrationRequest] Update Fail',
  APPROVE_REGISTRATION_REQUEST: '[RegistrationRequest] Approve',
  APPROVE_REGISTRATION_REQUEST_SUCCEEDED: '[RegistrationRequest] Approve Success',
  APPROVE_REGISTRATION_REQUEST_FAILED: '[RegistrationRequest] Approve Fail',
  DECLINE_REGISTRATION_REQUEST: '[RegistrationRequest] Decline',
  DECLINE_REGISTRATION_REQUEST_SUCCEEDED: '[RegistrationRequest] Decline Success',
  DECLINE_REGISTRATION_REQUEST_FAILED: '[RegistrationRequest] Decline Fail',
};
