import { SubscriptionTypes } from 'app/core/store/models/subscriptions.model';
import { LoadSubscriptions } from 'app/core/store/actions/subscriptions.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { MatchingTypes } from 'app/core/store/models/matching.model';
import { IconService } from 'app/services/icon.service';
import { Transport } from 'app/core/store/models/transport.model';
import { ConfirmPublishTransportComponent } from '../confirm-publish-transport/confirm-publish-transport.component';
import { EnableMatching, DisableMatching } from 'app/core/store/actions/matching.actions';
import { ToastService } from 'app/services/toast.service';
import {
  DeleteTransport,
  CheckTransportTaskReference,
  LoadTransportsByDaterange,
  CopyTransport
} from 'app/core/store/actions/transports.actions';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { State } from 'app/core/store/store.model';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, ViewChild, NgZone } from '@angular/core';
import * as Types from 'app/core/store/types/transport.types';
import * as MarketPlaceTypes from 'app/core/store/types/marketplace.types';
import * as MatchTypes from 'app/core/store/types/matching.types';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UnpublishMarketPostOffer } from 'app/core/store/actions/market-post-offer.actions';
import { DatatableService } from 'app/services/datatable.service';
import * as DateFns from 'date-fns';
import { extract } from 'app/services/i18n.service';
import { debounceTime } from 'rxjs/operators';
import { DownloadService } from 'app/services/download.service';
import { ConfirmActionModalComponent } from 'app/shared/confirm-action-modal/confirm-action-modal.component';
import { PublishDemandComponent } from '../../publish-demand/publish-demand.component';
import { TransportDownloadModalComponent } from '../transport-download/transport-download-modal.component';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-transport-list',
  templateUrl: './transport-list.component.html',
  styleUrls: ['./transport-list.component.scss']
})
export class TransportListComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: false })
  ngxDatatable: DatatableComponent;

  title = extract('Manage Transports');
  searchPlaceholder = '';
  transports = [];
  dayChange = new Subject();
  rowLimit: number = 30;
  totalElements: number = 0;
  page: Number = 1;
  fromDate: Date;
  toDate: Date;
  query?: string = null;
  isLoadingTransports: boolean;
  actionRow: number;
  taskIndexes = [];
  isLoading: boolean;
  referenceCheckCounter: number;
  hasActiveMatchingSubscription: boolean;
  hasActiveMarketplaceSubscription: boolean;
  SubscriptionTypes = SubscriptionTypes;
  transportTableMessages = {
    emptyMessage: extract('No transports for this day'),
    totalMessage: extract('total')
  };

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private router: Router,
    private zone: NgZone,
    private modalService: NgbModal,
    private datatableService: DatatableService,
    private toastr: ToastService,
    private iconService: IconService,
    private route: ActivatedRoute,
    private downloadService: DownloadService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      /**
       * Check if route has optional date param
       * https://angular.io/guide/router#heroes-list-optionally-selecting-a-hero
       */
      if (params.get('date')) {
        const date = new Date(params.get('date'));
        this.fromDate = date;
        this.toDate = date;
      } else {
        const today = new Date();
        if (!localStorage.getItem('transportsFromDate')) {
          this.fromDate = today;
        } else {
          const date = new Date(localStorage.getItem('transportsFromDate'));
          this.fromDate = date;
        }
        if (!localStorage.getItem('transportsToDate')) {
          this.toDate = today;
        } else {
          const date = new Date(localStorage.getItem('transportsToDate'));
          this.toDate = date;
        }
      }
    });

    this.dayChange
      .pipe(debounceTime(200))
      .subscribe((data: { fromDate: Date; toDate: Date }) => {
        if (data.fromDate instanceof NgbDate) {
          const temp = Object.assign({}, data.fromDate);
          data.fromDate = new Date(temp.year, temp.month, temp.day);
        }
        if (data.toDate instanceof NgbDate) {
          const temp = Object.assign({}, data.toDate);
          data.toDate = new Date(temp.year, temp.month, temp.day);
        }
        this.handleDateSelection({
          fromDate: data.fromDate,
          toDate: data.toDate
        });
      });

    this.handleDateSelection({ fromDate: this.fromDate, toDate: this.toDate });
    this.store
      .select(state => state.transports)
      .pipe(untilDestroyed(this))
      .subscribe(transportsState => {
        if (transportsState) {
          if (!transportsState['data']) {
            return;
          }
          this.transports = transportsState['data'] as Transport[];
          this.totalElements = transportsState['totalItems'];
          this.isLoadingTransports = false;
        }
      });

    this.store.dispatch(new LoadSubscriptions());
    this.store
      .select(state => state.subscriptions)
      .pipe(untilDestroyed(this))
      .subscribe(subscriptionsState => {
        if (subscriptionsState) {
          this.hasActiveMatchingSubscription =
            subscriptionsState.filter(
              subscription =>
                subscription.subscriptionType.code ===
                SubscriptionTypes.MATCHING
            ).length !== 0;

          this.hasActiveMarketplaceSubscription =
            subscriptionsState.filter(
              subscription =>
                subscription.subscriptionType.code ===
                SubscriptionTypes.MARKETPLACE
            ).length !== 0;
        }
      });

    this.updates$
      .pipe(ofType(Types.transports.CHECK_TRANSPORT_TASK_REFERENCE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.referenceCheckCounter = this.referenceCheckCounter - 1;
        if (this.referenceCheckCounter === 0) {
          this.reloadTransports();
          this.resetReferenceCheckVariables();
          this.toastr.showSuccess({
            message: extract('Reference checked successfully')
          });
        }
      });
    this.updates$
      .pipe(ofType(Types.transports.CHECK_TRANSPORT_TASK_REFERENCE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetReferenceCheckVariables();
        this.reloadTransports();
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to check reference')
        });
      });
    this.updates$
      .pipe(ofType(Types.transports.DELETE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.reloadTransports();
        this.toastr.showSuccess({
          message: extract('Transport deleted!')
        });
        this.resetReferenceCheckVariables();
      });
    this.updates$
      .pipe(ofType(Types.transports.DELETE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to delete transport')
        });
        this.resetReferenceCheckVariables();
      });

    this.updates$
      .pipe(ofType(MarketPlaceTypes.marketposts.UNPUBLISH_MARKET_POST_OFFER_SUCCESS))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Unpublished transport successfully')
        });
        this.resetReferenceCheckVariables();
      });
    this.updates$
      .pipe(ofType(MarketPlaceTypes.marketposts.UNPUBLISH_MARKET_POST_OFFER_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to unpublish marketpost')
        });
        this.resetReferenceCheckVariables();
      });
    this.updates$
      .pipe(ofType(Types.transports.UPDATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          message: extract('Update succeeded!')
        });
        this.store.dispatch(
          new LoadTransportsByDaterange({
            fromDate: DateFns.format(this.fromDate, 'YYYY-MM-DD'),
            toDate: DateFns.format(this.toDate, 'YYYY-MM-DD')
          })
        );
        this.resetReferenceCheckVariables();
      });
    this.updates$
      .pipe(ofType(Types.transports.UPDATE_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to update transport')
        });
        this.resetReferenceCheckVariables();
      });
    this.updates$
      .pipe(ofType(MatchTypes.matching.ENABLE_MATCHING_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(
          new LoadTransportsByDaterange({
            fromDate: DateFns.format(this.fromDate, 'YYYY-MM-DD'),
            toDate: DateFns.format(this.toDate, 'YYYY-MM-DD')
          })
        );
        this.resetReferenceCheckVariables();
        this.toastr.showSuccess({
          message: extract('Enabled matching successfully')
        });
      });
    this.updates$
      .pipe(ofType(MatchTypes.matching.ENABLE_MATCHING_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetReferenceCheckVariables();
      });
    this.updates$
      .pipe(ofType(MatchTypes.matching.DISABLE_MATCHING_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.reloadTransports();
        this.resetReferenceCheckVariables();
        this.toastr.showSuccess({
          message: extract('Disable matching successfully')
        });
      });
    this.updates$
      .pipe(ofType(MatchTypes.matching.DISABLE_MATCHING_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetReferenceCheckVariables();
        this.toastr.showDanger({
          message: extract('Disable matching failed')
        });
      });
  }

  ngOnDestroy() { }

  /**
   * Checks if the transport has at least one reference
   * @param transport
   */
  transportHasReference(transport): boolean {
    let hasReference = false;
    hasReference = transport.transportTasks.some(task => {
      if (task.reference) {
        return true;
      }
    });
    return hasReference;
  }

  publishAsMarketpost(transport) {
    this.openConfirmPublishModal(transport);
  }

  unpublishFromMarketplace(transport, rowIndex) {
    if (!transport.marketPostOffer) {
      return;
    }
    this.store.dispatch(
      new UnpublishMarketPostOffer({
        id: transport.marketPostOffer.id,
        transportId: transport.id
      })
    );
    this.actionRow = rowIndex;
    this.isLoading = true;
  }

  /**
   * Checks the references of all the tasks of the given transport
   * @param transport transport whose task references will be checked
   * @param rowIndex index of the table row that triggered this function, used for displaying a loading indicator
   */
  checkTaskReferences(transport, rowIndex) {
    const tasksWithReference = [...transport.transportTasks];
    this.referenceCheckCounter = tasksWithReference
      .filter(task => task.reference)
      .filter(task => task.location.connector).length;
    transport.transportTasks.forEach((task) => {
      if (task.reference && task.location.connector) {
        this.checkTaskReference(task, rowIndex);
      }
    });
  }

  reloadTransports(): void {
    this.store.dispatch(
      new LoadTransportsByDaterange({
        fromDate: DateFns.format(this.fromDate, 'YYYY-MM-DD'),
        toDate: DateFns.format(this.toDate, 'YYYY-MM-DD')
      })
    );
  }

  checkTaskReference(task, rowIndex) {
    this.actionRow = rowIndex;
    if (!this.referenceCheckCounter) {
      this.referenceCheckCounter = 1;
    }
    this.taskIndexes = [...this.taskIndexes, task.id];
    this.isLoading = true;
    this.store.dispatch(
      new CheckTransportTaskReference({
        id: task.id
      })
    );
  }

  resetReferenceCheckVariables() {
    this.actionRow = null;
    this.taskIndexes = [];
    this.referenceCheckCounter = null;
    this.isLoading = false;
  }

  edit(transport, rowIndex) {
    this.isLoading = true;
    this.actionRow = rowIndex;
    this.zone.run(() => {
      if (transport.id) {
        this.router.navigate([`tms/transports/${transport.id}/edit`]);
      }
    });
  }

  deleteTransport(transport: Transport, rowIndex) {
    const modalRef = this.modalService.open(ConfirmActionModalComponent);
    modalRef.componentInstance.message = extract(
      'Are you sure you want to permanently delete this transport?'
    );
    modalRef.componentInstance.confirmButtonText = extract('Delete');
    modalRef.result.then(() => {
      this.handleDeleteTransport(transport, rowIndex);
    });
  }

  handleDeleteTransport(transport, rowIndex) {
    this.actionRow = rowIndex;
    this.isLoading = true;
    this.store.dispatch(
      new DeleteTransport({
        id: transport.id
      })
    );
  }
  openConfirmPublishModal(transport): any {
    const modalRef = this.modalService.open(ConfirmPublishTransportComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.transport = Object.assign({}, transport);
    modalRef.componentInstance.transportId = transport.id;
  }
  openPublishDemandModal(transport): any {
    const modalRef = this.modalService.open(PublishDemandComponent, {
      size: 'lg'
    });
    const tempTransport = Object.assign({}, transport);
    const originLocation = this.getOriginLocation(tempTransport);
    const destinationLocation = this.getDestinationLocation(tempTransport);
    tempTransport.transportTasks = [originLocation, destinationLocation];
    modalRef.componentInstance.transport = Object.assign({}, tempTransport);
  }
  /**
   * Redirects to the create new visit page
   */
  goToCreate() {
    this.router.navigateByUrl('/tms/transports/new');
  }

  getOriginLocation(transport: Transport) {
    let task = null;
    switch (transport.transportType.code) {
      case 'load':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'pickup'
        );
        break;
      case 'unload':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'unload'
        );
        break;
      case 'shunt':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'dropoff'
        );
        break;
      default:
        break;
    }
    if (!task) {
      return;
    }
    return task;
  }

  getDestinationLocation(transport: Transport) {
    let task = null;
    switch (transport.transportType.code) {
      case 'load':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'load'
        );
        break;
      case 'unload':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'dropoff'
        );
        break;
      case 'shunt':
        task = transport.transportTasks.find(
          x => x.taskType.code === 'pickup'
        );
        break;
      default:
        break;
    }
    if (!task) {
      return;
    }
    return task;
  }
  getDropdownPlacement(rowIndex) {
    const placement = this.datatableService.getDropdownPlacement(
      rowIndex,
      this.totalElements,
      this.rowLimit
    );
    return placement;
  }

  disableMatching(transport, rowIndex) {
    if (!this.hasActiveMatchingSubscription) {
      this.toastr.showWarning({
        message: extract(
          'You need to have an active matching subscription in order to activate matching on a transport'
        )
      });
      return;
    }
    if (this.primaryTaskDateIsBeforeToday(rowIndex)) {
      this.toastr.showWarning({
        message: extract(
          'You can\'t activate matching for transports with tasks that are in the past'
        )
      });
      return;
    }
    this.actionRow = rowIndex;
    this.isLoading = true;
    this.store.dispatch(
      new DisableMatching({
        type: MatchingTypes.TRANSPORT,
        id: transport.id
      })
    );
  }

  enableMatching(transport, rowIndex) {
    if (!this.hasActiveMatchingSubscription) {
      this.toastr.showWarning({
        message: extract(
          'You need to have an active matching subscription in order to activate matching on a transport'
        )
      });
      return;
    }
    if (this.primaryTaskDateIsBeforeToday(rowIndex)) {
      this.toastr.showWarning({
        message: extract(
          'You can\'t activate matching for transports with tasks that are in the past'
        )
      });
      return;
    }
    this.actionRow = rowIndex;
    this.isLoading = true;
    this.store.dispatch(
      new EnableMatching({
        type: MatchingTypes.TRANSPORT,
        id: transport.id
      })
    );
  }

  primaryTaskDateIsBeforeToday(transport) {
    return DateFns.isBefore(transport.primaryTaskDate, new Date());
  }

  setPage($event) {
    this.page = $event.offset + 1;
    this.store.dispatch(
      new LoadTransportsByDaterange({
        fromDate: DateFns.format(this.fromDate, 'YYYY-MM-DD'),
        toDate: DateFns.format(this.toDate, 'YYYY-MM-DD'),
        page: $event.offset + 1
      })
    );
  }

  /**
   * Load transports between daterange
   * @param daterange fromDate and toDate
   */
  handleDateSelection(daterange: { fromDate: Date; toDate: Date }) {
    this.isLoadingTransports = true;
    let fromDate: string;
    let toDate: string;
    fromDate = DateFns.format(daterange.fromDate, 'YYYY-MM-DD');
    toDate = DateFns.format(daterange.toDate, 'YYYY-MM-DD');
    this.fromDate = Object.assign(daterange.fromDate);
    this.toDate = Object.assign(daterange.toDate);
    localStorage.setItem('transportsFromDate', fromDate);
    localStorage.setItem('transportsToDate', toDate);
    this.store.dispatch(
      new LoadTransportsByDaterange({
        fromDate: fromDate,
        toDate: toDate,
        query: this.query
      })
    );
  }

  handleSearch(query: string) {
    this.isLoadingTransports = true;
    this.query = query;
    this.store.dispatch(
      new LoadTransportsByDaterange({
        fromDate: DateFns.format(this.fromDate, 'YYYY-MM-DD'),
        toDate: DateFns.format(this.toDate, 'YYYY-MM-DD'),
        query: query
      })
    );
  }

  getTransportTypeIcon(type: Transport): string {
    return this.iconService.getTransportTypeIcon(type.transportType);
  }

  download(transport: Transport, index: number, locale: string) {
    const path = `/tms/transports/${transport.id}/download`;
    this.downloadService.downloadPDF(path, transport.reference, locale);
  }

  openTransportDownloadModal(transport: Transport): void {
    const modalRef = this.modalService.open(TransportDownloadModalComponent);
    modalRef.componentInstance.transport = transport;
  }

  copyTransport(transport: Transport) {
    this.store.dispatch(new CopyTransport(transport));
    this.router.navigateByUrl('/tms/transports/copy');
  }
}
