<div class="pl-3 pr-3">
  <ngb-alert *ngIf="showAlert" (close)="showAlert = false" [type]="'warning'">
    <i class="far fa-exclamation-triangle"></i>
    <span>{{ 'Warning: posts don\'t refresh with the map open' | translate }}</span>
  </ngb-alert>
</div>
<div id="map" class="map"></div>
<div class="map-toolbar">
  <button class="btn btn-primary text-white" id="updateMapButton" (click)="updateMarketpostsByGeoBoundBox()">
    <i class="far fa-undo"></i> {{ 'Search in this area' | translate }}
  </button>
</div>
<!-- <div id="popup" class="ol-popup"> -->
<!-- <a href="" id="popup-closer" class="ol-popup-closer"></a>
<div>
    <app-cmp-market-post-offer *ngIf="marketPostOffer" id="popup-content" [marketPost]="marketPostOffer"></app-cmp-market-post-offer>
    <app-cmp-market-post-demand *ngIf="marketPostDemand" id="popup-content" [marketPost]="marketPostDemand"></app-cmp-market-post-demand>
</div>
</div> -->
