<div class="card mb-3">
  <h3 class="card-header">
    <span>{{ 'My Circles' | translate }}</span>
    <app-cmp-loading [loading]="loading"></app-cmp-loading>
  </h3>
  <div class="card-body" *ngIf="!noCircles">
    <div class="mb-3" *ngFor="let circle of circles$ | async">
      <app-cmp-circle [circle]="circle" (circleChanged)="saveCircle($event, circle)" (deleteCircleEmitter)="deleteCircle(circle)"></app-cmp-circle>
    </div>
  </div>
  <div class="card-body" *ngIf="noCircles">
    <div class="alert alert-info">
      <i class="fa far-info"></i>{{ 'You don\'t have any circles yet.' | translate }}</div>
    <h5>{{ 'What are Circles ?' | translate }}</h5>
    <p class="text-muted">{{ 'Circles can be used to manage who can see certain posts. When creating a new post in the marketplace, you can choose
      to hide it for certain circles or only share it with certain specific circles.' | translate }}</p>
  </div>
</div>
