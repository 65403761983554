<div class="m-3">
  <div class="card">
    <nav class="navbar navbar-expand navbar-light bg-light mb-4 pt-2 d-none d-sm-flex flex-wrap d-flex justify-content-between ">
      <ul class="navbar-nav">
        <li class="nav-item">
          <button class="btn" (click)="navigateBack()">
            <i class="far fa-arrow-left"></i>
          </button>
        </li>
      </ul>
      <h3 class="d-flex align-items-center">{{ 'PSA Accounts' | translate }}</h3>
      <form
        class="form-inline"
        [formGroup]="reportForm"
        (ngSubmit)="onReportFormSubmit()"
        *ngIf="reportForm"
      >
        <div class="input-group mr-2">
          <input class="form-control"
            [ngClass]="{
              'is-invalid': reportForm.get('sinceDate').touched && reportForm.get('sinceDate').invalid
            }"
            (click)="d1.toggle()"
            placeholder="{{ 'From date (dd/mm/yyyy)' | translate }}"
            name="d1"
            formControlName="sinceDate"
            ngbDatepicker
            placement="bottom-left"
            #d1="ngbDatepicker"
            data-cy="sinceDate"
          >
          <div class="input-group-append">
            <button class="btn btn-outline-dark"
              type="button"
              (click)="d1.toggle()"
              data-cy="sinceDateCalendarButton"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div class="input-group mr-2">
          <input class="form-control"
            [ngClass]="{
              'is-invalid': reportForm.get('untilDate').touched && reportForm.get('untilDate').invalid
            }"
            (click)="d2.toggle()"
            placeholder="{{ 'To date (dd/mm/yyyy)' | translate }}"
            name="d2"
            formControlName="untilDate"
            ngbDatepicker
            placement="bottom-left"
            #d2="ngbDatepicker"
            data-cy="untilDate"
          >
          <div class="input-group-append">
            <button class="btn btn-outline-dark"
              type="button"
              (click)="d2.toggle()"
              data-cy="untilDateCalendarButton"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <button class="btn btn-info text-white" [disabled]="loadingReport">
          <i class="far fa-download" *ngIf="!loadingReport"></i>
          <i class="far fa-spinner fa-spin" *ngIf="loadingReport"></i>
          {{ 'CSV Transaction Report' | translate }}
        </button>
      </form>
    </nav>
  </div>
  <div class="card">
    <ngx-datatable
      class="bootstrap ml-0 mr-0"
      [rows]="companies$ | async"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [selectionType]="undefined"
      [externalPaging]="false"
      [limit]="30"
      [loadingIndicator]="loading"
    >
      <ngx-datatable-column name="ID" prop="id"></ngx-datatable-column>
      <ngx-datatable-column name="PSA company" prop="name"></ngx-datatable-column>
      <ngx-datatable-column name="Hakka company" prop="tenant.name"></ngx-datatable-column>
      <ngx-datatable-column name="VAT" prop="vatNumber">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ (!row.tenant || row.vatNumber == row.tenant.vatNumber)
            ? row.vatNumber
            : row.vatNumber + ' (Hakka VAT ' + row.tenant.vatNumber + ')' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Credit threshold" prop="creditsThreshold" maxWidth="150" cellClass="text-right">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.creditsThreshold }}
          <button class="btn btn-sm btn-info ml-1" (click)="editCreditThreshold(row)">
            <i class="far fa-pencil"></i>
            <span>{{ 'Edit' | translate }}</span>
          </button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Status" prop="" maxWidth="150">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.blocked ? 'Blocked' : 'Active' }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" prop="">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <button class="btn btn-sm btn-info mr-1" (click)="showActionHistory(row)">
            <i class="far fa-search"></i>
            <span>{{ 'History' | translate }}</span>
          </button>
          <button class="btn btn-sm btn-danger" (click)="unlink(row)" *ngIf="row.tenant">
            <i class="far fa-bomb"></i>
            <span>{{ 'Unlink' | translate }}</span>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
