/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../transport-form/transport-form.component.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../shared/datepicker-config";
import * as i4 from "../transport-form/transport-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../services/icon.service";
import * as i8 from "@ngrx/effects";
import * as i9 from "../../../services/toast.service";
import * as i10 from "./new-transport.component";
import * as i11 from "../../../services/marketpost-parser.service";
import * as i12 from "@angular/router";
var styles_NewTransportComponent = [];
var RenderType_NewTransportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewTransportComponent, data: {} });
export { RenderType_NewTransportComponent as RenderType_NewTransportComponent };
export function View_NewTransportComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-transport-form", [], null, [[null, "submitAndContinueEnabled"], [null, "transportFormSubmitted"], [null, "matchingStateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitAndContinueEnabled" === en)) {
        var pd_0 = ((_co.submitAndContinue = $event) !== false);
        ad = (pd_0 && ad);
    } if (("transportFormSubmitted" === en)) {
        var pd_1 = (_co.onSubmit($event) !== false);
        ad = (pd_1 && ad);
    } if (("matchingStateChanged" === en)) {
        var pd_2 = (_co.toggleMatchingState($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_TransportFormComponent_0, i1.RenderType_TransportFormComponent)), i0.ɵprd(4608, null, i2.NgbDateAdapter, i3.NgbDateNativeAdapter, []), i0.ɵprd(4608, null, i2.NgbDateParserFormatter, i3.NgbDateCustomParserFormatter, []), i0.ɵdid(4, 770048, null, 0, i4.TransportFormComponent, [i5.FormBuilder, i6.Store, i7.IconService, i2.NgbModal, i8.Actions, i9.ToastService], { transportTypes: [0, "transportTypes"], transport: [1, "transport"], customerTariffs: [2, "customerTariffs"], supplierTariffs: [3, "supplierTariffs"], customers: [4, "customers"], suppliers: [5, "suppliers"], isLoadingCustomers: [6, "isLoadingCustomers"], liners: [7, "liners"], containerTypes: [8, "containerTypes"], loading: [9, "loading"] }, { transportFormSubmitted: "transportFormSubmitted", matchingStateChanged: "matchingStateChanged", submitAndContinueEnabled: "submitAndContinueEnabled" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transportTypes; var currVal_1 = _co.transport; var currVal_2 = _co.customerTariffs; var currVal_3 = _co.supplierTariffs; var currVal_4 = _co.customers; var currVal_5 = _co.suppliers; var currVal_6 = _co.isLoadingCustomers; var currVal_7 = _co.liners; var currVal_8 = _co.containerTypes; var currVal_9 = _co.loading; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_NewTransportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-new-transport", [], null, null, null, View_NewTransportComponent_0, RenderType_NewTransportComponent)), i0.ɵdid(1, 245760, null, 0, i10.NewTransportComponent, [i6.Store, i11.MarketpostParserService, i8.Actions, i9.ToastService, i12.Router, i7.IconService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewTransportComponentNgFactory = i0.ɵccf("app-new-transport", i10.NewTransportComponent, View_NewTransportComponent_Host_0, { transport: "transport" }, {}, []);
export { NewTransportComponentNgFactory as NewTransportComponentNgFactory };
