export const wallet = {
  LOAD_WALLET: '[Wallet] Load',
  LOAD_SUCCEEDED: '[Wallet] Load Success',
  LOAD_FAILED: '[Wallet] Load Fail',
  REQUEST_RECHARGE_WALLET: '[Wallet] Request Recharge',
  REQUEST_RECHARGE_WALLET_SUCCEEDED: '[Wallet] Request Recharge Success',
  REQUEST_RECHARGE_WALLET_FAILED: '[Wallet] Request Recharge Fail',
  RECHARGE_WALLET: '[Wallet] Recharge',
  RECHARGE_SUCCEEDED: '[Wallet] Recharge Success',
  RECHARGE_FAILED: '[Wallet] Recharge Fail',
  ADD_CREDITS_TO_WALLET: '[Wallet] Add Credits',
  ADD_CREDITS_TO_WALLET_SUCCEEDED: '[Wallet] Add Credits Success',
  ADD_CREDITS_TO_WALLET_FAILED: '[Wallet] Add Credits Fail',
  SUBTRACT_CREDITS_FROM_WALLET: '[Wallet] Subtract Credits',
  SUBTRACT_CREDITS_FROM_WALLET_SUCCEEDED: '[Wallet] Subtract Credits Success',
  SUBTRACT_CREDITS_FROM_WALLET_FAILED: '[Wallet] Subtract Credits Fail',
  CLEAR: '[Wallet] Clear',
  UPDATE_WALLET: '[Wallet] Set warning threshold',
  UPDATE_WALLET_SUCCEEDED: '[Wallet] Set warning threshold success',
  UPDATE_WALLET_FAILED: '[Wallet] Set warning threshold fail',
};
