<table class="location-table">
  <!-- Origin -->
  <tr>
    <td>
      <span>
        <span
          class="icon"
          ngbTooltip="{{ type | translate }}"
          placement="right"
        >
          <i class="fa-map-marker" [ngClass]="getIconClass()"></i>
        </span>
        <span *ngIf="location; else: noLocation">{{
          location.shortName
            ? location.shortName
            : location.name
            ? location.name
            : location
        }}</span>
        <ng-template #noLocation>
          <span>{{ 'No location' | translate }}</span>
        </ng-template>
      </span>
    </td>
    <td class="text-right">
      <span>
        <span *ngIf="timeIntervalStart">
          {{ timeIntervalStart | date: 'dd/MM' }}
          <span *ngIf="timeIntervalStart && timeIntervalStartTimeSpecified">
            {{ timeIntervalStart | date: 'HH:mm' }}
          </span>
        </span>
        <span
          *ngIf="
            (timeIntervalEnd && timeIntervalEnd !== timeIntervalStart) ||
            timeIntervalStartTimeSpecified
          "
        >
          -
        </span>
        <span *ngIf="timeIntervalEnd && timeIntervalEnd !== timeIntervalStart">
          {{ timeIntervalEnd | date: 'dd/MM' }}
        </span>
        <span *ngIf="timeIntervalEnd && timeIntervalEndTimeSpecified">
          {{ timeIntervalEnd | date: 'HH:mm' }}
        </span>
      </span>
    </td>
  </tr>
</table>
