import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { State } from 'app/core/store/store.model';
import { ToastService } from 'app/services/toast.service';
import * as Types from 'app/core/store/types/tms.types';
import { CreateVisit } from 'app/core/store/actions/visits.actions';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-new-visit-modal',
  templateUrl: './new-visit-modal.component.html',
})
export class NewVisitModalComponent implements OnInit, OnDestroy {
  @Input()
  task;
  @Input()
  primaryTaskDate;
  @Output()
  visitCreated = new EventEmitter<boolean>();

  isLoading = false;

  constructor(
    private store: Store<State>,
    private updates$: Actions,
    private toastr: ToastService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.updates$
      .pipe(ofType(Types.visits.CREATE_VISIT_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: extract('Prenotification created!')
        });
        this.isLoading = false;
        this.visitCreated.emit(true);
        this.activeModal.close();
      });
    this.updates$
      .pipe(ofType(Types.visits.CREATE_VISIT_FAILED))
      .pipe(untilDestroyed(this))
      .subscribe(error => {
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to create prenotification')
        });
        this.isLoading = false;
      });
  }

  createVisit(body) {
    this.store.dispatch(new CreateVisit({ body }));
    this.isLoading = true;
  }

  ngOnDestroy() { }
}
