<div class="card">
  <h3 class="card-header">{{ 'Reload Requests Report' | translate }}</h3>
  <div class="card-body">
    <form
      class="form-inline"
      [formGroup]="reportForm"
      (ngSubmit)="onReportFormSubmit()"
      *ngIf="reportForm"
    >
      <div class="input-group mr-2 mb-2">
        <input class="form-control"
          [ngClass]="{
            'is-invalid': reportForm.get('sinceDate').touched && reportForm.get('sinceDate').invalid
          }"
          (click)="d1.toggle()"
          placeholder="{{ 'From date (dd/mm/yyyy)' | translate }}"
          name="d1"
          formControlName="sinceDate"
          ngbDatepicker
          placement="bottom-left"
          #d1="ngbDatepicker"
          data-cy="sinceDate"
        >
        <div class="input-group-append">
          <button class="btn btn-outline-dark"
            type="button"
            (click)="d1.toggle()"
            data-cy="sinceDateCalendarButton"
          >
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div class="input-group mr-2 mb-2">
        <input class="form-control"
          [ngClass]="{
            'is-invalid': reportForm.get('untilDate').touched && reportForm.get('untilDate').invalid
          }"
          (click)="d2.toggle()"
          placeholder="{{ 'To date (dd/mm/yyyy)' | translate }}"
          name="d2"
          formControlName="untilDate"
          ngbDatepicker
          placement="bottom-left"
          #d2="ngbDatepicker"
          data-cy="untilDate"
        >
        <div class="input-group-append">
          <button class="btn btn-outline-dark"
            type="button"
            (click)="d2.toggle()"
            data-cy="untilDateCalendarButton"
          >
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div class="input-group mr-2 mb-2">
        <button class="btn btn-info text-white" [disabled]="loading">
          <i class="far fa-download" *ngIf="!loading"></i>
          <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
          {{ 'Download' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
