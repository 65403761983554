import { Action } from '@ngrx/store';
import * as types from '../types/marketplace.types';

export class LoadTransportTypes implements Action {
  readonly type = types.transportTypes.LOAD_TRANSPORT_TYPES;
  constructor(public payload?: any) { }
}

export class LoadTransportTypesSuccess implements Action {
  readonly type = types.transportTypes.LOAD_SUCCEEDED;
  constructor(public payload?: any) { }
}

export class LoadTransportTypesFail implements Action {
  readonly type = types.transportTypes.LOAD_FAILED;
  constructor(public payload?: any) { }
}

export type All = LoadTransportTypes | LoadTransportTypesSuccess | LoadTransportTypesFail;
