import { Injectable } from '@angular/core';

@Injectable()
export class DatatableService {
  constructor() {}

  /**
   * Returns the placement of a dropdown element based on the index of the row the element is placed in.
   * This prevents the dropdown being hidden at the end of a (data)table
   * @param rowIndex index of the row in the datatable
   * @param totalElements total elements of the datatable
   * @param rowLimit rowLimit of the datatable
   */
  getDropdownPlacement(rowIndex, totalElements, rowLimit) {
    return rowIndex >
      (totalElements > rowLimit ? rowLimit : totalElements) * 0.7
      ? 'top-right'
      : 'bottom-right';
  }
}
