<div class="grid-container mr-3 mb-2">
  <button class="btn" (click)="goToCreate()">
    <i class="far fa-fw fa-plus"></i>
    {{ 'Add driver' | translate }}
  </button>
</div>
<ngx-datatable
  class="bootstrap row ml-3 mr-3"
  [loadingIndicator]="isLoadingDrivers"
  [rows]="drivers"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [rowHeight]="50"
  [externalPaging]="true"
  (page)="setPage($event)"
  [count]="totalElements"
  [limit]="30"
>
  <ngx-datatable-column
    name="{{ '' | translate }}"
    prop="enabled"
    [minWidth]="30"
    [width]="30"
    [maxWidth]="30"
  >
    <ng-template ngx-datatable-cell-template let-value="value">
      <i
        class="far"
        [ngClass]="{
          'fa-check-circle text-success': value,
          'fa-times-circle text-danger': !value
        }"
      ></i>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'First Name' | translate }}"
    prop="user.firstName"
  ></ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Last Name' | translate }}"
    prop="user.lastName"
  ></ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Email' | translate }}"
    prop="user.email"
  ></ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'Phone Number' | translate }}"
    prop="phoneNumber"
  ></ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'License Plate' | translate }}"
    prop="licensePlate"
  ></ngx-datatable-column>
  <ngx-datatable-column
    [cellClass]="'datatable-actions'"
    name="{{ 'Action' | translate }}"
    prop=""
    [width]="120"
  >
    <ng-template
      ngx-datatable-cell-template
      let-rowIndex="rowIndex"
      let-value="value"
      let-row="row"
    >
      <div
        ngbDropdown
        placement="getDropdownPlacement(rowIndex)"
        class="d-inline-block"
      >
        <button
          class="btn btn-outline-primary"
          id="userContextsActions"
          ngbDropdownToggle
          data-cy="actions-dropdown"
        >
          <span
            translate
            class="btn__label"
            [ngClass]="{ invisible: isLoading && actionRow === rowIndex }"
            >Select Action</span
          >
          <i
            class="fa fa-spinner fa-spin btn__loader"
            [ngClass]="{ visible: isLoading && actionRow === rowIndex }"
          ></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="userContextsActions">
          <button
            class="dropdown-item"
            (click)="edit(row)"
            data-cy="edit-action"
          >
            <i class="far fa-pencil"></i>
            <span>&nbsp; {{ 'Edit' | translate }}</span>
          </button>
          <button
            class="dropdown-item text-success"
            *ngIf="!row.enabled"
            (click)="enable(row, rowIndex)"
            data-cy="enable-action"
          >
            <i class="far fa-check"></i>
            <span>&nbsp; {{ 'Enable' | translate }}</span>
          </button>
          <button
            class="dropdown-item text-warning"
            *ngIf="row.enabled"
            (click)="disable(row, rowIndex)"
            data-cy="disable-action"
          >
            <i class="far fa-times"></i>
            <span>&nbsp; {{ 'Disable' | translate }}</span>
          </button>
          <button
            class="dropdown-item text-danger"
            (click)="delete(row, rowIndex)"
            data-cy="delete-action"
          >
            <i class="far fa-trash-alt" *ngIf="!loading"></i>
            <span *ngIf="!loading">&nbsp; {{ 'Delete' | translate }}</span>
          </button>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
