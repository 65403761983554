import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilDestroyed } from 'app/shared/rxjs-util';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { LoadLiners } from 'app/core/store/actions/liner.actions';
import { LoadAllTenants } from 'app/core/store/actions/tenants.actions';
import { State } from 'app/core/store/store.model';
import { ShippingAgent } from 'app/core/store/models/shipping-agent.model';
import { Liner } from 'app/core/store/models/liner.model';
import { Tenant } from 'app/core/store/models/tenant.model';

@Component({
  selector: 'app-shipping-agent-form',
  templateUrl: './shipping-agent-form.component.html',
})
export class ShippingAgentFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() shippingAgent: ShippingAgent;
  @Output() shippingAgentFormSubmitted = new EventEmitter<ShippingAgent>();

  shippingAgentForm: FormGroup;
  loading = false;
  countries: { value: string; label: string }[];
  liners: Liner[];
  tenants: Tenant[];

  constructor(private fb: FormBuilder, private store: Store<State>) {
    this.store
      .select(state => state.tms.countries)
      .pipe(untilDestroyed(this))
      .subscribe(countries => {
        if (!countries) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countries];
      });
    store.dispatch(new LoadLiners());
    store
      .select(state => state.marketplace.liners)
      .pipe(untilDestroyed(this))
      .subscribe(liners => {
        if (liners) {
          this.liners = liners;
        }
      });
    store
      .select(state => state.tenants)
      .pipe(untilDestroyed(this))
      .subscribe(tenants => {
        if (tenants && tenants['data']) {
          this.tenants = tenants['data'];
        } else {
          this.store.dispatch(new LoadAllTenants());
        }
      });
  }

  ngOnInit() {
    if (!this.shippingAgentForm) {
      this.createForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shippingAgent && changes.shippingAgent.currentValue) {
      if (!this.shippingAgentForm) {
        this.createForm();
      }
      const shippingAgent = changes.shippingAgent.currentValue;
      this.shippingAgentForm.patchValue({
        name: shippingAgent.name,
        tenants: shippingAgent.tenants
      });
      if (shippingAgent.branches && shippingAgent.branches.length > 0) {
        this.removeBranch(0);
        shippingAgent.branches.forEach(() => {
          this.addBranch();
        });
        this.shippingAgentForm.patchValue({
          branches: shippingAgent.branches
        });
      }
    }
  }

  ngOnDestroy() { }

  createForm() {
    this.shippingAgentForm = this.fb.group({
      name: [null],
      tenants: [null],
      branches: this.fb.array([this.createBranch()])
    });
  }

  createBranch(): FormGroup {
    return this.fb.group({
      liner: null,
      country: null
    });
  }

  addBranch(): void {
    const branches = <FormArray>this.shippingAgentForm.controls['branches'];
    branches.push(this.createBranch());
  }
  removeBranch(index: number): void {
    const branches = <FormArray>this.shippingAgentForm.controls['branches'];
    branches.removeAt(index);
    this.shippingAgentForm.markAsDirty();
  }

  onSubmit() {
    const value = this.shippingAgentForm.value;

    // Filter out empty branches
    value['branches'] = value['branches'].filter(
      branch => branch.liner && branch.country
    );

    value['branches'].forEach(branch => {
      if (branch.liner) {
        branch.liner = branch.liner['@id'];
      }
    });
    value['tenants'] = value['tenants'].map(tenant => tenant['@id']);

    this.shippingAgentFormSubmitted.emit(value);
  }
}
