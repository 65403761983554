import * as types from '../types/tenant.types';
import * as TenantActions from '../actions/tenants.actions';
import { Tenant } from '../models/tenant.model';

export interface TenantState {
  data: Tenant[];
  userTenants: Tenant[];
  totalElements: number;
  page: string;
  nextPage: string;
  lastPage: string;
}

const INITIAL_STATE = {
  data: null,
  userTenants: null,
  totalElements: 0,
  page: null,
  nextPage: null,
  lastPage: null
};

export function reducer(state = INITIAL_STATE, action: TenantActions.All): TenantState {
  switch (action.type) {
    case types.tenants.LOAD_SUCCEEDED:
      state.userTenants = [...action.payload['hydra:member']];
      return Object.assign({}, state);
    case types.tenants.LOAD_ALL_SUCCEEDED:
      state.data = [...action.payload['hydra:member']];
      state.totalElements = action.payload['hydra:totalItems'];
      state.page = action.payload['hydra:view']
        ? action.payload['hydra:view']['hydra:first']
        : null;
      state.nextPage = action.payload['hydra:view']
        ? action.payload['hydra:view']['hydra:next']
        : null;
      state.lastPage = action.payload['hydra:view']
        ? action.payload['hydra:view']['hydra:last']
        : null;
      return Object.assign({}, state);
    case types.tenants.CREATE_SUCCEEDED:
      state.data = state.data
        ? [...state.data, action.payload]
        : [action.payload];
      return Object.assign({}, state);
    case types.tenants.CLEAR:
      Object.keys(state).forEach(key => (state[key] = null));
      return Object.assign({}, state);
  }
  return state;
}
