import { Supplier } from 'app/core/store/models/supplier.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'app/core/store/store.model';
import { Actions, ofType } from '@ngrx/effects';
import * as SupplierTypes from 'app/core/store/types/supplier.types';
import { LoadCountries } from 'app/core/store/actions/country.actions';
import { UpdateSupplier } from 'app/core/store/actions/supplier.actions';
import { extract } from 'app/services/i18n.service';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'app-edit-supplier-modal',
  templateUrl: './edit-supplier-modal.component.html',
})
export class EditSupplierModalComponent implements OnInit {
  @Input()
  supplier: Supplier;

  countries: any[];
  isLoading: boolean;
  destroyed$ = new Subject<boolean>();

  constructor(
    private store: Store<State>,
    private modal: NgbActiveModal,
    private updates$: Actions,
    private toastr: ToastService
  ) {
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.UPDATE_SUPPLIER_SUCCEEDED))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(supplier => {
        this.isLoading = false;
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
        this.modal.close(supplier['payload']);
      });
    this.updates$
      .pipe(ofType(SupplierTypes.supplier.UPDATE_SUPPLIER_FAILED))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.isLoading = false;
        this.toastr.showDanger({
          title: extract('Something went wrong'),
          message: extract('Failed to update supplier')
        });
      });
  }

  ngOnInit() {
    this.store
      .select(state => state.tms.countries)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(countriesState => {
        if (!countriesState) {
          this.store.dispatch(new LoadCountries());
          return;
        }
        this.countries = [...countriesState];
      });
  }

  updateSupplier(supplierFormValue: Supplier) {
    supplierFormValue.defaultContact['id'] = this.supplier.defaultContact['id'];
    supplierFormValue.billingAddress['id'] = this.supplier.billingAddress['id'];
    this.isLoading = true;
    this.store.dispatch(
      new UpdateSupplier({
        supplierId: this.supplier.id,
        supplier: supplierFormValue
      })
    );
  }
}
