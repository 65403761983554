import { ApiService } from 'app/core/api/api.service';
import { Transaction } from 'app/core/store/models/transaction.model';
import { Component, Input } from '@angular/core';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-cmp-credit-transaction',
  templateUrl: 'credit-transaction.component.html',
  styleUrls: ['./../transactions.component.scss']
})
export class CreditTransactionComponent {
  @Input()
  creditTransaction: Transaction;

  constructor(
    private api: ApiService
  ) { }

  getInvoice(transaction) {
    this.api.get(
      { path: '/credit_transactions/' + transaction.id + '/invoice' },
      {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      }, 'blob')
      .subscribe(data => {
        const blob = new Blob([<any>data], {
          type: 'application/pdf' // must match the Accept type
        });
        const invoiceDate = moment(transaction.created).format('DD/MM/YYYY');
        const filename = 'hakka_invoice_' + invoiceDate + '.pdf';
        fileSaver.saveAs(blob, filename);
      });
  }
}
