import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as types from '../types/marketplace.types';
import * as MarketPostDemandsActions from '../actions/market-post-demand.actions';

import { ApiService } from '../../api/api.service';

@Injectable()
export class MarketPostDemandEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions
  ) { }

  @Effect()
  loadMarketPostDemands$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.LOAD_MARKETPOSTS_DEMANDS),
    concatMap(() => this.api.get({
      path: '/market_post_demands'
    }).pipe(
      map(data => new MarketPostDemandsActions.LoadMarketPostDemandsSuccess(data)),
      catchError(error => of(new MarketPostDemandsActions.LoadMarketPostDemandsFail(error)))
    )),
  );

  @Effect()
  createMarketPostDemand$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.CREATE_MARKET_POST_DEMAND),
    map((action: MarketPostDemandsActions.CreateMarketPostDemand) => action.payload),
    concatMap(payload => this.api.post({
      path: '/market_post_demand_collections', body: payload
    }).pipe(
      map(data => new MarketPostDemandsActions.CreateMarketPostDemandSuccess(data)),
      catchError(error => of(new MarketPostDemandsActions.CreateMarketPostDemandFail(error)))
    )),
  );

  @Effect()
  replyMarketPostDemand$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.REPLY_MARKET_POST_DEMAND),
    map((action: MarketPostDemandsActions.ReplyMarketPostDemand) => action.payload),
    concatMap(payload => this.api.post({
      path: `/market_post_demand_replies`,
      body: payload.body
    }).pipe(
      map(data => new MarketPostDemandsActions.ReplyMarketPostDemandSuccess(data)),
      catchError(error => of(new MarketPostDemandsActions.ReplyMarketPostDemandFail(error)))
    )),
  );

  @Effect()
  publishMarketPostDemand$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.PUBLISH_MARKET_POST_DEMAND),
    map((action: MarketPostDemandsActions.PublishMarketPostDemand) => action.payload),
    concatMap(payload => this.api.put({
      path: '/market_post_demands/' + payload.id + '/publish'
    }).pipe(
      map(data => new MarketPostDemandsActions.PublishMarketPostDemandSuccess(data)),
      catchError(error => of(new MarketPostDemandsActions.PublishMarketPostDemandFail(error)))
    )),
  );

  @Effect()
  unpublishMarketPostDemand$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.UNPUBLISH_MARKET_POST_DEMAND),
    map((action: MarketPostDemandsActions.UnpublishMarketPostDemand) => action.payload),
    concatMap(payload => this.api.put({
      path: '/market_post_demands/' + payload.id + '/unpublish'
    }).pipe(
      map(data => new MarketPostDemandsActions.UnpublishMarketPostDemandSuccess(data)),
      catchError(error => of(new MarketPostDemandsActions.UnpublishMarketPostDemandFail(error)))
    )),
  );

  @Effect()
  updateMarketPostDemand$: Observable<Action> = this.actions$.pipe(
    ofType(types.marketposts.UPDATE_MARKETPOSTS_DEMAND),
    map((action: MarketPostDemandsActions.UpdateMarketPostDemand) => action.payload),
    concatMap(payload => this.api.put({
      path: '/market_post_demands/' + payload.id, body: payload.body
    }).pipe(
      map(data => new MarketPostDemandsActions.UpdateMarketPostDemandSuccess(data)),
      catchError(error => of(new MarketPostDemandsActions.UpdateMarketPostDemandFail(error)))
    )),
  );
}
