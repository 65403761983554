import { liner } from '../types/liner.types';
import { Action } from '@ngrx/store';

export class LoadLiners implements Action {
  readonly type = liner.LOAD_LINERS;
  constructor(public payload?: any) {}
}

export class LoadLinersSuccess implements Action {
  readonly type = liner.LOAD_LINERS_SUCCEEDED;
  constructor(public payload?: any) {}
}

export class LoadLinersFail implements Action {
  readonly type = liner.LOAD_LINERS_FAILED;
  constructor(public payload?: any) {}
}

export type All = LoadLiners | LoadLinersSuccess | LoadLinersFail;
