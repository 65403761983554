import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CreateUserContext, UpdateUserContext } from 'app/core/store/actions/user-contexts.actions';
import { Component, Input, OnDestroy } from '@angular/core';
import * as Types from 'app/core/store/types/user-context.types';
// models
import { State } from 'app/core/store/store.model';
import { User } from 'app/core/store/models/user.model';
import { Role, Roles } from 'app/core/store/models/roles.model';
import { ToastService } from 'app/services/toast.service';
import { LoadRoles } from 'app/core/store/actions/roles.actions';
import { extract } from 'app/services/i18n.service';
import { untilDestroyed } from 'app/shared/rxjs-util';

@Component({
  selector: 'app-cmp-new-user-context',
  templateUrl: 'new-user-context.component.html'
})
export class NewUserContextComponent implements OnDestroy {
  @Input()
  driver = false;
  @Input()
  navbarTitle = extract('Add new user');

  ROLES = Roles;
  success: boolean;
  userContext: any;
  roles: Role[];
  loading = false;

  constructor(
    private store: Store<State>,
    private userContextUpdates$: Actions,
    private toastr: ToastService
  ) {
    this.store
      .select(state => state.roles)
      .pipe(untilDestroyed(this))
      .subscribe(rolesState => {
        if (!rolesState) {
          this.store.dispatch(new LoadRoles());
          return;
        }
        this.roles = [...rolesState];
      });

    userContextUpdates$
      .pipe(ofType(Types.userContexts.CREATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.updateUserContext(data['payload'].id);
      });

    userContextUpdates$
      .pipe(ofType(Types.userContexts.UPDATE_SUCCEEDED))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showSuccess({
          message: 'User successfully created'
        });
        this.loading = false;
      });

    userContextUpdates$
      .pipe(ofType(
        Types.userContexts.CREATE_FAILED,
        Types.userContexts.UPDATE_FAILED
      ))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toastr.showDanger({
          title: 'Something went wrong',
          message: 'Failed to create user'
        });
        this.loading = false;
      });
  }

  /**
   * this function sends a user object to the backend to check if the user already exists
   * @param userContextFormValue the value emitted from the user-context form
   */
  createUserContext(userContextFormValue: User) {
    this.userContext = userContextFormValue;
    this.success = false;
    const body = {
      email: userContextFormValue.email,
      firstName: userContextFormValue.firstName,
      lastName: userContextFormValue.lastName,
      locale: userContextFormValue.locale
    };
    this.store.dispatch(new CreateUserContext(body));
    this.loading = true;
  }

  /**
   * update the usercontext
   * @param userId the id of the newly created user or already existing user
   */
  updateUserContext(userId) {
    const body = {
      roles: [],
      phoneNumber: this.userContext.phoneNumber,
      locale: this.userContext.locale
    };
    if (this.driver) {
      body['licensePlate'] = this.userContext.licensePlate;
    }
    if (this.userContext.isAdmin) {
      body.roles.push(
        this.roles.find(role => role.code === this.ROLES.ADMIN)['@id']
      );
    }

    if (this.userContext.isPlanner) {
      body.roles.push(
        this.roles.find(role => role.code === this.ROLES.PLANNER)['@id']
      );
    }

    if (this.userContext.isDriver) {
      body.roles.push(
        this.roles.find(role => role.code === this.ROLES.DRIVER)['@id']
      );
    }
    if (userId) {
      this.store.dispatch(new UpdateUserContext({ id: userId, body: body }));
    }
  }

  ngOnDestroy() { }
}
