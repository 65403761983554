<form (ngSubmit)="onSubmit()" [formGroup]="form" class="pb-3">

  <div class="form-group">
    <label class="center-block">{{ 'Announced on' | translate }}</label>
    <div class="d-flex justify-content-start">
      <div class="input-group w-auto">
        <input class="form-control"
          [ngClass]="{'is-invalid': form.get('announcedOn').touched && form.get('announcedOn').invalid}"
          (click)="d1.toggle()"
          placeholder="{{ 'Announced on' | translate }}"
          name="d1"
          formControlName="announcedOn"
          ngbDatepicker
          placement="bottom-left"
          #d1="ngbDatepicker"
          data-cy="announcedOn"
        >
        <div class="input-group-append">
          <button class="btn btn-outline-dark"
            type="button"
            (click)="d1.toggle()"
            data-cy="announcedOnCalendarButton"
          >
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      &nbsp;
      <ngb-timepicker formControlName="announcedOnTime"
        [spinners]="false"
        data-cy="announcedOnTime"></ngb-timepicker>
    </div>
  </div>

  <div class="form-group">
    <label class="center-block">{{ 'Liner' | translate }}</label>
    <ng-select
      id="carrier"
      [items]="liners"
      bindLabel="shortName"
      bindValue="@id"
      placeholder="{{ 'Liner' | translate }}"
      formControlName="liner"
    >
    </ng-select>
  </div>

  <div class="form-row">
    <div class="col-4">
      <div class="form-group">
        <label class="center-block">{{ 'Container number' | translate }}</label>
        <input
          class="form-control"
          formControlName="containerNumber"
          placeholder="{{ 'Container number' | translate }}"
          [ngClass]="{'is-invalid': form.controls['containerNumber'].invalid && form.controls['containerNumber'].touched }"
        />
      </div>
    </div>

    <div class="col-4">
      <div class="form-group">
        <label class="center-block">{{ 'Booking reference' | translate }}</label>
        <input
          class="form-control"
          formControlName="bookingReference"
          placeholder="{{ 'Booking reference' | translate }}"
          [ngClass]="{'is-invalid': form.controls['bookingReference'].invalid && form.controls['bookingReference'].touched }"
        />
      </div>
    </div>

    <div class="col-4">
      <div class="form-group">
        <label class="center-block">{{ 'Seal number' | translate }}</label>
        <input
          class="form-control"
          formControlName="sealNumber"
          placeholder="{{ 'Seal number' | translate }}"
          [ngClass]="{'is-invalid': form.controls['sealNumber'].invalid && form.controls['sealNumber'].touched }"
        />
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-6">
      <div class="form-group">
        <label class="center-block">{{ 'Weight' | translate }}</label>
        <input
          type="number"
          class="form-control"
          formControlName="weight"
          placeholder="{{ 'Weight' | translate }}"
          [ngClass]="{'is-invalid': form.controls['weight'].invalid && form.controls['weight'].touched }"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label class="center-block">{{ 'Data' | translate }}</label>
        <input
          class="form-control"
          formControlName="data"
          placeholder="{{ 'Data' | translate }}"
          [ngClass]="{'is-invalid': form.controls['data'].invalid && form.controls['data'].touched }"
        />
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="center-block">{{ 'Measurement key' | translate }}</label>
    <input
      class="form-control"
      formControlName="measurementKey"
      placeholder="{{ 'Measurement key' | translate }}"
      [ngClass]="{'is-invalid': form.controls['measurementKey'].invalid && form.controls['measurementKey'].touched }"
    />
  </div>

  <div class="form-row">
    <div class="col-4">
      <div class="form-group">
        <label class="center-block">{{ 'Weighing site' | translate }}</label>
        <ng-select
          id="site"
          [items]="weighingSites"
          bindLabel="name"
          bindValue="@id"
          placeholder="{{ 'Weighing site' | translate }}"
          formControlName="site"
        ></ng-select>
      </div>
    </div>

    <div class="col-4">
      <div class="form-group">
        <label class="center-block">{{ 'Terminal' | translate }}</label>
        <ng-select
          id="terminal"
          [items]="terminals"
          bindLabel="name"
          bindValue="@id"
          placeholder="{{ 'Terminal' | translate }}"
          formControlName="terminal"
        ></ng-select>
      </div>
    </div>
  </div>

  <button class="btn btn-primary text-white" type="submit" [disabled]="form.invalid || form.pristine">
    <i class="far fa-spinner fa-spin" *ngIf="loading"></i>
    <span>{{ 'Submit' | translate }}</span>
  </button>

  <button class="btn btn-primary text-white ml-3" type="button" [disabled]="loading || form.invalid" (click)="sendToAvantida()">
    <span>{{ 'Send to Avantida' | translate }}</span>
  </button>

</form>
