/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./message-input.component";
import * as i8 from "@ngrx/effects";
import * as i9 from "../../../services/toast.service";
var styles_MessageInputComponent = [i0.styles];
var RenderType_MessageInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageInputComponent, data: {} });
export { RenderType_MessageInputComponent as RenderType_MessageInputComponent };
function View_MessageInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "far fa-comments"]], null, null, null, null, null))], null, null); }
function View_MessageInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "far fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
function View_MessageInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngb-alert", [["class", "alert"], ["role", "alert"]], [[2, "alert-dismissible", null]], null, null, i2.View_NgbAlert_0, i2.RenderType_NgbAlert)), i1.ɵdid(1, 638976, null, 0, i3.NgbAlert, [i3.NgbAlertConfig, i1.Renderer2, i1.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = false; var currVal_2 = "warning"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Everyone can read this message.")); _ck(_v, 4, 0, currVal_3); }); }
export function View_MessageInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-muted"], ["id", "message-length"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", " / 256)"])), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "input-group"], ["id", "message-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "input", [["class", "form-control"], ["data-cy", "messageBoardInput"], ["id", "message"], ["maxlength", "256 "], ["type", "text"]], [[8, "placeholder", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup.enter"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup.enter" === en)) {
        var pd_4 = (_co.onSubmit() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.message = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(5, 540672, null, 0, i5.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i5.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.MaxLengthValidator]), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(8, 671744, null, 0, i5.NgModel, [[8, null], [6, i5.NG_VALIDATORS], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(10, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "span", [["class", "input-group-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageInputComponent_1)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageInputComponent_2)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageInputComponent_3)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = "256 "; _ck(_v, 5, 0, currVal_10); var currVal_11 = _co.message; _ck(_v, 8, 0, currVal_11); var currVal_12 = !_co.loading; _ck(_v, 15, 0, currVal_12); var currVal_13 = _co.loading; _ck(_v, 17, 0, currVal_13); var currVal_14 = _co.reply; _ck(_v, 19, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.length; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 11).transform("Enter your message here")), ""); var currVal_2 = (i1.ɵnov(_v, 5).maxlength ? i1.ɵnov(_v, 5).maxlength : null); var currVal_3 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 10).ngClassValid; var currVal_8 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_MessageInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cmp-message-input", [], null, null, null, View_MessageInputComponent_0, RenderType_MessageInputComponent)), i1.ɵdid(1, 180224, null, 0, i7.MessageInputComponent, [i8.Actions, i9.ToastService], null, null)], null, null); }
var MessageInputComponentNgFactory = i1.ɵccf("app-cmp-message-input", i7.MessageInputComponent, View_MessageInputComponent_Host_0, { loading: "loading", reply: "reply" }, { messageSubmitted: "messageSubmitted" }, []);
export { MessageInputComponentNgFactory as MessageInputComponentNgFactory };
